import * as React from 'react'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { StatsService } from 'http/statsService'
import { DateRange } from 'dateRanges'
import { format, startOfToday, addDays } from 'date-fns'
import { MessageKind } from 'uiComponents/messages'
import { ChartWrapperWithDateRange as WithDateRangeNotice, areDateRangeDatesEqual } from 'reports/helpers'
import MarketingEmailsTable from './marketingEmailsTable'
import SingleDataItems from './singleDataItems'
import { EmailsData } from 'reports/schema'
import { Sorting } from 'uiComponents/table'
import { HorizontalLine } from 'uiComponents/pageElements'
import { ChartContainer } from 'uiComponents/charts/styleComponents'

interface MarketingEmailsProps {
    dateRange: DateRange
    statsService: StatsService
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    mapEmailName: (data: EmailsData[]) => EmailsData[]
}

interface MarketingEmailsState {
    emailsData: EmailsData[]
    loading: boolean
}

class MarketingEmails extends React.Component<MarketingEmailsProps, MarketingEmailsState> {
    _isMounted = false
    private _lastRequest?: number = undefined

    constructor(props: MarketingEmailsProps) {
        super(props)
        this.state = {
            emailsData: [],
            loading: true,
        }
    }

    async componentDidMount() {
        this._isMounted = true
        await this.getData()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    async componentDidUpdate(prevProps: MarketingEmailsProps) {
        if (
            !areDateRangeDatesEqual(prevProps.dateRange, this.props.dateRange) ||
            prevProps.accountSlug !== this.props.accountSlug
        ) {
            await this.getData()
        }
        const prevQuery = prevProps.navigation.query()
        const query = this.props.navigation.query()
        if (query.meSortBy !== prevQuery.meSortBy || query.meSortDirection !== prevQuery.meSortDirection) {
            const sortedData = this.state.emailsData ? this.sortData(this.state.emailsData) : this.state.emailsData
            this.setState({ emailsData: sortedData })
        }
    }

    getData = async () => {
        const requestTime = new Date().valueOf()
        this._lastRequest = requestTime

        this.setState({ loading: true })
        const dateFrom = format(this.props.dateRange.from || new Date(0), 'yyyy-MM-dd')
        const dateTo = format(this.props.dateRange.to || addDays(startOfToday(), 1), 'yyyy-MM-dd')
        try {
            const emailsData = await this.props.statsService.getEmailsOverviewStats(
                this.props.accountSlug,
                dateFrom,
                dateTo,
                'marketing',
            )
            if (this._lastRequest !== requestTime) {
                return
            }
            const data = this.props.mapEmailName(emailsData)
            const sortedData = data ? this.sortData(data) : data
            if (this._isMounted) {
                this.setState({ emailsData: sortedData, loading: false })
            }
        } catch {
            this.props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Marketing emails table could not be loaded, please try again later.',
            )
            this.setState({ loading: false })
        }
    }

    sortByValue = (item1: EmailsData, item2: EmailsData) => {
        const query = this.props.navigation.query()
        const sortByNonObject = !query.meSortBy || query.meSortBy === 'emailName' || query.meSortBy === 'emailsSent'
        const sortKeyA = sortByNonObject ? item1[query.meSortBy || 'emailName'] : item1[query.meSortBy].number
        const sortKeyB = sortByNonObject ? item2[query.meSortBy || 'emailName'] : item2[query.meSortBy].number
        if (sortKeyA < sortKeyB) {
            return -1
        }
        if (sortKeyA > sortKeyB) {
            return 1
        }
        return 0
    }

    desc = (sortingFunc: (item1: EmailsData, item2: EmailsData) => {}) => {
        return (item1: EmailsData, item2: EmailsData) => -sortingFunc(item1, item2)
    }

    sortData = (dataSeries: EmailsData[]) => {
        const query = this.props.navigation.query()
        const sortByFunction =
            query.meSortDirection && query.meSortDirection === 'desc' ? this.desc(this.sortByValue) : this.sortByValue
        return [...dataSeries].sort(sortByFunction)
    }

    onSortChanged = (sorting: Sorting) => {
        this.props.navigation.addQueryWithReplace({
            meSortBy: sorting.prop,
            meSortDirection: sorting.direction,
        })
    }

    render() {
        const { dateRange, accountSlug, replaceTopMessages, statsService } = this.props

        return (
            <>
                {this.state.emailsData && this.state.emailsData.length > 0 && (
                    <div style={{ marginTop: '-0.8rem' }}>
                        <WithDateRangeNotice dateRange={dateRange} userpilot="emails-marketing-table">
                            <MarketingEmailsTable
                                title="Marketing Emails"
                                dataSeries={this.state.emailsData}
                                loading={this.state.loading}
                                onSortChanged={this.onSortChanged}
                            />
                        </WithDateRangeNotice>
                        <ChartContainer style={{ margin: '3em 0' }}>
                            <SingleDataItems
                                statsService={statsService}
                                accountSlug={accountSlug}
                                dateRange={dateRange}
                                replaceTopMessages={replaceTopMessages}
                            />
                        </ChartContainer>
                        <HorizontalLine />
                    </div>
                )}
            </>
        )
    }
}

export default withNavigation(MarketingEmails)
