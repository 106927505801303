import { Order } from 'orders/schema'
import { calculateOrderPartnerRefundFee } from 'orders/utils/calculateOrderRefund'

interface GetTotalRefundedAmount {
    orders: Order[]
    partnerRefundFee: boolean
}

export const getTotalRefundedAmount = ({ orders, partnerRefundFee }: GetTotalRefundedAmount) => {
    const total = orders.reduce((sum: number, order) => {
        const partnerFee = partnerRefundFee ? calculateOrderPartnerRefundFee({ order: order }) : 0

        return order.totalAfterDiscount + sum - partnerFee
    }, 0)

    return Math.round(total * 100) / 100
}
