export type Department = 'marketing' | 'sales' | 'onboarding' | 'tech'

export interface ConviousMetricsValue {
    name: string
    value: number | null
    format: string | null
}

export interface ConviousMetrics {
    name: string
    values: ConviousMetricsValue[]
}

export interface CardList {
    name: string
    dataName: string
    units: string
    department: Department | null
}

export const weekDataCardList: CardList[] = [
    {
        name: 'Website visits',
        dataName: 'Website visitors',
        units: '',
        department: 'marketing',
    },
    {
        name: 'Downloads',
        dataName: 'Downloads',
        units: '',
        department: 'marketing',
    },
    {
        name: 'Demo requests',
        dataName: 'Demo requests',
        units: '',
        department: 'marketing',
    },
    {
        name: 'Social reach',
        dataName: 'Social reach',
        units: '',
        department: 'marketing',
    },
    { name: 'Leads', dataName: 'Leads', units: '', department: 'marketing' },
    {
        name: 'Qualified leads',
        dataName: "MQL's",
        units: '',
        department: 'marketing',
    },
    {
        name: 'Opportunities',
        dataName: 'Opportunities',
        units: '',
        department: 'marketing',
    },
    {
        name: 'Sales qualified leads',
        dataName: "SQL's",
        units: '',
        department: 'sales',
    },
    {
        name: 'Companies touched',
        dataName: 'Companies touched',
        units: '',
        department: 'sales',
    },
    {
        name: 'Demos given',
        dataName: 'Demos given',
        units: '',
        department: 'sales',
    },
    {
        name: 'Quotes sent',
        dataName: 'Quotes sent',
        units: '',
        department: 'sales',
    },
    { name: 'GMV', dataName: 'GMV', units: '', department: 'sales' },
    {
        name: 'Net revenue',
        dataName: 'Net revenue',
        units: '',
        department: 'sales',
    },
    {
        name: 'Inventory sold',
        dataName: 'Inventory sold',
        units: '',
        department: 'sales',
    },
    {
        name: 'Conversion',
        dataName: 'Checkout conversion rate',
        units: '%',
        department: 'sales',
    },
    {
        name: 'Average fee',
        dataName: 'Average fee',
        units: '%',
        department: 'sales',
    },
]

export const ytdDataCardList: CardList[] = [
    {
        name: 'New partners',
        dataName: 'New partners',
        units: '',
        department: 'sales',
    },
    {
        name: 'Partners',
        dataName: 'Current partners',
        units: '',
        department: 'sales',
    },
    {
        name: 'YTD Revenue',
        dataName: 'YTD revenue',
        units: '',
        department: 'sales',
    },
    {
        name: 'Products live',
        dataName: 'Products live',
        units: '',
        department: 'onboarding',
    },
    {
        name: 'Signature to live',
        dataName: 'Signature to live',
        units: 'wd',
        department: 'onboarding',
    },
    { name: 'Velocity', dataName: 'Velocity', units: '', department: 'tech' },
    { name: 'Uptime', dataName: 'Uptime', units: '%', department: 'tech' },
    {
        name: 'Performance',
        dataName: 'Performance',
        units: 'ms',
        department: 'tech',
    },
    {
        name: 'Cost / transaction',
        dataName: 'Infra cost/transaction, Eur',
        units: '',
        department: 'tech',
    },
    {
        name: 'Committed Revenue 2021',
        dataName: 'Committed GMV 2021',
        units: '',
        department: 'sales',
    },
    {
        name: 'Committed partners 2021',
        dataName: 'Committed partners 2021',
        units: '',
        department: 'sales',
    },
]

export const departmentMap = {
    marketing: {
        abbr: 'M',
        color: '#61A7F7',
        text: 'Marketing department',
    },
    sales: {
        abbr: 'S',
        color: '#9369BD',
        text: 'Sales department',
    },
    onboarding: {
        abbr: 'O',
        color: '#93E7EB',
        text: 'Onboarding department',
    },
    tech: {
        abbr: 'T',
        color: '#C64191',
        text: 'Tech department',
    },
}

export const legend = [
    {
        status: 'error',
        text: '<40% of target',
    },
    {
        status: 'warn',
        text: '40%-69% of target',
    },
    {
        status: 'success',
        text: '>=70% of target',
    },
    {
        status: 'disabled',
        text: 'No target set / no data available',
    },
]

export const colorMap = {
    good: 'success',
    average: 'warn',
    bad: 'error',
}
