import { AppServices, history } from 'middleware'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-typed'

import { UploadInput } from 'uiComponents/input'
import { removeAllMessages } from 'uiComponents/messages/actions'
import InlineMessage from 'uiComponents/messages/inlineMessage'
import { Body } from 'uiComponents/typography'
import { downloadFile } from 'utils/downloadFile'
import { pathUtils } from 'utils/pathnameFormatter'
import { CodePageParams, CODES_PATHS } from 'venue/bookingCodes/paths'
import { useUploadDiscountCodeMutation } from 'venue/reduxQuery'

const StyledDownloadLink = styled.span`
    color: ${(props) => props.theme.colors.boyBlue};
    text-decoration: underline;
    cursor: pointer;
`

type FileUploadError = { header?: string; body?: string }

const UploadCsv = () => {
    const { accountSlug, id } = useParams<CodePageParams>()
    const { search } = useLocation()
    const [upload] = useUploadDiscountCodeMutation()
    const dispatch = useDispatch()
    const [fileUploadErrors, setFileUploadErrors] = useState<FileUploadError>()

    const closeModal = () => {
        history.push(
            pathUtils.addQueryString(
                pathUtils.populateParams(CODES_PATHS.fullPaths.poolsPage, { accountSlug }),
                search,
            ),
        )
    }

    const onUpload = (file: File) => {
        setFileUploadErrors(undefined)

        return upload({ accountSlug, uuid: id, file })
            .unwrap()
            .then(() => {
                return {
                    file_name: file.name,
                    file_id: id,
                }
            })
            .catch((error) => {
                const message =
                    error?.response?.error?.message ||
                    'Something went wrong. Please try again or contact your administrator.'

                setFileUploadErrors({ body: message, header: 'Upload error' })

                throw {
                    file_name: file.name,
                    file_id: id,
                }
            })
    }

    return (
        <div style={{ paddingTop: '1rem' }}>
            {!!fileUploadErrors && (
                <div style={{ marginBottom: '1rem' }}>
                    <InlineMessage
                        type="error"
                        header={fileUploadErrors?.header}
                        onClose={() => setFileUploadErrors(undefined)}
                    >
                        {fileUploadErrors?.body}
                    </InlineMessage>
                </div>
            )}
            <UploadInput
                type="file"
                acceptedTypesMsg="CSV"
                acceptedTypes="text/csv"
                extensionCheckOnly
                onUpload={onUpload}
                handleUploadSuccessResponse={closeModal}
                style={{ height: '5em' }}
                uploadArea
                inputId="upload"
                fileSizeLimit={16}
                parseErrorMessage
                replaceTopMessages={(id, status, text) => {
                    if (id === 'validation_error') {
                        setFileUploadErrors({ body: text, header: 'Validation error' })
                    }
                }}
                removeAllMessages={() => dispatch(removeAllMessages())}
            />
            <Body size={2} color="light" style={{ paddingTop: '.5rem', userSelect: 'none' }}>
                You can also:&nbsp;
                <StyledDownloadLink
                    onClick={() => {
                        downloadFile(
                            `${AppServices.accountService.backofficeEndpoint}api/v1/discount_codes_upload_example/`,
                            'discount_code.csv',
                        )
                    }}
                >
                    Download
                </StyledDownloadLink>
                &nbsp; a CSV sample file.
            </Body>
        </div>
    )
}

export default UploadCsv
