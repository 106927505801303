import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import ActionButton, { ActionButtonA } from 'uiComponents/buttons'
import { BaseRouteParams } from '../../../../hocs'
import OverlayConfig from './overlayConfig'
import useUpdateMap from 'venue/parkMap/hooks/useUpdateMap'
import SnapshotConfig from './snapshotConfig'
import { useSelectedParkMapContext } from 'venue/parkMap/context/selectedParkMap'
import OverlayBackgroundColorSelector from './backgroundColorSelector'
import InformationBox from '../informationBox'

export const TabContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding-top: 1em;
`

const Form = styled.form`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
`

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: ${(props) => props.theme.colors.border};
    padding-top: 1em;
`

const Config = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 10px;
`

const Info = styled.div`
    margin: 0.5em 0 1.5em 0;
`

const StyleDetails = () => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const { updateMapStylingData } = useUpdateMap()
    const { selectedParkMap, setSelectedParkMap } = useSelectedParkMapContext()

    const saveChanges = () => {
        updateMapStylingData()
    }

    return (
        <Form>
            <Content>
                <Config>
                    <Info>
                        <InformationBox
                            text={`To create a custom parkmap you will need to first take a map snapshot of the area you desire,
                            you must use the image produced and not crop or change it's dimensions. Then you can upload the
                            custom park map image take note of the max resolution otherwise map won't work. Then adjust the
                            background color to match the maps design.`}
                        />
                    </Info>
                    <OverlayBackgroundColorSelector
                        onColorChange={(bgColor) => {
                            setSelectedParkMap((prev) => {
                                if (prev && prev.overlay) {
                                    return {
                                        ...prev,
                                        overlay: {
                                            ...prev.overlay,
                                            bgColor: bgColor,
                                        },
                                    }
                                }
                                return prev
                            })
                        }}
                        bgColor={selectedParkMap?.overlay?.bgColor ?? null}
                    />
                    <SnapshotConfig />
                    <OverlayConfig />
                </Config>
            </Content>
            <Footer>
                <ActionButtonA size="large" href={`/account/${accountSlug}/venue/park_map/list`} secondary>
                    Back to list
                </ActionButtonA>
                <ActionButton size="large" onClickCapture={saveChanges}>
                    Save
                </ActionButton>
            </Footer>
        </Form>
    )
}

export default StyleDetails
