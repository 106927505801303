import styled from 'styled-typed'
import { HeaderRow, DynamicRow } from 'uiComponents/table'

export const SecondLevelHeaderRow = styled(HeaderRow)`
    &.hidden {
        display: none;
    }
    &&& {
        background: ${(props) => props.theme.colors.white};
    }
`

export const SecondLevelTableRow = styled(DynamicRow)`
    &.hidden {
        display: none;
    }
    height: 4em;
    padding-bottom: 2.5em;
`
