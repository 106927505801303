import React from 'react'
import styled from 'styled-typed'
import { Row, Col } from 'uiComponents/flex'
import { NumberInput, SingleSelect } from 'uiComponents/input'
import { SecondaryText } from 'uiComponents/typography'
import { WrapWithSymbol } from 'uiComponents/form/formElements'
import { ValidationNotice } from 'products/components/validationNotice'
import { DynamicInterval } from 'products/pricing/pricingService'
import { Currency } from 'uiComponents/money/moneyHoc'
import { ToggleWithValues } from 'uiComponents/input/toggle'

const Section = styled.div`
    padding: 0 1em 0 0;
`

const AddText = styled.span`
    color: ${(props) => props.theme.colors.boyBlue};
    font-size: 0.9em;
    cursor: pointer;
    padding: 0 0.5em;
`

const RemoveText = styled(AddText)`
    color: ${(props) => props.theme.colors.status.error};
`

const MetaText = styled(SecondaryText)`
    align-self: center;
`

type Rule = '-' | '+'

type AdjustmentType = 'ABSOLUTE' | 'PERCENTAGE'

interface AdjustmentTypeOption {
    value: AdjustmentType
    name: string
}

const adjustmentOptions = [
    { value: '-', name: 'Decrease' },
    { value: '+', name: 'Increase' },
]

interface DynamicRuleItemProps {
    interval: DynamicInterval | null
    index: number | null
    setInterval: (intervalObject: DynamicInterval) => void
    removeInterval?: () => void
    showRemoveRow: boolean
    currency: Currency
    validate: boolean
    addNewRow?: () => void
    showNewRow?: boolean
}

interface DynamicRuleItemState {
    rule: '+' | '-'
    factor: number | null
    type: 'ABSOLUTE' | 'PERCENTAGE'
    daysValid: boolean
    startDay: number | null
    endDay: number | null
}

export class DynamicRuleItem extends React.Component<DynamicRuleItemProps, DynamicRuleItemState> {
    adjustmentTypes: AdjustmentTypeOption[] = [
        { value: 'ABSOLUTE', name: this.props.currency.symbol },
        { value: 'PERCENTAGE', name: '%' },
    ]
    constructor(props: DynamicRuleItemProps) {
        super(props)
        this.state = {
            rule: this.props.interval ? this.getRuleFromAdjustment(this.props.interval.adjustment) : '+',
            factor: this.props.interval ? this.getValueFromAdjustment(this.props.interval.adjustment) : null,
            type: this.props.interval ? this.props.interval.adjustmentType : 'ABSOLUTE',
            daysValid: true,
            startDay: this.props.interval
                ? this.props.interval.startDay === null
                    ? 0
                    : this.props.interval.startDay
                : 0,
            endDay: this.props.interval ? (this.props.interval.endDay === null ? 0 : this.props.interval.endDay) : 0,
        }
    }
    componentDidUpdate(prevProps: DynamicRuleItemProps) {
        if (prevProps.interval !== this.props.interval && this.props.interval) {
            this.setState({
                rule: this.getRuleFromAdjustment(this.props.interval.adjustment),
                factor: this.getValueFromAdjustment(this.props.interval.adjustment),
                type: this.props.interval.adjustmentType,
            })
        }
    }
    getRuleFromAdjustment = (adjustment: string | null): Rule => {
        if (adjustment) {
            return Number(adjustment) < 0 ? '-' : '+'
        }
        return '-'
    }

    getValueFromAdjustment = (adjustment: string | null): number | null => {
        if (adjustment === null) {
            return null
        }
        return Math.abs(Number(adjustment))
    }

    getStartDay = (): number | null => {
        return this.props.interval && this.props.interval.startDay ? this.props.interval.startDay : null
    }

    getEndDay = (): number | null => {
        return this.props.interval && this.props.interval.endDay ? this.props.interval.endDay : null
    }

    getAdjustmentFromRule = (rule: Rule) => {
        return rule === '+' || this.state.factor === null ? this.state.factor : this.state.factor * -1
    }

    handleRuleChange = (value: Rule) => {
        const adjustment = this.getAdjustmentFromRule(value)
        this.props.setInterval({
            startDay: this.getStartDay(),
            endDay: this.getEndDay(),
            adjustment: this.getAdjustmentValue(adjustment),
            adjustmentType: this.state.type,
        })
    }

    getAdjustmentValue = (adjustment: number | null): string | null => {
        return adjustment === null ? null : String(adjustment)
    }

    handleFactorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        this.setState({ factor: value === '' ? null : +e.target.value })
    }

    handleIntervalChange = (v: string) => {
        const value = this.state.rule === '+' ? Number(v) : Number(v) * -1
        const adjustment = v === '' ? null : String(value)
        this.props.setInterval({
            startDay: this.getStartDay(),
            endDay: this.getEndDay(),
            adjustment: adjustment,
            adjustmentType: this.state.type,
        })
    }

    handleAdjustmentTypeChange = (value: AdjustmentType) => {
        let adjustment = this.getAdjustmentFromRule(this.state.rule)
        if (value === 'PERCENTAGE' && !!adjustment) {
            adjustment = Math.min(adjustment, 99)
        }
        this.props.setInterval({
            startDay: this.getStartDay(),
            endDay: this.getEndDay(),
            adjustment: this.getAdjustmentValue(adjustment),
            adjustmentType: value,
        })
    }

    handleDayChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'start' | 'end') => {
        const value = e.target.value
        if (type === 'start') {
            this.setState({ startDay: value === '' ? null : +e.target.value })
        } else {
            this.setState({ endDay: value === '' ? null : +e.target.value })
        }
    }

    updateInterval = (v: string, type: 'start' | 'end') => {
        this.validateDays(v, type)
        const adjustment = this.getAdjustmentFromRule(this.state.rule)
        const value = v === '' ? null : +v
        if (type === 'start') {
            this.props.setInterval({
                startDay: value,
                endDay: this.getEndDay(),
                adjustment: this.getAdjustmentValue(adjustment),
                adjustmentType: this.state.type,
            })
        } else {
            this.props.setInterval({
                startDay: this.getStartDay(),
                endDay: value,
                adjustment: this.getAdjustmentValue(adjustment),
                adjustmentType: this.state.type,
            })
        }
    }

    validateDays = (value: string, type: 'start' | 'end') => {
        if (!this.props.interval) {
            return
        }
        if (type === 'start') {
            if (this.props.interval.endDay) {
                this.setState({ daysValid: +value >= this.props.interval.endDay })
            } else {
                this.setState({ daysValid: true })
            }
        } else {
            if (this.props.interval.startDay) {
                this.setState({ daysValid: +value <= this.props.interval.startDay })
            } else {
                this.setState({ daysValid: true })
            }
        }
    }

    render() {
        const { interval, showRemoveRow, showNewRow } = this.props
        const ruleInvalid = !this.state.factor && this.props.validate
        return (
            <Col span={12} style={{ marginBottom: '.8em' }}>
                <Row style={{ alignItems: 'center' }}>
                    <Section>
                        <SingleSelect
                            id="adjustmentAction"
                            options={adjustmentOptions}
                            selected={this.state.rule}
                            width="10em"
                            height="2.8em"
                            onSelect={this.handleRuleChange}
                            status="highlight"
                        />
                    </Section>
                    <Section>
                        <MetaText>by</MetaText>
                    </Section>
                    <Section>
                        <WrapWithSymbol
                            position="right"
                            symbol={this.state.type === 'ABSOLUTE' ? this.props.currency.symbol : '%'}
                        >
                            <NumberInput
                                id="adjustmentSize"
                                name="adjustmentSize"
                                min={0}
                                max={this.state.type === 'PERCENTAGE' ? '99.99' : 9999999}
                                maxLength={this.state.type === 'PERCENTAGE' ? 5 : 7}
                                value={this.state.factor === null ? '' : this.state.factor}
                                onChange={this.handleFactorChange}
                                onFinishTyping={this.handleIntervalChange}
                                status={ruleInvalid ? 'error' : 'normal'}
                                block={true}
                                style={{ margin: '0', width: '6em' }}
                            />
                        </WrapWithSymbol>
                        {ruleInvalid && (
                            <ValidationNotice className="validation-message-visible">Set a value</ValidationNotice>
                        )}
                    </Section>
                    <Section>
                        <ToggleWithValues
                            options={this.adjustmentTypes}
                            onClick={this.handleAdjustmentTypeChange}
                            selected={this.state.type}
                        />
                    </Section>
                    <Section>
                        <MetaText>from</MetaText>
                    </Section>
                    <Section>
                        <NumberInput
                            id="startDay"
                            name="startDay"
                            placeholder="3"
                            min={0}
                            max={99999}
                            maxLength={3}
                            value={this.state.startDay ? this.state.startDay : ''}
                            onChange={(e) => this.handleDayChange(e, 'start')}
                            onFinishTyping={(v) => {
                                this.updateInterval(v, 'start')
                            }}
                            status={this.state.daysValid ? 'normal' : 'error'}
                        />
                    </Section>
                    <Section>
                        <MetaText>to</MetaText>
                    </Section>
                    <Section>
                        <NumberInput
                            id="endDay"
                            name="endDay"
                            placeholder="1"
                            min={0}
                            max={99999}
                            maxLength={3}
                            value={this.state.endDay ? this.state.endDay : ''}
                            onChange={(e) => this.handleDayChange(e, 'end')}
                            onFinishTyping={(v) => {
                                this.updateInterval(v, 'end')
                            }}
                            status={this.state.daysValid ? 'normal' : 'error'}
                        />
                    </Section>
                    <Section>
                        <MetaText>days before the event</MetaText>
                    </Section>
                    {!interval && !showNewRow && !showRemoveRow && <Section />}
                    {!!this.props.removeInterval && showRemoveRow && (
                        <Section>
                            <RemoveText onClick={this.props.removeInterval}>Remove row</RemoveText>
                        </Section>
                    )}
                    {showNewRow && (
                        <Section>
                            <AddText onClick={this.props.addNewRow}>Add row</AddText>
                        </Section>
                    )}
                </Row>
                <Row>
                    <Section>
                        {!this.state.daysValid && (
                            <ValidationNotice className="validation-message-visible" style={{ left: '43em' }}>
                                End day larger than start day
                            </ValidationNotice>
                        )}
                        {(interval && interval.startDay === null) ||
                            (interval && interval.endDay === null && (
                                <ValidationNotice className="validation-message-visible" style={{ left: '43em' }}>
                                    Please enter both values
                                </ValidationNotice>
                            ))}
                    </Section>
                </Row>
            </Col>
        )
    }
}
