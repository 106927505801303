import React, { useMemo } from 'react'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome'
import EnableProduct from './enable'
import Priority from './priority'
import { ArticleFromApi, CategoryFromApi } from '../types'
import Validity from './validity'
import ProductIdColumn from './productIdColumn'
import ProductListActions from './action'
import { TableColumn } from '../../uiComponents/table/reactTable'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TooltipWrapper from '../../uiComponents/tooltip/tooltipWrapper'

const ClosedIcon = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.colors.aluminiumShades[10]};
    font-size: 1.2rem;
    width: unset !important;
`

const OpenedIcon = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.colors.aluminiumShades[30]};
    font-size: 1.2rem;
    width: unset !important;
`

interface Props {
    depth: number
}

const isArticle = (obj?: any) => {
    return !Object.keys(obj || {}).includes('parentCategory')
}

export const useGetProductTableColumns = ({ depth }: Props) => {
    return useMemo(
        () =>
            [
                {
                    Header: () => null,
                    id: 'expander',
                    accessor: 'expander',
                    width: '2.5rem',
                    disableSortBy: true,
                    disableHideColumn: true,
                    Cell: ({ row }) => {
                        if (Object.keys(row.original).includes('parentCategory')) {
                            return (
                                <div {...row.getToggleRowExpandedProps()} data-testid={`expand_${row.original.id}`}>
                                    {row.isExpanded ? (
                                        <OpenedIcon icon={faChevronUp as IconProp} />
                                    ) : (
                                        <ClosedIcon icon={faChevronDown as IconProp} />
                                    )}
                                </div>
                            )
                        }

                        return null
                    },
                },
                {
                    accessor: 'name',
                    Header: 'Name',
                    Cell: ({ row: { original } }) => {
                        const name = original?.name?.en || original?.name[Object.keys(original?.name)[0]] || '-'

                        return <TooltipWrapper text={name}>{name}</TooltipWrapper>
                    },
                    style: { textOverflow: 'ellipsis' },
                    width: `calc(30.5em - ${depth === 1 ? 0 : (depth - 1) * 30}px)`,
                },
                {
                    accessor: 'enabled',
                    Header: () => null,
                    width: '4rem',
                    disableSortBy: true,
                    disableHideColumn: true,
                    Cell: ({ row: { original }, value }) => {
                        if (isArticle(original)) {
                            return (
                                <EnableProduct
                                    priority={original.priority}
                                    enabled={value}
                                    isArticle={isArticle(original)}
                                    id={original.id}
                                />
                            )
                        }

                        return null
                    },
                },
                {
                    accessor: 'priority',
                    Header: 'Priority',
                    width: '6rem',
                    Cell: ({ value, row: { original } }) => (
                        <Priority
                            enabled={original.enabled}
                            id={original.id}
                            isArticle={isArticle(original)}
                            priority={value}
                        />
                    ),
                },
                {
                    accessor: 'validFrom',
                    Header: 'Validity',
                    width: '12em',
                    Cell: ({ row: { original } }) => <Validity product={original} />,
                },
                {
                    id: 'productId',
                    Header: 'Product ID',
                    width: '12em',
                    Cell: ({ row: { original } }) => <ProductIdColumn productUuid={original.id} />,
                },
                {
                    accessor: 'id',
                    align: 'right',
                    disableHideColumn: true,
                    disableSortBy: true,
                    Cell: ({ row: { original } }) => <ProductListActions product={original} />,
                },
            ] as TableColumn<CategoryFromApi | ArticleFromApi>[],
        [],
    )
}
