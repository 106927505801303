import * as React from 'react'
import Svg from 'svg'
import styled from 'styled-typed'
import { Col } from 'uiComponents/flex'
import { ActionButton } from 'uiComponents/buttons'
import closeIcon from 'uiComponents/error.svg'
import { UrlRules, Rule, ProductName } from 'engageTools/studio/schema'
import { SelectField, TextField } from 'uiComponents/studio/form/fieldComponentSchema'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { FormFieldComponent, InputComponent } from 'uiComponents/studio/form/fieldComponents'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { ErrorMessage } from 'uiComponents/studio/form/errorMessage'
import { ConfigurationUnit } from 'uiComponents/studio/menuItem'

interface BlacklistUrlSectionProps {
    urlRules: UrlRules | null
    updateUrlRules: (list: string, logic: string, ruleType: string, value: string, product: ProductName) => void
    section: ConfigurationUnit
    product: ProductName
}

interface BlacklistUrlSectionState {
    urlRuleType: string
    urlRuleValue: string
    ruleValueValid: boolean
    disabled: boolean
}

export const RuleDisplayContainer = styled.div`
    &:hover {
        .ruleValue {
            .removeTag {
                visibility: visible;
            }
        }
    }
`

export const RuleDisplay = styled(Col)`
    margin-right: 2em;
    > .ruleTitle {
        font-weight: bold;
        margin-bottom: 0.8em;
    }
    > .ruleValue {
        margin-bottom: 0.8em;
        display: flex;
        .valueText {
            width: 23em;
            word-wrap: break-word;
        }
        .removeTag {
            float: right;
            visibility: hidden;
            cursor: pointer;
            color: red;
            width: 1.5em;
            > svg {
                max-width: 1em;
                width: 100%;
                height: auto;
                max-height: 1em;
                float: right;
            }
        }
    }
`
export interface AvailableOption {
    name: string
    value: string
}

export function getRuleTypeName(ruleType: string, rules: AvailableOption[]): string {
    const rule = rules.find((x) => x.value === ruleType)
    if (rule) {
        return rule.name
    } else {
        return ruleType
    }
}

export function sortAlphabetically(a: Rule, b: Rule) {
    if (a.type < b.type) {
        return -1
    }
    if (a.type > b.type) {
        return 1
    }
    return 0
}

export class BlacklistUrlSection extends React.Component<BlacklistUrlSectionProps, BlacklistUrlSectionState> {
    constructor(props: BlacklistUrlSectionProps) {
        super(props)
        this.state = {
            urlRuleType: 'contains',
            urlRuleValue: '',
            ruleValueValid: true,
            disabled: true,
        }
    }
    selectUrlRuleType = (v: string, path: string) => {
        this.setState({ urlRuleType: v })
    }
    handleValueChange = (e: React.ChangeEvent<HTMLSelectElement>, path: string) => {
        this.setState({ disabled: true, urlRuleValue: e.target.value })
    }
    addValueToUrlRule = () => {
        if (this.state.urlRuleValue !== '' && this.state.ruleValueValid) {
            if (
                !this.props.urlRules?.blacklist.find(
                    (rule) => rule.type === this.state.urlRuleType && rule.values.indexOf(this.state.urlRuleValue) > -1,
                )
            ) {
                this.props.updateUrlRules(
                    'blacklist',
                    'add',
                    this.state.urlRuleType,
                    this.state.urlRuleValue,
                    this.props.product,
                )
            }
            this.setState({ urlRuleValue: '' })
        }
    }
    removeValueFromUrlRule = (ruleType: string, ruleValue: string) => {
        this.props.updateUrlRules('blacklist', 'remove', ruleType, ruleValue, this.props.product)
    }

    checkRuleValueValidity = (v: string): void => {
        this.setState({ ruleValueValid: true, disabled: v === '' })
    }

    render() {
        const { section } = this.props
        const urlRules: UrlRules | null = this.props.urlRules
        const selectRuleField = {
            name: 'blacklisted-logic',
            type: 'select',
            availableOptions: [
                {
                    name: 'URLs that contain',
                    value: 'contains',
                },
                {
                    name: 'URLs that do not contain',
                    value: 'not_contains',
                },
                {
                    name: 'REGEX',
                    value: 'regex',
                },
            ],
            label: 'URL that',
            span: 12,
            path: '',
            defaultValue: 'Contains',
        } as SelectField
        const inputField = {
            name: 'blacklisted-string',
            type: 'text',
            span: 8,
            placeholder: 'Enter your condition',
        } as TextField
        return (
            <SectionWrapper title={section.label || ''} infotip={section.infotip || ''}>
                <FormItem>
                    <Col span={selectRuleField.span}>
                        <FormFieldComponent
                            field={selectRuleField}
                            handleChange={this.selectUrlRuleType}
                            value={this.state.urlRuleType}
                        />
                    </Col>
                </FormItem>
                <FormItem>
                    <Col span={inputField.span}>
                        <InputComponent
                            field={inputField}
                            handleChange={this.handleValueChange}
                            value={this.state.urlRuleValue}
                            checkValidity={(v: string) => {
                                this.checkRuleValueValidity(v)
                            }}
                            error={!this.state.ruleValueValid}
                            maxLength={100}
                        />
                    </Col>
                    <Col span={inputField.span ? 12 - inputField.span : 12} style={{ paddingLeft: '0' }}>
                        <ActionButton
                            kind="action"
                            size="small"
                            style={{ height: '100%' }}
                            onClick={this.addValueToUrlRule}
                            disabled={!this.state.ruleValueValid || this.state.disabled}
                        >
                            Add
                        </ActionButton>
                    </Col>
                    {!this.state.ruleValueValid && <ErrorMessage errType="partOfUrl" />}
                </FormItem>
                <FormItem>
                    <RuleDisplayContainer>
                        {urlRules &&
                            urlRules.blacklist.sort(sortAlphabetically).map((rule, i) => {
                                if (rule.values.length > 0) {
                                    return (
                                        <RuleDisplay key={i} span={12}>
                                            <div className="ruleTitle">
                                                {getRuleTypeName(rule.type, selectRuleField.availableOptions)}
                                            </div>
                                            {rule.values.map((val, j) => {
                                                return (
                                                    <div className="ruleValue" key={j}>
                                                        <div className="valueText">{val}</div>
                                                        <div
                                                            className="removeTag"
                                                            onClick={(e) => {
                                                                this.removeValueFromUrlRule(rule.type, val)
                                                            }}
                                                        >
                                                            <Svg src={closeIcon} />
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </RuleDisplay>
                                    )
                                } else {
                                    return null
                                }
                            })}
                    </RuleDisplayContainer>
                </FormItem>
            </SectionWrapper>
        )
    }
}
