import * as React from 'react'
import { connect } from 'react-redux'
import { State } from 'store'
import { Account } from 'auth/state'
import { Col } from 'uiComponents/flex'
import Infotip from 'uiComponents/infotip'
import { MultiSelectList } from 'uiComponents/input/multiselectList'
import { SelectComponent } from 'uiComponents/studio/form/fieldComponents'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { ErrorMessage } from 'uiComponents/studio/form/errorMessage'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { transformLanguagesIntoOptions } from 'engageTools/studio/languages'
import { languagesDict } from 'utils/languages'
import { ConfigurationUnit } from 'uiComponents/studio/menuItem'
import {
    Languages,
    ModalWindowConfiguration,
    Translation,
    OnChangeFunc,
    GetConfigValueFunc,
} from 'engageTools/studio/schema'
import CopySection from './copySection'

interface LanguagesSectionProps {
    accounts: Account[]
    accountSlug: string
    section: ConfigurationUnit
    configuration: ModalWindowConfiguration
    getConfigValue: GetConfigValueFunc
    languages: Languages
    translations: Translation[]
    updateConfiguration: (path: string, value: string | number | boolean | string[]) => void
    updateLanguages: (selected: string[], primary: string) => void
    updateTranslations: (slug: string, language: string, value: string) => void
    resetATranslation: (slug: string) => void
    handleInputChange: OnChangeFunc
    translationsValidation: boolean
    setTranslationsValidation: (on: boolean) => void
    setActiveLanguage: (v: string) => void
    activeLanguage: string
}

interface LanguagesSectionState {
    buttonTextValid: boolean
    barTextValid: boolean
    barUrlValid: boolean
}

export class LanguagesSection extends React.Component<LanguagesSectionProps, LanguagesSectionState> {
    constructor(props: LanguagesSectionProps) {
        super(props)
        this.state = {
            buttonTextValid: true,
            barTextValid: true,
            barUrlValid: true,
        }
    }

    updatePrimaryLanguage = (v: string) => {
        this.props.updateLanguages(this.props.languages.selected, v)
        this.props.setActiveLanguage(v)
    }

    updateLanguages = (languages: string[]) => {
        const primary =
            languages.indexOf(this.props.languages.primary) < 0
                ? languages.length > 0
                    ? languages[0]
                    : 'en'
                : this.props.languages.primary
        this.props.updateLanguages(languages, primary)
        this.props.setActiveLanguage('')
    }

    handleLanguageChange = (v: string, path: string) => {
        this.props.setActiveLanguage(v)
    }

    render() {
        const { section, accounts, accountSlug, handleInputChange, languages, getConfigValue, activeLanguage } =
            this.props
        type SelectType = 'select'
        const selectedLanguagesAsOptions = transformLanguagesIntoOptions(languages.selected)
        const defaultLanguageField = {
            type: 'select' as SelectType,
            name: 'default-language',
            label: 'default language',
            path: 'defaultLanguage',
            defaultValue: 'en',
            availableOptions: selectedLanguagesAsOptions,
            infotip: 'The main language used on your website.',
            span: 6,
        }

        const activeAccount = accounts.find((x) => x.slug === accountSlug)
        const accountIsPartner = activeAccount ? activeAccount.accountType === 'partner' : false
        const languageField = {
            name: 'language',
            type: 'select' as SelectType,
            label: '',
            path: '',
            span: 6,
            availableOptions: transformLanguagesIntoOptions(
                languages.selected.filter((sl) => sl !== this.props.languages.primary),
            ),
            defaultValue: {
                title: 'Select language',
                value: '',
            },
        }
        const secondaryLanguage =
            languages.selected.length === 1
                ? ''
                : activeLanguage !== languages.primary
                ? activeLanguage
                : languages.selected.filter((al) => al !== activeLanguage)[0]

        return (
            <>
                <SectionWrapper title={section.name}>
                    {!accountIsPartner && (
                        <FormItem>
                            <Col span={12}>
                                <MultiSelectList
                                    values={languages.selected}
                                    onChange={this.updateLanguages}
                                    options={languagesDict}
                                    status={languages.selected.length > 0 ? 'normal' : 'error'}
                                />
                                {languages.selected.length < 1 && <ErrorMessage errType="minOne" />}
                            </Col>
                        </FormItem>
                    )}
                    <FormItem>
                        <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                            <label htmlFor={defaultLanguageField.name}>
                                Default language
                                <Infotip pointer="left" maxWidth="17em" smallBoxText>
                                    {defaultLanguageField.infotip}
                                </Infotip>
                            </label>
                        </Col>
                        <Col span={6}>
                            <SelectComponent
                                id={languages.primary}
                                field={defaultLanguageField}
                                handleChange={this.updatePrimaryLanguage}
                                value={languages.primary}
                            />
                        </Col>
                    </FormItem>
                    <CopySection
                        activeLanguage={languages.primary}
                        handleChange={handleInputChange}
                        getConfigValue={(item) => getConfigValue(item, this.props.configuration)}
                        configuration={this.props.configuration as ModalWindowConfiguration}
                        languages={this.props.languages}
                        translations={this.props.translations}
                        updateConfiguration={this.props.updateConfiguration}
                        updateTranslations={this.props.updateTranslations}
                        resetATranslation={this.props.resetATranslation}
                        translationsValidation={this.props.translationsValidation}
                        setTranslationsValidation={this.props.setTranslationsValidation}
                        activeGlobalLanguage={this.props.activeLanguage}
                        setActiveLanguage={this.props.setActiveLanguage}
                    />
                </SectionWrapper>
                {languages.selected.length > 1 && (
                    <SectionWrapper title="Add your translations">
                        <FormItem>
                            <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                                <label htmlFor={languageField.name}>Select language</label>
                            </Col>
                            <Col span={6}>
                                <SelectComponent
                                    id={secondaryLanguage}
                                    field={languageField}
                                    handleChange={this.handleLanguageChange}
                                    value={secondaryLanguage}
                                />
                            </Col>
                        </FormItem>
                        <CopySection
                            activeLanguage={secondaryLanguage}
                            handleChange={handleInputChange}
                            getConfigValue={(item) => getConfigValue(item, this.props.configuration)}
                            configuration={this.props.configuration as ModalWindowConfiguration}
                            languages={this.props.languages}
                            translations={this.props.translations}
                            updateConfiguration={this.props.updateConfiguration}
                            updateTranslations={this.props.updateTranslations}
                            resetATranslation={this.props.resetATranslation}
                            translationsValidation={this.props.translationsValidation}
                            setTranslationsValidation={this.props.setTranslationsValidation}
                            activeGlobalLanguage={this.props.activeLanguage}
                            setActiveLanguage={this.props.setActiveLanguage}
                        />
                    </SectionWrapper>
                )}
            </>
        )
    }
}

function mapStateToProps(state: State) {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
    }
}

export default connect(mapStateToProps)(LanguagesSection)
