import { IconProp } from '@fortawesome/fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight, faCalendar, IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import styled from 'styled-typed'
import { FilterTypes } from '../../hooks/useAudienceBuilder'
import { IDate, IOption, IRule } from '../../types'
import { formatISOString } from 'utils/dates'

const ContainerDefault = styled.div`
    margin-right: 0.8em;
    &:hover {
        cursor: pointer;
    }
`

const ContainerActive = styled(ContainerDefault)`
    & > div {
        border: 1px solid ${(props) => props.theme.colors.boyBlue};
        color: ${(props) => props.theme.colors.boyBlue};
    }
`

const Label = styled.span`
    font-size: 0.75em;
`

const Rule = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors.border};
    height: 32px;
    margin-top: 4px;
`

const Value = styled.div`
    font-size: 0.75em;
    font-weight: 400;
    margin: 0 1em;
`

const Counter = styled.div`
    background-color: ${(props) => props.theme.colors.aluminiumShades['5']};
    font-size: 0.75em;
    font-weight: 400;
    padding: 4px 7px;
    margin-right: 8px;
`

const Icon = styled(FontAwesomeIcon)`
    height: 0.8em;
    cursor: pointer;
    margin-right: 0.8em;
    opacity: 0.4;
    &:hover {
        opacity: 1;
    }
`

interface RuleOutcomeProps {
    rule: IRule
    onSetValue?: (value: string) => void
    onClick: () => void
    onClear: () => void
    isActive?: boolean
}

const RuleOutcomeElement = (props: RuleOutcomeProps) => {
    const Container = props.isActive ? ContainerActive : ContainerDefault

    const QUANTITY_OPERATORS = {
        greater_than: '>',
        less_than: '<',
        equal: '=',
    }

    const onClear = (e: React.MouseEvent) => {
        e.stopPropagation()

        if (!props.onClear) {
            return
        }

        props.onClear()
    }

    const findProductName = (productValue: string, pathHash: string, options: IOption[]): string | undefined => {
        let productName: string | undefined

        options.forEach((option) => {
            if (option.value === productValue) {
                productName = option.caption
            }
            if (option.paths_id?.includes(pathHash) && option.children) {
                productName = findProductName(productValue, pathHash, option.children)
            }
        })
        return productName
    }

    const getProductName = (rule: IRule) => {
        if (!rule.value || rule.value.products.length === 0) {
            return null
        }
        const firstProductValue = rule.value?.products[0] as unknown as string
        const pathHash = rule.value?.product_list_path[0]
        if (!firstProductValue) {
            return null
        }

        return findProductName(firstProductValue, pathHash, rule.options)
    }

    const getIcon = (): IconDefinition => {
        switch (props.rule.rule_id) {
            case FilterTypes.DATE_RANGE:
                return faCalendar
            default:
                return faArrowRight
        }
    }

    const getCaption = (rule: IRule) => {
        switch (props.rule.rule_id) {
            case FilterTypes.DATE_RANGE:
                const value = rule.value?.value as IDate
                const type = rule.value?.type

                if (type === 'preset') {
                    return rule.options.find((option) => option.value === value)?.caption
                } else {
                    return (
                        (value?.start || value?.end) &&
                        `${value?.start ? formatISOString(value?.start, 'yyyy-MM-dd') : '...'} - ${
                            value?.end ? formatISOString(value?.end, 'yyyy-MM-dd') : '...'
                        }`
                    )
                }
            case FilterTypes.PRODUCT:
                return getProductName(rule)
            case FilterTypes.QUANTITY:
                return rule.value?.operator && `${QUANTITY_OPERATORS[rule.value?.operator]} ${rule.value?.value || 0}`
            default:
                return rule.options.find((option) => option.value === rule.value?.value)?.caption
        }
    }

    const caption = getCaption(props.rule)

    const getPlaceholder = () => {
        switch (props.rule.rule_id) {
            case FilterTypes.DATE_RANGE:
                return 'Select range'
            case FilterTypes.QUANTITY:
                return 'Select quantity'
            default:
                return 'Select'
        }
    }

    const getProductsNumber = () => {
        if (props.rule.rule_id !== FilterTypes.PRODUCT) {
            return null
        }

        const value = props?.rule?.value?.products

        if (!value) {
            return null
        }

        const productsNumber = value.length - 1

        if (productsNumber > 0) {
            return `+${productsNumber}`
        }

        return null
    }

    const productsNumber = getProductsNumber()

    return (
        <Container onClick={props.onClick}>
            <Label>{props.rule.name}</Label>
            <Rule>
                {caption ? (
                    <>
                        <Value>{caption}</Value>
                        {productsNumber && <Counter>{productsNumber}</Counter>}
                        <Icon icon={faTimes} onClick={onClear} />
                    </>
                ) : (
                    <>
                        <Value>{getPlaceholder()}</Value>
                        <Icon icon={getIcon() as IconProp} />
                    </>
                )}
            </Rule>
        </Container>
    )
}

export default RuleOutcomeElement
