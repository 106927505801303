import * as React from 'react'
import styled from 'styled-typed'
import { withFeatures } from 'features'
import { match as RouteMatch } from 'react-router-dom'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { StatsService } from 'http/statsService'
import { MessageKind } from 'uiComponents/messages'
import { ChartContainer, ChartHeadline } from 'uiComponents/charts/styleComponents'
import ForecastSingleDataItems from 'reports/forecast/singleDataItems'
import TimeSeriesChart from 'reports/forecast/forecastTimeseriesChart'
import { createTimeseriesBarChart, TimeseriesBarChartSource } from 'reports/forecast/forecastChartSource'
import { startOfToday, endOfDay, addDays } from 'date-fns'
import { DateRangeName, DateRangePeriod } from 'dateRanges'
import PresetDateRangeElement, { PresetType } from 'uiComponents/popups/presetsElement'
import Feature from 'features/feature'
import Infotip from 'uiComponents/infotip'
import Disclaimer from 'reports/disclaimer'
import { AxisTitle } from '../helpers'

const DateRangeTabsWrapper = styled.div`
    position: absolute;
    top: 1.5em;
    right: 3em;
    font-size: 1.2em;
`

interface ForecastSectionProps {
    navigation: Navigation
    match: RouteMatch<any>
    statsService: StatsService
    accountSlug: string
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    hasFeature: (feature: string, slug: string) => boolean
}

interface ForecastSectionState {
    timeseriesChart: TimeseriesBarChartSource
    forecastRange: number
}

class ForecastSection extends React.Component<ForecastSectionProps, ForecastSectionState> {
    constructor(props: ForecastSectionProps) {
        super(props)
        this.state = {
            timeseriesChart: createTimeseriesBarChart(this.getForecastDateRange()),
            forecastRange: this.getForecastRange(),
        }
    }

    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    async componentDidUpdate(prevProps: ForecastSectionProps) {
        if (prevProps.accountSlug !== this.props.accountSlug) {
            const updatedRange = this.getForecastRange()
            if (updatedRange !== this.state.forecastRange) {
                this.setState({
                    timeseriesChart: createTimeseriesBarChart(this.getForecastDateRange(updatedRange)),
                    forecastRange: updatedRange,
                })
            }
        }
    }

    getForecastDateRange = (forecastRange: number | null = null) => {
        const range = forecastRange ? forecastRange : this.getForecastRange()
        const today = startOfToday()
        return {
            name: 'custom' as DateRangeName,
            period: 'day' as DateRangePeriod,
            from: addDays(today, -range),
            to: endOfDay(addDays(today, range - 1)),
        }
    }

    onForecastRangeChange = (rangeString: string) => {
        const range = rangeString === '4weeks' ? 28 : 7
        this.setState({
            timeseriesChart: createTimeseriesBarChart(this.getForecastDateRange(range)),
            forecastRange: range,
        })
    }

    getForecastRange = () => {
        if (!this.props.hasFeature('AnalyticsForecastChart4w', this.props.accountSlug)) {
            return 7
        }
        const query = this.props.navigation.query()
        return query.forecast_presetRange === '4weeks' ? 28 : 7
    }

    render() {
        const { accountSlug, statsService, replaceMessages } = this.props
        const dateRange = this.getForecastDateRange()
        const rangeName = 'forecast_presetRange'
        const defaultRange = '7days'
        const rangeOptions: PresetType[] = ['7days', '4weeks']

        return (
            <>
                <ChartContainer style={{ marginBottom: '2em', position: 'relative' }}>
                    <ChartHeadline size={4} style={{ marginBottom: '1.5rem' }}>
                        Visitors forecast (beta)
                        <div style={{ fontSize: '0.5em' }}>
                            <Infotip pointer="left" maxWidth="20em">
                                Beta means that we are continuously working on improving this graph. You will be
                                informed about any major updates.
                            </Infotip>
                        </div>
                    </ChartHeadline>
                    <Feature name="AnalyticsForecastChart4w" accountSlug={this.props.accountSlug}>
                        <DateRangeTabsWrapper>
                            <PresetDateRangeElement
                                presetName={rangeName}
                                default={defaultRange}
                                options={rangeOptions}
                                onChange={this.onForecastRangeChange}
                            />
                        </DateRangeTabsWrapper>
                    </Feature>
                    <ForecastSingleDataItems
                        statsService={statsService}
                        accountSlug={accountSlug}
                        dateRange={dateRange}
                        forecastRange={this.state.forecastRange}
                        replaceTopMessages={replaceMessages}
                    />
                    <AxisTitle
                        title="Visit Dates"
                        left="50%"
                        bottom="1.5em"
                        style={{ transform: 'translateX(-50%)' }}
                    />
                    <TimeSeriesChart
                        statsService={statsService}
                        accountSlug={accountSlug}
                        dateRange={dateRange}
                        forecastRange={this.state.forecastRange}
                        chart={this.state.timeseriesChart}
                        replaceTopMessages={replaceMessages}
                    />
                </ChartContainer>
                <Disclaimer />
            </>
        )
    }
}

export default withFeatures(withNavigation(ForecastSection))
