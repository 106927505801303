import * as React from 'react'
import styled from 'styled-typed'
import Svg from 'svg'
import tapIcon from 'images/tap-icon.svg'

const IconContainer = styled.div`
    width: 1.75em;
    height: 1.75em;
    border-radius: 5px;
    position: relative;
    border: 1px solid ${(props) => props.theme.colors.textLight};

    &.centered {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

export const WonderBarIcon = styled(IconContainer)`
    &:after {
        content: '';
        height: 0.44em;
        width: 100%;
        background: ${(props) => props.theme.colors.textDark};
        position: absolute;
        border-radius: 5px 5px 0 0;
    }
`

export const ModalWindowIcon = styled(IconContainer)`
    &:after {
        content: '';
        height: 0.875em;
        width: 0.875em;
        background: ${(props) => props.theme.colors.textDark};
        position: absolute;
        border-radius: 3px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
`

export const TriggerButtonIcon = styled(IconContainer)`
  &:after {
    content: '';
    height: .5em;
    width: .5em;
    background: ${(props) => props.theme.colors.textDark};
    position: absolute;
    bottom: .25em;
    left: .25em
    border-radius: 50%;
  }
`

export function TAPAppIcon() {
    return (
        <IconContainer className="centered">
            <Svg src={tapIcon} style={{ width: '1.25em' }} />
        </IconContainer>
    )
}
