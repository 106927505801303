import React from 'react'

import FieldWrapper, { FieldStatus } from '../fieldWrapper'
import { useCustomId } from '../_shared/hooks'
import { SingleSelect, SingleSelectProps } from './singleSelect'

interface SingleSelectFieldProps<T = any> extends Omit<SingleSelectProps<T>, 'selected' | 'onSelect' | 'status'> {
    name: string
    label?: string
    id?: string
    error?: string
    value: string
    onChange(value: string): void
    state?: FieldStatus
}

const SingleSelectField: React.FC<SingleSelectFieldProps> = ({
    name,
    label,
    id,
    error,
    value,
    onChange,
    state,
    ...props
}) => {
    const innerId = useCustomId(id)
    const _state = !!error ? 'error' : (state as FieldStatus) || 'normal'

    return (
        <FieldWrapper label={label} error={error} id={innerId} status={_state}>
            <SingleSelect
                {...props}
                id={innerId}
                onSelect={(_value: string) => onChange(_value)}
                selected={value}
                status={_state}
                name={name}
            />
        </FieldWrapper>
    )
}

export default SingleSelectField
