import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ActionButton from 'uiComponents/buttons'
import { FormWrapper } from 'uiComponents/form/form'
import { CheckboxFieldFormik } from 'uiComponents/input'
import { ModalDialog, ModalDialogTitle, NavigationSection } from 'uiComponents/popups/modal'
import { Body } from 'uiComponents/typography'
import { AuthTicket } from '../../../http/oauthService'
import { AppServices } from '../../../middleware'
import { downloadLinkExtendForm } from '../../../uiComponents/downloadLink/utils'
import { replaceMessage } from '../../../uiComponents/messages/actions'
import { useLazyGetExportAudienceEndpointQuery } from '../reduxQuery'
import { ICustomAudience } from '../types'

interface downloadCustomModalProps {
    audience: ICustomAudience | undefined
    onClose: () => void
}

const DownloadCustomModal = ({ audience, onClose }: downloadCustomModalProps) => {
    const dispatch = useDispatch()
    const ticket = useSelector<any, AuthTicket>((state) => state.auth.ticket)

    const [getExportAudienceEndpoint, { data: endpoint }] = useLazyGetExportAudienceEndpointQuery()

    const filterValues = (key: string, values: { [key: string]: any }) => {
        const isKeyForVisualEffect = key === 'guest'
        const onlyTrueValuesSent = values[key]

        if (isKeyForVisualEffect) {
            return false
        }

        return onlyTrueValuesSent
    }

    return (
        <>
            {!!audience && (
                <ModalDialog
                    onDismiss={onClose}
                    interactive
                    fromTop="20%"
                    style={{
                        width: '508px',
                    }}
                >
                    <FormWrapper
                        enableReinitialize
                        initialValues={{
                            marketingCookies: true,
                            marketingOptIn: true,
                        }}
                        onSubmit={async (values) => {
                            if (audience.id) {
                                await getExportAudienceEndpoint({ id: audience.id, slug: audience.account_slug })

                                dispatch(
                                    replaceMessage(
                                        'export_starting',
                                        'success',
                                        'Audience data generation may take up to 30 seconds and will download automatically.',
                                        30000,
                                    ),
                                )

                                if (endpoint) {
                                    downloadLinkExtendForm({
                                        downloadEndpoint: endpoint,
                                        loginService: AppServices.loginService,
                                        ticket: ticket,
                                        values: Object.keys(values)
                                            .filter((key) => filterValues(key, values))
                                            .map((key) => {
                                                return {
                                                    element: 'input',
                                                    name: key,
                                                    value: values[key],
                                                }
                                            }),
                                    })
                                }

                                onClose()
                            }
                        }}
                    >
                        <ModalDialogTitle>Apply audience filters</ModalDialogTitle>
                        <Body size={1} style={{ paddingBottom: 24 }}>
                            Please select the filters to apply to “{audience?.name}” audience before downloading it.
                        </Body>
                        <CheckboxFieldFormik variant="inline" name="marketingCookies" label="Marketing cookies" />
                        <CheckboxFieldFormik variant="inline" name="marketingOptIn" label="Marketing opt-in" />
                        <Body size={3} weight="light" color="light" style={{ paddingTop: 8 }}>
                            Be aware that following the General Data Protection Regulations, audiences used for direct
                            marketing purposes need to consent to receive communication and/or be subject to
                            personalization.
                        </Body>
                        <NavigationSection>
                            <ActionButton secondary size="large" onClick={onClose}>
                                Cancel
                            </ActionButton>
                            <ActionButton size="large" id="edit-refund-bulk-orders" type="submit">
                                Download
                            </ActionButton>
                        </NavigationSection>
                    </FormWrapper>
                </ModalDialog>
            )}
        </>
    )
}

export default DownloadCustomModal
