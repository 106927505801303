import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import { OneLevelMenu, OptionsList, OptionBox, OptionCheckBox } from 'uiComponents/menus/'
import { Radio } from 'uiComponents/input'
import { usePrevious } from 'reactUtils'
import { SmallText } from 'uiComponents/typography'
import { IconProp } from '@fortawesome/fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { Navigation } from 'navigation'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import isEqual from 'lodash/isEqual'

const checkboxOptions = [
    { slug: 'rtp', name: 'RTP' },
    { slug: 'static', name: 'STATIC' },
    { slug: 'upsell', name: 'UPSELL' },
    { slug: 'nyop', name: 'NYOP' },
]

const radioOptions = [
    { slug: 'categories', name: 'Categories view' },
    { slug: 'products', name: 'Products view' },
]

const GrayText = styled.span`
    color: ${(props) => props.theme.colors.textLight};
`

interface ViewSelectorProps {
    setRadioSelection: (option: string) => void
    navigation: Navigation
    match: RouteMatch<any>
}

function ViewSelector(props: ViewSelectorProps) {
    const query = props.navigation.query()
    const selectedPricingTypes = query.pricingTypes ? decodeURIComponent(query.pricingTypes).split(',') : []
    const [selectedRadio, setSelectedRadio] = useState<'categories' | 'products'>('categories')
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>(selectedPricingTypes)

    useEffect(() => {
        if (location.pathname.indexOf('flat') > -1) {
            setSelectedRadio('products')
        } else {
            setSelectedRadio('categories')
        }
    }, [location.pathname])

    const prevPricingTypes = usePrevious(query.pricingTypes)
    useEffect(() => {
        if (!isEqual(query.pricingTypes, prevPricingTypes)) {
            setSelectedCheckboxes(selectedPricingTypes)
        }
    }, [query])

    const toggleCheckboxOptions = (slug: string) => {
        let updatedSelection = [...selectedCheckboxes]
        updatedSelection.indexOf(slug) > -1
            ? (updatedSelection = updatedSelection.filter((f) => f !== slug))
            : updatedSelection.push(slug)
        setSelectedCheckboxes(updatedSelection)
        props.navigation.addQueryWithReplace({
            pricingTypes: encodeURIComponent(updatedSelection.join(',')),
        })
    }

    const toggleRadioOptions = (slug: 'categories' | 'products') => {
        setSelectedRadio(slug)
        props.setRadioSelection(slug)
    }

    return (
        <div style={{ width: '10em', fontSize: '.85em' }}>
            <OneLevelMenu
                arrowHidden
                menuName="Adjust view"
                closeMenu={false}
                disabledInfotipText=""
                icon={faCog as IconProp}
            >
                <OptionsList style={{ padding: '.2em 0' }}>
                    <div style={{ margin: '.5em 0 .5em 1em' }}>
                        <GrayText>
                            <SmallText>View mode</SmallText>
                        </GrayText>
                    </div>
                    {radioOptions.map((r) => (
                        <OptionBox
                            key={r.slug}
                            onClick={() => toggleRadioOptions(r.slug as 'categories' | 'products')}
                            style={{ paddingLeft: '1em' }}
                        >
                            <Radio name={r.slug} onChange={() => {}} checked={selectedRadio === r.slug} />
                            <span>{r.name}</span>
                        </OptionBox>
                    ))}
                    {selectedRadio === 'products' && (
                        <>
                            <div style={{ margin: '.5em 0 .5em 1em' }}>
                                <GrayText>
                                    <SmallText>Show by type</SmallText>
                                </GrayText>
                            </div>
                            {checkboxOptions.map((f) => (
                                <OptionBox key={f.slug} onClick={() => toggleCheckboxOptions(f.slug)}>
                                    <OptionCheckBox
                                        name={f.slug}
                                        checked={selectedCheckboxes.indexOf(f.slug) > -1}
                                        onChange={() => {}}
                                    />
                                    <span>{f.name}</span>
                                </OptionBox>
                            ))}
                        </>
                    )}
                </OptionsList>
            </OneLevelMenu>
        </div>
    )
}

export default withNavigation(ViewSelector)
