import * as React from 'react'
import { match as RouteMatch } from 'react-router-dom'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { InventoryService } from 'inventory/inventoryService'
import { withMessages, MessageProps } from 'hocs'
import { StatsService } from 'http/statsService'
import { dateRangeToQuery, DateRange } from 'dateRanges'
import { PageTitle, PageHeading, Headline } from 'uiComponents/typography'
import { createTimeseriesBarChart, TimeseriesBarChartSource } from 'uiComponents/charts/timeseriesBarChartSource'
import { Messages } from 'uiComponents/messages'
import { FilterCategory } from 'uiComponents/filter/schema'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { getDataFromQuery, areDateRangeDatesEqual, ReportsToolsWrapper } from 'reports/helpers'
import { AVAILABLE_FILTERS, STANDALONE_FILTERS, METADATA_CATEGORIES } from 'reports/constants'
import ReportsFilter from 'reports/reportsFilter'
import SingleDataItems from './singleDataItems'
import PushNotificationChart from './pushNotificationChart'
import { ChartContainer } from 'uiComponents/charts/styleComponents'
import NotificationsTable, { TABLE_TYPE } from './notificationsTable'
import Disclaimer from 'reports/disclaimer'

interface ReportsPageProps {
    navigation: Navigation
    match: RouteMatch<any>
    statsService: StatsService
    inventoryService: InventoryService
    accountSlug: string
}

function PushNotificationReportsPage(props: ReportsPageProps & MessageProps) {
    const { dateRange, filters } = getDataFromQuery(props.navigation.query())
    const [chart, setChart] = React.useState<TimeseriesBarChartSource>(createTimeseriesBarChart(dateRange))

    React.useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    const onDateRangeChanged = (range: DateRange) => {
        if (!areDateRangeDatesEqual(dateRange, range)) {
            props.navigation.addQueryWithReplace(dateRangeToQuery(range))
            setChart(createTimeseriesBarChart(range))
        }
    }

    const appliedFilters = filters.filter(
        (f) => AVAILABLE_FILTERS['notification_campaigns'].indexOf(f.attribute as FilterCategory) > -1,
    )

    return (
        <div id="notiication-campaigns-page" style={{ marginBottom: '7em' }}>
            <Messages messages={props.messages} hideMessage={props.hideMessage} />
            <PageTitle data-userpilot="sales-header-tooltip">Push notifications</PageTitle>
            <PageHeading fullWidth>
                See how your Push and chat notification campaigns perform. View our{' '}
                <a
                    target="_blank"
                    href="https://support.convious.com/help/how-to-use-your-push-notifications-report"
                    rel="noreferrer"
                >
                    Knowledge Base
                </a>{' '}
                on how to understand and use the insights in this report.
            </PageHeading>
            <ReportsToolsWrapper>
                <ReportsFilter
                    accountSlug={props.accountSlug}
                    statsService={props.statsService}
                    inventoryService={props.inventoryService}
                    dateRange={dateRange}
                    applicableFilters={AVAILABLE_FILTERS['notification_campaigns']}
                    standaloneFilters={STANDALONE_FILTERS['notification_campaigns']}
                    metadataCategories={METADATA_CATEGORIES['notification_campaigns']}
                    appliedFilters={appliedFilters}
                    replaceMessages={props.replaceMessages}
                    hideMessage={props.hideMessage}
                />
                <DateRangePicker
                    range={dateRange}
                    onChange={onDateRangeChanged}
                    firstAvailableDay={new Date(2019, 0, 1)}
                />
            </ReportsToolsWrapper>
            <SingleDataItems
                filters={appliedFilters}
                accountSlug={props.accountSlug}
                dateRange={dateRange}
                replaceMessages={props.replaceMessages}
            />
            <ChartContainer>
                <Headline size={4}>Push notifications</Headline>
                <PushNotificationChart
                    accountSlug={props.accountSlug}
                    dateRange={dateRange}
                    filters={appliedFilters}
                    chart={chart}
                    replaceTopMessages={props.replaceMessages}
                    hideMessage={props.hideMessage}
                />
            </ChartContainer>
            <div style={{ marginTop: '3em' }}>
                <NotificationsTable
                    accountSlug={props.accountSlug}
                    dateRange={dateRange}
                    filters={appliedFilters}
                    statsService={props.statsService}
                    replaceTopMessages={props.replaceMessages}
                    hideMessage={props.hideMessage}
                    type={TABLE_TYPE.chat_notifications}
                />
                <NotificationsTable
                    accountSlug={props.accountSlug}
                    dateRange={dateRange}
                    filters={appliedFilters}
                    statsService={props.statsService}
                    replaceTopMessages={props.replaceMessages}
                    hideMessage={props.hideMessage}
                    type={TABLE_TYPE.push_notification}
                />
            </div>
            <Disclaimer />
        </div>
    )
}

export default withMessages(withNavigation(PushNotificationReportsPage))
