import * as React from 'react'
import { connect } from 'react-redux'
import { State } from 'store'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Account } from 'auth/state'
import { withFeatures } from 'features'

interface PageContentProps {
    children?: React.ReactNode | undefined
    accountSlug: string
    accounts: Account[]
    requiresPasswordChange: boolean
    isConviousAdmin: boolean
    hasFeature: (feature: string, accountSlug?: string) => boolean
}

const RequiresInstalledScript = (props: PageContentProps) => {
    const { children, hasFeature, accounts, accountSlug, requiresPasswordChange, isConviousAdmin } = props
    const currentAccount = accounts.filter((a) => a.slug === accountSlug)
    const scriptInstalled = currentAccount.length > 0 ? currentAccount[0].scriptDetected : true
    const redirect =
        hasFeature('ScriptInstalledCheck', accountSlug) &&
        !isConviousAdmin &&
        !requiresPasswordChange &&
        !scriptInstalled &&
        location.pathname.indexOf(`/account/${accountSlug}/settings/add_snippet`) < 0
    return (
        <Route>
            {redirect ? <Redirect to={`/account/${accountSlug}/settings/add_snippet`} /> : <Switch>{children}</Switch>}
        </Route>
    )
}

const mapStateToProps = (state: State) => {
    return {
        requiresPasswordChange: state.profile.profile ? state.profile.profile.requiresPasswordChange : false,
        accountSlug: state.preferences.activeAccount,
        isConviousAdmin: state.auth.user ? state.auth.user.isAdmin : false,
        accounts: state.auth.user ? state.auth.user.accounts : [],
    }
}
export default withFeatures(connect(mapStateToProps)(RequiresInstalledScript))
