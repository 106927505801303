import React, { useCallback, useMemo, useState } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sumBy } from 'lodash'
import { useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import Big from 'big.js'

import { useLazyListCartsQuery } from 'orders/reduxQueries'
import { Cart as CartSchema } from 'orders/schema'
import {
    ExtensibleReactTableComponentProps,
    LoadDataParams,
    ReactTable,
    TableColumn,
} from 'uiComponents/table/reactTable'
import { BaseRouteParams, useNavigation } from 'hocs'
import Money from 'uiComponents/money'
import { OnClickMenu, OnClickMenuItem } from 'uiComponents/menus/'
import CartItemTable from './cartItemTable'
import { pathUtils } from 'utils/pathnameFormatter'
import PaymentDeadlineCell from './paymentDeadlineCell'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { ORDER_PATHS } from 'orders/paths'
import { MarkCartAsPaidConfirmationDialog } from './markCartAsPaidConfirmationDialog'
import TooltipWrapper from 'uiComponents/tooltip/tooltipWrapper'
import './cartTable.scss'

const emptyData = [] as any[]

const CartTable = () => {
    const [listCarts, { isFetching, data }] = useLazyListCartsQuery()
    const { accountSlug } = useParams<BaseRouteParams>()
    const navigation = useNavigation()
    const theme = useTheme()
    const { q } = navigation.query()
    const [markAsPaidCartId, setMarkAsPaidCartId] = useState<string | null>(null)

    const onLoadData = useCallback(
        ({ token, pagination: { pageSize } }: LoadDataParams) => {
            listCarts({
                accountSlug,
                query: pathUtils.formatSerchParams({
                    cursor: token,
                    page_size: pageSize,
                    search: q,
                }),
            })
        },
        [accountSlug, q],
    )

    const columns = useMemo(
        () =>
            [
                {
                    Header: () => null,
                    id: 'expander',
                    accessor: 'expander',
                    width: '2.5rem',
                    disableSortBy: true,
                    disableHideColumn: true,
                    Cell: ({ row }) => {
                        return (
                            <div {...row.getToggleRowExpandedProps()}>
                                {row.isExpanded ? (
                                    <FontAwesomeIcon icon={faAngleUp as IconProp} className="opened-icon" />
                                ) : (
                                    <FontAwesomeIcon icon={faAngleDown as IconProp} className="closed-icon" />
                                )}
                            </div>
                        )
                    },
                },
                {
                    accessor: 'customer.email',
                    Header: 'Email',
                    Cell: ({ value }) => <span title={value}>{value}</span> || '-',
                },
                {
                    accessor: 'quantity',
                    Header: 'Qty',
                    Cell: ({ row: { original } }) => {
                        return sumBy(
                            original.items.filter((item) => !!item.amount),
                            (cartItem) => cartItem.amount,
                        )
                    },
                },
                {
                    accessor: 'total',
                    Header: 'Total',
                    Cell: ({ row: { original } }) => {
                        const total = Big(original.totalPriceAfterDiscount).toNumber()
                        return <Money amount={total} accountSlug={accountSlug} />
                    },
                },
                {
                    accessor: 'expiresAt',
                    Header: 'Payment deadline',
                    Cell: ({
                        value,
                        row: {
                            original: { id },
                        },
                    }) => <PaymentDeadlineCell value={value} cartId={id} />,
                },
                {
                    accessor: 'id',
                    align: 'right',
                    style: {
                        overflow: 'visible',
                    },
                    classNames: '',
                    Cell: ({ value, cell }) => {
                        const urls = cell.row.original.urls || []

                        return (
                            <OnClickMenu title="Actions" kind="action" secondary>
                                <OnClickMenuItem>
                                    {!urls.find(({ rel }) => rel === 'resume')?.url ? (
                                        <TooltipWrapper text="Payment initiated" className="disabled-dropdown-item">
                                            Edit Cart
                                        </TooltipWrapper>
                                    ) : (
                                        <UnstyledLink
                                            to={pathUtils.populateParams(ORDER_PATHS.fullPaths.cartEditPage, {
                                                accountSlug,
                                                id: value,
                                            })}
                                        >
                                            Edit Cart
                                        </UnstyledLink>
                                    )}
                                </OnClickMenuItem>
                                <OnClickMenuItem onClick={() => setMarkAsPaidCartId(value)}>
                                    Mark as paid
                                </OnClickMenuItem>
                            </OnClickMenu>
                        )
                    },
                },
            ] as TableColumn<CartSchema>[],
        [accountSlug],
    )

    return (
        <>
            <ReactTable
                noResultsRow
                pagination={{
                    next: data?.next,
                    previous: data?.previous,
                }}
                rowContainerProps={({ isExpanded }) => {
                    return {
                        style: {
                            borderBottom: `1px solid  ${theme.colors.border}`,
                            backgroundColor: isExpanded ? theme.colors.tableRow : 'inherit',
                        },
                    }
                }}
                expanded={({ row }: ExtensibleReactTableComponentProps<CartSchema>) => (
                    <CartItemTable cartItems={row.original.items || emptyData} />
                )}
                loading={isFetching}
                loadData={onLoadData}
                data={data?.results || emptyData}
                columns={columns}
                storeStateInQuery={false}
                tableProps={{ className: 'cart-table' }}
            />
            <MarkCartAsPaidConfirmationDialog
                accountSlug={accountSlug}
                cartId={markAsPaidCartId}
                onConfirm={() => setMarkAsPaidCartId(null)}
                onCancel={() => setMarkAsPaidCartId(null)}
            />
        </>
    )
}

export default CartTable
