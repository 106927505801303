import * as React from 'react'
import { useParams } from 'react-router-dom'
import { BaseRouteParams } from '../../../hocs'
import { LoaderWrapper } from '../../../uiComponents/loaders'
import ConsentText from '../components/consentText'
import useIsPartnerAllowed from '../hooks/useIsPartnerAllowed'
import CustomAudienceForm from '../modules/customAudienceForm'
import { useGetAudienceQuery, useGetFiltersQuery } from '../reduxQuery'
import { Container, Title } from './createCustom'

interface AudiencesRouteParams extends BaseRouteParams {
    id: string
}

const EditCustom = () => {
    useIsPartnerAllowed()

    const { accountSlug } = useParams<BaseRouteParams>()
    const params = useParams<AudiencesRouteParams>()
    const { data, isFetching } = useGetAudienceQuery({ id: params.id, slug: accountSlug })
    const getFilters = useGetFiltersQuery({ slug: accountSlug })

    const filters = React.useMemo(() => {
        return getFilters.data || []
    }, [getFilters.data])

    return (
        <Container>
            <Title>Edit your target audience</Title>
            <ConsentText />
            <LoaderWrapper loading={isFetching}>
                {data && <CustomAudienceForm filters={filters} data={data} />}
            </LoaderWrapper>
        </Container>
    )
}

export default EditCustom
