import { useCallback, useEffect, useState } from 'react'
import googleMapsService from 'googleMaps'
import { MapSnapshotQueryParams } from 'googleMaps/googleMapsService'
import { ImageUploadResponse } from 'http/imagesService'
import { useUploadImageMutation } from 'http/reduxQuery'
import { useParkMapViewerContext } from '../context/mapViewer'
import { useSelectedParkMapContext } from '../context/selectedParkMap'
import useParkMap from './useParkMap'
import useUpdateMap from './useUpdateMap'

const useGetMapSnapshot = () => {
    const { coords, zoom, mapType } = useParkMapViewerContext()
    const { selectedParkMap, setSelectedParkMap } = useSelectedParkMapContext()
    const [snapshotImage, setSnapShotImage] = useState<string | null>(null)
    const [uploadImage] = useUploadImageMutation()
    const { getBounds, getRegionDimensions } = useParkMap()
    const snapshotFileName = `${selectedParkMap?.slug}_parkmap_snapshot.png`
    const { updateMap } = useUpdateMap()

    useEffect(() => {
        if (selectedParkMap?.overlay?.snapshot) {
            setSnapShotImage(selectedParkMap.overlay.snapshot)
        }
    }, [selectedParkMap])

    const takeSnapshot = useCallback(async () => {
        if (!coords || !zoom || selectedParkMap?.overlay?.image) {
            return
        }

        try {
            const regionDimension = getRegionDimensions()
            if (!regionDimension) {
                return
            }

            const params: MapSnapshotQueryParams = {
                center: { ...coords },
                format: 'png',
                zoom: zoom,
                size: {
                    width: regionDimension,
                    height: regionDimension,
                },
                scale: 4,
                mapType,
            }

            const imageResponse = await googleMapsService.getMapSnapshotImg(params)
            if (imageResponse?.status === 200) {
                const blob = await imageResponse.blob()
                var file = new File([blob], snapshotFileName, { type: 'image/png' })
                const response = (await uploadImage({ file })) as { data: ImageUploadResponse | undefined }

                if (response.data && response.data.url) {
                    const bounds = getBounds()
                    setSelectedParkMap((prev) => {
                        if (prev && bounds && response.data) {
                            const newValue = {
                                ...prev,
                                overlay: {
                                    image: null,
                                    snapshot: response.data.url,
                                    northWest: {
                                        lat: bounds?.getNorthEast().lat(),
                                        lng: bounds?.getSouthWest().lng(),
                                    },
                                    southEast: {
                                        lat: bounds?.getSouthWest().lat(),
                                        lng: bounds?.getNorthEast().lng(),
                                    },
                                },
                            }
                            updateMap(newValue)
                            return newValue
                        }
                        return prev
                    })
                }
            }
        } catch (e) {
            console.error(e)
        }
    }, [coords, zoom, mapType, selectedParkMap])

    const openSnapshotImage = async () => {
        if (!snapshotImage) {
            return
        }
        window.open(snapshotImage, '_blank')
    }

    return { snapshotImage, takeSnapshot, openSnapshotImage, snapshotFileName }
}

export default useGetMapSnapshot
