import React from 'react'
import { IOrderItems } from 'orders/schema'
import ItemSection from './components/itemSection'
import SectionDataGrid from './components/sectionDataGrid'
import './styles.scss'

const ItemCancelationRefundSection = ({
    item,
    accountSlug,
    isItemSelected,
}: {
    item: IOrderItems
    accountSlug: string
    isItemSelected: boolean
}) => {
    const detailsData = React.useMemo(
        () => [
            { name: 'Canceled by', value: item.refundInfo?.requestedBy },
            { name: 'Cancelation reason', value: item.refundInfo?.reason },
        ],
        [item, accountSlug],
    )

    return (
        <ItemSection sectionName="Canceled" isItemSelected={isItemSelected} isItemCanceledRefunded={true}>
            <SectionDataGrid dataItems={detailsData} />
        </ItemSection>
    )
}

export default ItemCancelationRefundSection
