import * as React from 'react'
import styled from 'styled-typed'
import { FormItem as Item, FormItemName, WrapWithSymbol } from 'uiComponents/form/formElements'
import { match as RouteMatch } from 'react-router-dom'
import { TextInput, NumberInput, SingleSelect } from 'uiComponents/input'
import { RelativeMessage } from 'uiComponents/messages'
import { ActionButton } from 'uiComponents/buttons/'
import Infotip from 'uiComponents/infotip'
import { TextWithHorizontalLine, Column, ColumnGap, ButtonWrapper } from 'uiComponents/pageElements'
import { Row, Col } from 'uiComponents/flex'
import { History } from 'history'
import { withFeatures } from 'features'
import { Validities } from '../productPricingDetailRouter'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { PricingType, pricingTypesNames } from 'products/articleConfigurationService'
import { ValidationNotice } from 'products/components/validationNotice'
import { priceStepSizeOptions, priceStepSizeInfotipText, charmPricingInfotipText } from '../common'
import { FormTogglerCol, FormToggler } from 'uiComponents/input/toggle'
import LoadingButton from '@mui/lab/LoadingButton'

interface ProductPricingPageParams {
    accountSlug: string
    id: string
}

const FormItem = styled(Item)`
    margin-bottom: 2em;
`

interface PricingDetailsFormProps {
    history: History
    match: RouteMatch<ProductPricingPageParams>
    hasFeature: (feature: string, slug: string) => boolean
    accountSlug: string
    originalPrice: number | null
    minAcceptedPrice: number | null
    averageTargetPrice: number | null
    gatePrice: number | null
    stepSize: string
    charmPricing: boolean
    onSettingsSave: (e: React.FormEvent<HTMLElement>) => void
    onMaxAcceptedPriceChange: (e: React.ChangeEvent<any>) => void
    onMinAcceptancePriceChange: (e: React.ChangeEvent<any>) => void
    onAvgTargetPriceChange: (e: React.ChangeEvent<any>) => void
    onGatePriceChange: (e: React.ChangeEvent<any>) => void
    onStepSizeChange: (v: string) => void
    onCharmPricingChange: (v: boolean) => void
    settingsChanged: boolean
    onSettingsAlertMsgDismiss: () => void
    onFinishTyping: (v: string) => void
    validities: Validities
    formValid: boolean
    getCurrency: (accountSlug: string) => Currency
    hasPermission: (permission: string, accountSlug: string) => boolean
    articleName: string
    pricingType: PricingType
    viewMode: 'flat' | 'nested' | ''
    isSubmitting: boolean
}

function PricingDetailsForm(props: PricingDetailsFormProps) {
    const {
        accountSlug,
        validities,
        formValid,
        originalPrice,
        minAcceptedPrice,
        averageTargetPrice,
        gatePrice,
        stepSize,
        charmPricing,
        articleName,
        pricingType,
        isSubmitting,
    } = props
    const currency = props.getCurrency(accountSlug)

    return (
        <form noValidate id="product-pricing-detail-form">
            <Row>
                <Column>
                    <FormItem htmlFor="productName">
                        <FormItemName style={{ display: 'flex' }}>Product name</FormItemName>
                        <TextInput value={articleName} block disabled />
                    </FormItem>
                    <FormItem htmlFor="productType">
                        <FormItemName style={{ display: 'flex' }}>Pricing type</FormItemName>
                        <TextInput value={pricingTypesNames[pricingType]} block disabled />
                    </FormItem>
                    <Row>
                        <Col span={6}>
                            <FormItem htmlFor="minAccepted">
                                <FormItemName style={{ display: 'flex' }}>
                                    Minimum Accepted Price
                                    <Infotip pointer="left" maxWidth="18em">
                                        Minimum price that you’ll accept for this Product. The difference between Box
                                        Office Price and Minimum Accepted Price is the room that our algorithms will
                                        have to optimize your sales.
                                    </Infotip>
                                </FormItemName>
                                <WrapWithSymbol symbol={currency.symbol} position="left">
                                    <NumberInput
                                        align="left"
                                        id="minAccepted"
                                        name="minAccepted"
                                        maxLength={7}
                                        max={9999}
                                        value={minAcceptedPrice !== null ? minAcceptedPrice : ''}
                                        onChange={props.onMinAcceptancePriceChange}
                                        onFinishTyping={(v) => {
                                            props.onFinishTyping(v)
                                        }}
                                        status={validities['MIN'].valid ? 'normal' : 'error'}
                                        block
                                    />
                                </WrapWithSymbol>
                                <ValidationNotice
                                    className={!validities['MIN'].valid ? 'validation-message-visible' : ''}
                                >
                                    {validities['MIN'].message}
                                </ValidationNotice>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem htmlFor="maxAccepted">
                                <FormItemName style={{ display: 'flex' }}>
                                    Maximum Accepted Price
                                    <Infotip pointer="left" maxWidth="18em">
                                        The maximum price your visitors might pay. Usually your box office price.
                                    </Infotip>
                                </FormItemName>
                                <WrapWithSymbol symbol={currency.symbol} position="left">
                                    <NumberInput
                                        align="left"
                                        id="maxAccepted"
                                        name="maxAccepted"
                                        maxLength={7}
                                        max={9999}
                                        value={originalPrice !== null ? originalPrice : ''}
                                        onChange={props.onMaxAcceptedPriceChange}
                                        onFinishTyping={props.onFinishTyping}
                                        status={validities['BOX'].valid ? 'normal' : 'error'}
                                        block
                                    />
                                </WrapWithSymbol>
                                <ValidationNotice
                                    className={!validities['BOX'].valid ? 'validation-message-visible' : ''}
                                >
                                    {validities['BOX'].message}
                                </ValidationNotice>
                            </FormItem>
                        </Col>
                    </Row>
                    <FormItem htmlFor="averageTarget">
                        <FormItemName style={{ display: 'flex' }}>
                            Average Price
                            <Infotip pointer="left" maxWidth="18em">
                                Your target price for this product. Our algorithms will work within the range you
                                defined to reach this target.
                            </Infotip>
                        </FormItemName>
                        <WrapWithSymbol symbol={currency.symbol} position="left">
                            <NumberInput
                                align="left"
                                id="averageTarget"
                                name="averageTarget"
                                maxLength={7}
                                max={9999}
                                value={averageTargetPrice !== null ? averageTargetPrice : ''}
                                onChange={props.onAvgTargetPriceChange}
                                onFinishTyping={(v) => {
                                    props.onFinishTyping(v)
                                }}
                                status={validities['AVG'].valid ? 'normal' : 'error'}
                                block
                            />
                        </WrapWithSymbol>
                        <ValidationNotice className={!validities['AVG'].valid ? 'validation-message-visible' : ''}>
                            {validities['AVG'].message}
                        </ValidationNotice>
                    </FormItem>
                    <TextWithHorizontalLine
                        text="OPTIONAL SETTINGS"
                        alignment="left"
                        style={{ marginTop: '1em', marginBottom: '1.7em' }}
                    />
                    <Row>
                        <Column>
                            <FormItem htmlFor="gatePrice">
                                <FormItemName style={{ display: 'flex' }}>
                                    Gate price
                                    <Infotip pointer="left" maxWidth="18em">
                                        The box office price. This is the price your users will see stroked out to
                                        emphasise the discounted price.
                                    </Infotip>
                                </FormItemName>
                                <WrapWithSymbol symbol={currency.symbol} position="left">
                                    <NumberInput
                                        align="left"
                                        id="gatePrice"
                                        name="gatePrice"
                                        maxLength={7}
                                        max={9999}
                                        value={gatePrice !== null ? gatePrice : ''}
                                        onChange={props.onGatePriceChange}
                                        onFinishTyping={props.onFinishTyping}
                                        status={validities['GATE'].valid ? 'normal' : 'error'}
                                        block
                                    />
                                </WrapWithSymbol>
                                <ValidationNotice
                                    className={!validities['GATE'].valid ? 'validation-message-visible' : ''}
                                >
                                    {validities['GATE'].message}
                                </ValidationNotice>
                            </FormItem>
                        </Column>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <FormItem htmlFor="stepSize">
                                <FormItemName style={{ display: 'flex' }}>
                                    Price step size
                                    <Infotip pointer="left" maxWidth="26em">
                                        {priceStepSizeInfotipText}
                                    </Infotip>
                                </FormItemName>
                                <SingleSelect
                                    id="stepSize"
                                    options={priceStepSizeOptions}
                                    selected={stepSize}
                                    height="2.5rem"
                                    maxHeight="11em"
                                    noSelectOption="Select step size"
                                    onSelect={props.onStepSizeChange}
                                    style={{ marginBottom: '0.625em', zIndex: 11 }}
                                />
                            </FormItem>
                        </Col>
                        <FormTogglerCol span={6}>
                            <FormItemName style={{ display: 'flex' }}>
                                Charm pricing
                                <Infotip pointer="left" maxWidth="26em">
                                    {charmPricingInfotipText}
                                </Infotip>
                            </FormItemName>
                            <FormToggler
                                id="card-open-by-default"
                                isOn={charmPricing}
                                onClick={props.onCharmPricingChange}
                            />
                        </FormTogglerCol>
                    </Row>
                </Column>
                <ColumnGap />
                <Column />
            </Row>
            {props.settingsChanged && (
                <RelativeMessage
                    style={{ top: '-1em' }}
                    kind="error"
                    showMessage={props.settingsChanged}
                    onDismiss={props.onSettingsAlertMsgDismiss}
                >
                    Changing your prices will affect your revenue and restricts our AI to reach the optimal ticket
                    price.
                </RelativeMessage>
            )}
            <ButtonWrapper>
                <ActionButton
                    size="large"
                    secondary
                    onClick={() => {
                        props.history.push(`/account/${accountSlug}/products/home/${props.viewMode}`)
                    }}
                >
                    {props.hasPermission('edit_pricing_settings', accountSlug) ? 'Cancel' : 'Back'}
                </ActionButton>
                {props.hasPermission('edit_pricing_settings', accountSlug) && (
                    <LoadingButton
                        id="saveSettings"
                        type="button"
                        size="large"
                        variant="contained"
                        disabled={!formValid}
                        onClick={props.onSettingsSave}
                        style={{ marginLeft: '1.5em' }}
                        loading={isSubmitting}
                    >
                        Save
                    </LoadingButton>
                )}
            </ButtonWrapper>
        </form>
    )
}

export default withFeatures(withCurrency(PricingDetailsForm))
