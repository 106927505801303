import * as React from 'react'
import { TargetType } from 'engageTools/studio/schema'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { ChartDataLoader } from 'uiComponents/loaders'
import { ProductName, ConfigurationVersions } from 'engageTools/studio/componentsService'
import { EngageToolStats } from 'engageTools/engageToolsService'
import StatsElement, { DeviceTypeElement } from './statsElement'
import { CampaignContainer, Stats } from './statsContainer'
import { abbreviateNumber } from 'utils'

interface StatsContainerProps {
    product: ProductName
    data: EngageToolStats[] | null
    versions: ConfigurationVersions | null
    activeAccount: string
    loading: boolean
    getCurrency: (accountSlug: string) => Currency
    disabled?: boolean
}

class CheckoutStatsContainer extends React.Component<StatsContainerProps> {
    render() {
        const { getCurrency, loading, versions, disabled } = this.props
        const deviceType: TargetType | 'total' = !!versions
            ? !!versions.deviceType
                ? versions.deviceType
                : 'any'
            : 'total'
        const currency = getCurrency(this.props.activeAccount)
        const hideData = this.props.data?.length === 0
        const data = this.props.data && this.props.data.length > 0 ? this.props.data[0].values : []

        let websiteSessions: number | string = 0
        let checkoutSessions: number | string = 0
        let buyersSessions: number | string = 0
        let revenue: number | string = 0
        let checkoutConversionRate: number | string = 0
        let checkoutVisitRate: number | string = 0
        if (data.length > 0) {
            websiteSessions = data.filter((e) => e.name === 'website_sessions')[0].value
            checkoutSessions = data.filter((e) => e.name === 'checkout_sessions')[0].value
            buyersSessions = data.filter((e) => e.name === 'buyers_sessions')[0].value
            revenue = data.filter((e) => e.name === 'revenue')[0].value
            const conversion = data.filter((e) => e.name === 'conversion_rate')[0].value
            checkoutConversionRate = Math.round(conversion * 100 * 100) / 100 || 0
            const visitRate = data.filter((e) => e.name === 'checkout_visit_rate')[0].value
            checkoutVisitRate = Math.round(visitRate * 100 * 100) / 100 || 0
        }
        if (disabled) {
            websiteSessions = websiteSessions || '-'
            checkoutSessions = checkoutSessions || '-'
            buyersSessions = buyersSessions || '-'
            revenue = revenue || '-'
            checkoutConversionRate = checkoutConversionRate || '-'
            checkoutVisitRate = checkoutVisitRate || '-'
        }

        const formatedRevenue = isNaN(Number(revenue))
            ? revenue
            : Number(revenue) >= 10000
            ? abbreviateNumber(Number(revenue), 1)
            : Number(revenue).toFixed(2)

        return (
            <CampaignContainer className={disabled ? 'disabled' : ''}>
                {(loading || !this.props.data) && <ChartDataLoader topOffset="0" />}
                <Stats>
                    <DeviceTypeElement device={deviceType} name={versions?.name} />
                    <StatsElement
                        title="Website sessions"
                        data={websiteSessions}
                        hideData={hideData}
                        infotipMaxWidth="28em"
                        infotipText="A website session includes all user interactions on your website that are no more than 30 minutes apart."
                        style={{ minWidth: '9.2em' }}
                    />
                    <StatsElement
                        title="Checkout sessions"
                        data={checkoutSessions}
                        hideData={hideData}
                        infotipMaxWidth="28em"
                        infotipText="A Checkout session is a session in which the ticket shop was visited at least once."
                        style={{ minWidth: '9.7em' }}
                    />
                    <StatsElement
                        title="Converted sessions"
                        data={buyersSessions}
                        hideData={hideData}
                        infotipMaxWidth="27.5em"
                        infotipText="The number of sessions in which a user made at least one purchase (Excluding reservations and date changes)."
                        style={{ minWidth: '9.9em' }}
                    />
                    <StatsElement
                        title="Visit rate"
                        data={checkoutVisitRate}
                        qualifier={checkoutVisitRate === '-' ? undefined : '%'}
                        hideData={hideData}
                        infotipMaxWidth="28em"
                        infotipPosition="right"
                        infotipText="The percentage of website sessions in which users entered your Covious Checkout (Checkout sessions/ Website sessions)."
                        style={{ minWidth: '6.2em' }}
                    />
                    <StatsElement
                        title="Conversion"
                        data={checkoutConversionRate}
                        qualifier={checkoutConversionRate === '-' ? undefined : '%'}
                        hideData={hideData}
                        infotipMaxWidth="28em"
                        infotipPosition="right"
                        infotipText="The percentage of Checkout sessions in which users made at least one purchase via your Covious Checkout."
                    />
                    <StatsElement
                        title="Revenue"
                        data={formatedRevenue}
                        qualifier={
                            checkoutConversionRate === '-'
                                ? undefined
                                : currency.position === 'BEFORE'
                                ? `${currency.symbol} `
                                : ` ${currency.symbol}`
                        }
                        qualifierInFront={currency.position === 'BEFORE'}
                        hideData={hideData}
                        infotipPosition="right"
                        infotipMaxWidth="41em"
                        infotipText="The total amount of revenue generated through your Convious Checkout."
                        style={{ minWidth: '7.8em' }}
                    />
                </Stats>
            </CampaignContainer>
        )
    }
}

export default withCurrency(CheckoutStatsContainer)
