import { createApi } from '@reduxjs/toolkit/query/react'
import { ArticleListItem, OrphanArticle, ProductList, ProductListMinimum } from 'admin/articleService'
import { AppServices } from 'middleware/services'
import { createQueryResponseHandling } from 'utils/errorHandler'
import { authenticatedHttpServiceQuery, BaseQueryType } from 'utils/reduxQuery'
import { AvailabilityDates } from './articleConfigurationService'
import { getRequired } from 'config'
import { getActiveAccountSlug } from 'auth/selectors'
import { UpdateArticleStatusResponse } from 'admin/types'
import { replaceMessage } from 'uiComponents/messages/actions'
import { fetchFullyCursorPaginatedData } from 'utils/fetchFullyPaginatedData'
import { ArticleFromApi, CategoryFromApi, ProductAdditionalInfo } from './types'
import { CursorPaginatedResponse } from '../../types'
import { Dispatch } from 'redux'
import { State } from '../store'

export const articleApi = createApi({
    reducerPath: 'articles',
    tagTypes: [
        'listArticles',
        'listProductLists',
        'getProductListMinimalDetails',
        'getAllArticles',
        'getAllCategories',
    ],
    baseQuery: authenticatedHttpServiceQuery({
        baseUrl: getRequired('backoffice-endpoint'),
    }),
    endpoints: (builder) => ({}),
})

interface GetAvailabilityReqProps {
    accountSlug: string
    articleId: string
    categoryId?: string
    startAt: Date
    endAt: Date
}

interface Commission {
    id: number
    name: string
    cornucopiaId: string
}

const articleCache10Min = 10 * 60

const extendedArticleApi = articleApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        listArticles: builder.query<ArticleListItem[], string>({
            providesTags: ['listArticles'],
            queryFn: async (accountSlug: string) => {
                try {
                    const data = await AppServices.articleService.listArticles(accountSlug)

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        getProductLists: builder.query<
            ProductList[],
            {
                accountSlug: string
                parentId?: string
            }
        >({
            providesTags: ['listProductLists'],
            queryFn: async ({ accountSlug, parentId }) => {
                return createQueryResponseHandling(() =>
                    AppServices.articleService.listProductList({ accountSlug, parentId }),
                )
            },
        }),
        getProductListWithArticles: builder.query({
            queryFn: async (accountSlug: string) => {
                return createQueryResponseHandling(() =>
                    AppServices.articleService.getProductListsWithArticles(accountSlug),
                )
            },
        }),
        getOrphanArticles: builder.query<OrphanArticle[], string>({
            queryFn: async (accountSlug: string) => {
                return createQueryResponseHandling(() => AppServices.articleService.listOrphanArticles(accountSlug))
            },
        }),
        getProductListDescendants: builder.query<ProductList, { accountSlug: string; uuid: string }>({
            providesTags: ['listProductLists'],
            queryFn: async ({ accountSlug, uuid }) => {
                return createQueryResponseHandling(() =>
                    AppServices.articleService.getProductListDescendantsOneLayer(accountSlug, uuid),
                )
            },
        }),
        getProductListMinimalDetails: builder.query<ProductListMinimum[], { accountSlug: string }>({
            providesTags: ['getProductListMinimalDetails'],
            queryFn: async ({ accountSlug }) => {
                return createQueryResponseHandling(() =>
                    AppServices.articleService.getProductListMinimalDetails(accountSlug),
                )
            },
        }),
        getAvailability: builder.query<AvailabilityDates, GetAvailabilityReqProps>({
            queryFn: async ({ accountSlug, articleId, categoryId, startAt, endAt }) => {
                return createQueryResponseHandling(() =>
                    AppServices.articleConfigurationService.getAvailability(
                        accountSlug,
                        articleId,
                        categoryId || null,
                        startAt,
                        endAt,
                    ),
                )
            },
        }),
        updateArticleStatus: builder.mutation<UpdateArticleStatusResponse, { id: string; enabled: boolean }>({
            invalidatesTags: [
                'listArticles',
                'listProductLists',
                'getProductListMinimalDetails',
                'getAllArticles',
                'getAllCategories',
            ],
            queryFn: async ({ id, enabled }, { getState, dispatch }) => {
                const accountSlug = getActiveAccountSlug(getState() as any) as string

                const response = await createQueryResponseHandling(() =>
                    AppServices.articleService.updateArticleStatus(accountSlug, id, enabled),
                )

                if (response.error) {
                    dispatch(replaceMessage('updateArticleStatus', 'error', 'Failed to update article status', 5000))
                }

                return response
            },
        }),
        updateProductListStatusAndPriority: builder.mutation<any, { id: string; enabled: boolean; priority: number }>({
            invalidatesTags: [
                'listArticles',
                'listProductLists',
                'getProductListMinimalDetails',
                'getAllArticles',
                'getAllCategories',
            ],
            queryFn: async ({ id, enabled, priority }, { getState, dispatch }) => {
                const accountSlug = getActiveAccountSlug(getState() as any) as string

                const response = await createQueryResponseHandling(() =>
                    AppServices.articleService.updateProductListStatusAndPriority(accountSlug, id, enabled, priority),
                )

                if (response.error) {
                    dispatch(
                        replaceMessage('updateArticleStatus', 'error', 'Failed to update product list status', 5000),
                    )
                }

                return response
            },
        }),
        updateArticlePriority: builder.mutation<any, { id: string; priority: number }>({
            invalidatesTags: ['listArticles', 'listProductLists', 'getProductListMinimalDetails'],
            queryFn: async ({ id, priority }, { getState, dispatch }) => {
                const accountSlug = getActiveAccountSlug(getState() as any) as string

                const response = await createQueryResponseHandling(() =>
                    AppServices.articleService.updateArticlePriority(accountSlug, id, priority),
                )

                if (response.error) {
                    dispatch(replaceMessage('updateArticleStatus', 'error', 'Failed to update product priority', 5000))
                }

                return response
            },
        }),
        getCommission: builder.query<Commission[], any>({
            providesTags: [],
            query: () => ({
                url: 'api/v1/commission_groups/',
            }),
        }),
        getAllCategories: builder.query<CategoryFromApi[], { account: string }>({
            providesTags: (_, __, { account }) => [{ type: 'getAllCategories', id: account }, 'getAllCategories'],
            keepUnusedDataFor: articleCache10Min,
            queryFn: (args, { dispatch }, __, baseQuery: BaseQueryType<CursorPaginatedResponse<CategoryFromApi[]>>) => {
                const params = new URLSearchParams({ ...args, include: 'name,parent_category' }).toString()
                return createQueryResponseHandling(() =>
                    fetchFullyCursorPaginatedData<CategoryFromApi[]>({
                        fetchData: ({ cursor }) =>
                            baseQuery({
                                url: cursor || `api/v1/categories/?${params}`,
                            }).then((res) => res.data),
                        onError: () => {
                            dispatch(util.invalidateTags(['getAllCategories']))
                        },
                    }),
                )
            },
        }),
        getAllArticles: builder.query<ArticleFromApi[], { account: string }>({
            providesTags: (_, __, { account }) => [{ type: 'getAllArticles', id: account }, 'getAllArticles'],
            keepUnusedDataFor: articleCache10Min,
            queryFn: (args, { dispatch }, __, baseQuery: BaseQueryType<CursorPaginatedResponse<ArticleFromApi[]>>) => {
                const params = new URLSearchParams({ ...args, include: 'name,categories,resources' }).toString()

                return createQueryResponseHandling(() =>
                    fetchFullyCursorPaginatedData<ArticleFromApi[]>({
                        fetchData: ({ cursor }) =>
                            baseQuery({
                                url: cursor || `api/v1/products/?${params}`,
                            }).then((res) => res.data),
                        onError: () => {
                            dispatch(util.invalidateTags(['getAllArticles']))
                        },
                    }),
                )
            },
        }),
        archiveCategory: builder.mutation<void, { uuid: string }>({
            invalidatesTags: [
                'getAllArticles',
                'getAllCategories',
                'listArticles',
                'listProductLists',
                'getProductListMinimalDetails',
            ],
            queryFn: ({ uuid }, { getState }) => {
                const accountSlug = getActiveAccountSlug(getState() as State)

                return createQueryResponseHandling(() =>
                    AppServices.articleService.archivePoductList(accountSlug as string, uuid),
                )
            },
        }),
        archiveArticle: builder.mutation<void, { uuid: string }>({
            invalidatesTags: [
                'getAllArticles',
                'getAllCategories',
                'listArticles',
                'listProductLists',
                'getProductListMinimalDetails',
            ],
            queryFn: ({ uuid }, { getState }) => {
                const accountSlug = getActiveAccountSlug(getState() as State)

                return createQueryResponseHandling(() =>
                    AppServices.articleService.archiveArticle(accountSlug as string, uuid),
                )
            },
        }),
        duplicateProduct: builder.mutation<string, { uuid: string }>({
            invalidatesTags: [
                'getAllArticles',
                'getAllCategories',
                'listArticles',
                'listProductLists',
                'getProductListMinimalDetails',
            ],
            queryFn: ({ uuid }, { getState }) => {
                const accountSlug = getActiveAccountSlug(getState() as State)

                return createQueryResponseHandling(() =>
                    AppServices.articleService.duplicateArticle(accountSlug as string, uuid),
                )
            },
        }),
        getProductAdditionalInfo: builder.query<ProductAdditionalInfo[], { account: string }>({
            keepUnusedDataFor: articleCache10Min,
            queryFn: (
                args,
                { dispatch },
                __,
                baseQuery: BaseQueryType<CursorPaginatedResponse<ProductAdditionalInfo[]>>,
            ) => {
                const params = new URLSearchParams(args).toString()

                return createQueryResponseHandling(() =>
                    fetchFullyCursorPaginatedData<ProductAdditionalInfo[]>({
                        fetchData: ({ cursor }) =>
                            baseQuery({
                                url: cursor || `api/v1/products/additional_info/?${params}`,
                            }).then((res) => res.data),
                    }),
                )
            },
        }),
    }),
})

export const {
    useListArticlesQuery,
    useGetProductListsQuery,
    useLazyGetAvailabilityQuery,
    useUpdateArticleStatusMutation,
    useUpdateProductListStatusAndPriorityMutation,
    useUpdateArticlePriorityMutation,
    useGetCommissionQuery,
    useGetAllCategoriesQuery,
    useGetAllArticlesQuery,
    useArchiveArticleMutation,
    useArchiveCategoryMutation,
    useDuplicateProductMutation,
    useGetProductAdditionalInfoQuery,
    endpoints,
    util,
} = extendedArticleApi
export const { reducer, reducerPath } = articleApi

export const invalidatePLCache = (dispatch: Dispatch, accountSlug: string) => {
    dispatch(
        util.invalidateTags([
            { type: 'getAllArticles', id: accountSlug },
            { type: 'getAllCategories', id: accountSlug },
        ]),
    )
}
