import styled, { css } from 'styled-typed'

export const Link = styled.a`
    color: ${(props) => props.theme.colors.boyBlue};
    font-weight: bold;
    transition: color 0.1s ease-in;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: ${(props) => props.theme.colors.textDark};
    }
`

export type StatusType = 'success' | 'error' | 'processing' | 'info'
export type TransformType = 'capitalize' | 'uppercase' | 'lowercase'
interface TextProps {
    status?: StatusType
    transform?: TransformType
}

const successCss = css`
    color: ${(props) => props.theme.colors.status.success};
`

const errorCss = css`
    color: ${(props) => props.theme.colors?.status?.error};
`

const processingCss = css`
    color: ${(props) => props.theme.colors.status.warn};
`

const infoCss = css`
    color: ${(props) => props.theme.colors.textLight};
`

export const Text = styled.span<TextProps>`
    ${(props) => (props.status === 'success' ? successCss : '')}
    ${(props) => (props.status === 'error' ? errorCss : '')}
  ${(props) => (props.status === 'processing' ? processingCss : '')}
  ${(props) => (props.status === 'info' ? infoCss : '')}
  ${(props) => {
        switch (props?.transform) {
            case 'capitalize':
                return 'text-transform: capitalize;'
            case 'uppercase':
                return 'text-transform: uppercase;'
            case 'lowercase':
                return 'text-transform: lowercase;'
            default:
                return ''
        }
    }}
`
