import React from 'react'
import { useField } from 'formik'

import FieldWrapper, { FieldVariant } from '../fieldWrapper'
import { useCustomId, useGetComponentState } from '../_shared/hooks'
import { Checkbox, CheckboxProps } from './checkbox'

interface CheckboxFieldFormikProps extends CheckboxProps, React.InputHTMLAttributes<HTMLInputElement> {
    name: string
    id?: string
    label?: string
    children?: (props: CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>) => React.ReactElement
    variant?: FieldVariant
}

const CheckboxFieldFormik: React.FC<CheckboxFieldFormikProps> = ({
    name,
    label,
    id,
    children: Children,
    variant,
    ...props
}) => {
    const [field, { error }] = useField({ name, type: 'checkbox', value: props.value })
    const state = useGetComponentState(name)
    const innerId = useCustomId(id)

    const customProps = {
        ...props,
        ...field,
        id: innerId,
    }

    return (
        <FieldWrapper
            variant={variant}
            label={label}
            error={error}
            id={innerId}
            status={state}
            labelProps={{ style: { cursor: 'pointer' } }}
        >
            {!!Children ? <Children {...customProps} /> : <Checkbox {...customProps} />}
        </FieldWrapper>
    )
}

export default CheckboxFieldFormik
