import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { BaseRouteParams, ReplaceMessagesFunc } from 'hocs'
import { useGetOrderDetailsByUuidQuery } from 'orders/reduxQueries'
import { IOrder, IOrderItems } from 'orders/schema'
import Money from 'uiComponents/money'
import {
    ExtensibleReactTableComponentProps,
    ReactTable,
    ReactTableRows,
    TableColumn,
} from 'uiComponents/table/reactTable'
import ItemOptions from './itemOptions'
import OrderItemStatus from './orderItemStatus'
import RedeemedOn from './redeemedOn'
import { Checkbox } from 'uiComponents/input'
import {
    getIsTicketRowCheckboxDisabled,
    getSelectedOrderById,
    getSelectedOrderItemsByTableIdAndRowId,
    getSelectedOrderItemsListByTableId,
    shouldToggleOrder,
    toggleOrder,
    toggleOrderItem,
} from 'orders/orders/redux'
import ValidTo from '../../commons/validTo'
import LongTextWithTooltip from 'uiComponents/typography/longTextWithTooltip'
import { convertTimeFormat, DateFormats, formatISOString } from 'utils/dates'

const emptyArray = [] as any[]

interface Props {
    replaceMessages: ReplaceMessagesFunc
    hideMessage: (id: string) => void
}

const OrderDetails: React.FC<Props & ExtensibleReactTableComponentProps<IOrder>> = ({
    row,
    replaceMessages,
    hideMessage,
}) => {
    const theme = useTheme()
    const { accountSlug } = useParams<BaseRouteParams>()

    const { data, isFetching } = useGetOrderDetailsByUuidQuery({
        uuid: row.original.id,
        include: ['items', 'items.options', 'items.barcode', 'items.refund_info'],
    })
    const status = row.original.status
    const tableId = row.original.id
    const rowsSelected = useSelector(getSelectedOrderItemsListByTableId(tableId))
    const dispatch = useDispatch()
    const selectedOrders = useSelector(getSelectedOrderById(row.original.id))

    const columns = (() => {
        return [
            {
                accessor: 'selectable',
                width: '.5rem',
                disableSortBy: true,
                disableHideColumn: true,
                Header: ({}) => {
                    return <div />
                },
                Cell: ({ row }) => {
                    const isSelected = useSelector(getSelectedOrderItemsByTableIdAndRowId(tableId, row.original.id))
                    const disabled = useSelector(getIsTicketRowCheckboxDisabled(tableId, row.original.id))
                    const orderShouldToggle = useSelector(shouldToggleOrder(tableId, row.original.id))
                    const isOrderSelected = useSelector(getSelectedOrderById(tableId))

                    return (
                        <Checkbox
                            id="select-ticket"
                            data-testid="orders-select-ticket-checkbox"
                            checked={isSelected || (!row.original.refundInfo && isOrderSelected)}
                            onChange={(e) => {
                                dispatch(
                                    toggleOrderItem({
                                        rowId: row.original.id,
                                        tableId: tableId,
                                    }),
                                )
                                if (orderShouldToggle) {
                                    dispatch(toggleOrder({ orderUuid: tableId, selected: e.target.checked }))
                                }
                            }}
                            disabled={disabled}
                        />
                    )
                },
            },
            {
                Header: 'Product',
                style: { fontWeight: 400 },
                accessor: 'product',
                width: '11.313rem',
                Cell: ({ value }) => <LongTextWithTooltip text={value}>{value}</LongTextWithTooltip>,
            },
            {
                Header: 'Product ID',
                accessor: 'productNumericId',
                width: '123px',
                Cell: ({ value, row }) => (
                    <Link
                        title={value}
                        to={`/account/${accountSlug}/products/crud/${row.original.productId}`}
                        target="__blank"
                    >
                        {value}
                    </Link>
                ),
            },
            {
                Header: 'Barcode',
                style: { fontWeight: 400 },
                accessor: 'barcode.barcode',
                width: '181px',
                Cell: ({ value }) => <LongTextWithTooltip text={value}>{value}</LongTextWithTooltip>,
            },
            {
                Header: 'Redeemed on',
                style: { fontWeight: 400 },
                accessor: 'barcode.redeemed',
                width: '9.875rem',
                Cell: ({ value }) => <RedeemedOn value={value} />,
            },
            {
                Header: 'Amount',
                style: { fontWeight: 400 },
                accessor: 'pricePerItem',
                width: '4.875rem',
                Cell: ({ value }) => <Money amount={value} accountSlug={accountSlug} />,
            },
            {
                Header: 'Visit Date',
                style: { fontWeight: 400 },
                accessor: 'visitDate',
                width: '7.688rem',
                Cell: ({ value }) => (value ? formatISOString(value, DateFormats.LONG_DATE) : '-'),
            },
            {
                Header: 'Visit time',
                style: { fontWeight: 400 },
                accessor: 'visitTime',
                width: '7.688rem',
                Cell: ({ value }) => (value ? convertTimeFormat(value, 'HH:mm:ss', DateFormats.SHORT_TIME) : '-'),
            },
            {
                Header: 'Valid from',
                style: { fontWeight: 400 },
                accessor: 'validFrom',
                width: '7.688rem',
                Cell: ({ value }) => (value ? formatISOString(value, DateFormats.LONG_DATE) : '-'),
            },
            {
                Header: 'Valid to',
                style: { fontWeight: 400 },
                accessor: 'validTo',
                width: '7.688rem',
                Cell: ({ row: { original } }) => {
                    return (
                        <ValidTo
                            status={status}
                            orderItem={original}
                            orderId={row.original.id}
                            replaceMessages={replaceMessages}
                            hideMessage={hideMessage}
                        />
                    )
                },
            },
            {
                Header: 'Status',
                style: { fontWeight: 400 },
                accessor: 'barcode.status',
                width: '7.688rem',
                Cell: ({ value }) => <OrderItemStatus value={value} />,
            },
        ]
    })() as TableColumn<IOrderItems>[]

    const renderItemOptions = useCallback((row: ReactTableRows<IOrderItems>) => {
        return <ItemOptions data={row.original?.options} />
    }, [])

    return (
        <div style={{ display: 'flex' }} className="orders-details">
            <div style={{ paddingRight: '5rem' }} />
            <ReactTable
                loading={isFetching}
                noResultsRow
                rowSelect
                storeStateInQuery={false}
                rowContainerProps={(row: ReactTableRows<IOrderItems>) => ({
                    style: {
                        borderRadius: 4,
                        border: `1px solid ${
                            !!row.original.refundInfo
                                ? theme.colors.sunrise
                                : rowsSelected.includes(row.original.id)
                                ? theme.colors.boyBlue
                                : theme.colors.aluminiumShades[5]
                        }`,
                        flexWrap: 'wrap',
                        backgroundColor: rowsSelected.includes(row.original.id)
                            ? theme.colors.boyBlueShades[5]
                            : theme.colors.white,
                        marginBottom: '0.5rem',
                    },
                })}
                rowProps={() => ({
                    style: {
                        height: 32,
                        fontSize: '0.75rem',
                    },
                    rowFooter: renderItemOptions,
                })}
                tableProps={{ style: { marginRight: '5rem', fontWeight: 400 } }}
                elevation={false}
                columns={columns}
                data={data?.items || emptyArray}
                backgroundColor="transparent"
                headerProps={{
                    style: {
                        backgroundColor: selectedOrders ? theme.colors.aluminium : 'inherit',
                        color: selectedOrders ? theme.colors.white : theme.colors.aluminium,
                    },
                }}
                innerTable={true}
            />
        </div>
    )
}

export default OrderDetails
