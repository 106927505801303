import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetProductAdditionalInfoQuery } from '../reduxApi'
import { BaseRouteParams } from '../../hocs'

interface Props {
    productUuid: string
}

const ProductIdColumn = ({ productUuid }: Props) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const { data: response, isFetching } = useGetProductAdditionalInfoQuery({ account: accountSlug })
    const id = response?.find((rec) => rec.uuid === productUuid)?.id

    return <div>{isFetching ? '' : id}</div>
}

export default ProductIdColumn
