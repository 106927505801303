import React, { useState, useEffect, useContext, useRef } from 'react'
import styled from 'styled-typed'
import { delay } from 'utils'
import { History } from 'history'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { ActionButton, ActionButtonA } from 'uiComponents/buttons'
import { format, isAfter, isBefore } from 'date-fns'
import { withNavigation } from 'hocs'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { ButtonWrapper } from 'uiComponents/pageElements'
import { MessageKind } from 'uiComponents/messages'
import { Checkbox } from 'uiComponents/input'
import { ContainerBody } from 'uiComponents/settingsContainer'
import { TableLoader, ChartDataLoader } from 'uiComponents/loaders'
import { Col, Row } from 'uiComponents/flex'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { TimePicker, TextInput } from 'uiComponents/input'
import { WeekdayPattern } from 'uiComponents/weekdayPattern'
import { OpeningTimesServiceContext } from './context'
import { Exception, CreateExceptionError } from './openingTimesService'
import Infotip from 'uiComponents/infotip'
import { parseDate, parseTime } from 'utils/dates'
import { SecondaryText } from 'uiComponents/typography'

const Section = styled(Col)`
    margin-bottom: 2em;
`
export const ErrorMessage = styled(ValidationMessage)`
    margin-left: 0;
`

const CheckBoxWrapper = styled.div`
    display: flex;
    cursor: pointer;
    user-select: none;
    & > div:last-child {
        margin: 0;
    }
`

interface OverrideFormParams {
    accountSlug: string
    id: string
    openingTimesId: string
}

interface OverrideFormProps {
    accountSlug: string
    match: RouteMatch<OverrideFormParams>
    history: History
    navigation: Navigation
    setActiveHeader: (header: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

function OverrideForm(props: OverrideFormProps) {
    const didMountRef = useRef(false)
    const openingTimesService = useContext(OpeningTimesServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [updating, setUpdating] = useState<boolean>(false)
    const [name, setName] = useState<string>('')
    const [openingTime, setOpeningTime] = useState<string>('00:00')
    const [closingTime, setClosingTime] = useState<string>('00:00')
    const [sellUntilTime, setSellUntilTime] = useState<string | null>(null)
    const [availableFrom, setAvailableFrom] = useState<string | null>(null)
    const [availableTo, setAvailableTo] = useState<string | null>(null)
    const [openingTimeError, setOpeningTimeError] = useState<string | null>(null)
    const [closingTimeError, setClosingTimeError] = useState<string | null>(null)
    const [sellUntilTimeError, setSellUntilTimeError] = useState<string | null>(null)
    const [availableFromError, setAvailableFromError] = useState<string | null>(null)
    const [availableToError, setAvailableToError] = useState<string | null>(null)
    const [weekdays, setWeekdays] = useState<string>('0,1,2,3,4,5,6')
    const [openingTimesName, setOpeningTimesName] = useState<string>('')
    const [confirmDeletion, setConfirmDeletion] = useState<boolean>(false)
    const [locationClosed, setLocationClosed] = useState<boolean>(false)

    const fetchOverrideData = async (id: string, defaultId: string) => {
        setLoading(true)
        try {
            const defaultData = await openingTimesService.getOpeningTimesUnit(props.accountSlug, defaultId)
            setOpeningTimesName(defaultData.locationName)
            const data = await openingTimesService.getException(props.accountSlug, defaultId, id)
            setDataToState(data)
        } catch {
            props.replaceMessages('server_error', 'error', 'Oops! Could not fetch exception, please try again later.')
        } finally {
            setLoading(false)
        }
    }

    const fetchOpeningTimesUnitData = async (id: string) => {
        setLoading(true)
        try {
            const data = await openingTimesService.getOpeningTimesUnit(props.accountSlug, id)
            setOpeningTimesName(data.locationName)
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Could not fetch opening times, please try again later.',
            )
        } finally {
            setLoading(false)
        }
    }

    const setDataToState = (data: Exception) => {
        setName(data.name)
        setOpeningTime(data.openingTime || '00:00')
        setClosingTime(data.closingTime || '00:00')
        setSellUntilTime(data.sellUntilTime)
        setAvailableFrom(data.availableFrom)
        setAvailableTo(data.availableTo)
        setWeekdays(data.weekdays.join(','))
        setLocationClosed(data.locationClosed || false)
    }

    const validateTimes = () => {
        setOpeningTimeError(
            !locationClosed && isAfter(parseTime(openingTime), parseTime(closingTime))
                ? 'Opening time must be before closing time'
                : null,
        )
        setClosingTimeError(
            !locationClosed && isBefore(parseTime(closingTime), parseTime(openingTime))
                ? 'Closing time must be after opening time'
                : null,
        )
        setSellUntilTimeError(
            !locationClosed && sellUntilTime && isAfter(parseTime(sellUntilTime), parseTime(closingTime))
                ? 'Tickets sale should end before the closing time'
                : null,
        )
    }

    const validateDates = () => {
        setAvailableFromError(
            availableTo && !availableFrom
                ? 'Enter either both effective range dates or neither'
                : !availableTo && !availableFrom && !!locationClosed
                ? 'You must enter the date range'
                : null,
        )
        setAvailableToError(
            availableFrom && !availableTo
                ? 'Enter either both effective range dates or neither'
                : availableTo && availableFrom && isBefore(new Date(availableTo), new Date(availableFrom))
                ? 'Must be after the start date'
                : null,
        )
    }

    useEffect(validateTimes, [openingTime, closingTime, sellUntilTime, locationClosed])
    useEffect(validateDates, [availableFrom, availableTo])

    useEffect(() => {
        const defaultId = props.match.params.openingTimesId
        const exceptionId = props.match.params.id
        props.setActiveHeader(exceptionId === 'new' ? 'Add New Opening Hours Exception' : 'Opening Hours Exception')
        if (exceptionId !== 'new') {
            fetchOverrideData(exceptionId, defaultId)
        } else {
            fetchOpeningTimesUnitData(defaultId)
        }
    }, [])

    useEffect(() => {
        if (didMountRef.current) {
            props.history.push(`/account/${props.accountSlug}/venue/opening_times/locations`)
        } else {
            didMountRef.current = true
        }
    }, [props.accountSlug])

    const isDataValid = () =>
        !openingTimeError &&
        !closingTimeError &&
        !sellUntilTimeError &&
        !availableFromError &&
        !availableToError &&
        name

    const onSaveSuccess = async () => {
        setUpdating(false)
        props.history.push(
            `/account/${props.accountSlug}/venue/opening_times/default/${props.match.params.openingTimesId}`,
        )
        props.replaceMessages('exception_save_sucess', 'success', 'The exception has been saved successfully.')
        props.replaceMessages('success', 'success', 'The exception has been saved successfully.')
        await delay(3000)
        props.removeAllMessages()
    }

    const onExceptionSave = async () => {
        if (isDataValid()) {
            setUpdating(true)
            const exceptionId = props.match.params.id
            const openingTimesId = props.match.params.openingTimesId
            const payload = {
                id: exceptionId,
                name,
                openingTime,
                closingTime,
                sellUntilTime,
                availableFrom,
                availableTo,
                weekdays: weekdays.split(','),
                locationClosed,
            }
            if (exceptionId !== 'new') {
                const responseData = await openingTimesService.updateException(
                    props.accountSlug,
                    payload,
                    openingTimesId,
                    exceptionId,
                )
                responseData.ifFailure(handleFailure).ifSuccess((result) => {
                    onSaveSuccess()
                })
            } else {
                const responseData = await openingTimesService.createException(
                    props.accountSlug,
                    payload,
                    openingTimesId,
                )
                responseData.ifFailure(handleFailure).ifSuccess((result) => {
                    onSaveSuccess()
                })
            }
        } else {
            props.replaceMessages('validation_error', 'error', 'Please fix the validation errors below.')
        }
    }

    const handleFailure = (err: CreateExceptionError) => {
        if (err.code === 'server_error') {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } else {
            props.replaceMessages(err.code, 'error', `Error: ${err.message}.`)
        }
        setUpdating(false)
        throw new Error('Failed to save exception')
    }

    const onDeleteException = async () => {
        if (confirmDeletion) {
            const openingTimesId = props.match.params.openingTimesId
            try {
                await openingTimesService.deleteException(props.accountSlug, openingTimesId, props.match.params.id)
                props.history.replace(`/account/${props.accountSlug}/venue/opening_times/default/${openingTimesId}`)
            } catch {
                props.replaceMessages('server_error', 'error', 'There was a problem deleting the rule.')
            }
        } else {
            setConfirmDeletion(true)
        }
    }

    return (
        <div style={{ position: 'relative' }}>
            {loading && <TableLoader />}
            {updating && <ChartDataLoader />}
            {!loading && (
                <ContainerBody id="exceptions-form" style={{ flexDirection: 'column' }}>
                    <Row>
                        <Col span={6}>
                            <FormItem htmlFor="name">
                                <FormItemName style={{ display: 'flex' }}>Exception name</FormItemName>
                                <TextInput
                                    id="exception-name"
                                    value={name || ''}
                                    block
                                    onChange={(e) => setName(e.target.value)}
                                    status={!name ? 'error' : 'normal'}
                                />
                            </FormItem>
                            <ErrorMessage className={!name ? 'validation-message-visible' : ''}>
                                Please enter an exception name
                            </ErrorMessage>
                        </Col>
                        <Col span={6}>
                            <FormItem htmlFor="openingTimesName">
                                <FormItemName style={{ display: 'flex' }}>Location name</FormItemName>
                                <TextInput value={openingTimesName} block disabled />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <FormItem htmlFor="ticketsSoldUntil">
                                <FormItemName>
                                    Until when tickets should be sold for the same day visit&nbsp;
                                    <SecondaryText>(optional)</SecondaryText>
                                    <Infotip pointer="left">
                                        Leave empty if tickets should be sold the whole day.
                                    </Infotip>
                                </FormItemName>
                                <TimePicker
                                    id="ticketsSoldUntil"
                                    value={sellUntilTime}
                                    onChange={(time) => setSellUntilTime(time)}
                                    style={{ fontWeight: 'lighter' }}
                                    errorMessage={sellUntilTimeError}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem htmlFor="weekly-pattern" style={{ position: 'relative' }}>
                                <FormItemName>Define weekly pattern</FormItemName>
                                <WeekdayPattern
                                    id="weekly-pattern"
                                    weekdays={weekdays ? weekdays : '0,1,2,3,4,5,6'}
                                    large
                                    handleWeekdayToggleChange={(wkds) => setWeekdays(wkds.join(','))}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Section span={6}>
                            <FormItem htmlFor="locationClosed" style={{ marginBottom: '.5em' }}>
                                <CheckBoxWrapper>
                                    <Checkbox
                                        id="locationClosed"
                                        name="locationClosed"
                                        checked={locationClosed}
                                        onChange={(e) => setLocationClosed(e.target.checked)}
                                    />
                                    <FormItemName>Venue is closed</FormItemName>
                                </CheckBoxWrapper>
                            </FormItem>
                            {!locationClosed && (
                                <Row>
                                    <Col span={6}>
                                        <FormItem htmlFor="venueOpens">
                                            <FormItemName>When venue opens</FormItemName>
                                            <TimePicker
                                                id="venueOpens"
                                                value={openingTime}
                                                onChange={(time) => time && setOpeningTime(time)}
                                                style={{ fontWeight: 'lighter' }}
                                                errorMessage={openingTimeError}
                                                required={true}
                                                fallbackValue="00:00"
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem htmlFor="venueCloses">
                                            <FormItemName>When venue closes</FormItemName>
                                            <TimePicker
                                                id="venueCloses"
                                                value={closingTime}
                                                onChange={(time) => time && setClosingTime(time)}
                                                style={{ fontWeight: 'lighter' }}
                                                errorMessage={closingTimeError}
                                                required={true}
                                                fallbackValue="00:00"
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                            )}
                        </Section>
                        <Section span={6}>
                            <Row>
                                <Col span={6} style={{ zIndex: 2 }}>
                                    <FormItem htmlFor="validFrom" style={{ flex: '1' }}>
                                        <FormItemName style={{ display: 'flex' }}>Effective from</FormItemName>
                                        <DatePicker
                                            id="validFrom"
                                            date={availableFrom ? parseDate(availableFrom) : null}
                                            onChange={(value: Date) =>
                                                setAvailableFrom(value ? format(value, 'yyyy-MM-dd') : null)
                                            }
                                            status={availableFromError ? 'error' : 'normal'}
                                            allowNullDate
                                        />
                                    </FormItem>
                                    {availableFromError && (
                                        <ErrorMessage className="validation-message-visible" style={{ top: '0' }}>
                                            {availableFromError}
                                        </ErrorMessage>
                                    )}
                                </Col>
                                <Col span={6} style={{ zIndex: 2 }}>
                                    <FormItem htmlFor="validTo" style={{ flex: '1' }}>
                                        <FormItemName style={{ display: 'flex' }}>Effective to</FormItemName>
                                        <DatePicker
                                            id="validTo"
                                            date={availableTo ? parseDate(availableTo) : null}
                                            onChange={(value: Date) =>
                                                setAvailableTo(value ? format(value, 'yyyy-MM-dd') : null)
                                            }
                                            status={availableToError ? 'error' : 'normal'}
                                            allowNullDate
                                            lowerBoundary={availableFrom ? parseDate(availableFrom) : null}
                                        />
                                    </FormItem>
                                    {availableToError && (
                                        <ErrorMessage className="validation-message-visible" style={{ top: '0' }}>
                                            {availableToError}
                                        </ErrorMessage>
                                    )}
                                </Col>
                            </Row>
                        </Section>
                    </Row>
                    {confirmDeletion && (
                        <Row>
                            <div style={{ fontSize: '.9em', marginBottom: '1.5em' }}>
                                Are you sure you want to delete this rule?
                            </div>
                        </Row>
                    )}
                    <Row style={{ zIndex: 1 }}>
                        <Col span={6}>
                            {props.match.params.id !== 'new' && (
                                <ActionButton type="button" kind="destructive" size="large" onClick={onDeleteException}>
                                    Delete
                                </ActionButton>
                            )}
                            {confirmDeletion && (
                                <ActionButton
                                    size="large"
                                    secondary
                                    onClick={() => setConfirmDeletion(false)}
                                    style={{ marginLeft: '1.5em' }}
                                >
                                    Cancel
                                </ActionButton>
                            )}
                        </Col>
                        <Col span={6}>
                            <ButtonWrapper>
                                <ActionButtonA
                                    size="large"
                                    href={`/account/${props.accountSlug}/venue/opening_times/default/${props.match.params.openingTimesId}`}
                                    secondary
                                    style={{ marginRight: '1.5em' }}
                                    kind="action"
                                >
                                    Cancel
                                </ActionButtonA>
                                <ActionButton
                                    id="save-exception"
                                    size="large"
                                    onClick={onExceptionSave}
                                    style={{ marginRight: '1.5em' }}
                                >
                                    Save
                                </ActionButton>
                            </ButtonWrapper>
                        </Col>
                    </Row>
                </ContainerBody>
            )}
        </div>
    )
}

export default withNavigation(OverrideForm)
