import * as React from 'react'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { ChartDataLoader } from 'uiComponents/loaders'
import { StatsItems } from 'engageTools/engageToolsService'
import StatsElement, { DeviceTypeElement } from './statsElement'
import { CampaignContainer, Stats } from './statsContainer'
import { abbreviateNumber } from 'utils'

interface StatsContainerProps {
    data: StatsItems[]
    activeAccount: string
    loading: boolean
    getCurrency: (accountSlug: string) => Currency
    disabled?: boolean
}

function StatsContainer(props: StatsContainerProps) {
    const currency = props.getCurrency(props.activeAccount)
    const hideData = props.data.length === 0
    let visitors: number | string = 0
    let newVisitors: number | string = 0
    let orders: number | string = 0
    let reservationRate: number | string = 0
    let conversionRate: number | string = 0
    let revenue: number | string = 0
    if (props.data.length > 0) {
        visitors = props.data.filter((e) => e.name === 'app_visitors')[0].value
        newVisitors = props.data.filter((e) => e.name === 'new_app_visitors')[0].value
        orders = props.data.filter((e) => e.name === 'orders')[0].value
        const resRate = props.data.filter((e) => e.name === 'session_reservation_rate')[0].value
        reservationRate = Math.round(resRate * 100 * 100) / 100 || 0
        const conversion = props.data.filter((e) => e.name === 'session_conversion_rate')[0].value
        conversionRate = Math.round(conversion * 100 * 100) / 100 || 0
        revenue = props.data.filter((e) => e.name === 'revenue')[0].value
    }
    if (props.disabled) {
        visitors = visitors || '-'
        newVisitors = newVisitors || '-'
        orders = orders || '-'
        reservationRate = reservationRate || '-'
        conversionRate = conversionRate || '-'
        revenue = revenue || '-'
    }

    const formatedRevenue = isNaN(Number(revenue))
        ? revenue
        : Number(revenue) >= 10000
        ? abbreviateNumber(Number(revenue), 1)
        : Number(revenue).toFixed(2)

    return (
        <CampaignContainer className={props.disabled ? 'disabled' : ''}>
            {props.loading && <ChartDataLoader topOffset="0" />}
            <Stats>
                <DeviceTypeElement device="mobile" />
                <StatsElement
                    title="App visitors"
                    data={visitors}
                    hideData={hideData}
                    infotipText="The number of unique visitors who visited your TAP app."
                    style={{ minWidth: '7.8em' }}
                />
                <StatsElement
                    title="New downloads"
                    data={newVisitors}
                    hideData={hideData}
                    infotipMaxWidth="24em"
                    infotipText="The number of users who had joined your venue on the TAP app for the first time."
                    style={{ minWidth: '9em' }}
                />
                <StatsElement
                    title="Total orders"
                    data={orders}
                    hideData={hideData}
                    infotipMaxWidth="24em"
                    infotipText="Number of orders created by TAP users, when they enter checkout from the TAP app. Both visit reservations and new purchases."
                    style={{ minWidth: '9.5em' }}
                />
                <StatsElement
                    title="Reservations"
                    data={reservationRate}
                    qualifier={reservationRate === '-' ? undefined : '%'}
                    hideData={hideData}
                    infotipPosition="right"
                    infotipMaxWidth="22em"
                    infotipText="The percentage of TAP app visitors that made a visit reservation."
                />
                <StatsElement
                    title="Conversion"
                    data={conversionRate}
                    qualifier={conversionRate === '-' ? undefined : '%'}
                    hideData={hideData}
                    infotipMaxWidth="22em"
                    infotipPosition="right"
                    infotipText="The percentage of TAP app visitors that completed a purchase (paid)."
                />
                <StatsElement
                    title="Revenue"
                    data={formatedRevenue}
                    qualifier={
                        revenue === '-'
                            ? undefined
                            : currency.position === 'BEFORE'
                            ? `${currency.symbol} `
                            : ` ${currency.symbol}`
                    }
                    qualifierInFront={currency.position === 'BEFORE'}
                    hideData={hideData}
                    infotipMaxWidth="28em"
                    infotipPosition="right"
                    infotipText="The total collected revenue attributed to the TAP app."
                />
            </Stats>
        </CampaignContainer>
    )
}

export default withCurrency(StatsContainer)
