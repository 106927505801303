import * as React from 'react'
import styled, { keyframes } from 'styled-typed'
import Svg from 'svg'
import logo from 'convious-logo-with-name.svg'
import guyOnChairImg from 'uiComponents/signUpContainer/guy-on-chair.svg'
import plantLampImg from 'uiComponents/signUpContainer/plant-lamp.svg'
import { Card } from 'uiComponents/cards'

const SlideInDown = keyframes`
  from {
      transform: translate3d(0, -100%, 0);
      visibility: visible;
      opacity: 0;
  }

  to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
  }
`
const FadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`
const FadeOutLeft = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
`
export const SignUpContainer = styled(Card)`
    width: 25em;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3em 3.5em 2.8em 3.5em;
    opacity: 0;
    animation: ${SlideInDown} 0.5s ease-in;
    animation-fill-mode: forwards;

    &.slide-out {
        opacity: 1;
        animation: ${FadeOutLeft} 0.5s ease-in;
        animation-fill-mode: forwards;
    }

    @media (min-width: 568px) {
        width: 100%;
    }
    @media (min-width: 768px) {
        width: 40em;
        padding: 3em 7.75em 2.8em 7.75em;
    }
`
export const SignUpForm = styled.form`
    width: 100%;
    opacity: 0;
    animation: ${FadeIn} 0.5s ease-in 0.7s;
    animation-fill-mode: forwards;
`
export const PageHeader = styled.div`
    font-size: 1.5625em;
    font-weight: 500;
    text-align: center;
    margin: 0 0 1.4em 0;
`
export const NextContainer = styled.div`
    text-align: center;
    margin-top: 2em;
`
export const TermsAgreement = styled.span`
    font-size: 0.714285714em;
    color: ${(props) => props.theme.colors.textLight};
    margin-left: 0.7em;

    & a {
        color: ${(props) => props.theme.colors.boyBlue};
        text-decoration: none;
    }
`
export const ErrorMessageContainer = styled.div`
    position: absolute;
    top: -0.8em;
    width: 22em;

    @media (min-width: 568px) {
        width: 28em;
    }
    @media (min-width: 768px) {
        width: 28.5em;
    }
`

const Background = styled.div`
    overflow-x: hidden;
    font-family: ${(props) => props.theme.fonts.primary};
    color: ${(props) => props.theme.colors.textDark};
    text-align: center;
    flex: 1;
`
const MainContainer = styled.div`
    margin: auto;
    width: 100%;
    min-height: 55.6em;

    @media (min-width: 768px) {
        width: 45em;
    }
`
const Logo = styled(Svg)`
    position: absolute;
    top: 1.7em;
    left: 50%;
    transform: translateX(-50%);
    width: 8.75em;
    max-height: 3em;

    @media (min-width: 768px) {
        left: 2.5em;
        transform: translateX(0%);
    }
    @media (min-width: 1220px) {
        left: -15em;
    }
    @media (min-width: 1400px) {
        top: 2em;
        left: -20em;
    }
`

const LeftImage = styled(Svg)`
    position: absolute;
    width: 14em;
    top: 23em;
    left: -16em;
    max-height: 27em;

    @media (min-width: 1220px) {
        width: 16em;
        top: 21em;
        left: -16em;
    }
    @media (min-width: 1400px) {
        top: 17em;
        width: 18.375em;
        left: -22em;
    }
`
const RightImage = styled(Svg)`
    position: absolute;
    width: 12em;
    top: 0;
    right: -15em;
    z-index: -1;

    @media (min-width: 1220px) {
        width: 14em;
        right: -17em;
    }
    @media (min-width: 1400px) {
        width: 15.625em;
        right: -22em;
    }
`
const Content = styled.div`
    position: relative;
    padding-top: 7.5em;
    text-align: left;

    @media (min-width: 460px) {
        min-height: 51em;
    }

    @media (min-width: 568px) {
        min-width: 100%;
    }
    @media (min-width: 768px) {
        min-width: 40em;
    }
`
const Copyright = styled.div`
    font-size: 0.625em;
    color: ${(props) => props.theme.colors.textLight};
    margin: 2em 0;
    letter-spacing: 0.04em;
`

interface SignUpFlowContainerProps {
    children: React.ReactNode
}

export function SignUpFlowBackground(props: SignUpFlowContainerProps) {
    return (
        <Background>
            <MainContainer>
                <Content>
                    <a href="https://convious.com">
                        <Logo src={logo} />
                    </a>
                    <LeftImage src={guyOnChairImg} />
                    {props.children}
                    <RightImage src={plantLampImg} />
                </Content>
            </MainContainer>
            <Copyright>&copy; 2018 Convious. All Rights Reserved</Copyright>
        </Background>
    )
}
