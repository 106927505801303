import * as React from 'react'

import { ActionButton } from 'uiComponents/buttons'
import { ModalDialog, ModalDialogTitle, NavigationSection } from 'uiComponents/popups/modal'
import { LoaderWrapper } from 'uiComponents/loaders'
import { SingleSelectOption } from 'uiComponents/input'
import { ReservationPoolForm, reservationPoolValidationSchema } from './_shared/form'
import { FormWrapper } from 'uiComponents/form/form'

interface DialogProps {
    accountSlug: string
    loading: boolean
    retailerOptions: SingleSelectOption[]
    onCancel: () => void
    onConfirm: (name: string, retailer: string, isScannable: boolean) => void
}

export function AddNewPoolDialog(props: DialogProps) {
    return (
        <ModalDialog onDismiss={props.onCancel} interactive fromTop="20%">
            <LoaderWrapper loading={props.loading}>
                <div style={{ width: '25em' }}>
                    <ModalDialogTitle>Add new campaign</ModalDialogTitle>
                    <FormWrapper
                        initialValues={{
                            name: undefined,
                            externalChannel: undefined,
                        }}
                        onSubmit={(values) => {
                            props.onConfirm(values.name as string, values.externalChannel, values.isScannable)
                        }}
                        validationSchema={reservationPoolValidationSchema}
                    >
                        <ReservationPoolForm accountSlug={props.accountSlug} retailerOptions={props.retailerOptions} />
                        <NavigationSection>
                            <ActionButton size="large" secondary onClick={props.onCancel}>
                                Cancel
                            </ActionButton>
                            <ActionButton type="submit" id="add-new-res-codes-pool-button" size="large">
                                Save
                            </ActionButton>
                        </NavigationSection>
                    </FormWrapper>
                </div>
            </LoaderWrapper>
        </ModalDialog>
    )
}
