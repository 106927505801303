import * as React from 'react'
import styled from 'styled-typed'
import Svg from 'svg'
import checkIcon from 'uiComponents/input/checkIcon.svg'

const Container = styled.div`
    font-size: 0.625em;
    margin-bottom: 2.7em;
    display: flex;
    align-items: flex-end;
`
const CheckMarkBox = styled.div<ValidationCheckmarkProps>`
    display: inline-block;
    height: 1.2em;
    width: 1.2em;
    border-radius: 50%;
    border: 1px solid ${(props) => (props.valid ? props.theme.colors.status.success : props.theme.colors.textLight)};
    background: ${(props) => (props.valid ? props.theme.colors.status.success : props.theme.colors.white)};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in;
`
const CheckIcon = styled(Svg)<ValidationCheckmarkProps>`
    width: 0.8em;
    margin-top: 0.05em;
    color: ${(props) => props.theme.colors.white};
    transition: color 0.2s ease-in;
`
const ValidationText = styled.span<ValidationCheckmarkProps>`
    color: ${(props) =>
        props.valid || props.valid == null ? props.theme.colors.textLight : props.theme.colors.status.error};
    margin-left: 0.5em;
    transition: color 0.2s ease-in;
`

interface ValidationCheckmarkProps {
    text?: string
    valid: boolean | null
}

export function ValidationCheckmark(props: ValidationCheckmarkProps) {
    return (
        <Container>
            <CheckMarkBox valid={props.valid}>
                <CheckIcon valid={props.valid} src={checkIcon} />
            </CheckMarkBox>
            <ValidationText valid={props.valid}>{props.text}</ValidationText>
        </Container>
    )
}
