import { MaterialSelectOption } from './types'

export const createGetAncestors = (options: MaterialSelectOption[]) => {
    const categoryRecords = options?.reduce((acc, option) => {
        acc[option.id] = option
        return acc
    }, {} as Record<string, MaterialSelectOption>)

    const getCategoryAncestors = (id: string, ancestors = []): MaterialSelectOption[] => {
        const child = categoryRecords[id]

        if (!child) {
            return []
        }

        if (!child?.parentId) {
            return [child]
        }

        return [...getCategoryAncestors(child.parentId), child]
    }

    return getCategoryAncestors
}

export function getAllChildren(data: MaterialSelectOption[], id: string) {
    const result = [] as MaterialSelectOption[]

    // Find the object with the specified ID
    const parent = data?.find((item) => item.id === id)

    if (!parent) {
        return result
    }

    // Filter the data for matching parent ID
    const children = data.filter((item) => item.parentId === parent.id)

    // Recursively get children and subchildren
    for (const child of children) {
        const { id } = child
        const subchildren = getAllChildren(data, id)
        result.push(child, ...subchildren)
    }

    return result
}
