import * as React from 'react'
import styled from 'styled-typed'
import { usePrevious } from 'reactUtils'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation, QueryData } from 'navigation'
import { History } from 'history'
import { MessageKind } from 'uiComponents/messages'
import {
    BookingCodesService,
    BarcodePool,
    BarcodePoolFormat,
    BarcodePoolType,
} from 'venue/bookingCodes/bookingCodesService'
import { SearchSection } from './searchSection'
import {
    InlineDataTable,
    HeaderRow,
    TableHeader,
    Cell,
    ExpandCell,
    DynamicRow,
    Sorting,
    Pagination,
} from 'uiComponents/table'
import { NestedContainerWrapper } from 'uiComponents/table/nestedContainer'
import { TableLoader, ChartDataLoader } from 'uiComponents/loaders'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { Text, SmallText } from 'uiComponents/typography'
import { ActionButtonA } from 'uiComponents/buttons'
import AttachedProductsTreeList from 'products/attachedProductsTreeList'
import { PaginationSection } from 'uiComponents/table/pagination'
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons'
import { faFileTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome'
import { InlineEdit } from 'uiComponents/input'
import { DisabledItemHoverInfotip } from 'uiComponents/infotip'
import { NewPoolDialog } from './newPoolDialog'
import { delay } from 'utils'
import { barcodeFormatOptions } from './common'

const StatusContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const SpinnerIcon = styled(FontAwesomeIcon)`
    font-size: 0.8em;
    margin-left: 0.5em;
`
const ProductsIcon = styled(FontAwesomeIcon)`
    font-size: 1.2em;
    opacity: 0.5;
    color: ${(props) => props.theme.colors.status.error};
`

const poolTypeNameMap = {
    Uploaded: 'Uploaded',
    Generated: 'Generated',
    Merac: 'Merac',
    Elli: 'Elli',
    ReservationVouchers: 'Reservations',
}

interface BarcodePoolsPageProps {
    accountSlug: string
    bookingCodesService: BookingCodesService
    navigation: Navigation
    match: RouteMatch<{}>
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    history: History
}

function BarcodePoolsPage(props: BarcodePoolsPageProps) {
    const busyRef = React.useRef(false)
    const monitorUpdateRef = React.useRef(false)
    const searchRef = React.useRef('')
    const sortRef = React.useRef({ prop: 'days_left', direction: 'asc' })
    const paginationRef = React.useRef({ page: 1, pageSize: 10 })
    const accountRef = React.useRef(props.accountSlug)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [updating, setUpdating] = React.useState<boolean>(false)
    const [creating, setCreating] = React.useState<boolean>(false)
    const [currentExpanded, setCurrentExpanded] = React.useState<string>('')
    const [barcodePoolsList, setBarcodePoolsList] = React.useState<BarcodePool[]>([])
    const [sort, setSort] = React.useState<Sorting>({
        prop: 'days_left',
        direction: 'asc',
    })
    const [pagination, setPagination] = React.useState<Pagination>({
        page: 1,
        pageSize: 10,
    })
    const [totalPools, setTotalPools] = React.useState<number>(0)
    const [depletedPools, setDepletedPools] = React.useState<number>(0)

    async function getBarcodePoolsList() {
        const page = paginationRef.current.page
        const pageSize = paginationRef.current.pageSize
        const search = searchRef.current ? `&search=${searchRef.current}` : ''
        const sortBy = sortRef.current.prop
        const sortDirection = sortRef.current.direction
        const query = `?page=${page}&page_size=${pageSize}&sort_by=${sortBy}&sort_direction=${sortDirection}${search}`
        const data = await props.bookingCodesService.getBarcodePoolsList(accountRef.current, query)

        monitorUpdateRef.current = !!data.entries.find(
            (p) => p.uploadStatus === 'pending' || (p.daysLeft === null && p.products.length > 0),
        )
        setTotalPools(data.totalCount)
        setDepletedPools(data.depletedCount)
        setPagination({ page: data.currentPage, pageSize: data.pageSize })
        setBarcodePoolsList(data.entries)
    }

    const updateLoaders = (action: 'load' | 'update' | null, state: boolean) => {
        if (action === 'load') {
            setLoading(state)
            busyRef.current = state
        } else if (action === 'update') {
            setUpdating(state)
            busyRef.current = state
        }
    }

    async function getListData(action: 'load' | 'update' | null = null) {
        updateLoaders(action, true)
        try {
            await getBarcodePoolsList()
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            updateLoaders(action, false)
        }
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (monitorUpdateRef.current && !busyRef.current) {
                getListData()
            }
        }, 30000)
        return () => clearInterval(interval)
    }, [])

    const prevAccount = usePrevious(props.accountSlug)
    const prevQuery: QueryData = usePrevious(props.navigation.query()) || {}
    React.useEffect(() => {
        const newQuery = props.navigation.query()
        const sortDirection = newQuery.sortDirection === 'desc' ? 'desc' : 'asc'
        const sortChanged =
            (prevQuery.sortBy !== newQuery.sortBy && sort.prop !== newQuery.sortBy) || sortDirection !== sort.direction
        const paginationChanged =
            (prevQuery.page !== newQuery.page && String(pagination.page) !== newQuery.page) ||
            (prevQuery.pageSize !== newQuery.pageSize && String(pagination.pageSize) !== newQuery.pageSize)
        const searchChanged = prevQuery.q !== newQuery.q

        if (searchChanged) {
            searchRef.current = newQuery.q
        }
        if (sortChanged) {
            const newSort = {
                prop: newQuery.sortBy,
                direction: sortDirection as 'asc' | 'desc',
            }
            setSort(newSort)
            sortRef.current = newSort
        }
        if (paginationChanged) {
            const newPagination = {
                page: isNaN(Number(newQuery.page)) ? 1 : Number(newQuery.page),
                pageSize: isNaN(Number(newQuery.pageSize)) ? 10 : Number(newQuery.pageSize),
            }
            setPagination(newPagination)
            paginationRef.current = newPagination
        }

        if (props.accountSlug !== prevAccount) {
            accountRef.current = props.accountSlug
            getListData('load')
        } else if (sortChanged || paginationChanged || searchChanged) {
            getListData('update')
        }
    }, [props.navigation.query(), props.accountSlug])

    const onSortChanged = (sorting: Sorting) => {
        props.navigation.addQueryWithReplace({
            sortBy: sorting.prop,
            sortDirection: sorting.direction,
        })
    }

    const onPaginationChanged = (newPagination: Pagination) => {
        props.navigation.addQueryWithReplace({
            page: newPagination.page.toString(),
            pageSize: newPagination.pageSize.toString(),
        })
    }

    const getDaysLeftDisplay = (daysLeft: number | null) => {
        if (daysLeft === null) {
            return '-'
        } else if (daysLeft > 365) {
            return '> 1 year'
        } else if (daysLeft > 30) {
            return '> 1 month'
        } else if (daysLeft > 14) {
            return '> 2 weeks'
        } else {
            const sign = daysLeft ? <>&#126;</> : ''
            return (
                <>
                    {sign}
                    {Math.round(daysLeft * 10) / 10} days
                </>
            )
        }
    }

    const getBarcodesLeftText = (item: BarcodePool) => {
        if (item.poolType === 'Generated') {
            return <Text status="success">Unlimited</Text>
        }
        const status =
            item.daysLeft === null
                ? undefined
                : item.daysLeft >= 7
                ? 'success'
                : item.daysLeft > 1
                ? 'processing'
                : 'error'
        const daysLeft = getDaysLeftDisplay(item.daysLeft)
        return (
            <StatusContainer>
                {item.barcodesLeft === null ? (
                    <>-</>
                ) : (
                    <Text status={status}>
                        {item.barcodesLeft}
                        {item.daysLeft !== null && <> ({daysLeft})</>}
                    </Text>
                )}
                {item.uploadStatus === 'pending' && (
                    <SmallText style={{ marginTop: '.2em' }}>
                        New upload pending
                        <SpinnerIcon icon={faSpinner as IconProp} className="fa-pulse" />
                    </SmallText>
                )}
            </StatusContainer>
        )
    }

    const toggleExpanded = (id: string) => {
        currentExpanded === id ? setCurrentExpanded('') : setCurrentExpanded(id)
    }

    const onSaveNewPool = async (name: string, format: BarcodePoolFormat, type: BarcodePoolType, prefix: string) => {
        updateLoaders('update', true)
        try {
            await props.bookingCodesService.createBarcodePool(props.accountSlug, name, format, type, prefix)
            await getBarcodePoolsList()
            setCreating(false)
            updateLoaders('update', false)
            props.replaceMessages('success', 'success', 'Barcode pool was created successfully.')
            await delay(7000)
            props.removeAllMessages()
        } catch {
            updateLoaders('update', false)
            props.replaceMessages(
                'unknown_error',
                'error',
                'Oops! We could not create a new barcode pool. Please try again later.',
            )
        }
    }

    const onNameEditAccept = async (poolId: string, name: string) => {
        updateLoaders('update', true)
        try {
            await props.bookingCodesService.editBarcodePoolName(props.accountSlug, poolId, name)
            await getBarcodePoolsList()
        } catch {
            props.replaceMessages(
                'unknown_error',
                'error',
                'Oops! We could not update the name. Please try again later.',
            )
        } finally {
            updateLoaders('update', false)
        }
    }

    const columnWidths = ['4em', null, '11em', '8em', '9.3em', '12.2em', '10em']

    return (
        <>
            {creating && (
                <NewPoolDialog
                    accountSlug={props.accountSlug}
                    loading={updating}
                    onCancel={() => setCreating(false)}
                    onConfirm={onSaveNewPool}
                />
            )}
            {loading && <TableLoader />}
            {!loading && (
                <>
                    <SearchSection
                        barcodePoolsCount={totalPools}
                        depletedPools={depletedPools}
                        onAddNew={() => setCreating(true)}
                    />
                    <InlineDataTable columnWidths={columnWidths} bordered id="barcode-pools-list">
                        <HeaderRow>
                            <TableHeader nonInteractive />
                            <TableHeader sortKey="name" sorting={sort} changeSort={onSortChanged} noPadding>
                                Name
                            </TableHeader>
                            <TableHeader sortKey="format" sorting={sort} changeSort={onSortChanged}>
                                Format
                            </TableHeader>
                            <TableHeader sortKey="pool_type" sorting={sort} changeSort={onSortChanged}>
                                Type
                            </TableHeader>
                            <TableHeader
                                sortKey="barcodes_used_in_last_day"
                                sorting={sort}
                                changeSort={onSortChanged}
                                align="center"
                            >
                                Used in 24h
                            </TableHeader>
                            <TableHeader sortKey="days_left" sorting={sort} changeSort={onSortChanged}>
                                Barcodes left
                            </TableHeader>
                            <TableHeader nonInteractive />
                        </HeaderRow>
                        {updating && !loading && <ChartDataLoader />}
                        {barcodePoolsList.length === 0 && !creating && (
                            <NoResultsRow text="There are no barcode pools yet." />
                        )}
                        {barcodePoolsList.length > 0 &&
                            barcodePoolsList.map((item, i) => {
                                const expanded = item.id === currentExpanded
                                const poolFormatOption = barcodeFormatOptions.find(
                                    (option) => option.value.type === item.format,
                                )
                                const format = poolFormatOption ? poolFormatOption.name : 'Unknown format'
                                return (
                                    <div key={i}>
                                        <DynamicRow
                                            interactive={!!item.products && item.products.length > 0}
                                            highlighted={expanded}
                                            onClick={() =>
                                                !!item.products && item.products.length > 0
                                                    ? toggleExpanded(item.id)
                                                    : () => {}
                                            }
                                            className={expanded ? 'expanded barcodes-pool' : 'barcodes-pool'}
                                        >
                                            {!!item.products && item.products.length > 0 ? (
                                                <ExpandCell className="expand" expanded={expanded} />
                                            ) : (
                                                <Cell align="center" style={{ overflow: 'visible' }}>
                                                    <DisabledItemHoverInfotip
                                                        active
                                                        infotipText="No products attached"
                                                        offsetLeft="-1em"
                                                        width="12em"
                                                    >
                                                        <ProductsIcon icon={faFileTimes as IconProp} />
                                                    </DisabledItemHoverInfotip>
                                                </Cell>
                                            )}
                                            <Cell title={item.name} className="pool-name" noPadding>
                                                <InlineEdit
                                                    id="new-pool-name"
                                                    value={item.name}
                                                    maxLength={100}
                                                    onEditAccept={(v) => onNameEditAccept(item.id, v)}
                                                    responsiveWidth
                                                />
                                            </Cell>
                                            <Cell className="pool-format">{format}</Cell>
                                            <Cell className="pool-type">
                                                {!item.poolType
                                                    ? 'Uploaded'
                                                    : poolTypeNameMap[item.poolType] || 'Unknown type'}
                                            </Cell>
                                            <Cell className="barcodes-used" align="center">
                                                {item.barcodesUsedInLastDay !== null ? item.barcodesUsedInLastDay : '-'}
                                            </Cell>
                                            <Cell className="barcodes-left">{getBarcodesLeftText(item)}</Cell>
                                            <Cell align="right">
                                                {(!item.poolType || item.poolType === 'Uploaded') && (
                                                    <ActionButtonA
                                                        kind="action"
                                                        secondary
                                                        href={`/account/${props.accountSlug}/venue/codes/tickets/upload/${item.id}?${location.search}`}
                                                    >
                                                        Upload
                                                    </ActionButtonA>
                                                )}
                                            </Cell>
                                        </DynamicRow>
                                        {expanded && item.products.length > 0 && (
                                            <NestedContainerWrapper grade={0} className="expanded">
                                                <AttachedProductsTreeList
                                                    products={item.products || []}
                                                    // flattenedCategories={flattenedCategories}
                                                    // accountCategories={accountCategories}
                                                    accountSlug={props.accountSlug}
                                                    history={props.history}
                                                />
                                            </NestedContainerWrapper>
                                        )}
                                    </div>
                                )
                            })}
                    </InlineDataTable>
                    <PaginationSection
                        pagination={pagination}
                        onPaginationChanged={onPaginationChanged}
                        totalItemsCount={totalPools}
                        pageSizeOptions={['10', '20', '50']}
                        style={{ margin: '1em' }}
                        onWhiteBackground
                    />
                </>
            )}
        </>
    )
}

export default withNavigation(BarcodePoolsPage)
