import * as React from 'react'
import { PageLoader } from 'uiComponents/loaders'
import { FieldType } from 'uiComponents/studio/form/fieldComponentSchema'
import { ConfigurationUnit } from 'uiComponents/studio/menuItem'
import GeneralSection from './generalSection'
import { CheckoutConfiguration, EngageToolConfig } from 'engageTools/studio/schema'
import { getConfigValue, handleInputChange } from 'engageTools/studio/shared'
import { ComponentsService } from 'engageTools/studio/componentsService'
import { ImagesService } from 'http/imagesService'
import { MessageKind } from 'uiComponents/messages'
import AudienceMarketing from '../form/fields/audienceMarketing'

interface FormProps {
    accountSlug: string
    section: ConfigurationUnit
    checkoutConfiguration: CheckoutConfiguration
    updateConfiguration: (path: string, value: string | number | boolean | string[]) => void
    updateWholeConfig: (config: EngageToolConfig) => void
    componentsService: ComponentsService
    imagesService: ImagesService
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    fontsValidation: boolean
    setFontsValidation: (v: boolean) => void
}

export function CheckoutForm(props: FormProps) {
    const onInputChange = (value: React.ChangeEvent<HTMLInputElement> | string | boolean, path: string) => {
        handleInputChange(value, path, setConfigValue)
    }

    function setConfigValue(path: string, value: boolean | string | number) {
        if (path) {
            props.updateConfiguration(path, value)
        }
    }

    if (!props.checkoutConfiguration) {
        return <PageLoader />
    } else {
        const { section } = props
        if (section.name === 'Audience') {
            return <AudienceMarketing />
        }

        return (
            <GeneralSection
                section={props.section}
                getConfigValue={(item: FieldType) => getConfigValue(item, props.checkoutConfiguration)}
                handleInputChange={onInputChange}
                updateWholeConfig={props.updateWholeConfig}
                componentsService={props.componentsService}
                imagesService={props.imagesService}
                replaceTopMessages={props.replaceTopMessages}
                removeAllMessages={props.removeAllMessages}
                accountSlug={props.accountSlug}
                configuration={props.checkoutConfiguration}
                fontsValidation={props.fontsValidation}
                setFontsValidation={props.setFontsValidation}
            />
        )
    }
}
