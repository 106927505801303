import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import { History } from 'history'
import { CRMService } from '../crmService'
import { Headline } from 'uiComponents/typography'
import { Messages } from 'uiComponents/messages'
import { withMessages, MessageProps } from 'hocs'
import SearchField from 'uiComponents/search/searchWithButton'
import { PaginationSection } from 'uiComponents/table/pagination'
import { DataTable, HeaderRow, TableHeader } from 'uiComponents/table'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { useIsMounted } from 'reactUtils'
import { CustomerProfileListItem } from '../schema'
import { Cell } from 'uiComponents/table'
import { DataRow } from 'uiComponents/table'
import Money from 'uiComponents/money'
import { TableLoader } from 'uiComponents/loaders'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { Pagination } from 'uiComponents/table'
import { ProfileIdElement, ProfileNameElement } from 'crm/common'
import isEqual from 'lodash/isEqual'
import { abbreviateNumber } from 'utils'
import { DateFormats, formatISOString } from 'utils/dates'

const TotalNumber = styled.span`
    font-size: 1.125rem;
    margin-left: 0.5em;
    opacity: 0;
    transition: opacity 0.2s;
    &.visible {
        opacity: 1;
    }
`

interface ProfileListProps {
    accountSlug: string
    history: History
    crmService: CRMService
    navigation: Navigation
    match: RouteMatch<{}>
}

function ProfileList(props: ProfileListProps & MessageProps) {
    const isMounted = useIsMounted()
    const query = props.navigation.query()
    const initialPagination: Pagination = {
        page: isNaN(Number(query.page)) ? 1 : Number(query.page),
        pageSize: isNaN(Number(query.pageSize)) ? 10 : Number(query.pageSize),
    }

    const [loading, setLoading] = useState<boolean>(true)
    const [profileList, setProfileList] = useState<CustomerProfileListItem[]>([])
    const [total, setTotal] = useState<number>(0)
    const [pagination, setPagination] = useState<Pagination>(initialPagination)

    async function getData(pageDetails: Pagination = pagination) {
        try {
            setLoading(true)
            setTotal(0)
            let queryString = `?page=${pageDetails.page}&page_size=${pageDetails.pageSize}`
            queryString = query.q ? `${queryString}&search=${encodeURIComponent(query.q)}` : queryString
            const data = await props.crmService.getProfileList(props.accountSlug, queryString)
            if (isMounted) {
                setProfileList(data.entries)
                setTotal(data.totalCount)
            }
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        const newPagination = { page: 1, pageSize: pagination.pageSize }
        getData({ page: 1, pageSize: pagination.pageSize })
        setPagination(newPagination)
    }, [props.accountSlug, props.navigation.query().q])

    const onPaginationChanged = (newPagination: Pagination) => {
        if (!isEqual(pagination, newPagination)) {
            props.navigation.addQueryWithReplace({
                page: newPagination.page.toString(),
                pageSize: newPagination.pageSize.toString(),
            })
            setPagination(newPagination)
            getData(newPagination)
        }
    }

    const columnWidths = [
        '1em', // space
        '9em', // ID
        null, // name
        '14em', // total purchases
        '14em', // avg order value
        '14em', // latest activity
    ]

    return (
        <>
            <Messages messages={props.messages} hideMessage={props.hideMessage} />
            <Headline size={1}>
                Profiles
                {!!total && (
                    <TotalNumber className={total ? 'visible' : ''}>
                        ({abbreviateNumber(total, 0)} profiles)
                    </TotalNumber>
                )}
            </Headline>
            <SearchField
                placeholder="Seach by email, cokie ID or name"
                style={{ width: '20em' }}
                disabled={loading}
                hideExtendedSearch
            />
            <DataTable id="profiles-table" style={{ marginTop: '2em' }} columnWidths={columnWidths}>
                <HeaderRow>
                    <TableHeader nonInteractive noPadding />
                    <TableHeader>ID</TableHeader>
                    <TableHeader>Name &amp; email</TableHeader>
                    <TableHeader align="center">Total purchases</TableHeader>
                    <TableHeader align="center">Avg. order value</TableHeader>
                    <TableHeader align="center">Latest activity</TableHeader>
                </HeaderRow>
                {loading && <TableLoader />}
                {!loading &&
                    profileList.map((profile) => (
                        <DataRow
                            narrow
                            key={profile.cookieId}
                            className="profile-row"
                            interactive
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                                props.history.push(
                                    `/account/${props.accountSlug}/crm/profiles/details/${profile.cookieId}/home${location.search}`,
                                )
                            }
                        >
                            <Cell noPadding />
                            <Cell title={profile.cookieId}>
                                <ProfileIdElement
                                    id={profile.cookieId}
                                    to={`/account/${props.accountSlug}/crm/profiles/details/${profile.cookieId}/home${location.search}`}
                                />
                            </Cell>
                            <Cell
                                className="productName"
                                title={
                                    profile.name || profile.email ? `${profile.name || ''} ${profile.email || ''}` : ''
                                }
                            >
                                <ProfileNameElement name={profile.name} email={profile.email} unmask={!!query.q} />
                            </Cell>
                            <Cell align="center">{profile.paymentsCount || 0}</Cell>
                            <Cell className="order-subtotal" align="center">
                                <Money
                                    amount={(!!profile.averageOrderValue && profile.averageOrderValue.length > 0
                                        ? profile.averageOrderValue[0].amount
                                        : 0
                                    ).toFixed(2)}
                                    accountSlug={props.accountSlug}
                                />
                            </Cell>
                            <Cell align="center">
                                {formatISOString(
                                    profile.lastSeen,
                                    `${DateFormats.EXTRA_SHORT_DATE} ${DateFormats.SHORT_TIME}`,
                                )}
                            </Cell>
                        </DataRow>
                    ))}
                {!loading && profileList.length === 0 && <NoResultsRow alignLeft item="profiles" />}
            </DataTable>
            <PaginationSection
                pagination={pagination}
                onPaginationChanged={onPaginationChanged}
                totalItemsCount={total}
            />
        </>
    )
}

export default withNavigation(withMessages(ProfileList))
