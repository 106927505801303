import React, { useEffect, useState } from 'react'
import { Alert, Button } from '@mui/material'
import { isLocalStorageAvailable } from '../../utils/storage'

const DO_NOT_SHOW_WARNING_KEY_NAME = 'doNotShowRtpSettingsWarning'
export const DoNotEditSettingsWarningMessage = () => {
    const [doNotShowRtpSettingsWarning, setDoNotShowRtpSettingsWarning] = useState<boolean | null>(null)
    const [showWarning, setShowWarning] = useState<boolean>(true)
    const [doNotShowAgainButtonHidden, setDoNotShowAgainButtonHidden] = useState<boolean>(false)

    useEffect(() => {
        if (isLocalStorageAvailable()) {
            if (localStorage.getItem(DO_NOT_SHOW_WARNING_KEY_NAME) === null) {
                setDoNotShowRtpSettingsWarning(false)
            } else {
                setDoNotShowRtpSettingsWarning(JSON.parse(localStorage.getItem(DO_NOT_SHOW_WARNING_KEY_NAME) as string))
            }
        } else {
            setDoNotShowAgainButtonHidden(true)
            setDoNotShowRtpSettingsWarning(false)
        }
    }, [])

    useEffect(() => {
        if (isLocalStorageAvailable()) {
            localStorage.setItem(DO_NOT_SHOW_WARNING_KEY_NAME, `${doNotShowRtpSettingsWarning}`)
        }
    }, [doNotShowRtpSettingsWarning])

    if (doNotShowRtpSettingsWarning === true || !showWarning) {
        return null
    }

    return (
        <Alert
            severity="warning"
            onClose={() => setShowWarning(false)}
            data-testid="do-not-edit-settings-warning-message"
        >
            Please be careful when you make changes to these settings because they can greatly affect the price of the
            products. If you need assistance, you can contact our support team at&nbsp;
            <a href="mailto:support@convious.com">support@convious.com</a>
            {!doNotShowAgainButtonHidden && (
                <div className="actions">
                    <Button
                        style={{ float: 'right' }}
                        size="small"
                        variant="text"
                        onClick={() => setDoNotShowRtpSettingsWarning(true)}
                    >
                        Do not show again
                    </Button>
                </div>
            )}
        </Alert>
    )
}
