import styled, { css } from 'styled-typed'
import { Cell, CellProps } from '..'
import { getCssSizeFromString } from './utils'

interface CellExtendedProps extends CellProps {
    width?: string | number
}

export const ReactTableCell = styled(Cell)<CellExtendedProps>`
    ${(props) => {
        const size = getCssSizeFromString(props.width)

        return size
            ? css`
                  width: ${size};
                  max-width: ${size};
                  min-width: ${size};
                  /* Edge */
                  @supports (-ms-ime-align: auto) {
                      min-width: ${size};
                  }
              `
            : ''
    }}
`
