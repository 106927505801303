import React, { useEffect, useState } from 'react'
import { usePrevious } from 'reactUtils'
import styled from 'styled-typed'
import { addMinutes, getHours, getMinutes, getDayOfYear, isBefore } from 'date-fns'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, TimeInput, TimePicker } from 'uiComponents/input'
import { Col, Row } from 'uiComponents/flex'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { ElementWithDashedLine, ContainerWithStatus } from 'uiComponents/pageElements'
import { SecondaryText, RegularText } from 'uiComponents/typography'
import { Section } from './form'
import { TimeSlot } from './timeSlotsService'
import { parseTime } from 'utils'

interface TimeSlotsProps {
    slots: TimeSlot[]
    updateSlots: (slots: TimeSlot[]) => void
    duplicates: boolean
    timesValid: boolean
    validate: boolean
}

const DeleteNote = styled.div`
    margin: 0 0 1.5rem 0;
    display: flex;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
    > span {
        font-size: 0.8rem;
    }
`

const ClickableText = styled.div`
    padding-left: 0.5em;
`
const Icon = styled.div``

const AddSlotActionBlock = styled.div`
    display: flex;
    width: fit-content;
    line-height: 1em;
    color: ${(props) => props.theme.colors.boyBlue};

    > ${Icon}, ${ClickableText} {
        &:hover {
            cursor: pointer;
        }
    }

    &.disabled {
        ${Icon}, ${ClickableText} {
            pointer-events: none;
        }
        &:hover {
            cursor: not-allowed;
        }
    }
    align-items: center;
`

export function startGreaterThanEnd(start: string, end: string | null) {
    if (!end) {
        return false
    }
    const startUTCDateTime = new Date('1970-01-01T' + start + 'Z')
    const endUTCDateTime = new Date('1970-01-01T' + end + 'Z')
    return startUTCDateTime > endUTCDateTime
}

export function TimeSlotsSection(props: TimeSlotsProps) {
    const [startTime, setStartTime] = useState<string>('00:00')
    const [intervalSize, setIntervalSize] = useState<number>(60)
    const [canAddNew, setCanAddNew] = useState<boolean>(true)
    const [generateEndTimes, setGenerateEndTimes] = useState<boolean>(false)

    useEffect(() => {
        calculateNewStartEndTime()
        if (props.slots.length === 0) {
            addNewSlot()
        }
    }, [props.slots, intervalSize])

    const prevSlots = usePrevious(props.slots)
    useEffect(() => {
        if (!!prevSlots && !!props.slots) {
            if (props.slots.length > 0 && !!generateEndTimes) {
                const sortedSlots = [...props.slots]
                sortedSlots.sort((a, b) => (a.startTime > b.startTime ? 1 : -1))
                sortedSlots[sortedSlots.length - 1].endTime = startTime
                props.updateSlots(sortedSlots)
            }
        }
    }, [startTime])

    function editTimeSlot(id: string | null, endOrStart: 'end' | 'start', value: string | null, index: number) {
        let newSlots = [...props.slots]

        if (!!id) {
            newSlots = props.slots.map((timeSlot) => {
                if (timeSlot.id === id) {
                    if (endOrStart === 'start') {
                        timeSlot.startTime = value!
                    } else {
                        timeSlot.endTime = value
                    }
                }
                return timeSlot
            })
        } else if (!!index || index === 0) {
            if (endOrStart === 'start') {
                newSlots[index].startTime = value!
            } else {
                newSlots[index].endTime = value
            }
        }
        props.updateSlots(newSlots)
    }

    function addNewSlot() {
        const newSlots = [...props.slots]
        newSlots.push({
            startTime,
            endTime: null,
        })
        props.updateSlots(newSlots)
    }

    function removeTimeSlot(index: number) {
        const newSlots = props.slots.filter((_, i) => i !== index)
        props.updateSlots(newSlots)
    }

    function calculateNewStartEndTime() {
        if (props.slots.length > 0) {
            const sortedSlots = [...props.slots]
            sortedSlots.sort((a, b) => (a.startTime > b.startTime ? 1 : -1))
            const lastStartTime = sortedSlots[sortedSlots.length - 1].startTime

            const utcDatetime = new Date('1970-01-01T' + lastStartTime + 'Z')
            const localDatetime = addMinutes(utcDatetime, utcDatetime.getTimezoneOffset())
            const datetimeAfter = addMinutes(localDatetime, intervalSize)
            if (getDayOfYear(datetimeAfter) === getDayOfYear(localDatetime)) {
                const hours = getHours(datetimeAfter)
                let minutes = getMinutes(datetimeAfter)
                setStartTime(`${hours < 10 ? `0${hours}` : `${hours}`}:${minutes < 10 ? `0${minutes}` : `${minutes}`}`)
                setCanAddNew(true)
            } else {
                setCanAddNew(false)
            }
        } else {
            setStartTime('00:00')
        }
    }

    function intervalSizeToTime(): string {
        const hours = Math.floor(intervalSize / 60)
        const minutes = intervalSize % 60
        return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`
    }

    function onIntervalTimeChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value
        const hours = +value.split(':')[0]
        const mins = +value.split(':')[1]
        const size = hours * 60 + mins
        setIntervalSize(size)
    }

    return (
        <>
            {props.validate && props.duplicates && (
                <ValidationMessage className="validation-message-visible">Please remove duplicates</ValidationMessage>
            )}
            {props.slots.length < 2 && (
                <Row>
                    <Col span={12} style={{ display: 'flex' }}>
                        <Checkbox
                            name="addEndTimes"
                            checked={generateEndTimes}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGenerateEndTimes(e.target.checked)}
                        />
                        <FormItemName>Prefill end times</FormItemName>
                    </Col>
                </Row>
            )}
            <ContainerWithStatus
                status={props.validate && (props.duplicates || !props.timesValid) ? 'error' : 'success'}
            >
                <Col span={12}>
                    {props.slots.map((timeSlot, index) => (
                        <Row key={timeSlot.id}>
                            <Section span={6}>
                                <Row>
                                    <Col span={6}>
                                        <FormItem htmlFor="startTime" style={{ position: 'relative' }}>
                                            <FormItemName>Start time</FormItemName>
                                            <TimePicker
                                                id={`startTime-${index}`}
                                                name="startTime"
                                                value={timeSlot.startTime}
                                                onChange={(time) => {
                                                    editTimeSlot(timeSlot.id ?? null, 'start', time, index)
                                                }}
                                                required
                                                fallbackValue="00:00"
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem htmlFor="endTime" style={{ position: 'relative' }}>
                                            <FormItemName>
                                                End time&nbsp;<SecondaryText>(optional)</SecondaryText>
                                            </FormItemName>
                                            <TimePicker
                                                id={`endTime-${index}`}
                                                name="endTime"
                                                value={timeSlot.endTime}
                                                onChange={(time) => {
                                                    editTimeSlot(timeSlot.id ?? null, 'end', time, index)
                                                }}
                                                errorMessage={
                                                    timeSlot.endTime &&
                                                    isBefore(
                                                        parseTime(timeSlot.endTime),
                                                        parseTime(timeSlot.startTime || '00:00'),
                                                    )
                                                        ? 'End time can not be before start time.'
                                                        : null
                                                }
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Section>
                            {props.slots.length > 1 && (
                                <Section span={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <DeleteNote onClick={() => removeTimeSlot(index)}>
                                        <SecondaryText>x Remove</SecondaryText>
                                    </DeleteNote>
                                </Section>
                            )}
                        </Row>
                    ))}
                </Col>
            </ContainerWithStatus>
            <Row>
                <Col span={12}>
                    <ElementWithDashedLine alignment="center">
                        <AddSlotActionBlock className={!canAddNew ? 'disabled' : ''}>
                            <Icon onClick={(e) => addNewSlot()}>
                                <FontAwesomeIcon icon={faPlusCircle} />
                            </Icon>
                            <ClickableText onClick={(e) => addNewSlot()}>
                                <RegularText>Add time slot of</RegularText>
                            </ClickableText>
                            <TimeInput
                                id="intervalSize"
                                name="intervalSize"
                                value={intervalSizeToTime()}
                                onChange={onIntervalTimeChange}
                                style={{
                                    fontWeight: 'lighter',
                                    width: '5em',
                                    margin: '0 0.5em',
                                }}
                            />
                            <div>
                                <RegularText>hour(s)</RegularText>
                            </div>
                        </AddSlotActionBlock>
                    </ElementWithDashedLine>
                </Col>
            </Row>
        </>
    )
}
