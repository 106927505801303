import React from 'react'
import { connect } from 'react-redux'
import { State } from 'store'
import { withFeatures } from 'features'

interface GoogleTranslateProps {
    hasFeature: (feature: string, accountSlug: string) => boolean
    activeAccount: string
}

class GoogleTranslateScript extends React.Component<GoogleTranslateProps> {
    googleTranslateElementInit() {
        window['translateElement'] = new (window as any)['google'].translate.TranslateElement(
            {
                pageLanguage: 'nl',
                layout: (window as any)['google'].translate.TranslateElement.InlineLayout.SIMPLE,
                autoDisplay: false,
            },
            'google_translate_element',
        )
    }

    hackyFix() {
        // a temp workaround for:
        // Uncaught DOMException: Failed to execute 'removeChild' on 'Node':
        // The node to be removed is not a child of this node.
        // from: https://github.com/facebook/react/issues/11538
        if (typeof Node === 'function' && Node.prototype) {
            const originalRemoveChild = Node.prototype.removeChild
            Node.prototype.removeChild = function (child: any) {
                if (child.parentNode !== this) {
                    if (console) {
                        console.error('Cannot remove a child from a different parent', child, this)
                    }
                    return child
                }
                return originalRemoveChild.apply(this, arguments)
            }

            const originalInsertBefore = Node.prototype.insertBefore
            Node.prototype.insertBefore = function (newNode: any, referenceNode: any) {
                if (referenceNode && referenceNode.parentNode !== this) {
                    if (console) {
                        console.error(
                            'Cannot insert before a reference node from a different parent',
                            referenceNode,
                            this,
                        )
                    }
                    return newNode
                }
                return originalInsertBefore.apply(this, arguments)
            }
        }
    }

    componentDidMount() {
        this.hackyFix()
        window['googleTranslateElementInit'] = this.googleTranslateElementInit
        const script = document.createElement('script')
        script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
        script.async = true
        document.head!.appendChild(script)
    }

    render() {
        return null
    }
}

const mapStateToProps = (state: State) => {
    return {
        activeAccount: state.preferences.activeAccount,
    }
}

export default withFeatures(connect(mapStateToProps)(GoogleTranslateScript))
