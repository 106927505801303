import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { History, Location } from 'history'
import { Container, FullWidthContainerBody } from 'uiComponents/settingsContainer'
import { withMessages, MessageProps } from 'hocs'
import { Messages } from 'uiComponents/messages'
import UploadReservationsPage from 'venue/bookingCodes/reservationCodePools/uploadReservationsPage'
import { UploadVouchersPage } from 'venue/bookingCodes/uploadVouchersPage'
import UploadCouponsPage from 'venue/bookingCodes/discountPools/uploadCouponsPage'
import { UploadSeasonPassesPage } from 'venue/bookingCodes/uploadSeasonPassesPage'
import BarcodePoolsPage from 'venue/bookingCodes/barcodePools'
import ReservationCodePoolsPage from 'venue/bookingCodes/reservationCodePools'
import DiscountPoolsPage from 'venue/bookingCodes/discountPools'
import UploadBarcodesPage from 'venue/bookingCodes/barcodePools/uploadBarcodesPage'
import ProductIdsPage from 'venue/bookingCodes/productIdsPage'
import { ArticleService } from 'admin/articleService'
import { BookingCodesService } from 'venue/bookingCodes/bookingCodesService'
import Feature from 'features/feature'
import { withFeatures } from 'features'
import { LoginService } from 'http/loginService'
import DiscountCodes from './discountCodes'
import Pools from './discountCodes/Pools'
import Codes from './discountCodes/Codes'
import AddCode from './discountCodes/addCode'
import UploadCsv from './discountCodes/addCode/uploadCsv'
import { pathUtils } from 'utils/pathnameFormatter'
import DeletePool from './discountCodes/Pools/deletePool'
import { CODES_PATHS } from './paths'
import CodesTabsNavigation from './navigation'
import CodesHeading from './heading'
import AddPool from './discountCodes/addPool'
import AddByCode from './discountCodes/addCode/addByCode'
import EditCode from './discountCodes/editCode'

interface VouchersPageProps {
    history: History
    accountSlug: string
    location: Location
    articleService: ArticleService
    bookingCodesService: BookingCodesService
    backofficeEndpoint: string
    loginService: LoginService
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function VouchersPage(props: VouchersPageProps & MessageProps) {
    const reservationPoolsOn = props.hasFeature('RetailersReservationCodes', props.accountSlug)

    return (
        <div style={{ position: 'relative' }} id="codes">
            <Messages messages={props.messages} hideMessage={props.hideMessage} />
            <CodesHeading />
            <Container style={{ maxWidth: '75rem' }}>
                <CodesTabsNavigation />
                <Switch location={props.location}>
                    <Route
                        path="/account/:accountSlug/venue/codes/tickets/list"
                        render={() => (
                            <FullWidthContainerBody>
                                <BarcodePoolsPage
                                    bookingCodesService={props.bookingCodesService}
                                    accountSlug={props.accountSlug}
                                    replaceMessages={props.replaceMessages}
                                    removeAllMessages={props.removeAllMessages}
                                    history={props.history}
                                />
                            </FullWidthContainerBody>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/venue/codes/tickets/upload/:poolId"
                        render={() => (
                            <FullWidthContainerBody>
                                <UploadBarcodesPage
                                    history={props.history}
                                    bookingCodesService={props.bookingCodesService}
                                    loginService={props.loginService}
                                    backofficeEndpoint={props.backofficeEndpoint}
                                    accountSlug={props.accountSlug}
                                    replaceMessages={props.replaceMessages}
                                    removeAllMessages={props.removeAllMessages}
                                />
                            </FullWidthContainerBody>
                        )}
                    />
                    {!reservationPoolsOn && (
                        <Route
                            path="/account/:accountSlug/venue/codes/reservations"
                            render={() => (
                                <FullWidthContainerBody>
                                    <UploadReservationsPage
                                        history={props.history}
                                        bookingCodesService={props.bookingCodesService}
                                        loginService={props.loginService}
                                        backofficeEndpoint={props.backofficeEndpoint}
                                        accountSlug={props.accountSlug}
                                        replaceMessages={props.replaceMessages}
                                        removeAllMessages={props.removeAllMessages}
                                    />
                                </FullWidthContainerBody>
                            )}
                        />
                    )}
                    {reservationPoolsOn && (
                        <Route
                            path="/account/:accountSlug/venue/codes/reservations/list/"
                            render={() => (
                                <FullWidthContainerBody>
                                    <ReservationCodePoolsPage
                                        bookingCodesService={props.bookingCodesService}
                                        loginService={props.loginService}
                                        backofficeEndpoint={props.backofficeEndpoint}
                                        accountSlug={props.accountSlug}
                                        replaceMessages={props.replaceMessages}
                                        removeAllMessages={props.removeAllMessages}
                                        history={props.history}
                                    />
                                </FullWidthContainerBody>
                            )}
                        />
                    )}
                    {reservationPoolsOn && (
                        <Route
                            path="/account/:accountSlug/venue/codes/reservations/upload/:poolId"
                            render={() => (
                                <FullWidthContainerBody>
                                    <UploadReservationsPage
                                        history={props.history}
                                        bookingCodesService={props.bookingCodesService}
                                        loginService={props.loginService}
                                        backofficeEndpoint={props.backofficeEndpoint}
                                        accountSlug={props.accountSlug}
                                        replaceMessages={props.replaceMessages}
                                        removeAllMessages={props.removeAllMessages}
                                    />
                                </FullWidthContainerBody>
                            )}
                        />
                    )}
                    <Route
                        path="/account/:accountSlug/venue/codes/vouchers"
                        render={() => (
                            <FullWidthContainerBody>
                                <UploadVouchersPage
                                    bookingCodesService={props.bookingCodesService}
                                    loginService={props.loginService}
                                    backofficeEndpoint={props.backofficeEndpoint}
                                    accountSlug={props.accountSlug}
                                    replaceMessages={props.replaceMessages}
                                    removeAllMessages={props.removeAllMessages}
                                />
                            </FullWidthContainerBody>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/venue/codes/coupons/list"
                        render={() => (
                            <FullWidthContainerBody>
                                <DiscountPoolsPage
                                    bookingCodesService={props.bookingCodesService}
                                    accountSlug={props.accountSlug}
                                    replaceMessages={props.replaceMessages}
                                    removeAllMessages={props.removeAllMessages}
                                />
                            </FullWidthContainerBody>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/venue/codes/coupons/upload/:poolId"
                        render={() => (
                            <FullWidthContainerBody>
                                <UploadCouponsPage
                                    history={props.history}
                                    bookingCodesService={props.bookingCodesService}
                                    loginService={props.loginService}
                                    backofficeEndpoint={props.backofficeEndpoint}
                                    accountSlug={props.accountSlug}
                                    replaceMessages={props.replaceMessages}
                                    removeAllMessages={props.removeAllMessages}
                                />
                            </FullWidthContainerBody>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/venue/codes/sold_tickets"
                        render={() => (
                            <FullWidthContainerBody>
                                <UploadSeasonPassesPage
                                    bookingCodesService={props.bookingCodesService}
                                    loginService={props.loginService}
                                    backofficeEndpoint={props.backofficeEndpoint}
                                    accountSlug={props.accountSlug}
                                    replaceMessages={props.replaceMessages}
                                    removeAllMessages={props.removeAllMessages}
                                />
                            </FullWidthContainerBody>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/venue/codes/product_ids"
                        render={() => (
                            <FullWidthContainerBody>
                                <ProductIdsPage
                                    history={props.history}
                                    accountSlug={props.accountSlug}
                                    articleService={props.articleService}
                                    replaceMessages={props.replaceMessages}
                                />
                            </FullWidthContainerBody>
                        )}
                    />

                    <Route path={CODES_PATHS.pagePaths.discountPath}>
                        <Feature name="discountCodes">
                            <Switch>
                                <Route path={CODES_PATHS.fullPaths.editCode} component={EditCode} />
                                <Route>
                                    <DiscountCodes>
                                        <Switch>
                                            <Route path={CODES_PATHS.fullPaths.poolsPage}>
                                                <Pools>
                                                    <Switch>
                                                        <Route
                                                            path={CODES_PATHS.fullPaths.poolsPageAddPool}
                                                            component={AddPool}
                                                        />
                                                        <Route
                                                            path={[
                                                                CODES_PATHS.fullPaths.poolsPageAddByForm,
                                                                CODES_PATHS.fullPaths.poolsPageAddByUpload,
                                                            ]}
                                                        >
                                                            <AddCode>
                                                                <Switch>
                                                                    <Route
                                                                        path={CODES_PATHS.fullPaths.poolsPageAddByForm}
                                                                        component={AddByCode}
                                                                    />
                                                                    <Route
                                                                        path={
                                                                            CODES_PATHS.fullPaths.poolsPageAddByUpload
                                                                        }
                                                                        component={UploadCsv}
                                                                    />
                                                                    <Route
                                                                        render={({
                                                                            match: { params },
                                                                            location: { search },
                                                                        }) => (
                                                                            <Redirect
                                                                                to={pathUtils.populateParams(
                                                                                    pathUtils.addQueryString(
                                                                                        CODES_PATHS.fullPaths
                                                                                            .poolsPageAddByForm,
                                                                                        search,
                                                                                    ),
                                                                                    params,
                                                                                )}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Switch>
                                                            </AddCode>
                                                        </Route>
                                                        <Route
                                                            path={CODES_PATHS.fullPaths.poolsPageDeletePool}
                                                            component={DeletePool}
                                                        />
                                                    </Switch>
                                                </Pools>
                                            </Route>
                                            <Route path={CODES_PATHS.fullPaths.codesPage} component={Codes} />
                                            <Route
                                                render={() => (
                                                    <Redirect
                                                        to={pathUtils.populateParams(
                                                            CODES_PATHS.fullPaths.poolsPage,
                                                            props,
                                                        )}
                                                    />
                                                )}
                                            />
                                        </Switch>
                                    </DiscountCodes>
                                </Route>
                            </Switch>
                        </Feature>
                    </Route>
                    <Route render={() => <Redirect to={`/account/${props.accountSlug}/venue/codes/tickets/list`} />} />
                </Switch>
            </Container>
        </div>
    )
}

export default withFeatures(withMessages(VouchersPage))
