import React, { FC, useCallback, useState } from 'react'
import { ChevronLeftSolid, ChevronRightSolid } from '@convious/icons'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { PageHeading, PageTitle } from 'uiComponents/typography'
import { FormItem } from 'uiComponents/form/formElements'
import { Button } from '@mui/material'
import CapacityReportTimeline from './components/CapacityReportTimeline'
import './capacityReportPage.scss'

const CapacityReport: FC = () => {
    const [selectedDate, setSelectedDate] = useState<Date>(new Date())

    const handleDateChange = useCallback((date: Date) => {
        setSelectedDate(date)
    }, [])

    const stepDate = useCallback(
        (step: 'increment' | 'decrement') => () => {
            const date = new Date(selectedDate)
            const newDate = new Date(date)

            if (step === 'increment') newDate.setDate(date.getDate() + 1)
            else newDate.setDate(date.getDate() - 1)

            handleDateChange(newDate)
        },
        [selectedDate, handleDateChange],
    )

    return (
        <>
            <PageTitle>Capacity</PageTitle>
            <PageHeading fullWidth>
                Monitor capacity limits, admission quotas, and available spots per timeslot, ensuring efficient crowd
                management. Learn more in our{' '}
                <a href="https://support.convious.com/help/capacity-and-admission" target="_blank" rel="noreferrer">
                    Knowledge base
                </a>
                .
            </PageHeading>

            <div className="capacity-controls">
                <div className="capacity-controls__left" />
                <div className="capacity-controls__right">
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="medium"
                        onClick={() => handleDateChange(new Date())}
                    >
                        Today
                    </Button>
                    <FormItem className="date-picker" htmlFor="validFrom">
                        <DatePicker
                            id="selectedDate"
                            date={selectedDate ? new Date(selectedDate) : null}
                            onChange={handleDateChange}
                        />
                    </FormItem>
                    <Button
                        className="day-step-button"
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={stepDate('decrement')}
                    >
                        <ChevronLeftSolid />
                    </Button>
                    <Button
                        className="day-step-button"
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={stepDate('increment')}
                    >
                        <ChevronRightSolid />
                    </Button>
                </div>
            </div>

            <CapacityReportTimeline selectedDate={selectedDate} />
        </>
    )
}

export default CapacityReport
