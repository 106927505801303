import * as React from 'react'
import styled from 'styled-typed'
import { buttonReset } from '.'

interface CloseButtonProps {
    onClick: () => void
    height?: number
    transparent?: boolean
}

export const CloseButton = styled.button<CloseButtonProps>`
    ${buttonReset}
    display: inline-block;
    height: ${(props) => (props.height ? `${props.height}px` : '30px')};
    width: ${(props) => (props.height ? `${props.height}px` : '30px')};
    border-radius: 20px;
    font-size: ${(props) => `(${props.height} || 30) * 3 / 2` + 'px'};
    color: #808080;
    background-color: ${(props) => (props.transparent ? '' : 'rgba(0,0,0,0.04)')};
    text-align: center;
    overflow: visible;
    position: relative;
`

export function CloseButtonElement({ onClick, height, transparent }: CloseButtonProps) {
    return (
        <CloseButton onClick={onClick} height={height} transparent={transparent}>
            <span>&#10005;</span>
        </CloseButton>
    )
}
