import React from 'react'

import PaginationComponent from './paginationComponent'
import { ReactTableInstance } from '../interface'
import PaginationContainer from 'uiComponents/table/paginationContainer'

interface Props {
    itemsTotalCount?: number
    instance: ReactTableInstance
}

const Pagination: React.FC<Props> = ({ instance, itemsTotalCount }) => {
    return (
        <PaginationContainer
            pagination={{
                page: instance.state.pageIndex + 1,
                pageSize: instance.state.pageSize,
            }}
            onPaginationChanged={(pagination) => {
                if (pagination.pageSize !== instance.state.pageSize) {
                    return instance.setPageSize(pagination.pageSize)
                }

                if (pagination.page !== instance.state.pageIndex + 1) {
                    return instance.gotoPage(pagination.page - 1)
                }
            }}
            totalItemsCount={itemsTotalCount as number}
        >
            {({ pageNumbers, currentPage, onPageChange }) => (
                <PaginationComponent
                    onPageChange={onPageChange}
                    pageNumbers={pageNumbers as any}
                    currentPage={currentPage}
                />
            )}
        </PaginationContainer>
    )
}

export default Pagination
