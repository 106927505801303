import React, { useState } from 'react'
import ActionButton from '../../uiComponents/buttons'
import { useCancelFundTransferMutation, useGetPartnerFundLogQuery } from '../reduxQuery'
import { LoaderWrapper } from '../../uiComponents/loaders'
import { ConfirmationDialog } from '../../uiComponents/popups/confirmationDialog'
import Currency from '../../uiComponents/money/currency'
import { Body, Headline } from '../../uiComponents/typography'

interface Props {
    accountSlug: string
    accountName: string
}

const CancelFundTransfer = ({ accountSlug, accountName }: Props) => {
    const [open, setOpen] = useState<boolean>(false)
    const { data, isFetching } = useGetPartnerFundLogQuery({ accountSlug })
    const activeFundTransfer = data?.find((transfer) => transfer.status === 'PAYOUT_ON_HOLD')
    const [cancelFundTransfer, { isLoading: isCanceling }] = useCancelFundTransferMutation()

    if (!activeFundTransfer) {
        return null
    }

    return (
        <LoaderWrapper loading={isFetching}>
            {open && (
                <ConfirmationDialog
                    title="Unlock Payout"
                    buttonText="Stop Transfer"
                    onCancel={() => setOpen(false)}
                    loading={isCanceling}
                    onConfirm={() => {
                        cancelFundTransfer({ accountSlug, fundTransferId: activeFundTransfer.id }).then(() =>
                            setOpen(false),
                        )
                    }}
                >
                    <Body size={1} style={{ minWidth: '250px', display: 'inline-block' }}>
                        <Headline style={{ display: 'inline' }} size={6}>
                            {accountName}
                        </Headline>
                        &nbsp; payout has been locked for&nbsp;
                        <Headline size={6} style={{ display: 'inline' }}>
                            <Currency
                                amount={activeFundTransfer.amountValue}
                                currency={activeFundTransfer.currencyCode}
                            />
                        </Headline>{' '}
                        transfer request. Do you wish to cancel transfer and unlock{' '}
                        <Headline size={6} style={{ display: 'inline' }}>
                            {accountName}
                        </Headline>{' '}
                        payout?
                    </Body>
                </ConfirmationDialog>
            )}
            <ActionButton className="cancel-fund-transfer" size="small" secondary onClick={() => setOpen(true)}>
                Stop Transfer
            </ActionButton>
        </LoaderWrapper>
    )
}

export default CancelFundTransfer
