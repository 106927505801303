import * as React from 'react'
import styled, { keyframes, css } from 'styled-typed'
import { faPencilAlt, faEyeSlash, faEye, faUser } from '@fortawesome/free-solid-svg-icons'
import TimeField from 'react-simple-timefield'
import { NumberInput } from 'uiComponents/input'
import { maskPersonalData } from 'utils'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { ActionIcon } from 'uiComponents/buttons/actionIcon'

interface ValueTextProps {
    limitedTextWidth?: string
    responsiveWidth?: boolean
}

export const Container = styled.div`
    width: 100%;
`
export const ItemLabel = styled.label`
    font-size: 0.75em;
    text-transform: uppercase;
    margin-bottom: 1.24em;
    display: block;
    color: ${(props) => props.theme.colors.textLight};
`
export const ItemValue = styled.div<ValueTextProps>`
    width: ${(props) => (props.limitedTextWidth ? props.limitedTextWidth : '')};
    display: flex;
    color: ${(props) => props.theme.colors.textDark};
    margin-bottom: 0.125em;
`
export const ItemValueText = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: center;

    &.placeholder {
        opacity: 0.5;
        font-style: italic;
        padding-right: 0.1em;
    }
`
export const EditBox = styled.div<ValueTextProps>`
    position: relative;
    width: ${(props) => (props.limitedTextWidth ? props.limitedTextWidth : '')};
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &.stacked {
        flex-direction: column;
    }
`

const editInputCss = css`
    width: 100%;
    padding: 0;
    border: none;
    color: ${(props) => props.theme.colors.textDark};
    caret-color: ${(props) => props.theme.colors.boyBlue};
    &:focus {
        outline: none;
        border: none;
    }
    ::placeholder {
        opacity: 0.5;
        font-style: italic;
    }
`

const EditInput = styled.input`
    ${editInputCss}
`

const EditNumberInput = styled(NumberInput)`
    ${editInputCss}
    height: auto;
    border: 0 solid;
    padding: 0.2em;
`

const SaveButton = styled.div<{ disabled?: boolean }>`
    font-size: 0.75em;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    color: ${(props) => (props.disabled ? props.theme.colors.textLight : props.theme.colors.boyBlue)};
    align-self: center;
    margin-left: 1em;
`
const CancelButton = styled(SaveButton)`
    opacity: 0.5;
    margin-left: 0;
    color: ${(props) => props.theme.colors.textDark};
`
const expand = keyframes`
  from { width: 0; }
  to { width: 100%; }
`
export const InputBox = styled.div<ValueTextProps>`
    display: flex;
    width: ${(props) => (props.responsiveWidth ? '100%' : 'auto')};
    flex-direction: column;
    margin-right: ${(props) => (props.limitedTextWidth ? '.7em' : '1.5625em')};
    opacity: 1;
    transition: opacity 0.1s;

    &:after:not(.hide-underline) {
        display: block;
        content: '';
        background-color: ${(props) => props.theme.colors.boyBlue};
        height: 0.125em;
        width: 0;
        animation: ${expand} 0.2s ease-in;
        animation-fill-mode: forwards;
    }

    &.hide-box {
        opacity: 0;
    }
`

const TimeInput = styled(TimeField)`
    color: inherit;
    background: inherit;
    border: none;
    width: 50px;
    min-width: 50px;
    &:focus {
        outline: none;
    }
`
const ActionBox = styled.div`
    display: flex;

    &.stacked {
        margin-top: 0.3em;
        align-self: auto;
    }
`

interface InlineEditState {
    editMode: boolean
    inputValue: string | number
    maskData: boolean
}

interface InlineEditProps {
    accountSlug?: string
    id: string
    label?: string
    value: string | number
    disabled?: boolean
    limitedTextWidth?: string
    responsiveWidth?: boolean
    onEditAccept: (value: string) => void
    maxLength?: number
    timeField?: boolean
    isNumber?: boolean
    min?: number
    max?: number
    integerOnly?: boolean
    placeholder?: string | React.ReactElement
    style?: React.CSSProperties
    maskData?: boolean
    maskIcon?: boolean
    profileIcon?: boolean
    disabledOnEmpty?: boolean
}

export class InlineEdit extends React.PureComponent<InlineEditProps, InlineEditState> {
    constructor(props: InlineEditProps) {
        super(props)
        this.state = {
            editMode: false,
            inputValue: this.props.value,
            maskData: this.props.maskData || false,
        }
    }

    editInput: HTMLInputElement | NumberInput
    container: HTMLDivElement | null = null

    componentDidUpdate = (prevProps: InlineEditProps) => {
        if (prevProps.maskData !== this.props.maskData) {
            this.setState({ maskData: this.props.maskData || false })
        }
    }

    enableEdit = (e: React.MouseEvent<any>) => {
        e.stopPropagation()
        if (!!this.props.isNumber) {
            this.setState({ editMode: true, inputValue: this.props.value })
        } else {
            this.setState({ editMode: true, inputValue: this.props.value }, () =>
                (this.editInput as HTMLInputElement).select(),
            )
        }
    }

    getProfilesPageLink = () => {
        return `/account/${this.props.accountSlug}/crm/profiles/list?q=${encodeURIComponent(this.props.value)}`
    }

    toggleMaskedData = (e: React.MouseEvent<any>) => {
        e.stopPropagation()
        this.setState({ maskData: !this.state.maskData })
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            inputValue: e.target.value,
        })
    }

    onAccept = () => {
        const value = this.state.inputValue.toString().trim()
        if (!value) {
            return
        }
        this.props.onEditAccept(value)
        this.setState({ editMode: false })
    }

    render() {
        const {
            id,
            label,
            value,
            disabled,
            limitedTextWidth,
            timeField,
            isNumber,
            placeholder,
            min,
            max,
            integerOnly,
            style,
            responsiveWidth,
            maskIcon,
            profileIcon,
        } = this.props
        const { editMode, inputValue, maskData } = this.state
        const maxWidth = responsiveWidth && this.container ? `${this.container.scrollWidth - 10}` : limitedTextWidth
        const showMaskIcon = maskIcon && !!value

        return (
            <Container style={style} ref={(node: HTMLDivElement) => (this.container = node)}>
                {label && <ItemLabel>{label}</ItemLabel>}
                {!editMode && (
                    <ItemValue id={id} limitedTextWidth={maxWidth}>
                        {value && (
                            <ItemValueText title={value?.toString()}>
                                {!timeField && !isNumber && maskData && value
                                    ? maskPersonalData(value.toString())
                                    : value}
                            </ItemValueText>
                        )}
                        {!value && placeholder && <ItemValueText className="placeholder">{placeholder}</ItemValueText>}
                        {showMaskIcon && !maskData && (
                            <ActionIcon
                                onClick={this.toggleMaskedData}
                                title="Hide details"
                                className="first-icon"
                                icon={faEyeSlash}
                            />
                        )}
                        {showMaskIcon && maskData && (
                            <ActionIcon
                                onClick={this.toggleMaskedData}
                                title="Show details"
                                className="first-icon"
                                icon={faEye}
                            />
                        )}
                        {!disabled && (
                            <ActionIcon
                                onClick={this.enableEdit}
                                title="Edit"
                                className={showMaskIcon ? 'open-edit' : 'open-edit first-icon'}
                                icon={faPencilAlt}
                            />
                        )}
                        {profileIcon && (
                            <UnstyledLink to={this.getProfilesPageLink()}>
                                <ActionIcon title="Go to profile" icon={faUser} />
                            </UnstyledLink>
                        )}
                    </ItemValue>
                )}
                {editMode && (
                    <EditBox
                        limitedTextWidth={maxWidth}
                        className={timeField || limitedTextWidth || responsiveWidth ? 'stacked' : ''}
                    >
                        <InputBox limitedTextWidth={maxWidth} responsiveWidth={responsiveWidth}>
                            {!timeField && !isNumber && (
                                <EditInput
                                    id={id}
                                    value={inputValue ? inputValue : ''}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={this.handleChange}
                                    ref={(input: HTMLInputElement) => (this.editInput = input)}
                                    maxLength={this.props.maxLength}
                                    placeholder={typeof placeholder === 'string' ? placeholder : ''}
                                />
                            )}
                            {isNumber && (
                                <EditNumberInput
                                    id={id}
                                    value={inputValue ? inputValue : ''}
                                    onChange={this.handleChange}
                                    min={min}
                                    max={max}
                                    integerOnly={integerOnly}
                                    ref={(input: NumberInput) => (this.editInput = input)}
                                    maxLength={this.props.maxLength}
                                    placeholder={typeof placeholder === 'string' ? placeholder : ''}
                                />
                            )}
                            {timeField && (
                                <TimeInput
                                    value={inputValue ? inputValue : ''}
                                    onChange={this.handleChange}
                                    input={<input ref={(input: HTMLInputElement) => (this.editInput = input)} />}
                                />
                            )}
                        </InputBox>
                        <InlineEditActionSection
                            stacked={timeField || !!limitedTextWidth}
                            onCancel={() => this.setState({ editMode: false })}
                            onAccept={this.onAccept}
                            hasValue={this.props.disabledOnEmpty ? !!this.state.inputValue.toString().trim() : true}
                        />
                    </EditBox>
                )}
            </Container>
        )
    }
}

interface ActionSectionProps {
    hasValue?: boolean
    stacked: boolean
    onCancel: () => void
    onAccept: () => void
}

export function InlineEditActionSection({ stacked, onAccept, onCancel, hasValue = true }: ActionSectionProps) {
    const cancel = (e: React.MouseEvent<any>) => {
        e.stopPropagation()
        onCancel()
    }

    const accept = (e: React.MouseEvent<any>) => {
        e.stopPropagation()
        onAccept()
    }

    return (
        <ActionBox className={stacked ? 'stacked' : ''}>
            <CancelButton onClick={cancel}>Cancel</CancelButton>
            <SaveButton onClick={accept} className="save-inline-edit" disabled={!hasValue}>
                Save
            </SaveButton>
        </ActionBox>
    )
}
