import * as React from 'react'
import { usePrevious } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { AccountSettingsServiceContext } from 'settings/context'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { DataTable, HeaderRow, DataRow, TableHeader, Cell } from 'uiComponents/table'
import { MessageKind } from 'uiComponents/messages'
import { areDateRangeDatesEqual, mapTableData } from 'reports/helpers'
import { format } from 'date-fns'
import { DateRange } from 'dateRanges'
import Money from 'uiComponents/money'
import { TableLoader } from 'uiComponents/loaders'
import { withFeatures } from 'features'
import { ChartHeadline } from 'uiComponents/charts/styleComponents'
import isEqual from 'lodash/isEqual'
import { Filters } from 'uiComponents/filter/schema'
import {
    ReportTimeIdentifierType,
    dummyDownloadData,
    DownloadData,
    DownloadHeader,
    GeneralDataSeriesStats,
} from 'reports/schema'
import { QueryConfig } from 'reports/queryGenerator'
import { ResellerResponseItem } from 'settings/settingsService'
import { TitleWrapper } from './table'
import ExportMenu from 'reports/exportMenu'
import { LoginService } from 'http/loginService'
import { LoggingService } from 'http/loggingService'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import Infotip from 'uiComponents/infotip'
import { Feature } from 'features'

const queryConfig: QueryConfig = {
    querySetName: 'ResellersRevenueTable',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'metric', type: 'StatsMetric' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'groupBy', type: 'String' },
        { name: 'filters', type: '[FilterDictionary]' },
        { name: 'timeIdentifierType', type: 'TimeIdentifierType' },
    ],
    queries: [
        {
            name: 'stats',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'groupBy', 'filters', 'timeIdentifierType'],
            customVariables: [],
            presetResult: 'dataSeries',
        },
        {
            name: 'totals',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'filters', 'timeIdentifierType'],
            customVariables: [],
            presetResult: 'dataSeries',
        },
    ],
}

interface ResellersTableData {
    resellerName: string
    revenue: string
    refunded: string
    netRevenue: string
}

interface ResellersRevenueTableProps {
    dateRange: DateRange
    timeIdentifierType: ReportTimeIdentifierType
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    filters: Filters[]
    loginService: LoginService
    loggingService: LoggingService
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hideMessage: (id: string) => void
    hasFeature: (feature: string, accountSlug: string) => boolean
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    getCurrency: (accountSlug: string) => Currency
}

function ResellersRevenueTable(props: ResellersRevenueTableProps) {
    const _isMounted = React.useRef(false)
    const _lastRequest = React.useRef<number>()
    const statsService = React.useContext(StatsServiceContext)
    const settingsService = React.useContext(AccountSettingsServiceContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [revenueData, setRevenueData] = React.useState<ResellersTableData[]>([])
    const [totalData, setTotalData] = React.useState<ResellersTableData | null>(null)
    const [downloadData, setDownloadData] = React.useState<DownloadData>(dummyDownloadData)

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    const prevFilters = usePrevious(props.filters)
    const prevTimeIdentifierType = usePrevious(props.timeIdentifierType)
    React.useEffect(() => {
        _isMounted.current = true
        if (
            prevAccountSlug !== props.accountSlug ||
            !areDateRangeDatesEqual(prevDateRange, props.dateRange) ||
            !isEqual(prevFilters, props.filters) ||
            prevTimeIdentifierType !== props.timeIdentifierType
        ) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug, props.dateRange, props.filters, props.timeIdentifierType])

    React.useEffect(() => {
        if (!loading && _isMounted.current) {
            const currency = props.getCurrency(props.accountSlug)
            const name = 'reseller_revenue_breakdown'
            const headers: DownloadHeader[] = [
                { slug: 'reseller', label: 'Reseller', width: 25 },
                {
                    slug: 'revenue',
                    label: `Reseller revenue generated (${currency.symbol})`,
                    width: 25,
                },
                {
                    slug: 'refunded',
                    label: `Refunded or canceled revenue (${currency.symbol})`,
                    width: 27,
                },
                {
                    slug: 'netRefunded',
                    label: `Net revenue collected (${currency.symbol})`,
                    width: 21,
                },
            ]
            const values = (revenueData.length && mapTableData(revenueData)) || [['', '', '', '']]
            setDownloadData({ name, headers, values })
        }
    }, [loading])

    const roundedAmount = (value: string) => {
        if (value && Number(value)) {
            return Number(value).toFixed(2)
        } else {
            return '0'
        }
    }

    const setData = (data: GeneralDataSeriesStats, resellers: ResellerResponseItem[]) => {
        if (!data.stats.dataSeries.columns.length) {
            setRevenueData([])
            setTotalData(null)
            return
        }
        const resellerIdIndex = data.stats.dataSeries.columns.indexOf('reseller_id')
        const revenueIndex = data.stats.dataSeries.columns.indexOf('revenue')
        const refundedIndex = data.stats.dataSeries.columns.indexOf('refunded')
        const netRevenueIndex = data.stats.dataSeries.columns.indexOf('net_revenue')
        const mappedData = data.stats.dataSeries.data.map((d: any) => {
            const reseller = resellers.find((r) => r.resellerId === d[resellerIdIndex])
            return {
                resellerName: reseller?.name || d[resellerIdIndex],
                revenue: roundedAmount(d[revenueIndex]),
                refunded: roundedAmount(d[refundedIndex]),
                netRevenue: roundedAmount(d[netRevenueIndex]),
            }
        })
        setRevenueData(mappedData)
        setTotalData({
            resellerName: '',
            revenue: roundedAmount(data.totals.dataSeries.data[0][revenueIndex]),
            refunded: roundedAmount(data.totals.dataSeries.data[0][refundedIndex]),
            netRevenue: roundedAmount(data.totals.dataSeries.data[0][netRevenueIndex]),
        })
    }

    const getData = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        try {
            setLoading(true)
            const dateFrom = format(props.dateRange.from, 'yyyy-MM-dd')
            const dateTo = format(props.dateRange.to, 'yyyy-MM-dd')
            const variables = {
                widget: props.accountSlug,
                metric: 'reseller_overview',
                dateFrom,
                dateTo,
                groupBy: 'reseller_id',
                filters: props.filters,
                timeIdentifierType: props.timeIdentifierType,
            }
            const resellers = await settingsService.getResellersList(props.accountSlug)
            const data = await statsService.getStats(queryConfig, variables)
            if (_lastRequest.current !== requestTime) {
                return
            }
            setData(data, resellers)
            if (_isMounted.current) {
                setLoading(false)
            }
        } catch {
            props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Resellers revenue table could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }

    const columnWidths = [null, '17em', '19em', '15em', '0']

    const infotipMargin = '-0.3em'

    return (
        <div style={{ marginBottom: '2em' }}>
            <TitleWrapper>
                <ChartHeadline size={4}>Reseller Revenue Breakdown</ChartHeadline>
                <ExportMenu
                    accountSlug={props.accountSlug}
                    downloadData={downloadData}
                    loginService={props.loginService}
                    loggingService={props.loggingService}
                    replaceMessages={props.replaceTopMessages}
                    hideMessage={props.hideMessage}
                />
            </TitleWrapper>
            <DataTable columnWidths={columnWidths}>
                <HeaderRow>
                    <TableHeader>Reseller</TableHeader>
                    <TableHeader align="right">
                        Reseller revenue generated
                        <Infotip pointer="right" maxWidth="32em" style={{ marginRight: infotipMargin }}>
                            This is the total initial revenue generated from the resellers’ sold products.
                        </Infotip>
                    </TableHeader>
                    <TableHeader align="right">
                        Refunded or canceled revenue
                        <Infotip pointer="right" maxWidth="32em" style={{ marginRight: infotipMargin }}>
                            This is the amount of revenue that your resellers refunded to the customer.
                        </Infotip>
                    </TableHeader>
                    <TableHeader align="right">
                        Net revenue collected
                        <Infotip pointer="right" maxWidth="32em" style={{ marginRight: infotipMargin }}>
                            The net revenue collected is the revenue you collect from and invoice your resellers (= The
                            total revenue collected by your resellers - the refunded revenue).
                        </Infotip>
                    </TableHeader>
                    <TableHeader nonInteractive />
                </HeaderRow>
                {loading && <TableLoader />}
                {!loading && (
                    <>
                        {revenueData.map((d, i) => (
                            <DataRow key={d.resellerName + i} narrow>
                                <Cell>{d.resellerName}</Cell>
                                <Cell align="right">
                                    <Money amount={d.revenue} accountSlug={props.accountSlug} />
                                </Cell>
                                <Cell align="right">
                                    <Money amount={d.refunded} accountSlug={props.accountSlug} />
                                </Cell>
                                <Cell align="right">
                                    <Money amount={d.netRevenue} accountSlug={props.accountSlug} />
                                </Cell>
                                <Cell />
                            </DataRow>
                        ))}
                    </>
                )}
                <Feature name="ResellersTableTotalsLine" accountSlug={props.accountSlug}>
                    <DataRow narrow bold>
                        <Cell>Total</Cell>
                        <Cell align="right">
                            <Money amount={(!loading && totalData?.revenue) || '0'} accountSlug={props.accountSlug} />
                        </Cell>
                        <Cell align="right">
                            <Money amount={(!loading && totalData?.refunded) || '0'} accountSlug={props.accountSlug} />
                        </Cell>
                        <Cell align="right">
                            <Money
                                amount={(!loading && totalData?.netRevenue) || '0'}
                                accountSlug={props.accountSlug}
                            />
                        </Cell>
                        <Cell />
                    </DataRow>
                </Feature>
            </DataTable>
        </div>
    )
}

export default withCurrency(withFeatures(withNavigation(ResellersRevenueTable)))
