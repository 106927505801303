import { BarcodePoolFormat, BarcodePoolType } from '../bookingCodesService'

export type BarcodeFormatOption = {
    name: string
    value: {
        type: BarcodePoolFormat
        allowPrefix: boolean
        allowGenerated: boolean
    }
}

export type BarcodePoolTypeOption = {
    name: string
    value: BarcodePoolType
}

export const barcodeFormatOptions: BarcodeFormatOption[] = [
    { name: 'EAN-13', value: { type: 'ean13', allowPrefix: false, allowGenerated: true } },
    { name: 'EAN-8', value: { type: 'ean8', allowPrefix: false, allowGenerated: false } },
    { name: 'Code-128', value: { type: 'code128', allowPrefix: true, allowGenerated: true } },
    { name: 'Code-39', value: { type: 'code39', allowPrefix: true, allowGenerated: true } },
    { name: 'QR-Code', value: { type: 'qr_code', allowPrefix: true, allowGenerated: true } },
]

export const poolTypeOptions: BarcodePoolTypeOption[] = [
    { name: 'Generated', value: 'Generated' },
    { name: 'Uploaded', value: 'Uploaded' },
]
