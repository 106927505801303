import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import styled from 'styled-typed'
import { Radio } from 'uiComponents/input'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { DATA_TYPES, DataType, IDate, IOption } from '../../types'
import RuleContainer from './container'
import { parseDate } from 'utils'

const Element = styled.div`
    padding: 1.5em 1.2em;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Label = styled.div`
    font-size: 1em;
    font-weight: 500;
`

const LabelDate = styled.div`
    font-size: 0.8em;
    padding-bottom: 0.5em;
    font-weight: 500;
`

const Types = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: ${(props) => props.theme.colors.background};
`

const Presets = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: ${(props) => props.theme.colors.white};
    padding: 1em;
`

const Item = styled.div`
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    padding: 0.5em;
    font-size: 0.8em;
    color: ${(props) => props.theme.colors.textLight};
    cursor: pointer;
`

const Customs = styled.div`
    display: flex;
`

const DateContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1em;
`

export interface CustomDate {
    type: string
    caption: string
    value: {
        start: string
        end: string
    }
}

export interface BasicDateRangeOptions {
    type: string
    caption: string
    value: null | string
}

interface FilterRadioProps {
    label: string
    options: IOption[]
    value: IOption
    onChange: (value: IOption) => void
}

const FilterDateRange = (props: FilterRadioProps) => {
    const [type, setType] = useState<DataType>(props.value?.type || 'preset')
    const dateValue = props.value?.value as IDate
    const [start, setStart] = useState<string | null>(dateValue?.start || null)
    const [end, setEnd] = useState<string | null>(dateValue?.end || null)
    const theme = useTheme()
    const value = props.value?.value
    const presets = props.options.filter((item) => item.type === 'preset') as BasicDateRangeOptions[]

    const onChange = (value: IOption) => {
        props.onChange(value)
    }

    useEffect(() => {
        if (start || end) {
            props.onChange({
                type: 'custom',
                value: {
                    start: start || '',
                    end: end || '',
                },
            })
        }
    }, [start, end])

    const onChangeType = (type: DataType) => {
        setType(type)
        props.onChange({
            type: type,
            value:
                type === 'preset'
                    ? value
                    : {
                          start: start || '',
                          end: end || '',
                      },
        })
    }

    const onChangeStart = (value: Date) => {
        setStart(value ? format(value, 'yyyy-MM-dd') : null)
    }

    const onChangeEnd = (value: Date) => {
        setEnd(value ? format(value, 'yyyy-MM-dd') : null)
    }

    return (
        <RuleContainer label={props.label}>
            <Types>
                {DATA_TYPES.map((item, index) => (
                    <Element onClick={() => onChangeType(item)} key={index}>
                        <Radio checked={type === item} onChange={() => {}} />
                        <Label>{item}</Label>
                    </Element>
                ))}
            </Types>
            {type === 'preset' ? (
                <Presets>
                    {presets.map((option, index) => (
                        <Item
                            onClick={() => onChange({ type: 'preset', value: option.value })}
                            key={index}
                            style={{
                                borderColor: theme.colors[value === option.value ? 'border' : 'white'],
                                color: theme.colors[value === option.value ? 'textDark' : 'textLight'],
                            }}
                        >
                            {option.caption}
                        </Item>
                    ))}
                </Presets>
            ) : (
                <Customs>
                    <DateContainer>
                        <LabelDate>Start</LabelDate>
                        <DatePicker
                            id="startDate"
                            date={start ? parseDate(start) : null}
                            onChange={onChangeStart}
                            status="normal"
                            allowNullDate
                        />
                    </DateContainer>
                    <DateContainer>
                        <LabelDate>End</LabelDate>
                        <DatePicker
                            id="endDate"
                            date={end ? parseDate(end) : null}
                            onChange={onChangeEnd}
                            status="normal"
                            allowNullDate
                        />
                    </DateContainer>
                </Customs>
            )}
        </RuleContainer>
    )
}

export default FilterDateRange
