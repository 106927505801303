import * as React from 'react'
import { Navigation } from 'navigation'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Messages } from 'uiComponents/messages'
import { withMessages, MessageProps } from 'hocs'
import { StatsService } from 'http/statsService'
import DatedChart from './datedChart'
import UndatedChart from './undatedChart'
import { createTimeseriesBarChart, TimeseriesBarChartSource } from 'uiComponents/charts/timeseriesBarChartSource'
import { PageTitle, PageHeading } from 'uiComponents/typography'
import { dateRangeToQuery, DateRange } from 'dateRanges'
import {
    ChartWrapperWithDateRange as WithDateRangeNotice,
    getDataFromQuery,
    chartRowsGap,
    areDateRangeDatesEqual,
    ReportsToolsWrapper,
    ReportUpdateInfo,
} from '../helpers'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import ReportsFilter from 'reports/reportsFilter'
import { AVAILABLE_FILTERS, STANDALONE_FILTERS, METADATA_CATEGORIES } from 'reports/constants'
import { FilterCategory } from 'uiComponents/filter/schema'
import { ChartContainer } from 'uiComponents/charts/styleComponents'
import { InventoryService } from 'inventory/inventoryService'
import Disclaimer from 'reports/disclaimer'

interface BarcodesReportProps {
    accountSlug: string
    statsService: StatsService
    inventoryService: InventoryService
    navigation: Navigation
    match: RouteMatch<any>
}

interface BarcodesReportState {
    datedChart: TimeseriesBarChartSource
    undatedChart: TimeseriesBarChartSource
}

class BarcodesReport extends React.Component<BarcodesReportProps & MessageProps, BarcodesReportState> {
    constructor(props: BarcodesReportProps & MessageProps) {
        super(props)
        const { dateRange } = getDataFromQuery(this.props.navigation.query())
        this.state = {
            datedChart: createTimeseriesBarChart(dateRange),
            undatedChart: createTimeseriesBarChart(dateRange),
        }
    }

    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    onDateRangeChanged = (range: DateRange) => {
        const { dateRange } = getDataFromQuery(this.props.navigation.query())
        if (!areDateRangeDatesEqual(dateRange, range)) {
            this.props.navigation.addQueryWithReplace(dateRangeToQuery(range))
            this.setState({
                datedChart: createTimeseriesBarChart(range),
                undatedChart: createTimeseriesBarChart(range),
            })
        }
    }

    render() {
        const { accountSlug, statsService, replaceMessages, hideMessage, navigation } = this.props
        const { dateRange, filters } = getDataFromQuery(navigation.query())
        const appliedFilters = filters.filter(
            (f) => AVAILABLE_FILTERS['barcodes'].indexOf(f.attribute as FilterCategory) > -1,
        )

        return (
            <div id="reports-barcodes-page">
                <Messages messages={this.props.messages} hideMessage={this.props.hideMessage} />
                <PageTitle>Barcodes Report</PageTitle>
                <PageHeading fullWidth>
                    <div>
                        Your barcodes report holds information about your redeemable tickets and whether they were
                        redeemed or not.
                    </div>
                    <div>
                        If you would like to see the total amount of visitors coming to the venue, select only
                        “reservation” and “single ticket” product types.
                    </div>
                    <div>
                        Find answers to frequently asked questions in our{' '}
                        <a target="_blank" href="https://support.convious.com/help/barcode-report-faq" rel="noreferrer">
                            Knowledge Base
                        </a>
                        .
                    </div>
                </PageHeading>
                <ReportUpdateInfo
                    metric="Barcodes that are redeemed directly via Convious"
                    text="updated every 15 minutes."
                />
                <ReportsToolsWrapper>
                    <ReportsFilter
                        accountSlug={accountSlug}
                        statsService={statsService}
                        inventoryService={this.props.inventoryService}
                        applicableFilters={AVAILABLE_FILTERS['barcodes']}
                        standaloneFilters={STANDALONE_FILTERS['barcodes']}
                        metadataCategories={METADATA_CATEGORIES['barcodes']}
                        appliedFilters={appliedFilters}
                        replaceMessages={replaceMessages}
                        hideMessage={hideMessage}
                        dateRange={dateRange}
                    />
                    <DateRangePicker
                        range={dateRange}
                        onChange={this.onDateRangeChanged}
                        firstAvailableDay={new Date(2019, 0, 1)}
                        allowFutureDateSelection
                    />
                </ReportsToolsWrapper>
                <ChartContainer>
                    <WithDateRangeNotice dateRange={dateRange}>
                        <DatedChart
                            statsService={this.props.statsService}
                            filters={appliedFilters}
                            accountSlug={accountSlug}
                            chart={this.state.datedChart}
                            dateRange={dateRange}
                            replaceTopMessages={this.props.replaceMessages}
                        />
                    </WithDateRangeNotice>
                </ChartContainer>
                <ChartContainer style={{ marginTop: chartRowsGap, marginBottom: '2em' }}>
                    <WithDateRangeNotice dateRange={dateRange}>
                        <UndatedChart
                            statsService={this.props.statsService}
                            filters={appliedFilters}
                            accountSlug={accountSlug}
                            chart={this.state.undatedChart}
                            dateRange={dateRange}
                            replaceTopMessages={this.props.replaceMessages}
                        />
                    </WithDateRangeNotice>
                </ChartContainer>
                <Disclaimer />
            </div>
        )
    }
}

export default withMessages(withNavigation(BarcodesReport))
