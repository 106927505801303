import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Svg from 'svg'

import { useNavigation } from 'hocs'
import { useSendMollieAuthMutation } from 'settings/reduxQuery'
import { State } from 'store'
import { replaceMessage } from 'uiComponents/messages/actions'
import { LoggerReject } from '../../../../../types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faForward } from '@fortawesome/pro-regular-svg-icons'
import { MollieFullIcon } from './mollieIcon'
import conviousLogo from 'images/convious-logo-pink.svg'
import { IconProp } from '@fortawesome/fontawesome'
import { Headline } from 'uiComponents/typography'
import { Col, Row } from 'uiComponents/flex'
import ActionButton from 'uiComponents/buttons'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'

const RedirectDefault = () => {
    return (
        <>
            <Row align="center">
                <Col span="auto">
                    <Headline size={4}>Redirecting...</Headline>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <MollieFullIcon height="22" />
                    <span style={{ paddingLeft: '1em' }} />
                    <FontAwesomeIcon icon={faForward as IconProp} />
                    <span style={{ paddingLeft: '1em' }} />
                    <Svg src={conviousLogo} style={{ height: '1em' }} />
                </Col>
            </Row>
        </>
    )
}

const RedirectAfter10sec = () => {
    return (
        <>
            <Row align="center">
                <Col span="auto">
                    <Headline size={4}>Redirecting takes a bit longer...</Headline>
                </Col>
            </Row>
            <Row align="center">
                <Col span="auto">
                    <ActionButton secondary disabled>
                        Stay on this page
                    </ActionButton>
                </Col>
            </Row>
        </>
    )
}

const RedirectFallback = () => {
    const accountSlug = useSelector((state: State) => state.preferences.activeAccount)

    return (
        <>
            <Row align="center">
                <Col span="auto">
                    <Headline size={4}>Redirecting takes way too long...</Headline>
                </Col>
            </Row>
            <Row align="center">
                <Col span="auto">
                    <UnstyledLink to={`/account/${accountSlug}/settings/account_settings/integrations`}>
                        <ActionButton kind="action">Go back</ActionButton>
                    </UnstyledLink>
                </Col>
            </Row>
        </>
    )
}

type ScreenStates = 'default' | '10sec' | 'longWaiting'

const MollieAuthCallback = () => {
    const [sendMollieAuth] = useSendMollieAuthMutation()
    const navigation = useNavigation()
    const { state, code } = navigation.query()
    const history = useHistory()
    const accountSlug = useSelector((state: State) => state.preferences.activeAccount)
    const dispatch = useDispatch()
    const [renderScreen, setRenderScreen] = useState<ScreenStates>('default')

    useEffect(() => {
        if (code && accountSlug) {
            sendMollieAuth({ authorizationResponse: window.location.href, requestId: state })
                .unwrap()
                .then(() => {
                    history.push(`/account/${accountSlug}/settings/account_settings/integrations`)
                })
                .catch((err?: LoggerReject) => {
                    dispatch(
                        replaceMessage(
                            'mollie_integration',
                            'error',
                            err?.response?.error?.error || 'Something went wrong',
                            5000,
                        ),
                    )
                })
        }
    }, [state, code, accountSlug])

    useEffect(() => {
        const firstTimeoutId = setTimeout(() => setRenderScreen('10sec'), 10e3)
        const secondTimeoutId = setTimeout(() => setRenderScreen('longWaiting'), 20e3)

        return () => {
            clearTimeout(firstTimeoutId)
            clearTimeout(secondTimeoutId)
        }
    }, [])

    const getRedirectWindow = () => {
        if (renderScreen === '10sec') {
            return <RedirectAfter10sec />
        }

        if (renderScreen === 'longWaiting') {
            return <RedirectFallback />
        }

        return <RedirectDefault />
    }

    return (
        <Row align="center" style={{ alignContent: 'center', height: '100vh', width: '100%' }}>
            <Col span="auto">{getRedirectWindow()}</Col>
        </Row>
    )
}

export default MollieAuthCallback
