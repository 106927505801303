import React from 'react'
import { currencyMapper } from '../../utils/currencyMapper'
import Big from 'big.js'

interface Props {
    currency?: string
    amount: number
    precision?: number
}

const Currency = ({ currency, amount, precision = 2 }: Props) => {
    let currencySymbol = currencyMapper(currency)

    if (!amount) {
        return null
    }

    return (
        <>
            {currencySymbol}&nbsp;{Big(amount).toFixed(precision).toString()}
        </>
    )
}

export default Currency
