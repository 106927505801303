import React from 'react'
import styled from 'styled-typed'
import { Row } from 'uiComponents/flex'
import { Checkbox } from 'uiComponents/input'

const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 1.2rem;
    border-radius: 0.375em;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
`

const MetricLabel = styled.div`
    font-size: 0.75rem;
    color: ${(p) => p.theme.colors.textLight};
    margin-bottom: 1.2em;
    text-align: center;
`

interface VerticalWrapperProps {
    height: string
}

const Wrapper = styled.div<VerticalWrapperProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: ${(p) => p.height};
`

interface ColumnProps {
    values: boolean[]
    onChange: (v: boolean[]) => void
    defaultValues?: boolean[] | null
    metric?: string
    id?: string
}

export function CheckboxColumn(props: ColumnProps) {
    const { metric } = props

    const setValues = (v: boolean, index: number) => {
        const newValues = [...props.values]
        newValues[index] = v
        props.onChange(newValues)
    }

    return (
        <Row>
            <CheckboxWrapper>
                {metric && <MetricLabel>{metric}</MetricLabel>}
                <Row>
                    <Wrapper height="9rem">
                        {props.values.map((v, index) => (
                            <div key={index}>
                                <Checkbox
                                    name="termsAgreement"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setValues(e.target.checked, index)
                                    }
                                    checked={v}
                                    style={{ marginRight: '0' }}
                                    bordered={!!props.defaultValues && !!props.defaultValues[index] && !v}
                                />
                            </div>
                        ))}
                    </Wrapper>
                </Row>
            </CheckboxWrapper>
        </Row>
    )
}
