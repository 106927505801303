import React from 'react'
import styled from 'styled-typed'
import { useParams } from 'react-router'

import conviousLogo from 'images/convious-logo-pink.svg'
import { RequiresToChangePassword } from 'routesUtils'
import { AppServices, history } from 'middleware'
import { BaseRouteParams } from 'hocs'
import Header from 'header'
import Svg from 'svg'
import { PageContent } from './pageContent'
import { State } from 'store'
import { connect } from 'react-redux'

const Layout = styled.div`
    font-family: ${(props) => props.theme.fonts.primary};
    font-size: 16px;
    color: ${(props) => props.theme.colors.textDark};
    background: ${(props) => props.theme.colors.background};
    padding-left: 11.2em;
    height: 100%;
    width: 100%;
    flex: 1;
    transition: 0.15s all ease;

    &.fixed {
        position: fixed;
    }

    @media (max-width: ${(props) => props.theme.breakPoints.md}) {
        padding-left: 0;
    }
`
const LayoutNoSidebar = styled(Layout)`
    padding: 0 1em;
`
const LogoWrapper = styled.div`
    width: 6em;
    position: absolute;
    top: 2em;
    left: 2em;
`

const ContentWrapper = styled.div`
    height: 100%;
`

export const FilterSection = styled.div`
    margin-top: 1em;
    margin-bottom: 1.5em;

    &:after {
        content: '';
        display: table;
        clear: both;
    }
`

interface HeaderOnlyProps {
    logoutOnly?: boolean
    requiresPasswordChange: boolean
}

export function withHeaderOnly(
    props: HeaderOnlyProps,
    accountSlug: string,
    logoutOnly: boolean,
    component: JSX.Element,
) {
    return (
        <HeaderOnly requiresPasswordChange={props.requiresPasswordChange} logoutOnly={logoutOnly}>
            {component}
        </HeaderOnly>
    )
}

export const HeaderOnly: React.FC<HeaderOnlyProps> = ({ children, requiresPasswordChange, logoutOnly }) => {
    const { accountSlug } = useParams<BaseRouteParams>()

    return (
        <LayoutNoSidebar>
            <ContentWrapper>
                <LogoWrapper>
                    <a href="/">
                        <Svg src={conviousLogo} />
                    </a>
                </LogoWrapper>
                <Header
                    logout={AppServices.loginService.logout}
                    accountSlug={accountSlug}
                    logoutOnly={logoutOnly}
                    history={history}
                />
                <PageContent className="no-sidebar">
                    <RequiresToChangePassword requiresPasswordChange={requiresPasswordChange}>
                        {children}
                    </RequiresToChangePassword>
                </PageContent>
            </ContentWrapper>
        </LayoutNoSidebar>
    )
}

const mapStateToProps = (state: State) => {
    return {
        requiresPasswordChange: state.profile?.profile?.requiresPasswordChange || false,
    }
}

export default connect(mapStateToProps)(HeaderOnly)
