import * as React from 'react'
import { Checkbox } from 'uiComponents/input'
import { Cell, ExpandCell, DataRow } from 'uiComponents/table'
import { TicketsForRefund } from './types'
import Money from 'uiComponents/money'
import { flatMap } from 'utils'
import classNames from 'classnames'
import './styles.scss'
import AmountCell from './amountCell'

interface RefundRowProps {
    accountSlug: string
    ticket: TicketsForRefund
    selected: boolean
    selectedTickets: string[]
    confirmRefundView: boolean
    fullRefundOnly: boolean
    cancellation: boolean
    updateHeight: () => void
    handleOrderItemSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleTicketSelect: (orderItemUuid: string, ticketUuids: string[]) => void
}

const RefundRow = ({
    accountSlug,
    ticket,
    selectedTickets,
    handleOrderItemSelect,
    handleTicketSelect,
    confirmRefundView,
    fullRefundOnly,
    cancellation,
    updateHeight,
}: RefundRowProps) => {
    const [expanded, setExpanded] = React.useState(false)
    const allTicketBarcodes = flatMap(
        ticket.items.filter((i) => i.itemRefundable),
        (x) => x.ticketUuids,
    )
    const selected = allTicketBarcodes.length === selectedTickets.length
    const refundableItems = ticket.items.filter((i) => i.itemRefundable)
    const ticketTotal = refundableItems.reduce((s, i) => s + Number(i.price), 0).toFixed(2)

    React.useEffect(() => {
        updateHeight()
    }, [expanded])

    const toggleExpanded = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        let target = e.target as HTMLInputElement
        if (target.type !== 'checkbox') {
            setExpanded((prev) => !prev)
        }
    }

    return (
        <div>
            <DataRow
                onClick={(e) => {
                    if ((e.target as Element).tagName !== 'LABEL') {
                        toggleExpanded(e)
                    }
                }}
                className={classNames('refund-ticket-row expandable refund-data-row', {
                    disabled: !ticket.canRefund,
                })}
            >
                {!confirmRefundView && !fullRefundOnly && ticket.canRefund && (
                    <Cell>
                        <Checkbox
                            id={`${ticket.orderItemId}`}
                            name={`${ticket.orderItemId}`}
                            checked={selectedTickets.length > 0}
                            onChange={handleOrderItemSelect}
                            indeterminateState={!selected}
                            data-testid="refund-checkbox"
                        />
                    </Cell>
                )}
                {!ticket.canRefund && !fullRefundOnly && <Cell />}
                <Cell title={ticket.product}>
                    <label htmlFor={`${ticket.orderItemId}`}>{ticket.product}</label>
                </Cell>
                <Cell align="right">{ticket.items.filter((i) => i.itemRefundable).length}&times;</Cell>
                <Cell align="right">
                    {ticket.canRefund && !fullRefundOnly && <Money accountSlug={accountSlug} amount={ticketTotal} />}
                    {(!ticket.canRefund || (fullRefundOnly && ticket.status !== 'not redeemed')) && (
                        <div className="status">{ticket.status}</div>
                    )}
                </Cell>
                <ExpandCell expanded={expanded} />
            </DataRow>
            <div className={classNames('barcodes', { expanded })}>
                {ticket.items.map((item) => {
                    const barcodesString = item.barcodeList.join(', ') || '-'
                    const truncatedBarcodeString =
                        barcodesString.length > 4 ? `...${barcodesString.slice(-4)}` : barcodesString
                    return (
                        <DataRow
                            key={item.ticketUuids[0]}
                            className={classNames('refund-data-row', { disabled: !item.itemRefundable })}
                        >
                            {!confirmRefundView && !fullRefundOnly && <Cell />}
                            <Cell>
                                {!confirmRefundView && !fullRefundOnly && item.itemRefundable && (
                                    <Checkbox
                                        className="barcode-checkbox"
                                        id={item.ticketUuids[0].toString()}
                                        name={item.ticketUuids[0].toString()}
                                        checked={selectedTickets.indexOf(item.ticketUuids[0]) > -1}
                                        onChange={() => handleTicketSelect(ticket.orderItemId, item.ticketUuids)}
                                        style={{ marginRight: '1.2em' }}
                                    />
                                )}
                                {(!item.itemRefundable || fullRefundOnly) && <Cell />}
                                <label
                                    htmlFor={item.ticketUuids[0].toString()}
                                    style={{ marginLeft: confirmRefundView ? '2.5em' : '' }}
                                    title={ticket.product}
                                >
                                    {ticket.product}
                                </label>
                            </Cell>
                            <Cell align="right" title={barcodesString}>
                                <span>{truncatedBarcodeString}</span>
                            </Cell>
                            <Cell align="right" className="overflow-allowed">
                                {!fullRefundOnly && (
                                    <AmountCell
                                        accountSlug={accountSlug}
                                        ticket={ticket}
                                        ticketEntities={item.ticketEntities}
                                        amount={item.price}
                                        status={item.status}
                                        cancellation={cancellation}
                                    />
                                )}
                                {fullRefundOnly && item.status !== 'not redeemed' && (
                                    <div className="status">{item.status}</div>
                                )}
                            </Cell>
                            <Cell />
                        </DataRow>
                    )
                })}
            </div>
        </div>
    )
}

export default RefundRow
