import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-typed'
import { StatsService } from 'http/statsService'
import { History } from 'history'
import { SecondaryText } from 'uiComponents/typography'
import { abbreviateNumber } from 'utils'
import {
    ConviousMetrics,
    CardList,
    ytdDataCardList,
    weekDataCardList,
    colorMap,
    departmentMap,
    Department,
    legend,
} from './schema'
import { ChartDataLoader } from 'uiComponents/loaders'
import { format, getISOWeek, startOfWeek, endOfWeek, addDays, isBefore } from 'date-fns'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { withMessages, MessageProps } from 'hocs'
import { Messages } from 'uiComponents/messages'
import { parseISODate } from 'utils/dates'

const Page = styled.div`
    color: ${(props) => props.theme.colors.textDark};
    font-family: ${(props) => props.theme.fonts.primary};
    font-size: 16px;
    margin: 0 auto;
`
const ContentWrapper = styled.div`
    position: relative;
    margin: 0 0.7em;
`
const DataCardsContainer = styled.div<{ width: number }>`
    font-size: 0.7em;
    @media screen and (min-width: 340px) {
        font-size: 0.83em;
    }
    @media screen and (min-width: 400px) {
        font-size: 1em;
    }
    @media screen and (min-width: 960px) {
        font-size: 1.15em;
    }
    max-width: ${(props) => props.width}px;

    &.legend {
        margin: 3em 0 5em 0;
        display: flex;
    }
`
const HeaderSection = styled.div<{ width: number }>`
    margin: 0 0.4em 1em 0.3em;
    position: fixed;
    top: 0;
    z-index: 1;
    background: ${(props) => props.theme.colors.background};
    width: ${(props) => props.width}px;
    max-width: 66.5em;
`
const NavigationContainer = styled.div`
    position: relative;
    font-size: 0.875em;
    margin-top: 0.5em;
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.textLight};
    text-align: center;
    line-height: 3em;
    border: 1px solid ${(props) => props.theme.colors.border};
`
const Arrow = styled.div<{ left?: boolean; disabled?: boolean }>`
    position: absolute;
    top: 0;
    ${(props) => (props.left ? 'left: 0' : 'right: 0')};
    height: 3em;
    width: 3.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${(props) => props.theme.colors.boyBlue};
    ${(props) => `border-${props.left ? 'right' : 'left'}`}: 1px solid ${(props) => props.theme.colors.border};
    ${(props) => (props.disabled ? 'visibility: hidden' : '')};
`
const BackLink = styled(SecondaryText)`
    color: ${(props) => props.theme.colors.boyBlue};
    display: block;
    padding-top: 0.7em;
    & span {
        cursor: pointer;
    }
`
const CardContainer = styled.div<{ color: string }>`
    display: inline-block;
    position: relative;
    background: ${(props) => props.theme.colors.white};
    border-radius: 4px;
    width: 6.5em;
    height: 6.5em;
    margin: 0 0.4em 1em 0.4em;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 0.3em;
        width: 100%;
        border-radius: 0 0 4px 4px;
        background: ${(props) => props.theme.colors.status[props.color]};
    }
`
const CardContent = styled.div`
    padding: 0.5em 0.5em 0.3em 0.5em;
`
const CardTitle = styled.div`
    font-size: 0.75em;
    height: 3em;
    margin-right: 0.5em;
`
const CardValue = styled.div`
    font-size: 1.5em;
    font-weight: bold;
`
const TargetSection = styled.div`
    font-size: 0.75em;
    display: flex;
    justify-content: space-between;
    margin: 0.5em 0;

    & span {
        font-size: 0.8em;
    }
`
const CornerRibon = styled.div<{ color: string }>`
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 1.5em solid ${(props) => props.color};
    border-left: 1.5em solid transparent;

    & span {
        font-size: 0.6em;
        position: absolute;
        top: -2.2em;
        right: 0.35em;
        color: ${(props) => props.theme.colors.white};
    }
`
const SectionsHeader = styled.div`
    font-size: 0.875em;
    color: ${(props) => props.theme.colors.textLight};
    margin: 1em 0.4em 0.7em 0.4em;

    &.top-section {
        padding-top: 8em;
        @media screen and (min-width: 400px) {
            padding-top: 5em;
        }
    }
`
const LegendRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.3em;
    margin-left: 0.4em;
`
const LegendColor = styled.div<{ color?: string; status?: string }>`
    height: 0.8em;
    width: 1.5em;
    margin-right: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => (props.status ? props.theme.colors.status[props.status] : props.color)};
`
const LegendName = styled.div`
    font-size: 0.8em;
`

interface InfoCardProps {
    name: string
    value: string | null
    target: string | null
    percent: string | null
    color: string
    department: Department | null
}

export function InfoCard(props: InfoCardProps) {
    return (
        <CardContainer color={props.color}>
            <CardContent>
                <CornerRibon color={props.department ? departmentMap[props.department].color : '#FFF'}>
                    <span>{props.department ? departmentMap[props.department].abbr : ''}</span>
                </CornerRibon>
                <CardTitle>{props.name}</CardTitle>
                <CardValue>{props.value ? props.value : '-'}</CardValue>
                <TargetSection>
                    <div>
                        {props.target ? (
                            <>
                                <span>&#8594;</span>
                                {props.target}
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                    <div>{props.percent ? `${props.percent}%` : '-'}</div>
                </TargetSection>
            </CardContent>
        </CardContainer>
    )
}

function formatValue(value: string) {
    return Number(value) >= 10000 ? abbreviateNumber(Number(value), 1) : value
}

function mapData(data: ConviousMetrics[], cardList: CardList[]) {
    return cardList.map((c) => {
        const metric = data.find((d) => d.name === c.dataName)
        if (metric) {
            const metricValue = metric.values.find((v) => v.name === 'actual_value')
            const metricTarget = metric.values.find((v) => v.name === 'target_value')
            const metricPercent = metric.values.find((v) => v.name === 'percentage')
            const metricColor = metric.values.find((v) => v.name === 'color')
            const targetUnits = c.units === '%' ? '%' : ''
            return {
                name: c.name,
                value: metricValue && metricValue.format ? formatValue(metricValue.format) + c.units : null,
                target: metricTarget && metricTarget.format ? formatValue(metricTarget.format) + targetUnits : null,
                percent: metricPercent ? metricPercent.format : null,
                color: metricColor && metricColor.format ? colorMap[metricColor.format] : 'disabled',
                department: c.department,
            }
        } else {
            return {
                name: c.name,
                value: null,
                target: null,
                percent: null,
                color: 'disabled',
                department: c.department,
            }
        }
    })
}

function getStartOfWeek(weekStart: string) {
    return weekStart ? weekStart : format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd')
}

interface ConviousMetricsProps {
    statsService: StatsService
    history: History
    navigation: Navigation
    match: RouteMatch<{}>
}

function ConviousMetricsPage(props: ConviousMetricsProps & MessageProps) {
    const cardRef = useRef(null)
    const [week, setWeek] = useState<number>(getISOWeek(new Date()))
    const [weekStart, setWeekStart] = useState<string>(getStartOfWeek(props.navigation.query().weekStart))
    const [weekCards, setWeekCards] = useState<InfoCardProps[]>([])
    const [ytdCards, setYTDCards] = useState<InfoCardProps[]>([])
    const [width, setWidth] = useState<number>(window.innerWidth)
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const weekEnd = format(endOfWeek(parseISODate(weekStart), { weekStartsOn: 1 }), 'yyyy-MM-dd')
                const metricsData = await props.statsService.getConviousMetrics(weekStart, weekEnd)
                const weekData = mapData(metricsData, weekDataCardList)
                const ytdData = mapData(metricsData, ytdDataCardList)
                setWeekCards(weekData)
                setYTDCards(ytdData)
            } catch {
                props.replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again.')
            } finally {
                setLoading(false)
            }
        }
        fetchData()
        setWeek(getISOWeek(parseISODate(weekStart)))
    }, [weekStart])

    const onBack = () => {
        props.history.push('/')
    }

    const disablePrev = isBefore(parseISODate(weekStart), new Date(2020, 0, 1))
    const disableNext = week === getISOWeek(new Date())

    const onNavigation = (direction: string) => {
        if ((direction === 'next' && disableNext) || (direction === 'prev' && disablePrev)) {
            return
        }
        const days = direction === 'next' ? 7 : -7
        const newWeekStart = format(addDays(parseISODate(weekStart), days), 'yyyy-MM-dd')
        setWeekStart(newWeekStart)
        props.navigation.addQueryWithReplace({ weekStart: newWeekStart })
    }

    const getWidth = () => {
        if (!!cardRef && !!cardRef.current) {
            let numberInRow = weekCards.length
            const windowWidth = window.innerWidth > 700 ? window.innerWidth - 40 : window.innerWidth - 50
            // @ts-ignore: Object is possibly 'null'.
            while (numberInRow * cardRef.current.clientWidth > windowWidth) {
                numberInRow = numberInRow < 7 ? numberInRow - 1 : Math.floor(numberInRow / 2)
            }
            // @ts-ignore: Object is possibly 'null'.
            setWidth(numberInRow * cardRef.current.clientWidth + 5)
        }
    }

    useEffect(() => {
        getWidth()
        window.addEventListener('resize', getWidth)
        return () => window.removeEventListener('resize', getWidth)
    }, [weekCards])

    return (
        <Page>
            {loading && <ChartDataLoader />}
            <Messages messages={props.messages} hideMessage={props.hideMessage} />
            {weekCards.length > 0 && (
                <ContentWrapper>
                    <HeaderSection width={width - 13}>
                        <BackLink>
                            <span onClick={onBack}>&#8592;&nbsp;Back</span>
                        </BackLink>
                        <NavigationContainer>
                            <Arrow onClick={() => onNavigation('prev')} disabled={disablePrev} left>
                                &#8592;
                            </Arrow>
                            Week {week}
                            <Arrow onClick={() => onNavigation('next')} disabled={disableNext}>
                                &#8594;
                            </Arrow>
                        </NavigationContainer>
                    </HeaderSection>
                    <DataCardsContainer width={width}>
                        <SectionsHeader className="top-section">Metrics for this week</SectionsHeader>
                        <div className="container">
                            {weekCards.map((c) => (
                                <div ref={cardRef} key={c.name} style={{ display: 'inline-block' }}>
                                    <InfoCard
                                        name={c.name}
                                        value={c.value}
                                        target={c.target}
                                        percent={c.percent}
                                        color={c.color}
                                        department={c.department}
                                    />
                                </div>
                            ))}
                        </div>
                    </DataCardsContainer>
                    <DataCardsContainer width={width}>
                        <SectionsHeader>Convious 2021 YTD</SectionsHeader>
                        {ytdCards.map((c) => (
                            <InfoCard
                                key={c.name}
                                name={c.name}
                                value={c.value}
                                target={c.target}
                                percent={c.percent}
                                color={c.color}
                                department={c.department}
                            />
                        ))}
                    </DataCardsContainer>
                    <DataCardsContainer width={width} className="legend">
                        <div style={{ marginRight: '2em' }}>
                            {legend.map((l) => (
                                <LegendRow key={l.text}>
                                    <LegendColor status={l.status} />
                                    <LegendName>{l.text}</LegendName>
                                </LegendRow>
                            ))}
                            <LegendRow>
                                <LegendColor>-</LegendColor>
                                <LegendName>No data available</LegendName>
                            </LegendRow>
                        </div>
                        <div>
                            {Object.keys(departmentMap).map((k) => (
                                <LegendRow key={k}>
                                    <LegendColor color={departmentMap[k].color} />
                                    <LegendName>{departmentMap[k].text}</LegendName>
                                </LegendRow>
                            ))}
                        </div>
                    </DataCardsContainer>
                </ContentWrapper>
            )}
        </Page>
    )
}

export default withNavigation(withMessages(ConviousMetricsPage))
