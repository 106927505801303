import { createSelector } from '@reduxjs/toolkit'
import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'store'
import styled from 'styled-typed'
import { CONVIOUS_INFO_COMPONENT_NAME } from 'uiComponents/appLayouts/infoNotificationHook'
import { ActionButtonExternalLink } from 'uiComponents/buttons'
import { Message } from 'uiComponents/messages'
import { removeMessage } from './actions'
import InlineMessage from './inlineMessage'

const InlineMessageLayout = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: end;
    width: 100%;

    @media (max-width: ${(props) => props.theme.breakPoints.md}) {
        flex-wrap: wrap;

        & ${ActionButtonExternalLink} {
            margin-top: 1rem;
        }
    }
`

const getInlineMessages = createSelector(
    (state: State) => state.messages.messages,
    (state: State, tag: string) => tag,
    (messages, tag) => {
        return messages.filter((message) => message.type === 'inline' && message.tag === tag)
    },
)

interface ConnectedInlineMessageProps {
    tag: 'EngageTools' | 'general'
    children?: FunctionComponent
    texts?: { [key: string]: FunctionComponent<Message> }
}

const ConnectedInlineMessage: React.FC<ConnectedInlineMessageProps> = ({ tag, children: Container, texts }) => {
    const messages = useSelector((state: State) => getInlineMessages(state, tag))
    const dispatch = useDispatch()
    const firstMessage = messages[0]

    if (firstMessage) {
        if (Container) {
            return (
                <Container>
                    <ConnectedInlineMessage tag={tag} texts={texts} />
                </Container>
            )
        }

        const text = texts?.[firstMessage.text] ? texts?.[firstMessage.text](firstMessage) : firstMessage.text
        const closeNotification = () => {
            dispatch(removeMessage(firstMessage.id))
            localStorage.setItem(CONVIOUS_INFO_COMPONENT_NAME, firstMessage.header || '')
        }

        return (
            <InlineMessage header={firstMessage.header} type={firstMessage.status} onClose={closeNotification}>
                <InlineMessageLayout>
                    {text}
                    {!!firstMessage.actionButton && (
                        <ActionButtonExternalLink
                            style={{
                                padding: '0.125rem 0.75rem',
                                marginRight: '2rem',
                                height: 'unset',
                                lineHeight: '1.25rem',
                                display: 'flex',
                                flexWrap: 'nowrap',
                                whiteSpace: 'nowrap',
                                marginLeft: '1rem',
                            }}
                            target="_blank"
                            size="small"
                            kind={firstMessage.actionButton?.kind}
                            href={firstMessage.actionButton?.href}
                            secondary={firstMessage.actionButton.secondary}
                            onClick={closeNotification}
                        >
                            {firstMessage.actionButton.buttonText}
                        </ActionButtonExternalLink>
                    )}
                </InlineMessageLayout>
            </InlineMessage>
        )
    }

    return null
}

export default ConnectedInlineMessage
