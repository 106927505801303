import { usePushNotificationsContext } from '../context/PushNotifications'
import { PNFrequency, PNScheduleType } from '../types'

const usePNClearForms = () => {
    const { setTargetForm, setContentForm, setScheduleForm, setCampaign } = usePushNotificationsContext()

    const clearForms = () => {
        // campaign
        setCampaign(undefined)

        // target
        setTargetForm({
            name: '',
            segment_id: '',
            location_id: '',
        })

        // content
        setContentForm({
            url: '',
            content: [
                {
                    locale: 'en',
                    title: '',
                    body: '',
                },
            ],
        })

        // schedule
        setScheduleForm({
            type: PNScheduleType.time,
            recurring: false,
            frequency: PNFrequency.once,
        })
    }

    return {
        clearForms,
    }
}

export default usePNClearForms
