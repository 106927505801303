import { isAfter, isBefore } from 'date-fns'
import { PlacementListItem } from 'tapPlacements/schema'

export const placementExpired = (endDate: null | number, compareDate: Date = new Date()): boolean => {
    if (endDate) {
        return isAfter(compareDate, new Date(endDate))
    } else {
        return false
    }
}

export const itemMatchesSearch = (item: PlacementListItem, search: string) => {
    return item.name.toLocaleLowerCase().includes(search)
}

export const itemMatchStatusFilter = (item: PlacementListItem, statuses: string[] = []) => {
    let matchesPublished = false
    let matchesExpired = false
    let showEmpty = statuses.length === 0

    statuses.forEach((status) => {
        switch (status) {
            case 'published':
                matchesPublished = item.active && !placementExpired(item.endDate)
                break
            case 'expired':
                matchesExpired = placementExpired(item.endDate)
                break
            default:
                break
        }
    })

    return matchesPublished || matchesExpired || showEmpty
}

export const itemMatchAudienceFilter = (item: PlacementListItem, audiences: string[] = []) => {
    const itemAudiences = item?.audiences
        ?.filter((audience) => audience.audience !== '')
        .map((audience) => audience.audience)
    if (itemAudiences && itemAudiences.length > 0 && audiences.length > 0) {
        return audiences.some((r) => itemAudiences.includes(r))
    } else {
        return true
    }
}

export const itemMatchesDateFilter = (item: PlacementListItem, dateRange: { from?: Date; to?: Date }) => {
    if (!dateRange.from || !dateRange.to) {
        return true
    }

    if (item.endDate) {
        return (
            isAfter(item.startDate, dateRange.from) &&
            isBefore(item.startDate, dateRange.to) &&
            isBefore(item.endDate, dateRange.to)
        )
    } else {
        return true
    }
}
