import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'
import styled from 'styled-typed'
import { Checkbox } from 'uiComponents/input'
import { IOption } from 'crm/audiences/types'

interface ElementProps {
    level: number
}

const Element = styled.div<ElementProps>`
    position: relative;
    padding: 1.2em 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    padding-left: 16px;
    background-color: ${(props) => props.theme.colors.aluminiumShades[props.level === 2 ? 5 : (props.level - 2) * 10]};
`

const Label = styled.div`
    font-size: 0.9em;
    padding-left: 0.6em;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 70px;
`

const IconWrapper = styled.div`
    height: 100%;
    width: 70px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Icon = styled(FontAwesomeIcon)`
    height: 0.9em;
    align-self: center;
`

interface productElementProps {
    product: IOption
    onChange: (value: IOption | null) => void
    selectedValues: string[]
    disabled?: boolean
}

const ProductElement = ({ product, onChange, selectedValues }: productElementProps) => {
    const isProduct = product.is_product

    const isChecked = useCallback(() => {
        return product.is_product && selectedValues.includes(product.value as string)
    }, [selectedValues, product])

    return (
        <div key={product.value as string} onClick={() => onChange(product)} style={{ cursor: 'pointer' }}>
            <Element level={1}>
                <Row>
                    {isProduct && <Checkbox checked={isChecked()} />}
                    <Label>{product.caption}</Label>
                </Row>
                {!isProduct ? (
                    <IconWrapper>
                        <Icon icon={faChevronRight} />
                    </IconWrapper>
                ) : null}
            </Element>
        </div>
    )
}

export default ProductElement
