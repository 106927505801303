import * as React from 'react'
import styled from 'styled-typed'
import { Card } from 'uiComponents/cards'
import { TargetType } from 'engageTools/studio/schema'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { ChartDataLoader } from 'uiComponents/loaders'
import { ProductName, ConfigurationVersions } from 'engageTools/studio/componentsService'
import { productTitles } from 'engageTools/studio/shared'
import { EngageToolStats } from 'engageTools/engageToolsService'
import StatsElement, { Container, DeviceTypeElement } from './statsElement'
import { BORDER_RADIUS } from 'engageTools/overview'
import { abbreviateNumber } from 'utils'

export const CampaignContainer = styled(Card)`
    border-radius: ${BORDER_RADIUS};
    padding: 0.5em 0.5em 0.5em 2.2em;
    position: relative;
    &.disabled {
        background-color: ${(props) => props.theme.colors.tableRow};
    }
`

export const Stats = styled.div`
    min-height: 4.8em;
    display: flex;
    justify-content: space-between;
    ${Container}:last-child {
        border-right: none;
    }
`

interface StatsContainerProps {
    product: ProductName
    data: EngageToolStats[] | null
    versions: ConfigurationVersions
    activeAccount: string
    loading: boolean
    getCurrency: (accountSlug: string) => Currency
    disabled?: boolean
}

class StatsContainer extends React.Component<StatsContainerProps> {
    render() {
        const { getCurrency, loading, product, versions, disabled } = this.props
        const deviceType: TargetType = !!versions.deviceType ? versions.deviceType : 'any'
        const productTitle = productTitles[product]
        const currency = getCurrency(this.props.activeAccount)
        const hideData = !this.props.data || (this.props.data && this.props.data.length === 0)
        const data = this.props.data && this.props.data.length > 0 ? this.props.data[0].values : []
        let users: number | string = 0
        let sessions: number | string = 0
        let widgetviews: number | string = 0
        let ctr: number | string = 0
        let conversionRate: number | string = 0
        let revenue: number | string = 0
        if (data.length > 0) {
            users = data.filter((e) => e.name === 'users')[0].value
            sessions = data.filter((e) => e.name === 'sessions')[0].value
            widgetviews = data.filter((e) => e.name === 'widgetviews')[0].value
            const ctrData = data.filter((e) => e.name === 'ctr')[0].value
            ctr = Math.round(ctrData * 100 * 100) / 100 || 0
            const conversion = data.length > 0 ? data.filter((e) => e.name === 'conversion')[0].value : 0
            conversionRate = Math.round(conversion * 100 * 100) / 100 || 0
            revenue = data.filter((e) => e.name === 'revenue')[0].value
        }
        if (disabled) {
            users = users || '-'
            sessions = sessions || '-'
            widgetviews = widgetviews || '-'
            ctr = ctr || '-'
            conversionRate = conversionRate || '-'
            revenue = revenue || '-'
        }

        const formatedRevenue = isNaN(Number(revenue))
            ? revenue
            : Number(revenue) >= 10000
            ? abbreviateNumber(Number(revenue), 1)
            : Number(revenue).toFixed(2)

        return (
            <CampaignContainer className={disabled ? 'disabled' : ''}>
                {(loading || !this.props.data) && <ChartDataLoader topOffset="0" />}
                <Stats>
                    <DeviceTypeElement device={deviceType} name={versions?.name} />
                    <StatsElement
                        title="Users"
                        data={users}
                        hideData={hideData}
                        infotipText={`Unique users, identified as cookies who were exposed to ${productTitle}.`}
                    />
                    <StatsElement
                        title="Sessions"
                        data={sessions}
                        hideData={hideData}
                        infotipMaxWidth="30em"
                        infotipText="A session is defined as a group of interactions one user takes within a given time frame on your website.
              Convious defaults that time frame to 30 minutes. Meaning whatever a user does on your website (e.g. browses pages,
              visit checkout, purchases tickets) before they leave equals one session."
                    />
                    <StatsElement
                        title="Widgetviews"
                        data={widgetviews}
                        hideData={hideData}
                        infotipMaxWidth="24em"
                        infotipText={`The number of times a user was exposed to or interacted with the ${productTitle}.`}
                    />
                    <StatsElement
                        title="CTR"
                        data={ctr}
                        qualifier={ctr === '-' ? undefined : '%'}
                        hideData={hideData}
                        infotipPosition="right"
                        infotipMaxWidth="24em"
                        infotipText={`The percentage of sessions where visitors clicked on the
              ${productTitle}'s Call-to-Action button.`}
                    />
                    <StatsElement
                        title="Conversion"
                        data={conversionRate}
                        qualifier={conversionRate === '-' ? undefined : '%'}
                        hideData={hideData}
                        infotipMaxWidth="30em"
                        infotipPosition="right"
                        infotipText={`The percentage of sessions with completed (paid) purchase which can be attributed to
              the ${productTitle} (using the last-touch attribution model).`}
                    />
                    <StatsElement
                        title="Revenue"
                        data={formatedRevenue}
                        qualifier={
                            revenue === '-'
                                ? undefined
                                : currency.position === 'BEFORE'
                                ? `${currency.symbol} `
                                : ` ${currency.symbol}`
                        }
                        qualifierInFront={currency.position === 'BEFORE'}
                        hideData={hideData}
                        infotipMaxWidth="30em"
                        infotipPosition="right"
                        infotipText={`The total collected revenue is attributed to the ${productTitle} using the last-touch attribution model.`}
                    />
                </Stats>
            </CampaignContainer>
        )
    }
}

export default withCurrency(StatsContainer)
