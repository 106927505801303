import React from 'react'
import { ComboboxWithParentStyling } from 'uiComponents/input/combobox'
import { useGetAllCategoriesForSelect } from 'products/redux'

interface CategoriesSelectionProps {
    open: boolean
    accountSlug: string
    onOutsideClick: () => void
    setProductCategories: (ids: string[]) => void
    selectedCategories: string[]
}

export function CategoriesSelection(props: CategoriesSelectionProps) {
    const categories = useGetAllCategoriesForSelect()

    return (
        <ComboboxWithParentStyling
            label="Product categories"
            name="category_select"
            options={categories}
            excludeParentsFromValue
            nested
            multiple
            value={props.selectedCategories}
            onChange={props.setProductCategories}
        />
    )
}
