import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faImage } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { useParkMapViewerContext } from '../../context/mapViewer'
import { Toggler } from './typeControl'

const Container = styled.div`
    position: absolute;
    top: 14px;
    left: 94px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`

const SwitchInactive = styled(Toggler)`
    background: ${(props) => props.theme.colors.textDark};
    fill: ${(props) => props.theme.colors.white};
`

const SwitchActive = styled(SwitchInactive)`
    background: ${(props) => props.theme.colors.white};
    fill: ${(props) => props.theme.colors.textDark};
`

const OverlaySwitch = () => {
    const theme = useTheme()
    const { overlayIsVisible, setOverlayVisibility } = useParkMapViewerContext()

    const Switch = overlayIsVisible ? SwitchActive : SwitchInactive

    return (
        <Container>
            <Switch
                onClick={() => {
                    setOverlayVisibility(!overlayIsVisible)
                }}
            >
                <FontAwesomeIcon
                    icon={faImage as IconProp}
                    color={theme.colors[overlayIsVisible ? 'textDark' : 'white']}
                    size="sm"
                />
            </Switch>
        </Container>
    )
}

export default OverlaySwitch
