import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AppServices } from 'middleware'
import { replaceMessage } from 'uiComponents/messages/actions'
import { ICustomAudience } from './types'

export const audienceApi = createApi({
    reducerPath: 'audienceApi',
    tagTypes: ['filters', 'audience', 'audienceList'],
    baseQuery: fetchBaseQuery({}),
    endpoints: (builder) => ({}),
})

const extendedAudienceApi = audienceApi.injectEndpoints({
    endpoints: (builder) => ({
        listAudience: builder.query({
            providesTags: ['audienceList'],
            queryFn: async ({ slug }: { slug: string }, api) => {
                try {
                    const data = await AppServices.audienceService.getAudiences(slug)

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('audienceList', 'error', 'Failed to get audience list', 5000))

                    return { error }
                }
            },
        }),
        getFilters: builder.query({
            providesTags: ['filters'],
            queryFn: async ({ slug }: { slug: string }, api) => {
                try {
                    const data = await AppServices.audienceService.getFilters(slug)

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('audience', 'error', 'Failed to get audience filters', 5000))

                    return { error }
                }
            },
        }),
        getAudience: builder.query({
            providesTags: ['audience'],
            queryFn: async ({ id, slug }: { id: string; slug: string }, api) => {
                try {
                    const data = await AppServices.audienceService.getAudience(id, slug)

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('audience', 'error', 'Failed to get audience', 5000))

                    return { error }
                }
            },
        }),
        createAudience: builder.mutation({
            invalidatesTags: ['audienceList'],
            queryFn: async ({ audience }: { audience: ICustomAudience }, api) => {
                try {
                    const data = await AppServices.audienceService.createAudience(audience)

                    api.dispatch(
                        extendedAudienceApi.util.updateQueryData(
                            'listAudience',
                            { slug: audience.account_slug },
                            (draft) => {
                                draft?.push(data)
                                return draft
                            },
                        ),
                    )

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        updateAudience: builder.mutation({
            invalidatesTags: ['audience'],
            queryFn: async ({ audience }: { audience: ICustomAudience }, api) => {
                try {
                    const data = await AppServices.audienceService.updateAudience(audience)

                    api.dispatch(
                        extendedAudienceApi.util.updateQueryData(
                            'listAudience',
                            { slug: audience.account_slug },
                            (draft) => {
                                return draft?.map((item) => {
                                    if (item.id === audience.id) {
                                        return data
                                    }
                                    return item
                                })
                            },
                        ),
                    )

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('audience', 'error', 'Failed to update audience', 5000))

                    return { error }
                }
            },
        }),
        deleteAudience: builder.mutation({
            invalidatesTags: ['audienceList'],
            queryFn: async ({ id, slug }: { id: number; slug: string }, api) => {
                try {
                    const data = await AppServices.audienceService.deleteAudience(id, slug)

                    api.dispatch(
                        extendedAudienceApi.util.updateQueryData('listAudience', { slug: slug }, (draft) => {
                            return draft?.filter((item) => item.id !== id)
                        }),
                    )

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('audience', 'error', 'Failed to delete audience', 5000))

                    return { error }
                }
            },
        }),
        getExportAudienceEndpoint: builder.query({
            queryFn: async ({ id, slug }: { id: number; slug: string }, api) => {
                try {
                    const data = await AppServices.audienceService.getExportAudienceEndpoint(id, slug)

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('audience', 'error', 'Failed to get audience export endpoint', 5000))

                    return { error }
                }
            },
        }),
    }),
})

export const { reducer, reducerPath } = audienceApi
export const {
    useListAudienceQuery,
    useGetFiltersQuery,
    useGetAudienceQuery,
    useDeleteAudienceMutation,
    useCreateAudienceMutation,
    useUpdateAudienceMutation,
    useLazyGetExportAudienceEndpointQuery,
    endpoints,
} = extendedAudienceApi
