import { rest } from 'msw'
import { delayedResponse } from './delayed-response'

export const handlers = [
    // Examples of mocked responses
    // Handles only work in development mode
    rest.get('/msw_test', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                message: 'Hello World',
            }),
        )
    }),

    rest.post('/msw_test_delay', (req, res, ctx) => {
        return delayedResponse(
            ctx.status(500),
            ctx.json({
                message: 'Error after delay',
            }),
        )
    }),
    rest.post('https://example.com/msw_test_network_error', (req, res, ctx) => {
        return res.networkError('Failed to connect')
    }),
]
