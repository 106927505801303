import React, { useContext } from 'react'
import styled from 'styled-typed'
import { MessageKind } from 'uiComponents/messages'
import { CopyEditorServiceContext } from 'copyEditor/context'
import { CopyDetails, dummyCopyDetails } from 'copyEditor/copyEditorService'
import { transparency } from 'utils/css'
import { ConfigField } from './schema'
import { PageLoader, ChartLoaderBackground } from 'uiComponents/loaders'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Account } from 'auth/state'
import conviousLogo from 'images/convious-logo-white.svg'
import Svg from 'svg'
import { rtlLanguages } from 'utils/languages'

const Form = styled.div`
    position: relative;
    min-width: 26.25em;
    max-width: 26.25em;
    text-align: center;
    align-self: center;
    margin-top: 2em;
    background: #e5ebed;
    height: 90%;
`
const OverlayTextBox = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid ${(p) => p.theme.colors.textLight};
    border-radius: 4px;
    color: ${(p) => p.theme.colors.textLight};
    height: 6em;
    width: 15em;
    display: flex;
    align-items: center;
`
const LogoWrapper = styled.div<{ color: string }>`
    position: relative;
    height: 1.875em;
    background: ${(p) => p.color};
`
const LogoWrapperOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${(p) => transparency(p.theme.colors.richBlack, 0.1)};
`
const LogoBox = styled.div`
    height: 1.875em;
    position: absolute;
    top: 0;
    width: 100%;
    transform: scale(0.5);
`
const Logo = styled.img`
    margin: 0px auto;
    display: inline-block;
    max-height: 100%;
    max-width: 100%;
    width: auto;
`
const ScrollContainer = styled.div`
    height: 90%;
    overflow: auto;
`
const Header = styled.div<{ color: string }>`
    background: ${(p) => p.color};
    min-height: 6.875em;
    width: 100%;
`
const TextWrapper = styled.div`
    padding: 1.25em 0.625em;
`
const HeaderTitle = styled.div`
    font-size: 1.5625em;
    line-height: 1em;
    font-weight: bold;
    margin-bottom: 0.2em;
    color: ${(p) => p.theme.colors.white};
`
const HeaderSubtitle = styled.div`
    line-height: 1.25em;
    color: ${(p) => p.theme.colors.white};
`
const FieldsContainer = styled.div`
    padding: 0.625em 1.5625em;
`
const FieldWrapper = styled.div`
    margin-bottom: 1.25em;
    text-align: left;

    &.rtl {
        text-align: right;
    }
`
const FieldName = styled.div`
    font-size: 1.125em;
    line-height: 1.16em;
    font-weight: 500;
    margin-bottom: 0.28em;

    span {
        font-size: 0.833em;
        margin-left: 0.3em;
    }
`
const FieldBox = styled.div`
    background: ${(p) => p.theme.colors.white};
    border: 1px solid rgba(128, 136, 137, 0.5);
    box-shadow: rgb(0 0 0 / 20%) 0px 1px 10px 0px;
    border-radius: 6px;
    padding: 0 0.67em;
    display: flex;
    align-items: center;
    min-height: 2.6875em;
    &.choice-field {
        padding-right: 2em;
    }
`
const ChevronWrapper = styled.div<{ color: string }>`
    font-size: 0.7em;
    border-radius: 0 6px 6px 0;
    background: ${(p) => p.color};
    width: 2em;
    height: 100%;
    color: ${(p) => p.theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
`
const FieldCheckBox = styled.div`
    background: ${(p) => p.theme.colors.white};
    box-shadow: rgb(0 0 0 / 20%) 0px 1px 10px 0px;
    border-radius: 6px;
    height: 1.5em;
    width: 1.5em;
    margin-right: 0.7em;
`
const CheckBoxWrapper = styled.div`
    display: flex;
    align-items: center;
`
const FieldPlaceholder = styled.div`
    color: #26323a;
    font-size: 0.875em;
    font-weight: 400;
    letter-spacing: 0.25px;
    width: 100%;
`

interface PreviewProps {
    accountSlug: string
    accounts: Account[]
    activeLanguage: string
    fieldsConfig: ConfigField[]
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

function APFPreview(props: PreviewProps) {
    const copyEditorService = useContext(CopyEditorServiceContext)
    const [logo, setLogo] = React.useState<string>('')
    const [brandColor, setBrandColor] = React.useState<string>('')
    const [titleCopy, setTitleCopy] = React.useState<CopyDetails>(dummyCopyDetails)
    const [subtitleCopy, setSubitleCopy] = React.useState<CopyDetails>(dummyCopyDetails)
    const [loading, setLoading] = React.useState<boolean>(false)

    async function getData() {
        try {
            setLoading(true)
            const account = props.accounts.find((a) => a.slug === props.accountSlug)
            setLogo(account?.style?.logoUrl || '')
            setBrandColor(account?.style?.brandColor || '#FF2E60')
            const titleCopyDetails = await copyEditorService.getCopyDetails(
                props.accountSlug,
                'after_payment_form.title',
            )
            const subtitleCopyDetails = await copyEditorService.getCopyDetails(
                props.accountSlug,
                'after_payment_form.subtitle',
            )
            setTitleCopy(titleCopyDetails)
            setSubitleCopy(subtitleCopyDetails)
        } catch {
            props.replaceMessages(
                'unknown_error',
                'error',
                'Oops! We could no load the preview. Please try again later.',
            )
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        getData()
    }, [])

    const getCopy = (copy: CopyDetails) => {
        return (
            copy.custom.find((c) => c.locale === props.activeLanguage)?.text ||
            copy.default.find((c) => c.locale === props.activeLanguage)?.text
        )
    }

    const rtlLanguage = rtlLanguages.includes(props.activeLanguage)

    return (
        <>
            {loading && <PageLoader />}
            {!loading && (
                <Form>
                    {props.fieldsConfig.length === 0 && (
                        <ChartLoaderBackground>
                            <OverlayTextBox>Toggled On fields will be previewed here</OverlayTextBox>
                        </ChartLoaderBackground>
                    )}
                    <LogoWrapper color={brandColor}>
                        <LogoWrapperOverlay />
                        <LogoBox>
                            {!!logo && <Logo src={logo} />}
                            {!logo && <Svg src={conviousLogo} style={{ width: '12em', marginTop: '0.2em' }} />}
                        </LogoBox>
                    </LogoWrapper>
                    <ScrollContainer>
                        <Header color={brandColor}>
                            <TextWrapper>
                                <HeaderTitle>{getCopy(titleCopy)}</HeaderTitle>
                                <HeaderSubtitle>{getCopy(subtitleCopy)}</HeaderSubtitle>
                            </TextWrapper>
                        </Header>
                        <FieldsContainer>
                            {props.fieldsConfig
                                .filter((f) => f.buyer)
                                .map((f, i) => (
                                    <PreviewField
                                        key={f.slug + i}
                                        activeLanguage={props.activeLanguage}
                                        field={f}
                                        brandColor={brandColor}
                                        rtlLanguage={rtlLanguage}
                                    />
                                ))}
                            {props.fieldsConfig
                                .filter((f) => !f.buyer)
                                .map((f, i) => (
                                    <PreviewField
                                        key={i + f.slug}
                                        activeLanguage={props.activeLanguage}
                                        field={f}
                                        brandColor={brandColor}
                                        ticketHolder
                                        rtlLanguage={rtlLanguage}
                                    />
                                ))}
                        </FieldsContainer>
                    </ScrollContainer>
                </Form>
            )}
        </>
    )
}

export default APFPreview

interface PreviewFieldProps {
    field: ConfigField
    activeLanguage: string
    brandColor: string
    rtlLanguage: boolean
    ticketHolder?: boolean
}

function PreviewField(props: PreviewFieldProps) {
    return (
        <FieldWrapper className={props.rtlLanguage ? 'rtl' : ''}>
            <FieldName>
                {props.rtlLanguage && !!props.ticketHolder && <span>#1 [Product name] </span>}
                {props.field.translations[props.activeLanguage].label || '[Translation missing]'}
                {!props.rtlLanguage && !!props.ticketHolder && <span>[Product name] #1</span>}
            </FieldName>
            {props.field.type !== 'bool' && props.field.type !== 'choice' && (
                <FieldBox>
                    <FieldPlaceholder>{props.field.translations[props.activeLanguage].placeholder}</FieldPlaceholder>
                </FieldBox>
            )}
            {props.field.type === 'choice' && (
                <div style={{ position: 'relative' }}>
                    <FieldBox className="choice-field">
                        <FieldPlaceholder>
                            {props.field.translations[props.activeLanguage].placeholder}
                        </FieldPlaceholder>
                    </FieldBox>
                    <ChevronWrapper color={props.brandColor}>
                        <FontAwesomeIcon icon={faChevronDown} />
                    </ChevronWrapper>
                </div>
            )}
            {props.field.type === 'bool' && (
                <CheckBoxWrapper>
                    <FieldCheckBox />
                    <FieldPlaceholder>{props.field.translations[props.activeLanguage].placeholder}</FieldPlaceholder>
                </CheckBoxWrapper>
            )}
        </FieldWrapper>
    )
}
