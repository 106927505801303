import * as React from 'react'
import styled, { keyframes } from 'styled-typed'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'

export const CONTAINER_BOX_SHADOW = '0 5px 10px 0 rgba(57,73,84,0.15)'

const FadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

export const Section = styled.div`
  flex: 1;
  animation: ${FadeIn} .5s ease-in};
  animation-fill-mode: forwards;
`
export const SectionForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    transition: height 0.4s ease-in;
`
export const InputColumn = styled.div`
    flex: 1;
    &:not(:first-child) {
        margin-left: 3.5em;
    }
`
export const InputRow = styled.div`
    display: flex;
`

interface PageActionsProps {
    align?: 'right' | 'left'
}

export const PageActions = styled.div<PageActionsProps>`
    align-self: ${(p) => (p.align === 'left' ? 'flex-start' : 'flex-end')};
    justify-content: ${(p) => (p.align === 'left' ? 'flex-start' : 'flex-end')};
    display: flex;
    padding: 1.5rem 0 1.5rem 1.5rem;
    float: ${(p) => (p.align === 'left' ? 'left' : 'right')};
    & > button {
        ${(p) => (p.align === 'left' ? 'margin-right: 2rem;' : 'margin-left: 2rem;')}
    }
`

export const TopButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.75rem;
`

export const Container = styled.div`
    position: relative;
    margin-bottom: 15em;
    background: ${(props) => props.theme.colors.white};
    border-radius: 0.625em 0.625em;
    box-shadow: ${CONTAINER_BOX_SHADOW};
    opacity: 1;
`
export const ContainerHeading = styled.div`
    background-color: ${(props) => props.theme.colors.aluminium};
    color: ${(props) => props.theme.colors.white};
    border-radius: 0.625em 0.625em 0 0;
    height: 2.5rem;
`

export const ContainerBody = styled.div`
    max-width: 75rem;
    display: flex;
    padding: 1.5rem;
    > div {
        width: 100%;
    }
`

export const ProductContainerBody = styled.div`
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
`

export const FullWidthContainerBody = styled.div`
    max-width: 75rem;
    display: flex;
    flex-direction: column;
`

const HeadingSectionNameStyle = styled.div`
    font-size: 0.875em;
    font-weight: 400;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    padding: 0.406em 1em;
    margin-left: 1em;
    height: 100%;
    color: ${(props) => props.theme.colors.aluminiumShades[5]};
    transition: all ease-in-out 0.2s;

    &.active {
        font-weight: 500;
        border-bottom: 4px solid ${(props) => props.theme.colors.boyBlue};
    }

    &:hover {
        color: ${(props) => props.theme.colors.border};
        background-color: rgba(255, 255, 255, 0.05);
        border-bottom: 4px solid ${(props) => props.theme.colors.boyBlueShades[60]};
    }
`

interface PersonalSectionStyleProps {
    confirmed: boolean
}
export const EmailConfirmationMessage = styled.div<PersonalSectionStyleProps>`
    position: relative;
    font-size: 0.625em;
    font-style: italic;
    width: ${(props) => (props.confirmed ? '5em' : '11em')};
    margin-top: -0.2em;
    float: right;
    color: ${(props) => (props.confirmed ? props.theme.colors.status.success : props.theme.colors.status.error)};
`
export const EmailResendMessage = styled.div`
    position: absolute;
    right: 0.2em;
    top: 0;
    text-decoration: underline;
    font-style: normal;
    cursor: pointer;
    color: ${(props) => props.theme.colors.textDark};
    opacity: 0;
    transition: opacity 0.3s ease-in;

    &.visible {
        opacity: 1;
    }
    &.done {
        cursor: auto;
        text-decoration: none;
    }
`

const HeadingTag = styled(UnstyledLink)`
    line-height: 2em;
`

interface HeadingSectionNameProps {
    to: string
    children: string | JSX.Element
    id?: string
    className?: string
    style?: React.CSSProperties
    disabled?: boolean
    onClick?: () => void
}

export function HeadingSectionName(props: HeadingSectionNameProps) {
    return (
        <HeadingSectionNameStyle className={props.className} style={props.style}>
            <HeadingTag block to={props.to} onClick={props.onClick} id={props.id} disabled={props.disabled}>
                {props.children}
            </HeadingTag>
        </HeadingSectionNameStyle>
    )
}
