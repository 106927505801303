import * as React from 'react'
import styled from 'styled-typed'
import { Card } from 'uiComponents/cards'
import { Row, Column } from 'uiComponents/pageElements'
import { Recurrence } from 'products/articleConfigurationService'
import { recurrenceOptions } from './exceptionDayTimeFormItem'
import { ArticleFromApi } from '../../types'
import { getProductName } from '../../productTable/utils'
import { useAppSelector } from 'store/hooks'
import { getIsUSPartner } from 'auth/selectors'

interface ExceptionSummaryProps {
    name: string
    products: ArticleFromApi[]
    from: string | null
    to: string | null
    weekdays: string | null
    recurrence: Recurrence | null
}

const Container = styled(Card)`
    font-size: 0.8em;
    margin-bottom: 3em;
`

const Body = styled.div`
    display: flex;
    padding: 1.3em 0 2em 1em;
`

const LabelAndValue = styled.div`
    display: flex;
    margin-bottom: 0.5em;
`

const Light = styled.span`
    font-weight: lighter;
`

const Bold = styled.span`
    font-weight: bold;
    margin-left: 0.6em;
`

const Bold500 = styled(Bold)`
    font-weight: 500;
`

const Title = styled.span`
    color: ${(props) => props.theme.colors.textDark};
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-size: 0.61rem;
    font-weight: lighter;
`

const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

function mapToWeekdayNames(indexes: string | null, isUSPartner: boolean): string {
    const daysOrder = isUSPartner ? [6, 0, 1, 2, 3, 4, 5] : [0, 1, 2, 3, 4, 5, 6]

    if (!indexes || indexes === '') {
        return daysOrder.map((i) => weekdays[i]).join(', ')
    }

    const indexesArray = indexes.split(',').map((i) => parseInt(i, 10))
    const result = []
    for (const i of daysOrder) {
        if (indexesArray.includes(i)) {
            result.push(weekdays[i])
        }
    }
    return result.join(', ')
}

function getPatternName(recurrence: Recurrence) {
    const option = recurrenceOptions.find((ro) => ro.value === recurrence)
    return option ? option.name : ''
}

export function ExceptionSummary(props: ExceptionSummaryProps) {
    const isUSPartner = useAppSelector(getIsUSPartner)
    const productNames = props.products.map((a) => getProductName(a.name)).join(', ')
    const notAvailableDays = mapToWeekdayNames(props.weekdays, isUSPartner)
    const pattern = props.recurrence ? getPatternName(props.recurrence) : ''
    return (
        <Container>
            <Row>
                <Title>Exception summary</Title>
            </Row>
            <Body>
                <Column>
                    <LabelAndValue>
                        <Light>Exception name:</Light>
                        <Bold500>{props.name}</Bold500>
                    </LabelAndValue>
                    <LabelAndValue>
                        <Light>Products:</Light>
                        <Bold>{productNames}</Bold>
                    </LabelAndValue>
                </Column>
                <Column>
                    <LabelAndValue>
                        <Light>Dates:</Light>
                        <Bold>
                            {props.from} {(!!props.from || !!props.to) && <Light>to</Light>} {props.to}
                        </Bold>
                    </LabelAndValue>
                    <LabelAndValue>
                        <Light>Not available on:</Light>
                        <Bold>{notAvailableDays}</Bold>
                    </LabelAndValue>
                    <LabelAndValue>
                        <Light>Pattern:</Light>
                        <Bold>{pattern}</Bold>
                    </LabelAndValue>
                </Column>
            </Body>
        </Container>
    )
}
