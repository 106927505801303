import { AvailableFilters } from 'uiComponents/filter/schema'
import {
    clearDayIcon,
    partlyCloudyIcon,
    cloudyIcon,
    windIcon,
    fogIcon,
    rainIcon,
    sleetIcon,
    snowIcon,
} from 'reports/weatherIcons'

export const AVAILABLE_FILTERS: AvailableFilters = {
    customers: [
        'products',
        'location_id',
        'language',
        'capacity_type',
        'time_slots',
        'availability_pool_uuid',
        'product_types',
        'channels',
    ],
    sales: [
        'products',
        'device_type',
        'location_id',
        'website_traffic_acquisition_channel',
        'checkout_acquisition_channel_category',
        'language',
        'payment_method',
        'confirmation_flow',
        'channels',
    ],
    traffic: ['device_type', 'website_traffic_acquisition_channel'],
    barcodes: ['products', 'product_types', 'location_id'],
    pricing: ['product_types', 'products'],
    notification_campaigns: [
        'tap_notifications_campaign_name',
        'tap_platform',
        'tap_notifications_category',
        'tap_notifications_target_type',
        'tap_notifications_schedule_type',
    ],
    crm: ['audience', 'marketing_permission', 'purchases', 'preferred_device_type', 'email_identified'],
    checkout_funnel: ['product_lists'],
    checkout_funnel_screen_overview: ['product_lists_with_screens'],
}

export const STANDALONE_FILTERS: AvailableFilters = {
    dashboard: ['device_type', 'location_id', 'website_traffic_acquisition_channel'],
    customers: ['product_types', 'products', 'location_id', 'language', 'channels'],
    emails: [],
    revenue: ['products'],
    sales: ['products', 'confirmation_flow', 'checkout_acquisition_channel_category'],
    traffic: ['device_type', 'website_traffic_acquisition_channel'],
    barcodes: ['products', 'product_types', 'location_id'],
    pricing: ['product_types', 'products'],
    notification_campaigns: [
        'tap_notifications_campaign_name',
        'tap_platform',
        'tap_notifications_category',
        'tap_notifications_target_type',
        'tap_notifications_schedule_type',
    ],
    crm: ['audience', 'marketing_permission', 'purchases'],
    checkout_funnel: ['product_lists'],
    checkout_funnel_screen_overview: ['product_lists_with_screens'],
}

// not all are the same as AVAILABLE_FILTERS (e.g. customers), therefore a separate config
export const METADATA_CATEGORIES: AvailableFilters = {
    customers: ['products', 'location_id', 'language', 'product_types', 'channels'],
    sales: [
        'products',
        'device_type',
        'location_id',
        'website_traffic_acquisition_channel',
        'checkout_acquisition_channel_category',
        'language',
        'payment_method',
        'confirmation_flow',
        'channels',
    ],
    traffic: ['device_type', 'website_traffic_acquisition_channel'],
    barcodes: ['products', 'product_types', 'location_id'],
    pricing: ['product_types', 'products'],
    notification_campaigns: [
        'tap_notifications_campaign_name',
        'tap_platform',
        'tap_notifications_category',
        'tap_notifications_target_type',
        'tap_notifications_schedule_type',
    ],
    crm: ['audience', 'preferred_device_type', 'email_identified', 'marketing_permission', 'purchases'],
    checkout_funnel: ['product_lists'],
    checkout_funnel_screen_overview: ['product_lists_with_screens'],
}

export const weatherIconMap = {
    fog: fogIcon,
    snow: snowIcon,
    sleet: sleetIcon,
    rain: rainIcon,
    'partly-cloudy-day': partlyCloudyIcon,
    wind: windIcon,
    cloudy: cloudyIcon,
    'clear-day': clearDayIcon,
}
