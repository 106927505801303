import { getIsUSPartner } from 'auth/selectors'
import React, { useCallback, useEffect, useState } from 'react'
import { useAppSelector } from 'store/hooks'
import styled, { css, FlattenInterpolation } from 'styled-typed'
import { transparency } from 'utils/css'

export type PatternType = 'default' | 'strict'

interface WrapperProps {
    large?: boolean
}

interface PatternBarProps {
    type?: PatternType
    invert?: boolean
}

const Wrapper = styled.div<WrapperProps>`
    display: flex;
    justify-content: ${(props) => (props.large ? 'space-between' : 'space-around')};
    height: 3em;
    width: ${(props) => (props.large ? '100%' : '7em')};
`

interface Day {
    index: number
    letter: string
}

const WEEKDAYS = [
    { index: 0, letter: 'M' },
    { index: 1, letter: 'T' },
    { index: 2, letter: 'W' },
    { index: 3, letter: 'T' },
    { index: 4, letter: 'F' },
    { index: 5, letter: 'S' },
    { index: 6, letter: 'S' },
]

const US_WEEKDAYS = [
    { index: 6, letter: 'S' },
    { index: 0, letter: 'M' },
    { index: 1, letter: 'T' },
    { index: 2, letter: 'W' },
    { index: 3, letter: 'T' },
    { index: 4, letter: 'F' },
    { index: 5, letter: 'S' },
]

const barDefaultCss = css`
    background-color: ${(props) => props.theme.colors.tableRow};
    color: ${(props) => props.theme.colors.textLight};
    &.active {
        background-color: ${(props) => props.theme.colors.textLight};
        color: ${(props) => props.theme.colors.textDark};
    }
`
const barStrictCss = css`
    background-color: ${(props) => transparency(props.theme.colors.status.error, 0.2)};
    color: ${(props) => props.theme.colors.status.error};
    &.active {
        background-color: ${(props) => transparency(props.theme.colors.status.success, 0.15)};
        color: ${(props) => props.theme.colors.status.success};
    }
`

const invertBarStrictCss = css`
    background-color: ${(props) => transparency(props.theme.colors.status.success, 0.15)};
    color: ${(props) => props.theme.colors.status.success};
    &.active {
        background-color: ${(props) => transparency(props.theme.colors.status.error, 0.2)};
        color: ${(props) => props.theme.colors.status.error};
    }
`

const boxDefaultCss = css`
    background-color: ${(props) => props.theme.colors.tableRow};
    color: ${(props) => props.theme.colors.textLight};
    &:hover {
        background-color: ${(props) => props.theme.colors.boyBlue};
        color: ${(props) => props.theme.colors.white};
    }
    &.active {
        background-color: ${(props) => props.theme.colors.boyBlue};
        color: ${(props) => props.theme.colors.white};
        &:hover {
            background-color: ${(props) => props.theme.colors.boyBlue};
            opacity: 0.75;
        }
    }
`

const boxStrictCss = css`
    background-color: ${(props) => transparency(props.theme.colors.status.error, 0.2)};
    color: ${(props) => props.theme.colors.status.error};
    &:hover {
        background-color: ${(props) => transparency(props.theme.colors.status.success, 0.15)};
        color: ${(props) => props.theme.colors.status.success};
    }
    &.active {
        background-color: ${(props) => transparency(props.theme.colors.status.success, 0.15)};
        color: ${(props) => props.theme.colors.status.success};
        &:hover {
            background-color: ${(props) => transparency(props.theme.colors.status.error, 0.2)};
            color: ${(props) => props.theme.colors.status.error};
        }
    }
`

const invertBoxStrictCss = css`
    background-color: ${(props) => transparency(props.theme.colors.status.success, 0.15)};
    color: ${(props) => props.theme.colors.status.success};
    &:hover {
        background-color: ${(props) => transparency(props.theme.colors.status.error, 0.2)};
        color: ${(props) => props.theme.colors.status.error};
    }
    &.active {
        background-color: ${(props) => transparency(props.theme.colors.status.error, 0.2)};
        color: ${(props) => props.theme.colors.status.error};
        &:hover {
            background-color: ${(props) => transparency(props.theme.colors.status.success, 0.15)};
            color: ${(props) => props.theme.colors.status.success};
        }
    }
`

const barStyle: { [key in PatternType]: FlattenInterpolation<any> } = {
    default: barDefaultCss,
    strict: barStrictCss,
}

const invertBarStyle: { [key in PatternType]: FlattenInterpolation<any> } = {
    default: barDefaultCss,
    strict: invertBarStrictCss,
}

const boxStyle: { [key in PatternType]: FlattenInterpolation<any> } = {
    default: boxDefaultCss,
    strict: boxStrictCss,
}

const invertBoxStyle: { [key in PatternType]: FlattenInterpolation<any> } = {
    default: boxDefaultCss,
    strict: invertBoxStrictCss,
}

const Bar = styled.span<PatternBarProps>`
    font-size: 0.7em;
    max-width: 0.95em;
    padding: 0 0.125em;
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    ${(props) => (props.invert ? invertBarStyle[props.type || 'default'] : barStyle[props.type || 'default'])}
`

const Box = styled.span<PatternBarProps>`
    height: 2.7em;
    width: 2.7em;
    border-radius: 0.7em;
    font-weight: lighter;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) => (props.invert ? invertBoxStyle[props.type || 'default'] : boxStyle[props.type || 'default'])}
`

interface WeekdayElementProps {
    weekdayLetter: string
    weekdayIndex: number
    onClick?: (index: number, active: boolean) => void
    active: boolean
    large?: boolean
    type?: PatternType
    invert?: boolean
}

const WeekdayElement: React.FC<WeekdayElementProps> = ({
    weekdayLetter,
    weekdayIndex,
    onClick,
    active,
    large,
    type,
    invert,
}) => {
    const handleOnClick = () => {
        if (onClick) {
            onClick(weekdayIndex, !active)
        }
    }

    if (large) {
        return (
            <Box className={active ? 'active' : ''} onClick={handleOnClick} type={type} invert={invert}>
                {weekdayLetter}
            </Box>
        )
    }
    return (
        <Bar className={active ? 'active' : ''} type={type} invert={invert}>
            {weekdayLetter}
        </Bar>
    )
}

interface WeekdayPatternProps {
    weekdays: string
    large?: boolean
    handleWeekdayToggleChange?: (weekdays: string[]) => void
    id?: string
    type?: PatternType
    invert?: boolean
}

export const WeekdayPattern: React.FC<WeekdayPatternProps & React.HTMLAttributes<HTMLElement>> = ({
    weekdays: weekdaysProp,
    large,
    handleWeekdayToggleChange,
    id,
    type,
    invert,
    ...rest
}) => {
    const [weekdays, setWeekdays] = useState<string[]>(weekdaysProp.split(','))
    const isUSPartner = useAppSelector(getIsUSPartner)

    useEffect(() => {
        setWeekdays(weekdaysProp.split(','))
    }, [weekdaysProp])

    const toggleWeekday = useCallback(
        (dayIndex: number, active: boolean) => {
            const index = dayIndex.toString()
            let updatedWeekdays
            if (active) {
                if (!weekdays.includes(index)) {
                    updatedWeekdays = [...weekdays, index]
                }
            } else {
                if (weekdays.includes(index)) {
                    updatedWeekdays = weekdays.filter((w) => w !== index)
                }
            }

            if (updatedWeekdays) {
                setWeekdays(updatedWeekdays)
                if (handleWeekdayToggleChange) {
                    handleWeekdayToggleChange(updatedWeekdays)
                }
            }
        },
        [weekdays, handleWeekdayToggleChange],
    )

    const weekdaysInNumbers = weekdaysProp ? weekdaysProp.split(',').map(Number) : []

    return (
        <Wrapper large={large} {...rest} id={id}>
            {(isUSPartner ? US_WEEKDAYS : WEEKDAYS).map((d: Day) => {
                const isActive = weekdaysInNumbers.includes(d.index)
                return (
                    <WeekdayElement
                        key={d.index}
                        active={isActive}
                        weekdayLetter={d.letter}
                        weekdayIndex={d.index}
                        large={large}
                        onClick={toggleWeekday}
                        type={type}
                        invert={invert}
                    />
                )
            })}
        </Wrapper>
    )
}
