import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { User } from 'auth/state'
import { AppServices } from 'middleware'
import { CalendarData, PriceSettingsForOverview } from './articlePricingService'
import { ExceptionForOverview, GlobalAccountSettings, ProductData } from './pricingService'
import { createQueryResponseHandling } from 'utils/errorHandler'
import { GlobalAccountSettingsFormData } from '../../globalPricingSettings/studio/types'
import { formatISOString } from 'utils/dates'

export const pricingApi = createApi({
    reducerPath: 'pricingApi',
    tagTypes: ['accountSettings', 'productPricing', 'calendarPrices', 'pricerIndustryDefaults'],
    baseQuery: fetchBaseQuery({}),
    endpoints: () => ({}),
})

interface GetPricesForCalendarReqProps {
    accountSlug: string
    userInfo: Readonly<User | null>
    productsList: string | null
    productData: {
        id: string
        priceSettings: PriceSettingsForOverview
        override: ExceptionForOverview | {}
    }
    dateFrom: string
    dateTo: string
    accountSettings?: GlobalAccountSettings
}

interface SaveGlobalAccountSettingsReqProps {
    accountSlug: string
    accountSettings: GlobalAccountSettingsFormData
}

const extendedPricingApi = pricingApi.injectEndpoints({
    endpoints: (builder) => ({
        getGlobalAccountSettings: builder.query({
            providesTags: ['accountSettings'],
            queryFn: async ({ accountSlug }: { accountSlug: string }) => {
                return createQueryResponseHandling(() =>
                    AppServices.pricingService.getGlobalAccountSettings(accountSlug),
                )
            },
        }),
        getDefaultIndustrySettings: builder.query<GlobalAccountSettings, { modelId: string; version?: 'V1' | 'V2' }>({
            providesTags: ['pricerIndustryDefaults'],
            queryFn: async ({ modelId, version }) => {
                return createQueryResponseHandling(() =>
                    AppServices.pricingService.getDefaultIndustrySettings(modelId, version),
                )
            },
        }),
        getProductPricing: builder.query<ProductData[], { ids: string[] }>({
            providesTags: ['productPricing'],
            queryFn: async ({ ids }) => {
                return createQueryResponseHandling(() => AppServices.pricingService.getProductPricing(ids))
            },
        }),
        getPricesForCalendar: builder.query<CalendarData, GetPricesForCalendarReqProps>({
            providesTags: ['calendarPrices'],
            queryFn: async ({
                accountSlug,
                userInfo,
                productsList,
                productData,
                dateFrom,
                dateTo,
                accountSettings,
            }) => {
                return createQueryResponseHandling(() =>
                    AppServices.articlePricingService.getPricesForCalendar(
                        accountSlug,
                        userInfo as User,
                        productsList,
                        productData,
                        formatISOString(dateFrom, 'yyyy-MM-dd'),
                        formatISOString(dateTo, 'yyyy-MM-dd'),
                        accountSettings,
                    ),
                )
            },
        }),
        saveGlobalAccountSettings: builder.mutation<boolean, SaveGlobalAccountSettingsReqProps>({
            invalidatesTags: ['accountSettings'],
            queryFn: async ({ accountSlug, accountSettings }) => {
                return createQueryResponseHandling(() =>
                    AppServices.pricingService.saveGlobalAccountSettings(accountSlug, accountSettings),
                )
            },
        }),
    }),
})

export const { reducer, reducerPath } = pricingApi
export const {
    useGetGlobalAccountSettingsQuery,
    useLazyGetProductPricingQuery,
    useGetDefaultIndustrySettingsQuery,
    useLazyGetPricesForCalendarQuery,
    useSaveGlobalAccountSettingsMutation,
} = extendedPricingApi
