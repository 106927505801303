import { ImageUploadResponse } from 'http/imagesService'
import * as React from 'react'
import styled from 'styled-typed'
import { Col, Row } from 'uiComponents/flex'
import { FormItemName } from 'uiComponents/form/formElements'
import { TimePicker, UploadInput } from 'uiComponents/input'
import { MessageKind } from 'uiComponents/messages'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { InputComponent, PickerComponent, SelectComponent } from 'uiComponents/studio/form/fieldComponents'
import {
    MultiSelectField,
    PickerField,
    SelectField,
    TextField,
    UploadField,
} from 'uiComponents/studio/form/fieldComponentSchema'
import { MultiSelectStudio } from 'uiComponents/studio/form/fields/multiSelectStudio'
import { AlignedCol, FormLabel } from 'uiComponents/studio/form/formComponents'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { Italic, RegularText, SecondaryText, SmallText } from 'uiComponents/typography'
import { UseDateInput, UseFormInput } from '../schema'

export const DeleteNote = styled.div`
    margin: 0 1em 0 1em;
    display: flex;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
    > span {
        font-size: 0.8rem;
    }
`

export const ImageUrlCol = styled(Col)`
    word-break: break-all;
`

export const CheckboxWrapper = styled.div`
    font-size: 0.875rem;
    display: flex;
    align-items: center;
`

export const renderInputComponent = (componentField: TextField, property: UseFormInput, footerNote?: string) => (
    <FormItem>
        <Col span={12}>
            <FormLabel htmlFor={componentField.name}>{componentField.label}</FormLabel>
        </Col>
        <Col span={12}>
            <InputComponent
                field={componentField}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => property.onChange(e.target.value)}
                value={property.value}
                maxLength={componentField.maxLength}
                style={{ fontWeight: 'normal' }}
            />
        </Col>
        {footerNote && (
            <SecondaryText>
                <SmallText>
                    <Italic>{footerNote}</Italic>
                </SmallText>
            </SecondaryText>
        )}
    </FormItem>
)

export const renderSelectComponent = (
    componentField: SelectField,
    property: UseFormInput | UseDateInput,
    noSelectOption?: string,
) => (
    <FormItem>
        <Col span={12}>
            <FormLabel htmlFor={componentField.name}>{componentField.label}</FormLabel>
        </Col>
        <Col span={12}>
            <SelectComponent
                id="defaultLanguage"
                field={componentField}
                handleChange={(value) => property.onChange(value as string)}
                value={property.value}
                noSelectOption={noSelectOption}
            />
        </Col>
    </FormItem>
)

export const renderPickerComponent = (componentField: PickerField, property: UseFormInput) => {
    return (
        <FormItem>
            <AlignedCol span={8}>
                <label htmlFor={componentField.name}>{componentField.label}</label>
            </AlignedCol>
            <AlignedCol span={4} className="align-right">
                <PickerComponent
                    field={componentField}
                    handleChange={(value: string) => property.onChange(value as string)}
                    value={property.value || '#000000'}
                />
            </AlignedCol>
        </FormItem>
    )
}

export const renderMultiSelectComponent = (
    componentField: MultiSelectField,
    property: UseFormInput | UseDateInput,
    noSelectOption?: string,
) => (
    <FormItem>
        <Col span={12}>
            <FormLabel htmlFor={componentField.name}>{componentField.label}</FormLabel>
        </Col>
        <Col span={12}>
            <MultiSelectStudio
                field={componentField}
                handleChange={(value) => property.onChange(value as string)}
                value={property.value as unknown as string[]}
                noSelectOption={noSelectOption}
            />
        </Col>
    </FormItem>
)

export const renderImageComponent = (
    componentField: UploadField,
    property: UseFormInput,
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void,
    removeAllMessages: () => void,
) => (
    <FormItem>
        <Col span={12}>
            <label htmlFor={componentField.name}>{componentField.label}</label>
        </Col>
        <Col span={12}>
            <UploadInput
                fileSizeLimit={0.25}
                onUpload={property.onUpload}
                handleUploadSuccessResponse={(response: ImageUploadResponse) => property.onUploadSuccess(response)}
                inputId={componentField.name}
                replaceTopMessages={replaceTopMessages}
                removeAllMessages={removeAllMessages}
                type="img"
                enableCropping={true}
            />
        </Col>
        <Col span={12}>
            {property.value && (
                <>
                    <FormItemName>Current image url:</FormItemName>
                    <Row>
                        <ImageUrlCol span={9}>
                            <RegularText>
                                <Italic>{property.value}</Italic>
                            </RegularText>
                        </ImageUrlCol>
                        <Col
                            span={3}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                padding: 0,
                            }}
                        >
                            <DeleteNote onClick={() => property.onChange('')} style={{ margin: '0' }}>
                                <SecondaryText>x Remove</SecondaryText>
                            </DeleteNote>
                        </Col>
                    </Row>
                </>
            )}
        </Col>
    </FormItem>
)

const DatePickerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const datePickerComponent = (property: UseDateInput) => {
    return (
        <FormItem>
            <DatePickerWrapper>
                <Col span={6}>
                    <DatePicker
                        id="date"
                        date={property.date ? new Date(property.date) : null}
                        onChange={(value: Date) => property.updateDate(value)}
                        status="normal"
                        lowerBoundary={new Date()}
                        allowNullDate
                    />
                </Col>
                <Col span={6} offset={0.25}>
                    <TimePicker
                        id="time"
                        value={property.time}
                        onChange={(time) => time && property.updateHours(time)}
                    />
                </Col>
            </DatePickerWrapper>
        </FormItem>
    )
}
