import React from 'react'

import FieldWrapper, { FieldStatus, FieldVariant } from '../fieldWrapper'
import { useCustomId } from '../_shared/hooks'
import { MultiSelect } from './multiSelect'
import { MultiSelectProps } from './interface'

interface MultiSelectMappedProps extends Omit<MultiSelectProps, 'onSelect' | 'selected'> {}

interface MultiSelectFieldProps extends MultiSelectMappedProps {
    name: string
    id?: string
    label?: string
    error?: string
    state?: FieldStatus
    onChange(values: string[]): void
    value: string[]
    children?: (props: MultiSelectFieldProps) => React.ReactElement
    variant?: FieldVariant
}

const emptyArray: string[] = []

const MultiSelectField: React.FC<MultiSelectFieldProps> = ({
    name,
    label,
    id,
    children: Children,
    error,
    state,
    variant,
    onChange,
    value,
    ...props
}) => {
    const innerId = useCustomId(id)
    const customProps = {
        ...props,
        id: innerId,
    }

    return (
        <FieldWrapper variant={variant} label={label} error={error} id={innerId} status={state as FieldStatus}>
            {!!Children ? (
                <Children {...customProps} name={name} value={value || emptyArray} onChange={onChange} />
            ) : (
                <MultiSelect status={state} {...customProps} selected={value || emptyArray} onSelect={onChange} />
            )}
        </FieldWrapper>
    )
}

export default MultiSelectField
