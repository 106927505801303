import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import { Row } from 'uiComponents/flex'

export interface TabsProps {
    titles: string[]
    children: React.ReactNode[]
    setActive?: (i: number) => void
    activeTabIndex?: number
}

export const NavigationSection = styled.div`
    margin: 1em 0 0 0;
    &.hidden {
        display: none;
    }
`

export const Tab = styled.div`
    font-size: 0.875rem;
    font-weight: 400;
    height: 2.8rem;
    display: inline-flex;
    align-items: center;
    padding: 0.5em 1.5em;
    border-top: 1px solid ${(p) => p.theme.colors.border};
    border-right: 1px solid ${(p) => p.theme.colors.border};
    border-bottom: 1px solid ${(p) => p.theme.colors.border};
    cursor: pointer;
    background-color: ${(p) => p.theme.colors.tableRow};
    color: ${(p) => p.theme.colors.textLight};

    &.active {
        border-bottom: 1px solid ${(p) => p.theme.colors.white};
        background-color: ${(p) => p.theme.colors.white};
        color: ${(p) => p.theme.colors.textDark};
        font-weight: 500;
    }
    &:first-child {
        border-top-left-radius: 4px;
        border-left: 1px solid ${(p) => p.theme.colors.border};
    }
    &:last-child {
        border-top-right-radius: 4px;
    }
    transform: translateY(1px);
`
export const TabRow = styled(Row)`
    width: 30em;
    border-bottom: 1px solid ${(p) => p.theme.colors.border};
`

export function Tabs({ titles, children, setActive, activeTabIndex }: TabsProps) {
    const [activeIndex, setActiveIndex] = useState<number>(0)
    useEffect(() => {
        if ((!!activeTabIndex || activeTabIndex === 0) && activeTabIndex !== activeIndex) {
            setActiveIndex(activeTabIndex)
        }
    }, [activeTabIndex])

    return (
        <>
            <TabRow>
                {titles.map((t, i) => (
                    <Tab
                        key={i}
                        className={activeIndex === i ? 'active' : ''}
                        onClick={() => {
                            setActiveIndex(i)
                            if (!!setActive) {
                                setActive(i)
                            }
                        }}
                    >
                        {t}
                    </Tab>
                ))}
            </TabRow>
            {children.map((ch, i) => (
                <NavigationSection key={i} id="London" className={activeIndex === i ? '' : 'hidden'}>
                    {ch}
                </NavigationSection>
            ))}
        </>
    )
}

const TabsContainer = styled.div.attrs((props) => ({
    className: classNames('tabs-container', props.className),
}))<{ light?: boolean }>`
    margin: 0 auto;
    width: fit-content;
    display: flex;
    background: ${(props) => (props.light ? props.theme.colors.white : props.theme.colors.background)};
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: 0.25rem;
`
const TabElement = styled.div.attrs((props) => ({
    className: classNames('tab-element', props.className),
}))<{
    current: boolean
    disabled?: boolean
    fixedWidth?: string
}>`
    ${(props) => (props.fixedWidth ? `width: ${props.fixedWidth};` : '')}
    display: flex;
    justify-content: center;
    padding: 0.7rem 1.6rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: ${(props) => (props.current || props.disabled ? '' : 'pointer')};
    color: ${(props) => (props.current ? props.theme.colors.boyBlue : props.theme.colors.textLight)};
    &:not(:last-child) {
        border-right: 1px solid ${(props) => props.theme.colors.border};
    }
`

interface NavigationTab {
    name: string
    onClick: () => void
    current: boolean
    disabled?: boolean
}

interface NavigationTabsProps {
    tabs: NavigationTab[]
    light?: boolean
    fixedWidth?: string
}

export function NavigationTabs(props: NavigationTabsProps) {
    return (
        <TabsContainer light={props.light}>
            {props.tabs.map((t, i) => (
                <TabElement
                    key={i}
                    onClick={t.current || t.disabled ? () => {} : t.onClick}
                    current={t.current}
                    disabled={t.disabled}
                    fixedWidth={props.fixedWidth}
                >
                    {t.name}
                </TabElement>
            ))}
        </TabsContainer>
    )
}
