import * as React from 'react'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { ConfigField, FieldCategory } from './schema'
import { SelectComponent, InputComponent } from 'uiComponents/studio/form/fieldComponents'
import { AlignedCol } from 'uiComponents/studio/form/formComponents'
import { Locale } from 'admin/articleService'
import { SingleSelectOption } from 'uiComponents/input/singleSelect'

interface SectionProps {
    accountSlug: string
    category: FieldCategory
    fieldsConfig: ConfigField[]
    locales: Locale[]
    activeLanguage: string
    markErrors: boolean
    handleChange: (value: string, field: string, type: 'label' | 'placeholder', fieldCategory: FieldCategory) => void
    onLanguageChange: (language: string) => void
}

function TranslationsSection(props: SectionProps) {
    const [languageOptions, setlanguageOptions] = React.useState<SingleSelectOption[]>([])
    const [fieldsWithErrors, setFieldsWithErrors] = React.useState<string[]>([])
    const [clickedSections, setClickedSections] = React.useState<string[]>([])
    const [autoScrollOn, setAutoScrollOn] = React.useState<boolean>(true)

    React.useEffect(() => {
        const mapped = props.locales.map((l) => ({
            value: l.code,
            name: l.name,
        }))
        setlanguageOptions(mapped)
    }, [props.locales])

    React.useEffect(() => {
        if (props.markErrors) {
            const fiels = props.fieldsConfig.filter(
                (f) =>
                    Object.entries(f.translations).filter(
                        ([k, v]) => k === props.activeLanguage && !(v.label || '').trim(),
                    ).length > 0,
            )
            setFieldsWithErrors(fiels.map((f) => f.slug))
        }
    }, [props.markErrors, props.fieldsConfig, props.activeLanguage, props.category])

    React.useEffect(() => {
        if (props.markErrors) {
            setAutoScrollOn(true)
        }
    }, [props.activeLanguage, props.category])

    React.useEffect(() => {
        scrollToShowError()
    }, [fieldsWithErrors])

    const scrollToShowError = () => {
        if (autoScrollOn) {
            const elements = document.getElementsByClassName('error-scroll-to')
            if (elements[0]) {
                elements[0].scrollIntoView()
            }
        }
    }

    const setClicked = (slug: string) => {
        if (autoScrollOn) {
            setAutoScrollOn(false)
        }
        if (!clickedSections.includes(slug)) {
            setClickedSections([...clickedSections, slug])
        }
    }

    const handleChange = (value: string, slug: string, type: 'label' | 'placeholder') => {
        setClicked(slug)
        props.handleChange(value, slug, type, props.category)
    }

    type SelectType = 'select'
    type TextField = 'text'
    const translationLanguageField = {
        type: 'select' as SelectType,
        name: 'translation-language',
        label: 'Translation language',
        path: '',
        defaultValue: 'en',
        availableOptions: languageOptions,
    }
    const textField = {
        name: 'text',
        type: 'text' as TextField,
        label: 'Text',
        path: '',
        defaultValue: 'Text',
    }

    return (
        <>
            <SectionWrapper title="Translations">
                <FormItem>
                    <AlignedCol span={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <label htmlFor={translationLanguageField.name}>{translationLanguageField.label}</label>
                    </AlignedCol>
                    <AlignedCol span={6} className="align-right">
                        <SelectComponent
                            id="translationsLanguage"
                            field={translationLanguageField}
                            handleChange={(v: string) => props.onLanguageChange(v)}
                            value={props.activeLanguage}
                        />
                    </AlignedCol>
                </FormItem>
            </SectionWrapper>
            {props.fieldsConfig.map((f, i) => (
                <SectionWrapper
                    key={f.slug + i}
                    title={f.name}
                    onClick={() => setClicked(f.slug)}
                    defaultCollapsed={
                        (!props.markErrors || !fieldsWithErrors.includes(f.slug)) && !clickedSections.includes(f.slug)
                    }
                >
                    <FormItem>
                        <AlignedCol span={12}>
                            <label htmlFor={f.slug}>{`'${f.name}' label text`}</label>
                        </AlignedCol>
                        <AlignedCol span={12}>
                            <InputComponent
                                field={textField}
                                handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleChange(e.target.value, f.slug, 'label')
                                }
                                value={f.translations[props.activeLanguage].label || ''}
                                maxLength={100}
                                style={{ fontWeight: 'normal' }}
                                error={props.markErrors && !f.translations[props.activeLanguage].label}
                                locale={props.activeLanguage}
                            />
                        </AlignedCol>
                    </FormItem>
                    <FormItem>
                        <AlignedCol span={12}>
                            <label htmlFor={f.slug}>{`'${f.name}' placeholder text`}</label>
                        </AlignedCol>
                        <AlignedCol
                            span={12}
                            className={props.markErrors && fieldsWithErrors.includes(f.slug) ? 'error-scroll-to' : ''}
                        >
                            <InputComponent
                                field={textField}
                                handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleChange(e.target.value, f.slug, 'placeholder')
                                }
                                value={f.translations[props.activeLanguage].placeholder || ''}
                                maxLength={100}
                                style={{ fontWeight: 'normal' }}
                                locale={props.activeLanguage}
                            />
                        </AlignedCol>
                    </FormItem>
                </SectionWrapper>
            ))}
        </>
    )
}

export default TranslationsSection
