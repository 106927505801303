import React, { useMemo, useState } from 'react'
import { startOfToday } from 'date-fns'
import { useFormikContext } from 'formik'

import { RtpCalendar } from '@convious/rtp-calendar'
import { useGetCalendarData } from 'globalPricingSettings/studio/pricerAvailabilityHook'
import { GlobalAccountSettings } from 'products/pricing/pricingService'
import { getEndDate, getStartDate } from 'products/utils'
import { LoaderWrapper } from 'uiComponents/loaders'
import { useHandlePreviewFieldsState } from './useHandlePreviewFieldsState'
import SelectArticle from './selectArticle'
import './pricePreview.scss'
import Timeslots from './timeslots'
import { format } from 'date-fns'
import { PricingTimeslot } from './types'
import { InputCard } from '../../uiComponents/cards'
import { useAppSelector } from 'store/hooks'
import { getIsUSPartner } from 'auth/selectors'

const PricePreview = () => {
    const now = useMemo(() => startOfToday(), [])
    const { values } = useFormikContext<GlobalAccountSettings>()
    const [selectedDate, setSelectedDate] = useState<Date>(now)
    const [{ endAt, startAt, article, category }, dispatch] = useHandlePreviewFieldsState()
    const isUSPartner = useAppSelector(getIsUSPartner)
    const { data, loading } = useGetCalendarData({
        accountSettings: values,
        articleId: article,
        categoryId: category,
        startAt: startAt.toJSON(),
        endAt: endAt.toJSON(),
    })

    const timeslots = useMemo<PricingTimeslot[]>(() => {
        const selectedPrice = data?.find((price) => price.date === format(selectedDate, 'yyyy-MM-dd'))
        return selectedPrice?.timeslots ?? []
    }, [data, selectedDate])

    return (
        <InputCard className="price-preview">
            <SelectArticle
                category={category}
                setCategory={(value) => dispatch({ type: 'category', payload: value })}
                article={article}
                setArticle={(value) => dispatch({ type: 'article', payload: value })}
            />
            <div style={{ position: 'relative', marginTop: '2rem' }}>
                <LoaderWrapper loading={loading}>
                    <RtpCalendar
                        selectedDays={selectedDate}
                        onDayClick={setSelectedDate}
                        locale="en"
                        prices={data || []}
                        fromMonth={now}
                        onMonthChange={(month) => {
                            dispatch({ type: 'startAt', payload: getStartDate(month) })
                            dispatch({ type: 'endAt', payload: getEndDate(month) })
                        }}
                        modifiers={{
                            before: now,
                            past: { before: now },
                            selected: selectedDate,
                        }}
                        firstDayOfWeek={isUSPartner ? 0 : 1}
                    />
                    {timeslots.length > 0 && (
                        <Timeslots style={{ marginTop: '1rem' }} selectedDate={selectedDate} timeslots={timeslots} />
                    )}
                </LoaderWrapper>
            </div>
        </InputCard>
    )
}

export default PricePreview
