import * as React from 'react'
import styled from 'styled-typed'
import { Radio, RadioWrapper } from 'uiComponents/input/radio'
import { DateRangeType } from 'uiComponents/popups/comparisonDateRangePicker/schema'
import { Body } from 'uiComponents/typography'

const Container = styled.div`
    display: flex;
    justify-content: center;
`
const OptionWrapper = styled(RadioWrapper)`
    cursor: pointer;
    margin: 0.1em 0;
    &:first-child {
        margin-right: 0.7em;
    }
`
const RadioText = styled(Body)`
    &.disabled {
        opacity: 0.5;
        cursor: default;
    }
`

interface DateRangeTypeSelectProps {
    dateRangeType: DateRangeType
    onChange: (type: DateRangeType) => void
    disabled?: boolean
}

export function DateRangeTypeSelect(props: DateRangeTypeSelectProps) {
    const [dateRangeType, setDateRangeType] = React.useState<DateRangeType>('sale')

    React.useEffect(() => {
        setDateRangeType(props.dateRangeType)
    }, [props.dateRangeType])

    const onChange = (type: DateRangeType) => {
        setDateRangeType(type)
        props.onChange(type)
    }

    return (
        <Container>
            <OptionWrapper>
                <Radio
                    onChange={() => onChange('sale')}
                    checked={dateRangeType === 'sale'}
                    checkMarkSize="small"
                    disabled={props.disabled}
                />
                <RadioText
                    size={2}
                    weight={dateRangeType === 'sale' ? 'medium' : 'regular'}
                    className={props.disabled ? 'disabled' : ''}
                >
                    Date of sale
                </RadioText>
            </OptionWrapper>
            <OptionWrapper>
                <Radio
                    onChange={() => onChange('visit')}
                    checked={dateRangeType === 'visit'}
                    checkMarkSize="small"
                    disabled={props.disabled}
                />
                <RadioText
                    size={2}
                    weight={dateRangeType === 'visit' ? 'medium' : 'regular'}
                    className={props.disabled ? 'disabled' : ''}
                >
                    Date of visit
                </RadioText>
            </OptionWrapper>
        </Container>
    )
}

interface DateRangeTogglerProps {
    dateRangeOn?: boolean
    enableDateRange: (v: boolean) => void
}

export function DateRangeToggler(props: DateRangeTogglerProps) {
    return (
        <Container>
            <OptionWrapper>
                <Radio
                    onChange={() => props.enableDateRange(false)}
                    checked={!props.dateRangeOn}
                    checkMarkSize="small"
                />
                <RadioText size={2} weight={props.dateRangeOn ? 'regular' : 'medium'}>
                    Show all
                </RadioText>
            </OptionWrapper>
            <OptionWrapper>
                <Radio onChange={() => props.enableDateRange(true)} checked={props.dateRangeOn} checkMarkSize="small" />
                <RadioText size={2} weight={props.dateRangeOn ? 'medium' : 'regular'}>
                    Define by date range
                </RadioText>
            </OptionWrapper>
        </Container>
    )
}
