import React, { FC, useEffect, useState } from 'react'

import { LoginFormProps, LoginFormData } from './schema'
import { withFeatures } from 'features'
import { LoginPageContainer } from './loginContainer'
import { withNavigation } from 'hocs'
import { LinkedInButton } from 'uiComponents/buttons/linkedInButton'
import { GoogleButton } from 'uiComponents/buttons/googleButton'
import TextInputFieldFormik from 'uiComponents/input/textInput/textInputFieldFormik'
import styled from 'styled-typed'
import { ActionButton } from 'uiComponents/buttons'
import { StyledATag, Body, Text } from 'uiComponents/typography'
import { LoginFormSchema } from './validation'
import { FormWrapper } from 'uiComponents/form/form'

const LoginHeader = styled.h1`
    font-size: 2.625em;
    line-height: 3.5rem;
    font-weight: bold;
    margin: 0 0 0.5rem 0;
`
const LoginSubheader = styled(Body)`
    margin: 0 0 2rem 0;
    color: ${(props) => props.theme.colors.textLight};
`

const ForgotPasswordLinkWrapper = styled(Body)`
    margin-top: 0.7em;

    a {
        text-decoration: none;
    }
`
const ValidationMessage = styled(Text).attrs((props) => ({
    status: 'error',
}))`
    font-size: 0.75em;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in;
    display: block;
    margin-bottom: 0.3em;

    &.validation-message-visible {
        opacity: 1;
        visibility: visible;
    }
`
export const Separator = styled.div`
    color: ${(props) => props.theme.colors.textLight};
    font-size: 0.875em;
    margin: 2em 0 1em 0;
    position: relative;
    text-align: center;

    &:before,
    &:after {
        content: '';
        display: block;
        height: 2px;
        background: ${(props) => props.theme.colors.textLight};
        width: 40%;
        opacity: 0.5;
        position: absolute;
        top: 0.43em;
    }
    &:after {
        right: 0;
    }
`
const LoginButton = styled(ActionButton)`
    margin-bottom: 0.5em;
`

const EmailInput = styled(TextInputFieldFormik)`
    margin-bottom: 1em;
`

const PasswordInput = styled(TextInputFieldFormik)`
    margin-bottom: 2.5em;
`

const StyledFormWrapper = styled(FormWrapper)`
    width: 100%;
`

const sosErrorMessagesMap = (error: string) => {
    switch (error) {
        case 'user_cancelled_authorize':
            return 'Login credentials were not provided.'
        case 'user_cancelled_login':
            return 'Authorization was not provided.'
        default:
            return 'An unknown error has occurred. Please try again later.'
    }
}

const initialFormValues: LoginFormData = {
    email: '',
    password: '',
}

export const LoginPage: FC<LoginFormProps> = (props) => {
    const { navigation, ssoService, hasFeature, login, history } = props
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [ssoErrorMessage, setSsoErrorMessage] = useState<string | null>(null)
    const linkedInButtonEnabled = hasFeature('LinkedInLogin')
    const googleInButtonEnabled = hasFeature('GoogleLogin')
    const hasSsoButtons = linkedInButtonEnabled || googleInButtonEnabled

    useEffect(() => {
        if (navigation.query().login_error) {
            setSsoErrorMessage(sosErrorMessagesMap(navigation.query().login_error))
        }
    }, [navigation])

    const handleOnSsoLogin = (provider: string) => {
        const redirectPath = navigation.query().next
        sessionStorage.setItem('ssoLoginRedirectPath', redirectPath)
        return ssoService.initiateAuth(provider)
    }

    const handleOnSubmit = (formData: LoginFormData) => {
        const { email, password } = formData
        setIsSubmitting(true)
        return login(email, password)
            .then((success) => {
                if (success) {
                    const destinationPath = navigation.query().next
                    const newAccountAddScriptPage = destinationPath
                        ? destinationPath.indexOf('new_account') > 0 && destinationPath.indexOf('add_snippet') > 0
                        : false
                    if (destinationPath && !newAccountAddScriptPage) {
                        if (destinationPath[0] !== '/') {
                            history.push('/' + destinationPath)
                        } else {
                            history.push(destinationPath)
                        }
                    } else {
                        history.push('/')
                    }
                } else {
                    setErrorMessage('Invalid credentials')
                }
            })
            .catch(() => {
                setErrorMessage('An unknown error has occurred. Please try again later.')
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    return (
        <LoginPageContainer>
            <StyledFormWrapper
                formId="login-form"
                validationSchema={LoginFormSchema}
                initialValues={initialFormValues}
                onSubmit={handleOnSubmit}
            >
                <LoginHeader>Log In</LoginHeader>
                <LoginSubheader size={1}>Enter your email and password to login to your control panel.</LoginSubheader>
                {errorMessage && (
                    <ValidationMessage id="login-message" className="validation-message-visible">
                        {errorMessage}
                    </ValidationMessage>
                )}
                <EmailInput
                    id="login-username"
                    type="email"
                    name="email"
                    block
                    label="Email"
                    placeholder="Email address"
                />
                <PasswordInput
                    id="login-password"
                    block
                    type="password"
                    name="password"
                    label="Password"
                    placeholder="Password"
                />
                <LoginButton disabled={isSubmitting} id="login-submit" size="large" type="submit" block>
                    Sign in
                </LoginButton>
                <ForgotPasswordLinkWrapper size={1}>
                    <StyledATag href="/account/forgotPassword">Forgot your password?</StyledATag>
                </ForgotPasswordLinkWrapper>
                {hasSsoButtons && (
                    <>
                        <Separator>OR</Separator>
                        <ValidationMessage className={ssoErrorMessage ? 'validation-message-visible' : ''}>
                            {ssoErrorMessage || '&nbsp;'}
                        </ValidationMessage>
                        {linkedInButtonEnabled && (
                            <LinkedInButton
                                onClick={() => handleOnSsoLogin('linkedin')}
                                buttonText="Log in with LinkedIn"
                            />
                        )}
                        {googleInButtonEnabled && (
                            <GoogleButton
                                onClick={() => handleOnSsoLogin('google')}
                                buttonText="Log in with Google"
                                style={{ marginTop: '0.8175em' }}
                            />
                        )}
                    </>
                )}
            </StyledFormWrapper>
        </LoginPageContainer>
    )
}

export default withNavigation(withFeatures(LoginPage))
