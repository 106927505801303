import { Order, Ticket, TicketForRefund } from 'orders/schema'
import { RefundFeeType } from 'settings/accountSettings/contract/managementService'

const calculateRefundFee = (total: number, fee: number | null, feeType: RefundFeeType | null) => {
    if (!!total && !!fee && !!feeType) {
        return feeType === 'absolute' ? fee : (total * fee) / 100
    }

    return 0
}

const getTicketTotalAmount = (ticket: TicketForRefund) => {
    return ticket.items.reduce((s, item) => s + item.price, 0)
}

const getTotalAmount = (tickets: TicketForRefund[]) => {
    return tickets.reduce((s, t) => s + getTicketTotalAmount(t), 0).toFixed(2)
}

const checkStatusesEqual = (statuses: string[]) => {
    return statuses.every((s, i, arr) => s === arr[0])
}

const getTicketItemsForRefund = (t: Ticket) => {
    const items = t.groupedTicketEntities.map((gte, j) => {
        const itemStatus = checkStatusesEqual(gte.map((te) => te.status)) ? gte[0].status : 'partially redeemed'
        return {
            ticketEntities: gte,
            ticketUuids: gte.map((i) => i.uuid),
            barcodeList: gte.map((b) => b.barcode?.barcode || ''),
            status: itemStatus,
            itemRefundable: gte.filter((i) => !i.canRefund).length === 0,
            price: t.isBundle
                ? t.bundleItemPrices
                    ? t.bundleItemPrices[j]
                    : t.pricePerItemAfterDiscount
                : gte[0]?.price || 0,
        }
    })
    return items
}
const getTicketsForRefund = (order: Order): TicketForRefund[] => {
    const mappedTicketItemsData = order.tickets.map((t) => ({
        ...t,
        items: getTicketItemsForRefund(t),
    }))
    const mappedTicketsData = mappedTicketItemsData.map((t) => ({
        ...t,
        status: checkStatusesEqual(t.items.map((b) => b.status)) ? t.items[0].status : 'partial',
    }))
    return mappedTicketsData
}

interface CalculateOrderRefund {
    order: Order
    fullRefundOnly?: boolean
}

export const calculateOrderTotalAmount = ({ order, fullRefundOnly }: CalculateOrderRefund = {} as any) => {
    const ticketsForRefund = getTicketsForRefund(order)
    const refundableTicketsList = ticketsForRefund
        .filter((t) => t.canRefund)
        .map((t) => ({ ...t, items: t.items.filter((i) => i.itemRefundable) }))

    const totalAmount = fullRefundOnly ? order.totalAfterDiscount.toFixed(2) : getTotalAmount(refundableTicketsList)

    return totalAmount
}

export const calculateOrderPartnerRefundFee = ({ order, fullRefundOnly }: CalculateOrderRefund) => {
    const totalAmount = calculateOrderTotalAmount({ order, fullRefundOnly })

    return calculateRefundFee(Number(totalAmount), order.partnerRefundFee, order.partnerRefundFeeType) || 0
}
