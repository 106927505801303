import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import { TableLoader } from 'uiComponents/loaders'
import {
    CustomerDetails,
    ValueWithCurrency,
    profileActivityConfig,
    FieldConfigItem,
    PreferredDeviceTypes,
} from 'crm/schema'
import { getInitials, Initials, ProfileIdElement } from 'crm/common'
import { Col, Row } from 'uiComponents/flex'
import { format } from 'date-fns'
import { faMapMarkerAlt, faUserFriends } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { groupArrayByN } from 'utils'
import Infotip from 'uiComponents/infotip'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { DateFormats, parseISODate } from 'utils/dates'

const LeftSideContainer = styled(Col)`
    color: ${(p) => p.theme.colors.textLight};
    border-right: 1px solid ${(p) => p.theme.colors.tableRow};
    &&& {
        padding: 0;
    }
`
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    padding: 2.8em 1.8em;
`
const ProfileId = styled.div`
    margin: 0.7em 0 0.6em 0;
    font-size: 1.125em;
    color: ${(p) => p.theme.colors.textDark};
`
const AudiencesListWrapper = styled(Wrapper)`
    align-items: flex-start;
    padding-top: 2.3em;
    font-size: 0.75em;
    border-top: 1px solid ${(p) => p.theme.colors.tableRow};
`
const AudiencesListItem = styled(UnstyledLink)`
    font-weight: normal;
    margin-bottom: 1.2em;
    display: flex;
    span {
        margin-left: 0.6em;
        &:hover {
            text-decoration: underline;
        }
    }
`
const RightSideContainer = styled(Col)`
    max-width: 800px;
    &&& {
        padding: 2.8em 3.2em;
    }
`
const Location = styled.div`
    font-size: 0.75em;
    margin-top: 0.8em;
    color: ${(p) => p.theme.colors.boyBlue};
`
const StatsWrapper = styled.div`
    display: flex;
    margin-top: 2em;
`
const StatsItem = styled.div`
    color: ${(p) => p.theme.colors.textDark};
    font-size: 1.25em;
    padding: 0 0.5em;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
        color: ${(p) => p.theme.colors.textLight};
        font-size: 0.6em;
        margin-top: 0.4em;
        display: flex;
    }

    &.right-border {
        border-right: 1px solid ${(p) => p.theme.colors.tableRow};
    }
`
const Section = styled.div`
    margin-bottom: 2.7em;
    &:last-child {
        margin-bottom: 1em;
    }
`
const SectionTitle = styled.div`
    font-size: 1.25em;
    margin-bottom: 1em;
`
const ItemsRow = styled(Row)`
    margin-bottom: 2em;
`
const ItemValue = styled.div`
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.4em;
`
const ItemName = styled(ItemValue)`
    color: ${(p) => p.theme.colors.textLight};
    display: flex;
`
const ProductsTable = styled.table`
    width: 100%;
    font-size: 0.75em;
    border-collapse: collapse;
`
const ProductsCell = styled.td`
    border: 1px solid ${(p) => p.theme.colors.tableRow};
    padding: 1.7em 1.5em;

    &:nth-child(2) {
        min-width: 13em;
    }

    &.header {
        border: none;
        padding: 0.5em 0;
    }
`

interface ProfileDetailsPageProps {
    accountSlug: string
    details: CustomerDetails
    loading: boolean
    onSectionChange: () => void
}

function ProfileDetailsPage(props: ProfileDetailsPageProps) {
    const [activityFields, setActivityFields] = useState<FieldConfigItem[][]>([])

    useEffect(() => {
        props.onSectionChange()
    }, [])

    useEffect(() => {
        if (!!props.details.profile) {
            getFieldsToShowList()
        }
    }, [props.details])

    const getFieldsToShowList = () => {
        if (!props.details.profile) {
            return
        }
        const fieldsToShow: any = []
        profileActivityConfig.forEach((f) => {
            if (props.details.profile && !!checkHasValue(f.slug, f.array, props.details.profile[f.slug])) {
                fieldsToShow.push(f)
            }
        })
        setActivityFields(groupArrayByN(3, fieldsToShow))
    }

    const checkHasValue = (slug: string, array: boolean, value: any) => {
        if (slug === 'lastActivityAction') {
            return true
        } else if (value === null) {
            return false
        } else {
            return array ? value.length > 0 : true
        }
    }

    const getActivityFieldValue = (field: FieldConfigItem) => {
        if (!props.details.profile) {
            return '-'
        }
        const value = props.details.profile[field.slug]
        switch (field.slug) {
            case 'firstSeen':
                return field.name === 'Returning/new'
                    ? getNewVisitorValue()
                    : format(parseISODate(value), `${DateFormats.LONG_DATE}, ${DateFormats.SHORT_TIME}`)
            case 'lastSeen':
            case 'firstPurchaseAt':
            case 'lastPurchaseAt':
            case 'firstVenueVisitedAt':
            case 'lastVenueVisitedAt':
                return format(parseISODate(value), `${DateFormats.LONG_DATE}, ${DateFormats.SHORT_TIME}`)
            case 'hasTapApp':
            case 'marketingOptIn':
            case 'isSeasonpassHolder':
                return value ? 'Yes' : 'No'
            case 'preferredDeviceTypes':
                return value.length > 0 ? getDeviceTypeValue(value) : 'Unknown'
            case 'upcomingVisitsCount':
            case 'language':
                return value
            case 'acquisitionChannels':
                return value.join(', ')
            case 'lastActivityAction':
                return props.details.activity.length > 0 ? props.details.activity[0].activityName : '-'
            case 'totalOrderValue':
                const lifetimeValue = props.details.profile['lifetimeValue']
                const showLifetime = !!lifetimeValue && lifetimeValue.length > 0
                return `${getCurrencyString(value)}${
                    showLifetime && lifetimeValue ? ` (${getCurrencyString(lifetimeValue)})` : ''
                }`
            case 'averageTransactionValue':
                const averageOrderValue = props.details.profile['averageOrderValue']
                const showOrderValue = !!averageOrderValue && averageOrderValue.length > 0
                return `${getCurrencyString(value)}${
                    showOrderValue && averageOrderValue ? ` (${getCurrencyString(averageOrderValue)})` : ''
                }`
            default:
                return '-'
        }
    }

    const getDeviceTypeValue = (devices: PreferredDeviceTypes[]) => {
        const sorted = devices.sort((a, b) => b.usage - a.usage)
        return sorted[0].deviceType
    }

    const getNewVisitorValue = () => {
        const newVisitor =
            !props.details.profile?.firstSeen ||
            !props.details.profile?.lastSeen ||
            (!!props.details.profile?.firstSeen &&
                !!props.details.profile?.lastSeen &&
                format(props.details.profile.firstSeen, 'yyyy-MM-dd') ===
                    format(props.details.profile.lastSeen, 'yyyy-MM-dd'))
        return newVisitor ? 'New' : 'Returning'
    }

    const getCurrencyString = (values: ValueWithCurrency[]) => {
        const mappedValues = values.map((v) => `${v.amount.toFixed(2)} ${v.currency}`)
        return mappedValues.length > 0 ? mappedValues.join(', ') : '-'
    }

    return (
        <>
            {props.loading && <TableLoader />}
            {!props.loading && (
                <Row>
                    <LeftSideContainer span={4}>
                        <Wrapper>
                            <Initials className="large">
                                {getInitials(props.details.profile?.name || null, props.details.profile?.email || null)}
                            </Initials>
                            <ProfileId title={props.details.cookieId}>
                                ID: <ProfileIdElement id={props.details.cookieId} />
                            </ProfileId>
                            {!!props.details.profile?.firstSeen && (
                                <div style={{ fontSize: '0.75em' }}>
                                    Profile created on {format(props.details.profile.firstSeen, DateFormats.LONG_DATE)}
                                </div>
                            )}
                            {!!props.details.profile?.location && (
                                <Location>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: '0.5em' }} />
                                    {props.details.profile.location}
                                </Location>
                            )}
                            <StatsWrapper>
                                <StatsItem className="right-border">
                                    {(props.details.profile?.ordersCount || 0) -
                                        (props.details.profile?.paymentsCount || 0)}
                                    <span>
                                        Abandoned Carts
                                        <Infotip maxWidth="20em" pointer="left">
                                            A number of shopping carts made on Convious checkout, but exited without
                                            completing the purchase.
                                        </Infotip>
                                    </span>
                                </StatsItem>
                                <StatsItem>
                                    {props.details.profile?.paymentsCount || 0}
                                    <span>
                                        Placed Orders
                                        <Infotip maxWidth="20em" pointer="left">
                                            A number of unique purchases made on the Convious checkout.
                                        </Infotip>
                                    </span>
                                </StatsItem>
                            </StatsWrapper>
                        </Wrapper>
                        {!!props.details.profile?.audiences && props.details.profile.audiences.length > 0 && (
                            <AudiencesListWrapper>
                                <div style={{ marginBottom: '2.2em' }}>Profile attributed to audience(s):</div>
                                {props.details.profile.audiences.map((a) => (
                                    <AudiencesListItem key={a.slug} to={`/account/${props.accountSlug}/crm/audiences/`}>
                                        <>
                                            <FontAwesomeIcon icon={faUserFriends} />
                                            <span>{a.name}</span>
                                        </>
                                    </AudiencesListItem>
                                ))}
                            </AudiencesListWrapper>
                        )}
                    </LeftSideContainer>
                    <RightSideContainer span={8}>
                        <Section>
                            <SectionTitle>Personal details</SectionTitle>
                            <ItemsRow>
                                {props.details.profile?.name || props.details.profile?.email ? (
                                    <>
                                        {props.details.profile?.name && (
                                            <Col span={4}>
                                                <ItemName>Full name</ItemName>
                                                <ItemValue>{props.details.profile?.name || '-'}</ItemValue>
                                            </Col>
                                        )}
                                        <Col span={4}>
                                            <ItemName>Email</ItemName>
                                            <ItemValue>{props.details.profile?.email || '-'}</ItemValue>
                                        </Col>
                                    </>
                                ) : (
                                    <Col span={4}>
                                        <ItemName>No entries</ItemName>
                                    </Col>
                                )}
                            </ItemsRow>
                        </Section>
                        <Section>
                            <SectionTitle>Activity details</SectionTitle>
                            {activityFields.length === 0 && <ItemName>No entries</ItemName>}
                            {activityFields.map((fieldGroup, i) => (
                                <ItemsRow key={i}>
                                    {fieldGroup.map((f, y) => (
                                        <Col span={4} key={f.slug + y}>
                                            <ItemName>
                                                {f.name}
                                                {!!f.tooltipText && (
                                                    <Infotip maxWidth="25em" pointer={y === 0 ? 'left' : 'right'}>
                                                        {f.tooltipText}
                                                    </Infotip>
                                                )}
                                            </ItemName>
                                            <ItemValue>{getActivityFieldValue(f)}</ItemValue>
                                        </Col>
                                    ))}
                                </ItemsRow>
                            ))}
                        </Section>
                        {!!props.details.profile?.products && props.details.profile.products.length > 0 && (
                            <Section>
                                <SectionTitle>Products bought</SectionTitle>
                                <ProductsTable>
                                    <tbody>
                                        <tr>
                                            <ProductsCell className="header">
                                                <ItemName>Product name</ItemName>
                                            </ProductsCell>
                                            <ProductsCell className="header">
                                                <ItemName>Number</ItemName>
                                            </ProductsCell>
                                        </tr>
                                        {props.details.profile.products.map((p, i) => (
                                            <tr key={i}>
                                                <ProductsCell>{p.product_name}</ProductsCell>
                                                <ProductsCell>{p.amount}</ProductsCell>
                                            </tr>
                                        ))}
                                    </tbody>
                                </ProductsTable>
                            </Section>
                        )}
                    </RightSideContainer>
                </Row>
            )}
        </>
    )
}

export default ProfileDetailsPage
