import * as React from 'react'
import styled from 'styled-typed'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { TextArea, TextInputProps, Checkbox } from 'uiComponents/input'
import { Order, SelectedForRefund, TicketForRefund } from 'orders/schema'
import { DataTable, Cell, DataRow } from 'uiComponents/table'
import { ModalDialogTitle, NavigationSection } from 'uiComponents/popups/modal'
import TicketRow, { RefundDataRow } from './refundDialogRow'
import Money from 'uiComponents/money'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { User } from 'auth/state'
import { RefundPaypalDetails } from 'orders/paypalDisputes/schema'
import { AccountNameAndLocation } from 'orders/paypalDisputes/disputesDialog'
import classNames from 'classnames'
import { Button } from '@mui/material'
import { formatISOString } from 'utils/dates'

const FormTextArea = styled(TextArea)<TextInputProps>`
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    font-weight: 400;
    font-size: 0.875rem;
    padding: 0.5rem;
    min-height: 4.5rem;
`
export const InfoRow = styled.div`
    font-size: 0.875rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
`
const RefundHeaderRow = styled(DataRow)`
    height: 2.5rem;
`
const Instructions = styled.div`
    text-align: center;
    font-size: 0.75rem;
    color: ${(props) => props.theme.colors.textLight};
    margin: 0.8rem 0;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;

    &.visible {
        visibility: visible;
        height: 0.75rem;
        opacity: 1;
    }
`
const Confirmation = styled.div`
    text-align: center;
    font-size: 1.5rem;
    width: 250px;
    margin: auto;
    margin-top: -4rem;
    transition: 0.3s;

    &.visible {
        margin-top: 1rem;
    }
`

const ProductList = styled(DataTable)`
    font-size: 0.875rem;
    box-shadow: none;
    margin-top: 2rem;
    max-height: 12.5rem; /* header + 4 rows, best for 1080p screens */
    overflow-y: auto;

    & > :nth-child(2n + 1) {
        background: ${(props) => props.theme.colors.tableRow};
    }
    & > :nth-child(2n) {
        background: ${(props) => props.theme.colors.white};
    }
    & > ${RefundDataRow}:first-child {
        background: ${(props) => props.theme.colors.white};
    }
    & > ${RefundDataRow}.refund-fee-row {
        background: ${(props) => props.theme.colors.white};
    }
`
const ErrorMessage = styled(ValidationMessage)`
    font-size: 0.875rem;
    top: 0.25rem;
    margin-left: 0;
`
const RefundFeeText = styled.label`
    font-weight: bold;
`
const RequestForm = styled(FormItem)`
    position: relative;
    z-index: 1;
`

interface RefundDialogTableProps {
    user: User
    accountSlug: string
    order: Order
    ticketList: TicketForRefund[]
    selectedTickets: SelectedForRefund[]
    totalAmount: string
    partnerRefundFeeAmount: number
    refundReason: string
    noneSelectedMsg: string
    fullRefund: boolean
    partnerRefundFeeOff: boolean
    confirmRefundView: boolean
    refunding: boolean
    showPartialRedeemWarning: boolean
    loading: boolean
    fullRefundOnly: boolean
    cancellation: boolean
    paypalRefund?: RefundPaypalDetails
    toggleFullRefund: () => void
    updateHeight: () => void
    handleOrderItemSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleTicketSelect: (orderItemUuid: string, ticketUuidsList: string[]) => void
    togglePartnerRefundFee: () => void
    handleTextAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    onNext: () => void
    onCancel: () => void
    formatCurrency: (amount: number | string, accountSlug: string) => React.ReactNode
    getCurrency: (accountSlug: string) => Currency
}

function RefundDialog(props: RefundDialogTableProps) {
    const {
        accountSlug,
        formatCurrency,
        user,
        order,
        confirmRefundView,
        loading,
        fullRefund,
        toggleFullRefund,
        showPartialRedeemWarning,
        totalAmount,
        ticketList,
        selectedTickets,
        updateHeight,
        handleOrderItemSelect,
        handleTicketSelect,
        partnerRefundFeeOff,
        togglePartnerRefundFee,
        partnerRefundFeeAmount,
        refundReason,
        handleTextAreaChange,
        noneSelectedMsg,
        onNext,
        onCancel,
        refunding,
        fullRefundOnly,
        cancellation,
        paypalRefund,
    } = props

    const columnWidths =
        confirmRefundView || fullRefundOnly ? [null, '6em', '6.8em', '3em'] : ['0', null, '6em', '6.5em', '3em']
    const totalRefundFee = partnerRefundFeeAmount
    const totalRefundAmount = confirmRefundView ? Number(totalAmount) - totalRefundFee : totalAmount
    const refundDisallowed = totalRefundFee > Number(totalAmount) && !cancellation
    const disableRefundButton = confirmRefundView && (refunding || refundDisallowed)
    const disallowedRefundMsg =
        Number(totalAmount) === 0
            ? 'Total refund amount should be more than a 0.'
            : 'Refund fee should be less than the total.'

    return (
        <div style={{ maxHeight: '80vh' }}>
            <ModalDialogTitle>{cancellation ? 'Cancel items' : 'Refund items'}</ModalDialogTitle>
            {paypalRefund && (
                <>
                    <InfoRow>
                        <span>Case ID:</span>
                        <strong>{paypalRefund.id}</strong>
                    </InfoRow>
                    {user.isAdmin && (
                        <InfoRow>
                            <span>Partner:</span>
                            <AccountNameAndLocation>
                                <span>{paypalRefund.accountName}</span>
                                <span>{paypalRefund.location}</span>
                            </AccountNameAndLocation>
                        </InfoRow>
                    )}
                    <InfoRow>
                        <span>Reason:</span>
                        <strong>{paypalRefund.reason}</strong>
                    </InfoRow>
                    <InfoRow>
                        <span>Requested refund:</span>
                        <strong>
                            <Money amount={paypalRefund.refundAmount.toFixed(2)} accountSlug={props.accountSlug} />
                        </strong>
                    </InfoRow>
                </>
            )}
            <InfoRow>
                <span>Order ID:</span>
                <strong id="order-id">{order.id}</strong>
            </InfoRow>
            <InfoRow>
                <span>Email:</span>
                <strong id="email">{order.customer.email}</strong>
            </InfoRow>
            {order.visitDate && (
                <InfoRow>
                    <span>Visit date:</span>
                    <strong>{formatISOString(order.visitDate, 'dd MMM')}</strong>
                </InfoRow>
            )}
            <div />
            <ProductList columnWidths={columnWidths}>
                {!loading && (fullRefund || (!fullRefund && !confirmRefundView)) && (
                    <RefundHeaderRow style={{ fontWeight: 'bold' }}>
                        {!confirmRefundView && !fullRefundOnly && (
                            <Cell>
                                <Checkbox
                                    id="full-refund-checkbox"
                                    data-testid="orders-full-refund-checkbox"
                                    name="fullRefund"
                                    checked={selectedTickets.length > 0}
                                    onChange={toggleFullRefund}
                                    indeterminateState={!fullRefund}
                                />
                            </Cell>
                        )}
                        <Cell>
                            <strong>{cancellation ? 'Full cancellation' : 'Full refund'}</strong>
                            &nbsp;
                            {showPartialRedeemWarning ? '(includes redeemed tickets)' : ''}
                        </Cell>
                        <Cell />
                        <Cell align="right">
                            <strong>
                                <Money amount={totalAmount} accountSlug={accountSlug} />
                            </strong>
                        </Cell>
                        <Cell />
                    </RefundHeaderRow>
                )}
                {ticketList.map((ticket) => {
                    const filteredSelectList = selectedTickets.filter((st) => st.uuid === ticket.orderItemUuid)
                    const selected = filteredSelectList.length > 0
                    return (
                        <TicketRow
                            key={ticket.orderItemUuid}
                            accountSlug={accountSlug}
                            ticket={ticket}
                            selected={selected}
                            selectedTickets={selected ? filteredSelectList[0].ticketUuids : []}
                            confirmRefundView={confirmRefundView}
                            updateHeight={updateHeight}
                            handleOrderItemSelect={handleOrderItemSelect}
                            handleTicketSelect={handleTicketSelect}
                            fullRefundOnly={fullRefundOnly}
                            cancellation={cancellation}
                        />
                    )
                })}
                {confirmRefundView && !cancellation && !!order.partnerRefundFee && (
                    <RefundDataRow className="refund-fee-row">
                        <Cell>
                            <Checkbox
                                id="partner-refund-fee-checkbox"
                                name="partnerRefundFee"
                                checked={!partnerRefundFeeOff}
                                onChange={togglePartnerRefundFee}
                                style={{ marginRight: '.5em' }}
                            />
                            <RefundFeeText htmlFor="partner-refund-fee-checkbox">Visitor Refund Fee</RefundFeeText>
                        </Cell>
                        <Cell />
                        <Cell align="right">
                            <RefundFeeText>
                                {!!partnerRefundFeeAmount && <>-</>}
                                <Money amount={Number(partnerRefundFeeAmount).toFixed(2)} accountSlug={accountSlug} />
                            </RefundFeeText>
                        </Cell>
                        <Cell />
                    </RefundDataRow>
                )}
            </ProductList>
            <ErrorMessage
                className={classNames({
                    'validation-message-visible': noneSelectedMsg || (confirmRefundView && refundDisallowed),
                })}
            >
                {confirmRefundView && refundDisallowed ? disallowedRefundMsg : noneSelectedMsg || '&nbsp;'}
            </ErrorMessage>
            <Instructions className={classNames({ visible: !confirmRefundView })}>
                {fullRefundOnly
                    ? `Only full ${cancellation ? 'cancellation' : 'refund'} is allowed`
                    : `Uncheck items if you want to make a partial ${cancellation ? 'cancellation' : 'refund'}`}
            </Instructions>
            <RequestForm htmlFor="request">
                <FormItemName>Description</FormItemName>
                <FormTextArea
                    id="request"
                    name="request"
                    maxLength={4000}
                    placeholder={`Please write a reason why items requires a ${
                        cancellation ? 'cancellation' : 'refund'
                    }`}
                    value={refundReason ? refundReason : ''}
                    onChange={handleTextAreaChange}
                />
            </RequestForm>
            <Confirmation id="refund-conf-msg" className={classNames({ visible: confirmRefundView })}>
                Are you sure you want to {cancellation ? 'cancel' : 'refund'} this items?
            </Confirmation>
            <NavigationSection>
                <Button variant="outlined" size="large" onClick={onCancel} color="secondary">
                    {confirmRefundView ? (cancellation ? 'Edit cancellation' : 'Edit refund') : 'Cancel'}
                </Button>
                <Button
                    variant="outlined"
                    size="large"
                    id="refund-next-button"
                    onClick={onNext}
                    color="error"
                    disabled={disableRefundButton}
                >
                    {confirmRefundView ? (
                        cancellation ? (
                            'Cancel items'
                        ) : (
                            <>Refund {formatCurrency(Number(totalRefundAmount).toFixed(2), accountSlug)}</>
                        )
                    ) : cancellation ? (
                        'Review cancellation'
                    ) : (
                        'Review refund'
                    )}
                </Button>
            </NavigationSection>
        </div>
    )
}

export default withCurrency(RefundDialog)
