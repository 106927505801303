import { createSelector } from '@reduxjs/toolkit'
import { State } from 'store'

export const isUserReseller = (state: State) => !!state.auth.user?.resellerId
export const getActiveAccountSlug = (state: State) => state.preferences.activeAccount
export const getDateLocale = (state: State) => state.preferences.dateLocale
export const getAccounts = (state: State) => state.auth.user?.accounts

export const getCurrentAccount = createSelector(getActiveAccountSlug, getAccounts, (accountSlug, accounts) => {
    return accounts?.find((account) => account.slug === accountSlug)
})

export const getIsUSPartner = (state: State) => getCurrentAccount(state)?.countryCode === 'US'
