import * as React from 'react'
import styled from 'styled-typed'
import Infotip from 'uiComponents/infotip'
import { PageHeaderTooltipContainer } from 'uiComponents/pageElements'
import { Disclamer } from 'dashboard/statsCard'

const TooltipContainer = styled.div`
    position: absolute;
    z-index: 1;
    top: 1.75em;
    left: -1.4em;
`

const ListSection = styled.div`
    margin-top: 1em;
`
const List = styled.ol`
    margin: 0.35em 0 0 0;
    padding-left: 1.1em;
`

interface ReportsPageHeaderInfotipProps {
    pointerOffset: number
    boxOffset: number
    children: React.ReactNode
}

export function ReportsPageHeaderInfotip(props: ReportsPageHeaderInfotipProps) {
    return (
        <PageHeaderTooltipContainer>
            <Infotip pointer="left" maxWidth="69em" pointerOffset={props.pointerOffset} boxOffset={props.boxOffset}>
                {props.children}
            </Infotip>
        </PageHeaderTooltipContainer>
    )
}

interface ChartInfotipProps {
    children: React.ReactNode
    maxWidth: string
    minWidth?: string
}

export function ChartInfotip(props: ChartInfotipProps) {
    return (
        <TooltipContainer>
            <Infotip pointer="left" maxWidth={props.maxWidth} minWidth={props.minWidth}>
                {props.children}
            </Infotip>
        </TooltipContainer>
    )
}

export function TrafficSourseInfotipContent() {
    return (
        <div>
            Sources represent where your visitors are coming from or have been prior to visiting your page. This can be
            a website or a mobile application. To land on your website, your visitor used a medium. These mediums can be
            social media links, an email, a banner, or a paid search ad. Thus, Channels are groupings of the mediums or
            vehicles used to get to your website.
            <ListSection>
                Default marketing channels in Convious analytics are:
                <List>
                    <li>Organic search</li>
                    <li>Paid search</li>
                    <li>Display</li>
                    <li>Direct</li>
                    <li>Social</li>
                    <li>Email</li>
                    <li>Other</li>
                </List>
            </ListSection>
            <Disclamer>* Reservations and order changes excluded</Disclamer>
        </div>
    )
}

export function TrafficSourseInfotip() {
    return (
        <ChartInfotip maxWidth="40em">
            <TrafficSourseInfotipContent />
        </ChartInfotip>
    )
}
