import conviousLogo from 'images/convious-logo.svg'
import React, { memo, useState } from 'react'
import styled from 'styled-typed'
import Svg from 'svg'
import { ActionButtonA } from 'uiComponents/buttons'
import { Body, Headline } from 'uiComponents/typography'
import welcomeImage from '../assets/welcome.png'
import RequestCustomAudiencesDialog from '../components/requestCustomAudiences'

const Container = styled.div`
    height: 660px;
    max-width: 1200px;
    background: #ffffff;
    display: flex;
    flex-direction: row;
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 67px;
`

const Buttons = styled.div`
    margin-top: 63px;
    gap: 8px;
`

const Content = styled.div`
    padding: 100px 40px 100px 84px;
`

const ConviousLogo = styled(Svg).attrs((props) => ({ src: conviousLogo }))`
    height: 12.4px;
    margin-left: 8px;
    color: ${(props) => props.theme.colors.convious};
`

const Image = styled.img.attrs((props) => ({ src: welcomeImage }))`
    flex: 1;
`

const AudiencesWelcome = () => {
    const [requestDialogVisible, setRequestDialogVisible] = useState<boolean>(false)
    const [requestSent, setRequestSent] = useState<boolean>(false)

    return (
        <>
            {requestDialogVisible && (
                <RequestCustomAudiencesDialog
                    onCancel={() => setRequestDialogVisible(false)}
                    requestSent={requestSent}
                    setRequestSent={setRequestSent}
                />
            )}
            <Container>
                <Content>
                    <Header>
                        <ConviousLogo />
                    </Header>
                    <Headline size={2} style={{ margin: 0, marginBottom: '2rem', fontWeight: 500 }} bold>
                        Communicate with Audiences
                    </Headline>
                    <Body size={1} weight="regular">
                        Use our predefined audiences to target specific types of customers and enhance your marketing
                        campaigns with our tool. Get a sneak peek at our Audience solution in our{' '}
                        <a
                            href="https://support.convious.com/help/audiences-in-your-engage-tools-overview-campaign-inspiration"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Knowledge Base
                        </a>
                    </Body>
                    <Buttons>
                        <ActionButtonA onClick={() => setRequestDialogVisible(true)} size="large">
                            Request module
                        </ActionButtonA>
                    </Buttons>
                </Content>
                <Image />
            </Container>
        </>
    )
}

export default memo(AudiencesWelcome)
