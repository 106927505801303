import * as React from 'react'
import styled from 'styled-components'
import ComputerSvg from '../../assets/computer.svg'
import PhoneSvg from '../../assets/phone.svg'
import { PHONE, WEB } from './consts'
import { PreviewTogglers } from './types'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`

const Toggler = styled.a`
    display: flex;
    justify-content: center;
    height: 35px;
    width: 35px;
    padding: 8px;
    border-width: 1px;
    border-color: ${(props) => props.theme.colors.textDark};
    border-style: solid;
    border-radius: 6px;
    background: ${(props) => props.theme.colors.white};
    cursor: pointer;
`

const Left = styled(Toggler)`
    background: ${(props) => props.theme.colors.white};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-style: none;
    fill: ${(props) => props.theme.colors.textDark};
`

const LeftActive = styled(Left)`
    background: ${(props) => props.theme.colors.textDark};
    fill: ${(props) => props.theme.colors.white};
`

const Right = styled(Toggler)`
    fill: ${(props) => props.theme.colors.textDark};
    background: ${(props) => props.theme.colors.white};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-style: none;
    fill: ${(props) => props.theme.colors.textDark};
`

const RightActive = styled(Right)`
    background: ${(props) => props.theme.colors.textDark};
    fill: ${(props) => props.theme.colors.white};
`

interface TogglePreviewProps {
    preview: string
    onToggle: (preview: PreviewTogglers) => void
}

const TogglePreview: React.FC<TogglePreviewProps> = ({ preview, onToggle }) => {
    const LeftToggler = preview === WEB ? LeftActive : Left
    const RightToggler = preview === PHONE ? RightActive : Right

    return (
        <Container>
            <LeftToggler onClick={() => onToggle(WEB)}>
                <ComputerSvg />
            </LeftToggler>
            <RightToggler onClick={() => onToggle(PHONE)}>
                <PhoneSvg />
            </RightToggler>
        </Container>
    )
}

export default TogglePreview
