import React, { ClassAttributes, InputHTMLAttributes } from 'react'
import styled from 'styled-typed'

interface ListProps extends InputHTMLAttributes<HTMLInputElement>, ClassAttributes<HTMLInputElement> {
    component?: string | React.FunctionComponent
}

const List: React.FC<ListProps> = ({ component = 'ul', children, ...props }) => {
    return React.createElement(component, props, children)
}

export default styled(List)`
    list-style-type: none;
`
