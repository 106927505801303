import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-typed'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

export const IconContainer = styled.div`
    position: relative;
    margin: 0.25rem 0.25rem 0 0;
`
export const Icon = styled(FontAwesomeIcon)`
    position: absolute;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in;
    font-size: 0.85rem;
    &.visible {
        opacity: 1;
        z-index: 1;
    }
`

const ToggleSelectIcon = ({ open }: { open: boolean }) => {
    return (
        <IconContainer>
            <Icon icon={faChevronDown} className={open ? '' : 'visible'} />
            <div>
                <Icon icon={faChevronUp} className={open ? 'visible' : ''} />
            </div>
        </IconContainer>
    )
}

export default ToggleSelectIcon
