import { generalIcons, animalsIcons } from '@convious/tap-icons'
import { useFormikContext } from 'formik'
import { useState } from 'react'
import * as React from 'react'
import { useTheme } from 'styled-components'
import { ModalDialog } from 'uiComponents/popups/modal'
import styled from 'styled-typed'
import { ActionButtonA } from 'uiComponents/buttons'
import TextInputFieldFormik from 'uiComponents/input/textInput/textInputFieldFormik'
import Svg from 'svg'
import { POI } from '../../../models'
import InformationBox from '../../informationBox'
import { Field } from '../../mapDetails/mapForm'
import MapTranslations from '../../mapTranslations'
import IconSelection from '../IconSelection'
const tapIcons = [...generalIcons, ...animalsIcons]

const ModalDialogBody = styled.div`
    font-weight: lighter;
    width: 1200px;
    line-height: 1.6em;
    display: flex;
    justify-content: space-between;
`

const Container = styled.div`
    display: block;
    padding: 2em 1.5em 2em 1.5em;
    width: 800px;
`

const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 2em;
`

const Preview = styled.div`
    background-color: ${(props) => props.theme.colors.background};
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Title = styled.div`
    font-size: 1.4em;
    font-weight: 500;
`

const PreviewModal = styled.div`
    min-width: 250px;
    max-width: 80%;
    border-radius: 20px;
    background-color: ${(props) => props.theme.colors.white};
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #151515;
    text-align: center;
    box-shadow: 0px 1px 0px 0px #0000000f;
`

const ButtonTap = styled.div`
    border-radius: 24px;
    background-color: #ff155b;
    color: ${(props) => props.theme.colors.white};
    padding: 10px 12px;
    font-weight: 400;
    margin-top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const PreviewTitle = styled.div`
    font-size: 1.4em;
    font-weight: 500;
    padding-bottom: 8px;
`

const PreviewText = styled.div`
    font-size: 0.8em;
    line-height: 1.2em;
    font-weight: 300;
    opacity: 0.7;
`

export const Icon = styled(Svg)`
    width: 14px;
    margin: 0;
    margin-right: 8px;
`

const FieldA = styled(Field)`
    margin-bottom: 0.8em;
`

const Info = styled.div`
    margin: 1em 0 1.5em 0;
`

const Error = styled.div`
    font-size: 0.75em;
    font-weight: 400;
    color: ${(props) => props.theme.colors.status.error};
`

interface DialogProps {
    onCancel: () => void
    onSave: () => void
}

const ActionPoiDialog = (props: DialogProps) => {
    const theme = useTheme()
    const { values } = useFormikContext<POI>()
    const [linkError, setLinkError] = useState<boolean>(false)
    const previewContent = values?.action?.content?.[0]
    const previewIcon = tapIcons.find((icon) => icon.name === values?.action?.icon)?.icon

    const save = () => {
        if (!values.action?.link) {
            setLinkError(true)

            return
        }

        props.onSave()
    }

    return (
        <ModalDialog onDismiss={props.onCancel} interactive fromTop="10%" style={{ padding: 0, maxHeight: '80%' }}>
            <ModalDialogBody>
                <Container>
                    <Title>Action block - Hyperlink</Title>
                    <Info>
                        <InformationBox text="The action block is shown on the page of the point of interest (POI). The action block can be used for additional information for the guest." />
                    </Info>
                    <FieldA>
                        <TextInputFieldFormik
                            id="actionlink"
                            name="action.link"
                            label="Url"
                            placeholder="https://"
                            style={{
                                border: linkError ? `1px solid ${theme.colors.status.error}` : 'none',
                                width: '100%',
                            }}
                        />
                        {linkError && <Error>Required</Error>}
                    </FieldA>
                    <MapTranslations
                        fieldName="action.content"
                        fields={[
                            { name: 'title', label: 'Title', type: 'input', placeholder: 'title' },
                            { name: 'subtitle', label: 'Description', type: 'textarea', placeholder: 'description' },
                            {
                                name: 'buttonCaption',
                                label: 'Button caption',
                                type: 'input',
                                placeholder: 'button caption',
                            },
                        ]}
                    />
                    <Field>
                        <IconSelection
                            field="action.icon"
                            tooltip="Select an icon for action button"
                            allowEmptySelection={true}
                        />
                    </Field>
                    <Buttons>
                        <ActionButtonA secondary style={{ marginRight: '1em' }} onClick={props.onCancel}>
                            Cancel
                        </ActionButtonA>
                        <ActionButtonA onClick={save}>Save</ActionButtonA>
                    </Buttons>
                </Container>
                <Preview>
                    <PreviewModal>
                        <PreviewTitle>{previewContent?.title || 'Title'}</PreviewTitle>
                        <PreviewText>{previewContent?.subtitle || 'Description'}</PreviewText>
                        <ButtonTap>
                            {previewIcon && <Icon src={previewIcon} />}
                            {previewContent?.buttonCaption || 'Button caption'}
                        </ButtonTap>
                    </PreviewModal>
                </Preview>
            </ModalDialogBody>
        </ModalDialog>
    )
}

export default ActionPoiDialog
