import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import ActionButton, { ActionButtonA } from 'uiComponents/buttons'
import { FormWrapper } from 'uiComponents/form/form'
import { Headline } from 'uiComponents/typography'
import { Category } from 'venue/parkMap/models'
import { ParkMapPagesParams } from 'venue/parkMap/paths'
import * as yup from 'yup'
import ColorsRowSelector from 'uiComponents/colorsRowSelector'
import MapTranslations from '../../mapTranslations'
import IconSelection from '../IconSelection'
import { getIconSvg } from '../utils'

const Field = styled.div`
    margin-bottom: 2em;
`

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const FormConatiner = styled.div`
    flex: 1;
    display: flex;
    & > form {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`

const FormBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
`

export const FormFooter = styled.div`
    border-top-width: 0.063em;
    border-top-style: solid;
    border-top-color: ${(props) => props.theme.colors.border};
    padding-top: 1em;
    display: flex;
    justify-content: flex-end;
    gap: 0.5em;
`

interface CategoryFormProps {
    data?: Category
    pageTitle: string
    onSubmit(category: Category): void
}

const CategoryForm = ({ data, pageTitle, onSubmit }: CategoryFormProps) => {
    const { accountSlug, parkMapId } = useParams<ParkMapPagesParams>()

    const validationSchema = yup.object().shape({
        content: yup.array(
            yup.object({
                locale: yup.string().required('Required'),
                name: yup.string().required('Required'),
            }),
        ),
    })

    const submit = (values: Category) => {
        const contents = values?.content
            .filter((content) => !!content.name && !!content.locale)
            .map((content) => {
                return { ...content, default: !!content.default }
            })

        if (!contents.length) {
            contents.push({
                name: '',
                locale: 'en',
                default: true,
            })
        }

        onSubmit({ ...values, iconSvg: getIconSvg(values.icon), content: contents })
    }

    return (
        <Container>
            <Headline size={4} style={{ marginBottom: '0.8em' }}>
                {pageTitle}
            </Headline>
            <FormConatiner>
                <FormWrapper
                    enableReinitialize
                    initialValues={data}
                    onSubmit={(values) => submit(values)}
                    validationSchema={validationSchema}
                >
                    <FormBody>
                        <IconSelection field="icon" tooltip="Select an icon representing the category" />
                        <ColorsRowSelector field="color" />
                        <Field>
                            <MapTranslations
                                fieldName="content"
                                fields={[{ name: 'name', label: 'Name', type: 'input', placeholder: 'name' }]}
                            />
                        </Field>
                    </FormBody>
                    <FormFooter>
                        <ActionButtonA
                            secondary
                            size="large"
                            href={`/account/${accountSlug}/venue/park_map/${parkMapId}/cat_poi`}
                        >
                            Cancel
                        </ActionButtonA>
                        <ActionButton size="large" type="submit">
                            Save
                        </ActionButton>
                    </FormFooter>
                </FormWrapper>
            </FormConatiner>
        </Container>
    )
}

export default CategoryForm
