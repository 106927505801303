import * as React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-typed'
import { Navigation, renderSearch } from 'navigation'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { IconProp } from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { closeSideBar } from './reducer'

const ChevronDiv = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    visibility: hidden;
    color: ${(props) => props.theme.colors.textLight};
`

export const MenuLink = styled(NavLink)`
    padding: 0.6em 0;
    font-size: 0.8125rem;
    color: ${(props) => props.theme.colors.textDark};
    line-height: 20px;
    display: block;
    outline: none;
    text-decoration: none;
    transition: color 0.2s ease-in;
    font-weight: normal;

    &.active {
        color: ${(props) => props.theme.colors.boyBlue};
        & svg {
            color: ${(props) => props.theme.colors.boyBlue};
        }
        & ${ChevronDiv} {
            visibility: visible;
        }
    }
    &:hover:not(.active) {
        color: ${(props) => props.theme.colors.boyBlue};
        & svg {
            color: ${(props) => props.theme.colors.boyBlue};
            transition: color 0.2s ease-in;
        }
        & ${ChevronDiv} svg {
            visibility: visible;
            color: ${(props) => props.theme.colors.textLight};
        }
    }
`

const SublinkList = styled.ul`
    padding: 0;
    margin: 0;
    margin-left: 1.6em;
    list-style-type: none;
`
const SublinkArea = styled.div`
    position: relative;
    z-index: 50;
    box-shadow: none;
`
const Sublinks = styled.li`
    display: ${(props: SublinkProps) => (props.show ? 'list-item' : 'none')};
    & a {
        padding: 0.5em 0 0.5em 0;
        font-weight: 300;
    }
`

const Separator = styled.div`
    margin: 0.4em 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
`

const BetaSign = styled.div`
    font-size: 0.5625rem;
    font-weight: 400;
    position: absolute;
    right: -2.7em;
    top: -0.25em;
    color: ${(props) => props.theme.colors.boyBlue};
`

const OldSign = styled.div`
    font-size: 0.5625rem;
    font-weight: 400;
    position: absolute;
    right: -2.4em;
    top: -0.25em;
    color: ${(props) => props.theme.colors.boyBlue};
`

const Badge = styled.div`
    height: 1.25rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.25rem;
    border-radius: 0.625rem;
    font-size: 0.75rem;
    font-weight: 500;
    position: absolute;
    left: calc(100% + 0.25rem);
    top: -0.25em;
    background-color: ${(props) => props.theme.colors.boyBlue};
    color: ${(props) => props.theme.colors.white};
`

interface SublinkProps {
    show: boolean
}

interface MenuItemProps {
    children?: React.ReactNode
    path: string
    icon?: IconProp
    text: string
    style?: object
    className?: string
    userpilot?: string
    navigation: Navigation
    match: RouteMatch<any>
    onClick?: (path: string) => void
    bordered?: boolean
    count?: number
    close?: boolean
    withBeta?: boolean
    withOld?: boolean
}

function MenuItem(props: MenuItemProps) {
    const { path, icon, text, children, className, userpilot, count } = props
    const originalQuery = props.navigation.query()
    const persistedQueryItems = ['dateRange', 'dateFrom', 'dateTo', 'period']
    const query = Object.keys(originalQuery)
        .filter((key) => persistedQueryItems.indexOf(key) > -1)
        .reduce((obj, key) => {
            obj[key] = originalQuery[key]
            return obj
        }, {})
    const persistedSearch = renderSearch(query)
    const [expanded, setExpanded] = React.useState<boolean>(false)
    const dispatch = useDispatch()

    React.useEffect(() => {
        if (props.children && props.match.url.indexOf(props.path) > -1) {
            setExpanded(true)
        }
    }, [])

    React.useEffect(() => {
        if (props.close) {
            setExpanded(false)
        }
    }, [props.close])

    const toggleExpanded = (e: React.MouseEvent<any>) => {
        if (props.children) {
            e.preventDefault()
            setExpanded(!expanded)
        }

        if (props.onClick) {
            props.onClick(props.path)
        }

        if (!props.children) {
            dispatch(closeSideBar())
        }
    }

    return (
        <>
            <li data-userpilot={userpilot}>
                <MenuLink
                    to={{ pathname: path, search: persistedSearch }}
                    onClick={toggleExpanded}
                    className={className}
                >
                    <div style={{ display: 'flex' }}>
                        {icon && (
                            <div style={{ width: '25px' }}>
                                <FontAwesomeIcon icon={icon} />
                            </div>
                        )}
                        <div style={{ position: 'relative' }}>
                            {!!props.withBeta && <BetaSign>BETA</BetaSign>}
                            {!!props.withOld && <OldSign>OLD</OldSign>}
                            {text}
                            {!!props.count && <Badge>{count}</Badge>}
                        </div>
                        {!!children && (
                            <ChevronDiv>
                                <FontAwesomeIcon
                                    style={{ fontSize: '.8em' }}
                                    icon={expanded ? faChevronUp : faChevronDown}
                                />
                            </ChevronDiv>
                        )}
                    </div>
                </MenuLink>
            </li>
            {children && (
                <Sublinks show={expanded}>
                    <SublinkArea>
                        <SublinkList>{children}</SublinkList>
                    </SublinkArea>
                </Sublinks>
            )}
            {props.bordered && (
                <li>
                    <Separator />
                </li>
            )}
        </>
    )
}

export default withNavigation(MenuItem)
