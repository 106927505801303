import * as React from 'react'
import {
    InlineDataTable,
    HeaderRow,
    TableHeader,
    Cell,
    MultipleStatus,
    NameAndStatus,
    Name,
    DataRow,
} from 'uiComponents/table'
import { WeekdayPattern } from 'uiComponents/weekdayPattern'
import { InventoryRule } from 'inventory/inventoryService'
import { ActionButtonA } from 'uiComponents/buttons'
import { format, startOfToday } from 'date-fns'
import { DateRange, TimeRange } from 'uiComponents/date'

interface RulesListProps {
    accountSlug: string
    rulesList: InventoryRule[]
    inventoryId: string
    hasFeature: (feature: string, accountSlug: string) => boolean
}

type Status = 'success' | 'error' | 'disabled' | 'warn'

interface InventoryRuleWithStatus extends InventoryRule {
    status: Status
}

export function RulesList(props: RulesListProps) {
    const [rulesListWithStatus, setRulesListWithStatus] = React.useState<InventoryRuleWithStatus[]>([])

    const getStatus = (d: InventoryRule) => {
        let status: Status = 'success'
        if (d.effectiveFrom && d.effectiveTo) {
            const today = format(startOfToday(), 'yyyy-MM-dd')
            if (today < d.effectiveFrom) {
                status = 'warn'
            } else if (today > d.effectiveTo) {
                status = 'error'
            }
        }
        return status
    }

    React.useEffect(() => {
        const mappedRules = props.rulesList.map((r) => ({
            ...r,
            status: getStatus(r),
        }))
        setRulesListWithStatus(mappedRules)
    }, [props.rulesList])

    const columnWidths = [null, '5em', '9em', '10em', '9.5em', '6em', '8em']

    return (
        <>
            <InlineDataTable columnWidths={columnWidths} id="time-slots-list">
                <HeaderRow>
                    <TableHeader>Name</TableHeader>
                    <TableHeader align="center">Limit</TableHeader>
                    <TableHeader align="center">Time slot</TableHeader>
                    <TableHeader>Weekly Pattern</TableHeader>
                    <TableHeader>Date range</TableHeader>
                    <TableHeader align="center">Exception priority</TableHeader>
                    <TableHeader nonInteractive />
                </HeaderRow>
                {rulesListWithStatus.map((r) => (
                    <DataRow key={r.id} className={r.status === 'success' ? '' : 'inactive'}>
                        <Cell title={r.name || 'Rule name'}>
                            <NameAndStatus>
                                <Name className="name">{r.name ? r.name : 'Exception name'}</Name>
                                <MultipleStatus
                                    status={r.status}
                                    withText
                                    successText="Active"
                                    errorText="Expired"
                                    warnText="Upcoming"
                                />
                            </NameAndStatus>
                        </Cell>
                        <Cell align="center">{r.availability}</Cell>
                        <Cell align="center">
                            <TimeRange startDateString={r.timeFrom} endDateString={r.timeTo} fallback="N/A" />
                        </Cell>
                        <Cell>
                            {r.weekdays && (
                                <WeekdayPattern
                                    weekdays={r.weekdays.length > 0 ? r.weekdays.toString() : '0,1,2,3,4,5,6'}
                                    type="strict"
                                />
                            )}
                        </Cell>
                        <Cell className="dates wrapContent">
                            <DateRange startDateString={r.effectiveFrom} endDateString={r.effectiveTo} fallback="N/A" />
                        </Cell>
                        <Cell align="center">{r.priority}</Cell>
                        <Cell align="right">
                            <ActionButtonA
                                className="editTimeSlot"
                                kind="action"
                                secondary
                                href={`/account/${props.accountSlug}/products/inventory/rule/${r.id}/item/${props.inventoryId}`}
                            >
                                Edit
                            </ActionButtonA>
                        </Cell>
                    </DataRow>
                ))}
            </InlineDataTable>
        </>
    )
}
