import React, { useEffect, useState } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { last } from 'lodash'
import styled from 'styled-typed'

import { NumberInput } from 'uiComponents/input'

const Container = styled.div`
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    background-color: ${(props) => props.theme.colors.tableRow};
    font-size: 0.875rem;
    line-height: 1rem;
    padding: 0.25rem;
`

const PageNumberSection = styled.div`
    display: flex;
    align-items: center;
    margin-left: 2rem;
`
const SectionItem = styled.span`
    padding: 0.25rem 0.5rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.textLight};
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid transparent;

    &.disabled {
        color: ${(props) => props.theme.colors.border};
    }

    &:not(.disabled):hover {
        border: 1px solid ${(props) => props.theme.colors.border};
    }

    &:not(.disabled):focus {
        outline: unset;
        border: 1px solid ${(props) => props.theme.colors.boyBlueShades[60]};
    }

    &:not(.disabled):active {
        background-color: ${(props) => props.theme.colors.aluminiumShades[5]};
        border: 1px solid ${(props) => props.theme.colors.border};
    }
`

const Page = styled(SectionItem)`
    padding: 0.05rem 0.4rem 0.05rem 0.4rem;
    border-radius: 2px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.textLight};

    &:not(:nth-last-child(2)) {
        margin-right: 0.1rem;
    }

    &.active:not(.spreader) {
        background: ${(props) => props.theme.colors.aluminium};
        color: ${(props) => props.theme.colors.white};
    }
`

const Icon = styled(FontAwesomeIcon)`
    font-size: 0.7rem;
    margin: 0 1rem 0.1rem 1rem;
`

const EnterPageSection = styled.div`
    margin-left: 1.5rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.textLight};
`

const PageContainer = styled.div`
    display: inline-block;
    margin: 0 2rem;
`

const PageInput = styled(NumberInput)<{ whiteBackground?: boolean }>`
    display: inline-block;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.border};
    height: 24px;
    padding: 3px 0 3px 0.5rem;
    margin: 0 2rem 0 0.5rem;
    width: 43px;
    box-sizing: border-box;

    &:focus {
        border-color: ${(props) => props.theme.colors.boyBlueShades[60]};
    }
`

interface Props {
    pageNumbers: number[]
    currentPage: number
    onPageChange: (page: number) => void
}

const PaginationComponent: React.FC<Props> = ({ pageNumbers, currentPage, onPageChange }) => {
    const [page, setPage] = useState<number>(currentPage)
    const lastPage = last(pageNumbers)

    useEffect(() => {
        setPage(Number(currentPage).toFixed() as unknown as number)
    }, [currentPage])

    return (
        <Container>
            <PageNumberSection>
                <div>
                    <SectionItem
                        className={classNames({
                            active: currentPage === pageNumbers[0],
                            disabled: currentPage === 1,
                        })}
                        onClick={() => onPageChange(currentPage - 1)}
                        tabIndex={currentPage === 1 ? -1 : 0}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onPageChange(currentPage - 1)
                            }
                        }}
                    >
                        <Icon icon={faArrowLeft as IconProp} />
                        Prev
                    </SectionItem>
                    <PageContainer>
                        {pageNumbers.map((page, i) => (
                            <Page
                                key={i}
                                className={currentPage === page ? 'active' : isNaN(Number(page)) ? 'spreader' : ''}
                                onClick={() => onPageChange(Number(page))}
                                tabIndex={0}
                            >
                                {page}
                            </Page>
                        ))}
                    </PageContainer>
                    <SectionItem
                        className={classNames({
                            active: currentPage !== pageNumbers[pageNumbers.length - 1],
                            disabled: currentPage === pageNumbers[pageNumbers.length - 1],
                        })}
                        onClick={() => {
                            if (currentPage !== pageNumbers[pageNumbers.length - 1]) {
                                onPageChange(currentPage + 1)
                            }
                        }}
                        tabIndex={currentPage === pageNumbers[pageNumbers.length - 1] ? -1 : 0}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onPageChange(currentPage + 1)
                            }
                        }}
                    >
                        Next
                        <Icon icon={faArrowRight as IconProp} />
                    </SectionItem>
                </div>
                <EnterPageSection>
                    Go to
                    <PageInput
                        integerOnly
                        min={1}
                        placeholder="1"
                        value={page}
                        onChange={(e) => {
                            const newPage = e.target.value
                            setPage(newPage ? Number.parseInt(newPage, 10) : 1)
                        }}
                        onFinishTyping={(page) => {
                            if (lastPage && lastPage < Number.parseInt(page, 10)) {
                                return setPage(Number.parseInt(lastPage.toString(), 10))
                            }
                        }}
                    />
                    <SectionItem
                        tabIndex={0}
                        onClick={() => {
                            onPageChange(page)
                        }}
                    >
                        Go
                    </SectionItem>
                </EnterPageSection>
            </PageNumberSection>
        </Container>
    )
}

export default PaginationComponent
