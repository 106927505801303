import React from 'react'
import styled, { css, FlattenInterpolation } from 'styled-typed'
import { Route } from 'react-router-dom'
import { History } from 'history'
import { transparency } from 'utils/css'
import { delay } from 'utils'

export type ButtonKind = 'default' | 'action' | 'success' | 'destructive' | 'settings'
export type ButtonSize = 'small' | 'medium' | 'large'

export interface ActionButtonProps {
    className?: string
    style?: React.CSSProperties
    block?: boolean
    kind?: ButtonKind
    size?: ButtonSize
    secondary?: boolean
    doneText?: string
    width?: string
    stopPropagation?: boolean
    disabled?: boolean
}

const defaultCss = css`
    background-color: ${(props) => props.theme.colors.boyBlue};
    color: ${(props) => props.theme.colors.white};

    &:hover:not(:disabled) {
        box-shadow: 0px -2px 2px 0px rgba(${(props) => props.theme.colors.richBlack}, 0.1) inset;
    }

    &:focus:not(:disabled) {
        border-color: ${(props) => props.theme.colors.boyBlueShades['60']};
        box-shadow: 0px -2px 2px 0px rgba(${(props) => props.theme.colors.richBlack}, 0.1) inset;
    }

    &:active:not(:disabled) {
        background-color: ${(props) => props.theme.colors.boyBlueShades['60']};
    }

    &:disabled {
        background-color: ${(props) => props.theme.colors.aluminiumShades['20']};
        color: ${(props) => props.theme.colors.aluminiumShades['30']};
    }
`

export const actionCss = css`
    background-color: ${(props) => props.theme.colors.boyBlue};
    color: ${(props) => props.theme.colors.white};

    &:hover:not(:disabled) {
        box-shadow: 0px -2px 2px 0px rgba(${(props) => props.theme.colors.richBlack}, 0.1) inset;
    }

    &:focus:not(:disabled) {
        border-color: ${(props) => props.theme.colors.boyBlueShades['60']};
        box-shadow: 0px -2px 2px 0px rgba(${(props) => props.theme.colors.richBlack}, 0.1) inset;
    }

    &:active:not(:disabled) {
        background-color: ${(props) => props.theme.colors.boyBlueShades['60']};
    }

    &:disabled {
        background-color: ${(props) => props.theme.colors.aluminiumShades['20']};
        color: ${(props) => props.theme.colors.aluminiumShades['30']};
    }
`

const successCss = css`
    background: ${(props) => props.theme.colors.status.success};
`
const destructiveCss = css`
    background: ${(props) => props.theme.colors.status.error};

    &:hover:not(:disabled) {
        background: hsl(8, 90%, 60%);
    }
`

const kinds: { [key in ButtonKind]: FlattenInterpolation<any> } = {
    default: defaultCss,
    action: actionCss,
    success: successCss,
    destructive: destructiveCss,
    settings: defaultCss,
}

const secondaryDefaultCss = css`
    border: 1px solid ${(props) => props.theme.colors.boyBlue};
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.boyBlue};

    &:hover:not(:disabled) {
        background-color: ${(props) => props.theme.colors.boyBlueShades['5']};
    }

    &:focus:not(:disabled) {
        border-color: ${(props) => props.theme.colors.boyBlueShades['60']};
        background-color: ${(props) => props.theme.colors.boyBlueShades['5']};
    }

    &:active:not(:disabled) {
        background-color: ${(props) => props.theme.colors.boyBlueShades['10']};
    }

    &:disabled {
        border: 1px solid ${(props) => props.theme.colors.aluminiumShades['20']};
        color: ${(props) => props.theme.colors.aluminiumShades['20']};
    }
`

const secondaryActionCss = css`
    border: 1px solid ${(props) => props.theme.colors.boyBlueShades['20']};
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.boyBlue};

    &:hover:not(:disabled) {
        background-color: ${(props) => props.theme.colors.boyBlueShades['5']};
    }

    &:focus:not(:disabled) {
        border-color: ${(props) => props.theme.colors.boyBlueShades['60']};
        background-color: ${(props) => props.theme.colors.boyBlueShades['5']};
    }

    &:active:not(:disabled) {
        border: 1px solid ${(props) => props.theme.colors.boyBlue};
        background-color: ${(props) => props.theme.colors.boyBlueShades['10']};
    }

    &:disabled {
        border: 1px solid ${(props) => props.theme.colors.aluminiumShades['20']};
        color: ${(props) => props.theme.colors.aluminiumShades['20']};
    }
`

const secondarySettingsCss = css`
    border: 1px solid ${(props) => props.theme.colors.aluminiumShades['20']};
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.textDark};

    &:hover:not(:disabled) {
        background-color: ${(props) => props.theme.colors.background};
    }

    &:focus:not(:disabled) {
        border-color: ${(props) => props.theme.colors.boyBlueShades['60']};
    }

    &:active:not(:disabled) {
        background-color: ${(props) => props.theme.colors.aluminiumShades['5']};
    }

    &:disabled {
        border: 1px solid ${(props) => props.theme.colors.aluminiumShades['20']};
        color: ${(props) => props.theme.colors.aluminiumShades['20']};
    }
`

const secondaryDestructiveCss = css`
    border: 1px solid ${(props) => transparency(props.theme.colors.status.error, 0.3)};
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.status.error};

    &:hover:not(:disabled) {
        background: ${(props) => transparency(props.theme.colors.status.error, 0.3)};
        border: 1px solid ${(props) => props.theme.colors.status.error};
    }
`

const secondaryKinds: { [key in ButtonKind]: FlattenInterpolation<any> } = {
    default: secondaryDefaultCss,
    action: secondaryActionCss,
    success: successCss,
    destructive: secondaryDestructiveCss,
    settings: secondarySettingsCss,
}

const blockCss = css`
    display: block;
    width: 100%;
`

const smallCss = css`
    padding: 0 0.75rem;
    font-size: 0.75rem;
    font-weight: 500;
    height: 1.5rem;
`

const mediumCss = css`
    padding: 0 1rem;
    height: 2rem;
    font-size: 0.875rem;
    font-weight: 500;
`

const largeCss = css`
    padding: 0 1.25rem;
    height: 2.5rem;
    font-size: 1rem;
    font-weight: 500;
`

const sizes: { [key in ButtonSize]: FlattenInterpolation<any> } = {
    small: smallCss,
    medium: mediumCss,
    large: largeCss,
}

const secondarySizes: { [key in ButtonSize]: FlattenInterpolation<any> } = {
    small: smallCss,
    medium: mediumCss,
    large: largeCss,
}

export const buttonReset = css`
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    touch-action: manipulation;
    user-select: none;
    background-image: none;
    font-weight: lighter;

    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;

    vertical-align: middle;
    background: none;
    display: block;
    width: auto;
    height: auto;

    text-transform: initial;
    white-space: normal;

    &:disabled {
        cursor: not-allowed;
    }

    &:focus {
        outline: none !important;
    }

    &:active {
        box-shadow: none;
    }

    &.btn::before,
    &.btn::after,
    &::before,
    &::after {
        display: none !important;
        content: '' !important;
    }
`

export const ActionButtonBaseCss = css`
    display: inline-flex;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    color: ${(props) => props.theme.colors.white};
    background: inherit;
    ${(props: ActionButtonProps) => (props.width ? `width: ${props.width}` : '')}

    ${(props: ActionButtonProps) =>
        props.secondary ? secondarySizes[props.size || 'medium'] : sizes[props.size || 'medium']}

    ${(props: ActionButtonProps) =>
        props.secondary ? secondaryKinds[props.kind || 'default'] : kinds[props.kind || 'default']}

    font-family: ${(props) => props.theme.fonts.primary};
    ${(props: ActionButtonProps) => (props.block ? blockCss : '')}

    transition: background 0.1s ease-in,
                color 0.1s ease-in,
                opacity 0.1s ease-in
                box-shadow 0.05s ease-in;

    &:focus {
        outline: none;
    }

    &:disabled {
        cursor: not-allowed;
    }
`

const ActionButtonElement = styled.button.attrs((props) => ({
    type: props.type ? props.type : 'button',
}))<ActionButtonProps>`
    ${ActionButtonBaseCss}
`

export const ActionButtonExternalLink = styled.a<ActionButtonProps>`
    ${ActionButtonBaseCss}
    text-decoration: none;
    ${(p) => (p.disabled ? 'cursor: not-allowed' : '')}
`

function isModifiedEvent(event: React.MouseEvent<any>) {
    return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

export class ActionButtonA extends React.Component<ActionButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>> {
    handleClick(event: React.MouseEvent<HTMLAnchorElement>, history: History) {
        if (this.props.stopPropagation) {
            event.stopPropagation()
        }
        if (this.props.disabled) {
            event.preventDefault()
            return
        }
        if (this.props.href && event.button === 0 && !isModifiedEvent(event)) {
            event.preventDefault()
            history.push(this.props.href)
        }
    }
    render() {
        return (
            <Route
                render={({ history }) => (
                    <ActionButtonExternalLink
                        onClick={(e: React.MouseEvent<any>) => this.handleClick(e, history)}
                        {...this.props}
                    />
                )}
            />
        )
    }
}

interface ButtonComponentProps {
    children?: React.ReactNode
    className?: string
    doneText?: string
    onClick?: (evt: React.MouseEvent<any>) => Promise<void> | void
}

interface ButtonProps<T> {
    component: React.ComponentType<T>
    componentProps: T
}

interface ButtonState {
    actionDoneStart: boolean
    actionDoneRunning: boolean
}

class Button<T extends ButtonComponentProps> extends React.Component<ButtonProps<T>, ButtonState> {
    constructor(props: ButtonProps<T>) {
        super(props)
        this.state = {
            actionDoneStart: false,
            actionDoneRunning: false,
        }
    }

    resetDone = async () => {
        await delay(1000)
        this.setState({ actionDoneStart: false, actionDoneRunning: true })
        await delay(2000)
        this.setState({ actionDoneStart: false, actionDoneRunning: false })
    }

    onClick = async (evt: React.MouseEvent<any>) => {
        const clickHandler = this.props.componentProps.onClick || (() => {})
        await clickHandler(evt)

        if (this.props.componentProps.doneText) {
            this.setState({ actionDoneStart: true, actionDoneRunning: false }, this.resetDone)
        }
    }

    render() {
        const Component = this.props.component
        const props = Object.assign({}, this.props.componentProps)
        const children = props.children
        const doneText = props.doneText
        const classNames = (props.className || '').split(' ').filter((x) => x !== '')
        if (this.state.actionDoneStart) {
            classNames.push('action-done-start')
        }
        if (this.state.actionDoneRunning) {
            classNames.push('action-done-running')
        }
        delete props.children
        delete props.onClick
        delete props.doneText
        delete props.className
        return (
            <Component onClick={this.onClick} className={classNames.join(' ')} {...props}>
                {this.state.actionDoneStart || this.state.actionDoneRunning ? doneText : children}
            </Component>
        )
    }
}

/** @component */
export const ActionButton = (props: ActionButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => (
    <Button component={ActionButtonElement} componentProps={props} />
)

export default ActionButton
