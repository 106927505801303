import * as React from 'react'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { Col } from 'uiComponents/flex'
import Infotip from 'uiComponents/infotip'
import { SelectComponent } from 'uiComponents/studio/form/fieldComponents'
import CopySection from './copySection'
import { FormItemType, TemplateType, TemplateSettingsWithVariables } from 'emailTemplates/schema'
import { SingleSelectOption } from 'uiComponents/input/singleSelect'
import { Locale } from 'admin/articleService'
import { AlignedCol } from './globalSection'

interface LanguagesSectionProps {
    templateType: TemplateType
    templateSettings: TemplateSettingsWithVariables
    defaultLanguage: string
    activeLanguage: string
    locales: Locale[]
    unusedFields: FormItemType[]
    markAllErrors: boolean
    onFinishTyping: (value: string, item: FormItemType) => void
    handleChange: (value: string, item: FormItemType) => void
    toggleUnusedField: (item: FormItemType, add: boolean) => void
}

function LanguagesSection(props: LanguagesSectionProps) {
    const [languageOptions, setlanguageOptions] = React.useState<SingleSelectOption[]>([])

    React.useEffect(() => {
        const test = props.locales.map((l) => ({
            value: l.code,
            name: l.name,
        }))
        setlanguageOptions(test)
    }, [props.locales])

    type SelectType = 'select'
    const defaultLanguageField = {
        type: 'select' as SelectType,
        name: 'default-language',
        label: 'Default language',
        path: '',
        defaultValue: 'en',
        availableOptions: languageOptions,
        infotip: 'The main language used on your website.',
    }
    const translationLanguageField = {
        type: 'select' as SelectType,
        name: 'translation-language',
        label: 'Translation language',
        path: '',
        defaultValue: 'en',
        availableOptions: languageOptions,
    }

    return (
        <>
            <SectionWrapper title="Languages">
                <FormItem>
                    <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <label htmlFor={defaultLanguageField.name}>
                            {defaultLanguageField.label}
                            <Infotip pointer="left" maxWidth="17em" smallBoxText>
                                {defaultLanguageField.infotip}
                            </Infotip>
                        </label>
                    </Col>
                    <AlignedCol span={6} className="align-right">
                        <SelectComponent
                            id="defaultLanguage"
                            field={defaultLanguageField}
                            handleChange={(v) => props.handleChange(v as string, 'defaultLanguage')}
                            value={props.defaultLanguage}
                        />
                    </AlignedCol>
                </FormItem>
            </SectionWrapper>
            <SectionWrapper title="Translations">
                <FormItem>
                    <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <label htmlFor={translationLanguageField.name}>{translationLanguageField.label}</label>
                    </Col>
                    <AlignedCol span={6} className="align-right">
                        <SelectComponent
                            id="translationsLanguage"
                            field={defaultLanguageField}
                            handleChange={(v) => props.handleChange(v as string, 'activeLanguage')}
                            value={props.activeLanguage}
                        />
                    </AlignedCol>
                </FormItem>
                <CopySection
                    handleChange={props.handleChange}
                    activeLanguage={props.activeLanguage}
                    templateSettings={props.templateSettings}
                    templateType={props.templateType}
                    toggleUnusedField={props.toggleUnusedField}
                    unusedFields={props.unusedFields}
                    markAllErrors={props.markAllErrors}
                    onFinishTyping={props.onFinishTyping}
                />
            </SectionWrapper>
        </>
    )
}

export default LanguagesSection
