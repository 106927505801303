import styled from 'styled-typed'
import { DashboardTheme } from 'theme'

type FontWeightType = 'light' | 'regular' | 'medium' | 'bold'

const weight = {
    light: '300',
    regular: '400',
    medium: '500',
    bold: '700',
}

interface HeadlineProps {
    size: 1 | 2 | 3 | 4 | 5 | 6
    bold?: boolean
    color?: TextColor
}
const headlineSizes = {
    1: { font: '2.625rem', line: '3.35rem' },
    2: { font: '2.125rem', line: '3rem' },
    3: { font: '1.75rem', line: '2.5rem' },
    4: { font: '1.375rem', line: '2rem' },
    5: { font: '1.125rem', line: '1.75rem' },
    6: { font: '0.875rem', line: '1.5rem' },
}
export const Headline = styled.div<HeadlineProps>`
    margin: 0 0 1rem 0;
    font-family: Roboto;
    color: ${(props) => getColor(props.theme, props.color)};
    font-size: ${(props) => headlineSizes[props.size]?.font || '1rem'};
    line-height: ${(props) => headlineSizes[props.size]?.line || '1rem'};
    font-weight: ${(props) => (props.bold ? '700' : '500')};
`

export const Subtitle = styled.div`
    color: ${(props) => props.theme.colors.textDark};
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 300;
`

interface CaptionProps {
    color?: TextColor
}

export const Caption = styled.div<CaptionProps>`
    color: ${(props) => getColor(props.theme, props.color)};
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
`

type TextColor = 'primary' | 'light' | 'white'

const getColor = (theme: DashboardTheme, color?: TextColor): string => {
    switch (color) {
        case 'light':
            return theme.colors.textLight
        case 'white':
            return theme.colors.white
        default:
            return theme.colors.textDark
    }
}

interface BodyProps {
    size?: 1 | 2 | 3
    weight?: FontWeightType
    color?: TextColor
}

const bodySizes = {
    1: { font: '0.875rem', line: '1.5rem' },
    2: { font: '0.75rem', line: '1.25rem' },
    3: { font: '0.625rem', line: '1rem' },
}

export const Body = styled.div<BodyProps>`
    font-size: ${(props) => bodySizes[props.size ?? 1]?.font || '1rem'};
    line-height: ${(props) => bodySizes[props.size ?? 1]?.line || '1rem'};
    font-weight: ${(props) => weight[props.weight || 'regular']};
    color: ${(props) => getColor(props.theme, props.color)};
`
