import { Field, FieldProps } from 'formik'
import React, { useEffect } from 'react'
import { Col, Row } from 'uiComponents/flex'
import RadioFieldFormik from 'uiComponents/input/radio/radioFieldFormik'
import { focusField } from 'uiComponents/input/_shared/hooks'
import NumberInputFieldFormik from 'uiComponents/input/numberInput/numberInputFieldFormik'

const UsesSection = () => {
    return (
        <Col span={12}>
            <Row gutter={0} align="space-between" style={{ width: 197, marginBottom: 3 }}>
                <Col span="auto" style={{ paddingBottom: 0 }}>
                    <RadioFieldFormik checkMarkSize="small" name="uses" value="unlimited" label="Unlimited" />
                </Col>
                <Col span="auto" style={{ paddingBottom: 0 }}>
                    <RadioFieldFormik checkMarkSize="small" name="uses" value="maxUses" label="Max uses" />
                </Col>
            </Row>
            <Row align="flex-end">
                <Field name="uses">
                    {({ field: { value }, form: { setFieldValue } }: FieldProps<string>) => {
                        useEffect(() => {
                            if (value === 'unlimited') {
                                setFieldValue('usesCount', undefined)
                            }

                            if (value === 'maxUses') {
                                focusField('usesCount')
                            }
                        }, [value])

                        if (value === 'unlimited') {
                            return null
                        }

                        return (
                            <Col span={3} style={{ paddingLeft: 0, maxWidth: 'unset' }}>
                                <NumberInputFieldFormik
                                    style={{ width: '92px', height: '34px', textAlign: 'right' }}
                                    name="usesCount"
                                    placeholder=""
                                />
                            </Col>
                        )
                    }}
                </Field>
            </Row>
        </Col>
    )
}

export default UsesSection
