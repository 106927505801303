import { useField } from 'formik'
import React, { FC, InputHTMLAttributes } from 'react'
import FieldWrapper from '../fieldWrapper'
import { useCustomId, useGetComponentState } from '../_shared/hooks'
import { TextInputProps } from './interface'
import { TextInput } from './textInput'

interface TextInputFieldFormikProps extends TextInputProps, Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
    name: string
    id?: string
    label?: string | JSX.Element
    validationMessageProps?: any
    width?: string
}

const TextInputFieldFormik: FC<TextInputFieldFormikProps> = ({
    name,
    label,
    id,
    className,
    width,
    validationMessageProps,
    type = 'text',
    onBlur,
    ...rest
}) => {
    const [{ value, ...field }, { error }] = useField(name)
    const innerId = useCustomId(id)
    const state = useGetComponentState(name)

    return (
        <FieldWrapper
            className={`${className}${error ? ' error' : ''}`}
            label={label}
            error={error}
            status={state}
            validationMessageProps={{
                ...validationMessageProps,
                style: { position: 'relative', ...validationMessageProps?.style },
            }}
        >
            <TextInput
                style={{ width: width || '100%' }}
                type={type}
                {...field}
                {...rest}
                value={value ?? ''}
                id={innerId}
                status={state}
                onBlur={onBlur}
            />
        </FieldWrapper>
    )
}

export default TextInputFieldFormik
