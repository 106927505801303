import React from 'react'
import { useHasFeature } from '../../../features'
import ComboboxNew, { Props as NewComboboxProps } from './combobox.new'
import ComboboxOld, { Props as OldComboboxProps } from './combobox'

export { ComboboxWithParentStyling } from './styles'

export interface ComboboxProps extends NewComboboxProps, OldComboboxProps {}

export const Combobox = (props: ComboboxProps) => {
    const shouldShowNewCombobox = useHasFeature('combobox')

    if (shouldShowNewCombobox) {
        return <ComboboxNew {...(props as NewComboboxProps)} />
    }

    return <ComboboxOld {...(props as OldComboboxProps)} />
}
