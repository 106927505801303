import * as React from 'react'
import styled, { css } from 'styled-typed'
import Svg from 'svg'
import checkIcon from 'uiComponents/input/checkIcon.svg'
import bigTickIcon from 'uiComponents/input/bigTick.svg'
import classNames from 'classnames'

const CheckboxContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`

const StyledCheckbox = styled.input.attrs<React.InputHTMLAttributes<HTMLInputElement>>((props) => ({
    type: 'checkbox',
}))`
    appearance: none;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid rgba(161, 176, 178, 0.25);
    width: 1.25em;
    height: 1.25em;
    border-radius: 0.3em;
    vertical-align: -0.25em;
    margin-right: 0.25em;
    cursor: pointer;

    &:checked {
        background: ${(props) => props.theme.colors.boyBlue};
    }

    &:focus {
        outline: none;
        border: 1px solid rgba(161, 176, 178, 0.25);
    }
    &.mark-focus {
        &:focus {
            border: 1px solid ${(props) => props.theme.colors.boyBlue};
        }
    }
    &.bordered {
        border: 1px solid ${(props) => props.theme.colors.boyBlue};
    }

    &.disabled {
        background: ${(props) => props.theme.colors.background};
        cursor: not-allowed;
    }
`
const markStyle = css`
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.1s, opacity 0.1s ease-in;
    pointer-events: none;
    ${StyledCheckbox}:checked + & {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.1s ease-in;
    }
`
const Checkmark = styled(Svg)<{ disabled: boolean }>`
    ${markStyle}
    max-height: 0.7em;
    width: 0.6em;
    left: 0.34em;
    color: ${(props) => (props.disabled ? props.theme.colors.aluminium : props.theme.colors.white)};
`
const Dashmark = styled.span`
    ${markStyle}
    top: 0.1em;
    left: 0.33em;
    color: ${(props) => props.theme.colors.white};
`

const StyledBigCheckbox = styled.input.attrs<React.InputHTMLAttributes<HTMLInputElement>>((props) => ({
    type: 'checkbox',
}))`
    appearance: none;
    border: 1px solid rgba(161, 176, 178, 0.25);
    width: 2.6em;
    height: 2.6em;
    vertical-align: -0.25em;
    transition: border 0.1s ease-in;
    margin-right: 0.25em;
    cursor: pointer;
    &:focus {
        outline: none;
        border: 2px solid ${(props) => props.theme.colors.status.success};
    }
`
const BigCheckmark = styled(Svg)`
    position: absolute;
    width: 1.5em;
    top: 0.6em;
    left: 0.6em;
    color: ${(props) => props.theme.colors.status.success};
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.1s, opacity 0.1s ease-in;
    pointer-events: none;
    ${StyledBigCheckbox}:checked + & {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.1s ease-in;
    }
`

export interface CheckboxProps {
    indeterminateState?: boolean
    markFocus?: boolean
    bordered?: boolean
    innerRef?: any
    disabled?: boolean
}

export function Checkbox({ innerRef, ...props }: CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>) {
    return (
        <CheckboxContainer>
            <StyledCheckbox
                {...props}
                ref={innerRef}
                className={classNames(
                    {
                        'mark-focus': props.markFocus,
                        bordered: props.bordered,
                        disabled: props.disabled,
                    },
                    props.className,
                )}
                onChange={props.onChange ? props.onChange : () => {}}
            />
            {props.indeterminateState && <Dashmark>&ndash;</Dashmark>}
            {!props.indeterminateState && <Checkmark src={checkIcon} disabled={!!props.disabled} />}
        </CheckboxContainer>
    )
}

export function BigCheckbox(props: React.InputHTMLAttributes<HTMLInputElement>) {
    return (
        <CheckboxContainer>
            <StyledBigCheckbox {...props} />
            <BigCheckmark src={bigTickIcon} />
        </CheckboxContainer>
    )
}
