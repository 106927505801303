import React from 'react'
import { OrderTicketStatusKeys } from 'orders/schema'
import { Text } from 'uiComponents/typography'

interface OrderItemStatusProps {
    value: OrderTicketStatusKeys
}

const OrderItemStatus: React.FC<OrderItemStatusProps> = ({ value }) => {
    return (
        <Text transform="capitalize" status={value === 'redeemed' ? 'success' : 'processing'}>
            {value}
        </Text>
    )
}

export default OrderItemStatus
