import * as React from 'react'
import styled from 'styled-typed'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { transparency } from 'utils/css'
import { ChartDataLoader } from 'uiComponents/loaders'
import { CONTAINER_BOX_SHADOW } from 'uiComponents/settingsContainer'

interface ContainerProps {
    minHeight: string
    noShadow?: boolean
}

const TableScrollContainer = styled.div<ContainerProps>`
    position: relative;
    width: 100%;
    overflow: auto;
    border-radius: 0.7em 0.7em 0 0;
    box-shadow: ${(props) => (props.noShadow ? '' : CONTAINER_BOX_SHADOW)};
    transition: all 0.4s;
    background: ${(p) => p.theme.colors.white};
    min-height: ${(p) => p.minHeight};
`
const ScrollIndicatorLeft = styled.div<{ noRounding?: boolean }>`
    cursor: pointer;
    z-index: 5;
    font-size: 1.3em;
    height: 100%;
    width: 2em;
    color: ${(p) => p.theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: ${(props) => (props.noRounding ? '' : '0.7em 0 0 0')};
    background: linear-gradient(
        90deg,
        ${(p) => transparency(p.theme.colors.textDark, 0.3)},
        ${(p) => transparency(p.theme.colors.textDark, 0)}
    );
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.1s, opacity 0.1s ease-in;

    &.visible {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.1s ease-in;
    }
`
const ScrollIndicatorRight = styled(ScrollIndicatorLeft)`
    right: 0;
    left: unset;
    border-radius: ${(props) => (props.noRounding ? '' : '0 0.7em 0 0')};
    background: linear-gradient(
        90deg,
        ${(p) => transparency(p.theme.colors.textDark, 0)},
        ${(p) => transparency(p.theme.colors.textDark, 0.3)}
    );
`
const Icon = styled(FontAwesomeIcon)`
    filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.2));
`

interface ScrollTableContainerProps {
    children: React.ReactNode
    accountSlug: string
    loading: boolean
    inline?: boolean
}

interface ScrollTableContainereState {
    leftIndicator: boolean
    rightIndicator: boolean
}

export class ScrollTableContainer extends React.Component<ScrollTableContainerProps, ScrollTableContainereState> {
    constructor(props: ScrollTableContainerProps) {
        super(props)
        this.state = {
            leftIndicator: false,
            rightIndicator: false,
        }
    }

    table: HTMLDivElement | null = null

    setRef = (node: HTMLDivElement) => {
        this.table = node
    }

    componentDidMount() {
        this.setIndicators()
        if (this.table) {
            this.table.addEventListener('scroll', this.setIndicators)
        }
        window.addEventListener('resize', this.setIndicators)
    }

    componentDidUpdate(prevProps: ScrollTableContainerProps) {
        if (prevProps.accountSlug !== this.props.accountSlug || prevProps.loading !== this.props.loading) {
            this.setIndicators()
        }
    }

    componentWillUnmount() {
        if (this.table) {
            this.table.removeEventListener('scroll', this.setIndicators)
        }
        window.removeEventListener('resize', this.setIndicators)
    }

    setIndicators = () => {
        if (this.table && this.table.scrollLeft === this.table.scrollWidth - this.table.clientWidth) {
            this.setState({
                rightIndicator: false,
                leftIndicator: !!this.table.scrollLeft,
            })
        } else {
            this.setState({
                rightIndicator: true,
                leftIndicator: this.table ? !!this.table.scrollLeft : false,
            })
        }
    }

    onScroll = (add: string) => {
        if (this.table) {
            const scrollDistance = this.table.scrollLeft + (!!add ? 450 : -450)
            this.table.scrollTo({ left: scrollDistance, behavior: 'smooth' })
        }
    }

    render() {
        const minHeight = this.table
            ? this.table.scrollWidth > this.table.clientWidth || this.props.loading
                ? '7em'
                : ''
            : ''

        return (
            <div style={{ position: 'relative' }}>
                {this.props.loading && <ChartDataLoader />}
                <ScrollIndicatorLeft
                    className={this.state.leftIndicator ? 'visible' : ''}
                    onClick={() => this.onScroll('')}
                    noRounding={this.props.inline}
                >
                    <Icon icon={faChevronLeft} style={{ marginRight: '0.2em' }} />
                </ScrollIndicatorLeft>
                <ScrollIndicatorRight
                    className={this.state.rightIndicator ? 'visible' : ''}
                    onClick={() => this.onScroll('+')}
                    noRounding={this.props.inline}
                >
                    <Icon icon={faChevronRight} style={{ marginLeft: '0.2em' }} />
                </ScrollIndicatorRight>
                <TableScrollContainer ref={this.setRef} minHeight={minHeight} noShadow={this.props.inline}>
                    {this.props.children}
                </TableScrollContainer>
            </div>
        )
    }
}
