import React, { FC, ReactNode, useMemo } from 'react'

import StudioContext, { StudioContextValue } from './context'
import { useGetAllArticles, useGetAllCategories, useIsLoadingCategoriesOrArticles } from '../../../products/redux'

interface Props {
    children: ReactNode
    accountSlug: string
}

const StudioProvider: FC<Props> = ({ children }) => {
    const isLoading = useIsLoadingCategoriesOrArticles()
    const products = useGetAllCategories()
    const articles = useGetAllArticles()

    const initialContextValue = useMemo<StudioContextValue>(
        () => ({ isLoading: isLoading, products, articles }),
        [isLoading, articles, products],
    )

    return <StudioContext.Provider value={initialContextValue}>{children}</StudioContext.Provider>
}

export default StudioProvider
