import * as React from 'react'
import styled from 'styled-typed'
import Svg from 'svg'
import helpIcon from 'images/ic-help.svg'

const HelpCardContainer = styled.div`
    cursor: pointer;
    background: ${(props) => props.theme.colors.white};
    border-radius: 4px;
    width: 18.75em;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    padding: 1.25em;
    position: fixed;
    bottom: 5em;
    right: 0;
    z-index: 1;
`
const TextWrapper = styled.div`
    margin-left: 0.6em;
`
const HelpCardTitle = styled.div`
    margin: 0.25em 0;
    font-weight: 500;
`
const HelpCardText = styled.div`
    font-size: 0.875em;
    line-height: 1.4em;
    width: 12em;
`
const Icon = styled(Svg)`
    height: 1.5em;
`

interface HelpCardProps {
    header: string
    text: string
    onClick: () => void
}

export function HelpCard(props: HelpCardProps) {
    return (
        <HelpCardContainer onClick={props.onClick}>
            <Icon src={helpIcon} />
            <TextWrapper>
                <HelpCardTitle>
                    <strong>{props.header}</strong>&nbsp;&#8594;
                </HelpCardTitle>
                <HelpCardText>{props.text}</HelpCardText>
            </TextWrapper>
        </HelpCardContainer>
    )
}
