import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { replaceMessage } from 'uiComponents/messages/actions'
import { BaseRouteParams } from '../../../hocs'
import { useCreateAudienceMutation, useDeleteAudienceMutation, useUpdateAudienceMutation } from '../reduxQuery'
import { ICustomAudience, ICustomAudienceStatus } from '../types'

const useAudienceActions = () => {
    const [createAudience] = useCreateAudienceMutation()
    const [updateAudience] = useUpdateAudienceMutation()
    const [deleteAudience] = useDeleteAudienceMutation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const { accountSlug } = useParams<BaseRouteParams>()
    const history = useHistory()

    const createCA = (audience: ICustomAudience) => {
        setIsLoading(true)

        createAudience({ audience: audience })
            .unwrap()
            .then(() => {
                dispatch(replaceMessage('audience', 'success', 'Audience created', 5000))
                setIsLoading(false)
                history.push(`/account/${accountSlug}/crm/audiences/custom`)
            })
            .catch((e) => {
                dispatch(
                    replaceMessage(
                        'audience',
                        'error',
                        e.response.message || 'Ups. Something went wrong, please try again',
                        5000,
                    ),
                )
                setIsLoading(false)
            })
    }
    const updateCA = (audience: ICustomAudience) => {
        setIsLoading(true)

        updateAudience({ audience: audience })
            .unwrap()
            .then(() => {
                dispatch(replaceMessage('audience', 'success', 'Audience has been updated', 5000))
                setIsLoading(false)
                history.push(`/account/${accountSlug}/crm/audiences/custom`)
            })
            .catch((e) => {
                dispatch(
                    replaceMessage(
                        'audience',
                        'error',
                        e.response.message || 'Ups. Something went wrong, please try again',
                        5000,
                    ),
                )
                setIsLoading(false)
            })
    }

    const duplicateCA = (audience: ICustomAudience) => {
        const duplicatedAudience = { ...audience, name: `${audience.name} (copy)` }
        delete duplicatedAudience.id

        createCA(duplicatedAudience)
    }

    const deleteCA = (audience: ICustomAudience) => {
        setIsLoading(true)

        if (audience.id) {
            deleteAudience({ id: audience.id, slug: audience.account_slug })
                .unwrap()
                .then(() => {
                    dispatch(replaceMessage('audience', 'success', 'Audience has been deleted', 5000))
                    setIsLoading(false)
                })
                .catch((e) => {
                    dispatch(
                        replaceMessage(
                            'audience',
                            'error',
                            e.response.message || 'Ups. Something went wrong, please try again',
                            5000,
                        ),
                    )
                    setIsLoading(false)
                })
        }
    }

    const pauseCA = async (audience: ICustomAudience) => {
        updateCA({ ...audience, status: ICustomAudienceStatus.DRAFT })
    }

    const startCA = async (audience: ICustomAudience) => {
        updateCA({ ...audience, status: ICustomAudienceStatus.ACTIVE })
    }

    return {
        createCA,
        updateCA,
        duplicateCA,
        deleteCA,
        pauseCA,
        startCA,
        isLoading,
    }
}

export default useAudienceActions
