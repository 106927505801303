import styled, { keyframes } from 'styled-typed'
import { PriceSetting, AdjustmentType } from 'products/pricing/pricingService'
import { Toggle } from 'uiComponents/input'

export interface PriceTypeOption {
    value: PriceSetting
    name: string
}

export interface Adjustment {
    name: PriceSetting
    value: number | null
    type: AdjustmentType | null
    on: boolean
}

export interface Adjustments {
    [key: string]: Adjustment
}

export const flash = keyframes`
  from {background-color: rgba(51,196,202,0.5);}
  to {background-color: rgba(245,247,247,0.5);}
`

export const RuleFormContainer = styled.div`
    flex: 1;
    background-color: rgba(245, 247, 247, 0.5);
    border-radius: 1em;
    padding: 0.5em 1em 0 1em;
    height: 6.5em;
    position: relative;
    &.inactive {
        pointer-events: none;
        opacity: 0.5;
    }
`

export const RadioWrapper = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const HighlightedText = styled.div`
    color: ${(p) => p.theme.colors.boyBlue};
    font-size: 0.9em;
    display: flex;
    align-items: center;
`

export const Toggler = styled(Toggle)`
    font-size: 0.9em;
`

export const RuleHeader = styled.div`
    display: flex;
`
