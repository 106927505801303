import copyIcon from 'uiComponents/studio/icons/copy.svg'
import targetIcon from 'uiComponents/studio/icons/target.svg'
import calendarIcon from 'uiComponents/studio/icons/calendar.svg'
import { SectionObject } from 'uiComponents/studio/menuItem'
import { Locale } from 'admin/articleService'
import { Audience } from 'crm/schema'

export interface FormDefinitionSchema {
    type: SectionObject
    action: SectionObject
    scheduling: SectionObject
    targeting: SectionObject
}

export const formDefinition = (accountLocales: Locale[], audiences: Audience[]): FormDefinitionSchema => {
    return {
        type: {
            permission: 'TapPromotions',
            name: 'type',
            label: 'Type',
            header: 'Create your Promotion',
            icon: copyIcon,
            description: 'Create and edit your Promotion text and all corresponding translations.',
            sections: [
                {
                    name: 'Type',
                    fields: [
                        {
                            name: 'promotion-name',
                            type: 'text',
                            label: 'Promotion name',
                            span: 6,
                            path: 'promotionName',
                            defaultValue: '',
                        },
                        {
                            name: 'default-language',
                            type: 'select',
                            label: 'Default language',
                            span: 6,
                            path: 'defaultLanguage',
                            defaultValue: '',
                            availableOptions: accountLocales.map((locale) => ({
                                name: locale.name,
                                value: locale.code,
                            })),
                        },
                        {
                            name: 'theme',
                            type: 'select',
                            label: 'Background color',
                            span: 6,
                            path: 'theme',
                            defaultValue: 'DARK',
                            availableOptions: [
                                {
                                    name: 'Dark',
                                    value: 'DARK',
                                },
                                {
                                    name: 'Light',
                                    value: 'LIGHT',
                                },
                                {
                                    name: 'Brand',
                                    value: 'BRAND',
                                },
                            ],
                        },
                    ],
                },
                {
                    name: 'Add Languages',
                    fields: [
                        {
                            name: 'content-language',
                            type: 'select',
                            label: 'Select language',
                            span: 6,
                            path: 'language',
                            defaultValue: '',
                            availableOptions: accountLocales.map((locale) => ({
                                name: locale.name,
                                value: locale.code,
                            })),
                        },
                        {
                            name: 'content-title',
                            type: 'text',
                            label: 'Promotion title',
                            span: 6,
                            path: 'content-title',
                            defaultValue: '',
                        },
                        {
                            name: 'content-content',
                            type: 'text',
                            label: 'Promotion content',
                            span: 6,
                            path: 'content-content',
                            defaultValue: '',
                        },
                    ],
                },
            ],
        },
        action: {
            name: 'action',
            label: 'Action',
            header: 'Design your Look & Feel',
            icon: targetIcon,
            permission: 'TapPromotionPartnerSlug',
            description:
                "To start, we use a default style and default colors for your Trigger Button, but you can customize them below to match your website's look and feel.",
            sections: [
                {
                    name: 'General',
                    fields: [
                        {
                            name: 'partnerSlug',
                            type: 'text',
                            label: 'Partner slug',
                            span: 6,
                            path: 'partnerSlug',
                            defaultValue: '',
                        },
                    ],
                },
            ],
        },
        scheduling: {
            permission: 'TapPromotions',
            name: 'scheduling',
            label: 'Scheduling',
            header: 'Set your schedule',
            icon: calendarIcon,
            description: 'Choose the schedule for the promotion to show up in tap',
            sections: [
                {
                    name: 'Scheduling',
                    infotip: 'When should your promotion be visible on the channel screen',
                    fields: [
                        {
                            name: 'startDate',
                            type: 'date',
                            label: 'Start date',
                            span: 12,
                            path: 'startDate',
                            defaultValue: '',
                        },
                        {
                            name: 'endDate',
                            type: 'date',
                            label: 'End date',
                            span: 12,
                            path: 'endDate',
                            defaultValue: '',
                            infotip: 'Not setting an end date will make it never expire',
                        },
                        {
                            name: 'active',
                            type: 'select',
                            label: 'Active',
                            span: 12,
                            path: 'active',
                            defaultValue: '',
                            availableOptions: [
                                {
                                    name: 'Active',
                                    value: 1,
                                },
                                {
                                    name: 'Inactive',
                                    value: 0,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        targeting: {
            permission: 'TapPromotions',
            name: 'targeting',
            label: 'Targeting',
            header: 'Set your targeting',
            icon: targetIcon,
            description: 'Choose the targeting for the promotion to show up in tap',
            feature: 'CRMAudiencesPage',
            sections: [
                {
                    name: 'Targeting',
                    infotip: 'When should your promotion be visible on the channel screen',
                    fields: [
                        {
                            name: 'audience',
                            type: 'multiSelect',
                            label: 'Audience',
                            span: 6,
                            path: 'audience',
                            defaultValue: [],
                            availableOptions: audiences.map((audience) => ({
                                name: audience.name,
                                value: audience.slug,
                            })),
                        },
                        {
                            name: 'marketingConsent',
                            type: 'checkbox',
                            label: 'Marketing consent provided',
                            path: 'marketingConsent',
                            defaultValue: false,
                        },
                    ],
                },
            ],
        },
    }
}
