import * as React from 'react'
import { StatsService } from 'http/statsService'
import { DateRange } from 'dateRanges'
import { format, startOfToday, addDays } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { areDateRangeDatesEqual, comparisonTextMap } from 'reports/helpers'
import { ComparisonDataNumber } from 'uiComponents/charts/dataNumber'
import { ChartRow } from 'uiComponents/charts/styleComponents'
import { ComparisonTotal } from 'reports/schema'

const dummyComparison: ComparisonTotal = {
    value: '',
    change: 'not_enough_data',
}

interface SingleDataItemsProps {
    dateRange: DateRange
    statsService: StatsService
    accountSlug: string
    forecastRange: number
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
}

interface SingleDataItemsState {
    comparisonToday: ComparisonTotal
    comparisonTomorrow: ComparisonTotal
    comparisonNextRange: ComparisonTotal
    todayDelta: string
    tomorrowDelta: string
    nextRangeDelta: string
    loading: boolean
}

class SingleDataItems extends React.Component<SingleDataItemsProps, SingleDataItemsState> {
    _isMounted = false
    private _lastRequest?: number = undefined

    constructor(props: SingleDataItemsProps) {
        super(props)
        this.state = {
            comparisonToday: dummyComparison,
            comparisonTomorrow: dummyComparison,
            comparisonNextRange: dummyComparison,
            todayDelta: '-',
            tomorrowDelta: '-',
            nextRangeDelta: '-',
            loading: false,
        }
    }

    async componentDidMount() {
        this._isMounted = true
        await this.getData()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    async componentDidUpdate(prevProps: SingleDataItemsProps) {
        if (
            !areDateRangeDatesEqual(prevProps.dateRange, this.props.dateRange) ||
            prevProps.accountSlug !== this.props.accountSlug
        ) {
            await this.getData()
        }
    }

    getData = async () => {
        const requestTime = new Date().valueOf()
        this._lastRequest = requestTime

        this.setState({ loading: true })
        const today = format(new Date(), 'yyyy-MM-dd')
        const tomorrow = format(addDays(startOfToday(), 1), 'yyyy-MM-dd')
        const nextPeriodTo = format(addDays(startOfToday(), this.props.forecastRange), 'yyyy-MM-dd')
        try {
            const data = await this.props.statsService.getForecastSingleItemsStats(
                this.props.accountSlug,
                today,
                tomorrow,
                nextPeriodTo,
            )

            if (this._lastRequest !== requestTime) {
                return
            }

            const comparisonToday = data.comparisonToday.totals ? data.comparisonToday.totals : dummyComparison
            const comparisonTomorrow = data.comparisonTomorrow.totals ? data.comparisonTomorrow.totals : dummyComparison
            const comparisonNextRange = data.comparisonNextRange.totals
                ? data.comparisonNextRange.totals
                : dummyComparison
            const todayDelta =
                data.todayDelta && data.todayDelta.dataSeries.data[0] ? data.todayDelta.dataSeries.data[0][0] : '-'
            const tomorrowDelta =
                data.tomorrowDelta && data.tomorrowDelta.dataSeries.data[0]
                    ? data.tomorrowDelta.dataSeries.data[0][0]
                    : '-'
            const nextRangeDelta =
                data.nextRangeDelta && data.nextRangeDelta.dataSeries.data[0]
                    ? data.nextRangeDelta.dataSeries.data[0][0]
                    : '-'
            if (this._isMounted) {
                this.setState({
                    comparisonToday,
                    comparisonTomorrow,
                    comparisonNextRange,
                    todayDelta,
                    tomorrowDelta,
                    nextRangeDelta,
                    loading: false,
                })
            }
        } catch {
            this.props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Some data could not be loaded, please try again later.',
            )
            this.setState({ loading: false })
        }
    }

    render() {
        const {
            loading,
            comparisonToday,
            comparisonTomorrow,
            comparisonNextRange,
            todayDelta,
            tomorrowDelta,
            nextRangeDelta,
        } = this.state

        return (
            <div style={{ position: 'relative' }}>
                {loading && <ChartDataLoader topOffset="0" />}
                <ChartRow>
                    <ComparisonDataNumber
                        value={comparisonToday.value ? Number(comparisonToday.value).toFixed(0) : '0'}
                        comparison={comparisonTextMap[comparisonToday.change]}
                        errorMargin={todayDelta}
                        title="Expected visitors today"
                    />
                    <ComparisonDataNumber
                        value={comparisonTomorrow.value ? Number(comparisonTomorrow.value).toFixed(0) : '0'}
                        comparison={comparisonTextMap[comparisonTomorrow.change]}
                        errorMargin={tomorrowDelta}
                        title="Expected visitors tomorrow"
                    />
                    <ComparisonDataNumber
                        value={comparisonNextRange.value ? Number(comparisonNextRange.value).toFixed(0) : '0'}
                        comparison={comparisonTextMap[comparisonNextRange.change]}
                        errorMargin={nextRangeDelta}
                        title={`Expected visitors next ${this.props.forecastRange === 28 ? '4 weeks' : 'week'}`}
                    />
                </ChartRow>
            </div>
        )
    }
}

export default SingleDataItems
