import React, { useContext } from 'react'
import styled from 'styled-typed'
import { useAccountChange } from 'reactUtils'
import { MetadataContext } from 'products/discountRules/metadataContext'
import { RuleFormContext } from './formContext'
import { TableLoader } from 'uiComponents/loaders'
import RuleDefinition from '../ruleDefinition'
import RuleFormActions from './actions'
import RuleSetup from './ruleSetup'
import GeneralSection from './general'
import TranslationsSection from './translations'

const Section = styled.div`
    padding: 1.5em;
    &.with-border {
        border-bottom: 1px solid ${(props) => props.theme.colors.aluminiumShades[5]};
    }
    &.actions {
        display: flex;
        justify-content: space-between;
    }
`

function DiscountRuleDetails() {
    const { accountSlug, history } = useContext(MetadataContext)
    const { loading, rule } = useContext(RuleFormContext)

    useAccountChange(accountSlug, () => history.push(`/account/${accountSlug}/products/discount_rules/home`))

    return (
        <>
            {loading && <TableLoader />}
            {!loading && (
                <>
                    <Section className="with-border">
                        <GeneralSection />
                    </Section>
                    <Section>
                        <RuleSetup />
                    </Section>
                    <RuleDefinition rule={rule} />
                    <Section className="with-border">
                        <TranslationsSection />
                    </Section>
                    <Section className="actions">
                        <RuleFormActions />
                    </Section>
                </>
            )}
        </>
    )
}

export default DiscountRuleDetails
