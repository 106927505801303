import React, { useContext } from 'react'
import { ConfirmationDialog } from '../../uiComponents/popups/confirmationDialog'
import { ProductTableActionKind, productTableContext } from './provider'
import { getProductName } from './utils'
import { useDuplicateProductMutation } from '../reduxApi'
import { useParams } from 'react-router-dom'
import { BaseRouteParams } from '../../hocs'
import { useDispatch } from 'react-redux'
import { replaceMessage } from '../../uiComponents/messages/actions'

const DuplicateProduct = () => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const { state, dispatch: productTableDispatch } = useContext(productTableContext)
    const [duplicateProduct] = useDuplicateProductMutation()
    const dispatch = useDispatch()
    const closeModal = () =>
        productTableDispatch({ type: ProductTableActionKind.SET_PRODUCT_FOR_DUPLICATING, payload: undefined })
    const onConfirm = async () => {
        const copyId = await duplicateProduct({ uuid: state.selectProductToDuplicate?.id as string }).unwrap()
        dispatch(
            replaceMessage(
                'duplicateProduct',
                'warn',
                `This is a copy of the product '${getProductName(
                    state?.selectProductToDuplicate?.name,
                )}'. Please remember to update the name in all languages.
      Also note that the external id, bundle, template parameters and product option groups setting have not been copied.`,
                10e3,
            ),
        )

        closeModal()
        window.open(`/account/${accountSlug}/products/crud/${copyId}${location.search}`, '_blank')
    }

    if (!state.selectProductToDuplicate) {
        return null
    }

    return (
        <ConfirmationDialog
            title="Duplicate the product?"
            buttonText="Duplicate"
            onConfirm={onConfirm}
            onCancel={closeModal}
            text={
                <span>
                    Are you sure you want to duplicate the product{' '}
                    <b>{getProductName(state.selectProductToDuplicate.name)}?</b>
                </span>
            }
        />
    )
}

export default DuplicateProduct
