import { PriceVariation } from '../pricingService'
import { PricingFormState, Rounding } from './types'

export const roundToNearest = (value: number, rounding: Rounding): number => {
    return Math.round(value / Number(rounding)) * Number(rounding)
}

export const roundPrice = (price: number, rounding: Rounding, charmPricing?: boolean): number => {
    const roundedPrice = roundToNearest(price, rounding)
    const roundedCharmingPrice = roundedPrice - (charmPricing ? 0.01 : 0)

    if (rounding === '0.01' && charmPricing) {
        const decimalPart = Number(roundedPrice - Math.floor(roundedPrice)).toFixed(2)

        if (!(decimalPart.endsWith('5') || decimalPart.endsWith('0'))) {
            return Number(roundedPrice.toFixed(2))
        }
    }

    return Number(roundedCharmingPrice.toFixed(2))
}

const modifierMap: Record<Exclude<PriceVariation, 'custom'>, number> = {
    low: 5,
    medium: 12,
    high: 20,
}

export const getMinMaxValues = (formValues: PricingFormState): [number, number] => {
    const { regularPrice, priceVariation, rounding, charmPricing, minPrice, maxPrice, gatePrice, dynamicPricing } =
        formValues

    if (!dynamicPricing) {
        return [regularPrice, regularPrice]
    }

    if (priceVariation === 'custom') {
        return [roundPrice(minPrice, rounding, charmPricing), roundPrice(maxPrice, rounding, charmPricing)]
    }

    if (!regularPrice || isNaN(regularPrice) || !priceVariation) {
        return [0, 0]
    }

    const percentageModifier = modifierMap[priceVariation]

    const min = roundPrice(regularPrice - regularPrice * (percentageModifier / 100), rounding, charmPricing)
    const max = roundPrice(regularPrice + regularPrice * (percentageModifier / 100), rounding, charmPricing)

    if (gatePrice) {
        if (min > gatePrice) {
            return [gatePrice, gatePrice]
        } else if (max > gatePrice) {
            return [min, gatePrice]
        }
    }

    return [min, max]
}
