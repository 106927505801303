import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { BaseRouteParams } from '../../hocs'
import { replaceMessage } from '../../uiComponents/messages/actions'
import { formatDateTimeStr } from '../helpers'
import { useCreateCampaignMutation, useDeleteCampaignMutation, useUpdateCampaignMutation } from '../reduxQuery'
import { PNCampaign, PNContentForm, PNScheduleForm, PNTargetForm } from '../types'

const usePNActions = () => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [deleteCampaign] = useDeleteCampaignMutation()
    const [createCampaign] = useCreateCampaignMutation()
    const [updateCampaign] = useUpdateCampaignMutation()
    const dispatch = useDispatch()

    const createPN = ({
        targetForm,
        contentForm,
        scheduleForm,
    }: {
        targetForm: PNTargetForm
        contentForm: PNContentForm
        scheduleForm: PNScheduleForm
    }) => {
        setIsLoading(true)

        const campaign: PNCampaign = {
            active: false,
            slug: accountSlug,
            ...targetForm,
            schedule: {
                start_time: formatDateTimeStr(scheduleForm.startDate, scheduleForm.startTime),
                end_time: formatDateTimeStr(scheduleForm.endDate, scheduleForm.endTime),
                frequency: scheduleForm.frequency,
            },
            content: contentForm.content,
            url: contentForm.url,
        }

        createCampaign({ campaign })
            .unwrap()
            .then(() => {
                dispatch(replaceMessage('notifications', 'success', 'Campaign has been created', 5000))
                setIsLoading(false)
            })
            .catch(() => {
                dispatch(replaceMessage('notifications', 'error', 'Ups. Something went wrong, please try again', 5000))
                setIsLoading(false)
            })
    }

    const update = (campaign: PNCampaign) => {
        updateCampaign({ campaign: campaign })
            .unwrap()
            .then(() => {
                dispatch(replaceMessage('notifications', 'success', 'Campaign has been updated', 5000))
                setIsLoading(false)
            })
            .catch((e) => {
                dispatch(
                    replaceMessage(
                        'notifications',
                        'error',
                        e.response.message || 'Ups. Something went wrong, please try again',
                        5000,
                    ),
                )
                setIsLoading(false)
            })
    }

    const updatePN = ({
        campaign,
        targetForm,
        contentForm,
        scheduleForm,
    }: {
        campaign: PNCampaign
        targetForm: PNTargetForm
        contentForm: PNContentForm
        scheduleForm: PNScheduleForm
    }) => {
        setIsLoading(true)

        const updatedCampaign: PNCampaign = {
            ...campaign,
            ...targetForm,
            schedule: {
                start_time: formatDateTimeStr(scheduleForm.startDate, scheduleForm.startTime),
                end_time: formatDateTimeStr(scheduleForm.endDate, scheduleForm.endTime),
                frequency: scheduleForm.frequency,
            },
            content: contentForm.content,
            url: contentForm.url,
        }

        update(updatedCampaign)
    }

    const deletePN = (campaign: PNCampaign) => {
        setIsLoading(true)

        if (campaign.id && campaign.slug) {
            deleteCampaign({ slug: campaign.slug, campaignId: campaign.id as string })
                .then(() => {
                    dispatch(replaceMessage('notifications', 'success', 'Campaign has been deleted', 5000))
                    setIsLoading(false)
                })
                .catch((e) => {
                    dispatch(
                        replaceMessage(
                            'notifications',
                            'error',
                            e.response.message || 'Ups. Something went wrong, please try again',
                            5000,
                        ),
                    )
                    setIsLoading(false)
                })
        }
    }

    const stopPN = (campaign: PNCampaign) => {
        update({ ...campaign, active: false })
    }

    const startPN = (campaign: PNCampaign) => {
        update({ ...campaign, active: true })
    }

    return {
        isLoading,
        createPN,
        updatePN,
        deletePN,
        stopPN,
        startPN,
    }
}

export default usePNActions
