import React, { useCallback, useEffect, useState } from 'react'
import { find, sortBy } from 'lodash'
import { DropResult } from 'react-beautiful-dnd'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import styled from 'styled-typed'

import ActionButton, { ActionButtonA } from 'uiComponents/buttons'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { useSelectedParkMapContext } from 'venue/parkMap/context/selectedParkMap'
import {
    useDeleteCategoryMutation,
    useDeletePoiMutation,
    useParkMapCategoriesQuery,
    useUpdateCategoryPositionsMutation,
    useUpdatePoiMutation,
} from 'venue/parkMap/reduxQuery'
import { BaseRouteParams } from '../../../../hocs'
import { useParkMapViewerContext } from '../../context/mapViewer'
import useMarkers from '../../hooks/useMarkers'
import { Footer } from '../mapStyle/details'
import CategoriesPoisList from './categoriesPoisList'
import { LoaderWrapper } from 'uiComponents/loaders'
import { sortCategories } from './utils'
import InformationBox from '../informationBox'

const Container = styled.div`
    padding: 0 0 1em 0;
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`

const Info = styled.div`
    margin: -0.5em 0 1.5em 0;
`

const CategoriesPoisListPage = () => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const { selectedParkMap } = useSelectedParkMapContext()
    const { map } = useParkMapViewerContext()
    const { data, isLoading } = useParkMapCategoriesQuery({ locationId: selectedParkMap?.locationId })
    const [updatePoi] = useUpdatePoiMutation()
    const { url } = useRouteMatch()
    const history = useHistory()
    const categories = sortBy(data, 'position') ?? []
    const [poiIdToDelete, setPoiIdToDelete] = useState<string>()
    const [deletePoi, deletePoiEffects] = useDeletePoiMutation()
    const [categoryIdToDelete, setCategoryIdToDelete] = useState<string>()
    const [deleteCategory, deleteCategoryEffects] = useDeleteCategoryMutation()
    const { displayMarkers, removeMarker } = useMarkers()
    const [updateCategoryPositions] = useUpdateCategoryPositionsMutation()

    useEffect(() => {
        if (categories.length) {
            displayMarkers(categories)
        }
    }, [categories.length, map])

    const onDeletePoi = () => {
        if (poiIdToDelete) {
            deletePoi({ poiId: poiIdToDelete })
            removeMarker(poiIdToDelete)
            setPoiIdToDelete(undefined)
        }
    }

    const onDeleteCategory = () => {
        if (categoryIdToDelete) {
            deleteCategory({ categoryId: categoryIdToDelete })
            setCategoryIdToDelete(undefined)
        }
    }

    const onDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) {
                return
            }

            if (result.type === 'droppable-poi') {
                const categorySource = find(categories, { id: result.source.droppableId })
                const poiToUpdate = find(categorySource?.pois, { id: result.draggableId })

                if (poiToUpdate) {
                    updatePoi({
                        poi: { ...poiToUpdate, categoryId: result.destination.droppableId },
                        oldCategoryId: result.source.droppableId,
                        indexDestination: result.destination.index,
                    })
                }
            } else {
                const resultSortedCategories = sortCategories(
                    result.draggableId,
                    result.source.index,
                    result.destination.index,
                    categories,
                )
                updateCategoryPositions({
                    sortedCategories: resultSortedCategories,
                    slug: categories[0].slug,
                    locationId: categories[0].locationId,
                })
            }
        },
        [categories],
    )

    return (
        <LoaderWrapper loading={isLoading}>
            <Container>
                <Info>
                    <InformationBox text="Here you can move Categories to organize the order that they appear in the app, and you can move POIs from one category to another." />
                </Info>
                {poiIdToDelete && (
                    <ConfirmationDialog
                        title="Are you sure you want to delete this POI?"
                        text="Once deleted this data will be permanently lost."
                        buttonText="Delete"
                        loading={deletePoiEffects.isLoading}
                        onCancel={() => setPoiIdToDelete(undefined)}
                        onConfirm={onDeletePoi}
                    />
                )}
                {categoryIdToDelete && (
                    <ConfirmationDialog
                        title="Are you sure you want to delete this Category?"
                        text="Once deleted this data will be permanently lost."
                        buttonText="Delete"
                        loading={deleteCategoryEffects.isLoading}
                        onCancel={() => setCategoryIdToDelete(undefined)}
                        onConfirm={onDeleteCategory}
                    />
                )}
                <CategoriesPoisList
                    data={categories}
                    onDragEnd={onDragEnd}
                    onEditCategory={(categoryId: string) => history.push(`${url}/category/${categoryId}`)}
                    onAddPoi={(categoryId: string) => history.push(`${url}/poi/create/${categoryId}`)}
                    onDeleteCategory={(categoryId) => setCategoryIdToDelete(categoryId)}
                    onDeletePoi={(poiId) => setPoiIdToDelete(poiId)}
                    onEditPoi={(categoryId: string) => history.push(`${url}/poi/${categoryId}`)}
                />
                <ButtonContainer>
                    <ActionButton kind="action" defaultChecked onClick={() => history.push(`${url}/category/create`)}>
                        + Add Category
                    </ActionButton>
                </ButtonContainer>
            </Container>
            <Footer>
                <ActionButtonA size="large" href={`/account/${accountSlug}/venue/park_map/list`} secondary>
                    Back to list
                </ActionButtonA>
            </Footer>
        </LoaderWrapper>
    )
}

export default CategoriesPoisListPage
