import React, { useState, useEffect, useMemo } from 'react'
import { Result } from 'result'
import { useAccountChange } from 'reactUtils'
import styled from 'styled-typed'
import { History } from 'history'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { MINUTES_PER_DAY, delay, parseDate } from 'utils'
import { Feature, withFeatures } from 'features'
import { format } from 'date-fns'
import { ActionButton, ActionButtonA } from 'uiComponents/buttons'
import { withNavigation } from 'hocs'
import { ButtonWrapper, TextWithHorizontalLine } from 'uiComponents/pageElements'
import { MessageKind } from 'uiComponents/messages'
import { ContainerBody } from 'uiComponents/settingsContainer'
import { WeekdayPattern } from 'uiComponents/weekdayPattern'
import { TableLoader } from 'uiComponents/loaders'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { Col, Row } from 'uiComponents/flex'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { SingleSelectOption, SingleSelect, TextInput, NumberInput } from 'uiComponents/input'
import { TimeSlotsSection, startGreaterThanEnd } from './slots'
import { TimeSlotsServiceContext } from './context'
import { TimeSlotGroup, TimeSlot, TimeSlotsValidationError } from './timeSlotsService'
import Infotip from 'uiComponents/infotip'
import { Radio, RadioWrapper, RadioText } from 'uiComponents/input/radio'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { isLoadingArticlesAndCategories, useGetAllArticles, useGetAllCategoriesForSelect } from '../products/redux'
import { useGetProductAdditionalInfoQuery } from '../products/reduxApi'
import { getProductName } from '../products/productTable/utils'
import { ArticleFromApi, ProductAdditionalInfo } from '../products/types'
import { useSelector } from 'react-redux'
import { Combobox } from '../uiComponents/input/combobox'

export const Section = styled(Col)`
    margin-bottom: 1rem;
`
const RadioButtonWrapper = styled(RadioWrapper)`
    margin-bottom: 0.5rem;
`
const DuplicateButtonWrapper = styled.div`
    text-align: right;
    width: 100%;
`

interface TimeSlotPageParams {
    accountSlug: string
    id: string
}

interface TimeSlotGroupFormProps {
    prototype?: boolean
    accountSlug: string
    match: RouteMatch<TimeSlotPageParams>
    history: History
    navigation: Navigation
    setActiveSection: (section: string, header: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function TimeSlotGroupForm(props: TimeSlotGroupFormProps) {
    const timeSlotsService = React.useContext(TimeSlotsServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [name, setName] = useState<string>('')
    const [weekdays, setWeekdays] = useState<number[]>([0, 1, 2, 3, 4, 5, 6])
    const [availableFrom, setAvailableFrom] = useState<string>('')
    const [availableTo, setAvailableTo] = useState<string>('')
    const [priority, setPriority] = useState<number | null>(null)
    const [visibilityOffsetMinutes, setVisibilityOffsetMinutes] = useState<number | null>(null)
    const [visibilityOffsetMinutesInput, setVisibilityOffsetMinutesInput] = useState<string>('')
    const [visibilityOffsetMinutesError, setVisibilityOffsetMinutesError] = useState<boolean>(false)
    const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([])
    const [validate, setValidate] = useState<boolean>(false)
    const [selectedProduct, setSelectedProduct] = useState<string | null>(null)
    const [duplicates, setDuplicates] = useState<boolean>(false)
    const [slotsTimesValid, setSlotsTimesValid] = useState<boolean>(true)
    const [confirmDeletion, setConfirmDeletion] = useState<boolean>(false)
    const [defaultForAllCat, setDefaultForAllCat] = useState<boolean>(true)
    const [selectedCategories, setSelectedCategories] = useState<string[]>([])
    const [tempSelectedCategories, setTempSelectedCategories] = useState<string[]>([])
    const [showDuplicateWarning, setShowDuplicateWarning] = useState<boolean>(false)
    const [productTimeslot, setProductTimeslot] = useState<boolean>(false)
    const [invalidProductAssigned, setInvalidProductAssigned] = useState<boolean>(false)
    const categories = useGetAllCategoriesForSelect()
    const allProducts = useGetAllArticles()
    const loadingMetadata = useSelector(isLoadingArticlesAndCategories)
    const { data: productsAdditionalInfo } = useGetProductAdditionalInfoQuery({ account: props.accountSlug })

    const productsAdditionalInfoInObject = useMemo(
        () =>
            productsAdditionalInfo?.reduce((acc, product) => {
                acc[product.uuid] = product
                return acc
            }, {}) ?? {},
        [productsAdditionalInfo],
    )

    const productsOptions = useMemo(() => {
        return (
            allProducts
                ?.filter((product) => checkArticleCanHaveTimeslots(product, productsAdditionalInfoInObject[product.id]))
                ?.map((product) => {
                    let name = getProductName(product.name)
                    const additionalInfo = productsAdditionalInfoInObject[product.id]

                    if (additionalInfo?.id) {
                        name = `${additionalInfo?.id} ${name}`
                    }

                    return {
                        value: product.id,
                        name: name,
                        numericId: additionalInfo?.id,
                    } as SingleSelectOption & { numericId: string }
                }) ?? []
        )
    }, [allProducts, productsAdditionalInfoInObject])

    async function fetchTimeSlotData(id: string) {
        try {
            setLoading(true)
            const data = await timeSlotsService.getTimeSlot(props.accountSlug, id)
            setDataToState(data)
        } catch {
            setLoading(false)
            props.replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again.')
        }
    }

    function setDataToState(data: TimeSlotGroup) {
        setName(props.prototype ? `COPY OF ${data.name}` : data.name)
        setPriority(data.priority)
        setVisibilityOffsetMinutesInput(data.visibilityOffsetMinutes ? data.visibilityOffsetMinutes.toString() : '')
        setVisibilityOffsetMinutes(data.visibilityOffsetMinutes)
        setWeekdays(data.weekdays)
        setAvailableFrom(data.availableFrom || '')
        setAvailableTo(data.availableTo || '')
        setTimeSlots(data.timeSlots)
        setSelectedCategories(data.productsLists.map((pl) => pl.id))
        setTempSelectedCategories(data.productsLists.map((pl) => pl.id))
        setDefaultForAllCat(data.productsLists.length === 0)
        setSelectedProduct(data.article?.uuid || null)
        setLoading(false)
    }

    function loadTimeSlotGroup() {
        const productView = props.navigation.query().timeslots_view === 'products'
        setProductTimeslot(productView)
        const timeSlotId = props.match.params.id
        const titlePart = !props.hasFeature('TimeSlotsForProduct', props.accountSlug)
            ? ''
            : productView
            ? 'Product '
            : 'Category '
        props.setActiveSection(
            'timeSlots',
            timeSlotId === 'new' ? `Add New ${titlePart}Time Slots Group` : `${titlePart}Time Slots Group`,
        )
        if (!!timeSlotId && timeSlotId !== 'new') {
            fetchTimeSlotData(timeSlotId)
        }
    }

    useEffect(loadTimeSlotGroup, [])

    useEffect(() => {
        checkForDuplicateSlots()
        checkSlotsTimeValidity()
    }, [timeSlots])

    useEffect(() => {
        const selectedProductOnTheList = productsOptions.find((o) => o.value === selectedProduct)
        setInvalidProductAssigned(!selectedProductOnTheList)
    }, [productsOptions, selectedProduct])

    useAccountChange(props.accountSlug, () =>
        props.history.push(`/account/${props.accountSlug}/products/time_slots/home`),
    )

    function getInvalidProductMessage() {
        const articleData = productsAdditionalInfoInObject[selectedProduct as string]
        const article = allProducts.find((product) => product.id === selectedProduct)
        const articleName = getProductName(article?.name)

        return articleData
            ? `Currently assigned product (${articleData?.id}) ${articleName} cannot have time slots, because it is
      neither an upsell nor it belongs to only the Dated Checkout flow.`
            : `Currently assigned product with the UUID "${selectedProduct}" is not on the list of the available products.`
    }

    function checkArticleCanHaveTimeslots(article: ArticleFromApi, additionalInfo?: ProductAdditionalInfo) {
        const articleParentsHaveOnlyDatedFlow =
            article.categories?.filter((category) => category.flow !== 'dated').length === 0
        return additionalInfo?.pricingType === 'upsell' || articleParentsHaveOnlyDatedFlow
    }

    function checkForDuplicateSlots(): boolean {
        let duplicate = false
        timeSlots.every((ts) => {
            if (timeSlots.filter((t) => t.startTime === ts.startTime && t.endTime === ts.endTime).length > 1) {
                duplicate = true
                return false
            } else {
                return true
            }
        })
        setDuplicates(duplicate)
        return duplicate
    }

    function checkSlotsTimeValidity(): boolean {
        let valid = !timeSlots.find((ts) => !!ts.endTime && startGreaterThanEnd(ts.startTime, ts.endTime))
        setSlotsTimesValid(valid)
        return valid
    }

    function isTimeSlotDataValid() {
        setValidate(true)
        const duplicate = checkForDuplicateSlots()
        const slotsTimeValid = checkSlotsTimeValidity()
        const categoriesSelected = defaultForAllCat || selectedCategories.length > 0
        const productSelected = productTimeslot ? !!selectedProduct && !invalidProductAssigned : true
        const valid =
            !!name &&
            !!priority &&
            weekdays.length > 0 &&
            (!availableFrom || !availableTo || new Date(availableFrom) <= new Date(availableTo)) &&
            timeSlots.length > 0 &&
            !!slotsTimeValid &&
            !duplicate &&
            categoriesSelected &&
            productSelected &&
            !visibilityOffsetMinutesError
        if (!valid) {
            props.replaceMessages('validation_error', 'error', 'Please fix the validation errors below.')
        }
        return valid
    }

    async function onTimeSlotGroupSave() {
        if (isTimeSlotDataValid()) {
            setValidate(false)
            const productsLists = defaultForAllCat ? [] : selectedCategories.map((c) => ({ id: c, name: '' }))
            const article = selectedProduct ? { uuid: selectedProduct, id: '', name: '' } : null
            const id = props.match.params.id
            const payload = {
                id,
                name,
                priority,
                visibilityOffsetMinutes,
                weekdays,
                productsLists,
                article,
                availableFrom: availableFrom || null,
                availableTo: availableTo || null,
                timeSlots,
            }
            try {
                setLoading(true)
                if (props.match.params.id !== 'new' && !props.prototype) {
                    const responseData = await timeSlotsService.updateTimeSlotGroup(props.accountSlug, payload, id)
                    handleResponse(responseData)
                } else {
                    const responseData = await timeSlotsService.createTimeSlotGroup(props.accountSlug, payload)
                    handleResponse(responseData)
                }
            } catch {
                setLoading(false)
                props.replaceMessages(
                    'server_error',
                    'error',
                    'Oops! Could not save the time slot, please try again later.',
                )
            }
        }
    }

    function getQueryString() {
        const hasFeature = props.hasFeature('TimeSlotsForProduct', props.accountSlug)
        return hasFeature ? `/?timeslots_view=${productTimeslot ? 'products' : 'categories'}` : ''
    }

    function handleResponse(responseData: Result<TimeSlotGroup, TimeSlotsValidationError>) {
        responseData
            .ifFailure((err: TimeSlotsValidationError) => {
                props.replaceMessages(err.code, 'error', `Oops! ${err.message}`)
                setLoading(false)
            })
            .ifSuccess(() => {
                loadTimeSlotGroup()
                flashSuccessMessage()
            })
    }

    async function flashSuccessMessage() {
        props.replaceMessages('success', 'success', 'The time slot group has been saved successfully.')
        await delay(3000)
        props.removeAllMessages()
    }

    function endDateValid(): boolean {
        return !availableTo || !availableFrom || new Date(availableTo) >= new Date(availableFrom)
    }

    async function onTimeSlotGroupDelete() {
        if (confirmDeletion) {
            setLoading(true)
            try {
                await timeSlotsService.deleteTimeSlotGroup(props.accountSlug, props.match.params.id)
                setConfirmDeletion(false)
                props.history.push(`/account/${props.accountSlug}/products/time_slots/home${getQueryString()}`)
            } catch {
                setLoading(false)
                props.replaceMessages(
                    'server_error',
                    'error',
                    'Oops! Could not delete the time slot, please try again later.',
                )
            }
        } else {
            setConfirmDeletion(true)
        }
    }

    const isOffsetValid = (value: string) => {
        if (value === '') {
            return true
        }
        if (isNaN(Number(value))) {
            return false
        }
        return Number(value) >= -MINUTES_PER_DAY && Number(value) <= 2 * MINUTES_PER_DAY
    }

    const onOffsetChange = (value: string) => {
        setVisibilityOffsetMinutesInput(value)
        if (isOffsetValid(value)) {
            setVisibilityOffsetMinutes(Number(value))
            setVisibilityOffsetMinutesError(false)
        } else {
            setVisibilityOffsetMinutesError(true)
        }
    }

    const toggleDefaultForAll = () => {
        if (defaultForAllCat) {
            setSelectedCategories(tempSelectedCategories)
        } else {
            setSelectedCategories([])
        }
        setDefaultForAllCat(!defaultForAllCat)
    }

    const onCatSelectionChange = (ids: string[]) => {
        setSelectedCategories(ids)
        setTempSelectedCategories(ids)
    }

    const onDuplicate = () => {
        props.history.push(
            `/account/${props.accountSlug}/products/time_slots/duplicate/${props.match.params.id}${getQueryString()}`,
        )
        setShowDuplicateWarning(false)
        setName(`COPY OF ${name}`)
        props.replaceMessages(
            'warning',
            'warn',
            `You are making a copy of "${name}" Time Slot Group. Please remember to update the name and
      change either priority or applicable dates before saving this copy.`,
        )
    }

    return (
        <div style={{ position: 'relative' }}>
            {showDuplicateWarning && (
                <ConfirmationDialog
                    title="Duplicate Time Slot Group?"
                    text="Time Slot Group will be duplicated with all of its settings."
                    buttonText="Duplicate"
                    onCancel={() => setShowDuplicateWarning(false)}
                    onConfirm={onDuplicate}
                />
            )}
            {(loading || loadingMetadata) && <TableLoader />}
            {!loading && !loadingMetadata && (
                <ContainerBody id="timeSlot-form" style={{ flexDirection: 'column' }}>
                    <Row>
                        {!props.prototype && props.match.params.id !== 'new' && (
                            <DuplicateButtonWrapper>
                                <ActionButton
                                    onClick={() => setShowDuplicateWarning(true)}
                                    kind="action"
                                    size="large"
                                    secondary
                                >
                                    Duplicate
                                </ActionButton>
                            </DuplicateButtonWrapper>
                        )}
                    </Row>
                    <Row>
                        <Section span={6}>
                            <FormItem htmlFor="timeSlot-name" style={{ position: 'relative' }}>
                                <FormItemName>Time Slot Group name</FormItemName>
                                <TextInput
                                    id="timeSlot-name"
                                    name="timeSlot-name"
                                    type="text"
                                    maxLength={60}
                                    value={name || ''}
                                    placeholder="Add time slot name..."
                                    onChange={(e) => setName(e.target.value)}
                                    status={validate && !name ? 'error' : 'normal'}
                                    block
                                />
                            </FormItem>
                            {validate && !name && (
                                <ValidationMessage className="validation-message-visible">
                                    This field is required
                                </ValidationMessage>
                            )}
                        </Section>
                        <Section span={6}>
                            <FormItem htmlFor="timeSlot-pattern" style={{ position: 'relative' }}>
                                <FormItemName>Define weekly pattern</FormItemName>
                                <WeekdayPattern
                                    id="timeSlot-pattern"
                                    weekdays={weekdays.length > 0 ? weekdays.toString() : '0,1,2,3,4,5,6'}
                                    large
                                    handleWeekdayToggleChange={(wkds) => setWeekdays(wkds.map((wd) => Number(wd)))}
                                    type="strict"
                                />
                            </FormItem>
                        </Section>
                    </Row>
                    <Row>
                        <Col span={6} style={{ marginTop: productTimeslot ? '' : '-0.7em' }}>
                            {!productTimeslot && (
                                <div style={{ display: 'flex' }}>
                                    <RadioButtonWrapper>
                                        <Radio
                                            id="default-for-all"
                                            onChange={toggleDefaultForAll}
                                            checked={defaultForAllCat}
                                        />
                                        <RadioText>Default for all categories</RadioText>
                                    </RadioButtonWrapper>
                                    <RadioButtonWrapper style={{ marginLeft: '1.5em' }}>
                                        <Radio
                                            id="selected-cats"
                                            onChange={toggleDefaultForAll}
                                            checked={!defaultForAllCat}
                                        />
                                        <RadioText>For selected categories</RadioText>
                                    </RadioButtonWrapper>
                                </div>
                            )}
                            <FormItem htmlFor="timeSlot-category" style={{ position: 'relative' }}>
                                <FormItemName>
                                    {productTimeslot ? 'Product' : 'Category'}
                                    {productTimeslot && (
                                        <Infotip pointer="left" maxWidth="30em">
                                            Only either upsell products or products that are only in the Dated Checkout
                                            flow can be assigned a Time Slots group.
                                        </Infotip>
                                    )}
                                </FormItemName>
                                <Feature name="TimeSlotsForProduct" accountSlug={props.accountSlug} invert>
                                    <Combobox
                                        id="timeSlot-category"
                                        name="timeSlot-category"
                                        options={categories}
                                        value={selectedCategories}
                                        multiple
                                        nested
                                        onChange={onCatSelectionChange}
                                        disabled={defaultForAllCat}
                                        status={
                                            validate && !defaultForAllCat && selectedCategories.length < 1
                                                ? 'error'
                                                : 'normal'
                                        }
                                    />
                                </Feature>
                                <Feature name="TimeSlotsForProduct" accountSlug={props.accountSlug}>
                                    {!productTimeslot && (
                                        <Combobox
                                            id="timeSlot-category"
                                            name="timeSlot-category"
                                            multiple
                                            nested
                                            options={categories}
                                            value={selectedCategories}
                                            onChange={onCatSelectionChange}
                                            disabled={defaultForAllCat}
                                            status={
                                                validate && !defaultForAllCat && selectedCategories.length < 1
                                                    ? 'error'
                                                    : 'normal'
                                            }
                                        />
                                    )}
                                    {productTimeslot && (
                                        <>
                                            <SingleSelect
                                                options={productsOptions}
                                                selected={selectedProduct}
                                                id="article-select"
                                                noSelectOption="Select a product"
                                                height="2.6em"
                                                maxHeight="25em"
                                                onSelect={(v) => setSelectedProduct(v)}
                                                width="100%"
                                                status={
                                                    validate && (!selectedProduct || invalidProductAssigned)
                                                        ? 'error'
                                                        : 'normal'
                                                }
                                            />
                                            {validate && !selectedProduct && (
                                                <ValidationMessage
                                                    className="validation-message-visible"
                                                    style={{ top: '0' }}
                                                >
                                                    Please select a product
                                                </ValidationMessage>
                                            )}
                                            {validate && selectedProduct && invalidProductAssigned && (
                                                <ValidationMessage
                                                    className="validation-message-visible"
                                                    style={{ top: '0' }}
                                                >
                                                    {getInvalidProductMessage()}
                                                </ValidationMessage>
                                            )}
                                        </>
                                    )}
                                </Feature>
                            </FormItem>
                            {validate && !defaultForAllCat && selectedCategories.length < 1 && (
                                <ValidationMessage className="validation-message-visible">
                                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                                    Select at least one category or choose "Default for all categories"
                                </ValidationMessage>
                            )}
                        </Col>
                        <Section span={6}>
                            <Row>
                                <Col span={6}>
                                    <FormItem htmlFor="availableFrom" style={{ position: 'relative' }}>
                                        <FormItemName>Effective from (optional)</FormItemName>
                                        <DatePicker
                                            id="availableFrom"
                                            date={availableFrom ? parseDate(availableFrom) : null}
                                            onChange={(value: Date) =>
                                                setAvailableFrom(value ? format(value, 'yyyy-MM-dd') : '')
                                            }
                                            allowNullDate={true}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem htmlFor="availableTo" style={{ position: 'relative' }}>
                                        <FormItemName>
                                            Effective to (optional)
                                            <Infotip pointer="left">Date inclusive</Infotip>
                                        </FormItemName>
                                        <DatePicker
                                            id="availableTo"
                                            date={availableTo ? parseDate(availableTo) : null}
                                            onChange={(value: Date) =>
                                                setAvailableTo(value ? format(value, 'yyyy-MM-dd') : '')
                                            }
                                            status={validate && !endDateValid() ? 'error' : 'normal'}
                                            allowNullDate={true}
                                            lowerBoundary={availableFrom ? parseDate(availableFrom) : null}
                                        />
                                        {validate &&
                                            availableFrom &&
                                            availableTo &&
                                            new Date(availableTo) < new Date(availableFrom) && (
                                                <ValidationMessage
                                                    className="validation-message-visible"
                                                    style={{ top: '0' }}
                                                >
                                                    End date has to be greater than the start date
                                                </ValidationMessage>
                                            )}
                                    </FormItem>
                                </Col>
                            </Row>
                        </Section>
                    </Row>
                    <Row
                        style={{
                            position: 'relative',
                            marginTop: productTimeslot ? '' : '1.5em',
                        }}
                    >
                        <Section span={2}>
                            <FormItem htmlFor="product-priority">
                                <FormItemName>
                                    Priority
                                    <Infotip pointer="left" maxWidth="25em">
                                        A number from 1 to 1000. The Time Slot Group with the highest number will have
                                        the highest priority.
                                    </Infotip>
                                </FormItemName>
                                <NumberInput
                                    id="priority"
                                    integerOnly
                                    value={priority || ''}
                                    placeholder="1"
                                    min={1}
                                    max={1000}
                                    status={validate && !priority ? 'error' : 'normal'}
                                    onChange={(e) => setPriority(Number(e.target.value))}
                                />
                            </FormItem>
                            {validate && !priority && (
                                <ValidationMessage
                                    className="validation-message-visible"
                                    style={{ top: '0', marginLeft: '0' }}
                                >
                                    Please enter a number from 1 to 1000
                                </ValidationMessage>
                            )}
                        </Section>
                        <Section span={4}>
                            <FormItemName>
                                Minimum advance booking time (minutes)
                                <Infotip pointer="left" maxWidth="25em">
                                    The time in minutes time slots have to be booked in advance. Negative values can be
                                    used to allow bookings after time slots have started.
                                </Infotip>
                            </FormItemName>
                            <FormItem htmlFor="offset-minutes">
                                <NumberInput
                                    id="offset-minutes"
                                    type="text"
                                    integerOnly
                                    value={visibilityOffsetMinutesInput}
                                    placeholder="-"
                                    min={-MINUTES_PER_DAY}
                                    max={2 * MINUTES_PER_DAY}
                                    onChange={(e) => onOffsetChange(e.target.value)}
                                    autoCorrectInput={false}
                                    status={visibilityOffsetMinutesError ? 'error' : 'normal'}
                                    style={{ width: 70 }}
                                />
                            </FormItem>
                            <ValidationMessage
                                className={visibilityOffsetMinutesError ? 'validation-message-visible' : ''}
                                style={{ top: '0', marginLeft: '0' }}
                            >
                                Please enter a valid number
                            </ValidationMessage>
                        </Section>
                        <Section span={6} />
                    </Row>
                    <TextWithHorizontalLine text="Manage times" alignment="left" />
                    <TimeSlotsSection
                        slots={timeSlots}
                        updateSlots={setTimeSlots}
                        duplicates={duplicates}
                        timesValid={slotsTimesValid}
                        validate={validate}
                    />
                    {confirmDeletion && (
                        <Row>
                            <div style={{ fontSize: '.9em', marginBottom: '1.5em' }}>
                                Are you sure you want to delete this time slot?
                            </div>
                        </Row>
                    )}
                    <Row>
                        <Col span={6}>
                            {props.match.params.id !== 'new' && !props.prototype && (
                                <ActionButton
                                    type="button"
                                    kind="destructive"
                                    size="large"
                                    onClick={onTimeSlotGroupDelete}
                                >
                                    Delete
                                </ActionButton>
                            )}
                            {confirmDeletion && (
                                <ActionButton
                                    size="large"
                                    secondary
                                    onClick={() => setConfirmDeletion(false)}
                                    style={{ marginLeft: '1.5em' }}
                                >
                                    Cancel
                                </ActionButton>
                            )}
                        </Col>
                        <Col span={6}>
                            <ButtonWrapper>
                                <ActionButtonA
                                    size="large"
                                    href={`/account/${props.accountSlug}/products/time_slots/home`}
                                    secondary
                                    style={{ marginRight: '1.5em' }}
                                    kind="action"
                                >
                                    Cancel
                                </ActionButtonA>
                                <ActionButton
                                    className="submit-timeslots"
                                    id="save-timeSlot"
                                    size="large"
                                    onClick={onTimeSlotGroupSave}
                                    style={{ marginRight: '1.5em' }}
                                >
                                    Save
                                </ActionButton>
                            </ButtonWrapper>
                        </Col>
                    </Row>
                </ContainerBody>
            )}
        </div>
    )
}

export default withFeatures(withNavigation(TimeSlotGroupForm))
