import React from 'react'
import { useParams } from 'react-router-dom'

import { useListParkMapQuery } from '../reduxQuery'
import ParkMapWelcomePage from './welcome'
import ParkMapList from './list'
import { ParkMapPagesParams } from '../paths'
import { LoaderWrapper } from 'uiComponents/loaders'

const ParkMap = () => {
    const { accountSlug } = useParams<ParkMapPagesParams>()
    const { data, isFetching } = useListParkMapQuery({ slug: accountSlug })

    return (
        <LoaderWrapper loading={isFetching}>
            {!data || data?.length === 0 ? <ParkMapWelcomePage /> : <ParkMapList parkmaps={data} />}
        </LoaderWrapper>
    )
}

export default ParkMap
