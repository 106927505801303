import React, { useState, useEffect } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import {
    MultiSelect,
    Radio,
    RadioWrapper,
    RadioText,
    FormTogglerCol,
    FormToggler,
    SingleSelectOption,
} from 'uiComponents/input'
import { Row } from 'uiComponents/flex'
import { FormItemName } from 'uiComponents/form/formElements'
import { ProductListData } from 'admin/articleService'
import Feature from 'features/feature'
import { DisabledItemHoverInfotip } from 'uiComponents/infotip'
import Infotip from 'uiComponents/infotip'

interface Props {
    accountSlug: string
    categoryData: ProductListData
    resellersOptions: SingleSelectOption[]
    validate: boolean
    setCategoryData: (data: ProductListData) => void
}

export function ResellersSection(props: Props) {
    const [markErrors, setMarkErrors] = useState<boolean>(false)

    const { accountSlug, categoryData, resellersOptions, validate, setCategoryData } = props

    useEffect(() => {
        if (validate) {
            setMarkErrors(true)
        }
    }, [validate])

    function onResellersToggle(v: boolean) {
        const newData = cloneDeep(categoryData)
        newData.enabledForResellers = v
        setCategoryData(newData)
    }

    function onResellersVisibilityChange(e: React.ChangeEvent<HTMLInputElement>) {
        const newValue = e.target.id === 'all-resellers'
        const newData = cloneDeep(categoryData)
        newData.visibleToAllResellers = newValue
        setCategoryData(newData)
    }

    function onResellerSelectionChange(resellers: string[]) {
        const newData = cloneDeep(categoryData)
        newData.visibleToSelectedResellers = resellers
        setCategoryData(newData)
    }

    const missingResellerSelection =
        !categoryData.visibleToAllResellers && !categoryData.visibleToSelectedResellers.length

    return (
        <>
            <Row>
                <FormTogglerCol span={12} style={{ marginTop: '1.5em' }}>
                    <FormItemName>
                        <span className={!categoryData.enabled ? 'disabled' : ''}>Show to resellers</span>
                        <Infotip pointer="left" maxWidth="20em">
                            If this toggle is on, the category will be visible to resellers even if it is hidden from
                            general checkout users.
                        </Infotip>
                    </FormItemName>
                    <DisabledItemHoverInfotip
                        active={!categoryData.enabled}
                        infotipText="The category is disabled"
                        width="20em"
                    >
                        <FormToggler
                            id="resellers-visible"
                            isOn={categoryData.enabledForResellers}
                            onClick={onResellersToggle}
                            className={!categoryData.enabled ? 'disabled' : ''}
                            disabled={!categoryData.enabledForResellers && !categoryData.enabled}
                        />
                    </DisabledItemHoverInfotip>
                </FormTogglerCol>
            </Row>
            <Feature name="ProductListPerReseller" accountSlug={accountSlug}>
                {categoryData.enabledForResellers && (
                    <>
                        <Row>
                            <FormTogglerCol span={4}>
                                <RadioWrapper>
                                    <Radio
                                        id="all-resellers"
                                        value="none"
                                        onChange={onResellersVisibilityChange}
                                        checked={categoryData.visibleToAllResellers}
                                    />
                                    <RadioText>Show to all resellers</RadioText>
                                </RadioWrapper>
                            </FormTogglerCol>
                            <FormTogglerCol span={8}>
                                <DisabledItemHoverInfotip
                                    active={!resellersOptions.length}
                                    infotipText="The are no resellers created"
                                    width="15em"
                                >
                                    <RadioWrapper>
                                        <Radio
                                            id="selected-resellers"
                                            value="none"
                                            onChange={onResellersVisibilityChange}
                                            checked={!categoryData.visibleToAllResellers}
                                            disabled={!resellersOptions.length}
                                        />
                                        <RadioText>Show to selected resellers</RadioText>
                                    </RadioWrapper>
                                </DisabledItemHoverInfotip>
                            </FormTogglerCol>
                        </Row>
                        {!categoryData.visibleToAllResellers && (
                            <Row>
                                <FormTogglerCol span={4} />
                                <FormTogglerCol span={8}>
                                    <MultiSelect
                                        id="resellers"
                                        options={resellersOptions}
                                        selected={categoryData.visibleToSelectedResellers}
                                        height="2.5rem"
                                        maxHeight="25em"
                                        width="100%"
                                        noSelectOption="No resellers selected"
                                        onSelect={onResellerSelectionChange}
                                        status={markErrors && missingResellerSelection ? 'error' : 'normal'}
                                    />
                                </FormTogglerCol>
                            </Row>
                        )}
                    </>
                )}
            </Feature>
        </>
    )
}
