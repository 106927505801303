import styled from 'styled-typed'
import { ValidationMessage } from 'uiComponents/form/formElements'

interface ValidationNoticeProps {
    large?: boolean
}

export const ValidationNotice = styled(ValidationMessage)<ValidationNoticeProps>`
    font-size: ${(props) => (props.large ? '.9em' : '0.625em')};
    display: block;
    margin-top: 1em;
    margin-left: 0;
`
