import { faCopy, faFileExport, faPauseCircle, faPencilAlt, faPlay, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BaseRouteParams } from 'hocs'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ReactTable, TableColumn } from 'uiComponents/table/reactTable'
import { StatusAndText } from '../../../notificationCampaigns/components/ui'
import styled from '../../../styled-typed'
import { useVenueLocations } from '../../../tapPlacements/studio/helpers'
import { OnClickMenu, OnClickMenuItem } from '../../../uiComponents/menus'
import { MultipleStatus, NameAndStatus } from '../../../uiComponents/table'
import { NoResultsRow } from '../../../uiComponents/table/noResultsRow'
import DownloadCustomModal from '../components/downloadCustomModal'
import useAudienceActions from '../hooks/useAudienceActions'
import useIsPartnerAllowed from '../hooks/useIsPartnerAllowed'
import { useListAudienceQuery } from '../reduxQuery'
import { ICustomAudience, ICustomAudienceStatus } from '../types'
import { DateFormats, formatOrInvalid } from 'utils'

export const Icon = styled(FontAwesomeIcon)`
    font-size: 0.8em;
    margin-right: 0.4em;
`

const IconDelete = styled(Icon)`
    color: ${(props) => props.theme.colors.status.error};
`

const CustomList = () => {
    useIsPartnerAllowed()

    const { accountSlug } = useParams<BaseRouteParams>()
    const { data, isFetching } = useListAudienceQuery({ slug: accountSlug })
    const history = useHistory()
    const venueLocations = useVenueLocations(accountSlug)
    const { duplicateCA, pauseCA, deleteCA, startCA, isLoading } = useAudienceActions()
    const [downloadCustom, setDownloadCustom] = useState<ICustomAudience | undefined>(undefined)

    const getStatus = (item: ICustomAudience) => {
        switch (item.status) {
            case ICustomAudienceStatus.ACTIVE:
                return 'success'
            case ICustomAudienceStatus.DRAFT:
                return 'blue'
            default:
                return 'disabled'
        }
    }

    const getLocation = (item: ICustomAudience) => {
        const location = venueLocations.find((location) => location.value === item.location_id)
        return location?.name || '-'
    }

    const columns = useMemo(() => {
        return [
            {
                accessor: 'name',
                Header: 'Name',
            },
            {
                accessor: 'createdOn',
                Header: 'Created on',
                disableSortBy: true,
                Cell: (item: ICustomAudience) =>
                    formatOrInvalid(new Date(item.created_at || '2021-01-01'), DateFormats.LONG_DATE),
            },
            {
                accessor: 'modifiedOn',
                Header: 'Modified on',
                disableSortBy: true,
                Cell: (item: ICustomAudience) =>
                    formatOrInvalid(new Date(item.updated_at || '2021-01-01'), DateFormats.LONG_DATE),
            },
            {
                accessor: 'location',
                Header: 'Location',
                disableSortBy: true,
                Cell: ({ row: { original } }) => getLocation(original),
            },
            {
                accessor: 'state',
                Header: 'State',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    return (
                        <NameAndStatus>
                            <StatusAndText>
                                <MultipleStatus
                                    status={getStatus(original)}
                                    withText
                                    successText="Active"
                                    errorText="Inactive"
                                    blueText="Draft"
                                />
                            </StatusAndText>
                        </NameAndStatus>
                    )
                },
            },
            {
                Header: 'Audience size',
                Cell: () => '-',
            },
            {
                accessor: 'action',
                disableHideColumn: true,
                disableSortBy: true,
                width: '10em',
                align: 'right',
                style: { overflow: 'unset' },
                Cell: ({ row: { original } }) => (
                    <OnClickMenu title="Actions" kind="action" width="15em" secondary alignRight>
                        <OnClickMenuItem onClick={() => duplicateCA(original)}>
                            <Icon icon={faCopy} /> Duplicate
                        </OnClickMenuItem>
                        <OnClickMenuItem onClick={() => setDownloadCustom(original)}>
                            <Icon icon={faFileExport} /> Export
                        </OnClickMenuItem>
                        {original.status === ICustomAudienceStatus.ACTIVE && (
                            <OnClickMenuItem onClick={() => pauseCA(original)}>
                                <Icon icon={faPauseCircle} /> Pause
                            </OnClickMenuItem>
                        )}
                        {original.status !== ICustomAudienceStatus.ACTIVE && (
                            <OnClickMenuItem onClick={() => startCA(original)}>
                                <Icon icon={faPlay} /> Start
                            </OnClickMenuItem>
                        )}
                        <OnClickMenuItem
                            onClick={() => {
                                history.push(`/account/${accountSlug}/crm/audiences/custom/edit/${original.id}`)
                            }}
                        >
                            <Icon icon={faPencilAlt} /> Edit
                        </OnClickMenuItem>
                        <OnClickMenuItem onClick={() => deleteCA(original)}>
                            <IconDelete icon={faTrashAlt} /> Delete
                        </OnClickMenuItem>
                    </OnClickMenu>
                ),
            },
        ] as TableColumn<ICustomAudience>[]
    }, [])

    return (
        <div style={{ position: 'relative' }}>
            <ReactTable
                noResultsRow={() => <NoResultsRow text="There aren't any custom audiences on the list." />}
                size="small"
                sort
                loading={isFetching || isLoading}
                data={data || []}
                columns={columns}
            />
            <div style={{ height: '6rem' }} />
            <DownloadCustomModal audience={downloadCustom} onClose={() => setDownloadCustom(undefined)} />
        </div>
    )
}

export default CustomList
