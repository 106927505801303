import React, { useState, useEffect, useContext, useRef } from 'react'
import { usePrevious } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import TimeseriesBarChart from 'uiComponents/charts/timeseriesBarChart'
import { format, startOfToday, addDays, endOfDay } from 'date-fns'
import { TimeseriesBarChartSource, createTimeseriesBarChart } from 'reports/forecast/forecastChartSource'
import {
    getTooltipHeader,
    getTooltipFooter,
    getTooltipRow,
    mapTimeseriesForTooltip,
    getTooltipXAxisLabel,
} from 'reports/helpers'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { DataPoint } from 'reports/schema'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import { DateRangeName, DateRangePeriod } from 'dateRanges'
import { ChartHeadline, ChartContainer } from 'uiComponents/charts/styleComponents'
import Infotip from 'uiComponents/infotip'
import { QueryConfig } from 'reports/queryGenerator'
import PresetDateRangeElement, { PresetType } from 'uiComponents/popups/presetsElement'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import Feature from 'features/feature'
import { withFeatures } from 'features'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'

const queryConfig: QueryConfig = {
    querySetName: 'OverviewForecastChart',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'granularity', type: 'StatsGranularity' },
        { name: 'metric', type: 'StatsMetric' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'forecastFrom', type: 'Date' },
        { name: 'forecastTo', type: 'Date' },
    ],
    queries: [
        {
            name: 'actualSales',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo'],
            customVariables: [],
            presetResult: 'timeseries',
        },
        {
            name: 'estimateSales',
            type: 'stats',
            configVariables: ['widget', 'metric'],
            customVariables: [
                { name: 'dateFrom', configValue: 'forecastFrom' },
                { name: 'dateTo', configValue: 'forecastTo' },
            ],
            presetResult: 'timeseries',
        },
    ],
}

interface ChartsProps {
    navigation: Navigation
    match: RouteMatch<{}>
    accountSlug: string
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function MiniVisitorsChart(props: ChartsProps) {
    const _isMounted = useRef(false)
    const _lastRequest = useRef<number>()
    const statsService = useContext(StatsServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [mappedActualSales, setMappedActualSales] = useState<DataPoint[]>([])
    const [forecastRangeNumber, setForecastRangeNumber] = useState<number>(7)

    const rangeName = 'forecast_presetRange'
    const defaultRange = '7days'
    const rangeOptions: PresetType[] = ['7days', '4weeks']

    const getForecastRange = () => {
        if (!props.hasFeature('AnalyticsForecastChart4w', props.accountSlug)) {
            return 7
        }
        const query = props.navigation.query()
        return query[rangeName] === '4weeks' ? 28 : 7
    }

    const getForecastDateRange = (forecastRange: number | null = null) => {
        const range = forecastRange ? forecastRange : getForecastRange()
        const today = startOfToday()
        return {
            name: 'custom' as DateRangeName,
            period: 'day' as DateRangePeriod,
            from: addDays(today, -range),
            to: endOfDay(addDays(today, range - 1)),
        }
    }
    const [chart, setChart] = useState<TimeseriesBarChartSource>(createTimeseriesBarChart(getForecastDateRange()))

    const prevAccountSlug = usePrevious(props.accountSlug)
    useEffect(() => {
        _isMounted.current = true
        if (prevAccountSlug !== props.accountSlug) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug])

    const prevChart = usePrevious(chart)
    useEffect(() => {
        // @ts-ignore: Object is possibly 'undefined'.
        if (!!prevChart && prevChart.props().xAxisData.length !== chart.props().xAxisData.length) {
            getData()
        }
    }, [chart])

    const getData = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        setLoading(true)
        const dateFrom = format(chart.from(), 'yyyy-MM-dd')
        const dateTo = format(startOfToday(), 'yyyy-MM-dd')
        const forecastFrom = format(startOfToday(), 'yyyy-MM-dd')
        const forecastTo = format(addDays(startOfToday(), forecastRangeNumber - 1), 'yyyy-MM-dd')
        try {
            const variables = {
                widget: props.accountSlug,
                metric: 'actual_and_expected_visitors',
                dateFrom,
                dateTo,
                forecastFrom,
                forecastTo,
                granularity: 'day',
            }
            const data = await statsService.getStats(queryConfig, variables)
            if (_lastRequest.current !== requestTime) {
                return
            }
            chart.formaMiniChartLineSeries([data.actualSales.timeSeries[0], data.estimateSales.timeSeries[0]])
            const xAxisCategories = chart.props().xAxisDataRaw
            const granularity = chart.granularity
            const mappedSales =
                data.actualSales.timeSeries.length > 0
                    ? mapTimeseriesForTooltip(xAxisCategories, data.actualSales.timeSeries[0].points, granularity)
                    : []
            setMappedActualSales(mappedSales)
            setLoading(false)
        } catch {
            props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Forecast chart could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }

    const onRangeChange = (rangeString: string) => {
        const range = rangeString === '4weeks' ? 28 : 7
        if (range === forecastRangeNumber) {
            return
        }
        setChart(createTimeseriesBarChart(getForecastDateRange(range)))
        setForecastRangeNumber(range)
    }

    const roundExpectedValue = (value: number) => {
        if (value > 1000) {
            return Math.round(value / 100) * 100
        }
        if (value > 100) {
            return Math.round(value / 10) * 10
        }
        return value
    }

    const getMarker = (color: string) => {
        return `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color};"></span>`
    }

    const chartTooltipFormatter = (params: TooltipParams[]) => {
        const salesPoint = mappedActualSales[params[0].dataIndex]
        const date = salesPoint ? format(new Date(salesPoint.timestamp), 'yyyy-MM-dd') : null
        const sriesIndex = date && date >= format(startOfToday(), 'yyyy-MM-dd') ? 1 : 0
        const xAxisLabel = salesPoint
            ? getTooltipXAxisLabel(salesPoint.timestamp, chart.granularity)
            : params[0].axisValue

        let tooltip = getTooltipHeader(xAxisLabel)
        tooltip += getTooltipRow([
            `${getMarker(params[sriesIndex].color)} ${sriesIndex ? 'Forecast' : 'Sold'}:`,
            sriesIndex ? roundExpectedValue(Number(params[sriesIndex].value)) : params[sriesIndex].value,
        ])
        tooltip += getTooltipFooter()
        return tooltip
    }

    const axisData = chart.props().xAxisData
    const series = chart.props().series
    const navigationLink = `/account/${props.accountSlug}/reports/forecast/${location.search}`

    return (
        <ChartContainer className="clickable-title">
            {loading && <ChartDataLoader />}
            <ChartHeadline size={4}>
                <span>
                    <UnstyledLink to={navigationLink}>Visitors forecast</UnstyledLink>
                </span>
                <Infotip pointer="left" fixedMaxSize>
                    See a detailed visitor forecast in the&nbsp;
                    <span className="link">
                        <UnstyledLink to={navigationLink}>Forecast report</UnstyledLink>
                    </span>
                </Infotip>
                <Feature name="AnalyticsForecastChart4w" accountSlug={props.accountSlug}>
                    <PresetDateRangeElement
                        presetName={rangeName}
                        default={defaultRange}
                        options={rangeOptions}
                        onChange={onRangeChange}
                    />
                </Feature>
            </ChartHeadline>
            <TimeseriesBarChart
                axisData={axisData}
                series={series}
                legendWidth={525}
                loading={loading}
                tooltipFormatter={chartTooltipFormatter}
                legendDisabled
                height="350px"
                titleOffset={20}
            />
        </ChartContainer>
    )
}

export default withFeatures(withNavigation(MiniVisitorsChart))
