import * as React from 'react'
import styled from 'styled-typed'
import Money from 'uiComponents/money'
import Infotip from 'uiComponents/infotip'
import Svg from 'svg'
import { ChartHeadline } from 'uiComponents/charts/styleComponents'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { Body, Headline } from 'uiComponents/typography'

export const DataNumberGroupTitle = styled.div`
    opacity: 0.5;
`

const InfotipContainer = styled.div`
    position: absolute;
    z-index: 1;
    left: -1.4em;
`
const Title = styled.div`
    color: ${(props) => props.theme.colors.textLight};
    font-size: 0.875em;
    position: relative;
    margin-top: 0.2em;
    span {
        text-transform: uppercase;
    }
`
const Value = styled.div`
    font-size: 1.625em;
    font-weight: bold;
`
const ContainerBody = styled.div`
    height: 2.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin-right: 1.5em;
    margin-top: 0.5em;
`

interface LegacyDataNumberProps {
    title: string
    value: number | string
    percent?: boolean
    withCurrency?: boolean
    accountSlug?: string
    id?: string
    infotipContent?: string | React.ReactNode
}

export function LegacyDataNumber(props: LegacyDataNumberProps & React.HTMLAttributes<HTMLElement>) {
    return (
        <>
            <ContainerBody>
                <Value id={props.id}>
                    {props.withCurrency && props.accountSlug ? (
                        <Money amount={props.value} accountSlug={props.accountSlug} />
                    ) : (
                        <>{props.value}</>
                    )}
                    {props.percent && '%'}
                </Value>
            </ContainerBody>
            <Title>
                {props.infotipContent && (
                    <InfotipContainer>
                        <Infotip pointer="left" maxWidth="19em" minWidth="19em">
                            {props.infotipContent}
                        </Infotip>
                    </InfotipContainer>
                )}
                <span>{props.title}</span>
            </Title>
        </>
    )
}

const ComparisonBody = styled.div`
    flex: 1;
    height: auto;
    margin-right: 4em;

    &:last-child {
        margin-right: 0;
    }
`
const ComparisonTitle = styled.div`
    background: linear-gradient(90deg, #f6f7fa 0%, rgba(246, 247, 250, 0.25) 108.01%);
    color: ${(props) => props.theme.colors.textLight};
    font-size: 0.875em;
    text-transform: uppercase;
    padding: 0.28em 0.78em;
    border-radius: 1.85em;
`
const ComparisonValue = styled.div`
    font-size: 2em;
    font-weight: 300;
    padding: 0.3em 0.3em 0.2em 0.3em;

    span {
        font-size: 0.5em;
        margin-left: 0.6em;
        color: ${(props) => props.theme.colors.textLight};
    }
`
export const ComparrisonText = styled.div`
    display: flex;
    font-size: 0.875em;
    span {
        &.green {
            color: ${(props) => props.theme.colors.status.success};
        }
        &.red {
            color: ${(props) => props.theme.colors.status.error};
        }
        &.yellow {
            color: ${(props) => props.theme.colors.status.warn};
        }
        &.gray {
            color: ${(props) => props.theme.colors.textLight};
        }
    }
`

interface ComparisonDataNumberProps {
    title: string
    value: number | string
    errorMargin?: number | string
    comparison: React.ReactNode
    id?: string
}

export function ComparisonDataNumber(props: ComparisonDataNumberProps & React.HTMLAttributes<HTMLElement>) {
    return (
        <ComparisonBody>
            <ComparisonTitle>{props.title}</ComparisonTitle>
            <ComparisonValue id={props.id}>
                {props.value}
                {props.errorMargin && <span>+/-{props.errorMargin}</span>}
            </ComparisonValue>
            <ComparrisonText style={{ marginLeft: '0.6em' }}>
                {props.comparison || (
                    <>
                        <span className="gray">Unknown</span>
                    </>
                )}
            </ComparrisonText>
        </ComparisonBody>
    )
}

interface ContainerWithIconStyleProps {
    compact?: boolean
    id?: string
    nthChildStyle?: string
}

const ContainerWithIcon = styled.div<ContainerWithIconStyleProps>`
    display: flex;
    flex: 1;
    align-items: flex-start;
    position: relative;
    cursor: ${(props) => (!!props.onClick ? 'pointer' : 'auto')};

    &:not(:first-child):before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: ${(props) => (props.compact ? '-1em' : '-2.2em')};
        border-left: 1px solid ${(props) => props.theme.colors.border};
    }

    &:last-child {
        margin-right: ${(props) => (props.compact ? '' : '-5em')};
    }

    &:nth-child(${(props) => props.id}) {
        ${(props) => props.nthChildStyle};
    }
`

interface DataNumberProps {
    icon?: SVGAElement
    title: string
    value: number | string
    comparisonItem: number | string
    comparisonItemColor: string
    tooltipType: 'WoW' | 'MoM' | 'prev. period'
    accountSlug?: string
    withCurrency?: boolean
    infotipPosition?: 'left' | 'right'
    infotipText?: string | React.ReactNode
    metricInfotipText?: string | React.ReactNode
    id?: string
    nthChildStyle?: string
    compact?: boolean
    navigationLink?: string
}

export function DataNumber(props: DataNumberProps & React.HTMLAttributes<HTMLElement>) {
    const explanation =
        props.tooltipType === 'MoM'
            ? 'Month over Month (or MoM) means comparing data from one month to the previous month.'
            : 'Week over Week (or WoW) means comparing data from one week to the previous week.'
    return (
        <ContainerWithIcon compact={props.compact} id={props.id} nthChildStyle={props.nthChildStyle}>
            {props.icon && <Svg src={props.icon} style={{ width: '4.25em' }} />}
            <ComparisonBody style={{ marginLeft: props.compact ? '' : '1em' }}>
                <ChartHeadline size={6} style={{ marginBottom: '0.25rem' }}>
                    <Body size={1}>
                        {props.navigationLink ? (
                            <UnstyledLink to={props.navigationLink}>{props.title}</UnstyledLink>
                        ) : (
                            props.title
                        )}
                    </Body>
                    {props.metricInfotipText && (
                        <Infotip pointer={props.infotipPosition ? props.infotipPosition : 'left'} maxWidth="33em">
                            {props.metricInfotipText}
                        </Infotip>
                    )}
                </ChartHeadline>
                <div id={props.id}>
                    <Headline size={3} bold style={{ marginBottom: '0' }}>
                        {props.withCurrency && props.accountSlug ? (
                            <Money amount={props.value} accountSlug={props.accountSlug} />
                        ) : (
                            <>{props.value}</>
                        )}
                    </Headline>
                </div>
                <ComparrisonText>
                    <span className={props.comparisonItemColor}>
                        <Body size={2}>{props.comparisonItem}</Body>
                    </span>
                    <Infotip pointer={props.infotipPosition ? props.infotipPosition : 'left'} maxWidth="23em">
                        {props.infotipText}
                        {(props.tooltipType === 'WoW' || props.tooltipType === 'MoM') && <div>{explanation}</div>}
                    </Infotip>
                </ComparrisonText>
            </ComparisonBody>
        </ContainerWithIcon>
    )
}
