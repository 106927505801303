import { Store } from 'redux'
import { Session } from 'http/session'
import { FeatureService } from 'features'
import { ComponentsService } from 'engageTools/studio/componentsService'
import { ImpersonationService } from 'http/impersonationService'
import { AuthenticatedHttpService } from 'http/httpService'
import { LoggingService } from 'http/loggingService'
import { ReduxBackedSession } from 'auth/reduxBackedSession'
import { UserService } from 'auth/userService'
import { OAuthService } from 'http/oauthService'
import { history } from './history'
import * as config from 'config'
import { getBrowserInfo } from 'utils/browserInfo'
import { createPersistedStore, State } from '../store'

const browserInfo = getBrowserInfo()
const browserInfoEncoded = btoa(encodeURIComponent(JSON.stringify(browserInfo)))
const clientId = config.getRequired('oauth-client-id')
const clientSecret = config.getRequired('oauth-client-secret')
const backofficeEndpoint = config.getRequired('backoffice-endpoint')
const experimentsEndpoint = config.getRequired('experiments-endpoint')
const backendEndpoint = config.getRequired('dashboard-api-endpoint')

export let session: Session
const oauthSettings = {
    tokenEndpoint: config.getRequired('oauth-endpoint'),
    clientId,
    clientSecret,
    extraHeaders: {
        'convious-browser-info': browserInfoEncoded,
    },
}
export const oauthService = new OAuthService(oauthSettings)
export const httpService = new AuthenticatedHttpService(
    () => session,
    oauthService,
    () => store.getState(),
)
export const featureService = new FeatureService(httpService, experimentsEndpoint)
export const componentsService = new ComponentsService(httpService, () => loggingService, backofficeEndpoint)
export const impersonationService = new ImpersonationService(
    () => session,
    () => loggingService,
    httpService,
    oauthSettings,
    history,
)

export const store = createPersistedStore({
    featureService,
    componentsService,
    impersonationService,
    history,
})

session = new ReduxBackedSession(store, () => userService.getLoggedInUser())
export const userService = new UserService(httpService, backendEndpoint)
export const loggingService = new LoggingService(httpService, backendEndpoint, store)

function monitorLogout(reduxStore: Store<State>) {
    let prevTicket = reduxStore.getState().auth.ticket
    reduxStore.subscribe(() => {
        const newTicket = reduxStore.getState().auth.ticket
        if (prevTicket !== newTicket && newTicket === null && location.pathname !== '/login/') {
            if (location.pathname.indexOf('/new_account/add_snippet') > 0) {
                history.push('/login')
            } else {
                history.push('/login/?next=' + encodeURIComponent(location.pathname + location.search))
            }
        }
        prevTicket = newTicket
    })
}

monitorLogout(store)
