import * as React from 'react'
import styled from 'styled-typed'
import { FormItem, FormItemName } from 'uiComponents/form/formElements'
import { PageLoader } from 'uiComponents/loaders'
import { TextInput } from 'uiComponents/input'
import { ButtonWrapper, Column, ColumnGap, Row, TextWithHorizontalLine } from 'uiComponents/pageElements'
import { Tag } from 'uiComponents/tag'
import { ActionButton } from 'uiComponents/buttons'
import { MessageKind } from 'uiComponents/messages'
import { ValidationNotice } from 'products/components/validationNotice'
import { ArticleService as AdminArticleService } from 'admin/articleService'
import { SingleSelect, SingleSelectOption } from 'uiComponents/input/singleSelect'
import { createGetCategoryAncestors, useGetAllArticles, useGetAllCategories } from '../../redux'
import { ArticleFromApi, CategoryFromApi } from '../../types'
import { getProductName } from '../../productTable/utils'
import { ExceptionProductList } from './exceptionProductList'

const TagHolder = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1.5em;
`

interface ExceptionInfoFormSectionProps {
    exceptionProducts?: ArticleFromApi[]
    exception: boolean
    prototype?: boolean
    exceptionName: string
    onDuplicateException: () => void
    updateProducts: (products: ArticleFromApi[]) => void
    updateExceptionName: (name: string) => void
    validate: boolean
    articleService: AdminArticleService
    accountSlug: string
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    productList: CategoryFromApi[]
    articles: ArticleFromApi[]
    getCategoryAncestors: (id: string) => CategoryFromApi[]
}

interface ExceptionInfoFormSectionState {
    exceptionProducts: ArticleFromApi[]
    selectedProductList?: string
    exceptionName: string
    nameValid: boolean
    productsValid: boolean
    loading: boolean
}

class ExceptionInfoFormSectionComponent extends React.Component<
    ExceptionInfoFormSectionProps,
    ExceptionInfoFormSectionState
> {
    constructor(props: ExceptionInfoFormSectionProps) {
        super(props)
        this.state = {
            exceptionProducts: this.props.exceptionProducts ? this.props.exceptionProducts : [],
            exceptionName: this.props.exceptionName ? this.props.exceptionName : '',
            nameValid: true,
            productsValid: true,
            loading: false,
        }
    }

    componentDidUpdate(prevProps: ExceptionInfoFormSectionProps) {
        if (!prevProps.validate && this.props.validate) {
            this.checkNameValidity(this.state.exceptionName)
            this.checkProductsValidity(this.state.exceptionProducts)
        }

        if (prevProps.exceptionProducts !== this.props.exceptionProducts) {
            this.setState({ exceptionProducts: this.props.exceptionProducts ?? [] })
        }
    }

    getAvailableProducts = () => {
        const exceptionProductsIds = this.state.exceptionProducts.map((ep) => ep.id)
        const productListId = this.state.selectedProductList
        const availableProducts = this.props.articles.filter((ap) => {
            return (
                !exceptionProductsIds.includes(ap.id) &&
                !!ap.categories?.find((category) => category.id === productListId)
            )
        })
        const options = availableProducts.map((ap) => {
            return { value: ap.id, name: getProductName(ap.name) as string }
        })
        options.push({ value: 'all', name: 'Select all' })
        return options
    }

    getAvailableProductLists = (): SingleSelectOption[] => {
        const exceptionProductsIds = this.state.exceptionProducts.map((ep) => ep.id)
        const getCategoryAncestors = createGetCategoryAncestors(this.props.productList)
        const availableAccountProducts = this.props.articles
            ?.filter((ap) => {
                return !exceptionProductsIds.includes(ap.id)
            })
            ?.reduce((acc, article) => {
                article.categories?.forEach((category) => {
                    acc.push(category.id)
                })

                return acc
            }, [] as string[])

        const filtered = this.props.productList
            .filter((pl) => availableAccountProducts?.includes(pl.id))
            .reduce((acc, category) => {
                // acc.push(category)
                getCategoryAncestors(category.id)?.forEach((parentCategory) => acc.push(parentCategory))

                return acc
            }, [] as CategoryFromApi[])

        const options = filtered.map((f) => {
            return {
                value: f.id,
                name: getProductName(f.name) as string,
                parentId: f.parentCategory?.id,
            } as SingleSelectOption
        })
        options.push({ value: 'all', name: 'Select all' })

        return options
    }

    handleProductListSelectChange = async (id: string) => {
        if (id === 'all') {
            this.setState({ exceptionProducts: this.props.articles })
            this.props.updateProducts(this.props.articles)
            this.checkProductsValidity(this.props.articles)
        } else {
            this.setState({ selectedProductList: id })
        }
    }

    checkProductsValidity = (products: ArticleFromApi[]) => {
        if (products.length === 0) {
            this.setState({ productsValid: false })
        } else {
            this.setState({ productsValid: true })
        }
    }

    handleProductSelectChange = (id: string) => {
        const products = [...this.state.exceptionProducts]

        if (id === 'all') {
            ;[...this.props.articles]
                .filter((article) => {
                    const categoryIds = article.categories.map((category) => category.id)

                    return this.state.selectedProductList && categoryIds.includes(this.state.selectedProductList)
                })
                .forEach((ap) => {
                    if (!products.find((p) => p.id === ap.id)) {
                        products.push(ap)
                    }
                })
            this.setState({ exceptionProducts: products, selectedProductList: undefined })
        } else {
            const selectedProduct = this.props.articles.find((p) => p.id === id)
            if (selectedProduct) {
                products.push(selectedProduct)
                this.setState({ exceptionProducts: products })
            }
        }
        this.checkProductsValidity(products)
        this.props.updateProducts(products)
    }

    removeProduct = (productId: string) => {
        const newProdArray = this.state.exceptionProducts.filter((p) => p.id !== productId)
        this.setState({ exceptionProducts: newProdArray })
        this.checkProductsValidity(newProdArray)
        this.props.updateProducts(newProdArray)
    }

    updateExceptionName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value
        this.setState({ exceptionName: name })
    }

    checkNameValidity = (n: string) => {
        if (!n || n.length < 1) {
            this.setState({ nameValid: false })
        } else {
            this.setState({ nameValid: true })
        }
    }

    onFinishTypingName = (n: string) => {
        this.checkNameValidity(n)
        this.props.updateExceptionName(n)
    }

    groupProducts = (products: ArticleFromApi[]): { name: string; products: ArticleFromApi[] }[] => {
        const groups = new Array(this.props.productList.length)

        for (let i = 0; i < groups.length; i++) {
            const productList = this.props.productList[i]

            const productsInGroup = products.filter((p) => {
                if (p.categories?.length === 1) {
                    return !!p.categories.find((category) => category.id === productList.id)
                }
                return false
            })

            const ancestors = this.props.getCategoryAncestors(productList.id)

            groups[i] = {
                name: ancestors.map((ancestor) => getProductName(ancestor.name)).join('-> '),
                products: productsInGroup,
            }
        }
        if (this.props.productList.length > 0) {
            groups.push({
                name: 'Multiple product lists',
                products: products.filter((p) => p.categories?.length > 1),
            })
        }
        return groups
    }

    render() {
        const nameNotValid = !this.state.nameValid && this.props.validate
        const productGroups = this.groupProducts(this.state.exceptionProducts)
        return (
            <>
                {this.state.loading && <PageLoader style={{ paddingBottom: '4em' }} />}
                {!this.state.loading && (
                    <>
                        <Row>
                            <Column>
                                <FormItem htmlFor="exceptionName">
                                    <FormItemName style={{ display: 'flex' }}>Exception name</FormItemName>
                                    <TextInput
                                        id="exceptionName"
                                        name="exceptionName"
                                        placeholder="Type the name..."
                                        value={this.state.exceptionName}
                                        onChange={this.updateExceptionName}
                                        onFinishTyping={this.onFinishTypingName}
                                        status={nameNotValid ? 'error' : 'normal'}
                                        block={true}
                                    />
                                </FormItem>
                                <ValidationNotice className={nameNotValid ? 'validation-message-visible' : ''}>
                                    Exception name should be at least 10 characters long
                                </ValidationNotice>
                            </Column>
                            <ColumnGap />
                            <Column>
                                <ButtonWrapper>
                                    {this.props.exception && !this.props.prototype && (
                                        <ActionButton
                                            onClick={this.props.onDuplicateException}
                                            kind="action"
                                            size="large"
                                            secondary
                                        >
                                            Duplicate
                                        </ActionButton>
                                    )}
                                </ButtonWrapper>
                            </Column>
                        </Row>
                        <Row>
                            {this.props.productList.length > 0 && (
                                <>
                                    <ExceptionProductList
                                        selectedProductList={this.state.selectedProductList || null}
                                        selectedArticles={this.state.exceptionProducts}
                                        productsValid={this.state.productsValid}
                                        handleProductListSelectChange={this.handleProductListSelectChange}
                                    />
                                    <ColumnGap />
                                </>
                            )}
                            <Column>
                                <FormItem htmlFor="products">
                                    <FormItemName style={{ display: 'flex' }}>Product(s)</FormItemName>
                                    <SingleSelect
                                        id="exceptionProducts"
                                        noSelectOption="Select products"
                                        options={this.getAvailableProducts()}
                                        selected={null}
                                        height="2.8em"
                                        status={this.state.productsValid ? 'normal' : 'error'}
                                        onSelect={this.handleProductSelectChange}
                                    />
                                </FormItem>
                                <ValidationNotice
                                    className={!this.state.productsValid ? 'validation-message-visible' : ''}
                                >
                                    Select at least one product
                                </ValidationNotice>
                            </Column>
                            {this.props.articles.length === 0 && (
                                <>
                                    <ColumnGap />
                                    <Column />
                                </>
                            )}
                        </Row>
                        <Row>
                            {productGroups.length === 0 ? (
                                <TagHolder>
                                    {this.state.exceptionProducts.map((p) => {
                                        return (
                                            <Tag
                                                onClick={() => {
                                                    this.removeProduct(p.id)
                                                }}
                                                key={p.id}
                                                style={{ margin: '.25em .5em' }}
                                            >
                                                {p.name}
                                            </Tag>
                                        )
                                    })}
                                </TagHolder>
                            ) : (
                                <Column>
                                    {productGroups.map((pg, i) => {
                                        return (
                                            <div key={i}>
                                                {pg.products.length > 0 ? (
                                                    <>
                                                        <TextWithHorizontalLine
                                                            text={pg.name}
                                                            alignment="left"
                                                            style={{ margin: '1em 0', color: 'black' }}
                                                        />
                                                        <TagHolder>
                                                            {pg.products.map((p) => {
                                                                return (
                                                                    <Tag
                                                                        onClick={() => {
                                                                            this.removeProduct(p.id)
                                                                        }}
                                                                        key={p.id}
                                                                        style={{ margin: '.25em .5em' }}
                                                                    >
                                                                        {getProductName(p.name)}
                                                                    </Tag>
                                                                )
                                                            })}
                                                        </TagHolder>
                                                    </>
                                                ) : null}
                                            </div>
                                        )
                                    })}
                                </Column>
                            )}
                        </Row>
                    </>
                )}
            </>
        )
    }
}

export const ExceptionInfoFormSection = (
    props: Omit<ExceptionInfoFormSectionProps, 'articles' | 'productList' | 'getCategoryAncestors'>,
) => {
    const productList = useGetAllCategories()
    const articles = useGetAllArticles()
    const getCategoryAncestors = createGetCategoryAncestors(productList)

    return (
        <ExceptionInfoFormSectionComponent
            productList={productList}
            articles={articles}
            getCategoryAncestors={getCategoryAncestors}
            {...props}
        />
    )
}
