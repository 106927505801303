import { useEffect, useRef } from 'react'

const useOutsideClick = (callback: any) => {
    const ref = useRef<HTMLButtonElement | null>(null)

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback()
            }
        }

        document.addEventListener('click', handleClick, false)

        return () => {
            document.removeEventListener('click', handleClick, false)
        }
    }, [ref])

    return ref
}

export default useOutsideClick
