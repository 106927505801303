import * as React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { BaseRouteParams } from '../../hocs'
import { LoaderWrapper } from '../../uiComponents/loaders'
import Header from '../components/header'
import { usePushNotificationsContext } from '../context/PushNotifications'
import TargetForm from '../forms/target'
import usePNMap from '../hooks/usePNMap'
import { useGetCampaignQuery } from '../reduxQuery'
import { TargetPageHeader, TemplateContainer } from './target'

export interface PNEditRouteParams extends BaseRouteParams {
    campaign_id: string
    location_id: string
}

const EditPage = () => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const { campaign_id } = useParams<PNEditRouteParams>()
    const { targetForm, setTargetForm } = usePushNotificationsContext()

    const { data, isFetching } = useGetCampaignQuery({
        slug: accountSlug,
        campaignId: campaign_id,
    })
    const { mapCampaignToForm } = usePNMap()

    useEffect(() => {
        if (data) {
            mapCampaignToForm(data)
        }
    }, [data])

    return (
        <div>
            <Header />
            <TemplateContainer>
                <TargetPageHeader />
                <LoaderWrapper loading={isFetching}>
                    <TargetForm
                        onSubmit={(data) => {
                            setTargetForm(data)
                        }}
                        data={targetForm}
                    />
                </LoaderWrapper>
            </TemplateContainer>
        </div>
    )
}

export default EditPage
