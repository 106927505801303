import * as React from 'react'
import { PageLoader } from 'uiComponents/loaders'
import { FieldType } from 'uiComponents/studio/form/fieldComponentSchema'
import { ConfigurationUnit } from 'uiComponents/studio/menuItem'
import { BlacklistUrlSection } from 'engageTools/studio/form/blacklistUrlSection'
import { GeneralSection } from './generalSection'
import {
    WonderbarConfiguration,
    TargetType,
    Languages,
    Translation,
    UrlRules,
    OnChangeFunc,
} from 'engageTools/studio/schema'
import LanguagesSection from './languagesSection'
import { getConfigValue, handleInputChange } from 'engageTools/studio/shared'
import AudienceMarketing from '../form/fields/audienceMarketing'

interface WondebarFormProps {
    accountSlug: string
    section: ConfigurationUnit
    wonderbarConfiguration: WonderbarConfiguration | null
    languages: Languages | null
    translations: Translation[]
    urlRules: UrlRules | null
    updateUrlRules: (list: string, logic: string, ruleType: string, value: string) => void
    updateConfiguration: (path: string, value: string | number | boolean | string[]) => void
    updateTranslations: (slug: string, language: string, value: string) => void
    onLanguageChange: (selected: string[], primary: string) => void
    resetATranslation: (slug: string) => void
    deviceType: TargetType
    translationsValidation: boolean
    setTranslationsValidation: (on: boolean) => void
    setActiveLanguage: (v: string) => void
    activeLanguage: string
}

export function WondebarForm(props: WondebarFormProps) {
    const onInputChange: OnChangeFunc = (
        value: React.ChangeEvent<HTMLInputElement> | string | boolean,
        path: string,
    ) => {
        handleInputChange(value, path, setConfigValue)
    }

    const setConfigValue = (path: string, value: boolean | string) => {
        if (path) {
            props.updateConfiguration(path, value)
        }
    }

    if (!props.wonderbarConfiguration) {
        return <PageLoader />
    } else {
        const { section } = props
        if (section.name === 'Languages' && props.languages) {
            return (
                <LanguagesSection
                    accountSlug={props.accountSlug}
                    section={section}
                    getConfigValue={(item: FieldType) => getConfigValue(item, props.wonderbarConfiguration)}
                    handleInputChange={onInputChange}
                    languages={props.languages}
                    translations={props.translations}
                    configuration={props.wonderbarConfiguration}
                    updateLanguages={props.onLanguageChange}
                    updateConfiguration={props.updateConfiguration}
                    updateTranslations={props.updateTranslations}
                    resetATranslation={props.resetATranslation}
                    translationsValidation={props.translationsValidation}
                    setTranslationsValidation={props.setTranslationsValidation}
                    activeLanguage={props.activeLanguage}
                    setActiveLanguage={props.setActiveLanguage}
                />
            )
        } else if (section.name === 'Blacklist') {
            return (
                <BlacklistUrlSection
                    product="wonderbar"
                    section={section}
                    urlRules={props.urlRules}
                    updateUrlRules={props.updateUrlRules}
                />
            )
        } else if (section.name === 'Audience') {
            return <AudienceMarketing />
        } else {
            return (
                <GeneralSection
                    section={section}
                    configuration={props.wonderbarConfiguration}
                    getConfigValue={(item: FieldType) => getConfigValue(item, props.wonderbarConfiguration)}
                    handleInputChange={onInputChange}
                    deviceType={props.deviceType}
                />
            )
        }
    }
}
