export type OperatorTypes = 'and' | 'or' | 'not'

export type IValue = string | number | null

export interface IDate {
    start: string
    end: string
}

export const DATA_TYPES = ['preset', 'custom']
export type DataType = typeof DATA_TYPES[number]

export interface IOption {
    type?: DataType
    name?: string
    caption?: string
    description?: string
    operator?: string
    children?: IOption[]
    is_product?: boolean
    is_last?: boolean
    paths_id?: string[]
    value: IDate | string | number | null
}

export interface IRule {
    rule_id: number
    name: string
    options: IOption[]
    value: any | null
}

export interface IFilter {
    filter_id: number
    name: string
    filter_order: number
    description: string
    operator: OperatorTypes
    rules: IRule[]
}

export enum ICustomAudienceStatus {
    ACTIVE = 'active',
    DRAFT = 'draft',
}

export interface ICustomAudience {
    id?: number
    name: string
    description: string
    account_slug: string
    location_id?: string
    status: ICustomAudienceStatus
    filters: IFilter[]
    created_at?: string
    updated_at?: string
}
