import React from 'react'
import styled from 'styled-typed'
import { History } from 'history'
import { ProductList, ArticleListItem } from 'admin/articleService'
import { NestedElement } from 'products/components/nestedContainer'
import { Row, Col } from 'uiComponents/flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faTag, faCalendarAlt, faPencilAlt, faCopy } from '@fortawesome/pro-light-svg-icons'
import { Feature } from 'features'
import { transparency } from 'utils/css'
import Permission from 'admin/permissionRequired'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { withFeatures } from 'features'

export const IconBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 2.5em;
    height: 1.575em;
    border-right: 1px solid ${(p) => p.theme.colors.border};
    cursor: pointer;
`

export const IconWrapper = styled.div`
    border-radius: 50%;
    min-width: 2em;
    height: 2em;
    background-color: ${(p) => transparency(p.theme.colors.border, 0.25)};
    display: flex;
    align-items: center;
    justify-content: center;
    &.hidden {
        background-color: ${(p) => transparency(p.theme.colors.border, 0)};
    }
`

export const IconRow = styled(Row)`
    width: 100%;
    ${Col} {
        padding: 0;
    }
    ${Col}:last-child {
        ${IconBox} {
            border-right: none;
        }
    }
    justify-content: flex-end;
`

export const MenuIcon = styled(FontAwesomeIcon)`
    font-size: 1em;
`

const TagIcon = styled(FontAwesomeIcon)`
    font-size: 1em;
    transform: rotate(90deg);
`

interface ActionMenuProps {
    history: History
    accountSlug: string
    isArticle: boolean
    hasPermission: (permission: string, accountSlug: string) => boolean
    deleteArticle?: (id: string) => void
    deleteProductList?: (id: string) => void
    duplicateProduct?: (id: string, name: string) => void
    element: NestedElement
    hasFeature: (feature: string, accountSlug: string) => boolean
    origin?: '?from=flat' | '?from=nested' | ''
}

function ActionMenu(props: ActionMenuProps & React.HTMLAttributes<HTMLDivElement>) {
    const { element, origin, deleteArticle, deleteProductList, duplicateProduct } = props
    const elementAsArticle = element as ArticleListItem
    const elementAsProductList = element as ProductList

    return (
        <>
            {props.isArticle ? (
                <IconRow id="productActionsMenuWrapper">
                    <Permission name="partner_admin" accountSlug={props.accountSlug}>
                        <Col span={2.4}>
                            <IconBox title="Product details">
                                <UnstyledLink
                                    to={`/account/${props.accountSlug}/products/crud/${elementAsArticle.id}${origin}`}
                                    id="edit-icon"
                                >
                                    <IconWrapper>
                                        <MenuIcon icon={faPencilAlt} />
                                    </IconWrapper>
                                </UnstyledLink>
                            </IconBox>
                        </Col>
                    </Permission>
                    <Permission name="partner_admin" accountSlug={props.accountSlug}>
                        <Col span={2.4}>
                            <IconBox
                                title="Duplicate article"
                                onClick={() =>
                                    duplicateProduct ? duplicateProduct(elementAsArticle.id, elementAsArticle.name) : {}
                                }
                            >
                                <IconWrapper>
                                    <MenuIcon icon={faCopy} />
                                </IconWrapper>
                            </IconBox>
                        </Col>
                    </Permission>
                    <Permission name="view_pricing_settings" accountSlug={props.accountSlug}>
                        <Feature name="Pricing" accountSlug={props.accountSlug}>
                            <Col span={2.4}>
                                <IconBox title="Pricing settings">
                                    <UnstyledLink
                                        to={`/account/${props.accountSlug}/products/pricing/${elementAsArticle.id}/detail${origin}`}
                                    >
                                        <IconWrapper>
                                            <TagIcon icon={faTag} />
                                        </IconWrapper>
                                    </UnstyledLink>
                                </IconBox>
                            </Col>
                        </Feature>
                    </Permission>
                    <Permission name="view_product_validity" accountSlug={props.accountSlug}>
                        <Col span={2.4}>
                            <IconBox className="edit-validity" title="Validity settings">
                                <UnstyledLink
                                    to={`/account/${props.accountSlug}/products/validity/${elementAsArticle.id}/detail${origin}`}
                                >
                                    <IconWrapper>
                                        <MenuIcon icon={faCalendarAlt} />
                                    </IconWrapper>
                                </UnstyledLink>
                            </IconBox>
                        </Col>
                    </Permission>
                    <Permission name="partner_admin" accountSlug={props.accountSlug}>
                        <Col span={2.4}>
                            <IconBox
                                title="Remove article"
                                onClick={() => (deleteArticle ? deleteArticle(elementAsArticle.id) : {})}
                            >
                                <IconWrapper>
                                    <MenuIcon icon={faTrash} />
                                </IconWrapper>
                            </IconBox>
                        </Col>
                    </Permission>
                </IconRow>
            ) : (
                <IconRow>
                    <Col span={2.4} />
                    <Col span={2.4} />
                    <Col span={2.4} />
                    <Col span={2.4}>
                        <IconBox title="Category details">
                            <UnstyledLink
                                to={`/account/${props.accountSlug}/products/crud/productList/${elementAsProductList.uuid}${origin}`}
                            >
                                <IconWrapper>
                                    <MenuIcon icon={faPencilAlt} />
                                </IconWrapper>
                            </UnstyledLink>
                        </IconBox>
                    </Col>
                    <Col span={2.4}>
                        <IconBox
                            title="Remove category"
                            onClick={() => (deleteProductList ? deleteProductList(elementAsProductList.uuid) : {})}
                        >
                            <IconWrapper>
                                <MenuIcon icon={faTrash} />
                            </IconWrapper>
                        </IconBox>
                    </Col>
                </IconRow>
            )}
        </>
    )
}

export default withFeatures(ActionMenu)
