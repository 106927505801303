import React from 'react'
import { useSelect, useMultipleSelection } from 'downshift'
import styled from 'styled-typed'
import TextInputField from '../textInput/textInputField'
import { Checkbox } from '../checkbox'
import { SingleSelectOption } from '../singleSelect'
import ToggleSelectIcon from '../_shared/toggleSelectIcon'
import VirtualizedTree from 'uiComponents/tree/virtualizedTree'
import classNames from 'classnames'

export const PopupArea = styled.div`
    position: absolute;
    top: 2.8em;
    left: 0;
    right: 0;
    background: ${(props) => props.theme.colors.white};
    border-radius: 0.375em;
    border-top: none;
    overflow: hidden;
    z-index: 3;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-in, visibility 0s 0.2s;

    &.visible {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.2s ease-in, visibility 0s 0s;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    }
`

const RowStyles = styled.label<{ depth: number; disabled: boolean }>`
    display: flex;
    padding: 3px 0 3px ${({ depth }) => getDepth(depth)}px;
    font-size: 12px;
`

const getDepth = (depth: number) => {
    if (!!depth || depth - 1 > 0) {
        return (depth - 1) * 30
    }

    return 0
}

const getValueText = (selectedItems?: SingleSelectOption[]) => {
    if (!selectedItems?.length) {
        return ''
    }

    if (selectedItems?.length === 1) {
        return selectedItems[0].name
    }

    if (selectedItems?.length > 1) {
        return `${selectedItems.length} items selected`
    }

    return ''
}

function getAllChildren(data: SingleSelectOption[], id: string) {
    const result = [] as SingleSelectOption[]

    // Find the object with the specified ID
    const parent = data?.find((item) => item.value === id)

    if (!parent) {
        return result
    }

    // Filter the data for matching parent ID
    const children = data.filter((item) => item.parentId === parent.value)

    // Recursively get children and subchildren
    for (const child of children) {
        const { value } = child
        const subchildren = getAllChildren(data, value)
        result.push(child, ...subchildren)
    }

    return result
}

export interface Props {
    options: SingleSelectOption[]
    label?: string | React.ReactNode
    value?: string[]
    onChange?: (value: string[]) => void
    id?: string
    name?: string
    placeholder?: string
    status?: 'normal' | 'error'
    error?: string
    disabled?: boolean
}

const Combobox: React.FC<Props> = ({
    options = [],
    label,
    value,
    onChange,
    name,
    id,
    placeholder,
    status,
    error,
    disabled,
}) => {
    const { getDropdownProps, setSelectedItems, selectedItems } = useMultipleSelection<SingleSelectOption>({
        selectedItems: options.filter((option) => value?.includes(option.value)) || [],
        onStateChange: ({ selectedItems: newSelectedItems }) => {
            if (onChange && newSelectedItems) {
                onChange(newSelectedItems.map((item) => item.value))
            }
        },
    })

    const { isOpen, getMenuProps, getItemProps, getToggleButtonProps } = useSelect({
        selectedItem: null,
        items: options,
        stateReducer: (state, actionAndChanges) => {
            const { changes, type } = actionAndChanges
            switch (type) {
                case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
                case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
                case useSelect.stateChangeTypes.ItemClick:
                    return {
                        ...changes,
                        isOpen: true,
                    }
                default:
                    return changes
            }
        },
        onStateChange: ({ type, selectedItem: newSelectedItem }) => {
            switch (type) {
                case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
                case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
                case useSelect.stateChangeTypes.ItemClick:
                case useSelect.stateChangeTypes.ToggleButtonBlur:
                    if (newSelectedItem) {
                        const relatives = getAllChildren(options, newSelectedItem.value)
                        if (selectedItems.includes(newSelectedItem)) {
                            const relativeIds = relatives.map((item) => item.value)
                            const filteredItems = selectedItems.filter((item) => {
                                return item.value !== newSelectedItem.value && !relativeIds.includes(item.value)
                            })

                            setSelectedItems(filteredItems)
                        } else {
                            setSelectedItems([...selectedItems, newSelectedItem, ...relatives])
                        }
                    }
                    break
                default:
                    break
            }
        },
    })

    const getInputProps = () => {
        const { ref, ...inputProps } = getToggleButtonProps(
            getDropdownProps({ preventKeyAction: isOpen, disabled, id, name }),
        )

        return {
            ...inputProps,
            inputRef: ref,
        }
    }

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ width: '100%' }}>
                <TextInputField
                    label={label}
                    style={{ width: '100%' }}
                    readOnly
                    name={name}
                    id={id}
                    value={getValueText(selectedItems)}
                    placeholder={placeholder || 'Select'}
                    postfix={<ToggleSelectIcon open={isOpen} />}
                    status={status}
                    error={error}
                    disabled={disabled}
                    {...getInputProps()}
                />
            </div>
            <PopupArea
                className={isOpen ? 'visible' : ''}
                style={{ height: 300, padding: '8px', overflow: 'auto', top: label ? 70 : 42 }}
                {...getMenuProps({ disabled })}
            >
                <VirtualizedTree
                    width={3000}
                    height={284}
                    rowHeight={21}
                    idField="value"
                    parentField="parentId"
                    data={options}
                    component={({ data, depth, style }) => {
                        const isSelected =
                            !!selectedItems?.find((item) => item.value === data?.original?.value) || false

                        return (
                            <>
                                <RowStyles
                                    depth={depth}
                                    className={classNames({
                                        'combobox-select-item': true,
                                        'selected-item': isSelected,
                                        'has-child': data?.childNodes?.length > 0,
                                        disabled: data?.disabled,
                                    })}
                                    style={style}
                                    key={`${data.value}${data.parentId}`}
                                    {...getItemProps({
                                        item: data.original,
                                        index: data.index,
                                        disabled: data?.disabled,
                                        id: data.original.value,
                                    })}
                                >
                                    <Checkbox
                                        disabled={data?.disabled}
                                        className={classNames({
                                            'combobox-select-item-checkbox': true,
                                            disabled: data?.disabled,
                                        })}
                                        checked={isSelected}
                                    />
                                    <span>{data.name}</span>
                                </RowStyles>
                            </>
                        )
                    }}
                />
            </PopupArea>
        </div>
    )
}

export default Combobox
