import * as React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { State } from 'store'

interface PageContentProps {
    children?: React.ReactNode | undefined
    welcomeSeen: boolean | null
    isConviousAdmin: boolean
    accountSlug: string
}
export const RequiresWelcomeSeen = (props: PageContentProps) => {
    const { welcomeSeen, accountSlug, children, isConviousAdmin } = props
    return (
        <Route>
            {!welcomeSeen && !isConviousAdmin ? (
                <Redirect to={`/account/${accountSlug}/welcome_popup`} />
            ) : (
                <Switch>{children}</Switch>
            )}
        </Route>
    )
}
const mapStateToProps = (state: State) => {
    return {
        welcomeSeen: state.profile.profile ? state.profile.profile.welcomeSeen : null,
        isConviousAdmin: state.auth.user ? state.auth.user.isAdmin : false,
        accountSlug: state.preferences.activeAccount,
    }
}
export default connect(mapStateToProps)(RequiresWelcomeSeen)
