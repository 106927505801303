import * as React from 'react'
import { FieldType } from 'uiComponents/studio/form/fieldComponentSchema'
import { usePromotion } from './context'
import { CheckboxWrapper } from 'tapPlacements/studio/components'
import { Checkbox } from 'uiComponents/input'

type Props = {
    field: FieldType
    initialValue: string | number
}

const ConsentField = (props: Props) => {
    const { setValueForPath, getValueForPath } = usePromotion()

    const toggle = () => {
        const consent = !!getValueForPath('marketingConsent') ? '' : '1'
        setValueForPath(consent, 'marketingConsent')
    }

    return (
        <CheckboxWrapper>
            <Checkbox
                checked={!!getValueForPath('marketingConsent')}
                onChange={toggle}
                style={{ cursor: 'pointer', marginRight: '0.5em' }}
            />
            <span onClick={toggle}>Marketing consent provided</span>
        </CheckboxWrapper>
    )
}

export default ConsentField
