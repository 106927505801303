import React from 'react'
import DataItem from '../../components/dataItem'
import './styles.scss'

interface ItemSectionProps {
    dataItems: { name: string; value: React.ReactNode }[]
}

const ItemSection = ({ dataItems }: ItemSectionProps) => {
    return (
        <div className="grid">
            {dataItems.map((item, index) => (
                <DataItem className="gridItem" key={item.name + index} name={item.name}>
                    {item.value}
                </DataItem>
            ))}
        </div>
    )
}

export default ItemSection
