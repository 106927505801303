import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionMenu } from './listActionMenu'
import { productURLSlugs } from 'engageTools/studio/shared'
import { ConfigurationVersions, ProductName } from 'engageTools/studio/componentsService'
import EngageToolStatus from './cells/status'
import { getStatus } from './cells/utils'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { ReactTable, ReactTableCellProps, TableColumn } from 'uiComponents/table/reactTable'
import { getDevice } from './common'
import { useParams } from 'react-router-dom'
import { BaseRouteParams } from 'hocs'
import { useDispatch } from 'react-redux'
import { addInlineMessage, addMessage } from 'uiComponents/messages/actions'
import { Toggle } from 'uiComponents/input'
import { usePublishCampaignMutation, useUnpublishCampaignMutation } from 'engageTools/reduxApi'
import styled from 'styled-components'
import uuid from 'uuid'
import { DateFormats, formatISOString } from 'utils/dates'
import { DateRange } from 'uiComponents/date'

interface TableComponentProps {
    tools: ConfigurationVersions[]
    setToEdit: (id: string) => void
    setModalOn: (on: boolean) => void
    type: ProductName
    onUnpublish?: (id: string) => void
    onPublish?: (id: string) => void
    onDelete: (id: string) => void
    canEditCampaigns: boolean
    updating: boolean
    loading: boolean
}

const Toggler = styled(Toggle)`
    font-size: 0.875em;
`

const TableComponent: React.FC<TableComponentProps> = ({
    tools,
    updating,
    loading,
    type,
    canEditCampaigns,
    onDelete,
    setModalOn,
    setToEdit,
    onPublish,
    onUnpublish,
}) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const dispatch = useDispatch()
    const [publish] = usePublishCampaignMutation()
    const [unpulish] = useUnpublishCampaignMutation()

    const columns = useMemo(() => {
        return [
            {
                accessor: 'deviceType',
                Header: 'Device(s)',
                align: 'center',
                width: '8em',
                Cell: ({ row }: ReactTableCellProps<ConfigurationVersions>) => {
                    const deviceIcon = getDevice(row.original.deviceType as any)
                    if (deviceIcon) {
                        return <FontAwesomeIcon icon={deviceIcon.icon} style={{ marginRight: '.3em' }} />
                    }

                    return null
                },
            },
            {
                accessor: 'name',
                Header: 'Name',
                width: '25em',
                Cell: ({ row }: ReactTableCellProps<ConfigurationVersions>) => {
                    return (
                        <div>
                            <div>{row.original.name}</div>
                            <EngageToolStatus tool={row.original} />
                        </div>
                    )
                },
            },
            {
                accessor: 'publish',
                disableSortBy: true,
                width: '6rem',
                Cell: ({ row }: ReactTableCellProps<ConfigurationVersions>) => {
                    const status = getStatus(row.original)

                    return (
                        <Toggler
                            isOn={status.publishState}
                            onClick={async () => {
                                if (status.publishState) {
                                    unpulish({
                                        accountSlug,
                                        type,
                                        id: row.original.id as string,
                                    })
                                } else {
                                    try {
                                        await publish({
                                            accountSlug,
                                            type,
                                            id: row.original.id as string,
                                            tool: row.original,
                                        }).then((response: any) => {
                                            if (!response.error) {
                                                dispatch(
                                                    addInlineMessage({
                                                        status: 'warn',
                                                        header: 'Duplicates found!',
                                                        tag: 'EngageTools',
                                                        text: 'duplicate',
                                                        data: row.original.id,
                                                    }),
                                                )
                                            } else {
                                                dispatch(
                                                    addMessage(
                                                        uuid.v4(),
                                                        'error',
                                                        response?.error?.message ||
                                                            response?.error?.error?.message ||
                                                            'Unable to publish',
                                                    ),
                                                )
                                            }
                                        })
                                    } catch (error) {}
                                }
                            }}
                        />
                    )
                },
            },
            {
                accessor: 'validDate',
                Header: 'Valid date',
                width: '10em',
                Cell: ({ row: { original } }: ReactTableCellProps<ConfigurationVersions>) => (
                    <DateRange
                        startDateString={original.validFrom}
                        endDateString={original.validTo}
                        fromFormat="yyyy-MM-dd"
                    />
                ),
            },
            {
                accessor: 'audiences',
                feature: 'CRMAudiencesPage',
                Header: 'Audience(s)',
                width: '10em',
                Cell: ({ row }: ReactTableCellProps<ConfigurationVersions>) => {
                    return row.original.audiences?.length || 0
                },
            },
            {
                accessor: 'updatedBy',
                Header: 'Updated by',
                width: '15em',
                Cell: ({ row }: ReactTableCellProps<ConfigurationVersions>) => {
                    const version = row.original.current || row.original.next

                    return (
                        <>
                            {version?.updatedBy && (
                                <>
                                    {version?.updatedBy},<br />
                                </>
                            )}
                            {formatISOString(version?.unpublishedAt || version?.createdAt || '', DateFormats.LONG_DATE)}
                        </>
                    )
                },
            },
            {
                accessor: 'actions',
                disableHideColumn: true,
                disableSortBy: true,
                width: '11em',
                style: { overflow: 'unset' },
                align: 'right',
                Cell: ({ row }: ReactTableCellProps<ConfigurationVersions>) => {
                    const { publishState } = getStatus(row.original)
                    const unublish = () => {
                        if (onUnpublish) {
                            return onUnpublish(row.original.id || '')
                        }
                    }

                    const _publish = () => {
                        if (onPublish) {
                            dispatch(
                                addInlineMessage({
                                    status: 'warn',
                                    header: 'Duplicates found!',
                                    tag: 'EngageTools',
                                    text: 'duplicate',
                                    data: row.original.id,
                                }),
                            )

                            return onPublish(row.original.id || '')
                        }
                    }
                    if (canEditCampaigns) {
                        return (
                            <ActionMenu
                                onUnpublish={publishState ? unublish : null}
                                onPublish={!publishState ? _publish : null}
                                onEditInfo={() => {
                                    if (row.original.id) {
                                        setToEdit(row.original.id)
                                    }
                                    setModalOn(true)
                                }}
                                studioHref={
                                    `/account/${accountSlug}/engage/tools/${
                                        productURLSlugs[row.original.product || 'wonderbar']
                                    }/${row.original.id}/style` + `?device=${row.original.deviceType}&from=${type}`
                                }
                                onDelete={() => onDelete(row.original.id || '')}
                            />
                        )
                    }

                    return null
                },
            },
        ] as TableColumn<ConfigurationVersions>[]
    }, [publish, unpulish, accountSlug, canEditCampaigns, type])

    return (
        <ReactTable
            elevation={false}
            data={tools}
            loading={updating && !loading}
            noResultsRow={() => <NoResultsRow text="There are no configured engage tools yet." />}
            columns={columns}
        />
    )
}

export default TableComponent
