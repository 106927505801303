import * as React from 'react'
import { ExportQuery } from 'orders/schema'
import { LoginService } from 'http/loginService'
import DownloadLink from 'uiComponents/downloadLink'
import { withFeatures } from 'features'
import { OnClickMenu, OnClickMenuItem } from 'uiComponents/menus/'
import { MessageKind, MessageButton } from 'uiComponents/messages'
import { DateRange } from 'dateRanges'
import { withCurrentUser } from 'hocs'
import { User } from 'auth/state'
import { delay } from 'utils'
import { OrdersService } from 'orders/ordersService'
import { LoggingService, ActionEventData } from 'http/loggingService'
import { parseSearch } from 'navigation'
import Feature from 'features/feature'
import cloneDeep from 'lodash/cloneDeep'

interface ExportButtonProps {
    page: 'transactions' | 'details'
    user: User
    ordersNumber: number
    ordersQuery: ExportQuery
    backofficeEndpoint: string
    loginService: LoginService
    loggingService: LoggingService
    activeSlug: string
    dateRange: DateRange
    ordersService: OrdersService
    hasFeature: (feature: string, accountSlug: string) => boolean
    getComponentValue: (component: string, accountSlug: string) => any
    replaceMessages: (id: string, status: MessageKind, text: string, actionButton?: MessageButton) => void
    hideMessage: (id: string) => void
}

class ExportMenu extends React.Component<ExportButtonProps> {
    getQueryObject = (path: string) => {
        if (path.includes('refund-details')) {
            const query = cloneDeep(this.props.ordersQuery)
            query.date_range_type = 'refund'
            return query
        }
        return this.props.ordersQuery
    }

    downloadLink = (path: string, format: string, async: boolean = false) => {
        const queryObject = this.getQueryObject(path)
        const query = Object.keys(queryObject)
            .map((k) =>
                queryObject[k] ? `${encodeURIComponent(k)}=${encodeURIComponent(this.props.ordersQuery[k])}` : '',
            )
            .filter((x) => x !== '')
            .join('&')
        const timezoneOffset = new Date().getTimezoneOffset()
        const params = `${query}&export_format=${format}&timezone_offset=${timezoneOffset}`
        const schedule = async ? 'schedule' : ''
        return `${this.props.backofficeEndpoint}dashboard_api/orders/${path}${schedule}?${params}`
    }

    sendAsyncExportRequest = async (path: string, format: string) => {
        try {
            const endpoint = this.downloadLink(path, format, true)
            await this.props.ordersService.scheduleAsyncExport(endpoint)
            const message =
                path === 'discounts/export/'
                    ? 'Discount codes export request was placed successfully. An email with be sent to you shortly.'
                    : 'Request was placed successfully. An email with be sent to you shortly.'
            this.props.replaceMessages('schedule_success', 'success', message)
            await delay(6000)
            this.props.hideMessage('schedule_success')
        } catch {
            this.props.replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again.')
        }
    }

    isOrderNumberUnderLimit = (path: string, format: string) => {
        const ordersLimit = this.props.getComponentValue('OrdersAsyncExportLimit', this.props.activeSlug)
        if (ordersLimit && this.props.ordersNumber > ordersLimit) {
            const messageButton: MessageButton = {
                kind: 'action',
                buttonText: 'Send archive',
                postButtonText: `to ${this.props.user.username}`,
                onClick: () => this.sendAsyncExportRequest(path, format),
            }
            this.props.replaceMessages(
                'export_message',
                'warn',
                `You are exceeding the limit of ${ordersLimit} items per export.
        Please adjust the time range or search parameters to limit the result or`,
                messageButton,
            )
            return false
        }
        return true
    }

    setMessage = async () => {
        this.props.replaceMessages(
            'export_starting',
            'success',
            'Report generation may take up to 30 seconds and will download automatically.',
        )
        await delay(45000)
        this.props.hideMessage('export_starting')
    }

    logDownloadInitiated = (endpoint: string) => {
        const params = parseSearch(`?${endpoint.split('?')[1]}`)
        const eventData: ActionEventData = {
            category: endpoint.indexOf('orders/details') > -1 ? 'orders_details' : 'orders_transactions',
            payload: {
                search: params.search,
                export_format: params.export_format,
                date_from: params.date_from,
                date_to: params.date_to,
                date_range_type: params.date_range_type,
                sort_by: params.sort_by,
                sort_direction: params.sort_direction,
                endpoint,
            },
        }
        this.props.loggingService.logAction('order_export_requested', eventData)
    }

    render() {
        return (
            <OnClickMenu kind="default" title="Export" style={{ marginLeft: '1em' }} alignRight>
                <DownloadLink
                    loginService={this.props.loginService}
                    downloadEndpoint={this.downloadLink(`${this.props.page}/export/`, 'csv')}
                    downloadAllowed={() => this.isOrderNumberUnderLimit(`${this.props.page}/export/`, 'csv')}
                    onDownloadInitiated={this.logDownloadInitiated}
                >
                    <OnClickMenuItem onClick={this.setMessage}>Export orders .CSV</OnClickMenuItem>
                </DownloadLink>
                <DownloadLink
                    loginService={this.props.loginService}
                    downloadEndpoint={this.downloadLink(`${this.props.page}/export/`, 'xlsx')}
                    downloadAllowed={() => this.isOrderNumberUnderLimit(`${this.props.page}/export/`, 'xlsx')}
                    onDownloadInitiated={this.logDownloadInitiated}
                >
                    <OnClickMenuItem onClick={this.setMessage}>Export orders .XLSX</OnClickMenuItem>
                </DownloadLink>
                {this.props.page === 'transactions' && (
                    <>
                        <OnClickMenuItem onClick={() => this.sendAsyncExportRequest('discounts/export/', 'csv')}>
                            Export discount codes .CSV
                        </OnClickMenuItem>
                        <OnClickMenuItem onClick={() => this.sendAsyncExportRequest('discounts/export/', 'xlsx')}>
                            Export discount codes .XLSX
                        </OnClickMenuItem>
                    </>
                )}
                {this.props.page === 'details' && (
                    <Feature name="OrderRefundExport" accountSlug={this.props.activeSlug}>
                        <OnClickMenuItem onClick={() => this.sendAsyncExportRequest('refund-details/export/', 'csv')}>
                            Export refunds .CSV
                        </OnClickMenuItem>
                        <OnClickMenuItem onClick={() => this.sendAsyncExportRequest('refund-details/export/', 'xlsx')}>
                            Export refunds .XLSX
                        </OnClickMenuItem>
                    </Feature>
                )}
            </OnClickMenu>
        )
    }
}

export default withFeatures(withCurrentUser(ExportMenu))
