import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import ActionButton, { ActionButtonA } from 'uiComponents/buttons'
import { FormWrapper } from 'uiComponents/form/form'
import { SingleSelectFieldFormik } from 'uiComponents/input'
import { Headline } from 'uiComponents/typography'
import { useSelectedParkMapContext } from '../../../context/selectedParkMap'
import { POI, POIAction } from '../../../models'
import { useParkMapCategoriesQuery } from '../../../reduxQuery'
import { FormFooter } from '../categoryDetails/categoryForm'
import { Field } from '../../mapDetails/mapForm'
import MapTranslations from '../../mapTranslations'
import ActionBlock from './actionBlock'
import { STATUS } from './consts'
import IconSelection from '../IconSelection'
import ImageUpload from './imageUpload'
import MarkerCoordinates from './markerCoordinates'
import { poiValidationSchema } from './validation'
import { getIconSvg } from '../utils'

const Conatiner = styled.div`
    padding-right: 10px;
`

export const Subtitle = styled.div`
    font-size: 1.1em;
    padding: 0.6em 0;
`

export const Label = styled.div`
    font-size: 0.875rem;
    padding-right: 16px;
`

const SpacerWithBorder = styled.div`
    width: 100%;
    height: 1px;
    margin: 1em 0;
    background-color: ${(props) => props.theme.colors.textLight};
    opacity: 0.3;
`

const FormBody = styled.div`
    padding-bottom: 2em;
`

interface PoiFormProps {
    data?: POI
    pageTitle: string
    onSubmit(poi: POI): void
}

const PoiForm = ({ data, pageTitle, onSubmit }: PoiFormProps) => {
    const history = useHistory()
    const { selectedParkMap } = useSelectedParkMapContext()
    const categories = useParkMapCategoriesQuery({ locationId: selectedParkMap?.locationId })
    const iconColor = categories?.data?.find((category) => category.id === data?.categoryId)?.color

    const submit = (values: POI) => {
        if (values) {
            const contents = values?.content
                .filter((content) => !!content.name && !!content.locale)
                .map((content) => {
                    return { ...content, default: !!content.default }
                })

            const actionBlocksContents = values?.action?.content
                .filter((content) => !!content.title && !!content.locale)
                .map((content) => {
                    return { ...content, default: !!content.default }
                })

            const action = !values?.action?.link
                ? undefined
                : {
                      ...values?.action,
                      iconSvg: getIconSvg(values.action?.icon),
                      content: actionBlocksContents,
                  }
            const image = values.image || null

            onSubmit({
                ...values,
                action: action as any as POIAction,
                content: contents,
                image: image,
                iconSvg: getIconSvg(values.icon),
            })
        }
    }

    return (
        <>
            {!!data && (
                <Conatiner>
                    <FormWrapper
                        enableReinitialize
                        initialValues={data}
                        onSubmit={submit}
                        validationSchema={poiValidationSchema}
                    >
                        <FormBody>
                            <Headline size={4} style={{ marginBottom: '0.8em' }}>
                                {pageTitle}
                            </Headline>
                            <Field>
                                <IconSelection
                                    field="icon"
                                    color={iconColor}
                                    tooltip="Select an icon representing the poi"
                                />
                            </Field>
                            <Field>
                                <MarkerCoordinates data={data} />
                            </Field>
                            <Field>
                                <Subtitle>Translations</Subtitle>
                                <MapTranslations
                                    fieldName="content"
                                    fields={[
                                        { name: 'name', label: 'Name', type: 'input', placeholder: 'name' },
                                        {
                                            name: 'description',
                                            label: 'Description',
                                            type: 'textarea',
                                            placeholder: 'description',
                                        },
                                    ]}
                                />
                            </Field>
                            <Field>
                                <SingleSelectFieldFormik
                                    name="status"
                                    options={STATUS}
                                    noSelectOption="Select Status"
                                    label="Status"
                                />
                            </Field>
                            <SpacerWithBorder />
                            <Field>
                                <Label>Action block</Label>
                                <ActionBlock />
                            </Field>
                            <Field>
                                <ImageUpload data={data} />
                            </Field>
                        </FormBody>
                        <FormFooter>
                            <ActionButtonA secondary size="large" onClick={() => history.goBack()}>
                                Cancel
                            </ActionButtonA>
                            <ActionButton size="large" type="submit">
                                Save and Exit
                            </ActionButton>
                        </FormFooter>
                    </FormWrapper>
                </Conatiner>
            )}
        </>
    )
}

export default PoiForm
