import { faCopy, faTrash } from '@fortawesome/free-solid-svg-icons'
import { BaseRouteParams } from 'hocs'
import { isNull, omitBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { ActionButtonA } from 'uiComponents/buttons'
import { ActionIcon } from 'uiComponents/buttons/actionIcon'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { ReactTable, TableColumn } from 'uiComponents/table/reactTable'
import { useVenueLocations } from '../../../tapPlacements/studio/helpers'
import { useSelectedParkMapContext } from '../context/selectedParkMap'
import { Parkmap } from '../models'
import { useCreateParkMapMutation, useDeleteParkMapMutation } from '../reduxQuery'

const ActivityContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.25em 0;
`

const ActiveIndicatorContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.9em;
    font-weight: lighter;
    opacity: 0.8;
    margin-top: 5px;
`

const ActiveIndicator = styled.div`
    width: 9px;
    height: 9px;
    background: ${(props) => props.theme.colors.status.success};
    border-radius: 9px;
    margin-right: 8px;
`

interface ParkMapListProps {
    parkmaps: Parkmap[]
}

const ParkMapList = ({ parkmaps }: ParkMapListProps) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const [parkMapToDelete, setParkMapToDelete] = useState<Parkmap>()
    const [deleteParkMap, { isLoading }] = useDeleteParkMapMutation()
    const [createParkMap] = useCreateParkMapMutation()
    const theme = useTheme()
    const { setSelectedParkMap } = useSelectedParkMapContext()
    const venueLocations = useVenueLocations(accountSlug)

    const onDeleteParkMap = () => {
        if (parkMapToDelete) {
            deleteParkMap({ parkMap: parkMapToDelete })
            setParkMapToDelete(undefined)
        }
    }

    const copyParkMap = (parkMap: Parkmap) => {
        const parkMapCopy = omitBy(parkMap, isNull) as Parkmap

        createParkMap({
            parkMap: {
                ...parkMapCopy,
                name: `${parkMapCopy.name} copy`,
                enabled: false,
            },
        })
    }

    const columns: TableColumn<Parkmap>[] = useMemo(
        () =>
            [
                {
                    accessor: 'name',
                    Header: 'Park Map',
                    Cell: ({ row: { original } }) => {
                        return (
                            <>
                                {original.enabled ? (
                                    <ActivityContainer>
                                        {original.name}
                                        <ActiveIndicatorContainer>
                                            <ActiveIndicator /> Active
                                        </ActiveIndicatorContainer>
                                    </ActivityContainer>
                                ) : (
                                    original.name
                                )}
                            </>
                        )
                    },
                },
                {
                    accessor: 'locationId',
                    Header: 'Venue location',
                    Cell: ({ row: { original } }) => {
                        return <>{venueLocations.find((location) => location.value === original.locationId)?.name}</>
                    },
                },
                {
                    accessor: 'action',
                    disableHideColumn: true,
                    disableSortBy: true,
                    align: 'right',
                    Cell: ({ row: { original } }) => {
                        return (
                            <>
                                <ActionButtonA
                                    className="edit-park-map"
                                    kind="action"
                                    secondary
                                    href={`/account/${accountSlug}/venue/park_map/${original.id}/details`}
                                    onClickCapture={() => setSelectedParkMap(original)}
                                >
                                    Edit
                                </ActionButtonA>
                                <ActionIcon
                                    onClick={() => copyParkMap(original)}
                                    title="Create park map copy"
                                    className="first-icon"
                                    icon={faCopy}
                                    size="large"
                                />
                                <ActionIcon
                                    onClick={() => setParkMapToDelete(original)}
                                    title="Delete"
                                    className="first-icon"
                                    icon={faTrash}
                                    size="large"
                                />
                            </>
                        )
                    },
                },
            ] as TableColumn<Parkmap>[],
        [accountSlug, venueLocations],
    )

    return (
        <>
            {parkMapToDelete && (
                <ConfirmationDialog
                    title="Are you sure you want to delete this park map from the list?"
                    text="Once deleted this data will be permanently lost."
                    buttonText="Delete"
                    loading={isLoading}
                    onCancel={() => setParkMapToDelete(undefined)}
                    onConfirm={onDeleteParkMap}
                />
            )}
            <ReactTable
                noResultsRow={() => <NoResultsRow text="There are no park maps yet." />}
                loading={false}
                elevation={false}
                rowProps={() => ({
                    style: {
                        borderBottom: `1px solid ${theme.colors.aluminiumShades[5]}`,
                    },
                })}
                data={parkmaps}
                totalCount={parkmaps.length}
                columns={columns}
                storeStateInQuery={false}
            />
        </>
    )
}

export default ParkMapList
