import * as yup from 'yup'
import { DiscountCodeBEForm, DiscountCodeDetails } from 'venue/bookingCodes/types'
import { parseErrorsFromBE } from 'utils/errorHandler'
import cleanDeep from 'clean-deep'
import { formatOrInvalid, parseISODate } from 'utils/dates'

export interface DiscountCodeFormSchema {
    poolUuid?: string
    id?: string
    type: 'cart' | 'productId'
    code: string
    validity: {
        from: string
        to: string
    }
    products?: {
        uuids?: string[]
        amount?: number
    }
    uses: 'maxUses' | 'unlimited'
    rate: 'flat' | 'percentage'
    reductionRate: string
    usesCount?: number
    remainderUsable?: boolean
}

export const discountCodeFormatter = {
    toForm(code?: DiscountCodeDetails): DiscountCodeFormSchema {
        if (!code) {
            return {} as any
        }
        const products = code.products as any
        return {
            poolUuid: code.discountPoolUuid,
            id: code.id,
            code: code.code,
            type: products?.length > 0 ? 'productId' : 'cart',
            validity: {
                from: code.validity?.fromDate,
                to: code.validity?.toDate,
            },
            products: {
                amount: code.productsAmount,
                uuids: code?.products?.map((product) => product.uuid),
            },
            uses: !code.maxUses ? 'unlimited' : 'maxUses',
            usesCount: code.maxUses,
            remainderUsable: code.remainderUsable,
            rate: code.discount?.type,
            reductionRate: code.discount?.amount,
        }
    },
    toDomain(code: DiscountCodeFormSchema): DiscountCodeBEForm {
        return cleanDeep({
            discountPoolUuid: code.poolUuid,
            id: code.id,
            code: code.code,
            discount: {
                amount: code.reductionRate,
                type: code.rate,
            },
            remainderUsable: code.remainderUsable,
            maxUses: code.usesCount,
            unlimited: code.uses === 'unlimited',
            products: code.products,
            validity: {
                fromDate: formatOrInvalid(parseISODate(code?.validity?.from), 'yyyy-MM-dd'),
                toDate: formatOrInvalid(parseISODate(code?.validity?.to), 'yyyy-MM-dd'),
            },
        }) as DiscountCodeBEForm
    },
    formatErrors(errors: any): any {
        const parsedErrors = parseErrorsFromBE(errors)

        return cleanDeep({
            code: parsedErrors.code,
            validity: {
                from: parsedErrors.validFrom,
                to: parsedErrors.validTo,
            },
            products: parsedErrors.products,
            usesCount: parsedErrors.maxUses || parsedErrors.unlimited,
            remainderUsable: parsedErrors.remainderUsable,
            reductionRate: parsedErrors.percentageReduction || parsedErrors.flatReduction,
        })
    },
}

export const discountCodeSchema = yup.object().shape({
    code: yup.string().required(),
    validity: yup.object().required(),
    reductionRate: yup
        .number()
        .min(1)
        .required()
        .when('rate', {
            is: 'percentage',
            then: yup.number().max(100),
        }),
    usesCount: yup
        .number()
        .min(1)
        .when('uses', (uses, schema) => {
            return uses === 'maxUses' ? schema.required() : schema
        }),
    products: yup.object().when('type', {
        is: 'productId',
        then: yup.object().shape({
            uuids: yup.array().min(1).required(),
            amount: yup.number().min(1).nullable(),
        }),
        otherwise: yup.object().nullable(),
    }),
    remainderUsable: yup
        .boolean()
        .when('usesCount', {
            is: (value: number) => value > 1,
            then: yup.boolean().isFalse('Cannot be set if Max uses is greater than 1'),
        })
        .when('uses', {
            is: 'unlimited',
            then: yup.boolean().isFalse('Cannot be set if unlimited max uses set'),
        }),
})
