import * as React from 'react'
import { Navigation } from 'navigation'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Messages } from 'uiComponents/messages'
import { withMessages, MessageProps } from 'hocs'
import { StatsService } from 'http/statsService'
import { createTimeseriesBarChart, TimeseriesBarChartSource } from 'uiComponents/charts/timeseriesBarChartSource'
import { PageTitle, PageHeading } from 'uiComponents/typography'
import { dateRangeToQuery, DateRange } from 'dateRanges'
import WebisteTrafficChart from './websiteTrafficChart'
import {
    ChartWrapperWithDateRange as WithDateRangeNotice,
    getDataFromQuery,
    areDateRangeDatesEqual,
    ReportsToolsWrapper,
    ReportUpdateInfo,
} from '../helpers'
import TrafficByGroupCharts from './trafficByGroupCharts'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import ReportsFilter from 'reports/reportsFilter'
import { AVAILABLE_FILTERS, STANDALONE_FILTERS, METADATA_CATEGORIES } from 'reports/constants'
import { FilterCategory } from 'uiComponents/filter/schema'
import { ChartContainer } from 'uiComponents/charts/styleComponents'
import { InventoryService } from 'inventory/inventoryService'
import Disclaimer from 'reports/disclaimer'
import { CharonIncidentWarning } from '../utils/charonIncidentWarning'

interface TrafficReportProps {
    accountSlug: string
    statsService: StatsService
    navigation: Navigation
    match: RouteMatch<any>
    inventoryService: InventoryService
}

interface TrafficReportState {
    transactionsChart: TimeseriesBarChartSource
}

class TrafficReport extends React.Component<TrafficReportProps & MessageProps, TrafficReportState> {
    constructor(props: TrafficReportProps & MessageProps) {
        super(props)
        const { dateRange } = getDataFromQuery(this.props.navigation.query())
        this.state = {
            transactionsChart: createTimeseriesBarChart(dateRange),
        }
    }

    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    onDateRangeChanged = (range: DateRange) => {
        const { dateRange } = getDataFromQuery(this.props.navigation.query())
        if (!areDateRangeDatesEqual(dateRange, range)) {
            this.props.navigation.addQueryWithReplace(dateRangeToQuery(range))
            this.setState({ transactionsChart: createTimeseriesBarChart(range) })
        }
    }

    render() {
        const { accountSlug, statsService, replaceMessages, hideMessage, navigation } = this.props
        const { dateRange, filters } = getDataFromQuery(navigation.query())
        const appliedFilters = filters.filter(
            (f) => AVAILABLE_FILTERS['traffic'].indexOf(f.attribute as FilterCategory) > -1,
        )

        return (
            <div id="reports-traffic-page">
                <Messages messages={this.props.messages} hideMessage={this.props.hideMessage} />
                <PageTitle data-userpilot="traffic-header-tooltip">Traffic Report</PageTitle>
                <PageHeading fullWidth>
                    View your website’s traffic and find information about how your website visitors behave online,
                    where they are coming from, which devices they are using, and on what days and hours your website is
                    more or less popular. Use the date picker to explore specific date ranges and hover over the graphs
                    to view details. You can find more information in our{' '}
                    <a target="_blank" href="https://support.convious.com/help/traffic-report" rel="noreferrer">
                        Knowledge Base
                    </a>
                    .
                </PageHeading>
                <CharonIncidentWarning dateRange={dateRange}>
                    Due to a technical issue, the traffic metrics from 20th to 23rd of February, 2024 will be excluded
                    from our reports. We apologize for any inconvenience.
                </CharonIncidentWarning>
                <ReportUpdateInfo metric="Data" text="updated every 15 minutes." />
                <ReportsToolsWrapper>
                    <ReportsFilter
                        accountSlug={accountSlug}
                        statsService={statsService}
                        inventoryService={this.props.inventoryService}
                        applicableFilters={AVAILABLE_FILTERS['traffic']}
                        standaloneFilters={STANDALONE_FILTERS['traffic']}
                        metadataCategories={METADATA_CATEGORIES['traffic']}
                        appliedFilters={appliedFilters}
                        replaceMessages={replaceMessages}
                        hideMessage={hideMessage}
                        dateRange={dateRange}
                        userpilot="traffic-filter"
                    />
                    <DateRangePicker
                        range={dateRange}
                        onChange={this.onDateRangeChanged}
                        userpilot="traffic-date-range"
                        firstAvailableDay={new Date(2019, 0, 1)}
                    />
                </ReportsToolsWrapper>
                <ChartContainer>
                    <WithDateRangeNotice dateRange={dateRange} userpilot="traffic-total-canvas">
                        <WebisteTrafficChart
                            dateRange={dateRange}
                            statsService={statsService}
                            accountSlug={accountSlug}
                            chart={this.state.transactionsChart}
                            replaceTopMessages={replaceMessages}
                            filters={appliedFilters}
                        />
                    </WithDateRangeNotice>
                </ChartContainer>
                <TrafficByGroupCharts
                    statsService={statsService}
                    accountSlug={accountSlug}
                    dateRange={dateRange}
                    replaceTopMessages={replaceMessages}
                    filters={appliedFilters}
                />
                <Disclaimer />
            </div>
        )
    }
}

export default withMessages(withNavigation(TrafficReport))
