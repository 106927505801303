import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { SingleSelectOption } from '../singleSelect'
import {
    Autocomplete,
    AutocompleteProps,
    Chip,
    createFilterOptions,
    FilterOptionsState,
    TextField,
} from '@mui/material'
import FieldWrapper from '../fieldWrapper'
import { createDataTree } from '../../tree/utils'
import { createGetAncestors, getAllChildren } from './utils'
import ComboboxList from './comboboxList'
import { MaterialSelectOption } from './types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome'
import { ComboboxContextProvider } from './comboboxContext'
import {
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
    AutocompleteValue,
} from '@mui/base/useAutocomplete/useAutocomplete'
import { AutocompleteOwnerState, AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete'

export interface Props
    extends Omit<
        AutocompleteProps<SingleSelectOption, boolean, boolean, false>,
        'value' | 'onChange' | 'renderInput' | 'filterOptions' | 'renderGroup' | 'renderOption'
    > {
    options: SingleSelectOption[]
    label?: ReactNode
    onChange?: (value: string[]) => void
    nested?: boolean
    value?: string[]
    name?: string
    status?: 'error' | 'normal'
    error?: string
    excludeParentsFromValue?: boolean
}

const formatSelectOption = (option: SingleSelectOption, depth?: number) =>
    ({
        id: option.value,
        label: option.name,
        depth: depth,
        parentId: option.parentId,
        disabled: option.disabled,
        original: option,
    } as MaterialSelectOption)

const useFormatOptions = ({
    options,
    nested,
}: {
    options: SingleSelectOption[]
    nested?: boolean
}): MaterialSelectOption[] => {
    return useMemo(() => {
        if (!nested) {
            return options.map((option) => formatSelectOption(option))
        }

        const opts = createDataTree(options, 'value', 'parentId')
        return opts.reduce((acc, ele) => {
            const itterateEl = (option: any, depth = 0) => {
                acc.push(formatSelectOption(option, depth))

                if (option.childNodes.length) {
                    option.childNodes.forEach((opt: any) => itterateEl(opt, depth + 1))
                }
            }

            itterateEl(ele, 1)

            return acc
        }, [] as MaterialSelectOption[])
    }, [options, nested])
}

const createGetSelectOptions =
    (nested?: boolean) => (options: MaterialSelectOption[], state: FilterOptionsState<MaterialSelectOption>) => {
        const filter = createFilterOptions({
            trim: true,
            ignoreCase: true,
            matchFrom: 'any',
            ignoreAccents: true,
        })

        if (!state.inputValue) {
            return options
        }

        if (!nested) {
            return filter(options, state)
        }

        const getAncestors = createGetAncestors(options)
        const allVisibleOptions = [] as string[]

        options.forEach((option: any) => {
            const hasName = filter([option], state)[0]

            if (hasName && option.parentId) {
                const ancestors = getAncestors(option.parentId)

                ancestors.forEach((ancestor) => {
                    allVisibleOptions.push(ancestor.id)
                })
            }

            if (hasName) {
                allVisibleOptions.push(option.id)
            }
        })

        return options.filter((option) => allVisibleOptions?.includes(option.id))
    }

const renderTags = (
    tags: MaterialSelectOption[],
    getTagProps: AutocompleteRenderGetTagProps,
    ownerState: AutocompleteOwnerState<any, any, any, any, any>,
) => {
    let tagsToRender = tags

    if (tags.length >= 15) {
        tagsToRender = [...tags.slice(0, 14)]
    }

    return (
        <>
            <div style={{ width: ownerState.expanded ? '100%' : 'auto' }}>
                {tagsToRender.map((tag: MaterialSelectOption, index) => {
                    const { key, ...tagProps } = getTagProps({ index })

                    return <Chip size="small" key={key} {...tagProps} label={tag?.label} />
                })}
            </div>
            {tags.length > 15 && <span>{tags.length - 15}+ &nbsp;</span>}
        </>
    )
}

const ComboboxNew = ({
    options,
    label,
    nested,
    multiple,
    value,
    onChange,
    name,
    status,
    placeholder,
    excludeParentsFromValue,
    ...rest
}: Props) => {
    const internalOptions = useFormatOptions({ options, nested })
    const [searchInput, setSearchInput] = useState<string>('')
    const [open, setOpen] = useState<boolean>(false)
    const optionChilds = useMemo(() => {
        return internalOptions.reduce((acc, option) => {
            if (acc[option.id]) {
                acc[option.id] = [...acc[option.id], ...getAllChildren(internalOptions, option?.id)]
            } else {
                acc[option.id] = getAllChildren(internalOptions, option?.id)
            }

            return acc
        }, {})
    }, [internalOptions])

    const objectIds = useMemo(
        () =>
            internalOptions.reduce((acc, ele) => {
                acc[ele.id] = ele
                return acc
            }, {}),
        [internalOptions],
    )
    const [internalValue, setValue] = useState<MaterialSelectOption | MaterialSelectOption[]>([])

    useEffect(() => {
        if (Array.isArray(value)) {
            const mappedValue = value?.map((id) => objectIds[id])
            setValue(mappedValue)
            return
        }

        if (value) {
            const mappedValue = objectIds[value]
            setValue(mappedValue)

            return
        }
    }, [value, objectIds])

    const internalOnChange = (
        _: React.SyntheticEvent,
        newValue: AutocompleteValue<MaterialSelectOption, any, any, any>,
        __: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<MaterialSelectOption>,
    ) => {
        if (multiple && nested && details?.option) {
            const listValues = internalValue as MaterialSelectOption[]
            const newValues = newValue as MaterialSelectOption[]
            const relatives = getAllChildren(internalOptions, details?.option?.id)
            const getIsSelected = (
                childs: MaterialSelectOption[],
                selected: MaterialSelectOption[],
                excludeParentsFromValue?: boolean,
            ) => {
                if (excludeParentsFromValue) {
                    const selectedIds = selected.map((option) => option?.id)
                    const checkIfAllChildsAreSelected = (option: MaterialSelectOption): boolean => {
                        const childs = getAllChildren(internalOptions, option?.id)

                        if (childs.length) {
                            return false
                        }

                        return selectedIds.includes(option?.id)
                    }

                    return childs.filter(checkIfAllChildsAreSelected).length !== 0
                }

                return listValues.find((option) => option.id === details.option.id)
            }

            const isSelected = getIsSelected(relatives, listValues, excludeParentsFromValue)

            if (isSelected) {
                const getAllParents = createGetAncestors(internalOptions)
                const parents = getAllParents(details.option.id)?.map((parent) => parent?.id)

                const relativeIds = relatives.map((item) => item?.id)
                const filteredItems = listValues.filter((item) => {
                    return (
                        item.id !== details?.option?.id &&
                        !relativeIds.includes(item?.id) &&
                        !parents?.includes(item?.id)
                    )
                })

                setValue(filteredItems)
                if (onChange) {
                    onChange(filteredItems.map((option) => option?.id))
                }
            } else {
                const alreadySelectedOptions = [] as string[]
                const parents = [] as string[]
                const allOptions = [...newValues, ...relatives].filter((option) => {
                    const id = `${option.id}-${option.parentId}`
                    const hasChilds = !!optionChilds[option.id]?.length

                    if (!alreadySelectedOptions.includes(id)) {
                        alreadySelectedOptions.push(id)

                        if (excludeParentsFromValue && hasChilds) {
                            parents.push(id)
                            return false
                        }

                        return true
                    }

                    return false
                })

                setValue(allOptions)
                if (onChange) {
                    onChange(allOptions.map((option) => option.id))
                }
            }

            return
        }

        if (!multiple && details?.option?.label) {
            setSearchInput(details?.option?.label)
        }

        setValue(newValue as MaterialSelectOption)

        if (onChange) {
            if (Array.isArray(newValue)) {
                if (typeof newValue?.[0] === 'string') {
                    onChange(newValue as string[])
                    return
                }
                const selectOptions = newValue as MaterialSelectOption[]

                onChange(selectOptions?.map((option) => option.id))
            } else {
                const val = typeof newValue === 'string' ? newValue : newValue?.id

                onChange(val)
            }
        }
    }

    const selectedOptionsObject = useMemo(() => {
        if (Array.isArray(internalValue)) {
            return internalValue.map((value) => value?.id)
        }

        return internalValue?.id
    }, [internalValue])

    return (
        <ComboboxContextProvider
            nested={nested}
            multiple={multiple}
            optionChilds={optionChilds}
            selectedOptions={selectedOptionsObject}
        >
            <FieldWrapper status={status ?? 'normal'} label={label}>
                <Autocomplete
                    {...rest}
                    freeSolo
                    multiple={multiple}
                    options={internalOptions}
                    disableListWrap
                    limitTags={2}
                    placeholder={placeholder ?? 'Search...'}
                    disableCloseOnSelect={multiple}
                    value={internalValue}
                    onChange={internalOnChange}
                    size="small"
                    open={open}
                    inputValue={(searchInput || (internalValue as MaterialSelectOption)?.label) ?? ''}
                    onInputChange={(e, value, reason) => {
                        if (reason === 'input' || reason === 'clear') {
                            setSearchInput(value)
                        }
                    }}
                    onBlur={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    onClose={() => {
                        if (multiple) {
                            setSearchInput('')
                        }
                    }}
                    getOptionDisabled={(option: MaterialSelectOption) => !!option.disabled}
                    popupIcon={<FontAwesomeIcon icon={faChevronDown as IconProp} size="sm" />}
                    getOptionLabel={(option: MaterialSelectOption) => option?.label ?? ''}
                    ListboxComponent={ComboboxList}
                    renderTags={renderTags}
                    openOnFocus={true}
                    renderOption={(props, option, state) => [props, option, state.index, state] as React.ReactNode}
                    renderGroup={(params) => params as any}
                    filterOptions={createGetSelectOptions(nested)}
                    renderInput={(params) => <TextField placeholder="Search..." name={name} {...params} />}
                />
            </FieldWrapper>
        </ComboboxContextProvider>
    )
}

export default ComboboxNew
