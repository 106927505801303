import { faDownload } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Audience } from 'crm/schema'
import { BaseRouteParams } from 'hocs'

import { ActionEventData } from 'http/loggingService'
import { AppServices } from 'middleware'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-typed'
import DownloadLink from 'uiComponents/downloadLink'
import { OnClickMenuItem } from 'uiComponents/menus'
import { replaceMessage } from 'uiComponents/messages/actions'

const Icon = styled(FontAwesomeIcon)`
    margin-right: 0.5em;
`

interface DownloadOdlButtonProps {
    audience: Audience
}

const DownloadOdlButton: React.FC<DownloadOdlButtonProps> = ({ audience }) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const dispatch = useDispatch()

    const getDownloadLink = () => {
        return AppServices.crmService.getExportAudienceEndpoint(accountSlug, audience)
    }

    const setMessage = async () => {
        dispatch(
            replaceMessage(
                'export_starting',
                'success',
                'Audience data generation may take up to 30 seconds and will download automatically.',
                30000,
            ),
        )
    }

    const logDownloadInitiated = (endpoint: string) => {
        const eventData: ActionEventData = {
            category: 'crm_audiences',
            payload: { endpoint },
        }
        AppServices.loggingService.logAction('crm_audiences_export_requested', eventData)
    }
    return (
        <DownloadLink
            loginService={AppServices.loginService}
            downloadEndpoint={getDownloadLink()}
            onDownloadInitiated={logDownloadInitiated}
        >
            <OnClickMenuItem onClick={setMessage}>
                <Icon icon={faDownload} />
                Download
            </OnClickMenuItem>
        </DownloadLink>
    )
}

export default DownloadOdlButton
