import * as React from 'react'
import styled from 'styled-typed'
import { State } from 'store'
import { connect } from 'react-redux'
import { Account } from 'auth/state'
import { Switch, Route, Redirect } from 'react-router-dom'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Location, History } from 'history'
import LandingPage from './landingPage'
import TransactionsPage from 'orders/transactions'
import OrdersDetailsPage from 'orders/details'
import { Navigation } from 'navigation'
import { dateRangeFromQuery, dateRangeToQuery, DateRange } from 'dateRanges'
import { Sorting, Pagination } from 'uiComponents/table'
import { OrdersService } from 'orders/ordersService'
import { LoginService } from 'http/loginService'
import { LoggingService } from 'http/loggingService'
import { PaypalService } from 'orders/paypalService'
import InlineCheckout from 'orders/inlineCheckout'
import { defaultPageData } from 'orders/schema'
import { DateRangeType } from 'uiComponents/popups/comparisonDateRangePicker/schema'
import { withPermission } from 'admin/hocs'
import { User } from 'auth/state'
import { ActionButtonA } from 'uiComponents/buttons'
import { IconProp } from '@fortawesome/fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AccountSettingsService } from 'settings/settingsService'
import PersonalSettingsPage from 'settings/personalSettings'
import { UserService } from 'auth/userService'
import { PasswordResetService } from 'http/passwordResetService'

const NavButtons = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
`
const Icon = styled(FontAwesomeIcon)`
    margin-right: 0.5em;
    margin-bottom: 0.05em;
    font-size: 0.75em;
`

interface OrdersPageParams {
    accountSlug: string
}

interface ResellersHomePageProps {
    user: User
    accounts: Account[]
    accountSlug: string
    history: History
    location: Location
    navigation: Navigation
    ordersService: OrdersService
    loginService: LoginService
    loggingService: LoggingService
    userService: UserService
    backofficeEndpoint: string
    passwordResetService: PasswordResetService
    paypalService: PaypalService
    match: RouteMatch<OrdersPageParams>
    accountSettingsService: AccountSettingsService
    hasPermission: (permission: string, accountSlug: string) => boolean
}

class ResellersHomePage extends React.Component<ResellersHomePageProps> {
    onSortChanged = (sorting: Sorting) => {
        this.props.navigation.addQueryWithReplace({
            sortBy: sorting.prop,
            sortDirection: sorting.direction,
        })
    }

    onPaginationChanged = (pagination: Pagination) => {
        this.props.navigation.addQueryWithReplace({
            page: pagination.page.toString(),
            pageSize: pagination.pageSize.toString(),
        })
    }

    onDateRangeChanged = (range: DateRange) => {
        this.props.navigation.addQueryWithReplace({
            ...dateRangeToQuery(range),
            page: '1',
        })
    }

    onDateRangeTypeChanged = (rangeType: DateRangeType) => {
        this.props.navigation.addQueryWithReplace({
            dateRangeType: rangeType,
            page: '1',
        })
    }

    render() {
        const query = this.props.navigation.query()
        const sortDirection = query.sortDirection === 'asc' ? 'asc' : 'desc'
        const sort: Sorting = {
            prop: query.sortBy,
            direction: sortDirection,
        }
        const pagination: Pagination = {
            page: isNaN(Number(query.page)) ? defaultPageData.currentPage : Number(query.page),
            pageSize: isNaN(Number(query.pageSize)) ? defaultPageData.pageSize : Number(query.pageSize),
        }
        const dateRange = dateRangeFromQuery(query)
        const dateRangeType = query.dateRangeType === 'visit' ? 'visit' : 'sale'

        const activeAccount =
            this.props.accounts.find((x) => x.slug === this.props.accountSlug) || this.props.accounts[0]
        const disableCheckout = !!this.props.user?.resellerId && !activeAccount.activeResellerContract

        return (
            <div style={{ position: 'relative' }}>
                {!this.props.location.pathname.includes('resellers/home') && (
                    <NavButtons>
                        <ActionButtonA href="/resellers/home/" secondary size="large">
                            <Icon icon={faChevronLeft as IconProp} />
                            Back to list
                        </ActionButtonA>
                        {this.props.location.pathname.includes('new_order') && (
                            <ActionButtonA
                                href={`/resellers/account/${this.props.accountSlug}/orders/transactions/`}
                                secondary
                                size="large"
                                kind="action"
                                style={{ marginLeft: '1em' }}
                            >
                                Orders history
                            </ActionButtonA>
                        )}
                    </NavButtons>
                )}
                <Switch location={this.props.location}>
                    <Route
                        path="/resellers/home"
                        render={() => (
                            <LandingPage
                                history={this.props.history}
                                accountSlug={this.props.accountSlug}
                                accounts={this.props.accounts}
                                disableCheckout={disableCheckout}
                                filterQuery={query.filter}
                                accountSettingsService={this.props.accountSettingsService}
                            />
                        )}
                    />
                    <Route
                        path="/resellers/account/:accountSlug/orders/transactions"
                        render={() => (
                            <TransactionsPage
                                history={this.props.history}
                                accountSlug={this.props.accountSlug}
                                search={query.q}
                                sort={sort}
                                onSortChanged={this.onSortChanged}
                                pagination={pagination}
                                onPaginationChanged={this.onPaginationChanged}
                                dateRange={dateRange}
                                onDateRangeChanged={this.onDateRangeChanged}
                                dateRangeType={dateRangeType}
                                onDateRangeTypeChange={this.onDateRangeTypeChanged}
                                ordersService={this.props.ordersService}
                                loginService={this.props.loginService}
                                loggingService={this.props.loggingService}
                                backofficeEndpoint={this.props.backofficeEndpoint}
                            />
                        )}
                    />
                    <Route
                        path="/resellers/account/:accountSlug/orders/details"
                        render={() => (
                            <OrdersDetailsPage
                                history={this.props.history}
                                accountSlug={this.props.accountSlug}
                                search={query.q}
                                sort={sort}
                                onSortChanged={this.onSortChanged}
                                pagination={pagination}
                                onPaginationChanged={this.onPaginationChanged}
                                dateRange={dateRange}
                                onDateRangeChanged={this.onDateRangeChanged}
                                dateRangeType={dateRangeType}
                                onDateRangeTypeChange={this.onDateRangeTypeChanged}
                                ordersService={this.props.ordersService}
                                loginService={this.props.loginService}
                                loggingService={this.props.loggingService}
                                backofficeEndpoint={this.props.backofficeEndpoint}
                            />
                        )}
                    />
                    {!disableCheckout && (
                        <Route
                            path="/resellers/account/:accountSlug/new_order"
                            render={() => (
                                <InlineCheckout
                                    history={this.props.history}
                                    accountSlug={this.props.accountSlug}
                                    loginService={this.props.loginService}
                                />
                            )}
                        />
                    )}
                    <Route
                        path="/resellers/personal_settings"
                        render={() => (
                            <PersonalSettingsPage
                                accountSlug={this.props.accountSlug}
                                history={this.props.history}
                                accountSettingsService={this.props.accountSettingsService}
                                userService={this.props.userService}
                                loginService={this.props.loginService}
                                passwordResetService={this.props.passwordResetService}
                                location={this.props.location}
                                welcomePage={false}
                            />
                        )}
                    />
                    <Route render={() => <Redirect to={`/resellers/home/${this.props.location.search}`} />} />
                </Switch>
            </div>
        )
    }
}

function mapStateToProps(state: State) {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
        user: state.auth.user,
    }
}

export default withPermission(withNavigation(connect(mapStateToProps)(ResellersHomePage)))
