import { DateRange } from 'dateRanges'
import { format } from 'date-fns'
import { startOfToday, differenceInDays, addMonths, addDays, compareAsc, startOfMonth } from 'date-fns'
import { indexBy } from 'utils'
import { DataPoint, TimeSeries } from 'reports/schema'
import { pricingChartColors } from 'reports/pricing/pricingChart'
import { DateFormats, formatISOString, parseISODate } from 'utils/dates'

const dayLabelFormat = 'd'
const weekdayFormat = 'eee'
const monthLabelFormat = 'MMM'

interface Series {
    name: string | null
    type: string
    stack: string
    data: string[]
}

export interface XAxisData {
    category: Date
}

function generateXAxis(from: Date, to: Date, next: (d: Date) => Date): XAxisData[] {
    const result = []
    let current = from
    while (compareAsc(current, to) < 0) {
        result.push({ category: current })
        current = next(current)
    }
    return result
}

function getTodayLineOptions(today: number, label: string = 'TODAY') {
    return {
        silent: true,
        symbol: ['none', 'none'],
        animation: false,
        label: {
            show: true,
            formatter: label,
            position: 'start',
            distance: -30,
            color: '#FFFFFF',
            backgroundColor: 'rgba(164, 175, 178, 0.8)',
            padding: 5,
            borderRadius: 4,
            fontSize: 10,
            fontWeight: 'bold',
        },
        lineStyle: { color: 'rgba(164, 175, 178, 0.8)' },
        data: [{ xAxis: today }],
    }
}

export class TimeseriesBarChartSource {
    constructor(
        public readonly granularity: 'month' | 'day' | 'hour',
        private readonly xAxisData: XAxisData[],
        private readonly dateFrom: Date,
        private readonly dateTo: Date,
        private series: Series[] = [],
        public customGranularity: boolean = false,
        private customXAxis: string[] = [],
    ) {}

    formatSeries(barSeries: TimeSeries[], decimalPoints: number = 0, showTodayLine: boolean = false): void {
        const xAxisValues = this.xAxisData.map((x) => format(x.category, 'yyyy-MM-dd'))
        const today = xAxisValues.indexOf(format(startOfToday(), 'yyyy-MM-dd'))

        if (barSeries) {
            this.series = barSeries.map((s, i) => {
                return {
                    name: s.label,
                    type: 'bar',
                    stack: '1',
                    data: this.setSeriesData(s.points, decimalPoints),
                    markLine: showTodayLine && i === 0 ? getTodayLineOptions(today) : null,
                }
            })
        }
    }

    formatCustomSeries(series: TimeSeries[], decimalPoints: number = 0): void {
        this.customGranularity = true
        if (series) {
            this.overrideXAxis(series)
            this.series = series.map((s) => {
                return {
                    name: s.label,
                    type: 'bar',
                    stack: '1',
                    data: s.points.map((p) => p.value.toFixed(decimalPoints)),
                }
            })
        }
    }

    formatPricingChartSeries(
        series: TimeSeries[],
        decimalPoints: number,
        showTodayLine: boolean,
        oneDayChart: boolean,
    ): void {
        const xAxisValues = this.xAxisData.map((x) => format(x.category, 'yyyy-MM-dd'))
        const today = xAxisValues.indexOf(format(startOfToday(), 'yyyy-MM-dd'))
        const lastDay = xAxisValues.length - 1
        const markLineIndex = oneDayChart ? lastDay : today
        const label = oneDayChart ? 'VISIT DATE' : 'TODAY'
        const additionalProps = [
            { color: pricingChartColors['avgPrice'], stack: '', yAxisIndex: 1 },
            { color: pricingChartColors['minPrice'], stack: '1', yAxisIndex: 1 },
            {
                color: pricingChartColors['maxPrice'],
                stack: '1',
                areaStyle: {},
                yAxisIndex: 1,
            },
            { color: pricingChartColors['actualSales'], areaStyle: {} },
        ]

        if (series) {
            this.series = series.map((s, i) => {
                return {
                    name: s.label,
                    type: 'line',
                    stack: '',
                    data: this.setSeriesData(s.points, decimalPoints, true),
                    markLine: showTodayLine && i === 0 ? getTodayLineOptions(markLineIndex, label) : null,
                    symbol: 'circle',
                    ...additionalProps[i],
                }
            })
        }
    }

    overrideXAxis(series: TimeSeries[]): void {
        if (series) {
            const points = series[0].points.length > 0 ? series[0].points : series[1].points
            this.customXAxis = points.map((p) => {
                return formatISOString(p.timestamp, DateFormats.SHORT_TIME)
            })
        }
    }

    addLineSeries(lineSeries: TimeSeries[], decimalPoints: number = 0): void {
        if (lineSeries) {
            const mappedLineSeries = lineSeries.map((s) => ({
                name: s.label,
                type: 'line',
                stack: '',
                yAxisIndex: 1,
                data: this.setSeriesData(s.points, decimalPoints),
            }))
            this.series.unshift(...mappedLineSeries)
        }
    }

    setSeriesData = (datapoints: DataPoint[], decimalPoints: number, hideZeroValues: boolean = false): string[] => {
        if (datapoints.length === 0) {
            return []
        }
        const values = []
        let indexedPoints = {}
        indexedPoints = indexBy(datapoints, (x) => parseISODate(x.timestamp).getTime().toString())
        for (let i = 0; i < this.xAxisData.length; i++) {
            const existing =
                this.granularity === 'hour'
                    ? indexedPoints[this.xAxisData[i].category.getTime().toString()]
                    : indexedPoints[parseISODate(format(this.xAxisData[i].category, 'yyyy-MM-dd')).getTime().toString()]
            if (existing) {
                values.push(existing.value.toFixed(decimalPoints))
            } else {
                values.push(hideZeroValues ? null : 0)
            }
        }
        return values
    }

    formatXAxisData(): string[] {
        let xAxisData = []
        switch (this.granularity) {
            case 'hour':
                xAxisData = this.xAxisData.map((x) => `${x.category.getHours()}`)
                break
            case 'month':
                xAxisData = this.xAxisData.map((x) => format(x.category, monthLabelFormat))
                break
            default:
                xAxisData = this.xAxisData.map(
                    (x) => format(x.category, dayLabelFormat) + '\n' + format(x.category, weekdayFormat),
                )
        }
        return xAxisData
    }

    from(): Date {
        return this.dateFrom
    }

    to(): Date {
        return this.dateTo
    }

    props(): any {
        return {
            xAxisData: this.customGranularity ? this.customXAxis : this.formatXAxisData(),
            xAxisDataRaw: this.xAxisData,
            legendData: this.series.map((s) => s.name),
            series: this.series,
        }
    }
}

export function createTimeseriesBarChart(range: DateRange): TimeseriesBarChartSource {
    const dFrom = range.from || new Date(0)
    const dTo = range.to || addDays(startOfToday(), 1)
    const daysBetween = differenceInDays(dTo, dFrom) + 1

    // commented out until timeslots are supported in stats api
    // if (daysBetween < 3) {
    //   return new TimeseriesBarChartSource(
    //     'hour',
    //     generateXAxis(dFrom, dTo, d => addHours(d, 1)),
    //     dFrom,
    //     dTo,
    //   )
    // }

    if (daysBetween < 62) {
        return new TimeseriesBarChartSource(
            'day',
            generateXAxis(dFrom, dTo, (d) => addDays(d, 1)),
            dFrom,
            dTo,
        )
    }

    const from = startOfMonth(dFrom)
    return new TimeseriesBarChartSource(
        'month',
        generateXAxis(from, dTo, (d) => addMonths(d, 1)),
        dFrom,
        dTo,
    )
}
