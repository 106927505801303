import * as React from 'react'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { ConfigField, FieldCategory } from './schema'
import { AlignedCol } from 'uiComponents/studio/form/formComponents'
import { ProductsMetaData, Article } from 'uiComponents/filter/schema'
import { MultiSelect } from 'uiComponents/input/multiSelectTree'
import { FilterOption } from 'uiComponents/filter/schema'
import { getProductTreeOptions } from 'uiComponents/filter/filterHelpers'

interface SectionProps {
    accountSlug: string
    category: FieldCategory
    fieldsConfig: ConfigField[]
    categories: ProductsMetaData[]
    products: Article[]
    handleAssign: (value: string[], field: string, fieldCategory: FieldCategory) => void
}

function AssignSection(props: SectionProps) {
    const [productOptions, setProductOptions] = React.useState<FilterOption[]>([])

    React.useEffect(() => {
        if (productOptions.length) {
            return
        }
        const options = getProductTreeOptions(props.products, props.categories)
        setProductOptions(options)
    }, [props.categories, props.products])

    return (
        <SectionWrapper title="Assign field to specific products">
            {props.fieldsConfig.map((f, i) => (
                <FormItem key={f.slug + i} style={{ position: 'relative' }}>
                    <AlignedCol span={6} style={{ position: 'absolute', top: '1.2em' }}>
                        <label htmlFor={f.slug}>{f.name}</label>
                    </AlignedCol>
                    <AlignedCol span={12} className="align-right">
                        <MultiSelect
                            id={f.slug}
                            options={productOptions}
                            applied={f.products}
                            onApply={(v) => props.handleAssign(v, f.slug, props.category)}
                            noSelectOption="All products"
                            selectedItemName={f.products.length === 1 ? 'product' : 'products'}
                            widthWhenOpen="100%"
                        />
                    </AlignedCol>
                </FormItem>
            ))}
        </SectionWrapper>
    )
}

export default AssignSection
