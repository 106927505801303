import React, { InputHTMLAttributes } from 'react'
import FieldWrapper, { FieldStatus } from '../fieldWrapper'
import { useCustomId } from '../_shared/hooks'
import { TextInputProps } from './interface'
import { TextInput } from './textInput'

interface TextInputFieldProps extends TextInputProps, Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
    name: string
    id?: string
    label?: string
    state?: FieldStatus
    error?: string
    value?: string
}

const TextInputField: React.FC<TextInputFieldProps> = ({ name, label, id, error, state = 'normal', ...props }) => {
    const innerId = useCustomId(id)

    return (
        <FieldWrapper label={label} error={error} id={innerId} status={!!error ? 'error' : state}>
            <TextInput type="text" {...props} id={innerId} status={state === 'highlight' ? 'normal' : state} />
        </FieldWrapper>
    )
}

export default TextInputField
