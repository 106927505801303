import { faDownload } from '@fortawesome/pro-light-svg-icons'
import * as React from 'react'
import { OnClickMenu, OnClickMenuItem } from 'uiComponents/menus'
import { ReactTable, ReactTableCellProps, TableColumn } from 'uiComponents/table/reactTable'
import { useHasFeature } from '../../../features'
import { Audience } from '../../schema'
import AudienceDownloadModal from '../components/downloadModal'
import DownloadOdlButton from '../components/downloadOldButton'
import { useFetchAudiences } from '../hooks/useFetchAudiences'
import useIsPartnerAllowed from '../hooks/useIsPartnerAllowed'
import { Icon } from './customList'
import { format } from 'date-fns'
import { DateFormats } from 'utils'

const PredefinedList = () => {
    useIsPartnerAllowed()

    const canUseNewDownload = useHasFeature('audienceDownloadConsentSelection')
    const { fetch: fetchAudience, data, loading } = useFetchAudiences()

    return (
        <>
            <AudienceDownloadModal>
                {({ setOpen, setSelectedAudience }) => {
                    const columns = React.useMemo(() => {
                        return [
                            {
                                accessor: 'name',
                                Header: 'Name',
                            },
                            {
                                accessor: 'createdOn',
                                Header: 'Created on',
                                disableSortBy: true,
                                Cell: () => format(new Date('2021-01-01'), DateFormats.LONG_DATE),
                                width: '10em',
                            },
                            {
                                accessor: 'modifiedOn',
                                Header: 'Modified on',
                                disableSortBy: true,
                                Cell: () => format(new Date('2021-01-01'), DateFormats.LONG_DATE),
                                width: '11em',
                            },
                            {
                                accessor: 'action',
                                disableSortBy: true,
                                width: '10em',
                                align: 'right',
                                style: { overflow: 'unset' },
                                Cell: ({ row: { original: item } }: ReactTableCellProps<Audience>) => (
                                    <OnClickMenu title="Actions" kind="action" width="15em" secondary alignRight>
                                        {!!canUseNewDownload && (
                                            <OnClickMenuItem
                                                onClick={() => {
                                                    setOpen()
                                                    setSelectedAudience(item)
                                                }}
                                            >
                                                <Icon icon={faDownload} />
                                                Download
                                            </OnClickMenuItem>
                                        )}
                                        {!canUseNewDownload && <DownloadOdlButton audience={item} />}
                                    </OnClickMenu>
                                ),
                            },
                        ] as TableColumn<Audience>[]
                    }, [canUseNewDownload])

                    return (
                        <ReactTable
                            size="small"
                            sort
                            loading={loading}
                            loadData={fetchAudience}
                            data={data}
                            columns={columns}
                        />
                    )
                }}
            </AudienceDownloadModal>
            <div style={{ height: '6rem' }} />
        </>
    )
}

export default PredefinedList
