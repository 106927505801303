import { useField } from 'formik'
import React, { InputHTMLAttributes } from 'react'
import FieldWrapper from '../fieldWrapper'
import { useCustomId, useGetComponentState } from '../_shared/hooks'
import { TextArea, TextAreaProps } from './textArea'

interface TextAreaFieldFormikProps extends TextAreaProps, Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'prefix'> {
    name: string
    id?: string
    label?: string
}

const TextAreaFieldFormik: React.FC<TextAreaFieldFormikProps> = ({ name, label, id, ...props }) => {
    const [{ value, ...field }, { error }] = useField(name)
    const innerId = useCustomId(id)
    const state = useGetComponentState(name)

    return (
        <FieldWrapper label={label} error={error} id={innerId} status={state}>
            <TextArea {...props} {...field} value={value || ''} id={innerId} status={state} />
        </FieldWrapper>
    )
}

export default TextAreaFieldFormik
