import { useGetFeatureValue } from 'utils/useHasFeature'
import { GlobalPricingGoal } from '../../products/pricing/pricingService'

export interface PricingModelOption {
    value: GlobalPricingGoal
    name: string
    description?: string
}

export const pricingModelOptions: PricingModelOption[] = [
    { value: 'opt_revenue', name: 'Optimize for Revenue' },
    { value: 'opt_max_sold', name: 'Optimize for Max Sold Products' },
    { value: 'opt_visitor_dist', name: 'Optimize for Even Visitor Distribution' },
    { value: 'opt_rule_based', name: 'Advanced rule based pricing (new partners)' },
    { value: 'early_bird_by_inventory', name: 'Early bird by inventory' },
    { value: 'early_bird_by_time', name: 'Early bird by time' },
]

export const useGetPricingModels = (): PricingModelOption[] => {
    const pricingModelOptionsFromComponent = useGetFeatureValue('gpsModelsList')

    return pricingModelOptionsFromComponent || pricingModelOptions
}
