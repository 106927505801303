import React, { lazy, Suspense } from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

import { BaseRouteParams } from 'hocs'
import { AppServices } from 'middleware'
import { ContainerBody } from 'uiComponents/settingsContainer'
import { PageLoader } from 'uiComponents/loaders'
import UsersContainer from 'settings/users'

const UserListSection = lazy(() => import('settings/users/userListSection'))
const AccountUserForm = lazy(() => import('settings/users/userForm'))

const UserRoutes = ({ match, history }: RouteComponentProps<BaseRouteParams>) => {
    return (
        <UsersContainer>
            <Switch>
                <Suspense fallback={<PageLoader />}>
                    <Route path="/account/:accountSlug/settings/users/user_list" component={UserListSection} />
                    <Route
                        path="/account/:accountSlug/settings/users/user_form/:id?"
                        render={() => (
                            <ContainerBody>
                                <AccountUserForm
                                    accountSlug={match.params.accountSlug}
                                    accountSettingsService={AppServices.accountSettingsService}
                                    history={history}
                                />
                            </ContainerBody>
                        )}
                    />
                    <Route
                        render={() => <Redirect to={`/account/${match.params.accountSlug}/settings/users/user_list`} />}
                    />
                </Suspense>
            </Switch>
        </UsersContainer>
    )
}

export default UserRoutes
