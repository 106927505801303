import React, { createContext, useCallback, useContext, useState, useEffect } from 'react'
import { DiscountRulesServiceContext } from 'products/discountRules/context'
import { DiscountRule } from 'products/discountRules/discountRulesService'
import { MetadataContext } from 'products/discountRules/metadataContext'
import { Sorting, SortDirection } from 'uiComponents/table'
import { getSortedRules } from './helpers'

interface DiscountRulesContextProps {
    updating: boolean
    rulesList: DiscountRule[]
    sort: Sorting
    onSortChanged: (s: Sorting) => void
    reloadData: () => void
}

export const DiscountRulesContext = createContext<DiscountRulesContextProps>(undefined as any)

const DiscountRulesContextState: React.FC<{}> = ({ children }) => {
    const discountRulesService = useContext(DiscountRulesServiceContext)
    const { accountSlug, navigation, replaceMessages } = useContext(MetadataContext)

    const [loadingData, setLoadingData] = useState<boolean>(false)
    const [rulesList, setRulesList] = useState<DiscountRule[]>([])

    const loadData = useCallback(async () => {
        setLoadingData(true)
        try {
            const rules = await discountRulesService.getDiscountRuleList(accountSlug)
            setRulesList(getSortedRules(rules, getSort()))
        } catch {
            replaceMessages('unknown_error', 'error', 'Oops! Unable to get discount rules. Please try again later.')
        } finally {
            setLoadingData(false)
        }
    }, [accountSlug])

    useEffect(() => {
        loadData()
    }, [accountSlug])

    const onSortChanged = useCallback(
        (sorting: Sorting) => {
            navigation.addQueryWithReplace({
                sortBy: sorting.prop,
                sortDirection: sorting.direction,
            })
            const sort = {
                prop: sorting.prop,
                direction: sorting.direction,
            }
            setRulesList(getSortedRules(rulesList, sort))
        },
        [navigation, rulesList],
    )

    const getSort = () => {
        const query = navigation.query()
        const sort: Sorting = {
            prop: query.sortBy || 'id',
            direction: (query.sortDirection || 'desc') as SortDirection,
        }
        return sort
    }

    return (
        <DiscountRulesContext.Provider
            value={{
                updating: loadingData,
                rulesList,
                sort: getSort(),
                onSortChanged,
                reloadData: loadData,
            }}
        >
            {children}
        </DiscountRulesContext.Provider>
    )
}

export default DiscountRulesContextState
