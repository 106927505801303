import React, { useMemo } from 'react'
import styled from 'styled-typed'
import { CartItem } from 'orders/schema'
import { ReactTable, TableColumn } from 'uiComponents/table/reactTable'
import { useParams } from 'react-router-dom'
import { BaseRouteParams } from 'hocs'
import Money from 'uiComponents/money'
import { useTheme } from 'styled-components'

const emptyData = [] as any[]

const RowTableContainer = styled.div`
    padding: 1rem 5.5rem;
    width: 100%;
    & .table {
        background-color: inherit;
    }
    & .body .tr {
        background-color: white;
    }
`

interface CartItemTableProps {
    cartItems: CartItem[]
}

const CartItemTable: React.FC<CartItemTableProps> = ({ cartItems }) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const theme = useTheme()
    const columns = useMemo(
        () =>
            [
                {
                    accessor: 'productName.en',
                    Header: 'Product',
                    width: '14rem',
                    Cell: ({ value }) => <span title={value}>{value}</span>,
                },
                {
                    accessor: 'price',
                    Header: 'Price',
                    width: '6.25rem',
                    Cell: ({ value }) => <Money amount={value} accountSlug={accountSlug} />,
                },
                {
                    accessor: 'amount',
                    width: '6.25rem',
                    Header: 'Qty',
                },
            ] as TableColumn<CartItem>[],
        [accountSlug],
    )

    return (
        <RowTableContainer>
            <ReactTable
                size="small"
                elevation={false}
                columns={columns}
                storeStateInQuery={false}
                tableProps={{
                    style: {
                        width: 'fit-content',
                        backgroundColor: theme.colors.tableRow,
                    },
                }}
                rowProps={() => {
                    return {
                        style: {
                            border: `1px solid ${theme.colors.aluminiumShades[5]}`,
                            margin: '.5rem 0',
                            borderRadius: 4,
                        },
                    }
                }}
                data={cartItems || emptyData}
            />
        </RowTableContainer>
    )
}

export default CartItemTable
