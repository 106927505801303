import React, { ReactNode, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

import { ModalDialog, ModalDialogTitle, NavigationSection } from 'uiComponents/popups/modal'
import { Body, Caption, Link } from 'uiComponents/typography'
import ActionButton from 'uiComponents/buttons'
import { crmSelectors } from 'crm/selectors'
import { useHasFeature } from 'features'
import { Audience } from 'crm/schema'
import { addInlineMessage } from 'uiComponents/messages/actions'
import { useParams } from 'react-router-dom'
import { BaseRouteParams } from 'hocs'
import { Configuration } from './componentsService'

interface SubmitOptions {
    submit: () => void
    saveDraft: (publish?: boolean) => void
}

export type HandleStudioSubmit = (values: Configuration, options: SubmitOptions) => void

const formatAudienceName = (audiences?: (Audience | undefined)[]) => {
    if (!Array.isArray(audiences)) {
        return
    }

    return audiences
        .filter((audience) => !!audience)
        .map((audience: Audience) => audience.name)
        .join(', ')
}

interface AudienceConfirmationProps {
    children({ handleSubmit }: { handleSubmit: HandleStudioSubmit }): ReactNode
}

const AudienceConfirmation: React.FC<AudienceConfirmationProps> = ({ children }) => {
    const hasFeature = useHasFeature('CRMAudiencesPage')
    const [open, setOpen] = useState(false)
    const selectedAudiencesRef = useRef<string[]>()
    const audiences = useSelector(crmSelectors.findAudienceBySlugArray(selectedAudiencesRef.current))
    const optionsRef = useRef<SubmitOptions>()
    const closeModal = () => setOpen(false)
    const dispatch = useDispatch()
    const { id } = useParams<BaseRouteParams & { id: string }>()
    const handleSubmit: HandleStudioSubmit = (values, options) => {
        if (hasFeature) {
            const selectedAudiences = get(values, 'productConfig.audiences')
            const marketingConsent = get(values, 'productConfig.marketingConsent')
            selectedAudiencesRef.current = selectedAudiences
            const isAudienceFilterApplied = !!selectedAudiences?.length && !marketingConsent

            if (isAudienceFilterApplied) {
                selectedAudiencesRef.current = selectedAudiences
                optionsRef.current = options
                return setOpen(true)
            }
        }

        dispatch(
            addInlineMessage({
                status: 'warn',
                header: 'Duplicates found!',
                tag: 'EngageTools',
                text: 'duplicate',
                data: id,
            }),
        )

        return options.submit()
    }

    return (
        <>
            {open && (
                <ModalDialog
                    onDismiss={closeModal}
                    interactive
                    fromTop="20%"
                    style={{
                        width: '508px',
                    }}
                >
                    <ModalDialogTitle>Are you sure you want to publish?</ModalDialogTitle>
                    <Body size={1} style={{ paddingBottom: '2em' }}>
                        The audiences you selected, &nbsp;
                        <b>“{formatAudienceName(audiences)}”</b> &nbsp; includes all users of this audience.
                    </Body>
                    <Body size={1} style={{ paddingBottom: '2em' }}>
                        <b>
                            Depending on the character of your message, please ensure to select the necessary filters.
                        </b>
                        &nbsp; According to the local execution of the GDPR, receiving or being subject to marketing
                        messages may require consent
                    </Body>
                    <Caption>
                        For more information visit our &nbsp;
                        <Link
                            href="https://support.convious.com/help/audiences-in-your-engage-tools-overview-campaign-inspiration"
                            target="_blank"
                        >
                            Knowledge Base
                        </Link>
                        .
                    </Caption>
                    <NavigationSection>
                        <ActionButton secondary size="large" onClick={closeModal}>
                            Cancel
                        </ActionButton>
                        <ActionButton
                            secondary
                            kind="action"
                            size="large"
                            onClick={() => {
                                closeModal()
                                optionsRef.current?.saveDraft(false)
                            }}
                        >
                            Save as draft
                        </ActionButton>

                        <ActionButton
                            size="large"
                            onClick={() => {
                                closeModal()
                                optionsRef.current?.submit()
                            }}
                        >
                            Publish
                        </ActionButton>
                    </NavigationSection>
                </ModalDialog>
            )}

            {children({ handleSubmit })}
        </>
    )
}

export default AudienceConfirmation
