import React from 'react'
import { getListOrderData, getSelectedOrderDetails, getSelectedOrdersList } from 'orders/orders/redux'
import { useMemo } from 'react'
import Permission from 'admin/permissionRequired'
import { BaseRouteParams, WithNavigationProps, withNavigation } from 'hocs'
import BulkOrderCancelRefund from './bulkCancelRefund'
import SingleOrderCancelRefund from './singleCancelRefund'
import { useAppSelector } from 'store/hooks'

const CancelRefund: React.FC<WithNavigationProps<BaseRouteParams>> = ({ match }) => {
    const { accountSlug } = match.params
    const selectedOrderRowsIds = useAppSelector(getSelectedOrdersList)
    const currentData = useAppSelector(getListOrderData)?.data
    const selectedOrder = useAppSelector((state) => state.orderTable.selectedOrder)
    const selectedOrderItems = useAppSelector(getSelectedOrderDetails)
    const selectedItemsState = useAppSelector((state) => state.orderTable.selectedItems)

    const selectedRows = useMemo(() => {
        const ordersData = [...(currentData?.results ?? [])]
        if (selectedOrder && ordersData.length === 0) {
            ordersData.push(selectedOrder)
        }
        const rows = ordersData.filter((order) => selectedOrderRowsIds.includes(order.id))

        if (rows.length === 0 && selectedOrderItems.length > 0) {
            const orderIdFromSelectedItem = Object.keys(selectedItemsState).find((orderId) =>
                selectedItemsState[orderId].hasOwnProperty(selectedOrderItems[0].id),
            )
            const orderFromSelectedItem = ordersData.find((order) => order.id === orderIdFromSelectedItem)
            return orderFromSelectedItem ? [orderFromSelectedItem] : []
        }

        return rows
    }, [currentData, selectedOrderRowsIds, selectedOrder, selectedOrderItems])

    return (
        <>
            {selectedRows.length > 1 && <BulkOrderCancelRefund selectedOrderRows={selectedRows} />}
            <Permission name="refund_orders" accountSlug={accountSlug}>
                {(selectedRows.length === 1 || (selectedOrderItems.length > 0 && selectedRows.length <= 1)) && (
                    <>
                        <SingleOrderCancelRefund order={selectedRows[0]} cancellation={true} />
                        <SingleOrderCancelRefund order={selectedRows[0]} cancellation={false} />
                    </>
                )}
            </Permission>
        </>
    )
}

export default withNavigation(CancelRefund)
