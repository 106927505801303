import { object, string } from 'yup'
import { barcodeFormatOptions } from './common'

export const validationSchema = object({
    barcodePoolName: string().max(255).required('Name is required'),
    barcodePrefix: string()
        .max(8)
        .when('barcodeFormatOption', (barcodeFormatOption, schema) => {
            if (barcodeFormatOption?.allowPrefix) {
                switch (barcodeFormatOption?.type) {
                    case 'qr_code':
                        return schema.matches(
                            /^[\u0020-\u00FF]*$/,
                            'QR code format allows only Latin-1 (ISO/IEC 8859-1) characters',
                        )
                    case 'code128':
                        return schema.matches(
                            /^[\u0020-\u007E]*$/,
                            'Code-128 format allows only ASCII characters (0-9, A-Z, special characters)',
                        )
                    case 'code39':
                        return schema.matches(
                            /^[0-9A-Z\w$%*+\-.\/]*$/,
                            "Code-39 format allows only uppercase letters, numbers, space and '$ % * + - . /' characters",
                        )
                    default:
                        return schema.length(0, 'Barcode prefix is not allowed for this format')
                }
            }
            return schema.length(0, 'Barcode prefix is not allowed for this format')
        }),
    barcodeFormatOption: object({
        type: string().when('poolTypeOption', {
            is: 'Generated',
            then: string().oneOf(
                barcodeFormatOptions
                    .filter(({ value: { allowGenerated } }) => allowGenerated)
                    .map(({ value: { type } }) => type),
                'Barcode format not allowed for generated pools',
            ),
            otherwise: string().required('Barcode format must be selected'),
        }),
    })
        .nullable()
        .required('Barcode format must be selected'),
    poolTypeOption: string()
        .when('barcodeFormatOption', (barcodeFormatOption, schema) =>
            barcodeFormatOption?.type === 'ean8'
                ? schema.oneOf(['Uploaded'], 'For EAN-8 format pool must be uploaded')
                : schema,
        )
        .nullable()
        .required('Pool type must be selected'),
})
