import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import { TemplatePreview } from './preview'
import { ModalDialog } from 'uiComponents/popups/modal'
import { TemplateType } from './schema'
import { MessageKind } from 'uiComponents/messages'
import { EmailTemplatesService } from 'emailTemplates/emailTemplatesService'
import { PreviewContainer } from 'uiComponents/studio/preview/components'

const PreviewWrapper = styled(PreviewContainer)`
    top: 0;
    height: 98%;
    width: auto;
`

interface DialogProps {
    accountSlug: string
    templateType: TemplateType
    emailTemplatesService: EmailTemplatesService
    onClose: () => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

export function PreviewModal(props: DialogProps) {
    const [templateHtml, setTemplateHtml] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        async function getTemplateHtml() {
            setLoading(true)
            try {
                const html = await props.emailTemplatesService.getTemplateHtml(
                    props.accountSlug,
                    props.templateType,
                    null,
                    'en',
                )
                setTemplateHtml(html)
            } catch {
                props.onClose()
                props.replaceMessages(
                    'server_error',
                    'error',
                    'Oops! We could not load the preview. Please try again later.',
                )
            }
            setLoading(false)
        }
        getTemplateHtml()
    }, [props.accountSlug])

    return (
        <ModalDialog onDismiss={props.onClose} interactive fromTop="10%" style={{ width: '47.5em', height: '80%' }}>
            <PreviewWrapper className="desktop">
                <TemplatePreview templateHtml={templateHtml} loading={loading} />
            </PreviewWrapper>
        </ModalDialog>
    )
}
