import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useParkMapViewerContext } from '../context/mapViewer'

export const useOnPageLeave = () => {
    const history = useHistory()
    const { setIsRegionChanged } = useParkMapViewerContext()

    useEffect(() => {
        const unlisten = history.listen(() => {
            setIsRegionChanged(false)
        })

        return unlisten
    }, [])
}

export default useOnPageLeave
