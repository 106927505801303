/* eslint-disable id-denylist */
import { defaultPageSize } from 'uiComponents/table/pagination'
import { RefundFeeType } from 'settings/accountSettings/contract/managementService'
import { OptionItem } from 'products/options/optionsService'
import { StatusType } from 'uiComponents/typography'

interface Status {
    text: string
    style: StatusType
}

export const statusesMap: { [key: string]: Status } = {
    paid: { text: 'Paid', style: 'success' },
    confirmed: { text: 'Confirmed', style: 'success' },
    booked: { text: 'Booked', style: 'success' },
    cancelled: { text: 'Cancelled', style: 'info' },
    refunded: { text: 'Refunded', style: 'info' },
    refunding: { text: 'Refunding...', style: 'processing' },
    charged_back: { text: 'Charged back', style: 'info' },
    'charged back': { text: 'Charged back', style: 'info' }, // BO issues, need to check if this is valid
    redeemed: { text: 'Redeemed', style: 'success' },
    not_redeemed: { text: 'Not redeemed', style: 'processing' },
    open: { text: 'Open', style: 'processing' },
    failed: { text: 'Failed', style: 'error' },
    pending: { text: 'Pending', style: 'processing' },
    partial: { text: 'Partial', style: 'processing' },
    partially_canceled: { text: 'Partially cancelled', style: 'info' }, // temp until typo removed from BO
    partially_cancelled: { text: 'Partially cancelled', style: 'info' },
    partially_refunded: { text: 'Partially refunded', style: 'info' },
    partially_redeemed: { text: 'Partially redeemed', style: 'processing' },
    unknown: { text: 'Unknown', style: 'error' },
}
export type OrderStatusKeys = keyof typeof statusesMap

export const getOrderStatus = (status: OrderStatusKeys): Status => {
    const statusObj = statusesMap[status]

    if (!statusObj) {
        return statusesMap['unknown']
    }

    return statusObj
}

export const ticketStatusesMap: { [key: string]: Status } = {
    unpaid: { text: 'Unpaid', style: 'info' },
    cancelled: { text: 'Cancelled', style: 'info' },
    refund_in_progress: { text: 'Refund in progress', style: 'processing' },
    paid: { text: 'Paid', style: 'success' },
    refunded: { text: 'Refunded', style: 'info' },
    unknown: { text: 'Unknown', style: 'error' },
}

export type OrderTicketStatusKeys = keyof typeof ticketStatusesMap

export const getOrderTicketStatus = (status: OrderTicketStatusKeys): Status => {
    const statusObj = ticketStatusesMap[status]

    if (!statusObj) {
        return ticketStatusesMap['unknown']
    }

    return statusObj
}

export const defaultPageData = {
    entries: [],
    totalCount: 0,
    currentPage: 1,
    nextPage: null,
    prevPage: null,
    pageSize: defaultPageSize,
    userFieldConfig: {
        enabled: false,
        fields: [],
    },
}

export interface Barcode {
    barcode: string
    redeemed: string | null
    refunded: boolean
    canRefund: boolean
    status: string
}

export interface TicketEntity {
    id: number
    uuid: string
    invalidatedAt: string | null
    status: string
    barcode: Barcode | null
    canRefund: boolean
    price: number
}

export interface Customer {
    email: string
    language: string
}

export interface Ticket {
    orderItemId: number
    orderItemUuid: string
    product: string
    quantity: number
    pricePerItem: number
    pricePerItemAfterDiscount: number
    freeItems: number
    isBundle: boolean
    groupedTicketEntities: TicketEntity[][]
    canRefund: boolean
    bundleItemPrices: number[] | null
}

export interface TicketItemForRefund {
    ticketEntities: TicketEntity[]
    ticketUuids: string[]
    barcodeList: string[]
    status: string
    itemRefundable: boolean
    price: number
}

export interface TicketForRefund extends Ticket {
    items: TicketItemForRefund[]
    status: string
}

export interface Order {
    id: string
    number: string
    uuid: string
    status: string
    orderStatus: string
    paymentStatus: string
    customer: Customer
    orderDate: string
    paymentDate: string
    visitDate: string | null
    visitTime: string | null
    tickets: Ticket[]
    ticketDownloadUrl: string
    total: number
    totalAfterDiscount: number
    discountAmount: number
    discountCode: string
    quantity: number
    canRefund: boolean
    canPartialRefund: boolean
    location: string
    partnerRefundFee: number | null
    partnerRefundFeeType: RefundFeeType | null
    emailStatus: string
    resellerName: string | null
    paymentProvider: string
    unfilledApf: boolean
    downloaded: string | null
    paymentMethod: string | null
    items?: OptionItem[]
}

export interface OrderDetailsRestSchema extends Omit<OrderDetails, 'barcode'> {
    barcode?: Barcode
}
export interface Page {
    totalCount: number
    prevPage: number | null
    nextPage: number | null
    currentPage: number
    pageSize: number
}

export interface OrderPage extends Page {
    entries: Order[]
}

export interface TransactionsQueryResponse {
    orders: OrderPage
    openDisputeCount: number
}

export interface UserField {
    name: string
    value: string
    type: string
    label: string
    placeholder: string
    isRequired: boolean
    editable: boolean
    repeatable: boolean
}

export interface ConfigUserField {
    name: string
    articles: string[]
    repeatable: boolean
    type: string
    label: string
    placeholder: string
    required: boolean
}

export interface OrderItemOptions {
    name: string
    numberOfItems: number
    price: number
}

export interface OrderDetails {
    id: string
    barcode: string
    orderId: string
    orderItemId: string
    orderUuid: string
    orderEmail: string
    product: string
    amountPaid: number
    discountCode: string
    bundle: boolean
    bundleOrderItemId: string
    paymentDate: string
    visitDate: string
    visitTime: string
    validFrom: string
    validTo: string
    redeemed: string | null
    status: string
    marketingOptIn: boolean
    userFields: UserField[]
    printed: string | null
    resellerName: string | null
    options: OrderItemOptions[]
}

export interface OrderDetailsPage extends Page {
    entries: OrderDetails[]
    userFieldConfig: UserFieldConfig
}

export interface UserFieldConfig {
    enabled: boolean
    fields: ConfigUserField[]
}

export interface OrderDetailsPageData {
    barcodes: OrderDetailsPage
}

export interface SelectedForRefund {
    uuid: string
    ticketUuids: string[]
}

export interface DateAndTime {
    date: string | null
    time: string | null
}

export interface ExportQuery {
    search: string | undefined
    widget_slug: string
    date_from: string | null
    date_to: string | null
    sort_by: string
    sort_direction: 'asc' | 'desc'
    date_range_type: string
}

export interface UpdateTicketValidToDetails {
    bundleOrderItemId: string
    orderId: string
    orderItemId: string
    date: Date
}

type OrderDetailsIncludeOptions =
    | 'payments'
    | 'items'
    | 'customer'
    | 'discounts'
    | 'location'
    | 'reseller'
    | 'items.barcode'
    | 'items.external_ids'
    | 'items.options'
    | 'date_changes'

export interface GetOrderDetailsRestParams {
    uuid: string
    include: OrderDetailsIncludeOptions[]
}

export interface ListOrdersParams {
    accountSlug: string
    dateRangeFrom: Date | string
    dateRangeTo: Date | string
    dateRangeType: 'visit' | 'sale'
    query: string
    sortProp: string
    sortDirection: string
    page: number
    pageSize: number
    searchType: 'extended' | 'simple'
    filter: string
}

export interface BulkRefundOrderProps {
    accountSlug: string
    orderUuids: string[]
    refundReason?: string
    forgoPartnerRefundFee?: boolean
    isCancellation?: boolean
}

export interface CartItem {
    productId: string
    amount: number
    price: string
    productName: Record<string, string>
}

export interface Cart {
    id: string
    categoryId: string
    eventDate: string
    startTime: string
    endTime: string
    externalId: string
    totalPrice: string
    totalPriceAfterDiscount: string
    items: CartItem[]
    extras: Record<string, any>
    expiresAt: string
    urls?: { rel: string; url: string }[]
}

// ----------------------------------------- Orders Beta schemas -------------------------------------------- //
export interface OrdersListRequestPayload {
    accountSlug: string
    include: string
    sortBy: string
    sortDirection: string
    search: string
    searchBy: string
    resellers: string
    directSales: boolean
    paymentMethods: string
    locations: string
    fromCreatedAt: string
    toCreatedAt: string
    fromEventDate: string
    toEventDate: string
    status: string
    emailStatus: string
    products: string
    pageSize: number
    offset: string
}

export interface OrdersListResult {
    next: string
    previous: string
    results: IOrder[]
}

export interface IOrder {
    id: string
    number: string
    account: string
    status: string
    productType: string
    categoryId: string
    orderDate: string
    updatedAt: string
    visitDate: string
    visitTime: string
    visitEndTime: string
    timezone: string
    ticketDownloadUrl: string
    quantity: number
    totalPriceInclTax: string
    totalPriceExclTax: string
    totalDiscountedPriceInclTax: string
    totalDiscountedPriceExclTax: string
    discountAmount: string
    currency: string
    locationId: string
    emailStatus: string
    unfilledApf: boolean
    unfilledApfUrl: string
    printed: boolean | null
    downloaded: string | null
    externalId: string | null
    orderSource: string
    customer: IOrderCustomer
    discounts: IOrderDiscount[]
    location: IOrderLocation
    reseller: IOrderReseller
    payments: IOrderPayment[]
    items?: IOrderItems[]
}

export interface IOrderItems {
    id: string
    orderItemId: string
    productId: string
    productNumericId: number
    product: string
    gatePriceInclTax: string
    gatePriceExclTax: string
    priceInclTax: string
    priceExclTax: string
    discountedPriceInclTax: string
    discountedPriceExclTax: string
    discountedPriceWithoutOptionsInclTax: string
    discountedPriceWithoutOptionsExclTax: string
    invalidatedAt?: string
    validFrom: string
    validTo: string
    visitDate?: string
    visitTime?: string
    visitEndTime: string
    refunded: boolean
    canRefund: boolean
    isBundle: boolean
    afterPaymentForm: Record<string, string>
    barcode: IOrderItemBarcode
    options: IOrderItemOption[]
    refundInfo: IOrderItemRefundInfo | null
}

interface IOrderCustomer {
    email: string
    name: string | null
    language: string
    marketingOptIn: boolean
}

interface IOrderDiscount {
    code: string
    externalId: string | null
}

interface IOrderLocation {
    id: string
    name: string
}

interface IOrderReseller {
    id: string | null
    name: string | null
}

export interface IOrderPayment {
    id: number
    paymentProvider: string
    paymentMethod: string
    paymentStatus: string
    paymentDate: string
}

interface IOrderItemBarcode {
    barcode: string
    redeemed: string | null
    status: string
}

export interface IOrderItemOption {
    id: string
    name: string
    priority: number | null
    pricingId: string
    priceInclTax: string
    priceExclTax: string
    numberOfItems?: number
}

interface IOrderItemRefundInfo {
    isCancellation: boolean
    reason: string
    requestedBy: string
}
