import * as React from 'react'
import { Exception } from './openingTimesService'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { InlineDataTable, HeaderRow, TableHeader, Cell, DataRow } from 'uiComponents/table'
import { ActionButtonA } from 'uiComponents/buttons'
import { WeekdayPattern } from 'uiComponents/weekdayPattern'
import { DateRange, TimeRange } from 'uiComponents/date'

interface OpeningTimesOverridesProps {
    accountSlug: string
    overrides: Exception[]
    openingTimesId: string
}

const columnWidths = [null, '10em', '10em', '10em', '7em']

export function OpeningTimesExceptions(props: OpeningTimesOverridesProps) {
    return (
        <>
            {props.overrides.length > 0 ? (
                <InlineDataTable columnWidths={columnWidths} id="exceptions-list">
                    <HeaderRow>
                        <TableHeader>Name</TableHeader>
                        <TableHeader>Opening Hours</TableHeader>
                        <TableHeader>Weekly Pattern</TableHeader>
                        <TableHeader>Date Range</TableHeader>
                        <TableHeader nonInteractive />
                    </HeaderRow>
                    {props.overrides.map((o, key) => (
                        <DataRow key={key} narrow>
                            <Cell title={o.name}>{o.name || 'Exception name'}</Cell>
                            <Cell>
                                {o.locationClosed ? (
                                    <>closed</>
                                ) : (
                                    <TimeRange startDateString={o.openingTime} endDateString={o.closingTime} />
                                )}
                            </Cell>
                            <Cell>
                                {o.weekdays && (
                                    <WeekdayPattern
                                        type="strict"
                                        invert={!!o.locationClosed}
                                        weekdays={o.weekdays.join(',')}
                                    />
                                )}
                            </Cell>
                            <Cell>
                                <DateRange startDateString={o.availableFrom} endDateString={o.availableTo} />
                            </Cell>
                            <Cell align="right">
                                <ActionButtonA
                                    className="edit-exception"
                                    kind="action"
                                    secondary
                                    href={`/account/${props.accountSlug}/venue/opening_times/exception/${o.id}/default/${props.openingTimesId}/`}
                                >
                                    Edit
                                </ActionButtonA>
                            </Cell>
                        </DataRow>
                    ))}
                </InlineDataTable>
            ) : (
                <NoResultsRow text="There are no opening times exceptions yet." />
            )}
        </>
    )
}
