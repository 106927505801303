import React from 'react'
import ConnectedInlineMessage from 'uiComponents/messages/connectedInlineMessage'
import { useShowConviousStudyInfo } from './infoNotificationHook'

const GeneralNotifications = () => {
    useShowConviousStudyInfo()

    return (
        <div style={{ width: '100%', paddingBottom: '1rem', display: 'flex', justifyContent: 'center' }}>
            <div style={{ maxWidth: '50vw' }}>
                <ConnectedInlineMessage tag="general" />
            </div>
        </div>
    )
}

export default GeneralNotifications
