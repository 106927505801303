import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-typed'

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const ContentSection = styled.div`
    min-width: 27.75em;
`
export const PageActionsSection = styled.div`
    width: 100%;
    padding-top: 1.5em;
    border-top: 1px solid ${(props) => props.theme.colors.border};
    display: flex;
    justify-content: center;
`
export const Container = styled.div`
    position: relative;
    margin-bottom: 3em;
    max-width: 75em;
`
export const ErrorMessage = styled.div`
    color: ${(props) => props.theme.colors.status.error};
`
export const HorizontalLine = styled.div`
    width: 100%;
    border-bottom: 1px ${(props) => props.theme.colors.textLight} solid;
    margin-bottom: 1.8em;
    margin-top: 0.9em;
    opacity: 0.5;
`

export const Row = styled.div`
    display: flex;
    margin-bottom: 0.5em;
`

export const Column = styled.div`
    flex: 1;
`

export const ColumnGap = styled(Column)`
    max-width: 3.5em;
    margin: 0 0.2em;
`

export const PageHeaderTooltipContainer = styled.div`
    display: inline-block;
    position: relative;
    top: -1.4em;
    left: 0.15em;
    font-size: 0.37em;
`

export const ButtonWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

interface TextWithHorizontalLineProps {
    text: string
    alignment: 'center' | 'left' | 'right'
}

const TextLineWrapper = styled.div<TextWithHorizontalLineProps>`
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.colors.textLight};
    line-height: 0.1em;
    margin: 3em 0;
    text-align: ${(props) => (props.alignment === 'center' ? 'center' : props.alignment === 'left' ? 'left' : 'right')};
    color: ${(props) => props.theme.colors.textLight};
    > span {
        color: ${(props) => props.theme.colors.textLight};
        font-size: 0.75rem;
        text-transform: uppercase;
        background: #fff;
        padding: 0 0.5em 0 0.1em;
    }
`

export class TextWithHorizontalLine extends React.Component<
    TextWithHorizontalLineProps & React.HTMLAttributes<HTMLElement>
> {
    render() {
        return (
            <TextLineWrapper style={this.props.style} {...this.props}>
                <span>{this.props.text}</span>
            </TextLineWrapper>
        )
    }
}

interface ElementWithDashedLineProps {
    children: React.ReactNode
    alignment: 'center' | 'left' | 'right'
}

const DashedLine = styled.div`
    width: 100%;
    border-bottom: 1px dashed ${(props) => props.theme.colors.boyBlue};
    color: ${(props) => props.theme.colors.boyBlue};
`

interface DashedLineWrapperProps {
    childHeight: number
    childWidth: number
    width: number
    alignment: 'center' | 'left' | 'right'
}

const DashedLineWrapper = styled.div<DashedLineWrapperProps>`
  display: block;
  line-height: .1em;
  margin-top: 3em;
  padding-bottom: 3em;
  > div {
    position: absolute;
  }
  ${DashedLine} {
    width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  }
  > div.children {
    ${(props) => (props.alignment === 'center' ? `margin-left: ${props.width / 2 - props.childWidth / 2}px;` : '')}
    ${(props) => (props.alignment === 'right' ? 'margin-right: 0%;' : '')}
    background-color: #fff;
    margin-top: ${(props) => `-${props.childHeight / 2}px`}
    padding-left: .7em;
    padding-right: .7em;
  }
`

export function ElementWithDashedLine(props: ElementWithDashedLineProps & React.HTMLAttributes<HTMLElement>) {
    const wrapperRef = useRef(null)
    const childRef = useRef(null)
    const [wrapperWidth, setWrapperWidth] = useState(0)
    const [childHeight, setChildHeight] = useState(0)
    const [childWidth, setChildWidth] = useState(0)

    useEffect(() => {
        // @ts-ignore: Object is possibly 'null'.
        setWrapperWidth(!!wrapperRef.current ? wrapperRef.current.clientWidth : 0)
        // @ts-ignore: Object is possibly 'null'.
        setChildHeight(!!childRef.current ? childRef.current.clientHeight : 0)
        // @ts-ignore: Object is possibly 'null'.
        setChildWidth(!!childRef.current ? childRef.current.clientWidth : 0)
    }, [])
    return (
        <DashedLineWrapper
            ref={wrapperRef}
            style={props.style}
            width={wrapperWidth}
            childHeight={childHeight}
            childWidth={childWidth}
            {...props}
        >
            <DashedLine />
            <div className="children" ref={childRef}>
                {props.children}
            </div>
        </DashedLineWrapper>
    )
}

interface ContainerProps {
    status: 'success' | 'error'
}

export const ContainerWithStatus = styled(Row)<ContainerProps>`
    padding: 1em;
    margin: 0 -1em 0.5em -1em;
    ${(props) =>
        props.status === 'error'
            ? `
      border-radius: .5em;
      box-shadow: inset 0 0 0 2px ${props.theme.colors.status.error};
    `
            : ''}
`
