import * as React from 'react'
import { usePrevious, useIsMounted } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { format, differenceInCalendarDays, startOfToday, addDays } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { getComparisonColorFromPercentString, chartRowsGap, comparisonCopyMap } from 'reports/helpers'
import { DataNumber } from 'uiComponents/charts/dataNumber'
import { ChartRow, ChartContainer } from 'uiComponents/charts/styleComponents'
import { QueryConfig } from 'reports/queryGenerator'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { DateRange, DateRangeName } from 'dateRanges'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { abbreviateNumber } from 'utils'
import { Filters } from 'uiComponents/filter/schema'
import isEqual from 'lodash/isEqual'

const queryConfig: QueryConfig = {
    querySetName: 'SingleDataItems',
    variablesConfig: [
        { name: 'accountSlug', type: 'String' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'comparisonType', type: 'ComparisonTypes' },
        { name: 'delayType', type: 'DelayTypes' },
        { name: 'delay', type: 'Int' },
        { name: 'filters', type: '[FilterDictionary]' },
    ],
    queries: [
        {
            name: 'appUsers',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'dateFrom', 'dateTo', 'comparisonType', 'delayType', 'delay', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'app_users' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'notificationsSent',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'dateFrom', 'dateTo', 'comparisonType', 'delayType', 'delay', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'app_notifications_sent' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'notificationsOpened',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'dateFrom', 'dateTo', 'comparisonType', 'delayType', 'delay', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'app_notifications_opened' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'notificationsClicked',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'dateFrom', 'dateTo', 'comparisonType', 'delayType', 'delay', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'app_notifications_clicked' }],
            presetResult: 'totalsComparison',
        },
    ],
}

const infotipContentMap = {
    appUsers: {
        pageName: 'App users opt-in',
        itemName: 'number of app users opt-in',
        more: 'higher',
        less: 'lower',
        metricText: 'All users who agreed to receive push notifications.',
    },
    notificationsSent: {
        pageName: 'Sent push notifications',
        itemName: 'number of push notifications sent',
        more: 'higher',
        less: 'lower',
        metricText: 'The total number of push notifications sent.',
    },
    notificationsOpened: {
        pageName: 'Opened push notifications',
        itemName: 'number of push notifications opened',
        more: 'higher',
        less: 'lower',
        metricText: 'The total number of push notifications opened.',
    },
    notificationsClicked: {
        pageName: 'Clicked push notifications',
        itemName: 'number of push notifications clicked',
        more: 'higher',
        less: 'lower',
        metricText: 'The total number of push notifications clicked.',
    },
}

interface SingleDataItemsProps {
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    dateRange: DateRange
    filters: Filters[]
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

function SingleDataItems(props: SingleDataItemsProps) {
    const isMounted = useIsMounted()
    const _lastRequest = React.useRef<number>()
    const statsService = React.useContext(StatsServiceContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [timePeriod, setTimePeriod] = React.useState<DateRangeName>('yesterday')
    const [timePeriodLength, setTimePeriodLength] = React.useState<number>(0)
    const [appUsers, setAppUsers] = React.useState<string>('0')
    const [comparisonAppUsers, setComparisonAppUsers] = React.useState<string>('-')
    const [notificationSent, setNotificationSent] = React.useState<string>('0')
    const [comparisonNotificationSent, setComparisonNotificationSent] = React.useState<string>('-')
    const [notificationOpened, setNotificationOpened] = React.useState<string>('0')
    const [comparisonNotificationOpened, setComparisonNotificationOpened] = React.useState<string>('-')
    const [notificationClicked, setNotificationClicked] = React.useState<string>('0')
    const [comparisonNotificationClicked, setComparisonNotificationClicked] = React.useState<string>('-')

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    const prevFilters = usePrevious(props.filters)
    React.useEffect(() => {
        if (
            prevAccountSlug !== props.accountSlug ||
            !areDateRangeDatesEqual(prevDateRange, props.dateRange) ||
            !isEqual(prevFilters, props.filters)
        ) {
            getData()
        }
    }, [props.accountSlug, props.dateRange, props.filters])

    const getData = async () => {
        setLoading(true)
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        const timePeriods = ['today', 'yesterday', 'thisWeek', 'thisMonth']
        const timePeriodForMap = timePeriods.indexOf(props.dateRange.name) > -1 ? props.dateRange.name : 'custom'
        setTimePeriod(timePeriodForMap)
        const dateRangeLength = differenceInCalendarDays(props.dateRange.to, props.dateRange.from) + 1
        setTimePeriodLength(dateRangeLength)
        try {
            const dateFrom = format(props.dateRange.from || new Date(0), 'yyyy-MM-dd')
            const dateTo = format(props.dateRange.to || addDays(startOfToday(), 1), 'yyyy-MM-dd')

            const variables = {
                accountSlug: props.accountSlug,
                dateFrom,
                dateTo,
                comparisonType: 'percentage_growth',
                delayType:
                    timePeriodForMap === 'thisMonth' ? 'months' : dateRangeLength <= 7 ? 'weeks' : 'previous_stretch',
                filters: props.filters,
                delay: 1,
            }
            const data = await statsService.getStats(queryConfig, variables)
            if (!isMounted.current || _lastRequest.current !== requestTime) {
                return
            }
            setAppUsers(data.appUsers.totals?.value || '0')
            setComparisonAppUsers(data.appUsers.totals?.change || '-')

            setNotificationSent(data.notificationsSent.totals?.value || '0')
            setComparisonNotificationSent(data.notificationsSent.totals?.change || '-')

            setNotificationOpened(data.notificationsOpened.totals?.value || '0')
            setComparisonNotificationOpened(data.notificationsOpened.totals?.change || '-')

            setNotificationClicked(data.notificationsClicked.totals?.value || '0')
            setComparisonNotificationClicked(data.notificationsClicked.totals?.change || '-')
            setLoading(false)
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Some data could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }

    const getPlusSign = (change: string) => {
        const value = Number(change.split('%')[0])
        return value > 0 ? '+' : ''
    }

    const formatChangeText = (stats: string, change: string) => {
        const value = Number(change.split('%')[0])
        return value >= 0
            ? `${getPlusSign(change)}${change} ${infotipContentMap[stats].more}`
            : `${change} ${infotipContentMap[stats].less}`
    }

    const formatInfotipText = (stats: string, change: string) => {
        const textPart = timePeriod === 'yesterday' || timePeriod === 'custom' ? 'had' : 'are expected to have'
        return (
            <>
                {comparisonCopyMap[timePeriod].infotipStart} you {textPart}
                &nbsp;{formatChangeText(stats, change)} {infotipContentMap[stats].itemName}
                &nbsp;than the {comparisonCopyMap[timePeriod].infotipEnd}.
            </>
        )
    }

    const getComparisonText = () => {
        return timePeriod === 'thisMonth' ? 'MoM' : timePeriodLength <= 7 ? 'WoW' : 'prev. period'
    }

    return (
        <ChartContainer style={{ marginTop: chartRowsGap, marginBottom: '2em' }}>
            {loading && <ChartDataLoader />}
            <ChartRow>
                <DataNumber
                    title={infotipContentMap['appUsers'].pageName}
                    value={Number(appUsers) >= 10000 ? abbreviateNumber(Number(appUsers), 1) : appUsers}
                    comparisonItem={`${getPlusSign(comparisonAppUsers)}${comparisonAppUsers} ${getComparisonText()}`}
                    comparisonItemColor={getComparisonColorFromPercentString(comparisonAppUsers)}
                    infotipText={formatInfotipText('appUsers', comparisonAppUsers)}
                    metricInfotipText={infotipContentMap['appUsers'].metricText}
                    tooltipType={getComparisonText()}
                    accountSlug={props.accountSlug}
                    compact
                    id="1"
                    nthChildStyle="margin-right: -3.5em"
                />
                <DataNumber
                    title={infotipContentMap['notificationsSent'].pageName}
                    value={
                        Number(notificationSent) >= 10000
                            ? abbreviateNumber(Number(notificationSent), 1)
                            : notificationSent
                    }
                    comparisonItem={`${getPlusSign(
                        comparisonNotificationSent,
                    )}${comparisonNotificationSent} ${getComparisonText()}`}
                    comparisonItemColor={getComparisonColorFromPercentString(comparisonNotificationSent)}
                    infotipText={formatInfotipText('notificationsSent', comparisonNotificationSent)}
                    metricInfotipText={infotipContentMap['notificationsSent'].metricText}
                    tooltipType={getComparisonText()}
                    accountSlug={props.accountSlug}
                    compact
                    id="1"
                    nthChildStyle="margin-right: -3.5em"
                />
                <DataNumber
                    title={infotipContentMap['notificationsOpened'].pageName}
                    value={
                        Number(notificationOpened) >= 10000
                            ? abbreviateNumber(Number(notificationOpened), 1)
                            : notificationOpened
                    }
                    comparisonItem={`${getPlusSign(
                        comparisonNotificationOpened,
                    )}${comparisonNotificationOpened} ${getComparisonText()}`}
                    comparisonItemColor={getComparisonColorFromPercentString(comparisonNotificationOpened)}
                    infotipText={formatInfotipText('notificationsOpened', comparisonNotificationOpened)}
                    metricInfotipText={infotipContentMap['notificationsOpened'].metricText}
                    tooltipType={getComparisonText()}
                    accountSlug={props.accountSlug}
                    compact
                    id="1"
                    nthChildStyle="margin-right: -3.5em"
                />
                <DataNumber
                    title={infotipContentMap['notificationsClicked'].pageName}
                    value={
                        Number(notificationClicked) >= 10000
                            ? abbreviateNumber(Number(notificationClicked), 1)
                            : notificationClicked
                    }
                    comparisonItem={`${getPlusSign(
                        comparisonNotificationClicked,
                    )}${comparisonNotificationClicked} ${getComparisonText()}`}
                    comparisonItemColor={getComparisonColorFromPercentString(comparisonNotificationClicked)}
                    infotipText={formatInfotipText('notificationsClicked', comparisonNotificationClicked)}
                    infotipPosition="right"
                    metricInfotipText={infotipContentMap['notificationsClicked'].metricText}
                    tooltipType={getComparisonText()}
                    accountSlug={props.accountSlug}
                    compact
                    id="1"
                    nthChildStyle="margin-right: -3.5em"
                />
            </ChartRow>
        </ChartContainer>
    )
}

export default withNavigation(SingleDataItems)
