import React from 'react'
import { History } from 'history'
import {
    SignUpFlowBackground,
    SignUpContainer,
    SignUpForm,
    PageHeader,
    NextContainer,
    TermsAgreement,
    ErrorMessageContainer,
} from 'uiComponents/signUpContainer'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { ActionButton } from 'uiComponents/buttons'
import { Checkbox, TextInput } from 'uiComponents/input'
import { PageTopMessage } from 'uiComponents/messages'
import { isValidUrl } from 'utils/formFieldChecks'
import { SignUpService } from 'signUp/signUpService'
import { Profile } from 'settings/schema'
import { State } from 'store'
import { connect } from 'react-redux'

interface AccountDetailsProps {
    history: History
    signUpService: SignUpService
    profile: Profile
}

interface AccountDetailsState {
    errorMessage: string
    errorMessageVisible: boolean
    errorMessageStyle: 'error' | 'warn'
    companyUrl: string
    termsAgreement: boolean
    urlMessage: string
}

class AccountDetailsPage extends React.Component<AccountDetailsProps, AccountDetailsState> {
    constructor(props: AccountDetailsProps) {
        super(props)

        this.state = {
            errorMessage:
                `Hi ${props.profile.firstName}, we couldn’t find your account in our system. ` +
                'Complete the form below and create a new Convious account now!',
            errorMessageVisible: true,
            errorMessageStyle: 'warn',
            termsAgreement: false,
            companyUrl: '',
            urlMessage: '',
        }
    }

    onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault()
        this.setState({ urlMessage: '', errorMessageVisible: false })
        if (!this.validate()) {
            return
        }

        try {
            await this.props.signUpService.setAccountDetails({
                companyName: 'Company name',
                companyUrl: this.state.companyUrl,
                termsAccepted: this.state.termsAgreement,
            })
            this.props.history.push('/')
        } catch (e) {
            this.setState({
                errorMessage: 'Oops! An unknown error occured. Please try again later.',
                errorMessageStyle: 'error',
                errorMessageVisible: true,
            })
        }
    }

    checkValidUrl = () => {
        if (!isValidUrl(this.state.companyUrl)) {
            this.setState({ urlMessage: 'Please enter a valid URL' })
            return false
        } else {
            this.setState({ urlMessage: '' })
        }
        return true
    }

    checkMandatoryFields = () => {
        if (!this.state.termsAgreement) {
            this.setState({
                errorMessage:
                    'Check the box to confirm you agree with our Privacy Policy before creating your account.',
                errorMessageStyle: 'error',
                errorMessageVisible: true,
            })
            return false
        }
        return true
    }

    validate() {
        return this.checkMandatoryFields() && this.checkValidUrl()
    }

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        this.setState({ [event.target.name]: value, showMessage: false } as any)
        if (event.target.name === 'url') {
            sessionStorage.setItem('signUpUrl', event.target.value)
        }
    }

    render() {
        const { companyUrl, termsAgreement, urlMessage } = this.state
        return (
            <SignUpFlowBackground>
                <SignUpContainer>
                    <ErrorMessageContainer>
                        <PageTopMessage
                            showMessage={this.state.errorMessageVisible}
                            kind={this.state.errorMessageStyle}
                            onDismiss={() => this.setState({ errorMessageVisible: false })}
                        >
                            {this.state.errorMessage}
                        </PageTopMessage>
                    </ErrorMessageContainer>
                    <SignUpForm id="account-details-form" noValidate onSubmit={this.onSubmit}>
                        <PageHeader>Create your account</PageHeader>
                        <FormItem htmlFor="url">
                            <FormItemName>Your website</FormItemName>
                            <TextInput
                                id="companyUrl"
                                name="companyUrl"
                                type="text"
                                value={companyUrl || ''}
                                onFinishTyping={this.checkValidUrl}
                                onChange={this.handleInputChange}
                                status={this.state.urlMessage ? 'error' : 'normal'}
                                block={true}
                            />
                        </FormItem>
                        <ValidationMessage className={urlMessage ? 'validation-message-visible' : ''}>
                            {urlMessage || '&nbsp;'}
                        </ValidationMessage>
                        <FormItem htmlFor="termsAgreement" style={{ display: 'inline-block', marginTop: '0' }}>
                            <Checkbox
                                id="termsAgreement"
                                name="termsAgreement"
                                checked={termsAgreement}
                                onChange={this.handleInputChange}
                            />
                            <TermsAgreement>
                                I agree with the Convious
                                <a href="https://www.convious.com/privacy-policy/" target="_blank" rel="noreferrer">
                                    {' '}
                                    Privacy Policy
                                </a>{' '}
                                and
                                <a href="https://www.convious.com/terms-of-service/" target="_blank" rel="noreferrer">
                                    {' '}
                                    Terms of Service
                                </a>
                            </TermsAgreement>
                        </FormItem>
                        <NextContainer>
                            <ActionButton id="create-account-submit" size="large" type="submit" kind="action">
                                Create free account
                            </ActionButton>
                        </NextContainer>
                    </SignUpForm>
                </SignUpContainer>
            </SignUpFlowBackground>
        )
    }
}

function mapStateToProps(state: State) {
    return {
        profile: state.profile.profile,
    }
}

export default connect(mapStateToProps)(AccountDetailsPage)
