import React from 'react'
import { useField } from 'formik'
import FieldWrapper from '../fieldWrapper'
import { useCustomId, useGetComponentState } from '../_shared/hooks'
import './index.css'
import { DatePicker, DatePickerProps } from 'uiComponents/popups/datePickerInput'

interface DatePickerFieldFormikProps
    extends Omit<DatePickerProps, 'onChange' | 'range' | 'navigation' | 'match' | 'theme' | 'date'> {
    name: string
    id?: string
    label?: string
}

const DatePickerFieldFormik: React.FC<DatePickerFieldFormikProps> = ({ name, id, label, ...props }) => {
    const [{ value }, { error }, { setValue }] = useField(name)
    const innerId = useCustomId(id)
    const state = useGetComponentState(name)

    return (
        <FieldWrapper id={innerId} status={state} label={label} error={error}>
            <DatePicker {...props} date={value} onChange={(value: Date) => setValue(value.toISOString())} />
        </FieldWrapper>
    )
}

export default DatePickerFieldFormik
