import React from 'react'
import { Checkbox } from '../../input'

export const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || (defaultRef as any)

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
        <>
            <Checkbox type="checkbox" innerRef={resolvedRef} {...rest} />
        </>
    )
})

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox'

export default IndeterminateCheckbox
