import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-typed'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em 0;
`

const IconWrapper = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.aluminiumShades['5']};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
`

const Icon = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.colors.aluminiumShades['40']};
    font-size: 2.2em;
`

const Title = styled.div`
    font-size: 0.8em;
`

const Description = styled.div`
    font-size: 0.8em;
    color: ${(props) => props.theme.colors.textLight};
    max-width: 200px;
    text-align: center;
    line-height: 1.8em;
    padding-top: 12px;
    font-weight: 300;
`

const ProductNoResults = () => {
    return (
        <Container>
            <IconWrapper>
                <Icon icon={faSearch} />
            </IconWrapper>
            <Title>No results found</Title>
            <Description>There are no existing product that match your search query</Description>
        </Container>
    )
}

export default ProductNoResults
