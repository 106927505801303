import styled from 'styled-typed'
import { transparency } from 'utils/css'
import { InlineDataTable, DynamicRow } from 'uiComponents/table'

interface WrapperProps {
    grade: number
    flat?: boolean
}

export const NestedContainerWrapper = styled.div<WrapperProps>`
    background-color: ${(props) => transparency(props.theme.colors.border, props.grade * 0.25)};
    visibility: hidden;
    position: relative;
    height: 0;
    transition: height 0.2s ease-in, margin-bottom 0s linear 0.2s;

    &.expanded {
        visibility: visible;
        height: auto;
    }
    ${(props) =>
        props.flat
            ? `& > div:nth-child(2n){
      background: ${props.theme.colors.tableRow};
    }`
            : ''}
`

export const NestedTableRow = styled(DynamicRow)`
    height: 2rem;
    &:hover {
        background: ${(props) => props.theme.colors.background};
    }
`

export const NestedTable = styled(InlineDataTable)`
    & > div:nth-child(2n + 1) > ${NestedTableRow} {
        background: ${(props) => transparency(props.theme.colors.tableRow, 0.5)};
        &:hover {
            background: ${(props) => props.theme.colors.background};
        }
    }
    border-bottom: 1px solid ${(props) => transparency(props.theme.colors.border, 0.5)};
`
