import { FC } from 'react'
import * as React from 'react'
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom'

import { BaseRouteParams } from 'hocs'
import ParkMapsContainer from 'venue/parkMap/components/parkMapsContainer'
import TabMapCreate from 'venue/parkMap/components/mapDetails/tabMapCreate'
import TabStyles from 'venue/parkMap/components/mapStyle/index'
import TabMap from 'venue/parkMap/components/mapDetails/tabMapEdit'
import TabPois from 'venue/parkMap/components/categoriesAndPois'
import ParkMapDetailsContainer from 'venue/parkMap/components/parkMapDetailContainer'
import ParkMap from 'venue/parkMap/components'

export interface MapRouteParams {
    id: string
}

const ParkMapRoutes: FC<RouteComponentProps<BaseRouteParams>> = ({ match }) => {
    return (
        <ParkMapsContainer>
            <Switch>
                <Route exact path="/account/:accountSlug/venue/park_map" component={ParkMap} />
                <Route
                    path={[
                        '/account/:accountSlug/venue/park_map/:parkMapId/:tab?',
                        '/account/:accountSlug/venue/park_map/create/:tab?',
                    ]}
                >
                    <ParkMapDetailsContainer>
                        <Switch>
                            <Route
                                exact
                                path={['/account/:accountSlug/venue/park_map/create/details']}
                                component={TabMapCreate}
                            />
                            <Route
                                exact
                                path={['/account/:accountSlug/venue/park_map/:parkMapId/details']}
                                component={TabMap}
                            />
                            <Route
                                path="/account/:accountSlug/venue/park_map/:parkMapId/styles"
                                component={TabStyles}
                            />
                            <Route path="/account/:accountSlug/venue/park_map/:parkMapId/cat_poi" component={TabPois} />
                            <Redirect to={`/account/${match.params.accountSlug}/venue/park_map`} />
                        </Switch>
                    </ParkMapDetailsContainer>
                </Route>
                <Route path="/account/:accountSlug/venue/park_map/delete/:id" component={() => <></>} />
            </Switch>
        </ParkMapsContainer>
    )
}

export default ParkMapRoutes
