import React from 'react'
import { match as RouteMatch } from 'react-router-dom'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { History } from 'history'
import { SsoService } from 'auth/ssoService'
import {
    SignUpFlowBackground,
    SignUpContainer,
    SignUpForm,
    PageHeader,
    NextContainer,
    ErrorMessageContainer,
} from 'uiComponents/signUpContainer'
import { FormItem, FormItemName } from 'uiComponents/form/formElements'
import { ActionButton } from 'uiComponents/buttons'
import { TextInput } from 'uiComponents/input'
import { PageTopMessage } from 'uiComponents/messages'

interface LinkAccountParams {
    username: string
    ssoProvider: string
    state: string
}

interface LinkAccountPageProps {
    history: History
    navigation: Navigation
    match: RouteMatch<LinkAccountParams>
    ssoService: SsoService
    login: (u: string, p: string) => Promise<boolean>
}

interface LinkAccountState {
    errorMessage: string
    errorMessageVisible: boolean
    errorMessageStyle: 'error' | 'warn'
    password: string
}

const providers = {
    linkedin: 'LinkedIn',
    google: 'Google',
}

class LinkAccountPage extends React.Component<LinkAccountPageProps, LinkAccountState> {
    constructor(props: LinkAccountPageProps) {
        super(props)

        this.state = {
            errorMessage: '',
            errorMessageVisible: false,
            errorMessageStyle: 'warn',
            password: '',
        }
    }

    componentDidMount() {
        const { ssoProvider } = this.props.navigation.query()
        this.setState({
            // tslint:disable-next-line:max-line-length
            errorMessage: `An account is already associated with this email. Enter your password so we can connect your ${providers[ssoProvider]} with this account.`,
            errorMessageStyle: 'warn',
            errorMessageVisible: true,
        })
    }

    onAccountLink = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault()
        this.setState({ errorMessageVisible: false })
        try {
            const { state, username } = this.props.navigation.query()
            const success = await this.props.login(username, this.state.password)
            if (success) {
                await this.props.ssoService.linkAccounts(state)
                this.props.history.push('/')
            } else {
                this.setState({
                    errorMessageStyle: 'error',
                    errorMessage: 'The credentials you provided did not match.',
                    errorMessageVisible: true,
                })
            }
        } catch {
            this.setState({
                errorMessageStyle: 'error',
                errorMessage: 'An unknown error has occured. Please try again later',
                errorMessageVisible: true,
            })
        }
    }

    render() {
        return (
            <SignUpFlowBackground>
                <SignUpContainer>
                    <ErrorMessageContainer>
                        <PageTopMessage
                            showMessage={this.state.errorMessageVisible}
                            kind={this.state.errorMessageStyle}
                            onDismiss={() => this.setState({ errorMessageVisible: false })}
                        >
                            {this.state.errorMessage}
                        </PageTopMessage>
                    </ErrorMessageContainer>
                    <SignUpForm noValidate onSubmit={this.onAccountLink}>
                        <PageHeader>Create your account</PageHeader>
                        <FormItem htmlFor="password">
                            <FormItemName>Your Password</FormItemName>
                            <TextInput
                                id="password"
                                name="password"
                                type="password"
                                value={this.state.password}
                                onChange={(e) => this.setState({ password: e.target.value })}
                                block
                            />
                        </FormItem>
                        <NextContainer>
                            <ActionButton
                                id="link-account-submit"
                                size="large"
                                type="submit"
                                kind="action"
                                style={{ width: '18.75em' }}
                            >
                                Log in
                            </ActionButton>
                        </NextContainer>
                    </SignUpForm>
                </SignUpContainer>
            </SignUpFlowBackground>
        )
    }
}

export default withNavigation(LinkAccountPage)
