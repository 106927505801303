import { createSelector, createSlice } from '@reduxjs/toolkit'
import { State } from 'store'
import { isEqual } from 'lodash'
import { endpoints } from './reduxQuery'
import { DiscountCodeProducts } from './bookingCodes/bookingCodesService'

export interface UploadsQueueState {
    type: 'code' | 'upload'
    status: 'pending' | 'fetching' | 'done'
    id: string
    poolId: string
}

export interface PoolUploadRow {
    id: string
    poolId?: string
    name: string
    uploadedBy?: string
    uploadedAt?: string
    usage?: {
        used: number
        total: number
    }
    status: string
    type: 'code' | 'upload'
    products?: DiscountCodeProducts[]
}

interface InitialState {
    listDiscountPoolsLastArgs?: { accountSlug: string; query?: string }
    discountUploadsQueue: Record<string, UploadsQueueState>
    discountUploads: Record<string, Partial<PoolUploadRow>>
    visibleUploads: Record<string, string[]>
}

const initialState: InitialState = {
    listDiscountPoolsLastArgs: undefined,
    discountUploadsQueue: {},
    discountUploads: {},
    visibleUploads: {},
}

const venueSlice = createSlice({
    initialState,
    name: 'venue',
    reducers: {
        addDiscountUploadsQueue(
            state,
            { payload }: { payload: { items: any[]; poolId: string; type: 'upload' | 'code' } },
        ) {
            payload?.items?.forEach((key: string) => {
                if (!state.discountUploadsQueue[key]) {
                    state.discountUploadsQueue[key] = {
                        status: 'pending',
                        type: payload.type,
                        id: key,
                        poolId: payload.poolId,
                    }
                }
            })
        },
        setVisibleData(state, { payload: { poolId, ids } }) {
            if (!isEqual(state.visibleUploads[poolId], ids)) {
                state.visibleUploads[poolId] = ids
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(endpoints.listDiscountPools.matchPending, (state, action) => {
            state.listDiscountPoolsLastArgs = action.meta.arg.originalArgs
        })
        builder.addMatcher(endpoints.listDiscountPools.matchFulfilled, (state, action) => {
            action.payload?.entries.forEach((pool) => {
                pool.uploads?.forEach((upload) => {
                    if (!state.discountUploads[upload.uuid]) {
                        state.discountUploadsQueue[upload.uuid] = {
                            status: 'pending',
                            type: 'upload',
                            id: upload.uuid,
                            poolId: pool.uuid,
                        }

                        state.discountUploads[upload.uuid] = {
                            id: upload.uuid,
                            poolId: pool.uuid,
                            name: upload.name,
                        }
                    }
                })

                pool.codes?.forEach((code) => {
                    if (!state.discountUploads[code.id]) {
                        state.discountUploadsQueue[code.id] = {
                            status: 'pending',
                            type: 'code',
                            id: code.id,
                            poolId: pool.uuid,
                        }

                        state.discountUploads[code.id] = {
                            id: code.id,
                            poolId: pool.uuid,
                            name: code.code,
                        }
                    }
                })
            })
        })
        builder.addMatcher(endpoints.getDiscountUploadInfo.matchPending, (state, action) => {
            const id = action.meta.arg.originalArgs.id

            if (!state.discountUploadsQueue[id]) {
                state.discountUploadsQueue[id] = {} as any
            }
            state.discountUploadsQueue[id].status = 'fetching'
        })
        builder.addMatcher(endpoints.getDiscountUploadInfo.matchFulfilled, (state, action) => {
            const id = action.meta.arg.originalArgs.id

            state.discountUploadsQueue[id].status = 'done'
            if (action.payload?.entry) {
                state.discountUploads[id] = {
                    id: action.payload?.entry?.uuid,
                    name: action.payload?.entry?.name,
                    uploadedBy: action.payload?.entry?.uploadedBy,
                    uploadedAt: action.payload?.entry?.uploadedAt,
                    usage: action.payload?.entry?.usage,
                    status: action.payload?.entry?.status,
                    type: 'upload',
                    products: action.payload?.entry?.products,
                    ...(state.discountUploads[id] || {}),
                }
            }
        })
        builder.addMatcher(endpoints.getDiscountUploadInfo.matchRejected, (state, action) => {
            const id = action.meta.arg.originalArgs.id

            state.discountUploadsQueue[id].status = 'done'
        })
        builder.addMatcher(endpoints.getDiscountCodeDetails.matchFulfilled, (state, action) => {
            const id = action.meta.arg.originalArgs.codeUuid

            state.discountUploadsQueue[id].status = 'done'
            if (action.payload) {
                state.discountUploads[id] = {
                    ...(state.discountUploads[id] || {}),
                    id: action.payload?.id,
                    name: action.payload?.code,
                    uploadedAt: action.payload?.createdAt,
                    usage: action.payload?.usage,
                    status: action.payload?.status,
                    type: 'code',
                    products: action.payload?.products,
                }
            }
        })
    },
})

export default venueSlice.reducer
export const { name } = venueSlice
export const { addDiscountUploadsQueue, setVisibleData } = venueSlice.actions

const emptyObject = {} as any
const getDiscountUploadsQueue = (state: State) => state.venue.discountUploadsQueue
const getDiscountUploads = (state: State) => state.venue.discountUploads || emptyObject
export const getVisibleUploads = (state: State) => state.venue.visibleUploads
export const getVisiblePoolIds = (state: State) => Object.keys(state.venue.visibleUploads)

export const getPendingVisibleDiscountUploads = (poolId: string) =>
    createSelector(getVisibleUploads, getDiscountUploadsQueue, (visibleData, uploads) => {
        return Object.values(uploads).filter((upload) => {
            return upload.status === 'pending' && visibleData[poolId]?.includes(upload.id)
        })
    })

const getListDiscountPoolsLastArgs = (state: State) => state.venue.listDiscountPoolsLastArgs

export const getDiscountPools = (state: State) => {
    const lastArgs = getListDiscountPoolsLastArgs(state)

    if (lastArgs) {
        return endpoints.listDiscountPools.select(lastArgs)(state).data?.entries
    }

    return []
}

export const getDiscountPoolById = (id: string) =>
    createSelector(getDiscountPools, (pools) => {
        if (pools) {
            return pools.find((pool) => pool.uuid === id)
        }

        return undefined
    })

export const getDiscountUpload = (id: string) =>
    createSelector(getDiscountUploads, (uploads) => uploads[id] || emptyObject)
