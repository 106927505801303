import { Audience } from 'crm/schema'
import { BaseRouteParams } from 'hocs'
import { AuthTicket } from 'http/oauthService'
import { AppServices } from 'middleware'
import React, { ReactNode, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import ActionButton from 'uiComponents/buttons'
import { downloadLinkExtendForm } from 'uiComponents/downloadLink/utils'
import { FormWrapper } from 'uiComponents/form/form'
import { CheckboxFieldFormik } from 'uiComponents/input'
import { replaceMessage } from 'uiComponents/messages/actions'

import { ModalDialog, ModalDialogTitle, NavigationSection } from 'uiComponents/popups/modal'
import { Body } from 'uiComponents/typography'

interface AudienceDownloadModalProps {
    children: ({
        setSelectedAudience,
        setOpen,
    }: {
        setSelectedAudience: (audience: Audience) => void
        setOpen: () => void
    }) => ReactNode
}

const filterValues = (key: string, values: { [key: string]: any }) => {
    const isKeyForVisualEffect = key === 'guest'
    const onlyTrueValuesSent = values[key]

    if (isKeyForVisualEffect) {
        return false
    }

    return onlyTrueValuesSent
}

const AudienceDownloadModal: React.FC<AudienceDownloadModalProps> = ({ children }) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const ticket = useSelector<any, AuthTicket>((state) => state.auth.ticket)
    const [open, setOpen] = useState(false)
    const [selectedAudience, setSelectedAudience] = useState<Audience>()
    const dispatch = useDispatch()

    const setMessage = async () => {
        dispatch(
            replaceMessage(
                'export_starting',
                'success',
                'Audience data generation may take up to 30 seconds and will download automatically.',
                30000,
            ),
        )
    }

    const logDownloadInitiated = (endpoint: string) => {
        AppServices.loggingService.logAction('crm_audiences_export_requested', {
            category: 'crm_audiences',
            payload: { endpoint },
        })
    }

    const closeModal = () => {
        setSelectedAudience(undefined)
        setOpen(false)
    }

    return (
        <>
            {!!open && (
                <ModalDialog
                    onDismiss={closeModal}
                    interactive
                    fromTop="20%"
                    style={{
                        width: '508px',
                    }}
                >
                    <FormWrapper
                        enableReinitialize
                        initialValues={{
                            marketingCookies: true,
                            marketingOptIn: true,
                        }}
                        onSubmit={async (values) => {
                            const endpoint = AppServices.crmService.getExportAudienceEndpoint(
                                accountSlug,
                                selectedAudience as Audience,
                            )

                            setMessage()
                            downloadLinkExtendForm({
                                onDownloadInitiated: () => {
                                    closeModal()
                                    logDownloadInitiated(endpoint)
                                },
                                downloadEndpoint: endpoint,
                                loginService: AppServices.loginService,
                                ticket: ticket,
                                values: Object.keys(values)
                                    .filter((key) => filterValues(key, values))
                                    .map((key) => {
                                        return {
                                            element: 'input',
                                            name: key,
                                            value: values[key],
                                        }
                                    }),
                            })
                        }}
                    >
                        <ModalDialogTitle>Apply audience filters</ModalDialogTitle>
                        <Body size={1} style={{ paddingBottom: 24 }}>
                            Please select the filters to apply to “{selectedAudience?.name}” audience before downloading
                            it.
                        </Body>
                        <CheckboxFieldFormik variant="inline" name="marketingCookies" label="Marketing cookies" />
                        <CheckboxFieldFormik variant="inline" name="marketingOptIn" label="Marketing opt-in" />
                        <Body size={3} weight="light" color="light" style={{ paddingTop: 8 }}>
                            Be aware that following the General Data Protection Regulations, audiences used for direct
                            marketing purposes need to consent to receive communication and/or be subject to
                            personalization.
                        </Body>
                        <NavigationSection>
                            <ActionButton secondary size="large" onClick={closeModal}>
                                Cancel
                            </ActionButton>
                            <ActionButton size="large" id="edit-refund-bulk-orders" type="submit">
                                Download
                            </ActionButton>
                        </NavigationSection>
                    </FormWrapper>
                </ModalDialog>
            )}
            {children({ setSelectedAudience, setOpen: () => setOpen(true) })}
        </>
    )
}

export default AudienceDownloadModal
