import { POI } from './models'

export const formatFileName = (filePath: string, maxLength?: number): string => {
    const fileName = filePath.replace(/^.*[\\\/]/, '')

    if (maxLength && fileName.length > maxLength) {
        return `...${fileName.substring(fileName.length - maxLength)}`
    }

    return fileName
}

export const jsonStyleBgColor = (backgroundColor: string) => {
    return [
        { featureType: 'all', elementType: 'all', stylers: [{ color: backgroundColor }] },
        { featureType: 'all', elementType: 'labels', stylers: [{ visibility: 'off' }] },
        {
            featureType: 'administrative',
            elementType: 'all',
            stylers: [{ visibility: 'on' }, { color: backgroundColor }],
        },
        {
            featureType: 'administrative',
            elementType: 'geometry',
            stylers: [{ visibility: 'on' }, { color: backgroundColor }],
        },
        { featureType: 'administrative', elementType: 'labels', stylers: [{ visibility: 'off' }] },
    ] as google.maps.MapTypeStyle[]
}

export const sortPoisByName = () => {
    return (a: POI, b: POI) => {
        const nameA = a.content[0].name.toUpperCase() // ignore upper and lowercase
        const nameB = b.content[0].name.toUpperCase() // ignore upper and lowercase

        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }

        return 0
    }
}

export const shortenText = (text: string, numberOfSentences: number) => {
    const arrayOfSentences = text.replace(/([.?!])\s*(?=[A-Z])/g, '$1|').split('|')

    if (arrayOfSentences.length > numberOfSentences) {
        let ret = ''
        arrayOfSentences.forEach((sentence, index) => {
            if (index < numberOfSentences) {
                ret += `${sentence} `
            }

            if (index === numberOfSentences) {
                ret += `${sentence}..`
            }
        })
        return ret
    }

    return text
}
