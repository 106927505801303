import { BaseRouteParams } from 'hocs'
import { history } from 'middleware'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import ActionButton from 'uiComponents/buttons'
import { Col, Row } from 'uiComponents/flex'
import { FormWrapper } from 'uiComponents/form/form'
import TextInputFieldFormik from 'uiComponents/input/textInput/textInputFieldFormik'
import { ModalDialogTitle } from 'uiComponents/popups/modal'
import { pathUtils } from 'utils/pathnameFormatter'
import { DiscountPool } from 'venue/bookingCodes/bookingCodesService'
import { CODES_PATHS } from 'venue/bookingCodes/paths'
import { useAddPoolMutation } from 'venue/reduxQuery'

interface AddPoolFormProps {
    onPoolAdd?: (pool: DiscountPool) => void
}

const AddPoolForm: React.FC<AddPoolFormProps> = ({ onPoolAdd }) => {
    const { search } = useLocation()
    const { accountSlug } = useParams<BaseRouteParams>()
    const [addPool, { isLoading }] = useAddPoolMutation()

    const closePool = () => {
        history.push(
            pathUtils.populateParams(pathUtils.addQueryString(CODES_PATHS.fullPaths.poolsPage, search), {
                accountSlug,
            }),
        )
    }

    return (
        <>
            <ModalDialogTitle>Add Pool</ModalDialogTitle>
            <FormWrapper
                initialValues={{}}
                onSubmit={(values) => {
                    addPool({ accountSlug, name: values.name })
                        .unwrap()
                        .then((pool) => {
                            if (pool && onPoolAdd) {
                                onPoolAdd(pool)
                            }
                        })
                }}
            >
                <TextInputFieldFormik name="name" label="Pool name" required />
                <Row align="flex-end" style={{ paddingTop: '32px' }}>
                    <Col span="auto">
                        <ActionButton size="large" secondary onClick={closePool}>
                            Cancel
                        </ActionButton>
                    </Col>
                    <Col span="auto">
                        <ActionButton disabled={isLoading} size="large" type="submit">
                            Add new pool
                        </ActionButton>
                    </Col>
                </Row>
            </FormWrapper>
        </>
    )
}

export default AddPoolForm
