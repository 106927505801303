import * as React from 'react'
import styled from 'styled-typed'
import { History } from 'history'
import {
    FormContainer,
    FormContent,
    SidebarMenu,
    Form,
    FormBody,
    ScrollArea,
    Menu,
    SidebarTitle,
} from 'uiComponents/studio/form/formComponents'
import MenuItem, { MenuItem as MenuSideItem } from 'uiComponents/studio/menuItem'
import Section from './section'
import { MessageKind } from 'uiComponents/messages'
import { Footer } from 'uiComponents/studio/form/footer'
import { PageLoader } from 'uiComponents/loaders'
import StudioConviousLogo from 'uiComponents/studio/studioLogo'
import { FormDefinitionSchema } from './configurationOptions'
import { MenuStepType } from './types'
import { withFeatures } from 'features'

const Header = styled.div`
    font-size: 1.25em;
    padding: 1.2em;
    span {
        font-size: 0.6em;
        display: inline-block;
        margin-top: 0.8em;
    }
`

interface StudioFormProps {
    configuration: FormDefinitionSchema
    currentStep: 'action' | 'type' | 'scheduling'
    history: History
    accountSlug: string
    loading: boolean
    setCurrentStep: (step: MenuStepType) => void
    onPublish: () => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    hasFeature: (feature: string, accountSlug: string) => boolean
}

const StudioForm = (props: StudioFormProps) => {
    const MENU_ITEMS = React.useMemo(() => {
        const menuItems: MenuSideItem[] = []
        const keys = Object.keys(props.configuration)
        let menuIndex = 1
        keys.map((key) => {
            const config = props.configuration[key]

            if (props.hasFeature(config.permission, props.accountSlug)) {
                const menuItem = {
                    name: config.name,
                    label: `${menuIndex}. ${config.label}`,
                    icon: config.icon,
                }
                menuItems.push(menuItem)
                menuIndex += 1
            }
        })

        return menuItems
    }, [props.configuration])

    const renderMenuItems = () => {
        return (
            <nav>
                <Menu>
                    {MENU_ITEMS.map((item, index) => {
                        return (
                            <MenuItem
                                key={`${index}`}
                                product=""
                                accountSlug={props.accountSlug}
                                item={item}
                                highlighted={props.currentStep === item.name}
                                history={props.history}
                                onClick={props.setCurrentStep}
                                configurationId=""
                            />
                        )
                    })}
                </Menu>
            </nav>
        )
    }

    return (
        <FormContainer>
            <FormContent>
                <SidebarMenu>
                    <StudioConviousLogo />
                    <SidebarTitle>PROMOTION</SidebarTitle>
                    {renderMenuItems()}
                </SidebarMenu>
                <Form noValidate>
                    <FormBody>
                        {props.loading && <PageLoader />}
                        {!props.loading && (
                            <>
                                <Header>
                                    Edit your promotion
                                    <span>Compose and edit your Promotion.</span>
                                </Header>
                                <ScrollArea id="scroll-area">
                                    <Section section={props.configuration[props.currentStep]} />
                                </ScrollArea>
                            </>
                        )}
                    </FormBody>
                </Form>
            </FormContent>
            <Footer
                accountSlug={props.accountSlug}
                menuItems={MENU_ITEMS.map((item) => item.name)}
                formSubmit={props.onPublish}
                history={props.history}
                navPathOverride={`/account/${props.accountSlug}/engage/tools/promotions`}
                ongoingPublish={false}
                currentStepOverride={props.currentStep}
                currentStep=""
                product=""
                configurationId=""
                componentValues={null}
            />
        </FormContainer>
    )
}

export default withFeatures(StudioForm)
