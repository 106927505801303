import * as React from 'react'
import styled from 'styled-components'
import { Headline } from '../../uiComponents/typography'

const Text = styled.div`
    font-size: 0.85em;
    line-height: 1.5em;
    font-weight: 400;
    color: ${(props) => props.theme.colors.textLight};
    margin-bottom: 2.4em;
    margin-top: -1em;
    max-width: 600px;
`

const Header = () => {
    return (
        <div>
            <Headline size={2}>Push notification Campaigns</Headline>
            <Text>
                Create and manage your campaigns to reach out to your customers with customised messages. Follow
                campaigns performance data.
            </Text>
        </div>
    )
}

export default Header
