import { SingleSelectOption } from './singleSelect'

export const findFirstAvailableOption = (
    options: SingleSelectOption[],
    selectedOption?: SingleSelectOption,
): SingleSelectOption | undefined => {
    const filteredOptions = options.filter((option) => option.parentId === (selectedOption?.value ?? undefined))

    if (filteredOptions.length === 0 && !!selectedOption && !selectedOption.disabled) {
        return selectedOption
    }

    for (const option of filteredOptions) {
        const result = findFirstAvailableOption(options, option)
        if (result) {
            return result
        }
    }

    return undefined
}
