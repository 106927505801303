import { useCallback } from 'react'
import { AppServices } from '../../middleware'
import { useCreateAppMutation } from '../reduxQuery'
import { PNApp } from '../types'

const usePNApps = (accountSlug: string) => {
    const [createApp] = useCreateAppMutation()

    const checkPNApp = useCallback(async () => {
        const newApp: PNApp = {
            slug: accountSlug,
            name: accountSlug,
        }

        try {
            const response = await AppServices.angeliaNotificationsService.getApp(accountSlug)

            if (!response.data) {
                await createPNApp(newApp)
            }
        } catch {
            await createPNApp(newApp)
        }
    }, [accountSlug])

    const createPNApp = useCallback(
        async (app: PNApp) => {
            try {
                const response: any = await createApp({ app })

                return response.data || {}
            } catch (e) {
                console.error(e)
            }

            return
        },
        [createApp],
    )

    return {
        checkPNApp,
    }
}

export default usePNApps
