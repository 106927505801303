export interface MapSnapshotQueryParams {
    center: {
        lat: number
        lng: number
    }
    format?: 'png' | 'png32' | 'gif' | 'jpg' | 'jpg-baseline'
    zoom: number
    size: {
        width: number
        height: number
    }
    scale?: number
    mapType?: string
}

export class GoogleMapsService {
    constructor(private googleMapsApiEndpoint: string, private googleMapsApiKey: string) {}

    async getMapSnapshotImg({
        center,
        format = 'png',
        zoom,
        size,
        scale = 1,
        mapType = 'satellite',
    }: MapSnapshotQueryParams): Promise<Response> {
        let response = await fetch(
            `${this.googleMapsApiEndpoint}maps/api/staticmap?center=${center.lat},${center.lng}&format=${format}&zoom=${zoom}&size=${size.width}x${size.height}&key=${this.googleMapsApiKey}&scale=${scale}&maptype=${mapType}`,
        )
        return response
    }
}
