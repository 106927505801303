import * as React from 'react'
import { connect } from 'react-redux'
import { State } from 'store'
import { Account } from 'auth/state'
import { Col } from 'uiComponents/flex'
import Infotip from 'uiComponents/infotip'
import { MultiSelectList } from 'uiComponents/input/multiselectList'
import { SelectComponent } from 'uiComponents/studio/form/fieldComponents'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { transformLanguagesIntoOptions, getAvailableLanguages } from '../languages'
import { languagesDict } from 'utils/languages'
import {
    WonderbarConfiguration,
    Languages,
    Translation,
    OnChangeFunc,
    GetConfigValueFunc,
} from 'engageTools/studio/schema'
import { ErrorMessage } from 'uiComponents/studio/form/errorMessage'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { ConfigurationUnit } from 'uiComponents/studio/menuItem'
import CopySection from './copySection'

interface LanguagesSectionProps {
    accounts: Account[]
    accountSlug: string
    section: ConfigurationUnit
    configuration: WonderbarConfiguration
    languages: Languages
    translations: Translation[]
    getConfigValue: GetConfigValueFunc
    updateConfiguration: (path: string, value: string | number | boolean | string[]) => void
    updateLanguages: (selected: string[], primary: string) => void
    updateTranslations: (slug: string, language: string, value: string) => void
    resetATranslation: (slug: string) => void
    handleInputChange: OnChangeFunc
    setActiveLanguage: (v: string) => void
    activeLanguage: string
    translationsValidation: boolean
    setTranslationsValidation: (on: boolean) => void
}

interface LanguagesSectionState {
    buttonTextValid: boolean
    barTextValid: boolean
    barUrlValid: boolean
    defaultLanguage: string
    availableLanguages: string[]
}

export class LanguagesSection extends React.Component<LanguagesSectionProps, LanguagesSectionState> {
    constructor(props: LanguagesSectionProps) {
        super(props)
        this.state = {
            buttonTextValid: true,
            barTextValid: true,
            barUrlValid: true,
            defaultLanguage: this.getDefaultLanguage(),
            availableLanguages: getAvailableLanguages(this.props.languages.selected, 'wonderbar'),
        }
    }

    getDefaultLanguage = (): string => {
        const available = getAvailableLanguages(this.props.languages.selected, 'wonderbar')
        if (available.indexOf(this.props.languages.primary) < 0) {
            return available.length > 0 ? available[0] : 'en'
        } else {
            return this.props.languages.primary
        }
    }

    componentDidMount() {
        const { activeLanguage, languages } = this.props
        const { availableLanguages } = this.state

        if (availableLanguages.indexOf(activeLanguage) < 0) {
            const firstAvailableLanguage = availableLanguages[0]
            this.props.setActiveLanguage(firstAvailableLanguage)

            if (availableLanguages.indexOf(languages.primary) < 0) {
                this.setState({ defaultLanguage: firstAvailableLanguage })
            }
        }
    }

    updateDefaultLanguage = (v: string) => {
        this.props.updateLanguages(this.props.languages.selected, v)
        this.setState({ defaultLanguage: v })
    }

    updateLanguages = (languages: string[]) => {
        const primary =
            languages.indexOf(this.state.defaultLanguage) < 0
                ? languages.length > 0
                    ? languages[0]
                    : 'en'
                : this.state.defaultLanguage
        this.props.updateLanguages(languages, primary)
        this.props.setActiveLanguage('')
    }

    render() {
        const { section, accounts, accountSlug, handleInputChange, languages, getConfigValue } = this.props
        type SelectType = 'select'
        const selectedLanguagesAsOptions = transformLanguagesIntoOptions(this.props.languages.selected)
        const defaultLanguageField = {
            type: 'select' as SelectType,
            name: 'default-language',
            label: 'default language',
            path: 'defaultLanguage',
            defaultValue: 'en',
            availableOptions: selectedLanguagesAsOptions,
            infotip: 'The main language used on your website.',
            span: 6,
        }

        const activeAccount = accounts.find((x) => x.slug === accountSlug)
        const accountIsPartner = activeAccount ? activeAccount.accountType === 'partner' : false
        return (
            <>
                <SectionWrapper title={section.name}>
                    {!accountIsPartner && (
                        <FormItem>
                            <Col span={12}>
                                <MultiSelectList
                                    values={languages.selected}
                                    onChange={this.updateLanguages}
                                    options={languagesDict}
                                    status={languages.selected.length > 0 ? 'normal' : 'error'}
                                />
                                {languages.selected.length < 1 && <ErrorMessage errType="minOne" />}
                            </Col>
                        </FormItem>
                    )}
                    <FormItem>
                        <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                            <label
                                htmlFor={defaultLanguageField.name}
                                style={{ display: 'flex', position: 'relative' }}
                            >
                                Default language
                                <Infotip pointer="left" maxWidth="17em" smallBoxText>
                                    {defaultLanguageField.infotip}
                                </Infotip>
                            </label>
                        </Col>
                        <Col span={6}>
                            <SelectComponent
                                field={defaultLanguageField}
                                handleChange={this.updateDefaultLanguage}
                                value={this.state.defaultLanguage}
                            />
                        </Col>
                    </FormItem>
                </SectionWrapper>
                <SectionWrapper title="Add your text">
                    <CopySection
                        activeLanguage={this.props.activeLanguage}
                        setActiveLanguage={this.props.setActiveLanguage}
                        availableLanguages={this.state.availableLanguages}
                        handleChange={handleInputChange}
                        getConfigValue={(item) => getConfigValue(item, this.props.configuration)}
                        wonderbarConfiguration={this.props.configuration}
                        wonderbarLanguages={this.props.languages}
                        wonderbarTranslations={this.props.translations}
                        updateConfiguration={this.props.updateConfiguration}
                        updateTranslations={this.props.updateTranslations}
                        resetATranslation={this.props.resetATranslation}
                        translationsValidation={this.props.translationsValidation}
                        setTranslationsValidation={this.props.setTranslationsValidation}
                    />
                </SectionWrapper>
            </>
        )
    }
}

function mapStateToProps(state: State) {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
    }
}

export default connect(mapStateToProps)(LanguagesSection)
