import * as React from 'react'
import { useAccountChange } from 'reactUtils'
import { History } from 'history'
import { MessageKind } from 'uiComponents/messages'
import { BookingCodesService, UploadDetails } from 'venue/bookingCodes/bookingCodesService'
import UploadPage from 'venue/bookingCodes/uploadPage'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { NavigationBack } from 'uiComponents/navigation/navigationBack'
import { LoginService } from 'http/loginService'

interface UploadBarcodesPageProps {
    accountSlug: string
    bookingCodesService: BookingCodesService
    navigation: Navigation
    match: RouteMatch<{ poolId: string }>
    history: History
    backofficeEndpoint: string
    loginService: LoginService
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

function UploadBarcodesPage(props: UploadBarcodesPageProps) {
    const busyRef = React.useRef(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [uploadsHistoryList, setUploadsHistoryList] = React.useState<UploadDetails[]>([])

    async function getData() {
        const data = await props.bookingCodesService.getBarcodesUploadHistoryList(
            props.accountSlug,
            props.match.params.poolId,
        )
        setUploadsHistoryList(data)
    }

    async function getUploadHistoryList() {
        setLoading(true)
        busyRef.current = true
        try {
            await getData()
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            setLoading(false)
            busyRef.current = false
        }
    }

    async function refreshHistoryList() {
        try {
            await getData()
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please reload the page.')
        }
    }

    React.useEffect(() => {
        getUploadHistoryList()
        const interval = setInterval(() => {
            if (!busyRef.current) {
                refreshHistoryList()
            }
        }, 30000)
        return () => clearInterval(interval)
    }, [])

    useAccountChange(props.accountSlug, () => navigateBack())

    const onUpload = async (file: File) => {
        busyRef.current = true
        return await props.bookingCodesService.uploadBarcodes(file, props.accountSlug, props.match.params.poolId)
    }

    const reloadUploadsHistoryList = async () => {
        await getUploadHistoryList()
    }

    const getCsvDownloadHref = () => {
        const csvRows = ['1234567890', '9876543210', '6549873210', '4789632156']
        const csv = csvRows.join('\n')
        return 'data:text/csv;charset=utf-8,' + encodeURI(csv)
    }

    const navigateBack = () => {
        props.history.push(`/account/${props.accountSlug}/venue/codes/tickets/list/?${location.search}`)
    }

    return (
        <>
            <NavigationBack onClick={navigateBack} text="Back to barcode pools list" topOffset="-4.7em" />
            <UploadPage
                accountSlug={props.accountSlug}
                uploadsHistoryList={uploadsHistoryList}
                bookingCodesService={props.bookingCodesService}
                loginService={props.loginService}
                backofficeEndpoint={props.backofficeEndpoint}
                uploadType="barcodes"
                exampleCSVHref={getCsvDownloadHref()}
                exampleFileName="barcodes_upload_example"
                loading={loading}
                onUpload={onUpload}
                reloadUploadsHistoryList={reloadUploadsHistoryList}
                onUploadError={() => (busyRef.current = false)}
                replaceMessages={props.replaceMessages}
                removeAllMessages={props.removeAllMessages}
            />
        </>
    )
}

export default withNavigation(UploadBarcodesPage)
