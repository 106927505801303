import * as React from 'react'
import { connect } from 'react-redux'
import { match as RouteMatch } from 'react-router-dom'
import { User } from 'auth/state'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { State } from 'store'
import { parseSearch } from 'navigation'
import { withFeatures } from 'features'
import { withMessages, MessageProps, withCurrentUser } from 'hocs'
import { Messages } from 'uiComponents/messages'
import { Profile } from 'settings/schema'
import { SnippetService } from 'snippet/snippetService'
import { LoggingService, EventType } from 'http/loggingService'
import { LocaleService } from 'engageTools/studio/localesService'
import { ImagesService } from 'http/imagesService'
import { productURLSlugs, getProductSlug } from 'engageTools/studio/shared'
import { MobileDeviceWarning } from 'uiComponents/studio/mobileDeviceWarning'
import {
    WonderbarConfiguration,
    ModalWindowConfiguration,
    Languages,
    StudioStep,
    TriggerButtonConfiguration,
    CheckoutConfiguration,
    UrlRules,
    Translation,
    ProductName,
    TargetType,
    ProductConfig,
} from 'engageTools/studio/schema'
import { ComponentsService, ConfigurationVersions } from 'engageTools/studio/componentsService'
import Form from 'engageTools/studio/form'
import Preview from 'engageTools/studio/preview/preview'
import { PreviewSize } from 'uiComponents/studio/preview/components'
import Header from 'uiComponents/studio/header'
import { Page, Side, Body } from 'uiComponents/studio/pageComponents'
import './index.css'
import AudienceConfirmation from './audienceConfirmation'

export type MenuItem = 'style' | 'text' | 'targeting' | 'settings'

const menuItemsOld: MenuItem[] = ['text', 'style', 'targeting', 'settings']

const menuItems: MenuItem[] = ['style', 'text', 'settings', 'targeting']

export interface StudioParams {
    step: StudioStep
    id: string
}

interface StudioProps {
    product: ProductName
    accountSlug: string
    previewEndpoint: string
    localeService: LocaleService
    snippetService: SnippetService
    profile: Profile
    match: RouteMatch<StudioParams>
    componentsService: ComponentsService
    loggingService: LoggingService
    imagesService: ImagesService
    user: User
    hasFeature: (feature: string, accountSlug: string) => boolean
}

interface StudioState {
    previewSize: PreviewSize
    targetDevice: TargetType
    snippetDetected: boolean
    formLoading: boolean
    translationsLoading: boolean
    componentValues:
        | WonderbarConfiguration
        | ModalWindowConfiguration
        | TriggerButtonConfiguration
        | CheckoutConfiguration
        | null
    translations: Translation[]
    languages: Languages | null
    productConfig: ProductConfig | null
    urlRules: UrlRules | null
    draftId: string
    triggerPublish: boolean
    triggerSaveDraft: boolean
    currentStep: StudioStep
    activeLanguage: string
    currentConfig: ConfigurationVersions | null
}

class Studio extends React.Component<StudioProps & RouteComponentProps<{}> & MessageProps, StudioState> {
    private timeoutHandler: any = null
    productSlug = getProductSlug(this.props.product)

    constructor(props: StudioProps & RouteComponentProps<{}> & MessageProps) {
        super(props)

        const search = location.search
        this.state = {
            previewSize: 'desktop',
            snippetDetected: false,
            formLoading: false,
            translationsLoading: false,
            targetDevice: !!parseSearch(search).device ? (parseSearch(search).device as TargetType) : 'any',
            componentValues: null,
            translations: [],
            productConfig: null,
            languages: null,
            urlRules: null,
            draftId: '',
            triggerPublish: false,
            triggerSaveDraft: false,
            currentStep: 'style',
            activeLanguage: '',
            currentConfig: null,
        }
    }

    async componentDidMount() {
        await this.detectSnippet()
        this.logEvent('studio_opened')
        const currentStep = this.props.match.params.step
        const step = this.props.product === 'checkout' ? 'style' : this.props.match.params.step || 'style'
        if (step !== 'success') {
            this.setState({ currentStep })
        }
        this.setState({
            previewSize: this.state.targetDevice === 'mobile' ? 'mobile' : 'desktop',
            currentStep: step,
        })
    }

    componentWillUnmount() {
        if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler)
        }
    }

    componentDidUpdate(prevProps: StudioProps & RouteComponentProps<{}> & MessageProps) {
        if (prevProps.location !== this.props.location) {
            const currentStep = this.props.match.params.step
            if (currentStep !== 'success') {
                this.setState({ currentStep })
                this.logEvent('studio_step_accessed', { step_name: currentStep })
            }
        }
    }

    logEvent = (eventType: EventType, additionalData?: { [key: string]: string }) => {
        const eventData = {
            category: `engage_tools_${this.props.product}`,
            product: this.props.product,
        }
        if (additionalData) {
            this.props.loggingService.logAction(eventType, Object.assign(eventData, additionalData))
        } else {
            this.props.loggingService.logAction(eventType, eventData)
        }
    }

    detectSnippet = async () => {
        try {
            await this.props.snippetService.detectSnippet(this.props.accountSlug)
            this.setState({ snippetDetected: true })
        } catch {}
    }

    onLoadToggle = (on: boolean) => {
        this.setState({ formLoading: on })
    }

    onPreviewTranslationsLoadToggle = (on: boolean) => {
        this.setState({ translationsLoading: on })
    }

    dismissErrorMessage = (id: string) => {
        this.props.hideMessage(id)
    }

    onConfirmNavigation = () => {
        this.logEvent('studio_closed')
    }

    render() {
        const { product, accountSlug, componentsService, imagesService } = this.props
        return (
            <>
                <Page
                    className="studio"
                    id={`${product}-wizard-page`}
                    style={{ position: 'relative', justifyContent: 'center' }}
                >
                    <Messages messages={this.props.messages} hideMessage={this.dismissErrorMessage} />
                    <MobileDeviceWarning
                        history={this.props.history}
                        returnPath={`/account/${accountSlug}/${productURLSlugs[product]}/summary`}
                    />
                    <Side>
                        <AudienceConfirmation>
                            {({ handleSubmit }) => {
                                return (
                                    <Form
                                        accountSlug={accountSlug}
                                        hasFeature={this.props.hasFeature}
                                        menuItems={this.props.product === 'modal_window' ? menuItems : menuItemsOld}
                                        snippetDetected={this.state.snippetDetected}
                                        componentsService={componentsService}
                                        imagesService={imagesService}
                                        replaceTopMessages={this.props.replaceMessages}
                                        removeAllMessages={this.props.removeAllMessages}
                                        loading={this.state.formLoading}
                                        translationsLoading={this.state.translationsLoading}
                                        translations={this.state.translations}
                                        targetDevice={this.state.targetDevice}
                                        configurationId={this.props.match.params.id}
                                        product={this.props.product}
                                        onProductConfigUpdate={(values) => this.setState({ productConfig: values })}
                                        onComponentValuesUpdate={(values) => this.setState({ componentValues: values })}
                                        onLanguagesUpdate={(values) => this.setState({ languages: values })}
                                        onTranslationsUpdate={(values) => this.setState({ translations: values })}
                                        onUrlRulesUpdate={(values) => this.setState({ urlRules: values })}
                                        setDraftId={(id) => this.setState({ draftId: id })}
                                        publish={this.state.triggerPublish}
                                        handleSubmit={handleSubmit}
                                        saveDraft={this.state.triggerSaveDraft}
                                        logEvent={this.logEvent}
                                        currentStep={this.state.currentStep}
                                        activeLanguage={this.state.activeLanguage}
                                        setActiveLanguage={(v) => this.setState({ activeLanguage: v })}
                                        setCurrentProductConfiguration={(v) => this.setState({ currentConfig: v })}
                                    />
                                )
                            }}
                        </AudienceConfirmation>
                    </Side>
                    <Body>
                        <Header
                            accountSlug={accountSlug}
                            previewSize={this.state.previewSize}
                            onPreviewSizeChange={(previewSize: PreviewSize) => this.setState({ previewSize })}
                            targetDevice={this.state.targetDevice}
                            replaceMessages={this.props.replaceMessages}
                            removeAllMessages={this.props.removeAllMessages}
                            studioType="engageTools"
                            onPublish={() =>
                                this.setState({ triggerPublish: true }, () => {
                                    this.timeoutHandler = setTimeout(() => {
                                        this.setState({ triggerPublish: false })
                                    }, 1000)
                                })
                            }
                            onSaveDraft={() =>
                                this.setState({ triggerSaveDraft: true }, () => {
                                    this.timeoutHandler = setTimeout(() => {
                                        this.setState({ triggerSaveDraft: false })
                                    }, 1000)
                                })
                            }
                            configIsPublished={
                                (this.state.currentConfig?.current?.publishedAt &&
                                    !this.state.currentConfig?.current?.unpublishedAt) ||
                                false
                            }
                        />
                        <Preview
                            product={this.props.product}
                            accountSlug={accountSlug}
                            previewSize={this.state.previewSize}
                            previewEndpoint={this.props.previewEndpoint}
                            localeService={this.props.localeService}
                            toggleLoad={this.onPreviewTranslationsLoadToggle}
                            componentValues={this.state.componentValues}
                            languages={this.state.languages}
                            translations={this.state.translations}
                            urlRules={this.state.urlRules}
                            draftId={this.state.draftId}
                            handleInitialTranslations={(t: Translation[]) => this.setState({ translations: t })}
                            activeLanguage={this.state.activeLanguage}
                        />
                    </Body>
                </Page>
            </>
        )
    }
}

const mapStateToProps = (state: State) => {
    return {
        profile: state.profile.profile,
    }
}

export default withFeatures(withCurrentUser(withMessages(withRouter(connect(mapStateToProps)(Studio)))))
