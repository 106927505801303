import React, { useState, useEffect } from 'react'
import { match as RouteMatch } from 'react-router-dom'
import { Switch, Route } from 'react-router-dom'
import ProductsListPage from 'products/productList'
import FlatProductsListPage from 'products/flatProductList'
import ProductForm from 'products/crud/form'
import CategoriesForm from 'products/crud/categories/form'
import { Account } from 'auth/state'
import { iterateProductLists, ProductListWithPath } from 'products/crud/common'
import { useAccountChange } from 'reactUtils'
import { ArticleService, ProductList, ProductListMinimum } from 'admin/articleService'
import { TicketTemplatesService } from 'ticketTemplates/ticketTemplatesService'
import { PricingService } from 'products/pricing/pricingService'
import { OptionsService } from 'products/options/optionsService'
import { History, Location } from 'history'
import { withNavigation } from 'hocs'
import { withRoutePermission } from 'routesUtils'
import { FullWidthContainerBody } from 'uiComponents/settingsContainer'
import { Navigation } from 'navigation'
import { MessageKind, MessageButton } from 'uiComponents/messages'

interface ProductListPageParams {
    accountSlug: string
}

interface RoutePermissionData {
    accounts: Account[]
    accountSlug: string
    history: History
    hasPermission: (permission: string, accountSlug: string) => boolean
}

interface ProductListPageProps {
    accountSlug: string
    location: Location
    history: History
    articleService: ArticleService
    pricingService: PricingService
    optionsService: OptionsService
    match: RouteMatch<ProductListPageParams>
    navigation: Navigation
    setActiveSection: (section: string, header?: string) => void
    hasPermission: (permission: string, accountSlug: string) => boolean
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    addMessage: (id: string, status: MessageKind, text: string, actionButton?: MessageButton) => void
    hasFeature: (feature: string, accountSlug: string) => boolean
    removeAllMessages: () => void
    hideMessage: (id: string) => void
    routePermissionCheckData: RoutePermissionData
    viewMode: 'flat' | 'nested' | ''
    ticketTemplatesService: TicketTemplatesService
    setShowBulkButtons: (v: boolean) => void
    bulkEditPricing: boolean
    bulkEditValidity: boolean
    cancelBulkEditPricing: () => void
    cancelBulkEditValidity: () => void
    setLoading: (v: boolean) => void
}

function HomePage(props: ProductListPageProps) {
    const isNestedView = location.pathname.includes('products/home/nested')
    const [flattenedCategories, setFlattenedCategories] = useState<ProductListWithPath[] | null>(null)
    const [accountCategories, setAccountCategories] = useState<ProductList[]>([])
    const [firstLevelCategories, setFirstLevelCategories] = useState<ProductListMinimum[] | null>(null)

    useEffect(() => {
        if (isNestedView) {
            getCategories()
        }
    }, [location.pathname])

    useAccountChange(props.accountSlug, () => getCategories())

    const getCategories = async () => {
        props.setLoading(true)
        try {
            if (location.pathname.includes('nested')) {
                const firstLevelCats = await props.articleService.getProductListMinimalDetails(props.accountSlug)
                setFirstLevelCategories(firstLevelCats)
                setFlattenedCategories(null)
                setAccountCategories([])
            } else {
                const accountCats = await props.articleService.getProductLists(props.accountSlug)
                const flattenedCats = iterateProductLists(accountCategories, '', [])
                setFlattenedCategories(flattenedCats)
                setAccountCategories(accountCats)
            }
            props.setLoading(false)
        } catch {
            props.setLoading(true)
        }
    }

    return (
        <Switch location={props.location}>
            <Route
                path="/account/:accountSlug/products/home/flat"
                render={() => (
                    <FullWidthContainerBody>
                        <FlatProductsListPage
                            pricingService={props.pricingService}
                            articleService={props.articleService}
                            history={props.history}
                            setActiveSection={props.setActiveSection}
                            replaceTopMessages={props.replaceTopMessages}
                            hasPermission={props.hasPermission}
                            hasFeature={props.hasFeature}
                            addMessage={props.addMessage}
                            removeAllMessages={props.removeAllMessages}
                            hideMessage={props.hideMessage}
                            flattenedCategories={flattenedCategories}
                            accountCategories={accountCategories}
                            showBulkEditButtons={(v: boolean) => props.setShowBulkButtons(v)}
                            bulkEditPricing={props.bulkEditPricing}
                            bulkEditValidity={props.bulkEditValidity}
                            cancelBulkEditPricing={props.cancelBulkEditPricing}
                            cancelBulkEditValidity={props.cancelBulkEditValidity}
                        />
                    </FullWidthContainerBody>
                )}
            />
            <Route
                path="/account/:accountSlug/products/home/nested"
                render={() => (
                    <FullWidthContainerBody>
                        {firstLevelCategories && (
                            <ProductsListPage
                                pricingService={props.pricingService}
                                articleService={props.articleService}
                                history={props.history}
                                setActiveSection={props.setActiveSection}
                                replaceTopMessages={props.replaceTopMessages}
                                hasPermission={props.hasPermission}
                                hasFeature={props.hasFeature}
                                addMessage={props.addMessage}
                                removeAllMessages={props.removeAllMessages}
                                hideMessage={props.hideMessage}
                                accountCategories={accountCategories}
                                firstLevelCategories={firstLevelCategories}
                                setLoading={() => props.setLoading(true)}
                            />
                        )}
                    </FullWidthContainerBody>
                )}
            />
            <Route
                path="/account/:accountSlug/products/crud/productList/:id"
                render={() =>
                    withRoutePermission({
                        ...props.routePermissionCheckData,
                        permissions: ['partner_admin'],
                        component: (
                            <CategoriesForm
                                articleService={props.articleService}
                                history={props.history}
                                setActiveSection={props.setActiveSection}
                                replaceMessages={props.replaceTopMessages}
                                removeAllMessages={props.removeAllMessages}
                                viewMode={props.viewMode}
                            />
                        ),
                    })
                }
            />
            <Route
                path="/account/:accountSlug/products/crud/:id"
                render={() =>
                    withRoutePermission({
                        ...props.routePermissionCheckData,
                        permissions: ['partner_admin'],
                        component: (
                            <ProductForm
                                accountSlug={props.accountSlug}
                                setActiveSection={props.setActiveSection}
                                replaceMessages={props.replaceTopMessages}
                                removeAllMessages={props.removeAllMessages}
                                viewMode={props.viewMode}
                            />
                        ),
                    })
                }
            />
        </Switch>
    )
}

export default withNavigation(HomePage)
