import { BaseRouteParams } from 'hocs'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { replaceMessage } from 'uiComponents/messages/actions'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { PoolUploadRow } from 'venue/redux'
import {
    useRedeemUnusedDiscountCodesFromCodeMutation,
    useRedeemUnusedDiscountCodesFromUploadsMutation,
} from 'venue/reduxQuery'

interface Props {
    upload: PoolUploadRow
    children: (props: { openModal: () => void }) => any
}

const RedeemCodes: React.FC<Props> = ({ children, upload }) => {
    const [redeemUploads, { isLoading: isRedeemingUploads }] = useRedeemUnusedDiscountCodesFromUploadsMutation()
    const [redeemCodes, { isLoading: isRedeemingCodes }] = useRedeemUnusedDiscountCodesFromCodeMutation()
    const [open, setOpen] = useState<boolean>()
    const { accountSlug } = useParams<BaseRouteParams>()
    const dispatch = useDispatch()
    const isRedeeming = isRedeemingUploads || isRedeemingCodes

    const onConfirm = () => {
        let response: Promise<any> | null = null

        if (upload.type === 'upload') {
            response = redeemUploads({
                accountSlug,
                uuid: upload.id,
            }).unwrap()
        }

        if (upload.type === 'code') {
            response = redeemCodes({
                accountSlug,
                uuid: upload.id,
            }).unwrap()
        }

        if (response) {
            response
                .catch((res: any) => {
                    dispatch(
                        replaceMessage(
                            'redeem_error',
                            'error',
                            res?.error?.message || 'Failed to redeem unused codes',
                            5000,
                        ),
                    )
                })
                .finally(closeModal)
        }
    }

    const closeModal = () => {
        setOpen(false)
    }

    const openModal = () => {
        setOpen(true)
    }

    return (
        <>
            {open && (
                <ConfirmationDialog
                    title="Redeem unused codes"
                    text={
                        <>
                            Are you sure you want to redeem unused codes {upload ? <b>from {upload.name}</b> : ''}? They
                            will no longer be usable.
                        </>
                    }
                    buttonText="Redeem"
                    loading={isRedeeming}
                    onConfirm={onConfirm}
                    onCancel={closeModal}
                />
            )}
            {children({ openModal })}
        </>
    )
}

export default RedeemCodes
