export type FilterCategory =
    | 'products'
    | 'website_traffic_acquisition_channel'
    | 'device_type'
    | 'payment_method'
    | 'checkout_acquisition_channel_category'
    | 'location_id'
    | 'language'
    | 'confirmation_flow'
    | 'capacity_type'
    | 'time_slots'
    | 'availability_pool_uuid'
    | 'product_types'
    | 'capacity_pools'
    | 'channels'
    | 'categories'
    | 'email_status'
    | 'apf_status'
    | 'order_status'
    | 'payment_status'
    | 'ticket_status'
    | 'redeem_status'
    | 'print_status'
    | 'tool_type'
    | 'tool_status'
    | 'audience'
    | 'user_role'
    | 'partner'
    | 'industry'
    | 'tap_notifications_campaign_name'
    | 'tap_platform'
    | 'tap_notifications_category'
    | 'tap_notifications_target_type'
    | 'tap_notifications_schedule_type'
    | 'marketing_permission'
    | 'purchases'
    | 'preferred_device_type'
    | 'email_identified'
    | 'product_lists'
    | 'product_lists_with_screens'
    | 'status'

export const categoryNameMap = {
    products: 'Product',
    website_traffic_acquisition_channel: 'Traffic acquisition channel',
    checkout_acquisition_channel_category: 'Checkout acquisition channel',
    device_type: 'Device type',
    payment_method: 'Payment method',
    location_id: 'Location',
    language: 'Language',
    confirmation_flow: 'Checkout flow',
    product_types: 'Product type',
    capacity_pools: 'Capacity',
    channels: 'Sales channel',
    email_status: 'Email status',
    apf_status: 'APF status',
    order_status: 'Order status',
    payment_status: 'Payment status',
    ticket_status: 'Ticket status',
    redeem_status: 'Redeem status',
    print_status: 'Print status',
    tool_type: 'Tool type',
    tool_status: 'Status',
    audience: 'Audience',
    user_role: 'User role',
    partner: 'Partner',
    industry: 'Industry',
    preferred_device_type: 'Prefered device type',
    email_identified: 'Email identified',
    marketing_permission: 'Marketing permission',
    purchases: 'Purchases',
    tap_notifications_campaign_name: 'Name',
    tap_platform: 'Platform',
    tap_notifications_category: 'Campaigns',
    tap_notifications_target_type: 'Audience',
    tap_notifications_schedule_type: 'Campaign type',
    platform: 'Platform',
    product_lists: 'Product list',
    product_lists_with_screens: 'Screen selection',
    status: 'Order status',
}

export const multiLevelCategories: FilterCategory[] = ['channels', 'product_lists', 'product_lists_with_screens']

export interface AvailableFilters {
    [key: string]: FilterCategory[]
}

export interface Filters {
    attribute: string
    values: string[]
}

export interface RawFilterOption {
    slug: string
    display_name: string
    children: RawFilterOption[]
}

export interface Article {
    id: string
    name: string
    numericId: string
    apfEnabled?: boolean
}

export interface ProductsMetaData {
    uuid: string
    name: string
    articles: Article[]
    children: ProductsMetaData[]
}

export interface RawMetaData {
    products: ProductsMetaData[]
    website_traffic_acquisition_channel: RawFilterOption[]
    checkout_acquisition_channel_category: RawFilterOption[]
    device_type: RawFilterOption[]
    payment_method: RawFilterOption[]
    location_id: RawFilterOption[]
    language: RawFilterOption[]
    tool_type: RawFilterOption[]
    tool_status: RawFilterOption[]
    audience: RawFilterOption[]
    channels: RawFilterOption[]
}

export interface MetaData {
    category: FilterCategory
    options: FilterOption[]
}

export interface FilterQueryItems {
    slug: string
    category: FilterCategory
}

export interface SingleLevelFilterOption {
    name: string
    slug: string
}

export interface FilterOption extends SingleLevelFilterOption {
    category: FilterCategory
    parents: string[]
    children: FilterOption[]
    leafNode: boolean
    priority?: number
}
