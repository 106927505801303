import React, { useState, useEffect } from 'react'
import { Col, Row } from 'uiComponents/flex'
import { SecondaryText } from 'uiComponents/typography'
import { FormItem, FormItemName, WrapWithSymbol } from 'uiComponents/form/formElements'
import { SingleSelect, NumberInput } from 'uiComponents/input'
import { ValidationNotice } from 'products/components/validationNotice'
import { Currency } from 'uiComponents/money/moneyHoc'
import { ToggleWithValues } from 'uiComponents/input/toggle'
import { PriceSetting, AdjustmentType } from 'products/pricing/pricingService'
import { SetAdjustmentFunc } from 'products/pricing/exceptions/exceptionForm'
import { RuleFormContainer } from './shared'

type Rule = '-' | '+' | 'set'

interface AdjustmentTypeOption {
    value: AdjustmentType
    name: string
}

const adjustmentOptions = [
    { value: '-', name: 'Decrease' },
    { value: '+', name: 'Increase' },
    { value: 'set', name: 'Set' },
]

interface AdjustRuleItemProps {
    isOn: boolean
    currency: Currency
    priceSetting: PriceSetting
    adjustmentType: AdjustmentType | null
    adjustmentSize: number | null
    handleRuleChange: SetAdjustmentFunc
    validate: boolean
}

interface AdjustRuleItemState {
    rule: Rule
    factor: number | null
    type: AdjustmentType
}

const initialRuleItemState: AdjustRuleItemState = {
    rule: '-',
    factor: 0,
    type: 'ABSOLUTE',
}

export function AdjustRuleItem(props: AdjustRuleItemProps) {
    const [ruleItem, setRuleItem] = useState<AdjustRuleItemState>(initialRuleItemState)

    const adjustmentTypes: AdjustmentTypeOption[] = [
        { value: 'ABSOLUTE', name: props.currency.symbol },
        { value: 'PERCENTAGE', name: '%' },
    ]

    useEffect(() => {
        const adjustment = ruleItem.rule === '-' && !!ruleItem.factor ? ruleItem.factor * -1 : ruleItem.factor
        if (ruleItem.type !== props.adjustmentType || adjustment !== props.adjustmentSize) {
            setRuleItem({
                rule: getRuleFromAdjustment(props.adjustmentSize, props.adjustmentType),
                factor: getValueFromAdjustment(props.adjustmentSize),
                type: props.adjustmentType ? props.adjustmentType : 'ABSOLUTE',
            })
        }
    }, [props.adjustmentSize, props.adjustmentType])

    function getRuleFromAdjustment(adjustment: number | null, type: AdjustmentType | null): Rule {
        if (type === 'SET') {
            return 'set'
        } else {
            if (adjustment !== null && adjustment !== 0) {
                return adjustment < 0 ? '-' : '+'
            }
            return ruleItem.rule
        }
    }

    function getValueFromAdjustment(adjustment: number | null): number | null {
        if (!!adjustment || adjustment === 0) {
            return adjustment < 0 ? adjustment * -1 : adjustment
        }
        return null
    }

    function handleRuleChange(value: Rule) {
        if (value === 'set') {
            setRuleItem({ ...ruleItem, rule: value, type: 'SET' })
            props.handleRuleChange(props.priceSetting, ruleItem.factor, 'SET')
        } else {
            const adjustment = !!ruleItem.factor && value === '-' ? ruleItem.factor * -1 : ruleItem.factor
            const newType = ruleItem.type === 'SET' ? 'ABSOLUTE' : ruleItem.type
            setRuleItem({ ...ruleItem, rule: value, type: newType })
            props.handleRuleChange(props.priceSetting, adjustment, ruleItem.type === 'SET' ? 'ABSOLUTE' : ruleItem.type)
        }
    }

    function handleFactorChange(e: React.ChangeEvent<HTMLInputElement>) {
        const factorSize = e.target.value === '' ? null : +e.target.value
        const adjustment = !!factorSize && ruleItem.rule === '-' ? factorSize * -1 : factorSize
        setRuleItem({ ...ruleItem, factor: factorSize })
        props.handleRuleChange(props.priceSetting, adjustment, ruleItem.type)
    }

    function handleAdjustmentTypeChange(value: AdjustmentType) {
        let amount = ruleItem.factor
        if (value === 'PERCENTAGE' && amount !== null) {
            amount = Math.min(amount, 99)
        }
        const adjustment = !!amount && ruleItem.rule === '-' ? amount * -1 : amount
        setRuleItem({ ...ruleItem, factor: amount, type: value })
        props.handleRuleChange(props.priceSetting, adjustment, value)
    }

    function getSymbol(ruleType: AdjustmentType): string {
        return ruleType === 'SET' || ruleType === 'ABSOLUTE' ? props.currency.symbol : '%'
    }

    const ruleInvalid = props.isOn && !ruleItem.factor && ruleItem.factor !== 0 && props.validate
    return (
        <>
            <RuleFormContainer className={props.isOn ? '' : 'inactive'}>
                <Row style={{ minWidth: '45rem' }}>
                    <Col span={3}>
                        <FormItem htmlFor="action">
                            <FormItemName style={{ display: 'flex' }}>Action</FormItemName>
                            <SingleSelect
                                id={`adjustmentAction${props.priceSetting}`}
                                options={adjustmentOptions}
                                selected={ruleItem.rule}
                                width="13em"
                                height="2.8em"
                                onSelect={handleRuleChange}
                                status="highlight"
                            />
                        </FormItem>
                    </Col>
                    <Col span={1}>
                        <Row>
                            <SecondaryText style={{ margin: '3.6em auto 0', padding: '0 .5em' }}>
                                {ruleItem.rule === 'set' ? 'to' : 'by'}
                            </SecondaryText>
                        </Row>
                    </Col>
                    <Col span={2}>
                        <FormItem htmlFor="amount">
                            <FormItemName style={{ display: 'flex' }}>Amount</FormItemName>
                            <WrapWithSymbol position="right" symbol={getSymbol(ruleItem.type)}>
                                <NumberInput
                                    id={`adjustmentSize${props.priceSetting}`}
                                    name="adjustmentSize"
                                    min={0}
                                    max={ruleItem.type === 'PERCENTAGE' ? '99.99' : 99999}
                                    maxLength={ruleItem.type === 'PERCENTAGE' ? 5 : 7}
                                    value={ruleItem.factor === null ? '' : ruleItem.factor}
                                    onChange={handleFactorChange}
                                    status={ruleInvalid ? 'error' : 'normal'}
                                    block={true}
                                    style={{ margin: '0 1em 0 0', height: '2.8rem' }}
                                />
                            </WrapWithSymbol>
                            {ruleInvalid && (
                                <ValidationNotice className="validation-message-visible">Set a value</ValidationNotice>
                            )}
                        </FormItem>
                    </Col>
                    {ruleItem.rule !== 'set' && (
                        <Col span={2}>
                            <ToggleWithValues
                                id={`adjustmentType${props.priceSetting}`}
                                options={adjustmentTypes}
                                onClick={handleAdjustmentTypeChange}
                                selected={ruleItem.type}
                                style={{ marginTop: '2.8em' }}
                            />
                        </Col>
                    )}
                    <Col span={4}>
                        <Row>
                            <SecondaryText style={{ marginTop: '3.6em', padding: '0 .5em' }}>
                                for selected products
                            </SecondaryText>
                        </Row>
                    </Col>
                </Row>
            </RuleFormContainer>
            <Col span={3} />
        </>
    )
}
