const settingPrefix = 'convious-settings-'

function extractSettings() {
    if (typeof document === 'undefined') {
        return {}
    }

    let head = document.head || document.getElementsByTagName('head')[0]
    let metaElements = head.getElementsByTagName('meta')

    let result: { [key: string]: string | null } = {}

    for (let i = 0; i < metaElements.length; i++) {
        let node = metaElements[i]
        let itempropAttr = node.getAttribute('itemprop')
        if (itempropAttr && startsWith(itempropAttr, settingPrefix)) {
            let key = itempropAttr.substr(settingPrefix.length)
            let value = node.getAttribute('content')
            result[key] = value
        }
    }

    return result
}

function startsWith(str: string, prefix: string) {
    if (String.prototype['startsWith']) {
        return str['startsWith'](prefix)
    }

    return str.substr(0, prefix.length) === prefix
}

let settings: { [key: string]: string | null }

export function get(key: string) {
    settings = settings || extractSettings()
    return settings[key]
}

export function getRequired(key: string): string {
    const val = get(key)
    if (!val) {
        throw new Error(
            `Unable to find a setting named '${key}' in application configuration.` +
                `Please add <meta itemprop="${settingPrefix}${key}" content="{your value here}"> ` +
                "to the document's HEAD section",
        )
    }

    return val
}
