import { useField } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { CropOptions, ImageUploadResponse } from 'http/imagesService'
import { useUploadImageMutation } from 'http/reduxQuery'
import { UploadInput, UploadResponse } from 'uiComponents/input'
import FieldWrapper from 'uiComponents/input/fieldWrapper'
import { Caption } from 'uiComponents/typography'
import { formatFileName } from '../../../helpers'
import { POI } from '../../../models'
import { Button, Buttons, ImageContainer, Image } from '../../mapStyle/overlayConfig'

const ImageUpload = ({ data }: { data: POI }) => {
    const [uploadImage] = useUploadImageMutation()
    const [{ value }, {}, { setValue }] = useField('image')

    useEffect(() => {
        if (data && data.image) {
            setValue(data.image)
        }
    }, [data])

    const onUpload = async (file: File, cropOptions: CropOptions) => {
        const response = (await uploadImage({ file, options: cropOptions })) as {
            data: ImageUploadResponse | undefined
        }
        return response.data as UploadResponse
    }

    const onRemove = () => {
        setValue('')
    }

    const handleUploadSuccessResponse = useCallback(async (response: ImageUploadResponse) => {
        const url = response.url
        setValue(url)
    }, [])

    return (
        <FieldWrapper label={!!value ? 'Image uploaded' : 'Upload an image for POI'} id="upload-image" status="normal">
            {!!value ? (
                <ImageContainer>
                    <Image src={value} />
                    <div>
                        <Caption>
                            <div title={value}>{formatFileName(value)}</div>
                        </Caption>
                        <Buttons>
                            <Button onClick={onRemove} kind="destructive" secondary>
                                Remove
                            </Button>
                        </Buttons>
                    </div>
                </ImageContainer>
            ) : (
                <UploadInput
                    type="img"
                    onUpload={onUpload}
                    handleUploadSuccessResponse={handleUploadSuccessResponse}
                    inputId="map"
                    status="normal"
                    fileSizeLimit={50}
                    replaceTopMessages={() => {}}
                    removeAllMessages={() => {}}
                    uploadArea={true}
                    enableCropping={true}
                />
            )}
        </FieldWrapper>
    )
}

export default ImageUpload
