import { BaseRouteParams } from 'hocs'
import * as React from 'react'
import { FC } from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import AudiencesContainer from './audiencesContainer'
import CreateCustom from './pages/createCustom'
import CustomList from './pages/customList'
import EditCustom from './pages/editCustom'
import PredefinedList from './pages/predefinedList'
import AudiencesWelcome from './pages/welcome'

const AudiencesRoutes: FC<RouteComponentProps<BaseRouteParams>> = ({ match }) => {
    return (
        <AudiencesContainer>
            <Switch>
                <Route exact path="/account/:accountSlug/crm/audiences/welcome" component={AudiencesWelcome} />
                <Route exact path="/account/:accountSlug/crm/audiences/list" component={PredefinedList} />
                <Route exact path="/account/:accountSlug/crm/audiences/custom" component={CustomList} />
                <Route exact path="/account/:accountSlug/crm/audiences/custom/create" component={CreateCustom} />
                <Route exact path="/account/:accountSlug/crm/audiences/custom/edit/:id" component={EditCustom} />
                <Redirect to={`/account/${match.params.accountSlug}/crm/audiences/list`} />
            </Switch>
        </AudiencesContainer>
    )
}

export default AudiencesRoutes
