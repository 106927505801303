import styled from 'styled-typed'
import mobileFrame from 'uiComponents/studio/images/mobile-frame.png'
import tabletFrame from 'uiComponents/studio/images/tablet-frame.png'

interface FrameProps {
    zoom: number
}

export const PreviewContainer = styled.div`
    position: fixed;
    top: 6em;
    height: calc(100vh - 6em);
    width: calc(100vw - 30em);
    min-width: 700px;

    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-repeat: no-repeat;
    background-position-x: center;
    background-attachment: local;

    &.mobile {
        background-image: url(${mobileFrame});
        background-size: 480px auto;
    }

    &.tablet {
        background-image: url(${tabletFrame});
    }
`

export const FrameWrapper = styled.div`
    overflow: hidden;

    ${PreviewContainer}.desktop & {
        flex: 1;
        border-radius: 1em;
        margin: 1.25em 1.875em 0.25em;
        max-height: calc(100vh - 6em);
    }

    ${PreviewContainer}.tablet & {
        width: 768px;
        height: 964px;
        position: absolute;
        top: 175px;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 150px;
    }

    ${PreviewContainer}.mobile & {
        width: 324px;
        height: 700px;
        position: absolute;
        top: 120px;
        margin-left: -1px;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 150px;
    }
`

export const SafariMobilePreviewSrollWrapper = styled.div`
    overflow: hidden;
    position: relative;
    height: 100%;
    ${PreviewContainer}.mobile & {
        height: 560px;
    }
`

export type PreviewSize = 'desktop' | 'tablet' | 'mobile'

export const PreviewFrame = styled.iframe<FrameProps>`
    width: ${(props) => 100 / props.zoom}%;
    height: ${(props) => 100 / props.zoom}%;
    border: none;
    transform-origin: 0 0;
    transform: scale(${(props) => props.zoom});
`
