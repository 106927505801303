import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { History } from 'history'
import { Account } from 'auth/state'
import { AccessDeniedPage } from 'admin/accessDeniedPage'

interface RoutePermissionCheckData {
    permissions: string[]
    accountSlug: string
    accounts: Account[]
    history: History
    component: JSX.Element
    hasPermission: (permission: string, accountSlug: string) => boolean
}

export function withRoutePermission({
    permissions,
    accountSlug,
    accounts,
    history,
    component,
    hasPermission,
}: RoutePermissionCheckData) {
    if (accounts.length === 0) {
        history.push('/login/?next=' + encodeURIComponent(location.pathname + location.search))
    }
    const hasRequiredPermission = !!permissions.find((p) => hasPermission(p, accountSlug))
    return hasRequiredPermission ? <>{component}</> : <AccessDeniedPage />
}

interface RequiresToChangePasswordProps {
    children?: React.ReactNode | undefined
    requiresPasswordChange: boolean
}

export function RequiresToChangePassword({ requiresPasswordChange, children }: RequiresToChangePasswordProps) {
    if (requiresPasswordChange && location.pathname.indexOf('/new_user/welcome') < 0) {
        return <Route render={() => <Redirect to="/new_user/welcome" />} />
    }

    return <>{children}</>
}

export function redirectUser(
    selfSignUp: boolean,
    activeAccount: string | undefined,
    isReseller: boolean,
    accounts: Account[],
) {
    if (!activeAccount) {
        return <Redirect to="/login" />
    }
    if (selfSignUp) {
        return <Redirect to={`/account/${activeAccount}/engage/tools/home/`} />
    }
    if (isReseller && accounts.length > 1) {
        return <Redirect to="/resellers/home" />
    }
    if (isReseller) {
        return <Redirect to={`/account/${activeAccount}/orders/transaction/`} />
    }
    return <Redirect to={`/account/${activeAccount}/dashboard/overview/`} />
}
