import React, { useEffect, useState } from 'react'
import styled from 'styled-typed'
import { History } from 'history'
import { EmailTemplatesService } from 'emailTemplates/emailTemplatesService'
import { Messages } from 'uiComponents/messages'
import { withMessages, MessageProps } from 'hocs'
import { Container } from 'uiComponents/pageElements'
import { PageTitle, PageHeading, StyledATag, Headline } from 'uiComponents/typography'
import { templatesConfig, Template, TemplateType } from './schema'
import { ActionButton, ActionButtonA } from 'uiComponents/buttons'
import { Toggle } from 'uiComponents/input/toggle'
import { TableLoader } from 'uiComponents/loaders'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { PreviewModal } from './previewModal'
import { DisabledItemHoverInfotip } from 'uiComponents/infotip'
import { withFeatures } from 'features'
import { CONTAINER_BOX_SHADOW } from 'uiComponents/settingsContainer'

const ListContainer = styled.div`
    background: ${(props) => props.theme.colors.white};
    border-radius: 6px;
    min-height: 7.5em;
    box-shadow: ${CONTAINER_BOX_SHADOW};
`
const TemplateContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25em 1.75em;
    border-bottom: 3px solid ${(props) => props.theme.colors.background};

    &:last-child {
        border: none;
    }
`
const TemplateName = styled.div`
    font-weight: bold;
    margin-bottom: 0.2em;
`
const TemplateDescription = styled.div`
    font-size: 0.875em;
    margin-bottom: 1.2em;
    color: ${(props) => props.theme.colors.textLight};
`
const ActionSection = styled.div`
    display: flex;
`
const ToggleBox = styled.div`
    font-size: 0.8em;
    margin-right: 1em;
`
const Icon = styled(FontAwesomeIcon)`
    font-size: 0.9em;
    margin-right: 0.5em;
`

interface ListItemProps {
    accountSlug: string
    template: Template
    onPreview: () => void
    onToggleEnable: (value: boolean) => void
}

function ListItem(props: ListItemProps) {
    return (
        <TemplateContainer>
            <div>
                <Headline size={4} style={{ marginBottom: 0 }}>
                    {templatesConfig[props.template.type].name}
                </Headline>
                <TemplateDescription>{templatesConfig[props.template.type].description}</TemplateDescription>
                <ActionSection>
                    <ActionButton kind="action" onClick={props.onPreview} style={{ marginRight: '1em' }}>
                        <Icon icon={faSearch} />
                        Preview
                    </ActionButton>
                    <DisabledItemHoverInfotip
                        active={props.template.custom}
                        infotipText="This is a custom template. Please contact Convious customer support team to make any changes."
                        width="30em"
                    >
                        <ActionButtonA
                            kind="action"
                            secondary
                            href={`/account/${props.accountSlug}/engage/email_templates/edit/${props.template.type}`}
                            disabled={props.template.custom}
                        >
                            Edit
                        </ActionButtonA>
                    </DisabledItemHoverInfotip>
                </ActionSection>
            </div>
            <ToggleBox>
                <Toggle isOn={props.template.status === 'enabled'} onClick={props.onToggleEnable} />
            </ToggleBox>
        </TemplateContainer>
    )
}

interface EmailTemplatesHomeProps {
    accountSlug: string
    history: History
    emailTemplatesService: EmailTemplatesService
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function EmailTemplatesHome(props: EmailTemplatesHomeProps & MessageProps) {
    const [templateList, setTemplateList] = useState<Template[]>([])
    const [previewTemplate, setPreviewTemplate] = useState<TemplateType>('cart_paid')
    const [showPreview, setShowPreview] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        async function getData() {
            try {
                setLoading(true)
                const list = await props.emailTemplatesService.getTemplatesList(props.accountSlug)
                setTemplateList(list.filter((l) => !!templatesConfig[l.type]))
            } catch {
                props.replaceMessages(
                    'server_error',
                    'error',
                    'Oops! Could not get the email templates list. Please try again later.',
                )
            } finally {
                setLoading(false)
            }
        }
        getData()
    }, [props.accountSlug])

    const onToggleEnable = async (type: TemplateType, enabled: boolean) => {
        const oldSatus = enabled ? 'disabled' : 'enabled'
        const newStatus = enabled ? 'enabled' : 'disabled'
        setTemplateList(templateList.map((t) => (t.type === type ? { ...t, status: newStatus } : { ...t })))
        try {
            await props.emailTemplatesService.updateTemplateStatus(props.accountSlug, type, newStatus)
        } catch {
            setTemplateList(templateList.map((t) => (t.type === type ? { ...t, status: oldSatus } : { ...t })))
            props.replaceMessages(
                'server_error',
                'error',
                `Oops! We could not change the status of ${templatesConfig[type].name} template. Please try again.`,
            )
        }
    }

    const onPreview = (templateType: TemplateType) => {
        setPreviewTemplate(templateType)
        setShowPreview(true)
    }

    return (
        <Container>
            <Messages messages={props.messages} hideMessage={props.hideMessage} />
            {showPreview && (
                <PreviewModal
                    accountSlug={props.accountSlug}
                    emailTemplatesService={props.emailTemplatesService}
                    templateType={previewTemplate}
                    onClose={() => setShowPreview(false)}
                    replaceMessages={props.replaceMessages}
                />
            )}
            <PageTitle>Email Templates</PageTitle>
            <PageHeading>
                <div>Customize and edit all your email templates.</div>
                Learn how to edit your templates with our{' '}
                <StyledATag target="_blank" href="https://support.convious.com/help/how-to-edit-email-templates">
                    detailed guide
                </StyledATag>
                .
            </PageHeading>
            <ListContainer>
                {loading && <TableLoader />}
                {!loading && (
                    <>
                        {templateList.length === 0 && (
                            <TemplateContainer>
                                <TemplateName>No templates found</TemplateName>
                            </TemplateContainer>
                        )}
                        {Object.keys(templatesConfig).map((key) => {
                            const template = templateList.find((t) => t.type === key)
                            return template ? (
                                <ListItem
                                    key={template.type}
                                    template={template}
                                    accountSlug={props.accountSlug}
                                    onPreview={() => onPreview(template.type)}
                                    onToggleEnable={(value) => onToggleEnable(template.type, value)}
                                />
                            ) : null
                        })}
                    </>
                )}
            </ListContainer>
        </Container>
    )
}

export default withFeatures(withMessages(EmailTemplatesHome))
