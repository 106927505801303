import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faLongArrowAltLeft } from '@fortawesome/pro-regular-svg-icons'
import { IOption } from 'crm/audiences/types'
import styled from 'styled-typed'

const ArrowContainer = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.aluminiumShades['10']};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    cursor: pointer;
`

const BreadCrumb = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 4px 16px 4px 4px;
    flex: 0 0 auto;
    align-self: flex-start;
    background-color: ${(props) => props.theme.colors.background};
    border-radius: 23px;
`

const Container = styled.div`
    margin-bottom: 16px;
    display: flex;
`

interface ProductSelectionBreadCrumbProps {
    selectedParentProductList?: IOption
    onGoBackToParentProductList: () => void
}

const ProductSelectionBreadcrumb = (props: ProductSelectionBreadCrumbProps) => {
    if (!props.selectedParentProductList) {
        return null
    }

    return (
        <Container>
            <BreadCrumb>
                <ArrowContainer onClick={props.onGoBackToParentProductList}>
                    <FontAwesomeIcon icon={faLongArrowAltLeft as IconProp} />
                </ArrowContainer>
                <span>{props.selectedParentProductList.caption}</span>
            </BreadCrumb>
        </Container>
    )
}

export default ProductSelectionBreadcrumb
