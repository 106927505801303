import { ProductName } from 'engageTools/studio/schema'
import { Language, languagesDict } from 'utils/languages'

export function transformLanguagesIntoOptions(languages: string[]): Language[] {
    return languagesDict.filter((language) => languages.includes(language.value))
}

export function getAvailableLanguages(accountLanguages: string[], product: ProductName): string[] {
    if (product === 'wonderbar') {
        return languagesDict.filter(({ value }) => accountLanguages.includes(value)).map(({ value }) => value)
    }
    return accountLanguages
}
