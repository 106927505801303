import React, { useState, useEffect, useContext, useRef } from 'react'
import { usePrevious } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import TimeseriesBarChart from 'uiComponents/charts/timeseriesBarChart'
import { DateRange } from 'dateRanges'
import { format, differenceInDays } from 'date-fns'
import { TimeseriesBarChartSource } from 'uiComponents/charts/timeseriesBarChartSource'
import {
    areDateRangeDatesEqual,
    mapTimeseriesForTooltip,
    checkRequiredCapacityFiltersPresent,
    AxisTitle,
    mapGroupedTimeseriesForTooltip,
    getTooltipRow,
    getTooltipXAxisLabel,
    getTooltipHeader,
    getTooltipFooter,
    mapGroupedTimeseriesData,
} from 'reports/helpers'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { Filters } from 'uiComponents/filter/schema'
import { DownloadData, DownloadHeader } from 'reports/schema'
import { DataPoint, TimeSeries, SalesRevenueTimeseriesStats } from 'reports/schema'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import isEqual from 'lodash/isEqual'
import { queryConfig, shouldShowCustomChart } from './utils'
import { parseISODate } from 'utils/dates'

interface VisitorsChartProps {
    dateRange: DateRange
    filters: Filters[]
    accountSlug: string
    chart: TimeseriesBarChartSource
    loading: boolean
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    getCurrency: (accountSlug: string) => Currency
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hideMessage: (id: string) => void
    setDownloadData: (data: DownloadData) => void
}

function VisitorsChart(props: VisitorsChartProps) {
    const _isMounted = useRef(false)
    const _lastRequest = useRef<number>()
    const statsService = useContext(StatsServiceContext)
    const [rawData, setRawData] = React.useState<TimeSeries[][]>([])

    const [loading, setLoading] = useState<boolean>(false)
    const [mappedSalesTotals, setMappedSalesTotals] = useState<DataPoint[]>([])
    const [mappedRevenueTotals, setMappedRevenueTotals] = useState<DataPoint[]>([])
    const [mappedRevenuePerItem, setMappedRevenuePerItem] = useState<TimeSeries[]>([])
    const [mappedCapacity, setMappedCapacity] = useState<DataPoint[]>([])

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    const prevFilters = usePrevious(props.filters)
    useEffect(() => {
        _isMounted.current = true
        if (
            (prevAccountSlug !== props.accountSlug && !isEqual(prevFilters, props.filters)) ||
            !areDateRangeDatesEqual(prevDateRange, props.dateRange) ||
            !isEqual(prevFilters, props.filters)
        ) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug, props.dateRange, props.filters])

    useEffect(() => {
        if (!loading) {
            const currency = props.getCurrency(props.accountSlug)
            const name = 'customers_per_time_range'
            const width = 20
            const headers: DownloadHeader[] = [
                { slug: 'date', label: 'Date', width },
                { slug: 'productName', label: 'Product', width },
                { slug: 'items', label: 'Items', width },
                { slug: 'revenue', label: `Revenue (${currency.symbol})`, width },
            ]
            const values = mapGroupedTimeseriesData(rawData, props.chart.props().xAxisDataRaw)
            props.setDownloadData({ name, headers, values })
        }
    }, [loading])

    useEffect(() => {
        if (!!mappedRevenuePerItem.length) {
            setRawData([...rawData, mappedRevenuePerItem])
        }
    }, [mappedRevenuePerItem])

    const getData = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        if (!checkRequiredCapacityFiltersPresent(props.filters)) {
            return
        }
        setLoading(true)
        const dateFrom = format(props.chart.from(), 'yyyy-MM-dd')
        const dateTo = format(props.chart.to(), 'yyyy-MM-dd')
        const oneDayPeriod = differenceInDays(parseISODate(dateTo), parseISODate(dateFrom)) < 1
        try {
            const variables = {
                widget: props.accountSlug,
                metric: 'items_with_capacity_limits',
                dateFrom,
                dateTo,
                filters: props.filters,
                groupBy: 'product_name',
                granularity: props.chart.granularity,
            }
            const data = await statsService.getStats(queryConfig, variables)

            if (_lastRequest.current !== requestTime) {
                return
            }

            const capacityTimeseries = {
                label: data.capacity.timeSeries[0].points.length > 0 ? 'Capacity' : null,
                points: data.capacity.timeSeries[0].points,
            }
            const timeseries = [capacityTimeseries, ...data.salesPerItem.timeSeries]

            setRawData([timeseries])
            if (oneDayPeriod && shouldShowCustomChart(data.salesPerItem)) {
                props.chart.formatCustomSeries(timeseries)
            } else {
                props.chart.formatSeries(timeseries)
            }
            setData(data)
        } catch {
            setLoading(false)
            props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Customers chart could not be loaded, please try again later.',
            )
        }
    }

    const setData = (data: SalesRevenueTimeseriesStats) => {
        const granularity = props.chart.customGranularity ? 'customTimeseries' : props.chart.granularity
        const xAxisCategories = props.chart.props().xAxisDataRaw
        const mappedSalesTotalsData =
            data.salesTotals.timeSeries.length > 0
                ? mapTimeseriesForTooltip(xAxisCategories, data.salesTotals.timeSeries[0].points, granularity)
                : []
        const mappedRevenueTotalsData =
            data.revenueTotals.timeSeries.length > 0
                ? mapTimeseriesForTooltip(xAxisCategories, data.revenueTotals.timeSeries[0].points, granularity)
                : []
        const mappedRevenuePerItemData = mapGroupedTimeseriesForTooltip(
            xAxisCategories,
            data.revenuePerItem.timeSeries,
            granularity,
        )
        const mappedSCapacityData =
            data.capacity.timeSeries.length > 0
                ? mapTimeseriesForTooltip(xAxisCategories, data.capacity.timeSeries[0].points, granularity)
                : []

        if (_isMounted.current) {
            setMappedSalesTotals(mappedSalesTotalsData)
            setMappedRevenueTotals(mappedRevenueTotalsData)
            setMappedRevenuePerItem(mappedRevenuePerItemData)
            setMappedCapacity(mappedSCapacityData)
            setLoading(false)
        }
    }

    const formatMoney = (amount: string | number) => {
        return props.formatCurrencyString(amount, props.accountSlug)
    }

    const chartTooltipFormatter = (params: TooltipParams[]) => {
        const showCapacity = !!props.filters.find((f) => f.attribute === 'capacity_pools')
        const capacityPoint = mappedCapacity[params[0].dataIndex]
        const capacity = capacityPoint ? capacityPoint.value : '0'
        const totalSalesPoint = mappedSalesTotals[params[0].dataIndex]
        const totalSales = totalSalesPoint ? totalSalesPoint.value : '0'
        const totalRevenuePoint = mappedRevenueTotals[params[0].dataIndex]
        const totalRevenue = totalRevenuePoint ? totalRevenuePoint.value : '0'
        const granularity = props.chart.customGranularity ? 'customTimeseries' : props.chart.granularity
        const xAxisLabel = totalSalesPoint
            ? getTooltipXAxisLabel(totalSalesPoint.timestamp, granularity)
            : params[0].axisValue
        params.sort((a, b) => Number(b.value) - Number(a.value))

        let tooltip = getTooltipHeader(xAxisLabel)
        if (showCapacity) {
            tooltip += getTooltipRow(['Capacity:', capacity], true)
        }
        tooltip += getTooltipRow(['Total:', totalSales, `(${formatMoney(totalRevenue)})`], true)
        params
            .filter((p) => p.seriesName !== 'Capacity')
            .forEach((p) => {
                if (Number(p.value) > 0) {
                    const item = mappedRevenuePerItem.find((r) => r.label === p.seriesName)
                    const revenuePoint = item ? item.points[p.dataIndex] : null
                    const revenue = revenuePoint ? revenuePoint.value : 0
                    tooltip += getTooltipRow([`${p.marker} ${p.seriesName}:`, p.value, `(${formatMoney(revenue)})`])
                }
            })
        tooltip += getTooltipFooter()
        return tooltip
    }

    const legendData = props.chart.props().legendData
    const axisData = props.chart.props().xAxisData
    const series = props.chart.props().series
    if (series.length > 0 && series[0].name === 'Capacity') {
        series[0] = {
            ...series[0],
            color: 'rgba(74, 165, 219, 0.08)',
            stack: null,
            barGap: -1,
        }
    }

    return (
        <div style={{ position: 'relative' }}>
            {(loading || props.loading) && <ChartDataLoader />}
            <AxisTitle title="Visit dates" left="50%" bottom="-2em" style={{ transform: 'translateX(-50%)' }} />
            <TimeseriesBarChart
                axisData={axisData}
                series={series}
                legendData={legendData}
                loading={loading}
                tooltipFormatter={chartTooltipFormatter}
            />
        </div>
    )
}

export default withCurrency(VisitorsChart)
