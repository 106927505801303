import * as React from 'react'
import { DataTable, HeaderRow, Sorting, TableHeader } from 'uiComponents/table'
import { ScrollTableContainer } from 'uiComponents/table/scrollTableContainer'
import { Order } from '../schema'
import TransactionRow from 'orders/transactions/transactionRow'
import { OrdersService } from 'orders/ordersService'
import { LoggingService } from 'http/loggingService'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { MessageKind } from 'uiComponents/messages'
import { History } from 'history'
import { withFeatures } from 'features'

interface OrdersTableProps {
    ordersService: OrdersService
    loggingService: LoggingService
    orders: Order[] | undefined
    loading: boolean
    sort: Sorting
    onSortChanged: (s: Sorting) => void
    accountSlug: string
    saveNewEmail: (orderUuid: string, email: string) => void
    saveNewVisitDate: (orderId: string, date: Date) => void
    saveNewVisitTime: (orderId: string, time: string) => void
    reloadOrderList: () => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    hideMessage: (id: string) => void
    history: History
    withTimeSlots: boolean
    hasFeature: (feature: string, accountSlug: string) => boolean
}

interface OrdersTableState {
    rightInsetShadow: boolean
}

class TransactionsTable extends React.Component<OrdersTableProps, OrdersTableState> {
    constructor(props: OrdersTableProps) {
        super(props)
        this.state = {
            rightInsetShadow:
                (this.table && this.table.scrollLeft === this.table.scrollWidth - this.table.clientWidth) || false,
        }
    }

    table: HTMLDivElement | null = null

    setRef = (node: HTMLDivElement) => {
        this.table = node
    }

    componentDidMount() {
        this.setInsetShadow()
        if (this.table) {
            this.table.addEventListener('scroll', this.setInsetShadow)
        }
    }

    componentWillUnmount() {
        if (this.table) {
            this.table.removeEventListener('scroll', this.setInsetShadow)
        }
    }

    setInsetShadow = () => {
        if (this.table && this.table.scrollLeft === this.table.scrollWidth - this.table.clientWidth) {
            this.setState({ rightInsetShadow: false })
        } else {
            this.setState({ rightInsetShadow: true })
        }
    }

    render() {
        const {
            sort,
            onSortChanged,
            orders = [],
            ordersService,
            loggingService,
            loading,
            accountSlug,
            saveNewEmail,
            saveNewVisitDate,
            saveNewVisitTime,
            reloadOrderList,
            replaceMessages,
            hideMessage,
            history,
            withTimeSlots,
            hasFeature,
        } = this.props
        const showNoResults = !loading && orders && orders.length === 0
        const showResellerColumn = hasFeature('ResellersFeature', accountSlug)
        const frontColumns = [
            '.5em', // expand
            '7.3em', // location
            '6em', // id
            '15.5em', // email
            '5.3em', // quantity
            '6.3em', // subtotal
            '6.3em', // discound
            '8em', // discount code
            '6.3em', // total
            '7em', // sale date
            '7em', // pay date
            '8.5em', // visit date
        ]
        const visitTimeColumn = withTimeSlots ? ['7em'] : []
        const resellerColumn = showResellerColumn ? ['8em'] : []
        const endColumns = [
            '9.3em', // order status
            '9.3em', // payment status
            ...resellerColumn,
            '6.7em', // email status
            '8em', // downloaded
            '11.3em', // actions
        ]
        const columnWidths = [...frontColumns, ...visitTimeColumn, ...endColumns]

        return (
            <ScrollTableContainer loading={loading} accountSlug={accountSlug}>
                <DataTable id="transactions-table" columnWidths={columnWidths} customSidePadding=".5em" allowOverflow>
                    <HeaderRow>
                        <TableHeader />
                        <TableHeader sortKey="location" sorting={sort} changeSort={onSortChanged}>
                            Location
                        </TableHeader>
                        <TableHeader sortKey="id" sorting={sort} changeSort={onSortChanged}>
                            ID
                        </TableHeader>
                        <TableHeader sortKey="email" sorting={sort} changeSort={onSortChanged}>
                            Email
                        </TableHeader>
                        <TableHeader align="center" sortKey="quantity" sorting={sort} changeSort={onSortChanged}>
                            Quantity
                        </TableHeader>
                        <TableHeader sortKey="total" sorting={sort} changeSort={onSortChanged}>
                            Subtotal
                        </TableHeader>
                        <TableHeader>Discount</TableHeader>
                        <TableHeader sortKey="discount_code" sorting={sort} changeSort={onSortChanged}>
                            Code
                        </TableHeader>
                        <TableHeader>Total</TableHeader>
                        <TableHeader sortKey="order_date" sorting={sort} changeSort={onSortChanged}>
                            Sale date
                        </TableHeader>
                        <TableHeader sortKey="payment_date" sorting={sort} changeSort={onSortChanged}>
                            Pay date
                        </TableHeader>
                        <TableHeader sortKey="visit_date" sorting={sort} changeSort={onSortChanged}>
                            Visit date
                        </TableHeader>
                        {withTimeSlots && (
                            <TableHeader sortKey="visit_time" sorting={sort} changeSort={onSortChanged}>
                                Event time
                            </TableHeader>
                        )}
                        <TableHeader>Order status</TableHeader>
                        <TableHeader>Payment status</TableHeader>
                        {showResellerColumn && <TableHeader>Reseller</TableHeader>}
                        <TableHeader>Email status</TableHeader>
                        <TableHeader>Downloaded on</TableHeader>
                        <TableHeader nonInteractive />
                    </HeaderRow>
                    {orders &&
                        orders.map((order, i) => (
                            <TransactionRow
                                key={order.id}
                                loading={loading}
                                order={order}
                                ordersService={ordersService}
                                loggingService={loggingService}
                                accountSlug={accountSlug}
                                saveNewEmail={saveNewEmail}
                                saveNewVisitDate={saveNewVisitDate}
                                saveNewVisitTime={saveNewVisitTime}
                                reloadOrderList={reloadOrderList}
                                replaceMessages={replaceMessages}
                                hideMessage={hideMessage}
                                history={history}
                                withTimeSlots={withTimeSlots}
                                showResellerColumn={showResellerColumn}
                                rowIndex={i}
                                rowCount={orders.length}
                            />
                        ))}
                    {loading && (!orders || orders.length === 0) && <div style={{ height: '6em' }} />}
                    {showNoResults && <NoResultsRow alignLeft item="orders" />}
                </DataTable>
            </ScrollTableContainer>
        )
    }
}

export default withFeatures(TransactionsTable)
