import { createApi } from '@reduxjs/toolkit/query/react'
import { authenticatedHttpServiceQuery } from 'utils/reduxQuery'
import { getRequired } from 'config'

export const supersetApi = createApi({
    reducerPath: 'superset',
    tagTypes: ['getGuestToken'],
    baseQuery: authenticatedHttpServiceQuery({
        baseUrl: getRequired('backoffice-endpoint'),
    }),
    endpoints: () => ({}),
})

const extendedAdminApi = supersetApi.injectEndpoints({
    endpoints: (builder) => ({
        getGuestToken: builder.mutation<{ token: string }, { dashboardId: string; accountSlug: string }>({
            query: ({ dashboardId, accountSlug }) => ({
                url: `dashboard_api/reports/account/${accountSlug}/guest_token/`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    dashboardId,
                }),
            }),
        }),
    }),
})

export const { reducer, reducerPath } = supersetApi
export const { useGetGuestTokenMutation } = extendedAdminApi
