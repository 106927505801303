import * as React from 'react'
import { match as RouteMatch } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import { ArticleConfigurationService } from 'products/articleConfigurationService'
import { ArticleService as AdminArticleService } from 'admin/articleService'
import { History, Location } from 'history'
import ExceptionForm from './exceptionForm'
import ExceptionEdit from './exceptionEdit'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { MessageKind } from 'uiComponents/messages'
import { withPermission } from 'admin/hocs'

interface ProductPricingPageParams {
    accountSlug: string
    id: string
    copy_from: string
}

interface ExceptionDetailPageProps {
    history: History
    location: Location
    articleConfigurationService: ArticleConfigurationService
    articleService: AdminArticleService
    match: RouteMatch<ProductPricingPageParams>
    navigation: Navigation
    setActiveSection: (section: string, header?: string) => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    hasPermission: (permission: string, accountSlug: string) => boolean
    className?: string
}

class ExceptionDetailPage extends React.Component<ExceptionDetailPageProps> {
    async componentDidUpdate(prevProps: ExceptionDetailPageProps) {
        if (prevProps.match.params.accountSlug !== this.props.match.params.accountSlug) {
            this.props.history.push(`/account/${this.props.match.params.accountSlug}/products/validity/exceptions/list`)
        }
    }

    render() {
        const overrideId = this.props.match.params.id
        const accountSlug = this.props.match.params.accountSlug
        const prototypeId = this.props.navigation.query().copy_from
        const { articleConfigurationService, history, setActiveSection, className } = this.props
        return (
            <div
                style={{
                    padding: '2.8em 3.2em',
                    position: 'relative',
                    maxWidth: '75rem',
                }}
            >
                <Switch>
                    <Route
                        path="/account/:accountSlug/products/validity/exceptions/duplicate"
                        render={() => (
                            <ExceptionEdit
                                className={className}
                                key="new"
                                id={prototypeId}
                                prototype={true}
                                accountSlug={accountSlug}
                                history={history}
                                articleService={this.props.articleService}
                                articleConfigurationService={articleConfigurationService}
                                setActiveSection={setActiveSection}
                                replaceTopMessages={this.props.replaceTopMessages}
                                removeAllMessages={this.props.removeAllMessages}
                                hasPermission={this.props.hasPermission}
                            />
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/products/validity/exceptions/create"
                        render={() => (
                            <ExceptionForm
                                key="new"
                                className={className}
                                history={this.props.history}
                                accountSlug={accountSlug}
                                articleService={this.props.articleService}
                                articleConfigurationService={articleConfigurationService}
                                setActiveSection={setActiveSection}
                                replaceTopMessages={this.props.replaceTopMessages}
                                removeAllMessages={this.props.removeAllMessages}
                                hasPermission={this.props.hasPermission}
                            />
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/products/validity/exceptions/:id"
                        render={() => (
                            <ExceptionEdit
                                className={className}
                                key={overrideId}
                                id={overrideId}
                                accountSlug={accountSlug}
                                history={history}
                                articleService={this.props.articleService}
                                articleConfigurationService={articleConfigurationService}
                                setActiveSection={setActiveSection}
                                replaceTopMessages={this.props.replaceTopMessages}
                                removeAllMessages={this.props.removeAllMessages}
                                hasPermission={this.props.hasPermission}
                            />
                        )}
                    />
                </Switch>
            </div>
        )
    }
}

export default withNavigation(withPermission(ExceptionDetailPage))
