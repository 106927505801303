import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { State } from 'store'

type Props = {
    featureName: string
}

export function useHasFeature({ featureName }: Props) {
    const hasFeature = useHasFeatureFunc()

    return hasFeature(featureName)
}

export const useGetFeatureValue = (featureName: string) => {
    const features = useSelector((state: State) => state.features)
    const accountSlug = useSelector((state: State) => state.preferences.activeAccount)

    return useMemo(() => {
        const accountFeatures = accountSlug ? features.forAccount[accountSlug] : []
        const allApplicableFeatures =
            accountFeatures && accountFeatures.length > 0 ? accountFeatures : features.defaults

        return allApplicableFeatures.find((x) => x.name === featureName)?.value
    }, [features, accountSlug])
}

export function useHasFeatureFunc() {
    const features = useSelector((state: State) => state.features)
    const isAdmin = useSelector((state: State) => !!state.auth.user?.isAdmin)
    const accountSlug = useSelector((state: State) => state.preferences.activeAccount)

    const hasFeature = useCallback(
        (featureName): boolean => {
            const accountFeatures = accountSlug ? features.forAccount[accountSlug] : []
            const allAplicableFeatures = accountFeatures?.length ? accountFeatures : features.defaults
            const feature = allAplicableFeatures.filter((x) => x.name === featureName)
            return feature[0]?.value === 'enabled' || (feature[0]?.value === 'convious' && isAdmin)
        },
        [accountSlug, isAdmin, features],
    )

    return hasFeature
}
