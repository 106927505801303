import { useCallback, useState } from 'react'
import { useParkMapViewerContext } from '../context/mapViewer'
import { Category, POI } from '../models'

const useMarkers = () => {
    const { map, poisToDisplay, setPoisToDisplay, setCategories } = useParkMapViewerContext()
    const [markerCoordinates, setMarkerCoordinates] = useState<google.maps.LatLng | null | undefined>()
    const [activeMarker, setActiveMarker] = useState<google.maps.Marker>()

    const addActiveMarker = useCallback(
        (latLng?: google.maps.LatLng) => {
            const activeMarker = new google.maps.Marker()
            activeMarker.setPosition(latLng)
            activeMarker.setAnimation(google.maps.Animation.DROP)
            activeMarker.setMap(map)
            activeMarker.setDraggable(true)
            activeMarker.addListener('dragend', () => setMarkerCoordinates(activeMarker.getPosition()))
            setMarkerCoordinates(activeMarker.getPosition())
            setActiveMarker(activeMarker)
        },
        [map],
    )

    const removeActiveMarker = useCallback((marker: google.maps.Marker) => {
        marker.setMap(null)
    }, [])

    const onChangeCoordinates = useCallback(
        (latLng: google.maps.LatLng) => {
            activeMarker?.setPosition(latLng)
        },
        [activeMarker],
    )

    const displayMarkers = useCallback(
        (categories?: Category[]) => {
            if (categories) {
                const pois: POI[] = []
                categories.forEach((category) => {
                    category.pois?.forEach((poi) => {
                        pois.push(poi)
                    })
                })
                setCategories(categories)
                setPoisToDisplay(pois)
            }
        },
        [poisToDisplay, map],
    )

    const removeMarker = useCallback(
        (poiId: string) => {
            if (poisToDisplay.find((poi) => poi.id === poiId)) {
                setPoisToDisplay(poisToDisplay.filter((poi) => poi.id !== poiId))
            }
        },
        [poisToDisplay],
    )

    return {
        poisToDisplay,
        markerCoordinates,
        onChangeCoordinates,
        activeMarker,
        addActiveMarker,
        removeActiveMarker,
        displayMarkers,
        removeMarker,
    }
}

export default useMarkers
