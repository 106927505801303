import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { PromotionListResponse } from 'tapPromotions/types'
import { ActionMenu } from '../listActionMenu'
import { Cell, DataRow, MultipleStatus, StatusName } from 'uiComponents/table'
import { format } from 'date-fns'
import styled from 'styled-typed'
import { faBars } from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Audience } from 'crm/schema'
import { find } from 'lodash'
import { DateFormats } from 'utils/dates'

type Props = {
    accountSlug: string
    item: PromotionListResponse
    index: number
    status: StatusName
    audiences: Audience[]
    onDelete(promotionId: string): void
}

export const NameCell = styled(Cell)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

const IconContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`

const PromotionTableRow = (props: Props) => {
    const formatDates = (startDate: number, endDate: number | null) => {
        const endText = endDate ? format(new Date(endDate), DateFormats.LONG_DATE) : 'never'

        return (
            <>
                {format(new Date(startDate), DateFormats.LONG_DATE)} -<br />
                {endText}
            </>
        )
    }

    const renderAudienceText = () => {
        let readableName = ''
        props.item.audiences?.map((audience, itemIndex) => {
            readableName = find(props.audiences, { slug: audience.audience })?.name || audience.audience
        })

        return readableName
    }

    return (
        <Draggable key={props.item.id} draggableId={props.item.id} index={props.index}>
            {(provided, snapshot) => (
                <DataRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <Cell>
                        <IconContainer>
                            <FontAwesomeIcon icon={faBars as IconProp} />
                        </IconContainer>
                    </Cell>
                    <NameCell>
                        <div>{props.item.name}</div>
                        <MultipleStatus
                            status={props.status}
                            withText
                            successText="Active"
                            errorText="Inactive"
                            warnText="Expired"
                        />
                    </NameCell>
                    <Cell>{renderAudienceText()}</Cell>
                    <Cell>{formatDates(props.item.startDate, props.item.endDate)}</Cell>
                    <Cell align="right" style={{ overflow: 'unset' }}>
                        <ActionMenu
                            onUnpublish={null}
                            onPublish={null}
                            onEditInfo={null}
                            studioHref={`/account/${props.accountSlug}/engage/tools/promotions/type/${props.item.id}`}
                            onDelete={() => {
                                props.onDelete(props.item.id)
                            }}
                        />
                    </Cell>
                </DataRow>
            )}
        </Draggable>
    )
}

export default PromotionTableRow
