import React, { useCallback, useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Headline } from 'uiComponents/typography'
import ColorPickerFieldFormik from 'uiComponents/input/color/colorPickerFieldFormik'
import { FormWrapper } from 'uiComponents/form/form'
import { Checkbox } from 'uiComponents/input'
import { Tooltip } from 'uiComponents/tooltip'
import { useParkMapViewerContext } from '../../context/mapViewer'

const BgColorSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 8px 0 0 0;
    padding: 0.25em 0;
`

const Label = styled(Headline)`
    margin: 0;
    margin-right: 0.25em;
`

const TooltipText = styled.div`
    color: ${(props) => props.theme.colors.white};
    font-size: 0.8em;
`

const LabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    margin-left: 0.25em;
`

interface OverlayBackgroundColorSelectorProps {
    bgColor: string | null
    onColorChange(bgColor: string | null): void
}

const OverlayBackgroundColorSelector = ({ onColorChange, bgColor }: OverlayBackgroundColorSelectorProps) => {
    const theme = useTheme()
    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)
    const [enabled, setEnabled] = useState<boolean>(!!bgColor)
    const defaultBgColor = theme.colors.emeraldShades[60]
    const [color, setColor] = useState<string | null>(bgColor ?? defaultBgColor)
    const { setOverlayVisibility, setMapType } = useParkMapViewerContext()

    useEffect(() => {
        setEnabled(!!bgColor)
        setColor(bgColor || defaultBgColor)
    }, [bgColor])

    const onChange = useCallback(() => {
        setEnabled((prev) => {
            if (prev) {
                onColorChange(null)
            } else {
                onColorChange(color)
                setOverlayVisibility(true)
                setMapType(google.maps.MapTypeId.ROADMAP)
            }
            return !prev
        })
    }, [color])

    return (
        <FormWrapper enableReinitialize initialValues={{ bgColor: color }} onSubmit={() => {}}>
            <BgColorSection>
                <Checkbox onChange={onChange} checked={enabled} />
                <LabelContainer>
                    {tooltipVisible && (
                        <Tooltip style={{ width: 260, left: enabled ? 244 : 144, bottom: -10, position: 'absolute' }}>
                            <TooltipText>Select background color for park map</TooltipText>
                        </Tooltip>
                    )}
                    <Label size={6}>Background Color</Label>
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        color={theme.colors.textLight}
                        size="sm"
                        style={{ margin: 0 }}
                        onMouseEnter={() => setTooltipVisible(true)}
                        onMouseLeave={() => setTooltipVisible(false)}
                    />
                </LabelContainer>
                {enabled && (
                    <ColorPickerFieldFormik
                        id="s"
                        name="bgColor"
                        onChange={(col) => {
                            onColorChange(col)
                            setColor(col)
                        }}
                        alignTriggerRight
                        hasFeature={() => false}
                        color={bgColor || undefined}
                    />
                )}
            </BgColorSection>
        </FormWrapper>
    )
}

export default OverlayBackgroundColorSelector
