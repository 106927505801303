import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import { CopyIcon } from 'uiComponents/buttons/copyIcon'
import { maskPersonalData, maskFullNameValue } from 'utils'
import { faUser, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon } from 'uiComponents/buttons/actionIcon'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'

export function getInitials(name: string | null, email: string | null) {
    try {
        if (!!name) {
            const nameParts = name.trim().split(' ')
            return `${nameParts[0][0]}${nameParts[nameParts.length - 1][0]}`
        }
        if (email) {
            const emailStart = email.split('@')[0].replace(/[^a-zA-Z]+/g, '')
            return emailStart.length > 1 ? emailStart.slice(0, 2) : emailStart[0] + emailStart[0]
        }
    } catch {
        return <FontAwesomeIcon icon={faUser} />
    }
    return <FontAwesomeIcon icon={faUser} />
}

const Wrapper = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
const Container = styled(Wrapper)`
    display: flex;
    align-items: center;
    cursor: pointer;
`
export const Initials = styled.div`
    background: ${(p) => p.theme.colors.border};
    color: ${(p) => p.theme.colors.textLight};
    text-transform: uppercase;
    font-size: 0.75rem;
    height: 2.25rem;
    width: 2.25rem;
    flex: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-right: 0.7em;

    &.large {
        font-size: 1.875em;
        height: 5.5rem;
        width: 5.5rem;
        margin-right: 0;
    }
`
const Link = styled.span`
    color: ${(p) => p.theme.colors.boyBlue};
    &:hover {
        text-decoration: underline;
    }
`

interface ProfileNameElementProps {
    name: string | null
    email: string | null
    unmask: boolean
}

export function ProfileNameElement(props: ProfileNameElementProps) {
    const [unmasked, setUnmasked] = useState<boolean>(props.unmask)
    const showEyeIcon = !!props.email || !!props.name

    useEffect(() => {
        setUnmasked(props.unmask)
    }, [props.unmask])

    return (
        <Container>
            <Initials>{getInitials(props.name, props.email)}</Initials>
            <Wrapper>
                <Wrapper>
                    {!unmasked && !!props.name ? (
                        <Wrapper>{maskFullNameValue(props.name)}</Wrapper>
                    ) : (
                        !!props.name && <Wrapper>{props.name}</Wrapper>
                    )}
                    {!unmasked && !!props.email ? (
                        <Wrapper>{maskPersonalData(props.email)}</Wrapper>
                    ) : (
                        !!props.email && <Wrapper>{props.email}</Wrapper>
                    )}
                </Wrapper>
            </Wrapper>
            <div onClick={(e) => e.stopPropagation()} style={{ marginLeft: '0.3em' }}>
                {showEyeIcon && unmasked && (
                    <ActionIcon
                        onClick={() => setUnmasked(false)}
                        title="Hide details"
                        className="first-icon"
                        icon={faEyeSlash}
                    />
                )}
                {showEyeIcon && !unmasked && (
                    <ActionIcon
                        onClick={() => setUnmasked(true)}
                        title="Show details"
                        className="first-icon"
                        icon={faEye}
                    />
                )}
            </div>
        </Container>
    )
}

export function ProfileIdElement(props: { id: string; to?: string }) {
    return (
        <>
            {!props.to && <span>{props.id.slice(0, 8)}</span>}
            {props.to && (
                <Link>
                    <UnstyledLink to={props.to}>{props.id.slice(0, 8)}</UnstyledLink>
                </Link>
            )}
            <span onClick={(e) => e.stopPropagation()}>
                <CopyIcon text={props.id} style={{ marginLeft: '0.5em' }} />
            </span>
        </>
    )
}
