import { IOption } from 'crm/audiences/types'
import React from 'react'
import { useTheme } from 'styled-components'
import styled from 'styled-typed'
import { Checkbox } from 'uiComponents/input'

const Element = styled.div<{ disabled: boolean }>`
    position: relative;
    padding: 1.2em 1.2em;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 70px;
`

const Label = styled.div`
    font-size: 0.9em;
    padding-left: 0.6em;
`

const Breadcrumbs = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 1em;
`

const Breadcrumb = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.8em;
    padding-right: 0.6em;
    color: ${(props) => props.theme.colors.textLight};
`

const Spacer = styled.div`
    font-size: 0.9em;
    padding-left: 0.6em;
    color: ${(props) => props.theme.colors.textLight};
`

interface productSearchedProps {
    product: IOption
    parents?: IOption[]
    products: IOption[]
    onChange: (value: IOption | null) => void
    isChecked: (value: IOption) => boolean
    isDisabled: (value: IOption) => boolean
}

const ProductSearched = (props: productSearchedProps) => {
    const theme = useTheme()

    return (
        <Element disabled={props.isDisabled(props.product)}>
            {props.parents && (
                <Breadcrumbs>
                    {props.parents.map((parent, index) => {
                        const lastItem = props.parents?.length === index + 1
                        return (
                            <Breadcrumb key={index}>
                                <div style={{ color: theme.colors[lastItem ? 'textDark' : 'textLight'] }}>
                                    {parent.caption}
                                </div>
                                <Spacer>{!lastItem && '/'}</Spacer>
                            </Breadcrumb>
                        )
                    })}
                </Breadcrumbs>
            )}
            <Row>
                <Checkbox
                    checked={props.isChecked(props.product)}
                    onChange={() => props.onChange(props.product)}
                    bordered={true}
                    disabled={props.isDisabled(props.product)}
                />
                <Label>{props.product.caption}</Label>
            </Row>
        </Element>
    )
}

export default ProductSearched
