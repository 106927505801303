import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import { history } from 'middleware'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { pathUtils } from 'utils/pathnameFormatter'
import { CodePageParams, CODES_PATHS } from 'venue/bookingCodes/paths'
import { getDiscountPoolById } from 'venue/redux'
import { useDeleteDiscountPoolMutation } from 'venue/reduxQuery'
import { addMessage, removeMessage } from 'uiComponents/messages/actions'

const DeletePool = () => {
    const routeParams = useParams<CodePageParams>()
    const location = useLocation()
    const pool = useSelector(getDiscountPoolById(routeParams.id))
    const [deletePool, { isLoading }] = useDeleteDiscountPoolMutation()
    const dispatch = useDispatch()

    const closeModal = () => {
        const path = pathUtils.addQueryString(CODES_PATHS.fullPaths.poolsPage, location.search)
        history.push(pathUtils.populateParams(path, routeParams))
    }

    const onConfirm = () => {
        deletePool({ accountSlug: routeParams.accountSlug, uuid: routeParams.id })
            .unwrap()
            .then(closeModal)
            .catch(() => {
                const errorVisibleTime = 5000
                dispatch(addMessage('delete_pool_error', 'error', 'Failed to delete pool. Please try again later.'))

                setTimeout(() => {
                    dispatch(removeMessage('delete_pool_error'))
                }, errorVisibleTime)
            })
    }

    return (
        <ConfirmationDialog
            title="Delete pool"
            text={
                <>
                    Are you sure you want to delete {pool ? <b>{pool.name}</b> : 'the pool'}? This action is
                    irreversible.
                </>
            }
            buttonText="Delete"
            loading={isLoading}
            onConfirm={onConfirm}
            onCancel={closeModal}
        />
    )
}

export default DeletePool
