import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import { withFeatures } from 'features'
import { History } from 'history'
import { ManagementService } from 'settings/accountSettings/contract/managementService'
import { MessageKind } from 'uiComponents/messages'
import { ArticleService as AdminArticleService } from 'admin/articleService'
import { ActionButton } from 'uiComponents/buttons'
import { ModalDialog, ModalDialogTitle } from 'uiComponents/popups/modal'
import { PricingService } from '../pricingService'
import ExceptionsTable from './exceptionsTable'
import { ChannelsServiceContext } from 'channels/context'

const DialogText = styled.div`
    line-height: 1.4rem;
`
export const Text = styled.div`
    line-height: 1.75em;
    margin-bottom: 1.5em;
`
const Container = styled.div`
    width: 25em;
`
const NavigationSection = styled.div`
    font-size: 0.875em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2.5em;
`

interface ExceptionsListPageProps {
    pricingService: PricingService
    articleService: AdminArticleService
    managementService: ManagementService
    accountSlug: string
    history: History
    setActiveSection: (section: string, header?: string) => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hasPermission: (permission: string, accountSlug: string) => boolean
    hasFeature: (feature: string, slug: string) => boolean
}

function ExceptionsListPage(props: ExceptionsListPageProps) {
    const channelsService = React.useContext(ChannelsServiceContext)
    const [exceptionsAlert, setExceptionsAlert] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    async function fetchPricingStrategy() {
        setLoading(true)
        const alertClosed = sessionStorage.getItem('exceptionsAlertClosed') === 'true'
        const str = await props.managementService.getPricingStrategy(props.accountSlug)
        if (str === 'opt_visitor_dist' && !alertClosed) {
            setExceptionsAlert(true)
        }
        setLoading(false)
    }

    useEffect(() => {
        props.setActiveSection('pricingExceptions', 'Pricing Exceptions')
        fetchPricingStrategy()
    }, [])

    const handleError = (message: string | null = null) => {
        if (message) {
            props.replaceTopMessages('server_error', 'error', message)
        }
    }

    const onCancel = () => {
        setExceptionsAlert(false)
        sessionStorage.setItem('exceptionsAlertClosed', 'true')
    }

    return (
        <>
            {exceptionsAlert && !loading && (
                <ModalDialog onDismiss={onCancel} interactive fromTop="20%">
                    <Container>
                        <ModalDialogTitle>Pricing exceptions are ignored</ModalDialogTitle>
                        <DialogText style={{ marginBottom: '1em' }}>
                            Pricing exceptions are ignored, because Even Visitor Distribution strategy is selected.
                        </DialogText>
                        <DialogText>
                            Your products will use the minimum price (lowest price of all the related exceptions) and
                            the maximum price (highest price of all the related exceptions).
                        </DialogText>
                        <NavigationSection>
                            <ActionButton size="large" secondary onClick={onCancel}>
                                Cancel
                            </ActionButton>
                            {props.hasPermission('convious_admin', props.accountSlug) && (
                                <ActionButton
                                    size="large"
                                    kind="default"
                                    style={{ marginLeft: '2em' }}
                                    onClick={() =>
                                        props.history.push(`/account/${props.accountSlug}/global_pricing_settings`)
                                    }
                                >
                                    Go to pricing goal settings
                                </ActionButton>
                            )}
                        </NavigationSection>
                    </Container>
                </ModalDialog>
            )}
            <ExceptionsTable
                pricingService={props.pricingService}
                articleService={props.articleService}
                channelsService={channelsService}
                accountSlug={props.accountSlug}
                handleError={handleError}
                hasPermission={props.hasPermission}
            />
        </>
    )
}

export default withFeatures(ExceptionsListPage)
