import React from 'react'
import { useUpdateArticleStatusMutation, useUpdateProductListStatusAndPriorityMutation } from 'products/reduxApi'
import { Toggle } from 'uiComponents/input'
import styled from 'styled-components'

const EnableProductStyles = styled.div`
    font-size: 9px;
`

interface Props {
    enabled: boolean
    id: string
    isArticle: boolean
    priority: number
}

const EnableProduct: React.FC<Props> = ({ enabled, id, isArticle, priority }) => {
    const [updateArticleStatus] = useUpdateArticleStatusMutation()
    const [updateProductListStatusAndPriority] = useUpdateProductListStatusAndPriorityMutation()

    const toggle = () => {
        if (isArticle) {
            updateArticleStatus({ id, enabled: !enabled })
        } else {
            updateProductListStatusAndPriority({ id, priority, enabled: !enabled })
        }
    }

    return (
        <EnableProductStyles>
            <Toggle isOn={enabled} onClick={toggle} />
        </EnableProductStyles>
    )
}

export default EnableProduct
