import React from 'react'
import styled from 'styled-typed'
import { Currency } from 'uiComponents/money/moneyHoc'
import { AdjustmentType, PricingSettingToEnforce } from '../pricingService'
import { PricingType } from 'products/articleConfigurationService'
import { PriceSettingInfotip } from './priceSettingInfotip'
import { ToggleBox } from 'uiComponents/toggleBox'

const BoxWrapper = styled.div`
  display: flex;
  justify-content: start;
  height: 2em;
  width: 10em;
`

interface ToggleBoxProps {
    active: boolean
    infotipVisibile: boolean
    text: string
    shortName: string
    onMouseOver: () => void
    onMouseOut: () => void
}

function ToggleBoxWithInfotip(props: ToggleBoxProps) {
    return (
        <PriceSettingInfotip show={props.active ? props.infotipVisibile : false} text={props.text}>
            <ToggleBox
                className={props.active ? 'active' : ''}
                onMouseOver={props.onMouseOver}
                onMouseOut={props.onMouseOut}
            >
                {props.shortName}
            </ToggleBox>
        </PriceSettingInfotip>
    )
}

interface PriceTypeBoxElementProps {
    maxAdjustmentType: AdjustmentType | null
    maxAdjustment: string | null
    minAdjustmentType: AdjustmentType | null
    minAdjustment: string | null
    targetAdjustmentType: AdjustmentType | null
    targetAdjustment: string | null
    enforceFrom: PricingSettingToEnforce | null
    pricingType: PricingType | null
    currency: Currency
}

interface PriceTypeBoxElementState {
    infotipVisible_max: boolean
    infotipVisible_avg: boolean
    infotipVisible_min: boolean
}

export class PriceSettingBoxElement extends React.Component<PriceTypeBoxElementProps, PriceTypeBoxElementState> {
    constructor(props: PriceTypeBoxElementProps) {
        super(props)
        this.state = {
            infotipVisible_max: false,
            infotipVisible_avg: false,
            infotipVisible_min: false,
        }
    }

    componentDidUpdate(prevProps: PriceTypeBoxElementProps) {
        if (prevProps !== this.props) {
            this.setState({
                infotipVisible_max: false,
                infotipVisible_avg: false,
                infotipVisible_min: false,
            })
        }
    }

    getRuleFromAdjustment(adjustment: string | null, type: AdjustmentType | null): 'Increase' | 'Decrease' | 'Set' {
        if (type === 'SET') {
            return 'Set'
        } else {
            if (!adjustment) {
                return 'Decrease'
            }
            return Number(adjustment) < 0 ? 'Decrease' : 'Increase'
        }
    }

    getNumberFromAdjustment(adjustment: string | null): number {
        if (!adjustment) {
            return 0
        }
        const adj = Number(adjustment)
        return Number(adj) < 0 ? Number(adj) * -1 : adj
    }

    getAdjustmentSummary(adjustment: string | null, type: AdjustmentType | null, setting: string): string {
        if (this.props.enforceFrom) {
            return `Enforce price to equal ${this.props.enforceFrom} price setting`
        }
        const rule = this.getRuleFromAdjustment(adjustment, type)
        const preposition = type === 'SET' ? 'to' : 'by'
        const amount = this.getNumberFromAdjustment(adjustment)
        const sign = type === 'PERCENTAGE' ? '%' : this.props.currency.symbol
        return `${rule} ${setting} ${preposition} ${amount} ${sign}`
    }

    render() {
        const {
            maxAdjustmentType,
            maxAdjustment,
            minAdjustmentType,
            minAdjustment,
            targetAdjustmentType,
            targetAdjustment,
            pricingType,
            enforceFrom,
        } = this.props
        return (
            <BoxWrapper className="priceTypeBoxes">
                {(!!enforceFrom || (!!maxAdjustment && !!maxAdjustmentType) || pricingType === 'static') && (
                    <ToggleBoxWithInfotip
                        active={true}
                        infotipVisibile={this.state.infotipVisible_max}
                        text={this.getAdjustmentSummary(
                            maxAdjustment,
                            maxAdjustmentType,
                            pricingType === 'static' || pricingType === 'upsell' ? 'Price' : 'Max. price',
                        )}
                        shortName={pricingType === 'static' || pricingType === 'upsell' ? 'PRICE' : 'MAX'}
                        onMouseOver={() => {
                            this.setState({ infotipVisible_max: true })
                        }}
                        onMouseOut={() => {
                            this.setState({ infotipVisible_max: false })
                        }}
                    />
                )}
                {(pricingType === 'rtp' || pricingType === 'nyop' || !pricingType) && (
                    <>
                        {(!!enforceFrom || (!!targetAdjustment && !!targetAdjustmentType)) && (
                            <ToggleBoxWithInfotip
                                active={true}
                                infotipVisibile={this.state.infotipVisible_avg}
                                text={this.getAdjustmentSummary(targetAdjustment, targetAdjustmentType, 'Avg. price')}
                                shortName="AVG"
                                onMouseOver={() => {
                                    this.setState({ infotipVisible_avg: true })
                                }}
                                onMouseOut={() => {
                                    this.setState({ infotipVisible_avg: false })
                                }}
                            />
                        )}
                        {(!!enforceFrom || (!!minAdjustment && !!minAdjustmentType)) && (
                            <ToggleBoxWithInfotip
                                active={true}
                                infotipVisibile={this.state.infotipVisible_min}
                                text={this.getAdjustmentSummary(minAdjustment, minAdjustmentType, 'Min. price')}
                                shortName="MIN"
                                onMouseOver={() => {
                                    this.setState({ infotipVisible_min: true })
                                }}
                                onMouseOut={() => {
                                    this.setState({ infotipVisible_min: false })
                                }}
                            />
                        )}
                    </>
                )}
            </BoxWrapper>
        )
    }
}
