import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import styled from 'styled-typed'
import { PopupArea } from 'uiComponents/input'
import useOutsideClick from '../hooks/useOutsideClick'

const Container = styled.div`
    position: relative;
    margin: 1.5em 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const ButtonAdd = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.boyBlue};
    border: 1px solid ${(props) => props.theme.colors.boyBlueShades['20']};
    border-radius: 4px;
    padding: 0 1.25em;
    height: 2.5rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
`
const ButtonLabel = styled.span`
    font-size: 1rem;
    margin-right: 0.5em;
`

const IconWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const Icon = styled(FontAwesomeIcon)`
    height: 12px;
    cursor: pointer;
`

const TopCaret = styled(Icon)`
    position: relative;
    top: 5px;
`

const BottomCaret = styled(Icon)`
    position: relative;
    bottom: 5px;
`

const Select = styled(PopupArea)`
    width: 200px;
`

const Option = styled.div`
    cursor: pointer;

    :hover {
        background: ${(props) => props.theme.colors.background};
    }

    :first-child {
        border-bottom: 0.5px solid ${(props) => props.theme.colors.border};
    }
`

const OptionText = styled.span`
    padding: 0.2em 1em 1em;
    font-size: 0.75rem;
    color: ${(props) => props.theme.colors.textLight};
    line-height: 1.5em;
    display: block;
`

const OptionLabel = styled.div`
    padding: 1em 1em 0;
    font-size: 0.75rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.textDark};
`

interface AudiencesAddConditionProps {
    onSelect: (value: string) => void
}

const AudiencesAddCondition = (props: AudiencesAddConditionProps) => {
    const [expanded, setExpanded] = useState(false)

    const addConditionOptions = [
        { name: 'Visits', text: 'Target Audiences that have purchased tickets to your venue on set date criteria.' },
        { name: 'Products', text: 'Target specific Audiences who have or will purchase your products.' },
    ]

    const handleClickOutside = () => {
        setExpanded(false)
    }

    const ref = useOutsideClick(handleClickOutside)

    const onSelect = (value: string) => {
        setExpanded(false)
        props.onSelect(value)
    }

    return (
        <Container>
            <ButtonAdd onClick={() => setExpanded((prev) => !prev)} ref={ref}>
                <ButtonLabel>Add Condition</ButtonLabel>
                <IconWrapper>
                    <TopCaret icon={faSortUp} />
                    <BottomCaret icon={faSortDown} />
                </IconWrapper>
            </ButtonAdd>
            <Select className={expanded ? 'visible' : ''}>
                {addConditionOptions.map((option) => (
                    <Option key={option.name}>
                        <OptionLabel onClick={() => onSelect(option.name)}>{option.name}</OptionLabel>
                        <OptionText onClick={() => onSelect(option.name)}>{option.text}</OptionText>
                    </Option>
                ))}
            </Select>
        </Container>
    )
}

export default AudiencesAddCondition
