import { ImagesServiceContext } from 'http/context'
import { ImageUploadResponse, CropOptions } from 'http/imagesService'
import * as React from 'react'
import { UseDateInput, UseFormInput, VenueLocation } from 'tapPlacements/schema'
import { ComponentsService } from 'engageTools/studio/componentsService'
import { get } from 'lodash'
import { OpeningTimesServiceContext } from 'venue/openingTimes/context'

export const useFormInput = (initialValue: string | any = ''): UseFormInput => {
    const imagesService = React.useContext(ImagesServiceContext)

    const [value, setValue] = React.useState(initialValue)

    const handleChange = (text: string) => {
        setValue(text)
    }

    const onUpload = async (file: File, cropOptions: CropOptions) => {
        return await imagesService.uploadImage(file, cropOptions)
    }

    const onUploadSuccess = (response: ImageUploadResponse) => {
        setValue(response.url)
    }

    return {
        value,
        onChange: handleChange,
        onUpload: onUpload,
        onUploadSuccess: onUploadSuccess,
    }
}

export const useDateInput = (initialDate: Date = new Date()): UseDateInput => {
    const [date, setDate] = React.useState(initialDate)
    const [value, setValue] = React.useState('')
    const [time, setTime] = React.useState('')

    const updateHours = (newHours: string) => {
        setTime(newHours)
    }

    const updateDate = (newDate: Date) => {
        setDate(newDate)
    }

    const handleChange = (text: string) => {
        setValue(text)
    }

    const getTime = (): number | null => {
        if (value === 'never') {
            return null
        }

        if (value === 'now') {
            return new Date().getTime()
        }

        if (date === null) {
            return date
        }

        const hours = time.split(':')[0] || '0'
        const minutes = time.split(':')[1] || '0'

        const newDate = new Date(date)
        newDate.setHours(parseInt(hours, 10))
        newDate.setMinutes(parseInt(minutes, 10))
        newDate.setSeconds(0)

        return newDate.getTime()
    }

    return {
        date,
        time,
        value,
        getTime,
        onChange: handleChange,
        updateHours,
        updateDate,
    }
}

export const usePrimaryColor = (service: ComponentsService, slug: string) => {
    const [value, setValue] = React.useState('#000000')

    React.useEffect(() => {
        const getCheckoutId = async () => {
            const versions = await service.getConfigurationsVersions(slug, 'checkout')
            if (versions.length > 0) {
                if (versions[0].id) {
                    return versions[0].id
                }
            }
            return
        }

        const getPrimaryColor = async () => {
            const checkoutId = await getCheckoutId()

            if (checkoutId) {
                const config = await service.getConfiguration(slug, 'checkout', checkoutId)
                if (config.components.length) {
                    const configBrandColor = get(config.components[0], 'value.primaryColor', value)
                    setValue(configBrandColor)
                }
            }
        }

        getPrimaryColor()
    }, [])

    return value
}

export const useVenueLocations = (accountSlug: string) => {
    const [locations, setLocations] = React.useState<VenueLocation[]>([])
    const openingTimesService = React.useContext(OpeningTimesServiceContext)

    React.useEffect(() => {
        openingTimesService.listLocations(accountSlug).then((result) => {
            const mappedResult = result.map((location) => {
                return { name: location.locationName, value: location.id }
            })
            setLocations(mappedResult)
        })
    }, [accountSlug])

    return locations
}
