import * as React from 'react'
import styled from 'styled-typed'
import { AdditionalDataNotice } from 'uiComponents/charts/styleComponents'
import { ActionButton } from 'uiComponents/buttons'
import { SearchContainer } from 'uiComponents/search/searchField'
import SearchWithButtonField from 'uiComponents/search/searchWithButton'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

interface SearchSectionProps {
    poolsCount: number
    onAddNew: () => void
}

export function SearchSection(props: SearchSectionProps) {
    return (
        <SearchContainer style={{ margin: '1.25rem' }}>
            <SearchWithButtonField style={{ width: '12.938rem' }} hideExtendedSearch />
            <Container>
                <AdditionalDataNotice style={{ maxWidth: '33em' }}>
                    You have <span>{props.poolsCount}</span> available campaign
                    {props.poolsCount === 1 ? '' : 's'}.
                </AdditionalDataNotice>
                <ActionButton
                    kind="action"
                    size="medium"
                    style={{ marginLeft: '1.5em' }}
                    onClick={props.onAddNew}
                    id="add-new-pool"
                    data-userpilot="reservation-codes-add-new-pool"
                >
                    + Add new
                </ActionButton>
            </Container>
        </SearchContainer>
    )
}
