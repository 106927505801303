import { Category } from 'venue/parkMap/models'
import { generalIcons, animalsIcons } from '@convious/tap-icons'
import { find } from 'lodash'
const tapIcons = [...generalIcons, ...animalsIcons]

export const parseCategoriesPoisToTreeData = (data: Category[] | undefined) => {
    const result = [] as any[]

    if (data) {
        data.forEach((category) => {
            result.push(category)
            if (category.pois) {
                category.pois.forEach((poi) => {
                    result.push({ ...poi, color: category.color })
                })
            }
        })
    }

    return result
}

export const sortCategories = (
    updatedCategoryId: string,
    initialIndex: number,
    finalIndex: number,
    categories: Category[],
): Category[] => {
    return categories.map((cat) => {
        if (cat.id === updatedCategoryId) {
            return { ...cat, position: finalIndex }
        } else if (initialIndex > finalIndex && cat.position >= finalIndex && cat.position <= initialIndex) {
            return { ...cat, position: cat.position + 1 }
        } else if (initialIndex < finalIndex && cat.position <= finalIndex && cat.position >= initialIndex) {
            return { ...cat, position: cat.position - 1 }
        }
        return cat
    })
}

export const getIconSvg = (iconName?: string): string | null => {
    if (!iconName) {
        return null
    }
    return find(tapIcons, { name: iconName })?.stringSvg ?? null
}
