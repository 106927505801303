import React from 'react'
import { format, startOfToday } from 'date-fns'
import { useAppSelector } from 'store/hooks'
import { getSelectedOrderItemsDetails } from 'orders/orders/redux'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { IOrderItems } from 'orders/schema'
import { BaseRouteParams, ReplaceMessagesFunc, withNavigation, WithNavigationProps } from 'hocs'
import useRedeemBarcodes from './useRedeemBarcodes'
import { Button } from '@mui/material'
import { ScannerSolid } from '@convious/icons'
import { formatISOString } from 'utils/dates'

interface RedeemTicketsProps {
    hideMessage: (id: string) => void
    replaceMessages: ReplaceMessagesFunc
}

const RedeemTickets: React.FC<WithNavigationProps<BaseRouteParams> & RedeemTicketsProps> = ({
    match,
    replaceMessages,
    hideMessage,
}) => {
    const { accountSlug } = match.params
    const { redeem } = useRedeemBarcodes({ accountSlug, replaceMessages, hideMessage })
    const userIsReseller = useAppSelector((state) => state.auth.user?.resellerId)
    const selectedOrderItems = useAppSelector(getSelectedOrderItemsDetails)
    const [selectedUnredeemedBarcodes, setSelectedUnredeemedBarcodes] = React.useState<string[]>([])
    const [showRedeemDialog, setShowRedeemDialog] = React.useState(false)
    const redeemDialogS = selectedUnredeemedBarcodes.length === 1 ? '' : 's'

    React.useEffect(() => {
        const unredeemedBarcodes = selectedOrderItems
            .filter(({ refunded, barcode }) => !refunded && !barcode?.redeemed && barcode?.status === 'available')
            .map(({ barcode }) => barcode?.barcode)

        setSelectedUnredeemedBarcodes(unredeemedBarcodes)
    }, [selectedOrderItems])

    const allBarcodesValid = () => {
        const invalidBarcodes = selectedOrderItems.filter(
            (orderItem) =>
                selectedUnredeemedBarcodes.includes(orderItem.barcode.barcode) && hasInvalidVisitDate(orderItem),
        )

        if (invalidBarcodes.length > 0) {
            const message =
                'There are dated tickets in your selection with the visit date in the future. If you still wish to redeem them, \
            please change the order visit date to today.'
            replaceMessages('invalid_visit-date', 'error', message)
        }
        return invalidBarcodes.length === 0
    }

    const hasInvalidVisitDate = (detailsItem: IOrderItems) => {
        if (!detailsItem.visitDate) {
            return false
        }

        const today = format(startOfToday(), 'yyyy-MM-dd')
        return formatISOString(detailsItem.visitDate, 'yyyy-MM-dd') > today
    }

    if (!userIsReseller && selectedUnredeemedBarcodes.length > 0) {
        return (
            <>
                {showRedeemDialog && (
                    <ConfirmationDialog
                        title={`Redeem ${selectedUnredeemedBarcodes.length} barcode${redeemDialogS}?`}
                        text="Redeeming can't be undone!"
                        buttonText="Redeem"
                        onCancel={() => setShowRedeemDialog(false)}
                        onConfirm={async () => {
                            await redeem(selectedUnredeemedBarcodes)
                            setShowRedeemDialog(false)
                        }}
                    />
                )}
                <Button
                    size="medium"
                    variant="outlined"
                    color="primary"
                    data-testid="orders-redeem-tickets-button"
                    onClick={() => {
                        if (selectedUnredeemedBarcodes.length > 0 && allBarcodesValid()) {
                            setShowRedeemDialog(true)
                        }
                    }}
                    startIcon={<ScannerSolid />}
                    className="action-button"
                >
                    Redeem
                </Button>
            </>
        )
    } else {
        return null
    }
}

export default withNavigation(RedeemTickets)
