import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import { connect } from 'react-redux'
import { State } from 'store'
import { Account } from 'auth/state'
import { useAccountChange } from 'reactUtils'
import { History } from 'history'
import { MessageKind } from 'uiComponents/messages'
import {
    OptionsService,
    OptionGroupDetails,
    EditorType,
    getOptionItemPrice,
    sortByPriority,
    getCopyKey,
} from 'products/options/optionsService'
import { ArticleService, LocalesInfo, Locale } from 'admin/articleService'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { delay } from 'utils'
import cloneDeep from 'lodash/cloneDeep'
import { ContainerBody } from 'uiComponents/settingsContainer'
import { Row, Col } from 'uiComponents/flex'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import Infotip from 'uiComponents/infotip'
import { SingleSelect, SingleSelectOption, TextInput, NumberInput } from 'uiComponents/input'
import { ButtonWrapper } from 'uiComponents/pageElements'
import { ActionButton, ActionButtonA } from 'uiComponents/buttons'
import { Tabs } from 'uiComponents/navigation/tabs'
import { PageLoader } from 'uiComponents/loaders'
import { FormTogglerCol, FormToggler } from 'uiComponents/input/toggle'
import { TextWithHorizontalLine } from 'uiComponents/pageElements'
import { OptionItemsList } from './optionItemsList'
import { PricingServiceContext } from 'products/pricing/context'

const SectionTitle = styled.div`
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 1em;
`

const dummyLocales: LocalesInfo = {
    locales: [{ code: 'EN', name: 'English' }],
    defaultLocale: 'EN',
}

const dummyOptionGroupDetails: OptionGroupDetails = {
    uuid: '',
    name: '',
    pricingId: '',
    priority: 1,
    minItems: 1,
    maxItems: 1,
    editorType: 'checkbox',
    mandatory: false,
    groupWithMainItem: true,
    nameTranslations: { key: 'option_group.name', text: { en: '' } },
    optionItems: [],
}

const EditorTypeOptions: SingleSelectOption[] = [
    { name: 'Checkbox', value: 'checkbox' },
    { name: 'Dropdown', value: 'dropdown' },
    { name: 'Multiselect', value: 'multiselect' },
]

interface OptionGroupDetailsProps {
    navigation: Navigation
    match: RouteMatch<{ id: string }>
    history: History
    accountSlug: string
    optionsService: OptionsService
    articleService: ArticleService
    accounts: Account[]
    setActiveSection: (section: string, header: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

function OptionGroupDetailsForm(props: OptionGroupDetailsProps) {
    const pricingService = React.useContext(PricingServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingMetadata, setLoadingMetadata] = useState<boolean>(false)
    const [groupData, setGroupData] = useState<OptionGroupDetails>(dummyOptionGroupDetails)
    const [locales, setLocales] = useState<LocalesInfo>(dummyLocales)
    const [activeLanguageIndex, setActiveLanguageIndex] = useState<number>(0)
    const [langWithMissingName, setLangWithMissingName] = useState<string>('')
    const [langWithMissingDescription, setLangWithMissingDescription] = useState<string>('')
    const [markErrors, setMarkErrors] = useState<boolean>(false)

    async function getGroupDetails() {
        try {
            setLoading(true)
            const data = await props.optionsService.getOptionGroupDetails(props.accountSlug, props.match.params.id)
            const pricingIds = data.optionItems.map((i) => i.pricingId)
            const pricing = await pricingService.getProductPricing(pricingIds)
            data.optionItems = data.optionItems
                .map((oi) => ({
                    ...oi,
                    price: getOptionItemPrice(oi.pricingId, pricing),
                }))
                .sort(sortByPriority)
            setGroupData(data)
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Could not get the options group with this ID, please try again later.',
            )
        } finally {
            setLoading(false)
        }
    }

    async function getMetadata() {
        try {
            setLoadingMetadata(true)
            const accountLocales = await props.articleService.getAccountLocales(props.accountSlug)
            setLocales(accountLocales)
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Could not get the options group details, please try again later.',
            )
        } finally {
            setLoadingMetadata(false)
        }
    }

    useEffect(() => {
        props.setActiveSection('optionGroups', 'Option Groups')
        getMetadata()
        const productId = props.match.params.id
        if (!!productId && productId !== 'new') {
            getGroupDetails()
        }
    }, [])

    useAccountChange(props.accountSlug, () =>
        props.history.push(`/account/${props.accountSlug}/products/option_groups/home`),
    )

    const setNameCopyKey = () => {
        const updatedData = cloneDeep(groupData)
        const enName = updatedData.nameTranslations.text.en
        updatedData.nameTranslations.key = getCopyKey(props.accountSlug, enName)
        setGroupData(updatedData)
        return updatedData
    }

    const cleanUpPayload = (payload: OptionGroupDetails) => {
        const cleanPayload = cloneDeep(payload)
        if (!hasFoodAndBev()) {
            delete cleanPayload.groupWithMainItem
        }
        if (!checkAtLeastOneDescription()) {
            cleanPayload.descriptionTranslations = null
        } else if (cleanPayload.descriptionTranslations && !cleanPayload.descriptionTranslations?.key) {
            const enName = cleanPayload.nameTranslations.text.en
            cleanPayload.descriptionTranslations.key = getCopyKey(props.accountSlug, enName, 'tooltip')
        }
        return cleanPayload
    }

    const checkAtLeastOneDescription = () => {
        return !!locales.locales.find((l) => !!groupData.descriptionTranslations?.text[l.code]?.trim())
    }

    const itemNoError = groupData.minItems && groupData.maxItems && groupData.minItems > groupData.maxItems

    const isDataValid = () => {
        let mandatoryFieldsError = false
        if (itemNoError || groupData.minItems === null || groupData.maxItems === null || groupData.priority === null) {
            mandatoryFieldsError = true
            setMarkErrors(true)
        }

        let missingLang = ''
        locales.locales.every((l, i) => {
            if (!groupData.nameTranslations.text[l.code]?.trim()) {
                missingLang = l.code
                setActiveLanguageIndex(i)
                return false
            } else {
                return true
            }
        })
        setLangWithMissingName(missingLang)

        if (!missingLang) {
            const atLeastOneDescription = checkAtLeastOneDescription()
            locales.locales.every((l, i) => {
                if (atLeastOneDescription && !groupData.descriptionTranslations?.text[l.code]?.trim()) {
                    missingLang = l.code
                    setActiveLanguageIndex(i)
                    return false
                } else {
                    return true
                }
            })
            setLangWithMissingDescription(missingLang)
        }

        return !mandatoryFieldsError && !missingLang
    }

    const onSaveOptionGroup = async () => {
        if (isDataValid()) {
            setLoading(true)
            try {
                if (props.match.params.id !== 'new') {
                    const savedGroup = await props.optionsService.updateOptionGroup(
                        props.accountSlug,
                        cleanUpPayload(groupData),
                        props.match.params.id,
                    )
                    setGroupData(savedGroup)
                    setLoading(false)
                } else {
                    const groupDataWithCopyKey = setNameCopyKey()
                    await props.optionsService.createOptionGroup(
                        props.accountSlug,
                        cleanUpPayload(groupDataWithCopyKey),
                    )
                    props.history.push(`/account/${props.accountSlug}/products/option_groups/home`)
                }
                props.replaceMessages('success', 'success', 'Product has been saved successfully.')
                await delay(3000)
                props.removeAllMessages()
            } catch {
                props.replaceMessages(
                    'server_error',
                    'error',
                    'Oops! Could not save the option group, please try again later.',
                )
                setLoading(false)
            }
        }
    }

    function onNameChange(e: React.ChangeEvent<HTMLInputElement>, language: Locale) {
        const updatedData = cloneDeep(groupData)
        updatedData.nameTranslations.text[language.code] = e.target.value
        setGroupData(updatedData)
        setLangWithMissingName('')
    }

    function onDescriptionChange(e: React.ChangeEvent<HTMLInputElement>, language: Locale) {
        const updatedData = cloneDeep(groupData)
        if (updatedData.descriptionTranslations) {
            updatedData.descriptionTranslations.text[language.code] = e.target.value
        } else {
            updatedData.descriptionTranslations = {
                key: '',
                text: { [language.code]: e.target.value },
            }
        }
        setGroupData(updatedData)
        setLangWithMissingName('')
    }

    function onEditorTypeChange(type: EditorType) {
        const updatedData = cloneDeep(groupData)
        updatedData.editorType = type
        setGroupData(updatedData)
    }

    function onMinItemsChange(e: React.ChangeEvent<HTMLInputElement>) {
        const updatedData = cloneDeep(groupData)
        updatedData.minItems = e.target.value === '' ? null : +e.target.value
        setGroupData(updatedData)
    }

    function onMaxItemsChange(e: React.ChangeEvent<HTMLInputElement>) {
        const updatedData = cloneDeep(groupData)
        updatedData.maxItems = e.target.value === '' ? null : +e.target.value
        setGroupData(updatedData)
    }

    function onPriorityChange(e: React.ChangeEvent<HTMLInputElement>) {
        const updatedData = cloneDeep(groupData)
        updatedData.priority = e.target.value === '' ? null : +e.target.value
        setGroupData(updatedData)
    }

    function onMandatoryChange(value: boolean) {
        const updatedData = cloneDeep(groupData)
        updatedData.mandatory = value
        setGroupData(updatedData)
    }

    function onGroupWithMainChange(value: boolean) {
        const updatedData = cloneDeep(groupData)
        updatedData.groupWithMainItem = value
        setGroupData(updatedData)
    }

    function hasFoodAndBev() {
        const activeAccount = props.accounts.find((x) => x.slug === props.accountSlug) || props.accounts[0]
        return activeAccount.hasFoodAndBeverage
    }

    const minItemsError = markErrors && (groupData.minItems === null || itemNoError)
    const maxItemsError = markErrors && (groupData.maxItems === null || itemNoError)
    const priorityError = markErrors && groupData.priority === null

    return (
        <ContainerBody style={{ flexDirection: 'column' }}>
            {(loading || loadingMetadata) && <PageLoader style={{ paddingBottom: '4em' }} />}
            {!loading && !loadingMetadata && (
                <>
                    <Row style={{ marginBottom: '3em' }}>
                        <Col span={6}>
                            <SectionTitle>Translations</SectionTitle>
                            <Tabs
                                titles={locales.locales.map((l) => l.name)}
                                setActive={setActiveLanguageIndex}
                                activeTabIndex={activeLanguageIndex}
                            >
                                {locales.locales.map((l, i) => {
                                    return (
                                        <div key={l.code}>
                                            <FormItem htmlFor="option-group-name">
                                                <FormItemName>
                                                    Options group name
                                                    <Infotip pointer="left">
                                                        This is the name of the options group that will appear in the
                                                        checkout.
                                                    </Infotip>
                                                </FormItemName>
                                                <TextInput
                                                    id={`option-group-name-${[l.code]}`}
                                                    name="option-group-name"
                                                    type="text"
                                                    maxLength={60}
                                                    value={
                                                        groupData.nameTranslations.text[
                                                            locales.locales[activeLanguageIndex].code
                                                        ] || ''
                                                    }
                                                    status={
                                                        langWithMissingName && langWithMissingName === l.code
                                                            ? 'error'
                                                            : 'normal'
                                                    }
                                                    placeholder="Add options group name..."
                                                    onChange={(e) => onNameChange(e, l)}
                                                    locale={l.code}
                                                    block
                                                />
                                            </FormItem>
                                            <ValidationMessage
                                                className={
                                                    langWithMissingName === l.code ? 'validation-message-visible' : ''
                                                }
                                            >
                                                This field is required
                                            </ValidationMessage>
                                            <FormItem htmlFor={`option-group-description-${[l.code]}`}>
                                                <FormItemName>
                                                    Options group description (optional)
                                                    <Infotip pointer="left">
                                                        This is the description of the options group that will appear in
                                                        the infotip in the checkout.
                                                    </Infotip>
                                                </FormItemName>
                                                <TextInput
                                                    id={`option-group-description-${[l.code]}`}
                                                    name="option-group-description"
                                                    type="text"
                                                    maxLength={100}
                                                    value={
                                                        groupData.descriptionTranslations?.text[
                                                            locales.locales[activeLanguageIndex].code
                                                        ] || ''
                                                    }
                                                    status={
                                                        langWithMissingDescription &&
                                                        langWithMissingDescription === l.code
                                                            ? 'error'
                                                            : 'normal'
                                                    }
                                                    placeholder="Add options group description..."
                                                    onChange={(e) => onDescriptionChange(e, l)}
                                                    locale={l.code}
                                                    block
                                                />
                                            </FormItem>
                                            <ValidationMessage
                                                className={
                                                    langWithMissingDescription === l.code
                                                        ? 'validation-message-visible'
                                                        : ''
                                                }
                                            >
                                                All translations of the description are required.
                                            </ValidationMessage>
                                        </div>
                                    )
                                })}
                            </Tabs>
                            <FormItem htmlFor="editor-type" style={{ marginTop: '-0.5em' }}>
                                <FormItemName>Selector type</FormItemName>
                                <SingleSelect
                                    id="editor-type"
                                    options={EditorTypeOptions}
                                    selected={groupData.editorType}
                                    height="2.5rem"
                                    noSelectOption="No type selected"
                                    onSelect={onEditorTypeChange}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={6}>
                                    <FormItem htmlFor="min-items">
                                        <FormItemName>Min items</FormItemName>
                                        <NumberInput
                                            align="left"
                                            id="min-items"
                                            placeholder="1"
                                            max={100}
                                            min={1}
                                            value={groupData.minItems !== null ? groupData.minItems : ''}
                                            onChange={onMinItemsChange}
                                            status={minItemsError ? 'error' : 'normal'}
                                            block
                                        />
                                    </FormItem>
                                    <ValidationMessage className={minItemsError ? 'validation-message-visible' : ''}>
                                        {groupData.minItems === null
                                            ? 'This field is required'
                                            : 'Min items cannot be greater than max items'}
                                    </ValidationMessage>
                                </Col>
                                <Col span={6}>
                                    <FormItem htmlFor="max-items">
                                        <FormItemName>Max items</FormItemName>
                                        <NumberInput
                                            align="left"
                                            id="max-items"
                                            placeholder="10"
                                            max={100}
                                            min={1}
                                            value={groupData.maxItems !== null ? groupData.maxItems : ''}
                                            onChange={onMaxItemsChange}
                                            status={maxItemsError ? 'error' : 'normal'}
                                            block
                                        />
                                    </FormItem>
                                    <ValidationMessage className={maxItemsError ? 'validation-message-visible' : ''}>
                                        {groupData.maxItems === null ? 'This field is required' : ''}
                                    </ValidationMessage>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '-0.2em' }}>
                                <Col span={6}>
                                    <FormItem htmlFor="priority">
                                        <FormItemName>
                                            Priority
                                            <Infotip pointer="left" maxWidth="20em">
                                                This number determines the order of options in the checkout. The option
                                                with the lowest number will be displayed on top.
                                            </Infotip>
                                        </FormItemName>
                                        <NumberInput
                                            align="left"
                                            id="priority"
                                            placeholder="1"
                                            max={100}
                                            min={1}
                                            value={groupData.priority === null ? '' : groupData.priority}
                                            onChange={onPriorityChange}
                                            status={priorityError ? 'error' : 'normal'}
                                            block
                                        />
                                    </FormItem>
                                    <ValidationMessage className={priorityError ? 'validation-message-visible' : ''}>
                                        This field is required
                                    </ValidationMessage>
                                </Col>
                                <Col span={6} />
                            </Row>
                            <Row
                                style={{
                                    margin: hasFoodAndBev() ? '1.3em 0 1em 0' : '3.3em 0 1em 0',
                                }}
                            >
                                <FormTogglerCol span={12}>
                                    <FormItemName>Mark as mandatory</FormItemName>
                                    <FormToggler
                                        id="mandatory"
                                        isOn={groupData.mandatory}
                                        onClick={onMandatoryChange}
                                    />
                                </FormTogglerCol>
                            </Row>
                            {hasFoodAndBev() && (
                                <Row>
                                    <FormTogglerCol span={12}>
                                        <FormItemName>
                                            Group with the main item
                                            <Infotip pointer="right" maxWidth="20em">
                                                Group with the main item in the restaurant UI instead of showing option
                                                separately.
                                            </Infotip>
                                        </FormItemName>
                                        <FormToggler
                                            id="group-with-main"
                                            isOn={!!groupData.groupWithMainItem}
                                            onClick={onGroupWithMainChange}
                                        />
                                    </FormTogglerCol>
                                </Row>
                            )}
                        </Col>
                    </Row>
                    <ButtonWrapper>
                        <ActionButtonA
                            size="large"
                            secondary
                            href={`/account/${props.accountSlug}/products/option_groups/home`}
                        >
                            Cancel
                        </ActionButtonA>
                        <ActionButton
                            id="save-option-group"
                            size="large"
                            onClick={onSaveOptionGroup}
                            style={{ marginLeft: '1.5em' }}
                        >
                            Save
                        </ActionButton>
                    </ButtonWrapper>
                    {props.match.params.id !== 'new' && (
                        <>
                            <TextWithHorizontalLine
                                style={{ margin: '2em 0' }}
                                text="OPTION GROUP ITEMS"
                                alignment="left"
                            />
                            <OptionItemsList
                                history={props.history}
                                accountSlug={props.accountSlug}
                                optionItems={groupData.optionItems}
                                groupUuid={groupData.uuid}
                                optionsService={props.optionsService}
                                loading={loading}
                                reloadList={getGroupDetails}
                                replaceMessages={props.replaceMessages}
                            />
                        </>
                    )}
                </>
            )}
        </ContainerBody>
    )
}

function mapStateToProps(state: State) {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
    }
}

export default withNavigation(connect(mapStateToProps)(OptionGroupDetailsForm))
