import { Page } from 'orders/schema'

export type ModalType = 'details' | 'message' | 'reject' | 'refund'
export type DisputeReason =
    | 'MERCHANDISE_OR_SERVICE_NOT_RECEIVED'
    | 'MERCHANDISE_OR_SERVICE_NOT_AS_DESCRIBED'
    | 'UNAUTHORISED'
    | 'CREDIT_NOT_PROCESSED'
    | 'DUPLICATE_TRANSACTION'
    | 'INCORRECT_AMOUNT'
    | 'PAYMENT_BY_OTHER_MEANS'
    | 'CANCELED_RECURRING_BILLING'
    | 'PROBLEM_WITH_REMITTANCE'
    | 'OTHER'
export type DisputeStatus =
    | 'WAITING_FOR_SELLER_RESPONSE'
    | 'WAITING_FOR_BUYER_RESPONSE'
    | 'UNDER_REVIEW'
    | 'OPEN'
    | 'RESOLVED'
    | 'OTHER'

export interface RefundPaypalDetails {
    id: string
    accountName: string
    location: string
    reason: string
    refundAmount: number
}

export const disputeReasonMap = {
    MERCHANDISE_OR_SERVICE_NOT_RECEIVED: 'Item not received',
    MERCHANDISE_OR_SERVICE_NOT_AS_DESCRIBED: 'Item not as described',
    UNAUTHORISED: 'Unauthorised',
    CREDIT_NOT_PROCESSED: 'Credit not processed',
    DUPLICATE_TRANSACTION: 'Duplicate transaction',
    INCORRECT_AMOUNT: 'Incorrect amount',
    PAYMENT_BY_OTHER_MEANS: 'Payment by other means',
    CANCELED_RECURRING_BILLING: 'Canceled recurring billing',
    PROBLEM_WITH_REMITTANCE: 'Problem with remittance',
    OTHER: 'Other',
}

export const disputeStatusMap = {
    WAITING_FOR_SELLER_RESPONSE: 'Needs seller response',
    WAITING_FOR_BUYER_RESPONSE: 'Needs buyer response',
    UNDER_REVIEW: 'Under review',
    OPEN: 'Open',
    RESOLVED: 'Resolved',
    OTHER: 'Other',
}

export interface Message {
    content: string
    postedAt: string
    postedBy: 'BUYER' | 'SELLER'
}

export interface Dispute {
    account: {
        slug: string
        name: string
    }
    amount: string
    currency: string
    disputeId: string
    id: string
    order: {
        number: string
        email: string
        status: string
        location: string
    }
    reason: DisputeReason
    status: DisputeStatus
    openedAt: string
    responseDueDate: string
    updatedAt: string
    messages: Message[]
    updatedBy: string
}

export interface DisputeDetails extends Dispute {
    messages: Message[]
}

export interface PaypalDisputesPage extends Page {
    entries: Dispute[]
}
