import * as React from 'react'
import styled from 'styled-typed'
import Svg from 'svg'
import { Headline } from 'uiComponents/typography'
import { CONTAINER_BOX_SHADOW } from 'uiComponents/settingsContainer'
import classNames from 'classnames'

const Card = styled.div<CardStyleProps>`
    cursor: ${(props) => (props.closed ? 'pointer' : 'auto')};
    background: #fff;
    border-radius: 10px;
    border: ${(props) => (props.closed ? '2px solid transparent' : `2px solid ${props.theme.colors.boyBlue}`)};
    box-shadow: ${CONTAINER_BOX_SHADOW};
    padding: 1.85em 0;
    max-height: 6.7em;
    position: relative;
    margin-bottom: 0.625em;
    overflow: hidden;
    transition: all 0.3s ease-in;
    transition: max-height 0.5s ease-in;

    &:hover {
        box-shadow: none;
    }

    &.expanded {
        max-height: 46em;
    }
`
const IconContainer = styled.div`
    width: 3.125em;
    height: 4em;
    position: absolute;
    left: 2.8em;
    top: 1.2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const Content = styled.div`
    margin-left: 8.3em;
    max-width: 75%;
`
const Header = styled(Headline)<CardStyleProps>`
    cursor: pointer;
    color: ${(props) => (props.closed ? props.theme.colors.textDark : props.theme.colors.boyBlue)};
    margin-bottom: 0.2rem;
    transition: color 0.3s ease-in;
`
const SubHeader = styled.div`
    cursor: pointer;
    font-size: 0.875em;
    line-height: 1.4em;
    font-weight: 300;
    opacity: 0;
    transition: opacity 0.8s;

    &.visible {
        opacity: 0.75;
    }
`
const ExpandButton = styled.div`
    cursor: pointer;
    font-size: 2.21em;
    position: absolute;
    right: 1.5em;
    top: 0.85em;
    opacity: 0;
    transition: all 0.5s;

    &.visible {
        opacity: 1;
    }
`
const ExpandedContent = styled.div`
    margin-top: 1em;
`

interface CardStyleProps {
    closed: boolean
}

interface InstructionsCardProps {
    children: React.ReactNode
    iconSrc: SVGAElement
    header: string
    closedSubHeader: string
    openedSubHeader?: string | JSX.Element
    className?: string
}

interface InstructionsCardState {
    closed: boolean
}

export class InstructionsCard extends React.Component<InstructionsCardProps, InstructionsCardState> {
    constructor(props: InstructionsCardProps) {
        super(props)
        this.state = {
            closed: true,
        }
    }

    toggleExpand = (e: React.MouseEvent<any>) => {
        if (e.currentTarget.className.indexOf('toggle') > -1) {
            this.setState({ closed: !this.state.closed })
        } else {
            if (this.state.closed) {
                this.setState({ closed: false })
            }
        }
    }

    render() {
        const { iconSrc, header, closedSubHeader, openedSubHeader, children, className } = this.props
        const { closed } = this.state

        return (
            <Card
                id="card"
                closed={closed}
                className={classNames(className, {
                    expanded: !closed,
                })}
                onClick={this.toggleExpand}
            >
                <IconContainer onClick={this.toggleExpand}>
                    <Svg src={iconSrc} />
                </IconContainer>
                <Content>
                    <Header size={6} className="toggle" closed={closed} onClick={this.toggleExpand}>
                        {header}
                    </Header>
                    <SubHeader
                        className={closed || !openedSubHeader ? 'toggle visible' : 'toggle'}
                        onClick={this.toggleExpand}
                    >
                        {closedSubHeader}
                    </SubHeader>
                    <SubHeader
                        className={!closed && openedSubHeader ? 'toggle visible' : 'toggle'}
                        style={{ position: 'relative', top: '-1.4em' }}
                        onClick={this.toggleExpand}
                    >
                        {openedSubHeader}
                    </SubHeader>
                    <ExpandedContent>{children}</ExpandedContent>
                </Content>
                <ExpandButton onClick={this.toggleExpand} className={closed ? 'toggle visible' : 'toggle'}>
                    &#43;
                </ExpandButton>
                <ExpandButton className={closed ? 'toggle' : 'toggle visible'} onClick={this.toggleExpand}>
                    &#8722;
                </ExpandButton>
            </Card>
        )
    }
}
