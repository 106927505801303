import * as React from 'react'
import styled from 'styled-typed'
import { Redirect, Route, Switch } from 'react-router-dom'
import { withRoutePermission } from 'routesUtils'
import { History, Location } from 'history'
import { Account } from 'auth/state'
import {
    Container,
    ContainerBody,
    ContainerHeading,
    FullWidthContainerBody,
    HeadingSectionName,
} from 'uiComponents/settingsContainer'
import { getSubheader } from './utils'
import { PageHeading, PageTitle } from 'uiComponents/typography'
import { Messages } from 'uiComponents/messages'
import { MessageProps, withMessages } from 'hocs'
import Feature from 'features/feature'
import { withFeatures } from 'features'
import { withPermission } from 'admin/hocs'
import { StatsService } from 'http/statsService'
import Permission from 'admin/permissionRequired'
import { TicketTemplatesService } from 'ticketTemplates/ticketTemplatesService'
import { parseSearch } from 'navigation'
import { ArticleService } from 'admin/articleService'
import { AddNewTopButton, ExceptionsTopButtons, ProductsTopButtons } from 'products/topButtons'
import { ProductListWithPath } from 'products/crud/common'
import { ManagementService } from 'settings/accountSettings/contract/managementService'
import ProductPricingDetailRouter from 'products/pricing/productPricingDetailRouter'
import PricingExceptionPage from 'products/pricing/exceptions/exceptionDetailPage'
import PricingExceptionsListPage from 'products/pricing/exceptions/exceptionsListPage'
import { PricingService } from 'products/pricing/pricingService'
import ValidityExceptionsListPage from 'products/validity/exceptions/exceptionsList'
import ValidityExceptionPage from 'products/validity/exceptions/exceptionDetailPage'
import ProductValidityDetailPage from 'products/validity/articleDetailPage'
import { ArticleConfigurationService } from 'products/articleConfigurationService'
import TimeSlotsListPage from 'timeSlots/list'
import TimeSlotGroupForm from 'timeSlots/form'
import InventoryListPage from 'inventory/list'
import OptionGroupsListPage from 'products/options/groupsList'
import OptionGroupDetails from 'products/options/groupDetails'
import OptionItemDetails from 'products/options/optionItemDetails'
import { OptionsService } from 'products/options/optionsService'
import CrowdControlForm from 'inventory/crowdControl'
import InventoryRuleForm from 'inventory/ruleEditForm'
import { ChannelsService } from 'channels/channelsService'
import HomePage from 'products/home'
import ProductTable from './productTable'

const TAB_WIDTH = '11.3em'

const Heading = styled(HeadingSectionName)`
    width: ${TAB_WIDTH};
`

const Header = styled.div`
    margin-bottom: 1.375em;
    position: relative;
    opacity: 1;
`

interface ProductsContainerProps {
    history: History
    accountSlug: string
    accounts: Account[]
    location: Location
    articleConfigurationService: ArticleConfigurationService
    articleService: ArticleService
    pricingService: PricingService
    channelsService: ChannelsService
    managementService: ManagementService
    ticketTemplatesService: TicketTemplatesService
    statsService: StatsService
    optionsService: OptionsService
    hasPermission: (permission: string, accountSlug: string) => boolean
    hasFeature: (feature: string, accountSlug: string) => boolean
}

interface ProductsContainerState {
    header: string
    subheader: React.ReactNode
    activeSection: string
    generalMessage: string
    generalMessageVisible: boolean
    flattenedCategories: ProductListWithPath[] | null
    menuClosed: boolean
    loadingProducts: boolean
    showBulkButtons: boolean
    bulkEditPricing: boolean
    bulkEditValidity: boolean
}

class ProductsContainer extends React.Component<ProductsContainerProps & MessageProps, ProductsContainerState> {
    constructor(props: ProductsContainerProps & MessageProps) {
        super(props)
        this.state = {
            header: 'Products Overview',
            subheader: '',
            activeSection: 'productsList',
            generalMessage: '',
            generalMessageVisible: false,
            flattenedCategories: null,
            menuClosed: false,
            loadingProducts: false,
            showBulkButtons: false,
            bulkEditPricing: false,
            bulkEditValidity: false,
        }
    }

    setActiveSectionAndHeader = (section: string, header?: string) => {
        const subheader = getSubheader(section)
        this.setState({ subheader })

        if (header) {
            this.setState({ header })
        }

        if (
            section === 'productsList' &&
            (location.pathname.includes('crud') || location.pathname.includes('detail'))
        ) {
            return
        }

        this.setState({ activeSection: section })
    }

    generateParentsPath = async (
        array: { name: string; path: string; id: string }[],
        id: string,
        allLists: ProductListWithPath[] = this.state.flattenedCategories || [],
    ): Promise<{ name: string; path: string; id: string }[] | null> => {
        try {
            const parentPl = allLists.find((l) => l.uuid === id)
            const parentCategoryPath = {
                name: parentPl?.name || 'Unknown',
                id: id,
                path: `/account/${this.props.accountSlug}/products/home?listIds=`,
            }
            array.unshift(parentCategoryPath)

            const parentId = parentPl?.parentIds[parentPl?.parentIds.length - 2]
            if (!!parentId) {
                return await this.generateParentsPath(array, parentId, allLists)
            } else {
                return array
            }
        } catch {
            this.props.replaceMessages('server_error', 'error', 'Oops! An unknown error occurred.')
            return null
        }
    }

    render() {
        const {
            location,
            history,
            accountSlug,
            pricingService,
            articleConfigurationService,
            statsService,
            managementService,
        } = this.props
        const { activeSection, header, subheader } = this.state
        const params = parseSearch(location.search)
        const viewMode =
            location.pathname.indexOf('nested') > -1
                ? 'nested'
                : location.pathname.indexOf('flat') > -1
                ? 'flat'
                : ((params.from || 'nested') as 'nested' | 'flat')

        type SourceType = '?from=flat' | '?from=nested'
        const source = `?from=${params.from || viewMode}` as SourceType
        const routePermissionCheckData = {
            accounts: this.props.accounts,
            accountSlug: accountSlug,
            history: this.props.history,
            hasPermission: this.props.hasPermission,
        }
        return (
            <div style={{ position: 'relative' }} id="pricing-settings">
                <Messages messages={this.props.messages} hideMessage={this.props.hideMessage} />
                <Header
                    style={{
                        marginBottom: '0',
                        minHeight: '0',
                    }}
                >
                    <PageTitle data-userpilot="products-page-header">{header}</PageTitle>
                    <PageHeading fullWidth style={{ marginBottom: '5rem' }}>
                        {subheader}
                    </PageHeading>
                </Header>
                {(location.pathname.includes('products/home') ||
                    location.pathname.includes('/products/productTable')) && (
                    <ProductsTopButtons
                        showBulkButtons={this.state.showBulkButtons}
                        accountSlug={accountSlug}
                        loading={this.state.loadingProducts}
                        showAdjustView={!location.pathname.includes('/products/productTable')}
                        showBulkEditPricing={() => this.setState({ bulkEditPricing: true })}
                        showBulkEditValidity={() => this.setState({ bulkEditValidity: true })}
                        origin={viewMode}
                        history={this.props.history}
                    />
                )}
                <Permission name="edit_pricing_settings" accountSlug={accountSlug}>
                    {location.pathname.indexOf('pricing/exceptions/list') > -1 && (
                        <ExceptionsTopButtons accountSlug={accountSlug} for="pricing" />
                    )}
                </Permission>
                <Permission name="edit_product_validity" accountSlug={accountSlug}>
                    {location.pathname.indexOf('validity/exceptions/list') > -1 && (
                        <ExceptionsTopButtons accountSlug={accountSlug} for="validity" />
                    )}
                </Permission>
                <Permission name="partner_admin" accountSlug={accountSlug}>
                    {location.pathname.indexOf('option_groups/home') > -1 && (
                        <AddNewTopButton accountSlug={accountSlug} for="option_groups" />
                    )}
                    {location.pathname.indexOf('inventory/home') > -1 && (
                        <AddNewTopButton accountSlug={accountSlug} for="inventory" />
                    )}
                    {location.pathname.indexOf('time_slots/home') > -1 && (
                        <AddNewTopButton accountSlug={accountSlug} for="time_slots" />
                    )}
                </Permission>
                <Container>
                    <ContainerHeading style={{ paddingLeft: '0.3em' }}>
                        <Heading
                            className={activeSection === 'productsList' ? 'active' : ''}
                            to={`/account/${accountSlug}/products/home/${viewMode}`}
                        >
                            Products
                        </Heading>
                        <Feature name="Pricing" accountSlug={accountSlug}>
                            <Permission name="view_pricing_settings" accountSlug={accountSlug}>
                                <Heading
                                    id="exceptionsTab"
                                    className={activeSection === 'pricingExceptions' ? 'active' : ''}
                                    to={`/account/${accountSlug}/products/pricing/exceptions/list${source}`}
                                >
                                    Pricing exceptions
                                </Heading>
                            </Permission>
                        </Feature>
                        <Permission name="view_product_validity" accountSlug={accountSlug}>
                            <Heading
                                id="exceptionsTab"
                                className={activeSection === 'validityExceptions' ? 'active' : ''}
                                to={`/account/${accountSlug}/products/validity/exceptions/list${source}`}
                            >
                                Validity exceptions
                            </Heading>
                        </Permission>
                        <Permission name="partner_admin" accountSlug={accountSlug}>
                            <Heading
                                id="timeSlotsTab"
                                className={activeSection === 'timeSlots' ? 'active' : ''}
                                to={`/account/${accountSlug}/products/time_slots/home${source}`}
                            >
                                Time slots
                            </Heading>
                        </Permission>
                        <Permission name="partner_admin" accountSlug={accountSlug}>
                            <Heading
                                id="optionGroupsTab"
                                className={activeSection === 'optionGroups' ? 'active' : ''}
                                to={`/account/${accountSlug}/products/option_groups/home${source}`}
                            >
                                Option groups
                            </Heading>
                        </Permission>
                        <Permission name="partner_admin" accountSlug={accountSlug}>
                            <Heading
                                id="inventoryTab"
                                className={activeSection === 'inventory' ? 'active' : ''}
                                to={`/account/${accountSlug}/products/inventory/home${source}`}
                            >
                                Crowd Control
                            </Heading>
                        </Permission>
                    </ContainerHeading>
                    <Switch location={location}>
                        <Route path="/account/:accountSlug/products/productTable" component={ProductTable} />
                        <Route
                            path="/account/:accountSlug/products/pricing/:id/detail"
                            render={() =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['view_pricing_settings'],
                                    component: (
                                        <ContainerBody>
                                            <ProductPricingDetailRouter
                                                pricingService={pricingService}
                                                articleService={this.props.articleService}
                                                channelsService={this.props.channelsService}
                                                articleConfigurationService={articleConfigurationService}
                                                history={history}
                                                setActiveSection={this.setActiveSectionAndHeader}
                                                replaceTopMessages={this.props.replaceMessages}
                                                hideTopMessage={this.props.hideMessage}
                                                hasPermission={this.props.hasPermission}
                                                viewMode={viewMode}
                                            />
                                        </ContainerBody>
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/products/pricing/exceptions/list"
                            render={() =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['view_pricing_settings'],
                                    component: (
                                        <FullWidthContainerBody>
                                            <PricingExceptionsListPage
                                                accountSlug={accountSlug}
                                                history={history}
                                                pricingService={pricingService}
                                                articleService={this.props.articleService}
                                                managementService={managementService}
                                                setActiveSection={this.setActiveSectionAndHeader}
                                                replaceTopMessages={this.props.replaceMessages}
                                                hasPermission={this.props.hasPermission}
                                            />
                                        </FullWidthContainerBody>
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/products/pricing/exceptions/:id"
                            render={() =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['view_pricing_settings'],
                                    component: (
                                        <ContainerBody>
                                            <PricingExceptionPage
                                                pricingService={pricingService}
                                                articleService={this.props.articleService}
                                                channelsService={this.props.channelsService}
                                                history={history}
                                                location={location}
                                                statsService={statsService}
                                                setActiveSection={this.setActiveSectionAndHeader}
                                                replaceTopMessages={this.props.replaceMessages}
                                                removeAllMessages={this.props.removeAllMessages}
                                                hasPermission={this.props.hasPermission}
                                            />
                                        </ContainerBody>
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/products/validity/:id/detail"
                            render={() =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['view_product_validity'],
                                    component: (
                                        <ContainerBody>
                                            <ProductValidityDetailPage
                                                history={history}
                                                location={location}
                                                setActiveSection={this.setActiveSectionAndHeader}
                                                replaceTopMessages={this.props.replaceMessages}
                                                hideTopMessage={this.props.hideMessage}
                                                removeAllMessages={this.props.removeAllMessages}
                                                hasFeature={this.props.hasFeature}
                                                viewMode={viewMode}
                                            />
                                        </ContainerBody>
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/products/validity/exceptions/list"
                            render={() =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['view_product_validity'],
                                    component: (
                                        <FullWidthContainerBody>
                                            <ValidityExceptionsListPage
                                                articleConfigurationService={articleConfigurationService}
                                                articleService={this.props.articleService}
                                                accountSlug={accountSlug}
                                                setActiveSection={this.setActiveSectionAndHeader}
                                                replaceTopMessages={this.props.replaceMessages}
                                                standalonePage
                                            />
                                        </FullWidthContainerBody>
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/products/validity/exceptions/:id"
                            render={() =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['view_product_validity'],
                                    component: (
                                        <ValidityExceptionPage
                                            articleConfigurationService={articleConfigurationService}
                                            articleService={this.props.articleService}
                                            history={history}
                                            location={location}
                                            setActiveSection={this.setActiveSectionAndHeader}
                                            replaceTopMessages={this.props.replaceMessages}
                                            removeAllMessages={this.props.removeAllMessages}
                                        />
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/products/time_slots/home"
                            render={() =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['partner_admin'],
                                    component: (
                                        <FullWidthContainerBody>
                                            <TimeSlotsListPage
                                                history={this.props.history}
                                                setActiveSection={this.setActiveSectionAndHeader}
                                                replaceMessages={this.props.replaceMessages}
                                                removeAllMessages={this.props.removeAllMessages}
                                                hasPermission={this.props.hasPermission}
                                                accountSlug={this.props.accountSlug}
                                            />
                                        </FullWidthContainerBody>
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/products/time_slots/edit/:id"
                            render={() =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['partner_admin'],
                                    component: (
                                        <TimeSlotGroupForm
                                            history={this.props.history}
                                            accountSlug={this.props.accountSlug}
                                            setActiveSection={this.setActiveSectionAndHeader}
                                            replaceMessages={this.props.replaceMessages}
                                            removeAllMessages={this.props.removeAllMessages}
                                        />
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/products/time_slots/duplicate/:id"
                            render={() =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['partner_admin'],
                                    component: (
                                        <TimeSlotGroupForm
                                            prototype
                                            history={this.props.history}
                                            accountSlug={this.props.accountSlug}
                                            setActiveSection={this.setActiveSectionAndHeader}
                                            replaceMessages={this.props.replaceMessages}
                                            removeAllMessages={this.props.removeAllMessages}
                                        />
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/products/option_groups/home"
                            render={() =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['partner_admin'],
                                    component: (
                                        <FullWidthContainerBody>
                                            <OptionGroupsListPage
                                                history={this.props.history}
                                                accountSlug={this.props.accountSlug}
                                                setActiveSection={this.setActiveSectionAndHeader}
                                                replaceMessages={this.props.replaceMessages}
                                                optionsService={this.props.optionsService}
                                            />
                                        </FullWidthContainerBody>
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/products/option_groups/group_details/:id"
                            render={() =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['partner_admin'],
                                    component: (
                                        <FullWidthContainerBody>
                                            <OptionGroupDetails
                                                history={this.props.history}
                                                accountSlug={this.props.accountSlug}
                                                replaceMessages={this.props.replaceMessages}
                                                removeAllMessages={this.props.removeAllMessages}
                                                optionsService={this.props.optionsService}
                                                articleService={this.props.articleService}
                                                setActiveSection={this.setActiveSectionAndHeader}
                                            />
                                        </FullWidthContainerBody>
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/products/option_groups/option_item/:groupUuid/:itemId"
                            render={() =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['partner_admin'],
                                    component: (
                                        <FullWidthContainerBody>
                                            <OptionItemDetails
                                                history={this.props.history}
                                                accountSlug={this.props.accountSlug}
                                                replaceMessages={this.props.replaceMessages}
                                                removeAllMessages={this.props.removeAllMessages}
                                                optionsService={this.props.optionsService}
                                                articleService={this.props.articleService}
                                                setActiveSection={this.setActiveSectionAndHeader}
                                            />
                                        </FullWidthContainerBody>
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/products/inventory/home"
                            render={() =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['partner_admin'],
                                    component: (
                                        <FullWidthContainerBody>
                                            <InventoryListPage
                                                history={this.props.history}
                                                accountSlug={this.props.accountSlug}
                                                setActiveSection={this.setActiveSectionAndHeader}
                                                replaceMessages={this.props.replaceMessages}
                                                source={source}
                                            />
                                        </FullWidthContainerBody>
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/products/inventory/item/:id"
                            render={() =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['partner_admin'],
                                    component: (
                                        <FullWidthContainerBody>
                                            <CrowdControlForm
                                                history={this.props.history}
                                                accountSlug={this.props.accountSlug}
                                                setActiveSection={this.setActiveSectionAndHeader}
                                                replaceMessages={this.props.replaceMessages}
                                                removeAllMessages={this.props.removeAllMessages}
                                            />
                                        </FullWidthContainerBody>
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/products/inventory/rule/:ruleId/item/:inventoryId"
                            render={() =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['partner_admin'],
                                    component: (
                                        <FullWidthContainerBody>
                                            <InventoryRuleForm
                                                history={this.props.history}
                                                accountSlug={this.props.accountSlug}
                                                setActiveSection={this.setActiveSectionAndHeader}
                                                replaceMessages={this.props.replaceMessages}
                                                removeAllMessages={this.props.removeAllMessages}
                                            />
                                        </FullWidthContainerBody>
                                    ),
                                })
                            }
                        />
                        <Route
                            path={['/account/:accountSlug/products/home', '/account/:accountSlug/products/crud']}
                            render={() => (
                                <HomePage
                                    location={location}
                                    accountSlug={accountSlug}
                                    pricingService={pricingService}
                                    articleService={this.props.articleService}
                                    optionsService={this.props.optionsService}
                                    history={history}
                                    setActiveSection={this.setActiveSectionAndHeader}
                                    replaceTopMessages={this.props.replaceMessages}
                                    hasPermission={this.props.hasPermission}
                                    hasFeature={this.props.hasFeature}
                                    addMessage={this.props.addMessage}
                                    removeAllMessages={this.props.removeAllMessages}
                                    hideMessage={this.props.hideMessage}
                                    routePermissionCheckData={routePermissionCheckData}
                                    ticketTemplatesService={this.props.ticketTemplatesService}
                                    viewMode={viewMode}
                                    setShowBulkButtons={(v: boolean) => this.setState({ showBulkButtons: v })}
                                    bulkEditPricing={this.state.bulkEditPricing}
                                    bulkEditValidity={this.state.bulkEditValidity}
                                    cancelBulkEditPricing={() => this.setState({ bulkEditPricing: false })}
                                    cancelBulkEditValidity={() => this.setState({ bulkEditValidity: false })}
                                    setLoading={(v: boolean) => this.setState({ loadingProducts: v })}
                                />
                            )}
                        />
                        <Route render={() => <Redirect to={`/account/${accountSlug}/products/home/nested`} />} />
                    </Switch>
                </Container>
            </div>
        )
    }
}

export default withFeatures(withMessages(withPermission(ProductsContainer)))
