import * as React from 'react'
import { useEffect } from 'react'
import ActionButtons from '../components/actionButtons'
import Header from '../components/header'
import { usePushNotificationsContext } from '../context/PushNotifications'
import ContentForm from '../forms/content'
import { TemplateContainer, TemplateDescription, TemplateName } from './target'

export const ContentPageHeader = () => {
    return (
        <>
            <ActionButtons />
            <TemplateName>Customize your message</TemplateName>
            <TemplateDescription>Edit and stylise the message for your audience.</TemplateDescription>
        </>
    )
}

const ContentPage = () => {
    const { contentForm, setContentForm, isFormFilled } = usePushNotificationsContext()

    useEffect(() => {
        isFormFilled()
    }, [])

    return (
        <div>
            <Header />
            <TemplateContainer>
                <ContentPageHeader />
                <ContentForm
                    onSubmit={(data) => {
                        setContentForm(data)
                    }}
                    data={contentForm}
                />
            </TemplateContainer>
        </div>
    )
}

export default ContentPage
