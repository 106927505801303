import * as React from 'react'
import { connect } from 'react-redux'
import { State } from 'store'
import { AuthTicket } from 'http/oauthService'
import { LoginService } from 'http/loginService'
import { DownloadData } from 'reports/schema'

interface DownloadLinkProps {
    ticket: AuthTicket
    downloadEndpoint: string
    loginService: LoginService
    children: React.ReactNode
    downloadData?: DownloadData
    className?: string
    style?: React.CSSProperties
    downloadAllowed?: () => boolean
    onDownloadInitiated?: (endpoint: string, data?: DownloadData) => void
}

export class DownloadLink extends React.Component<DownloadLinkProps> {
    submitForm = async () => {
        if (this.props.downloadAllowed && !this.props.downloadAllowed()) {
            return
        }

        try {
            await this.props.loginService.ensureTokenNotExpired(this.props.ticket)
            let frame = document.getElementById('download-frame') as HTMLIFrameElement
            if (!frame) {
                frame = document.createElement('iframe')
                frame.setAttribute('id', 'download-frame')
                frame.setAttribute('name', 'download-frame')
                frame.style.display = 'none'
                document.body.appendChild(frame)
            }
            const doc = frame.contentDocument || (frame.contentWindow ? frame.contentWindow.document : null)
            if (doc === null) {
                // tslint:disable-next-line no-console
                console.error('Unable to access iframe document')
                return
            }
            doc.body.innerHTML = ''
            const form = doc.createElement('form') as HTMLFormElement
            form.setAttribute('method', 'POST')
            form.setAttribute('action', this.props.downloadEndpoint)
            const token = doc.createElement('input') as HTMLInputElement
            token.setAttribute('type', 'hidden')
            token.setAttribute('name', 'token')
            token.setAttribute('value', this.props.ticket.accessToken)
            form.appendChild(token)

            if (this.props.downloadData) {
                const downloadData = doc.createElement('input') as HTMLInputElement
                downloadData.setAttribute('type', 'hidden')
                downloadData.setAttribute('name', 'download_data')
                downloadData.setAttribute('value', JSON.stringify(this.props.downloadData))
                form.appendChild(downloadData)
            }
            doc.body.appendChild(form)
            form.submit()
            if (this.props.onDownloadInitiated) {
                this.props.onDownloadInitiated(this.props.downloadEndpoint, this.props.downloadData)
            }
        } catch (e) {}
    }

    render() {
        return (
            <a onClick={this.submitForm} className={this.props.className} style={this.props.style}>
                {this.props.children}
            </a>
        )
    }
}

const mapStateToProps = (state: State) => {
    return {
        ticket: state.auth.ticket,
    }
}

export default connect(mapStateToProps)(DownloadLink)
