import React, { useState, useEffect } from 'react'
import { usePrevious } from 'reactUtils'
import { format } from 'date-fns'
import { DateRange } from 'dateRanges'
import { EngageToolsService, StatsItems } from 'engageTools/engageToolsService'
import { startOfToday, addDays } from 'date-fns'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { MessageKind } from 'uiComponents/messages'
import TAPStatsContainer from './statsContainerTAP'
import { EngageToolName } from './productContainer'
import { TAPAppIcon } from '../icons'

interface ProductContainerProps {
    accountSlug: string
    engageToolsService: EngageToolsService
    dateRange: DateRange
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
}

function ProductContainer(props: ProductContainerProps) {
    const [loading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<StatsItems[]>([])

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    useEffect(() => {
        if (prevAccountSlug !== props.accountSlug || !areDateRangeDatesEqual(prevDateRange, props.dateRange)) {
            getData()
        }
    }, [props.accountSlug, props.dateRange])

    const getData = async () => {
        try {
            setLoading(true)
            const dateFrom = format(props.dateRange.from || new Date(0), 'yyyy-MM-dd')
            const dateTo = format(props.dateRange.to || addDays(startOfToday(), 1), 'yyyy-MM-dd')
            const stats = await props.engageToolsService.getTAPAppStats(props.accountSlug, dateFrom, dateTo)
            setData(stats[0].values)
            setLoading(false)
        } catch {
            props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! TAP App stats could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }

    return (
        <>
            <EngageToolName>
                <TAPAppIcon />
                <span>TAP App</span>
            </EngageToolName>
            <TAPStatsContainer data={data} activeAccount={props.accountSlug} loading={loading} />
        </>
    )
}

export default ProductContainer
