import React from 'react'
import { useField } from 'formik'
import { startOfMonth, endOfMonth } from 'date-fns'
import DateRangePicker, { DateRangePickerProps } from 'uiComponents/popups/comparisonDateRangePicker'
import FieldWrapper from '../fieldWrapper'
import { useCustomId, useGetComponentState } from '../_shared/hooks'
import './index.css'
import { parseISODate } from 'utils/dates'

interface DateRangeFieldFormikProps
    extends Omit<DateRangePickerProps, 'onChange' | 'range' | 'navigation' | 'match' | 'theme'> {
    name: string
    id?: string
    label?: string
}

const DateRangeFieldFormik: React.FC<DateRangeFieldFormikProps> = ({ name, id, label, ...props }) => {
    const [{ value }, { error }, { setValue }] = useField(name)
    const innerId = useCustomId(id)
    const state = useGetComponentState(name)

    return (
        <FieldWrapper id={innerId} status={state} label={label} error={error}>
            <div style={{ position: 'relative' }} className="date-picker">
                <DateRangePicker
                    {...props}
                    range={{
                        from: parseISODate(value?.from) || startOfMonth(new Date()),
                        to: parseISODate(value?.to) || endOfMonth(new Date()),
                        name: 'custom',
                        period: 'day',
                    }}
                    onChange={({ from, to }) => {
                        setValue({ from: from.toISOString(), to: to.toISOString() })
                    }}
                    style={{ zIndex: 2, top: 'unset' }}
                />
            </div>
        </FieldWrapper>
    )
}

export default DateRangeFieldFormik
