import React, { useCallback, useEffect, useState } from 'react'
import { GoogleMap, GroundOverlay } from '@react-google-maps/api'
import styled, { useTheme } from 'styled-components'
import { ActionButton } from 'uiComponents/buttons'

import { useParkMapViewerContext } from '../../context/mapViewer'
import { PreviewDialog } from '../mapPreview'
import CustomMarkers from './customMarkers'
import InformationBox from '../informationBox'
import OverlaySwitch from './overlaySwitch'
import ParkMapViewerRegionOverlay from './regionOverlay'
import ParkMapTypeControl from './typeControl'
import ParkMapZoomControl from './zoomControl'
import { LoaderWrapper } from 'uiComponents/loaders'
import useParkMap from '../../hooks/useParkMap'
import { useLocation } from 'react-router-dom'
import { useSelectedParkMapContext } from 'venue/parkMap/context/selectedParkMap'
import { jsonStyleBgColor } from 'venue/parkMap/helpers'
import Sticky from 'react-sticky-el'

const Container = styled.div`
    position: relative;
    height: 100%;
`

const Info = styled.div`
    position: absolute;
    top: 70px;
    left: 70px;
    width: 380px;
`

const ButtonContainer = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 20px;
    justify-content: center;
`

export const PreviewButton = styled(ActionButton)`
    background: ${(props) => props.theme.colors.white};
    &:hover:not(:disabled) {
        background: ${(props) => props.theme.colors.background};
    }
`

const MapViewer = () => {
    const theme = useTheme()
    const location = useLocation()
    const {
        isLoaded,
        map,
        setMap,
        coords,
        setCoords,
        zoom,
        mapType,
        setZoom,
        setIsRegionChanged,
        revertMapToInitialState,
        overlayIsVisible,
    } = useParkMapViewerContext()
    const { selectedParkMap } = useSelectedParkMapContext()
    const { zoomIn, zoomOut } = useParkMap()
    const isDetailsPage = location.pathname.includes('details')
    const [previewVisible, setPreviewVisible] = useState<boolean>(false)
    const isPoiPage = location.pathname.includes('cat_poi')

    useEffect(() => {
        revertMapToInitialState()
    }, [location.pathname])

    const onLoad = useCallback((map: google.maps.Map) => {
        setMap(map)
    }, [])

    const onDragEnd = useCallback(() => {
        setCoords({
            lat: map?.getCenter()?.lat() || 0,
            lng: map?.getCenter()?.lng() || 0,
        })
        setIsRegionChanged(true)
    }, [map])

    const onZoomChanged = useCallback(() => {
        const newZoom = map?.getZoom()
        if (newZoom) {
            setZoom(newZoom)
            setIsRegionChanged(true)
        }
    }, [map])

    return (
        <Container>
            {previewVisible && <PreviewDialog onCancel={() => setPreviewVisible(false)} />}
            <Sticky>
                <LoaderWrapper loading={!isLoaded}>
                    {!!isLoaded ? (
                        <GoogleMap
                            mapContainerStyle={{
                                height: '100%',
                                width: '100%',
                                minHeight: map?.getDiv().offsetWidth ?? 0,
                            }}
                            center={coords}
                            zoom={zoom}
                            mapTypeId={mapType}
                            onLoad={onLoad}
                            onDragEnd={onDragEnd}
                            onZoomChanged={onZoomChanged}
                            options={{
                                disableDefaultUI: true,
                                styles:
                                    selectedParkMap?.overlay?.bgColor && overlayIsVisible
                                        ? jsonStyleBgColor(selectedParkMap?.overlay?.bgColor)
                                        : [],
                            }}
                        >
                            {selectedParkMap?.overlay && selectedParkMap.overlay.image && overlayIsVisible && (
                                <GroundOverlay
                                    url={selectedParkMap.overlay.image}
                                    bounds={
                                        new google.maps.LatLngBounds(
                                            {
                                                lat: selectedParkMap.overlay.southEast.lat,
                                                lng: selectedParkMap.overlay.northWest.lng,
                                            },
                                            {
                                                lat: selectedParkMap.overlay.northWest.lat,
                                                lng: selectedParkMap.overlay.southEast.lng,
                                            },
                                        )
                                    }
                                />
                            )}
                            <ParkMapViewerRegionOverlay
                                showOpacityRegion={isDetailsPage}
                                showDashOutline={!isPoiPage}
                                containerWidth={map?.getDiv().offsetWidth ?? 0}
                                containerHeight={map?.getDiv().offsetHeight ?? 0}
                            />
                            <ParkMapZoomControl onZoomIn={zoomIn} onZoomOut={zoomOut} />
                            <ParkMapTypeControl />
                            <OverlaySwitch />
                            {isDetailsPage && (
                                <Info>
                                    <InformationBox
                                        text="Place your park location inside the indicated boundaries"
                                        background={theme.colors.white}
                                    />
                                </Info>
                            )}
                            {isPoiPage && <CustomMarkers limited={true} />}
                        </GoogleMap>
                    ) : (
                        <></>
                    )}
                </LoaderWrapper>
                <ButtonContainer>
                    <PreviewButton secondary size="medium" onClick={() => setPreviewVisible(true)}>
                        Preview
                    </PreviewButton>
                </ButtonContainer>
            </Sticky>
        </Container>
    )
}

export default MapViewer
