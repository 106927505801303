import { usePerformFundTransferMutation } from 'admin/reduxQuery'
import { FundBalance } from 'admin/types'
import React, { useState } from 'react'
import ActionButton from 'uiComponents/buttons'
import { Col, Row } from 'uiComponents/flex'
import { LoaderWrapper } from 'uiComponents/loaders'
import Currency from 'uiComponents/money/currency'
import { ModalDialog, ModalDialogTitle } from 'uiComponents/popups/modal'
import { Body } from 'uiComponents/typography'
import { FormWrapper } from '../../uiComponents/form/form'
import TextAreaFieldFormik from '../../uiComponents/input/textInput/textAreaFieldFormik'
import { FundTransferFormValidationSchema } from './validationSchema'

interface Props {
    balance?: FundBalance
    pendingBalance?: FundBalance
    accountSlug: string
    accountName: string
    amount: number
    children: ({ openModal }: { openModal: () => void }) => React.ReactElement
}

interface FundTransferText {
    title: string
    body: string | React.ReactElement
    action: string | React.ReactElement
}

interface GetFundTransferTextProps {
    accountName: string
    amount: number
    pendingAmount?: number
    currency: string
    willFundsBeLocked?: boolean
}

const getFundTransferText = ({
    accountName,
    amount,
    currency,
    willFundsBeLocked,
}: GetFundTransferTextProps): FundTransferText => {
    if (willFundsBeLocked) {
        return {
            title: 'Payout suspension',
            body: (
                <>
                    <b>{accountName}</b> does not have sufficient funds for{' '}
                    <Currency amount={amount || 0} currency={currency} /> transfer. Do you wish to lock{' '}
                    <b>{accountName}</b> payout?
                </>
            ),
            action: <>Lock Payout</>,
        }
    }

    return {
        title: 'Fund transfer',
        body: (
            <>
                You are about to transfer{' '}
                <b>
                    <Currency amount={amount} currency={currency} />
                </b>{' '}
                from <b>{accountName}</b>. To confirm press Transfer
            </>
        ),
        action: (
            <>
                Transfer <Currency amount={amount} currency={currency} />
            </>
        ),
    }
}

const FundTransferNotification: React.FC<Props> = ({
    balance,
    pendingBalance,
    accountName,
    accountSlug,
    amount,
    children,
}) => {
    const text = getFundTransferText({
        accountName,
        amount,
        currency: (balance?.currency || pendingBalance?.currency) as string,
        willFundsBeLocked: amount > (balance?.value || 0),
    })
    const [open, setOpen] = useState<boolean>(false)
    const [performFundTransfer, { isLoading }] = usePerformFundTransferMutation()

    const handleTransfer = (values: any) => {
        performFundTransfer({
            ...values,
            accountSlug,
        }).then(() => setOpen(false))
    }

    return (
        <>
            {open && (
                <ModalDialog>
                    <LoaderWrapper loading={isLoading}>
                        <FormWrapper
                            formId="fundTransferConfirmationForm"
                            initialValues={{
                                amount: {
                                    currency: (balance?.currency || pendingBalance?.currency) as string,
                                    value: Number.parseFloat(amount as any).toFixed(2) as any,
                                },
                                capturedPaymentReference: null,
                            }}
                            validationSchema={FundTransferFormValidationSchema}
                            onSubmit={handleTransfer}
                        >
                            <ModalDialogTitle>{text.title}</ModalDialogTitle>
                            <Body size={2} style={{ padding: '1em 0' }}>
                                {text.body}
                            </Body>
                            <TextAreaFieldFormik
                                name="capturedPaymentReference"
                                label="Description"
                                style={{ width: '100%', minWidth: '100%', maxWidth: '100%' }}
                                aria-rowspan={4}
                            />
                            <Row gutter={16} align="flex-end" style={{ marginTop: '3em' }}>
                                <Col span="auto">
                                    <ActionButton secondary onClick={() => setOpen(false)}>
                                        Cancel
                                    </ActionButton>
                                </Col>
                                <Col span="auto">
                                    <ActionButton type="submit">{text.action}</ActionButton>
                                </Col>
                            </Row>
                        </FormWrapper>
                    </LoaderWrapper>
                </ModalDialog>
            )}
            {children({ openModal: () => setOpen(true) })}
        </>
    )
}

export default FundTransferNotification
