import * as React from 'react'
import { match as RouteMatch } from 'react-router-dom'
import { History } from 'history'
import { Navigation } from 'navigation'
import { MessageKind } from 'uiComponents/messages'
import { withNavigation } from 'hocs'
import { PricingService, Exception } from '../pricingService'
import { ArticleService as AdminArticleService, ArticleListItem } from 'admin/articleService'
import { StatsService } from 'http/statsService'
import ExceptionForm from './exceptionForm'
import { ChannelsService } from 'channels/channelsService'

interface ExceptionFormRouterProps {
    navigation: Navigation
    history: History
    match: RouteMatch<any>
    pricingService: PricingService
    articleService: AdminArticleService
    channelsService: ChannelsService
    statsService: StatsService
    accountSlug: string
    exception?: Exception
    exceptionProducts?: ArticleListItem[]
    setActiveSection: (section: string, header?: string) => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    hasPermission: (permission: string, accountSlug: string) => boolean
    className?: string
    prototype?: boolean
}

function ExceptionFormRouter(props: ExceptionFormRouterProps) {
    const {
        accountSlug,
        pricingService,
        setActiveSection,
        className,
        prototype,
        exception,
        exceptionProducts,
        statsService,
        channelsService,
    } = props
    return (
        <ExceptionForm
            className={className}
            key={exception ? exception.id : 'new'}
            exception={exception}
            history={props.history}
            exceptionProducts={exceptionProducts}
            accountSlug={accountSlug}
            pricingService={pricingService}
            channelsService={channelsService}
            articleService={props.articleService}
            setActiveSection={setActiveSection}
            replaceTopMessages={props.replaceTopMessages}
            removeAllMessages={props.removeAllMessages}
            hasPermission={props.hasPermission}
            prototype={prototype}
            statsService={statsService}
        />
    )
}

export default withNavigation(ExceptionFormRouter)
