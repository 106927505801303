import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons'

const Icon = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.colors.aluminiumShades[70]};
    cursor: pointer;
`

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

interface RowActionsProps {
    id: string
    expanded: boolean
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>
    onEdit(id: string): void
    onDelete(poi: string): void
}

export const RowActions = ({ id, expanded, setExpanded, onEdit, onDelete }: RowActionsProps) => {
    return (
        <RowContainer>
            <Icon icon={faPencil as IconProp} size="sm" style={{ marginRight: '0.75em' }} onClick={() => onEdit(id)} />
            <Icon icon={faTrash as IconProp} size="sm" style={{ marginRight: '1.5em' }} onClick={() => onDelete(id)} />
            <Icon icon={expanded ? faChevronUp : faChevronDown} onClick={() => setExpanded((prev) => !prev)} />
        </RowContainer>
    )
}
