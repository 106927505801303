import React from 'react'
import { connect } from 'react-redux'
import { State } from 'store'
import { User } from 'auth/state'
import { Profile } from 'settings/schema'
import { History } from 'history'
import { Navigation } from 'navigation'
import isEqual from 'lodash/isEqual'

interface UserPilotScriptProps {
    user: User | null
    profile: Profile | null
    activeAccount: string | null
    history: History
    navigation: Navigation
}

export class UserPilotScript extends React.Component<UserPilotScriptProps> {
    initialized = false

    componentDidMount() {
        const script = document.createElement('script')
        script.src = 'https://deploy.userpilot.io/14jv6h1.js'
        document.head!.appendChild(script)
        script.onload = () => this.identify()
    }

    componentDidUpdate(prevProps: UserPilotScriptProps) {
        if (
            prevProps.user !== this.props.user ||
            !isEqual(prevProps.profile, this.props.profile) ||
            prevProps.activeAccount !== this.props.activeAccount
        ) {
            this.identify()
        }
    }

    getRole = (user: User, activeAccount: string) => {
        if (user.isAdmin) {
            return 'convious'
        }
        if (!!user.resellerId) {
            return 'reseller'
        }
        const accountPermissions = user.permissions.filter((a) => a.account === activeAccount)
        const permissionsList = accountPermissions.length > 0 ? accountPermissions[0].permissions : []
        return permissionsList.indexOf('partner_admin') > -1 ? 'admin' : 'user'
    }

    identify = () => {
        const { user, profile, activeAccount } = this.props
        if (window['userpilot'] && user && profile && activeAccount) {
            const account = user.accounts.filter((a) => a.slug === activeAccount)
            const query = this.props.navigation.query()
            const role = this.getRole(user, activeAccount)
            window['userpilot'].identify(user.username, {
                name: profile.firstName ? profile.firstName.trim() : null,
                email: profile.email,
                role: role,
                slug: activeAccount,
                partner: account.length > 0 ? account[0].name : null,
                channel: query.utm_medium ? query.utm_medium : 'direct',
            })
        }

        if (window['userpilot'] && !this.initialized) {
            this.initialized = true
            this.props.history.listen(() => window['userpilot'].reload())
        }
    }

    render() {
        return null
    }
}

export default connect(
    (state: State) => ({
        user: state.auth.user,
        profile: state.profile.profile || null,
        activeAccount: state.preferences.activeAccount,
    }),
    () => ({}),
)(UserPilotScript)
