import React from 'react'
import styled from 'styled-components'
import { ParkMapViewerRegionOverlayProps, RegionOverlaySideProps, SIDES } from './types'

export const REGION_PADDING = 64

export const DashOutline = styled.div`
    position: absolute;
    top: ${(props: RegionOverlaySideProps) =>
        props.side === SIDES.BOTTOM ? `${props.containerWidth - REGION_PADDING}px` : `${REGION_PADDING - 2}px`};
    left: ${(props: RegionOverlaySideProps) =>
        props.side === SIDES.RIGHT ? `${props.containerWidth - REGION_PADDING}px` : `${REGION_PADDING - 2}px`};
    width: ${(props: RegionOverlaySideProps) =>
        props.side === SIDES.LEFT || props.side === SIDES.RIGHT ? 0 : props.containerWidth - 2 * REGION_PADDING}px;
    height: ${(props: RegionOverlaySideProps) =>
        props.side === SIDES.LEFT || props.side === SIDES.RIGHT ? props.containerWidth - 2 * REGION_PADDING : 0}px;
    border-style: dashed;
    border-color: ${(props) => props.theme.colors.boyBlueShades[90]};
    border-width: ${(props: RegionOverlaySideProps) => {
        switch (props.side) {
            case SIDES.LEFT:
                return '0 4px 0 0'
            case SIDES.TOP:
                return '0 0 4px 0'
            case SIDES.RIGHT:
                return '0 0 0 4px'
            case SIDES.BOTTOM:
                return '4px 0 0 0'
            default:
                return 'none'
        }
    }};
`

export const OverlayRegion = styled.div`
    position: absolute;
    background-color: rgba(0, 0, 0, 0.35);
    width: ${(props: RegionOverlaySideProps) =>
        props.side === SIDES.LEFT || props.side === SIDES.RIGHT
            ? REGION_PADDING
            : props.containerWidth - 2 * REGION_PADDING}px;
    height: ${(props: RegionOverlaySideProps) => {
        switch (props.side) {
            case SIDES.LEFT:
            case SIDES.RIGHT:
                return '100%'
            case SIDES.TOP:
                return `${REGION_PADDING}px`
            case SIDES.BOTTOM:
                if (props.containerHeight < props.containerWidth) {
                    return `${REGION_PADDING}px`
                } else {
                    return `${props.containerHeight - (props.containerWidth - 2 * REGION_PADDING) - REGION_PADDING}px`
                }
            default:
                return 'auto'
        }
    }}};
    top: ${(props: RegionOverlaySideProps) =>
        props.side === SIDES.BOTTOM ? `${props.containerWidth - REGION_PADDING}px` : '0px'};
    left: ${(props: RegionOverlaySideProps) => {
        switch (props.side) {
            case SIDES.LEFT:
                return '0px'
            case SIDES.RIGHT:
                return `${props.containerWidth - REGION_PADDING}px`
            case SIDES.TOP:
            case SIDES.BOTTOM:
                return `${REGION_PADDING}px`
            default:
                return 'auto'
        }
    }}};
`

const ParkMapViewerRegionOverlay = (props: ParkMapViewerRegionOverlayProps) => {
    const { containerWidth, containerHeight } = props

    return (
        <>
            {Object.values(SIDES).map((side, index) => (
                <div key={`${index}-overlay`}>
                    {props.showOpacityRegion && (
                        <OverlayRegion containerHeight={containerHeight} containerWidth={containerWidth} side={side} />
                    )}
                    {props.showDashOutline && (
                        <DashOutline containerHeight={containerHeight} containerWidth={containerWidth} side={side} />
                    )}
                </div>
            ))}
        </>
    )
}

export default ParkMapViewerRegionOverlay
