import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { History } from 'history'
import { User } from 'auth/state'
import { delay } from 'utils'
import { State } from 'store'
import { PageLoader } from 'uiComponents/loaders'
import { withFeatures } from 'features'
import { TemplatePreview } from '../preview'
import { PreviewSize } from 'ticketTemplates/studio/imports'
import { withMessages, MessageProps, withCurrentUser } from 'hocs'
import { Messages } from 'uiComponents/messages'
import { withPermission } from 'admin/hocs'
import { Header } from '../header'
import { Debounce } from 'debounce'
import { TogglesSection } from '../togglesSection'
import { BannersSection } from './bannersSection'
import { GiftCardGlobalSettings } from './giftCardGlobalSection'
import { DownloadSection } from '../downloadSection'
import { ArticleServiceContext } from 'admin/context'
import { Locale, LocalesInfo } from 'admin/articleService'
import { Menu } from 'uiComponents/studio/minimisedMenuVersion'
import { match as RouteMatch } from 'react-router-dom'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import {
    TicketTemplatesService,
    Translation,
    TemplateBody,
    PriceVisibilityType,
} from 'ticketTemplates/ticketTemplatesService'
import { LoginService } from 'http/loginService'
import { Page, Body, Form, FormBody, ScrollArea, Preview } from '../regularTickets/ticketsStudio'
import cloneDeep from 'lodash/cloneDeep'

interface GiftCardStudioPageProps {
    accountSlug: string
    history: History
    user: User
    hasFeature: (feature: string, accountSlug: string) => boolean
    getComponentValue: (component: string, accountSlug: string) => any
    hasPermission: (permission: string, accountSlug: string) => boolean
    ticketTemplatesService: TicketTemplatesService
    match: RouteMatch<{ id: string }>
    navigation: Navigation
    backofficeEndpoint: string
    loginService: LoginService
}

function GiftCardStudioPage(props: GiftCardStudioPageProps & MessageProps) {
    const didMountRef = React.useRef(false)
    const updateCountRef = React.useRef(0)
    const accounts = useSelector((state: State) => state.auth.user?.accounts || [])
    const articleService = React.useContext(ArticleServiceContext)

    const [loading, setLoading] = useState<boolean>(false)
    const [previewSize, setPreviewSize] = React.useState<PreviewSize>('desktop')
    const [activeLanguage, setActiveLanguage] = React.useState<string>('en')
    const [accountLocales, setAccountLocales] = React.useState<Locale[]>([])
    const [name, setName] = React.useState<string>('')
    const [bannerFirstInfo, setBannerFirstInfo] = useState<Translation | null>(null)
    const [bannerSecondInfo, setBannerSecondInfo] = useState<Translation | null>(null)
    const [showErrors, setShowErrors] = useState<boolean>(false)
    const [localesInfo, setLocalesInfo] = useState<LocalesInfo | null>(null)
    const [showFullName, setShowFullName] = React.useState<boolean>(false)
    const [showSupportEmail, setShowSupportEmail] = React.useState<boolean>(false)
    const [showPhoneNumber, setShowPhoneNumber] = React.useState<boolean>(false)
    const [showOpeningHours, setShowOpeningHours] = React.useState<boolean>(false)
    const [showAddress, setShowAddress] = React.useState<boolean>(false)
    const [showOrderNo, setShowOrderNo] = React.useState<boolean>(false)
    const [showEmail, setShowEmail] = React.useState<boolean>(false)
    const [showBarcode, setShowBarcode] = React.useState<boolean>(true)
    const [showBarcodeNumber, setShowBarcodeNumber] = React.useState<boolean>(true)
    const [showPrice, setShowPrice] = React.useState<boolean>(true)
    const [showPriceResellers, setShowPriceResellers] = React.useState<boolean>(true)
    const [backgroundColor, setBackgroundColor] = React.useState<string>('#B4BFC9')
    const [textColor, setTextColor] = React.useState<string>('#FFFFFF')
    const [image, setImage] = React.useState<string>('')
    const [productNameSize, setProductNameSize] = React.useState<string>('MEDIUM')
    const [showVat, setShowVat] = React.useState<boolean>(false)
    const [templateHtml, setTemplateHtml] = React.useState<string>('')
    const [previewLoading, setPreviewLoading] = React.useState<boolean>(false)
    const [vat, setVat] = React.useState<number | null>(null)

    const createEmptyTranslations = (locales: Locale[], bannerType: 'firstBanner' | 'secondBanner'): Translation => {
        const translations = {
            key: `${bannerType}Info`,
            text: {},
        }
        locales.forEach((l) => (translations.text[l.code] = ''))
        return translations
    }

    async function getData() {
        try {
            const localesDetails = await articleService.getAccountLocales(props.accountSlug)
            setLocalesInfo(localesDetails)
            const id = props.match.params.id
            const config = await props.ticketTemplatesService.getTemplate(props.accountSlug, id)
            if (config.bannerFirstInfo) {
                setBannerFirstInfo(config.bannerFirstInfo)
            } else {
                setBannerFirstInfo(createEmptyTranslations(localesDetails.locales, 'firstBanner'))
            }
            if (config.bannerSecondInfo) {
                setBannerSecondInfo(config.bannerSecondInfo)
            } else {
                setBannerSecondInfo(createEmptyTranslations(localesDetails.locales, 'secondBanner'))
            }
            setImage(config.image)
            setName(config.name)
            setShowBarcode(config.showBarcode)
            setShowBarcodeNumber(config.showBarcodeNumber)
            setShowPrice(config.hidePrice !== 'ALWAYS')
            setShowPriceResellers(config.hidePrice === 'NEVER')
            setShowVat(config.showVat)
            setShowFullName(!!config.showFullName)
            setShowPhoneNumber(!!config.showPhoneNumber)
            setShowSupportEmail(!!config.showSupportEmail)
            setShowOpeningHours(!!config.showOpeningHours)
            setShowAddress(!!config.showAddress)
            setShowEmail(config.showEmail)
            setShowOrderNo(config.showOrderNo)
            setBackgroundColor(config.backgroundColor || '#B4BFC9')
            setTextColor(config.textColor || '#FFFFFF')
            setProductNameSize(config.textSize || 'MEDIUM')
            setAccountLocales(localesDetails.locales)
            setActiveLanguage(localesDetails.defaultLocale || 'en')
        } catch {
            props.replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setLoading(true)
        getData()
        setPreviewSize('desktop')
        const activeAccount = accounts.find((x) => x.slug === props.accountSlug) || accounts[0]
        setVat(activeAccount.vat)
        updateTemplateHtml()

        return () => {
            if (getTemplateHtmlLater) {
                getTemplateHtmlLater.clear()
            }
        }
    }, [])

    useEffect(() => {
        if (loading) {
            return
        }
        didMountRef.current ? updateTemplateHtml() : (didMountRef.current = true)
    }, [
        activeLanguage,
        image,
        showVat,
        showEmail,
        showOrderNo,
        backgroundColor,
        textColor,
        productNameSize,
        loading,
        showBarcode,
        showBarcodeNumber,
        showPrice,
        showPriceResellers,
        showFullName,
        showSupportEmail,
        showAddress,
        showOpeningHours,
        showPhoneNumber,
        bannerFirstInfo,
        bannerSecondInfo,
    ])

    const getHidePriceValue = (): PriceVisibilityType => {
        return !showPrice ? 'ALWAYS' : !showPriceResellers ? 'RESELLERS_ONLY' : 'NEVER'
    }

    const noBannersUploaded = (bannerInfo: Translation | null): boolean => {
        if (!localesInfo || !bannerInfo) {
            return true
        }
        return localesInfo.locales.every((loc) => {
            return !bannerInfo.text[loc.code]
        })
    }

    const getPayload = () => {
        const payload: TemplateBody = {
            name,
            image,
            showVat,
            showBarcode,
            showBarcodeNumber,
            showFullName,
            showAddress,
            showOpeningHours,
            showPhoneNumber,
            showSupportEmail,
            showEmail,
            showOrderNo,
            backgroundColor,
            textColor,
            textSize: productNameSize,
            bannerFirstInfo: noBannersUploaded(bannerFirstInfo) ? null : bannerFirstInfo,
            bannerSecondInfo: noBannersUploaded(bannerSecondInfo) ? null : bannerSecondInfo,
            hidePrice: getHidePriceValue(),
            templateType: 'VOUCHER',
            additionalInfo: null,
            printingRequired: false,
            showTapQrCode: false,
            useBannerImage: false,
        }
        return payload
    }

    const missingLanguage = (bannerInfo: Translation | null) => {
        if (!localesInfo) {
            return 'en'
        }
        if (!bannerInfo) {
            return ''
        }
        let langWithError = ''
        let noneUploaded = true
        localesInfo.locales.forEach((loc) => {
            if (!bannerInfo.text[loc.code]) {
                langWithError = loc.code
            } else {
                noneUploaded = false
            }
        })
        return noneUploaded ? '' : langWithError
    }

    const isConfigValid = (): boolean => {
        if (!localesInfo) {
            return false
        }
        const missingFistBannerLanguage = missingLanguage(bannerFirstInfo)
        const missingSecondBannerLanguage = missingLanguage(bannerSecondInfo)
        if (missingFistBannerLanguage || missingSecondBannerLanguage) {
            setActiveLanguage(missingFistBannerLanguage || missingSecondBannerLanguage)
            setShowErrors(true)
        }
        return !missingFistBannerLanguage && !missingSecondBannerLanguage
    }

    const saveSettings = async () => {
        if (!isConfigValid()) {
            props.replaceMessages(
                'validation_error',
                'error',
                'Banners must be uploaded for either all or none of the languages.',
            )
            return
        }
        try {
            setLoading(true)
            const payload = getPayload()
            await props.ticketTemplatesService.updateTemplate(props.accountSlug, props.match.params.id, payload)
            await getData()
            props.replaceMessages('success', 'success', 'The template has been saved successfully.')
            await delay(4000)
            props.removeAllMessages()
        } catch {
            setLoading(false)
            props.replaceMessages('server_error', 'error', 'Could not save the template. Please try again later.')
        }
    }

    const setBanner = (url: string, type: 'first' | 'second') => {
        props.hideMessage('validation_error')
        const bannerInfo = type === 'first' ? bannerFirstInfo : bannerSecondInfo
        if (!bannerInfo) {
            return
        }
        const translations = cloneDeep(bannerInfo)
        translations.text[activeLanguage] = url
        type === 'first' ? setBannerFirstInfo(translations) : setBannerSecondInfo(translations)
    }

    const updateTemplateHtml = () => {
        if (!name || !activeLanguage) {
            return
        }
        const settings: TemplateBody = {
            ...getPayload(),
            language: activeLanguage,
        }
        if (!previewLoading) {
            setPreviewLoading(true)
        }
        updateCountRef.current += 1
        getTemplateHtmlLater.trigger(settings)
    }

    const getTemplateHtmlLater = new Debounce(async (settings: TemplateBody) => getTemplateHtml(settings), 1000)

    const getTemplateHtml = async (settings: TemplateBody) => {
        try {
            props.hideMessage('preview_error')
            const html = await props.ticketTemplatesService.getTemplateHtml(props.accountSlug, settings)
            setTemplateHtml(html)
        } catch {
            props.replaceMessages(
                'preview_error',
                'error',
                'Oops! We could not load the preview. Please try again later.',
            )
        } finally {
            updateCountRef.current -= 1
            if (updateCountRef.current < 1) {
                setPreviewLoading(false)
            }
        }
    }

    const getPreviewSetttingsForDownload = () => {
        const settings = {
            name,
            image,
            show_vat: showVat,
            show_barcode: showBarcode,
            show_barcode_number: showBarcodeNumber,
            show_full_name: showFullName,
            show_address: showAddress,
            show_opening_hours: showOpeningHours,
            show_email: showEmail,
            show_order_no: showOrderNo,
            background_color: backgroundColor,
            text_color: textColor,
            text_size: productNameSize,
            hide_price: getHidePriceValue(),
            banner_first_info: bannerFirstInfo,
            banner_second_info: bannerSecondInfo,
            show_phone_number: showPhoneNumber,
            show_support_email: showSupportEmail,
            language: activeLanguage,
            additional_info: null,
            printing_required: false,
            show_tap_qr_code: false,
            use_banner_image: false,
            template_type: 'VOUCHER',
        }
        return settings
    }

    return (
        <Page id="gift-card-template-studio">
            <Messages messages={props.messages} hideMessage={props.hideMessage} />
            <Menu />
            <Form noValidate>
                <FormBody>
                    {!loading && (
                        <>
                            <Header
                                history={props.history}
                                accountSlug={props.accountSlug}
                                onSave={saveSettings}
                                hasPermissionToSave={props.hasPermission('partner_admin', props.accountSlug)}
                            />
                            <ScrollArea id="scroll-area">
                                <GiftCardGlobalSettings
                                    backgroundColor={backgroundColor}
                                    textColor={textColor}
                                    productNameSize={productNameSize}
                                    setProductNameSize={setProductNameSize}
                                    setBackgroundColour={setBackgroundColor}
                                    setTextColour={setTextColor}
                                    setImage={setImage}
                                    replaceMessages={props.replaceMessages}
                                    removeAllMessages={props.removeAllMessages}
                                />
                                <BannersSection
                                    accountSlug={props.accountSlug}
                                    activeLanguage={activeLanguage}
                                    locales={accountLocales}
                                    showErrors={showErrors}
                                    bannerFirstInfo={bannerFirstInfo}
                                    bannerSecondInfo={bannerSecondInfo}
                                    noFirstBanners={noBannersUploaded(bannerFirstInfo)}
                                    noSecondBanners={noBannersUploaded(bannerSecondInfo)}
                                    handleLanguageChange={setActiveLanguage}
                                    setBanner={setBanner}
                                    replaceMessages={props.replaceMessages}
                                    removeAllMessages={props.removeAllMessages}
                                />
                                <TogglesSection
                                    accountSlug={props.accountSlug}
                                    templateType="VOUCHER"
                                    showBarcode={showBarcode}
                                    showBarcodeNumber={showBarcodeNumber}
                                    showPrice={showPrice}
                                    showPriceResellers={showPriceResellers}
                                    showVat={showVat}
                                    vat={vat}
                                    showOpeningHours={showOpeningHours}
                                    showAddress={showAddress}
                                    showFullName={showFullName}
                                    showEmail={showEmail}
                                    showOrderNumber={showOrderNo}
                                    showOptions={false}
                                    showTapQrCode={false}
                                    printingRequired={false}
                                    showPhoneNumber={showPhoneNumber}
                                    showSupportEmail={showSupportEmail}
                                    setShowOpeningHours={setShowOpeningHours}
                                    setShowAddress={setShowAddress}
                                    setShowPhoneNumber={setShowPhoneNumber}
                                    setShowSupportEmail={setShowSupportEmail}
                                    setShowBarcode={setShowBarcode}
                                    setShowBarcodeNumber={setShowBarcodeNumber}
                                    setShowPrice={setShowPrice}
                                    setShowPriceResellers={setShowPriceResellers}
                                    setShowVat={setShowVat}
                                    setShowFullName={setShowFullName}
                                    setShowEmail={setShowEmail}
                                    setShowOrderNumber={setShowOrderNo}
                                    setShowOptions={(v) => {}}
                                    setShowTapQrCode={(v) => {}}
                                    setPrintingRequired={(v) => {}}
                                />
                                <DownloadSection
                                    accountSlug={props.accountSlug}
                                    backofficeEndpoint={props.backofficeEndpoint}
                                    loginService={props.loginService}
                                    downloadData={Object.assign({
                                        ...getPreviewSetttingsForDownload(),
                                    })}
                                />
                            </ScrollArea>
                        </>
                    )}
                    {loading && <PageLoader />}
                </FormBody>
            </Form>
            <Body>
                <Preview className={previewSize}>
                    <TemplatePreview templateHtml={templateHtml} loading={previewLoading || loading} />
                </Preview>
            </Body>
        </Page>
    )
}

export default withPermission(withFeatures(withNavigation(withCurrentUser(withMessages(GiftCardStudioPage)))))
