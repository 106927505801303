import * as React from 'react'
import styled from 'styled-typed'
import { withFeatures } from 'features'
import { OrdersService } from 'orders/ordersService'
import { LoggingService } from 'http/loggingService'
import { DataTable, HeaderRow, Sorting, TableHeader } from 'uiComponents/table'
import { ScrollTableContainer } from 'uiComponents/table/scrollTableContainer'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { MessageKind } from 'uiComponents/messages'
import { Checkbox } from 'uiComponents/input'
import { OrderDetails, UserFieldConfig, UserField } from 'orders/schema'
import DetailsRow from './detailsRow'

const SelectedRowsHeader = styled.div`
    display: flex;
    align-items: center;
    padding-left: 0.7em;
`

export interface DetailsTableProps {
    ordersService: OrdersService
    userFieldConfig: UserFieldConfig
    loggingService: LoggingService
    orderDetails: OrderDetails[]
    loading: boolean
    sort: Sorting
    onSortChanged: (s: Sorting) => void
    accountSlug: string
    reloadOrderList: () => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    allBarcodesSelected: boolean
    toggleSelectAllBarcodes: () => void
    onSelectBarcode: (event: React.ChangeEvent<HTMLInputElement>) => void
    selectedBarcodes: string[]
    hasFeature: (feature: string, accountSlug: string) => boolean
    updateApfItem: (barcode: string, userField: UserField, value: string) => void
    saveNewValidToDate: (orderId: string, orderItemId: string, date: Date, bundleOrderItemId: string) => void
    withTimeSlots: boolean
}

interface DetailsTableState {}

class DetailsTable extends React.Component<DetailsTableProps, DetailsTableState> {
    constructor(props: DetailsTableProps) {
        super(props)
        this.state = {
            expandedOrder: null,
        }
    }

    render() {
        const {
            sort,
            onSortChanged,
            orderDetails,
            userFieldConfig,
            ordersService,
            loggingService,
            loading,
            accountSlug,
            reloadOrderList,
            replaceMessages,
            removeAllMessages,
            allBarcodesSelected,
            toggleSelectAllBarcodes,
            onSelectBarcode,
            selectedBarcodes,
            updateApfItem,
            saveNewValidToDate,
            withTimeSlots,
        } = this.props

        const showNoResults = !loading && orderDetails && orderDetails.length === 0
        const resellersFeatureOn = this.props.hasFeature('ResellersFeature', accountSlug)
        const userFieldList = userFieldConfig.enabled ? userFieldConfig.fields : []

        const fixedColumns = [
            '6.7em', // id
            '15em', // email
            '20.5em', // product
            '12.5em', // barcode
            '7.2em', // total
            '12.5em', // options
            '8.5em', // discount code
            '7.4em', // pay date
            '7.4em', // visit date
        ]
        const visitTimeColumn = withTimeSlots ? ['7.5em'] : []
        const dynamicColumns = userFieldList.map((f) => (f.label.length > 15 ? `${f.label.length * 0.8}em` : '15em'))
        const resellersColumn = resellersFeatureOn ? ['8em'] : []

        const columnWidths = [
            '3.4em', // checkbox
            '8.9em', // redeem column
            '7.7em', // status
            '7.4em', // printed
            ...fixedColumns,
            ...visitTimeColumn,
            '7.4em', // valid from
            '8.7em', // valid to
            ...resellersColumn,
            '5em', // opt in
            ...dynamicColumns,
            '1em', // expand
        ]
        const someBarcodesSelected = selectedBarcodes.length > 0

        return (
            <ScrollTableContainer loading={loading} accountSlug={accountSlug}>
                <DataTable id="orders-details-table" columnWidths={columnWidths} customSidePadding=".8em" allowOverflow>
                    <HeaderRow>
                        <TableHeader align="center" style={{ padding: '.7em .8em .5em .8em' }}>
                            <Checkbox
                                id="select-all"
                                name="selectAll"
                                checked={someBarcodesSelected}
                                onChange={toggleSelectAllBarcodes}
                                disabled={orderDetails.length < 1}
                                indeterminateState={someBarcodesSelected && !allBarcodesSelected}
                            />
                        </TableHeader>
                        {someBarcodesSelected && (
                            <SelectedRowsHeader>{selectedBarcodes.length} selected</SelectedRowsHeader>
                        )}
                        {!someBarcodesSelected && (
                            <>
                                <TableHeader>Redeemed on</TableHeader>
                                <TableHeader>Status</TableHeader>
                                <TableHeader>Printed on</TableHeader>
                                <TableHeader sortKey="order_id" sorting={sort} changeSort={onSortChanged}>
                                    ID
                                </TableHeader>
                                <TableHeader sortKey="email" sorting={sort} changeSort={onSortChanged}>
                                    Email
                                </TableHeader>
                                <TableHeader sortKey="product" sorting={sort} changeSort={onSortChanged}>
                                    Product
                                </TableHeader>
                                <TableHeader sortKey="barcode" sorting={sort} changeSort={onSortChanged}>
                                    Barcode
                                </TableHeader>
                                <TableHeader sortKey="total" sorting={sort} changeSort={onSortChanged}>
                                    Amount
                                </TableHeader>
                                <TableHeader>Options</TableHeader>
                                <TableHeader>Discount code</TableHeader>
                                <TableHeader sortKey="payment_date" sorting={sort} changeSort={onSortChanged}>
                                    Pay date
                                </TableHeader>
                                <TableHeader sortKey="visit_date" sorting={sort} changeSort={onSortChanged}>
                                    Visit date
                                </TableHeader>
                                {withTimeSlots && (
                                    <TableHeader sortKey="visit_time" sorting={sort} changeSort={onSortChanged}>
                                        Event time
                                    </TableHeader>
                                )}
                                <TableHeader>Valid from</TableHeader>
                                <TableHeader>Valid to</TableHeader>
                                {resellersFeatureOn && <TableHeader>Reseller</TableHeader>}
                                <TableHeader>Opt-in</TableHeader>
                                {userFieldList.map((f) => (
                                    <TableHeader key={f.name}>{f.label}</TableHeader>
                                ))}
                                <TableHeader nonInteractive />
                            </>
                        )}
                    </HeaderRow>
                    {orderDetails &&
                        orderDetails.map((item, i) => (
                            <DetailsRow
                                key={item.barcode + i}
                                detailsItem={item}
                                userFieldList={userFieldList}
                                ordersService={ordersService}
                                loggingService={loggingService}
                                accountSlug={accountSlug}
                                reloadOrderList={reloadOrderList}
                                replaceMessages={replaceMessages}
                                removeAllMessages={removeAllMessages}
                                onSelectBarcode={onSelectBarcode}
                                selectedBarcodes={selectedBarcodes}
                                updateApfItem={updateApfItem}
                                saveNewValidToDate={saveNewValidToDate}
                                withTimeSlots={withTimeSlots}
                                showResellerColumn={resellersFeatureOn}
                                loading={loading}
                            />
                        ))}
                    {loading && (!orderDetails || orderDetails.length === 0) && <div style={{ height: '6em' }} />}
                    {showNoResults && <NoResultsRow alignLeft item="orders" />}
                </DataTable>
            </ScrollTableContainer>
        )
    }
}

export default withFeatures(DetailsTable)
