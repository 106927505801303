import {
    startOfToday,
    startOfMonth,
    addDays,
    addMonths,
    addYears,
    differenceInDays,
    isEqual,
    isValid,
    startOfWeek,
    startOfDay,
    endOfDay,
    lastDayOfMonth,
    startOfYear,
    startOfQuarter,
} from 'date-fns'
import { PresetType } from 'uiComponents/popups/presetsElement'
import { parseISODate } from 'utils/dates'

export type DateRangeName =
    | 'today'
    | 'yesterday'
    | 'thisWeek'
    | 'lastWeek'
    | 'twoWeeksAgo'
    | 'thisMonth'
    | 'lastMonth'
    | 'nextMonth'
    | 'tomorrow'
    | 'dayAfterTomorrow'
    | 'nextWeek'
    | 'last7days'
    | 'last4weeks'
    | 'last30days'
    | 'last90days'
    | 'lifetime'
    | 'custom'
    | 'lastSixMonths'
    | 'thisQuarter'
    | 'thisYear'

export type DateRangePeriod = 'day' | 'month'

export interface DateRange {
    name: DateRangeName
    from: Date
    to: Date
    readonly period: DateRangePeriod
    next?: () => DateRange
    prev?: () => DateRange
}

export function today(): DateRange {
    const start = startOfToday()
    const end = endOfDay(start)
    return {
        name: 'today',
        period: 'day',
        from: start,
        to: end,
        prev: () => yesterday(),
        next: () => tomorrow(),
    }
}

export function tomorrow(): DateRange {
    const start = addDays(startOfToday(), 1)
    const end = endOfDay(start)
    return {
        name: 'tomorrow',
        period: 'day',
        from: start,
        to: end,
        prev: () => today(),
        next: () => dayAfterTomorrow(),
    }
}

export function dayAfterTomorrow(): DateRange {
    const start = addDays(startOfToday(), 2)
    const end = endOfDay(start)
    const nextDay = addDays(start, 1)
    return {
        name: 'dayAfterTomorrow',
        period: 'day',
        from: start,
        to: end,
        prev: () => tomorrow(),
        next: () => custom(nextDay, endOfDay(nextDay), 'day'),
    }
}

export function yesterday(): DateRange {
    const currentDay = startOfToday()
    const start = addDays(currentDay, -1)
    const end = endOfDay(start)
    const prevDay = addDays(start, -1)
    return {
        name: 'yesterday',
        from: start,
        to: end,
        period: 'day',
        next: () => today(),
        prev: () => custom(prevDay, endOfDay(prevDay), 'day'),
    }
}

export function thisWeek(): DateRange {
    const date = startOfToday()
    const start = startOfWeek(date, { weekStartsOn: 1 })
    const end = addDays(start, 6)
    return {
        name: 'thisWeek',
        from: start,
        to: endOfDay(end),
        period: 'day',
        prev: () => lastWeek(),
        next: () => nextWeek(),
    }
}

export function nextWeek(): DateRange {
    const date = startOfToday()
    const start = addDays(startOfWeek(date, { weekStartsOn: 1 }), 7)
    const end = addDays(start, 6)
    return {
        name: 'nextWeek',
        from: start,
        to: endOfDay(end),
        period: 'day',
        prev: () => thisWeek(),
        next: () => custom(startOfDay(addDays(end, 1)), endOfDay(addDays(end, 7)), 'day'),
    }
}

export function lastWeek(): DateRange {
    const date = startOfToday()
    const end = startOfWeek(date, { weekStartsOn: 1 })
    const start = addDays(end, -7)
    return {
        name: 'lastWeek',
        from: start,
        to: endOfDay(addDays(end, -1)),
        period: 'day',
        next: () => thisWeek(),
        prev: () => twoWeeksAgo(),
    }
}

export function twoWeeksAgo(): DateRange {
    const date = startOfToday()
    const weekStart = startOfWeek(date, { weekStartsOn: 1 })
    const start = addDays(weekStart, -14)
    const end = addDays(weekStart, -7)
    return {
        name: 'twoWeeksAgo',
        from: start,
        to: endOfDay(addDays(end, -1)),
        period: 'day',
        next: () => lastWeek(),
        prev: () => custom(addDays(start, -7), endOfDay(addDays(start, -1)), 'day'),
    }
}

export function thisMonth(): DateRange {
    const monthStart = startOfMonth(new Date())
    const monthEnd = addDays(addMonths(monthStart, 1), -1)
    return {
        name: 'thisMonth',
        from: monthStart,
        to: endOfDay(monthEnd),
        period: 'month',
        prev: () => lastMonth(),
        next: () => nextMonth(),
    }
}

export function lastMonth(): DateRange {
    const monthStart = startOfMonth(new Date())
    const lastMonthStart = addMonths(monthStart, -1)
    const lastMonthEnd = addDays(monthStart, -1)
    return {
        name: 'lastMonth',
        from: lastMonthStart,
        to: endOfDay(lastMonthEnd),
        period: 'month',
        next: () => thisMonth(),
        prev: () => customMonthly(addMonths(lastMonthStart, -1), endOfDay(addDays(lastMonthStart, -1))),
    }
}

export function lastSixMonths(): DateRange {
    const monthStart = startOfMonth(new Date())
    const thisMonthEnd = addDays(addMonths(monthStart, 1), -1)
    const sixMonthsAgo = addMonths(monthStart, -5)
    return {
        name: 'lastSixMonths',
        from: sixMonthsAgo,
        to: endOfDay(thisMonthEnd),
        period: 'month',
        next: () => customMonthly(addMonths(monthStart, 1), endOfDay(addDays(addMonths(monthStart, 7), -1))),
        prev: () => customMonthly(addMonths(sixMonthsAgo, -6), endOfDay(addDays(sixMonthsAgo, -1))),
    }
}

export function nextMonth(): DateRange {
    const monthStart = startOfMonth(new Date())
    const nextMonthStart = addMonths(monthStart, 1)
    const nextMonthEnd = addDays(addMonths(nextMonthStart, 1), -1)
    return {
        name: 'nextMonth',
        from: nextMonthStart,
        to: endOfDay(nextMonthEnd),
        period: 'month',
        next: () => customMonthly(addMonths(nextMonthStart, 1), endOfDay(addDays(addMonths(nextMonthStart, 2), -1))),
        prev: () => thisMonth(),
    }
}

export function thisQuarter(): DateRange {
    const quarterStart = startOfQuarter(new Date())
    const quarterEnd = addDays(addMonths(quarterStart, 3), -1)
    return {
        name: 'thisQuarter',
        from: quarterStart,
        to: endOfDay(quarterEnd),
        period: 'month',
        next: () => customMonthly(addMonths(quarterStart, 3), endOfDay(addDays(addMonths(quarterStart, 6), -1))),
        prev: () => customMonthly(addMonths(quarterStart, -3), endOfDay(addDays(quarterStart, -1))),
    }
}

export function thisYear(): DateRange {
    const yearStart = startOfYear(new Date())
    const yearEnd = addDays(addYears(yearStart, 1), -1)
    return {
        name: 'thisYear',
        from: yearStart,
        to: endOfDay(yearEnd),
        period: 'month',
        next: () => customMonthly(addYears(yearStart, 1), endOfDay(addDays(addYears(yearStart, 2), -1))),
        prev: () => customMonthly(addYears(yearStart, -1), endOfDay(addDays(yearStart, -1))),
    }
}

export function last7days(): DateRange {
    const end = startOfToday()
    const start = addDays(end, -6)
    return {
        name: 'last7days',
        from: start,
        to: endOfDay(end),
        period: 'day',
        next: () => custom(addDays(end, 1), endOfDay(addDays(end, 7)), 'day'),
        prev: () => custom(addDays(start, -7), endOfDay(addDays(start, -1)), 'day'),
    }
}

export function last4weeks(): DateRange {
    const end = startOfToday()
    const start = addDays(end, -27)
    return {
        name: 'last4weeks',
        from: start,
        to: endOfDay(end),
        period: 'day',
        next: () => custom(addDays(end, 1), endOfDay(addDays(end, 28)), 'day'),
        prev: () => custom(addDays(start, -28), endOfDay(addDays(start, -1)), 'day'),
    }
}

export function last30days(): DateRange {
    const end = startOfToday()
    const start = addDays(end, -29)
    return {
        name: 'last30days',
        from: start,
        to: endOfDay(end),
        period: 'day',
        next: () => custom(addDays(end, 1), endOfDay(addDays(end, 30)), 'day'),
        prev: () => custom(addDays(start, -30), endOfDay(addDays(start, -1)), 'day'),
    }
}

export function last90days(): DateRange {
    const end = startOfToday()
    const start = addDays(end, -89)
    return {
        name: 'last90days',
        from: start,
        to: endOfDay(end),
        period: 'day',
        next: () => custom(addDays(end, 1), endOfDay(addDays(end, 90)), 'day'),
        prev: () => custom(addDays(start, -90), endOfDay(addDays(start, -1)), 'day'),
    }
}

export function lifetime(): DateRange {
    const end = startOfToday()
    const start = new Date(2017, 0, 1)
    return {
        name: 'lifetime',
        from: start,
        to: endOfDay(end),
        period: 'day',
    }
}

export function custom(from: Date, to: Date, period: DateRangePeriod): DateRange {
    switch (period) {
        case 'day':
            return customDaily(from, to)
        case 'month':
            return customMonthly(from, to)
        default:
            throw new Error(`Unknown date range period: ${period}`)
    }
}

function customDaily(from: Date, to: Date): DateRange {
    from = startOfDay(from)
    to = endOfDay(to)
    const daysBetween = differenceInDays(to, from) + 1
    const next = () => createDateRange(startOfDay(addDays(to, 1)), addDays(to, daysBetween), 'day')
    const prev = () => createDateRange(addDays(from, -daysBetween), endOfDay(addDays(from, -1)), 'day')

    return { name: 'custom', period: 'day', from, to, next, prev }
}

function customMonthly(from: Date, to: Date): DateRange {
    const next = () => createDateRange(startOfDay(addDays(to, 1)), endOfDay(addDays(addMonths(from, 2), -1)), 'month')
    const prev = () => createDateRange(addMonths(from, -1), endOfDay(addDays(from, -1)), 'month')
    return { name: 'custom', period: 'month', from, to, next, prev }
}

export function createDateRange(from: Date, to: Date, period: DateRangePeriod = 'day'): DateRange {
    if (isEqual(from, startOfMonth(from)) && isEqual(to, endOfDay(lastDayOfMonth(to)))) {
        period = 'month'
    }

    const todayStart = startOfToday()
    const endOfToday = endOfDay(todayStart)
    if (isEqual(from, todayStart) && isEqual(to, endOfToday)) {
        return today()
    }

    const yesterdayStart = addDays(todayStart, -1)
    if (isEqual(from, yesterdayStart) && isEqual(to, endOfDay(yesterdayStart))) {
        return yesterday()
    }

    const tomorrowStart = addDays(todayStart, 1)
    const tomorrowEnd = endOfDay(tomorrowStart)
    if (isEqual(from, tomorrowStart) && isEqual(to, tomorrowEnd)) {
        return tomorrow()
    }

    const dayAfterTomorrowStart = addDays(tomorrowStart, 1)
    const dayAfterTomorrowEnd = endOfDay(dayAfterTomorrowStart)
    if (isEqual(from, dayAfterTomorrowStart) && isEqual(to, dayAfterTomorrowEnd)) {
        return dayAfterTomorrow()
    }

    const startOfThisWeek = startOfWeek(todayStart, { weekStartsOn: 1 })
    const endOfThisWeek = endOfDay(addDays(startOfThisWeek, 6))
    if (isEqual(from, startOfThisWeek) && isEqual(to, endOfThisWeek)) {
        return thisWeek()
    }

    const startOfLastWeek = addDays(startOfThisWeek, -7)
    const endOfLastWeek = endOfDay(addDays(startOfThisWeek, -1))
    if (isEqual(from, startOfLastWeek) && isEqual(to, endOfLastWeek)) {
        return lastWeek()
    }

    const startOfTwoWeeksAgo = addDays(startOfLastWeek, -7)
    const endOfTwoWeeksAgo = endOfDay(addDays(startOfLastWeek, -1))
    if (isEqual(from, startOfTwoWeeksAgo) && isEqual(to, endOfTwoWeeksAgo)) {
        return twoWeeksAgo()
    }

    const endOfNextWeek = endOfDay(addDays(endOfThisWeek, 6))
    if (isEqual(from, endOfThisWeek) && isEqual(to, endOfNextWeek)) {
        return nextWeek()
    }

    const startOfThisMonth = startOfMonth(new Date())
    const endOfThisMonth = endOfDay(addDays(addMonths(startOfThisMonth, 1), -1))
    if (isEqual(from, startOfThisMonth) && isEqual(to, endOfThisMonth)) {
        return thisMonth()
    }

    const startOfLastMonth = addMonths(startOfThisMonth, -1)
    const endOfLastMonth = endOfDay(addDays(startOfThisMonth, -1))
    if (isEqual(from, startOfLastMonth) && isEqual(to, endOfLastMonth)) {
        return lastMonth()
    }

    const days6Ago = addDays(todayStart, -6)
    if (isEqual(from, days6Ago) && isEqual(to, endOfToday)) {
        return last7days()
    }

    const days27Ago = addDays(todayStart, -27)
    if (isEqual(from, days27Ago) && isEqual(to, endOfToday)) {
        return last4weeks()
    }

    return custom(from, to, period)
}

type DateRangeProducer = () => DateRange
type DateRangeMap = { [key: string]: DateRangeProducer }

const dateRangeMap: DateRangeMap = {
    today,
    yesterday,
    tomorrow,
    dayAfterTomorrow,
    thisWeek,
    nextWeek,
    lastWeek,
    twoWeeksAgo,
    thisMonth,
    lastMonth,
    nextMonth,
    thisQuarter,
    thisYear,
    last7days,
    last4weeks,
    last30days,
    last90days,
    lifetime,
}

export function dateRangeWithName(name: DateRangeName | PresetType): DateRange | undefined {
    const producer = dateRangeMap[name]
    return producer ? producer() : undefined
}

function parseDateOrNull(s: string | undefined): Date | null {
    if (!s) {
        return null
    }

    const parsed = parseISODate(s)
    return isValid(parsed) ? parsed : null
}

export function dateRangeFromQuery(
    query: any,
    defaultRange: DateRangeName = 'thisMonth',
    dateRangeVar: string = 'dateRange',
    fromVar: string = 'dateFrom',
    toVar: string = 'dateTo',
): DateRange {
    const dateRangeName = (query[dateRangeVar] as DateRangeName) || defaultRange
    let dateRange = dateRangeWithName(dateRangeName)
    if (dateRange) {
        return dateRange
    }
    const period = query.period || 'day'
    const dateFrom = parseDateOrNull(query[fromVar])
    const dateTo = parseDateOrNull(query[toVar])
    return dateFrom && dateTo
        ? createDateRange(dateFrom, dateTo, period)
        : (dateRangeWithName(defaultRange) as DateRange)
}

export function dateRangeToQuery(
    range: DateRange,
    dateRangeVar: string = 'dateRange',
    fromVar: string = 'dateFrom',
    toVar: string = 'dateTo',
): { [key: string]: string | null } {
    const isCustom = range.name === 'custom'
    if (isCustom) {
        return {
            [dateRangeVar]: range.name,
            [fromVar]: range.from ? range.from.toISOString() : null,
            [toVar]: range.to ? range.to.toISOString() : null,
            period: range.period,
        }
    } else {
        return {
            [dateRangeVar]: range.name,
            [fromVar]: null,
            [toVar]: null,
            period: null,
        }
    }
}
