import React from 'react'
import { ProductRow as ProductListRow, ProductRowProps } from 'products/tableRow'
import { ProductRow as BookingCodesProductsListRow } from 'venue/bookingCodes/productRow'
import { History } from 'history'
import { NestedElement } from 'products/components/nestedContainer'

export interface NestedRowProps {
    rowType: 'product' | 'productIds'
    history: History
    element: NestedElement
    expanded: boolean
    expandable: boolean
    toggleExpanded: () => void
    accountSlug: string
    hasPermission: (permission: string, accountSlug: string) => boolean
}

export function NestedRow(props: NestedRowProps & ProductRowProps & React.HTMLAttributes<HTMLDivElement>) {
    return (
        <>
            {props.rowType === 'product' && <ProductListRow {...props} />}
            {props.rowType === 'productIds' && <BookingCodesProductsListRow {...props} />}
        </>
    )
}
