import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { AppServices } from 'middleware'
import { DateFormatter } from 'uiComponents/date'
import { OnClickMenu, OnClickMenuItem } from 'uiComponents/menus'
import { replaceMessage } from 'uiComponents/messages/actions'
import { ReactTable, ReactTableRows, TableColumn } from 'uiComponents/table/reactTable'
import { downloadFile } from 'utils/downloadFile'
import { DiscountPool } from 'venue/bookingCodes/bookingCodesService'
import { CodePageParams, CODES_PATHS } from 'venue/bookingCodes/paths'
import { getDiscountUpload, PoolUploadRow, setVisibleData } from 'venue/redux'
import AffectsCell from '../_shared/affectsCell'
import CodeStatus from './codeStatus'
import RedeemCodes from './redeemCodes'
import { pathUtils } from 'utils/pathnameFormatter'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import UsageCount from '../_shared/usageCount'
import { ReactTableStateReducer } from 'uiComponents/table/reactTable/interface'
import { DateFormats } from 'utils'

interface PoolUploadsProps {
    row: ReactTableRows<DiscountPool>
}
const PoolUploads: React.FC<PoolUploadsProps> = ({ row }) => {
    const routeParams = useParams<CodePageParams>()
    const location = useLocation()
    const dispatch = useDispatch()
    const poolId = row.original.uuid
    const childs = useMemo(() => {
        const codes = (row.original.codes || []).map((code) => ({ id: code.id, name: code.code }))
        const uploads = (row.original.uploads || []).map((code) => ({ id: code.uuid, name: code.name }))

        return [...codes, ...uploads].sort((childA, childB) => childA.name.localeCompare(childB.name))
    }, [])

    const downloadUpload = useCallback(
        (upload: PoolUploadRow) => {
            downloadFile(
                `${AppServices.accountService.backofficeEndpoint}dashboard_api/codes/export?account_slug=${routeParams.accountSlug}&import_id=${upload.id}&codes_type=discounts`,
                upload.name,
                { method: 'POST' },
                () => {
                    dispatch(replaceMessage('download_error', 'error', 'Failed to download', 5000))
                },
            )
        },
        [routeParams.accountSlug],
    )

    const columns = useMemo(() => {
        return [
            {
                accessor: 'name',
                Header: 'Name',
                Cell: ({ value }) => <span title={value}>{value}</span>,
            },
            {
                accessor: 'products',
                Header: 'Affects',
                width: '6.25rem',
                disableSortBy: true,
                Cell: ({ row }) => {
                    const id = row.original.id
                    const { products } = useSelector(getDiscountUpload(id))

                    return <AffectsCell productIds={products} />
                },
            },
            {
                accessor: 'uploadedBy',
                Header: 'Uploaded by',
                width: '11rem',
                Cell: ({ row }) => {
                    const id = row.original.id
                    const { uploadedBy } = useSelector(getDiscountUpload(id))

                    return <span title={uploadedBy}>{uploadedBy}</span>
                },
            },
            {
                accessor: 'uploadedAt',
                Header: 'Upload date',
                width: '10rem',
                Cell: ({ row }) => {
                    const id = row.original.id
                    const { uploadedAt } = useSelector(getDiscountUpload(id))

                    return (
                        <DateFormatter
                            value={uploadedAt}
                            format={`${DateFormats.LONG_DATE} ${DateFormats.SHORT_TIME}`}
                            fallback="-"
                        />
                    )
                },
            },
            {
                accessor: 'usage',
                Header: 'Count',
                width: '6.25rem',
                Cell: ({ row }) => {
                    const id = row.original.id
                    const { usage } = useSelector(getDiscountUpload(id))

                    return <UsageCount usage={usage} />
                },
            },
            {
                accessor: 'status',
                Header: 'Status',
                width: '13.5rem',
                Cell: ({ row }) => {
                    const id = row.original.id
                    const { status } = useSelector(getDiscountUpload(id))

                    return <CodeStatus status={status as any} />
                },
            },
            {
                accessor: 'actions',
                align: 'right',
                width: '7.625rem',
                style: { overflow: 'unset' },
                disableSortBy: true,
                Cell: ({ row }) => {
                    const id = row.original.id
                    const child = useSelector(getDiscountUpload(id))

                    return (
                        <OnClickMenu title="Actions" kind="action" secondary>
                            {child.type === 'upload' && child.status && (
                                <OnClickMenuItem onClick={() => downloadUpload(child as PoolUploadRow)}>
                                    Download csv
                                </OnClickMenuItem>
                            )}
                            {!!child.status && (
                                <RedeemCodes upload={child as PoolUploadRow}>
                                    {({ openModal }) => (
                                        <OnClickMenuItem onClick={openModal}>Redeem unused</OnClickMenuItem>
                                    )}
                                </RedeemCodes>
                            )}
                            {child.type === 'code' && (
                                <UnstyledLink
                                    to={pathUtils.addQueryString(
                                        pathUtils.populateParams(CODES_PATHS.fullPaths.editCode, {
                                            accountSlug: routeParams.accountSlug,
                                            poolId: poolId,
                                            codeId: child.id,
                                        }),
                                        location.search,
                                    )}
                                >
                                    <OnClickMenuItem>Edit</OnClickMenuItem>
                                </UnstyledLink>
                            )}
                        </OnClickMenu>
                    )
                },
            },
        ] as TableColumn<PoolUploadRow>[]
    }, [routeParams, poolId, location.search, downloadUpload])

    const tableStateReducer = useCallback<ReactTableStateReducer<PoolUploadRow>>(
        (state, action, _, instance) => {
            const pageSize = instance?.state?.pageSize || 10
            const pageIndex = action?.pageIndex || instance?.state?.pageIndex
            const firstIndex = pageIndex ? pageIndex * pageSize : 0
            const ids = childs?.map((row) => row.id)?.slice(firstIndex, firstIndex + pageSize)

            if (ids) {
                dispatch(setVisibleData({ poolId, ids }))
            }

            return state
        },
        [poolId, childs],
    )

    return (
        <div style={{ width: '100%' }}>
            <ReactTable
                size="small"
                pagination="new"
                storeStateInQuery={false}
                stateReducer={tableStateReducer}
                rowProps={() => ({
                    style: {
                        backgroundColor: '#FAFAFB',
                    },
                })}
                elevation={false}
                columns={columns}
                data={childs}
            />
        </div>
    )
}

export default PoolUploads
