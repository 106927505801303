import * as React from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

import { State } from 'store'
import { Account } from 'auth/state'
import { Container, ContainerHeading, HeadingSectionName } from 'uiComponents/settingsContainer'
import { PageTitle, PageHeading, StyledATag } from 'uiComponents/typography'
import { BaseRouteParams } from 'hocs'
import UsersFilter from 'settings/users/usersFilter'

interface UsersPageProps {
    accounts: Account[]
    isConviousAdmin: boolean
}

const UsersPageContainer: React.FC<UsersPageProps> = ({ accounts, children }) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const activeAccount = accounts.find((x) => x.slug === accountSlug) || accounts[0]
    const showFilter = location.pathname.indexOf('user_list') > -1

    return (
        <div style={{ position: 'relative' }} id="account-settings">
            <PageTitle>Users - {activeAccount.name}</PageTitle>
            <PageHeading>
                Here you can view and edit all your users roles and corresponding permissions. For an introduction to
                user management, click&nbsp;
                <StyledATag target="_blank" href="https://support.convious.com/help/adding-new-users">
                    here
                </StyledATag>
                .
            </PageHeading>
            {showFilter && (
                <div style={{ margin: '-1em 0 1em 0' }}>
                    <UsersFilter accountSlug={accountSlug} />
                </div>
            )}
            <Container style={{ marginTop: showFilter ? '0' : '5.3em' }}>
                <ContainerHeading>
                    <HeadingSectionName
                        id="users-list-link"
                        className="active"
                        to={`/account/${accountSlug}/settings/users/user_list`}
                    >
                        Users
                    </HeadingSectionName>
                </ContainerHeading>
                {children}
            </Container>
        </div>
    )
}

const mapStateToProps = (state: State) => {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
        isConviousAdmin: state.auth.user ? state.auth.user.isAdmin : false,
    }
}

export default connect(mapStateToProps)(UsersPageContainer)
