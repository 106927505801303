import React, { createContext, ReactNode } from 'react'
import { MaterialSelectOption } from './types'

interface ComboboxContextProps {
    multiple?: boolean
    nested?: boolean
    optionChilds?: Record<string, MaterialSelectOption[]>
    selectedOptions?: string | string[]
}

export const ComboboxContext = createContext<ComboboxContextProps>({})

interface Props extends ComboboxContextProps {
    children: ReactNode
}

export const ComboboxContextProvider = ({ children, multiple, nested, optionChilds, selectedOptions }: Props) => (
    <ComboboxContext.Provider value={{ multiple, nested, optionChilds, selectedOptions }}>
        {children}
    </ComboboxContext.Provider>
)
