import React, { createContext, useState } from 'react'
import { Parkmap } from '../models'

interface SelectedParkMapContextProps {
    selectedParkMap: Parkmap | null
    setSelectedParkMap: React.Dispatch<React.SetStateAction<Parkmap | null>>
}

type Props = {
    children: JSX.Element
}

export const SelectedParkMapContext = createContext<SelectedParkMapContextProps>({} as SelectedParkMapContextProps)

const SelectedParkMapProvider = ({ children }: Props) => {
    const [selectedParkMap, setSelectedParkMap] = useState<Parkmap | null>(null)

    return (
        <SelectedParkMapContext.Provider
            value={{
                selectedParkMap,
                setSelectedParkMap,
            }}
        >
            {children}
        </SelectedParkMapContext.Provider>
    )
}

export default SelectedParkMapProvider
export const useSelectedParkMapContext = () => React.useContext(SelectedParkMapContext)
