import * as React from 'react'
import { ChannelsServiceContext } from 'channels/context'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { ResellerResponseItem } from 'channels/channelsService'
import { InlineDataTable, HeaderRow, TableHeader, Cell, DataRow } from 'uiComponents/table'
import { TableLoader, ChartDataLoader } from 'uiComponents/loaders'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { InlineEdit } from 'uiComponents/input'
import { delay } from 'utils'
import { AddResellerDialog } from 'channels/addResellerDialog'
import { useDispatch } from 'react-redux'
import { addMessage, removeAllMessages } from 'uiComponents/messages/actions'

interface ResellersPageProps {
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    search?: string
    onSectionChange: (section: string) => void
    creatingModeOn: boolean
    resetCreatingMode: () => void
}

function ResellersPage(props: ResellersPageProps) {
    const channelsService = React.useContext(ChannelsServiceContext)
    const dispatch = useDispatch()

    const [loading, setLoading] = React.useState<boolean>(true)
    const [updating, setUpdating] = React.useState<boolean>(false)
    const [creating, setCreating] = React.useState<boolean>(false)
    const [showErrors, setShowErrors] = React.useState<boolean>(false)
    const [duplicateSlug, setDuplicateSlug] = React.useState<boolean>(false)
    const [resellersList, setResellersList] = React.useState<ResellerResponseItem[]>([])

    React.useEffect(() => {
        props.onSectionChange('resellers')
        !!resellersList.length ? getListData('update') : getListData('load')
    }, [props.accountSlug, props.search])

    React.useEffect(() => {
        if (props.creatingModeOn) {
            setCreating(props.creatingModeOn)
        }
    }, [props.creatingModeOn])

    React.useEffect(() => {
        if (!creating) {
            props.resetCreatingMode()
        }
    }, [creating])

    const updateLoaders = (action: 'load' | 'update' | null, state: boolean) => {
        if (action === 'load') {
            setLoading(state)
        } else if (action === 'update') {
            setUpdating(state)
        }
    }

    async function getListData(action: 'load' | 'update' | null = null) {
        updateLoaders(action, true)
        try {
            const query = props.search ? `?search=${props.search}` : ''
            const data = await channelsService.getResellersList(props.accountSlug, query)
            setResellersList(data)
            updateLoaders(action, false)
        } catch {
            updateLoaders(action, false)
            dispatch(addMessage('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.'))
        }
    }

    const formValid = (name: string, slug: string) => {
        const duplicate = !!resellersList.find((r) => r.resellerId === slug)
        setShowErrors(true)
        setDuplicateSlug(duplicate)
        return !!name && !!slug && !duplicate
    }

    const onSaveNewReseller = async (name: string, slug: string) => {
        if (!formValid(name, slug)) {
            return
        }
        updateLoaders('update', true)
        try {
            await channelsService.createReseller(props.accountSlug, name, slug)
            await getListData('update')
            setCreating(false)
            dispatch(addMessage('success', 'success', `Reseller ${name} was created successfully.`))
            await delay(5000)
            dispatch(removeAllMessages())
        } catch (e) {
            if (e.message === 'reseller_exists') {
                setDuplicateSlug(true)
            } else {
                dispatch(
                    addMessage(
                        'unknown_error',
                        'error',
                        'Oops! We could not create a new reseller. Please try again later.',
                    ),
                )
            }
        } finally {
            updateLoaders('update', false)
        }
    }

    const onNameEditAccept = async (resellerId: string, name: string) => {
        updateLoaders('update', true)
        try {
            await channelsService.updateReseller(props.accountSlug, name, resellerId)
            await getListData('update')
        } catch {
            dispatch(
                addMessage('unknown_error', 'error', 'Oops! We could not update the name. Please try again later.'),
            )
        } finally {
            updateLoaders('update', false)
        }
    }

    const columnWidths = [null, null]

    return (
        <>
            {creating && (
                <AddResellerDialog
                    accountSlug={props.accountSlug}
                    showErrors={showErrors}
                    duplicateSlug={duplicateSlug}
                    loading={updating}
                    onCancel={() => setCreating(false)}
                    onConfirm={onSaveNewReseller}
                />
            )}
            {loading && <TableLoader />}
            {!loading && (
                <>
                    <InlineDataTable columnWidths={columnWidths} id="resellers-list">
                        <HeaderRow>
                            <TableHeader>Name</TableHeader>
                            <TableHeader>ID</TableHeader>
                        </HeaderRow>
                        {updating && !loading && <ChartDataLoader />}
                        {resellersList.length === 0 && !creating && <NoResultsRow text="There are no resellers yet." />}
                        {resellersList.length > 0 &&
                            resellersList.map((item, i) => {
                                return (
                                    <DataRow key={i} narrow>
                                        <Cell title={item.name} className="pool-name">
                                            <InlineEdit
                                                id="reseller-name"
                                                value={item.name}
                                                maxLength={100}
                                                onEditAccept={(v) => onNameEditAccept(item.resellerId, v)}
                                                responsiveWidth
                                            />
                                        </Cell>
                                        <Cell title={item.resellerId}>
                                            <span>{item.resellerId}</span>
                                        </Cell>
                                    </DataRow>
                                )
                            })}
                    </InlineDataTable>
                </>
            )}
        </>
    )
}

export default withNavigation(ResellersPage)
