import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { LoaderWrapper } from 'uiComponents/loaders'
import { useSelectedParkMapContext } from 'venue/parkMap/context/selectedParkMap'
import { POI } from 'venue/parkMap/models'
import { useParkMapViewerContext } from '../../../context/mapViewer'
import { useCreatePoiMutation, useParkMapCategoriesQuery } from 'venue/parkMap/reduxQuery'
import useMarkers from '../../../hooks/useMarkers'
import { ParkMapPagesParams } from '../../../paths'
import { STATUS } from './consts'
import PoiForm from './poiForm'

const CreatePoi = () => {
    const { categoryId } = useParams<ParkMapPagesParams>()
    const { selectedParkMap } = useSelectedParkMapContext()
    const { map } = useParkMapViewerContext()
    const { data } = useParkMapCategoriesQuery({ locationId: selectedParkMap?.locationId })
    const [createPoi, { isLoading }] = useCreatePoiMutation()
    const history = useHistory()
    const { displayMarkers } = useMarkers()
    const categories = useParkMapCategoriesQuery({ locationId: selectedParkMap?.locationId })
    const iconCategory = categories?.data?.find((category) => category.id === categoryId)?.icon

    useEffect(() => {
        if (data?.length) {
            displayMarkers(data)
        }
    }, [data])

    const center = map?.getCenter()

    const poi = (): POI | undefined => {
        if (selectedParkMap) {
            return {
                id: '',
                slug: selectedParkMap.slug,
                locationId: selectedParkMap.locationId,
                categoryId: categoryId,
                status: STATUS[0].value,
                lat: center?.lat() || 0,
                lng: center?.lng() || 0,
                image: '',
                icon: iconCategory,
                iconSvg: null,
                content: [
                    {
                        locale: 'en',
                        name: '',
                        description: '',
                        default: true,
                    },
                ],
                action: {
                    link: '',
                    icon: '',
                    iconSvg: null,
                    content: [
                        {
                            locale: 'en',
                            title: '',
                            subtitle: '',
                            buttonCaption: '',
                            default: true,
                        },
                    ],
                },
            }
        }

        return undefined
    }

    return (
        <LoaderWrapper loading={isLoading}>
            <PoiForm
                pageTitle="Add POI"
                data={poi()}
                onSubmit={(data) => {
                    createPoi({ poi: data })
                        .unwrap()
                        .then(() => history.goBack())
                }}
            />
        </LoaderWrapper>
    )
}

export default CreatePoi
