import * as React from 'react'
import styled from 'styled-typed'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { Col } from 'uiComponents/flex'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { Checkbox } from 'uiComponents/input'
import {
    ConfigField,
    buyerPersonalFields,
    ticketHolderPersonalFields,
    buyerContactFields,
    ticketHolderContactFields,
    otherFields,
    FieldType,
    FieldCategory,
} from './schema'
import { ToggleComponent, InputComponent, SelectComponent } from 'uiComponents/studio/form/fieldComponents'
import { ActionButton } from 'uiComponents/buttons'
import { AlignedCol } from 'uiComponents/studio/form/formComponents'
import { faTrash, faTrashUndo } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome'
import { ErrorMessage } from 'uiComponents/studio/form/errorMessage'

const AddSecionToggle = styled.div`
    cursor: pointer;
    font-size: 0.7em;
    color: ${(props) => props.theme.colors.textLight};
    margin-bottom: 1em;
`
const Icon = styled(FontAwesomeIcon)`
    cursor: pointer;
    color: ${(props) => props.theme.colors.textLight};
    font-size: 2em;
    margin-right: 0.5em;
    padding-bottom: 0.15em;

    &.active {
        color: ${(props) => props.theme.colors.boyBlue};
        &:hover {
            color: ${(props) => props.theme.colors.status.error};
        }
    }
`
const Text = styled.div`
    &.deleted {
        opacity: 0.5;
        text-decoration: line-through;
    }
`

interface SectionProps {
    accountSlug: string
    category: FieldCategory
    fieldsConfig: ConfigField[]
    onAddField: (type: FieldType, label: string, category: FieldCategory) => void
    handleChange: (value: boolean, item: string, type: 'active' | 'optional', fieldCategory: FieldCategory) => void
}

function ActivateSection(props: SectionProps) {
    const labelError = React.useRef<string>('')
    const [customType, setCustomType] = React.useState<FieldType>('text')
    const [customLabel, setCustomLabel] = React.useState<string>('')
    const [showCustomFieldInputs, setShowCustomFieldInputs] = React.useState<boolean>(false)
    const [, setShowLabelError] = React.useState<boolean>(false)

    const onAddField = () => {
        if (!customLabel.trim()) {
            labelError.current = 'Please enter the label text.'
            setShowLabelError(true)
            return
        }
        props.onAddField(customType, customLabel.trim(), props.category)
        setShowCustomFieldInputs(false)
        setCustomType('text')
        setCustomLabel('')
    }

    const onFinishTyping = (v: string) => {
        if (!!labelError.current && !!v.trim()) {
            labelError.current = ''
            setShowLabelError(false)
        }
    }

    const fieldTypes = [
        { name: 'Text field', value: 'text' },
        { name: 'Email field', value: 'email' },
        { name: 'Phone number field', value: 'phone' },
        { name: 'Country selector', value: 'country' },
        { name: 'Date field', value: 'date' },
        { name: 'Yes/No', value: 'bool' },
        { name: 'Image upload', value: 'image' },
    ]

    type TextField = 'text'
    type SelectType = 'select'
    const labelField = {
        name: 'label-text',
        type: 'text' as TextField,
        label: 'Input label text in English',
        path: '',
        defaultValue: 'Dafault',
    }
    const typeField = {
        type: 'select' as SelectType,
        name: 'field-type',
        label: 'Input type',
        path: '',
        span: 12,
        defaultValue: 'text',
        availableOptions: fieldTypes,
    }

    const title = props.category === 'buyer' ? 'Buyer' : 'Ticket holder'
    const personalFieldsList = props.category === 'buyer' ? buyerPersonalFields : ticketHolderPersonalFields
    const contactFieldsList = props.category === 'buyer' ? buyerContactFields : ticketHolderContactFields
    const customFieldsList = props.fieldsConfig.filter((f) => f.custom)

    return (
        <>
            <SectionWrapper title={`${title} personal information`}>
                {personalFieldsList.map((f, i) => {
                    const field = props.fieldsConfig.find((x) => x.slug === f.slug)
                    if (!field) {
                        return
                    }
                    return (
                        <FormToggleField
                            key={field.slug + i}
                            config={field}
                            category={props.category}
                            handleChange={props.handleChange}
                        />
                    )
                })}
            </SectionWrapper>
            <SectionWrapper title={`${title} contact information`}>
                {contactFieldsList.map((f) => {
                    const field = props.fieldsConfig.find((x) => x.slug === f.slug)
                    if (!field) {
                        return
                    }
                    return (
                        <FormToggleField
                            key={field.slug}
                            config={field}
                            category={props.category}
                            handleChange={props.handleChange}
                        />
                    )
                })}
            </SectionWrapper>
            {props.category === 'buyer' && (
                <SectionWrapper title="Other information">
                    {otherFields.map((f) => {
                        const field = props.fieldsConfig.find((x) => x.slug === f.slug)
                        if (!field) {
                            return
                        }
                        return (
                            <FormToggleField
                                key={field.slug}
                                config={field}
                                category={props.category}
                                handleChange={props.handleChange}
                            />
                        )
                    })}
                </SectionWrapper>
            )}
            <SectionWrapper title={`${title} custom fields`}>
                {customFieldsList.map((f) => {
                    const field = props.fieldsConfig.find((x) => x.slug === f.slug)
                    if (!field) {
                        return
                    }
                    return (
                        <FormToggleField
                            key={field.slug}
                            config={field}
                            customField
                            category={props.category}
                            handleChange={props.handleChange}
                        />
                    )
                })}
                {!showCustomFieldInputs && (
                    <AddSecionToggle onClick={() => setShowCustomFieldInputs(true)}>
                        + Add a custom field
                    </AddSecionToggle>
                )}
                {showCustomFieldInputs && (
                    <>
                        <FormItem>
                            <Col span={12}>
                                <label htmlFor={typeField.name}>{typeField.label}</label>
                            </Col>
                            <Col span={12}>
                                <SelectComponent
                                    id="typeField"
                                    field={typeField}
                                    handleChange={(v: FieldType) => setCustomType(v)}
                                    value={customType}
                                />
                            </Col>
                        </FormItem>
                        <FormItem>
                            <Col span={12}>
                                <label htmlFor={labelField.name}>{labelField.label}</label>
                            </Col>
                            <Col span={12}>
                                <InputComponent
                                    field={labelField}
                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setCustomLabel(e.target.value)
                                    }
                                    value={customLabel}
                                    maxLength={100}
                                    style={{ fontWeight: 'normal' }}
                                    checkValidity={onFinishTyping}
                                    error={!!labelError.current}
                                />
                                {!!labelError.current && (
                                    <ErrorMessage
                                        errText={labelError.current}
                                        style={{ position: 'absolute', bottom: '3em' }}
                                    />
                                )}
                            </Col>
                        </FormItem>
                        <FormItem>
                            <Col span={12}>
                                <ActionButton onClick={onAddField}>+ Add</ActionButton>
                            </Col>
                        </FormItem>
                    </>
                )}
            </SectionWrapper>
        </>
    )
}

export default ActivateSection

interface FormToggleFieldProps {
    config: ConfigField
    category: FieldCategory
    customField?: boolean
    handleChange: (v: boolean, name: string, type: 'active' | 'optional', fieldCategory: FieldCategory) => void
}

function FormToggleField(props: FormToggleFieldProps) {
    type ToggleType = 'toggle'
    const field = {
        label: props.config.name,
        name: props.config.slug,
        type: 'toggle' as ToggleType,
        path: '',
        defaultValue: true,
    }
    const removedCustomField = props.customField && !props.config.active
    return (
        <FormItem>
            <AlignedCol span={6.5}>
                <label htmlFor={field.name}>
                    <Text className={removedCustomField ? 'deleted' : ''}>{field.label}</Text>
                </label>
            </AlignedCol>
            <AlignedCol span={3}>
                <Checkbox
                    name={field.name}
                    onChange={(e) => props.handleChange(e.target.checked, field.name, 'optional', props.category)}
                    checked={props.config.optional}
                    style={{ marginRight: '.5em' }}
                    disabled={removedCustomField}
                />
                <Text className={removedCustomField ? 'deleted' : ''}>optional</Text>
            </AlignedCol>
            <AlignedCol span={2.5} className="align-right">
                {!props.customField && (
                    <ToggleComponent
                        field={field}
                        handleChange={(v: boolean) => props.handleChange(v, field.name, 'active', props.category)}
                        value={props.config.active}
                    />
                )}
                {props.customField && props.config.active && (
                    <Icon
                        className="active"
                        icon={faTrash as IconProp}
                        onClick={() => props.handleChange(false, field.name, 'active', props.category)}
                        title="Remove"
                    />
                )}
                {removedCustomField && (
                    <Icon
                        icon={faTrashUndo as IconProp}
                        onClick={() => props.handleChange(true, field.name, 'active', props.category)}
                        title="Restore"
                    />
                )}
            </AlignedCol>
        </FormItem>
    )
}
