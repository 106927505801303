import React, { useState, useEffect } from 'react'
import { DynamicInterval } from 'products/pricing/pricingService'
import { Currency } from 'uiComponents/money/moneyHoc'
import { DynamicRuleItem } from './dynamicRuleItem'
import { Row } from 'uiComponents/flex'
import { RuleFormContainer } from './shared'

interface DynamicIntervalsProps {
    intervals: DynamicInterval[] | null
    setIntervals: (intervals: DynamicInterval[] | null) => void
    currency: Currency
    validate: boolean
}

export function DynamicIntervals(props: DynamicIntervalsProps) {
    const [newRow, setNewRow] = useState<boolean>(true)

    useEffect(() => {
        if ((props.intervals && props.intervals.length === 0) || !props.intervals) {
            setNewRow(true)
        } else {
            setNewRow(false)
        }
    }, [props.intervals])

    function setInterval(index: number | null, intervalObject: DynamicInterval) {
        if ((!!index || index === 0) && !!props.intervals) {
            const newIntervals = [...props.intervals]
            newIntervals[index] = intervalObject
            props.setIntervals(newIntervals)
        } else if (!index && !props.intervals) {
            props.setIntervals([intervalObject])
        } else if (!index && !!props.intervals) {
            props.setIntervals([...props.intervals, intervalObject])
        }
    }
    function removeInterval(index: number) {
        if (index === -1) {
            setNewRow(false)
            return
        }
        if (props.intervals && props.intervals.length > 0) {
            const newIntervals = [...props.intervals]
            newIntervals.splice(index, 1)
            props.setIntervals(newIntervals)
        }
    }

    function addNewRow() {
        setNewRow(true)
    }

    return (
        <RuleFormContainer style={{ height: 'auto' }}>
            <Row style={{ marginBottom: '.5em', fontSize: '.9em' }}>
                <div style={{ width: '14.5em' }}>Action</div>
                <div style={{ width: '18.5em' }}>Amount</div>
            </Row>
            <Row style={{ flexDirection: 'column' }}>
                {props.intervals &&
                    props.intervals.map((i, index) => (
                        <DynamicRuleItem
                            key={index}
                            index={index}
                            interval={i}
                            setInterval={(intervalObject) => setInterval(index, intervalObject)}
                            removeInterval={() => removeInterval(index)}
                            showRemoveRow={true}
                            currency={props.currency}
                            validate={props.validate}
                            addNewRow={addNewRow}
                            showNewRow={
                                !newRow &&
                                !!props.intervals &&
                                props.intervals.length > 0 &&
                                index === props.intervals.length - 1
                            }
                        />
                    ))}
                {newRow && (
                    <DynamicRuleItem
                        interval={null}
                        index={null}
                        showRemoveRow={!!props.intervals && props.intervals.length > 0}
                        removeInterval={() => removeInterval(-1)}
                        setInterval={(intervalObject) => setInterval(null, intervalObject)}
                        currency={props.currency}
                        validate={false}
                    />
                )}
            </Row>
        </RuleFormContainer>
    )
}
