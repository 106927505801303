import { isValid, parseISO, ParseISOOptions, format, parse, ParseOptions, isMatch } from 'date-fns'
import { formatInTimeZone, FormatOptionsWithTZ } from 'date-fns-tz'
import { DateLocale } from 'preferences/state'

type DateFormat = {
    EXTRA_SHORT_DATE: string
    SHORT_DATE: string
    LONG_DATE: string
    EXTRA_LONG_DATE: string
    SHORT_TIME: string
    LONG_TIME: string
}

const US_FORMATS: DateFormat = {
    EXTRA_SHORT_DATE: 'MMM d',
    SHORT_DATE: 'MM/dd/yyyy',
    LONG_DATE: 'MMM d, yyyy',
    EXTRA_LONG_DATE: 'MMMM do, yyyy',
    SHORT_TIME: 'h:mm a',
    LONG_TIME: 'h:mm:ss a',
}

const INTL_FORMATS: DateFormat = {
    EXTRA_SHORT_DATE: 'd MMM',
    SHORT_DATE: 'dd/MM/yyyy',
    LONG_DATE: 'd MMM yyyy',
    EXTRA_LONG_DATE: 'd MMMM yyyy',
    SHORT_TIME: 'HH:mm',
    LONG_TIME: 'HH:mm:ss',
}

interface DateFormatManager extends DateFormat {
    setLocale: (locale: DateLocale) => void
}

const createDateFormatManager = (): DateFormatManager => {
    let formats = INTL_FORMATS

    const setLocale = (locale: DateLocale) => {
        formats = locale === 'US' ? US_FORMATS : INTL_FORMATS
    }

    return {
        get EXTRA_SHORT_DATE() {
            return formats.EXTRA_SHORT_DATE
        },
        get SHORT_DATE() {
            return formats.SHORT_DATE
        },
        get LONG_DATE() {
            return formats.LONG_DATE
        },
        get EXTRA_LONG_DATE() {
            return formats.EXTRA_LONG_DATE
        },
        get SHORT_TIME() {
            return formats.SHORT_TIME
        },
        get LONG_TIME() {
            return formats.LONG_TIME
        },
        setLocale,
    }
}

export const DateFormats = createDateFormatManager()

export const parseISODate = (dateString: string | Date, options?: ParseISOOptions): Date => {
    if (dateString instanceof Date) {
        return dateString
    }
    if (dateString === null) {
        return new Date(0)
    }
    if (typeof dateString === 'undefined') {
        console.warn('undefined passed to parseISODate')
        return new Date(NaN)
    }
    const date = parseISO(dateString, options)
    if (!isValid(date)) {
        console.warn('Invalid input passed to parseISODate:', dateString)
    }
    return date
}

const formatWithOptionalTimeZone = (date: Date, formatString: string, options?: FormatOptionsWithTZ): string => {
    if (options?.timeZone) {
        return formatInTimeZone(date, options.timeZone, formatString, options)
    } else {
        return format(date, formatString, options)
    }
}

export const formatOrInvalid = (date: Date, formatString: string, options?: FormatOptionsWithTZ): string => {
    if (!isValid(date)) {
        return 'Invalid date'
    }
    return formatWithOptionalTimeZone(date, formatString, options)
}

export const formatISOString = (
    dateString: string | Date,
    formatString: string,
    options?: FormatOptionsWithTZ,
): string => {
    const date = parseISODate(dateString)
    if (!isValid(date)) {
        console.warn(`Invalid dateString: ${dateString}`)
        return 'Invalid date'
    }
    return formatWithOptionalTimeZone(date, formatString, options)
}

export const convertDateFormat = (
    dateString: string,
    fromFormat: string,
    toFormat: string,
    formatOptions?: FormatOptionsWithTZ,
    parseOptions?: ParseOptions,
): string => {
    const date = parse(dateString, fromFormat, new Date(), parseOptions)
    if (!isValid(date)) {
        console.warn(`Invalid dateString: ${dateString}`)
        return 'Invalid date'
    }
    return formatWithOptionalTimeZone(date, toFormat, formatOptions)
}

export const parseTime = (timeString: string, formatString: string = 'HH:mm', options?: ParseOptions) =>
    parse(timeString, formatString, new Date(), options)

export const convertTimeFormat = (
    timeString: string,
    fromFormat: string = 'HH:mm',
    toFormat: string = DateFormats.SHORT_TIME,
    formatOptions?: FormatOptionsWithTZ,
    parseOptions?: ParseOptions,
): string => {
    const date = parseTime(timeString, fromFormat, parseOptions)
    if (!isValid(date)) {
        console.warn(`Invalid timeString: ${timeString}`)
        return 'Invalid time'
    }
    return formatWithOptionalTimeZone(date, toFormat, formatOptions)
}

export const parseTimeOrNull = (timeString?: string | null, formatString: string = 'HH:mm') => {
    if (!timeString) {
        return null
    }
    return parseTime(timeString, formatString)
}

export const parseDate = (value: string) => {
    let parsed: Date
    if (isMatch(value, 'dd.MM.yyyy')) {
        parsed = parse(value, 'dd.MM.yyyy', new Date())
    } else if (isMatch(value, 'yyyy-MM-dd')) {
        parsed = parse(value, 'yyyy-MM-dd', new Date())
    } else {
        parsed = parseISODate(value)
    }

    if (!isValid(parsed)) {
        return null
    }

    return parsed
}
