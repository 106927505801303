import * as React from 'react'
import TimeseriesBarChart from 'uiComponents/charts/timeseriesBarChart'
import { StatsService } from 'http/statsService'
import { format } from 'date-fns'
import { TimeseriesBarChartSource } from 'uiComponents/charts/timeseriesBarChartSource'
import { ChartWrapperWithTotals } from '../helpers'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import {
    areDateRangeDatesEqual,
    mapTimeseriesForTooltip,
    getTooltipRow,
    getTooltipXAxisLabel,
    getTooltipHeader,
    getTooltipFooter,
} from 'reports/helpers'
import { DateRange } from 'dateRanges'
import { Filters } from 'uiComponents/filter/schema'
import isEqual from 'lodash/isEqual'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import { DataPoint } from 'reports/schema'

interface TrafficChartProps {
    filters: Filters[]
    dateRange: DateRange
    statsService: StatsService
    accountSlug: string
    chart: TimeseriesBarChartSource
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
}

interface TrafficChartState {
    total: string
    mappedSeries: DataPoint[]
    loading: boolean
}

class TrafficChart extends React.Component<TrafficChartProps, TrafficChartState> {
    _isMounted = false
    private _lastRequest?: number = undefined

    constructor(props: TrafficChartProps) {
        super(props)
        this.state = {
            total: '0',
            mappedSeries: [],
            loading: true,
        }
    }

    async componentDidMount() {
        this._isMounted = true
        await this.getTrafficData()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    async componentDidUpdate(prevProps: TrafficChartProps) {
        if (
            !areDateRangeDatesEqual(prevProps.dateRange, this.props.dateRange) ||
            prevProps.accountSlug !== this.props.accountSlug ||
            !isEqual(prevProps.filters, this.props.filters)
        ) {
            await this.getTrafficData()
        }
    }

    getTrafficData = async () => {
        const requestTime = new Date().valueOf()
        this._lastRequest = requestTime
        this.setState({ loading: true })
        const dateFrom = format(this.props.chart.from(), 'yyyy-MM-dd')
        const dateTo = format(this.props.chart.to(), 'yyyy-MM-dd')
        try {
            const data = await this.props.statsService.getReportStats(
                'generalTimeSeries',
                this.props.accountSlug,
                'website_sessions',
                dateFrom,
                dateTo,
                this.props.chart.granularity,
                this.props.filters,
            )
            if (this._lastRequest !== requestTime) {
                return
            }
            this.props.chart.formatSeries(data.stats.timeSeries)
            const total = data.stats.totals.filter((t) => t.name === 'total_value')[0]
            const xAxisCategories = this.props.chart.props().xAxisDataRaw
            const mappedSeries =
                data.stats.timeSeries.length > 0
                    ? mapTimeseriesForTooltip(
                          xAxisCategories,
                          data.stats.timeSeries[0].points,
                          this.props.chart.granularity,
                      )
                    : []
            if (this._isMounted) {
                this.setState({
                    total: total.value.toFixed(0),
                    mappedSeries,
                    loading: false,
                })
            }
        } catch {
            this.props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Traffic chart could not be loaded, please try again later.',
            )
        }
    }

    chartTooltipFormatter = (params: TooltipParams[]) => {
        const trafficPoint = this.state.mappedSeries[params[0].dataIndex]
        const xAxisLabel = trafficPoint
            ? getTooltipXAxisLabel(trafficPoint.timestamp, this.props.chart.granularity)
            : params[0].axisValue

        let tooltip = getTooltipHeader(xAxisLabel)
        tooltip += getTooltipRow([`${params[0].marker} Website traffic:`, params[0].value])
        tooltip += getTooltipFooter()

        return tooltip
    }

    render() {
        const series = this.props.chart.props().series
        const axisData = this.props.chart.props().xAxisData
        const { loading, total } = this.state
        return (
            <div style={{ position: 'relative' }}>
                {loading && <ChartDataLoader />}
                <ChartWrapperWithTotals total={total} id="traffic-total" title="Total website traffic">
                    <TimeseriesBarChart
                        axisData={axisData}
                        series={series}
                        tooltipFormatter={this.chartTooltipFormatter}
                        loading={loading}
                    />
                </ChartWrapperWithTotals>
            </div>
        )
    }
}

export default TrafficChart
