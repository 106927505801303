import * as React from 'react'
import { usePrevious, useIsMounted } from 'reactUtils'
import TimeseriesBarChart from 'uiComponents/charts/timeseriesBarChart'
import { StatsServiceContext } from 'http/context'
import { DateRange } from 'dateRanges'
import { format } from 'date-fns'
import { TimeseriesBarChartSource } from 'uiComponents/charts/timeseriesBarChartSource'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import {
    areDateRangeDatesEqual,
    mapTimeseriesForTooltip,
    getTooltipRow,
    getTooltipXAxisLabel,
    getTooltipHeader,
    getTooltipFooter,
    AxisTitle,
} from 'reports/helpers'
import { Filters } from 'uiComponents/filter/schema'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { DataPoint } from 'reports/schema'
import { QueryConfig } from 'reports/queryGenerator'
import isEqual from 'lodash/isEqual'
import { withFeatures } from 'features'
import { LoginService } from 'http/loginService'
import { LoggingService } from 'http/loggingService'
import { ChartHeadline, ChartContainer } from 'uiComponents/charts/styleComponents'

const queryConfig: QueryConfig = {
    querySetName: 'DiscountCodesTimeseriesChart',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'metric', type: 'StatsMetric' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'groupBy', type: 'String' },
        { name: 'filters', type: '[FilterDictionary]' },
        { name: 'granularity', type: 'StatsGranularity' },
    ],
    queries: [
        {
            name: 'codesUsed',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'groupBy', 'filters'],
            customVariables: [],
            presetResult: 'timeseries',
        },
        {
            name: 'codesUsedTotals',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'filters'],
            customVariables: [],
            presetResult: 'timeseries',
        },
    ],
}

interface RevenueChartProps {
    dateRange: DateRange
    filters: Filters[]
    accountSlug: string
    chart: TimeseriesBarChartSource
    groupByLocation: boolean
    backofficeEndpoint: string
    loginService: LoginService
    loggingService: LoggingService
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hideMessage: (id: string) => void
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    getCurrency: (accountSlug: string) => Currency
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function RevenueChart(props: RevenueChartProps) {
    const isMounted = useIsMounted()
    const _lastRequest = React.useRef<number>()
    const statsService = React.useContext(StatsServiceContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [mappedTotals, setMappedTotals] = React.useState<DataPoint[]>([])

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    const prevFilters = usePrevious(props.filters)
    const prevGroupBy = usePrevious(props.groupByLocation)
    React.useEffect(() => {
        if (
            prevAccountSlug !== props.accountSlug ||
            !areDateRangeDatesEqual(prevDateRange, props.dateRange) ||
            !isEqual(prevFilters, props.filters) ||
            prevGroupBy !== props.groupByLocation
        ) {
            getData()
        }
    }, [props.accountSlug, props.dateRange, props.filters, props.groupByLocation])

    const getData = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        setLoading(true)
        const dateFrom = format(props.chart.from(), 'yyyy-MM-dd')
        const dateTo = format(props.chart.to(), 'yyyy-MM-dd')
        try {
            const variables = {
                widget: props.accountSlug,
                metric: 'discount_coupons_used',
                dateFrom,
                dateTo,
                groupBy: 'discount_name',
                filters: props.filters,
                granularity: props.chart.granularity,
            }
            const data = await statsService.getStats(queryConfig, variables)
            if (_lastRequest.current !== requestTime) {
                return
            }
            props.chart.formatSeries(data.codesUsed.timeSeries)
            const xAxisCategories = props.chart.props().xAxisDataRaw
            const mappedTotalsData = !!data.codesUsed.timeSeries.length
                ? mapTimeseriesForTooltip(
                      xAxisCategories,
                      data.codesUsedTotals.timeSeries[0].points,
                      props.chart.granularity,
                  )
                : []
            if (!isMounted.current) {
                return
            }
            setMappedTotals(mappedTotalsData)
            setLoading(false)
        } catch {
            props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Discount codes timeseries chart could not be loaded, please try again later.',
            )
        }
    }

    const chartTooltipFormatter = (params: TooltipParams[]) => {
        const totalsPoint = mappedTotals[params[0].dataIndex]
        const totalData = totalsPoint?.value || '0'

        const xAxisLabel = totalsPoint
            ? getTooltipXAxisLabel(totalsPoint.timestamp, props.chart.granularity)
            : params[0].axisValue

        let tooltip = getTooltipHeader(xAxisLabel)

        const paramsWithValue = params
            .filter((p) => Number(p.value) > 0)
            .sort((a, b) => Number(b.value) - Number(a.value))
        const individualParamsNumber = 20
        if (paramsWithValue.length <= individualParamsNumber) {
            tooltip += getTooltipRow(['Total:', totalData], true)
            paramsWithValue.forEach((p) => {
                tooltip += getTooltipRow([`${p.marker} ${p.seriesName}:`, p.value])
            })
        } else {
            tooltip += getTooltipRow(['Total:', totalData], true)
            const individualParams = paramsWithValue.slice(0, individualParamsNumber)
            individualParams.forEach((p) => {
                tooltip += getTooltipRow([`${p.marker} ${p.seriesName}:`, p.value])
            })
            const sumParams = paramsWithValue.slice(
                individualParamsNumber,
                paramsWithValue.length - individualParamsNumber,
            )
            const otherSum = sumParams.reduce((sum, p) => sum + Number(p.value), 0)
            if (otherSum > 0) {
                tooltip += getTooltipRow(['Other:', otherSum])
            }
        }
        tooltip += getTooltipFooter()

        return tooltip
    }

    const axisData = props.chart.props().xAxisData
    const series = props.chart.props().series
    const legendData = props.chart.props().legendData

    return (
        <ChartContainer style={{ marginTop: '1em', marginBottom: '2em' }}>
            {loading && <ChartDataLoader />}
            <AxisTitle title="Purchase dates" left="50%" bottom="1.5em" style={{ transform: 'translateX(-50%)' }} />
            <ChartHeadline size={4}>Discount codes</ChartHeadline>
            <TimeseriesBarChart
                axisData={axisData}
                series={series}
                legendData={legendData.length > 1 ? legendData : null}
                loading={loading}
                tooltipFormatter={chartTooltipFormatter}
                height="400px"
                titleOffset={20}
            />
        </ChartContainer>
    )
}

export default withFeatures(withCurrency(RevenueChart))
