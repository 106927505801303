import * as React from 'react'
import { FrameWrapper, PreviewFrame, SafariMobilePreviewSrollWrapper } from 'ticketTemplates/studio/imports'
import { ChartDataLoader } from 'uiComponents/loaders'

interface TemplatePreviewProps {
    templateHtml: string
    loading: boolean
}

export function TemplatePreview(props: TemplatePreviewProps) {
    const frameRef = React.useRef(null)
    const [src, setSrc] = React.useState<string>('')

    React.useEffect(() => {
        const blob = new Blob([props.templateHtml], { type: 'text/html' })
        const frameSrc = URL.createObjectURL(blob)
        setSrc(frameSrc)
    }, [props.templateHtml])

    return (
        <FrameWrapper style={{ backgroundColor: 'white', borderRadius: '0.2em', margin: '1em' }}>
            <SafariMobilePreviewSrollWrapper>
                <PreviewFrame src={src} ref={frameRef} zoom={1} />
                {props.loading && <ChartDataLoader />}
            </SafariMobilePreviewSrollWrapper>
        </FrameWrapper>
    )
}
