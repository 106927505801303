import { FundAccountStatus } from 'admin/types'
import React from 'react'
import styled from 'styled-typed'
import { Col, Row } from 'uiComponents/flex'

const FundStatusStyles = styled.div<{ state: FundAccountStatus }>`
    background: ${(props) => {
        if (props.state === 'READY') {
            return props.theme.colors.emerald
        }

        if (props.state === 'PAYOUT_ON_HOLD') {
            return props.theme.colors.status.warn
        }

        if (props.state === 'PENDING') {
            return props.theme.colors.status.warn
        }

        return props.theme.colors.border
    }};
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    line-height: 1rem;
`

interface Props {
    state: FundAccountStatus
}

const stateToText = (state: FundAccountStatus) => {
    switch (state) {
        case 'NOT_CONFIGURED':
            return 'Not configured'
        case 'PAYOUT_ON_HOLD':
            return 'Payout locked'
        case 'PENDING':
            return 'Pending'
        case 'READY':
            return 'Debt, Ready'
        default:
            return 'Unknown'
    }
}

const AccountFundStatus: React.FC<Props> = ({ state }) => {
    return (
        <Row gutter={8} vAlign="center">
            <Col span="auto">
                <FundStatusStyles state={state} />
            </Col>
            <Col span="auto">{stateToText(state)}</Col>
        </Row>
    )
}

export default AccountFundStatus
