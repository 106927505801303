import * as React from 'react'
import styled from 'styled-typed'
import { DateRange } from 'dateRanges'
import { TextInput } from 'uiComponents/input'
import { SingleSelect } from 'uiComponents/input/singleSelect'
import { ValidationMessage } from 'uiComponents/form/formElements'
import { transparency } from 'utils/css'
import { Option } from './schema'

export const DateRangeInputs = styled.div`
    display: flex;
    margin-top: 0.5em;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in, visibility 0s 0.2s;

    &.visible {
        opacity: 1;
        visibility: visible;
        transition: visibility 0s;
    }
`
export const InputBox = styled.div`
    display: flex;
    flex-direction: column;
`
export const InputSeparator = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0.5em;
`
export const ValidationNotice = styled(ValidationMessage)`
    top: 0.5em;
    margin-left: 0.2em;
    font-size: 0.7em;
`
const DateRangeSelect = styled.div`
    display: flex;
    align-items: flex-end;
    position: relative;
    justify-content: space-between;
    margin-bottom: 0.8em;
`

const DateRangeControls = styled.div`
    width: max-content;
    margin: auto;
`

export const DateTextInput = styled(TextInput)`
    max-width: 9.5em;
    background: ${(props) => transparency(props.theme.colors.boyBlue, 0.4)};
    &:focus {
        border: 2px solid ${(props) => props.theme.colors.boyBlue};
    }
    ::placeholder {
        font-size: 0.85em;
    }
`

interface DateRangeSectionProps {
    dateRange: DateRange
    defaultDateRange: DateRange
    presetDateRangeOptions: Option[]
    inputFrom: string
    inputTo: string
    inputFromError: string
    inputToError: string
    dateFormat: string
    handlePresetRangeChange: (rangeName: string) => void
    handleDateInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleFinishDateEditing: () => void
    validateInputDate: (value: string, input: string) => void
}

export function DateRangeSection(props: DateRangeSectionProps) {
    const {
        dateRange,
        defaultDateRange,
        presetDateRangeOptions,
        inputFrom,
        inputTo,
        inputFromError,
        inputToError,
        dateFormat,
        handlePresetRangeChange,
        handleDateInputChange,
        validateInputDate,
        handleFinishDateEditing,
    } = props
    const availablePresetOptions = presetDateRangeOptions.map((o) => o.value)
    const selectedRangeName = dateRange ? dateRange.name : defaultDateRange.name
    return (
        <DateRangeControls>
            <DateRangeSelect>
                Date range
                <SingleSelect
                    options={presetDateRangeOptions}
                    selected={availablePresetOptions.indexOf(selectedRangeName) > -1 ? selectedRangeName : 'custom'}
                    noSelectOption="custom"
                    height="2.25em"
                    width="10em"
                    onSelect={handlePresetRangeChange}
                />
            </DateRangeSelect>
            <DateRangeInputs className="visible">
                <InputBox>
                    <label>
                        <DateTextInput
                            placeholder={dateFormat}
                            name="inputFrom"
                            value={inputFrom}
                            onChange={handleDateInputChange}
                            onFinishTyping={(v) => validateInputDate(v, 'inputFrom')}
                            onBlur={handleFinishDateEditing}
                            status={inputFromError ? 'error' : 'normal'}
                        />
                    </label>
                    <ValidationNotice className="validation-message-visible">{inputFromError}&nbsp;</ValidationNotice>
                </InputBox>
                <InputSeparator>&#8211;</InputSeparator>
                <InputBox>
                    <label>
                        <DateTextInput
                            placeholder={dateFormat}
                            name="inputTo"
                            value={inputTo}
                            onChange={handleDateInputChange}
                            onFinishTyping={(v) => validateInputDate(v, 'inputTo')}
                            onBlur={handleFinishDateEditing}
                            status={inputToError ? 'error' : 'normal'}
                        />
                    </label>
                    <ValidationNotice className="validation-message-visible">{inputToError}&nbsp;</ValidationNotice>
                </InputBox>
            </DateRangeInputs>
        </DateRangeControls>
    )
}
