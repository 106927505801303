import React from 'react'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'

interface DialogProps {
    onDismiss: () => void
}

export function DifferentPricingTypesDialog(props: DialogProps) {
    return (
        <ConfirmationDialog
            title="Error: different pricing types selected"
            text="In order to be able to edit selected products, they must belong to the same pricing type. You can filter your products by pricing type in ’Adjust view’ dial."
            buttonText="Close"
            destructive
            onConfirm={props.onDismiss}
        />
    )
}
