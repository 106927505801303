import * as React from 'react'
import styled from 'styled-typed'
import { Upsell } from 'admin/articleService'
import { Col } from 'uiComponents/flex'
import { ImageUploadResponse } from 'http/imagesService'
import { MessageKind } from 'uiComponents/messages'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { ToggleComponent } from 'uiComponents/studio/form/fieldComponents'
import { FormItemType, TemplateSettings } from 'emailTemplates/schema'
import { AlignedCol } from './globalSection'
import { UpsellItem } from './upsellItem'
import cloneDeep from 'lodash/cloneDeep'
import Infotip from 'uiComponents/infotip'

const AddNewLine = styled.div`
    cursor: pointer;
    color: ${(props) => props.theme.colors.boyBlue};
`
const NoUpsellsWarning = styled.div`
    color: ${(props) => props.theme.colors.tiger};
    width: 22em;
`

const dummyUpsell: Upsell = {
    id: '',
    numericId: '1',
    name: '',
    useInEmails: true,
    enabled: true,
    emailImage: '',
    preEventEmailPriority: null,
}

interface Props {
    templateSettings: TemplateSettings
    allUpsells: Upsell[]
    enabledUpsells: Upsell[]
    markAllErrors: boolean
    handleChange: (value: string | Upsell[], item: FormItemType) => void
    onUpload: (file: File) => Promise<any>
    onUploadSuccess: (response: ImageUploadResponse, type: 'logo' | 'agentPhoto') => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

export function UpsellsSection(props: Props) {
    const onAddUpsell = () => {
        const updatedUpsells = cloneDeep(props.enabledUpsells)
        updatedUpsells.push(dummyUpsell)
        props.handleChange(updatedUpsells, 'upsells')
    }

    const onSelect = (value: string, updatedItem: Upsell) => {
        const updatedUpsells = cloneDeep(props.enabledUpsells)
        const itemIndex = props.enabledUpsells.indexOf(updatedItem)
        updatedUpsells[itemIndex].id = value
        const upsellData = props.allUpsells.find((u) => u.id === value)
        updatedUpsells[itemIndex].preEventEmailPriority = upsellData?.preEventEmailPriority || null
        updatedUpsells[itemIndex].emailImage = upsellData?.emailImage || ''
        props.handleChange(updatedUpsells, 'upsells')
    }

    const onPriorityChange = (value: string, updatedItem: Upsell) => {
        const updatedUpsells = cloneDeep(props.enabledUpsells)
        const itemIndex = props.enabledUpsells.indexOf(updatedItem)
        updatedUpsells[itemIndex].preEventEmailPriority = value !== null ? Number(value) : null
        props.handleChange(updatedUpsells, 'upsells')
    }

    const onDelete = (updatedItem: Upsell) => {
        const upsellsCopy = cloneDeep(props.enabledUpsells)
        const updatedUpsells = upsellsCopy.filter((u) => u.id !== updatedItem.id)
        props.handleChange(updatedUpsells, 'upsells')
    }

    const onUploadSuccess = (response: ImageUploadResponse, updatedItem: Upsell) => {
        const updatedUpsells = cloneDeep(props.enabledUpsells)
        const itemIndex = props.enabledUpsells.indexOf(updatedItem)
        updatedUpsells[itemIndex].emailImage = response.url
        props.handleChange(updatedUpsells, 'upsells')
    }

    type ToggleType = 'toggle'
    const upsellsEnabledField = {
        name: 'upsells-enabled',
        type: 'toggle' as ToggleType,
        label: 'Upsells enabled',
        path: '',
        defaultValue: true,
    }

    return (
        <>
            <FormItem>
                <Col span={9}>
                    <label htmlFor={upsellsEnabledField.name}>
                        {upsellsEnabledField.label}
                        <Infotip pointer="left" maxWidth="17em" smallBoxText>
                            Descriptions of the upsells is going to be taken from the product descriptions.
                        </Infotip>
                    </label>
                </Col>
                <AlignedCol span={3} className="align-right">
                    <ToggleComponent
                        field={upsellsEnabledField}
                        handleChange={(v: string) => props.handleChange(v, 'upsellsEnabled')}
                        value={props.templateSettings.upsellsEnabled}
                    />
                </AlignedCol>
            </FormItem>
            {props.templateSettings.upsellsEnabled && (
                <>
                    {props.enabledUpsells.map((upsell) => (
                        <UpsellItem
                            key={upsell.id}
                            upsell={upsell}
                            allUpsells={props.allUpsells}
                            markAllErrors={props.markAllErrors}
                            enabledUpsells={props.enabledUpsells.map((u) => u.id)}
                            onSelect={(v) => onSelect(v, upsell)}
                            onPriorityChange={(v) => onPriorityChange(v, upsell)}
                            onDelete={() => onDelete(upsell)}
                            onUpload={props.onUpload}
                            onUploadSuccess={(v) => onUploadSuccess(v, upsell)}
                            replaceTopMessages={props.replaceTopMessages}
                            removeAllMessages={props.removeAllMessages}
                        />
                    ))}
                    {!props.enabledUpsells.filter((u) => !u.id).length &&
                        props.allUpsells.length > props.enabledUpsells.length && (
                            <FormItem>
                                <Col span={12}>
                                    <AddNewLine onClick={onAddUpsell}>+ Add an upsell</AddNewLine>
                                </Col>
                            </FormItem>
                        )}
                    {!props.allUpsells.length && (
                        <FormItem>
                            <Col span={12}>
                                <NoUpsellsWarning>
                                    There are no enabled upsells, that could be included in the email.
                                </NoUpsellsWarning>
                            </Col>
                        </FormItem>
                    )}
                </>
            )}
        </>
    )
}
