import React from 'react'
import styled from 'styled-components'
import { SingleSelect, SingleSelectOption } from 'uiComponents/input'
import { IOption } from '../../types'

const Container = styled.div`
    margin-bottom: 2em;
`

const Information = styled.div`
    margin-top: 0.5em;
    font-size: 0.8em;
    color: ${(props) => props.theme.colors.textLight};
`

const Label = styled.div`
    font-size: 0.875rem;
    margin: 0.5em 0;
`

interface LocationFilterProps {
    value: IOption | null
    locations: SingleSelectOption[]
    onSelect: (value: string) => void
}

const LocationFilter = (props: LocationFilterProps) => {
    return (
        <Container>
            <Label>Select Locations</Label>
            <SingleSelect
                options={props.locations}
                noSelectOption="Select venue location"
                onSelect={props.onSelect}
                selected={props.value?.value as string | ''}
            />
            <Information>Choosing a new location will reset all product filters</Information>
        </Container>
    )
}

export default LocationFilter
