import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { ConfigurationVersions, ProductName } from 'engageTools/studio/schema'
import { BaseRouteParams } from 'hocs'
import { ModalDialog, ModalDialogTitle } from 'uiComponents/popups/modal'
import { Body } from 'uiComponents/typography'
import { useGetConfigurationsVersionsQuery } from 'engageTools/reduxApi'
import DuplicatesTable from './duplicateTable'
import { removeAllTagMessages } from 'uiComponents/messages/actions'
import { ROUTE_PATHS } from 'engageTools/routePaths'

interface DuplicatesModalProps {
    type: ProductName
    id: string
}

const DuplicatesModalRoute: React.FC<DuplicatesModalProps> = ({ children, type, id }) => {
    const routeParams = useParams<BaseRouteParams>()
    const [selectedTool, setSelectedTool] = useState<ConfigurationVersions>()
    const { data } = useGetConfigurationsVersionsQuery({
        type,
        accountSlug: routeParams.accountSlug,
        query: '?',
    })
    const dispatch = useDispatch()
    const history = useHistory()
    const duplicates = useMemo(() => {
        const filteredData = (data || []).filter(
            (tl) => tl?.id && selectedTool?.duplicates?.map((dp) => dp.uuid).includes(tl.id),
        )

        return [selectedTool || [], ...filteredData]
    }, [data, selectedTool])

    useEffect(() => {
        if (id) {
            const tool = data?.find((_tool) => _tool.id === id)

            if (tool) {
                setSelectedTool(tool)
            }
        }
    }, [data, id])

    useEffect(() => {
        if (selectedTool) {
            const newTool = data?.find((tool) => tool.id === selectedTool.id)

            if (newTool) {
                setSelectedTool(newTool)
            }
        }
    }, [data, selectedTool])

    const close = () => {
        const path = generatePath('/account/:accountSlug/engage/tools/home/:section', routeParams as any)

        if (path) {
            history.push(path)
            setSelectedTool(undefined)
            dispatch(removeAllTagMessages('EngageTools'))
        }
    }

    return (
        <ModalDialog style={{ width: '46.5rem', minWidth: '46.5rem', maxHeight: '650px' }} onDismiss={close}>
            <ModalDialogTitle>Duplicates found</ModalDialogTitle>
            <Body size={1} style={{ paddingBottom: '.5rem' }}>
                Duplicates were found while attempting to publish, please choose the correct one you want to publish or
                go back and change settings.
            </Body>
            <DuplicatesTable
                type={type}
                data={duplicates as ConfigurationVersions[]}
                onDelete={(tool) => {
                    if (tool) {
                        const path = generatePath(ROUTE_PATHS.deleteModal, {
                            ...routeParams,
                            id: tool.id as string,
                        } as any)
                        history.push(path)
                    }
                }}
            />
        </ModalDialog>
    )
}

export default DuplicatesModalRoute
