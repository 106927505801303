import { Locale } from 'admin/articleService'
import * as React from 'react'
import { Col } from 'uiComponents/flex'
import { SingleSelectOption } from 'uiComponents/input'
import { MessageKind } from 'uiComponents/messages'
import { InputComponent } from 'uiComponents/studio/form/fieldComponents'
import { PickerField, SelectField, TextField, UploadField } from 'uiComponents/studio/form/fieldComponentSchema'
import { FormLabel } from 'uiComponents/studio/form/formComponents'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { PlacementContent, UseFormInput } from '../schema'
import { renderImageComponent, renderInputComponent, renderPickerComponent, renderSelectComponent } from './components'

interface Props {
    accountLocales: Locale[]
    defaultLanguage: UseFormInput
    name: UseFormInput
    placementSize: UseFormInput
    backgroundColor: UseFormInput
    customBackgroundColor: UseFormInput
    image: UseFormInput
    buttonAction: UseFormInput
    activeLanguage: UseFormInput
    activeTranslation: PlacementContent
    onTranslationUpdate: (key: string, value: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

const TypeSection = ({
    accountLocales,
    name,
    defaultLanguage,
    placementSize,
    backgroundColor,
    customBackgroundColor,
    image,
    activeLanguage,
    activeTranslation,
    onTranslationUpdate,
    replaceMessages,
    removeAllMessages,
}: Props) => {
    const [languageOptions, setlanguageOptions] = React.useState<SingleSelectOption[]>([])
    React.useEffect(() => {
        const formattedOptions = accountLocales.map((locale) => ({
            value: locale.code,
            name: locale.name,
        }))
        setlanguageOptions(formattedOptions)
    }, [accountLocales])

    const defaultLanguageField: SelectField = {
        name: 'default-language',
        type: 'select',
        label: 'Default language',
        path: '',
        defaultValue: 'en',
        availableOptions: languageOptions,
        span: 6,
    }

    const translationField: SelectField = {
        name: 'translation',
        type: 'select',
        label: 'Select language',
        path: '',
        defaultValue: 'en',
        availableOptions: languageOptions,
        span: 6,
    }

    const nameField: TextField = {
        name: 'name',
        type: 'text',
        label: 'Name of the placement',
        path: '',
        defaultValue: 'placement name',
        placeholder: 'Type name',
        maxLength: 100,
    }

    const sizeField: SelectField = {
        name: 'size',
        type: 'select',
        label: 'Type of placement',
        path: '',
        defaultValue: 'WIDE',
        availableOptions: [
            { name: 'Wide', value: 'WIDE' },
            { name: 'Small', value: 'SMALL' },
        ],
        span: 6,
    }

    const backgroundColorField: SelectField = {
        name: 'background-color',
        type: 'select',
        label: 'Background color',
        path: '',
        defaultValue: 'dark',
        availableOptions: [
            { name: 'Dark', value: 'DARK' },
            { name: 'Light', value: 'LIGHT' },
            { name: 'Brand', value: 'BRAND' },
            { name: 'Custom', value: 'CUSTOM' },
        ],
        span: 6,
    }

    const customBackgroundColorField: PickerField = {
        name: 'custom-background-color',
        type: 'picker',
        label: 'Background color',
        path: '',
        defaultValue: '',
    }

    const titleField: TextField = {
        name: 'title',
        type: 'text',
        label: 'Title',
        path: '',
        defaultValue: 'placement title',
        placeholder: 'Type title',
    }

    const subtitleField: TextField = {
        name: 'subtitle',
        type: 'text',
        label: 'Subtitle (optional)',
        path: '',
        defaultValue: 'placement subtitle',
        placeholder: 'Type subtitle',
    }

    const imageUploadField: UploadField = {
        name: 'image-upload',
        type: 'upload',
        label: 'Upload image',
        path: '',
        defaultValue: true,
    }

    const buttonActionField: TextField = {
        name: 'button-action',
        type: 'text',
        label: 'Button text (optional)',
        path: '',
        defaultValue: '',
        placeholder: 'Type button text',
    }

    return (
        <>
            <SectionWrapper title="Create/edit placement">
                {renderSelectComponent(defaultLanguageField, defaultLanguage)}
                {renderInputComponent(nameField, name, 'To identify the placement. This name is not shown to users.')}
                {renderSelectComponent(sizeField, placementSize)}
                {renderSelectComponent(backgroundColorField, backgroundColor)}
                {backgroundColor.value === 'CUSTOM' &&
                    renderPickerComponent(customBackgroundColorField, customBackgroundColor)}
                {placementSize.value === 'WIDE' &&
                    renderImageComponent(imageUploadField, image, replaceMessages, removeAllMessages)}
            </SectionWrapper>
            <SectionWrapper title="Add Languages">
                {renderSelectComponent(translationField, activeLanguage)}
                <FormItem>
                    <Col span={12}>
                        <FormLabel htmlFor={titleField.name}>{titleField.label}</FormLabel>
                    </Col>
                    <Col span={12}>
                        <InputComponent
                            field={titleField}
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                onTranslationUpdate('title', e.target.value)
                            }
                            value={activeTranslation.title || ''}
                            maxLength={100}
                            style={{ fontWeight: 'normal' }}
                            locale={activeLanguage.value}
                        />
                    </Col>
                </FormItem>
                <FormItem>
                    <Col span={12}>
                        <FormLabel htmlFor={subtitleField.name}>{subtitleField.label}</FormLabel>
                    </Col>
                    <Col span={12}>
                        <InputComponent
                            field={subtitleField}
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                onTranslationUpdate('subtitle', e.target.value)
                            }
                            value={activeTranslation.subtitle || ''}
                            maxLength={100}
                            style={{ fontWeight: 'normal' }}
                            locale={activeLanguage.value}
                        />
                    </Col>
                </FormItem>
                <FormItem>
                    <Col span={12}>
                        <FormLabel htmlFor={buttonActionField.name}>{buttonActionField.label}</FormLabel>
                    </Col>
                    <Col span={12}>
                        <InputComponent
                            field={buttonActionField}
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                onTranslationUpdate('buttonCaption', e.target.value)
                            }
                            value={activeTranslation.buttonCaption || ''}
                            maxLength={100}
                            style={{ fontWeight: 'normal' }}
                            locale={activeLanguage.value}
                        />
                    </Col>
                </FormItem>
            </SectionWrapper>
        </>
    )
}

export default TypeSection
