import * as React from 'react'
import styled from 'styled-typed'
import UserDisplay from './userDisplay'
import { History } from 'history'
import MobileHeader from './mobileHeader'

interface HeaderProps {
    logout: () => void
    accountSlug: string
    logoutOnly?: boolean
    history: History
}

const HeaderContainer = styled.div`
    flex: 0 0 90px;
    padding: 0 30px;
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const HeaderWrapper = styled.div`
    > #cp-mobile-header {
        display: none;
    }

    @media (max-width: ${(props) => props.theme.breakPoints.md}) {
        z-index: 101;
        top: 0;
        right: 0;
        position: sticky;

        > #cp-mobile-header {
            display: flex;
        }

        > #cp-header {
            display: none;
        }
    }
`

function Header({ logout, accountSlug, logoutOnly, history }: HeaderProps) {
    return (
        <HeaderWrapper>
            <MobileHeader />
            <HeaderContainer id="cp-header">
                <div />
                <UserDisplay
                    logout={logout}
                    accountSlug={accountSlug}
                    logoutOnly={logoutOnly}
                    history={history}
                    disregardLocationState
                />
            </HeaderContainer>
        </HeaderWrapper>
    )
}

export default Header
