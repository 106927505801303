import styled from 'styled-typed'

export const ToggleBox = styled.div`
    width: 3em;
    height: 2em;
    border-radius: 0.4em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    word-break: break-word;
    white-space: break-spaces;
    background-color: ${(props) => props.theme.colors.tableRow};
    color: ${(props) => props.theme.colors.textLight};
    &.active {
        background-color: ${(props) => props.theme.colors.textLight};
        color: ${(props) => props.theme.colors.textDark};
        cursor: pointer;
        &:hover {
            background-color: ${(props) => props.theme.colors.textDark};
            color: ${(props) => props.theme.colors.white};
            font-weight: lighter;
        }
    }
`
