const EMAIL_PATTERN =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const isValidEmail = (email: string): boolean => {
    return EMAIL_PATTERN.test(email)
}

export function isValidUrl(url: string): boolean {
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/[\]@!$&'()*+,;=#]+$/
    return urlRegex.test(url.toLowerCase())
}

export function isValidUrlIncludingHttp(url: string): boolean {
    const urlRegex = /^http(s)?:\/\/[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/
    return urlRegex.test(url.toLowerCase())
}

export function isValidContactNumber(contactNumber: string): boolean {
    const numberRegex = /^[+]?[\d()\- ]+$/
    return numberRegex.test(contactNumber)
}

export function textContainsHTMLTag(text: string): boolean {
    const htmlTagRegex = /(<\w*)((\s*\w*\/>)|(.*<\/\w*>))/gm
    return htmlTagRegex.test(text)
}

export function textContainsSpecChars(text: string): boolean {
    const regex = /[^- _A-Za-z0-9]/g
    return regex.test(text)
}
