import React, { useState, useEffect } from 'react'
import { match as RouteMatch } from 'react-router-dom'
import { TableLoader } from 'uiComponents/loaders'
import { usePrevious, useAccountChange } from 'reactUtils'
import { ArticleService, ArticleListItem, ProductList } from 'admin/articleService'
import { PricingService, CreatePricingSettingsError } from 'products/pricing/pricingService'
import { handleFailure } from 'products/pricing/common'
import { History } from 'history'
import { delay } from 'utils'
import { withNavigation } from 'hocs'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { Navigation } from 'navigation'
import { MessageKind, MessageButton } from 'uiComponents/messages'
import { ProductListWithPath, iterateProductLists } from 'products/crud/common'
import NestedTable from './nestedTable'
import { PricingType } from 'products/articleConfigurationService'
import isEqual from 'lodash/isEqual'
import { getDuplicationMessage } from './utils'

interface ProductListPageParams {
    accountSlug: string
}

interface ProductListPageProps {
    history: History
    articleService: ArticleService
    pricingService: PricingService
    match: RouteMatch<ProductListPageParams>
    navigation: Navigation
    setActiveSection: (section: string, header?: string) => void
    hasPermission: (permission: string, accountSlug: string) => boolean
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    addMessage: (id: string, status: MessageKind, text: string, actionButton?: MessageButton) => void
    hasFeature: (feature: string, accountSlug: string) => boolean
    removeAllMessages: () => void
    hideMessage: (id: string) => void
    flattenedCategories: ProductListWithPath[] | null
    accountCategories: ProductList[]
    showBulkEditButtons: (v: boolean) => void
    bulkEditPricing: boolean
    bulkEditValidity: boolean
    cancelBulkEditPricing: () => void
    cancelBulkEditValidity: () => void
}

function FlatProductListPage(props: ProductListPageProps) {
    const searchString = props.navigation.query().q
    const pricingTypes = props.navigation.query().pricingTypes

    const [articles, setArticles] = useState<ArticleListItem[] | null>(null)
    const [allArticles, setAllArticles] = useState<ArticleListItem[] | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false)
    const [articleToBeDeleted, setArticleToBeDeleted] = useState<string | null>(null)
    const [backgroundLoading, setBackgroundLoading] = useState<boolean>(false)
    const [accountCategories, setAccountCategories] = useState<ProductList[]>([])
    const [flattenedCategories, setFlattenedCategories] = useState<ProductListWithPath[]>([])
    const [showDuplicationConfirmation, setShowDuplicationConfirmation] = useState<boolean>(false)
    const [productToBeDuplicated, setProductToBeDuplicated] = useState<{
        id: string
        name: string
    } | null>(null)
    const [duplicating, setDuplicating] = useState<boolean>(false)

    useEffect(() => {
        props.setActiveSection('productsList', 'Products Overview')
        getFilteredArticles()
        getAccountCategories()
    }, [])

    useAccountChange(props.match.params.accountSlug, () => getArticlesList())

    const prevPricingTypes = usePrevious(pricingTypes)
    const prevSearch = usePrevious(searchString)
    useEffect(() => {
        const search = props.navigation.query().q
        if (!isEqual(prevPricingTypes, pricingTypes) || prevSearch !== search) {
            getFilteredArticles()
        }
    }, [props.navigation.query])

    const getFilteredArticles = async () => {
        let filtered = allArticles || (await getArticlesList())

        if (!searchString && !pricingTypes) {
            setArticles(filtered)
            return
        }

        setLoading(true)

        if (searchString) {
            filtered = [...filtered].filter(
                (a) =>
                    a.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
                    a.id.indexOf(searchString) > -1 ||
                    a.numericId.toString().indexOf(searchString) > -1,
            )
        }
        if (pricingTypes) {
            filtered = [...filtered].filter((a) => pricingTypes.indexOf(a.pricingType) > -1)
        }

        setArticles(filtered)

        setLoading(false)
    }

    const getArticlesList = async () => {
        setLoading(true)
        try {
            const query = props.navigation.query()
            const selectedPricingTypes = query.pricingTypes
                ? (decodeURIComponent(query.pricingTypes).split(',') as PricingType[])
                : null
            const response = await props.articleService.listFlatArticles(
                props.match.params.accountSlug,
                selectedPricingTypes,
            )
            setArticles(response)
            if (!selectedPricingTypes) {
                setAllArticles(response)
            }
            setLoading(false)
            return response
        } catch {
            props.replaceTopMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
            return []
        }
    }

    const setAllAccountCategories = (cats: ProductList[]) => {
        const flattened = iterateProductLists(cats, '', [])
        setAccountCategories(cats)
        setFlattenedCategories(flattened)
        setBackgroundLoading(false)
    }

    const getAccountCategories = async () => {
        if (location.pathname.includes('flat')) {
            setBackgroundLoading(true)
            try {
                const cats = await props.articleService.getProductLists(props.match.params.accountSlug)
                setAllAccountCategories(cats)
            } catch {
                props.replaceTopMessages('server_error', 'error', 'Oops! Error occured in fetching categories.')
                setBackgroundLoading(false)
            }
        }
    }

    const confirmArticleDeletion = (id: string) => {
        setShowDeleteConfirmation(true)
        setArticleToBeDeleted(id)
    }

    const deleteArticle = async (id: string) => {
        setLoading(true)
        setShowDeleteConfirmation(false)
        try {
            await props.articleService.archiveArticle(props.match.params.accountSlug, id)
            props.replaceTopMessages('server_success', 'success', 'The product was successfully deleted.')
            getArticlesList()
        } catch {
            props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Could not delete the product. Please try again later.',
            )
        }
        setLoading(false)
    }

    const duplicateProduct = (id: string, name: string) => {
        setShowDuplicationConfirmation(true)
        setProductToBeDuplicated({ id, name })
    }

    const confirmProductDuplication = async () => {
        setDuplicating(true)
        try {
            const accountSlug = props.match.params.accountSlug
            const id = productToBeDuplicated?.id || ''
            const copyId = await props.articleService.duplicateArticle(accountSlug, id)
            const message = getDuplicationMessage(productToBeDuplicated?.name || '')
            props.navigation.pushWithMessages(
                [message],
                `/account/${accountSlug}/products/crud/${copyId}${location.search}`,
                true,
            )
        } catch {
            setDuplicating(false)
            props.replaceTopMessages(
                'unknown_error',
                'error',
                'Oops! We coudl not duplicate the product. Please try again later.',
            )
        }
    }

    const updateArticlePriority = async (id: string, priority: number) => {
        if (!priority) {
            return
        }
        try {
            await props.articleService.updateArticlePriority(props.match.params.accountSlug, id, priority)
            props.addMessage('server_success', 'success', 'The priority was successfully updated.', null)
            if (!!props.hideMessage) {
                await delay(2000)
                props.hideMessage('server_success')
            }
        } catch {
            getArticlesList()
            props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Could not update the priority. Please try again later.',
            )
        }
    }

    const updateArticleStatus = async (id: string, enabled: boolean) => {
        try {
            await props.articleService.updateArticleStatus(props.match.params.accountSlug, id, enabled)
            props.replaceTopMessages('server_success', 'success', 'Product was successfully updated.')
        } catch {
            getArticlesList()
            props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Could not update the status. Please try again later.',
            )
        }
    }

    const updateArticleOriginalPrice = async (
        id: string,
        price: string,
        min: number | null,
        avg: number | null,
        gate: number | null,
        stepSize: string | null,
        charmPricing: boolean | null,
        pricingType: PricingType,
    ) => {
        const responseData = await props.pricingService.sendPricingSettings({
            pricingType,
            id,
            account: props.match.params.accountSlug,
            originalPrice: +price,
            minAcceptedPrice: min,
            avgTargetPrice: avg,
            gatePrice: gate,
            stepSize,
            charmPricing,
            version: null,
        })
        responseData
            .ifFailure((err: CreatePricingSettingsError) => {
                if (props.replaceTopMessages) {
                    handleFailure(err, props.replaceTopMessages)
                }
            })
            .ifSuccess(async () => {
                if (props.replaceTopMessages && props.hideMessage) {
                    getArticlesList()
                    props.replaceTopMessages(
                        'pricing_settings_save_success',
                        'success',
                        'Pricing settings were successfully saved.',
                    )
                    await delay(5000)
                    props.hideMessage('pricing_settings_save_success')
                }
            })
    }

    return (
        <>
            {showDeleteConfirmation && (
                <ConfirmationDialog
                    title="Delete the product?"
                    text="Are you sure you want to delete this product?"
                    buttonText="Delete"
                    onCancel={() => setShowDeleteConfirmation(false)}
                    onConfirm={() => (articleToBeDeleted ? deleteArticle(articleToBeDeleted) : {})}
                />
            )}
            {showDuplicationConfirmation && (
                <ConfirmationDialog
                    title="Duplicate the product?"
                    text={`Are you sure you want to duplicate the product '${productToBeDuplicated?.name || ''}'?`}
                    buttonText="Duplicate"
                    loading={duplicating}
                    onCancel={() => setShowDuplicationConfirmation(false)}
                    onConfirm={confirmProductDuplication}
                />
            )}
            {loading && <TableLoader />}
            {!loading && !!articles && (
                <NestedTable
                    elements={articles}
                    accountSlug={props.match.params.accountSlug}
                    pricingService={props.pricingService}
                    hasPermission={props.hasPermission}
                    flat
                    deleteArticle={confirmArticleDeletion}
                    duplicateProduct={duplicateProduct}
                    deleteProductList={() => {}}
                    updateArticlePriority={updateArticlePriority}
                    updateArticleStatus={updateArticleStatus}
                    hasFeature={props.hasFeature}
                    replaceTopMessages={props.replaceTopMessages}
                    hideMessage={props.hideMessage}
                    removeAllMessages={props.removeAllMessages}
                    flattenedCategories={flattenedCategories}
                    accountCategories={accountCategories}
                    setAccountCategories={setAllAccountCategories}
                    backgroundLoading={backgroundLoading}
                    origin="?from=flat"
                    showBulkEditButtons={props.showBulkEditButtons}
                    bulkEditPricing={props.bulkEditPricing}
                    bulkEditValidity={props.bulkEditValidity}
                    cancelBulkEditPricing={props.cancelBulkEditPricing}
                    cancelBulkEditValidity={props.cancelBulkEditValidity}
                    updateArticleOriginalPrice={updateArticleOriginalPrice}
                    refreshArticles={getArticlesList}
                    navigation={props.navigation}
                />
            )}
        </>
    )
}

export default withNavigation(FlatProductListPage)
