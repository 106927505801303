import * as React from 'react'
import styled from 'styled-typed'
import { Card } from 'uiComponents/cards'
import { PlainLoader } from 'uiComponents/loaders'
import { Row, Col } from 'uiComponents/flex'
import { Overlay } from 'uiComponents/popups/modal'
import { productTitles } from 'engageTools/studio/shared'
import { PreviewSize } from 'uiComponents/studio/preview/components'
import { ButtonPosition, BarPosition, ProductName } from 'engageTools/studio/schema'

const tBDesktopPositioning = {
    'bottom-left': 'margin: unset; left: 4em; bottom: 1em;',
    'bottom-right': 'margin: unset; right: 4em; left: unset; bottom: 1em;',
    'top-left': 'margin: unset; left: 4em; top: 2em;',
    'top-right': 'margin: unset; right: 4em; left: unset; top: 2em;',
}

const tBMobilePositioning = {
    'bottom-left': 'bottom: 1.5em;',
    'bottom-right': 'bottom: 1.5em;',
    'top-left': 'top: 2em;',
    'top-right': 'top: 2em;',
}

const PreviewCard = styled(Card)<PreviewProps>`
    z-index: 151;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    width: ${(p) => (p.size === 'desktop' ? '25em' : '22em')};
    padding: ${(p) => (p.size === 'desktop' ? '.3em 1em' : '.3em')};
    ${(p) => (p.size !== 'desktop' ? 'font-size: .8em;' : null)}
    ${(p) => {
        switch (p.product) {
            case 'modal_window':
                return 'top: 50%;'
            case 'trigger_button':
                return p.size === 'desktop'
                    ? !!p.position
                        ? tBDesktopPositioning[p.position]
                        : 'bottom: 1em;'
                    : !!p.position
                    ? tBMobilePositioning[p.position]
                    : 'bottom: 1.5em;'
            case 'wonderbar':
                return !!p.position && p.position === 'bottom' ? 'bottom: 1em;' : 'top: 2em;'
            default:
                return 'top: 50%;'
        }
    }}
`

const LoaderText = styled.div``

interface PreviewProps {
    product: ProductName
    size: PreviewSize
    position: ButtonPosition | BarPosition | null
}

export function PreviewLoaderIndicator(props: PreviewProps) {
    return (
        <>
            <Overlay style={{ position: 'absolute' }} />
            <PreviewCard product={props.product} size={props.size} position={props.position}>
                <Row>
                    <Col span={2}>
                        <PlainLoader style={{ width: '2.2em' }} />
                    </Col>
                    <Col span={10} style={{ display: 'flex', alignItems: 'center' }}>
                        <LoaderText>{productTitles[props.product]} preview is loading...</LoaderText>
                    </Col>
                </Row>
            </PreviewCard>
        </>
    )
}
