import React, { forwardRef } from 'react'
import { format } from 'date-fns'
import { TimelineItem } from '../types'
import './capacityItemTooltip.scss'

type CapacityItemTooltipProps = {
    item: TimelineItem
    strategy: 'absolute' | 'fixed'
    x: number | null
    y: number | null
}

const CapacityItemTooltip = forwardRef<HTMLDivElement, CapacityItemTooltipProps>(({ strategy, item, x, y }, ref) => {
    return (
        <div className="capacity-item-tooltip" ref={ref} style={{ top: y ?? 0, left: x ?? 0, position: strategy }}>
            <strong>Reserved: {item.capacity - item.available}</strong>
            <span>Available: {item.available}</span>
            <span>Start: {format(item.start, 'HH:mm')}</span>
            <span>End: {format(item.end, 'HH:mm')}</span>
        </div>
    )
})

CapacityItemTooltip.displayName = 'Tooltip'

export default CapacityItemTooltip
