import { DateRange } from 'dateRanges'
import { format, addDays, compareAsc } from 'date-fns'
import { indexBy } from 'utils'
import { DataPoint, TimeSeries } from 'reports/schema'
import { Series } from 'uiComponents/charts/timeseriesBarChart'
import { parseISODate } from 'utils/dates'

export interface XAxisData {
    category: Date
}

function generateXAxis(from: Date, to: Date, next: (d: Date) => Date): XAxisData[] {
    const result = []
    let current = from
    while (compareAsc(current, to) < 0) {
        result.push({ category: current })
        current = next(current)
    }
    return result
}

export class TimeseriesBarChartSource {
    constructor(
        public readonly granularity: 'day',
        private readonly xAxisData: XAxisData[],
        private readonly dateFrom: Date,
        private readonly dateTo: Date,
        private series: Series[] = [],
    ) {}

    formatLineSeries(lineSeries: TimeSeries[]): void {
        if (lineSeries) {
            const mappedLineSeries: Series[] = lineSeries.map((s, i) => ({
                name: s.label || '',
                type: 'line',
                data: this.setSeriesData(s.points),
                symbol: 'circle',
            }))
            this.series.push(...mappedLineSeries)
        }
    }

    clearSeries(): void {
        this.series = []
    }

    setSeriesData = (datapoints: DataPoint[]): string[] => {
        if (datapoints.length === 0) {
            return []
        }
        const values = []
        let indexedPoints = {}
        indexedPoints = indexBy(datapoints, (x) => parseISODate(x.timestamp).getTime().toString())
        for (let i = 0; i < this.xAxisData.length; i++) {
            const existing = indexedPoints[this.xAxisData[i].category.getTime().toString()]
            if (existing) {
                values.push(this.toPercent(existing.value))
            } else {
                values.push('0')
            }
        }
        return values
    }

    toPercent = (value: any): string => {
        if (typeof value === 'string') {
            return (parseInt(value, 10) * 100).toString()
        } else {
            return (value * 100).toFixed(2)
        }
    }

    formatXAxisData(): string[] {
        return this.xAxisData.map((x) => format(x.category, 'do') + '\n' + format(x.category, 'eee'))
    }

    from(): Date {
        return this.dateFrom
    }

    to(): Date {
        return this.dateTo
    }

    props() {
        return {
            xAxisData: this.formatXAxisData(),
            xAxisDataRaw: this.xAxisData,
            legendData: this.series.map((s) => s.name),
            series: this.series,
        }
    }
}

export function createTimeseriesBarChart(range: DateRange): TimeseriesBarChartSource {
    return new TimeseriesBarChartSource(
        'day',
        generateXAxis(range.from, range.to, (d) => addDays(d, 1)),
        range.from,
        range.to,
    )
}
