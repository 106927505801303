import React, { useState } from 'react'
import styled from 'styled-typed'
import { Switch, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { State } from 'store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserNinja } from '@fortawesome/pro-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome'
import { endImpersonation } from 'auth/actions.async'
import { PlainLoader } from 'uiComponents/loaders'
import { transparency } from 'utils/css'

interface BannerStyleProps {
    opac: boolean
    error: boolean
}

const Banner = styled.div<BannerStyleProps>`
    font-family: ${(props) => props.theme.fonts.primary};
    font-size: 15px;
    font-weight: 500;
    background: ${(props) =>
        props.opac
            ? transparency(props.theme.colors.boyBlue, 0.3)
            : props.error
            ? props.theme.colors.status.error
            : props.theme.colors.boyBlue};
    color: ${(props) => props.theme.colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0s 0.3s;

    span {
        text-decoration: underline;
        cursor: pointer;
        margin-left: 0.5em;
    }

    &.visible {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s;
    }
`
const Icon = styled(FontAwesomeIcon)`
    margin-right: 0.5em;
`

interface ImpersonationBannerProps {
    children: React.ReactNode
    impersonating: boolean
    userEmail: string
    endImpersonation: typeof endImpersonation
}

function ImpersonationBanner(props: ImpersonationBannerProps) {
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const onEndImpersonation = async () => {
        try {
            setLoading(true)
            await props.endImpersonation()
        } catch {
            setErrorMessage('Oops! Please log out and log back in again.')
        } finally {
            setLoading(false)
        }
    }

    return (
        <Route>
            <Banner
                className={props.impersonating ? 'visible' : ''}
                opac={loading || !props.impersonating}
                error={!!errorMessage}
            >
                {loading && <PlainLoader style={{ position: 'absolute', width: '3em' }} />}
                <Icon icon={faUserNinja as IconProp} />
                {errorMessage ? (
                    errorMessage
                ) : (
                    <>
                        You are impersonating {props.userEmail}.<span onClick={onEndImpersonation}>Exit view</span>
                    </>
                )}
            </Banner>
            <Switch>{props.children}</Switch>
        </Route>
    )
}

function mapStateToProps(state: State) {
    return {
        impersonating: !!state.auth.user?.impersonatedBy,
        userEmail: state.auth.user?.username || '',
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            endImpersonation,
        },
        dispatch,
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ImpersonationBanner)
