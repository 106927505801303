import * as React from 'react'
import styled from 'styled-typed'
import conviousLogo from 'images/convious-logo-pink.svg'
import Svg from 'svg'
import { OnClickMenuItem } from 'uiComponents/menus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-solid-svg-icons'
import { AccountSelector } from 'sidebar/sidebarAccountSelector'
import { IconProp } from '@fortawesome/fontawesome'
import { connect } from 'react-redux'
import { toggleSideBar } from 'sidebar/reducer'
import { State } from 'store'

interface HeaderProps {
    toggle: () => void
    open: boolean
}

const HeaderContainer = styled.div`
    flex: 0 0 90px;
    padding: 0 16px;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    justify-content: space-between;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    align-self: flex-start;
`

const LogoWrapper = styled.div`
    width: 130px;
    position: static;

    @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
        width: 90px;
    }
`

const LeftSide = styled.div`
    display: flex;
    align-items: center;
`

const RightSide = styled.div`
    display: flex;
    justify-content: flex-end;
`

interface MenuBurgerProps {
    open: boolean
}

const MenuBurger = styled.span<MenuBurgerProps>`
    color: ${(props) => (props.open ? props.theme.colors.boyBlue : props.theme.colors.aluminium)};
`

function MobileHeader({ toggle, open }: HeaderProps) {
    return (
        <HeaderContainer id="cp-mobile-header">
            <LeftSide>
                <OnClickMenuItem style={{ marginRight: 8 }} onClick={toggle}>
                    <MenuBurger open={open}>
                        <FontAwesomeIcon icon={faBars as IconProp} />
                    </MenuBurger>
                </OnClickMenuItem>
                <LogoWrapper>
                    <a href="/">
                        <Svg src={conviousLogo} />
                    </a>
                </LogoWrapper>
            </LeftSide>
            <RightSide>
                <AccountSelector selfSignUp={null} />
            </RightSide>
        </HeaderContainer>
    )
}

export default connect(
    (state: State) => ({
        open: state.sidebar.open,
    }),
    (dispatch) => {
        return {
            toggle: () => dispatch(toggleSideBar()),
        }
    },
)(MobileHeader)
