import * as React from 'react'
import { MultiSelectField } from 'uiComponents/studio/form/fieldComponentSchema'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { UseFormInput } from '../schema'
import { CheckboxWrapper, renderMultiSelectComponent } from './components'
import { Checkbox } from 'uiComponents/input'
import { Audience } from 'crm/schema'

interface Props {
    audience: UseFormInput
    marketingConsent: UseFormInput
    hasPlacementAudienceFeature: boolean
    audiences: Audience[]
}

const TypeSection = ({ audience, marketingConsent, hasPlacementAudienceFeature, audiences }: Props) => {
    const toggleMarketingConsent = () => marketingConsent.onChange(marketingConsent.value ? '' : '1')

    const audienceField: MultiSelectField = {
        name: 'audience',
        type: 'multiSelect',
        label: 'Audience',
        path: 'audience',
        defaultValue: 'productConfig.audiences',
        availableOptions: audiences.map((audienceItem) => {
            return {
                value: audienceItem.slug,
                name: audienceItem.name,
            }
        }),
        span: 6,
    }

    return (
        <>
            <SectionWrapper title="Create/edit placement">
                {hasPlacementAudienceFeature && renderMultiSelectComponent(audienceField, audience)}
                <CheckboxWrapper>
                    <Checkbox
                        checked={!!marketingConsent.value}
                        onChange={toggleMarketingConsent}
                        style={{ cursor: 'pointer', marginRight: '0.5em' }}
                    />
                    <span onClick={toggleMarketingConsent}>Marketing consent provided</span>
                </CheckboxWrapper>
            </SectionWrapper>
        </>
    )
}

export default TypeSection
