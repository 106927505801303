import React from 'react'
import { ChevronDownSolid, ChevronUpSolid } from '@convious/icons'
import './styles.scss'

const ExpandIcon = ({
    isExpanded,
    setIsExpanded,
}: {
    isExpanded: boolean
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    if (isExpanded) {
        return <ChevronUpSolid className="expand-icon" onClick={() => setIsExpanded((prev) => !prev)} />
    }

    return <ChevronDownSolid className="expand-icon" onClick={() => setIsExpanded((prev) => !prev)} />
}

export default ExpandIcon
