import React from 'react'
import { useField } from 'formik'

import { useCustomId, useGetComponentState } from '../_shared/hooks'
import SliderField, { SliderFieldProps } from './sliderField'

interface SingleSelectFieldFormikProps extends Omit<SliderFieldProps, 'status' | 'value' | 'onChange'> {}

const SliderFieldFormik: React.FC<SingleSelectFieldFormikProps> = ({ name, label, id, ...props }) => {
    const [field, { error }] = useField(name)
    const innerId = useCustomId(id)
    const state = useGetComponentState(name)

    return <SliderField status={state} id={innerId} {...props} error={error} {...field} />
}

export default SliderFieldFormik
