import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import { Headline, Body } from 'uiComponents/typography'
import { Result } from 'result'
import { History } from 'history'
import { TableLoader, ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { delay } from 'utils'
import { useAccountChange } from 'reactUtils'
import { InlineDataTable, HeaderRow, TableHeader } from 'uiComponents/table'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { TimeSlotsServiceContext } from './context'
import { TimeSlotGroup, TimeSlotsValidationError, TimeSlotGroupWithStatus, Status } from './timeSlotsService'
import { format, startOfToday } from 'date-fns'
import { TimeSlotsListRow } from 'timeSlots/listRow'
import Infotip from 'uiComponents/infotip'
import Feature from 'features/feature'
import { NavigationTabs } from 'uiComponents/navigation/tabs'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { withFeatures } from 'features'
import { NestedContainerWrapper } from 'uiComponents/table/nestedContainer'
import { transparency } from 'utils/css'

const ExpandedAreaContainer = styled.div`
    border-bottom: 1px solid ${(props) => transparency(props.theme.colors.border, 0.5)};
`
const ExpandedAreaTitle = styled(Headline)`
    width: 100%;
    line-height: 2.29em;
    color: ${(props) => props.theme.colors.textLight};
    background: ${(props) => props.theme.colors.background};
    padding: 0 3.7em;
    margin: 0;
`
const ExpandedAreaItem = styled(Body)`
    width: 100%;
    padding: 0 3.7em;
    line-height: 2.14em;
    background: ${(props) => props.theme.colors.white};

    &:hover {
        background: ${(props) => props.theme.colors.background};
    }
`

interface TimeSlotProductList {
    name: string
    id: string
}

interface TimeSlotsListPageProps {
    history: History
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<any>
    setActiveSection: (section: string, header: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    hasPermission: (permission: string, accountSlug: string) => boolean
    hasFeature: (feature: string, slug: string) => boolean
}

function TimeSlotsListPage(props: TimeSlotsListPageProps) {
    const timeSlotsService = React.useContext(TimeSlotsServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [updating, setUpdating] = React.useState<boolean>(false)
    const [timeSlots, setTimeSlots] = useState<TimeSlotGroupWithStatus[]>([])
    const [productsView, setProductsView] = useState<boolean>(props.navigation.query().timeslots_view === 'products')
    const [expandedElement, setExpandedElement] = useState<string>('')
    const [flattenedCategories, setFlattenedCategories] = useState<TimeSlotProductList[]>([])

    function generateStatus(d: TimeSlotGroup): Status {
        let status: Status = 'success'
        if (!!d.availableFrom || !!d.availableTo) {
            const today = format(startOfToday(), 'yyyy-MM-dd')
            if (!!d.availableFrom && today < d.availableFrom) {
                status = 'warn'
            } else if (!!d.availableTo && today > d.availableTo) {
                status = 'error'
            }
        }
        return status
    }

    function getCategoryNames(allPls: TimeSlotProductList[], timeSlotGroup: TimeSlotGroup) {
        if (timeSlotGroup.productsLists.length === 0) {
            return 'Default for all categories'
        }
        const names: string[] = []
        timeSlotGroup.productsLists.forEach((pl) => {
            names.push(pl.name)
        })
        return names.join(', ')
    }

    async function getTimeSlotsList() {
        // const categories = await articleService.getProductLists(props.accountSlug)
        // const allProductLists = iterateProductLists(categories, '', [])
        const forArticles =
            props.hasFeature('TimeSlotsForProduct', props.accountSlug) &&
            props.navigation.query().timeslots_view === 'products'
        const data = await timeSlotsService.listTimeSlots(props.accountSlug, forArticles)
        const timeSlotsPL = [] as TimeSlotProductList[]
        data?.forEach((d: TimeSlotGroup) => {
            d.productsLists.forEach((pl) => {
                timeSlotsPL.push(pl)
            })
        })
        const dataWithStatus: TimeSlotGroupWithStatus[] = []
        data.forEach((d: TimeSlotGroup) => {
            const status = generateStatus(d)
            const productsListNames = getCategoryNames(timeSlotsPL, d)
            dataWithStatus.push({ ...d, status, productsListNames })
        })
        setFlattenedCategories(timeSlotsPL)
        setTimeSlots(dataWithStatus)
    }

    async function getData() {
        setLoading(true)
        try {
            await getTimeSlotsList()
            setLoading(false)
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    useEffect(() => {
        getData()
        const view = props.navigation.query().timeslots_view === 'products' ? 'Products' : 'Categories'
        const pageTitle = props.hasFeature('TimeSlotsForProduct', props.accountSlug)
            ? `${view} Time Slots Configuration`
            : 'Time Slots Configuration'
        props.setActiveSection('timeSlots', pageTitle)
    }, [props.navigation.query().timeslots_view])

    useAccountChange(props.accountSlug, () => getData())

    const onPriorityUpdateConfirm = async (priority: string, id: string) => {
        try {
            setUpdating(true)
            const responseData = await timeSlotsService.updateTimeSlotGroupPriority(
                props.accountSlug,
                Number(priority),
                id,
            )
            handleResponse(responseData)
        } catch {
            setUpdating(false)
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! We could not save the priority, please try again later.',
            )
        }
    }

    function handleResponse(responseData: Result<TimeSlotGroup, TimeSlotsValidationError>) {
        responseData
            .ifFailure((err: TimeSlotsValidationError) => {
                setUpdating(false)
                props.replaceMessages(err.code, 'error', `Oops! ${err.message}`)
            })
            .ifSuccess(() => {
                getTimeSlotsList()
                setUpdating(false)
                flashSuccessMessage()
            })
    }

    async function flashSuccessMessage() {
        props.replaceMessages('success', 'success', 'The Time Slot Group priority has been saved successfully.')
        await delay(3000)
        props.removeAllMessages()
    }

    function onViewChange(view: 'categories' | 'products') {
        props.navigation.addQueryWithReplace({ timeslots_view: view })
        setProductsView(view === 'products')
    }

    async function setExpandedTimeSlot(id: string) {
        expandedElement === id ? setExpandedElement('') : setExpandedElement(id)
    }

    const tabs = [
        {
            name: 'Categories',
            onClick: () => onViewChange('categories'),
            current: props.navigation.query().timeslots_view !== 'products',
            disabled: loading || updating,
        },
        {
            name: 'Products',
            onClick: () => onViewChange('products'),
            current: props.navigation.query().timeslots_view === 'products',
            disabled: loading || updating,
        },
    ]

    const dropDownColumn = props.hasFeature('TimeSlotsForProduct', props.accountSlug) ? ['2.3em'] : []
    const columnWidths = [...dropDownColumn, '22em', null, '6em', '9.7em', '9.3em', '7em']

    return (
        <>
            <Feature name="TimeSlotsForProduct" accountSlug={props.accountSlug}>
                <div style={{ margin: '1.5em' }}>
                    <NavigationTabs tabs={tabs} />
                </div>
            </Feature>
            <InlineDataTable columnWidths={columnWidths} bordered id="time-slots-list">
                <HeaderRow>
                    <Feature name="TimeSlotsForProduct" accountSlug={props.accountSlug}>
                        <TableHeader noPadding />
                    </Feature>
                    <TableHeader>Name</TableHeader>
                    <TableHeader>{productsView ? 'Product' : 'Categories'}</TableHeader>
                    <TableHeader style={{ paddingLeft: '0.8em' }}>
                        Priority
                        <Infotip pointer="right" maxWidth="25em">
                            A number from 1 to 1000. The Time Slot Group with the highest number will have the highest
                            priority.
                        </Infotip>
                    </TableHeader>
                    <TableHeader>Weekly pattern</TableHeader>
                    <TableHeader>Date range</TableHeader>
                    <TableHeader nonInteractive />
                </HeaderRow>
                {loading && <TableLoader />}
                {updating && <ChartDataLoader />}
                {!loading && (
                    <>
                        {timeSlots.length === 0 && <NoResultsRow text="There are no time slots yet." />}
                        {!productsView && (
                            <TimeSlotsList
                                accountSlug={props.accountSlug}
                                timeSlots={timeSlots}
                                flattenedCategories={flattenedCategories}
                                expandedElement={expandedElement}
                                productsView={productsView}
                                setExpandedTimeSlot={setExpandedTimeSlot}
                                onPriorityUpdateConfirm={onPriorityUpdateConfirm}
                                hasPermission={props.hasPermission}
                                hasFeature={props.hasFeature}
                            />
                        )}
                        {productsView && (
                            <NestedContainerWrapper flat grade={0} className="expanded">
                                <TimeSlotsList
                                    accountSlug={props.accountSlug}
                                    timeSlots={timeSlots}
                                    flattenedCategories={flattenedCategories}
                                    expandedElement={expandedElement}
                                    productsView={productsView}
                                    setExpandedTimeSlot={setExpandedTimeSlot}
                                    onPriorityUpdateConfirm={onPriorityUpdateConfirm}
                                    hasPermission={props.hasPermission}
                                    hasFeature={props.hasFeature}
                                />
                            </NestedContainerWrapper>
                        )}
                    </>
                )}
            </InlineDataTable>
        </>
    )
}

export default withFeatures(withNavigation(TimeSlotsListPage))

interface ListProps {
    accountSlug: string
    timeSlots: TimeSlotGroupWithStatus[]
    flattenedCategories: TimeSlotProductList[]
    expandedElement: string
    productsView: boolean
    setExpandedTimeSlot: (id: string) => void
    onPriorityUpdateConfirm: (priority: string, id: string) => void
    hasPermission: (permission: string, accountSlug: string) => boolean
    hasFeature: (feature: string, slug: string) => boolean
}

function TimeSlotsList(props: ListProps) {
    return (
        <>
            {props.timeSlots.map((ts) => {
                const isExpanded = props.expandedElement === ts.id
                return (
                    <div key={ts.id}>
                        <TimeSlotsListRow
                            accountSlug={props.accountSlug}
                            timeslot={ts}
                            productsView={props.productsView}
                            onPriorityUpdateConfirm={props.onPriorityUpdateConfirm}
                            hasPermission={props.hasPermission}
                            productsViewFeatureOn={props.hasFeature('TimeSlotsForProduct', props.accountSlug)}
                            toggleExpanded={() => props.setExpandedTimeSlot(ts.id)}
                            isExpanded={isExpanded}
                        />
                        {!!isExpanded && (
                            <NestedContainerWrapper grade={0} className="expanded">
                                <ExpandedAreaContainer>
                                    <ExpandedAreaTitle size={6}>Categories</ExpandedAreaTitle>
                                    {ts.productsLists.map((pl) => {
                                        const plData = props.flattenedCategories.find((c) => c.id === pl.id)
                                        return plData ? (
                                            <ExpandedAreaItem size={1} weight="light" key={plData.id}>
                                                {plData.name}
                                            </ExpandedAreaItem>
                                        ) : null
                                    })}
                                </ExpandedAreaContainer>
                            </NestedContainerWrapper>
                        )}
                    </div>
                )
            })}
        </>
    )
}
