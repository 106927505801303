import * as React from 'react'
import { ReactComponent } from 'reactUtils'
import { AppServices } from 'middleware'

interface ServicesProps {
    services: typeof AppServices
}

export function withServices<TProps>(Component: ReactComponent<TProps & ServicesProps>): ReactComponent<TProps> {
    return function withServicesHoc(props: TProps) {
        return <Component services={AppServices} {...props} />
    }
}
