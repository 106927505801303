import React, { useState, useEffect } from 'react'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { DateFormats, flatMap, formatOrInvalid } from 'utils'
import { useLazyGetOrderDetailsForRefundQuery } from 'orders/reduxQueries'
import { ReplaceMessagesFunc } from 'hocs'
import { TicketInfoForRefund } from '../orderTicketsActions/cancelAndRefund/singleCancelRefund/types'

interface UpdateDialogProps {
    accountSlug: string
    orderId: string
    orderItemUuid: string
    newDate: Date
    onCancel: () => void
    onConfirm: () => void
    replaceMessages: ReplaceMessagesFunc
}

function UpdateDialog(props: UpdateDialogProps) {
    const [getOrderDetailsForRefund, { isLoading }] = useLazyGetOrderDetailsForRefundQuery()
    const defaultMessage = `Are you sure you want to update the 'Valid to' date of this
    ticket to the ${formatOrInvalid(props.newDate, DateFormats.EXTRA_LONG_DATE)}?`
    const [text, setText] = useState<React.ReactNode>(defaultMessage)

    useEffect(() => {
        getOrderData()
    }, [])

    async function getOrderData() {
        try {
            const order = await getOrderDetailsForRefund({
                accountSlug: props.accountSlug,
                orderUuid: props.orderId,
            }).unwrap()

            if (!order) {
                closeWithError()
                return
            }

            const ticket = order.tickets.find((t) => t.orderItemId === props.orderItemUuid)
            checkTicketItems(ticket)
        } catch {
            closeWithError()
        }
    }

    function checkTicketItems(orderItem?: TicketInfoForRefund) {
        if (!orderItem) {
            closeWithError()
        } else if (orderItem.quantity < 2) {
            return
        } else {
            const flatMappedTickets = flatMap(orderItem.groupedTicketEntities, (x) => x)
            const barcodes: string[] = []
            flatMappedTickets.forEach((t) => {
                if (t.barcode?.barcode) {
                    barcodes.push(t.barcode.barcode)
                }
            })
            setText(
                <>
                    {defaultMessage}
                    <div>
                        <strong>Please note:</strong> the date will also be updated for all {flatMappedTickets.length}{' '}
                        same type tickets.
                        {barcodes.length > 0 && <> Barcodes: {barcodes.join(', ')}</>}
                    </div>
                </>,
            )
        }
    }

    function closeWithError() {
        props.replaceMessages(
            'unknown_error',
            'error',
            'Oops! We could not retrieve ticket details. Please try again later.',
        )
        props.onCancel()
    }

    return (
        <ConfirmationDialog
            title="Update ticket (Valid to) date?"
            text={text}
            buttonText="Update"
            onCancel={props.onCancel}
            onConfirm={props.onConfirm}
            loading={isLoading}
        />
    )
}

export default UpdateDialog
