import React from 'react'
import { ModalDialog } from 'uiComponents/popups/modal'
import RedeemModeDialogContent from './RedeemModeDialogContent'
import './index.scss'

interface DialogProps {
    onCancel: () => void
    groupRedeemMode?: boolean
}

export function RedeemModeDialog({ onCancel }: DialogProps) {
    return (
        <ModalDialog onDismiss={onCancel} interactive fromTop="20%" className="redeem-mode-dialog">
            <RedeemModeDialogContent />
        </ModalDialog>
    )
}
