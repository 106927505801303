import React, { FC, lazy, Suspense } from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

import WithRoutePermission from 'routes/guards/withRoutePermission'
import RequiredRole from 'routes/guards/requiredRole'
import AccountSettingsPageContainer from 'settings/accountSettings'
import { PageLoader } from 'uiComponents/loaders'
import { BaseRouteParams } from 'hocs'

const Integrations = lazy(() => import('settings/accountSettings/integrations'))
const ContractSection = lazy(() => import('settings/accountSettings/contract'))
const AccountInfoSection = lazy(() => import('settings/accountSettings/accountInfo'))
const TaxConfigurationsSection = lazy(() => import('settings/accountSettings/taxConfigurations'))

const AccountRoutes: FC<RouteComponentProps<BaseRouteParams>> = ({ match }) => {
    return (
        <WithRoutePermission requiredPermissions={['partner_admin']}>
            <AccountSettingsPageContainer>
                <Suspense fallback={<PageLoader />}>
                    <Switch>
                        <Route
                            path="/account/:accountSlug/settings/account_settings/account_info"
                            component={AccountInfoSection}
                        />
                        <Route
                            path="/account/:accountSlug/settings/account_settings/tax_configurations"
                            component={TaxConfigurationsSection}
                        />
                        <Route
                            path="/account/:accountSlug/settings/account_settings/contract"
                            component={() => (
                                <RequiredRole role="convious_admin">
                                    <ContractSection />
                                </RequiredRole>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/settings/account_settings/integrations"
                            component={() => (
                                <RequiredRole role="partner_admin">
                                    <Integrations />
                                </RequiredRole>
                            )}
                        />
                        <Redirect to={`/account/${match.params.accountSlug}/settings/account_settings/account_info`} />
                    </Switch>
                </Suspense>
            </AccountSettingsPageContainer>
        </WithRoutePermission>
    )
}

export default AccountRoutes
