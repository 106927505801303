import React from 'react'
import { useField } from 'formik'

import { FieldVariant } from '../fieldWrapper'
import { useCustomId, useGetComponentState } from '../_shared/hooks'
import RadioField, { RadioFieldProps } from './radioField'

interface RadioFieldFormikProps extends RadioFieldProps, React.InputHTMLAttributes<HTMLInputElement> {
    name: string
    id?: string
    label?: string
    children?: any
    variant?: FieldVariant
    labelProps?: any
}

const RadioFieldFormik: React.FC<RadioFieldFormikProps> = ({ name, id, value, ...props }) => {
    const innerId = useCustomId(id)
    const [field, { error }] = useField({
        name,
        type: 'radio',
        value,
        id: innerId,
    })

    const state = useGetComponentState(name)

    const customProps = {
        ...field,
        ...props,
        id: innerId,
    }

    return <RadioField {...customProps} status={state} error={error} />
}

export default RadioFieldFormik
