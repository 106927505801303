import React, { createContext, useContext } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { defaultTheme } from '../theme'
import { withNavigation, WithNavigationProps } from 'hocs'
import { closeSideBar } from 'sidebar/reducer'
import classNames from 'classnames'
import { isSidebarOpen } from 'sidebar/selectors'
import { State } from 'store'

const MenuBlur = styled.div`
    @media (max-width: ${(props) => props.theme.breakPoints.md}) {
        position: fixed;
        display: none;
        height: 100vh;
        width: 100vw;
        background-color: #0d151e;
        opacity: 0.4;
        z-index: 100;

        &.open {
            display: block;
        }
    }
`

interface MediaQueryProps {
    size: 'md' | 'lg'
}

interface BreakPointState {
    size: 'md' | 'lg'
}

export const MediaQueryContext = createContext<MediaQueryProps>({
    size: 'lg',
} as any)

const getDeviceConfig = (width: number): 'md' | 'lg' => {
    const px = defaultTheme.breakPoints.md.replace('px', '')
    const mdSize = Number.parseInt(px, 10)

    if (width <= mdSize) {
        return 'md'
    }

    return 'lg'
}

export const useMediaQuery = () => {
    return useContext(MediaQueryContext)
}

interface BreakPointProps extends WithNavigationProps<any> {
    closeSideMenu(): void
    open: boolean
}

class BreakPoint extends React.Component<BreakPointProps, BreakPointState> {
    state = {
        size: 'lg',
    } as BreakPointState

    componentDidMount() {
        const size = getDeviceConfig(window.outerWidth)
        this.setState({ size })
        window.addEventListener('resize', this.resizeCallback)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeCallback)
    }

    resizeCallback = () => {
        const size = getDeviceConfig(window.innerWidth)

        if (this.state.size !== size) {
            this.props.closeSideMenu()
            this.setState({ size })
        }
    }

    render() {
        return (
            <MediaQueryContext.Provider
                value={{
                    size: this.state.size,
                }}
            >
                <MenuBlur
                    className={classNames({ open: this.props.open })}
                    onClick={() => this.props.closeSideMenu()}
                />
                {this.props.children}
            </MediaQueryContext.Provider>
        )
    }
}

export default connect(
    (state: State) => ({
        open: isSidebarOpen(state),
    }),
    (dispatch) => ({
        closeSideMenu: () => dispatch(closeSideBar()),
    }),
)(withNavigation(BreakPoint))
