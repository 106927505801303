import * as React from 'react'
import styled, { css } from 'styled-typed'

const List = styled.ul`
    padding-left: 1em;
`
const Text = css`
    font-size: 0.875em;
    line-height: 1.75em;
    margin-bottom: 1.5em;
`
const Intro = styled.div`
    ${Text}
`
const ListItem = styled.li`
    ${Text}
`
const Container = styled.div`
    width: 32em;
`
const CardTitle = styled.div`
    font-size: 1.5em;
    margin-bottom: 1em;
`
const CloseLinkWrapper = styled.div`
    width: 100%;
    text-align: center;
    margin-bottom: -1em;
`
const CloseLink = styled.div`
    cursor: pointer;
    display: inline-block;
    text-decoration: underline;
`

interface ComparisonGuidelineProps {
    onClose: () => void
}

export function ComparisonGuideline(props: ComparisonGuidelineProps) {
    return (
        <Container>
            <CardTitle>What time periods should you compare?</CardTitle>
            <Intro>
                To help you get started, here are a few ideas for how you can use custom time period comparison for your
                advantage:
            </Intro>
            <List>
                <ListItem>
                    <b>Year-over-year</b>: Check how traffic is performing this year compared to last year. Is your
                    business growing? (Hint: you want it to be growing!).
                </ListItem>
                <ListItem>
                    <b>Quater-over-quarter</b>: Make sure you reach your annual goals by tracking your progress each
                    quarter. This allows you to stay on top of those goals that can sometimes be forgotten until the
                    last minute.
                </ListItem>
                <ListItem>
                    <b>Sprint-over-sprint and month-over-month</b>: We recommend running a &quot;firecheck&quot; on your
                    account every 2 weeks (or every &quot;sprint&quot; if agile project management is your thing). Look
                    at the last 2 weeks versus the 2 weeks prior. Then look at month-over-month. Are your goals
                    tracking? Are there any crazy drops in traffic? Are those drops on purpose or is the data getting
                    lost? Make sure you address any issues immediately.
                </ListItem>
            </List>
            <CloseLinkWrapper>
                <CloseLink onClick={props.onClose}>Close</CloseLink>
            </CloseLinkWrapper>
        </Container>
    )
}
