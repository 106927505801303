import { getActiveAccountSlug } from 'auth/selectors'
import { useGetAllArticlesQuery, useGetAllCategoriesQuery } from 'products/reduxApi'
import { useSelector } from 'react-redux'

export const useBackgroundProductInfoFetcher = () => {
    const accountSlug = useSelector(getActiveAccountSlug) as string

    useGetAllCategoriesQuery({ account: accountSlug })
    useGetAllArticlesQuery({ account: accountSlug })
}
