import { DateRange } from 'dateRanges'
import { format, startOfToday, addDays, compareAsc } from 'date-fns'
import { indexBy } from 'utils'
import { DataPoint, TimeSeries } from 'reports/schema'
import { parseISODate } from 'utils/dates'

interface Series {
    name: string | null
    type: string
    stack?: string
    areaStyle?: any
    data: (string | number | null)[]
    color?: string
    barGap?: number | null
}

export const chartColors = {
    actualSales: '#65D6AD',
    salesPrevRange: '#FC7D58',
    forecastBar: 'rgba(101, 214, 173, 0.2)',
    forecastLine: '#4AA5DB',
    forecastBound: 'rgba(74, 165, 219, 0.08)',
    forecastBoundTooltip: '#F0F8FD',
}

export interface XAxisData {
    category: Date
}

function generateXAxis(from: Date, to: Date, next: (d: Date) => Date): XAxisData[] {
    const result = []
    let current = from
    while (compareAsc(current, to) < 0) {
        result.push({ category: current })
        current = next(current)
    }
    return result
}

function getTodayLineOptions(today: number) {
    return {
        silent: true,
        symbol: ['none', 'none'],
        animation: false,
        label: {
            show: true,
            formatter: 'TODAY',
            position: 'start',
            distance: -30,
            color: '#FFFFFF',
            backgroundColor: '#84C2AF',
            padding: 5,
            borderRadius: 4,
            fontSize: 10,
            fontWeight: 'bold',
        },
        lineStyle: { color: 'rgba(164, 175, 178, 0.8)' },
        data: [{ xAxis: today }],
    }
}

export class TimeseriesBarChartSource {
    constructor(
        public readonly granularity: 'day',
        private readonly xAxisData: XAxisData[],
        private readonly dateFrom: Date,
        private readonly dateTo: Date,
        private series: Series[] = [],
    ) {}

    formatBarSeries(barSeries: TimeSeries[], decimalPoints: number = 0): void {
        const xAxisValues = this.xAxisData.map((x) => format(x.category, 'yyyy-MM-dd'))
        const today = xAxisValues.indexOf(format(startOfToday(), 'yyyy-MM-dd'))
        if (barSeries) {
            this.series = barSeries.map((s, i) => {
                return {
                    name: i === 0 ? 'Sold already' : 'Expected to be sold',
                    type: 'bar',
                    barGap: i === 0 ? -1 : null,
                    data: this.setSeriesData(s.points, decimalPoints, i === 1),
                    markLine: i === 0 ? getTodayLineOptions(today) : null,
                    color: i === 0 ? chartColors['actualSales'] : chartColors['forecastBar'],
                }
            })
        }
    }

    formatLineSeries(lineSeries: TimeSeries[], decimalPoints: number = 0): void {
        if (lineSeries) {
            const mappedLineSeries: Series[] = lineSeries.map((s, i) => ({
                name: i === 0 ? 'Forecast' : null,
                type: 'line',
                data: this.setSeriesData(s.points, decimalPoints, true),
                color: i === 0 ? chartColors['forecastLine'] : chartColors['forecastBound'],
                symbol: 'none',
            }))
            mappedLineSeries[1].stack = '1'
            mappedLineSeries[2].stack = '1'
            mappedLineSeries[2].areaStyle = {}
            this.series.push(...mappedLineSeries)
            this.addComparisonSeries()
        }
    }

    formaMiniChartLineSeries(lineSeries: TimeSeries[], decimalPoints: number = 0): void {
        const xAxisValues = this.xAxisData.map((x) => format(x.category, 'yyyy-MM-dd'))
        const today = xAxisValues.indexOf(format(startOfToday(), 'yyyy-MM-dd'))
        if (lineSeries) {
            this.series = lineSeries.map((s, i) => ({
                name: null,
                type: 'line',
                data: this.setSeriesData(s.points, decimalPoints, i === 1, i === 0),
                color: i === 0 ? chartColors['actualSales'] : chartColors['forecastLine'],
                symbol: 'none',
                markLine: i === 0 ? getTodayLineOptions(today) : null,
                lineStyle: {
                    type: i === 1 ? 'dashed' : null,
                },
            }))
        }
    }

    addComparisonSeries = () => {
        this.series.push({
            name: null,
            type: 'bar',
            barGap: -1,
            color: chartColors['salesPrevRange'],
            data: [],
        })
    }

    setSeriesData = (
        datapoints: DataPoint[],
        decimalPoints: number,
        forecast: boolean = false,
        onlyActual: boolean = false,
    ): string[] => {
        if (datapoints.length === 0) {
            return []
        }
        const values = []
        let indexedPoints = {}
        indexedPoints = indexBy(datapoints, (x) => parseISODate(x.timestamp).getTime().toString())
        for (let i = 0; i < this.xAxisData.length; i++) {
            const existing = indexedPoints[this.xAxisData[i].category.getTime().toString()]
            if (existing) {
                values.push(existing.value.toFixed(decimalPoints))
            } else if (forecast) {
                this.xAxisData[i].category < startOfToday() ? values.push(null) : values.push(0)
            } else if (onlyActual) {
                this.xAxisData[i].category > startOfToday() ? values.push(null) : values.push(0)
            } else {
                values.push(0)
            }
        }
        return values
    }

    addHoverSeries = (index: number, value: number) => {
        const data = this.xAxisData.map((x, i) => (i === index ? value : null))
        this.series[this.series.length - 1].data = data
    }

    removeHoverSeries = () => {
        this.series[this.series.length - 1].data = []
    }

    formatXAxisData(): string[] {
        return this.xAxisData.map((x) => format(x.category, 'do') + '\n' + format(x.category, 'eee'))
    }

    from(): Date {
        return this.dateFrom
    }

    to(): Date {
        return this.dateTo
    }

    props(): any {
        return {
            xAxisData: this.formatXAxisData(),
            xAxisDataRaw: this.xAxisData,
            legendData: this.series.map((s) => s.name),
            series: this.series,
        }
    }
}

export function createTimeseriesBarChart(range: DateRange): TimeseriesBarChartSource {
    return new TimeseriesBarChartSource(
        'day',
        generateXAxis(range.from, range.to, (d) => addDays(d, 1)),
        range.from,
        range.to,
    )
}
