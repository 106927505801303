import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome'
import classNames from 'classnames'
import { Typography } from '@mui/material'
import './modal.scss'

export const NavigationSection: React.FC<React.HTMLAttributes<HTMLElement>> = ({ children }) => (
    <div className="navigation-section">{children}</div>
)

export const Overlay: React.FC<React.HTMLAttributes<HTMLElement>> = ({ children }) => (
    <div className="overlay">{children}</div>
)

interface ModalDialogProps {
    children: React.ReactNode
    interactive?: boolean
    fromTop?: string
    dynamicHeight?: number
    onDismiss?: () => void
    className?: string
    noPadding?: boolean
}

interface ModalDialogState {
    containerHeight: string
}

export class ModalDialog extends React.Component<
    ModalDialogProps & React.HTMLAttributes<HTMLElement>,
    ModalDialogState
> {
    el = document.createElement('div')

    constructor(props: ModalDialogProps) {
        super(props)
        this.state = {
            containerHeight: 'auto',
        }
    }

    componentDidMount() {
        document.body.appendChild(this.el)
        window.addEventListener('resize', this.setContainerHeight)
        this.setContainerHeight()
    }

    componentDidUpdate(prevProps: ModalDialogProps) {
        if (prevProps.dynamicHeight !== this.props.dynamicHeight) {
            this.setContainerHeight()
        }
    }

    componentWillUnmount() {
        document.body.removeChild(this.el)
        window.removeEventListener('resize', this.setContainerHeight)

        const layout = document.getElementById('layout')
        if (layout) {
            layout.classList.remove('fixed')
        }
    }

    setContainerHeight = () => {
        if (this.container && this.container.scrollHeight > document.body.clientHeight * 0.8) {
            this.setState({
                containerHeight: `${document.body.clientHeight * 0.8}px`,
            })
        } else {
            this.setState({ containerHeight: 'auto' })
        }
        const layout = document.getElementById('layout')
        if (layout) {
            layout.classList.add('fixed')
        }
    }

    container: HTMLDivElement | null = null
    setRef = (node: HTMLDivElement) => {
        this.container = node
    }

    render() {
        const styles = {
            padding: this.props.noPadding ? 0 : '1.5em 2em 2em 2em',
            top: this.props.fromTop || '10%',
            height: this.state.containerHeight || 'auto',
            ...this.props.style,
        }

        return ReactDOM.createPortal(
            <Overlay>
                <div
                    ref={this.setRef}
                    style={styles}
                    className={classNames('container', 'modal', this.props.className)}
                >
                    {this.props.onDismiss && (
                        <div className="close-button" onClick={this.props.onDismiss}>
                            <FontAwesomeIcon icon={faTimes as IconProp} style={{ width: '1.375rem' }} />
                        </div>
                    )}
                    {this.props.children}
                </div>
            </Overlay>,
            this.el,
        )
    }
}

interface ModalDialogTitleProps {
    children: React.ReactNode
}

export function ModalDialogTitle({ children }: ModalDialogTitleProps) {
    return <Typography variant="h4">{children}</Typography>
}
