import * as React from 'react'
import styled from 'styled-typed'
import { ChartDataLoader } from 'uiComponents/loaders'
import { FrameWrapper, SafariMobilePreviewSrollWrapper } from 'uiComponents/studio/preview/components'

export const Container = styled.div`
    display: flex;
    flex: 1;
    margin: 10px;
    word-break: break-word;
`

const PlacementContainer = styled.div<{
    size?: string
    appearance?: string
    brandcolor?: string
    customColor?: string
}>`
    width: 50%;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    overflow: hidden;
    background-color: white;
    color: black;
    ${(props) =>
        props.size === 'WIDE' &&
        `
    width: 100%;
  `}
    ${(props) =>
        props.appearance === 'DARK' &&
        `
    background-color: black;
    color: ${props.theme.colors.white};
  `}
  ${(props) =>
        props.appearance === 'BRAND' &&
        `
    background-color: ${props.brandcolor};
    color: ${props.theme.colors.white};
  `}
  ${(props) =>
        props.appearance === 'CUSTOM' &&
        `
    background-color: ${props.customColor};
    color: ${props.theme.colors.white};
  `}
`

const PlacementContent = styled.div`
    padding: 20px;
`

const PlacementTitle = styled.p`
    margin: 0;
    padding: 0;
    font-size: 19pt;
    line-height: 22pt;
    letter-spacing: -0.46pt;
    flex: 2;
`

const PlacementSubtitle = styled.p<{ spacing?: number }>`
    margin: 0;
    padding: 0;
    font-size: 13pt;
    line-height: 18pt;
    letter-spacing: -0.08pt;
    ${(props) =>
        props.spacing &&
        `
    margin: ${props.spacing}px 0;
  `}
`

const PlacementWideAction = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`

const PlacementButton = styled.p<{ appearance?: string; spacing?: number }>`
    border-radius: 16px;
    background-color: rgba(119, 119, 119, 0.08);
    padding: 8pt;
    margin: 0;
    display: flex;
    flex: 1;
    justify-content: center;
    ${(props) =>
        props.appearance === 'DARK' &&
        `
    background-color: rgba(246, 246, 246, 0.08);
  `}
    ${(props) =>
        props.spacing &&
        `
    margin-left: ${props.spacing}px;
  `}
`

const PlacementImage = styled.img`
    height: auto;
    width: 100%;
`

interface TemplatePreviewProps {
    loading: boolean
    title?: string
    subtitle?: string
    buttonCaption?: string
    size: string
    image?: string
    appearance: string
    brandColor: string
    customColor: string
}

export function TemplatePreview(props: TemplatePreviewProps) {
    return (
        <FrameWrapper>
            <SafariMobilePreviewSrollWrapper>
                <Container>
                    {props.size === 'WIDE' ? <WidePlacement {...props} /> : <SmallPlacement {...props} />}
                </Container>
                {props.loading && <ChartDataLoader />}
            </SafariMobilePreviewSrollWrapper>
        </FrameWrapper>
    )
}

const shouldRenderContent = (title: string = '', subtitle: string = '', buttonCaption: string = ''): boolean => {
    return title.length > 0 || subtitle.length > 0 || buttonCaption.length > 0
}

const WidePlacement = ({
    title,
    subtitle,
    buttonCaption,
    size,
    image,
    appearance,
    brandColor,
    customColor,
}: TemplatePreviewProps) => {
    return (
        <PlacementContainer appearance={appearance} size={size} brandcolor={brandColor} customColor={customColor}>
            {image && <PlacementImage src={image} alt="Placement image" />}
            {shouldRenderContent(title, subtitle, buttonCaption) && (
                <PlacementContent>
                    {subtitle && <PlacementSubtitle>{subtitle}</PlacementSubtitle>}
                    <PlacementWideAction>
                        {title && <PlacementTitle>{title}</PlacementTitle>}
                        {buttonCaption && (
                            <PlacementButton appearance={appearance} spacing={10}>
                                <span>{buttonCaption}</span>
                            </PlacementButton>
                        )}
                    </PlacementWideAction>
                </PlacementContent>
            )}
        </PlacementContainer>
    )
}

const SmallPlacement = ({ title, subtitle, buttonCaption, size, appearance, brandColor }: TemplatePreviewProps) => {
    return (
        <PlacementContainer appearance={appearance} size={size} brandcolor={brandColor}>
            {shouldRenderContent(title, subtitle, buttonCaption) && (
                <PlacementContent>
                    {title && <PlacementTitle>{title}</PlacementTitle>}
                    {subtitle && <PlacementSubtitle spacing={10}>{subtitle}</PlacementSubtitle>}
                    {buttonCaption && (
                        <PlacementButton appearance={appearance}>
                            <span>{buttonCaption}</span>
                        </PlacementButton>
                    )}
                </PlacementContent>
            )}
        </PlacementContainer>
    )
}
