import React from 'react'
import styled from 'styled-typed'

import { PageHeading, PageTitle } from 'uiComponents/typography'
import { useBookingNavigation } from './hooks'

const Heading = styled(PageHeading)`
    margin-bottom: 0;
    min-height: 7rem;
    max-width: 1200px;
`

const GuidelinesLink = styled.a`
    color: ${(props) => props.theme.colors.boyBlue};
`

const CodesHeading = () => {
    const sections = useBookingNavigation()
    const activeSection = sections.find((section) => section.active)

    return (
        <>
            <PageTitle>{activeSection?.title}</PageTitle>
            <Heading>
                <div>{activeSection?.description}</div>
                {activeSection?.secondDescription && <div>{activeSection?.secondDescription}</div>}
                {activeSection?.guidelinesSubject && activeSection.guidelinesUrl && (
                    <div>
                        <>
                            Guidelines on{' '}
                            <GuidelinesLink target="_blank" href={activeSection.guidelinesUrl}>
                                how to upload {activeSection.guidelinesSubject}.
                            </GuidelinesLink>
                        </>
                    </div>
                )}
                {activeSection?.tabHeader}
            </Heading>
        </>
    )
}

export default CodesHeading
