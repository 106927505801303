import React from 'react'
import { PriceSetting, AdjustmentType } from 'products/pricing/pricingService'
import { Currency } from 'uiComponents/money/moneyHoc'
import { Row, Column } from 'uiComponents/pageElements'
import { Toggler } from './shared'
import { priceSettingNames } from './exceptionRulesFormSection'
import { AdjustRuleItem } from './ruleItem'
import { Adjustment, RuleHeader } from './shared'

interface RulesWithToggleProps {
    currency: Currency
    adjustments: Adjustment[]
    handlePriceSettingRuleToggle: (priceSetting: PriceSetting, on: boolean) => void
    handleRuleChange: (priceType: PriceSetting, value: number, type: AdjustmentType) => void
    validate: boolean
}
export function RulesWithToggle(props: RulesWithToggleProps) {
    const { adjustments, currency, validate } = props
    return (
        <>
            {adjustments.map((a, i) => {
                return (
                    <div key={i}>
                        <Row>
                            <Column>
                                <RuleHeader>
                                    <Toggler
                                        id={`ruleFor${a.name}`}
                                        isOn={a.on}
                                        onClick={(on: boolean) => {
                                            props.handlePriceSettingRuleToggle(a.name, on)
                                        }}
                                    />
                                    <div style={{ marginLeft: '0.5em', alignSelf: 'center' }}>
                                        Rule for {priceSettingNames[a.name]}
                                    </div>
                                </RuleHeader>
                            </Column>
                        </Row>
                        <Row>
                            <AdjustRuleItem
                                isOn={a.on}
                                priceSetting={a.name}
                                adjustmentType={a.type}
                                adjustmentSize={a.value}
                                currency={currency}
                                handleRuleChange={props.handleRuleChange}
                                validate={validate}
                            />
                        </Row>
                    </div>
                )
            })}
        </>
    )
}
