import * as React from 'react'
import { usePrevious } from 'reactUtils'
import TimeseriesBarChart from 'uiComponents/charts/timeseriesBarChart'
import { StatsServiceContext } from 'http/context'
import { DateRange } from 'dateRanges'
import { format } from 'date-fns'
import { TimeseriesBarChartSource } from 'uiComponents/charts/timeseriesBarChartSource'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import {
    AxisTitle,
    areDateRangeDatesEqual,
    mapTimeseriesForTooltip,
    getTooltipRow,
    getTooltipXAxisLabel,
    getTooltipHeader,
    getTooltipFooter,
    getMarker,
} from 'reports/helpers'
import { Filters } from 'uiComponents/filter/schema'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { TimeSeries, DataPoint, dummyTimeSeries } from 'reports/schema'
import { QueryConfig } from 'reports/queryGenerator'
import isEqual from 'lodash/isEqual'
import { withFeatures } from 'features'
import { transparency } from 'utils/css'
import { differenceInDays } from 'date-fns'
import { parseISODate } from 'utils/dates'

const queryConfig: QueryConfig = {
    querySetName: 'PricingPageTimeseriesChart',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'filters', type: '[FilterDictionary]' },
        { name: 'soldMetric', type: 'StatsMetric' },
        { name: 'groupBy', type: 'String' },
        { name: 'granularity', type: 'StatsGranularity' },
    ],
    queries: [
        {
            name: 'avgPrice',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters', 'groupBy'],
            customVariables: [{ name: 'metric', customValue: 'avg_item_price_by_event_date' }],
            presetResult: 'timeseries',
        },
        {
            name: 'minPrice',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters', 'groupBy'],
            customVariables: [{ name: 'metric', customValue: 'min_item_price_by_event_date' }],
            presetResult: 'timeseries',
        },
        {
            name: 'maxPrice',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters', 'groupBy'],
            customVariables: [{ name: 'metric', customValue: 'max_item_price_by_event_date' }],
            presetResult: 'timeseries',
        },
        {
            name: 'actualSales',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters', 'groupBy'],
            customVariables: [{ name: 'metric', configValue: 'soldMetric' }],
            presetResult: 'timeseries',
        },
    ],
}

export const pricingChartColors = {
    avgPrice: '#5298F4',
    minPrice: transparency('#5298F4', 0.1),
    maxPrice: transparency('#5298F4', 0.1),
    actualSales: '#E5EBED',
}

interface PricingChartProps {
    dateRange: DateRange
    filters: Filters[]
    accountSlug: string
    chart: TimeseriesBarChartSource
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    getCurrency: (accountSlug: string) => Currency
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function PricingChart(props: PricingChartProps) {
    const _isMounted = React.useRef(false)
    const _lastRequest = React.useRef<number>()
    const statsService = React.useContext(StatsServiceContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [mappedAvgPrice, setMappedAvgPrice] = React.useState<DataPoint[]>([])
    const [mappedMinPrice, setMappedMinPrice] = React.useState<DataPoint[]>([])
    const [mappedMaxPrice, setMappedMaxPrice] = React.useState<DataPoint[]>([])
    const [mappedActual, setMappedActual] = React.useState<DataPoint[]>([])
    const [salesLineName, setSalesLineName] = React.useState<string>('Actual sales')
    const [oneDayRange, setOneDayRange] = React.useState<boolean>(false)

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    const prevFilters = usePrevious(props.filters)
    React.useEffect(() => {
        _isMounted.current = true
        if (
            prevAccountSlug !== props.accountSlug ||
            !areDateRangeDatesEqual(prevDateRange, props.dateRange) ||
            !isEqual(prevFilters, props.filters)
        ) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug, props.dateRange, props.filters])

    const mapPricingTimeseries = (seriesList: TimeSeries[], oneDay: boolean): TimeSeries => {
        if (!seriesList) {
            return dummyTimeSeries
        }
        if (!oneDay) {
            return seriesList && seriesList.length > 0 ? seriesList[0] : dummyTimeSeries
        }
        const timeseries = {
            label: null,
            points: seriesList.map((s) => ({
                timestamp: s.label || '',
                value: s.points[0] ? s.points[0].value : 0,
            })),
        }
        return timeseries
    }

    const getData = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        const dateFrom = format(props.dateRange.from, 'yyyy-MM-dd')
        const dateTo = format(props.dateRange.to, 'yyyy-MM-dd')
        const oneDayPeriod = differenceInDays(parseISODate(dateTo), parseISODate(dateFrom)) < 1
        try {
            setLoading(true)
            const variables = {
                widget: props.accountSlug,
                dateFrom,
                dateTo,
                soldMetric: oneDayPeriod ? 'products_sold_so_far' : 'products_sold_by_event_date',
                filters: props.filters,
                granularity: props.chart.granularity,
                groupBy: oneDayPeriod ? 'purchase_date' : null,
            }
            const data = await statsService.getStats(queryConfig, variables)
            if (_lastRequest.current !== requestTime) {
                return
            }
            const avgPriceTimeseries = mapPricingTimeseries(data.avgPrice?.timeSeries, oneDayPeriod)
            const maxPriceTimeseries = mapPricingTimeseries(data.maxPrice?.timeSeries, oneDayPeriod)
            const minPriceTimeseries = mapPricingTimeseries(data.minPrice?.timeSeries, oneDayPeriod)
            const actualTimeseries = mapPricingTimeseries(data.actualSales?.timeSeries, oneDayPeriod)
            const mappedMaxPriceLineData = {
                label: null,
                points: maxPriceTimeseries.points.map((p, i) => ({
                    timestamp: p.timestamp,
                    value: p.value - minPriceTimeseries.points[i].value,
                })),
            }
            const actualSalesLineName = oneDayPeriod ? 'Sales so far' : 'Actual sales'
            const timeseries: TimeSeries[] = [
                { label: 'Avg. price', points: avgPriceTimeseries.points },
                minPriceTimeseries,
                mappedMaxPriceLineData,
                { label: actualSalesLineName, points: actualTimeseries.points },
            ]

            props.chart.formatPricingChartSeries(timeseries, 2, true, oneDayPeriod)
            const granularity = props.chart.granularity
            const xAxis = props.chart.props().xAxisDataRaw
            const dateFormat = oneDayPeriod ? 'yyyy-MM-dd' : undefined
            const mappedAvgPriceData = mapTimeseriesForTooltip(
                xAxis,
                avgPriceTimeseries.points,
                granularity,
                dateFormat,
            )
            const mappedMinPriceData = mapTimeseriesForTooltip(
                xAxis,
                minPriceTimeseries.points,
                granularity,
                dateFormat,
            )
            const mappedMaxPriceData = mapTimeseriesForTooltip(
                xAxis,
                maxPriceTimeseries.points,
                granularity,
                dateFormat,
            )
            const mappedActualData = mapTimeseriesForTooltip(xAxis, actualTimeseries.points, granularity, dateFormat)
            if (_isMounted.current) {
                setLoading(false)
                setOneDayRange(oneDayPeriod)
                setSalesLineName(actualSalesLineName)
                setMappedAvgPrice(mappedAvgPriceData)
                setMappedMinPrice(mappedMinPriceData)
                setMappedMaxPrice(mappedMaxPriceData)
                setMappedActual(mappedActualData)
            }
        } catch {
            setLoading(false)
            props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Pricing chart could not be loaded, please try again later.',
            )
        }
    }

    const formatMoney = (amount: string | number) => {
        return props.formatCurrencyString(Number(amount).toFixed(2), props.accountSlug)
    }

    const chartTooltipFormatter = (params: TooltipParams[]) => {
        const avgPricePoint = mappedAvgPrice[params[0].dataIndex]
        const avgPriceData = avgPricePoint ? formatMoney(avgPricePoint.value) : '0'
        const maxPricePoint = mappedMaxPrice[params[0].dataIndex]
        const maxPriceData = maxPricePoint ? formatMoney(maxPricePoint.value) : '0'
        const minPricePoint = mappedMinPrice[params[0].dataIndex]
        const minPriceData = minPricePoint ? formatMoney(minPricePoint.value) : '0'
        const actualPoint = mappedActual[params[0].dataIndex]
        const actualData = actualPoint ? actualPoint.value : '0'

        const xAxisLabel = avgPricePoint
            ? getTooltipXAxisLabel(avgPricePoint.timestamp, props.chart.granularity)
            : params[0].axisValue

        let tooltip = getTooltipHeader(xAxisLabel)

        tooltip += getTooltipRow([`${getMarker(pricingChartColors['avgPrice'])} Avg. price:`, avgPriceData])
        tooltip += getTooltipRow([`${getMarker('#ccfdff')} Max price:`, maxPriceData])
        tooltip += getTooltipRow([`${getMarker('#ccfdff')} Min price:`, minPriceData])
        tooltip += getTooltipRow([`${getMarker('#dae1e3')} ${salesLineName}:`, actualData])

        tooltip += getTooltipFooter()

        return tooltip
    }

    const checkNoLeftYAxisValues = (actualSales: string[]) => {
        return actualSales.filter((x) => !!x).length > 0
    }

    const axisData = props.chart.props().xAxisData
    const legendData = props.chart.props().legendData
    const series = props.chart.props().series

    const showRightSplitLines = series.length > 4 ? checkNoLeftYAxisValues(series[4].data) : false
    const commonYAxisParams = {
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { color: '#A4AFB2' },
    }
    const yAxis = [{ ...commonYAxisParams }, { ...commonYAxisParams, splitLine: { show: !showRightSplitLines } }]

    return (
        <div style={{ position: 'relative' }}>
            {loading && <ChartDataLoader />}
            <AxisTitle title="Tickets" left="1em" top="4.2em" />
            <AxisTitle title="Price" right="0.5em" top="4.2em" />
            <AxisTitle
                title={oneDayRange ? 'Purchase dates' : 'Visit dates'}
                left="50%"
                bottom="-2em"
                style={{ transform: 'translateX(-50%)' }}
            />
            <TimeseriesBarChart
                axisData={axisData}
                series={series}
                legendData={legendData.length > 1 ? legendData : null}
                yAxisConfig={yAxis}
                loading={loading}
                tooltipFormatter={chartTooltipFormatter}
                height="400px"
                legendDisabled
                titleOffset={5}
                gridRightOffset={40}
                lineSeriesOnly
            />
        </div>
    )
}

export default withFeatures(withCurrency(PricingChart))
