import React, { useState } from 'react'
import { TimePicker, TimePickerProps } from './timePicker'
import { ActionIcon } from 'uiComponents/buttons/actionIcon'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { convertDateFormat, DateFormats } from 'utils/dates'
import './inlineTimePicker.scss'
import { Button } from '@mui/material'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import classNames from 'classnames'

interface InlineTimePickerProps extends Omit<TimePickerProps, 'onChange'> {
    onSave: (time: string | null) => Promise<void> | void
    disabled?: boolean
    requireConfirmation?: boolean
    confirmationMessage: (time?: string | null) => void
}

export const InlineTimePicker = (props: InlineTimePickerProps) => {
    const { onSave, disabled, required, requireConfirmation, confirmationMessage } = props
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [savingInProgress, setSavingInProgress] = useState(false)
    const [editing, setEditing] = React.useState(false)
    const [time, setTime] = useState<string | null>(props.value ?? null)

    const saveButtonDisabled = required && !time

    const onCancelButtonClick = () => {
        setTime(props.value ?? null)
        setEditing(false)
    }

    const onSaveButtonClick = () => {
        setEditing(false)
        if (requireConfirmation) {
            setShowConfirmation(true)
        } else {
            onSave(time)
        }
    }

    const onConfirmCancel = () => {
        setTime(props.value ?? null)
        setShowConfirmation(false)
    }

    const onConfirm = async () => {
        setSavingInProgress(true)
        await onSave(time || '')
        setSavingInProgress(false)
        setShowConfirmation(false)
    }

    return (
        <span className={classNames('inline-time-picker', { editing })}>
            {showConfirmation && (
                <ConfirmationDialog
                    title="Confirmation"
                    text={confirmationMessage ? confirmationMessage(time) : 'Are you sure?'}
                    buttonText="Update"
                    onConfirm={onConfirm}
                    onCancel={onConfirmCancel}
                    loading={savingInProgress}
                />
            )}
            {editing ? (
                <>
                    <TimePicker
                        {...props}
                        autoFocus={true}
                        onChange={(time) => {
                            setTime(time)
                        }}
                    />
                    <Button className="cancel" size="small" onClick={onCancelButtonClick}>
                        Cancel
                    </Button>
                    <Button className="save" size="small" onClick={onSaveButtonClick} disabled={saveButtonDisabled}>
                        Save
                    </Button>
                </>
            ) : (
                <>
                    {time ? convertDateFormat(time, 'HH:mm', DateFormats.SHORT_TIME) : 'Any time'}
                    {!disabled && (
                        <ActionIcon
                            onClick={() => setEditing(true)}
                            title="Edit"
                            className="edit first-icon"
                            icon={faPencilAlt}
                        />
                    )}
                </>
            )}
        </span>
    )
}
