import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { State } from 'store'
import { History, Location } from 'history'
import { withFeatures } from 'features'
import { Container, ContainerHeading, FullWidthContainerBody, HeadingSectionName } from 'uiComponents/settingsContainer'
import ResellersPage from 'channels/resellersPage'
import RetailersPage from 'channels/retailersPage'
import GoogleTTDPage from 'channels/googleTTD'
import { Headline } from 'uiComponents/typography'
import { ActionButton } from 'uiComponents/buttons'
import SearchField, { TopSearchContainer } from 'uiComponents/search/searchWithButton'
import Feature from 'features/feature'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { CreateNewType, ChannelsSectionType } from './channelsService'
import { BookingCodesService } from 'venue/bookingCodes/bookingCodesService'
import { ArticleService } from 'admin/articleService'

interface ChannelsPageProps {
    history: History
    accountSlug: string
    location: Location
    navigation: Navigation
    match: RouteMatch<{}>
    bookingCodesService: BookingCodesService
    articleService: ArticleService
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function ChannelsPage(props: ChannelsPageProps) {
    const isConvious = useSelector((state: State) => state.auth.user?.isAdmin || false)

    const [activeSection, setActiveSection] = useState<ChannelsSectionType>('resellers')
    const [creatingNew, setCreatingNew] = useState<CreateNewType>(null)
    const [showAddCampaign, setShowAddCampaign] = useState<boolean>(false)
    const [showAddVenue, setShowAddVenue] = useState<boolean>(false)

    const onSectionChange = (section: ChannelsSectionType) => {
        setActiveSection(section)
    }

    const showAddCampaignButton = (value: boolean) => {
        setShowAddCampaign(value)
    }

    const showAddVenueButton = (value: boolean) => {
        setShowAddVenue(value)
    }

    const resellersFeatureOn = props.hasFeature('ResellersFeature', props.accountSlug)
    const retailersFeatureOn = props.hasFeature('RetailersReservationCodes', props.accountSlug)
    const googleTTDFeatureOn = props.hasFeature('GoogleThingsToDo', props.accountSlug)
    const sectionsList: ChannelsSectionType[] = []
    if (resellersFeatureOn) {
        sectionsList.push('resellers')
    }
    if (retailersFeatureOn) {
        sectionsList.push('retailers')
    }
    if (googleTTDFeatureOn) {
        sectionsList.push('google_ttd')
    }
    const search = props.navigation.query().q

    return (
        <div style={{ position: 'relative' }} id="channels">
            <Headline size={1}>Channels</Headline>
            <TopSearchContainer>
                {['resellers', 'retailers'].includes(activeSection) ? <SearchField hideExtendedSearch /> : <div />}
                {(resellersFeatureOn || retailersFeatureOn) && activeSection !== 'google_ttd' && (
                    <div>
                        {activeSection === 'retailers' && showAddCampaign && (
                            <ActionButton
                                kind="action"
                                size="medium"
                                secondary
                                style={{ marginLeft: '1.5em' }}
                                onClick={() => setCreatingNew('campaign')}
                                id="add-new"
                            >
                                + Add campaign
                            </ActionButton>
                        )}
                        <ActionButton
                            kind="action"
                            size="medium"
                            style={{ marginLeft: '1.5em' }}
                            onClick={() => setCreatingNew(activeSection as CreateNewType)}
                            id="add-new"
                        >
                            + Add new
                        </ActionButton>
                    </div>
                )}
                {isConvious && activeSection === 'google_ttd' && googleTTDFeatureOn && showAddVenue && (
                    <ActionButton
                        kind="action"
                        size="medium"
                        style={{ marginLeft: '1.5em' }}
                        onClick={() => setCreatingNew(activeSection as CreateNewType)}
                        id="add-new"
                    >
                        + Add venue
                    </ActionButton>
                )}
            </TopSearchContainer>
            <Container>
                <ContainerHeading style={{ paddingLeft: '1.5em' }}>
                    <Feature name="ResellersFeature" accountSlug={props.accountSlug}>
                        <HeadingSectionName
                            className={activeSection === 'resellers' ? 'active' : ''}
                            to={`/account/${props.accountSlug}/orders/channels/resellers/`}
                        >
                            Resellers
                        </HeadingSectionName>
                    </Feature>
                    <Feature name="RetailersReservationCodes" accountSlug={props.accountSlug}>
                        <HeadingSectionName
                            className={activeSection === 'retailers' ? 'active' : ''}
                            to={`/account/${props.accountSlug}/orders/channels/retailers/`}
                        >
                            Retailers
                        </HeadingSectionName>
                    </Feature>
                    <Feature name="GoogleThingsToDo" accountSlug={props.accountSlug}>
                        <HeadingSectionName
                            className={activeSection === 'google_ttd' ? 'active' : ''}
                            to={`/account/${props.accountSlug}/orders/channels/google_ttd/`}
                        >
                            Google
                        </HeadingSectionName>
                    </Feature>
                </ContainerHeading>
                <Switch location={props.location}>
                    <Route
                        path="/account/:accountSlug/orders/channels/resellers/"
                        render={() => (
                            <FullWidthContainerBody>
                                <ResellersPage
                                    accountSlug={props.accountSlug}
                                    onSectionChange={onSectionChange}
                                    creatingModeOn={creatingNew === ('resellers' as CreateNewType)}
                                    resetCreatingMode={() => setCreatingNew(null)}
                                    search={search}
                                />
                            </FullWidthContainerBody>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/orders/channels/retailers/"
                        render={() => (
                            <FullWidthContainerBody>
                                <RetailersPage
                                    accountSlug={props.accountSlug}
                                    onSectionChange={onSectionChange}
                                    creatingNew={creatingNew}
                                    resetCreatingMode={() => setCreatingNew(null)}
                                    search={search}
                                    showAddCampaignButton={showAddCampaignButton}
                                    bookingCodesService={props.bookingCodesService}
                                />
                            </FullWidthContainerBody>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/orders/channels/google_ttd/"
                        render={() => (
                            <FullWidthContainerBody>
                                <GoogleTTDPage
                                    accountSlug={props.accountSlug}
                                    onSectionChange={onSectionChange}
                                    creatingModeOn={creatingNew === ('google_ttd' as CreateNewType)}
                                    resetCreatingMode={() => setCreatingNew(null)}
                                    showAddVenueButton={showAddVenueButton}
                                    articleService={props.articleService}
                                />
                            </FullWidthContainerBody>
                        )}
                    />
                    <Route
                        render={() => (
                            <Redirect to={`/account/${props.accountSlug}/orders/channels/${sectionsList[0]}/`} />
                        )}
                    />
                </Switch>
            </Container>
        </div>
    )
}

export default withFeatures(withNavigation(ChannelsPage))
