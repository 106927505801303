import React, { useEffect, useState } from 'react'
import styled from 'styled-typed'
import { useFormikContext } from 'formik'
import { ArticleCrudData } from 'admin/articleService'
import { FormItem, FormItemName } from 'uiComponents/form/formElements'
import { SingleSelectFieldFormik, SingleSelectOption } from 'uiComponents/input'
import NumberInputFieldFormik from 'uiComponents/input/numberInput/numberInputFieldFormik'
import { FormTogglerCol, FormToggler } from 'uiComponents/input/toggle'
import { Body } from 'uiComponents/typography'
import Infotip from 'uiComponents/infotip'
import { Row, Col } from 'uiComponents/flex'
import { TicketDurationEditor } from './ticketDurationEditor'

const MaxScansCol = styled(Col)`
    display: flex;
    align-items: center;
`

const scanLimitTypeOptions: SingleSelectOption[] = [
    { name: 'Allow scanning on period starting on purchase date', value: 'scan_period_selected_date' },
    { name: 'Allow scanning on period that the product is valid', value: 'scan_period_fixed' },
    { name: 'Allow scanning on period starting on 1st scan', value: 'scan_period_1st_scan' },
]

export function ScanningOptionsEditor() {
    const {
        values: { scanningOptions },
        setFieldValue,
    } = useFormikContext<ArticleCrudData>()
    if (!scanningOptions) {
        return null
    }
    const [limitScans, setLimitScans] = useState<boolean>(scanningOptions.maxScans !== null)
    const [limitScansPerDay, setLimitScansPerDay] = useState<boolean>(scanningOptions.maxScansPerDay !== null)

    useEffect(() => {
        setLimitScans(scanningOptions.maxScans !== null)
        setLimitScansPerDay(scanningOptions.maxScansPerDay !== null)
    }, [scanningOptions])

    function onLimitScansChanged(value: boolean) {
        setLimitScans(value)
        if (!value) {
            setFieldValue('scanningOptions.maxScans', null)
        }
    }

    function onLimitScansPerDayChanged(value: boolean) {
        setLimitScansPerDay(value)
        if (!value) {
            setFieldValue('scanningOptions.maxScansPerDay', null)
        }
    }

    return (
        <Row>
            <Col span={6}>
                <Row>
                    <FormTogglerCol span={12}>
                        <FormItemName>Limit total number of scans</FormItemName>
                        <FormToggler isOn={limitScans} onClick={onLimitScansChanged} />
                    </FormTogglerCol>
                </Row>
                {limitScans && (
                    <Row>
                        <MaxScansCol span={12}>
                            <Body size={2}>Total number of scans allowed:</Body>
                            <NumberInputFieldFormik
                                id="max-scans"
                                name="scanningOptions.maxScans"
                                integerOnly
                                min={0}
                                max={999}
                                placeholder="1"
                                style={{
                                    marginLeft: '.5rem',
                                }}
                                validationMessageProps={{
                                    marginLeft: '.5rem',
                                }}
                            />
                        </MaxScansCol>
                    </Row>
                )}
                <Row>
                    <FormTogglerCol span={12}>
                        <FormItemName>Limit the number of scans per day</FormItemName>
                        <FormToggler isOn={limitScansPerDay} onClick={onLimitScansPerDayChanged} />
                    </FormTogglerCol>
                </Row>
                {limitScansPerDay && (
                    <Row>
                        <MaxScansCol span={12}>
                            <Body size={2}>Max number of scans per day:</Body>
                            <NumberInputFieldFormik
                                id="max-scans-per-day"
                                name="scanningOptions.maxScansPerDay"
                                integerOnly
                                min={1}
                                max={999}
                                placeholder="1"
                                style={{
                                    marginLeft: '.5rem',
                                }}
                                validationMessageProps={{
                                    marginLeft: '.5rem',
                                }}
                            />
                        </MaxScansCol>
                    </Row>
                )}
                <FormItem htmlFor="scan-limit-type">
                    <FormItemName>Scan limit type</FormItemName>
                    <SingleSelectFieldFormik
                        id="scan-limit-type"
                        name="scanningOptions.scanLimitType"
                        options={scanLimitTypeOptions}
                        height="2.5rem"
                        style={{ marginBottom: '0.625em' }}
                    />
                </FormItem>
                <Col span={12}>
                    <FormItemName>
                        Scanning period length
                        <Infotip pointer="left" maxWidth="25em">
                            Duration for which the ticket is valid for once bought
                        </Infotip>
                    </FormItemName>
                    <TicketDurationEditor name="scanningOptions.ticketDuration" />
                </Col>
            </Col>
        </Row>
    )
}
