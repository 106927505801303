import React from 'react'
import { NavigationTabs } from 'uiComponents/navigation/tabs'
import { History } from 'history'
import { withPermission } from 'admin/hocs'

interface ExternalConfigurationSectionProps {
    accountSlug: string
    history: History
    id: string
    viewMode: 'flat' | 'nested' | ''
    hasPermission: (permission: string, accountSlug: string) => boolean
}

function ExternalConfigurationSection(props: ExternalConfigurationSectionProps) {
    const tabs = [
        {
            name: 'Edit Details',
            onClick: () =>
                props.history.push(`/account/${props.accountSlug}/products/crud/${props.id}?from=${props.viewMode}`),
            current: false,
            disabled: !props.hasPermission('partner_admin', props.accountSlug),
        },
        {
            name: 'Edit Validity',
            onClick: () =>
                props.history.push(
                    `/account/${props.accountSlug}/products/validity/${props.id}/detail?from=${props.viewMode}`,
                ),
            current: false,
            disabled: !props.hasPermission('view_product_validity', props.accountSlug),
        },
        {
            name: 'Edit Pricing',
            onClick: () => {},
            current: true,
            disabled: !props.hasPermission('view_pricing_settings', props.accountSlug),
        },
    ]

    return (
        <div style={{ marginBottom: '2em' }}>
            <NavigationTabs tabs={tabs} />
        </div>
    )
}

export default withPermission(ExternalConfigurationSection)
