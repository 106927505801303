import styled, { keyframes } from 'styled-typed'

const FadeInUpTopContainer = keyframes`
  from {
    transform: translateY(70%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`
const FadeInUpBottomContainer = keyframes`
  from {
    transform: translateY(30%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`
export const Container = styled.div`
    position: relative;
    margin-bottom: 3em;
`
export const PageTitle = styled.h1`
    font-size: 2.5em;
    font-weight: normal;
    line-height: 100%;
    margin: 0;
`
export const PageHeading = styled.div`
    font-size: 1.25em;
    font-weight: bold;
    margin: 2.25em 0 0.8em 0;
`
export const TopContainer = styled.div`
    opacity: 0;
    animation: ${FadeInUpTopContainer} 0.5s ease-in;
    animation-fill-mode: forwards;
`
export const BottomContainer = styled.div`
    opacity: 0;
    animation: ${FadeInUpBottomContainer} 0.3s ease-in 1.5s;
    animation-fill-mode: forwards;
`
export const MessageContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media screen and (min-width: 992px) {
        flex-direction: row;
    }
`
export const MessageText = styled.div`
    word-break: break-word;

    @media screen and (min-width: 2060px) {
        margin-left: 2em;
    }
`
export const MessageLink = styled.div`
    text-decoration: underline;
    cursor: pointer;
    margin: 1em 0;

    @media screen and (min-width: 992px) {
        margin: 0 1em;
        min-width: 6.7em;
        margin-right: 2em;
    }
`
