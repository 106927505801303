import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { BaseRouteParams } from 'hocs'
import { ModalDialog, ModalDialogTitle } from 'uiComponents/popups/modal'
import { pathUtils } from 'utils/pathnameFormatter'
import { CODES_PATHS } from 'venue/bookingCodes/paths'
import { NavigationSection, Tab, TabRow } from 'uiComponents/navigation/tabs'

const AddCode: React.FC = ({ children }) => {
    const location = useLocation()
    const params = useParams<BaseRouteParams>()
    const history = useHistory()

    const closeAddCode = () => {
        const path = pathUtils.addQueryString(CODES_PATHS.fullPaths.poolsPage, location.search)

        history.push(pathUtils.populateParams(path, params))
    }

    const isActive = (path: string) => location.pathname.includes(path)
    const createOnClick = (path: string) => () => {
        const formattedPath = pathUtils.addQueryString(pathUtils.populateParams(path, params), location.search)

        history.push(formattedPath)
    }

    return (
        <ModalDialog onDismiss={closeAddCode} style={{ width: 576, overflow: 'unset' }}>
            <ModalDialogTitle>Add discount codes</ModalDialogTitle>
            <TabRow>
                <Tab
                    className={classNames({
                        active: isActive('/add/form'),
                    })}
                    onClick={createOnClick(CODES_PATHS.fullPaths.poolsPageAddByForm)}
                >
                    Create code
                </Tab>
                <Tab
                    className={classNames({
                        active: isActive('/add/csv'),
                    })}
                    onClick={createOnClick(CODES_PATHS.fullPaths.poolsPageAddByUpload)}
                >
                    Upload CSV
                </Tab>
            </TabRow>
            <NavigationSection>{children}</NavigationSection>
        </ModalDialog>
    )
}

export default AddCode
