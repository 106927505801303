import React, { memo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BaseRouteParams } from 'hocs'
import styled from 'styled-typed'
import { ActionButtonA } from 'uiComponents/buttons'
import tapIconReverse from 'images/tap-icon-reverse.svg'
import conviousLogo from 'images/convious-logo.svg'
import Svg from 'svg'
import { useHasFeature } from '../../../features'
import RequestMapDialog from './customOverlayRequest'
import welcomeImage from '../assets/welcome.png'
import { Body, Headline } from 'uiComponents/typography'

const Container = styled.div`
    height: 660px;
    max-width: 1200px;
    background: #ffffff;
    display: flex;
    flex-direction: row;
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 67px;
`

const Buttons = styled.div`
    margin-top: 63px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
`

const Content = styled.div`
    padding: 100px 84px;
`

const TapLogo = styled(Svg).attrs((props) => ({ src: tapIconReverse }))`
    width: 30px;
    height: 30px;
    margin-right: 10px;
    color: ${(props) => props.theme.colors.textDark};
`

const ConviousLogo = styled(Svg).attrs((props) => ({ src: conviousLogo }))`
    height: 12.4px;
    margin-left: 8px;
    color: ${(props) => props.theme.colors.textDark};
`

const Image = styled.img.attrs((props) => ({ src: welcomeImage }))`
    flex: 1;
`

const ParkMapWelcomePage = () => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const [requestMapDialogVisible, setRequestMapDialogVisible] = useState<boolean>(false)
    /*
        to activate button for specific partner go to BO,
        search for parkmap_paid component and
        set enable value for selected partner
     */
    const displayBuildButton = useHasFeature('parkmap_paid')

    return (
        <>
            {requestMapDialogVisible && <RequestMapDialog onCancel={() => setRequestMapDialogVisible(false)} />}
            <Container>
                <Content>
                    <Header>
                        <TapLogo />
                        <Headline size={5} style={{ margin: 0 }}>
                            Tap from
                        </Headline>
                        <ConviousLogo />
                    </Header>
                    <Headline size={1} style={{ margin: 0 }} bold>
                        Better Experiences
                    </Headline>
                    <Headline size={5} style={{ margin: '0 0 32px 0' }}>
                        With custom map
                    </Headline>
                    <Body size={1} weight="regular">
                        Give your visitors the best experience and display your map on their phones so they can easily
                        navigate around your attraction.
                        <br />
                        This module is designed in the map configurator, where you can fully customise and manage your
                        map settings and main points of interest.
                        <br />
                        To enable this feature, please click on{' '}
                        <i>
                            <b>Request a custom map</b>
                        </i>{' '}
                        and we will get in touch with you.
                    </Body>
                    <Buttons>
                        {displayBuildButton && (
                            <ActionButtonA href={`/account/${accountSlug}/venue/park_map/create/details`} size="large">
                                Start building
                            </ActionButtonA>
                        )}
                        <ActionButtonA onClick={() => setRequestMapDialogVisible(true)} secondary size="large">
                            Request a custom map
                        </ActionButtonA>
                    </Buttons>
                </Content>
                <Image />
            </Container>
        </>
    )
}

export default memo(ParkMapWelcomePage)
