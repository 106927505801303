import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import './expandablePanel.scss'

type ExpandablePanelProps = {
    collapsedSummary: string | JSX.Element
    expandedSummary: string | JSX.Element
    isExpanded?: boolean
}

export const ExpandablePanel: FC<PropsWithChildren<ExpandablePanelProps>> = ({
    collapsedSummary,
    expandedSummary,
    isExpanded,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (isExpanded !== undefined) {
            setIsOpen(isExpanded)
        }
    }, [isExpanded])

    const toggleOpen = useCallback(() => {
        setIsOpen(!isOpen)
    }, [])

    return (
        <details open={isOpen} onToggle={toggleOpen} className="expandable-panel">
            <summary>{isOpen ? expandedSummary : collapsedSummary}</summary>
            <div>{children}</div>
        </details>
    )
}
