import React from 'react'
import { withFeatures } from 'features'
import { withPermission } from 'admin/hocs'
import { getProductOptions, formatFlatOptions } from 'uiComponents/filter/filterHelpers'
import { MetaData, FilterCategory } from 'uiComponents/filter/schema'
import Filter from 'uiComponents/filter'
import { staticMetadaCategories, applicableFilters } from './config'
import { useGetFullProductsAndArticlesNestedTree } from 'products/redux'
import { useGetAccountLocalesQuery, useGetAccountLocationsQuery, useGetResellerListQuery } from 'orders/reduxQueries'
import { ResellerResponseItem } from 'channels/channelsService'

interface OrdersFilterProps {
    page: 'orders' | 'tickets'
    accountSlug: string
    hasFeature: (feature: string, accountSlug: string) => boolean
    hasPermission: (permission: string, accountSlug: string) => boolean
}

const OrdersDynamicFilter: React.FC<OrdersFilterProps> = (props) => {
    const isAdmin = props.hasPermission('partner_admin', props.accountSlug)
    const { products, isLoading } = useGetFullProductsAndArticlesNestedTree()
    const locationQuery = useGetAccountLocationsQuery({ accountSlug: props.accountSlug })
    const localesQuery = useGetAccountLocalesQuery({ accountSlug: props.accountSlug })
    const channelsQuery = useGetResellerListQuery({ accountSlug: props.accountSlug, query: '?include_archived=true' })

    const data = React.useMemo(() => {
        const productCategory = {
            category: 'products' as FilterCategory,
            options: getProductOptions(products),
        }

        const locationCategory = {
            category: 'location_id' as FilterCategory,
            options: formatFlatOptions(
                'location_id',
                (locationQuery.data ?? []).map((l) => ({
                    name: l.name,
                    slug: l.uuid,
                })),
            ),
        }

        const languageCategory = {
            category: 'language' as FilterCategory,
            options: formatFlatOptions(
                'language',
                (localesQuery.data?.locales ?? []).map((l) => ({ name: l.name, slug: l.code })),
            ),
        }

        const channelsCategory = ((resellers: ResellerResponseItem[]) => {
            const mapResellerOptions = (resellers: ResellerResponseItem[]) => {
                return resellers.map((r) => ({
                    name: r.name,
                    slug: r.resellerId,
                    category: 'channels' as FilterCategory,
                    parents: ['all_resellers'],
                    children: [],
                    leafNode: true,
                }))
            }

            const options =
                resellers.length > 0
                    ? [
                          {
                              name: 'Direct Sales',
                              slug: 'direct_sales',
                              category: 'channels' as FilterCategory,
                              parents: [],
                              children: [],
                              leafNode: true,
                          },
                          {
                              name: 'All resellers',
                              slug: 'all_resellers',
                              category: 'channels' as FilterCategory,
                              parents: [],
                              children: mapResellerOptions(resellers),
                              leafNode: false,
                          },
                      ]
                    : []
            return {
                category: 'channels' as FilterCategory,
                options,
            }
        })(isAdmin && channelsQuery.data ? channelsQuery.data : [])

        const categories: MetaData[] = [
            ...staticMetadaCategories,
            productCategory,
            locationCategory,
            languageCategory,
            channelsCategory,
        ]

        const categoriesInOrder: MetaData[] = []
        applicableFilters[props.page].forEach((f) => {
            const category = categories.find((c) => c.category === f)
            if (!!category) {
                categoriesInOrder.push(category)
            }
        })
        return categoriesInOrder
    }, [products, locationQuery.data, localesQuery.data, channelsQuery.data, isAdmin, props.page])

    const getApplicableFilters = React.useCallback(() => {
        const filtersToHide: FilterCategory[] = []
        if (
            !props.hasPermission('partner_admin', props.accountSlug) &&
            !props.hasFeature('ResellersFeature', props.accountSlug)
        ) {
            filtersToHide.push('channels')
        }
        return applicableFilters[props.page].filter((f) => filtersToHide.indexOf(f) < 0)
    }, [props.hasPermission, props.hasFeature, props.page])

    return (
        <Filter
            accountSlug={props.accountSlug}
            compact
            metaData={data}
            applicableFilters={getApplicableFilters()}
            standaloneFilters={[]}
            singleSelectCategories={[]}
            loading={locationQuery.isLoading || localesQuery.isLoading || channelsQuery.isLoading || isLoading}
        />
    )
}

export default withPermission(withFeatures(OrdersDynamicFilter))
