import * as React from 'react'
import styled, { keyframes, css, FlattenInterpolation } from 'styled-typed'
import { CloseButtonElement } from 'uiComponents/buttons/closeButton'

type ArrowPosition = 'left' | 'right' | 'top' | 'bottom' | 'none'

const arrowRight = css`
    &:after,
    &:before {
        left: 100%;
        top: 50%;
    }
    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-left-color: #ffffff;
        border-width: 25px;
        margin-top: -25px;
    }
    &:before {
        border-color: rgba(24, 245, 157, 0);
        border-left-color: ${(props) => props.theme.colors.boyBlue};
        border-width: 28px;
        margin-top: -28px;
    }
`

const arrowLeft = css`
    &:after,
    &:before {
        right: 100%;
        top: 50%;
    }
    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #ffffff;
        border-width: 25px;
        margin-top: -25px;
    }
    &:before {
        border-color: rgba(255, 255, 255, 0);
        border-right-color: ${(props) => props.theme.colors.boyBlue};
        border-width: 28px;
        margin-top: -28px;
    }
`
const arrowTop = css`
    &:after,
    &:before {
        bottom: 100%;
        left: 50%;
    }
    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 25px;
        margin-left: -25px;
    }
    &:before {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: ${(props) => props.theme.colors.boyBlue};
        border-width: 28px;
        margin-left: -28px;
    }
`
const arrowBottom = css`
    &:after,
    &:before {
        top: 100%;
        left: 50%;
    }
    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #ffffff;
        border-width: 25px;
        margin-left: -25px;
    }
    &:before {
        border-color: rgba(24, 245, 157, 0);
        border-top-color: ${(props) => props.theme.colors.boyBlue};
        border-width: 28px;
        margin-left: -28px;
    }
`

const arrows: { [key in ArrowPosition]: FlattenInterpolation<any> | string } = {
    left: arrowLeft,
    right: arrowRight,
    top: arrowTop,
    bottom: arrowBottom,
    none: '',
}

const appear = keyframes`
  from { transform: scale(0); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
`

interface PopoverBoxProps {
    arrow: ArrowPosition
    width?: string
    closeable?: boolean
}
const PopoverBox = styled.div<PopoverBoxProps>`
    position: relative;
    background: #ffffff;
    border: 2px solid ${(props) => props.theme.colors.boyBlue};
    border-radius: 0.8em;
    width: ${(props) => (props.width ? props.width : '26em')};
    color: ${(props) => props.theme.colors.textDark};
    z-index: 100;
    animation: ${appear} 0.4s ease-in-out;
    animation-fill-mode: forwards;
    padding: 1em;
    padding-top: ${(props) => (props.closeable ? '1.5em' : '1em')};

    ${(props) =>
        props.arrow !== 'none'
            ? ` &:after, &:before {
         border: solid transparent;
         content: " ";
         height: 0;
         width: 0;
         position: absolute;
         pointer-events: none;
      }`
            : ''}
    ${(props) => arrows[props.arrow || 'none']}

  & sup {
        top: -0.1em;
        font-size: 1.3em;
    }
`

const CloseWrapper = styled.div`
    position: absolute;
    top: 0.3em;
    right: 0.3em;
`

interface PopoverProps {
    children: React.ReactNode
    className?: string
    onCloseClick?: () => void
}

class Popover extends React.Component<PopoverProps & PopoverBoxProps> {
    render() {
        const { arrow = 'none', width, children, className, closeable, onCloseClick } = this.props

        return (
            <PopoverBox className={className} arrow={arrow} width={width} closeable={closeable}>
                {closeable && onCloseClick && (
                    <CloseWrapper>
                        <CloseButtonElement onClick={onCloseClick} height={20} transparent />
                    </CloseWrapper>
                )}
                {children}
            </PopoverBox>
        )
    }
}

export default Popover
