import * as React from 'react'
import styled from 'styled-typed'
import { ActionButton, ActionButtonProps } from 'uiComponents/buttons'
import { ModalDialog, ModalDialogTitle } from 'uiComponents/popups/modal'
import { ChartDataLoader } from 'uiComponents/loaders'

export const Text = styled.div`
    line-height: 1.75em;
    margin-bottom: 1.5em;
`
const Container = styled.div`
    width: 25em;
    word-break: break-word;
`
const NavigationSection = styled.div`
    font-size: 0.875em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2.5em;
`

interface DialogProps {
    children?: React.ReactNode
    text?: string | React.ReactNode
    title: string
    buttonText: string
    destructive?: boolean
    loading?: boolean
    className?: string
    onCancel?: () => void
    onConfirm: () => void
}

export function ConfirmationDialog(props: DialogProps) {
    const cancelButtonProps: Partial<ActionButtonProps> = props.destructive ? { kind: 'default' } : { secondary: true }

    return (
        <ModalDialog onDismiss={props.onCancel} interactive fromTop="20%" className={props.className}>
            {props.loading && <ChartDataLoader />}
            <Container>
                <ModalDialogTitle>{props.title}</ModalDialogTitle>
                {props.text && <Text>{props.text}</Text>}
                {props.children}
                <NavigationSection>
                    {props.onCancel && (
                        <ActionButton size="large" {...cancelButtonProps} onClick={props.onCancel}>
                            Cancel
                        </ActionButton>
                    )}
                    <ActionButton
                        data-testid="confirmation-dialog-confirm-button"
                        size="large"
                        secondary={!!props.destructive}
                        kind={props.destructive ? 'destructive' : 'default'}
                        style={{ marginLeft: '1em' }}
                        onClick={props.onConfirm}
                    >
                        {props.buttonText}
                    </ActionButton>
                </NavigationSection>
            </Container>
        </ModalDialog>
    )
}
