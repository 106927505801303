import { DateRange } from 'dateRanges'
import { Filters } from 'uiComponents/filter/schema'

export type RevenuePageMetricType = 'revenue' | 'transactions' | 'products_sold'
export type ReportTimeIdentifierType = 'visit_date' | 'purchase_date'

export interface DataPoint {
    timestamp: string
    value: number
}

export interface ExportsDataPoint {
    timestamp: string
    value: number | string
}

export const dummyTimeSeries = {
    label: null,
    points: [] as DataPoint[],
}

export interface TimeSeries {
    label: string | null
    points: DataPoint[]
}

export interface RawDataSeries {
    label: string | null
    points: ExportsDataPoint[]
}

export interface DataSeries {
    columns: string[]
    data: (string | number)[] | (string | number)[][]
}

interface DataSeriesStats {
    dataSeries: DataSeries
}

export interface DataTotal {
    name: string
    value: number
}

export interface ComparisonTotal {
    value: string
    change: 'higher_than_usual' | 'lower_than_usual' | 'as_usual' | 'not_enough_data' | string
}

export interface TimeseriesStats {
    timeSeries: TimeSeries[]
}

export interface Stats extends TimeseriesStats {
    totals: DataTotal[]
}

export interface TotalStats {
    totals: DataTotal[]
}

interface ComparisonTotalStats {
    totals: ComparisonTotal
}

interface GeneralStats {
    stats: Stats
}

export interface GeneralDataSeriesStats {
    stats: DataSeriesStats
    totals: DataSeriesStats
}

export interface GroupedStats {
    paymentMethod: TotalStats
    deviceType: TotalStats
    acquisitionChannel: TotalStats
    itemName: TotalStats
    clickPerCountry: TotalStats
    topProviders: TotalStats
    customGroupBy: TotalStats
}

export interface SalesRevenueGroupedStats {
    salesPaymentMethod: TotalStats
    salesDeviceType: TotalStats
    salesAcquisitionChannel: TotalStats
    salesItemName: TotalStats
    salesEngageTool: TotalStats
    revenuePaymentMethod: TotalStats
    revenueDeviceType: TotalStats
    revenueAcquisitionChannel: TotalStats
    revenueItemName: TotalStats
    revenueEngageTool: TotalStats
}

export interface SalesRevenueTimeseriesStats {
    salesTotals: Stats
    salesPerItem: TimeseriesStats
    revenueTotals: TimeseriesStats
    revenuePerItem: TimeseriesStats
    capacity: TimeseriesStats
}

export interface GroupedTrafficStats {
    returningVisitors: TotalStats
    deviceType: TotalStats
    acquisitionChannel: TotalStats
    weekday: TotalStats
    hour: TotalStats
}

export interface DataTable {
    columns: string[]
    data: string[][]
}

export interface BarcodesStats {
    timeSeries: TimeSeries[]
    totals: DataTotal[]
}

export interface BarcodesChartData {
    allBarcodes: BarcodesStats
    redeemedBarcodes: BarcodesStats
    allBarcodesRevenue: BarcodesStats
    redeemedBarcodesRevenue: BarcodesStats
}

export interface OverviewTotals {
    amount: number
    avgPrice: number
    avgPricePaid: number
    revenue: number
    collectedRevenue: number
    refundedAmount: number
    refundedMoney: number
    reservationsBooked: number
}

export interface OverviewDataItem extends OverviewTotals {
    ticketType: string
}

export interface StatsOverviewData {
    dataSeries: OverviewDataItem[]
    totals: OverviewTotals
}

export interface StatsSingleItems {
    avgCartSize: TotalStats
    avgCartValue: TotalStats
    checkoutConvRate: TotalStats
    websiteConvRate: TotalStats
    emailRevenue: TotalStats
    emailOrders: TotalStats
    emailAvgOrderValue: TotalStats
    revenue: TotalStats
    tickets: TotalStats
    avgTicketPrice: TotalStats
    orders: TotalStats
}

interface EmailsDataItem {
    number: number
    share: number
}

export interface EmailsData {
    emailName: string
    emailsSent: number
    emailsDelivered: EmailsDataItem
    emailsOpened: EmailsDataItem
    emailsClicked: EmailsDataItem
    emailsBounced: EmailsDataItem
    emailsUnsubscribed: EmailsDataItem
    emailsConverted: EmailsDataItem
}

export interface DashboardPageStats {
    revenue: ComparisonTotalStats
    orders: ComparisonTotalStats
    avgOrderValue: ComparisonTotalStats
}

export interface ChartData {
    value: number
    name: string
}

export interface ForecastSingleItemStats {
    comparisonToday: ComparisonTotalStats
    comparisonTomorrow: ComparisonTotalStats
    comparisonNextRange: ComparisonTotalStats
    todayDelta: DataSeriesStats
    tomorrowDelta: DataSeriesStats
    nextRangeDelta: DataSeriesStats
}

export interface ForecastTimeSeriesStats {
    actualSales: TimeseriesStats
    actualSalesSoFar: TimeseriesStats
    estimateSales: TimeseriesStats
    estimateLowerBound: TimeseriesStats
    estimateUpperBound: TimeseriesStats
    forecastValidation: DataSeriesStats
    weatherIcon: DataSeriesStats
    weatherTemperature: DataSeriesStats
    weatherRainChance: DataSeriesStats
    weatherRainIntensity: DataSeriesStats
}

export interface RevenueWithDiscountsStats {
    grouppedData: Stats
    totals: Stats
    discounts: TotalStats
    collected: TotalStats
    reseller: TotalStats
}

export interface SalesTimeSeriesStats {
    salesGroupped: Stats
    salesTotals: Stats
}

interface OverviewSingleItemStats {
    visitors: ComparisonTotalStats
    revenue: ComparisonTotalStats
    orders: ComparisonTotalStats
}

interface CustomersSingleItemStats {
    today: ComparisonTotalStats
    tomorrow: ComparisonTotalStats
    nextWeek: ComparisonTotalStats
    selectedPeriod: ComparisonTotalStats
}

interface RevenueSingleItemStats {
    avgCartSize: ComparisonTotalStats
    revenue: ComparisonTotalStats
    avgCartValue: ComparisonTotalStats
    checkoutConvRate: ComparisonTotalStats
    websiteConvRate: ComparisonTotalStats
}

interface PricingSingleItemStats {
    ticketsSold: ComparisonTotalStats
    avgPrice: ComparisonTotalStats
    avgLeadTime: ComparisonTotalStats
}

interface RevenueTimeseriesStats {
    groupped_revenue: TimeseriesStats
    groupped_transactions: TimeseriesStats
    groupped_products_sold: TimeseriesStats
    revenue: Stats
    transactions: Stats
    products_sold: Stats
}

interface PricingTimeseriesStats {
    avgPrice: TimeseriesStats
    minPrice: TimeseriesStats
    maxPrice: TimeseriesStats
    actualSales: TimeseriesStats
}

interface PricingDistributionStats {
    priceDistribution: TotalStats
    revenueShare: TotalStats
}

interface CustomersCapacityMetaData {
    inventoryFilters: DataSeriesStats
    inventoryMetadata: DataSeriesStats
}

interface DiscountCodesUsedTimeseries {
    codesUsed: TimeseriesStats
    codesUsedTotals: TimeseriesStats
}

interface DiscountCodesSingleDataItems {
    codesUsed: ComparisonTotalStats
    revenue: ComparisonTotalStats
    cartValue: ComparisonTotalStats
    avgDiscount: ComparisonTotalStats
}

interface CRMSingleDataItems {
    profiles: ComparisonTotalStats
    withMarketingOptIn: ComparisonTotalStats
    withMarketingOptInShare: ComparisonTotalStats
    valuePerProfile: ComparisonTotalStats
    valuePerEmail: ComparisonTotalStats
}

interface CRMTimeseries {
    profiles: TimeseriesStats
}

export type TrendChange = 'not_enough_data' | 'higher_than_usual' | 'lower_than_usual' | 'as_usual'
export type TrendPeriod = 'today and yesterday' | 'today so far'
export type TrendValueType = 'currency' | 'number' | 'percent'

export interface OverviewTrendsItemData {
    metric: string
    name: string
    value: string
    change: TrendChange
    diff: string
    period: TrendPeriod
    filters: string
    valueType: TrendValueType
    digits: string
    timeFound: string
    tooltipStart: string
}

export interface NotificationCampaignsTimeseriesStats {
    sent: TimeseriesStats
    opened: TimeseriesStats
    clicked: TimeseriesStats
}

interface NotificationCampaignMetricStats {
    appUsers: ComparisonTotalStats
    notificationsSent: ComparisonTotalStats
    notificationsOpened: ComparisonTotalStats
    notificationsClicked: ComparisonTotalStats
}

interface CheckoutFunnelMetricStats {
    websiteSessions: ComparisonTotalStats
    checkoutSessions: ComparisonTotalStats
    checkoutVisitRate: ComparisonTotalStats
    checkoutConversionRate: ComparisonTotalStats
    websiteConversionRate: ComparisonTotalStats
}

export interface NotificationCampaignTableItem {
    campaign_name: string
    sent: string
    received: string
    opened: string
    clicked: string
    opt_out: string
    p_received: number
    p_opened: number
    p_clicked: number
    p_opt_out: number
}

export interface CheckoutFunnelScreenOverviewStats {
    checkoutScreenTimeSpent: ComparisonTotalStats
    checkoutScreenReachRatio: ComparisonTotalStats
    checkoutScreenDropRatio: ComparisonTotalStats
    checkoutScreenConversionRate: ComparisonTotalStats
}

export interface CheckoutFunnelScreenTimeSeries {
    checkoutScreenTimeSpentTimeSerie: TimeseriesStats
    checkoutScreenReachRatioTimeSerie: TimeseriesStats
    checkoutScreenDropRatioTimeSerie: TimeseriesStats
    checkoutScreenConversionRateTimeSerie: TimeseriesStats
}

export interface CheckoutFunnelStats {
    checkoutReach: TotalStats & TimeseriesStats
    checkoutDropRatio: TotalStats
    checkoutReachRatio: TotalStats & TimeseriesStats
}

export interface CheckoutFunnelDefaultFilters {
    checkoutFunnelDefaultScreen: DataSeriesStats
    checkoutFunnelProductList: DataSeriesStats
}

export type StatsResponse = GeneralStats &
    SalesRevenueTimeseriesStats &
    SalesRevenueGroupedStats &
    GroupedTrafficStats &
    GroupedStats &
    StatsSingleItems &
    DashboardPageStats &
    RevenueWithDiscountsStats &
    SalesTimeSeriesStats &
    ForecastTimeSeriesStats &
    OverviewSingleItemStats &
    GeneralDataSeriesStats &
    RevenueSingleItemStats &
    RevenueTimeseriesStats &
    CustomersSingleItemStats &
    CustomersCapacityMetaData &
    PricingSingleItemStats &
    PricingTimeseriesStats &
    PricingDistributionStats &
    DiscountCodesUsedTimeseries &
    DiscountCodesSingleDataItems &
    NotificationCampaignsTimeseriesStats &
    CRMSingleDataItems &
    CRMTimeseries &
    NotificationCampaignMetricStats &
    CheckoutFunnelMetricStats &
    CheckoutFunnelScreenOverviewStats &
    CheckoutFunnelScreenTimeSeries &
    CheckoutFunnelStats &
    CheckoutFunnelDefaultFilters

export interface DownloadHeader {
    slug: string
    label: string
    width?: number
}

export interface DownloadData {
    name: string
    headers: DownloadHeader[]
    values: (string | number)[][]
}

export const dummyDownloadData: DownloadData = {
    name: '',
    headers: [],
    values: [],
}

export const dummyDataSeries: DataSeries = {
    columns: [],
    data: [],
}

export type FilterQueryResult = {
    dateRange: DateRange
    filters: Filters[]
    [query: string]: Filters[] | DateRange
}
