import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { LoaderWrapper } from 'uiComponents/loaders'
import { useSelectedParkMapContext } from 'venue/parkMap/context/selectedParkMap'
import { Category } from 'venue/parkMap/models'
import { useCreateCategoryMutation, useParkMapCategoriesQuery } from 'venue/parkMap/reduxQuery'
import useExpandedCategory from '../../../hooks/useExpandedCategory'

import CategoryForm from './categoryForm'

const CreateCategory = () => {
    const theme = useTheme()
    const { selectedParkMap } = useSelectedParkMapContext()
    const [createCategory, { isLoading }] = useCreateCategoryMutation()
    const { data } = useParkMapCategoriesQuery({ locationId: selectedParkMap?.locationId })
    const lastPosition = data?.length ? Math.max(...data.map((cat) => cat.position)) + 1 : 0
    const history = useHistory()
    const { saveExpandedIndex } = useExpandedCategory()

    const category = (): Category | undefined => {
        if (selectedParkMap) {
            return {
                id: '',
                slug: selectedParkMap.slug,
                locationId: selectedParkMap.locationId,
                icon: 'icon-1',
                iconSvg: 'icon-1-svg',
                color: theme.colors.convious,
                position: lastPosition,
                content: [
                    {
                        name: '',
                        locale: 'en',
                        default: true,
                    },
                ],
            }
        }

        return undefined
    }

    return (
        <LoaderWrapper loading={isLoading}>
            <CategoryForm
                pageTitle="Add category"
                data={category()}
                onSubmit={(data) => {
                    createCategory({ category: data })
                        .unwrap()
                        .then(() => history.goBack())
                    saveExpandedIndex(lastPosition - 1 || 0)
                }}
            />
        </LoaderWrapper>
    )
}

export default CreateCategory
