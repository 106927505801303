import { HttpService } from 'http/httpService'
import { LoggingService, EventType, ActionEventData } from 'http/loggingService'
import { InventoryPool } from 'admin/articleService'
import { ProductData } from 'products/pricing/pricingService'
import { generateRandomChars } from 'utils'
var slugify = require('slugify')

export interface OptionItem {
    id: string
    name: string
    priority: number | null
    pricingId: string
    price: string | number
    numberOfItems?: number
}

export interface OptionGroup {
    uuid: string
    name: string
    priority: number | null
    optionItems: OptionItem[]
}

export type EditorType = 'checkbox' | 'dropdown' | 'multiselect'

interface Translations {
    key: string
    text: {
        [lang: string]: string
    }
}

export interface OptionGroupDetails extends OptionGroup {
    minItems: number | null
    maxItems: number | null
    pricingId: string
    editorType: EditorType
    mandatory: boolean
    groupWithMainItem?: boolean
    nameTranslations: Translations
    descriptionTranslations?: Translations | null
}

export interface OptionItemDetails extends OptionItem {
    vat?: number | null
    taxConfigurationUuid?: string | null
    depositFee: number | null
    pricingId: string
    usedResources: InventoryPool[]
    nameTranslations: Translations
}

export function getCopyKey(accountSlug: string, name: string, insert: string = '') {
    const readable = slugify(name, {
        replacement: '_',
        remove: /[*+~.()'"!:@]/g,
        lower: true,
    })
    const random = generateRandomChars()
    const insertString = insert ? `${insert}_` : ''
    return `${accountSlug}.${readable}_${insertString}${random}`
}

export function sortByPriority(a: OptionGroup | OptionItem, b: OptionGroup | OptionItem) {
    if (a.priority === null || b.priority === null) {
        return 0
    }
    return a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
}

export function getOptionItemPrice(pricingId: string, pricing: ProductData[]) {
    const priceData = pricing.find((p) => p.productId === pricingId)
    return priceData?.originalPrice || 0
}

export class OptionsService {
    constructor(
        private httpService: HttpService,
        private loggingService: LoggingService,
        private backofficeEndpoint: string,
    ) {}

    async getOptionGroups(accountSlug: string): Promise<OptionGroup[]> {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/article_options/`,
            {
                method: 'GET',
            },
        )
        return await response.json()
    }

    async getOptionGroupDetails(accountSlug: string, uuid: string): Promise<OptionGroupDetails> {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/article_options/${uuid}/`,
            {
                method: 'GET',
            },
        )
        return await response.json()
    }

    async updateOptionGroup(
        accountSlug: string,
        payload: OptionGroupDetails,
        uuid: string,
    ): Promise<OptionGroupDetails> {
        const logEventType: EventType = 'option_group_updated'
        const logEventData: ActionEventData = {
            category: 'option_groups',
            payload: { accountSlug, payload, uuid },
        }

        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/article_options/${uuid}/`,
            {
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)

        return await response.json()
    }

    async createOptionGroup(accountSlug: string, payload: OptionGroupDetails): Promise<OptionGroupDetails> {
        const logEventType: EventType = 'option_group_created'
        const logEventData: ActionEventData = {
            category: 'option_groups',
            payload: { accountSlug, payload },
        }

        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/article_options/`,
            {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)

        return await response.json()
    }

    async getOptionItemDetails(accountSlug: string, id: string, groupUuid: string): Promise<OptionItemDetails> {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/article_options/${groupUuid}/items/${id}/`,
            {
                method: 'GET',
            },
        )
        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        return await response.json()
    }

    async updateOptionItem(
        accountSlug: string,
        payload: OptionItemDetails,
        itemId: string,
        groupUuid: string,
    ): Promise<OptionItemDetails> {
        const logEventType: EventType = 'option_item_updated'
        const logEventData: ActionEventData = {
            category: 'option_groups',
            payload: { accountSlug, payload, itemId, groupUuid },
        }

        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/article_options/${groupUuid}/items/${itemId}/`,
            {
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)

        return await response.json()
    }

    async createOptionItem(
        accountSlug: string,
        payload: OptionItemDetails,
        groupUuid: string,
    ): Promise<OptionItemDetails> {
        const logEventType: EventType = 'option_item_created'
        const logEventData: ActionEventData = {
            category: 'option_groups',
            payload: { accountSlug, payload, groupUuid },
        }

        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/article_options/${groupUuid}/items/`,
            {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)

        return await response.json()
    }

    async updateOptionGroupPriority(optionGroupUuid: string, priority: number): Promise<void> {
        const logEventType: EventType = 'option_group_priority_updated'
        const logEventData: ActionEventData = {
            category: 'option_groups',
            payload: { optionGroupUuid, priority },
        }

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}api/v1/...`, {
            method: 'POST',
        })
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    async deleteOptionGroup(accountSlug: string, optionGroupUuid: string): Promise<void> {
        const logEventType: EventType = 'option_group_deleted'
        const logEventData: ActionEventData = {
            category: 'option_groups',
            payload: { optionGroupUuid },
        }

        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/article_options/${optionGroupUuid}/`,
            {
                method: 'DELETE',
            },
        )
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    async deleteOptionItem(accountSlug: string, optionItemId: string, groupUuid: string): Promise<void> {
        const logEventType: EventType = 'option_item_deleted'
        const logEventData: ActionEventData = {
            category: 'option_groups',
            payload: { optionItemId, groupUuid },
        }

        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/article_options/${groupUuid}/items/${optionItemId}/`,
            {
                method: 'DELETE',
            },
        )
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
    }
}
