import React, { useState, useEffect, useContext, useRef } from 'react'
import { usePrevious } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { DataTotal } from 'reports/schema'
import BarChart from 'uiComponents/charts/barChart'
import { format, startOfToday, addDays } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { ChartHeadline, ChartContainer } from 'uiComponents/charts/styleComponents'
import Infotip from 'uiComponents/infotip'
import { getTooltipRow, getTooltipHeader, getTooltipFooter } from 'reports/helpers'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import PresetDateRangeElement, { PresetType } from 'uiComponents/popups/presetsElement'
import { QueryConfig } from 'reports/queryGenerator'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation, QueryData } from 'navigation'
import { parseSearch, renderSearch } from 'navigation'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { convertTimeFormat, DateFormats } from 'utils'

const queryConfig: QueryConfig = {
    querySetName: 'PopularHoursChart',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'metric', type: 'StatsMetric' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'groupBy', type: 'String' },
        { name: 'filters', type: '[FilterDictionary]' },
    ],
    queries: [
        {
            name: 'customGroupBy',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'groupBy', 'filters'],
            customVariables: [],
            presetResult: 'totals',
        },
    ],
}

interface ChartsProps {
    accountSlug: string
    timeslots: boolean
    navigation: Navigation
    match: RouteMatch<{}>
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

function PopularHoursChart(props: ChartsProps) {
    const _isMounted = useRef(false)
    const _lastRequest = useRef<number>()
    const statsService = useContext(StatsServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [popularHoursData, setPopularHoursData] = useState<DataTotal[]>([])

    const rangeName = 'hoursChart_presetRange'
    const defaultRange = 'weekdays'
    const rangeOptions: PresetType[] = ['today', 'weekdays', 'weekends']

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevQuery: QueryData = usePrevious(props.navigation.query()) || {}
    useEffect(() => {
        _isMounted.current = true
        const newQuery = props.navigation.query()
        if (prevAccountSlug !== props.accountSlug || prevQuery[rangeName] !== newQuery[rangeName]) {
            getData(newQuery[rangeName] as PresetType)
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug, props.navigation.query()])

    const getFilters = (presetRange: PresetType) => {
        if (presetRange === 'weekends' || presetRange === 'weekdays') {
            return [{ attribute: 'weekday_type', values: [presetRange] }]
        }
        return null
    }

    const getData = async (presetRange: PresetType) => {
        setLoading(true)
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        const daysBack = presetRange === 'today' ? 0 : 14
        const dateFrom = format(addDays(startOfToday(), -daysBack), 'yyyy-MM-dd')
        const dateTo = format(startOfToday(), 'yyyy-MM-dd')
        try {
            const variables = {
                widget: props.accountSlug,
                metric: props.timeslots ? 'visitors' : 'website_sessions_averages',
                dateFrom,
                dateTo,
                groupBy: props.timeslots ? 'start_time' : 'hour',
                filters: getFilters(presetRange),
            }
            const data = await statsService.getStats(queryConfig, variables)

            if (_lastRequest.current !== requestTime) {
                return
            }

            const popularHours = data.customGroupBy.totals ? data.customGroupBy.totals : []
            if (_isMounted.current) {
                setLoading(false)
                setPopularHoursData(popularHours.filter((h) => h.name !== '--:--:--'))
            }
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Some charts could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }

    const chartTooltipFormatter = (params: TooltipParams[]) => {
        const axisValue = convertTimeFormat(params[0].axisValue, 'HH:mm:ss', DateFormats.SHORT_TIME)
        let tooltip = getTooltipHeader(axisValue)
        tooltip += getTooltipRow([
            `${params[0].marker} ${props.timeslots ? 'Visitors' : 'Website traffic'}:`,
            params[0].value,
        ])
        tooltip += getTooltipFooter()

        return tooltip
    }

    const xAxisTrafficFormatter = (value: string, index: number) => {
        if (index === 1) {
            return 'Midnight'
        } else if (index === 8) {
            return 'Morning'
        } else if (index === 15) {
            return 'Day'
        } else if (index === popularHoursData.length - 2) {
            return 'Evening'
        }
        return ''
    }

    const xAxisTimeslotsFormatter = (value: string, index: number) => {
        return convertTimeFormat(value, 'HH:mm:ss')
    }

    const popularHoursSeries = [
        {
            name: '',
            type: 'bar',
            barWidth: '60%',
            data: popularHoursData,
        },
    ]

    const todayRange = props.navigation.query()[rangeName] === 'today' || !props.navigation.query()[rangeName]

    const getTimeslotsSearch = () => {
        const queryItems = parseSearch(location.search)
        if (queryItems.dateRange) {
            delete queryItems.dateRange
        }
        if (queryItems.dateFrom) {
            delete queryItems.dateFrom
        }
        if (queryItems.dateTo) {
            delete queryItems.dateTo
        }
        if (queryItems.period) {
            delete queryItems.period
        }
        const query = renderSearch(queryItems)
        return `${query}&dateRange=today`
    }

    const linkToCustomers = `/account/${props.accountSlug}/reports/customers/${getTimeslotsSearch()}`
    const linkToTraffic = `/account/${props.accountSlug}/reports/traffic/${location.search}`

    const getTooltipContent = () => {
        if (props.timeslots) {
            return (
                <>
                    Here you see bookings per time slot
                    {todayRange ? '.' : ' over the last 2 weeks.'}
                    &nbsp;You can select a specific date-range within your&nbsp;
                    <span className="link">
                        <UnstyledLink to={linkToCustomers}>Customers report</UnstyledLink>
                    </span>
                </>
            )
        } else {
            return (
                <>
                    {todayRange ? 'W' : 'Usual w'}ebsite traffic per hour
                    {todayRange ? '.' : ' over the last 2 weeks.'}
                    &nbsp;More details:&nbsp;
                    <span className="link">
                        <UnstyledLink to={linkToTraffic}>Traffic report</UnstyledLink>
                    </span>
                </>
            )
        }
    }

    return (
        <ChartContainer className="clickable-title">
            {loading && <ChartDataLoader />}
            <ChartHeadline size={4}>
                <span>
                    <UnstyledLink to={props.timeslots ? linkToCustomers : linkToTraffic}>Popular hours</UnstyledLink>
                </span>
                <Infotip pointer="right" fixedMaxSize maxWidth="25em">
                    {getTooltipContent()}
                </Infotip>
                <PresetDateRangeElement presetName={rangeName} default={defaultRange} options={rangeOptions} />
            </ChartHeadline>
            <BarChart
                series={popularHoursSeries}
                chartHeight="350px"
                gridBottom={20}
                gridLeft={45}
                gridRight={10}
                gridTop={40}
                loading={loading}
                tooltipFormatter={chartTooltipFormatter}
                xAxisInterval={props.timeslots ? 'auto' : undefined}
                xAxisFormatter={props.timeslots ? xAxisTimeslotsFormatter : xAxisTrafficFormatter}
            />
        </ChartContainer>
    )
}

export default withNavigation(PopularHoursChart)
