import styled from 'styled-typed'
import { Text } from 'uiComponents/typography'

export const ForgotPasswordContainer = styled.div`
    font-size: 16px;
    font-family: ${(props) => props.theme.fonts.primary};
    color: ${(props) => props.theme.colors.textDark};
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
`

export const ForgotPasswordForm = styled.form`
    background: ${(props) => props.theme.colors.white};
    padding: 1.6875em 1.875em 0.875em;
    width: 21.25em;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
    margin-top: 0.625em;
`

export const ForgotPasswordHeader = styled.h1`
    font-size: 1.5625em;
    font-weight: normal;
    text-align: center;
    margin: 0 0 0.8em;
`

export const ValidationMessage = styled(Text).attrs((props) => ({
    status: 'error',
}))`
    font-size: 0.75em;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in;

    &.validation-message-visible {
        opacity: 1;
        visibility: visible;
    }
`
