import React from 'react'
import { History } from 'history'
import { ForgotPasswordContainer, ForgotPasswordForm, ForgotPasswordHeader, ValidationMessage } from 'auth/components'
import { IconTextInput } from 'uiComponents/input'
import { ActionButton } from 'uiComponents/buttons'

export interface PasswordResetFinishPageProps {
    history: History
    login: (username: string, password: string) => Promise<boolean>
    resetPassword: (token: string, password: string) => Promise<boolean>
    username: string
    token: string
}

export interface PasswordResetFinishPageState {
    message: string | null
    password: string
    password2: string
}

export class PasswordResetFinishPage extends React.Component<
    PasswordResetFinishPageProps,
    PasswordResetFinishPageState
> {
    state = {
        message: null,
        password: '',
        password2: '',
    }

    checkPassStrength = () => {
        if (this.state.password.length < 10) {
            this.setState({
                message: 'Password must be at least 10 characters long.',
            })
        } else {
            this.setState({ message: '' })
        }
    }

    resetPassword = async (e: React.FormEvent<HTMLElement>) => {
        e.preventDefault()
        try {
            if (this.state.password !== this.state.password2) {
                this.setState({ message: 'Passwords do not match.' })
                return
            }

            this.setState({ message: '' })
            const result = await this.props.resetPassword(this.props.token, this.state.password)
            if (!result) {
                this.setState({ message: 'The link you were given has expired.' })
                return
            }

            const loginResult = await this.props.login(this.props.username, this.state.password)
            if (loginResult) {
                this.props.history.push('/')
            } else {
                this.setState({
                    message: 'Oops! An unknown error occured. Please try again later.',
                })
            }
        } catch (e) {
            this.setState({
                message: 'Oops! An unknown error occured. Please try again later.',
            })
        }
    }

    render() {
        const canSubmit = this.state.password.length > 9 && this.state.password2

        return (
            <ForgotPasswordContainer>
                <ForgotPasswordForm noValidate onSubmit={this.resetPassword}>
                    <ForgotPasswordHeader>Set your password</ForgotPasswordHeader>
                    <ValidationMessage className={this.state.message ? 'validation-message-visible' : ''}>
                        {this.state.message}
                    </ValidationMessage>
                    <IconTextInput
                        block
                        status={this.state.message ? 'error' : 'normal'}
                        type="password"
                        placeholder="New password"
                        value={this.state.password}
                        onChange={(evt) => this.setState({ password: evt.target.value })}
                        onFinishTyping={this.checkPassStrength}
                        style={{ marginBottom: '.9em' }}
                    />
                    <IconTextInput
                        block
                        status={this.state.message ? 'error' : 'normal'}
                        type="password"
                        placeholder="Confirm new password"
                        value={this.state.password2}
                        onChange={(evt) => this.setState({ password2: evt.target.value })}
                        style={{ marginBottom: '1.875em' }}
                    />
                    <ActionButton
                        size="large"
                        type="submit"
                        disabled={!canSubmit}
                        style={{ margin: '1.0625em 0 0.75em' }}
                        block
                    >
                        Set password
                    </ActionButton>
                </ForgotPasswordForm>
            </ForgotPasswordContainer>
        )
    }
}

export default PasswordResetFinishPage
