import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled, { useTheme } from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: 'row';
    border-radius: 6px;
    padding: 10px;
    box-shadow: 2px 2px 4px ${(props) => props.theme.colors.border};
`

const Text = styled.div`
    font-size: 0.8em;
    line-height: 1.4em;
    flex-direction: column;
    inline-size: 500px;
`

interface InformationBoxProps {
    text: string
    background?: string
}

const InformationBox = ({ text, background }: InformationBoxProps) => {
    const theme = useTheme()

    return (
        <Container style={{ backgroundColor: background || theme.colors.background }}>
            <FontAwesomeIcon
                icon={faInfoCircle as IconProp}
                color={theme.colors.textDark}
                size="1x"
                style={{ marginRight: '0.75rem' }}
            />
            <Text>{text}</Text>
        </Container>
    )
}

export default InformationBox
