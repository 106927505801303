import React, { FC } from 'react'
import { Alert, Box, Button } from '@mui/material'
import { getSelectedOrder } from 'orders/orders/redux'
import { useAppSelector } from 'store/hooks'

export const FillDetailsAlert: FC = () => {
    const order = useAppSelector(getSelectedOrder)

    if (!order?.unfilledApf) {
        return null
    }

    return (
        <Box display="flex" justifyContent="center" width="100%" mt={2}>
            <Alert
                severity="warning"
                action={
                    <Button color="primary" size="small" href={order.unfilledApfUrl}>
                        Fill details
                    </Button>
                }
            >
                This order is missing personal information, <strong>fill details</strong> to complete the order and
                access tickets.
            </Alert>
        </Box>
    )
}
