import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import { History } from 'history'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { delay } from 'utils'
import { ActionButton, ActionButtonA } from 'uiComponents/buttons'
import { withNavigation } from 'hocs'
import { ButtonWrapper, TextWithHorizontalLine } from 'uiComponents/pageElements'
import { MessageKind } from 'uiComponents/messages'
import { ContainerBody, PageActions } from 'uiComponents/settingsContainer'
import { PageLoader } from 'uiComponents/loaders'
import { Col, Row } from 'uiComponents/flex'
import { FormItem, FormItemName } from 'uiComponents/form/formElements'
import { TimePicker, TextInput } from 'uiComponents/input'
import { OpeningTimesServiceContext } from './context'
import { OpeningTimesUnit, Exception } from './openingTimesService'
import { OpeningTimesExceptions } from './exceptionsList'
import Infotip from 'uiComponents/infotip'
import { isAfter, isBefore } from 'date-fns'
import { SecondaryText } from 'uiComponents/typography'
import { parseTime } from 'utils/dates'

const Section = styled(Col)`
    margin-bottom: 2em;
`

interface OpeningTimesPageParams {
    accountSlug: string
    id: string
}

interface OpeningTimesFormProps {
    accountSlug: string
    match: RouteMatch<OpeningTimesPageParams>
    history: History
    navigation: Navigation
    setActiveHeader: (header: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

function OpeningTimesForm(props: OpeningTimesFormProps) {
    const didMountRef = React.useRef(false)
    const openingTimesService = React.useContext(OpeningTimesServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [locationName, setLocationName] = useState<string>('')
    const [openingTime, setOpeningTime] = useState<string>('00:00')
    const [closingTime, setClosingTime] = useState<string>('00:00')
    const [sellUntilTime, setSellUntilTime] = useState<string | null>(null)
    const [overrides, setOverrides] = useState<Exception[]>([])
    const [openingTimeError, setOpeningTimeError] = useState<string | null>(null)
    const [closingTimeError, setClosingTimeError] = useState<string | null>(null)
    const [sellUntilTimeError, setSellUntilTimeError] = useState<string | null>(null)

    const fetchOpeningTimesUnitData = async (id: string) => {
        setLoading(true)
        try {
            const data = await openingTimesService.getOpeningTimesUnit(props.accountSlug, id)
            setDataToState(data)
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Could not fetch opening times, please try again later.',
            )
        } finally {
            setLoading(false)
        }
    }

    const setDataToState = (data: OpeningTimesUnit) => {
        setLocationName(data.locationName)
        setOpeningTime(data.openingTime || '00:00')
        setClosingTime(data.closingTime || '00:00')
        setSellUntilTime(data.sellUntilTime)
        setOverrides(data.exceptions)
    }

    const validateTimes = () => {
        setOpeningTimeError(
            isAfter(parseTime(openingTime), parseTime(closingTime)) ? 'Opening time must be before closing time' : null,
        )
        setClosingTimeError(
            isBefore(parseTime(closingTime), parseTime(openingTime)) ? 'Closing time must be after opening time' : null,
        )
        setSellUntilTimeError(
            sellUntilTime && isAfter(parseTime(sellUntilTime), parseTime(closingTime))
                ? 'Tickets sale should end before the closing time'
                : null,
        )
    }

    useEffect(validateTimes, [openingTime, closingTime, sellUntilTime])

    useEffect(() => {
        const timeSlotId = props.match.params.id
        props.setActiveHeader('Default Opening Times')
        if (!!timeSlotId) {
            fetchOpeningTimesUnitData(timeSlotId)
        }
    }, [])

    useEffect(() => {
        if (didMountRef.current) {
            props.history.push(`/account/${props.accountSlug}/venue/opening_times/locations`)
        } else {
            didMountRef.current = true
        }
    }, [props.accountSlug])

    const isDataValid = () => !openingTimeError && !closingTimeError && !sellUntilTimeError

    const onOpeningTimesSave = async () => {
        if (isDataValid()) {
            setLoading(true)
            const payload = {
                openingTime,
                closingTime,
                sellUntilTime,
            }
            try {
                await openingTimesService.updateOpeningTimesUnit(
                    props.accountSlug,
                    payload,
                    props.match.params.id,
                    locationName,
                )
                setLoading(false)
                props.history.push(`/account/${props.accountSlug}/venue/opening_times/locations`)
                props.replaceMessages('success', 'success', 'The opening times settings have been saved successfully.')
                await delay(3000)
                props.removeAllMessages()
            } catch {
                setLoading(false)
                props.replaceMessages(
                    'server_error',
                    'error',
                    'Oops! Could not save the opening times, please try again later.',
                )
            }
        } else {
            props.replaceMessages('validation_error', 'error', 'Please fix the validation errors below.')
        }
    }

    return (
        <div style={{ position: 'relative' }}>
            {loading && <PageLoader style={{ paddingBottom: '4em' }} />}
            {!loading && (
                <ContainerBody id="openingHours-form" style={{ flexDirection: 'column' }}>
                    <Row>
                        <Col span={6}>
                            <FormItem htmlFor="name">
                                <FormItemName style={{ display: 'flex' }}>Location name</FormItemName>
                                <TextInput value={locationName} block disabled />
                            </FormItem>
                        </Col>
                        <Col span={6} />
                    </Row>
                    <Row>
                        <Section span={6}>
                            <Row>
                                <Col span={6}>
                                    <FormItem htmlFor="venueOpens">
                                        <FormItemName>When venue opens</FormItemName>
                                        <TimePicker
                                            id="venueOpens"
                                            value={openingTime}
                                            onChange={(time) => time && setOpeningTime(time)}
                                            style={{ fontWeight: 'lighter' }}
                                            required={true}
                                            errorMessage={openingTimeError}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem htmlFor="venueCloses">
                                        <FormItemName>When venue closes</FormItemName>
                                        <TimePicker
                                            id="venueCloses"
                                            value={closingTime}
                                            onChange={(time) => time && setClosingTime(time)}
                                            style={{ fontWeight: 'lighter' }}
                                            required={true}
                                            errorMessage={closingTimeError}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Section>
                        <Section span={6}>
                            <FormItem htmlFor="ticketsSoldUntil">
                                <FormItemName>
                                    Until when tickets should be sold for the same day visit&nbsp;
                                    <SecondaryText>(optional)</SecondaryText>
                                    <Infotip pointer="right">
                                        Leave empty if tickets should be sold the whole day.
                                    </Infotip>
                                </FormItemName>
                                <TimePicker
                                    id="ticketsSoldUntil"
                                    value={sellUntilTime}
                                    onChange={(time) => {
                                        setSellUntilTime(time)
                                        validateTimes()
                                    }}
                                    style={{ fontWeight: 'lighter' }}
                                    errorMessage={sellUntilTimeError}
                                />
                            </FormItem>
                        </Section>
                    </Row>
                    <Col span={12}>
                        <ButtonWrapper>
                            <ActionButtonA
                                size="large"
                                href={`/account/${props.accountSlug}/venue/opening_times`}
                                secondary
                                style={{ marginRight: '1.5em' }}
                                kind="action"
                            >
                                Cancel
                            </ActionButtonA>
                            <ActionButton id="save-opening-times" size="large" onClick={onOpeningTimesSave}>
                                Save
                            </ActionButton>
                        </ButtonWrapper>
                    </Col>
                    <TextWithHorizontalLine text="Opening times exceptions" alignment="left" />
                    <OpeningTimesExceptions
                        accountSlug={props.accountSlug}
                        overrides={overrides}
                        openingTimesId={props.match.params.id}
                    />
                    <PageActions>
                        <ActionButtonA
                            id="new-exception"
                            size="large"
                            href={`/account/${props.accountSlug}/venue/opening_times/exception/new/default/${props.match.params.id}`}
                        >
                            Add exception
                        </ActionButtonA>
                    </PageActions>
                </ContainerBody>
            )}
        </div>
    )
}

export default withNavigation(OpeningTimesForm)
