import * as React from 'react'
import styled from 'styled-typed'
import { History } from 'history'
import { PricingService, Exception, DetailedException } from '../pricingService'
import { ArticleService as AdminArticleService, ArticleListItem } from 'admin/articleService'
import ExceptionFormRouter from './exceptionFormRouter'
import { StatsService } from 'http/statsService'
import { PageLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { ChannelsService } from 'channels/channelsService'

const Loader = styled.div`
    margin: 0 auto 8em;
`

interface ExceptionEditProps {
    id: string
    prototype?: boolean
    history: History
    pricingService: PricingService
    articleService: AdminArticleService
    channelsService: ChannelsService
    statsService: StatsService
    accountSlug: string
    setActiveSection: (section: string, header?: string) => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    className?: string
    hasPermission: (permission: string, accountSlug: string) => boolean
}

interface ExceptionEditState {
    exception: Exception | null
    exceptionProducts: ArticleListItem[]
    loading: boolean
}

class ExceptionEdit extends React.Component<ExceptionEditProps, ExceptionEditState> {
    constructor(props: ExceptionEditProps) {
        super(props)
        this.state = {
            exception: null,
            exceptionProducts: [],
            loading: true,
        }
    }

    componentDidMount() {
        this.fetchExceptionInformation(this.props.id)
    }

    fetchExceptionInformation = async (id: string) => {
        try {
            const productList = await this.props.articleService.listArticles(this.props.accountSlug)
            const exception = await this.props.pricingService.getException(id)
            if (!exception || Object.keys(exception).length === 0) {
                this.props.replaceTopMessages('server_error', 'error', 'Oops! The exception could not be found.')
                return
            }
            const productIds = exception.priceSettings.map((p) => p.productId)
            const exceptionProductList = productList.filter((a) => productIds.indexOf(a.id) > -1)
            this.setState({
                exception: this.typeException(exception),
                exceptionProducts: exceptionProductList,
            })
        } catch {
            this.props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Something went wrong. Please try again later.',
            )
        } finally {
            this.setState({ loading: false })
        }
    }

    typeException = (e: DetailedException): Exception => {
        return Object.assign(
            { ...e },
            {
                originalPriceAdjustment: e.originalPriceAdjustment === null ? null : Number(e.originalPriceAdjustment),
                minAcceptedPriceAdjustment:
                    e.minAcceptedPriceAdjustment === null ? null : Number(e.minAcceptedPriceAdjustment),
                targetAvgPriceAdjustment:
                    e.targetAvgPriceAdjustment === null ? null : Number(e.targetAvgPriceAdjustment),
            },
        )
    }

    render() {
        const { accountSlug, pricingService, setActiveSection, className, prototype, statsService, channelsService } =
            this.props
        const { exception, exceptionProducts } = this.state
        return (
            <>
                {exception && (
                    <ExceptionFormRouter
                        className={className}
                        key={exception.id}
                        exception={exception}
                        history={this.props.history}
                        exceptionProducts={exceptionProducts}
                        accountSlug={accountSlug}
                        pricingService={pricingService}
                        channelsService={channelsService}
                        articleService={this.props.articleService}
                        statsService={statsService}
                        setActiveSection={setActiveSection}
                        replaceTopMessages={this.props.replaceTopMessages}
                        removeAllMessages={this.props.removeAllMessages}
                        hasPermission={this.props.hasPermission}
                        prototype={prototype}
                    />
                )}
                {this.state.loading && (
                    <Loader>
                        <PageLoader />
                    </Loader>
                )}
                {!this.state.loading && !this.state.exception && <div>No results</div>}
            </>
        )
    }
}

export default ExceptionEdit
