import * as React from 'react'
import { FrameWrapper, SafariMobilePreviewSrollWrapper } from 'uiComponents/studio/preview/components'
import { ChartDataLoader } from 'uiComponents/loaders'
import styled from 'styled-typed'
import { Appearances } from './types'

export const Container = styled.div`
    display: flex;
    flex: 1;
    margin: 10px;
    word-break: break-word;
`

const PromotionContainer = styled.div<{ bgColor: string; textColor: string }>`
    width: 50%;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    overflow: hidden;
    background-color: #fff;
    color: #000;
    ${(props) =>
        props.bgColor &&
        props.textColor &&
        `
    background-color: ${props.bgColor};
    color: ${props.textColor};
  `}
`

const PromotionContent = styled.div`
    padding: 10px;
`

const PromotionTitle = styled.p`
    margin: 0;
    padding: 0;
    font-size: 19pt;
    line-height: 22pt;
    letter-spacing: -0.46pt;
    flex: 2;
    margin-top: 10px;
`

const PromotionImageContainer = styled.div<{ bgColor: string }>`
    height: 40px;
    aspect-ratio: 1;
    border-radius: 12px;
    margin-right: 10px;
    ${(props) =>
        props.bgColor &&
        `
    background-color: ${props.bgColor};
  `}
`
const PromotionImage = styled.img`
    padding: 2px;
    height: 40px;
    aspect-ratio: 1;
    object-fit: contain;
`

const PromotionHeader = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
`

const PartnerLabel = styled.p`
    opacity: 0.6;
    font-size: 8pt;
`

interface TemplatePreviewProps {
    loading: boolean
    title?: string
    partnerName?: string
    partnerImage?: string
    appearance: Appearances
    brandColor: string
}

export function TemplatePreview(props: TemplatePreviewProps) {
    const getBackgroundColor = (appearance: Appearances, brandColor: string) => {
        switch (appearance) {
            case 'BRAND':
                return brandColor
            case 'DARK':
                return '#111'
            case 'LIGHT':
                return '#fff'
            default:
                return '#111'
        }
    }
    const getTextColor = (appearance: Appearances, brandColor: string) => {
        switch (appearance) {
            case 'BRAND':
                return '#fff'
            case 'DARK':
                return '#fff'
            case 'LIGHT':
                return '#111'
            default:
                return '#fff'
        }
    }

    const bgColor = getBackgroundColor(props.appearance, props.brandColor)
    const textColor = getTextColor(props.appearance, props.brandColor)

    return (
        <FrameWrapper>
            <SafariMobilePreviewSrollWrapper>
                <Container>
                    <PromotionContainer bgColor={bgColor} textColor={textColor}>
                        <PromotionContent>
                            <PromotionHeader>
                                {props.partnerImage && (
                                    <PromotionImageContainer bgColor={props.brandColor}>
                                        <PromotionImage src={props.partnerImage} />
                                    </PromotionImageContainer>
                                )}
                                <PartnerLabel>{props.partnerName}</PartnerLabel>
                            </PromotionHeader>
                            {props.title && <PromotionTitle>{props.title}</PromotionTitle>}
                        </PromotionContent>
                    </PromotionContainer>
                </Container>
                {props.loading && <ChartDataLoader />}
            </SafariMobilePreviewSrollWrapper>
        </FrameWrapper>
    )
}

export default React.memo(TemplatePreview)
