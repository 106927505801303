import { IOption } from 'crm/audiences/types'
import { SearchResult } from './search'

const getParent = (products: IOption[], childValue: string, parents: IOption[] = [], currentParent?: IOption) => {
    products.forEach((product) => {
        if (product.value === childValue && currentParent) {
            parents.push(currentParent)
        } else if (product.children) {
            getParent(product.children, childValue, parents, product)
        }
    })

    return parents
}

const getAllParents = (products: IOption[], childValue: string) => {
    let parents = []
    let currentParents: IOption[]
    let currentParent: IOption

    currentParent = getParent(products, childValue)[0]

    if (!currentParent) {
        return []
    }

    do {
        parents.push(currentParent)
        currentParents = getParent(products, currentParent.value as string)
        currentParent = currentParents[0]
    } while (currentParent)

    return parents
}

export const searchProducts = (options: IOption[], products: IOption[], search: string, results: SearchResult[]) => {
    products.forEach((product) => {
        if (
            product.caption &&
            product.caption.toLowerCase().includes(search.toLowerCase()) &&
            !product.children?.length
        ) {
            results.push({
                product: product,
                parents: getAllParents(options, product.value as string).reverse(),
            })
        }
        if (product.children) {
            searchProducts(options, product.children, search, results)
        }
    })
}
