import * as React from 'react'
import Styled from 'styled-typed'
import { Col } from 'uiComponents/flex'
import Infotip from 'uiComponents/infotip'
import { RichTextInput } from 'uiComponents/input/richTextInput'
import { isValidUrl } from 'utils/formFieldChecks'
import { FieldType } from 'uiComponents/studio/form/fieldComponentSchema'
import { InputComponent, ToggleComponent } from 'uiComponents/studio/form/fieldComponents'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { ErrorMessage } from 'uiComponents/studio/form/errorMessage'
import {
    Translation,
    TriggerButtonConfiguration,
    Languages,
    OnChangeFunc,
    GetConfigValueFunc,
} from 'engageTools/studio/schema'

export const TRIGGER_COPY_KEYS = {
    TITLE: 'default_trigger_buttons.title',
    URL: 'default_trigger_buttons.cta_link',
}

export const defaultTranslationValues = {
    TITLE: 'Title',
    URL: '',
}

const Note = Styled.span`
  font-style: italic;
  color: ${(props) => props.theme.colors.textLight};
  flex: 1;
  text-align: right;
`

interface CopySectionProps {
    handleChange: OnChangeFunc
    getConfigValue: GetConfigValueFunc
    configuration: TriggerButtonConfiguration
    translations: Translation[]
    languages: Languages
    updateConfiguration: (path: string, value: string | number | boolean | string[]) => void
    updateTranslations: (slug: string, language: string, value: string) => void
    resetATranslation: (slug: string) => void
    setActiveLanguage: (v: string) => void
    activeLanguage: string
    translationsValidation: boolean
    setTranslationsValidation: (on: boolean) => void
    activeGlobalLanguage: string
}

interface CopySectionState {
    titleTextValid: boolean
    buttonUrlValid: boolean
}

export class CopySection extends React.Component<CopySectionProps, CopySectionState> {
    constructor(props: CopySectionProps) {
        super(props)
        this.state = {
            titleTextValid: true,
            buttonUrlValid: true,
        }
    }

    componentDidMount() {
        if (this.props.translationsValidation) {
            this.checkTranslationValidity()
            this.props.setTranslationsValidation(false)
        }
    }

    componentDidUpdate(prevProps: CopySectionProps) {
        if (this.props.translationsValidation) {
            this.checkTranslationValidity()
            this.props.setTranslationsValidation(false)
        } else {
            if (prevProps.activeLanguage !== this.props.activeLanguage) {
                this.resetFormValidity()
            }
        }
    }

    checkIfUrlComplete = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        if (!this.state.buttonUrlValid) {
            return
        }
        if (!value.includes('https://') && !value.includes('http://')) {
            const newValue = 'https://' + value
            this.props.updateTranslations('default_trigger_buttons.cta_link', this.props.activeLanguage, newValue)
        }
    }

    updateValidityInState = (slug: string, valid: boolean): void => {
        switch (slug) {
            case TRIGGER_COPY_KEYS.TITLE:
                this.setState({ titleTextValid: valid })
                break
            case TRIGGER_COPY_KEYS.URL:
                this.setState({ buttonUrlValid: valid })
                break
            default:
                break
        }
    }
    checkTranslationValidity = (): void => {
        this.props.translations.forEach((t) => {
            if (t.slug === TRIGGER_COPY_KEYS.URL && this.props.configuration.linkToCheckout) {
                this.updateValidityInState(t.slug, true)
            } else if (!t.values[this.props.activeLanguage]) {
                this.updateValidityInState(t.slug, false)
            } else if (t.slug === TRIGGER_COPY_KEYS.URL) {
                this.checkButtonUrlValidity(t.values[this.props.activeLanguage])
            } else {
                this.checkTitleTextValidity(t.values[this.props.activeLanguage])
            }
        })
    }

    resetFormValidity = () => {
        this.props.translations.forEach((t) => {
            this.updateValidityInState(t.slug, true)
        })
    }

    getTranslationValue = (item: FieldType): string => {
        const translation = this.props.translations.find((t) => t.slug === item.path)
        const value =
            typeof translation !== 'undefined'
                ? translation.values[this.props.activeLanguage]
                    ? translation.values[this.props.activeLanguage]
                    : ''
                : ''
        return value
    }

    handleTranslationChange = (e: React.ChangeEvent<HTMLSelectElement>, path: string) => {
        const value = e.target.value
        this.props.updateTranslations(path, this.props.activeLanguage, value)
    }

    handleTitleTextChange = (html: string, text: string, fontStyles: string[]) => {
        this.props.updateTranslations(TRIGGER_COPY_KEYS.TITLE, this.props.activeLanguage, html)
    }

    handleSendToCheckout = (on: boolean, path: string): void => {
        this.props.handleChange(on, path)
        if (on) {
            this.props.resetATranslation(TRIGGER_COPY_KEYS.URL)
        }
        this.handleActiveLanguage()
    }
    checkTitleTextValidity = (v: string): void => {
        this.setState({ titleTextValid: v !== '' })
    }
    checkButtonUrlValidity = (v: string): void => {
        this.setState({ buttonUrlValid: v !== '' && isValidUrl(v) })
    }

    handleActiveLanguage = (e?: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (this.props.activeGlobalLanguage !== this.props.activeLanguage) {
            this.props.setActiveLanguage(this.props.activeLanguage)
        }
    }

    render() {
        const { activeLanguage } = this.props
        const sendToCheckoutEnabled = this.props.configuration.linkToCheckout
        const activeIsPrimary = activeLanguage === this.props.languages.primary

        type UrlType = 'url'
        type ToggleType = 'toggle'
        type TextField = 'text'
        const titleTextField = {
            name: 'title-text',
            type: 'text' as TextField,
            label: 'Catchphrase text',
            path: TRIGGER_COPY_KEYS.TITLE,
            defaultValue: 'Title text',
        }
        const sendToChekoutField = {
            name: 'send-to-checkout',
            type: 'toggle' as ToggleType,
            label: 'Link to checkout',
            path: 'linkToCheckout',
            defaultValue: true,
            infotip: 'After clicking the button, send your users to the checkout.',
        }
        const hrefField = {
            name: 'button-destination',
            type: 'url' as UrlType,
            label: 'Alternative URL',
            path: TRIGGER_COPY_KEYS.URL,
            placeholder: 'https://www.example.com/shop',
            defaultValue: '',
            infotip: 'Destination page your users will be sent to after clicking the button.',
        }
        return (
            <>
                {activeLanguage && (
                    <div>
                        <FormItem>
                            <Col span={12}>
                                <label htmlFor={titleTextField.name}>
                                    {titleTextField.label}
                                    <Note>*max. 100 characters</Note>
                                </label>
                            </Col>
                            <Col span={12}>
                                <RichTextInput
                                    id={titleTextField.name}
                                    onChange={this.handleTitleTextChange}
                                    value={this.getTranslationValue(titleTextField)}
                                    maxLength={100}
                                    style={{ fontSize: '0.75rem' }}
                                    onFinishTyping={(v: string) => {
                                        this.checkTitleTextValidity(v)
                                    }}
                                    status={!this.state.titleTextValid ? 'error' : 'normal'}
                                    onFocus={this.handleActiveLanguage}
                                />
                                {!this.state.titleTextValid && <ErrorMessage errType="notEmpty" />}
                            </Col>
                        </FormItem>
                        <FormItem>
                            {activeIsPrimary && (
                                <>
                                    <Col span={6}>
                                        <label htmlFor={sendToChekoutField.name}>{sendToChekoutField.label}</label>
                                    </Col>
                                    <Col span={6} className="justify-right">
                                        <ToggleComponent
                                            field={sendToChekoutField}
                                            handleChange={this.handleSendToCheckout}
                                            value={
                                                this.props.getConfigValue(
                                                    sendToChekoutField,
                                                    this.props.configuration,
                                                ) as boolean
                                            }
                                        />
                                    </Col>
                                </>
                            )}
                            {!sendToCheckoutEnabled && (
                                <>
                                    <Col span={12}>
                                        <label htmlFor={hrefField.name}>
                                            {hrefField.label}
                                            <Infotip pointer="left" maxWidth="20em">
                                                {hrefField.infotip}
                                            </Infotip>
                                        </label>
                                    </Col>
                                    <Col span={12}>
                                        <InputComponent
                                            field={hrefField}
                                            handleChange={this.handleTranslationChange}
                                            value={this.getTranslationValue(hrefField)}
                                            checkValidity={(v: string) => {
                                                this.checkButtonUrlValidity(v)
                                            }}
                                            error={!this.state.buttonUrlValid}
                                            onFocus={this.handleActiveLanguage}
                                            onBlur={this.checkIfUrlComplete}
                                        />
                                        {!this.state.buttonUrlValid && <ErrorMessage errType="url" />}
                                    </Col>
                                </>
                            )}
                        </FormItem>
                    </div>
                )}
            </>
        )
    }
}

export default CopySection
