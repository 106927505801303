import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import cloneDeep from 'lodash/cloneDeep'
import { Row, Col } from 'uiComponents/flex'
import { FormItem, FormItemName } from 'uiComponents/form/formElements'
import { Integration, ExternalIdItem, ArticleCrudData } from 'admin/articleService'
import Infotip from 'uiComponents/infotip'
import { SingleSelect, SingleSelectOption, sortOptionsAlphabetically, TextArea } from 'uiComponents/input'
import { ActionButton } from 'uiComponents/buttons'
import { SecondaryText } from 'uiComponents/typography'
import isEqual from 'lodash/isEqual'
import { LEFT_SIDE_SPAN, RIGHT_SIDE_SPAN } from './common'

const DeleteNote = styled.div`
    cursor: pointer;
    position: relative;
    top: 3em;
`

interface ProductIdsProps {
    productData: ArticleCrudData
    exeternalIds: ExternalIdItem[]
    integrations: Integration[]
    missingType: boolean
    updateExternalIdsList: (externalIds: ExternalIdItem[]) => void
    updateExternalIdValue: (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => void
}

export function ProductIds(props: ProductIdsProps) {
    const [integrationOptions, setIntegrationOptions] = useState<SingleSelectOption[]>([])
    const [updatedExternalIds, setUpdatedExternalIds] = useState<ExternalIdItem[]>([])

    useEffect(() => {
        const options = props.integrations
            .filter((i) => !!i.slug)
            .map((i) => ({
                name: i.name,
                value: i.slug,
            }))
            .sort(sortOptionsAlphabetically)
        setIntegrationOptions(options)
    }, [props.integrations])

    useEffect(() => {
        if (!isEqual(updatedExternalIds, props.exeternalIds)) {
            setUpdatedExternalIds(props.exeternalIds)
        }
    }, [props.exeternalIds])

    const onSelectChange = (value: string, index: number) => {
        const copy = cloneDeep(updatedExternalIds)
        copy[index].integrationType = value
        setUpdatedExternalIds(copy)
        props.updateExternalIdsList(copy)
    }

    const onAddExternalId = () => {
        const copy = cloneDeep(updatedExternalIds)
        const newList = [...copy, { integrationType: null, externalId: '' }]
        setUpdatedExternalIds(newList)
        props.updateExternalIdsList(newList)
    }

    const onRemoveExternalId = (index: number) => {
        const copy = cloneDeep(updatedExternalIds)
        copy.splice(index, 1)
        setUpdatedExternalIds(copy)
        props.updateExternalIdsList(copy)
    }

    const getUnusedIntegrations = () => {
        const usedIntegrations = updatedExternalIds.map((i) => i.integrationType)
        return integrationOptions.filter((o) => usedIntegrations.indexOf(o.value) < 0)
    }

    const getIntegrationsList = (slug: string | null) => {
        const unusedIntegrationOptions = getUnusedIntegrations()
        if (!slug) {
            return unusedIntegrationOptions
        }
        const thisIntegration = integrationOptions.find((o) => o.value === slug)
        if (thisIntegration) {
            return [{ name: thisIntegration.name, value: slug }, ...unusedIntegrationOptions]
        }
        return unusedIntegrationOptions
    }

    return (
        <Row style={{ marginBottom: '1em' }}>
            <Col span={12}>
                {updatedExternalIds.length === 0 && (
                    <FormItem>
                        <FormItemName>
                            External IDs
                            <Infotip pointer="left" maxWidth="33em">
                                Corresponding ID(s) that are being used for integrations (optional)
                            </Infotip>
                        </FormItemName>
                    </FormItem>
                )}
                {updatedExternalIds.map((item, i) => (
                    <Row key={i} style={{ marginBottom: '1em' }}>
                        <Col span={LEFT_SIDE_SPAN}>
                            <FormItem htmlFor={`product-external-id-${i}`} style={{ marginBottom: '1em' }}>
                                <FormItemName>
                                    External IDs
                                    <Infotip pointer="left" maxWidth="33em">
                                        Corresponding ID(s) that are being used for integrations (optional)
                                    </Infotip>
                                </FormItemName>
                                <SingleSelect
                                    id={`product-external-id-${i}`}
                                    options={getIntegrationsList(item.integrationType)}
                                    selected={item.integrationType}
                                    noSelectOption="Select integration"
                                    height="2.8em"
                                    maxHeight="50em"
                                    onSelect={(v) => onSelectChange(v, i)}
                                    status={props.missingType && !item.integrationType ? 'error' : 'normal'}
                                    width="100%"
                                />
                            </FormItem>
                            <TextArea
                                maxLength={5000}
                                value={item.externalId || ''}
                                onChange={(e) => props.updateExternalIdValue(e, i)}
                                style={{ width: '100%' }}
                                block
                            />
                        </Col>
                        <Col span={RIGHT_SIDE_SPAN}>
                            <DeleteNote onClick={() => onRemoveExternalId(i)}>
                                <SecondaryText>&times; Remove</SecondaryText>
                            </DeleteNote>
                        </Col>
                    </Row>
                ))}
                {updatedExternalIds.length < integrationOptions.length && (
                    <Row
                        style={{
                            marginTop: updatedExternalIds.length === 0 ? '0.5em' : '1.3em',
                        }}
                    >
                        <ActionButton kind="action" size="small" secondary onClick={onAddExternalId}>
                            Add external ID
                        </ActionButton>
                    </Row>
                )}
            </Col>
        </Row>
    )
}
