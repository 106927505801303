import React from 'react'
import styled, { useTheme } from 'styled-components'
import { ActionButtonA } from 'uiComponents/buttons'
import FieldWrapper from 'uiComponents/input/fieldWrapper'
import { Caption } from 'uiComponents/typography'
import { useSelectedParkMapContext } from 'venue/parkMap/context/selectedParkMap'
import useGetMapSnapshot from 'venue/parkMap/hooks/useGetMapSnapshot'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import placeholderBanner from 'images/placeholderBanner.png'

export const ImageContainer = styled.div`
    background-color: ${(p) => p.theme.colors.background};
    border: 1px dashed ${(p) => p.theme.colors.textLight};
    border-radius: 4px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: space-between;
`

export const Image = styled.img`
    max-width: 60px;
    max-height: 60px;
    border-radius: 3px;
    margin-right: 8px;
`

export const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-top: 8px;
`

export const Button = styled(ActionButtonA)`
    margin: none;
`

const SnapshotConfig = () => {
    const theme = useTheme()
    const { selectedParkMap } = useSelectedParkMapContext()
    const { snapshotImage, takeSnapshot, openSnapshotImage } = useGetMapSnapshot()
    const overlay = selectedParkMap?.overlay

    return (
        <FieldWrapper label="Map snapshot" id="upload-image" status="normal">
            <ImageContainer>
                <Image src={snapshotImage ?? placeholderBanner} />
                <div>
                    <Caption style={{ wordBreak: 'break-word' }}>
                        {snapshotImage
                            ? `north:${overlay?.northWest.lat}, west: ${overlay?.northWest.lng}, south: ${overlay?.southEast.lat}, east: ${overlay?.southEast.lng}`
                            : ''}
                    </Caption>
                    <Buttons>
                        {!selectedParkMap?.overlay?.image && (
                            <Button onClick={takeSnapshot} kind="action" secondary>
                                Take a map snapshot
                                <FontAwesomeIcon
                                    icon={faCamera as IconProp}
                                    color={theme.colors.boyBlue}
                                    size="sm"
                                    style={{ marginLeft: 5 }}
                                />
                            </Button>
                        )}
                        {snapshotImage && (
                            <Button onClick={openSnapshotImage} kind="success" secondary>
                                Download
                            </Button>
                        )}
                    </Buttons>
                </div>
            </ImageContainer>
        </FieldWrapper>
    )
}

export default SnapshotConfig
