import * as React from 'react'
import { DisabledItemHoverInfotip } from 'uiComponents/infotip'
import { IconProp } from '@fortawesome/fontawesome'
import { MenuToggleComponent, Container, OptionsListBox } from './index'

interface OneLevelMenuProps {
    children: React.ReactNode
    disabledInfotipText: string
    menuName: string
    selectedCount?: number
    closeMenu?: boolean
    expandedToggleStyle?: boolean
    disabled?: boolean
    loading?: boolean
    onOutsideClick?: () => void
    icon?: IconProp
    expandLeft?: boolean
    open?: boolean
    arrowHidden?: boolean
}

interface OneLevelMenuState {
    showOptions: boolean
    ignoreOutsideClick: boolean
}

export class OneLevelMenu extends React.Component<OneLevelMenuProps, OneLevelMenuState> {
    container: HTMLDivElement | null = null

    constructor(props: OneLevelMenuProps) {
        super(props)
        this.state = {
            showOptions: false,
            ignoreOutsideClick: false,
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.outsideClick, false)
        if (this.props.open) {
            this.setState({ showOptions: true })
        }
    }

    componentDidUpdate(prevProps: OneLevelMenuProps) {
        if (this.props.closeMenu && !prevProps.closeMenu) {
            this.closeMenu()
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.outsideClick, false)
    }

    setContainerRef = (node: HTMLDivElement) => {
        this.container = node
    }

    outsideClick = (ev: MouseEvent) => {
        if (this.state.ignoreOutsideClick) {
            this.setState({ ignoreOutsideClick: false })
            return
        }
        if (
            this.state.showOptions &&
            this.container &&
            this.container.contains &&
            !this.container.contains(ev.target as Node)
        ) {
            if (this.props.onOutsideClick) {
                this.props.onOutsideClick()
            }
            this.closeMenu()
        }
    }

    openMenu = () => {
        this.setState({ ignoreOutsideClick: true, showOptions: true })
    }

    closeMenu = () => {
        if (this.state.showOptions) {
            this.setState({ showOptions: false })
        }
    }

    toggleOptionsContainer = () => {
        if (this.props.loading || this.props.disabled) {
            return
        }
        this.setState({ showOptions: !this.state.showOptions })
    }

    render() {
        const { showOptions } = this.state
        const { children, disabledInfotipText, expandedToggleStyle, disabled, loading } = this.props
        const expanded = !disabled && (showOptions || expandedToggleStyle)
        return (
            <Container ref={this.setContainerRef}>
                {!this.props.open && (
                    <DisabledItemHoverInfotip active={disabled} infotipText={disabledInfotipText} width="20em">
                        <MenuToggleComponent
                            arrowHidden={this.props.arrowHidden}
                            menuName={this.props.menuName}
                            selectedCount={this.props.selectedCount}
                            loading={!!loading}
                            expanded={!!expanded}
                            toggleOptionsContainer={this.toggleOptionsContainer}
                            icon={this.props.icon}
                            open={this.props.open}
                        />
                    </DisabledItemHoverInfotip>
                )}
                <OptionsListBox
                    className={showOptions ? 'visible' : ''}
                    offsetLeft={0}
                    offsetTop={this.props.open ? 0 : 2.8}
                    expandLeft={this.props.expandLeft}
                >
                    {children}
                </OptionsListBox>
            </Container>
        )
    }
}
