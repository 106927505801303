import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { ArticleConfigurationService, ValidityException } from 'products/articleConfigurationService'
import { ArticleService as AdminArticleService } from 'admin/articleService'
import { MessageKind } from 'uiComponents/messages'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { InlineDataTable, HeaderRow, TableHeader, Cell, Sorting } from 'uiComponents/table'
import { ActionButtonA } from 'uiComponents/buttons'
import { TableLoader } from 'uiComponents/loaders'
import { WeekdayPattern } from 'uiComponents/weekdayPattern'
import { ExceptionDataRow } from 'products/pricing/exceptions/exceptionsTable'
import { withPermission } from 'admin/hocs'
import { withFeatures } from 'features'
import { useGetAllArticles } from '../../redux'
import { DateRange } from 'uiComponents/date'

const NoPattern = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
`
const NoPatternBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.2em;
    border-radius: 6px;
    height: 1em;
    width: 1em;
    background-color: rgba(247, 116, 97, 0.25);
    color: rgba(247, 116, 97, 0.7);
    font-weight: normal;
`

interface ExceptionsListPageProps {
    articleConfigurationService: ArticleConfigurationService
    articleService: AdminArticleService
    accountSlug: string
    productId?: string
    navigation: Navigation
    match: RouteMatch<{}>
    standalonePage?: boolean
    setActiveSection: (section: string, header?: string) => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hasPermission: (permission: string, accountSlug: string) => boolean
    hasFeature: (feature: string, slug: string) => boolean
}

function ExceptionsListPage(props: ExceptionsListPageProps & React.HTMLAttributes<HTMLDivElement>) {
    const query = props.navigation.query()
    const columnWidths = [null, '25em', '9em', '10.1em', '7.5em']
    const effectiveStyle = { boxShadow: 'none', ...props.style }
    const [exceptions, setExceptions] = useState<ValidityException[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const accountArticles = useGetAllArticles()

    useEffect(() => {
        if (props.standalonePage) {
            props.setActiveSection('validityExceptions', 'Validity Exceptions')
        }
    }, [])

    useEffect(() => {
        getExceptions(query.sortBy || 'name', query.sortDirection || 'desc')
    }, [props.navigation.query().sortBy, props.navigation.query().sortDirection, props.accountSlug])

    async function getExceptions(sortBy: string, sortDirection: string) {
        try {
            const exs = await props.articleConfigurationService.getValidityExceptionsList(
                props.accountSlug,
                sortBy,
                sortDirection,
            )

            exs.forEach((e) => {
                const productIds = e.articles.map((p) => p.id)
                const productNames = accountArticles
                    .filter((a) => productIds.indexOf(a.id) > -1)
                    .map((a) => a.name?.['en'])
                e['productNames'] = productNames.join(', ')
            })
            if (props.productId) {
                const productExceptions = exs.filter(
                    (e) => e.articles.filter((a) => a.id === props.productId).length > 0,
                )
                setExceptions(productExceptions)
            } else {
                setExceptions(exs)
            }
            setLoading(false)
        } catch {
            handleError('Oops! Something went wrong, please try again.')
        }
    }

    function handleError(message: string | null = null) {
        if (message) {
            props.replaceTopMessages('server_error', 'error', message)
        }
    }

    function onSortChanged(sorting: Sorting) {
        setLoading(true)
        props.navigation.addQueryWithReplace({
            sortBy: sorting.prop,
            sortDirection: sorting.direction,
        })
    }

    const sort = {
        prop: query.sortBy || 'name',
        direction: query.sortDirection || 'desc',
    }
    return (
        <>
            <InlineDataTable style={effectiveStyle} columnWidths={columnWidths}>
                <HeaderRow>
                    <TableHeader sortKey="name" sorting={sort as Sorting} changeSort={onSortChanged}>
                        Name
                    </TableHeader>
                    <TableHeader>Product(s)</TableHeader>
                    <TableHeader className="withPattern" style={{ textAlign: 'center' }}>
                        Pattern
                    </TableHeader>
                    <TableHeader sortKey="date_range" sorting={sort as Sorting} changeSort={onSortChanged}>
                        Date range
                    </TableHeader>
                    <TableHeader nonInteractive />
                </HeaderRow>
                {loading && <TableLoader />}
                {!loading && (
                    <>
                        {accountArticles.length === 0 ? (
                            <NoResultsRow text="Neither categories nor products were found. Exception creation is not available." />
                        ) : (
                            <>
                                {exceptions.length === 0 && <NoResultsRow text="There are no exceptions yet." />}
                                {exceptions.map((exception, key) => (
                                    <ExceptionDataRow key={key}>
                                        <Cell className="withName exceptionName" title={exception.name}>
                                            <span>{exception.name}</span>
                                        </Cell>
                                        <Cell className="withName exceptionProducts" title={exception.productNames}>
                                            <span>{exception.productNames}</span>
                                        </Cell>
                                        <Cell className="withPattern">
                                            {exception.recurrence !== 'WEEKLY' && <NoPattern>custom</NoPattern>}
                                            {!exception.weekday && exception.recurrence === 'WEEKLY' && (
                                                <NoPattern>
                                                    <NoPatternBox>&times;</NoPatternBox>
                                                </NoPattern>
                                            )}
                                            {exception.weekday && exception.recurrence === 'WEEKLY' && (
                                                <WeekdayPattern weekdays={exception.weekday} type="strict" invert />
                                            )}
                                        </Cell>
                                        <Cell className="exceptionDates wrapContent">
                                            <DateRange
                                                startDateString={exception.appliesFrom}
                                                endDateString={exception.appliesTo}
                                            />
                                        </Cell>
                                        <Cell>
                                            <ActionButtonA
                                                className="editException"
                                                kind="action"
                                                secondary
                                                href={`/account/${props.accountSlug}/products/validity/exceptions/${exception.id}`}
                                            >
                                                {props.hasPermission('edit_product_validity', props.accountSlug)
                                                    ? 'Edit'
                                                    : 'View'}
                                            </ActionButtonA>
                                        </Cell>
                                    </ExceptionDataRow>
                                ))}
                            </>
                        )}
                    </>
                )}
            </InlineDataTable>
        </>
    )
}

export default withFeatures(withNavigation(withPermission(ExceptionsListPage)))
