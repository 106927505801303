import React from 'react'
import { Route, Switch } from 'react-router'

import IframeRedeemMode from 'orders/iframeRedeemMode'

const UNAUTHORIZED_USER_ROUTES = {
    SCANNING: '/barcode-scanning',
}

export const ALL_UNAUTHORIZED_USER_ROUTES = Object.values(UNAUTHORIZED_USER_ROUTES)

const UnauthorizedUserRoutes = () => {
    return (
        <Switch>
            <Route path={UNAUTHORIZED_USER_ROUTES.SCANNING} component={IframeRedeemMode} />
        </Switch>
    )
}

export default UnauthorizedUserRoutes
