import React, { useContext, useEffect, useMemo } from 'react'

import SingleSelectField from 'uiComponents/input/singleSelect/singleSelectField'
import { SingleSelectOption } from 'uiComponents/input'
import StudioContext from './provider/context'
import { findFirstAvailableOption } from '../../uiComponents/input/singleSelect/utils'
import { getProductName } from '../../products/productTable/utils'
import { LoaderWrapper } from '../../uiComponents/loaders'

const formatArticleValue = ({ article, category }: Omit<Props, 'setArticle' | 'setCategory'>) =>
    `${article}||${category}`

const parseArticleValue = (value: string) => {
    const [article, category] = value.split('||')
    return { article, category }
}

interface Props {
    article?: string
    setArticle: (article: string) => void
    category?: string
    setCategory: (category: string) => void
}

const SelectArticle: React.FC<Props> = (props) => {
    const { category, setCategory, article, setArticle } = props
    const { products, articles, isLoading } = useContext(StudioContext)

    const options = useMemo<SingleSelectOption[]>(() => {
        const opt = [] as SingleSelectOption[]

        products.forEach((list) => {
            opt.push({
                name: getProductName(list.name) as string,
                parentId: list.parentCategory?.id,
                value: list.id,
                disabled: true,
            })
        })

        articles.forEach((article) => {
            if (article.categories?.length) {
                article.categories?.map(({ id }) => {
                    opt.push({
                        name: getProductName(article.name) as string,
                        parentId: id,
                        value: formatArticleValue({ article: article.id, category: id }),
                    })
                })
            }
        })

        return opt
    }, [products, articles])

    const firstAvailableOption = useMemo(() => findFirstAvailableOption(options), [options])

    useEffect(() => {
        if (!article && firstAvailableOption) {
            const { article: parsedArticle, category: parsedCategory } = parseArticleValue(firstAvailableOption.value)
            setArticle(parsedArticle)
            setCategory(parsedCategory)
        }
    }, [firstAvailableOption, article])

    return (
        <LoaderWrapper loading={isLoading}>
            <SingleSelectField
                nested
                style={{ zIndex: 4, position: 'relative' }}
                name="article"
                options={options}
                onChange={(value: string) => {
                    const { article, category } = parseArticleValue(value)
                    setArticle(article)
                    setCategory(category)
                }}
                value={formatArticleValue({ article: article, category: category })}
                maxHeight="40vh"
            />
        </LoaderWrapper>
    )
}

export default SelectArticle
