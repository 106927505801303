import React, { useEffect, useContext } from 'react'
import styled, { keyframes } from 'styled-typed'
import Svg from 'svg'
import lockIcon from './lock.svg'
import { LoggingServiceContext } from 'http/context'
import { CONTAINER_BOX_SHADOW } from 'uiComponents/settingsContainer'

const appear = keyframes`
  from { transform: scale(0, 0) translate(-50%, -50%);}
  to { transform: scale(1, 1) translate(-50%, -50%);}
`

const Container = styled.div`
    color: ${(props) => props.theme.colors.textDark};
    position: absolute;
    left: 50%;
    top: 50%;
    align-items: center;
    animation: ${appear} 0.2s ease-in;
    animation-fill-mode: forwards;
    animation-duration: 0.2s;
    transform-origin: top left;
    background: ${(props) => props.theme.colors.white};
    border-radius: 0.625em;
    padding: 2em 2em 2.6em 2em;
    box-shadow: ${CONTAINER_BOX_SHADOW};
    min-height: 350px;
    min-width: 400px;

    @media screen and (min-height: 700px) {
        top: 40%;
    }
`

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Text = styled.div`
    text-align: center;
    line-height: 1.35em;
    max-width: 17em;
`
const Message = styled.div`
    margin: auto;
    margin-top: 0.3em;
    font-size: 0.875em;
    font-weight: 300;
    line-height: 1.35em;
    max-width: 16em;
`
const IconContainer = styled.div`
    margin-bottom: 1em;
    width: 10em;
`

interface AccessDeniedPageProps {}

export function AccessDeniedPage(props: AccessDeniedPageProps) {
    const loggingService = useContext(LoggingServiceContext)

    useEffect(() => {
        loggingService.logAction('access_denied', {
            click_to: location.pathname + location.search,
        })
    }, [])

    return (
        <Container>
            <MessageContainer>
                <IconContainer>
                    <Svg src={lockIcon} />
                </IconContainer>
                <Text>
                    You do not have access to this page.
                    <Message>Please contact the admin to grant you the required permission.</Message>
                </Text>
            </MessageContainer>
        </Container>
    )
}
