import React, { ReactElement, useContext } from 'react'
import { SingleSelectOption } from '../singleSelect'
import { Checkbox, ListSubheader, ListItem } from '@mui/material'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { ListChildComponentProps } from 'react-window'
import { MaterialSelectOption } from './types'
import { ComboboxContext } from './comboboxContext'

export interface Props {
    options: SingleSelectOption[]
    label?: string
    nested?: boolean
}

const LISTBOX_PADDING = 8

export function renderComboboxRow({ data, index, style }: ListChildComponentProps<ReactElement>) {
    const dataSet = data[index]
    const option = dataSet[1] as MaterialSelectOption
    const { multiple, optionChilds, selectedOptions } = useContext(ComboboxContext)
    const matches = match(option?.label || '', dataSet[3].inputValue, { insideWords: true })
    const parts = parse(option?.label || '', matches)
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
        padding: `8px 8px 8px ${(option?.depth || 1) * 20}px`,
    }

    if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        )
    }

    let indeterminate = false
    let checked = dataSet[0]['aria-selected']

    if (!checked && multiple) {
        const initialOptionChilds = optionChilds?.[option.id] ?? ([] as MaterialSelectOption[])
        let allChilds = [
            ...(initialOptionChilds
                ?.filter((option) => {
                    return !optionChilds?.[option.id].length
                })
                ?.map((option) => option.id) as any[]),
        ]

        optionChilds?.[option.id]?.forEach((option) => {
            const index = selectedOptions?.indexOf(option.id) as number
            if (index >= 0) {
                indeterminate = true

                const allChildsIndex = allChilds?.indexOf(option.id)
                allChilds.splice(allChildsIndex, 1)
            }
        })

        if (allChilds.length === 0 && initialOptionChilds?.length) {
            indeterminate = false
            checked = true
        }
    }

    return (
        <ListItem {...dataSet[0]} style={inlineStyle} className={`list-item list-item-depth-${option.depth}`}>
            {!!multiple && !dataSet[0]['aria-disabled'] && (
                <Checkbox size="small" indeterminate={indeterminate} checked={checked} />
            )}
            {parts.map((part, index) => (
                <span
                    key={index}
                    style={{
                        fontWeight: part.highlight ? 700 : 400,
                    }}
                >
                    {part.text.replace(/ /g, '\u00A0')}
                </span>
            ))}
        </ListItem>
    )
}
