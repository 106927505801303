import { BaseRouteParams } from 'hocs'
import { pathUtils } from 'utils/pathnameFormatter'

export interface ParkMapPagesParams extends BaseRouteParams {
    parkMapId: string
    categoryId: string
    styleId: string
    poiId: string
}

export const CODES_PATHS = {
    pagePaths: {
        mapPath: '/account/:accountSlug/venue/park_map',
    },
    internalPaths: {
        add: {
            maps: ':parkMapId/details',
            styles: ':parkMapId/styles',
            pois: ':parkMapId/cat_poi',
        },
    },
    get fullPaths() {
        return {
            _internalPaths: this.internalPaths,

            mapsAddPage: pathUtils.addPath(this.pagePaths.mapPath, this.internalPaths.add.maps),
            stylesAddPage: pathUtils.addPath(this.pagePaths.mapPath, this.internalPaths.add.styles),
            poisAddPage: pathUtils.addPath(this.pagePaths.mapPath, this.internalPaths.add.pois),
        }
    },
}
