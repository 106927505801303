import * as React from 'react'
import { ReactComponent } from 'reactUtils'
import { State } from 'store'
import { connect } from 'react-redux'
import { Subtract } from 'utility-types'
import { Permissions, Account } from 'auth/state'
import { AccessPermission } from 'settings/schema'

const RESELLER_UI_PERMISSIONS: AccessPermission[] = ['access_orders', 'export_orders', 'edit_orders']

interface StateProps {
    conviousAdmin: boolean
    permissions: Permissions[]
    accounts: Account[]
    isReseller: boolean
    accountSlug: string
}

export interface PermissionProps {
    hasPermission: (permission: string, accountSlug?: string) => boolean
}

export function withPermission<TProps extends PermissionProps>(
    Component: ReactComponent<TProps>,
): ReactComponent<Subtract<TProps, PermissionProps>> {
    class WithPermission extends React.Component<TProps & StateProps> {
        hasPermission = (permission: string, accountSlug?: string): boolean => {
            accountSlug ??= this.props.accountSlug

            if (this.props.conviousAdmin) {
                return true
            }
            if (permission === 'convious_admin' && !this.props.conviousAdmin) {
                return false
            }
            if (permission === 'access_account') {
                return this.props.accounts.filter((a) => a.slug === accountSlug).length > 0
            }
            if (this.props.isReseller && RESELLER_UI_PERMISSIONS.indexOf(permission as AccessPermission) < 0) {
                return false
            }
            const accountPermissions = this.props.permissions.filter((a) => a.account === accountSlug)
            const permissionsList = accountPermissions.length > 0 ? accountPermissions[0].permissions : []
            return permissionsList.indexOf('partner_admin') > -1 || permissionsList.indexOf(permission) > -1
        }

        render() {
            return <Component {...this.props} hasPermission={this.hasPermission} />
        }
    }

    function mapStateToProps(state: State) {
        return {
            conviousAdmin: state.auth.user ? state.auth.user.isAdmin : false,
            permissions: state.auth.user ? state.auth.user.permissions : [],
            accounts: state.auth.user ? state.auth.user.accounts : [],
            isReseller: !!state.auth.user?.resellerId,
            accountSlug: state.preferences.activeAccount,
        }
    }

    // @ts-ignore
    const ConnectedComponent = connect(mapStateToProps)(WithPermission)

    // eslint-disable-next-line react/display-name
    return function (props: Subtract<TProps, PermissionProps>) {
        // @ts-ignore
        return <ConnectedComponent {...props} />
    }
}

interface AdminStateProps {
    conviousAdmin: boolean
}

interface AdminPermissionProps {
    isAdmin: boolean
}

export function withAdminPermission<TProps extends AdminPermissionProps>(
    Component: ReactComponent<TProps>,
): ReactComponent<Subtract<TProps, AdminPermissionProps>> {
    class WithPermission extends React.Component<TProps & AdminStateProps> {
        render() {
            return <Component {...this.props} isAdmin={this.props.conviousAdmin} />
        }
    }

    function mapStateToProps(state: State) {
        return {
            conviousAdmin: state.auth.user ? state.auth.user.isAdmin : false,
        }
    }

    // @ts-ignore
    const ConnectedComponent = connect(mapStateToProps)(WithPermission)

    // eslint-disable-next-line react/display-name
    return function (props: Subtract<TProps, AdminPermissionProps>) {
        // @ts-ignore
        return <ConnectedComponent {...props} />
    }
}
