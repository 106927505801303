import * as React from 'react'
import styled from 'styled-typed'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-light-svg-icons'
import CopyToClipboard from 'react-copy-to-clipboard'

const Icon = styled(FontAwesomeIcon)`
    cursor: pointer;
    &:active {
        color: ${(p) => p.theme.colors.boyBlue};
    }
`

export function CopyIcon(props: { text: string; style?: React.CSSProperties }) {
    return (
        <CopyToClipboard text={props.text}>
            <Icon icon={faCopy} style={props.style} title="Copy" />
        </CopyToClipboard>
    )
}
