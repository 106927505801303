import React, { ChangeEvent, FC } from 'react'
import { Box, FormControlLabel, FormControlLabelProps, Switch, SwitchProps } from '@mui/material'
import { useField } from 'formik'
import { ValidationMessage } from 'uiComponents/form/formElements'

type SwitchButtonProps = SwitchProps & {
    name: string
    label: string | JSX.Element
    labelProps?: Omit<FormControlLabelProps, 'label' | 'control'>
}

export const SwitchButtonFormik: FC<SwitchButtonProps> = ({ name, label, labelProps, onChange, ...props }) => {
    const [field, meta, { setValue }] = useField(name)

    const handleChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (onChange) {
            return onChange(event, checked)
        }

        setValue(event.target.checked)
    }

    return (
        <Box display="flex">
            <FormControlLabel
                control={<Switch checked={field.value} onChange={handleChange} color="primary" {...props} />}
                label={label}
                {...labelProps}
            />
            {meta.touched && meta.error && (
                <ValidationMessage className="validation-message-visible">{meta.error}</ValidationMessage>
            )}
        </Box>
    )
}
