import React, { FC } from 'react'

import Box from '@mui/material/Box'
import { getRailBackground } from './utils'
import { useTheme } from 'styled-typed'
import { SliderRailProps } from './types'

export const SliderRail: FC<SliderRailProps> = ({ recommendedValue, min, max, orientation, ...props }) => {
    const theme = useTheme()

    return (
        <Box
            {...props}
            className="convious-slider__rail"
            style={{
                background: getRailBackground({
                    recommendedValue,
                    min,
                    max,
                    orientation,
                    theme,
                }),
            }}
        />
    )
}
