import { History } from 'history'
import { sortBy } from 'lodash'
import { Navigation } from 'navigation'
import React from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { itemMatchesSearch } from 'tapPromotions/helpers'
import { PromotionListResponse, PromotionService } from 'tapPromotions/types'
import { ActionButton } from 'uiComponents/buttons'
import { TableLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import SearchField, { SearchContainer } from 'uiComponents/search/searchField'
import { HeaderRow, InlineDataTable, TableHeader } from 'uiComponents/table'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { useFetchAudiences } from '../../../crm/audiences/hooks/useFetchAudiences'
import TableBody from './tableBody'

type Props = {
    accountSlug: string
    history: History
    navigation: Navigation
    promotionService: PromotionService
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

const Promotions = (props: Props) => {
    const [loading, setLoading] = React.useState<boolean>(false)
    const [items, setItems] = React.useState<PromotionListResponse[]>([])
    const audiences = useFetchAudiences()

    const query = React.useMemo(() => {
        return props.navigation.query().q ? props.navigation.query().q.toLowerCase() : ''
    }, [props.navigation.query().q])

    const filteredItems = React.useMemo(() => {
        return items.filter((item) => {
            const matchesName = itemMatchesSearch(item.name, query)

            return matchesName
        })
    }, [props.navigation.query().q, JSON.stringify(items)])

    const columnWidths = ['6em', '15em', null, '10em', '11em']

    React.useEffect(() => {
        audiences.fetch()
    }, [props.accountSlug])

    React.useEffect(() => {
        fetchPromotions()
    }, [props.accountSlug])

    const fetchPromotions = async () => {
        setLoading(true)

        try {
            const result = await props.promotionService.fetchAll(props.accountSlug)
            const sortedPromotions = sortBy(result, 'weight')
            setLoading(false)
            setItems(sortedPromotions)
        } catch (error) {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! We could not retreive the promotions. Please try again.',
            )
            setLoading(false)
        }
    }

    const reorder = (list: PromotionListResponse[], startIndex: number, endIndex: number) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    const onDragEnd = async (result: DropResult) => {
        if (!result.destination) {
            return
        }

        setLoading(true)

        const orderedItems = reorder(items, result.source.index, result.destination.index)

        const payload = orderedItems.map((item, index) => ({
            id: item.id,
            weight: index + 1,
        }))
        try {
            const response = await props.promotionService.updateWeight(props.accountSlug, payload)
            const sortedPromotions = sortBy(response, 'weight')
            setLoading(false)
            setItems(sortedPromotions)
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! We could not retreive the promotions. Please try again.',
            )
            setLoading(false)
        }
    }

    const onDelete = async (promotionId: string) => {
        try {
            setLoading(true)
            await props.promotionService.delete(promotionId, props.accountSlug)
            await fetchPromotions()
        } catch {
            props.replaceMessages('server_error', 'error', 'Oops! We could not delete the promotion. Please try again.')
        } finally {
            setLoading(false)
        }
    }

    const renderSearchBar = () => {
        return (
            <SearchContainer style={{ margin: '1.5em' }}>
                <SearchField placeholder="Search by name" />
                <ActionButton
                    kind="action"
                    size="medium"
                    onClick={() => {
                        props.history.push(`/account/${props.accountSlug}/engage/tools/promotions/type`)
                    }}
                    id="add-new-promotion"
                >
                    + Add new
                </ActionButton>
            </SearchContainer>
        )
    }

    const renderTableData = () => {
        return (
            <InlineDataTable columnWidths={columnWidths} id="promotions-list">
                <HeaderRow>
                    <TableHeader>Arrange</TableHeader>
                    <TableHeader>Name</TableHeader>
                    <TableHeader>Audience</TableHeader>
                    <TableHeader>Valid date</TableHeader>
                    <TableHeader nonInteractive />
                </HeaderRow>
                {filteredItems.length === 0 ? (
                    <NoResultsRow text="There are no configured promotions yet." />
                ) : (
                    renderTableBody()
                )}
            </InlineDataTable>
        )
    }

    const renderTableBody = () => {
        return (
            <TableBody
                accountSlug={props.accountSlug}
                items={filteredItems}
                onDragEnd={onDragEnd}
                onDelete={onDelete}
                audiences={audiences.data}
            />
        )
    }

    return (
        <>
            {loading && <TableLoader />}
            {!loading && (
                <>
                    {renderSearchBar()}
                    {renderTableData()}
                </>
            )}
        </>
    )
}

export default Promotions
