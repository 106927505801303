import * as React from 'react'
import styled from 'styled-typed'
import { TextInput } from 'uiComponents/input'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox } from 'uiComponents/input'

interface SearchProps {
    navigation: Navigation
    match: RouteMatch<any>
    placeholder?: string
    style?: React.CSSProperties
    disabled?: boolean
    hideExtendedSearch?: boolean
}

interface SearchState {
    search: string
    extendedSearch: boolean
}

export const TopSearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    min-height: 2.5rem;
`

const Container = styled.div`
    display: flex;
    align-items: center;
`
const IconWrapper = styled.div`
    background: ${(props) => props.theme.colors.boyBlue};
    color: ${(props) => props.theme.colors.white};
    border-radius: 0 4px 4px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
    cursor: pointer;
    margin-right: 1em;

    &.disabled {
        opacity: 0.5;
    }
`
const CheckboxText = styled.div`
    font-size: 0.75em;
    cursor: pointer;
    margin-left: 0.3em;
`
const SearchFieldInput = styled(TextInput)`
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.border};
    border-right: none;
    border-radius: 4px 0 0 4px;
    font-size: 0.75em;
    width: 17em;
    height: 2rem;

    &:focus {
        box-shadow: none;
        border: 1px solid ${(props) => props.theme.colors.boyBlue};
    }
`

class SearchWithButtonField extends React.PureComponent<SearchProps, SearchState> {
    constructor(props: SearchProps) {
        super(props)
        const query = this.props.navigation.query()
        this.state = {
            search: query.q || '',
            extendedSearch: query.searchType === 'extended',
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onEnter)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onEnter)
    }

    componentDidUpdate(prevProps: SearchProps) {
        const prevQuery = prevProps.navigation.query()
        const query = this.props.navigation.query()
        if (query.q !== this.state.search && query.q !== prevQuery.q) {
            this.setState({ search: '' })
        }
    }

    onEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            this.executeSearch()
        }
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ search: e.target.value })
    }

    executeSearch = () => {
        if (!this.props.disabled) {
            this.props.navigation.addQueryWithReplace({
                q: this.state.search.trim(),
                page: '1',
            })
        }
    }

    toggleExtendedSearch = () => {
        if (!this.props.disabled) {
            this.props.navigation.addQueryWithReplace({
                searchType: this.state.extendedSearch ? 'simple' : 'extended',
            })
            this.setState({ extendedSearch: !this.state.extendedSearch })
        }
    }

    render() {
        return (
            <Container>
                <SearchFieldInput
                    placeholder={this.props.placeholder || 'Search...'}
                    value={this.state.search}
                    onChange={this.handleChange}
                    disabled={this.props.disabled}
                    style={this.props.style}
                />
                <IconWrapper onClick={() => this.executeSearch()} className={this.props.disabled ? 'disabled' : ''}>
                    <FontAwesomeIcon icon={faSearch} />
                </IconWrapper>
                {!this.props.hideExtendedSearch && (
                    <>
                        <Checkbox
                            checked={this.state.extendedSearch}
                            onChange={this.toggleExtendedSearch}
                            disabled={this.props.disabled}
                            style={{ cursor: 'pointer' }}
                        />
                        <CheckboxText onClick={() => this.toggleExtendedSearch()}>Extended search</CheckboxText>
                    </>
                )}
            </Container>
        )
    }
}

export default withNavigation(SearchWithButtonField)
