import * as React from 'react'
import { withAdminPermission } from './hocs'

interface PermissionProps {
    children: React.ReactNode
    isAdmin: boolean
}

function AdminRequired({ children, isAdmin }: PermissionProps) {
    if (!isAdmin || !children) {
        return null
    }

    return <>{children}</>
}

export default withAdminPermission(AdminRequired)
