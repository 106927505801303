import * as React from 'react'
import styled from 'styled-typed'
import Svg from 'svg'
import { buttonReset } from 'uiComponents/buttons'
import conviousLogo from 'images/convious-logo.svg'
import shoutoutPhoto from 'images/brand-photo-1.jpg'

const PageContainer = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

    font-family: 'Raleway', sans-serif;
    height: 100%;
    width: 100%;
    background: ${(props) => props.theme.colors.white};
    margin: 0 auto;
`

const LogoBar = styled.div`
    padding: 1.25em 2.5em;
    text-align: center;

    @media screen and (min-width: 600px) {
        text-align: left;
    }
`

const Logo = styled(Svg)`
    width: 8em;
`

const ShoutoutContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 600px) {
        flex-direction: row;
        align-items: stretch;

        & > * {
            flex: 1;
        }
    }
`

const Shoutout = styled.div`
    background: ${(props) => props.theme.colors.convious};
    color: ${(props) => props.theme.colors.white};
    font-weight: bold;
    padding: 6em 2em;
    text-align: center;

    @media screen and (min-width: 900px) {
        height: 25em;
        padding: 8em 2em;
    }

    @media screen and (min-width: 1200px) {
        height: 32.5em;
        padding: 10em 2em;
    }

    & > * {
        font-size: 3.125em;

        @media screen and (min-width: 900px) {
            font-size: 4em;
        }

        @media screen and (min-width: 1200px) {
            font-size: 5.625em;
        }
    }
`

const ShoutoutImage = styled.div`
    background: url(${(props) => shoutoutPhoto});
    background-size: cover;
    background-position: bottom left;
    height: 16.875em;

    @media screen and (min-width: 600px) {
        height: auto;
        background-size: auto 150%;
    }
`

const Content = styled.div`
    padding: 2.125em 0;
    text-align: center;
`

const Heading = styled.h1`
    color: ${(props) => props.theme.colors.convious};
    font-size: 1.75em;
    text-align: center;
    margin: 0 0 1em;
`

const Paragraph = styled.p`
    font-weight: bold;
    text-align: center;
    margin-bottom: 2em;
`

const CtaButton = styled.a`
    ${buttonReset}
    text-decoration: none;
    color: #000;
    border: 3px solid ${(props) => props.theme.colors.convious};
    border-radius: 27px;
    padding: 1em 2.25em;
    display: inline-block;
    font-weight: bold;

    transition: color 0.1s ease-in, background 0.1s ease-in;

    &:hover {
        color: ${(props) => props.theme.colors.white};
        background: ${(props) => props.theme.colors.convious};
    }
`

export function IntegrationSuccessPage() {
    return (
        <PageContainer>
            <LogoBar>
                <Logo src={conviousLogo} />
            </LogoBar>
            <ShoutoutContainer>
                <Shoutout>
                    <div>
                        As easy as
                        <br />
                        one click.
                    </div>
                </Shoutout>
                <ShoutoutImage />
            </ShoutoutContainer>
            <Content>
                <Heading>And it&apos;s done!</Heading>
                <Paragraph>Convious integration has been successful!</Paragraph>
                <CtaButton href="/">Open Convious</CtaButton>
            </Content>
            <style>{'html { background: transparent; }'}</style>
        </PageContainer>
    )
}
