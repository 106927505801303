import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-typed'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome'

const Wrapper = styled.div<{ fixedMaxSize?: boolean }>`
    position: relative;
    align-self: center;
    ${(props) => (props.fixedMaxSize ? 'font-size: 1rem' : '')};
`
const Toggle = styled.div<InfotipStyleProps>`
    cursor: pointer;
    color: ${(props) => (props.visible ? props.theme.colors.textDark : props.theme.colors.textLight)};
    margin: 0 0.2rem;
    transition: color 0.1s ease-in;
`
export const InfoBox = styled.div<InfotipStyleProps>`
    position: absolute;
    top: ${(props) => (props.bottom ? (props.smallBoxText ? '2em' : '2rem') : '')};
    bottom: ${(props) => (props.bottom ? '' : props.smallBoxText ? '2em' : '2rem')};
    left: ${(props) => (props.pointer === 'left' ? `${props.offset ? props.offset : -0.45}rem` : '')};
    right: ${(props) => (props.pointer === 'left' ? '' : `${props.offset ? props.offset : -0.55}rem`)};
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : '')};
    min-width: ${(props) => (props.minWidth ? props.minWidth : '')};
    width: ${(props) => (props.maxWidth ? props.maxWidth : '')}; /* Edge does not support max-content */
    width: max-content;
    white-space: initial;
    text-align: left;
    padding: ${(props) => (props.smallBoxText ? '0.4rem 0.6rem' : '0.8rem')};
    background: ${(props) => props.theme.colors.textDark};
    color: ${(props) => props.theme.colors.white};
    border-radius: 0.42em;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    display: none;
    transition: opacity 0.3s ease-in;
    font-size: ${(props) => (props.smallBoxText ? '0.75rem' : '0.875rem')};
    line-height: ${(props) => (props.smallBoxText ? '0.9rem' : '1.2rem')};
    font-weight: normal;
    z-index: -1;

    &.message-visible {
        display: block;
        z-index: 100;
    }

    & span.link {
        color: ${(props) => props.theme.colors.boyBlue};
        text-decoration: underline;
        cursor: pointer;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        min-width: 20rem;
    }
`

export const Pointer = styled.div<InfotipStyleProps>`
    position: absolute;
    ${(props) =>
        props.pointer
            ? props.pointer + `: ${props.offset ? props.offset : 1}em`
            : `left: ${props.offset ? props.offset : 1}em`};
    ${(props) => (props.bottom ? 'top: -0.3em;' : 'bottom: -0.3em;')}
    width: 0.6em;
    height: 0.6em;
    transform: rotate(45deg);
    background: ${(props) => props.theme.colors.textDark};
`

const HoverInfotipContainer = styled.div`
    position: relative;
`
const DisabledItemWrapper = styled.div`
    &.disabled:hover {
        opacity: 0.5;
        font-weight: normal;
    }
`
const HoverInfotip = styled(InfoBox)<{
    offsetLeft: string
    offsetTop: number
    width?: string
    bottom?: boolean
    toTheLeft?: boolean
}>`
    display: flex;
    font-size: 12px;
    bottom: inherit;
    ${(props) => (props.bottom ? `top: ${props.offsetTop + 5}px;` : `top: -${props.offsetTop + 5}px;`)}
    ${(props) => !props.toTheLeft && `left: ${props.offsetLeft ? props.offsetLeft : '0'};`}

  padding: .7em 1em;
    opacity: 0;
    transition: opacity 0.2s;
    width: ${(props) => (props.width ? props.width : '100%')};

    ${HoverInfotipContainer}:hover &.visible {
        opacity: 1;
        z-index: 1;
    }
    &&&:hover {
        opacity: 0;
    }
`

interface DisabledItemHoverInfotipProps {
    children: React.ReactNode
    infotipText: string
    active?: boolean
    offsetLeft?: string
    offsetTop?: number
    width?: string
    infotipStyle?: React.CSSProperties
    bottom?: boolean
    toTheLeft?: boolean
}

export const DisabledItemHoverInfotip = (props: DisabledItemHoverInfotipProps) => {
    const { children, infotipText, active, offsetLeft, offsetTop, width, bottom, toTheLeft } = props
    const [childHeight, setChildHeight] = useState<number>(0)
    const infotip = useRef(null)

    useEffect(() => {
        // @ts-ignore: Object is possibly 'null'.
        setChildHeight(infotip.current.offsetHeight)
    }, [])

    return (
        <HoverInfotipContainer>
            <HoverInfotip
                className={active ? 'visible' : ''}
                ref={infotip}
                offsetLeft={offsetLeft || ''}
                width={width}
                offsetTop={offsetTop || childHeight}
                style={props.infotipStyle}
                bottom={bottom}
                toTheLeft={toTheLeft}
            >
                {infotipText}
            </HoverInfotip>
            <DisabledItemWrapper className={active ? 'disabled' : ''}>{children}</DisabledItemWrapper>
        </HoverInfotipContainer>
    )
}

interface InfotipStyleProps {
    visible?: boolean
    bottom?: boolean
    maxWidth?: string
    minWidth?: string
    pointer?: 'left' | 'right'
    offset?: number
    smallBoxText?: boolean
}

interface InfotipProps {
    bottom?: boolean
    maxWidth?: string
    minWidth?: string
    pointer?: 'left' | 'right'
    pointerOffset?: number
    boxOffset?: number
    children: React.ReactNode
    smallBoxText?: boolean
    fixedMaxSize?: boolean
    allowDefault?: boolean
}

interface InfotipState {
    visible: boolean
}

class Infotip extends React.Component<InfotipProps & React.HTMLAttributes<HTMLElement>, InfotipState> {
    popup: HTMLDivElement | null = null

    constructor(props: InfotipProps) {
        super(props)
        this.state = {
            visible: false,
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.outsideClick, false)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.outsideClick, false)
    }

    outsideClick = (ev: MouseEvent) => {
        if (this.state.visible) {
            if (!this.props.allowDefault) {
                ev.preventDefault()
            }
            if (this.popup && this.popup.contains && !this.popup.contains(ev.target as Node)) {
                this.setState({
                    visible: false,
                })
            }
        }
    }

    setPopupRef = (node: HTMLDivElement) => {
        this.popup = node
    }

    toggleShowTip = (ev: React.MouseEvent) => {
        ev.stopPropagation()
        this.setState({ visible: !this.state.visible })
    }

    showTip = () => {
        this.setState({ visible: true })
    }

    hideTip = () => {
        this.setState({ visible: false })
    }

    render() {
        const { visible } = this.state
        const {
            children,
            maxWidth,
            minWidth,
            pointer,
            bottom,
            pointerOffset,
            boxOffset,
            style,
            smallBoxText,
            fixedMaxSize,
        } = this.props

        return (
            <Wrapper style={{ ...style }} ref={this.setPopupRef} fixedMaxSize={fixedMaxSize}>
                <Toggle
                    className="tooltip-toggle"
                    visible={this.state.visible}
                    onClick={this.toggleShowTip}
                    onMouseEnter={this.showTip}
                    onMouseLeave={this.hideTip}
                >
                    <FontAwesomeIcon icon={faInfoCircle as IconProp} />
                </Toggle>
                <InfoBox
                    bottom={bottom}
                    pointer={pointer}
                    maxWidth={maxWidth}
                    minWidth={minWidth}
                    offset={boxOffset}
                    className={visible ? 'message-visible tooltip-box' : 'tooltip-box'}
                    smallBoxText={smallBoxText}
                >
                    <Pointer bottom={bottom || false} pointer={pointer || 'right'} offset={pointerOffset} />
                    {children}
                </InfoBox>
            </Wrapper>
        )
    }
}

export default Infotip
