import * as React from 'react'
import styled from 'styled-typed'
import { AdditionalDataNotice } from 'uiComponents/charts/styleComponents'
import { ActionButton } from 'uiComponents/buttons'
import SearchWithButtonField from 'uiComponents/search/searchWithButton'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

interface SearchSectionProps {
    poolsCount: number
    depletedPools: number
    onAddNew: () => void
}

export function SearchSection(props: SearchSectionProps) {
    const depletesNoteTextPart = props.depletedPools > 1 ? 's are' : ' is'

    return (
        <Container style={{ margin: '1.25rem' }}>
            <SearchWithButtonField hideExtendedSearch />
            <Container>
                <AdditionalDataNotice style={{ maxWidth: '33em' }}>
                    You have <span>{props.poolsCount}</span> available coupon pool
                    {props.poolsCount === 1 ? '' : 's'}.
                    {!!props.depletedPools && (
                        <>
                            {' '}
                            <span>{props.depletedPools}</span> coupon pool
                            {depletesNoteTextPart} depleted.
                        </>
                    )}
                </AdditionalDataNotice>
                <ActionButton
                    kind="action"
                    size="medium"
                    style={{ marginLeft: '1.5em' }}
                    onClick={props.onAddNew}
                    id="add-new-pool"
                    data-userpilot="discounts-add-new-pool"
                >
                    + Add new
                </ActionButton>
            </Container>
        </Container>
    )
}
