import * as React from 'react'
import styled from 'styled-typed'
import { InstructionsCard } from 'snippet/instructionsCard'
import emailIcon from './snippetContainer/email.svg'
import { ActionButton } from 'uiComponents/buttons'
import { TextInput } from 'uiComponents/input'
import { Account } from 'auth/state'
import { isValidEmail } from 'utils/formFieldChecks'
import { ValidationMessage } from 'uiComponents/form/formElements'
import { SnippetService } from 'snippet/snippetService'

const Button = styled(ActionButton)`
    width: 9.375em;
    margin-top: 1.75em;
    opacity: 0;
    transition: opacity 0.5s ease-in;

    &.visible {
        opacity: 1;
    }
`
const SendButton = styled(Button)`
    position: relative;
    left: -9.4em;
`
const EmailHeader = styled.div`
    font-size: 0.875em;
    margin: 2.2em 0 2em -6em;
`
const HeaderItem = styled.div`
    margin: 1em 0;
    position: relative;
`
const ItemText = styled.div`
    opacity: 0.75;
    display: inline-block;
`
const ItemName = styled(ItemText)`
    width: 6em;
`
const EmailInput = styled(TextInput)`
    width: 30em;
    padding-left: 0.7em;
    height: 2em;
    opacity: 0.75;
    font-weight: normal;

    ::placeholder {
        font-style: italic;
        font-weight: normal;
    }
`
const EmailMessage = styled(ValidationMessage)`
    position: absolute;
    top: -1.2em;
    font-size: 0.9em;
`
const EmailBody = styled.div`
    font-size: 0.875em;
    line-height: 1.357em;
`
const Code = styled.div`
    font-weight: bold;
    word-break: break-all;
`
const EmailText = styled.div`
    opacity: 0.75;
    margin: 1em 0;
`

interface SendDeveloperCardProps {
    email: string
    account: Account
    snippetService: SnippetService
    handleUnknownError: () => void
}

interface SendDeveloperCardState {
    devEmail: string
    emailMessage: string
    emailSent: boolean
}

export class SendDeveloperCard extends React.Component<SendDeveloperCardProps, SendDeveloperCardState> {
    constructor(props: SendDeveloperCardProps) {
        super(props)
        this.state = {
            devEmail: '',
            emailMessage: '',
            emailSent: false,
        }
    }

    onSend = async () => {
        if (!(await this.checkValidEmail())) {
            return
        }

        try {
            const emailData = {
                devEmail: this.state.devEmail,
                url: this.props.account.name,
                widgetSlug: this.props.account.slug,
            }
            await this.props.snippetService.sendSnippetToDev(emailData)
            this.setState({ emailSent: true })
            setTimeout(() => this.setState({ emailSent: false }), 2000)
        } catch {
            this.props.handleUnknownError()
        }
    }

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ devEmail: event.target.value, message: false } as any)
    }

    checkValidEmail = async () => {
        if (!(await isValidEmail(this.state.devEmail || ''))) {
            this.setState({ emailMessage: 'Please enter a valid email address' })
            return false
        } else {
            this.setState({ emailMessage: '' })
        }
        return true
    }

    render() {
        const { email, account } = this.props
        const { devEmail, emailMessage, emailSent } = this.state
        const closedSubHeader = 'You have someone else who manages your page? Email them the instructions.'
        const firstParagraph = `I've just created a Convious account for ${account.name} and selected the option \
      ‘I want to email the script to my developer’. Please copy the following snippet and paste it \
      at the top of the <head> tag on every page we want Convious to display and track.`
        const lastParagraph = `If you have any questions don’t hesitate to email Convious at \
      installation-support@convious.com, or email me at ${email}.`
        const openingComment = '<!-- start Convious Code -->'
        const closingComment = '<!-- end Convious Code -->'
        const script = `<script id="convious_loader" src="https://client.convious-app.com/loader.js?k=${account.slug}"></script>`
        const emailFromAddress = 'Convious <installation-support@convious.com>'
        const emailSubject = `Please install Convious at ${account.name}`

        return (
            <InstructionsCard
                iconSrc={emailIcon}
                header="I want to email the script to my developer"
                closedSubHeader={closedSubHeader}
            >
                <EmailHeader>
                    <HeaderItem>
                        <ItemName>To:</ItemName>
                        <EmailMessage className={emailMessage ? 'validation-message-visible' : ''}>
                            {emailMessage || '&nbsp;'}
                        </EmailMessage>
                        <EmailInput
                            value={devEmail ? devEmail : ''}
                            onChange={this.handleInputChange}
                            placeholder="Add your developer’s email here"
                            onFinishTyping={this.checkValidEmail}
                            status={this.state.emailMessage ? 'error' : 'normal'}
                        />
                    </HeaderItem>
                    <HeaderItem>
                        <ItemName>From:</ItemName>
                        <ItemText>{emailFromAddress}</ItemText>
                    </HeaderItem>
                    <HeaderItem>
                        <ItemName>Subject:</ItemName>
                        <ItemText>{emailSubject}</ItemText>
                    </HeaderItem>
                </EmailHeader>
                <EmailBody>
                    <EmailText>Hi,</EmailText>
                    <EmailText>{firstParagraph}</EmailText>
                    <Code>{openingComment}</Code>
                    <Code>{script}</Code>
                    <Code>{closingComment}</Code>
                    <EmailText>{lastParagraph}</EmailText>
                    <EmailText style={{ marginBottom: '0' }}>Thanks!</EmailText>
                </EmailBody>
                <Button kind="action" size="large" className={emailSent ? 'visible' : ''}>
                    Sent!
                </Button>
                <SendButton size="large" onClick={this.onSend} className={emailSent ? '' : 'visible'}>
                    Send
                </SendButton>
            </InstructionsCard>
        )
    }
}
