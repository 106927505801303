import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { faExclamationCircle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC } from 'react'
import styled from 'styled-components'
import { Grid } from 'uiComponents/layout'
import { MessageKind } from 'uiComponents/messages'
import { Body, Headline } from 'uiComponents/typography'

interface InlineMessageContainerProps {
    type: MessageKind
}

const InlineMessageContainer = styled.div<InlineMessageContainerProps>`
    border: 1px solid
        ${(props) => {
            if (props.type === 'error') {
                return props.theme.colors.status.error
            }
            if (props.type === 'info') {
                return props.theme.colors.boyBlue
            }

            return '#ffc800'
        }};
    border-left: 4px solid
        ${(props) => {
            if (props.type === 'error') {
                return props.theme.colors.status.error
            }
            if (props.type === 'info') {
                return props.theme.colors.boyBlue
            }

            return '#ffc800'
        }};
    background: ${(props) => {
        if (props.type === 'error') {
            return '#fce9e8'
        }

        if (props.type === 'info') {
            return props.theme.colors.boyBlueShades[5]
        }

        return '#fffae6'
    }};
    padding: 0.5rem;
    display: inline-flex;
    align-items: center;

    & .message-icon-container {
        color: ${(props) => {
            if (props.type === 'error') {
                return props.theme.colors.status.error
            }

            if (props.type === 'info') {
                return props.theme.colors.boyBlue
            }

            return props.theme.colors.status.warn
        }};
    }
`

interface InlineMessageProps {
    header?: string
    type: MessageKind
    onClose?: () => void
    className?: string
}

const getIcon = (type: MessageKind) => {
    switch (type) {
        case 'warn':
            return <FontAwesomeIcon icon={faExclamationCircle as IconProp} />
        case 'info':
            return <FontAwesomeIcon icon={faInfoCircle as IconProp} />
        default:
            return <FontAwesomeIcon icon={faExclamationCircle as IconProp} />
    }
}

const InlineMessage: FC<InlineMessageProps> = (props) => {
    const { header, children, type, onClose, className } = props

    return (
        <InlineMessageContainer className={className} type={type}>
            <Grid item={1} className="message-icon-container" style={{ paddingTop: '0.15rem' }}>
                {getIcon(type)}
            </Grid>
            <Grid item style={{ paddingBottom: 0 }}>
                <Grid container style={{ paddingBottom: 0 }}>
                    <Grid item={12} style={{ paddingBottom: 0 }}>
                        <Grid container>
                            <Grid item={8} style={{ paddingBottom: 0 }}>
                                <Headline style={{ margin: 0 }} size={6}>
                                    {header}
                                </Headline>
                            </Grid>
                            {!!onClose && (
                                <Grid
                                    item={4}
                                    style={{ justifyContent: 'flex-end', display: 'flex', paddingBottom: 8 }}
                                >
                                    <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={onClose} icon={faTimes} />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item={12} style={{ paddingBottom: 0 }}>
                        <Body size={2}>{children}</Body>
                    </Grid>
                </Grid>
            </Grid>
        </InlineMessageContainer>
    )
}

export default InlineMessage
