import React, { useMemo } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ActionButton from 'uiComponents/buttons'
import { Col, Row } from 'uiComponents/flex'
import { FormWrapper } from 'uiComponents/form/form'
import { CheckboxFieldFormik } from 'uiComponents/input'
import { LoaderWrapper } from 'uiComponents/loaders'
import { pathUtils } from 'utils/pathnameFormatter'
import { CODES_PATHS, EditCodePageParams } from 'venue/bookingCodes/paths'
import { useEditDiscountCodeMutation, useGetDiscountCodeDetailsQuery } from 'venue/reduxQuery'
import AffectedOrders from '../_shared/affectedOrders'
import CodeStatus from '../Pools/codeStatus'
import CodeSection from '../_shared/form/codeSection'
import ProductSection from '../_shared/form/productSection'
import ReductionSection from '../_shared/form/reductionSection'
import UsesSection from '../_shared/form/usesSection'
import { discountCodeFormatter, discountCodeSchema } from '../_shared/formUtils'
import UsageCount from '../_shared/usageCount'
import ReadOnlyLine from './readOnlyLine'
import { Field, FieldProps } from 'formik'
import { DateFormats, formatISOString } from 'utils/dates'

const EditCode = () => {
    const { accountSlug, codeId, poolId } = useParams<EditCodePageParams>()
    const { data, isFetching } = useGetDiscountCodeDetailsQuery({ accountSlug, poolUuid: poolId, codeUuid: codeId })
    const [updateCode, { isLoading }] = useEditDiscountCodeMutation()
    const initialData = useMemo(() => discountCodeFormatter.toForm(data), [data])
    const history = useHistory()
    const { search } = useLocation()

    const goBack = () => {
        history.push(
            pathUtils.addQueryString(
                pathUtils.populateParams(CODES_PATHS.fullPaths.poolsPage, { accountSlug }),
                search,
            ),
        )
    }

    return (
        <div style={{ padding: '1.7em 1.5em', alignItems: 'baseline' }}>
            <LoaderWrapper loading={isFetching || isLoading}>
                <FormWrapper
                    enableReinitialize
                    validationSchema={discountCodeSchema}
                    initialValues={initialData}
                    onSubmit={(values, { setErrors }) => {
                        const formattedData = discountCodeFormatter.toDomain(values)
                        updateCode({
                            data: formattedData,
                            accountSlug,
                            codeUuid: codeId,
                            poolUuid: poolId,
                        })
                            .unwrap()
                            .then((response) => {
                                const data = response?.response

                                if (!data?.error) {
                                    return goBack()
                                }
                            })
                            .catch((error) => {
                                if (error.response) {
                                    const mappedErrors = discountCodeFormatter.formatErrors(
                                        error.response?.error?.errors,
                                    )

                                    setErrors(mappedErrors)
                                }
                            })
                    }}
                >
                    <Row>
                        <Col span={6}>
                            <Row>
                                <Col span={12}>
                                    <ReadOnlyLine title="Pool name:">{data?.code}</ReadOnlyLine>
                                </Col>
                                <Col span={12}>
                                    <ReadOnlyLine title="Order ID:">
                                        <AffectedOrders code={data?.code} orders={data?.orders} />
                                    </ReadOnlyLine>
                                </Col>
                                <Col span={12} style={{ marginBottom: '.5em' }}>
                                    <ReadOnlyLine title="Redemption count:">
                                        <UsageCount usage={data?.usage} />
                                    </ReadOnlyLine>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={12}>
                                    <ReadOnlyLine title="Status:">
                                        <Row gutter={8} vAlign="center">
                                            <Col span="auto">
                                                <CodeStatus status={data?.status as any} />
                                            </Col>
                                            <Col span="auto">
                                                {!!data?.validity?.toDate && (
                                                    <div>
                                                        till{' '}
                                                        {formatISOString(data?.validity?.toDate, DateFormats.LONG_DATE)}
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </ReadOnlyLine>
                                </Col>
                                <Col span={12}>
                                    <ReadOnlyLine title="Applies to:">
                                        {initialData.type === 'cart' ? 'All products' : 'Specific product'}
                                    </ReadOnlyLine>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6} style={{ marginBottom: '1.5em' }}>
                            <CodeSection />
                        </Col>
                        <Field name="type">
                            {({ field }: FieldProps) => {
                                if (field.value === 'productId') {
                                    return (
                                        <Col span={6} style={{ marginBottom: '1.5em' }}>
                                            <ProductSection />
                                        </Col>
                                    )
                                }
                                return null
                            }}
                        </Field>
                    </Row>
                    <Row>
                        <Col span={6} style={{ marginBottom: '1.5em' }}>
                            <Row>
                                <Col span="auto">
                                    <ReductionSection accountSlug={accountSlug} />
                                </Col>
                                <Col span="auto">
                                    <UsesSection />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} style={{ marginBottom: '3em' }}>
                            <CheckboxFieldFormik variant="inline" name="remainderUsable" label="Remainder usable" />
                        </Col>
                    </Row>
                    <Row align="flex-end">
                        <Col span="auto">
                            <ActionButton secondary size="large" onClick={goBack}>
                                Cancel
                            </ActionButton>
                        </Col>
                        <Col span="auto">
                            <ActionButton kind="default" size="large" type="submit">
                                Save
                            </ActionButton>
                        </Col>
                    </Row>
                </FormWrapper>
            </LoaderWrapper>
        </div>
    )
}

export default EditCode
