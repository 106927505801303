import React, { useEffect, useState, useContext } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { RuleFormContext } from './formContext'
import { Row, Col } from 'uiComponents/flex'
import { ActionButton } from 'uiComponents/buttons'
import { NumberInput, SingleSelect } from 'uiComponents/input'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { SingleSelectOption } from 'uiComponents/input'
import { ArticleFromApi } from '../../types'

function RuleSetupSection() {
    const [articleOptions, setArticleOptions] = useState<SingleSelectOption[]>([])
    const { catOptions, articles, rule, markErrors, updateRule } = useContext(RuleFormContext)

    const setOptionsForArticles = (allArticles: ArticleFromApi[], catUuid: string) => {
        const catArticles = allArticles.filter((a) => a.categories.find((cat) => cat.id === catUuid))
        const options = catArticles.map((a) => ({
            name: a.name['en'] || a.name[Object.keys(a.name)?.[0]],
            value: a.id,
        }))
        setArticleOptions(options)
    }

    useEffect(() => {
        setOptionsForArticles(articles, rule.categoryUuid)
    }, [articles, rule.categoryUuid])

    const onCategoryChange = (value: string) => {
        if (rule.categoryUuid === value) {
            return
        }
        const updatedRule = cloneDeep(rule)
        updatedRule.categoryUuid = value
        updatedRule.productUuid = ''
        updatedRule.rulePayload.productUuidPaid = ''
        updatedRule.rulePayload.productUuidFree = ''
        updateRule(updatedRule)
    }

    const onProductPaidChange = (value: string) => {
        const updatedRule = cloneDeep(rule)
        updatedRule.productUuid = value
        updatedRule.rulePayload.productUuidPaid = value
        updateRule(updatedRule)
    }

    const onProductFreeChange = (value: string) => {
        const updatedRule = cloneDeep(rule)
        updatedRule.rulePayload.productUuidFree = value
        updateRule(updatedRule)
    }

    const onProductPaidAmountChange = (value: string) => {
        const updatedRule = cloneDeep(rule)
        updatedRule.rulePayload.numberOfItemsPaid = value ? +value : null
        updateRule(updatedRule)
    }

    const onProductFreeAmountChange = (value: string) => {
        const updatedRule = cloneDeep(rule)
        updatedRule.rulePayload.numberOfItemsFree = value ? +value : null
        updateRule(updatedRule)
    }

    const onRuleSetupClear = () => {
        const updatedRule = cloneDeep(rule)
        updatedRule.categoryUuid = ''
        updatedRule.productUuid = ''
        updatedRule.rulePayload.productUuidPaid = ''
        updatedRule.rulePayload.productUuidFree = ''
        updatedRule.rulePayload.numberOfItemsPaid = null
        updatedRule.rulePayload.numberOfItemsFree = null
        updateRule(updatedRule)
    }

    return (
        <>
            <Row>
                <Col span={7}>
                    <FormItem htmlFor="category">
                        <FormItemName>Category</FormItemName>
                        <SingleSelect
                            nested
                            options={catOptions}
                            selected={rule.categoryUuid}
                            noSelectOption="Select a category"
                            withSearch
                            height="2.6em"
                            maxHeight="25em"
                            onSelect={onCategoryChange}
                            status={markErrors && !rule.categoryUuid ? 'error' : 'normal'}
                        />
                    </FormItem>
                    <ValidationMessage />
                </Col>
                <Col span={5} />
            </Row>
            <Row>
                <Col span={1}>
                    <FormItem htmlFor="rule-one">
                        <FormItemName>&nbsp;</FormItemName>
                        <SingleSelect
                            options={[{ name: 'BUY', value: 'buy' }]}
                            selected="buy"
                            height="2.6em"
                            onSelect={() => {}}
                        />
                    </FormItem>
                    <ValidationMessage />
                </Col>
                <Col span={1}>
                    <FormItem htmlFor="buy-products-no">
                        <FormItemName>&nbsp;</FormItemName>
                        <NumberInput
                            id="buy-products-no"
                            integerOnly
                            value={rule.rulePayload.numberOfItemsPaid || ''}
                            placeholder="1"
                            min={1}
                            max={100}
                            status={markErrors && !rule.rulePayload.numberOfItemsPaid ? 'error' : 'normal'}
                            onChange={(e) => onProductPaidAmountChange(e.target.value)}
                        />
                    </FormItem>
                    <ValidationMessage />
                </Col>
                <Col span={5}>
                    <FormItem htmlFor="buy-product" style={{ position: 'relative' }}>
                        <FormItemName>Product</FormItemName>
                        <SingleSelect
                            options={articleOptions}
                            selected={rule.rulePayload.productUuidPaid}
                            noSelectOption="Select a product"
                            height="2.6em"
                            maxHeight="25em"
                            onSelect={onProductPaidChange}
                            disabled={!rule.categoryUuid}
                            status={markErrors && !rule.rulePayload.productUuidPaid ? 'error' : 'normal'}
                        />
                    </FormItem>
                    <ValidationMessage />
                </Col>
                <Col span={5} />
            </Row>
            <Row>
                <Col span={1}>
                    <FormItem htmlFor="rule-two">
                        <FormItemName>&nbsp;</FormItemName>
                        <SingleSelect
                            options={[{ name: 'GET', value: 'get' }]}
                            selected="get"
                            noSelectOption="Select a product"
                            height="2.6em"
                            onSelect={() => {}}
                        />
                    </FormItem>
                </Col>
                <Col span={1}>
                    <FormItem htmlFor="free-products-no">
                        <FormItemName>&nbsp;</FormItemName>
                        <NumberInput
                            id="free-products-no"
                            integerOnly
                            value={rule.rulePayload.numberOfItemsFree || ''}
                            placeholder="1"
                            min={1}
                            max={100}
                            status={markErrors && !rule.rulePayload.numberOfItemsFree ? 'error' : 'normal'}
                            onChange={(e) => onProductFreeAmountChange(e.target.value)}
                        />
                        <ValidationMessage />
                    </FormItem>
                </Col>
                <Col span={5}>
                    <FormItem htmlFor="free-product">
                        <FormItemName>Product</FormItemName>
                        <SingleSelect
                            options={articleOptions}
                            selected={rule.rulePayload.productUuidFree}
                            noSelectOption="Select a product"
                            height="2.6em"
                            maxHeight="25em"
                            onSelect={onProductFreeChange}
                            disabled={!rule.categoryUuid}
                            status={markErrors && !rule.rulePayload.productUuidFree ? 'error' : 'normal'}
                        />
                        <ValidationMessage />
                    </FormItem>
                </Col>
                <Col span={2}>
                    <FormItem htmlFor="rule-three">
                        <FormItemName>&nbsp;</FormItemName>
                        <SingleSelect
                            options={[{ name: 'FREE', value: 'free' }]}
                            selected="free"
                            noSelectOption="Select a product"
                            height="2.6em"
                            onSelect={() => {}}
                        />
                    </FormItem>
                    <ValidationMessage />
                </Col>
                <Col span={1} style={{ paddingTop: '2.25em' }}>
                    <ActionButton kind="action" size="small" onClick={onRuleSetupClear}>
                        Clear
                    </ActionButton>
                </Col>
                <Col span={2} />
            </Row>
        </>
    )
}

export default RuleSetupSection
