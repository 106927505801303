import * as React from 'react'
import { ImagesService } from 'http/imagesService'
import { Col } from 'uiComponents/flex'
import Infotip from 'uiComponents/infotip'
import { MessageKind } from 'uiComponents/messages'
import { withFeatures } from 'features'
import { WhiteListUrlSection } from 'engageTools/studio/modalwindow/whitelistUrlSection'
import { ConfigurationUnit } from 'uiComponents/studio/menuItem'
import { ModalWindowConfiguration, GetConfigValueFunc, OnChangeFunc, UrlRules } from 'engageTools/studio/schema'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { FormFieldComponent, ToggleComponent } from 'uiComponents/studio/form/fieldComponents'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { FieldType, SelectField, ToggleField } from 'uiComponents/studio/form/fieldComponentSchema'
import { NumberFieldElement } from 'engageTools/studio/form/numberFieldElement'
import { UploadElement } from 'engageTools/studio/form/uploadElement'
import { ComponentsService } from 'engageTools/studio/componentsService'

interface GeneralSectionProps {
    accountSlug: string
    getConfigValue: GetConfigValueFunc
    section: ConfigurationUnit
    configuration: ModalWindowConfiguration
    handleInputChange: OnChangeFunc
    componentsService: ComponentsService
    imagesService: ImagesService
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    hasFeature: (feature: string, accountSlug: string) => boolean
    urlRules: UrlRules | null
    updateUrlRules: (list: 'blacklist' | 'whitelist', logic: string, ruleType: string, value: string) => void
    clearWhitelist: () => void
    resetATranslation: (slug: string) => void
}

function GeneralSection(props: GeneralSectionProps) {
    const { section, configuration, handleInputChange, imagesService, getConfigValue, componentsService } = props
    const displayAfterSomeTime = configuration.trigger === 'afterDelay'
    const displayOnPageLoad = configuration.trigger === 'onPageLoad'
    const displayOnSpecificUrl = configuration.trigger === 'onSpecificUrl'

    const [showWhiteList, setShowWhiteList] = React.useState<boolean>(configuration.trigger === 'onSpecificUrl')

    const renderField = (field: FieldType) => {
        const isTriggerHandler = field.name === 'display-trigger'
        return (
            <FormFieldComponent
                field={field}
                handleChange={isTriggerHandler ? handleTriggerChange : props.handleInputChange}
                value={props.getConfigValue(field, props.configuration)}
            />
        )
    }

    const handleTriggerChange = (v: string, path: string) => {
        if (v === 'onSpecificUrl') {
            setShowWhiteList(true)
        } else {
            setShowWhiteList(false)
            props.clearWhitelist()
        }
        props.handleInputChange(v, path)
    }

    const renderShowIconOrButtonField = (field: ToggleField, key: number) => {
        const style = field.span && field.span < 12 ? { display: 'flex', alignItems: 'center' } : {}
        return (
            <FormItem key={key}>
                <Col span={6} style={style}>
                    <label htmlFor={field.name}>
                        {field.label}
                        {field.infotip && (
                            <Infotip pointer="left" maxWidth="16em" smallBoxText>
                                {field.infotip}
                            </Infotip>
                        )}
                    </label>
                </Col>
                <Col span={6} className="justify-right">
                    <ToggleComponent
                        field={field}
                        handleChange={(v: boolean) => handleInputChange(!v, field.path)}
                        value={!getConfigValue(field, configuration)}
                    />
                </Col>
            </FormItem>
        )
    }

    if (section.fields) {
        return (
            <>
                <SectionWrapper key={section.name} title={section.name}>
                    {section.fields.map((field, j) => {
                        const displayOn =
                            field.name === 'display'
                                ? (field as SelectField).availableOptions.find(
                                      (ao) => ao.value === configuration.target,
                                  ).name
                                : 'all'
                        const style = field.span && field.span < 12 ? { display: 'flex', alignItems: 'center' } : {}
                        const labelName = field.label === '' || field.type === 'checkbox' ? null : field.label
                        if (
                            !displayOnPageLoad &&
                            !displayOnSpecificUrl &&
                            !displayAfterSomeTime &&
                            field.name === 'show-after'
                        ) {
                            return null
                        } else if (displayAfterSomeTime && field.name === 'minimum-stay') {
                            return null
                        } else if (field.name === 'show-button' || field.name === 'show-icon') {
                            return renderShowIconOrButtonField(field as ToggleField, j)
                        } else {
                            return (
                                <FormItem key={j}>
                                    {labelName && (
                                        <Col
                                            span={!!field.span && field.span !== 12 ? 12 - field.span : 12}
                                            style={style}
                                        >
                                            <label htmlFor={field.name}>
                                                {labelName}
                                                {field.infotip && (
                                                    <Infotip pointer="left" maxWidth="16em" smallBoxText>
                                                        {field.infotip}
                                                    </Infotip>
                                                )}
                                            </label>
                                        </Col>
                                    )}
                                    {field.name === 'display' ? (
                                        <div id="display-device">{displayOn}</div>
                                    ) : (
                                        <>
                                            {field.type === 'number' && (
                                                <NumberFieldElement
                                                    field={field}
                                                    style={style}
                                                    handleChange={handleInputChange}
                                                    value={getConfigValue(field, configuration)}
                                                />
                                            )}
                                            {field.type === 'upload' && (
                                                <UploadElement
                                                    key={field.name}
                                                    field={field}
                                                    style={style}
                                                    handleChange={handleInputChange}
                                                    value={getConfigValue(field, configuration)}
                                                    imagesService={imagesService}
                                                    componentsService={componentsService}
                                                    replaceTopMessages={props.replaceTopMessages}
                                                    removeAllMessages={props.removeAllMessages}
                                                    type={field.name === 'header-logo' ? 'img' : 'icon'}
                                                    acceptSvg={field.name === 'icon-url'}
                                                />
                                            )}
                                            {field.type !== 'upload' && field.type !== 'number' && (
                                                <Col span={field.span} style={{ ...style }} className="justify-right">
                                                    {renderField(field)}
                                                </Col>
                                            )}
                                        </>
                                    )}
                                </FormItem>
                            )
                        }
                    })}
                </SectionWrapper>
                {section.name === 'Display rules' && showWhiteList && (
                    <WhiteListUrlSection
                        product="modal_window"
                        section={{
                            name: 'Whitelist',
                            label: 'Show your Pop Up on',
                            infotip: 'Define which pages of your website will show the Pop Up.',
                            fields: [],
                        }}
                        urlRules={props.urlRules}
                        updateUrlRules={props.updateUrlRules}
                    />
                )}
            </>
        )
    } else {
        return null
    }
}

export default withFeatures(GeneralSection)
