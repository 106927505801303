import React from 'react'
import styled from 'styled-typed'
import { DiscountRule } from 'products/discountRules/discountRulesService'
import { Headline, Body } from 'uiComponents/typography'
import { useGetAllArticles } from '../redux'

const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 1em 1.5em;
    background: ${(props) => props.theme.colors.background};
`
const NumberOfItems = styled(Headline)`
    margin: 0 0 0 0.7em;
`
const ProductNameWrapper = styled.div`
    background-color: ${(props) => props.theme.colors.border};
    border-radius: 2em;
    padding: 0.2em 0.5em;
    margin: 0 0.7em;
`

interface Props {
    rule: DiscountRule
}

const Definition: React.FC<Props> = ({ rule }) => {
    const articles = useGetAllArticles()

    const getArticleName = (uuid: string) => {
        const articleDetails = articles.find((a) => a.id === uuid)
        const name = articleDetails?.name?.['en']

        return <Body size={2}>{name || uuid || 'Product'}</Body>
    }

    return (
        <Container>
            <Headline size={6} style={{ margin: '0 1.5em 0 0' }}>
                Definition:
            </Headline>
            <Body size={1}>BUY</Body>
            <NumberOfItems size={6}>{rule.rulePayload.numberOfItemsPaid}</NumberOfItems>
            <ProductNameWrapper>{getArticleName(rule.rulePayload.productUuidPaid)}</ProductNameWrapper>
            <Body size={1}>GET</Body>
            <NumberOfItems size={6}>{rule.rulePayload.numberOfItemsFree}</NumberOfItems>
            <ProductNameWrapper>{getArticleName(rule.rulePayload.productUuidFree)}</ProductNameWrapper>
            <Body size={1}>FREE</Body>
        </Container>
    )
}

export default Definition
