import React, { useState } from 'react'
import styled from 'styled-components'

import { ModalDialog } from 'uiComponents/popups/modal'
import { PHONE, WEB } from './consts'
import TogglePreview from './togglePreview'
import { PreviewTogglers } from './types'
import WebPreview from './webPreview'
import PhonePreview from './phonePreview'

interface DialogProps {
    onCancel: () => void
}

const ToggleContainer = styled.div`
    position: absolute;
    bottom: 40px;
    right: 40px;
    z-index: 2;
`

export function PreviewDialog({ onCancel }: DialogProps) {
    const [activePreview, setActivePreview] = useState<PreviewTogglers>(PHONE)

    return (
        <ModalDialog onDismiss={onCancel} interactive noPadding fromTop="10%">
            <div style={{ width: '80em', height: '42em' }}>
                {activePreview === WEB ? <WebPreview /> : <PhonePreview />}
                <ToggleContainer>
                    <TogglePreview preview={activePreview} onToggle={setActivePreview} />
                </ToggleContainer>
            </div>
        </ModalDialog>
    )
}
