import * as React from 'react'
import styled from 'styled-typed'
import { Link } from 'uiComponents/typography'

const Subtitle = styled.div`
    font-size: 0.875em;
    color: ${(props) => props.theme.colors.textDark};
    font-weight: 300;
    max-width: 560px;
    line-height: 1.5em;
    opacity: 0.8;
`

const ConsentText = () => {
    return (
        <Subtitle>
            Note that Receiving or being subject to marketing messages may require consent depending on your local
            execution of GDPR. For more information visit our&nbsp;
            <Link
                href="https://support.convious.com/help/audiences-in-your-engage-tools-overview-campaign-inspiration"
                target="_blank"
            >
                Knowledge Base
            </Link>
        </Subtitle>
    )
}

export default ConsentText
