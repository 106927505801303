import * as React from 'react'
import styled from 'styled-typed'
import { History } from 'history'
import { AttachedProduct } from 'products/attachedCategoriesList'
import { AttachedCategoriesList, Container } from 'products/attachedCategoriesList'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { HeaderRow, TableHeader, Cell } from 'uiComponents/table'
import { NestedTableRow, NestedTable } from 'uiComponents/table/nestedContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'
import { withPermission } from 'admin/hocs'

const Table = styled(NestedTable)`
    & > div:last-child > ${Container} {
        border-bottom: none;
    }
`

const SortUpIcon = styled(FontAwesomeIcon)`
    transform: translateY(17%);
`

const SortDownIcon = styled(SortUpIcon)`
    transform: translateY(-20%);
`

interface AttachedProductsTreeListProps {
    products: AttachedProduct[]
    accountSlug: string
    hasPermission: (permission: string, accountSlug: string) => boolean
    history: History
    hideNumericId?: boolean
    source?: '?from=flat' | '?from=nested'
}

function AttachedProductsTreeList(props: AttachedProductsTreeListProps) {
    const [currentExpanded, setCurrentExpanded] = React.useState<string>('')

    const toggleExpanded = (id: string) => (currentExpanded === id ? setCurrentExpanded('') : setCurrentExpanded(id))

    const partnerAdmin = !!props.hasPermission && props.hasPermission('partner_admin', props.accountSlug)

    return (
        <Table columnWidths={['1em', '30em', '30em']} className="attached-products-list">
            <HeaderRow style={{ paddingLeft: '3rem' }}>
                <TableHeader noPadding nonInteractive />
                <TableHeader style={{ paddingLeft: '.5rem' }}>Product Name</TableHeader>
                {!props.hideNumericId && <TableHeader>ID</TableHeader>}
            </HeaderRow>
            {props.products.map((p) => {
                const expanded = currentExpanded === p.id
                return (
                    <div key={p.id}>
                        <NestedTableRow
                            style={{ paddingLeft: '3rem' }}
                            interactive
                            highlighted={expanded}
                            onClick={() => toggleExpanded(p.id)}
                            className={expanded ? 'expanded' : ''}
                        >
                            <Cell noPadding>
                                {expanded ? <SortUpIcon icon={faSortUp} /> : <SortDownIcon icon={faSortDown} />}
                            </Cell>
                            <Cell title={p.name} style={{ paddingLeft: '.5rem' }}>
                                <UnstyledLink
                                    to={`/account/${props.accountSlug}/products/crud/${p.id}`}
                                    disabled={!partnerAdmin}
                                >
                                    {p.name}
                                </UnstyledLink>
                            </Cell>
                            {!props.hideNumericId && <Cell>{p.numericId}</Cell>}
                        </NestedTableRow>
                        {expanded && (
                            <AttachedCategoriesList
                                visible
                                productListUuids={p.productListUuids || []}
                                accountSlug={props.accountSlug}
                                hasPermission={props.hasPermission}
                                onEditDetailsClick={() =>
                                    props.history.push(
                                        `/account/${props.accountSlug}/products/crud/${p.id}${props.source || ''}`,
                                    )
                                }
                            />
                        )}
                    </div>
                )
            })}
        </Table>
    )
}

export default withPermission(AttachedProductsTreeList)
