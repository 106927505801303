import { QueryConfig } from 'reports/queryGenerator'

export const queryConfig: QueryConfig = {
    querySetName: 'stats',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'granularity', type: 'StatsGranularity' },
        { name: 'filters', type: '[FilterDictionary]' },
    ],
    queries: [
        {
            name: 'opened',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'app_notifications_opened' }],
            presetResult: 'timeseries',
        },
        {
            name: 'sent',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'app_notifications_sent' }],
            presetResult: 'timeseries',
        },
        {
            name: 'clicked',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'app_notifications_clicked' }],
            presetResult: 'timeseries',
        },
    ],
}
