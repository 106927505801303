import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import { Row, Col } from 'uiComponents/flex'
import { Navigation } from 'navigation'
import { PopupMenu } from 'uiComponents/menus/'
import { StatsService } from 'http/statsService'
import { Card } from 'uiComponents/cards'
import { getGranularityFromQuery } from 'reports/helpers'
import { ComparisonTotal } from 'reports/schema'
import { PlainLoader } from 'uiComponents/loaders'
import StatsSection from './statsSection'
import { withFeatures } from 'features'
import { cardStyle } from './actionCard'
import { QueryConfig } from 'reports/queryGenerator'
import { startOfWeek, endOfWeek, startOfToday } from 'date-fns'
import { format } from 'date-fns'
import { parseISODate } from 'utils/dates'

const queryConfig: QueryConfig = {
    querySetName: 'DashboardPageStats',
    variablesConfig: [
        { name: 'accountSlug', type: 'String' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'comparisonType', type: 'ComparisonTypes' },
        { name: 'delayType', type: 'DelayTypes' },
        { name: 'delay', type: 'Int' },
    ],
    queries: [
        {
            name: 'revenue',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'dateFrom', 'dateTo', 'comparisonType', 'delayType', 'delay'],
            customVariables: [{ name: 'metric', customValue: 'revenue' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'orders',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'dateFrom', 'dateTo', 'comparisonType', 'delayType', 'delay'],
            customVariables: [{ name: 'metric', customValue: 'orders' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'avgOrderValue',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'dateFrom', 'dateTo', 'comparisonType', 'delayType', 'delay'],
            customVariables: [{ name: 'metric', customValue: 'avg_order_size' }],
            presetResult: 'totalsComparison',
        },
    ],
}

export const Disclamer = styled.div`
    margin-top: 0.5em;
`

const MetricsWrapper = styled.div`
    width: 100%;
    z-index: 1;
    padding: 0 1.2em;
`

const HighlightsCol = styled(Col)`
    display: flex;
    align-items: center;
    font-size: 1.25rem;
`

const SelectorCol = styled(Col)`
    justify-content: flex-end;
    display: flex;
`

const GreenDisplay = styled.span`
    font-weight: 500;
    color: ${(p) => p.theme.colors.emerald};
`
const RedDisplay = styled.span`
    font-weight: 500;
    color: ${(p) => p.theme.colors.status.error};
`
const YellowDisplay = styled.span`
    font-weight: 500;
    color: ${(p) => p.theme.colors.status.warn};
`

const StatsCard = styled(Card)`
    ${cardStyle}
    height: 28.4em;
    padding: 1em 0 0.1em 0;
`

function VariationDisplay(props: { variation: string }) {
    switch (props.variation) {
        case 'higher_than_usual':
            return (
                <span>
                    <GreenDisplay>Higher</GreenDisplay>
                    &nbsp;than usual
                </span>
            )
        case 'lower_than_usual':
            return (
                <span>
                    <RedDisplay>Lower</RedDisplay>
                    &nbsp;than usual
                </span>
            )
        case 'as_usual':
            return (
                <span>
                    <YellowDisplay>Similar</YellowDisplay>&nbsp;to usual
                </span>
            )
        case 'not_enough_data':
            return <span>&nbsp;</span>
        default:
            return (
                <span>
                    <YellowDisplay>Similar</YellowDisplay>&nbsp;to usual
                </span>
            )
    }
}

type MenuOption = { value: Period; text: string }
const menuOptions: MenuOption[] = [
    {
        value: 'day',
        text: 'Today',
    },
    {
        value: 'week',
        text: 'This week',
    },
]

export type Period = 'day' | 'week'

interface StatsCardProps {
    statsService: StatsService
    accountSlug: string
    navigation: Navigation
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function StatsContainer(props: StatsCardProps) {
    const { granularity } = getGranularityFromQuery(props.navigation.query())
    const [revenueStats, setRevenueStats] = useState<ComparisonTotal | null>(null)
    const [ordersStats, setOrdersStats] = useState<ComparisonTotal | null>(null)
    const [orderValueStats, setOrderValueStats] = useState<ComparisonTotal | null>(null)
    const [currentPeriod, setCurrentPeriod] = useState<Period>(granularity as Period)
    const [loading, setLoading] = useState<boolean>(false)

    async function getStats() {
        const today = format(startOfToday(), 'yyyy-MM-dd')
        let dateFrom = today
        let dateTo = today
        if (currentPeriod === 'week') {
            const weekStart = format(startOfWeek(parseISODate(today), { weekStartsOn: 1 }), 'yyyy-MM-dd')
            const weekEnd = format(endOfWeek(parseISODate(today), { weekStartsOn: 1 }), 'yyyy-MM-dd')
            dateFrom = weekStart
            dateTo = weekEnd
        }
        setLoading(true)
        const variables = {
            accountSlug: props.accountSlug,
            dateFrom,
            dateTo,
            comparisonType: 'compare_with_usual',
            delayType: 'weeks',
            delay: 1,
        }
        const data = await props.statsService.getStats(queryConfig, variables)
        setRevenueStats(data.revenue.totals)
        setOrdersStats(data.orders.totals)
        setOrderValueStats(data.avgOrderValue.totals)
        setLoading(false)
    }

    useEffect(() => {
        getStats()
    }, [currentPeriod, props.accountSlug])

    const statistics = [
        {
            name: 'Revenue',
            data: revenueStats ? revenueStats.value : '',
            currency: true,
            comparison: revenueStats ? <VariationDisplay variation={revenueStats.change} /> : null,
            infoTextDay:
                'Total revenue generated today compared to the same weekdays in the past 2 weeks. Holidays are excluded in this calculation.',
            infoTextWeek:
                'Total revenue generated this week compared the past 2 similar weeks starting on Monday until today. Holidays are excluded in this calculation.',
        },
        {
            name: 'Orders',
            data: ordersStats ? ordersStats.value : '',
            currency: false,
            comparison: ordersStats ? <VariationDisplay variation={ordersStats.change} /> : null,
            infoTextDay:
                'Total Number of Orders, excluding reservations, generated today compared to the same weekdays in the past 2 weeks. Holidays are excluded in this calculation.',
            infoTextWeek:
                'Total Number of Orders, excluding reservations, this week compared the past 2 similar weeks starting on Monday until today. Holidays are excluded in this calculation.',
        },
        {
            name: 'Average order value',
            data: orderValueStats ? orderValueStats.value : '',
            currency: true,
            comparison: orderValueStats ? <VariationDisplay variation={orderValueStats.change} /> : null,
            infoTextDay:
                'Average Order Value, excluding reservations, generated today compared to the same weekdays in the past 2 weeks. Holidays are excluded in this calculation.',
            infoTextWeek:
                'Average Order Value, excluding reservations, generated this week compared the past 2 similar weeks starting on Monday until today. Holidays are excluded in this calculation.',
        },
    ]
    return (
        <StatsCard>
            <Row style={{ marginBottom: '1.2em', padding: '0 .8em' }}>
                <HighlightsCol span={6}>Highlights</HighlightsCol>
                <SelectorCol span={6} data-testid="cp-dashboard-stats-period-selector">
                    <PopupMenu
                        options={menuOptions}
                        value={currentPeriod}
                        onChange={(v: MenuOption, all: any[]) => {
                            setCurrentPeriod(v.value)
                            props.navigation.addQueryWithReplace({ granularity: v.value })
                        }}
                        noBorder
                    />
                </SelectorCol>
            </Row>
            <Row>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <PlainLoader
                            style={{
                                margin: '7em 2em',
                                position: 'relative',
                                width: '8em',
                                height: '8em',
                            }}
                        />
                    </div>
                ) : (
                    <MetricsWrapper>
                        <div id="homepage-stats">
                            {statistics.map((s, i) => (
                                <StatsSection
                                    data-testid={`stats-section-${s.name}`}
                                    key={i}
                                    name={s.name}
                                    data={s.data}
                                    currency={s.currency}
                                    comparison={s.comparison}
                                    infoText={currentPeriod === 'day' ? s.infoTextDay : s.infoTextWeek}
                                    accountSlug={props.accountSlug}
                                />
                            ))}
                        </div>
                    </MetricsWrapper>
                )}
            </Row>
        </StatsCard>
    )
}

export default withFeatures(StatsContainer)
