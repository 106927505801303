import * as React from 'react'
import styled from 'styled-typed'
import { ForgotPasswordContainer, ForgotPasswordForm, ForgotPasswordHeader, ValidationMessage } from 'auth/components'
import { IconTextInput } from 'uiComponents/input'
import { ActionButton } from 'uiComponents/buttons'
import { isValidEmail } from 'utils/formFieldChecks'

export interface ForgotPasswordPageProps {
    resetPassword: (email: string) => Promise<boolean>
}

export interface ForgotPasswordPageState {
    message: string | null
    email: string
    success: boolean
}

const SuccessMessage = styled.div`
    margin-top: 1em;
    color: ${(props) => props.theme.colors.status.success};
`

export class ForgotPasswordPage extends React.Component<ForgotPasswordPageProps, ForgotPasswordPageState> {
    state = {
        message: null,
        email: '',
        success: false,
    }

    checkValidEmail = () => {
        if (!isValidEmail(this.state.email)) {
            this.setState({ message: 'Invalid email address.' })
        } else {
            this.setState({ message: '' })
        }
    }

    sendResetEmail = async (e: React.FormEvent<HTMLElement>) => {
        e.preventDefault()
        try {
            this.setState({ message: '' })
            const result = await this.props.resetPassword(this.state.email)
            if (!result) {
                this.setState({
                    message: 'The email address provided did not match any user account.',
                })
                return
            }
            this.setState({
                success: true,
            })
        } catch (e) {
            this.setState({
                message: 'Oops! An unknown error occured. Please try again later.',
            })
        }
    }

    render() {
        const canSubmit = this.state.email.length > 0 && isValidEmail(this.state.email)

        return (
            <>
                <ForgotPasswordContainer>
                    <ForgotPasswordForm noValidate onSubmit={this.sendResetEmail}>
                        <ForgotPasswordHeader>What is your email address?</ForgotPasswordHeader>
                        <ValidationMessage className={this.state.message ? 'validation-message-visible' : ''}>
                            {this.state.message}
                        </ValidationMessage>
                        <IconTextInput
                            block
                            type="email"
                            status={this.state.message ? 'error' : 'normal'}
                            placeholder="Your email"
                            value={this.state.email}
                            onChange={(evt) => this.setState({ email: evt.target.value, success: false })}
                            onFinishTyping={this.checkValidEmail}
                            style={{ marginBottom: '.9em' }}
                        />
                        <ActionButton size="large" type="submit" disabled={!canSubmit} style={{ margin: '1em 3.5em' }}>
                            Send reset email
                        </ActionButton>
                    </ForgotPasswordForm>
                    {this.state.success && (
                        <SuccessMessage>
                            An email has been sent to
                            <b> {this.state.email}</b>. Please follow instructions provided there
                        </SuccessMessage>
                    )}
                </ForgotPasswordContainer>
            </>
        )
    }
}
