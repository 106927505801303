import * as React from 'react'
import { useDispatch } from 'react-redux'
import printJS from 'print-js'
import { AppServices } from 'middleware'
import { addMessage, removeMessage, replaceMessage } from 'uiComponents/messages/actions'
import {
    getAllSelectedOrderItemsOrdersUuids,
    getListOrderData,
    getSelectedOrderDetails,
    getSelectedOrdersDetails,
} from 'orders/orders/redux'
import { Button } from '@mui/material'
import { CircleDownSolid, PrintSolid } from '@convious/icons'
import { useAppSelector } from 'store/hooks'

const PrintDownload = () => {
    const dispatch = useDispatch()
    const selectedTicketsOrders = useAppSelector(getAllSelectedOrderItemsOrdersUuids)
    const selectedOrderItems = useAppSelector(getSelectedOrderDetails).filter((item) => !item.refundInfo)
    const selectedOrders = useAppSelector(getSelectedOrdersDetails)
    const orderList = useAppSelector(getListOrderData)

    const getDownloadLink = (print: boolean = false) => {
        const ordersString = selectedTicketsOrders.join(',')
        const barcodesString = selectedOrderItems.map((item) => item?.barcode?.barcode).join(',')

        if (selectedOrders.length === 1 && !print) {
            return `${selectedOrders[0].ticketDownloadUrl}?internal=1`
        }

        return `${AppServices.accountService.backofficeEndpoint}api/v1/cart/tickets/download?orders=${ordersString}&barcodes=${barcodesString}`
    }

    const onDownloadClick = (e: React.MouseEvent) => {
        if (selectedOrders.length !== 1) {
            e.preventDefault()
        }
    }

    const onPrint = () => {
        dispatch(removeMessage('print_error'))
        const endpoint = getDownloadLink(true)
        printJS({
            printable: `${endpoint}&printing=1`,
            onLoadingStart: () =>
                dispatch(
                    addMessage(
                        'print_start',
                        'success',
                        'We are preparing the tickets. Print dialog will open automatically in a moment.',
                    ),
                ),
            onLoadingEnd: () => dispatch(removeMessage('print_start')),
            onError: () =>
                dispatch(
                    replaceMessage(
                        'print_error',
                        'error',
                        'Oops! Something went wrong. Please check that all selected tickets have the same \
            template type (e.g. season pass vs regular ticket). Alternatively try printing tickets individually.',
                    ),
                ),
        })
    }

    // We don't show Print/Download buttons if there are more than 1 order selected or APF is not filled
    if (selectedOrders.length > 1 || (selectedOrders[0] && selectedOrders[0].unfilledApf)) {
        return null
    } else if (selectedOrderItems.length > 0 && selectedOrders.length === 0) {
        const order = orderList.data?.results.find((order) => order.id === selectedTicketsOrders[0])
        if (order && order.unfilledApf) {
            return null
        }
    }

    return (
        <>
            {(selectedOrderItems.length > 0 || selectedOrders.length === 1) && (
                <Button
                    size="medium"
                    color="primary"
                    variant="outlined"
                    startIcon={<CircleDownSolid />}
                    data-testid="orders-download-tickets-button"
                    target="_blank"
                    href={getDownloadLink()}
                    onClick={onDownloadClick}
                    className="action-button"
                >
                    Download tickets
                </Button>
            )}
            {selectedOrderItems.length > 0 && (
                <Button
                    size="medium"
                    color="primary"
                    variant="outlined"
                    startIcon={<PrintSolid />}
                    data-testid="orders-print-tickets-button"
                    onClick={onPrint}
                    className="action-button"
                >
                    Print tickets
                </Button>
            )}
        </>
    )
}

export default PrintDownload
