import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { filter, remove } from 'lodash'
import { AppServices } from 'middleware'
import { replaceMessage } from 'uiComponents/messages/actions'
import { Category, Parkmap, POI } from './models'

export const parkMapApi = createApi({
    reducerPath: 'parkMapApi',
    tagTypes: ['parkMap', 'parkMapList', 'parkMapCategories', 'parkMapCategory'],
    baseQuery: fetchBaseQuery({}),
    endpoints: (builder) => ({}),
})

const extendedParkMapApi = parkMapApi.injectEndpoints({
    endpoints: (builder) => ({
        listParkMap: builder.query({
            providesTags: ['parkMapList'],
            queryFn: async ({ slug }: { slug: string }, api) => {
                try {
                    const data = await AppServices.parkMapService.getParkMaps(slug)
                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('parkMapList', 'error', 'Failed to get park map list', 5000))

                    return { error }
                }
            },
        }),
        getParkMap: builder.query({
            providesTags: ['parkMap'],
            queryFn: async ({ id }: { id: string }, api) => {
                try {
                    const data = await AppServices.parkMapService.getParkMap(id)

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('parkMap', 'error', 'Failed to get park map', 5000))

                    return { error }
                }
            },
        }),
        createParkMap: builder.mutation({
            queryFn: async ({ parkMap }: { parkMap: Parkmap }, api) => {
                try {
                    const data = await AppServices.parkMapService.createParkMap(parkMap)
                    api.dispatch(
                        extendedParkMapApi.util.updateQueryData('listParkMap', { slug: parkMap.slug }, (draft) => {
                            draft?.push(data)
                            return draft
                        }),
                    )
                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        updateParkMap: builder.mutation({
            invalidatesTags: ['parkMap'],
            queryFn: async ({ parkMap }: { parkMap: Parkmap }, api) => {
                try {
                    const data = await AppServices.parkMapService.updateParkMap(parkMap)
                    api.dispatch(
                        extendedParkMapApi.util.updateQueryData('listParkMap', { slug: parkMap.slug }, (draft) => {
                            return draft?.map((map) => {
                                if (map.id === parkMap.id) {
                                    return data
                                }
                                return map
                            })
                        }),
                    )

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('parkMap', 'error', 'Failed to update park map', 5000))

                    return { error }
                }
            },
        }),
        deleteParkMap: builder.mutation({
            queryFn: async ({ parkMap }: { parkMap: Parkmap }, api) => {
                try {
                    const data = await AppServices.parkMapService.deleteParkMap(parkMap.id)
                    api.dispatch(
                        extendedParkMapApi.util.updateQueryData('listParkMap', { slug: parkMap.slug }, (draft) => {
                            return filter(draft, (map) => map.id !== parkMap.id)
                        }),
                    )
                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('parkMap', 'error', 'Failed to delete park map', 5000))

                    return { error }
                }
            },
        }),
        parkMapCategories: builder.query({
            providesTags: ['parkMapCategories'],
            queryFn: async ({ locationId }: { locationId?: string }, api) => {
                try {
                    const data = locationId ? await AppServices.parkMapService.getParkMapCategories(locationId) : []
                    return { data }
                } catch (error) {
                    api.dispatch(
                        replaceMessage('parkMapCategories', 'error', 'Failed to get park map categories', 5000),
                    )

                    return { error }
                }
            },
        }),
        parkMapCategory: builder.query({
            providesTags: (result, _, { categoryId }) =>
                result
                    ? [{ type: 'parkMapCategory' as const, id: categoryId }, 'parkMapCategory']
                    : ['parkMapCategory'],
            queryFn: async ({ categoryId }: { categoryId: string }, api) => {
                try {
                    const data = await AppServices.parkMapService.getCategory(categoryId)
                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('parkMapCategory', 'error', 'Failed to get park map category', 5000))

                    return { error }
                }
            },
        }),
        createCategory: builder.mutation({
            queryFn: async ({ category }: { category: Category }, api) => {
                try {
                    const data = await AppServices.parkMapService.createCategory(category)
                    api.dispatch(
                        extendedParkMapApi.util.updateQueryData(
                            'parkMapCategories',
                            { locationId: category.locationId },
                            (draft) => {
                                draft?.push(data)
                                return draft
                            },
                        ),
                    )

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('parkMapCategory', 'error', 'Failed to create park map category', 5000))

                    return { error }
                }
            },
        }),
        updateCategory: builder.mutation({
            invalidatesTags: (_, __, { category }) => [{ type: 'parkMapCategory', id: category.id }, 'parkMapCategory'],
            queryFn: async ({ category }: { category: Category }, api) => {
                try {
                    const data = await AppServices.parkMapService.updateCategory(category)
                    api.dispatch(
                        extendedParkMapApi.util.updateQueryData(
                            'parkMapCategories',
                            { locationId: category.locationId },
                            (draft) => {
                                return draft?.map((cat) => {
                                    if (cat.id === category.id) {
                                        return { ...cat, ...category }
                                    }
                                    return cat
                                })
                            },
                        ),
                    )

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('parkMapCategory', 'error', 'Failed to update park map category', 5000))

                    return { error }
                }
            },
        }),
        parkMapPoi: builder.query({
            providesTags: ['parkMapCategories'],
            queryFn: async ({ poiId }: { poiId: string }, api) => {
                try {
                    const data = await AppServices.parkMapService.getPoi(poiId)

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('parkMapCategory', 'error', 'Failed to get park map poi', 5000))

                    return { error }
                }
            },
        }),
        createPoi: builder.mutation({
            queryFn: async ({ poi }: { poi: POI }, api) => {
                try {
                    const data = await AppServices.parkMapService.createPoi(poi)

                    api.dispatch(
                        extendedParkMapApi.util.updateQueryData(
                            'parkMapCategories',
                            { locationId: poi.locationId },
                            (draft) => {
                                if (draft) {
                                    draft.forEach((cat, index) => {
                                        if (cat.id === poi.categoryId && draft[index].pois) {
                                            draft[index].pois?.push(data)
                                        }
                                    })
                                }

                                return draft
                            },
                        ),
                    )

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('parkMapCategory', 'error', 'Failed to create POI', 5000))

                    return { error }
                }
            },
        }),
        updatePoi: builder.mutation({
            invalidatesTags: ['parkMapCategories'],
            queryFn: async (
                {
                    poi,
                    oldCategoryId,
                    indexDestination,
                }: { poi: POI; oldCategoryId?: string; indexDestination?: number },
                api,
            ) => {
                try {
                    if (oldCategoryId) {
                        api.dispatch(
                            extendedParkMapApi.util.updateQueryData(
                                'parkMapCategories',
                                { locationId: poi.locationId },
                                (draft) => {
                                    if (draft) {
                                        let movingPoi: POI | null
                                        draft.forEach((cat, index) => {
                                            if (cat.id === oldCategoryId && draft[index].pois) {
                                                ;[movingPoi] = remove(draft[index].pois!, { id: poi.id })
                                            }
                                        })

                                        draft.forEach((cat, index) => {
                                            if (cat.id === poi.categoryId && draft[index].pois && movingPoi) {
                                                draft[index].pois?.splice(indexDestination ?? 0, 0, movingPoi)
                                            }
                                        })
                                    }

                                    return draft
                                },
                            ),
                        )
                    }

                    const data = await AppServices.parkMapService.updatePoi(poi)

                    api.dispatch(
                        extendedParkMapApi.util.updateQueryData(
                            'parkMapCategories',
                            { locationId: poi.locationId },
                            (draft) => {
                                if (draft) {
                                    return draft.map((cat) => {
                                        if (cat.id === poi.categoryId && cat.pois) {
                                            return {
                                                ...cat,
                                                pois: cat.pois.map((poiItem) => {
                                                    if (poiItem.id === data.id) {
                                                        return data
                                                    }

                                                    return poiItem
                                                }),
                                            }
                                        }

                                        return cat
                                    })
                                }

                                return draft
                            },
                        ),
                    )

                    return { data }
                } catch (error) {
                    if (oldCategoryId) {
                        api.dispatch(
                            extendedParkMapApi.util.updateQueryData(
                                'parkMapCategories',
                                { locationId: poi.locationId },
                                (draft) => {
                                    if (draft) {
                                        let movingPoi: POI | null
                                        draft.forEach((cat, index) => {
                                            if (cat.id === poi.categoryId && draft[index].pois) {
                                                ;[movingPoi] = remove(draft[index].pois!, { id: poi.id })
                                            }
                                        })

                                        draft.forEach((cat, index) => {
                                            if (cat.id === oldCategoryId && draft[index].pois && movingPoi) {
                                                draft[index].pois?.splice(indexDestination ?? 0, 0, movingPoi)
                                            }
                                        })
                                    }

                                    return draft
                                },
                            ),
                        )
                    }
                    api.dispatch(replaceMessage('parkMapCategory', 'error', 'Failed to update POI', 5000))

                    return { error }
                }
            },
        }),
        updateCategoryPositions: builder.mutation({
            invalidatesTags: ['parkMapCategories'],
            queryFn: async (
                {
                    sortedCategories,
                    locationId,
                    slug,
                }: { sortedCategories: Category[]; locationId: string; slug: string },
                api,
            ) => {
                try {
                    const sortedPayload = sortedCategories.map((cat) => ({
                        categoryId: cat.id,
                        position: cat.position,
                    }))

                    api.dispatch(
                        extendedParkMapApi.util.updateQueryData('parkMapCategories', { locationId }, (draft) => {
                            if (draft) {
                                return sortedCategories
                            }

                            return draft
                        }),
                    )

                    const data = await AppServices.parkMapService.updateCategoriesPosition(
                        sortedPayload,
                        slug,
                        locationId,
                    )

                    api.dispatch(
                        replaceMessage('parkMapCategories', 'success', 'Categories positions have been saved', 5000),
                    )

                    return { data }
                } catch (error) {
                    api.dispatch(
                        replaceMessage('parkMapCategories', 'error', 'Failed to update category position', 5000),
                    )
                    extendedParkMapApi.util.invalidateTags(['parkMapCategories'])
                    return { error }
                }
            },
        }),
        deletePoi: builder.mutation({
            invalidatesTags: ['parkMapCategories'],
            queryFn: async ({ poiId }: { poiId: string }, api) => {
                try {
                    await AppServices.parkMapService.deletePoi(poiId)

                    return { data: null }
                } catch (error) {
                    api.dispatch(replaceMessage('parkMapCategory', 'error', 'Failed to delete POI', 5000))

                    return { error }
                }
            },
        }),
        deleteCategory: builder.mutation({
            invalidatesTags: ['parkMapCategories'],
            queryFn: async ({ categoryId }: { categoryId: string }, api) => {
                try {
                    await AppServices.parkMapService.deleteCategory(categoryId)

                    return { data: null }
                } catch (error) {
                    api.dispatch(replaceMessage('parkMapCategory', 'error', 'Failed to delete category', 5000))

                    return { error }
                }
            },
        }),
    }),
})

export const { reducer, reducerPath } = parkMapApi
export const {
    useListParkMapQuery,
    useGetParkMapQuery,
    useLazyGetParkMapQuery,
    useDeleteParkMapMutation,
    useCreateParkMapMutation,
    useUpdateParkMapMutation,
    useParkMapCategoriesQuery,
    useParkMapCategoryQuery,
    useUpdateCategoryMutation,
    useUpdateCategoryPositionsMutation,
    useCreateCategoryMutation,
    useParkMapPoiQuery,
    useCreatePoiMutation,
    useDeletePoiMutation,
    useUpdatePoiMutation,
    useDeleteCategoryMutation,
    endpoints,
} = extendedParkMapApi
