import * as React from 'react'
import { PlacementListItem, UpdatePlacementWeightPayload } from 'tapPlacements/schema'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import TableRow from './tableRow'
import { placementExpired } from './helpers'
import { useVenueLocations } from 'tapPlacements/studio/helpers'
import { Audience } from 'crm/schema'

interface TableProps {
    accountSlug: string
    items: PlacementListItem[]
    hasPlacementAudienceFeature: boolean
    hasPlacementLocationFeature: boolean
    audiences: Audience[]
    updatePlacementsWeight(placements: UpdatePlacementWeightPayload[]): void
    onDelete(id: string): void
}

const TableBody = ({
    accountSlug,
    items,
    hasPlacementAudienceFeature,
    hasPlacementLocationFeature,
    audiences,
    onDelete,
    updatePlacementsWeight,
}: TableProps) => {
    const now = new Date()
    const [localItems, setLocalItems] = React.useState<PlacementListItem[]>(items)
    const venueLocations = useVenueLocations(accountSlug)

    React.useEffect(() => {
        setLocalItems(items)
    }, [items])

    const reorder = (list: PlacementListItem[], startIndex: number, endIndex: number) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return
        }

        const orderedItems = reorder(localItems, result.source.index, result.destination.index)

        setLocalItems(orderedItems)

        const payload = orderedItems.map((item, index) => ({
            id: item.id,
            weight: index + 1,
        }))
        updatePlacementsWeight(payload)
    }

    const dragTable = () => {
        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {localItems.map((item, index) => {
                                const status = item.active
                                    ? placementExpired(item.endDate, now)
                                        ? 'warn'
                                        : 'success'
                                    : 'error'
                                const venueLocation =
                                    venueLocations.find((location) => location.value === item.locationId)?.name || 'All'

                                return (
                                    <TableRow
                                        key={index}
                                        index={index}
                                        accountSlug={accountSlug}
                                        item={item}
                                        status={status}
                                        hasPlacementAudienceFeature={hasPlacementAudienceFeature}
                                        hasPlacementLocationFeature={hasPlacementLocationFeature}
                                        onDelete={onDelete}
                                        venueLocation={venueLocation}
                                        audiences={audiences}
                                    />
                                )
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }

    return dragTable()
}

export default TableBody
