import React, { useState, useEffect, useContext, useRef } from 'react'
import styled from 'styled-typed'
import { usePrevious } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { DataTotal } from 'reports/schema'
import PieChart from 'uiComponents/charts/pieChart'
import { format } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { ChartHeadline, ChartContainer } from 'uiComponents/charts/styleComponents'
import { QueryConfig } from 'reports/queryGenerator'
import Money from 'uiComponents/money'
import { productTypeNameMap } from 'products/crud/utils'
import { abbreviateNumber } from 'utils'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { DateRange } from 'dateRanges'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'

const TotalNumber = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5em;
    font-weight: bold;
    position: absolute;
    top: 37%;
    left: 50%;
    transform: translateX(-50%);
    span {
        font-size: 0.6em;
        font-weight: 300;
    }
`

const queryConfig: QueryConfig = {
    querySetName: 'RevenueByTypeChart',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'metric', type: 'StatsMetric' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'groupBy', type: 'String' },
    ],
    queries: [
        {
            name: 'customGroupBy',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'groupBy'],
            customVariables: [],
            presetResult: 'totals',
        },
        {
            name: 'stats',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo'],
            customVariables: [],
            presetResult: 'totals',
        },
    ],
}

interface TrafficByGroupChartsProps {
    navigation: Navigation
    match: RouteMatch<{}>
    accountSlug: string
    dateRange: DateRange
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

function ProductsSoldByTypeChart(props: TrafficByGroupChartsProps) {
    const _isMounted = useRef(false)
    const _lastRequest = useRef<number>()
    const statsService = useContext(StatsServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [productsSoldData, setProductsSoldData] = useState<DataTotal[]>([])
    const [totalRevenue, setTotalRevenue] = useState<number>(0)

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    useEffect(() => {
        _isMounted.current = true
        if (prevAccountSlug !== props.accountSlug || !areDateRangeDatesEqual(prevDateRange, props.dateRange)) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug, props.dateRange])

    const getData = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime
        setLoading(true)
        const dateFrom = format(props.dateRange.from || new Date(), 'yyyy-MM-dd')
        const dateTo = format(props.dateRange.to || new Date(), 'yyyy-MM-dd')
        try {
            const variables = {
                widget: props.accountSlug,
                metric: 'revenue',
                dateFrom,
                dateTo,
                groupBy: 'item_business_type',
            }
            const data = await statsService.getStats(queryConfig, variables)
            if (_lastRequest.current !== requestTime) {
                return
            }
            const groupedStats = data.customGroupBy.totals?.filter((t) => t.value > 0) || []
            const mappedStats = groupedStats.map((s) => ({
                value: s.value,
                name: productTypeNameMap[s.name],
            }))
            const total = data.stats.totals[0]?.value || 0
            if (_isMounted.current) {
                setLoading(false)
                setProductsSoldData(mappedStats)
                setTotalRevenue(total)
            }
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Revenue chart could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }

    function formatValue(value: number) {
        return value >= 10000 ? abbreviateNumber(value, 1) : value
    }
    const navigationLink = `/account/${props.accountSlug}/reports/revenue/${location.search}`

    return (
        <ChartContainer className="clickable-title">
            {loading && <ChartDataLoader />}
            <ChartHeadline size={4}>
                <span>
                    <UnstyledLink to={navigationLink}>Revenue</UnstyledLink>
                </span>
            </ChartHeadline>
            {productsSoldData.length > 0 && (
                <TotalNumber>
                    <Money amount={formatValue(totalRevenue)} accountSlug={props.accountSlug} />
                    <span>TOTAL REVENUE</span>
                </TotalNumber>
            )}
            <PieChart
                data={productsSoldData}
                pieRadius={['100%', '65%']}
                legendTop={340}
                chartHeight="450px"
                accountSlug={props.accountSlug}
                currency
                loading={loading}
            />
        </ChartContainer>
    )
}

export default withNavigation(ProductsSoldByTypeChart)
