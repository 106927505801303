import { ArticleFromApi, CategoryFromApi } from '../types'

export const isArticle = (product?: CategoryFromApi | ArticleFromApi) => {
    if (!product) {
        return null
    }

    return Object.keys(product).includes('isBundle')
}

export const getProductName = (name?: Record<string, string>) => {
    return (name?.['en'] || name?.[Object.keys(name)?.[0]]) ?? ''
}
