import React, { useState, useEffect } from 'react'
import { match as RouteMatch } from 'react-router-dom'
import { TableLoader } from 'uiComponents/loaders'
import isEqual from 'lodash/isEqual'
import { usePrevious } from 'reactUtils'
import { ArticleService, ArticleListItem, ProductList, ProductListMinimum, OrphanArticle } from 'admin/articleService'
import { PricingService, CreatePricingSettingsError } from 'products/pricing/pricingService'
import { History } from 'history'
import { delay } from 'utils'
import { withNavigation } from 'hocs'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { Navigation } from 'navigation'
import { handleFailure } from 'products/pricing/common'
import { PricingType } from 'products/articleConfigurationService'
import { Headline } from 'uiComponents/typography'
import { MessageKind, MessageButton } from 'uiComponents/messages'
import NestedTable from './nestedTable'
import { getDuplicationMessage } from './utils'

interface ProductListPageParams {
    accountSlug: string
}

interface ProductListPageProps {
    history: History
    articleService: ArticleService
    pricingService: PricingService
    match: RouteMatch<ProductListPageParams>
    navigation: Navigation
    setActiveSection: (section: string, header?: string) => void
    hasPermission: (permission: string, accountSlug: string) => boolean
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    addMessage: (id: string, status: MessageKind, text: string, actionButton?: MessageButton) => void
    hasFeature: (feature: string, accountSlug: string) => boolean
    removeAllMessages: () => void
    hideMessage: (id: string) => void
    accountCategories: ProductList[]
    firstLevelCategories: ProductListMinimum[]
    setLoading: () => void
}

function ProductListPage(props: ProductListPageProps) {
    let _isMounted = false
    const accountSlug = props.match.params.accountSlug
    const [articles, setArticles] = useState<ArticleListItem[] | null>(null)
    const [orphanArticles, setOrphanArticles] = useState<OrphanArticle[] | null>(null)
    const [loading, setLoading] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [showProductListDeleteConfirmation, setShowProductListDeleteConfirmation] = useState(false)
    const [articleToBeDeleted, setArticleToBeDeleted] = useState<string | null>(null)
    const [productListToBeDeleted, setProductListToBeDeleted] = useState<string | null>(null)
    const [showDuplicationConfirmation, setShowDuplicationConfirmation] = useState(false)
    const [productToBeDuplicated, setProductToBeDuplicated] = useState<{
        id: string
        name: string
    } | null>(null)
    const [duplicating, setDuplicating] = useState(false)

    useEffect(() => {
        _isMounted = true
        props.setActiveSection('productsList', 'Products Overview')
        getArticlesList()
        return () => {
            _isMounted = false
        }
    }, [])

    useEffect(() => {
        if (loading) {
            props.setLoading()
        }
    }, [loading])

    const prevAccountCategories = usePrevious(props.accountCategories)
    const prevFirstLevelCategories = usePrevious(props.firstLevelCategories)

    useEffect(() => {
        if (
            (!isEqual(prevAccountCategories, props.accountCategories) && !!prevAccountCategories) ||
            (!isEqual(prevFirstLevelCategories, props.firstLevelCategories) && !!prevFirstLevelCategories) ||
            accountSlug
        ) {
            getArticlesList()
        }
    }, [props.accountCategories, props.firstLevelCategories, accountSlug])

    const getArticlesList = async () => {
        setLoading(true)
        try {
            if (props.firstLevelCategories.length > 0) {
                const orphans = await props.articleService.listOrphanArticles(props.match.params.accountSlug)
                setArticles(null)
                setOrphanArticles(orphans.length > 0 ? orphans : null)
            } else {
                const orphans = await props.articleService.listOrphanArticles(props.match.params.accountSlug)
                if (_isMounted) {
                    setOrphanArticles(orphans.length > 0 ? orphans : null)
                }
            }
            setLoading(false)
        } catch {
            props.replaceTopMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            if (_isMounted) {
                setLoading(false)
            }
        }
    }

    const confirmArticleDeletion = (id: string) => {
        setShowDeleteConfirmation(true)
        setArticleToBeDeleted(id)
    }

    const duplicateProduct = (id: string, name: string) => {
        setShowDuplicationConfirmation(true)
        setProductToBeDuplicated({ id, name })
    }

    const confirmProductDuplication = async () => {
        setDuplicating(true)
        try {
            const id = productToBeDuplicated?.id || ''
            const copyId = await props.articleService.duplicateArticle(accountSlug, id)
            const message = getDuplicationMessage(productToBeDuplicated?.name || '')
            props.navigation.pushWithMessages(
                [message],
                `/account/${accountSlug}/products/crud/${copyId}${location.search}`,
                true,
            )
        } catch (e) {
            setDuplicating(false)
            if (e && e.message === 'price_settings_error') {
                props.replaceTopMessages(
                    'server_error',
                    'error',
                    "Price settings could not be duplicated. Most common issue is pricing type mismatch between product's price settings and related price exception.",
                )
            } else {
                props.replaceTopMessages('server_error', 'error', 'Oops! Something went wrong. Please try again later.')
            }
        }
    }

    const deleteArticle = async (id: string) => {
        setLoading(true)
        setShowDeleteConfirmation(false)
        try {
            await props.articleService.archiveArticle(props.match.params.accountSlug, id)
            props.replaceTopMessages('server_success', 'success', 'The product was successfully deleted.')
            getArticlesList()
        } catch {
            props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Could not delete the product. Please try again later.',
            )
        } finally {
            setLoading(false)
        }
    }

    const confirmProductListDeletion = (id: string) => {
        setShowProductListDeleteConfirmation(true)
        setProductListToBeDeleted(id)
    }

    const deleteProductList = async (id: string) => {
        setLoading(true)
        setShowProductListDeleteConfirmation(false)
        try {
            await props.articleService.archivePoductList(props.match.params.accountSlug, id)
            props.replaceTopMessages('server_success', 'success', 'The category was successfully deleted.')
            getArticlesList()
        } catch {
            props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Could not delete the category. Please try again later.',
            )
        } finally {
            setLoading(false)
        }
    }

    const updateProductListStatusAndPriority = async (id: string, enabled: boolean, priority: number) => {
        if (!priority) {
            return
        }
        try {
            await props.articleService.updateProductListStatusAndPriority(
                props.match.params.accountSlug,
                id,
                enabled,
                priority,
            )
            props.addMessage('server_success', 'success', 'The category was successfully updated.', null)
            if (!!props.hideMessage) {
                await delay(2000)
                props.hideMessage('server_success')
            }
        } catch {
            getArticlesList()
            props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Could not update the category. Please try again later.',
            )
        } finally {
            setLoading(false)
        }
    }

    const updateArticlePriority = async (id: string, priority: number) => {
        if (!priority) {
            return
        }
        try {
            await props.articleService.updateArticlePriority(props.match.params.accountSlug, id, priority)
            props.addMessage('server_success', 'success', 'The priority was successfully updated.', null)
            if (!!props.hideMessage) {
                await delay(2000)
                props.hideMessage('server_success')
            }
        } catch {
            getArticlesList()
            props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Could not update the priority. Please try again later.',
            )
        } finally {
            setLoading(false)
        }
    }

    const updateArticleStatus = async (id: string, enabled: boolean) => {
        try {
            await props.articleService.updateArticleStatus(props.match.params.accountSlug, id, enabled)
            props.replaceTopMessages('server_success', 'success', 'Product was successfully updated.')
        } catch {
            getArticlesList()
            props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Could not update the status. Please try again later.',
            )
        } finally {
            setLoading(false)
        }
    }

    const updateArticleOriginalPrice = async (
        id: string,
        price: string,
        min: number | null,
        avg: number | null,
        gate: number | null,
        stepSize: string | null,
        charmPricing: boolean | null,
        pricingType: PricingType,
    ) => {
        const responseData = await props.pricingService.sendPricingSettings({
            pricingType,
            id,
            account: props.match.params.accountSlug,
            originalPrice: +price,
            minAcceptedPrice: min,
            avgTargetPrice: avg,
            gatePrice: gate,
            stepSize,
            charmPricing,
            version: null,
        })

        responseData
            .ifFailure((err: CreatePricingSettingsError) => {
                if (props.replaceTopMessages) {
                    handleFailure(err, props.replaceTopMessages)
                }
            })
            .ifSuccess(async () => {
                if (props.replaceTopMessages && props.hideMessage) {
                    getArticlesList()
                    props.replaceTopMessages(
                        'pricing_settings_save_success',
                        'success',
                        'Pricing settings were successfully saved.',
                    )
                    await delay(5000)
                    props.hideMessage('pricing_settings_save_success')
                }
            })
    }
    const origin = '?from=nested'

    return (
        <>
            {showDeleteConfirmation && (
                <ConfirmationDialog
                    title="Delete the product?"
                    text="Are you sure you want to delete this product?"
                    buttonText="Delete"
                    destructive
                    onCancel={() => setShowDeleteConfirmation(false)}
                    onConfirm={() => (articleToBeDeleted ? deleteArticle(articleToBeDeleted) : {})}
                />
            )}
            {showProductListDeleteConfirmation && (
                <ConfirmationDialog
                    title="Delete the category?"
                    text="Are you sure you want to delete this category? All children categories and products will be removed."
                    buttonText="Delete"
                    destructive
                    onCancel={() => setShowProductListDeleteConfirmation(false)}
                    onConfirm={() => (productListToBeDeleted ? deleteProductList(productListToBeDeleted) : {})}
                />
            )}
            {showDuplicationConfirmation && (
                <ConfirmationDialog
                    title="Duplicate the product?"
                    text={`Are you sure you want to duplicate the product '${productToBeDuplicated?.name || ''}'?`}
                    buttonText="Duplicate"
                    loading={duplicating}
                    onCancel={() => setShowDuplicationConfirmation(false)}
                    onConfirm={confirmProductDuplication}
                />
            )}
            {loading && <TableLoader />}
            {!loading && !props.firstLevelCategories && !!articles && (
                <NestedTable
                    elements={articles}
                    accountSlug={accountSlug}
                    pricingService={props.pricingService}
                    hasPermission={props.hasPermission}
                    flat
                    deleteArticle={confirmArticleDeletion}
                    deleteProductList={confirmProductListDeletion}
                    duplicateProduct={duplicateProduct}
                    updateArticlePriority={updateArticlePriority}
                    updateArticleStatus={updateArticleStatus}
                    hasFeature={props.hasFeature}
                    replaceTopMessages={props.replaceTopMessages}
                    hideMessage={props.hideMessage}
                    flattenedCategories={null}
                    accountCategories={[]}
                    origin={origin}
                    navigation={props.navigation}
                    updateArticleOriginalPrice={updateArticleOriginalPrice}
                />
            )}
            {!loading && !!props.firstLevelCategories && !articles && (
                <NestedTable
                    elements={props.firstLevelCategories}
                    accountSlug={accountSlug}
                    pricingService={props.pricingService}
                    hasPermission={props.hasPermission}
                    deleteArticle={confirmArticleDeletion}
                    deleteProductList={confirmProductListDeletion}
                    duplicateProduct={duplicateProduct}
                    updateProductListStatusAndPriority={updateProductListStatusAndPriority}
                    updateArticlePriority={updateArticlePriority}
                    updateArticleStatus={updateArticleStatus}
                    hasFeature={props.hasFeature}
                    replaceTopMessages={props.replaceTopMessages}
                    hideMessage={props.hideMessage}
                    flattenedCategories={null}
                    accountCategories={[]}
                    origin={origin}
                    navigation={props.navigation}
                    updateArticleOriginalPrice={updateArticleOriginalPrice}
                    orphanArticlesExist={!!orphanArticles}
                />
            )}
            {!loading && !!props.firstLevelCategories && !!orphanArticles && (
                <>
                    <Headline size={4} style={{ margin: '1rem 2rem' }}>
                        Products without a category
                    </Headline>
                    <NestedTable
                        elements={orphanArticles}
                        accountSlug={accountSlug}
                        pricingService={props.pricingService}
                        hasPermission={props.hasPermission}
                        flat
                        deleteArticle={confirmArticleDeletion}
                        deleteProductList={confirmProductListDeletion}
                        duplicateProduct={duplicateProduct}
                        updateArticlePriority={updateArticlePriority}
                        updateArticleStatus={updateArticleStatus}
                        hasFeature={props.hasFeature}
                        replaceTopMessages={props.replaceTopMessages}
                        hideMessage={props.hideMessage}
                        flattenedCategories={null}
                        accountCategories={[]}
                        orphans
                        origin={origin}
                        updateArticleOriginalPrice={updateArticleOriginalPrice}
                        refreshArticles={getArticlesList}
                        navigation={props.navigation}
                    />
                </>
            )}
            {!loading && !props.firstLevelCategories && !!orphanArticles && (
                <NestedTable
                    elements={orphanArticles}
                    accountSlug={accountSlug}
                    pricingService={props.pricingService}
                    hasPermission={props.hasPermission}
                    flat
                    deleteArticle={confirmArticleDeletion}
                    deleteProductList={confirmProductListDeletion}
                    duplicateProduct={duplicateProduct}
                    updateArticlePriority={updateArticlePriority}
                    updateArticleStatus={updateArticleStatus}
                    hasFeature={props.hasFeature}
                    replaceTopMessages={props.replaceTopMessages}
                    hideMessage={props.hideMessage}
                    flattenedCategories={null}
                    accountCategories={[]}
                    orphans
                    origin={origin}
                    updateArticleOriginalPrice={updateArticleOriginalPrice}
                    refreshArticles={getArticlesList}
                    navigation={props.navigation}
                />
            )}
            {!loading && !props.firstLevelCategories && !articles && !orphanArticles && (
                <NestedTable
                    elements={[]}
                    accountSlug={accountSlug}
                    pricingService={props.pricingService}
                    hasPermission={props.hasPermission}
                    flat
                    deleteArticle={confirmArticleDeletion}
                    deleteProductList={confirmProductListDeletion}
                    duplicateProduct={duplicateProduct}
                    updateArticlePriority={updateArticlePriority}
                    updateArticleStatus={updateArticleStatus}
                    hasFeature={props.hasFeature}
                    replaceTopMessages={props.replaceTopMessages}
                    hideMessage={props.hideMessage}
                    flattenedCategories={null}
                    accountCategories={[]}
                    origin={origin}
                    navigation={props.navigation}
                />
            )}
        </>
    )
}

export default withNavigation(ProductListPage)
