import { Hooks, actions } from 'react-table'

actions.setPaginationToken = 'setPaginationToken'
actions.setPageSize = 'setPageSize'
actions.toggleSortBy = 'toggleSortBy'

export interface UseCursorPaginationState {
    token?: string
}

export const useCursorPagination = (hooks: Hooks<any>) => {
    hooks.stateReducers.push((state, action) => {
        switch (action.type) {
            case actions.setPageSize:
                return {
                    ...state,
                    token: undefined,
                    pageSize: action.payload,
                }

            case actions.setPaginationToken:
                return {
                    ...state,
                    token: action.payload,
                }
            case actions.toggleSortBy:
                return {
                    ...state,
                    token: undefined,
                }
            default:
                return state
        }
    })

    hooks.useInstance.push((instance) => {
        instance.setPaginationToken = (token: string) => {
            instance.dispatch({
                type: actions.setPaginationToken,
                payload: token,
            })
        }

        if (!instance.setPageSize) {
            instance.setPageSize = (pageSize: number) => {
                instance.dispatch({
                    type: actions.setPageSize,
                    payload: pageSize,
                })
            }
        }
    })
}
