import * as React from 'react'
import styled from 'styled-typed'
import { ActionButton } from 'uiComponents/buttons'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Account } from 'auth/state'

const CodeBox = styled.div`
    width: fit-content;
    border: 1px solid ${(props) => props.theme.colors.textLight};
    border-radius: 0.625em;
    background-color: ${(props) => props.theme.colors.background};
    padding: 1em;
`
const Code = styled.div`
    font-size: 0.875em;
    opacity: 0.75;
    line-height: 1.357em;
    word-break: break-all;
`
const Button = styled(ActionButton)`
    width: 9em;
    margin-top: 1.75em;
    margin-right: 1.25em;
    opacity: 0;
    transition: opacity 0.5s ease-in;

    &.visible {
        opacity: 1;
    }
`
export const RelativeButton = styled(Button)`
    position: relative;
    left: -10.25em;
`

interface CopySnippetElementProps {
    account: Account
    children?: React.ReactNode
}

interface CopySnippetElementState {
    copied: boolean
}

export class CopySnippetElement extends React.Component<CopySnippetElementProps, CopySnippetElementState> {
    constructor(props: CopySnippetElementProps) {
        super(props)
        this.state = {
            copied: false,
        }
    }

    onCopy = () => {
        this.setState({ copied: true })
        setTimeout(() => this.setState({ copied: false }), 2000)
    }

    render() {
        const { copied } = this.state
        const { account, children } = this.props
        const openingComment = '<!-- start Convious Code -->'
        const closingComment = '<!-- end Convious Code -->'
        const script = `<script id="convious_loader" src="https://client.convious-app.com/loader.js?k=${account.slug}"></script>`
        const snippet = `${openingComment}\r\n${script}\r\n${closingComment}`

        return (
            <>
                <CodeBox>
                    <Code>{openingComment}</Code>
                    <Code>{script}</Code>
                    <Code>{closingComment}</Code>
                </CodeBox>
                <Button kind="action" size="large" secondary className={copied ? 'visible' : ''}>
                    Copied!
                </Button>
                <CopyToClipboard text={snippet} onCopy={this.onCopy}>
                    <RelativeButton kind="action" size="large" secondary className={copied ? '' : 'visible'}>
                        Copy snippet
                    </RelativeButton>
                </CopyToClipboard>
                {children}
            </>
        )
    }
}
