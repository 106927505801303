import React from 'react'
import { Col, Row } from 'uiComponents/flex'
import { Headline } from 'uiComponents/typography'

interface ReadOnlyLineProps {
    title: string
}

const ReadOnlyLine: React.FC<ReadOnlyLineProps> = ({ title, children }) => {
    return (
        <Row>
            <Col span={4}>
                <Headline size={6}>{title}</Headline>
            </Col>
            <Col span="auto">{children}</Col>
        </Row>
    )
}

export default ReadOnlyLine
