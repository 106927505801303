export interface ParkMapViewerProps {
    initialCenter: google.maps.LatLng | google.maps.LatLngLiteral
    initialZoom: number
}

export interface ParkMapViewerMethods {
    setLocation: (center: google.maps.LatLng | google.maps.LatLngLiteral) => void
    getMapRegion: () => google.maps.LatLngBounds | undefined
}

export enum SIDES {
    LEFT = 'LEFT',
    TOP = 'TOP',
    RIGHT = 'RIGHT',
    BOTTOM = 'BOTTOM',
}

export interface RegionOverlaySideProps {
    containerWidth: number
    containerHeight: number
    side: SIDES
}

export interface ParkMapViewerRegionOverlayProps {
    showOpacityRegion: boolean
    showDashOutline: boolean
    containerWidth: number
    containerHeight: number
}

export interface ParkMapZoomControls {
    onZoomIn(): void
    onZoomOut(): void
}
