import React, { useRef, useState, useCallback } from 'react'
import { MessageKind } from 'uiComponents/messages'
import { BookingCodesService, UploadDetails } from 'venue/bookingCodes/bookingCodesService'
import UploadPage from 'venue/bookingCodes/uploadPage'
import { LoginService } from 'http/loginService'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { History } from 'history'
import { NavigationBack } from 'uiComponents/navigation/navigationBack'
import Feature from 'features/feature'
import { formatTableState } from 'uiComponents/table/reactTable/tableState'
import { Pagination, Sorting } from 'uiComponents/table'

interface UploadReservationsPageProps {
    accountSlug: string
    bookingCodesService: BookingCodesService
    backofficeEndpoint: string
    loginService: LoginService
    navigation: Navigation
    match: RouteMatch<{ poolId: string }>
    history: History
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

function getPaginationFromQuery(): Pagination {
    const url = new URL(window.location.href)
    return {
        page: Number(url.searchParams.get('page')),
        pageSize: Number(url.searchParams.get('pageSize')),
    }
}

function getSortingFromQuery(): Sorting {
    const url = new URL(window.location.href)
    return {
        prop: url.searchParams.get('sortBy') || 'createdAt',
        direction: url.searchParams.get('sortDirection') as 'asc' | 'desc',
    }
}

function UploadReservationsPage(props: UploadReservationsPageProps) {
    const busyRef = useRef(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [uploadsHistoryList, setUploadsHistoryList] = useState<UploadDetails[]>([])
    const [count, setCount] = useState<number>(0)

    async function getData(
        tableState: any = {
            pagination: getPaginationFromQuery(),
            sorting: getSortingFromQuery(),
        },
    ) {
        setLoading(true)
        const data = await props.bookingCodesService.getReservationsUploadHistoryList(
            props.accountSlug,
            props.match.params.poolId,
            formatTableState.toQueryFromLoadData(tableState),
        )
        setLoading(false)
        setCount(data.count)
        setUploadsHistoryList(data.results)
    }

    const loadData = useCallback(getData, [props.accountSlug, props.match.params.poolId])

    const onUpload = async (file: File) => {
        busyRef.current = true
        return await props.bookingCodesService.uploadReservationCodes(
            file,
            props.accountSlug,
            props.match.params.poolId,
        )
    }

    const getCsvDownloadHref = () => {
        const csvRows = [
            'barcode,valid_from,valid_to,product_id,unlimited',
            '12345678901234567890,2020-01-01,2020-12-01,123456,0',
            '12345678901234567891,2020-01-01,2020-12-01,123456,0',
            '01234567890123456789,2020-01-01,2020-12-01,201111,1',
            '01234567890123456790,2020-01-01,2020-12-01,201111,1',
            'C8523583343294443233,2020-06-01,2021-06-01,345679,1',
        ]
        const csv = csvRows.join('\n')
        return 'data:text/csv;charset=utf-8,' + encodeURI(csv)
    }

    const navigateBack = () => {
        props.history.push(`/account/${props.accountSlug}/venue/codes/reservations/list/?${location.search}`)
    }

    return (
        <>
            <Feature name="RetailersReservationCodes" accountSlug={props.accountSlug}>
                <NavigationBack onClick={navigateBack} text="Back to reservation campaigns list" topOffset="-5em" />
            </Feature>
            <UploadPage
                accountSlug={props.accountSlug}
                uploadsHistoryList={uploadsHistoryList}
                bookingCodesService={props.bookingCodesService}
                loginService={props.loginService}
                backofficeEndpoint={props.backofficeEndpoint}
                uploadType="reservations"
                exampleCSVHref={getCsvDownloadHref()}
                exampleFileName="reservations_upload_example"
                loading={loading}
                onUpload={onUpload}
                reloadUploadsHistoryList={loadData}
                onUploadError={() => (busyRef.current = false)}
                replaceMessages={props.replaceMessages}
                removeAllMessages={props.removeAllMessages}
                loadData={loadData}
                count={count}
                sort={{ prop: 'createdAt', direction: 'desc' }}
            />
        </>
    )
}

export default withNavigation(UploadReservationsPage)
