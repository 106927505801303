import * as React from 'react'
import styled from 'styled-typed'
import { Activity, ActivityEventMappedProperty, ActivityEventProduct } from 'crm/schema'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronUp,
    faChevronDown,
    faCaretUp,
    faCaretDown,
    faMobileAlt,
    faLaptop,
} from '@fortawesome/free-solid-svg-icons'
import { faGlobe, faEnvelope, faMobile, faCube, faUndoAlt, faQuestion } from '@fortawesome/pro-light-svg-icons'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { emailNameMap } from 'emailTemplates/schema'
import Svg from 'svg'
import tapIcon from 'images/tap-icon-white.svg'
import { DateFormats } from 'utils/dates'

const Link = styled(UnstyledLink)`
    color: ${(p) => p.theme.colors.boyBlue};
    &:hover {
        text-decoration: underline;
    }
`
const InARow = styled.div`
    display: flex;
    align-items: center;
    &.space-between {
        justify-content: space-between;
    }
`
const ChevronIcon = styled(FontAwesomeIcon)`
    font-size: 1.16em;
    position: absolute;
    left: 1.2em;
    top: 1.6em;
`
const CaretIcon = styled(FontAwesomeIcon)`
    font-size: 1.16em;
    position: relative;
    top: 0.05em;
    left: 0.4em;
`
const DevicetIcon = styled(FontAwesomeIcon)`
    font-size: 1.5rem;
    margin-bottom: 0.15em;
`
const ActivityNameWrapper = styled.div`
    font-size: 0.875rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 0.875rem;
`
const ActivityIconWrapper = styled.div`
    font-size: 1.45rem;
    display: inline-block;
    background: ${(p) => p.theme.colors.boyBlue};
    color: ${(p) => p.theme.colors.white};
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;
`
const ItemWrapper = styled.div`
    position: relative;
    font-size: 0.75em;
    border: 1px solid ${(p) => p.theme.colors.tableRow};
    padding: 1.6em 1.5em 1.6em 4.25em;
    margin-bottom: 0.7em;

    span {
        opacity: 0.5;
    }
`
const ActivityEventsContainer = styled.div`
    width: fit-content;
    position: relative;
    margin: 2.5em 0 2em 0;
`
const EventContainer = styled.div`
    display: flex;
    align-items: baseline;
`
const EventDate = styled.div`
    min-width: 6em;
`
const EventName = styled.div`
    font-weight: 500;
    margin-bottom: 0.25em;
    cursor: pointer;
`
const EventPropertiesWrapper = styled.div`
    position: relative;
    margin-top: 0.8em;
    padding-left: 1em;
    &:before {
        border-left: 3px solid ${(p) => p.theme.colors.boyBlue};
        content: ' ';
        position: absolute;
        left: -3em;
        top: 0;
        bottom: 0;
    }
    &.no-border {
        &:before {
            border-left: none;
        }
    }
`
const EventPropertiesWrapperPlaceholder = styled(EventPropertiesWrapper)`
    min-height: 2.5em;
`
const EventProp = styled.div`
    line-height: 1.5em;
    max-width: 70em;
    word-break: break-word;
`
const IndentedPropBlock = styled.div`
    padding-left: 1em;
`
const LinkAsEventName = styled.div`
    display: inline-block;
    text-decoration: underline;
    max-width: 51em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: -0.25em;
`
const GlobeIconInLink = styled(FontAwesomeIcon)`
    font-size: 1.1em;
    margin-right: 0.5em;
    margin-bottom: -0.05em;
`

const activityIconMap = {
    'Web session activity': faGlobe,
    'Email activity': faEnvelope,
    'Tap session activity': faMobile,
    'Widget activity': faCube,
    'Refund activity': faUndoAlt,
}

const deviceIconMap = {
    Mobile: faMobileAlt,
    Desktop: faLaptop,
    Unknown: faQuestion,
}

const getPropertyName = (name: string) => {
    const firstLetter = name.slice(0, 1).toUpperCase()
    const prettyName = firstLetter + name.substring(1)
    return prettyName.replace(/_/g, ' ')
}

const formatValue = (name: string, value: string | string[], accountSlug: string) => {
    switch (name) {
        case 'google_analytics_cid':
            return (value as string[]).join(', ')
        case 'order_number':
            return <Link to={`/account/${accountSlug}/orders/transactions/?q=${value}`}>{value as string}</Link>
        case 'category':
            return (value as string[]).map((v) => emailNameMap[v]).join(', ')
        default:
            return value as string
    }
}

const mapEventProperties = (properties: any, accountSlug: string) => {
    const fieldsToHide = ['event_type', 'event', 'timestamp', 'order_id', 'order_uuid', 'article_uuid', 'cart_id']
    fieldsToHide.forEach((f) => delete properties[f])
    const mappedProperties: ActivityEventMappedProperty[] = []
    Object.entries(properties).forEach(([key, value]) => {
        if (value) {
            mappedProperties.push({
                name: getPropertyName(key),
                value: formatValue(key, value as string | string[], accountSlug),
            })
        }
    })
    return mappedProperties
}

interface ActivityItemProps {
    accountSlug: string
    activity: Activity
    activityExpanded: boolean
    eventsExpanded: string[]
    setActivityItemExpanded: (item: string) => void
    setEventItemExpanded: (ev: React.MouseEvent, item: string) => void
}

export function ActivityItem(props: ActivityItemProps) {
    const { activity, activityExpanded, eventsExpanded } = props

    return (
        <ItemWrapper>
            <InARow className="space-between" onClick={() => props.setActivityItemExpanded(activity.activityStartTime)}>
                <ActivityNameWrapper>
                    <ChevronIcon icon={activityExpanded ? faChevronUp : faChevronDown} />
                    <ActivityIconWrapper>
                        {!!activityIconMap[activity.activityName] &&
                            activity.activityName !== 'Tap session activity' && (
                                <FontAwesomeIcon icon={activityIconMap[activity.activityName]} />
                            )}
                        {activity.activityName === 'Tap session activity' && (
                            <Svg src={tapIcon} style={{ width: '1em' }} />
                        )}
                    </ActivityIconWrapper>
                    <span>{activity.activityName} detected on&nbsp;</span>
                    {format(new Date(activity.activityStartTime), `${DateFormats.LONG_DATE}, ${DateFormats.LONG_TIME}`)}
                </ActivityNameWrapper>
                <InARow>
                    {!!activity.sessionTime && (
                        <div>
                            <span>Session time: </span>
                            {activity.sessionTime}
                        </div>
                    )}
                    {!!activity.activityDeviceType && !!deviceIconMap[activity.activityDeviceType] && (
                        <InARow style={{ marginLeft: '3em' }}>
                            <span style={{ marginRight: '0.4em', fontSize: '0.875rem' }}>Device:</span>
                            <DevicetIcon icon={deviceIconMap[activity.activityDeviceType]} />
                        </InARow>
                    )}
                </InARow>
            </InARow>
            {activityExpanded && (
                <ActivityEventsContainer onClick={(ev) => ev.stopPropagation()}>
                    {activity.activityEvents.map((e, i) => {
                        const expanded = eventsExpanded.indexOf(e.timestamp) > -1
                        const eventProperties = mapEventProperties(e.properties, props.accountSlug)
                        const lastEvent = i === activity.activityEvents.length - 1
                        return (
                            <EventContainer key={activity.activityStartTime + i}>
                                <EventDate>{format(new Date(e.timestamp), DateFormats.LONG_TIME)}</EventDate>
                                <RingedDot />
                                <div>
                                    <EventName onClick={(ev) => props.setEventItemExpanded(ev, e.timestamp)}>
                                        {e.event !== 'Webpage visit' && <>{e.event}</>}
                                        {e.event === 'Webpage visit' && (
                                            <LinkAsEventName>
                                                <GlobeIconInLink icon={faGlobe} />
                                                {e.properties?.landing_page || 'Webpage visit'}
                                            </LinkAsEventName>
                                        )}
                                        {eventProperties.length > 0 && (
                                            <CaretIcon icon={expanded ? faCaretUp : faCaretDown} />
                                        )}
                                    </EventName>
                                    {(eventProperties.length === 0 || !expanded) && !lastEvent && (
                                        <EventPropertiesWrapperPlaceholder />
                                    )}
                                    {eventProperties.length > 0 && expanded && (
                                        <EventPropertiesWrapper className={lastEvent ? 'no-border' : ''}>
                                            {eventProperties.map((p) => {
                                                if (p.name === 'Products') {
                                                    return (
                                                        <PropertyWithListValue
                                                            key={activity.activityStartTime + i + p.name}
                                                            property={p}
                                                        />
                                                    )
                                                }
                                                return (
                                                    <EventProp key={activity.activityStartTime + i + p.name}>
                                                        <span>{p.name}: </span>
                                                        {p.value}
                                                    </EventProp>
                                                )
                                            })}
                                        </EventPropertiesWrapper>
                                    )}
                                </div>
                            </EventContainer>
                        )
                    })}
                </ActivityEventsContainer>
            )}
        </ItemWrapper>
    )
}

interface PropertyWithListValueProps {
    property: ActivityEventMappedProperty
}

function PropertyWithListValue(props: PropertyWithListValueProps) {
    const value = props.property.value as ActivityEventProduct[]
    const productPropList = value.map((v) => mapEventProperties(v, ''))
    return (
        <EventProp>
            <span>{props.property.name}:</span>
            {value.map((v, i) => (
                <IndentedPropBlock key={v.product_name}>
                    {v.product_name}:
                    <IndentedPropBlock>
                        {productPropList[i].map((p, y) => {
                            if (p.name === 'Product name') {
                                return null
                            }
                            return (
                                <EventProp key={y}>
                                    <span>{p.name}: </span>
                                    {p.value}
                                </EventProp>
                            )
                        })}
                    </IndentedPropBlock>
                </IndentedPropBlock>
            ))}
        </EventProp>
    )
}

const DotContainer = styled.div`
    position: relative;
    top: 0.5em;
    min-width: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 2em;
`
const OuterRing = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: ${(p) => p.theme.colors.boyBlue};
`
const InnerRing = styled.div`
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    position: absolute;
    background: ${(p) => p.theme.colors.white};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`
const Dot = styled(InnerRing)`
    width: 0.625rem;
    height: 0.625rem;
    background: ${(p) => p.theme.colors.boyBlue};
`

function RingedDot() {
    return (
        <DotContainer>
            <OuterRing>
                <InnerRing>
                    <Dot />
                </InnerRing>
            </OuterRing>
        </DotContainer>
    )
}
