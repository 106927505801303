import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { State } from 'store'
import styled from 'styled-typed'
import { LocalesInfo, Locale } from 'admin/articleService'
import { VenueDetails, GoogleListingItem } from 'channels/googleTTDService'
import { Row, Col } from 'uiComponents/flex'
import { ChartDataLoader } from 'uiComponents/loaders'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { SingleSelect, SingleSelectOption, RichTextInput, TextInput, MultiSelect } from 'uiComponents/input'
import { ButtonWrapper } from 'uiComponents/pageElements'
import { ActionButton } from 'uiComponents/buttons'
import { Tabs } from 'uiComponents/navigation/tabs'
import { UploadInput } from 'uiComponents/input/upload'
import { SecondaryText, Body } from 'uiComponents/typography'
import { googleTTDCategories } from './consts'
import { MessageKind } from 'uiComponents/messages'
import { ImageUploadResponse } from 'http/imagesService'

export const Separator = styled.div`
    width: 100%;
    border-bottom: 1px solid ${(p) => p.theme.colors.aluminiumShades[5]};
    margin: 2em 0 3em 0;
`
const ImageUrlCol = styled(Col)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`
const Link = styled.a`
    color: ${(p) => p.theme.colors.boyBlue};
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`
const LinkText = styled(Body)`
    overflow: hidden;
    text-overflow: ellipsis;
`

interface VenueSectionProps {
    index: number
    accountSlug: string
    item: GoogleListingItem
    locales: LocalesInfo | null
    venueDataList: VenueDetails[]
    existingListings: string[]
    updating: boolean
    markErrors: boolean
    onVenueChange: (id: string) => void
    onPlaceIdChange: (id: string) => void
    onProductChange: (id: string) => void
    onCategoryChange: (ids: string[]) => void
    onDescriptionChange: (v: string, language: string) => void
    onSaveItem: () => void
    onDeleteItem: () => void
    onImageUpload: (file: File) => Promise<any>
    onImageUploadSuccess: (response: ImageUploadResponse) => void
    onRemoveImage: () => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

export function VenueSection(props: VenueSectionProps) {
    const isAdmin = useSelector((state: State) => state.auth.user?.isAdmin || false)

    const [activeLanguageIndex, setActiveLanguageIndex] = useState<number>(0)
    const [venueOptions, setVenueOptions] = useState<SingleSelectOption[]>([])
    const [productOptions, setProductOptions] = useState<SingleSelectOption[]>([])
    const [noDescriptions, setNoDescriptions] = useState<boolean>(true)

    React.useEffect(() => {
        const unlistedVenues = props.venueDataList.filter(
            (i) => !props.existingListings.includes(i.locationId) || i.locationId === props.item.locationId,
        )
        setVenueOptions(
            unlistedVenues.map((i) => ({
                name: i.locationName,
                value: i.locationId,
            })),
        )
        if (props.item.locationId) {
            const venue = props.venueDataList.find((v) => v.locationId === props.item.locationId)
            const productList = venue?.products || []
            setProductOptions(
                productList.map((i) => ({
                    name: `[ ${i.internalId} ] ${i.name}`,
                    value: i.uuid,
                })),
            )
        }
    }, [props.venueDataList, props.item, props.existingListings])

    React.useEffect(() => {
        setMissingLanguage()
    }, [props.markErrors])

    const setMissingLanguage = () => {
        if (props.markErrors) {
            let missingLocale: Locale | null = null
            let allEmpty = true
            const localesList = props.locales?.locales || []
            localesList.forEach((loc) => {
                if (!props.item.description.find((d) => d.language === loc.code)?.text) {
                    missingLocale = missingLocale || loc
                } else {
                    allEmpty = false
                }
            })
            setNoDescriptions(allEmpty)
            if (!missingLocale || allEmpty) {
                return
            }
            const index = props.locales?.locales.indexOf(missingLocale) || 0
            setActiveLanguageIndex(index)
        }
    }

    const onSaveItem = () => {
        setMissingLanguage()
        props.onSaveItem()
    }

    const locales = props.locales?.locales || []
    const placeIdError = props.markErrors && !props.item.googlePlaceId
    const productError = props.markErrors && !props.item.options[0].articleUUID
    const categoryError = props.markErrors && !props.item.options[0].category.length

    return (
        <div style={{ position: 'relative' }}>
            {props.updating && <ChartDataLoader />}
            <Separator />
            <Row>
                <Col span={6}>
                    <Row>
                        <Col span={12}>
                            <FormItem htmlFor="place-id">
                                <FormItemName>Place ID</FormItemName>
                                <TextInput
                                    id="place-id"
                                    placeholder="ID number"
                                    value={props.item.googlePlaceId}
                                    onChange={(e) => props.onPlaceIdChange(e.target.value.trim())}
                                    disabled={!isAdmin || !props.item.locationId}
                                    block
                                    style={{ marginBottom: '0', height: '2.5rem' }}
                                    status={placeIdError ? 'error' : 'normal'}
                                />
                            </FormItem>
                            <ValidationMessage />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormItem htmlFor="product">
                                <FormItemName>Product</FormItemName>
                                <SingleSelect
                                    id="product"
                                    options={productOptions}
                                    selected={props.item.options[0]?.articleUUID || ''}
                                    height="2.5rem"
                                    noSelectOption="No product selected"
                                    onSelect={props.onProductChange}
                                    disabled={!isAdmin || !props.item.locationId}
                                    status={productError ? 'error' : 'normal'}
                                />
                            </FormItem>
                            <ValidationMessage />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormItem htmlFor="categories">
                                <FormItemName>Categories</FormItemName>
                                <MultiSelect
                                    id="categories"
                                    options={googleTTDCategories}
                                    selected={props.item.options[0]?.category || []}
                                    viewOnly={!isAdmin}
                                    height="2.5rem"
                                    maxHeight="25em"
                                    noSelectOption="No categories selected"
                                    onSelect={props.onCategoryChange}
                                    status={categoryError ? 'error' : 'normal'}
                                />
                            </FormItem>
                            <ValidationMessage />
                        </Col>
                    </Row>
                </Col>
                <Col span={6}>
                    <Row>
                        <Col span={12}>
                            <FormItem htmlFor="venue-name">
                                <FormItemName>Location</FormItemName>
                                {venueOptions.length < 2 && (
                                    <TextInput
                                        placeholder="ID number"
                                        value={
                                            props.venueDataList.find((v) => v.locationId === props.item.locationId)
                                                ?.locationName
                                        }
                                        disabled
                                        block
                                        style={{ marginBottom: '0', height: '2.5rem' }}
                                    />
                                )}
                                {venueOptions.length > 1 && (
                                    <SingleSelect
                                        options={venueOptions}
                                        selected={props.item.locationId}
                                        height="2.5rem"
                                        noSelectOption="No venue selected"
                                        onSelect={props.onVenueChange}
                                        disabled={!isAdmin}
                                    />
                                )}
                            </FormItem>
                            <ValidationMessage />
                        </Col>
                    </Row>
                    <FormItemName>Translations</FormItemName>
                    <Tabs
                        titles={locales.map((l) => l.name)}
                        setActive={setActiveLanguageIndex}
                        activeTabIndex={activeLanguageIndex}
                    >
                        {locales.map((l) => {
                            const languageError =
                                props.markErrors &&
                                !noDescriptions &&
                                !props.item.description.find((d) => d.language === l.code)?.text
                            return (
                                <div key={`${props.item.id}-${l.code}`}>
                                    <FormItem htmlFor={`venue-description-${props.item.id}-${l.code}`}>
                                        <FormItemName>Venue description</FormItemName>
                                        <RichTextInput
                                            id={`venue-description-${props.item.id}-${l.code}`}
                                            onChange={(v) => props.onDescriptionChange(v, l.code)}
                                            value={
                                                props.item.description.find((d) => d.language === l.code)?.text || ''
                                            }
                                            maxLength={10000}
                                            showCharsLeft
                                            status={languageError ? 'error' : 'normal'}
                                            withOrderedList
                                            withUnorderedList
                                            hideAlignmentOption
                                        />
                                    </FormItem>
                                </div>
                            )
                        })}
                    </Tabs>
                    <Row style={{ marginTop: '-1em' }}>
                        <Col span={12}>
                            <FormItem htmlFor={`venue-image-${props.item.id}`}>
                                <FormItemName>Venue image</FormItemName>
                                <UploadInput
                                    type="img"
                                    fileSizeLimit={1}
                                    onUpload={props.onImageUpload}
                                    handleUploadSuccessResponse={props.onImageUploadSuccess}
                                    inputId={`venue-image-${props.item.id}`}
                                    parseErrorMessage
                                    replaceTopMessages={props.replaceMessages}
                                    removeAllMessages={props.removeAllMessages}
                                />
                                <Body size={3} style={{ float: 'right' }}>
                                    Square image minimum size 300x300
                                </Body>
                            </FormItem>
                        </Col>
                    </Row>
                    <FormItemName>Current image url:</FormItemName>
                    <Row>
                        {props.item.image && (
                            <>
                                <ImageUrlCol span={9}>
                                    <Link href={props.item.image} target="_blank" title={props.item.image}>
                                        <LinkText size={1}>{props.item.image}</LinkText>
                                    </Link>
                                </ImageUrlCol>
                                <Col
                                    span={3}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                    }}
                                >
                                    <SecondaryText
                                        onClick={props.onRemoveImage}
                                        style={{ margin: '0', cursor: 'pointer' }}
                                    >
                                        x Remove
                                    </SecondaryText>
                                </Col>
                            </>
                        )}
                        {!props.item.image && (
                            <Body size={1}>
                                <SecondaryText>No image uploaded</SecondaryText>
                            </Body>
                        )}
                    </Row>
                    <ButtonWrapper style={{ margin: '2em 0' }}>
                        {props.index !== 0 && (
                            <ActionButton secondary kind="destructive" onClick={props.onDeleteItem}>
                                Delete
                            </ActionButton>
                        )}
                        <ActionButton onClick={() => onSaveItem()} style={{ marginLeft: '1.5em' }}>
                            Save
                        </ActionButton>
                    </ButtonWrapper>
                </Col>
            </Row>
        </div>
    )
}
