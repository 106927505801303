import * as React from 'react'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { UseFormInput } from '../schema'
import { renderInputComponent } from './components'
import { TextField } from 'uiComponents/studio/form/fieldComponentSchema'

interface Props {
    actionLink: UseFormInput
}

const ActionSection = ({ actionLink }: Props) => {
    const actionLinkField: TextField = {
        name: 'action-link',
        type: 'text',
        label: 'Link',
        path: '',
        defaultValue: '',
    }

    return <SectionWrapper title="Action">{renderInputComponent(actionLinkField, actionLink)}</SectionWrapper>
}

export default ActionSection
