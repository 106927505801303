import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Container, ContainerHeading, HeadingSectionName } from 'uiComponents/settingsContainer'
import { Headline, StyledATag } from 'uiComponents/typography'
import Feature from 'features/feature'
import styled from 'styled-typed'
import { ActionButtonA } from 'uiComponents/buttons'
import ParkMapProvider from '../context/mapViewer'
import SelectedParkMapProvider from '../context/selectedParkMap'
import { ParkMapPagesParams } from '../paths'
import { useListParkMapQuery } from '../reduxQuery'

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
`

const HeaderText = styled.div`
    font-size: 1.1em;
    font-weight: lighter;
`

const ParkMapsContainer: React.FC = ({ children }) => {
    const { accountSlug } = useParams<ParkMapPagesParams>()
    const { data } = useListParkMapQuery({ slug: accountSlug })
    const parkmaps = data ?? []
    const location = useLocation()
    const isAddMapButtonHidden = ['details', 'styles', 'cat_poi'].some((path) => location.pathname.includes(path))

    return (
        <SelectedParkMapProvider>
            <ParkMapProvider>
                <div style={{ position: 'relative' }} id="park-map">
                    <Headline size={1} style={{ marginBottom: '0.5em' }}>
                        Park Map
                    </Headline>
                    <Feature name="parkMap">
                        <Header>
                            <HeaderText>
                                You can learn more about the park map configuration&nbsp;
                                <StyledATag
                                    target="_blank"
                                    href="https://support.convious.com/help/park-map-configurator-31b8cc3f"
                                >
                                    here
                                </StyledATag>
                            </HeaderText>
                            {parkmaps.length > 0 && !isAddMapButtonHidden && (
                                <ActionButtonA
                                    size="medium"
                                    href={`/account/${accountSlug}/venue/park_map/create/details`}
                                    kind="action"
                                >
                                    + Add new
                                </ActionButtonA>
                            )}
                        </Header>
                        <Container>
                            <ContainerHeading>
                                <HeadingSectionName className="active" to={`/account/${accountSlug}/venue/park_map`}>
                                    Park Maps
                                </HeadingSectionName>
                            </ContainerHeading>
                            {children}
                        </Container>
                    </Feature>
                </div>
            </ParkMapProvider>
        </SelectedParkMapProvider>
    )
}

export default ParkMapsContainer
