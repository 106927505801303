import React, { useContext } from 'react'
import { InlineEdit } from 'uiComponents/input'
import { DiscountRule } from 'products/discountRules/discountRulesService'
import { DiscountRulesServiceContext } from 'products/discountRules/context'
import { DiscountRulesContext } from 'products/discountRules/table/tableContext'
import { MetadataContext } from 'products/discountRules/metadataContext'

interface InlineNameProps {
    rule: DiscountRule
}

function InlineName({ rule }: InlineNameProps) {
    const discountRulesService = useContext(DiscountRulesServiceContext)
    const { reloadData } = useContext(DiscountRulesContext)
    const { accountSlug, replaceMessages } = useContext(MetadataContext)

    const saveNewName = async (orderId: number, email: string) => {
        try {
            await discountRulesService.updateDiscountRuleName(accountSlug, orderId, email)
            reloadData()
        } catch {
            replaceMessages(
                'email_error',
                'error',
                'Oops! There was a problem with saving new rule name. Please try again.',
            )
        }
    }

    return (
        <InlineEdit
            id="ruleName"
            value={rule.name}
            onEditAccept={(newEmail) => saveNewName(rule.id, newEmail)}
            limitedTextWidth="22em"
            responsiveWidth
            accountSlug={accountSlug}
        />
    )
}

export default InlineName
