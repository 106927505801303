import * as React from 'react'
import { ErrorMessage, FormItem } from 'ticketTemplates/studio/imports'
import { Col } from 'uiComponents/flex'
import { RichTextInput } from 'uiComponents/input/richTextInput'
import { Translation } from 'ticketTemplates/ticketTemplatesService'

interface CopySectionProps {
    activeLanguage: string
    onFinishTyping: (value: string) => void
    handleTranslations: (value: string) => void
    moreInformation: Translation
    additionalInfoError: string
}

function CopySection(props: CopySectionProps) {
    const onFinishTyping = (value: string) => {
        props.onFinishTyping(value)
    }

    type TextField = 'text'
    const moreInfoField = {
        name: 'more-information',
        type: 'text' as TextField,
        label: 'Important information',
        path: '',
        defaultValue: 'Important information',
    }

    const showError = props.additionalInfoError && !props.moreInformation.text[props.activeLanguage]

    return (
        <FormItem>
            <Col span={12}>
                <label htmlFor={moreInfoField.name}>{moreInfoField.label}</label>
            </Col>
            <Col span={12}>
                <RichTextInput
                    id={moreInfoField.name}
                    onChange={props.handleTranslations}
                    value={props.moreInformation.text[props.activeLanguage] || ''}
                    withLink
                    withUnorderedList
                    maxLength={1500}
                    onFinishTyping={onFinishTyping}
                    status={showError ? 'error' : 'normal'}
                />
                {!!showError && <ErrorMessage errText={props.additionalInfoError} />}
            </Col>
        </FormItem>
    )
}

export default CopySection
