import React from 'react'
import styled from 'styled-typed'
import { ActionButton } from 'uiComponents/buttons'
import Svg from 'svg'
import googleLogo from './google-logo.svg'

interface GoogleButtonProps {
    onClick: () => void
    buttonText: string
    style?: React.CSSProperties
}

const GoogleButtonText = styled.div`
    width: 100%;
    max-width: 17em;
    line-height: 2.875em;
    color: ${(props) => props.theme.colors.textLight};
`

const Icon = styled(Svg)`
    width: 3.5625em;
    height: 2.8125em;
    flex: 0 0 3.5625em;
    padding: 0.35em 0.8125em 0.35em 1em;
    border-right: 1px solid rgba(161, 176, 178, 0.5);
`

const Button = styled(ActionButton)`
    box-shadow: 0 1px 15px 0 rgba(35, 48, 57, 0.25);
    background: #f8f9fb;

    && {
        padding: 0;
        display: flex;
    }
`

export function GoogleButton({ onClick, buttonText, style }: GoogleButtonProps) {
    return (
        <Button id="google" size="large" kind="action" block={true} onClick={onClick} style={style}>
            <div style={{ maxHeight: '2.875em' }}>
                <Icon src={googleLogo} />
            </div>
            <GoogleButtonText>{buttonText}</GoogleButtonText>
        </Button>
    )
}
