import * as React from 'react'
import styled from 'styled-typed'
import { History } from 'history'
import {
    FormContainer,
    FormContent,
    SidebarMenu,
    Form,
    FormBody,
    ScrollArea,
    Menu,
    SidebarTitle,
} from 'uiComponents/studio/form/formComponents'
import MenuItem from 'uiComponents/studio/menuItem'
import { PageLoader } from 'uiComponents/loaders'
import LanguagesSection from './languagesSection'
import GlobalSection from './globalSection'
import { MessageKind } from 'uiComponents/messages'
import { Locale, Upsell } from 'admin/articleService'
import {
    TemplateType,
    TemplateSettingsWithVariables,
    FormItemType,
    MenuStepType,
    templatesConfig,
} from 'emailTemplates/schema'
import { ImageUploadResponse } from 'http/imagesService'
import copyIcon from 'uiComponents/studio/icons/copy.svg'
import settingsIcon from 'uiComponents/studio/icons/settings.svg'
import { Footer } from 'uiComponents/studio/form/footer'
import StudioConviousLogo from 'uiComponents/studio/studioLogo'
import { SingleSelectOption } from 'uiComponents/input/singleSelect'

const Header = styled.div`
    font-size: 1.25em;
    padding: 1.2em 1.2em 0.6em 1.2em;
    span {
        font-size: 0.6em;
        display: inline-block;
        margin-top: 0.8em;
    }
`

interface StudioFormProps {
    currentStep: MenuStepType
    setCurrentStep: (step: MenuStepType) => void
    history: History
    accountSlug: string
    templateType: TemplateType
    defaultLanguage: string
    activeLanguage: string
    accountLocales: Locale[]
    templateSettings: TemplateSettingsWithVariables
    unusedFields: FormItemType[]
    markAllErrors: boolean
    loading: boolean
    sendingEmail: boolean
    discountReservoirOptions: SingleSelectOption[]
    allUpsells: Upsell[]
    enabledUpsells: Upsell[]
    onPublish: () => void
    onFinishTyping: (value: string, item: FormItemType) => void
    toggleUnusedField: (item: FormItemType, add: boolean) => void
    handleItemChange: (value: string | Upsell[], item: FormItemType) => void
    onTestEmailSend: () => void
    onUpload: (file: File) => Promise<any>
    onUploadSuccess: (response: ImageUploadResponse, type: 'logo' | 'agentPhoto') => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

function StudioForm(props: StudioFormProps) {
    const {
        loading,
        defaultLanguage,
        activeLanguage,
        accountLocales,
        templateSettings,
        handleItemChange,
        onTestEmailSend,
        onUpload,
        toggleUnusedField,
        unusedFields,
        onUploadSuccess,
        discountReservoirOptions,
        replaceMessages,
        removeAllMessages,
        markAllErrors,
        onFinishTyping,
        sendingEmail,
    } = props

    return (
        <FormContainer>
            <FormContent>
                <SidebarMenu>
                    <StudioConviousLogo />
                    <SidebarTitle>{templatesConfig[props.templateType].name}</SidebarTitle>
                    <nav>
                        <Menu>
                            <MenuItem
                                product=""
                                accountSlug={props.accountSlug}
                                item={{
                                    name: 'settings',
                                    label: '1. Settings',
                                    icon: settingsIcon,
                                }}
                                highlighted={props.currentStep === 'settings'}
                                history={props.history}
                                onClick={props.setCurrentStep}
                                configurationId=""
                            />
                            <MenuItem
                                product=""
                                accountSlug={props.accountSlug}
                                item={{ name: 'text', label: '2. Text', icon: copyIcon }}
                                highlighted={props.currentStep === 'text'}
                                history={props.history}
                                onClick={props.setCurrentStep}
                                configurationId=""
                            />
                        </Menu>
                    </nav>
                </SidebarMenu>
                <Form noValidate>
                    <FormBody>
                        {!loading && (
                            <>
                                <Header>
                                    Edit your Email Template
                                    {props.currentStep === 'settings' ? (
                                        <span>
                                            Edit your emails’ settings and send yourself a test version to double-check.
                                        </span>
                                    ) : (
                                        <span>Edit your emails’ content and all corresponding translations.</span>
                                    )}
                                </Header>
                                <ScrollArea id="scroll-area">
                                    {props.currentStep === 'settings' && (
                                        <GlobalSection
                                            accountSlug={props.accountSlug}
                                            sendingEmail={sendingEmail}
                                            templateType={props.templateType}
                                            discountReservoirOptions={discountReservoirOptions}
                                            allUpsells={props.allUpsells}
                                            enabledUpsells={props.enabledUpsells}
                                            markAllErrors={props.markAllErrors}
                                            onSend={onTestEmailSend}
                                            onUpload={onUpload}
                                            onUploadSuccess={onUploadSuccess}
                                            handleChange={handleItemChange}
                                            templateSettings={templateSettings}
                                            replaceTopMessages={replaceMessages}
                                            removeAllMessages={removeAllMessages}
                                        />
                                    )}
                                    {props.currentStep === 'text' && (
                                        <LanguagesSection
                                            templateType={props.templateType}
                                            defaultLanguage={defaultLanguage}
                                            activeLanguage={activeLanguage}
                                            locales={accountLocales}
                                            templateSettings={templateSettings}
                                            handleChange={handleItemChange}
                                            toggleUnusedField={toggleUnusedField}
                                            unusedFields={unusedFields}
                                            markAllErrors={markAllErrors}
                                            onFinishTyping={onFinishTyping}
                                        />
                                    )}
                                </ScrollArea>
                            </>
                        )}
                        {loading && <PageLoader />}
                    </FormBody>
                </Form>
            </FormContent>
            <Footer
                accountSlug={props.accountSlug}
                menuItems={['settings', 'text']}
                formSubmit={props.onPublish}
                history={props.history}
                ongoingPublish={false}
                currentStepOverride={props.currentStep}
                navPathOverride={`/account/${props.accountSlug}/engage/email_templates/edit/${props.templateType}`}
                currentStep=""
                product=""
                configurationId=""
                componentValues={null}
            />
        </FormContainer>
    )
}

export default StudioForm
