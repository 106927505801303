import React from 'react'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { useDispatch } from 'react-redux'
import { addMessage, removeMessage } from 'uiComponents/messages/actions'
import { useMarkCartAsPaidMutation } from 'orders/reduxQueries'
import { ErrorResponse, LoggerReject } from '../../../types'

export interface MarkCartAsPaidConfirmationDialogProps {
    accountSlug: string
    cartId: string | null
    onConfirm: () => void
    onCancel: () => void
}

export const MarkCartAsPaidConfirmationDialog: React.FC<MarkCartAsPaidConfirmationDialogProps> = ({
    accountSlug,
    cartId,
    onConfirm,
    onCancel,
}) => {
    if (!cartId) {
        return null
    }

    const [markCartAsPaid, { isLoading }] = useMarkCartAsPaidMutation()
    const dispatch = useDispatch()

    const onMarkCartPaid = () => {
        markCartAsPaid({
            accountSlug,
            cartId,
        })
            .unwrap()
            .then(onConfirm)
            .catch((error?: LoggerReject<ErrorResponse<any>>) => {
                onConfirm()
                const errorVisibleTime = 5000

                dispatch(
                    addMessage(
                        'mark_cart_as_paid',
                        'error',
                        error?.response?.error?.message || 'Failed to mark cart as paid',
                    ),
                )

                setTimeout(() => {
                    dispatch(removeMessage('mark_cart_as_paid'))
                }, errorVisibleTime)
            })
    }

    return (
        <ConfirmationDialog
            title="Mark cart as paid?"
            text="This action cannot be undone. Do you want to proceed?"
            buttonText="Mark as paid"
            loading={isLoading}
            onConfirm={onMarkCartPaid}
            onCancel={onCancel}
        />
    )
}
