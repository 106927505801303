import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-typed'
import { Row, Col } from 'uiComponents/flex'
import { Radio, RadioWrapper, RadioText } from 'uiComponents/input/radio'
import { SingleSelectOption, NumberInputField } from 'uiComponents/input'
import { InventoryPool } from 'admin/articleService'
import { InventoryItem } from 'inventory/inventoryService'
import { ActionButton } from 'uiComponents/buttons'
import cloneDeep from 'lodash/cloneDeep'
import { SecondaryText } from 'uiComponents/typography'
import SingleSelectField from 'uiComponents/input/singleSelect/singleSelectField'
import { useHasFeature } from 'utils/useHasFeature'
import { LEFT_SIDE_SPAN } from './crud/common'
import { useGetAllCategories } from 'products/redux'

const DeleteNote = styled.div`
    cursor: pointer;
    position: relative;
    top: 3em;
    left: -2em;
`
type InventoryErrors = { poolId?: string; productListId?: string; amountUsed?: string }

interface InventorySelectProps {
    errors?: InventoryErrors
    inventoryOptions: SingleSelectOption[]
    inventoryPool: InventoryPool
    missingId: boolean
    onPoolChange: (poolId: string) => void
    onListChange: (listId: string) => void
    onAmountChange: (amount: string) => void
    onRemovePool: () => void
}

function InventorySelectRow({ onListChange, errors, ...props }: InventorySelectProps) {
    const productList = useGetAllCategories()
    const formattedData = useMemo(() => {
        const parents = productList.map((p) => p.parentCategory?.id)
        return productList.map((p) => ({
            name: p.name?.en || p.name?.[Object.keys(p.name)[0]] || '',
            value: p.id,
            disabled: parents?.includes(p.id),
            parentId: p.parentCategory?.id,
        }))
    }, [productList])

    const showProductList = useHasFeature({
        featureName: 'productListInInventory',
    })

    return (
        <Row style={{ marginBottom: '1em', position: 'relative' }}>
            <Col span={showProductList ? 4 : LEFT_SIDE_SPAN}>
                <SingleSelectField
                    id="barcode-pool"
                    options={props.inventoryOptions}
                    value={props.inventoryPool.poolId}
                    maxHeight="11em"
                    noSelectOption="Select inventory pool"
                    onChange={(v) => props.onPoolChange(v)}
                    error={errors?.poolId}
                    label="Inventory"
                    name="poolId"
                />
            </Col>
            {!!showProductList && (
                <Col span={4}>
                    <SingleSelectField
                        nested
                        withClear
                        label="Product list"
                        maxHeight="150px"
                        name="productListId"
                        onChange={onListChange}
                        error={errors?.productListId}
                        options={formattedData}
                        value={props.inventoryPool.productListId?.toString() || ''}
                    />
                </Col>
            )}
            <Col span={2} style={{ paddingRight: '0.5em' }}>
                <NumberInputField
                    label="Capacity reduction"
                    style={{ width: '4rem' }}
                    id="amount-used"
                    integerOnly
                    name="amountUsed"
                    value={props.inventoryPool.amountUsed as any}
                    onChange={(e) => props.onAmountChange(e.target.value)}
                    error={errors?.amountUsed}
                />
            </Col>
            <Col span={1} style={{ padding: '0' }}>
                <DeleteNote onClick={props.onRemovePool}>
                    <SecondaryText>&times; Remove</SecondaryText>
                </DeleteNote>
            </Col>
            <Col span={3} />
        </Row>
    )
}

const dummyInventoryPool = {
    poolId: '',
    amountUsed: 1,
}

interface InventorySectionProps {
    inventoryPools: InventoryItem[]
    usedInventory: InventoryPool[]
    missingId: boolean
    updateInventoryData: (updatedInventory: InventoryPool[]) => void
    fieldErrors?: Array<InventoryErrors>
}

export function InventorySection(props: InventorySectionProps) {
    const [usingPool, setUsingPool] = useState<boolean>(false)
    const [updatedInventory, setUpdatedInventory] = useState<InventoryPool[]>([])
    const [inventoryOptions, setInventoryOptions] = useState<SingleSelectOption[]>([])

    const updateInventoryOptions = () => {
        const allUsedPoolIds = updatedInventory.map((p) => p.poolId)
        const options = props.inventoryPools
            .filter((p) => allUsedPoolIds.indexOf(p.id) === -1)
            .map((i) => ({
                name: i.name,
                value: i.id,
            }))
        setInventoryOptions(options)
    }

    useEffect(() => {
        updateInventoryOptions()
    }, [props.inventoryPools])

    useEffect(() => {
        if (
            props.usedInventory.length > 0 &&
            JSON.stringify(updatedInventory) !== JSON.stringify(props.usedInventory)
        ) {
            setUsingPool(true)
            setUpdatedInventory(props.usedInventory)
        }
    }, [props.usedInventory])

    useEffect(() => {
        updateInventoryOptions()
        props.updateInventoryData(updatedInventory)
    }, [updatedInventory])

    const onSetUnlimitedInventory = () => {
        setUsingPool(false)
        setUpdatedInventory([])
    }

    const onUseInventoryPool = () => {
        setUsingPool(true)
        setUpdatedInventory([dummyInventoryPool])
    }

    const onPoolChange = (index: number, poolId: string) => {
        const copy = cloneDeep(updatedInventory)
        copy[index].poolId = poolId
        setUpdatedInventory(copy)
    }

    const onAmountChange = (index: number, amount: string) => {
        const copy = cloneDeep(updatedInventory)
        copy[index].amountUsed = Number(amount)
        setUpdatedInventory(copy)
    }

    const onListChange = (index: number, uuid: string) => {
        const copy = cloneDeep(updatedInventory)
        copy[index].productListId = uuid
        setUpdatedInventory(copy)
    }

    const onAddPool = () => {
        const copy = cloneDeep(updatedInventory)
        setUpdatedInventory([...copy, dummyInventoryPool])
    }

    const onRemovePool = (index: number) => {
        const copy = cloneDeep(updatedInventory)
        copy.splice(index, 1)
        setUpdatedInventory(copy)
        if (copy.length === 0) {
            setUsingPool(false)
        }
    }

    const getPoolOptionsList = (pool: InventoryPool) => {
        if (pool.poolId && !inventoryOptions.find((o) => o.value === pool.poolId)) {
            const poolDetails = props.inventoryPools.find((x) => x.id === pool.poolId)
            const poolName = poolDetails ? poolDetails.name : ''
            return [{ name: poolName, value: pool.poolId }, ...inventoryOptions]
        }
        return inventoryOptions
    }

    return (
        <>
            <Row>
                <Col span={5}>
                    <RadioWrapper>
                        <Radio
                            id="unlimitedInventory"
                            name="unlimitedOption"
                            value="none"
                            onChange={onSetUnlimitedInventory}
                            checked={!usingPool}
                        />
                        <RadioText>Unlimited sales</RadioText>
                    </RadioWrapper>
                    <RadioWrapper>
                        <Radio
                            id="usingInventoryPool"
                            name="poolOption"
                            value="none"
                            onChange={onUseInventoryPool}
                            checked={usingPool}
                        />
                        <RadioText>Use admission or capacity limits</RadioText>
                    </RadioWrapper>
                </Col>
                <Col span={7} />
            </Row>
            {usingPool && (
                <>
                    {updatedInventory.map((pool, i) => (
                        <InventorySelectRow
                            key={i}
                            errors={props.fieldErrors?.[i]}
                            inventoryOptions={getPoolOptionsList(pool)}
                            inventoryPool={pool}
                            onListChange={(id) => onListChange(i, id)}
                            onPoolChange={(id) => onPoolChange(i, id)}
                            onAmountChange={(amount) => onAmountChange(i, amount)}
                            onRemovePool={() => onRemovePool(i)}
                            missingId={props.missingId}
                        />
                    ))}
                    {inventoryOptions.length > 0 && (
                        <ActionButton kind="action" size="small" secondary onClick={onAddPool}>
                            Add inventory pool
                        </ActionButton>
                    )}
                </>
            )}
        </>
    )
}
