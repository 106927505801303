import React, { useContext, useMemo } from 'react'
import { ConfirmationDialog } from '../../uiComponents/popups/confirmationDialog'
import { ProductTableActionKind, productTableContext } from './provider'
import { isArticle } from './utils'
import { useArchiveArticleMutation, useArchiveCategoryMutation } from '../reduxApi'

interface Copy {
    title: string
    text: React.ReactNode | string
    onConfirm: () => void
}

const DeleteProduct = () => {
    const { state, dispatch } = useContext(productTableContext)
    const closeModal = () => dispatch({ type: ProductTableActionKind.SET_PRODUCT_FOR_DELETING, payload: undefined })
    const [archiveArticle] = useArchiveArticleMutation()
    const [archiveCategory] = useArchiveCategoryMutation()

    const name =
        state.selectProductToDelete?.name['en'] ||
        state?.selectProductToDelete?.name[Object.keys(state.selectProductToDelete.name)?.[0]]

    const modalProps = useMemo((): Copy => {
        if (isArticle(state.selectProductToDelete)) {
            return {
                title: 'Delete the product?',
                text: (
                    <span>
                        Are you sure you want to delete <b>{name}</b> product?
                    </span>
                ),
                onConfirm: () => {
                    archiveArticle({ uuid: state.selectProductToDelete?.id as string }).then(() => closeModal())
                },
            }
        }

        return {
            title: 'Delete the category?',
            text: (
                <span>
                    Are you sure you want to delete <b>{name}</b> category? All children categories and products will be
                    removed.
                </span>
            ),
            onConfirm: () => {
                archiveCategory({ uuid: state.selectProductToDelete?.id as string }).then(() => closeModal())
            },
        }
    }, [state.selectProductToDelete])

    if (!state.selectProductToDelete) {
        return null
    }

    return (
        <ConfirmationDialog
            destructive
            title={modalProps.title}
            text={modalProps.text}
            buttonText="Delete"
            onCancel={closeModal}
            onConfirm={modalProps.onConfirm}
        />
    )
}

export default DeleteProduct
