import React from 'react'
import { ModalDialog, ModalDialogTitle } from 'uiComponents/popups/modal'
import { ChartDataLoader } from 'uiComponents/loaders'
import { ActionButton } from 'uiComponents/buttons'
import { MessageKind } from 'uiComponents/messages'
import { usePrevious } from 'reactUtils'
import { FormItem, FormItemName } from 'uiComponents/form/formElements'
import { ValidationNotice } from 'products/components/validationNotice'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import Infotip from 'uiComponents/infotip'
import { Bold } from 'uiComponents/typography'
import { ProductsCard, NavigationSection, Container } from './common'
import { ArticleConfigurationServiceContext } from 'products/context'
import { delay, parseDate } from 'utils'
import { BulkValidityUpdateError } from 'products/articleConfigurationService'

interface DialogProps {
    onDismiss: () => void
    accountSlug: string
    affectedProducts: { id: string; name: string }[]
    replaceTopMessages?: (id: string, status: MessageKind, text: string) => void
    removeAllMessages?: () => void
    onSave: () => void
}

export function BulkEditValiditySettingsDialog(props: DialogProps) {
    const articleConfigurationService = React.useContext(ArticleConfigurationServiceContext)
    const [validFrom, setValidFrom] = React.useState<string>('')
    const [validTo, setValidTo] = React.useState<string>('')
    const [showFrom, setShowFrom] = React.useState<string>('')
    const [showTo, setShowTo] = React.useState<string>('')
    const [loading, setLoading] = React.useState<boolean>(false)
    const [endDateInvalid, setEndDateInvalid] = React.useState<boolean>(false)
    const [visibleEndDateInvalid, setVisibleEndDateInvalid] = React.useState<boolean>(false)
    const [validate, setValidate] = React.useState<boolean>(false)

    const onSave = async () => {
        setValidate(true)
        if (!validFrom && !validTo && !showFrom && !showTo && props.replaceTopMessages) {
            props.replaceTopMessages('warning', 'warn', 'Please fill at least one field.')
            return
        }
        if (endDateInvalid || visibleEndDateInvalid) {
            return
        }
        setLoading(true)
        const responseData = await articleConfigurationService.bulkUpdateValidity(
            props.accountSlug,
            props.affectedProducts.map((p) => p.id),
            validFrom,
            validTo,
            showFrom,
            showTo,
        )
        responseData
            .ifFailure((err: BulkValidityUpdateError) => {
                if (props.replaceTopMessages) {
                    setLoading(false)
                    let message = 'There was an error saving the validity settings.'

                    const productNames = props.affectedProducts
                        .filter((ap) => err.ids.indexOf(ap.id))
                        .map((ap) => ap.name)
                    switch (err.type) {
                        case 'articles_valid_from_after_valid_to':
                            message = message + ' Validity start date must be before the end date.'
                            break
                        case 'articles_valid_to_before_valid_from':
                            message = message + ' Validity end date must be after the start date.'
                            break
                        case 'articles_show_from_after_show_to':
                            message = message + ' Visibility start date must be before the end date.'
                            break
                        case 'articles_show_to_before_show_from':
                            message = message + ' Visibility end date must be after the start date.'
                            break
                        default:
                            message = message
                    }
                    message = message + ` Please check ${productNames.join(', ')} article(s).`
                    props.replaceTopMessages('server_error', 'error', message)
                }
            })
            .ifSuccess(async () => {
                props.onSave()
                props.onDismiss()
                if (props.replaceTopMessages && props.removeAllMessages) {
                    props.replaceTopMessages('success', 'success', 'Settings have been saved successfully.')
                    await delay(3000)
                    props.removeAllMessages()
                }
            })
    }

    const prevValidFrom = usePrevious(validFrom)
    const prevValidTo = usePrevious(validTo)
    React.useEffect(() => {
        function checkEndDateValidity() {
            if (validFrom && validTo && new Date(validTo) < new Date(validFrom)) {
                setEndDateInvalid(true)
            } else {
                setEndDateInvalid(false)
            }
        }
        if (prevValidFrom !== validFrom || prevValidTo !== validTo) {
            checkEndDateValidity()
        }
    }, [validFrom, validTo])

    React.useEffect(() => {
        function checkVisibleEndDateValidity() {
            if (showFrom && showTo && new Date(showTo) < new Date(showFrom)) {
                setVisibleEndDateInvalid(true)
            } else {
                setVisibleEndDateInvalid(false)
            }
        }
        checkVisibleEndDateValidity()
    }, [showFrom, showTo])

    React.useEffect(() => {
        setValidate(false)
        if (props.removeAllMessages) {
            props.removeAllMessages()
        }
    }, [validFrom, validTo, showFrom, showTo])

    React.useEffect(() => {
        if (!!validate && (endDateInvalid || visibleEndDateInvalid) && props.replaceTopMessages) {
            props.replaceTopMessages('validation_error', 'error', 'Please fix the validation errors below.')
        } else if (props.removeAllMessages) {
            props.removeAllMessages()
        }
    }, [validate])

    function onDateChanged(date: Date, type: 'validFrom' | 'validTo') {
        if (type === 'validFrom') {
            setValidFrom(date.toString())
        } else {
            setValidTo(date.toString())
        }
    }

    function onVisibleDateChanged(date: Date, type: 'showFrom' | 'showTo') {
        if (type === 'showFrom') {
            setShowFrom(date.toString())
        } else {
            setShowTo(date.toString())
        }
    }

    return (
        <ModalDialog onDismiss={props.onDismiss} interactive fromTop="10%" className={loading ? 'noscroll' : ''}>
            {loading && <ChartDataLoader />}
            <Container>
                <ModalDialogTitle>Overwrite validity settings</ModalDialogTitle>
                <FormItem htmlFor="validityStartDate">
                    <FormItemName>Validity start date</FormItemName>
                    <DatePicker
                        id="validityStartDate"
                        date={validFrom ? parseDate(validFrom) : null}
                        onChange={(value: Date) => onDateChanged(value, 'validFrom')}
                    />
                </FormItem>
                <FormItem htmlFor="validityEndDate">
                    <FormItemName>
                        Validity end date
                        <Infotip pointer="left">Date inclusive</Infotip>
                    </FormItemName>
                    <DatePicker
                        id="validityEndDate"
                        date={validTo ? parseDate(validTo) : null}
                        onChange={(value: Date) => onDateChanged(value, 'validTo')}
                        status={validate && endDateInvalid ? 'error' : 'normal'}
                        lowerBoundary={validFrom ? parseDate(validFrom) : null}
                    />
                </FormItem>
                <ValidationNotice className={validate && endDateInvalid ? 'validation-message-visible' : ''}>
                    The end date should be later than the start date.
                </ValidationNotice>
                <FormItem htmlFor="visibilityStartDate">
                    <FormItemName>Visibility start date</FormItemName>
                    <DatePicker
                        id="visibilityStartDate"
                        date={showFrom ? parseDate(showFrom) : null}
                        onChange={(value: Date) => onVisibleDateChanged(value, 'showFrom')}
                        positionAbove
                    />
                </FormItem>
                <FormItem htmlFor="visibilityEndDate">
                    <FormItemName>
                        Visibility end date
                        <Infotip pointer="left">Date inclusive</Infotip>
                    </FormItemName>
                    <DatePicker
                        id="visibilityEndDate"
                        date={showTo ? parseDate(showTo) : null}
                        onChange={(value: Date) => onVisibleDateChanged(value, 'showTo')}
                        status={validate && visibleEndDateInvalid ? 'error' : 'normal'}
                        lowerBoundary={showFrom ? parseDate(showFrom) : null}
                        positionAbove
                    />
                </FormItem>
                <ValidationNotice className={validate && visibleEndDateInvalid ? 'validation-message-visible' : ''}>
                    The end date should be later than the start date.
                </ValidationNotice>
                <ProductsCard>
                    <div style={{ marginBottom: '1em' }}>
                        Changes will affect below listed products&apos; validity settings:
                    </div>
                    {props.affectedProducts.map((p, i) => (
                        <div key={i} style={{ marginBottom: '.3em' }}>
                            <Bold>{p.name}</Bold>
                        </div>
                    ))}
                </ProductsCard>
                <NavigationSection>
                    <ActionButton size="large" secondary onClick={props.onDismiss}>
                        Cancel
                    </ActionButton>
                    <ActionButton
                        id="save-settings"
                        size="large"
                        kind="destructive"
                        style={{ marginLeft: '2em' }}
                        onClick={onSave}
                    >
                        Save
                    </ActionButton>
                </NavigationSection>
            </Container>
        </ModalDialog>
    )
}
