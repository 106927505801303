import * as React from 'react'
import { ImagesService } from './imagesService'
import { LoggingService } from './loggingService'
import { StatsService } from './statsService'
import { LoginService } from './loginService'

export const ImagesServiceContext = React.createContext(null as any as ImagesService)
export const LoggingServiceContext = React.createContext(null as any as LoggingService)
export const StatsServiceContext = React.createContext(null as any as StatsService)
export const LoginServiceContext = React.createContext(null as any as LoginService)
