import * as Yup from 'yup'

type ValidationSchema = {
    productName: Yup.StringSchema
    regularPrice: Yup.NumberSchema
    minPrice: Yup.NumberSchema
    maxPrice: Yup.NumberSchema
    rounding: Yup.StringSchema
    gatePrice?: Yup.NumberSchema
    priceVariation: Yup.StringSchema
}

export default Yup.object<ValidationSchema>().shape({
    dynamicPricing: Yup.boolean(),
    productName: Yup.string().required(),
    regularPrice: Yup.number()
        .typeError('Must be a valid number')
        .required()
        .min(0)
        .max(9999)
        .test('regularPrice', 'Regular price must be lower or equal to Gate price', function (value: number) {
            const { gatePrice } = this.parent
            return !gatePrice || value <= gatePrice
        }),
    minPrice: Yup.number().when('dynamicPricing', {
        is: true,
        then: Yup.number()
            .typeError('Must be a valid number')
            .required()
            .min(0)
            .max(9999)
            .max(Yup.ref('regularPrice'), 'Min price must be lower than regular price')
            .max(Yup.ref('maxPrice'), 'Min price must be lower than max price')
            .test('minPrice', 'Min price must be lower or equal to regular price', function (value: number) {
                const { regularPrice } = this.parent
                return regularPrice >= value
            })
            .test('minPrice', 'Min price must be lower or equal to Gate price', function (value: number) {
                const { gatePrice } = this.parent
                return !gatePrice || value <= gatePrice
            }),
    }),
    maxPrice: Yup.number().when('dynamicPricing', {
        is: true,
        then: Yup.number()
            .typeError('Must be a valid number')
            .required()
            .min(0)
            .max(9999)
            .min(Yup.ref('minPrice'), 'Max price must be higher than min price')
            .test('maxPrice', 'Max price must be higher or equal to regular price', function (maxPrice: number) {
                const { regularPrice } = this.parent
                return regularPrice <= maxPrice
            })
            .test('maxPrice', 'Max price must be lower or equal to Gate price', function (maxPrice: number) {
                const { gatePrice } = this.parent
                return !gatePrice || maxPrice <= gatePrice
            })
            .test('maxPrice', 'Max price must be higher than min price', function (maxPrice: number) {
                const { minPrice, dynamicPricing } = this.parent
                return !dynamicPricing || minPrice < maxPrice
            }),
    }),
    priceVariation: Yup.string().when('dynamicPricing', {
        is: true,
        then: Yup.string().required(),
    }),
    rounding: Yup.string(),
    gatePrice: Yup.number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .min(0)
        .max(9999),
})
