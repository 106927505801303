import * as React from 'react'
import ReactEcharts from 'echarts-for-react'
import { withTheme } from 'styled-typed'
import { EChartsOption } from 'echarts'
import { merge } from 'lodash'

import { DashboardTheme } from 'theme'
import { NoDataNotice, tooltipStyle } from 'uiComponents/charts/styleComponents'

export interface Series {
    name: string
    type: string
    data: string[]
}

export interface TooltipParams {
    axisValue: string
    dataIndex: number
    marker: string
    seriesName: string
    seriesType: string
    seriesIndex: number
    value: string
    color: string
    data?: any
    dataType?: string
}

export interface ToolTipAxisPointerOptions {
    type?: 'line' | 'shadow' | 'none' | 'cross'
    lineStyle?: {
        type?: 'solid' | 'dashed' | 'dotted'
    }
    z?: number
}

interface BarChartProps {
    series: Series[]
    axisData: string[]
    legendData?: string[]
    legendWidth?: number
    legendLeft?: number
    yAxisConfig?: Partial<EChartsOption['yAxis']>
    chartOptions?: Partial<EChartsOption>
    title?: string
    theme: DashboardTheme
    loading: boolean
    customColors?: string[]
    legendDisabled?: boolean
    height?: string
    titleOffset?: number
    gridRightOffset?: number
    gridLeftOffset?: number
    lineSeriesOnly?: boolean
    yAxisMin?: number
    axisPointerType?: 'none' | 'axis' | 'item'
    toolTipAxisPointer?: ToolTipAxisPointerOptions
    tooltipFormatter?: (params: TooltipParams[]) => string
    onMouseOver?: (params: any) => void
    onMouseOut?: (params: any) => void
}

class TimeseriesBarChart extends React.Component<BarChartProps> {
    echarts: any

    static defaultProps = {
        toolTipAxisPointer: {
            type: 'none',
        },
    }

    options = () => {
        const titleOffset = this.props.titleOffset || 0
        const eChartsOptions = {
            textStyle: {
                fontFamily: 'Roboto',
            },
            grid: {
                left: this.props.gridLeftOffset || 55,
                right: this.props.gridRightOffset || 20,
                bottom: 35,
                top: this.props.legendData ? 80 - titleOffset : 60 - titleOffset,
            },
            title: {
                text: this.props.title || '',
                textStyle: {
                    fontSize: 20,
                    fontWeight: 'normal',
                },
                padding: [10, 0, 0, 0] as any,
            },
            tooltip: {
                formatter: this.props.tooltipFormatter ? this.props.tooltipFormatter : undefined,
                trigger: 'axis',
                axisPointer: this.props.toolTipAxisPointer,
                confine: true,
                padding: [5, 10],
                ...tooltipStyle,
            },
            legend: {
                show: !!this.props.legendData,
                data: this.props.legendData,
                width: this.props.legendWidth ? this.props.legendWidth : null,
                top: this.props.title ? 40 : 20,
                left: this.props.legendLeft || 25,
                padding: 3,
                itemGap: 20,
                type: 'scroll',
                selectedMode: false,
            },
            xAxis: {
                boundaryGap: this.props.lineSeriesOnly ? false : 'auto',
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: {
                    color: '#A4AFB2',
                    lineHeight: 15,
                },
                data: this.props.axisData,
            },
            yAxis: this.props.yAxisConfig
                ? this.props.yAxisConfig
                : {
                      min: this.props.yAxisMin,
                      axisLine: { show: false },
                      axisTick: { show: false },
                      axisLabel: {
                          color: '#A4AFB2',
                      },
                      splitLine: {
                          lineStyle: {
                              color: 'rgba(164, 175, 178, 0.3)',
                          },
                      },
                  },
            series: this.props.series,
            color: this.props.customColors || this.props.theme.colors.chartRotatingColors,
        } as EChartsOption

        return merge(eChartsOptions, this.props.chartOptions)
    }

    onEvents = {
        mouseover: (e: any) => {
            if (this.props.onMouseOver) {
                this.props.onMouseOver(e)
                this.echarts.getEchartsInstance().setOption(this.options())
            }
        },
        mouseout: (e: any) => {
            if (this.props.onMouseOut) {
                this.props.onMouseOut(e)
                this.echarts.getEchartsInstance().setOption(this.options())
            }
        },
    }

    getDataLength = () => {
        const withData = this.props.series.find((s) => s.data.length > 0)
        return withData ? 999 : 0
    }

    render() {
        const events = !!this.props.onMouseOver && !!this.props.onMouseOut
        return (
            <NoDataNotice
                dataLength={this.getDataLength()}
                loading={this.props.loading}
                topOffset={this.props.title ? undefined : '0'}
            >
                <ReactEcharts
                    ref={(x: any) => (this.echarts = x)}
                    style={{ height: this.props.height || '500px' }}
                    option={this.options()}
                    onEvents={events ? this.onEvents : {}}
                    notMerge={true}
                />
            </NoDataNotice>
        )
    }
}

export default withTheme(TimeseriesBarChart)
