import React from 'react'
import { PriceSetting, AdjustmentType, DynamicInterval, OverrideType } from 'products/pricing/pricingService'
import { Currency } from 'uiComponents/money/moneyHoc'
import { SecondaryText } from 'uiComponents/typography'
import { Row, Column, ContainerWithStatus } from 'uiComponents/pageElements'
import { ValidationNotice } from 'products/components/validationNotice'
import { Radio } from 'uiComponents/input/radio'
import Infotip from 'uiComponents/infotip'
import { SetAdjustmentFunc } from 'products/pricing/exceptions/exceptionForm'
import { AdjustRuleItem } from './ruleItem'
import { DynamicIntervals } from './dynamicIntervals'
import { Adjustment, RadioWrapper, HighlightedText } from './shared'

interface RulesForStaticProps {
    currency: Currency
    adjustment: Adjustment
    intervals: DynamicInterval[] | null
    setIntervals: (intervals: DynamicInterval[] | null) => void
    setAdjustment: SetAdjustmentFunc
    activatePriceSettingRule: (priceSetting: PriceSetting, activate: boolean) => void
    validate: boolean
    areIntervalsValid: boolean
    doIntervalsOverlap: boolean
    overrideType: OverrideType | null
    onOverrideTypeChange: (type: OverrideType) => void
}

export function RulesForStatic(props: RulesForStaticProps) {
    function changeStrategy(e: React.ChangeEvent<HTMLInputElement>) {
        const s = e.target.value as OverrideType
        props.onOverrideTypeChange(s)
    }

    function setAdjustment(priceSetting: PriceSetting, value: number, type: AdjustmentType) {
        props.setAdjustment(priceSetting, value, type)
        props.activatePriceSettingRule(priceSetting, true)
    }

    const { currency, adjustment, validate, intervals, areIntervalsValid, overrideType, doIntervalsOverlap } = props
    const ruleNotValid = !intervals && !adjustment.on && validate
    const intervalsWithNull = intervals && intervals.find((i) => i.endDay === null || i.startDay === null)
    const intervalsNotValid = validate && intervals && (!areIntervalsValid || intervalsWithNull)
    return (
        <>
            {ruleNotValid && <ValidationNotice large>Select a rule</ValidationNotice>}
            <ContainerWithStatus
                status={ruleNotValid || intervalsNotValid || doIntervalsOverlap ? 'error' : 'success'}
                style={{ flexDirection: 'column' }}
            >
                <Row style={{ marginBottom: '2em' }}>
                    <RadioWrapper style={{ marginLeft: '.5em' }}>
                        <Radio
                            id="adjustStrategy"
                            name="strategy"
                            value="adjust"
                            onChange={changeStrategy}
                            defaultChecked={overrideType === 'adjust'}
                        />
                        <HighlightedText>Static price strategy</HighlightedText>
                        <Infotip pointer="left" maxWidth="18em">
                            Your price will be always the same, as defined by you.
                        </Infotip>
                    </RadioWrapper>
                    <SecondaryText style={{ margin: '0 2em' }}>or</SecondaryText>
                    <RadioWrapper>
                        <Radio
                            id="dynamicStrategy"
                            name="strategy"
                            value="dynamic"
                            onChange={changeStrategy}
                            defaultChecked={overrideType === 'dynamic'}
                        />
                        <HighlightedText>Dynamic price strategy</HighlightedText>
                        <Infotip pointer="left" maxWidth="18em">
                            Your price will change according to your rules. This is a rule-based approach.
                        </Infotip>
                    </RadioWrapper>
                </Row>
                {overrideType === 'adjust' && (
                    <>
                        <Row>
                            <Column>
                                <div style={{ marginLeft: '0.5em' }}>Rule for Price</div>
                            </Column>
                        </Row>
                        <Row>
                            <AdjustRuleItem
                                isOn={true}
                                priceSetting={adjustment.name}
                                adjustmentType={adjustment.type}
                                adjustmentSize={adjustment.value}
                                currency={currency}
                                handleRuleChange={setAdjustment}
                                validate={validate}
                            />
                        </Row>
                    </>
                )}
                {overrideType === 'dynamic' && (
                    <>
                        <Row>
                            <Column>
                                <div style={{ marginLeft: '0.5em' }}>Intervals</div>
                            </Column>
                        </Row>
                        <Row>
                            <DynamicIntervals
                                intervals={intervals}
                                currency={currency}
                                setIntervals={props.setIntervals}
                                validate={validate}
                            />
                        </Row>
                    </>
                )}
            </ContainerWithStatus>
            {ruleNotValid && (
                <ValidationNotice className="validation-message-visible" style={{ marginTop: '1em' }}>
                    Please set an interval
                </ValidationNotice>
            )}
            {doIntervalsOverlap && (
                <ValidationNotice className="validation-message-visible" style={{ marginTop: '1em' }}>
                    One or more intervals overlap
                </ValidationNotice>
            )}
            {intervalsNotValid && (
                <ValidationNotice className="validation-message-visible">Intervals are not complete</ValidationNotice>
            )}
        </>
    )
}
