import * as React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { State } from 'store'

interface PageContentProps {
    children?: React.ReactNode | undefined
    selfSignUp: string | null
}
export const RequiresCompleteSignup = (props: PageContentProps) => {
    const { selfSignUp, children } = props
    const isIncomplete = selfSignUp && selfSignUp === 'incomplete'
    return <Route>{isIncomplete ? <Redirect to="/sign_up/landing" /> : <Switch>{children}</Switch>}</Route>
}
const mapStateToProps = (state: State) => {
    return {
        selfSignUp: state.profile.profile ? state.profile.profile.signUp : null,
    }
}
export default connect(mapStateToProps)(RequiresCompleteSignup)
