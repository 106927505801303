import React, { useState, useEffect, useRef } from 'react'
import { MessageKind } from 'uiComponents/messages'
import { BookingCodesService, UploadDetails } from 'venue/bookingCodes/bookingCodesService'
import UploadPage from 'venue/bookingCodes/uploadPage'
import { LoginService } from 'http/loginService'

interface UploadSeasonPassesPageProps {
    accountSlug: string
    bookingCodesService: BookingCodesService
    backofficeEndpoint: string
    loginService: LoginService
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

export function UploadSeasonPassesPage(props: UploadSeasonPassesPageProps) {
    const busyRef = useRef(false)
    const accountRef = useRef(props.accountSlug)
    const [loading, setLoading] = useState<boolean>(false)
    const [uploadsHistoryList, setUploadsHistoryList] = useState<UploadDetails[]>([])

    async function getUploadHistoryList() {
        setLoading(true)
        busyRef.current = true
        try {
            const data = await props.bookingCodesService.getSeasonPassesUploadHistoryList(props.accountSlug)
            setUploadsHistoryList(data)
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            setLoading(false)
            busyRef.current = false
        }
    }

    async function refreshHistoryList() {
        try {
            const data = await props.bookingCodesService.getSeasonPassesUploadHistoryList(accountRef.current)
            setUploadsHistoryList(data)
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please reload the page.')
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (!busyRef.current) {
                refreshHistoryList()
            }
        }, 30000)
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        accountRef.current = props.accountSlug
        getUploadHistoryList()
    }, [props.accountSlug])

    const onUpload = async (file: File) => {
        busyRef.current = true
        return await props.bookingCodesService.uploadSeasonPasses(file, props.accountSlug)
    }

    const reloadUploadsHistoryList = async () => {
        await getUploadHistoryList()
    }

    const getCsvDownloadHref = () => {
        const csvRows = [
            'code,format,valid_from,valid_to,product_name,first_name,last_name,date_of_birth,photo_url',
            '12345678abcd,qr_code,2021-01-01,2021-12-31,Test product,John,Smith,1984-02-03,https://example.com/test.jpg',
            '98765432abcd,qr_code,2021-01-01,2021-12-31,Test product,,,,',
        ]
        const csv = csvRows.join('\n')
        return 'data:text/csv;charset=utf-8,' + encodeURI(csv)
    }

    return (
        <UploadPage
            accountSlug={props.accountSlug}
            uploadsHistoryList={uploadsHistoryList}
            bookingCodesService={props.bookingCodesService}
            loginService={props.loginService}
            backofficeEndpoint={props.backofficeEndpoint}
            uploadType="sold_tickets"
            exampleCSVHref={getCsvDownloadHref()}
            exampleFileName="season_passes_upload_example"
            loading={loading}
            onUpload={onUpload}
            reloadUploadsHistoryList={reloadUploadsHistoryList}
            onUploadError={() => (busyRef.current = false)}
            replaceMessages={props.replaceMessages}
            removeAllMessages={props.removeAllMessages}
        />
    )
}
