import React from 'react'
import classNames from 'classnames'

import { ContainerHeading, HeadingSectionName } from 'uiComponents/settingsContainer'
import { useBookingNavigation } from './hooks'

const CodesTabsNavigation = () => {
    const sections = useBookingNavigation()

    return (
        <ContainerHeading style={{ paddingLeft: '1em' }}>
            {sections.map((section) => {
                return (
                    <HeadingSectionName
                        key={section.path}
                        className={classNames({ active: section.active })}
                        to={section.path}
                    >
                        {section.tabTitle}
                    </HeadingSectionName>
                )
            })}
        </ContainerHeading>
    )
}

export default CodesTabsNavigation
