import * as React from 'react'
import { FilterToggleContainer } from './filterComponents'
import { ActionButton } from 'uiComponents/buttons'
import { FilterOption } from './schema'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { OneLevelMenu, OptionsList } from 'uiComponents/menus/'
import { FilterOptionsItem, CheckBoxesData } from 'uiComponents/filter/filterOptionsItem'
import { ActionsSection, ActionText } from 'uiComponents/filter/filterComponents'

interface FilterProps {
    navigation: Navigation
    match: RouteMatch<any>
    filterOptions: FilterOption[]
    checkBoxesData: CheckBoxesData
    appliedFilters?: FilterOption[]
    categorySlug: string
    categoryName: string
    singleSelectCategory?: boolean
    singleCustomFilter?: boolean
    onOutsideClick: () => void
    onOptionSelect: (option: FilterOption) => void
    onApply: () => void
    onToggleAllCheckboxes?: () => void
    open?: boolean
    selectedCount?: number
}

function StandaloneFilterCategory(props: FilterProps) {
    const [closeMenu, setCloseMenu] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (closeMenu) {
            setCloseMenu(false)
        }
    }, [closeMenu])

    const onApply = () => {
        setCloseMenu(true)
        props.onApply()
    }

    const onSelect = (option: FilterOption) => {
        if (props.singleSelectCategory) {
            setCloseMenu(true)
        }
        props.onOptionSelect(option)
    }

    const onToggleAllOptions = () => {
        if (props.singleSelectCategory) {
            setCloseMenu(true)
        }
        if (props.onToggleAllCheckboxes) {
            props.onToggleAllCheckboxes()
        }
    }

    const customMenuName = () => props.singleSelectCategory && props.singleCustomFilter

    const getMenuName = () => {
        if (customMenuName() && props.appliedFilters?.length) {
            return props.appliedFilters[0].name
        }
        return props.categoryName
    }

    const getSelectedCount = () => {
        return customMenuName() ? 0 : props.selectedCount || props.appliedFilters?.length || 0
    }

    const getCancelButtonText = () => {
        if (props.singleSelectCategory) {
            return 'Clear selected'
        }
        const noneSelected = props.checkBoxesData.selected.filter((f) => f.category === props.categorySlug).length === 0
        if (noneSelected) {
            return 'Select all'
        }
        return 'Clear all'
    }

    return (
        <FilterToggleContainer style={{ marginLeft: props.open ? '0' : '1em' }} id="category-container">
            <OneLevelMenu
                disabledInfotipText=""
                closeMenu={closeMenu}
                menuName={getMenuName()}
                selectedCount={getSelectedCount()}
                onOutsideClick={props.onOutsideClick}
                open={props.open}
            >
                <OptionsList>
                    {props.filterOptions.map((f, i) => (
                        <FilterOptionsItem
                            key={f.name + i}
                            isCategoriesSelector
                            singleSelectCategory={props.singleSelectCategory}
                            option={f}
                            onSelect={onSelect}
                            checkBoxesData={props.checkBoxesData}
                        />
                    ))}
                </OptionsList>
                <ActionsSection>
                    {!!props.onToggleAllCheckboxes ? (
                        <ActionText onClick={onToggleAllOptions}>{getCancelButtonText()}</ActionText>
                    ) : (
                        <ActionButton onClick={props.onOutsideClick} size="small" kind="default" secondary>
                            Cancel
                        </ActionButton>
                    )}
                    {!props.singleSelectCategory && (
                        <ActionButton
                            onClick={onApply}
                            size="small"
                            kind="action"
                            style={{ marginLeft: '1.5em' }}
                            id="apply-button"
                        >
                            Apply
                        </ActionButton>
                    )}
                </ActionsSection>
            </OneLevelMenu>
        </FilterToggleContainer>
    )
}

export default withNavigation(StandaloneFilterCategory)
