export enum PNFrequency {
    once = 'ONCE',
    hourly = 'HOURLY',
    daily = 'DAILY',
    weekly = 'WEEKLY',
    monthly = 'MONTHLY',
}

export enum PNScheduleType {
    time = 'TIME',
    event = 'EVENT',
}

export interface PNApp {
    slug: string
    name: string
}

export interface PNSchedule {
    frequency: PNFrequency.once | PNFrequency.hourly | PNFrequency.daily | PNFrequency.weekly | PNFrequency.monthly
    start_time?: string
    end_time?: string
}

export interface PNCampaign {
    id?: string
    slug: string
    location_id: string
    name: string
    active: boolean
    description?: string
    content: PNLocalizedContent[]
    schedule: PNSchedule
    segment_id: string
    url: string
}

export interface PNTargetForm {
    name: string
    segment_id: string
    location_id: string
}

export interface PNLocalizedContent {
    locale: string
    title: string
    body: string
    default?: boolean
}

export interface PNContentForm {
    url: string
    content: PNLocalizedContent[]
}

export interface PNScheduleForm {
    type: PNScheduleType.time | PNScheduleType.event
    recurring: boolean
    startDate?: string
    startTime?: string
    endDate?: string
    endTime?: string
    frequency: PNFrequency
}
