export const STATUS = [
    {
        name: 'OPEN',
        value: 'OPEN',
    },
    {
        name: 'MAINTENANCE',
        value: 'MAINTENANCE',
    },
    {
        name: 'CLOSED',
        value: 'CLOSED',
    },
]
