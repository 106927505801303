import React, { useState, useEffect } from 'react'
import { History } from 'history'
import { usePrevious } from 'reactUtils'
import { withFeatures } from 'features'
import { withMessages, MessageProps } from 'hocs'
import { Messages } from 'uiComponents/messages'
import { MobileDeviceWarning } from 'uiComponents/studio/mobileDeviceWarning'
import StudioForm from 'studioAPF/form'
import { PreviewContainer } from 'uiComponents/studio/preview/components'
import Preview from './preview'
import Header from 'uiComponents/studio/header'
import { MenuStepType, ConfigField, FieldType, FieldCategory, RawTranslations } from './schema'
import { mapRawFields, mapTranslationsForPayload, mapEmptyTranslations } from './utils'
import cloneDeep from 'lodash/cloneDeep'
import { withCurrentUser, withNavigation } from 'hocs'
import { User } from 'auth/state'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { ArticleService, Locale } from 'admin/articleService'
import { ProductsMetaData, Article } from 'uiComponents/filter/schema'
import { Page, Side, Body } from 'uiComponents/studio/pageComponents'
import { useAppSelector } from 'store/hooks'
import { getIsUSPartner } from 'auth/selectors'

interface StudioProps {
    accountSlug: string
    history: History
    user: User
    navigation: Navigation
    match: RouteMatch<any>
    articleService: ArticleService
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function StudioAPF(props: StudioProps & MessageProps) {
    const isUSPartner = useAppSelector(getIsUSPartner)
    const [loading, setLoading] = useState<boolean>(false)
    const [currentStep, setCurrentStep] = useState<MenuStepType>('activate')
    const [fieldsConfig, setFieldsConfig] = useState<ConfigField[]>([])
    const [allTranslations, setAllTranslations] = useState<RawTranslations>({
        en: [],
    })
    const [accountLocales, setAccountLocales] = useState<Locale[]>([])
    const [activeLanguage, setActiveLanguage] = useState<string>('en')
    const [categories, setCategories] = useState<ProductsMetaData[]>([])
    const [products, setProducts] = useState<Article[]>([])
    const [markErrors, setMarkErrors] = useState<boolean>(false)
    const [category, setCategory] = useState<FieldCategory>('buyer')
    const [apfProducts, setApfProducts] = useState<Article[]>([])
    const [selectedProducts, setSelectedProducts] = useState<string[]>([])
    const [enabledArticles, setEnabledArticles] = useState<string[]>([])
    const [disabledArticles, setDisabledArticles] = useState<string[]>([])

    useEffect(() => {
        const query = props.navigation.query()
        setCategory((query.section as FieldCategory) || 'buyer')
        getData()
    }, [])

    const prevStep = usePrevious(props.match.params.step)
    useEffect(() => {
        const newStep = props.match.params.step
        if (newStep && newStep !== currentStep && prevStep !== newStep) {
            onStepChange(newStep)
        }
    }, [props.match.params.step])

    useEffect(() => {
        updateArticles()
    }, [selectedProducts])

    const updateArticles = () => {
        const unselected = apfProducts
            .filter((pr) => !selectedProducts.find((selected) => selected === pr.id))
            .map((pr) => pr.id)
        setEnabledArticles(selectedProducts)
        setDisabledArticles(unselected)
    }

    useEffect(() => {
        if (products.length && fieldsConfig.length) {
            setSelectedApfProducts()
        }
    }, [products, fieldsConfig])

    const setSelectedApfProducts = () => {
        let assignedProducts = products
        if (!fieldsConfig.find((config) => !config.products.length)) {
            let ids: string[] = []
            fieldsConfig.map((config: ConfigField) => {
                ids = ids.concat(config.products)
            })
            let uniqueAssignedProductIds = Array.from(new Set(ids))
            assignedProducts = products.filter((prod) => !!uniqueAssignedProductIds.find((id) => id === prod.id))
        }
        setApfProducts(assignedProducts)
        const enabledForApf = assignedProducts.filter((pr) => !!pr.apfEnabled).map((pr) => pr.id)
        setSelectedProducts(enabledForApf)
    }

    async function getData() {
        try {
            setLoading(true)
            const data = await props.articleService.getProductListsAndLocales(props.accountSlug)
            const articlesList = await props.articleService.getMinimalSortedArticlesList(props.accountSlug)

            setCategories(data.productsLists)
            setProducts(articlesList)

            setAccountLocales(data.locales)
            const result = await props.articleService.getAPFConfig(props.accountSlug)
            const mappedFields = mapRawFields(result.fieldsConfig, data.locales, allTranslations, isUSPartner)
            setFieldsConfig(mappedFields)
            setAllTranslations(result.allTranslations)
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            setLoading(false)
        }
    }

    const checkTranslationsValid = (activeFields: ConfigField[]) => {
        return activeFields.every((field) => {
            const languagesToCheck = Object.keys(field.translations)
            return languagesToCheck.every((lang) => {
                if (!field.translations[lang].label) {
                    setActiveLanguage(lang)
                    setCategory(field.buyer ? 'buyer' : 'ticket_holder')
                    setMarkErrors(true)
                    onStepChange('translations')
                    props.replaceMessages(
                        'validation_error',
                        'error',
                        'Please check all the active field label translations are present.',
                        null,
                        true,
                    )
                    return false
                }
                return true
            })
        })
    }

    const onSave = async () => {
        const activeFields = fieldsConfig.filter((f) => f.active)
        if (!checkTranslationsValid(activeFields)) {
            return
        }
        try {
            setLoading(true)
            const updatedFields = activeFields.map((f) => ({
                name: f.slug,
                type: f.type,
                repeatable: !f.buyer,
                required: !f.optional,
                articles: f.products.length === 0 ? null : Array.from(new Set(f.products)),
                label: mapTranslationsForPayload(f.translations, 'label'),
                placeholder: mapTranslationsForPayload(f.translations, 'placeholder'),
            }))
            await props.articleService.updateAPFConfig(
                props.accountSlug,
                updatedFields,
                enabledArticles,
                disabledArticles,
            )
            props.history.push(`/account/${props.accountSlug}/products`)
        } catch {
            setLoading(false)
            props.replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    const onCategoryChange = (section: FieldCategory) => {
        setCategory(section)
        props.navigation.addQueryWithReplace({ section })
    }

    const onStepChange = (step: MenuStepType) => {
        setCurrentStep(step)
        props.history.push(`/account/${props.accountSlug}/products/apf_studio/${step}${location.search}`)
    }

    const onLanguageChange = (language: string) => {
        setActiveLanguage(language)
    }

    const handleEnabledChange = (
        value: boolean,
        item: string,
        type: 'active' | 'optional',
        fieldCategory: FieldCategory,
    ) => {
        const config = cloneDeep(fieldsConfig)
        const buyer = fieldCategory === 'buyer'
        const field = config.find((f) => f.slug === item && f.buyer === buyer)
        if (!field) {
            return
        }
        config[config.indexOf(field)][type] = value
        if (type === 'active' && !!value) {
            field.translations = mapEmptyTranslations(accountLocales, field.slug, allTranslations, field.name)
        }
        setFieldsConfig(config)
    }

    const onAddField = (type: FieldType, label: string, fieldCategory: FieldCategory) => {
        const config = cloneDeep(fieldsConfig)
        const slug = `${fieldCategory}_${label.toLowerCase().replace(/ /g, '_').replace(/[\W]+/g, '')}`
        config.push({
            name: label,
            slug: slug,
            type: type,
            buyer: fieldCategory === 'buyer',
            custom: true,
            active: true,
            optional: false,
            translations: mapEmptyTranslations(accountLocales, slug, allTranslations, label),
            products: [],
        })
        setFieldsConfig(config)
    }

    const handleTranslationChange = (
        value: string,
        fieldSlug: string,
        type: 'label' | 'placeholder',
        fieldCategory: FieldCategory,
    ) => {
        props.removeAllMessages()
        const config = cloneDeep(fieldsConfig)
        const buyer = fieldCategory === 'buyer'
        const field = config.find((f) => f.slug === fieldSlug && f.buyer === buyer)
        if (!field) {
            return
        }
        config[config.indexOf(field)].translations[activeLanguage][type] = value
        setFieldsConfig(config)
    }

    const handleAssignChange = (value: string[], fieldSlug: string, fieldCategory: FieldCategory) => {
        const config = cloneDeep(fieldsConfig)
        const buyer = fieldCategory === 'buyer'
        const field = config.find((f) => f.slug === fieldSlug && f.buyer === buyer)
        if (!field) {
            return
        }
        config[config.indexOf(field)].products = Array.from(new Set(value))
        setFieldsConfig(config)
    }

    return (
        <>
            <Page id="wizard-page" style={{ position: 'relative', justifyContent: 'center' }}>
                <Messages messages={props.messages} hideMessage={props.hideMessage} />
                <MobileDeviceWarning history={props.history} returnPath={`/account/${props.accountSlug}/products`} />
                <Side>
                    <StudioForm
                        history={props.history}
                        accountSlug={props.accountSlug}
                        fieldsConfig={fieldsConfig}
                        locales={accountLocales}
                        categories={categories}
                        products={products}
                        apfProducts={apfProducts}
                        handleEnabledChange={handleEnabledChange}
                        handleAssignChange={handleAssignChange}
                        handleTranslationChange={handleTranslationChange}
                        onLanguageChange={onLanguageChange}
                        onAddField={onAddField}
                        activeLanguage={activeLanguage}
                        currentStep={currentStep}
                        setCurrentStep={onStepChange}
                        loading={loading}
                        onSave={onSave}
                        markErrors={markErrors}
                        category={category}
                        onCategoryChange={onCategoryChange}
                        replaceMessages={props.replaceMessages}
                        removeAllMessages={props.removeAllMessages}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                    />
                </Side>
                <Body>
                    <Header
                        accountSlug={props.accountSlug}
                        previewSize="desktop"
                        targetDevice="desktop"
                        studioType="apf"
                        onPublish={onSave}
                        forceClosePath={`/account/${props.accountSlug}/products`}
                        replaceMessages={props.replaceMessages}
                        removeAllMessages={props.removeAllMessages}
                    />
                    <PreviewContainer className="desktop" style={{ minWidth: '27em' }}>
                        <Preview
                            accountSlug={props.accountSlug}
                            accounts={props.user.accounts}
                            activeLanguage={activeLanguage}
                            fieldsConfig={fieldsConfig.filter((f) => f.active)}
                            replaceMessages={props.replaceMessages}
                        />
                    </PreviewContainer>
                </Body>
            </Page>
        </>
    )
}

export default withFeatures(withNavigation(withMessages(withCurrentUser(StudioAPF))))
