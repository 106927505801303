import React, { useRef } from 'react'
import styledComponents, { css } from 'styled-components'
import { RadioInput } from './radio'

type Size = 'sm' | 'md' | 'lg'

const smallCss = css`
    font-weight: 500;
    padding: 0.25rem;
    font-size: 0.75rem;
    line-height: 1rem;
`

const mediumCss = css`
    font-weight: 500;
    padding: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
`
const largeCss = css`
    font-weight: 500;
    padding: 0.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
`

const sizes = {
    sm: smallCss,
    md: mediumCss,
    lg: largeCss,
}

const Container = styledComponents.div`
    cursor: pointer;
`

interface ChildContainerProps {
    size: Size
}

const ChildContainer = styledComponents.div<ChildContainerProps>`
    padding: .5rem;
    border: 1px solid ${(props) => props.theme.colors.aluminiumShades[20]};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    
    input:checked + & {
        background-color: ${(props) => props.theme.colors.boyBlueShades[10]};
        border-color: ${(props) => props.theme.colors.boyBlue};
        color: ${(props) => props.theme.colors.boyBlue};
    }
    
    &:focus-visible, &:hover {
        outline: unset;
        border-color: ${(props) => props.theme.colors.boyBlueShades[60]} !important;
    }

    &:active {
        border-color: ${(props) => props.theme.colors.boyBlueShades[60]};
    }
    
    input:checked + &:hover {
        outline: unset;
        background-color: ${(props) => props.theme.colors.boyBlueShades[20]};
    }

    input:disabled + & {
        color: ${(props) => props.theme.colors.border};
        border-color: ${(props) => props.theme.colors.border} !important;
        pointer: none;
    }

    input:checked:disabled + & {
        background-color: ${(props) => props.theme.colors.border};
        color: ${(props) => props.theme.colors.white};
        border-color: ${(props) => props.theme.colors.border};
    }

    ${(props) => sizes[props.size]} 
`

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
    label: string
    size: Size
}

const RadioButton: React.FC<Props> = ({ children, label, size = 'md', style, ...props }) => {
    const inputRef = useRef<HTMLInputElement>()

    return (
        <Container
            ref={(ref) => {
                ref?.childNodes.forEach((el: HTMLInputElement) => {
                    if (el.type === 'radio') {
                        el.tabIndex = -1
                        inputRef.current = el
                    }
                })
            }}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    inputRef.current?.click()
                }
            }}
            className="radio__button"
            onClick={() => {
                inputRef.current?.click()
            }}
        >
            <RadioInput {...props} />
            <ChildContainer tabIndex={0} size={size} style={style} className="radio__button__child">
                {children}
            </ChildContainer>
        </Container>
    )
}

export default RadioButton
