import React, { useState } from 'react'
import { Col } from 'uiComponents/flex'
import Infotip from 'uiComponents/infotip'
import { ToggleComponent } from 'uiComponents/studio/form/fieldComponents'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { ConfigurationUnit } from 'uiComponents/studio/menuItem'
import { NumberFieldElement } from 'engageTools/studio/form/numberFieldElement'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { TriggerButtonConfiguration } from 'engageTools/studio/schema'

export interface Language {
    value: string
    name: string
}

interface FrequencyLimitSectionProps {
    section: ConfigurationUnit
    configuration: TriggerButtonConfiguration
    updateConfiguration: (path: string, value: string | number | boolean | string[]) => void
}

export function FrequencyLimitSection(props: FrequencyLimitSectionProps) {
    const [frequency, setFrequency] = useState<number>(0)
    const [unlimited, setUnlimited] = useState<boolean>(true)
    const { section } = props
    type ToggleType = 'toggle'
    type NumberType = 'number'

    const displayFrequencyUnlimitedField = {
        name: 'display-frequency-unlimited',
        type: 'toggle' as ToggleType,
        label: 'Unlimited',
        span: 6,
        path: 'maxTimesShownPerPeriod',
        defaultValue: false,
        infotip: 'How many times per day at most your visitor will see the Trigger Button',
    }
    const displayFrequencyField = {
        name: 'display-frequency',
        type: 'number' as NumberType,
        min: 0,
        max: 120,
        label: 'Frequency per day',
        span: 6,
        unit: '',
        path: 'maxTimesShownPerPeriod',
        defaultValue: 5,
        infotip: 'How many times per day at most your visitor will see the Trigger Button',
    }

    function handleUnlimitedFrequencyToggle(v: boolean) {
        if (!v) {
            props.updateConfiguration(displayFrequencyField.path, frequency)
            setUnlimited(false)
            setFrequency(5)
        } else {
            props.updateConfiguration(displayFrequencyField.path, 0)
            setUnlimited(true)
            setFrequency(0)
        }
    }
    function handleFrequencyChange(v: number) {
        setFrequency(v)
        props.updateConfiguration(displayFrequencyField.path, v)
    }

    return (
        <SectionWrapper title={section.name}>
            <FormItem>
                <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor={displayFrequencyUnlimitedField.name}>
                        {displayFrequencyUnlimitedField.label}
                        <Infotip pointer="left" maxWidth="20em">
                            {displayFrequencyUnlimitedField.infotip}
                        </Infotip>
                    </label>
                </Col>
                <Col span={6}>
                    <ToggleComponent
                        field={displayFrequencyUnlimitedField}
                        handleChange={handleUnlimitedFrequencyToggle}
                        value={unlimited}
                    />
                </Col>
            </FormItem>
            {!unlimited && (
                <FormItem>
                    <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <label htmlFor={displayFrequencyField.name}>
                            {displayFrequencyField.label}
                            <Infotip pointer="left" maxWidth="20em">
                                {displayFrequencyField.infotip}
                            </Infotip>
                        </label>
                    </Col>
                    <Col span={6}>
                        <NumberFieldElement
                            field={displayFrequencyField}
                            handleChange={handleFrequencyChange}
                            value={frequency}
                        />
                    </Col>
                </FormItem>
            )}
        </SectionWrapper>
    )
}
