import { ColumnConfig } from '.'

export const ORDERS_TABLE_COLUMNS_CONFIG = [
    {
        section: 'Order details',
        columns: [
            { name: 'Location', key: 'location' },
            { name: 'Order ID', key: 'id' },
            { name: 'Email', key: 'email' },
            { name: 'Quantity', key: 'quantity' },
            { name: 'Subtotal', key: 'total' },
            { name: 'Discount', key: 'discountAmount' },
            { name: 'Code', key: 'discount_code' },
            { name: 'Total', key: 'totalAfterDiscount' },
            { name: 'Sale date', key: 'order_date' },
            { name: 'Payment date', key: 'payment_date' },
            { name: 'Order status', key: 'orderStatus' },
            { name: 'Payment status', key: 'paymentStatus' },
        ],
    },
    {
        section: 'Event information',
        columns: [
            { name: 'Visit date', key: 'visit_date' },
            { name: 'Email status', key: 'emailStatus' },
            { name: 'Event time', key: 'visit_time' },
        ],
    },
    {
        section: 'Transaction information',
        columns: [
            { name: 'Reseller', key: 'reseller' },
            { name: 'Downloaded on', key: 'downloaded' },
        ],
    },
]

export const ORDERS_TABLE_COLUMNS: ColumnConfig[] = ORDERS_TABLE_COLUMNS_CONFIG.flatMap((section, index) =>
    section.columns.map((column) => ({
        ...column,
        sectionKey: index,
        selected: true,
    })),
)
