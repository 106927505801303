import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Svg from 'svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faForward } from '@fortawesome/pro-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome'

import { useLazyCheckPaypalStatusQuery } from 'settings/reduxQuery'
import { State } from 'store'
import { PaypalIcon } from './paypalIcon'
import conviousLogo from 'images/convious-logo-pink.svg'
import { Headline } from 'uiComponents/typography'
import { Col, Row } from 'uiComponents/flex'
import ActionButton from 'uiComponents/buttons'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { useHistory } from 'react-router-dom'

const RedirectDefault = () => {
    return (
        <>
            <Row align="center">
                <Col span="auto">
                    <Headline size={4}>Redirecting...</Headline>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <PaypalIcon />
                    <span style={{ paddingLeft: '1em' }} />
                    <FontAwesomeIcon icon={faForward as IconProp} />
                    <span style={{ paddingLeft: '1em' }} />
                    <Svg src={conviousLogo} style={{ height: '1em' }} />
                </Col>
            </Row>
        </>
    )
}

const RedirectAfter10sec = () => {
    return (
        <>
            <Row align="center">
                <Col span="auto">
                    <Headline size={4}>Redirecting takes a bit longer...</Headline>
                </Col>
            </Row>
            <Row align="center">
                <Col span="auto">
                    <ActionButton secondary disabled>
                        Stay on this page
                    </ActionButton>
                </Col>
            </Row>
        </>
    )
}

const RedirectFallback = () => {
    const accountSlug = useSelector((state: State) => state.preferences.activeAccount)

    return (
        <>
            <Row align="center">
                <Col span="auto">
                    <Headline size={4}>Redirecting takes way too long...</Headline>
                </Col>
            </Row>
            <Row align="center">
                <Col span="auto">
                    <UnstyledLink to={`/account/${accountSlug}/settings/account_settings/integrations`}>
                        <ActionButton kind="action">Go back</ActionButton>
                    </UnstyledLink>
                </Col>
            </Row>
        </>
    )
}

type ScreenStates = 'default' | '10sec' | 'longWaiting'

const MollieAuthCallback = () => {
    const [checkPaypalStatus] = useLazyCheckPaypalStatusQuery()
    const accountSlug = useSelector((state: State) => state.preferences.activeAccount)
    const [renderScreen, setRenderScreen] = useState<ScreenStates>('default')
    const timeoutId = useRef<number>()
    const history = useHistory()

    const handleCheckPaypalStatus = () => {
        checkPaypalStatus({ accountSlug: accountSlug as string })
            .unwrap()
            .then((response) => {
                if (response?.isAuthorised) {
                    clearTimeout(timeoutId.current)

                    return history.push(`/account/${accountSlug}/settings/account_settings/integrations`)
                }

                if (response && 'isAuthorised' in response && !response?.isAuthorised) {
                    const id = setTimeout(() => {
                        handleCheckPaypalStatus()
                    }, 1000)

                    return (timeoutId.current = id as unknown as number)
                }

                clearTimeout(timeoutId.current)
            })
    }

    useEffect(() => {
        handleCheckPaypalStatus()

        return () => {
            clearTimeout(timeoutId.current)
        }
    }, [accountSlug])

    useEffect(() => {
        const firstTimeoutId = setTimeout(() => setRenderScreen('10sec'), 10e3)
        const secondTimeoutId = setTimeout(() => setRenderScreen('longWaiting'), 20e3)

        return () => {
            clearTimeout(firstTimeoutId)
            clearTimeout(secondTimeoutId)
        }
    }, [])

    const getRedirectWindow = () => {
        if (renderScreen === '10sec') {
            return <RedirectAfter10sec />
        }

        if (renderScreen === 'longWaiting') {
            return <RedirectFallback />
        }

        return <RedirectDefault />
    }

    return (
        <Row align="center" style={{ alignContent: 'center', height: '100vh', width: '100%' }}>
            <Col span="auto">{getRedirectWindow()}</Col>
        </Row>
    )
}

export default MollieAuthCallback
