import { DashboardTheme } from 'theme'
import { SliderRailProps } from './types'

interface ColorPriority {
    value: number
    priority: 'low' | 'high'
}

interface GetPercentageProps {
    value: number
    comperator: number
}

const getPercentage = ({ value, comperator }: GetPercentageProps) => {
    return (comperator * 100) / value
}

interface GetPriorityListProps {
    recommendedValue?: number
    min?: number
    max?: number
}

export const getPriorityList = ({ recommendedValue, min, max }: GetPriorityListProps) => {
    const priorities = [] as ColorPriority[]

    if (typeof min !== 'number' || typeof max !== 'number') {
        return priorities
    }

    if (min === recommendedValue) {
        priorities.push({
            value: getPercentage({ value: max, comperator: recommendedValue }),
            priority: 'high',
        })
    } else {
        priorities.push({
            value: 0,
            priority: 'low',
        })
    }

    if (max === recommendedValue) {
        priorities.push({
            value: getPercentage({ value: max, comperator: recommendedValue }),
            priority: 'high',
        })
    } else {
        priorities.push({
            value: getPercentage({ value: max, comperator: max }),
            priority: 'low',
        })
    }

    if (min !== recommendedValue && max !== recommendedValue && typeof recommendedValue == 'number') {
        priorities.push({
            value: getPercentage({ value: max + 2, comperator: recommendedValue }),
            priority: 'high',
        })
    }

    return priorities.sort((a, b) => a.value - b.value)
}

export const getRailBackground = (props: SliderRailProps & { theme: DashboardTheme }) => {
    const { recommendedValue, min, max, orientation, theme } = props

    if (recommendedValue) {
        const priorities = getPriorityList({ recommendedValue, min, max })

        const colors = priorities
            .map((priority) => {
                const color = priority.priority === 'low' ? theme.colors.boyBlueShades[10] : theme.colors.boyBlue
                return `${color} ${priority.value}%`
            })
            .join(', ')

        return `linear-gradient(${orientation === 'horizontal' ? '90deg' : '0deg'}, ${colors})`
    }

    return theme.colors.boyBlue
}
