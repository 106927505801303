import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { FormWrapper } from 'uiComponents/form/form'
import TextInputFieldFormik from 'uiComponents/input/textInput/textInputFieldFormik'
import { BaseRouteParams } from 'hocs'
import ActionButton, { ActionButtonA } from 'uiComponents/buttons'
import { useVenueLocations } from '../../../../tapPlacements/studio/helpers'
import SingleSelectFieldFormik from 'uiComponents/input/singleSelect/singleSelectFieldFormik'
import { useParkMapViewerContext } from '../../context/mapViewer'
import useOnPageLeave from '../../hooks/useOnPageLeave'
import useParkMap from '../../hooks/useParkMap'
import { Coord, Parkmap } from '../../models'
import InformationBox from '../informationBox'
import LocationAutocomplete from './autocomplete'
import * as yup from 'yup'
import ToggleMapEnabled from './toggleMapEnabled'

const Conatiner = styled.div`
    flex: 1;
    display: flex;
    & > form {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 1em;
        padding-top: 1em;
    }
`

export const Field = styled.div`
    margin-bottom: 2em;
`

export const FormContent = styled.div`
    position: relative;
    flex-grow: 1;
`

export const FormFooter = styled.div`
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: ${(props) => props.theme.colors.border};
    padding-top: 1em;
    display: flex;
    justify-content: space-between;
`

const Info = styled.div`
    margin: 0.5em 0 1.5em 0;
`

interface MapFormProps {
    data?: Parkmap | null
    onSubmit: (values: Parkmap) => void
}

const mapForm = ({ data, onSubmit }: MapFormProps) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const venueLocations = useVenueLocations(accountSlug)
    const { isRegionChanged, setIsRegionChanged, zoom, coords } = useParkMapViewerContext()
    const { getBounds } = useParkMap()

    const mapValidationSchema = yup.object().shape({
        name: yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Required'),
        locationId: venueLocations.length > 1 ? yup.string().required('Required') : yup.string(),
    })

    useOnPageLeave()

    return (
        <Conatiner>
            {data && (
                <FormWrapper
                    enableReinitialize
                    initialValues={data}
                    onSubmit={(values) => {
                        const bounds = getBounds()

                        onSubmit({
                            ...values,
                            region: {
                                zoom: zoom,
                                center: coords as Coord,
                                north: bounds?.getNorthEast().lat() || 0,
                                east: bounds?.getNorthEast().lng() || 0,
                                south: bounds?.getSouthWest().lat() || 0,
                                west: bounds?.getSouthWest().lng() || 0,
                            },
                        })
                    }}
                    validationSchema={mapValidationSchema}
                >
                    <FormContent>
                        <Info>
                            <InformationBox text="Here you can set the region of your park area. First search for your location in the “Location search” bar. When selected your can easily move and readjust areas position and scale by draging the map. Please notice that map should fit the given rectangle safe zone." />
                        </Info>
                        <Field>
                            <ToggleMapEnabled onChange={() => setIsRegionChanged(true)} />
                        </Field>
                        <Field>
                            <TextInputFieldFormik
                                id="name"
                                name="name"
                                label="Map name"
                                placeholder="Type the map name..."
                                onMouseDown={() => setIsRegionChanged(true)}
                            />
                        </Field>
                        <Field>
                            {venueLocations.length > 1 && (
                                <SingleSelectFieldFormik
                                    name="locationId"
                                    options={venueLocations}
                                    noSelectOption="Select venue location"
                                    label="Venue location"
                                />
                            )}
                        </Field>
                        <Field>
                            <LocationAutocomplete mapId={data.id} />
                        </Field>
                    </FormContent>
                    <FormFooter>
                        <ActionButtonA secondary size="large" href={`/account/${accountSlug}/venue/park_map/list`}>
                            Back to list
                        </ActionButtonA>
                        <ActionButton size="large" type="submit">
                            {isRegionChanged ? 'Save changes' : 'Save'}
                        </ActionButton>
                    </FormFooter>
                </FormWrapper>
            )}
        </Conatiner>
    )
}

export default mapForm
