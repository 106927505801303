import React, { useState, useEffect } from 'react'
import { History } from 'history'
import { useAccountChange } from 'reactUtils'
import { TableLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { InlineDataTable, HeaderRow, TableHeader, Cell, DataRow } from 'uiComponents/table'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { ActionButtonA } from 'uiComponents/buttons'
import { OpeningTimesServiceContext } from './context'
import { OpeningTimesListItem } from './openingTimesService'
import { convertTimeFormat } from 'utils/dates'

interface LocationsListPageProps {
    history: History
    accountSlug: string
    setActiveHeader: (headerText: string) => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hasPermission: (permission: string, accountSlug: string) => boolean
    hasFeature: (feature: string, accountSlug: string) => boolean
}

export function OpeningTimesListPage(props: LocationsListPageProps) {
    const openingTimesService = React.useContext(OpeningTimesServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [locations, setLocations] = useState<OpeningTimesListItem[] | null>(null)

    async function getLocationsList() {
        setLoading(true)
        try {
            const data = await openingTimesService.listLocations(props.accountSlug)
            setLocations(data)
            setLoading(false)
        } catch {
            props.replaceTopMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    useEffect(() => {
        getLocationsList()
        props.setActiveHeader('Opening Hours Configuration')
    }, [])

    useAccountChange(props.accountSlug, () => getLocationsList())

    const columnWidths = [null, '15em', '15em', '8em']

    return (
        <InlineDataTable columnWidths={columnWidths} id="locations-list">
            <HeaderRow>
                <TableHeader>Location</TableHeader>
                <TableHeader>Opening Hours</TableHeader>
                <TableHeader>Tickets Sold Until</TableHeader>
                <TableHeader nonInteractive />
            </HeaderRow>
            {loading && <TableLoader />}
            {!loading && !!locations && (
                <>
                    {locations.length === 0 ? (
                        <NoResultsRow text="There are no locations yet." />
                    ) : (
                        <>
                            {locations.map((l) => (
                                <DataRow key={l.id} className="locationsRow">
                                    <Cell className="name" title={l.locationName}>
                                        {l.locationName}
                                    </Cell>
                                    <Cell>
                                        {convertTimeFormat(l.openingTime)} - {convertTimeFormat(l.closingTime)}
                                    </Cell>
                                    <Cell>{l.sellUntilTime ? convertTimeFormat(l.sellUntilTime) : 'end of day'}</Cell>
                                    <Cell>
                                        <ActionButtonA
                                            className="edit-opening-times"
                                            kind="action"
                                            secondary
                                            href={`/account/${props.accountSlug}/venue/opening_times/default/${l.id}`}
                                        >
                                            Edit
                                        </ActionButtonA>
                                    </Cell>
                                </DataRow>
                            ))}
                        </>
                    )}
                </>
            )}
        </InlineDataTable>
    )
}
