import React from 'react'
import { Text } from 'uiComponents/typography'
import { DateFormats, formatISOString } from 'utils/dates'

interface RedeemedOnProps {
    value?: string
}

const RedeemedOn: React.FC<RedeemedOnProps> = ({ value }) => {
    if (!value) {
        return (
            <Text status="processing" title="Not redeemed">
                Not redeemed
            </Text>
        )
    }
    const formattedDate = formatISOString(value, `yyyy-MM-dd ${DateFormats.SHORT_TIME}`)

    return (
        <Text status="success" title={formattedDate}>
            {formattedDate}
        </Text>
    )
}

export default RedeemedOn
