import * as React from 'react'
import { ImagesService } from 'http/imagesService'
import { Col } from 'uiComponents/flex'
import Infotip from 'uiComponents/infotip'
import { MessageKind, RelativeMessage } from 'uiComponents/messages'
import { ConfigurationUnit } from 'uiComponents/studio/menuItem'
import { TriggerButtonConfiguration, GetConfigValueFunc, OnChangeFunc } from 'engageTools/studio/schema'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { FormFieldComponent } from 'uiComponents/studio/form/fieldComponents'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { FieldType, SelectField } from 'uiComponents/studio/form/fieldComponentSchema'
import { NumberFieldElement } from 'engageTools/studio/form/numberFieldElement'
import { UploadElement } from 'engageTools/studio/form/uploadElement'
import { ComponentsService } from 'engageTools/studio/componentsService'

interface GeneralSectionProps {
    getConfigValue: GetConfigValueFunc
    section: ConfigurationUnit
    configuration: TriggerButtonConfiguration
    handleInputChange: OnChangeFunc
    componentsService: ComponentsService
    imagesService: ImagesService
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

export function GeneralSection(props: GeneralSectionProps) {
    const { section, configuration, handleInputChange, imagesService, getConfigValue, componentsService } = props
    const renderField = (field: FieldType) => {
        return (
            <FormFieldComponent
                field={field}
                handleChange={handleInputChange}
                value={getConfigValue(field, configuration)}
            />
        )
    }

    if (section.fields) {
        return (
            <SectionWrapper key={section.name} title={section.name}>
                {section.fields.map((field, j) => {
                    const displayOn =
                        field.name === 'display'
                            ? (field as SelectField).availableOptions.find((ao) => ao.value === configuration.target)
                                  .name
                            : 'all'
                    let style = field.span && field.span < 12 ? { display: 'flex', alignItems: 'center' } : {}
                    const labelName = field.label === '' || field.type === 'checkbox' ? null : field.label
                    if (field.name === 'icon-color' && configuration.styling.iconButton.iconSource !== '') {
                        return null
                    } else if (configuration.trigger !== 'afterDelay' && field.name === 'show-after') {
                        return null
                    } else {
                        return (
                            <div key={j}>
                                <FormItem>
                                    {labelName && (
                                        <Col
                                            span={!!field.span && field.span !== 12 ? 12 - field.span : 12}
                                            style={style}
                                        >
                                            <label htmlFor={field.name}>
                                                {labelName}
                                                {field.infotip && (
                                                    <Infotip pointer="left" maxWidth="16em" smallBoxText>
                                                        {field.infotip}
                                                    </Infotip>
                                                )}
                                            </label>
                                        </Col>
                                    )}
                                    {field.name === 'display' ? (
                                        <div id="display-device">{displayOn}</div>
                                    ) : (
                                        <>
                                            {field.type === 'number' && (
                                                <NumberFieldElement
                                                    field={field}
                                                    style={style}
                                                    handleChange={handleInputChange}
                                                    value={getConfigValue(field, configuration)}
                                                />
                                            )}
                                            {field.type === 'upload' && (
                                                <UploadElement
                                                    key={field.name}
                                                    field={field}
                                                    style={style}
                                                    handleChange={handleInputChange}
                                                    value={getConfigValue(field, configuration)}
                                                    componentsService={componentsService}
                                                    imagesService={imagesService}
                                                    replaceTopMessages={props.replaceTopMessages}
                                                    removeAllMessages={props.removeAllMessages}
                                                    type="icon"
                                                    acceptSvg={true}
                                                />
                                            )}
                                            {field.type !== 'upload' && field.type !== 'number' && (
                                                <Col span={field.span} style={{ ...style }} className="justify-right">
                                                    {renderField(field)}
                                                </Col>
                                            )}
                                        </>
                                    )}
                                </FormItem>
                                {field.name === 'display-trigger' && configuration.trigger !== 'onLoad' && (
                                    <RelativeMessage
                                        kind="warn"
                                        showMessage={true}
                                        style={{
                                            margin: '-0.7em 0 1em 0',
                                            fontSize: '.7em',
                                            textAlign: 'left',
                                        }}
                                    >
                                        <div>
                                            This display rule might affect your revenue. We recommend using the
                                            &quot;After time spent&quot; option.
                                        </div>
                                    </RelativeMessage>
                                )}
                            </div>
                        )
                    }
                })}
            </SectionWrapper>
        )
    } else {
        return null
    }
}
