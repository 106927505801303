import React from 'react'
import { useField } from 'formik'

import FieldWrapper from '../fieldWrapper'
import { useCustomId, useGetComponentState } from '../_shared/hooks'
import { SingleSelect, SingleSelectProps } from './singleSelect'

interface SingleSelectFieldFormikProps<T = any> extends Omit<SingleSelectProps<T>, 'selected' | 'status' | 'onSelect'> {
    name: string
    label?: string | React.ReactNode
    id?: string
    className?: string
    validateOnBlur?: boolean
    validateOnChange?: boolean
    onSelect?: (value: string) => void
}

const SingleSelectFieldFormik: React.FC<SingleSelectFieldFormikProps> = ({
    name,
    label,
    id,
    onSelect,
    validateOnBlur = true,
    validateOnChange = true,
    ...props
}) => {
    const [{ value, onChange, onBlur, ...field }, { error }, { setValue, setTouched }] = useField(name)
    const innerId = useCustomId(id)
    const state = useGetComponentState(name)

    const handleOnSelect = (value: string) => (onSelect ? onSelect(value) : setValue(value, validateOnChange))

    return (
        <FieldWrapper label={label} error={error} id={innerId} status={state} className={props.className}>
            <SingleSelect
                {...props}
                id={innerId}
                onSelect={handleOnSelect}
                onBlur={() => setTouched(true, validateOnBlur)}
                selected={value}
                {...field}
                status={state}
            />
        </FieldWrapper>
    )
}

export default SingleSelectFieldFormik
