import React, { useEffect, useRef, useContext, useState } from 'react'
import styled from 'styled-typed'
import { History } from 'history'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { delay } from 'utils'
import { format, isAfter, isBefore, parse } from 'date-fns'
import { ActionButton, ActionButtonA } from 'uiComponents/buttons'
import { withNavigation } from 'hocs'
import { ButtonWrapper } from 'uiComponents/pageElements'
import { withFeatures } from 'features'
import { MessageKind } from 'uiComponents/messages'
import { ContainerBody } from 'uiComponents/settingsContainer'
import { WeekdayPattern } from 'uiComponents/weekdayPattern'
import { TableLoader, ChartDataLoader } from 'uiComponents/loaders'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { TextInput, NumberInput, TimePicker } from 'uiComponents/input'
import { Col, Row } from 'uiComponents/flex'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { InventoryServiceContext } from 'inventory/context'
import { InventoryRule } from 'inventory/inventoryService'
import Infotip from 'uiComponents/infotip'
import { DateFormats, parseTime } from 'utils/dates'
import { parseDate } from 'utils'

const Section = styled(Col)`
    margin-bottom: 2em;
`

interface InventoryRuleFormParams {
    accountSlug: string
    ruleId: string
    inventoryId: string
}

interface InventoryRuleFormProps {
    accountSlug: string
    match: RouteMatch<InventoryRuleFormParams>
    history: History
    navigation: Navigation
    setActiveSection: (section: string, header: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function InventoryRuleForm(props: InventoryRuleFormProps) {
    const didMountRef = useRef(false)
    const inventoryService = useContext(InventoryServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [updating, setUpdating] = useState<boolean>(false)
    const [name, setName] = useState<string | null>('')
    const [availability, setAvailability] = useState<number | null>(null)
    const [weekdays, setWeekdays] = useState<number[]>([0, 1, 2, 3, 4, 5, 6])
    const [timeFrom, setTimeFrom] = useState<string>('00:00')
    const [timeTo, setTimeTo] = useState<string>('23:59')
    const [effectiveFrom, setEffectiveFrom] = useState<string | null>(null)
    const [effectiveTo, setEffectiveTo] = useState<string | null>(null)
    const [timeFromError, setTimeFromError] = useState<string | null>(null)
    const [timeToError, setTimeToError] = useState<string | null>(null)
    const [effectiveFromError, setEffectiveFromError] = useState<string | null>(null)
    const [effectiveToError, setEffectiveToError] = useState<string | null>(null)
    const [priority, setPriority] = useState<number | null>(2)
    const [confirmDeletion, setConfirmDeletion] = useState<boolean>(false)

    const fetchRuleData = async (inventoryId: string, ruleId: string) => {
        try {
            setLoading(true)
            const data = await inventoryService.getInventoryRuleDetails(props.accountSlug, inventoryId, ruleId)
            setDataToState(data)
        } catch {
            props.replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again.')
        } finally {
            setLoading(false)
        }
    }

    const setDataToState = (data: InventoryRule) => {
        setName(data.name)
        setAvailability(data.availability)
        setWeekdays(data.weekdays)
        setTimeFrom(data.timeFrom || '00:00')
        setTimeTo(data.timeTo || '23:59')
        setEffectiveFrom(data.effectiveFrom)
        setEffectiveTo(data.effectiveTo)
        setPriority(data.priority)
    }

    const validateTimes = () => {
        setTimeFromError(isAfter(parseTime(timeFrom), parseTime(timeTo)) ? 'Start time must be before end time' : null)
        setTimeToError(isBefore(parseTime(timeTo), parseTime(timeFrom)) ? 'End time must be after start time' : null)
    }

    const validateDates = () => {
        setEffectiveFromError(
            !effectiveFrom && effectiveTo
                ? 'Enter either both effective range dates or neither'
                : effectiveFrom && effectiveTo && isAfter(new Date(effectiveFrom), new Date(effectiveTo))
                ? 'Start date must be before end date'
                : null,
        )
        setEffectiveToError(
            effectiveFrom && !effectiveTo
                ? 'Enter either both effective range dates or neither'
                : effectiveFrom && effectiveTo && isBefore(new Date(effectiveTo), new Date(effectiveFrom))
                ? 'End date must be after start date'
                : null,
        )
    }

    useEffect(validateTimes, [timeFrom, timeTo])
    useEffect(validateDates, [effectiveFrom, effectiveTo])

    useEffect(() => {
        const ruleId = props.match.params.ruleId
        const inventoryId = props.match.params.inventoryId
        props.setActiveSection('inventory', ruleId === 'new' ? 'Add New Limit Exception' : 'Limit Exception')
        if (inventoryId && ruleId && ruleId !== 'new') {
            fetchRuleData(inventoryId, ruleId)
        }
    }, [])

    useEffect(() => {
        if (didMountRef.current) {
            props.history.push(`/account/${props.accountSlug}/products/inventory/home`)
        } else {
            didMountRef.current = true
        }
    }, [props.accountSlug])

    const onCapacityChange = (value: string) => {
        value ? setAvailability(Number(value)) : setAvailability(null)
    }

    const isDataValid = () => {
        return !timeFromError && !timeToError && !effectiveFromError && !effectiveToError
    }

    const onSaveSuccess = () => {
        setUpdating(false)
        props.history.push(`/account/${props.accountSlug}/products/inventory/item/${props.match.params.inventoryId}`)
    }

    const onRuleSave = async () => {
        if (isDataValid()) {
            try {
                setUpdating(true)
                const inventoryId = props.match.params.inventoryId
                const ruleId = props.match.params.ruleId
                const payload = {
                    id: ruleId,
                    name: name ? name : null,
                    availability,
                    weekdays,
                    effectiveFrom,
                    effectiveTo,
                    timeFrom: timeFrom === '00:00' && timeTo === '23:59' ? null : timeFrom,
                    timeTo: timeFrom === '00:00' && timeTo === '23:59' ? null : timeTo,
                    priority: priority ? priority : 2,
                }
                if (ruleId !== 'new') {
                    await inventoryService.updateInventoryRule(props.accountSlug, inventoryId, ruleId, payload)
                    onSaveSuccess()
                } else {
                    await inventoryService.createInventoryRule(props.accountSlug, inventoryId, payload)
                    onSaveSuccess()
                }
                props.replaceMessages('success', 'success', 'The limit exception has been saved successfully.')
                await delay(3000)
                props.removeAllMessages()
            } catch {
                setUpdating(false)
                props.replaceMessages(
                    'server_error',
                    'error',
                    'Oops! Could not save the limit exception, please try again later.',
                )
            }
        } else {
            props.replaceMessages('validation_error', 'error', 'Please correct the errors below.')
        }
    }

    const onDeleteRule = async () => {
        if (confirmDeletion) {
            try {
                const inventoryId = props.match.params.inventoryId
                await inventoryService.deleteInventoryRule(props.accountSlug, inventoryId, props.match.params.ruleId)
                props.history.replace(`/account/${props.accountSlug}/products/inventory/item/${inventoryId}`)
            } catch {
                props.replaceMessages('server_error', 'error', 'There was a problem deleting the exception.')
            }
        } else {
            setConfirmDeletion(true)
        }
    }

    const formatTime = (time: string) => format(parse(time, 'HH:mm', new Date()), DateFormats.SHORT_TIME)
    const timeFromTooltipText = `Set as '${formatTime('00:00')}' if exception applies for the whole day`
    const timeToTooltipTopText = `Set as '${formatTime('23:59')}' if exception applies for the whole day`
    const timeToTooltipBottomText = `
        The end time in not inclusive, i.e. if a timeslot is set to
        ${formatTime('13:00')}-${formatTime('15:00')} it will apply at
        ${formatTime('14:30')}, but not at ${formatTime('15:00')}.
    `

    return (
        <div style={{ position: 'relative' }}>
            {loading && <TableLoader />}
            {updating && <ChartDataLoader />}
            {!loading && (
                <ContainerBody id="inventory-rule-form" style={{ flexDirection: 'column' }}>
                    <Row>
                        <Section span={6}>
                            <FormItem htmlFor="rule-name" style={{ position: 'relative' }}>
                                <FormItemName>Exception name</FormItemName>
                                <TextInput
                                    id="rule-name"
                                    name="rule-name"
                                    type="text"
                                    maxLength={60}
                                    value={name ? name : ''}
                                    placeholder="Add exception name..."
                                    onChange={(e) => setName(e.target.value)}
                                    block
                                />
                            </FormItem>
                        </Section>
                        <Section span={6}>
                            <FormItem htmlFor="inventory-rule-pattern" style={{ position: 'relative' }}>
                                <FormItemName>Define weekly pattern</FormItemName>
                                <WeekdayPattern
                                    id="inventory-rule-pattern"
                                    weekdays={weekdays.length > 0 ? weekdays.toString() : '0,1,2,3,4,5,6'}
                                    large
                                    handleWeekdayToggleChange={(wkds) => setWeekdays(wkds.map((wd) => Number(wd)))}
                                    type="strict"
                                />
                            </FormItem>
                        </Section>
                    </Row>
                    <Row>
                        <Section span={6}>
                            <Row>
                                <Col span={6}>
                                    <FormItem htmlFor="effectiveFrom" style={{ position: 'relative' }}>
                                        <FormItemName>Effective from</FormItemName>
                                        <DatePicker
                                            id="effectiveFrom"
                                            date={effectiveFrom ? parseDate(effectiveFrom) : null}
                                            onChange={(value: Date) =>
                                                setEffectiveFrom(value ? format(value, 'yyyy-MM-dd') : null)
                                            }
                                            status={effectiveFromError ? 'error' : 'normal'}
                                            allowNullDate
                                        />
                                        {effectiveFromError && (
                                            <ValidationMessage
                                                className="validation-message-visible"
                                                style={{ top: '0' }}
                                            >
                                                {effectiveFromError}
                                            </ValidationMessage>
                                        )}
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem htmlFor="effectiveTo" style={{ position: 'relative' }}>
                                        <FormItemName>
                                            Effective to
                                            <Infotip pointer="left">Date inclusive</Infotip>
                                        </FormItemName>
                                        <DatePicker
                                            id="effectiveTo"
                                            date={effectiveTo ? parseDate(effectiveTo) : null}
                                            onChange={(value: Date) =>
                                                setEffectiveTo(value ? format(value, 'yyyy-MM-dd') : null)
                                            }
                                            status={effectiveToError ? 'error' : 'normal'}
                                            allowNullDate
                                            lowerBoundary={effectiveFrom ? new Date(effectiveFrom) : null}
                                        />
                                        {effectiveToError && (
                                            <ValidationMessage
                                                className="validation-message-visible"
                                                style={{ top: '0' }}
                                            >
                                                {effectiveToError}
                                            </ValidationMessage>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                        </Section>
                        <Section span={6}>
                            <Row>
                                <Col span={6}>
                                    <FormItem htmlFor="timeFrom" style={{ position: 'relative' }}>
                                        <FormItemName>
                                            Time from
                                            <Infotip pointer="left">{timeFromTooltipText}</Infotip>
                                        </FormItemName>
                                        <TimePicker
                                            id="timeFrom"
                                            value={timeFrom ?? '00:00'}
                                            onChange={(time) => setTimeFrom(time || '00:00')}
                                            style={{ fontWeight: 'lighter' }}
                                            errorMessage={timeFromError}
                                            required
                                            fallbackValue="00:00"
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem htmlFor="timeTo" style={{ position: 'relative' }}>
                                        <FormItemName>
                                            Time to
                                            <Infotip pointer="right" maxWidth="25em">
                                                <div style={{ marginBottom: '.7em' }}>{timeToTooltipTopText}</div>
                                                <div>{timeToTooltipBottomText}</div>
                                            </Infotip>
                                        </FormItemName>
                                        <TimePicker
                                            id="timeTo"
                                            value={timeTo ?? '23:59'}
                                            onChange={(time) => setTimeTo(time || '23:59')}
                                            errorMessage={timeToError}
                                            required
                                            fallbackValue="23:59"
                                            style={{ fontWeight: 'lighter' }}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Section>
                    </Row>
                    <Row>
                        <Section span={6}>
                            <FormItem htmlFor="capacity" style={{ position: 'relative' }}>
                                <FormItemName>
                                    Limit
                                    <Infotip pointer="left">Leave blank for an unlimited capacity</Infotip>
                                </FormItemName>
                                <NumberInput
                                    id="capacity"
                                    name="capacity"
                                    integerOnly
                                    min={0}
                                    max={100000}
                                    value={availability !== null ? availability : ''}
                                    placeholder=" "
                                    onChange={(e) => onCapacityChange(e.target.value)}
                                    block
                                />
                            </FormItem>
                        </Section>
                        <Section span={6}>
                            <FormItem htmlFor="priority" style={{ position: 'relative' }}>
                                <FormItemName>
                                    Exception priority
                                    <Infotip pointer="left" maxWidth="30em">
                                        You are configuring an override to the default, which has prio 1. This override
                                        can start with prio 2 or higher. Higher numbers overrule lower numbers.
                                    </Infotip>
                                </FormItemName>
                                <NumberInput
                                    id="priority"
                                    name="priority"
                                    integerOnly
                                    min={2}
                                    max={100}
                                    value={priority !== null ? priority : ''}
                                    placeholder="2"
                                    onChange={(e) =>
                                        e.target.value ? setPriority(Number(e.target.value)) : setPriority(null)
                                    }
                                    block
                                />
                            </FormItem>
                        </Section>
                    </Row>
                    {confirmDeletion && (
                        <Row>
                            <div style={{ fontSize: '.9em', marginBottom: '1.5em' }}>
                                Are you sure you want to delete this exception?
                            </div>
                        </Row>
                    )}
                    <Row>
                        <Col span={6}>
                            {props.match.params.ruleId !== 'new' && (
                                <ActionButton type="button" kind="destructive" size="large" onClick={onDeleteRule}>
                                    Delete
                                </ActionButton>
                            )}
                            {confirmDeletion && (
                                <ActionButton
                                    size="large"
                                    secondary
                                    onClick={() => setConfirmDeletion(false)}
                                    style={{ marginLeft: '1.5em' }}
                                >
                                    Cancel
                                </ActionButton>
                            )}
                        </Col>
                        <Col span={6}>
                            <ButtonWrapper>
                                <ActionButtonA
                                    size="large"
                                    href={`/account/${props.accountSlug}/products/inventory/item/${props.match.params.inventoryId}`}
                                    secondary
                                    style={{ marginRight: '1.5em' }}
                                    kind="action"
                                >
                                    Cancel
                                </ActionButtonA>
                                <ActionButton id="save-timeSlot" size="large" onClick={onRuleSave}>
                                    Save
                                </ActionButton>
                            </ButtonWrapper>
                        </Col>
                    </Row>
                </ContainerBody>
            )}
        </div>
    )
}

export default withFeatures(withNavigation(InventoryRuleForm))
