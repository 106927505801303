import React, { useState, useEffect } from 'react'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { UpdateTicketValidToDetails, Ticket } from 'orders/schema'
import { OrdersService } from 'orders/ordersService'
import { MessageKind } from 'uiComponents/messages'
import { stubFalse } from 'lodash'
import { DateFormats, flatMap, formatOrInvalid } from 'utils'

interface UpdateDialogProps {
    accountSlug: string
    updateValidToDetails: UpdateTicketValidToDetails
    ordersService: OrdersService
    loading: boolean
    onCancel: () => void
    onConfirm: () => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

export function UpdateDialog(props: UpdateDialogProps) {
    const defaultMessage = `Are you sure you want to update the 'Valid to' date of this
    ticket to ${formatOrInvalid(props.updateValidToDetails.date, DateFormats.EXTRA_LONG_DATE)}?`
    const [loading, setLoading] = useState<boolean>(false)
    const [text, setText] = useState<React.ReactNode>(defaultMessage)

    useEffect(() => {
        getOrderData()
    }, [])

    async function getOrderData() {
        setLoading(true)
        try {
            const order = await props.ordersService.getOrderDetailsForRefundOld(
                props.accountSlug,
                props.updateValidToDetails.orderId,
            )
            const details = props.updateValidToDetails
            const orderItemId = details.bundleOrderItemId || details.orderItemId
            const ticket = order.tickets.find((t) => t.orderItemId === Number(orderItemId))
            checkTicketItems(ticket)
        } catch {
            closeWithError()
        } finally {
            setLoading(stubFalse)
        }
    }

    function checkTicketItems(orderItem?: Ticket) {
        if (!orderItem) {
            closeWithError()
        } else if (orderItem.quantity < 2) {
            return
        } else {
            const flatMappedTickets = flatMap(orderItem.groupedTicketEntities, (x) => x)
            const barcodes: string[] = []
            flatMappedTickets.forEach((t) => {
                if (t.barcode?.barcode) {
                    barcodes.push(t.barcode.barcode)
                }
            })
            setText(
                <>
                    {defaultMessage}
                    <div>
                        <strong>Please note:</strong> the date will also be updated for all {flatMappedTickets.length}{' '}
                        same type tickets.
                        {barcodes.length > 0 && <> Barcodes: {barcodes.join(', ')}</>}
                    </div>
                </>,
            )
        }
    }

    function closeWithError() {
        props.replaceMessages(
            'unknown_error',
            'error',
            'Oops! We could not retrieve ticket details. Please try again later.',
        )
        props.onCancel()
    }

    return (
        <ConfirmationDialog
            title="Update ticket 'Valid to' date?"
            text={text}
            buttonText="Update"
            onCancel={props.onCancel}
            onConfirm={props.onConfirm}
            loading={loading || props.loading}
        />
    )
}
