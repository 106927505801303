import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import classNames from 'classnames'
import { Checkbox } from 'uiComponents/input'
import Money from 'uiComponents/money'
import {
    getSelectedOrderItemsByTableIdAndRowId,
    shouldToggleOrder,
    toggleOrder,
    toggleOrderItem,
} from 'orders/orders/redux'
import { ReplaceMessagesFunc } from 'hocs'
import ExpandIcon from '../../commons/expandIcon'
import { IOrderItems } from 'orders/schema'
import ItemOptionsSection from './itemOptionsSection'
import ItemDetailsSection from './itemDetailsSection'
import ItemAPFSection from './itemAPFSection'
import './styles.scss'
import ItemCancelationRefundSection from './itemCancelationRefundSection'

const OrderItem = ({
    item,
    accountSlug,
    orderUuid,
    replaceMessages,
    hideMessage,
    shouldBeExpanded,
}: {
    item: IOrderItems
    accountSlug: string
    orderUuid: string
    replaceMessages: ReplaceMessagesFunc
    hideMessage: (id: string) => void
    shouldBeExpanded?: boolean
}) => {
    const [isExpanded, setIsExpanded] = React.useState(shouldBeExpanded ?? true)
    const dispatch = useDispatch()
    const orderShouldToggle = useSelector(shouldToggleOrder(orderUuid, item.id))
    const isItemSelected = useSelector(getSelectedOrderItemsByTableIdAndRowId(orderUuid, item.id))
    const isItemCanceledRefunded = !!item.refundInfo

    const handleCheckboxChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(toggleOrderItem({ rowId: item.id, tableId: orderUuid }))
            if (orderShouldToggle) {
                dispatch(toggleOrder({ orderUuid, selected: e.target.checked }))
            }
        },
        [dispatch, item.id, orderShouldToggle, orderUuid],
    )

    return (
        <div className="item">
            <div
                className={classNames({
                    'item-row': true,
                    selected: isItemSelected,
                    canceledRefunded: isItemCanceledRefunded,
                })}
            >
                <div className="left-side">
                    <ExpandIcon isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
                    <Checkbox
                        checked={isItemCanceledRefunded ? false : isItemSelected}
                        onChange={handleCheckboxChange}
                        disabled={isItemCanceledRefunded}
                    />
                    <h6>{item.product + ` (${item.productNumericId})`}</h6>
                </div>
                <h6>
                    <Money amount={item.priceInclTax} accountSlug={accountSlug} />
                </h6>
            </div>
            {isExpanded && (
                <div>
                    <ItemDetailsSection
                        item={item}
                        isItemSelected={isItemSelected}
                        isItemCanceledRefunded={isItemCanceledRefunded}
                        accountSlug={accountSlug}
                        orderId={orderUuid}
                        replaceMessages={replaceMessages}
                        hideMessage={hideMessage}
                    />
                    {item.options.length > 0 && (
                        <ItemOptionsSection
                            options={item.options}
                            isItemSelected={isItemSelected}
                            isItemCanceledRefunded={isItemCanceledRefunded}
                            accountSlug={accountSlug}
                        />
                    )}
                    {!isEmpty(item.afterPaymentForm) && (
                        <ItemAPFSection
                            item={item}
                            isItemSelected={isItemSelected}
                            isItemCanceledRefunded={isItemCanceledRefunded}
                            accountSlug={accountSlug}
                            replaceMessages={replaceMessages}
                            hideMessage={hideMessage}
                        />
                    )}
                    {!!item.refundInfo && (
                        <ItemCancelationRefundSection
                            item={item}
                            accountSlug={accountSlug}
                            isItemSelected={isItemSelected}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default OrderItem
