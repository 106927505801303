import { Autocomplete } from '@react-google-maps/api'
import React, { useState } from 'react'
import styled from 'styled-components'
import FieldWrapper from 'uiComponents/input/fieldWrapper'
import { useParkMapViewerContext } from '../../context/mapViewer'

const AutocompleteInput = styled.input.attrs(({ placeholder }) => ({
    type: 'text',
    placeholder: placeholder || 'Search for place or location',
}))`
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 100%;
    outline: none;
    text-overflow: ellipsis;
    background: #f5f7fa;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    height: 2.2rem;
    font-weight: lighter;
    border-radius: 0.375em;
    padding: 0.625em 1em;
`

interface Props {
    mapId?: string
}

const LocationAutocomplete = ({ mapId }: Props) => {
    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | undefined>()
    const { isLoaded, setCoords, setIsRegionChanged } = useParkMapViewerContext()

    const getPlaceholder = (mapId?: string | undefined) => {
        const defaultPlaceholder = 'Search for place or location'

        if (!mapId) {
            return defaultPlaceholder
        }

        const searchedLocation = localStorage.getItem(mapId)
        if (!searchedLocation) {
            return defaultPlaceholder
        }

        return `Last searched location: ${searchedLocation}`
    }

    const [placeholder, setPlaceholder] = useState<string>(getPlaceholder(mapId))

    const onLoadA = (autocomplete: google.maps.places.Autocomplete) => {
        setAutocomplete(autocomplete)
    }

    const onPlaceChangedA = () => {
        if (autocomplete) {
            setCoords({
                lat: autocomplete?.getPlace()?.geometry?.location?.lat() || 0,
                lng: autocomplete?.getPlace()?.geometry?.location?.lng() || 0,
            })

            if (mapId) {
                localStorage.setItem(mapId, autocomplete?.getPlace()?.name || '')
            }

            setIsRegionChanged(true)
        } else {
            console.warn('Autocomplete is not loaded yet!')
        }
    }

    const onFocus = () => {
        setPlaceholder(getPlaceholder())
    }

    const onBlur = () => {
        setPlaceholder(getPlaceholder(mapId))
    }

    return (
        <FieldWrapper
            label="Location search"
            id="location-autocomplete"
            status="normal"
            validationMessageProps={{ style: { position: 'relative' } }}
        >
            {isLoaded && (
                <Autocomplete onLoad={onLoadA} onPlaceChanged={onPlaceChangedA}>
                    <AutocompleteInput onFocus={onFocus} onBlur={onBlur} placeholder={placeholder} />
                </Autocomplete>
            )}
        </FieldWrapper>
    )
}

export default LocationAutocomplete
