import React from 'react'
import { LoaderWrapper } from 'uiComponents/loaders'
import { useParkMapViewerContext } from '../../context/mapViewer'
import { useSelectedParkMapContext } from '../../context/selectedParkMap'
import useUpdateMap from '../../hooks/useUpdateMap'
import MapForm from './mapForm'

const TabMapEdit = () => {
    const { isLoading, updateMap } = useUpdateMap()
    const { selectedParkMap } = useSelectedParkMapContext()
    const { isRegionChanged, setIsRegionChanged } = useParkMapViewerContext()

    return (
        <LoaderWrapper loading={isLoading}>
            <MapForm
                data={selectedParkMap}
                onSubmit={(values) => {
                    if (isRegionChanged) {
                        updateMap(values)
                    }

                    setIsRegionChanged(false)
                }}
            />
        </LoaderWrapper>
    )
}

export default TabMapEdit
