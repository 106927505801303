import { FundAccountStatus, FundBalance } from 'admin/types'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import styled from 'styled-typed'
import ActionButton from 'uiComponents/buttons'
import { Col, Row } from 'uiComponents/flex'
import FundTransferNotification from './fundTransferNotification'
import TextInputField from '../../uiComponents/input/textInput/textInputField'
import * as yup from 'yup'
import CancelFundTransfer from './cancelFundTransfer'

const RowInput = styled(TextInputField)`
    padding: 0;
    width: 124px;
    height: 24px;
    background: #ffffff;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    border-radius: 0;

    &:focus {
        border: none;
        border-bottom: 1px solid ${(props) => props.theme.colors.boyBlue};
    }

    &:hover {
        border: none;
        border-bottom: 1px solid ${(props) => props.theme.colors.boyBlueShades[50]};
    }

    &.error {
        color: ${(props) => props.theme.colors.status.error};
        border-bottom: 1px solid ${(props) => props.theme.colors.status.error};
    }

    &.warning {
        color: ${(props) => props.theme.colors.status.warn};
        border-bottom: 1px solid ${(props) => props.theme.colors.status.warn};
    }
`

interface Props {
    balance?: FundBalance
    pendingBalance?: FundBalance
    status: FundAccountStatus
    accountSlug: string
    accountName: string
}

type RowState = 'disabled' | 'active' | 'lack_funds' | 'locked'

const getRowState = ({
    balance,
    status,
    amount,
}: {
    balance?: FundBalance
    status: FundAccountStatus
    amount?: number
}): RowState => {
    if (status === 'PAYOUT_ON_HOLD') {
        return 'locked'
    }

    if (!amount) {
        return 'disabled'
    }
    const balanceValue = balance?.value ?? 0

    if (balanceValue < amount) {
        return 'lack_funds'
    }

    return 'active'
}

const validationSchema = yup.object().shape({
    amount: yup.number().required().min(0),
})

const FundAction: React.FC<Props> = ({ balance, pendingBalance, status, accountSlug, accountName }) => {
    const [touched, setTouched] = React.useState<boolean>(false)
    const [amount, setAmount] = React.useState<number>()
    const rowState = getRowState({ balance, status, amount })
    const isValid = useMemo(() => validationSchema.isValidSync({ amount }), [amount])

    if (['PENDING', 'NOT_CONFIGURED'].includes(status)) {
        return null
    }

    if ('PAYOUT_ON_HOLD' === status) {
        return <CancelFundTransfer accountName={accountName} accountSlug={accountSlug} />
    }

    return (
        <Row wrap="nowrap" gutter={16} vAlign="center" align="flex-start">
            <Col span="auto">
                <RowInput
                    placeholder="0.00"
                    className={classNames('fund_amount', {
                        error: rowState === 'lack_funds',
                        warning: rowState === 'locked',
                    })}
                    name="fund_amount"
                    value={amount ? String(amount) : ''}
                    onChange={(e) => setAmount(String(e.target.value).trim() as any)}
                    onFocus={() => setTouched(true)}
                    error={touched && !isValid ? 'Wrong format' : undefined}
                />
            </Col>
            <Col span="auto">
                <FundTransferNotification
                    accountSlug={accountSlug}
                    accountName={accountName}
                    balance={balance}
                    pendingBalance={pendingBalance}
                    amount={amount as number}
                >
                    {({ openModal }) => (
                        <ActionButton
                            className="fund-action-button"
                            size="small"
                            disabled={rowState === 'disabled' || !amount || !isValid}
                            kind={rowState === 'lack_funds' ? 'destructive' : 'action'}
                            onClick={openModal}
                            style={{ minWidth: '112px' }}
                        >
                            {rowState === 'lack_funds' ? 'Lock payout' : 'Transfer funds'}
                        </ActionButton>
                    )}
                </FundTransferNotification>
            </Col>
        </Row>
    )
}

export default FundAction
