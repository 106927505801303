import { NameCell } from '../common'
import React from 'react'
import { PlacementListItem } from 'tapPlacements/schema'
import { Cell, DataRow, MultipleStatus, StatusName } from 'uiComponents/table'
import { ActionMenu } from '../listActionMenu'
import { format } from 'date-fns'
import { Draggable } from 'react-beautiful-dnd'
import styled from 'styled-typed'
import { find } from 'lodash'
import { Audience } from 'crm/schema'
import { DateFormats } from 'utils/dates'

export const AudienceContainer = styled.div`
    display: flex;
    flex-direction: column;
    white-space: normal;
`

type Props = {
    index: number
    item: PlacementListItem
    status: StatusName
    hasPlacementAudienceFeature: boolean
    hasPlacementLocationFeature: boolean
    accountSlug: string
    venueLocation: string
    audiences: Audience[]
    onDelete(itemId: string): void
}

const TableRow = ({
    index,
    item,
    status,
    hasPlacementAudienceFeature,
    hasPlacementLocationFeature,
    accountSlug,
    venueLocation,
    audiences,
    onDelete,
}: Props) => {
    const formatDates = (startDate: number, endDate: number | null) => {
        const endText = endDate ? format(new Date(endDate), DateFormats.LONG_DATE) : 'never'

        return (
            <>
                {format(new Date(startDate), DateFormats.LONG_DATE)} -<br />
                {endText}
            </>
        )
    }

    const renderAudiences = () => {
        if (hasPlacementAudienceFeature && item.audiences?.length) {
            return (
                <AudienceContainer>
                    {item.audiences.map((audience, itemIndex) => {
                        const readableAudience = find(audiences, { slug: audience.audience })?.name || audience.audience
                        return <div key={itemIndex}>{readableAudience}</div>
                    })}
                </AudienceContainer>
            )
        }

        return ''
    }

    return (
        <Draggable key={item.id} draggableId={item.id} index={index}>
            {(provided) => (
                <DataRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <NameCell>
                        <div>{item.name}</div>
                        <MultipleStatus
                            status={status}
                            withText
                            successText="Active"
                            errorText="Inactive"
                            warnText="Expired"
                        />
                    </NameCell>
                    <Cell>{formatDates(item.startDate, item.endDate)}</Cell>
                    <Cell>{renderAudiences()}</Cell>
                    {hasPlacementLocationFeature && <Cell>{venueLocation}</Cell>}
                    <Cell align="right" className="wrapContent">
                        <ActionMenu
                            onUnpublish={null}
                            onPublish={null}
                            onEditInfo={null}
                            studioHref={`/account/${accountSlug}/engage/tools/placements/type/${item.id}`}
                            onDelete={() => {
                                onDelete(item.id)
                            }}
                        />
                    </Cell>
                </DataRow>
            )}
        </Draggable>
    )
}

export default TableRow
