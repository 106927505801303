import { Locale } from 'admin/articleService'
import { cloneDeep } from 'lodash'
import React from 'react'
import { TapChannelServiceContext } from 'tapPromotions/channelServiceContext'
import { PromotionAddPayload, PromotionGetResponse } from 'tapPromotions/types'
import { PromotionConfig, PromotionConfigPaths } from './types'
export const PromotionContext = React.createContext<{
    hasChanges: boolean
    error: string
    setHasChanges(value: boolean): void
    getValueForPath(path: PromotionConfigPaths): string | number | string[]
    setValueForPath(value: string | number | string[] | null, path: string): void
    setLocales(locales: Locale[]): void
    loadConfig(promotion: PromotionGetResponse): void
    toPayload(accountSlug: string): PromotionAddPayload
    fetchPartnerDetails(partnerSlug: string): void
}>(null as any)

type Props = {
    children: JSX.Element
}

const PromotionProvider = ({ children }: Props) => {
    const tapChannelService = React.useContext(TapChannelServiceContext)
    const [error, setError] = React.useState<string>('')
    const [hasChanges, setHasChanges] = React.useState(false)
    const [config, setConfig] = React.useState<PromotionConfig>({
        promotionName: '',
        partnerName: '',
        partnerSlug: '',
        active: '0',
        content: {},
        defaultLanguage: 'en',
        language: 'en',
        theme: 'DARK',
        marketingConsent: false,
        audience: null,
    })

    const fetchPartnerDetails = async (partnerSlug: string) => {
        try {
            const data = await tapChannelService.getTapAccountDetails(partnerSlug)
            setConfig({
                ...config,
                partnerSlug: partnerSlug,
                partnerName: data.channelTitle,
                brandColor: data.brandColor,
                logo: data.channelLogo,
            })
        } catch {
            setConfig({
                ...config,
                partnerSlug: partnerSlug,
                partnerName: '',
                brandColor: undefined,
                logo: undefined,
            })
            setError(`Failed to get partner details: "${partnerSlug}"`)
        }
    }

    const setContentValue = (value: string | number, contentType: 'title' | 'content') => {
        const lang = config.language
        const deepCopy = cloneDeep(config)
        const existingContent = deepCopy.content[lang] || {}

        existingContent[contentType] = value as string

        setConfig({
            ...deepCopy,
            content: {
                ...deepCopy.content,
                [lang]: existingContent,
            },
        })
    }

    const setValueForPath = (value: string | number, path: string) => {
        if (path.includes('content-title')) {
            setContentValue(value, 'title')
        } else if (path.includes('content-content')) {
            setContentValue(value, 'content')
        } else {
            const deepCopy = cloneDeep(config)
            deepCopy[path] = value
            setConfig(deepCopy)
        }

        setHasChanges(true)
    }

    const getValueForPath = (path: PromotionConfigPaths) => {
        if (path.includes('content-title')) {
            return config.content[config.language]?.title || ''
        } else if (path.includes('content-content')) {
            return config.content[config.language]?.content || ''
        }

        return config[path] || ''
    }

    const setLocales = (locales: Locale[]) => {
        const allContent = {}
        locales.map((locale) => {
            allContent[locale.code] = { title: '' }
        })

        const deepCopy = cloneDeep(config)
        deepCopy.content = allContent
        setConfig(deepCopy)
    }

    const loadConfig = (response: PromotionGetResponse) => {
        const deepCopy = cloneDeep(config)

        const contentItems = {}
        let defaultLanguage = 'en'
        response.content.map((content) => {
            contentItems[content.locale] = {
                title: content.title,
                content: content.content,
                default: content.default,
            }

            if (content.default) {
                defaultLanguage = content.locale
            }
        })

        setConfig({
            ...deepCopy,
            id: response.id,
            partnerSlug: response.partnerSlug,
            partnerName: response.partnerName,
            promotionName: response.name,
            startDate: response.startDate,
            endDate: response.endDate,
            weight: response.weight,
            theme: response.style.theme,
            content: contentItems,
            defaultLanguage,
            language: defaultLanguage,
            active: response.active ? '1' : '0',
            logo: response.style.image,
            brandColor: response.style.brandColor,
            marketingConsent: !!response.consentRequired,
            audience: response.audiences ? response.audiences.map((audience) => audience.audience) : null,
        })
    }

    const toPayload = (accountSlug: string) => {
        const contentItems = Object.keys(config.content).map((locale: any) => ({
            locale: locale,
            title: config.content[locale].title,
            content: config.content[locale].content,
            default: config.defaultLanguage === locale,
        }))

        const audiences = config.audience ? config.audience.map((audience) => ({ audience })) : null

        return {
            slug: accountSlug,
            partnerSlug: config.partnerSlug,
            partnerName: config.partnerName,
            active: !!parseInt(config.active, 10) || false,
            name: config.promotionName,
            startDate: config.startDate,
            endDate: config.endDate,
            weight: config.weight || 1,
            style: {
                theme: config.theme || '',
                image: config.logo,
                brandColor: config.brandColor,
            },
            content: contentItems,
            consentRequired: !!config.marketingConsent,
            audiences,
        }
    }

    return (
        <PromotionContext.Provider
            value={{
                hasChanges,
                error,
                getValueForPath,
                setValueForPath,
                setLocales,
                loadConfig,
                toPayload,
                fetchPartnerDetails,
                setHasChanges,
            }}
        >
            {children}
        </PromotionContext.Provider>
    )
}

export default PromotionProvider
export const usePromotion = () => React.useContext(PromotionContext)
