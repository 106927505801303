import * as React from 'react'
import styled from 'styled-typed'
import { UploadInput } from 'uiComponents/input/upload'
import { ItemValue, ItemValueText, EditBox, InputBox, InlineEditActionSection } from 'uiComponents/input/inlineEdit'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { UploadedFile } from 'orders/ordersService'
import { delay } from 'utils'
import { ActionIcon } from 'uiComponents/buttons/actionIcon'
import { MessageProps, withMessages } from 'hocs'

const ImgLink = styled.a`
    color: ${(props) => props.theme.colors.textDark};
`
const NewItemValue = styled(ItemValue)`
    display: flex;
    align-items: center;
    height: 1.9em;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s;

    &.visible {
        opacity: 1;
        visibility: visible;
    }
`

interface InlineUploadState {
    editMode: boolean
    newValue: string
}

interface InlineUploadProps {
    id: string
    label?: string
    value: string
    disabled?: boolean
    fileSizeLimit: number
    limitedTextWidth?: string
    onEditAccept: (value: string) => void
    maxLength?: number
    placeholder?: string | React.ReactElement
    onUpload: (file: File) => Promise<UploadedFile>
}

class InlineUploadComponent extends React.PureComponent<InlineUploadProps & MessageProps, InlineUploadState> {
    constructor(props: InlineUploadProps & MessageProps) {
        super(props)
        this.state = {
            editMode: false,
            newValue: '',
        }
    }

    editInput: HTMLInputElement

    enableEdit = () => {
        this.setState({ editMode: true })
    }

    handleUploadSuccess = (response: UploadedFile) => {
        delay(1000)
        this.setState({ newValue: response.file_id })
    }

    onCancel = () => {
        this.setState({ editMode: false, newValue: '' })
    }

    onAccept = () => {
        this.props.onEditAccept(this.state.newValue)
        this.setState({ editMode: false })
    }

    render() {
        const { id, value, disabled, limitedTextWidth, placeholder, onUpload, fileSizeLimit } = this.props
        const { editMode, newValue } = this.state

        return (
            <>
                {!editMode && (
                    <ItemValue id={id} limitedTextWidth={limitedTextWidth}>
                        <ItemValueText>
                            <ImgLink href={value} target="_blank">
                                {value}
                            </ImgLink>
                        </ItemValueText>
                        {!value && placeholder && <ItemValueText className="placeholder">{placeholder}</ItemValueText>}
                        {!disabled && (
                            <ActionIcon
                                onClick={this.enableEdit}
                                icon={faPencilAlt}
                                title="Edit"
                                className="open-edit"
                                style={{ marginLeft: '0.5em' }}
                            />
                        )}
                    </ItemValue>
                )}
                {editMode && (
                    <EditBox limitedTextWidth={limitedTextWidth} className="stacked">
                        <InputBox
                            limitedTextWidth={limitedTextWidth}
                            className={newValue ? 'hide-underline hide-box' : 'hide-underline'}
                            style={{ fontSize: '.85em' }}
                        >
                            <UploadInput
                                type="img"
                                fileSizeLimit={fileSizeLimit}
                                onUpload={onUpload}
                                handleUploadSuccessResponse={this.handleUploadSuccess}
                                style={{ height: '2.2em' }}
                                inputId={id}
                                replaceTopMessages={this.props.replaceMessages}
                                removeAllMessages={this.props.removeAllMessages}
                            />
                        </InputBox>
                        <NewItemValue limitedTextWidth={limitedTextWidth} className={newValue ? 'visible' : ''}>
                            <ItemValueText>
                                <ImgLink href={newValue} target="_blank">
                                    {newValue}
                                </ImgLink>
                            </ItemValueText>
                        </NewItemValue>
                        <InlineEditActionSection stacked onAccept={this.onAccept} onCancel={this.onCancel} />
                    </EditBox>
                )}
            </>
        )
    }
}

export const InlineUpload = withMessages(InlineUploadComponent)
