import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { history } from 'middleware'
import ActionButton from 'uiComponents/buttons'
import { Col, Row } from 'uiComponents/flex'
import { ModalDialogTitle } from 'uiComponents/popups/modal'
import { Headline } from 'uiComponents/typography'
import { pathUtils } from 'utils/pathnameFormatter'
import { CODES_PATHS } from 'venue/bookingCodes/paths'
import { DiscountPool } from 'venue/bookingCodes/bookingCodesService'
import { BaseRouteParams } from 'hocs'

interface AddPoolSuccessfulProps {
    pool?: DiscountPool
}

const AddPoolSuccessful: React.FC<AddPoolSuccessfulProps> = ({ pool }) => {
    const { search } = useLocation()
    const { accountSlug } = useParams<BaseRouteParams>()

    const closePool = () =>
        history.push(
            pathUtils.populateParams(pathUtils.addQueryString(CODES_PATHS.fullPaths.poolsPage, search), {
                accountSlug,
            }),
        )

    const addCodes = () =>
        history.push(
            pathUtils.addQueryString(
                pathUtils.populateParams(CODES_PATHS.fullPaths.poolsPageAddByUpload, {
                    accountSlug,
                    id: pool?.uuid,
                }),
                search,
            ),
        )

    return (
        <>
            <ModalDialogTitle>Pool successfully added!</ModalDialogTitle>
            <Headline size={6} style={{ wordBreak: 'break-word' }}>
                You have successfully added discount code pool{pool?.name && <b>&nbsp;“{pool.name}”</b>}. Add codes or
                upload .csv file with discount codes now!
            </Headline>
            <Row align="flex-end" style={{ paddingTop: '32px' }}>
                <Col span="auto">
                    <ActionButton size="large" secondary onClick={closePool}>
                        Cancel
                    </ActionButton>
                </Col>
                <Col span="auto">
                    <ActionButton size="large" onClick={addCodes}>
                        Add codes
                    </ActionButton>
                </Col>
            </Row>
        </>
    )
}

export default AddPoolSuccessful
