import React, { useState, useEffect, useContext, useRef } from 'react'
import { usePrevious } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { DataTotal } from 'reports/schema'
import BarChartYAxis from 'uiComponents/charts/barChartYAxis'
import { format } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { ChartHeadline, ChartContainer } from 'uiComponents/charts/styleComponents'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import { QueryConfig } from 'reports/queryGenerator'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { DateRange } from 'dateRanges'
import { areDateRangeDatesEqual, chartRowsGap } from 'reports/helpers'
import PresetDateRangeElement, { PresetType } from 'uiComponents/popups/presetsElement'
import { remove } from 'lodash'
import { toPercent } from './utils'
import Infotip from 'uiComponents/infotip'
import { DashboardTheme } from 'theme'
import { withTheme } from 'styled-typed'

const queryConfig: QueryConfig = {
    querySetName: 'CheckoutDropChart',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'metric', type: 'StatsMetric' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'groupBy', type: 'String' },
        { name: 'topN', type: 'Int' },
    ],
    queries: [
        {
            name: 'stats',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'groupBy', 'topN'],
            customVariables: [],
            presetResult: 'totals',
        },
    ],
}

interface ChartsProps {
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    dateRange: DateRange
    theme: DashboardTheme
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

function Chart(props: ChartsProps) {
    const rangeName = 'checkout_funnel_rates'
    const defaultRange = 'drop_rates'
    const rangeOptions: PresetType[] = ['drop_rates', 'back_rates']
    const _lastRequest = useRef<number>()

    const _isMounted = useRef(false)
    const statsService = useContext(StatsServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [chartData, setChartData] = useState<DataTotal[]>([])
    const [checkoutFunnelType, setCheckoutFunnelType] = useState(defaultRange)

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    useEffect(() => {
        _isMounted.current = true
        if (prevAccountSlug !== props.accountSlug || !areDateRangeDatesEqual(prevDateRange, props.dateRange)) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug, props.dateRange])

    useEffect(() => {
        getData()
    }, [checkoutFunnelType])

    const getData = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        setLoading(true)
        const dateFrom = format(props.dateRange.from || new Date(), 'yyyy-MM-dd')
        const dateTo = format(props.dateRange.to || new Date(), 'yyyy-MM-dd')
        try {
            const metric =
                checkoutFunnelType === 'drop_rates' ? 'checkout_screen_drop_ratio' : 'checkout_screen_back_ratio'

            const variables = {
                widget: props.accountSlug,
                metric,
                dateFrom,
                dateTo,
                groupBy: 'screen_path_name',
                topN: 5,
            }
            const data = await statsService.getStats(queryConfig, variables)

            if (requestTime !== _lastRequest.current) {
                return
            }

            const stats = data.stats.totals || []
            const percentSeries = stats.map((s) => ({
                ...s,
                value: toPercent(s.value),
            }))
            const sortedStats = percentSeries.sort((a, b) => b.value - a.value) || []
            remove(sortedStats, { name: 'Other' })

            if (_isMounted.current) {
                setLoading(false)
                setChartData(sortedStats)
            }
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Screens with biggest drop rates could not be loaded, please try again later.',
            )
            setLoading(false)
            setChartData([])
        }
    }

    const onRangeChange = (rangeString: string) => {
        setCheckoutFunnelType(rangeString)
    }

    const chartTooltipFormatter = (params: TooltipParams[]) => {
        let tooltip = ''
        params.forEach((p) => {
            if (Number(p.value) > 0) {
                tooltip = p.seriesName
            }
        })
        return tooltip
    }

    const chartSeries = chartData.map((x) => ({
        name: `${x.name}: ${x.value}%`,
        type: 'bar',
        barWidth: '50%',
        stack: 1,
        data: chartData.map((y) => ({
            name: y.name,
            value: x.name === y.name ? y.value : 0,
        })),
    }))

    const placeholderSeries = [
        {
            name: '',
            type: 'bar',
            barWidth: '60%',
            stack: 1,
            data: chartData,
        },
    ]

    const chartColors = ['70', '60', '40', '30', '20', '10'].map((shade) => props.theme.colors.boyBlueShades[shade])

    return (
        <>
            <div style={{ marginBottom: chartRowsGap }}>
                <PresetDateRangeElement
                    presetName={rangeName}
                    default={defaultRange}
                    options={rangeOptions}
                    onChange={onRangeChange}
                />
            </div>
            <ChartContainer>
                {loading && <ChartDataLoader />}
                <ChartHeadline size={4}>
                    <span>
                        Pages with the highest{' '}
                        {checkoutFunnelType === 'drop_rates' ? 'exit rates' : 'rates of users going backwards'}
                    </span>
                    <Infotip pointer="left" fixedMaxSize maxWidth="40em">
                        {checkoutFunnelType === 'drop_rates' ? (
                            <>
                                The exit rate is the rate of Checkout visitors who exit your Checkout on a specific
                                page. The higher the exit rate, the more people leave the funnel at that point of their
                                journey.
                            </>
                        ) : (
                            <>
                                The Backwards rate is the rate of Checkout visitors who go back to the previous step of
                                the funnel.
                            </>
                        )}
                    </Infotip>
                </ChartHeadline>
                <BarChartYAxis
                    series={chartSeries.length === 0 ? placeholderSeries : chartSeries}
                    chartHeight="300px"
                    loading={loading}
                    tooltipFormatter={chartTooltipFormatter}
                    legend={{
                        left: '55%',
                        top: 40,
                        height: '100%',
                    }}
                    grid={{
                        width: '50%',
                        height: '85%',
                        top: 30,
                        left: 10,
                        right: '0%',
                        bottom: '0%',
                        containLabel: true,
                    }}
                    colors={chartColors}
                    xAxisFormatter="{value}%"
                />
            </ChartContainer>
        </>
    )
}

export default withTheme(withNavigation(Chart))
