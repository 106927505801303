import { useJsApiLoader } from '@react-google-maps/api'
import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import * as config from '../../../config'
import { Category, Coord, POI } from '../models'
import { useSelectedParkMapContext } from './selectedParkMap'

const initialCoords: Coord = {
    lat: 52.36635,
    lng: 4.8881226,
}

const initialZoom: number = 18

interface ParkMapViewerContextProps {
    isLoaded: boolean
    map: google.maps.Map | null
    setMap: (map: google.maps.Map | null) => void
    mapType: string | undefined
    setMapType: (typeId: string) => void
    zoom: number
    setZoom: (zoom: number) => void
    resetZoom: () => void
    coords: Coord | undefined
    setCoords: (coords: Coord) => void
    bounds: google.maps.LatLngBounds | null
    setBounds: (bounds: google.maps.LatLngBounds) => void
    showOverlayRegion: boolean
    setShowOverlayRegion: Dispatch<SetStateAction<boolean>>
    categories: Category[]
    setCategories: React.Dispatch<React.SetStateAction<Category[]>>
    poisToDisplay: POI[]
    setPoisToDisplay: React.Dispatch<React.SetStateAction<POI[]>>
    markers: google.maps.Marker[]
    setMarkers: Dispatch<SetStateAction<google.maps.Marker[] | null>>
    isRegionChanged: boolean
    setIsRegionChanged: Dispatch<SetStateAction<boolean>>
    revertMapToInitialState: () => void
    overlayIsVisible: boolean
    setOverlayVisibility: Dispatch<SetStateAction<boolean>>
}

type Props = {
    children: JSX.Element
}

export const ParkMapViewerContext = createContext<ParkMapViewerContextProps>({} as ParkMapViewerContextProps)

const ParkMapViewerProvider = ({ children }: Props) => {
    const [zoom, setZoom] = useState<number>(initialZoom)
    const [coords, setCoords] = useState<Coord>(initialCoords)
    const [map, setMap] = useState<google.maps.Map | null>(null)
    const [bounds, setBounds] = useState<google.maps.LatLngBounds | null>(null)
    const { selectedParkMap } = useSelectedParkMapContext()
    const [showOverlayRegion, setShowOverlayRegion] = useState<boolean>(true)
    const [categories, setCategories] = useState<Category[]>([])
    const [poisToDisplay, setPoisToDisplay] = useState<POI[]>([])
    const [markers, setMarkers] = useState<google.maps.Marker[]>([])
    const [isRegionChanged, setIsRegionChanged] = useState<boolean>(false)
    const [mapType, setMapType] = React.useState<string>('standard')
    const [overlayIsVisible, setOverlayVisibility] = React.useState<boolean>(true)

    useEffect(() => {
        if (selectedParkMap && !mapType) {
            setMapType(selectedParkMap.googleMapType)
        }
    }, [selectedParkMap])

    useEffect(() => {
        if (selectedParkMap?.region?.south) {
            setCoords(selectedParkMap.region.center)
            setBounds(
                new google.maps.LatLngBounds(
                    { lat: selectedParkMap.region.south, lng: selectedParkMap.region.west },
                    { lat: selectedParkMap.region.north, lng: selectedParkMap.region.east },
                ),
            )
            setZoom(selectedParkMap.region.zoom)
        }
    }, [selectedParkMap])

    const { isLoaded } = useJsApiLoader({
        id: config.getRequired('google-maps-id'),
        googleMapsApiKey: config.getRequired('google-maps-api-key'),
        libraries: ['places'],
    })

    const resetZoom = useCallback(() => {
        setZoom(initialZoom)
    }, [])

    const revertMapToInitialState = useCallback(() => {
        if (selectedParkMap?.region?.south) {
            setCoords(selectedParkMap.region.center)
            setBounds(
                new google.maps.LatLngBounds(
                    { lat: selectedParkMap.region.south, lng: selectedParkMap.region.west },
                    { lat: selectedParkMap.region.north, lng: selectedParkMap.region.east },
                ),
            )
            setZoom(selectedParkMap.region.zoom)
            setIsRegionChanged(false)
        }
    }, [selectedParkMap])

    return (
        <ParkMapViewerContext.Provider
            value={{
                isLoaded,
                map,
                setMap,
                mapType,
                setMapType,
                zoom,
                setZoom,
                resetZoom,
                coords,
                setCoords,
                bounds,
                setBounds,
                markers,
                setMarkers,
                isRegionChanged,
                setIsRegionChanged,
                revertMapToInitialState,
                showOverlayRegion,
                setShowOverlayRegion,
                categories,
                setCategories,
                poisToDisplay,
                setPoisToDisplay,
                overlayIsVisible,
                setOverlayVisibility,
            }}
        >
            {children}
        </ParkMapViewerContext.Provider>
    )
}

export default ParkMapViewerProvider
export const useParkMapViewerContext = () => useContext(ParkMapViewerContext)
