import { useField } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import styled, { DefaultTheme, useTheme } from 'styled-components'
import { ColorResult } from 'react-color'

import ActionButton from 'uiComponents/buttons'
import { Headline } from 'uiComponents/typography'
import { ColorPickerPopup } from 'uiComponents/colorPicker'

const Color = styled.div`
    width: 0.5em;
    height: 1.5em;
    border-radius: 0.125em;
    background-color: ${(props) => props.color};
`

interface ColorButtonProps {
    isSelected: boolean
    theme: DefaultTheme
}

const ColorButton = styled.div`
    padding: ${(props: ColorButtonProps) => (props.isSelected ? 0.25 : 0.3125)}em;
    border: ${(props: ColorButtonProps) =>
        props.isSelected
            ? `0.125em solid ${props.theme.colors.boyBlue}`
            : `0.0625em solid ${props.theme.colors.border}`};
    border-radius: 0.25em;
    margin-right: 0.25em;
    cursor: pointer;
    &:hover {
        background: ${(props) => props.theme.colors.background};
    }
`

interface ColorSelectorProps {
    field: string
}

const ColorsRowSelector = ({ field }: ColorSelectorProps) => {
    const theme = useTheme()
    const [{ value }, {}, { setValue }] = useField(field)
    const [Colors, setColors] = useState<string[]>([
        theme.colors.sun,
        theme.colors.boyBlue,
        theme.colors.tiger,
        theme.colors.sunrise,
        theme.colors.emeraldShades[50],
        theme.colors.textDark,
        theme.colors.convious,
    ])
    const [newColor, setNewColor] = useState<string>('#FFFFFF')
    const [pickerVisible, setPickerVisible] = useState<boolean>(false)
    const pickerWrapper = useRef<HTMLDivElement>(null)

    const onColorSelect = (color: string) => setValue(color)

    useEffect(() => {
        if (value && !Colors.includes(value)) {
            setColors((colors) => {
                return [value, ...colors]
            })
        }
        document.addEventListener('click', handleClickOutsidePicker, true)
        return () => {
            document.removeEventListener('click', handleClickOutsidePicker, true)
        }
    }, [])

    const handleClickOutsidePicker = (event: MouseEvent) => {
        if (pickerWrapper.current && event.target && !pickerWrapper.current.contains(event.target as Node)) {
            setPickerVisible(false)
        }
    }

    const handleAcceptSelectedColor = () => {
        setColors((colors) => {
            return colors.map((col, i) => (i === 0 && newColor ? newColor : col))
        })
        setPickerVisible(false)
        onColorSelect(newColor)
    }

    const handleChangeComplete = (color: ColorResult) => {
        setNewColor(color.hex)
    }

    return (
        <div>
            <Headline size={6} style={{ margin: '0 0 0.25em 0' }}>
                Category Colour
            </Headline>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {Colors.map((color) => (
                    <ColorButton isSelected={color === value} key={color} onClick={() => onColorSelect(color)}>
                        <Color color={color} />
                    </ColorButton>
                ))}
                <ActionButton secondary kind="action" style={{ border: 'none' }} onClick={() => setPickerVisible(true)}>
                    + Other
                </ActionButton>
                <div ref={pickerWrapper}>
                    <ColorPickerPopup
                        visible={pickerVisible}
                        top={pickerWrapper.current?.offsetTop ?? 0}
                        left={pickerWrapper.current?.offsetLeft ?? 0}
                        selectedColor={newColor}
                        handleChangeComplete={handleChangeComplete}
                        onAcceptSelectedColor={handleAcceptSelectedColor}
                        hide={() => setPickerVisible(false)}
                        showRecommendedColors
                        recommendedColors={Colors}
                    />
                </div>
            </div>
        </div>
    )
}

export default ColorsRowSelector
