import * as React from 'react'
import { ActionButton } from 'uiComponents/buttons'
import { ModalDialog, ModalDialogTitle, NavigationSection } from 'uiComponents/popups/modal'
import { ChartDataLoader } from 'uiComponents/loaders'
import { NumberInput, TextInput, TextArea } from 'uiComponents/input'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { format } from 'date-fns'
import { GenerateReservationCodespayload } from 'venue/bookingCodes/bookingCodesService'
import { useGetFullProductsAndArticlesListForSelect } from 'products/redux'
import { Combobox } from 'uiComponents/input/combobox'
import { parseDate } from 'utils'

interface GenerateCodesDialogProps {
    accountSlug: string
    loading: boolean
    onCancel: () => void
    onConfirm: (payload: GenerateReservationCodespayload) => void
}

export function GenerateCodesDialog(props: GenerateCodesDialogProps) {
    const [name, setName] = React.useState<string>('')
    const [prefix, setPrefix] = React.useState<string>('')
    const [note, setNote] = React.useState<string>('')
    const [size, setSize] = React.useState<string>('')
    const [validFrom, setValidFrom] = React.useState<string>('')
    const [validTo, setValidTo] = React.useState<string>('')
    const [products, setProducts] = React.useState<string[]>([])
    const [validate, setValidate] = React.useState<boolean>(false)
    const [disabledSave, setDisabledSave] = React.useState<boolean>(false)

    const validName = () => !!name.trim()
    const validSize = () => !!size && Number(size) >= 1 && Number(size) <= 100000
    const validFromDate = () => !!validFrom
    const validToDate = () => !!validTo && validFrom <= validTo
    const validProducts = () => !!products.length
    const categoriesAndArticles = useGetFullProductsAndArticlesListForSelect()
    const formValid = () => {
        return validName() && validSize() && validFromDate() && validToDate() && validProducts()
    }

    const onSave = () => {
        setValidate(true)
        if (!formValid() || disabledSave) {
            return
        }
        setDisabledSave(true)
        const payload = {
            name,
            validFrom,
            validTo,
            prefix: prefix.trim(),
            barcodeScanningNote: note.trim(),
            amount: Number(size),
            articles: products,
        }
        props.onConfirm(payload)
    }

    return (
        <ModalDialog onDismiss={props.onCancel} interactive fromTop="10%">
            {props.loading && <ChartDataLoader />}
            <div style={{ width: '25em' }}>
                <ModalDialogTitle>Generate barcode batch</ModalDialogTitle>
                <FormItem htmlFor="batch-name">
                    <FormItemName>Name</FormItemName>
                    <TextInput
                        id="batch-name"
                        type="text"
                        placeholder="Code batch name"
                        maxLength={100}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        status={validate && !validName() ? 'error' : 'normal'}
                        block
                    />
                    <ValidationMessage className={validate && !validName() ? 'validation-message-visible' : ''}>
                        Please enter the name
                    </ValidationMessage>
                </FormItem>
                <FormItem htmlFor="batch-products" style={{ paddingBottom: '0.625em' }}>
                    <FormItemName>Products</FormItemName>
                    <Combobox
                        id="batch-products"
                        name="batch-products"
                        options={categoriesAndArticles}
                        value={products}
                        onChange={(v: any) => setProducts(v as string[])}
                        placeholder="Select products"
                        status={validate && !validProducts() ? 'error' : 'normal'}
                    />
                    <ValidationMessage
                        className={validate && !validProducts() ? 'validation-message-visible' : ''}
                        style={{ top: '0.2em' }}
                    >
                        Please select at least one product
                    </ValidationMessage>
                </FormItem>
                <FormItem htmlFor="batch-start-date" style={{ paddingBottom: '0.625em' }}>
                    <FormItemName>Valid from</FormItemName>
                    <DatePicker
                        id="batch-start-date"
                        date={validFrom ? parseDate(validFrom) : null}
                        onChange={(value: Date) => setValidFrom(value ? format(value, 'yyyy-MM-dd') : '')}
                        status={validate && !validFromDate() ? 'error' : 'normal'}
                    />
                    <ValidationMessage
                        className={validate && !validFromDate() ? 'validation-message-visible' : ''}
                        style={{ top: '0.2em' }}
                    >
                        Please select the code valid from date
                    </ValidationMessage>
                </FormItem>
                <FormItem htmlFor="batch-end-date" style={{ paddingBottom: '0.625em' }}>
                    <FormItemName>Valid to</FormItemName>
                    <DatePicker
                        id="batch-end-date"
                        date={validTo ? parseDate(validTo) : null}
                        onChange={(value: Date) => setValidTo(value ? format(value, 'yyyy-MM-dd') : '')}
                        status={validate && !validToDate() ? 'error' : 'normal'}
                        lowerBoundary={validFrom ? parseDate(validFrom) : null}
                    />
                    <ValidationMessage
                        className={validate && !validToDate() ? 'validation-message-visible' : ''}
                        style={{ top: '0.2em' }}
                    >
                        Please select the code valid to date (cannot be before valid from)
                    </ValidationMessage>
                </FormItem>
                <FormItem htmlFor="batch-amount">
                    <FormItemName>Batch size</FormItemName>
                    <NumberInput
                        id="batch-amount"
                        integerOnly
                        block
                        value={size}
                        min={1}
                        max={100000}
                        placeholder="1000"
                        status={validate && !validSize() ? 'error' : 'normal'}
                        onChange={(e) => setSize(e.target.value)}
                    />
                    <ValidationMessage className={validate && !validSize() ? 'validation-message-visible' : ''}>
                        Please enter a number between 1 and 100000
                    </ValidationMessage>
                </FormItem>
                <FormItem htmlFor="batch-prefix">
                    <FormItemName>Prefix (optional)</FormItemName>
                    <TextInput
                        id="batch-prefix"
                        type="text"
                        placeholder="Code prefix"
                        maxLength={5}
                        value={prefix}
                        onChange={(e) => setPrefix(e.target.value)}
                        block
                    />
                    <ValidationMessage />
                </FormItem>
                <FormItem htmlFor="batch-note">
                    <FormItemName>Note (optional)</FormItemName>
                    <TextArea
                        id="batch-note"
                        value={note}
                        placeholder="Code note"
                        onChange={(e) => setNote(e.target.value)}
                        maxLength={1000}
                        block
                    />
                    <ValidationMessage />
                </FormItem>
                <NavigationSection>
                    <ActionButton size="large" secondary onClick={props.onCancel}>
                        Cancel
                    </ActionButton>
                    <ActionButton id="generate-barcodes" size="large" onClick={onSave}>
                        Save
                    </ActionButton>
                </NavigationSection>
            </div>
        </ModalDialog>
    )
}
