import React, { useState, useEffect, useRef } from 'react'
import { MessageKind } from 'uiComponents/messages'
import { BookingCodesService, UploadDetails } from 'venue/bookingCodes/bookingCodesService'
import UploadPage from 'venue/bookingCodes/uploadPage'
import { LoginService } from 'http/loginService'
import { NavigationBack } from 'uiComponents/navigation/navigationBack'
import { History } from 'history'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { withFeatures } from 'features'
import { useAccountChange } from 'reactUtils'

interface UploadCouponsPageProps {
    accountSlug: string
    bookingCodesService: BookingCodesService
    backofficeEndpoint: string
    loginService: LoginService
    history: History
    navigation: Navigation
    match: RouteMatch<{ poolId: string }>
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function UploadCouponsPage(props: UploadCouponsPageProps) {
    const busyRef = useRef(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [uploadsHistoryList, setUploadsHistoryList] = useState<UploadDetails[]>([])

    const callHistoryListEndpoint = async () => {
        const data = await props.bookingCodesService.getCouponsUploadHistoryList(
            props.accountSlug,
            props.match.params.poolId,
        )
        setUploadsHistoryList(data)
    }

    async function getUploadHistoryList() {
        setLoading(true)
        busyRef.current = true
        try {
            await callHistoryListEndpoint()
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            setLoading(false)
            busyRef.current = false
        }
    }

    async function refreshHistoryList() {
        try {
            await callHistoryListEndpoint()
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please reload the page.')
        }
    }

    useEffect(() => {
        getUploadHistoryList()
        const interval = setInterval(() => {
            if (!busyRef.current) {
                refreshHistoryList()
            }
        }, 30000)
        return () => clearInterval(interval)
    }, [])

    useAccountChange(props.accountSlug, () => navigateBack())

    const onUpload = async (file: File) => {
        busyRef.current = true
        return await props.bookingCodesService.uploadCouponsCodes(file, props.accountSlug, props.match.params.poolId)
    }

    const reloadUploadsHistoryList = async () => {
        await getUploadHistoryList()
    }

    const getCsvDownloadHref = () => {
        const csvRows = [
            'name,code,valid_from,valid_to,percent_reduction,flat_reduction,reseller,max_uses,remainder_usable,validity_period_from,validity_period_to',
            'Example percentage discount,23423423423,2020-05-28,2020-12-31, 20.0,,,,0',
            'Example flat discount,23423423421,2020-05-28,2020-12-31,,7.50,,1,1',
            'Discount from the partner,23423423422,2020-05-28,2020-12-31,,5.00,Partner reseller,1,1',
            'Limited-use discount,C3423423423,2020-05-28,2020-12-31,15.0,,,25,0',
            'Example with validity period,23423423423,2020-05-28,2020-12-31,,5.00,,,0,2020-12-25,2020-12-31',
        ]
        const csv = csvRows.join('\n')
        return 'data:text/csv;charset=utf-8,' + encodeURI(csv)
    }

    const navigateBack = () => {
        props.history.push(`/account/${props.accountSlug}/venue/codes/coupons/list/?${location.search}`)
    }

    return (
        <>
            <NavigationBack onClick={navigateBack} text="Back to coupon pools list" topOffset="-5em" />
            <UploadPage
                accountSlug={props.accountSlug}
                uploadsHistoryList={uploadsHistoryList}
                bookingCodesService={props.bookingCodesService}
                loginService={props.loginService}
                backofficeEndpoint={props.backofficeEndpoint}
                uploadType="coupons"
                exampleCSVHref={getCsvDownloadHref()}
                exampleFileName="coupons_upload_example"
                loading={loading}
                onUpload={onUpload}
                reloadUploadsHistoryList={reloadUploadsHistoryList}
                onUploadError={() => (busyRef.current = false)}
                replaceMessages={props.replaceMessages}
                removeAllMessages={props.removeAllMessages}
            />
        </>
    )
}

export default withFeatures(withNavigation(UploadCouponsPage))
