import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-typed'
import { History } from 'history'
import { User } from 'auth/state'
import { Profile } from 'settings/schema'
import NoAvatar from './no-avatar.png'
import { withUserAndProfile } from 'hocs'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome'
import { NotificationsServiceContext } from 'settings/context'
import { withMessages, MessageProps } from 'hocs'
import { Messages } from 'uiComponents/messages'
import { withFeatures } from 'features'
import { CONTAINER_BOX_SHADOW } from 'uiComponents/settingsContainer'

interface AvatarProps {
    avatarUrl: string | null
}

interface UserDropdownStyleProps {
    expanded: boolean
}

const Avatar = styled.div<AvatarProps>`
    width: 40px;
    height: 40px;
    ${(props) =>
        props.avatarUrl
            ? 'background: url(' + props.avatarUrl + ') no-repeat center'
            : 'background: url(' + NoAvatar + ') no-repeat center'};
    background-size: cover;
    border-radius: 50%;
`
const UserDropdownContainer = styled.div<AvatarProps>`
    min-height: 7.5em;
    font-size: 0.875em;
    font-weight: 300;
    position: absolute;
    top: 1.3em;
    right: 1.7em;
    padding: 0.7em 1.2em 0.6em 2.2em;
    border-radius: 1em 0.43em 1em 6em;
    box-shadow: none;
    overflow: hidden;
    z-index: 0;
    transition: box-shadow 0.3s ease-in, border-radius 0.3s 0.2s ease-in, z-index 0.5s step-end;

    &.expanded {
        z-index: 500000;
        border-radius: 0.43em;
        box-shadow: ${CONTAINER_BOX_SHADOW};
        transition: box-shadow 0.3s 0.3s ease-in, z-index 0.5s step-start;
    }

    @media (max-width: ${(props) => props.theme.breakPoints.md}) {
        top: ;
    }
`
const UserDisplayBox = styled.div<AvatarProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    visibility: hidden;

    &.visible {
        visibility: visible;
    }
`
const UserDisplayBoxMinimized = styled(UserDisplayBox)<AvatarProps>`
    position: absolute;
    z-index: 1;
    right: 0;
    padding-right: 1.2em;
`
const Name = styled.div`
    opacity: 0;
    transition: all 0.5s;
    min-width: 4.3em;
    &.visible {
        opacity: 1;
    }
`
const MenuList = styled.ul`
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    visibility: hidden;

    &.expanded {
        visibility: visible;
    }

    > li {
        line-height: 1.4em;
        vertical-align: middle;
        font-weight: lighter;
        min-width: 8.5em;
    }
`

const PopupBackground = styled.div<UserDropdownStyleProps>`
    background: #fff;
    border-radius: 50%;
    box-shadow: ${CONTAINER_BOX_SHADOW};
    height: ${(props) => (props.expanded ? '30em' : '0')};
    width: ${(props) => (props.expanded ? '45em' : '0')};
    position: absolute;
    top: -6em;
    right: -4em;
    transition: all 0.6s ease-in;
`

const StyledLink = styled(Link)`
    position: relative;
    color: ${(props) => props.theme.colors.boyBlue};
    transition: color 0.1s ease-in;
    text-decoration: none;
    display: block;
    padding: 0.375em 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in, visibility 0s 0.3s ease-in;

    &:hover {
        color: ${(props) => props.theme.colors.textDark};
    }

    &.expanded {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.4s 0.3s ease-in, visibility 0.5s ease-in;
    }
`
const Notification = styled.div<UserDropdownStyleProps>`
    float: right;
    font-size: 0.5em;
    height: 2em;
    width: 2em;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.status.error};
    color: ${(props) => props.theme.colors.white};
    font-weight: 500;
    margin-left: 1em;
    vertical-align: text-bottom;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    top: -0.5em;
    opacity: 0;
    visiblity: hidden;
    transition: opacity 0.5s ease-in, visibility 0s 0.5s ease-in;

    &.visible {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.5s ease-in, visibility 0.5s ease-in;
    }

    @media (max-width: ${(props) => props.theme.breakPoints.md}) {
        display: none;
    }
`
const DropdownCaret = styled(FontAwesomeIcon)`
    margin-left: 0.5em;
    margin-right: 0.5em;
`
const NotificationsBell = styled.div`
    position: absolute;
    display: flex;
    left: -1.5em;
    font-size: 1.5rem;
    opacity: 0;
    transition: all 0.5s;

    &.visible {
        opacity: 1;
        transition: all 0.5s 0.5s;
    }
`

const RedDot = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.convious};

    &.inList {
        top: 0.5em;
        left: -0.6em;
        height: 6px;
        width: 6px;
    }
`

interface UserDisplayProps {
    profile: Profile
    user: User
    logout: () => void
    accountSlug: string
    logoutOnly?: boolean
    history: History
    disregardLocationState: boolean
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function UserDisplay(props: UserDisplayProps & MessageProps) {
    const popup = React.useRef(null)
    const expandedRef = React.useRef(false)
    const notificationsService = React.useContext(NotificationsServiceContext)
    const [expanded, setExpanded] = React.useState<boolean>(false)
    const [newNotificationsNo, setNewNotificationsNo] = React.useState<number>(0)

    React.useEffect(() => {
        document.addEventListener('click', outsideClick, false)
        return () => document.removeEventListener('click', outsideClick, false)
    }, [])

    const getData = async () => {
        try {
            const locationParts = props.history.location.pathname.split('/')
            if (locationParts.indexOf('feed') > -1) {
                return
            }
            const storageName = props.user.isAdmin
                ? 'notificationsLastSeen'
                : `${props.accountSlug}_notificationsLastSeen`
            const lastSeen = localStorage.getItem(storageName)
            const data = await notificationsService.getNotificationsForAccount(props.accountSlug, lastSeen)
            setNewNotificationsNo(data.length)
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Unable to check the number of new notifications.')
        }
    }

    React.useEffect(() => {
        if (
            !!props.user.resellerId ||
            props.logoutOnly ||
            !props.user.accounts.find((a) => a.slug === props.accountSlug)
        ) {
            return
        }
        getData()
    }, [props.accountSlug])

    React.useEffect(() => {
        if (!!props.user.resellerId || props.logoutOnly) {
            return
        }
        const locationParts = props.history.location.pathname.split('/')
        if (locationParts.indexOf('feed') > -1) {
            setNewNotificationsNo(0)
        }
    }, [props.history.location.pathname])

    const outsideClick = (ev: MouseEvent) => {
        if (
            !!expandedRef.current &&
            !!popup.current &&
            // @ts-ignore: Object is possibly 'null'.
            !!popup.current.contains &&
            // @ts-ignore: Object is possibly 'null'.
            !popup.current.contains(ev.target as Node)
        ) {
            setExpanded(false)
        }
    }

    const expand = (e: React.MouseEvent<any>) => {
        e.preventDefault()
        expandedRef.current = true
        setExpanded(true)
    }

    const logout = (e: React.MouseEvent<any>) => {
        e.preventDefault()
        props.logout()
    }

    const redirectToNotifications = (e: React.MouseEvent) => {
        e.stopPropagation()
        props.history.push(`/account/${accountSlug}/notifications/feed/`)
    }

    const { user, profile, accountSlug, logoutOnly } = props
    const avatarUrl = profile ? profile.avatarUrl : null
    const firstName = profile ? profile.firstName : null
    const username = firstName || user.name || user.username
    const profileNotifications = profile.emailConfirmed ? 0 : 1
    const notificationsFeature = !user.resellerId
    const resellerPages = !!user.resellerId && user.accounts.length > 1

    return (
        <>
            <Messages messages={props.messages} hideMessage={props.hideMessage} />
            <UserDropdownContainer ref={popup} className={expanded ? 'expanded' : ''} avatarUrl={avatarUrl}>
                <PopupBackground expanded={expanded} />
                <UserDisplayBoxMinimized avatarUrl={avatarUrl} onClick={expand} className={expanded ? '' : 'visible'}>
                    {notificationsFeature && !logoutOnly && !!user && !user.resellerId && (
                        <NotificationsBell
                            className={expanded ? '' : 'visible'}
                            onClick={(e) => redirectToNotifications(e)}
                        >
                            <FontAwesomeIcon icon={faBell as IconProp} />
                            {newNotificationsNo > 0 && <RedDot id="unread-notification-notice" />}
                        </NotificationsBell>
                    )}
                    <Name className={expanded ? '' : 'visible'}>My profile</Name>
                    <DropdownCaret icon={faCaretDown} />
                    <Avatar avatarUrl={avatarUrl} />
                    <Notification
                        className={profileNotifications && !expanded && !logoutOnly ? 'visible' : ''}
                        expanded={expanded}
                        style={{ position: 'absolute' }}
                    >
                        {profileNotifications}
                    </Notification>
                </UserDisplayBoxMinimized>
                <MenuList className={expanded ? 'expanded' : ''}>
                    <li style={{ marginBottom: '1em' }}>
                        <UserDisplayBox
                            avatarUrl={avatarUrl}
                            onClick={(e) => {
                                e.preventDefault()
                                setExpanded(false)
                            }}
                            className={expanded ? 'visible' : ''}
                        >
                            <Name className={expanded ? 'visible' : ''} style={{ minWidth: '5em' }}>
                                Hello <strong>{username}</strong>
                            </Name>
                            <DropdownCaret icon={faCaretUp} />
                            <Avatar avatarUrl={avatarUrl} />
                        </UserDisplayBox>
                    </li>
                    {!logoutOnly && (
                        <>
                            <li>
                                <StyledLink
                                    to={`${resellerPages ? '/resellers' : ''}/personal_settings/personal`}
                                    className={expanded ? 'expanded' : ''}
                                    onClick={() => setExpanded(false)}
                                >
                                    My profile
                                    {!!profileNotifications && !!expanded && <RedDot className="inList" />}
                                </StyledLink>
                            </li>
                            {!!user && !user.resellerId && (
                                <li>
                                    <StyledLink
                                        to={`/account/${accountSlug}/notifications/${
                                            notificationsFeature ? 'feed' : 'settings'
                                        }`}
                                        className={expanded ? 'expanded' : ''}
                                        onClick={() => setExpanded(false)}
                                    >
                                        Notification settings
                                        {notificationsFeature && newNotificationsNo > 0 && (
                                            <RedDot className="inList" />
                                        )}
                                    </StyledLink>
                                </li>
                            )}
                        </>
                    )}
                    <li>
                        <StyledLink to="" onClick={logout} className={expanded ? 'expanded' : ''}>
                            Log out
                        </StyledLink>
                    </li>
                </MenuList>
            </UserDropdownContainer>
        </>
    )
}

export default withFeatures(withMessages(withUserAndProfile(UserDisplay)))
