import React from 'react'

const DataItem: React.FC<{ name: string; className: string }> = ({ name, children, className }) => (
    <div className={className}>
        <h6>{name}</h6>
        <div className="body1 light">{children}</div>
    </div>
)

export default DataItem
