import * as React from 'react'
import TimeseriesBarChart from 'uiComponents/charts/timeseriesBarChart'
import { StatsService } from 'http/statsService'
import { DateRange } from 'dateRanges'
import { format, startOfToday, addDays } from 'date-fns'
import { TimeseriesBarChartSource, chartColors } from './forecastChartSource'
import {
    areDateRangeDatesEqual,
    mapTimeseriesForTooltip,
    getTooltipRow,
    getTooltipXAxisLabel,
    getTooltipHeader,
    getTooltipFooter,
    getMarker,
} from 'reports/helpers'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { DataPoint, DataSeries, ForecastTimeSeriesStats, dummyTimeSeries } from 'reports/schema'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import { CustomDataItem } from 'uiComponents/charts/styleComponents'
import { weatherIconMap } from 'reports/constants'
import { appleTrademarkIcon } from 'reports/weatherIcons'
import { convertTimeFormat } from 'utils'
import { getPartnersUnitTemperature } from 'utils/unitsSystem'
import { State } from 'store'
import { connect } from 'react-redux'
import { getIsUSPartner } from 'auth/selectors'

const forecastValidationMap = {
    venue_is_closed: 'Venue is closed.',
    some_locations_are_closed: 'Some locations are closed.',
    not_enough_data: 'Not enough data </br>within current setup or season.</br>Forecast accuracy may be lower.',
    all_good: '',
}

interface VisitorsChartProps {
    dateRange: DateRange
    statsService: StatsService
    accountSlug: string
    chart: TimeseriesBarChartSource
    forecastRange: number
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    getCurrency: (accountSlug: string) => Currency
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    isUSPartner: boolean
}

interface VisitorsChartState {
    forecastValidation: DataSeries
    weatherIcon: DataSeries
    weatherTemperature: DataSeries
    weatherRainChance: DataSeries
    weatherRainIntensity: DataSeries
    mappedSalesPrevRangeSoFar: DataPoint[]
    mappedActualSales: DataPoint[]
    mappedUpperBound: DataPoint[]
    mappedLowerBound: DataPoint[]
    todayForecastSales: string
    timeNow: string
    loading: boolean
}

class VisitorsChart extends React.Component<VisitorsChartProps, VisitorsChartState> {
    _isMounted = false
    _lastRequest?: number = undefined

    constructor(props: VisitorsChartProps) {
        super(props)
        this.state = {
            forecastValidation: { columns: [], data: [] },
            weatherIcon: { columns: [], data: [] },
            weatherTemperature: { columns: [], data: [] },
            weatherRainChance: { columns: [], data: [] },
            weatherRainIntensity: { columns: [], data: [] },
            mappedSalesPrevRangeSoFar: [],
            mappedActualSales: [],
            mappedUpperBound: [],
            mappedLowerBound: [],
            todayForecastSales: '-',
            timeNow: '',
            loading: false,
        }
    }

    async componentDidMount() {
        this._isMounted = true
        await this.getData()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    async componentDidUpdate(prevProps: VisitorsChartProps) {
        if (
            !areDateRangeDatesEqual(prevProps.dateRange, this.props.dateRange) ||
            prevProps.accountSlug !== this.props.accountSlug
        ) {
            await this.getData()
        }
    }

    setTime = () => {
        const today = new Date()
        const hours = today.getHours().toString()
        const minutes = today.getMinutes().toString()
        const timeNow = `${hours.length < 2 ? '0' + hours : hours}:${minutes.length < 2 ? '0' + minutes : minutes}`
        this.setState({ timeNow })
    }

    getData = async () => {
        const requestTime = new Date().valueOf()
        this._lastRequest = requestTime

        this.setState({ loading: true })
        const dateFrom = format(this.props.chart.from(), 'yyyy-MM-dd')
        const dateTo = format(this.props.chart.to(), 'yyyy-MM-dd')
        const forecastFrom = format(startOfToday(), 'yyyy-MM-dd')
        const forecastTo = format(addDays(startOfToday(), this.props.forecastRange - 1), 'yyyy-MM-dd')
        this.setTime()
        try {
            const data = await this.props.statsService.getForecastTimeseriesStats(
                this.props.accountSlug,
                dateFrom,
                dateTo,
                forecastFrom,
                forecastTo,
            )
            if (this._lastRequest !== requestTime) {
                return
            }
            const upperBound =
                data.estimateUpperBound.timeSeries.length > 0 ? data.estimateUpperBound.timeSeries[0] : dummyTimeSeries
            const lowerBound =
                data.estimateLowerBound.timeSeries.length > 0 ? data.estimateLowerBound.timeSeries[0] : dummyTimeSeries
            const mappedUpperBound = {
                label: '',
                points: upperBound.points.map((p, i) => ({
                    timestamp: p.timestamp,
                    value: p.value - lowerBound.points[i].value,
                })),
            }
            this.props.chart.formatBarSeries([data.actualSales.timeSeries[0], data.estimateSales.timeSeries[0]])
            this.props.chart.formatLineSeries([data.estimateSales.timeSeries[0], lowerBound, mappedUpperBound])
            this.setData(data)
        } catch {
            this.props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Forecast chart could not be loaded, please try again later.',
            )
            this.setState({ loading: false })
        }
    }

    setData = (data: ForecastTimeSeriesStats) => {
        const todayForecastSales = this.getExpectedToSell(data)
        const granularity = this.props.chart.granularity
        const xAxisCategories = this.props.chart.props().xAxisDataRaw
        const mappedActualSales =
            data.actualSales.timeSeries.length > 0
                ? mapTimeseriesForTooltip(xAxisCategories, data.actualSales.timeSeries[0].points, granularity)
                : []
        const mappedUpperBound =
            data.estimateUpperBound.timeSeries.length > 0
                ? mapTimeseriesForTooltip(xAxisCategories, data.estimateUpperBound.timeSeries[0].points, granularity)
                : []
        const mappedLowerBound =
            data.estimateLowerBound.timeSeries.length > 0
                ? mapTimeseriesForTooltip(xAxisCategories, data.estimateLowerBound.timeSeries[0].points, granularity)
                : []
        const mappedSalesPrevRangeSoFar =
            data.actualSalesSoFar.timeSeries.length > 0
                ? mapTimeseriesForTooltip(xAxisCategories, data.actualSalesSoFar.timeSeries[0].points, granularity)
                : []
        const forecastValidation = data.forecastValidation.dataSeries
            ? data.forecastValidation.dataSeries
            : { columns: [], data: [] }
        const weatherIcon = data.weatherIcon.dataSeries ? data.weatherIcon.dataSeries : { columns: [], data: [] }
        const weatherTemperature = data.weatherTemperature.dataSeries
            ? data.weatherTemperature.dataSeries
            : { columns: [], data: [] }
        const weatherRainChance = data.weatherRainChance.dataSeries
            ? data.weatherRainChance.dataSeries
            : { columns: [], data: [] }
        const weatherRainIntensity = data.weatherRainIntensity.dataSeries
            ? data.weatherRainIntensity.dataSeries
            : { columns: [], data: [] }

        if (this._isMounted) {
            this.setState({
                forecastValidation,
                weatherIcon,
                weatherTemperature,
                weatherRainChance,
                weatherRainIntensity,
                todayForecastSales,
                mappedActualSales,
                mappedUpperBound,
                mappedLowerBound,
                mappedSalesPrevRangeSoFar,
                loading: false,
            })
        }
    }

    getExpectedToSell = (data: ForecastTimeSeriesStats) => {
        const today = format(startOfToday(), 'yyyy-MM-dd')
        const estimateHighestData = data.estimateUpperBound.timeSeries[0].points.find(
            (p) => format(new Date(p.timestamp), 'yyyy-MM-dd') === today,
        )
        const estimateHighest = estimateHighestData ? estimateHighestData.value : 0
        const estimateLowestData = data.estimateLowerBound.timeSeries[0].points.find(
            (p) => format(new Date(p.timestamp), 'yyyy-MM-dd') === today,
        )
        const estimateLowest = estimateLowestData ? estimateLowestData.value : 0
        const todayActualData = data.actualSales.timeSeries[0].points.find(
            (p) => format(new Date(p.timestamp), 'yyyy-MM-dd') === today,
        )
        const todayActual = todayActualData ? todayActualData.value : 0
        return `${this.roundExpectedValue(Math.max(0, estimateLowest - todayActual))} - ${this.roundExpectedValue(
            Math.max(1, estimateHighest - todayActual),
        )}`
    }

    roundExpectedValue = (value: number) => {
        if (value > 1000) {
            return Math.round(value / 100) * 100
        }
        if (value > 100) {
            return Math.round(value / 10) * 10
        }
        return value
    }

    getWeatherForecastValueForDate = (date: string, dataSeries: string) => {
        if (date) {
            const index = this.state[dataSeries].columns.indexOf('weather_forecast')
            const data = (this.state[dataSeries].data as string[][]).find((v) => v.indexOf(date) > -1)
            return data ? data[index] : ''
        }
        return ''
    }

    getWeatherLine = (date: string | null) => {
        if (date) {
            const weather = this.getWeatherForecastValueForDate(date, 'weatherIcon')
            const temp = this.getWeatherForecastValueForDate(date, 'weatherTemperature')
            const tempString = `<span style="margin-left:5px">${getPartnersUnitTemperature(
                Number(temp),
                this.props.isUSPartner,
                true,
            )}</span>`
            const showPrecipitation = ['rain', 'sleet', 'snow'].indexOf(weather) > -1
            let rainChanceString = ''
            let rainIntensityString = ''
            if (showPrecipitation) {
                const rainChance = this.getWeatherForecastValueForDate(date, 'weatherRainChance')
                const rainIntensity = this.getWeatherForecastValueForDate(date, 'weatherRainIntensity')
                rainChanceString = `,<span style="margin-left:10px">${Math.round(Number(rainChance) * 100)}%</span>`
                rainIntensityString = `,<span style="margin-left:10px">${
                    Math.round(Number(rainIntensity) * 100) / 100
                }mm</span>`
            }

            return `
        <table style="width:100%">
          <tr>
            <td>${getMarker('')} ${appleTrademarkIcon} Weather:</td>
            <td style="text-align:right">
              ${weatherIconMap[weather] + tempString + rainChanceString + rainIntensityString}
            </td>
          </tr>
        </table>
      `
        }
        return ''
    }

    getValidation = (date: string | null) => {
        if (date) {
            const validationIndex = this.state.forecastValidation.columns.indexOf('forecast_validation')
            const data = (this.state.forecastValidation.data as string[][]).find((v) => v.indexOf(date) > -1)
            const validationSlug = data ? data[validationIndex] : 'all_good'
            return forecastValidationMap[validationSlug]
        }
        return forecastValidationMap.all_good
    }

    chartTooltipFormatter = (params: TooltipParams[]) => {
        const salesPoint = this.state.mappedActualSales[params[0].dataIndex]
        const date = salesPoint ? format(new Date(salesPoint.timestamp), 'yyyy-MM-dd') : null
        const weekday = format(new Date(), 'eeee')
        const actualSales = params.find((p) => p.seriesName === 'Sold already')
        const xAxisLabel = salesPoint
            ? getTooltipXAxisLabel(salesPoint.timestamp, this.props.chart.granularity)
            : params[0].axisValue

        let tooltip = getTooltipHeader(xAxisLabel)

        if (date && date >= format(startOfToday(), 'yyyy-MM-dd')) {
            const upperBound = this.state.mappedUpperBound[params[0].dataIndex]
            const lowerBound = this.state.mappedLowerBound[params[0].dataIndex]
            const soldPreviously = this.state.mappedSalesPrevRangeSoFar[params[0].dataIndex - 7]
            const forecastParams = params.find((p) => p.seriesName === 'Forecast')
            const validation = this.getValidation(date)

            tooltip += getTooltipRow([
                `${getMarker(chartColors['forecastLine'])} Forecast:`,
                forecastParams ? this.roundExpectedValue(Number(forecastParams.value)) : '0',
            ])
            const forecastBoundMarker = getMarker(chartColors['forecastBoundTooltip'])
            tooltip += getTooltipRow([
                `${forecastBoundMarker} Min forecast:`,
                lowerBound ? this.roundExpectedValue(lowerBound.value) : '0',
            ])
            tooltip += getTooltipRow([
                `${forecastBoundMarker} Max forecast:`,
                upperBound ? this.roundExpectedValue(upperBound.value) : '0',
            ])
            tooltip += getTooltipRow([
                `${getMarker(chartColors['actualSales'])} Sold already:`,
                actualSales ? actualSales.value : '',
            ])
            if (params[0].dataIndex < this.props.forecastRange + 7) {
                tooltip += getTooltipRow([
                    `${getMarker(chartColors['salesPrevRange'])} Sold by ${convertTimeFormat(
                        this.state.timeNow,
                    )} last ${weekday}:`,
                    soldPreviously ? soldPreviously.value : '0',
                ])
                tooltip += this.getWeatherLine(date)
            }
            tooltip += getTooltipFooter()

            if (validation) {
                tooltip += `<div style="background-color:rgba(243,209,78,0.5);border:1px solid #f3d14e;border-radius:6px;padding:5px 10px;\
        line-height:15px;margin:10px;">${validation}</div>`
            }
        } else {
            tooltip += getTooltipRow([
                `${getMarker(chartColors['actualSales'])} Sold:`,
                actualSales ? actualSales.value : '',
            ])
            tooltip += this.getWeatherLine(date)
            tooltip += getTooltipFooter()
        }

        return tooltip
    }

    onMouseOver = (params: TooltipParams) => {
        if (params.dataIndex > this.props.forecastRange - 1 && params.dataIndex < this.props.forecastRange + 7) {
            const lasPeriodDayIndex = params.dataIndex - 7
            this.props.chart.addHoverSeries(
                lasPeriodDayIndex,
                this.state.mappedSalesPrevRangeSoFar[lasPeriodDayIndex].value,
            )
        }
    }

    onMouseOut = (params: TooltipParams) => {
        if (params.dataIndex > this.props.forecastRange - 1 && params.dataIndex < this.props.forecastRange + 7) {
            this.props.chart.removeHoverSeries()
        }
    }

    render() {
        const legendData = this.props.chart.props().legendData
        const axisData = this.props.chart.props().xAxisData
        const series = this.props.chart.props().series
        const { loading } = this.state
        return (
            <div style={{ marginTop: '1em', position: 'relative' }}>
                {loading && <ChartDataLoader />}
                <CustomDataItem>
                    We expect to sell <span>{this.state.todayForecastSales}</span> tickets for today.
                </CustomDataItem>
                <TimeseriesBarChart
                    axisData={axisData}
                    series={series}
                    legendData={legendData}
                    legendWidth={525}
                    loading={loading}
                    tooltipFormatter={this.chartTooltipFormatter}
                    onMouseOver={this.onMouseOver}
                    onMouseOut={this.onMouseOut}
                    legendDisabled
                />
            </div>
        )
    }
}

function mapStateToProps(state: State) {
    return {
        isUSPartner: getIsUSPartner(state),
    }
}

export default connect(mapStateToProps)(withCurrency(VisitorsChart))
