import React from 'react'
import styled from 'styled-typed'
import { Col, Row } from 'uiComponents/flex'
import { SelectComponent, FormItem, SectionWrapper } from 'ticketTemplates/studio/imports'
import { SingleSelectOption } from 'uiComponents/input/singleSelect'
import { Locale } from 'admin/articleService'
import { UploadElement } from '../form/uploadElement'
import { ImagesServiceContext } from 'http/context'
import { MessageKind } from 'uiComponents/messages'
import { Translation } from 'ticketTemplates/ticketTemplatesService'
import { StudioDeleteIcon } from 'uiComponents/studio/icons/deleteIcon'

const AlignedCol = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

interface BannersSectionProps {
    accountSlug: string
    activeLanguage: string
    locales: Locale[]
    showErrors: boolean
    bannerFirstInfo: Translation | null
    bannerSecondInfo: Translation | null
    noFirstBanners: boolean
    noSecondBanners: boolean
    setBanner: (url: string, type: 'first' | 'second') => void
    handleLanguageChange: (value: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

export function BannersSection(props: BannersSectionProps) {
    const imagesService = React.useContext(ImagesServiceContext)
    const [languageOptions, setlanguageOptions] = React.useState<SingleSelectOption[]>([])

    React.useEffect(() => {
        const options = props.locales.map((l) => ({
            value: l.code,
            name: l.name,
        }))
        setlanguageOptions(options)
    }, [props.locales])

    type SelectType = 'select'
    type UploadType = 'upload'
    const translationLanguageField = {
        type: 'select' as SelectType,
        name: 'translation-language',
        label: 'Translation language',
        path: '',
        defaultValue: 'en',
        availableOptions: languageOptions,
    }
    const topBannerField = {
        name: 'top-banner-upload',
        type: 'upload' as UploadType,
        label: 'Upload top banner (recommended 2515x1085px)',
        path: '',
        defaultValue: '',
    }
    const bottomBannerField = {
        name: 'bottom-banner-upload',
        type: 'upload' as UploadType,
        label: 'Upload bottom banner (recommended 2515x1085px)',
        path: '',
        defaultValue: '',
    }

    const firstBannerError =
        props.showErrors && !props.noFirstBanners && !props.bannerFirstInfo?.text[props.activeLanguage]
    const secondBannerError =
        props.showErrors && !props.noSecondBanners && !props.bannerSecondInfo?.text[props.activeLanguage]

    return (
        <SectionWrapper title="Translations">
            <FormItem>
                <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor={translationLanguageField.name}>{translationLanguageField.label}</label>
                </Col>
                <Col span={6} className="justify-right">
                    <SelectComponent
                        id="translationsLanguage"
                        field={translationLanguageField}
                        handleChange={(v) => props.handleLanguageChange(v as string)}
                        value={props.activeLanguage}
                    />
                </Col>
            </FormItem>
            <FormItem>
                <Col span={12}>
                    <label htmlFor={topBannerField.name}>{topBannerField.label}</label>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={11.4}>
                            <UploadElement
                                key={topBannerField.name}
                                field={topBannerField}
                                status={firstBannerError ? 'error' : 'normal'}
                                handleChange={(url: string) => props.setBanner(url, 'first')}
                                imageOptions={{
                                    max_width: 2515,
                                    max_height: 1085,
                                }}
                                fileSizeLimit={5}
                                value=""
                                imagesService={imagesService}
                                replaceTopMessages={props.replaceMessages}
                                removeAllMessages={props.removeAllMessages}
                                type="img"
                            />
                        </Col>
                        <AlignedCol span={0.5}>
                            <StudioDeleteIcon onDelete={() => props.setBanner('', 'first')} />
                        </AlignedCol>
                    </Row>
                </Col>
            </FormItem>
            <FormItem>
                <Col span={12}>
                    <label htmlFor={bottomBannerField.name}>{bottomBannerField.label}</label>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={11.4}>
                            <UploadElement
                                key={bottomBannerField.name}
                                field={bottomBannerField}
                                status={secondBannerError ? 'error' : 'normal'}
                                handleChange={(url: string) => props.setBanner(url, 'second')}
                                imageOptions={{
                                    max_width: 2515,
                                    max_height: 1085,
                                }}
                                fileSizeLimit={5}
                                value=""
                                imagesService={imagesService}
                                replaceTopMessages={props.replaceMessages}
                                removeAllMessages={props.removeAllMessages}
                                type="img"
                            />
                        </Col>
                        <AlignedCol span={0.5}>
                            <StudioDeleteIcon onDelete={() => props.setBanner('', 'second')} />
                        </AlignedCol>
                    </Row>
                </Col>
            </FormItem>
        </SectionWrapper>
    )
}
