import React from 'react'

import { PageHeading, PageTitle } from 'uiComponents/typography'
import SearchField from 'uiComponents/search/searchField'
import CartTable from './cartTable'
import ErrorBoundary from 'uiComponents/errorBoundary'

const Cart: React.FC = ({ children }) => {
    return (
        <div id="cart__elem">
            <PageTitle>Carts</PageTitle>
            <PageHeading>View, edit, resend emails of active carts.</PageHeading>
            <div style={{ paddingBottom: '2rem' }}>
                <SearchField placeholder="Search by email" />
            </div>
            <div>
                <CartTable />
            </div>
            <ErrorBoundary>{children}</ErrorBoundary>
        </div>
    )
}

export default Cart
