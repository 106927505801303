import * as React from 'react'
import styled from 'styled-typed'
import { InstructionsCard } from 'snippet/instructionsCard'
import supportIcon from './snippetContainer/support.svg'
import { ActionButtonExternalLink } from 'uiComponents/buttons'

const Button = styled(ActionButtonExternalLink)`
    width: 16em;
    margin-top: 0.7em;
    margin-right: 2.5em;
    display: inline-flex;
`

export function NeedHelpCardCard() {
    const closedSubHeader = 'Not sure how to do it? We will be glad to help you!'

    return (
        <InstructionsCard iconSrc={supportIcon} header="I need help!" closedSubHeader={closedSubHeader}>
            <div style={{ marginTop: '1.7em' }}>
                <Button href="https://meetings.hubspot.com/maitane" target="_blank">
                    Get in touch
                </Button>
                <Button
                    kind="action"
                    secondary
                    href="https://support.convious.com/help/convious-onboarding"
                    target="_blank"
                >
                    Read Our Support Articles
                </Button>
            </div>
        </InstructionsCard>
    )
}
