import * as React from 'react'
import { connect } from 'react-redux'
import { State } from 'store'
import { AddMyselfCard } from 'snippet/addMyselfCard'
import { WordPressCard } from 'snippet/wordPressCard'
import { SendDeveloperCard } from 'snippet/sendDeveloperCard'
import { NeedHelpCardCard } from 'snippet/needHelpCard'
import { History } from 'history'
import { SnippetService } from 'snippet/snippetService'
import { PageTopMessage, HeaderMessageContainer } from 'uiComponents/messages'
import { Container, MessageContent, MessageText, MessageLink, TopContainer, BottomContainer } from './snippetContainer'
import { delay } from 'utils'
import { Account } from 'auth/state'
import { StyledATag, PageTitle, Headline, PageHeading } from 'uiComponents/typography'

interface AddSnippetProps {
    history: History
    email: string
    accounts: Account[]
    accountSlug: string
    snippetService: SnippetService
}

interface AddSnippetState {
    message: string
    messageVisible: boolean
    messageType: 'warn' | 'error' | 'success'
}

class AddSnippetPage extends React.Component<AddSnippetProps, AddSnippetState> {
    constructor(props: AddSnippetProps) {
        super(props)
        this.state = {
            message: '',
            messageVisible: false,
            messageType: 'warn',
        }
    }

    async componentDidMount() {
        await this.detectSnippet()
    }

    async componentDidUpdate(prevProps: AddSnippetProps) {
        if (prevProps.accountSlug !== this.props.accountSlug) {
            await this.detectSnippet()
        }
    }

    onCheckAgain = async () => {
        await this.hideMessage()
        await this.detectSnippet()
    }

    hideMessage = async () => {
        if (this.state.messageType) {
            this.setState({ messageVisible: false })
            await delay(600)
        }
    }

    detectSnippet = async () => {
        try {
            await this.props.snippetService.detectSnippet(this.props.accountSlug)
            const pathnameElements = location.pathname.split('/')
            if (pathnameElements.indexOf('new_account') > -1) {
                this.props.history.push('/')
            } else if (pathnameElements.indexOf('engage/tools') > -1) {
                this.props.history.push(`/account/${this.props.accountSlug}/engage/tools/home/`)
            } else {
                const account =
                    this.props.accounts.find((x) => x.slug === this.props.accountSlug) || this.props.accounts[0]
                this.setState({
                    message: `Congratulations! We successfully detected the Convious script on ${account.url}`,
                    messageType: 'success',
                    messageVisible: true,
                })
            }
        } catch (e) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            if (e.message === 'script not found') {
                this.setState({
                    messageType: 'warn',
                    messageVisible: true,
                })
            } else {
                this.handleUnknownError()
            }
        }
    }

    handleUnknownError = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        this.setState({
            message: 'Oops! Something went wrong. Please try again later.',
            messageType: 'error',
            messageVisible: true,
        })
    }

    render() {
        const { accounts, accountSlug, email, snippetService } = this.props
        const { message, messageVisible, messageType } = this.state
        const account = accounts.find((x) => x.slug === accountSlug) || accounts[0]

        return (
            <Container id="snippet-page" style={{ maxWidth: '1200px' }}>
                <div style={{ width: '100%' }}>
                    <HeaderMessageContainer>
                        <PageTopMessage
                            kind={messageType}
                            showMessage={messageVisible}
                            onDismiss={() => this.setState({ messageVisible: false })}
                        >
                            {(messageType === 'error' || messageType === 'success') && message}
                            {messageType === 'warn' && (
                                <MessageContent>
                                    <MessageText>We could not detect the Convious script on {account.name}</MessageText>
                                    <MessageLink onClick={this.onCheckAgain}>Check again</MessageLink>
                                </MessageContent>
                            )}
                        </PageTopMessage>
                    </HeaderMessageContainer>
                </div>
                <TopContainer key="top">
                    <PageTitle>Install the Convious script and go live now!</PageTitle>
                    <PageHeading fullWidth>
                        Installing the script is easy and done only once. Choose one of the methods below, and as soon
                        as we detect the script on {account.name}, you’re ready to start using Convious!
                        <div>
                            To make it as easy as possible, we have prepared a{' '}
                            <StyledATag
                                target="_blank"
                                href="https://support.convious.com/help/convious-script-installation"
                            >
                                detailed guide on how to install the Convious script
                            </StyledATag>{' '}
                            you can use or forward to your developer.
                        </div>
                    </PageHeading>
                </TopContainer>
                <BottomContainer key="bottom">
                    <Headline size={4}>How to install?</Headline>
                    <AddMyselfCard account={account} detectSnippet={this.onCheckAgain} />
                    <WordPressCard detectSnippet={this.onCheckAgain} />
                    <SendDeveloperCard
                        email={email}
                        account={account}
                        snippetService={snippetService}
                        handleUnknownError={this.handleUnknownError}
                    />
                    <NeedHelpCardCard />
                </BottomContainer>
            </Container>
        )
    }
}

const mapStateToProps = (state: State) => {
    return {
        email: state.auth.user ? state.auth.user.username : '',
        accounts: state.auth.user ? state.auth.user.accounts : [],
    }
}

export default connect(mapStateToProps)(AddSnippetPage)
