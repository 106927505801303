import classNames from 'classnames'
import React from 'react'

import { ContainerHeading, HeadingSectionName } from 'uiComponents/settingsContainer'
import { useAudienceNavigation } from '../hooks/useAudienceNavigation'

const AudiencesTabsNavigation = () => {
    const sections = useAudienceNavigation()

    return (
        <ContainerHeading style={{ paddingLeft: '1em' }}>
            {sections.map((section) => {
                return (
                    <HeadingSectionName
                        key={section.path}
                        className={classNames({ active: section.active })}
                        to={section.path}
                    >
                        {section.tabTitle}
                    </HeadingSectionName>
                )
            })}
        </ContainerHeading>
    )
}

export default AudiencesTabsNavigation
