import React from 'react'
import { Body } from 'uiComponents/typography'

const Disclaimer = () => {
    return (
        <div style={{ marginBottom: '7em', maxWidth: 1200 }}>
            <Body size={2} color="light" weight="medium">
                Disclaimer
            </Body>
            <Body size={3} color="light">
                All content included in the dashboard is for informative purposes only. Information presented is based
                on the Ad Hoc data which is subject to changes over time and might not be final.Information herein is
                subject to update, correction and revision with more information available for instance from psp data,
                refunds, reconciliation etc. Discrepancies between information contained in these dashboard and other
                forms of reporting may exist due to the different timing and methodologies of processing the information
                in accordance to various purposes. Appropriate and reasonable efforts will be continually made to ensure
                the consistency of the information. The presentation of information herein is not warranted to be error
                free.
            </Body>
        </div>
    )
}

export default Disclaimer
