import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AppServices } from 'middleware'
import { CropOptions } from './imagesService'

export const statsApi = createApi({
    reducerPath: 'statsApi',
    tagTypes: ['pricerIndustryDefaults'],
    baseQuery: fetchBaseQuery({}),
    endpoints: (builder) => ({}),
})

export const { reducer, reducerPath } = statsApi

export const imageApi = createApi({
    reducerPath: 'imageApi',
    tagTypes: ['uploadImage'],
    baseQuery: fetchBaseQuery({}),
    endpoints: (builder) => ({}),
})

const extendedImageApi = imageApi.injectEndpoints({
    endpoints: (builder) => ({
        uploadImage: builder.mutation({
            queryFn: async ({ file, options }: { file: File; options?: CropOptions }) => {
                try {
                    const data = await AppServices.imagesService.uploadImage(file, options)

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
    }),
})

export const { useUploadImageMutation } = extendedImageApi
