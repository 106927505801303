import * as React from 'react'
import styled from 'styled-typed'
import { SketchPicker, ColorResult } from 'react-color'
import { withFeatures } from 'features'
import { isUserOnMacOS } from 'utils/browserInfo'
import { ColorInput } from 'uiComponents/input'
import { CONTAINER_BOX_SHADOW } from 'uiComponents/settingsContainer'

const ColorPickerComponent = styled.div`
    font-size: 1rem;
    line-height: 1em;
    font-weight: 300;
    width: 6em;
    display: flex;
`

export const ColorPickerTrigger = styled.div<ColorPickerTriggerProps>`
    width: 4.2em;
    height: 1.82em;
    border-radius: 0.3em;
    background: ${(props) => props.color};
    cursor: pointer;
    border: ${(props) =>
        props.color === '#FFFFFF' || props.color === '#ffffff' || props.color === ''
            ? '1px solid rgba(161,176,178,0.5)'
            : 'none'};
    transition: all 0.2s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
`

const TriggerWrapper = styled.div<TriggerWrapperProps>`
    display: flex;
    flex: 1;
    ${(props) => (props.alignRight ? 'justify-content: flex-end' : '')};
`

const ColorPickerContainer = styled.div`
    font-size: 1rem;
    position: absolute;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in, visibility 0s 0.3s;

    &.visible {
        opacity: 1;
        z-index: 1;
        visibility: visible;
        transition: opacity 0.3s ease-in;
    }
`

const RecommendedColorText = styled.div`
    font-size: 0.75em;
    font-weight: 300;
    position: relative;
    top: -8.5em;
    left: 1.7em;
`

const Footer = styled.div<ColorPickerFooterProps>`
    font-size: 0.75em;
    font-weight: 300;
    position: relative;
    top: ${(props) => (props.showRecommendedColors ? '-4em' : '-3em')};
    left: 1.7em;
    display: flex;
    justify-content: space-between;
    max-width: 16.6em;
    cursor: pointer;
`

const ColorPickerBox = styled(SketchPicker)<ColorPickerBoxProps>`
    box-shadow: ${CONTAINER_BOX_SHADOW} !important;
    border-radius: 0.625em !important;
    padding: 1.18em 1.18em 2.7em 1.18em !important;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 240px !important;
    }

    input {
        border-radius: 0.3em !important;
        height: 2em !important;
        width: 100% !important;
        margin-top: 0.3em;
        text-align: center;
        box-shadow: none !important;
        border: 1px solid #d4dadd !important;
        transition: all 0.3s ease-in;

        &:focus {
            outline: none;
            border: 1px solid ${(props) => props.theme.colors.boyBlue} !important;
        }
    }

    /* rectangular colour selector */
    & > :first-child {
        border-radius: 0.2em;
    }

    /* horizontal colour selector block */
    & > :nth-child(2) {
        position: relative;
    }

    /* horizontal colour palete */
    & > :nth-child(2) > div > div {
        height: 1.125em !important;
        margin-top: 0.5em;
        border-radius: 0.2em;
    }

    /* horizontal colour selector slider */
    & > :nth-child(2) > div > div > div > div > div > div {
        height: 1em !important;
        width: 0.375em !important;
    }

    /* selected colour container*/
    & > :nth-child(2) > :nth-child(2) {
        position: absolute !important;
        top: 1.8em;
        right: 0em;

        & > :first-child {
            background: none !important;
        }
    }

    /* selected colour*/
    & > :nth-child(2) > :nth-child(2) > :nth-child(2) {
        height: 1.5em !important;
        width: 1.5em !important;
        border-radius: 50% !important;
    }

    /* colour input individual containers */
    & > :nth-child(3) > div {
        flex: none !important;

        &:first-child {
            max-width: 3.7em;
        }

        &:not(:first-child) {
            max-width: 2.3em;
            padding-left: 0.2em !important;
        }

        & span {
            text-transform: uppercase !important;
            font-size: 0.625em !important;
        }
    }

    /* recommended colours block */
    & > :last-child {
        margin-top: 1.7em !important;
        border: none !important;
    }

    /* recommended colours items */
    & > :last-child > div {
        height: 1.25em !important;
        width: 1.25em !important;
        margin: 0px 10px 10px 0px !important;

        & > span > div {
            border-radius: 50% !important;
        }
    }
`

export const PresetText = styled.span`
    font-size: 0.625em !important;
    letter-spacing: 2px;
`

interface ColorPickerTriggerProps {
    color: string
}

interface TriggerWrapperProps {
    alignRight?: boolean
}

interface ColorPickerFooterProps {
    showRecommendedColors: boolean
}

interface ColorPickerBoxProps {
    color: string
    disableAlpha: boolean
    presetColors: string[]
    onChangeComplete: (color: ColorResult) => void
}

export interface ColorPickerProps {
    color: string
    id: string
    alignTriggerRight?: boolean
    style?: React.CSSProperties
    onChange: (value: string) => void
    hasFeature: (feature: string) => boolean
}

interface ColorPickerState {
    selectedColor: string
    visible: boolean
    top: number
}

function getScrollParent(el: HTMLElement | null): HTMLElement | null {
    if (el === null) {
        return null
    }
    if (el.scrollHeight > el.clientHeight) {
        return el
    }
    return getScrollParent(el.parentElement)
}

function getScrollArea(el: HTMLElement | null): HTMLElement | null {
    if (el === null) {
        return null
    }
    if (el.id === 'scroll-area') {
        return el
    }
    return getScrollArea(el.parentElement)
}

interface ColorPickerPopupProps {
    visible: boolean
    top: number
    left?: number
    selectedColor: string
    recommendedColors?: string[]
    showRecommendedColors?: boolean
    handleChangeComplete: (color: ColorResult) => void
    onAcceptSelectedColor: () => void
    hide: () => void
}

export function ColorPickerPopup(props: ColorPickerPopupProps) {
    return (
        <ColorPickerContainer
            className={props.visible ? 'visible' : ''}
            style={{ top: props.top + 'px', left: props.left, zIndex: 2 }}
        >
            <ColorPickerBox
                color={props.selectedColor}
                disableAlpha={true}
                presetColors={props.recommendedColors || []}
                onChangeComplete={props.handleChangeComplete}
            />
            {props.showRecommendedColors && <RecommendedColorText>Recommended colours for you</RecommendedColorText>}
            <Footer showRecommendedColors={!!props.showRecommendedColors}>
                <span onClick={props.hide}>Cancel</span>
                <strong onClick={props.onAcceptSelectedColor}>Apply</strong>
            </Footer>
        </ColorPickerContainer>
    )
}

class ColorPicker extends React.Component<ColorPickerProps, ColorPickerState> {
    popup: HTMLDivElement | null = null
    scrollParent: HTMLElement | null = null
    scrollArea: HTMLElement | null = null

    constructor(props: ColorPickerProps) {
        super(props)
        this.state = {
            selectedColor: this.props.color || '#FFFFFF',
            visible: false,
            top: 0,
        }
    }

    componentDidMount() {
        this.setState({ top: this.calculateTopOffset() })
        document.addEventListener('click', this.outsideClick, false)
        window.addEventListener('resize', this.repositionOnWindowResize)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.outsideClick, false)
        window.removeEventListener('resize', this.repositionOnWindowResize)
    }

    outsideClick = (ev: MouseEvent) => {
        if (this.popup && this.popup.contains && !this.popup.contains(ev.target as Node) && this.state.visible) {
            this.setVisible(false)
        }
    }

    repositionOnWindowResize = () => {
        if (this.popup) {
            this.setState({ top: this.calculateTopOffset() })
        }
    }

    setPopupRef = (node: HTMLDivElement) => {
        this.popup = node
        this.scrollParent = getScrollParent(node)
        this.scrollArea = getScrollArea(node)
    }

    calculateTopOffset = () => {
        if (!this.popup || !this.scrollParent || !this.scrollArea) {
            return 0
        }
        let enoughSpaceTop = true
        let enoughSpaceBottom = true
        const scrollAreaLowestVisiblePoint = this.scrollArea.offsetTop + this.scrollArea.clientHeight
        const triggerOffset = this.scrollParent.offsetTop + this.popup.offsetTop - this.scrollArea.scrollTop
        const popupOffset = triggerOffset + 30
        enoughSpaceTop = triggerOffset - this.scrollArea.offsetTop > 300
        enoughSpaceBottom = scrollAreaLowestVisiblePoint - popupOffset > 300
        return enoughSpaceTop && !enoughSpaceBottom ? this.popup.offsetTop - 302 : this.popup.offsetTop + 33
    }

    setVisible = async (setVisible: boolean) => {
        if (!this.popup) {
            return
        }

        if (setVisible) {
            this.setState({ visible: true, top: this.calculateTopOffset() })
        } else {
            this.setState({ visible: false })
        }
    }

    toggleShowColorPicker = () => {
        this.setVisible(!this.state.visible)
    }

    handleChangeComplete = (color: ColorResult) => {
        this.setState({ selectedColor: color.hex })
    }

    handleNativePickerComplete = (color: string) => {
        this.setState({ selectedColor: color })
        this.props.onChange(color)
    }

    onAcceptSelectedColor = () => {
        this.setVisible(false)
        this.props.onChange(this.state.selectedColor)
    }

    render() {
        const { visible, selectedColor, top } = this.state
        const { hasFeature, color, id } = this.props
        const showRecommendedColors = hasFeature('RecommendedColors')
        const recommendedColors = showRecommendedColors
            ? ['#D0021B', '#F5A623', '#F8E71C', '#7ED321', '#417505', '#BD10E0', '#9013FE']
            : []
        const isOnMacOS = isUserOnMacOS()
        return (
            <ColorPickerComponent ref={this.setPopupRef} id={id}>
                {hasFeature('NativeColorPicker') && isOnMacOS ? (
                    <ColorInput setColor={this.handleNativePickerComplete} value={selectedColor} />
                ) : (
                    <>
                        <TriggerWrapper alignRight={this.props.alignTriggerRight}>
                            <ColorPickerTrigger
                                onClick={this.toggleShowColorPicker}
                                color={color}
                                className="color-picker-trigger"
                                style={this.props.style}
                            >
                                {!this.props.color && <PresetText>preset</PresetText>}
                            </ColorPickerTrigger>
                        </TriggerWrapper>
                        <ColorPickerPopup
                            visible={visible}
                            top={top}
                            selectedColor={selectedColor}
                            recommendedColors={recommendedColors}
                            showRecommendedColors={showRecommendedColors}
                            handleChangeComplete={this.handleChangeComplete}
                            onAcceptSelectedColor={this.onAcceptSelectedColor}
                            hide={() => this.setVisible(false)}
                        />
                    </>
                )}
            </ColorPickerComponent>
        )
    }
}

export default withFeatures(ColorPicker)
