import { dateRangeFromQuery } from 'dateRanges'
import { ComponentsService } from 'engageTools/studio/componentsService'
import { withFeatures } from 'features'
import { History } from 'history'
import { Navigation } from 'navigation'
import * as React from 'react'
import { usePrevious } from 'reactUtils'
import { PlacementListItem, UpdatePlacementWeightPayload } from 'tapPlacements/schema'
import { TapPlacementService } from 'tapPlacements/service'
import { ActionButton } from 'uiComponents/buttons'
import { TableLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import SearchField, { SearchContainer } from 'uiComponents/search/searchField'
import { HeaderRow, InlineDataTable, TableHeader } from 'uiComponents/table'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { delay } from 'utils'
import { useFetchAudiences } from '../../../crm/audiences/hooks/useFetchAudiences'
import { itemMatchAudienceFilter, itemMatchesDateFilter, itemMatchesSearch, itemMatchStatusFilter } from './helpers'
import PlacementTableBody from './tableBody'

interface TableProps {
    accountSlug: string
    history: History
    hasFeature: (feature: string, accountSlug: string) => boolean
    componentsService: ComponentsService
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    navigation: Navigation
    canEditCampaigns: boolean
    dateRangeApplied: boolean
    placementService: TapPlacementService
}

function Table(props: TableProps) {
    const hasCRMAudienceFeature = React.useMemo(() => {
        return props.hasFeature('CRMAudiencesPage', props.accountSlug)
    }, [props.accountSlug])

    const hasPlacementLocationFeature = React.useMemo(
        () => props.hasFeature('PlacementLocation', props.accountSlug),
        [props.accountSlug],
    )
    const audiences = useFetchAudiences()

    const columnWidths = [null, '10em', hasCRMAudienceFeature ? null : '0em', '11em']

    const [loading, setLoading] = React.useState<boolean>(false)
    const [toolToDelete, setToolToDelete] = React.useState<string>('')
    const [uponEdit, setUponEdit] = React.useState<boolean>(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState<boolean>(false)
    const [items, setItems] = React.useState<PlacementListItem[]>([])

    const query = React.useMemo(() => {
        return props.navigation.query().q ? props.navigation.query().q.toLowerCase() : ''
    }, [props.navigation.query().q])

    const combinedFilters = React.useMemo(() => {
        const allFilters = {}
        if (props.navigation.query().filter) {
            const decodedFilter = decodeURIComponent(props.navigation.query().filter)
            const filters = decodedFilter.split(';')

            filters.forEach((filter) => {
                const keyAndValue = filter.split(':')
                allFilters[keyAndValue[0]] = keyAndValue[1].split(',')
            })
        }

        return allFilters
    }, [props.navigation.query().filter])

    const dateFilter = React.useMemo(() => {
        let dateRange = {}
        const dateQuery = props.navigation.query()

        if (dateQuery.dateRange) {
            dateRange = dateRangeFromQuery(dateQuery)
        }

        return dateRange
    }, [props.navigation.query().dateRange, props.navigation.query().dateFrom, props.navigation.query().dateTo])

    const filteredItems = React.useMemo(() => {
        return items.filter((item) => {
            const matchesName = itemMatchesSearch(item, query)
            const matchesStatus = itemMatchStatusFilter(item, combinedFilters['tool_status'])
            const machtesAudiences = itemMatchAudienceFilter(item, combinedFilters['audience'])
            const matchesDateFilter = props.dateRangeApplied ? itemMatchesDateFilter(item, dateFilter) : true
            return matchesName && matchesStatus && machtesAudiences && matchesDateFilter
        })
    }, [
        props.navigation.query().filter,
        props.navigation.query().q,
        props.navigation.query().dateRange,
        props.navigation.query().dateFrom,
        props.navigation.query().dateTo,
        items,
    ])

    React.useEffect(() => {
        audiences.fetch()
    }, [])

    React.useEffect(() => {
        getEngageTools()
    }, [props.accountSlug])

    React.useEffect(() => {
        if (uponEdit) {
            getEngageTools()
            setUponEdit(false)
        }
    }, [uponEdit])

    const prevRangeApplied = usePrevious(props.dateRangeApplied)
    React.useEffect(() => {
        if (typeof prevRangeApplied !== 'undefined' && prevRangeApplied !== props.dateRangeApplied) {
            getEngageTools()
        }
    }, [props.dateRangeApplied])

    const getEngageTools = async () => {
        try {
            setLoading(true)
            const result = await props.placementService.fetchAll(props.accountSlug)
            setItems(result)
        } catch {
            handleErrorMessage()
        } finally {
            setLoading(false)
        }
    }

    const handleErrorMessage = () => {
        props.replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again.')
    }

    const deleteTool = async (configId: string) => {
        setLoading(true)

        try {
            await props.placementService.delete(configId, props.accountSlug)
            await getEngageTools()
            props.replaceMessages('server_success', 'success', 'Your placement was successfully deleted.')
            await delay(1000)
            props.removeAllMessages()
        } catch {
            handleErrorMessage()
        } finally {
            setLoading(false)
        }
    }

    const updateWeights = async (placements: UpdatePlacementWeightPayload[]) => {
        setLoading(true)

        try {
            await props.placementService.updatePlacementsWeights(props.accountSlug, placements)
            await getEngageTools()
            props.replaceMessages('server_success', 'success', 'Your placement were successfully updated.')
            await delay(1000)
            props.removeAllMessages()
        } catch {
            handleErrorMessage()
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            {showDeleteConfirmation && (
                <ConfirmationDialog
                    title="Are you sure you want to delete this placement?"
                    text="This can’t be undone!"
                    buttonText="Delete"
                    onCancel={() => setShowDeleteConfirmation(false)}
                    onConfirm={() => {
                        deleteTool(toolToDelete)
                        setShowDeleteConfirmation(false)
                        setUponEdit(true)
                    }}
                />
            )}
            {loading && <TableLoader />}
            {!loading && (
                <>
                    <SearchContainer style={{ margin: '1.5em' }}>
                        <SearchField placeholder="Search by name" />
                        <ActionButton
                            kind="action"
                            size="medium"
                            onClick={() => {
                                props.history.push(`/account/${props.accountSlug}/engage/tools/placements/type`)
                            }}
                            id="add-new-placement"
                        >
                            + Add new
                        </ActionButton>
                    </SearchContainer>
                    <InlineDataTable columnWidths={columnWidths} id="placements-list">
                        <HeaderRow>
                            <TableHeader>Name</TableHeader>
                            <TableHeader>Valid date</TableHeader>
                            <TableHeader>{hasCRMAudienceFeature && 'Audience(s)'}</TableHeader>
                            {hasPlacementLocationFeature && <TableHeader>Locations</TableHeader>}
                            <TableHeader nonInteractive />
                        </HeaderRow>
                        {filteredItems.length === 0 && <NoResultsRow text="There are no configured placements yet." />}
                        {filteredItems.length > 0 && (
                            <PlacementTableBody
                                accountSlug={props.accountSlug}
                                items={filteredItems}
                                onDelete={(id) => {
                                    setToolToDelete(id)
                                    setShowDeleteConfirmation(true)
                                }}
                                hasPlacementAudienceFeature={hasCRMAudienceFeature}
                                hasPlacementLocationFeature={hasPlacementLocationFeature}
                                updatePlacementsWeight={(placements: any[]) => {
                                    updateWeights(placements)
                                }}
                                audiences={audiences.data}
                            />
                        )}
                    </InlineDataTable>
                </>
            )}
        </>
    )
}

export default withFeatures(Table)
