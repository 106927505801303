import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import PushNotificationsProvider from '../../pushNotifications/context/PushNotifications'
import CampaignsPage from '../../pushNotifications/pages/campaigns'
import ContentPage from '../../pushNotifications/pages/content'
import EditPage from '../../pushNotifications/pages/edit'
import SchedulePage from '../../pushNotifications/pages/schedule'
import TargetPage from '../../pushNotifications/pages/target'

const PushNotificationsRoutes = () => {
    return (
        <PushNotificationsProvider>
            <Switch>
                <Route exact path="/account/:accountSlug/engage/push_notifications" component={CampaignsPage} />
                <Route exact path="/account/:accountSlug/engage/push_notifications/target" component={TargetPage} />
                <Route
                    exact
                    path="/account/:accountSlug/engage/push_notifications/target/:campaign_id"
                    component={EditPage}
                />
                <Route exact path="/account/:accountSlug/engage/push_notifications/content" component={ContentPage} />
                <Route exact path="/account/:accountSlug/engage/push_notifications/schedule" component={SchedulePage} />
            </Switch>
        </PushNotificationsProvider>
    )
}

export default PushNotificationsRoutes
