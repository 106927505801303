import React, { FC } from 'react'
import { Col, Row } from 'uiComponents/flex'
import { Caption, Headline } from 'uiComponents/typography'

interface Props {
    title: string
    helperText?: string
    bottomSpacing?: string
    horizontalSpacing?: string
}

const FieldRow: FC<Props> = (props) => {
    const { title, helperText, bottomSpacing, horizontalSpacing, children } = props

    return (
        <Row style={{ paddingBottom: bottomSpacing, paddingRight: horizontalSpacing, paddingLeft: horizontalSpacing }}>
            <Col span={6}>
                <Headline bold style={{ marginBottom: 4 }} size={6}>
                    {title}
                </Headline>
                {helperText && <Caption>{helperText}</Caption>}
            </Col>
            <Col span={6}>{children}</Col>
        </Row>
    )
}

export default FieldRow
