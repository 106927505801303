import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-typed'
import { ConfigField } from './schema'
import { Checkbox } from 'uiComponents/input'
import { Body } from 'uiComponents/typography'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { InlineDataTable, HeaderRow, TableHeader, Cell, DataRow, Sorting } from 'uiComponents/table'
import { Article } from 'uiComponents/filter/schema'
import { transparency } from 'utils/css'

interface SectionProps {
    accountSlug: string
    fieldsConfig: ConfigField[]
    apfProducts: Article[]
    selectedProducts: string[]
    setSelectedProducts: (ids: string[]) => void
}

const Row = styled(DataRow)`
    height: 1.5625rem;
    &.grayedOut {
        opacity: 0.5;
    }
`

const Header = styled(HeaderRow)`
    height: 2rem;
    font-size: 0.875rem;
    font-weight: normal;
`

const Table = styled(InlineDataTable)`
    & > ${DataRow}:nth-child(2n+1) {
        background: ${(props) => props.theme.colors.white};
    }
    & > ${DataRow}:hover {
        cursor: pointer;
        background: ${(props) => transparency(props.theme.colors.tableRow, 0.6)};
    }
`

function EnableSection(props: SectionProps) {
    const [products, setProducts] = useState<Article[]>([])
    const [sort, setSort] = useState<Sorting>({ prop: 'name', direction: 'asc' })
    const sortRef = useRef<{ prop: string; direction: 'asc' | 'desc' }>({
        prop: 'name',
        direction: 'asc',
    })

    useEffect(() => {
        setProducts(props.apfProducts)
    }, [props.apfProducts])

    const updateSelected = (id: string) => {
        if (props.selectedProducts.find((sp) => sp === id)) {
            props.setSelectedProducts(props.selectedProducts.filter((sp) => sp !== id))
        } else {
            const selected: string[] = [...props.selectedProducts]
            selected.push(id)
            props.setSelectedProducts(selected)
        }
    }

    const toggleSelectAll = (e: React.MouseEvent) => {
        e.stopPropagation()
        !!props.selectedProducts.length
            ? props.setSelectedProducts([])
            : props.setSelectedProducts(products.map((pr) => pr.id))
    }

    const sortByNameOrId = (product1: Article, product2: Article) => {
        const prop = sortRef.current.prop
        const sortKeyA = product1[prop].toString().toUpperCase()
        const sortKeyB = product2[prop].toString().toUpperCase()
        if (sortKeyA < sortKeyB) {
            return -1
        }
        if (sortKeyA > sortKeyB) {
            return 1
        }
        return 0
    }

    const sortByChecked = (product1: Article, product2: Article) => {
        const sortKeyA = !!props.selectedProducts.find((sp) => sp === product1.id)
        const sortKeyB = !!props.selectedProducts.find((sp) => sp === product2.id)
        if (sortKeyA < sortKeyB) {
            return -1
        }
        if (sortKeyA > sortKeyB) {
            return 1
        }
        return 0
    }

    const onSortChanged = async (sorting: Sorting) => {
        const sortInfo = {
            prop: sorting.prop,
            direction: sorting.direction,
        }
        sortRef.current = sortInfo
        setSort(sortInfo)

        let sortByFunction = sorting.prop === 'checked' ? sortByChecked : sortByNameOrId
        if (sortInfo.direction === 'desc') {
            sortByFunction = desc(sortByFunction)
        }

        const sortedProducts = [...products].sort(sortByFunction)
        setProducts(sortedProducts)
    }

    const desc = (sortingFunc: (item1: Article, item2: Article) => {}) => {
        return (item1: Article, item2: Article) => -sortingFunc(item1, item2) as 1 | -1 | 0
    }

    return (
        <div style={{ maxWidth: '75em' }}>
            <Table columnWidths={['2.5em', null, '3rem']}>
                <Header>
                    <TableHeader
                        style={{ marginRight: '0.5rem', marginLeft: '0.5rem' }}
                        sortKey="checked"
                        sorting={sort}
                        changeSort={onSortChanged}
                        noPadding
                        nonInteractive
                    >
                        <Checkbox
                            name="selectAll"
                            checked={
                                !!props.selectedProducts.length && props.selectedProducts.length === products.length
                            }
                            onClick={(e: React.MouseEvent) => toggleSelectAll(e)}
                        />
                    </TableHeader>
                    <TableHeader noPadding sortKey="name" sorting={sort} changeSort={onSortChanged}>
                        Name
                    </TableHeader>
                    <TableHeader
                        sortKey="numericId"
                        sorting={sort}
                        changeSort={onSortChanged}
                        align="left"
                        style={{ marginRight: '0.5rem' }}
                    >
                        ID
                    </TableHeader>
                </Header>
                {!products.length && <NoResultsRow text="There are no products to enable." />}
                {products.map((product, i) => {
                    const isChecked = !!props.selectedProducts.find((sp) => sp === product.id)
                    return (
                        <Row
                            key={i}
                            className={isChecked ? '' : 'grayedOut'}
                            onClick={() => updateSelected(product.id)}
                        >
                            <Cell style={{ marginRight: '0.5rem', marginLeft: '0.5rem' }} noPadding>
                                <Checkbox name={product.name} checked={isChecked} />
                            </Cell>
                            <Cell title={product.name} noPadding>
                                <Body size={2}>{product.name}</Body>
                            </Cell>
                            <Cell style={{ marginRight: '0.5rem' }}>
                                <Body size={2}>{product.numericId}</Body>
                            </Cell>
                        </Row>
                    )
                })}
            </Table>
        </div>
    )
}

export default EnableSection
