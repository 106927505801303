import * as React from 'react'
import { match as RouteMatch } from 'react-router-dom'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { StatsServiceContext } from 'http/context'
import { InventoryServiceContext } from 'inventory/context'
import { PageTitle, PageHeading, Headline } from 'uiComponents/typography'
import { StatsService } from 'http/statsService'
import { dateRangeToQuery, DateRange } from 'dateRanges'
import PricingChart from 'reports/pricing/pricingChart'
import PriceDistributionChart from 'reports/pricing/priceDistributionChart'
import { createTimeseriesBarChart, TimeseriesBarChartSource } from 'uiComponents/charts/timeseriesBarChartSource'
import { getDataFromQuery, areDateRangeDatesEqual, ReportsToolsWrapper, ReportUpdateInfo } from 'reports/helpers'
import { Messages } from 'uiComponents/messages'
import { withMessages, MessageProps } from 'hocs'
import ReportsFilter from 'reports/reportsFilter'
import { FilterCategory } from 'uiComponents/filter/schema'
import { AVAILABLE_FILTERS, STANDALONE_FILTERS, METADATA_CATEGORIES } from 'reports/constants'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { ChartContainer } from 'uiComponents/charts/styleComponents'
import { withFeatures } from 'features'
import SingleDataItems from 'reports/pricing/singleDataItems'
import { differenceInDays, addDays } from 'date-fns'
import Disclaimer from 'reports/disclaimer'

interface PricingReportsPageProps {
    navigation: Navigation
    match: RouteMatch<any>
    statsService: StatsService
    accountSlug: string
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function PricingReportsPage(props: PricingReportsPageProps & MessageProps) {
    const statsService = React.useContext(StatsServiceContext)
    const inventoryService = React.useContext(InventoryServiceContext)
    const { dateRange, filters } = getDataFromQuery(props.navigation.query())
    const createChart = (range: DateRange) => {
        const oneDayPeriod = differenceInDays(range.to, range.from) < 1
        const chartRange: DateRange = oneDayPeriod
            ? {
                  name: 'custom',
                  from: addDays(range.from, -6),
                  to: range.to,
                  period: 'day',
              }
            : range
        return createTimeseriesBarChart(chartRange)
    }
    const [chart, setChart] = React.useState<TimeseriesBarChartSource>(createChart(dateRange))

    React.useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    const onDateRangeChanged = (range: DateRange) => {
        if (!areDateRangeDatesEqual(dateRange, range)) {
            props.navigation.addQueryWithReplace(dateRangeToQuery(range))
            setChart(createChart(range))
        }
    }

    const appliedFilters = filters.filter(
        (f) => AVAILABLE_FILTERS['pricing'].indexOf(f.attribute as FilterCategory) > -1,
    )

    return (
        <div id="reports-pricing-page" style={{ marginBottom: '15em' }}>
            <Messages messages={props.messages} hideMessage={props.hideMessage} />
            <PageTitle data-userpilot="pricing-header-tooltip">Pricing Report</PageTitle>
            <PageHeading fullWidth>
                <div>Keep track of your pricing and its evolution.</div>
                <div>
                    Select a single day and product to see its price development leading up to the date of visit or
                    select a specific product to see its Checkout price for the upcoming days. Find more information in
                    our{' '}
                    <a target="_blank" href="https://support.convious.com/help/pricing-report" rel="noreferrer">
                        Knowledge Base
                    </a>
                    .
                </div>
            </PageHeading>
            <ReportUpdateInfo metric="Data" text="updated every 20 minutes." />
            <ReportsToolsWrapper>
                <ReportsFilter
                    accountSlug={props.accountSlug}
                    statsService={statsService}
                    inventoryService={inventoryService}
                    dateRange={dateRange}
                    applicableFilters={AVAILABLE_FILTERS['pricing']}
                    standaloneFilters={STANDALONE_FILTERS['pricing']}
                    metadataCategories={METADATA_CATEGORIES['pricing']}
                    appliedFilters={appliedFilters}
                    replaceMessages={props.replaceMessages}
                    hideMessage={props.hideMessage}
                    userpilot="pricing-filter"
                />
                <DateRangePicker
                    range={dateRange}
                    onChange={onDateRangeChanged}
                    userpilot="pricing-date-range"
                    futureDateRange
                />
            </ReportsToolsWrapper>
            <ChartContainer style={{ marginTop: '2em' }}>
                <Headline size={4}>Ticket price &amp; demand</Headline>
                <SingleDataItems
                    accountSlug={props.accountSlug}
                    dateRange={dateRange}
                    filters={appliedFilters}
                    replaceMessages={props.replaceMessages}
                />
                <PricingChart
                    filters={appliedFilters}
                    accountSlug={props.accountSlug}
                    dateRange={dateRange}
                    chart={chart}
                    replaceTopMessages={props.replaceMessages}
                />
            </ChartContainer>
            <ChartContainer style={{ marginTop: '2.2em', marginBottom: '2em' }}>
                <PriceDistributionChart
                    filters={appliedFilters}
                    accountSlug={props.accountSlug}
                    dateRange={dateRange}
                    replaceTopMessages={props.replaceMessages}
                />
            </ChartContainer>
            <Disclaimer />
        </div>
    )
}

export default withFeatures(withMessages(withNavigation(PricingReportsPage)))
