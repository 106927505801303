import * as React from 'react'
import styled from 'styled-typed'
import { Pagination } from 'uiComponents/table'
import { SingleSelect } from 'uiComponents/input/singleSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { NumberInput } from 'uiComponents/input'

const Container = styled.div`
    font-size: 0.875em;
    font-weight: 300;
    display: flex;
    align-items: center;
    margin-bottom: 13em;
    margin-top: 1.5em;
`
const PageSizeSelection = styled.div`
    display: flex;
    align-items: center;
`
const ResultCount = styled.span`
    margin-left: 2em;
`
const PageNumberSection = styled.div`
    display: flex;
    align-items: center;
    margin-left: 2em;

    @media screen and (min-width: 1320px) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
`
const SectionItem = styled.span`
    margin-right: 0.5em;
    opacity: 0.4;

    &.active {
        opacity: 1;
        cursor: pointer;
    }
`
const Page = styled(SectionItem)`
    padding: 0.05em 0.4em 0.05em 0.4em;
    border-radius: 2px;

    &:not(:nth-last-child(2)) {
        margin-right: 0.1em;
    }

    &:hover:not(.spreader) {
        opacity: 1;
        cursor: pointer;
        background: ${(props) => props.theme.colors.border};
    }

    &.active:not(.spreader) {
        background: ${(props) => props.theme.colors.aluminium};
        color: ${(props) => props.theme.colors.white};
    }
`
const Icon = styled(FontAwesomeIcon)`
    font-size: 0.7em;
    margin: 0 1em 0.1em 1em;
`
const EnterPageSection = styled.div`
    margin-left: 1.5em;
`
const PageInput = styled(NumberInput)<{ whiteBackground?: boolean }>`
    display: inline-block;
    background: ${(props) => (props.whiteBackground ? props.theme.colors.white : '')};
    margin-left: 1em;
    max-width: 3.5em;
    max-height: 2.2em;
    padding: 0.4em;
`

export const pageSizeOptions = ['10', '20', '50', '100']
export const defaultPageSize = 10

interface PaginationSectionProps {
    pagination: Pagination
    totalItemsCount: number
    pageSizeOptions?: string[]
    onWhiteBackground?: boolean
    style?: React.CSSProperties
    onPaginationChanged: (p: Pagination) => void
}

interface PaginationSectionState {
    inputPage: number | string
}

export class PaginationSection extends React.Component<PaginationSectionProps, PaginationSectionState> {
    constructor(props: PaginationSectionProps) {
        super(props)
        this.state = {
            inputPage: this.props.pagination.page,
        }
    }

    getNumberOfPages = () => {
        return Math.ceil(this.props.totalItemsCount / this.props.pagination.pageSize) || 1
    }

    getPageNumbersList = (numberOfPages: number, currentPage: number) => {
        const list: (number | string)[] = []
        for (let i = 1; i <= numberOfPages; i++) {
            list.push(i)
        }
        const pageIndex = currentPage - 1

        if (list.length < 5 || (list.length === 5 && pageIndex === 2)) {
            return list
        }

        if (pageIndex === 0 || pageIndex === list.length - 1) {
            list.splice(2, list.length - 4, '...')
            return list
        }

        if (pageIndex > 0 && pageIndex < 3) {
            const listStart = list.splice(0, pageIndex + 2)
            return [...listStart, '...', list[list.length - 1]]
        }

        if (pageIndex > list.length - 3 && pageIndex < list.length - 1) {
            const listEnd = list.splice(pageIndex - 1)
            return [list[0], '...', ...listEnd]
        }

        const listMiddle = list.splice(pageIndex - 1, 3)
        return [list[0], '...', ...listMiddle, '...', list[list.length - 1]]
    }

    getShowingItems = () => {
        const lastPageItem = this.props.pagination.pageSize * this.props.pagination.page
        const firstItemShown = this.props.totalItemsCount > 0 ? lastPageItem - this.props.pagination.pageSize + 1 : 0
        const lastItemShown = lastPageItem < this.props.totalItemsCount ? lastPageItem : this.props.totalItemsCount
        return {
            from: firstItemShown,
            to: lastItemShown,
        }
    }

    onPageSizeChange = (pageSize: string) => {
        this.props.onPaginationChanged({
            page: 1,
            pageSize: Number(pageSize),
        })
        this.setState({ inputPage: 1 })
    }

    onPageNumberChange = (page: number) => {
        if (!isNaN(page)) {
            this.props.onPaginationChanged({
                page,
                pageSize: this.props.pagination.pageSize,
            })
            this.setState({ inputPage: page })
        }
    }

    onInputFinishTyping = (inputPage: string) => {
        const numberOfPages = this.getNumberOfPages()
        let page = Number(inputPage)
        if (page < 1) {
            page = 1
        } else if (page > numberOfPages) {
            page = numberOfPages
        }

        if (page !== this.props.pagination.page) {
            this.onPageNumberChange(page)
        }

        if (page !== Number(inputPage)) {
            this.setState({ inputPage: this.props.pagination.page })
        }
    }

    onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ inputPage: e.target.value })
    }

    onPrevClick = (pageNumbers: (number | string)[]) => {
        if (!(this.props.pagination.page === Number(pageNumbers[0]))) {
            this.props.onPaginationChanged({
                page: this.props.pagination.page - 1,
                pageSize: this.props.pagination.pageSize,
            })
        }
    }

    onNextClick = (pageNumbers: (number | string)[]) => {
        if (!(this.props.pagination.page === Number(pageNumbers[pageNumbers.length - 1]))) {
            this.props.onPaginationChanged({
                page: this.props.pagination.page + 1,
                pageSize: this.props.pagination.pageSize,
            })
        }
    }

    componentDidUpdate() {
        const totalPage = this.getNumberOfPages()

        if (totalPage < this.props.pagination.page) {
            this.props.onPaginationChanged({
                page: 1,
                pageSize: this.props.pagination.pageSize,
            })
        }
    }

    render() {
        const options = this.props.pageSizeOptions || pageSizeOptions
        const selectOptions = options.map((o) => ({ value: o, name: o }))
        const numberOfPages = this.getNumberOfPages()
        const pageNumbers: (number | string)[] = this.getPageNumbersList(numberOfPages, this.props.pagination.page)
        const showingItems = this.getShowingItems()

        return (
            <Container style={this.props.style}>
                <PageSizeSelection className="page--selection">
                    Show
                    <SingleSelect
                        noSelectOption="-"
                        options={selectOptions}
                        selected={this.props.pagination.pageSize.toString()}
                        onSelect={this.onPageSizeChange}
                        whiteBackground={!this.props.onWhiteBackground}
                        width="5em"
                        height="2.2em"
                        style={{ margin: '0 1em' }}
                    />
                    <ResultCount>
                        {showingItems.from}-{showingItems.to} of {this.props.totalItemsCount}
                    </ResultCount>
                </PageSizeSelection>
                <PageNumberSection>
                    <div>
                        <SectionItem
                            className={this.props.pagination.page === pageNumbers[0] ? '' : 'active'}
                            onClick={() => this.onPrevClick(pageNumbers)}
                        >
                            <Icon icon={faChevronLeft} />
                            Prev
                        </SectionItem>
                        {pageNumbers.map((page, i) => (
                            <Page
                                key={i}
                                className={
                                    this.props.pagination.page === page
                                        ? 'active'
                                        : isNaN(Number(page))
                                        ? 'spreader'
                                        : ''
                                }
                                onClick={() => this.onPageNumberChange(Number(page))}
                            >
                                {page}
                            </Page>
                        ))}
                        <SectionItem
                            className={
                                this.props.pagination.page === pageNumbers[pageNumbers.length - 1] ? '' : 'active'
                            }
                            onClick={() => this.onNextClick(pageNumbers)}
                        >
                            Next
                            <Icon icon={faChevronRight} />
                        </SectionItem>
                    </div>
                    <EnterPageSection>
                        Go to
                        <PageInput
                            className="goto--input"
                            integerOnly
                            max={this.getNumberOfPages()}
                            min={1}
                            placeholder={`${this.props.pagination.page}`}
                            value={this.state.inputPage}
                            whiteBackground={!this.props.onWhiteBackground}
                            onChange={this.onInputChange}
                            onFinishTyping={this.onInputFinishTyping}
                        />
                    </EnterPageSection>
                </PageNumberSection>
            </Container>
        )
    }
}
