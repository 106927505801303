import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useFormikContext } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import ActionButton from 'uiComponents/buttons'
import { ActionIcon } from 'uiComponents/buttons/actionIcon'
import { POI, POIAction } from '../../../models'
import ActionPoiDialog from './actionPoiDialog'

const ActionBlockContainer = styled.div`
    background-color: ${(props) => props.theme.colors.background};
    border-radius: 6px;
    border: 2px dashed ${(props) => props.theme.colors.border};
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-top: 6px;
`

const ActionBlockTexts = styled.div`
    font-size: 0.8em;
`

const ActionBlockTextsLink = styled.div`
    padding-top: 6px;
    & > a {
        text-decoration: none;
        color: ${(props) => props.theme.colors.boyBlue};
    }
`

const ActionBlockButtons = styled.div`
    display: flex;
`

const ActionBlock = () => {
    const [actionDialogVisible, setActionDialogVisible] = useState(false)
    const { values, setValues } = useFormikContext<POI>()
    const [initialValues, setInitialValues] = useState<POIAction | undefined>(undefined)
    const title = values?.action?.content?.[0]?.title
    const actionLink = values?.action?.link

    useEffect(() => {
        if (actionDialogVisible) {
            setInitialValues(values?.action)
        }
    }, [actionDialogVisible])

    const clearActionBlock = useCallback(() => {
        setValues({
            ...values,
            action: undefined,
        })
    }, [values])

    const onCancel = useCallback(() => {
        setActionDialogVisible(false)
        setValues({
            ...values,
            action: initialValues,
        })
    }, [initialValues])

    return (
        <>
            {actionDialogVisible && (
                <ActionPoiDialog onCancel={onCancel} onSave={() => setActionDialogVisible(false)} />
            )}
            <ActionBlockContainer>
                <ActionBlockTexts>
                    <b>
                        {title ||
                            'You can add action block here. Action block is content of popup that will appear for end user after poi selection.'}
                    </b>
                    {actionLink && (
                        <ActionBlockTextsLink>
                            &#x1F517;{' '}
                            <a href={actionLink} target="_blank" rel="noreferrer">
                                {actionLink}
                            </a>
                        </ActionBlockTextsLink>
                    )}
                </ActionBlockTexts>
                <ActionBlockButtons>
                    <ActionButton kind="action" secondary onClick={() => setActionDialogVisible(true)}>
                        {actionLink ? 'Edit' : 'Add'}
                    </ActionButton>
                    {actionLink && (
                        <ActionIcon
                            onClick={clearActionBlock}
                            title="Delete"
                            className="first-icon"
                            icon={faTrash}
                            size="large"
                        />
                    )}
                </ActionBlockButtons>
            </ActionBlockContainer>
        </>
    )
}

export default ActionBlock
