import * as React from 'react'
import { InstructionsCard } from 'snippet/instructionsCard'
import codeIcon from './snippetContainer/code.svg'
import { Account } from 'auth/state'
import { CopySnippetElement, RelativeButton } from 'snippet/copySnippetElement'

interface AddMyselfCardProps {
    account: Account
    detectSnippet: () => void
}

interface AddMyselfCardState {
    copied: boolean
}

export class AddMyselfCard extends React.Component<AddMyselfCardProps, AddMyselfCardState> {
    onCopy = () => {
        this.setState({ copied: true })
        setTimeout(() => this.setState({ copied: false }), 2000)
    }

    render() {
        const { account, detectSnippet } = this.props
        const closedSubHeader = 'If you know HTML and can edit your site, our install only takes one line of code.'
        const openedSubHeader =
            'If you know HTML and can edit your site, simply copy this code snippet and paste it in \
      the top of the <head> tag of your page’s HTML. Remember to add it to all pages where you want Convious \
      to display and track.'

        return (
            <InstructionsCard
                iconSrc={codeIcon}
                header="I know how to add the code myself"
                closedSubHeader={closedSubHeader}
                openedSubHeader={openedSubHeader}
            >
                <CopySnippetElement account={account}>
                    <RelativeButton className="visible" onClick={detectSnippet} size="large">
                        Check now
                    </RelativeButton>
                </CopySnippetElement>
            </InstructionsCard>
        )
    }
}
