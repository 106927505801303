import React, { useContext } from 'react'
import Svg from 'svg'

import ActionButton from 'uiComponents/buttons'
import { Col, Row } from 'uiComponents/flex'
import ConviousLogo from 'images/logo-mini.svg'
import PricePreview from './pricePreview'
import { useHistory, useParams } from 'react-router-dom'
import { BaseRouteParams } from 'hocs'
import ResetToDefault from './resetToDefault'
import { Messages } from 'uiComponents/messages'
import { useDispatch, useSelector } from 'react-redux'
import { removeMessage } from 'uiComponents/messages/actions'
import { getSnackbarMessages } from 'uiComponents/messages/selectors'
import RtpProductsWarning from './rtpProductsWarning'
import StudioContext from './provider/context'
import { DoNotEditSettingsWarningMessage } from './doNotEditWarning'
import './layout.scss'

const GPSStudioLayout: React.FC = ({ children }) => {
    const history = useHistory()
    const messages = useSelector(getSnackbarMessages)
    const dispatch = useDispatch()
    const { accountSlug } = useParams<BaseRouteParams>()
    const { articles, isLoading } = useContext(StudioContext)

    const goBack = () => {
        history.push(`/account/${accountSlug}/dashboard/overview/`)
    }

    return (
        <Row wrap="nowrap" className="gpsStudioLayout">
            <Messages messages={messages} hideMessage={(id) => dispatch(removeMessage(id))} />
            <Col
                span="auto"
                style={{ minWidth: '5rem', flexBasis: '5rem', backgroundColor: '#E7EAEC', padding: '1.5rem 0' }}
            >
                <Row align="center">
                    <Svg style={{ height: 40, width: 40 }} src={ConviousLogo} />
                </Row>
            </Col>
            <Col span="auto" style={{ flexGrow: 1, background: 'white' }}>
                <Row
                    style={{
                        padding: '1rem 1rem 1.5rem 0',
                        position: 'sticky',
                        top: 0,
                        zIndex: 10,
                        background: 'white',
                    }}
                >
                    <Col span={12}>
                        <Row align="flex-end">
                            <Col span="auto">
                                <ActionButton onClick={goBack} secondary style={{ marginRight: '1rem' }} size="large">
                                    Exit
                                </ActionButton>
                                <ActionButton type="submit" size="large">
                                    Save
                                </ActionButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ padding: '0 1rem 0.5rem 1.5rem' }}>
                    <Col span={12}>
                        <div className="header">
                            <h1>Dynamic Pricing</h1>
                            <div className="subtitle" role="doc-subtitle">
                                If you are using dynamic pricing, you can fine-tune the prices here by amplifying
                                certain factors used to calculate your prices. Find more information in our{' '}
                                <a
                                    target="_blank"
                                    href="https://support.convious.com/help/how-to-use-advance-settings-to-fine-tune-pricing-factors"
                                    rel="noreferrer"
                                >
                                    Knowledge Base
                                </a>
                                .
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={12} style={{ minHeight: '1rem' }}>
                        <div className="messagesContainer">
                            <DoNotEditSettingsWarningMessage />
                            {!isLoading && articles.length === 0 && <RtpProductsWarning accountSlug={accountSlug} />}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span="auto" id="settings">
                        {children}
                    </Col>
                    <Col span="auto" id="sidePanel">
                        <aside>
                            <PricePreview />
                            <ResetToDefault />
                        </aside>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default GPSStudioLayout
