import { AsyncAction } from 'store'
import { impersonationStart, impersonationEnd } from './actions'

export function startImpersonation(targetUser: string): AsyncAction<Promise<void>> {
    return async (dispatch, getState, { impersonationService }): Promise<void> => {
        const impersonatorTicket = getState().auth.ticket
        const impersonator = getState().auth.user?.username || ''
        const account = getState().preferences.activeAccount
        await impersonationService.impersonate(targetUser, impersonator, account)
        dispatch(impersonationStart(impersonatorTicket))
    }
}

export function endImpersonation(): AsyncAction<Promise<void>> {
    return async (dispatch, getState, { impersonationService }): Promise<void> => {
        const impersonatorTicket = getState().auth.impersonatorTicket
        const targetUser = getState().auth.user?.username || ''
        const impersonator = getState().auth.user?.impersonatedBy || ''
        await impersonationService.endImpersonation(impersonatorTicket, targetUser, impersonator)
        dispatch(impersonationEnd())
    }
}
