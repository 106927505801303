import { useEffect, useMemo } from 'react'
import { useField } from 'formik'

export const useCustomId = (id?: string) => {
    return useMemo(() => {
        if (!!id) {
            return id
        }

        return `text-input-id-${Math.random()}`
    }, [id])
}

export const useGetComponentState = (name: string) => {
    const [, { error }] = useField(name)

    if (error) {
        return 'error'
    }

    return 'normal'
}

export const focusField = (name: String, element: string = 'input') => {
    try {
        // @ts-ignore
        document.querySelector(`${element}[name="${name}"]`)?.focus()
    } catch (error) {}
}

export const useFocusInputOnMounting = (name: String, element: string = 'input') => {
    useEffect(() => {
        focusField(name, element)
    }, [])
}
