import React from 'react'

export const PaypalIcon = () => {
    return (
        <svg width="131" height="32" viewBox="0 0 131 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M101.539 13.8317C101.146 16.4134 99.1742 16.4134 97.2672 16.4134H96.1821L96.9433 11.5934C96.9892 11.3021 97.2403 11.0875 97.5354 11.0875H98.0329C99.3307 11.0875 100.557 11.0875 101.189 11.8271C101.567 12.2696 101.682 12.9254 101.539 13.8317ZM100.709 7.09656H93.5167C93.0251 7.09656 92.6065 7.45438 92.5299 7.94019L89.6217 26.3816C89.5642 26.7452 89.8454 27.0743 90.2139 27.0743H93.9039C94.2482 27.0743 94.5413 26.8237 94.5947 26.4837L95.4196 21.2557C95.4962 20.7699 95.9148 20.4121 96.4064 20.4121H98.6823C103.42 20.4121 106.153 18.1194 106.868 13.5757C107.19 11.5885 106.881 10.027 105.95 8.93381C104.928 7.73203 103.115 7.09656 100.709 7.09656Z"
                fill="#2790C3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M50.243 13.8317C49.85 16.4134 47.8783 16.4134 45.9715 16.4134H44.8864L45.6473 11.5934C45.6935 11.3021 45.9447 11.0875 46.2395 11.0875H46.7369C48.035 11.0875 49.2614 11.0875 49.8933 11.8271C50.2718 12.2696 50.3862 12.9254 50.243 13.8317ZM49.4133 7.09656H42.221C41.7294 7.09656 41.3108 7.45438 41.2342 7.94019L38.3258 26.3816C38.2686 26.7452 38.5498 27.0743 38.9179 27.0743H42.352C42.8436 27.0743 43.2625 26.7164 43.3391 26.2309L44.1239 21.2557C44.2002 20.7699 44.6191 20.4121 45.1107 20.4121H47.3866C52.1236 20.4121 54.8578 18.1194 55.5725 13.5757C55.8941 11.5885 55.5854 10.027 54.6548 8.93381C53.6318 7.73203 51.8191 7.09656 49.4133 7.09656Z"
                fill="#27346A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M66.1097 20.4522C65.7771 22.4201 64.2149 23.7411 62.2225 23.7411C61.2237 23.7411 60.424 23.4198 59.9101 22.8115C59.4007 22.2084 59.209 21.3492 59.3703 20.3924C59.6806 18.4424 61.2677 17.0787 63.2297 17.0787C64.2078 17.0787 65.0016 17.4032 65.5259 18.017C66.0534 18.6354 66.2606 19.5003 66.1097 20.4522ZM70.909 13.7494H67.4653C67.1702 13.7494 66.9191 13.9637 66.8732 14.2556L66.7216 15.2185L66.4808 14.8694C65.7351 13.7869 64.073 13.4252 62.4132 13.4252C58.6084 13.4252 55.3583 16.3084 54.7258 20.3524C54.3964 22.3697 54.8638 24.2974 56.0077 25.643C57.0585 26.8794 58.5583 27.394 60.3452 27.394C63.412 27.394 65.1128 25.4239 65.1128 25.4239L64.959 26.381C64.9014 26.7449 65.1827 27.0743 65.5511 27.0743H68.6526C69.1442 27.0743 69.5628 26.7168 69.6394 26.231L71.5012 14.4421C71.5587 14.0781 71.2775 13.7494 70.909 13.7494Z"
                fill="#27346A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M117.405 20.4522C117.073 22.4201 115.511 23.7411 113.519 23.7411C112.519 23.7411 111.72 23.4198 111.206 22.8115C110.696 22.2084 110.505 21.3492 110.666 20.3924C110.976 18.4424 112.563 17.0787 114.526 17.0787C115.504 17.0787 116.298 17.4032 116.822 18.017C117.349 18.6354 117.556 19.5003 117.405 20.4522ZM122.205 13.7494H118.761C118.466 13.7494 118.215 13.9637 118.169 14.2556L118.017 15.2185L117.777 14.8694C117.031 13.7869 115.369 13.4252 113.709 13.4252C109.904 13.4252 106.654 16.3084 106.022 20.3524C105.692 22.3697 106.16 24.2974 107.303 25.643C108.355 26.8794 109.854 27.394 111.641 27.394C114.708 27.394 116.409 25.4239 116.409 25.4239L116.255 26.381C116.197 26.7449 116.478 27.0743 116.847 27.0743H119.948C120.44 27.0743 120.859 26.7168 120.935 26.231L122.797 14.4421C122.854 14.0781 122.573 13.7494 122.205 13.7494Z"
                fill="#2790C3"
            />
            <mask
                id="mask0_14_670"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="131"
                height="32"
            >
                <path d="M0 31.7346H130.778V0H0V31.7346Z" fill="white" />
            </mask>
            <g mask="url(#mask0_14_670)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M89.251 13.7494H85.7895C85.4585 13.7494 85.1488 13.9136 84.963 14.1874L80.1882 21.2199L78.1644 14.4621C78.0381 14.0393 77.6489 13.7494 77.2074 13.7494H73.8047C73.3939 13.7494 73.1046 14.1534 73.2371 14.5423L77.0486 25.73L73.4637 30.7886C73.1821 31.1855 73.4663 31.7347 73.9527 31.7347H77.411C77.7388 31.7347 78.0455 31.574 78.2323 31.3045L89.7439 14.6906C90.0193 14.2931 89.7349 13.7494 89.251 13.7494Z"
                    fill="#27346A"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M126.264 7.60306L123.312 26.3816C123.255 26.7455 123.536 27.0743 123.904 27.0743H126.873C127.365 27.0743 127.783 26.7164 127.86 26.2309L130.77 7.78957C130.828 7.42561 130.547 7.09656 130.178 7.09656H126.856C126.561 7.09656 126.31 7.31151 126.264 7.60306Z"
                    fill="#2790C3"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.6056 30.4272L14.5724 24.2967L14.6348 23.957C14.6813 23.6616 14.8326 23.3914 15.0605 23.1968C15.2883 23.0022 15.5789 22.8949 15.8786 22.8949H16.6462C17.9259 22.8949 19.1015 22.7582 20.1407 22.4886C21.2494 22.2012 22.2356 21.7535 23.0714 21.1591C23.9578 20.5288 24.7022 19.714 25.2843 18.7378C25.8984 17.708 26.3493 16.4681 26.6247 15.0529C26.8681 13.8033 26.9137 12.6846 26.7608 11.7275C26.5989 10.7165 26.2094 9.85375 25.6033 9.16268C25.2358 8.74409 24.7655 8.38143 24.2057 8.0847L24.1924 8.07759L24.1921 8.06143C24.3876 6.81407 24.3802 5.77359 24.1698 4.87791C23.9584 3.9803 23.5304 3.17254 22.8604 2.40906C21.4721 0.827165 18.947 0.0249023 15.3556 0.0249023H5.49214C5.16244 0.0249023 4.84244 0.142882 4.59161 0.357185C4.34078 0.571488 4.17464 0.869185 4.12292 1.195L0.0159347 27.2388C-0.0215602 27.4764 0.0466418 27.7178 0.203086 27.9001C0.359531 28.0834 0.587086 28.1885 0.827571 28.1885H6.94668L6.94119 28.2175L6.52098 30.8823C6.48866 31.0895 6.54814 31.2996 6.68422 31.4586C6.8203 31.618 7.01844 31.7097 7.22789 31.7097H12.3602C12.6482 31.7097 12.9271 31.6066 13.146 31.4195C13.3648 31.2323 13.5102 30.9728 13.5552 30.6887L13.6056 30.4272Z"
                    fill="#27346A"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.0894 8.09535C10.1543 7.68259 10.4191 7.34481 10.7756 7.17382C10.9378 7.09624 11.1189 7.05293 11.3086 7.05293H19.04C19.956 7.05293 20.81 7.11305 21.5909 7.23879C21.814 7.27467 22.0309 7.31604 22.2419 7.36291C22.453 7.40978 22.6576 7.46182 22.8557 7.52C22.955 7.54877 23.0523 7.57915 23.1483 7.61115C23.5316 7.7385 23.8885 7.88848 24.2169 8.06238C24.6041 5.59386 24.2143 3.91402 22.879 2.39257C21.409 0.717576 18.7533 0 15.3555 0H5.49203C4.79772 0 4.20653 0.505212 4.09825 1.19111L-0.00873979 27.2349C-0.0898711 27.7488 0.307381 28.2133 0.827462 28.2133H6.91651L10.0894 8.09535Z"
                    fill="#27346A"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.2168 8.06238C24.1874 8.25082 24.1547 8.4425 24.116 8.63999C22.8156 15.3183 18.3653 17.6271 12.6816 17.6271H9.7874C9.09309 17.6271 8.50675 18.1327 8.39847 18.8186L6.49624 30.8787C6.42546 31.328 6.77293 31.7346 7.22772 31.7346H12.3603C12.9677 31.7346 13.4848 31.2928 13.5796 30.6925L13.63 30.4317L14.5968 24.3006L14.6592 23.9619C14.7539 23.3616 15.271 22.9198 15.8784 22.9198H16.6461C21.6183 22.9198 25.5117 20.8996 26.6495 15.0574C27.1246 12.6164 26.8789 10.5787 25.6219 9.14618C25.2418 8.71304 24.7686 8.35458 24.2168 8.06238Z"
                fill="#2790C3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.8556 7.52005C22.6575 7.46187 22.4529 7.40983 22.2418 7.36296C22.0308 7.31609 21.8139 7.27472 21.5908 7.23884C20.8099 7.1131 19.9559 7.05298 19.0399 7.05298H11.3085C11.1188 7.05298 10.9378 7.09629 10.7755 7.17387C10.419 7.34486 10.1542 7.68264 10.0893 8.0954L8.44595 18.5145L8.39844 18.8186C8.50672 18.1327 9.09306 17.6272 9.78737 17.6272H12.6816C18.3653 17.6272 22.8156 15.3184 24.1159 8.64005C24.1547 8.44255 24.1874 8.25088 24.2168 8.06243C23.8884 7.88853 23.5315 7.73855 23.1482 7.6112C23.0522 7.5792 22.9549 7.54882 22.8556 7.52005Z"
                fill="#1F264F"
            />
        </svg>
    )
}
