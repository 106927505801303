import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import { parse } from 'tinyduration'
import { SingleSelect, NumberInput } from 'uiComponents/input'
import { Row } from 'uiComponents/flex'
import { ValidationMessage } from 'uiComponents/form/formElements'

const ticketDurationOptions = [
    { name: 'Days', value: 'day' as const },
    { name: 'Months', value: 'month' as const },
    { name: 'Years', value: 'year' as const },
]

export type TicketDurationType = 'day' | 'month' | 'year'

export interface TicketDuration {
    type: TicketDurationType
    value: number | null
}

interface TicketDurationFormProps {
    ticketDuration: TicketDuration | null
    onTicketDurationChange: (t: TicketDuration | null) => void
    validationError: string | null
}

function TicketDurationForm({ ticketDuration, onTicketDurationChange, validationError }: TicketDurationFormProps) {
    const [ticketDurationType, setTicketDurationType] = useState<TicketDurationType>(ticketDuration?.type || 'day')
    function onDurationValueChange(e: React.ChangeEvent<HTMLInputElement>) {
        const newValue = e.target.value === '' ? null : +e.target.value
        if (newValue) {
            onTicketDurationChange({
                type: ticketDurationType,
                value: newValue,
            })
        } else {
            onTicketDurationChange(null)
        }
    }

    useEffect(() => {
        if (ticketDuration && ticketDuration.type !== ticketDurationType) {
            setTicketDurationType(ticketDuration.type)
        }
    }, [ticketDuration])

    function onTicketDurationTypeChange(value: string) {
        const newValue = value as TicketDurationType
        setTicketDurationType(newValue)

        if (ticketDuration) {
            onTicketDurationChange({
                type: value as TicketDurationType,
                value: ticketDuration.value,
            })
        }
    }
    return (
        <>
            <Row>
                <NumberInput
                    integerOnly
                    placeholder="-"
                    value={ticketDuration?.value || ''}
                    onChange={onDurationValueChange}
                    style={{ width: '4em', height: '3em', marginRight: '2em' }}
                />
                <SingleSelect
                    options={ticketDurationOptions}
                    selected={ticketDurationType}
                    height="2.5rem"
                    width="12em"
                    onSelect={onTicketDurationTypeChange}
                />
            </Row>
            {validationError && (
                <ValidationMessage
                    className="validation-message-visible"
                    style={{ marginTop: '1em', display: 'block' }}
                >
                    {validationError}
                </ValidationMessage>
            )}
        </>
    )
}

const defaultDuration = { type: 'day' as const, value: null }

function parseTicketDuration(duration: string | null): TicketDuration {
    if (!duration) {
        return defaultDuration
    }
    try {
        const parsed = parse(duration)
        if (parsed.years) {
            return {
                type: 'year',
                value: parsed.years,
            }
        }
        if (parsed.months) {
            return {
                type: 'month',
                value: parsed.months,
            }
        }
        if (parsed.days) {
            return {
                type: 'day',
                value: parsed.days,
            }
        }
    } catch (e) {
        if (e.message === 'Invalid duration') {
            return defaultDuration
        }
        throw e
    }
    return defaultDuration
}

const durationTypeMap = {
    day: 'D',
    month: 'M',
    year: 'Y',
}

function serializeTicketDuration(duration: TicketDuration | null): string | null {
    if (!duration || !duration.value) {
        return null
    }

    return `P${duration.value}${durationTypeMap[duration.type]}`
}

interface TicketDurationEditorProps {
    name: string
}

export function ObjectTicketDurationEditor({ name }: TicketDurationEditorProps) {
    const [field, meta, helpers] = useField<TicketDuration | null>(name)
    const validationError = meta.touched && meta.error ? meta.error : null

    return (
        <TicketDurationForm
            ticketDuration={field.value || defaultDuration}
            onTicketDurationChange={helpers.setValue}
            validationError={validationError}
        />
    )
}

export function TicketDurationEditor({ name }: TicketDurationEditorProps) {
    const [field, meta, helpers] = useField<string | null>(name)
    const validationError = meta.touched && meta.error ? meta.error : null
    const parsedDuration = parseTicketDuration(field.value)
    return (
        <TicketDurationForm
            ticketDuration={parsedDuration}
            onTicketDurationChange={(d) => helpers.setValue(serializeTicketDuration(d))}
            validationError={validationError}
        />
    )
}
