import { get } from 'lodash'
import { ReactNode } from 'react'

interface FactoryTextSwitcherProps<SliceKeys extends string, WordKeys extends string, P extends object = {}> {
    slice: Record<SliceKeys, Record<WordKeys, string | ((props: P) => string | ReactNode)>>
    getSliceKey?: (props: P) => SliceKeys
    defaultSliceKey?: SliceKeys
}

interface TextSwitcherProps<T = string, N = string> {
    type?: T
    name: N
}

function createTextSwitcher<SliceKeys extends string, WordKeys extends string, P extends object = {}>(
    factoryProps: FactoryTextSwitcherProps<SliceKeys, WordKeys, P>,
) {
    return ({ type, name, ...rest }: TextSwitcherProps<SliceKeys, WordKeys> & P) => {
        const finalType =
            type ||
            (factoryProps.getSliceKey && factoryProps.getSliceKey(rest as P)) ||
            factoryProps.defaultSliceKey ||
            undefined
        const text = get(factoryProps.slice, `${finalType}.${name}`)
        const textType = typeof text

        if (textType === 'function') {
            return text(rest)
        }

        return text
    }
}

export default createTextSwitcher
