import { format } from 'date-fns'
import { DateFormats } from 'utils/dates'

export const formatDateTimeStr = (date?: string, time?: string): string => {
    const splitTime = time?.split(':')

    if (splitTime && splitTime.length > 1 && date) {
        const DateTime = new Date(date)
        DateTime.setHours(parseInt(splitTime[0], 10))
        DateTime.setMinutes(parseInt(splitTime[1], 10))
        DateTime.setSeconds(0)

        return DateTime.toISOString()
    }

    return ''
}

export const decodeDateTimeStr = (dateTimeStr: string | undefined) => {
    if (!dateTimeStr) {
        return { date: '', time: '' }
    }

    const dateTime = new Date(dateTimeStr)

    return {
        date: format(dateTime, 'yyyy-MM-dd'),
        time: format(dateTime, DateFormats.LONG_TIME),
    }
}
