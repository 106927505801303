import React, { useContext } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { RuleFormContext } from './formContext'
import { Row, Col } from 'uiComponents/flex'
import { TextArea } from 'uiComponents/input'
import { FormItem, FormItemName } from 'uiComponents/form/formElements'
import { Tabs } from 'uiComponents/navigation/tabs'

function TranslationsSection() {
    const { locales, formTranslations, rule, activeLangIndex, markErrors, updateTranslations, setActiveLangIndex } =
        useContext(RuleFormContext)

    const onTranslationChange = (value: string, lang: string) => {
        const updatedTranslations = cloneDeep(formTranslations)
        updatedTranslations.text[lang] = value
        updateTranslations(updatedTranslations)
    }

    const sameProduct = rule.rulePayload.productUuidPaid === rule.rulePayload.productUuidFree

    return (
        <Row>
            <Col span={5}>
                <FormItemName>Translations</FormItemName>
                <Tabs
                    titles={locales.map((l) => l.name)}
                    setActive={setActiveLangIndex}
                    activeTabIndex={activeLangIndex}
                >
                    {locales.map((l) => {
                        const languageError = markErrors && !formTranslations.text[l.code]
                        return (
                            <div key={l.code}>
                                <FormItem htmlFor={`translations-${l.code}`}>
                                    <FormItemName>Discount banner text</FormItemName>
                                    <TextArea
                                        id="batch-note"
                                        value={formTranslations.text[l.code] || ''}
                                        placeholder="Banner text"
                                        onChange={(e) => onTranslationChange(e.target.value, l.code)}
                                        maxLength={sameProduct ? 40 : 300}
                                        showCharsLeft
                                        status={languageError ? 'error' : 'normal'}
                                        block
                                    />
                                </FormItem>
                            </div>
                        )
                    })}
                </Tabs>
            </Col>
            <Col span={7} />
        </Row>
    )
}

export default TranslationsSection
