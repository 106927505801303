import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-typed'
import { usePrevious, useAccountChange } from 'reactUtils'
import { History } from 'history'
import { TableLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { ActionButton, ActionButtonA } from 'uiComponents/buttons'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { TextArea } from 'uiComponents/input'
import { RichTextInput } from 'uiComponents/input'
import cloneDeep from 'lodash/cloneDeep'
import { NavigationBack } from 'uiComponents/navigation/navigationBack'
import { dummyCopyDetails, CopyDetails, Copy } from 'copyEditor/copyEditorService'
import { CopyEditorServiceContext } from 'copyEditor/context'
import { languagesDict } from 'utils/languages'
import { delay } from 'utils'
import { textContainsHTMLTag } from 'utils/formFieldChecks'

const Container = styled.div`
    background: ${(props) => props.theme.colors.white};
    border-bottom: 2px solid ${(props) => props.theme.colors.background};
    padding: 2em;
    display: flex;
    font-size: 0.875em;
    justify-content: space-between;
`
const NameRow = styled.div`
    background: ${(props) => props.theme.colors.white};
    border-bottom: 2px solid ${(props) => props.theme.colors.background};
    padding: 2em 0 1.3em 2em;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const OptionsColumns = styled.div`
    display: flex;
    margin-top: 1em;
`
const LanguageColumn = styled.div`
    width: 16em;
    padding-left: 7em;
    font-weight: 300;
`
const ResetColumn = styled.div`
    width: 9.5em;
`
const ButtonWrapper = styled.div`
    padding: 2em 1.2em;
    text-align: right;
`
const PlainTextArea = styled(TextArea)`
    font-weight: normal;
    width: 100%;
`
const RichTextArea = styled(RichTextInput)`
    width: 18em;
`

interface Translation {
    language: string
    locale: string
    text: string
    defaultText: string
    richText: boolean
}

interface TranslationBoxProps {
    translation: Translation
    markErrors: boolean
    onChange: (v: string) => void
    onResetDefault: () => void
}

function TranslationBox(props: TranslationBoxProps) {
    const [value, setValue] = useState<string>('')

    useEffect(() => {
        setValue(props.translation.text)
    }, [])

    const prevTranslation = usePrevious(props.translation)
    useEffect(() => {
        const newValue = props.translation.text
        // @ts-ignore: Object is possibly 'undefined'.
        if (!!prevTranslation && prevTranslation.text !== newValue && value !== newValue) {
            setValue(newValue)
        }
    }, [props.translation])

    const onChange = (v: string) => {
        setValue(v)
        props.onChange(v)
    }

    const markError = props.markErrors && (!value.trim() || (!props.translation.richText && textContainsHTMLTag(value)))

    return (
        <Container>
            <div style={{ width: '100%' }}>
                {props.translation.richText ? (
                    <RichTextArea
                        id={`${props.translation.locale}-translation`}
                        onChange={(v) => onChange(v)}
                        value={value}
                        withColor
                        colorPickerOnRight
                        withLink
                        maxLength={4000}
                        status={markError ? 'error' : 'normal'}
                        style={{ fontSize: '0.875rem' }}
                    />
                ) : (
                    <PlainTextArea
                        id={`${props.translation.locale}-translation`}
                        name={`${props.translation.locale}-translation`}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        maxLength={4000}
                        status={markError ? 'error' : 'normal'}
                        locale={props.translation.locale}
                    />
                )}
            </div>
            <OptionsColumns>
                <LanguageColumn>{props.translation.language}</LanguageColumn>
                <ResetColumn>
                    <ActionButton
                        id={`${props.translation.locale}-default-button`}
                        secondary
                        kind="action"
                        size="small"
                        disabled={
                            props.translation.text === props.translation.defaultText || !props.translation.defaultText
                        }
                        onClick={props.onResetDefault}
                    >
                        Reset to default
                    </ActionButton>
                </ResetColumn>
            </OptionsColumns>
        </Container>
    )
}

interface CopyEditPageProps {
    history: History
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<any>
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    hideMessage: (id: string) => void
}

function CopyEditPage(props: CopyEditPageProps) {
    const copyEditorService = useContext(CopyEditorServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [copy, setCopy] = useState<CopyDetails>(dummyCopyDetails)
    const [translations, setTranslations] = useState<Translation[]>([])
    const [markErrors, setMarkErrors] = useState<boolean>(false)

    async function getCopyDetails() {
        setLoading(true)
        try {
            const copyDetails = await copyEditorService.getCopyDetails(props.accountSlug, props.match.params.copySlug)
            setCopy(copyDetails)
            configureTranslations(copyDetails)
        } catch {
            setLoading(false)
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    const configureTranslations = (copyDetails: CopyDetails) => {
        const mappedTranslations = copyDetails.locales.map((l) => {
            const language = languagesDict.find((i) => i.value === l)
            const custom = copyDetails.custom.find((i) => i.locale === l)
            const defaultCopy = copyDetails.default.find((i) => i.locale === l)
            return {
                language: language ? language.name : l,
                locale: l,
                text: custom ? custom.text : defaultCopy ? defaultCopy.text : '',
                defaultText: defaultCopy ? defaultCopy.text : '',
                richText: copyDetails.editorType === 'RICH_TEXT',
            }
        })
        mappedTranslations.sort((a, b) => sortLanguages(a, b, copyDetails.defaultLocale))
        setTranslations(mappedTranslations)
        setLoading(false)
    }

    const sortLanguages = (a: Translation, b: Translation, defaultLocale: string) => {
        if (a.locale === defaultLocale) {
            return -1
        }
        if (b.locale === defaultLocale) {
            return 1
        }
        if (a.language < b.language) {
            return -1
        }
        if (a.language > b.language) {
            return 1
        }
        return 0
    }

    useEffect(() => {
        getCopyDetails()
    }, [])

    useAccountChange(props.accountSlug, () => navigateBack())

    const onTextAreaChange = (newValue: string, i: number) => {
        const updatedTranslations = cloneDeep(translations)
        updatedTranslations[i].text = newValue
        setTranslations(updatedTranslations)
    }

    const onResetDefault = (i: number) => {
        const updatedTranslations = cloneDeep(translations)
        updatedTranslations[i].text = updatedTranslations[i].defaultText
        setTranslations(updatedTranslations)
    }

    const onResetAllDefault = () => {
        const updatedTranslations = translations.map((tr) => ({
            ...tr,
            text: tr.defaultText,
        }))
        setTranslations(updatedTranslations)
    }

    const checkCopiesValid = async (copies: Copy[]) => {
        if (copies.find((c) => !c.text.trim())) {
            await flashErrorMessage('Please enter values for all translations.')
            return false
        } else if (copy.editorType === 'TEXT' && !!copies.find((c) => textContainsHTMLTag(c.text))) {
            await flashErrorMessage(
                'HTML tags are not allowed in plain text copies. \
        Please contact customer support if this copy should accept formatting.',
            )
            return false
        }
        return true
    }

    const flashErrorMessage = async (message: string) => {
        setMarkErrors(true)
        props.replaceMessages('validation_error', 'error', message)
        await delay(4000)
        props.hideMessage('validation_error')
    }

    const checkSameAsDefault = (copies: (Copy | Translation)[]) => {
        return copies.every((c) => c.text === translations.filter((t) => t.locale === c.locale)[0].defaultText)
    }

    const checkNoDefaults = (copies: Translation[]) => {
        return copies.every((c) => !c.defaultText)
    }

    const onSave = async () => {
        const copies = translations.map((t) => ({
            locale: t.locale,
            text: t.text,
        }))
        if (await checkCopiesValid(copies)) {
            try {
                setLoading(true)
                if (checkSameAsDefault(copies)) {
                    await copyEditorService.deleteCopyset(props.accountSlug, copy.key)
                    if (props.navigation.query().searchBy === 'key' && !checkSameAsDefault(copy.custom)) {
                        props.navigation.addQueryWithReplace({ search: copy.key })
                    }
                } else {
                    await copyEditorService.updateCopy(props.accountSlug, copy.key, copies)
                }
                navigateBack()
            } catch {
                setLoading(false)
                props.replaceMessages(
                    'unknown_error',
                    'error',
                    'Oops! We could not save the copies. Please try again later.',
                )
            }
        }
    }

    const navigateBack = () => {
        props.history.push(`/account/${props.accountSlug}/engage/copy_editor/home${location.search}`)
    }

    return (
        <>
            <NavigationBack onClick={navigateBack} text="Back to Search results" topOffset="-8em" />
            {loading && <TableLoader />}
            {!loading && (
                <>
                    <NameRow>
                        <div>Key: {copy.name ? `${copy.name} (${copy.key})` : copy.key}</div>
                        <ActionButton
                            id="delete-default-copyset"
                            secondary
                            kind="action"
                            onClick={onResetAllDefault}
                            disabled={checkSameAsDefault(translations) || checkNoDefaults(translations)}
                            style={{ marginRight: '2.7em' }}
                        >
                            Reset all to default
                        </ActionButton>
                    </NameRow>
                    {translations.length > 0 &&
                        translations.map((item, index) => (
                            <TranslationBox
                                key={item.locale}
                                translation={item}
                                onChange={(v) => onTextAreaChange(v, index)}
                                onResetDefault={() => onResetDefault(index)}
                                markErrors={markErrors}
                            />
                        ))}
                    <ButtonWrapper>
                        <ActionButtonA
                            size="large"
                            href={`/account/${props.accountSlug}/engage/copy_editor/home${location.search}`}
                            secondary
                            style={{ marginRight: '1.5em' }}
                            kind="action"
                        >
                            Cancel
                        </ActionButtonA>
                        <ActionButton id="save-translations" size="large" onClick={onSave}>
                            Save
                        </ActionButton>
                    </ButtonWrapper>
                </>
            )}
        </>
    )
}

export default withNavigation(CopyEditPage)
