import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import checkIcon from 'uiComponents/input/checkIcon.svg'
import Svg from '../../svg'

const Container = styled.div`
    position: relative;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-vertical: 1.75em;
    background: ${(props) => props.theme.colors.white};
`

const Points = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    z-index: 5;

    &:first-child {
        align-items: flex-start;
    }

    &:last-child {
        align-items: flex-end;
    }
`

const Point = styled.div`
    font-size: 0.8em;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: ${(props) => props.theme.colors.textLight};
    color: ${(props) => props.theme.colors.white};
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
`

const PointDone = styled(Point)`
    background-color: ${(props) => props.theme.colors.status.success};
`

const PointActive = styled(Point)`
    background-color: ${(props) => props.theme.colors.status.success};
`

const Checkmark = styled(Svg)`
    width: 1em;
    color: ${(props) => props.theme.colors.white};
`

const Line = styled.div`
    position: absolute;
    height: 4px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.border};
    z-index: 1;
    top: 11px;
`

const Label = styled.div`
    font-size: 0.9em;
    color: ${(props) => props.theme.colors.aluminiumShades['30']};
    margin-top: 6px;

    &:last-child {
        text-align: right;
    }
`

const LabelActive = styled(Label)`
    color: ${(props) => props.theme.colors.textDark};
`

interface ProgressLineProps {
    width: string
    left: string
    background: string
}

const ProgressLine = styled.div<ProgressLineProps>`
    position: absolute;
    height: 4px;
    width: ${(props) => props.width};
    background: ${(props) => props.background};
    z-index: 2;
    top: 11px;
    left: ${(props) => props.left};
`

interface Item {
    name: string
    active?: boolean
    done?: boolean
}

interface ProgressBarProps {
    items: Item[]
}

const ProgressBar = ({ items }: ProgressBarProps) => {
    const theme = useTheme()

    const progressLines = items.slice(0, -1).map((item, index) => {
        return {
            done: item.done && items[index + 1]?.done,
            active: !item.done && item.active,
            inProgress: item.done && !items[index + 1]?.done,
            none: !item.done,
        }
    })

    const progressLinesWidth = 100 / progressLines.length

    return (
        <Container>
            {progressLines.map((item, index) => (
                <ProgressLine
                    key={`progress-${index}`}
                    width={`${progressLinesWidth}%`}
                    left={`${index * progressLinesWidth}%`}
                    background={
                        item.done
                            ? theme.colors.status.success
                            : item.active
                            ? theme.colors.textLight
                            : item.inProgress
                            ? theme.colors.status.success
                            : 'none'
                    }
                />
            ))}
            <Line />
            <Wrapper>
                {items.map((item, index) => {
                    return (
                        <Points key={item.name}>
                            {item.done ? (
                                <>
                                    <PointDone>
                                        <Checkmark src={checkIcon} />
                                    </PointDone>
                                    <Label>{item.name}</Label>
                                </>
                            ) : item.active ? (
                                <>
                                    <PointActive>{index + 1}</PointActive>
                                    <LabelActive>{item.name}</LabelActive>
                                </>
                            ) : (
                                <>
                                    <Point>{index + 1}</Point>
                                    <Label>{item.name}</Label>
                                </>
                            )}
                        </Points>
                    )
                })}
            </Wrapper>
        </Container>
    )
}

export default ProgressBar
