import React from 'react'
import { Col, Row } from 'uiComponents/flex'
import { DateRangeFieldFormik } from 'uiComponents/input/date'
import TextInputFieldFormik from 'uiComponents/input/textInput/textInputFieldFormik'

const CodeSection = () => {
    return (
        <Row align="space-between" gutter={0}>
            <Col span={6}>
                <TextInputFieldFormik name="code" placeholder="Give your codes a name" label="Code" />
            </Col>
            <Col span="auto" style={{ width: '196px' }}>
                <DateRangeFieldFormik name="validity" label="Validity" allowFutureDateSelection />
            </Col>
        </Row>
    )
}

export default CodeSection
