import { isBefore, startOfDay } from 'date-fns'
import { ConfigurationVersions } from 'engageTools/studio/schema'

interface StatusMessage {
    status: 'disabled' | 'success' | 'error' | 'blue' | 'warn'
    helperStatus: Array<'upcoming' | 'expired' | 'duplicate'>
    publishState: boolean
}

export const getStatus = (tool: Partial<ConfigurationVersions>): StatusMessage => {
    const helperStatus: StatusMessage['helperStatus'] = []
    let status: StatusMessage['status'] = 'disabled'
    let publishState = false
    const isToolExpired = tool.validTo && isBefore(startOfDay(tool.validTo), startOfDay(new Date()))

    if (tool.current && !!tool.current.publishedAt) {
        status = 'success'
        publishState = true
    } else if (!tool.current && !!tool.next && !!tool.next.unpublishedAt) {
        status = 'error'
        publishState = false
    } else if (!!tool.next && !!tool.next.createdAt) {
        status = 'disabled'
        publishState = false
    }

    if (tool.validFrom && new Date(tool.validFrom) > new Date()) {
        status = 'blue'
        helperStatus.push('upcoming')
    } else if (isToolExpired) {
        status = 'warn'
        helperStatus.push('expired')
    }

    if (!!tool?.duplicates?.length) {
        status = 'warn'
        helperStatus.push('duplicate')
    }

    return {
        status,
        publishState,
        helperStatus,
    }
}

export const formatStatusMessage = (status: StatusMessage): string => {
    let message = 'Inactive'

    if (status.status === 'success' || status.publishState) {
        message = 'Published'
    } else if (status.status === 'error') {
        message = 'Unpublished'
    }

    if (status.helperStatus?.includes('upcoming')) {
        message = 'Upcoming, ' + message.toLowerCase()
    } else if (status.helperStatus?.includes('expired')) {
        message = 'Expired, ' + message.toLowerCase()
    }

    if (status.helperStatus?.includes('duplicate')) {
        message = message + ', duplicate'
    }

    return message
}
