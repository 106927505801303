export const currencyMapper = (currencyCode?: string): string => {
    switch (currencyCode?.toUpperCase()) {
        case 'GBP':
            return '£'
        case 'US':
            return '$'
        case 'USD':
            return '$'
        case 'EUR':
            return '€'
        default:
            return currencyCode || ''
    }
}
