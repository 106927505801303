import React from 'react'
import styled from 'styled-typed'
import { FormItem } from 'ticketTemplates/studio/imports'
import { FormSectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { Col } from 'uiComponents/flex'
import Infotip from 'uiComponents/infotip'
import { ActionButtonBaseCss, actionCss } from 'uiComponents/buttons'
import DownloadLink from 'uiComponents/downloadLink'
import { LoginService } from 'http/loginService'

interface DownloadSectionProps {
    accountSlug: string
    backofficeEndpoint: string
    loginService: LoginService
    downloadData: any
}

const ToggleLabelCol = styled(Col)`
    display: flex;
    align-items: center;
`

const DownloadButtonImitation = styled.div`
    ${ActionButtonBaseCss}
    ${actionCss}
`

export function DownloadSection(props: DownloadSectionProps) {
    return (
        <FormSectionWrapper style={{ padding: '1em' }}>
            <FormItem>
                <ToggleLabelCol span={8}>
                    Download example PDF
                    <Infotip maxWidth="16em" pointer="left">
                        Download the PDF to see how the actual file will look like.
                    </Infotip>
                </ToggleLabelCol>
                <Col span={4} className="justify-right">
                    <DownloadLink
                        loginService={props.loginService}
                        downloadEndpoint={`${props.backofficeEndpoint}api/v1/accounts/${props.accountSlug}/ticket_configs/preview/pdf/`}
                        downloadData={props.downloadData}
                    >
                        <DownloadButtonImitation>Download</DownloadButtonImitation>
                    </DownloadLink>
                </Col>
            </FormItem>
        </FormSectionWrapper>
    )
}
