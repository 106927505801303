import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flatten } from 'lodash'
import { endpoints, selectGetOrderDetailsResult, selectListOrderResult } from 'orders/reduxQueries'
import { IOrder, OrdersListRequestPayload, IOrderItemOption } from 'orders/schema'
import { State } from 'store'
import { IOrderItems } from 'orders/schema'
import { ORDERS_TABLE_COLUMNS } from './ordersTable/header/utilitiesHeader/columnsConfigurator/config'
import { ColumnConfig } from './ordersTable/header/utilitiesHeader/columnsConfigurator'

const initialState = {
    lastArgs: {},
    selectedOrders: {} as Record<string, boolean>,
    selectedItems: {} as Record<string, Record<string, boolean>>,
    selectedOrder: null as IOrder | null,
    tableColumns: ORDERS_TABLE_COLUMNS,
}

const orderSlice = createSlice({
    name: 'orderTable',
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(endpoints.getListOrders.matchPending, (state, action) => {
            state.lastArgs = action.meta.arg.originalArgs
        })
    },
    reducers: {
        toggleOrder(state, action: PayloadAction<{ orderUuid: string; selected: boolean }>) {
            state.selectedOrders[action.payload.orderUuid] = action.payload.selected
        },
        toggleAllOrders(state, action: PayloadAction<Record<string, boolean>>) {
            state.selectedItems = {}
            state.selectedOrders = action.payload
        },
        toggleOrderItem(state, action: PayloadAction<{ tableId: string; rowId: string }>) {
            state.selectedItems[action.payload.tableId] = {
                ...(state.selectedItems[action.payload.tableId] || {}),
                [action.payload.rowId]: !state.selectedItems[action.payload.tableId]?.[action.payload.rowId],
            }
        },
        toggleAllOrderItems(state, action: PayloadAction<{ tableId: string; rows: Record<string, boolean> }>) {
            state.selectedItems[action.payload.tableId] = {
                ...(state.selectedItems[action.payload.tableId] || {}),
                ...action.payload.rows,
            }
        },
        toggleAllOrderItemsIfOrderSelected(
            state,
            action: PayloadAction<{ tableId: string; rows: Record<string, boolean> }>,
        ) {
            if (state.selectedOrders[action.payload.tableId]) {
                state.selectedItems[action.payload.tableId] = {
                    ...(state.selectedItems[action.payload.tableId] || {}),
                    ...action.payload.rows,
                }
            }
        },
        selectOrder(state, action: PayloadAction<IOrder | null>) {
            state.selectedOrder = action.payload
        },
        updateTableColumns(state, action: PayloadAction<ColumnConfig[]>) {
            state.tableColumns = action.payload
        },
        resetState(state) {
            state.selectedOrders = {}
            state.selectedItems = {}
        },
    },
})

export const {
    resetState,
    toggleOrder,
    toggleAllOrders,
    toggleOrderItem,
    toggleAllOrderItems,
    toggleAllOrderItemsIfOrderSelected,
    selectOrder,
    updateTableColumns,
} = orderSlice.actions
export default orderSlice.reducer
export const getOrdersTableInitialState = orderSlice.getInitialState

export const applySavedOrderTableState = (saved?: typeof initialState) => {
    const initialState = getOrdersTableInitialState()

    if (!saved) {
        return initialState
    }

    if (!saved.tableColumns) {
        saved.tableColumns = initialState.tableColumns
    }
    return saved
}

const getSelectedOrders = (state: State) => state.orderTable.selectedOrders
export const getSelectedOrdersList = createSelector(getSelectedOrders, (selectedOrders) =>
    Object.keys(selectedOrders).filter((key) => !!selectedOrders[key]),
)
export const getSelectedOrderById = (id: string) =>
    createSelector(getSelectedOrders, (selectedOrders) => selectedOrders[id])

const getSelectedOrderItems = (state: State) => state.orderTable.selectedItems
export const getSelectedOrderItemsByTableId = (tableId: string) =>
    createSelector(getSelectedOrderItems, (selectedItems) => selectedItems[tableId] || {})

export const getSelectedOrderItemsListByTableId = (tableId: string) =>
    createSelector(getSelectedOrderItemsByTableId(tableId), (selectedItems) => {
        return Object.keys(selectedItems).filter((key) => !!selectedItems[key])
    })

export const getSelectedOrderItemsByTableIdAndRowId = (tableId: string, rowId: string) =>
    createSelector(getSelectedOrderItemsByTableId(tableId), (selectedItems) => selectedItems?.[rowId] || false)

export const getListOrderData = (state: State) => {
    const lastArgs = state.orderTable.lastArgs

    return selectListOrderResult(lastArgs as unknown as OrdersListRequestPayload)(state)
}

export const getAllSelectedOrderItemsUuids = createSelector(getSelectedOrderItems, (selectedItems) => {
    const selectedOrderItems = Object.keys(selectedItems).map((tableId) => {
        const selectedOrderItemsInTable = selectedItems[tableId]

        return Object.keys(selectedOrderItemsInTable).filter((key) => selectedOrderItemsInTable[key])
    })

    return flatten(selectedOrderItems)
})

export const getAllSelectedOrderItemsOrdersUuids = createSelector(getSelectedOrderItems, (selectedItems) => {
    const selectedOrderItems = Object.keys(selectedItems).filter((tableId) => {
        const selectedOrderItemsInTable = selectedItems[tableId]

        return Object.keys(selectedOrderItemsInTable).filter((key) => selectedOrderItemsInTable[key]).length > 0
    })

    return selectedOrderItems
})

export const getSelectedOrderDetails = (state: State): IOrderItems[] => {
    const selectedOrderKeys = getAllSelectedOrderItemsOrdersUuids(state)
    const selectedItems = getAllSelectedOrderItemsUuids(state)

    const selectedItemsOrder = selectedOrderKeys
        .map(
            (key) =>
                selectGetOrderDetailsResult({
                    uuid: key,
                    include: ['items', 'items.options', 'items.barcode', 'items.refund_info'],
                })(state).data,
        )
        .reduce((acc, order) => {
            order?.items?.forEach((item: IOrderItemOption) => {
                if (selectedItems.includes(item.id)) {
                    acc[item.id] = item
                }
            })
            return acc
        }, {})

    return Object.values(selectedItemsOrder)
}

export const getSelectedUnredeemedOrderDetails = (state: State) => {
    const selectedItems = getSelectedOrderDetails(state)

    return selectedItems.filter((b) => !b.barcode?.redeemed && b.barcode?.status === 'paid')
}

export const getIsRowCheckboxDisabled = (selectedTableId: string) =>
    createSelector(getSelectedOrderItems, (selectedItems) =>
        Object.entries(selectedItems).some(
            ([tableId, items]) => tableId !== selectedTableId && Object.values(items).some((item) => item),
        ),
    )

const getState = (state: State) => state

export const getOrderInfoByOrderNumber = (orderNumber: string) =>
    createSelector(getState, (state) =>
        getListOrderData(state).data?.results.find((order) => order.number === orderNumber),
    )

export const getSelectedOrder = (state: State) => state.orderTable.selectedOrder
export const getIsTicketRowCheckboxDisabled = (selectedTableId: string, itemId: string) =>
    createSelector(getState, (state: State) => {
        const selectedItems = getSelectedOrderItems(state)
        const canceledOrderItems = getOrderItems(selectedTableId)(state).filter((item) => !!item.refundInfo)

        return (
            Object.entries(selectedItems).some(
                ([tableId, items]) => tableId !== selectedTableId && Object.values(items).some((item) => item),
            ) ||
            getSelectedOrdersList(state).length > 1 ||
            (getSelectedOrdersList(state).length > 0 && getSelectedOrdersList(state)[0] !== selectedTableId) ||
            canceledOrderItems.some((item) => item.id === itemId)
        )
    })

export const getOrderItemsUuid = (orderUuid: string) =>
    createSelector(getState, (state: State) => {
        const orderItems = selectGetOrderDetailsResult({
            uuid: orderUuid,
            include: ['items', 'items.options', 'items.barcode', 'items.refund_info'],
        })(state).data?.items?.map((item: IOrderItemOption) => item.id)

        return orderItems as string[]
    })

export const shouldToggleOrder = (orderUuid: string, itemUuid: string) =>
    createSelector(getState, (state: State) => {
        const orderItems =
            getOrderItems(orderUuid)(state)
                .filter((item) => !item.refundInfo)
                .map((item) => item.id) ?? []
        const selectedOrderItems = getSelectedOrderItemsListByTableId(orderUuid)(state)
        const isLastOrderItemToBeSelected =
            orderItems.length === 1 ||
            (selectedOrderItems.length === orderItems.length - 1 && !selectedOrderItems.includes(itemUuid))
        const isFirstOrderItemToBeDeSelected =
            orderItems?.length === selectedOrderItems.length && selectedOrderItems.includes(itemUuid)
        return isLastOrderItemToBeSelected || isFirstOrderItemToBeDeSelected
    })

export const getSelectedOrdersDetails = (state: State) => {
    const orders =
        getListOrderData(state).data?.results ??
        (state.orderTable.selectedOrder ? [state.orderTable.selectedOrder] : [])
    const selectedOrdersUuids = getSelectedOrdersList(state)

    return orders.filter((order) => selectedOrdersUuids.includes(order.id))
}

export const getIsOrderRowCheckboxDisabled = (selectedTableId: string) =>
    createSelector(getState, (state) => {
        const orderWithSelectedTickets = Object.entries(state.orderTable.selectedItems).filter(([, items]) =>
            Object.values(items).some((item) => item),
        )

        if (orderWithSelectedTickets.length === 1 && orderWithSelectedTickets[0][0] !== selectedTableId) {
            const selectedItems = Object.entries(orderWithSelectedTickets[0][1])
                .filter(([itemId, isSelected]) => isSelected)
                .map(([itemId]) => itemId)
            const orderItems = getOrderItemsUuid(orderWithSelectedTickets[0][0])(state) ?? []

            return !orderItems.every((item) => selectedItems.includes(item))
        }

        return false
    })

export const getOrderItems = (orderUuid: string) =>
    createSelector(getState, (state: State) => {
        const orderItems =
            selectGetOrderDetailsResult({
                uuid: orderUuid,
                include: ['items', 'items.options', 'items.barcode', 'items.refund_info'],
            })(state).data?.items ?? []

        return orderItems as IOrderItems[]
    })

export const getSelectedOrderItemsDetails = (state: State): IOrderItems[] => {
    const selectedOrderKeys = getAllSelectedOrderItemsOrdersUuids(state)
    const selectedItems = getAllSelectedOrderItemsUuids(state)

    const selectedItemsOrder = selectedOrderKeys
        .map(
            (key) =>
                selectGetOrderDetailsResult({
                    uuid: key,
                    include: ['items', 'items.options', 'items.barcode', 'items.refund_info'],
                })(state).data,
        )
        .reduce((acc, order) => {
            order?.items?.forEach((item: IOrderItemOption) => {
                if (selectedItems.includes(item.id)) {
                    acc[item.id] = item
                }
            })
            return acc
        }, {})

    return Object.values(selectedItemsOrder)
}
