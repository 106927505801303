import React from 'react'
import styled, { css } from 'styled-typed'
import Svg from 'svg'
import checkIcon from 'uiComponents/input/checkIcon.svg'
import { Color } from 'utils/css'

export interface SelectElementProps {
    status?: 'error' | 'success' | 'normal'
}

const errorCss = css`
    border: 2px solid ${(props) => props.theme.colors.status.error};
`

const SelectElement = styled.div`
    border-radius: 0.375em;
    background: ${(props) => props.theme.colors.tableRow};
    box-shadow: 0 5px 15px 0 rgba(27, 27, 78, 0.1);
    height: 15em;
    overflow-y: auto;
    padding: 0.5em 0;

    border: 1px solid transparent;
    outline: none;
    transition: 0.1s all ease-in;

    &::-webkit-scrollbar {
        width: 0.375em;
    }

    &::-webkit-scrollbar-track {
        background: ${(props) => props.theme.colors.textLight};
        border-radius: 0 0.375em 0.375em 0;
    }

    &::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.boyBlue};
        border-radius: 0 0.375em 0.375em 0;
    }

    ${(props: SelectElementProps) => (props.status === 'error' ? errorCss : '')}
`

const SelectOption = styled.label`
    color: ${(props) => props.theme.colors.textDark};
    font-family: ${(props) => props.theme.fonts.primary};
    font-weight: 300;
    height: 2em;
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    transition: 0.1s all ease-in;
    position: relative;

    &:hover {
        font-weight: 400;
    }
`

const SelectIndicator = styled.input.attrs((props) => ({
    type: 'checkbox',
}))`
    appearance: none;
    height: 1.5em;
    width: 1.5em;
    flex: 0 0 1.5em;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.white};
    border: 0.5px solid ${(props) => props.theme.colors.tableRow};
    margin: -2px 0.5em 0 1em;
    outline: none;
    transition: all 0.1s ease-in;

    &:focus {
        box-shadow: 0 0 1px 2px ${(props) => Color.fromCss(props.theme.colors.boyBlue).transparency(0.4).css()};
    }

    &:checked {
        background: ${(props) => props.theme.colors.boyBlue};
        border: 1px solid transparent;
    }

    ::-ms-check {
        display: none;
    }
`

const Checkmark = styled(Svg)`
    position: absolute;
    top: 0.5em;
    left: 1.25em;
    width: 1em;
    height: 1em;
    color: white;
    display: none;

    ${SelectIndicator}:checked ~ & {
        display: block;
    }
`

const SelectTitle = styled.div`
    ${SelectIndicator}:checked ~ & {
        font-weight: 400;
    }
`

export interface MultiSelectProps {
    values: string[]
    options: MultiSelectOption[]
    status?: 'error' | 'success' | 'normal'
    onChange?: (values: string[]) => void
}

export interface MultiSelectOption {
    value: string
    name: string
}

export class MultiSelectList extends React.Component<MultiSelectProps> {
    onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { values, onChange } = this.props
        if (!onChange) {
            return
        }

        const checked = evt.target.checked
        const newValues = checked ? [...values, evt.target.value] : values.filter((x) => x !== evt.target.value)
        onChange(newValues)
    }

    render() {
        const { values, options, status } = this.props
        return (
            <SelectElement status={status}>
                {options.map((opt) => (
                    <SelectOption key={opt.value}>
                        <SelectIndicator
                            value={opt.value}
                            checked={values.indexOf(opt.value) !== -1}
                            onChange={this.onChange}
                        />
                        <Checkmark src={checkIcon} />
                        <SelectTitle>{opt.name}</SelectTitle>
                    </SelectOption>
                ))}
            </SelectElement>
        )
    }
}
