import React, { useState } from 'react'
import { find } from 'lodash'
import styled, { useTheme } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripVertical } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { POI } from 'venue/parkMap/models'
import { Body, Headline } from 'uiComponents/typography'
import { Grid } from 'uiComponents/layout'
import placeholderBanner from 'images/placeholderBanner.png'
import { shortenText } from '../../../helpers'
import { RowActions, RowContainer } from './shared'
import { Draggable } from 'react-beautiful-dnd'

const POIListItem = styled.div`
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: 5px;
    overflow: hidden;
    margin: 8px 0 0 24px;
`

const Image = styled.img`
    height: 1.5rem;
    border-radius: 0.25rem;
`

const Badge = styled.div`
    position: absolute;
    bottom: -0.125rem;
    right: -0.25rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.25rem;
`

const PoiImageContainer = styled.div`
    height: 1.5rem;
    position: relative;
    margin-right: 0.5rem;
`

export interface ExtendedPoi extends POI {
    color: string
}

interface PoiLiProps {
    data: ExtendedPoi
    index: number
    onEditPoi(poiId: string): void
    onDeletePoi(poiId: string): void
}

const PoiLi: React.FC<PoiLiProps> = ({ data, index, onEditPoi, onDeletePoi }) => {
    const theme = useTheme()
    const [expanded, setExpanded] = useState<boolean>(false)
    const content = find(data.content, { locale: 'en' }) ?? find(data.content, { default: true })

    const poiContentConfig: { title: string; value: string; accentColor?: string; shorten?: boolean }[][] = [
        [{ title: 'Status', value: data.status, accentColor: theme.colors.emerald }],
        [
            { title: 'Latitude', value: `${data.lat}` },
            { title: 'Longitude', value: `${data.lng}` },
        ],
        [{ title: 'Description', value: data.content[0].description, shorten: true }],
    ]

    const renderPoiImage = (img: string | null, color: string) => {
        return (
            <PoiImageContainer>
                <Image src={img ?? placeholderBanner} />
                <Badge style={{ backgroundColor: color }} />
            </PoiImageContainer>
        )
    }

    const renderPoiContent = () => (
        <Grid container style={{ padding: '0.5rem 2.5rem', margin: 'none' }}>
            {poiContentConfig.map((column, i) => (
                <Grid key={i}>
                    {column.map((item, index) => (
                        <Grid key={index}>
                            <Headline size={6} style={{ margin: 0, color: theme.colors.aluminiumShades[20] }}>
                                {item.title}
                            </Headline>
                            <Body size={2} style={{ color: item.accentColor }}>
                                {item.shorten ? shortenText(item.value, 3) : item.value}
                            </Body>
                        </Grid>
                    ))}
                </Grid>
            ))}
        </Grid>
    )

    return (
        <Draggable key={data.id} draggableId={data.id} index={index}>
            {(provided) => (
                <POIListItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <RowContainer
                        style={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: expanded ? theme.colors.background : theme.colors.white,
                            padding: '0.5rem 1rem 0.5rem 0.5rem',
                        }}
                    >
                        <RowContainer>
                            <FontAwesomeIcon
                                icon={faGripVertical as IconProp}
                                color="black"
                                size="sm"
                                style={{ marginRight: '0.75rem' }}
                            />
                            {data.image && renderPoiImage(data.image, data.color)}
                            <Headline size={6} style={{ margin: 0 }}>
                                {content?.name}
                            </Headline>
                        </RowContainer>
                        <RowActions
                            id={data.id}
                            expanded={expanded}
                            setExpanded={setExpanded}
                            onEdit={onEditPoi}
                            onDelete={onDeletePoi}
                        />
                    </RowContainer>
                    {expanded && renderPoiContent()}
                </POIListItem>
            )}
        </Draggable>
    )
}

export default PoiLi
