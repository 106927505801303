import React from 'react'
import { ConfigurationVersions } from 'engageTools/studio/schema'
import { MultipleStatus, StatusName } from 'uiComponents/table'
import { formatStatusMessage, getStatus } from './utils'
import { Link } from 'uiComponents/typography'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { ROUTE_PATHS } from 'engageTools/routePaths'

interface EngageToolStatusProps {
    tool: ConfigurationVersions
}

const EngageToolStatus: React.FC<EngageToolStatusProps> = ({ tool }) => {
    const routeParams = useParams()
    const history = useHistory()
    const status = getStatus(tool)
    let statusText = formatStatusMessage(status) as any
    if (status.helperStatus.includes('duplicate')) {
        statusText = (
            <>
                {formatStatusMessage(status).replace('duplicate', '')}
                <Link
                    style={{ fontWeight: 'normal' }}
                    onClick={() => {
                        const path = generatePath(ROUTE_PATHS.duplicateModal, {
                            ...routeParams,
                            id: tool.id as string,
                        } as any)

                        history.push(path)
                    }}
                >
                    duplicate
                </Link>
            </>
        )
    }
    return (
        <div>
            <MultipleStatus
                status={status.status as StatusName}
                withText
                successText={statusText}
                errorText={statusText}
                warnText={statusText}
                blueText={statusText}
                disabledText={statusText}
            />
        </div>
    )
}

export default EngageToolStatus
