import { format } from 'date-fns'
import { CampaignError, ComponentsService, ConfigurationVersions } from 'engageTools/studio/componentsService'
import { ProductName, TargetType } from 'engageTools/studio/schema'
import { productNameOptions, productTitles } from 'engageTools/studio/shared'
import * as React from 'react'
import styled from 'styled-typed'
import { ActionButton } from 'uiComponents/buttons'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { MultiSelect, SingleSelect, SingleSelectOption, TextInput } from 'uiComponents/input'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { ModalDialog, ModalDialogTitle } from 'uiComponents/popups/modal'
import { delay, parseDate } from 'utils'
import { audiencesList } from '../../crm/audiences/consts'

export const Text = styled.div`
    line-height: 1.75em;
    margin-bottom: 1.5em;
`
const Container = styled.div`
    width: 25em;
`
const NavigationSection = styled.div`
    font-size: 0.875em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2.5em;
`

interface DialogProps {
    id?: string
    tools: ConfigurationVersions[]
    componentsService: ComponentsService
    onCancel: () => void
    onConfirm: () => void
    accountSlug: string
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    hasFeature: (feature: string, accountSlug: string) => boolean
    type: ProductName
}

const deviceTypeOptions = [
    { name: 'All', value: 'any' },
    { name: 'Desktop', value: 'desktop' },
    { name: 'Mobile', value: 'mobile' },
]

function canDefaultBeCreated(
    tools: ConfigurationVersions[],
    productType: ProductName,
    deviceType: TargetType,
): boolean {
    return !tools.find((t) => t.deviceType === deviceType && t.product === productType && !t.validTo && !t.validFrom)
}

export function AddEditDialog(props: DialogProps) {
    const [validate, setValidate] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [name, setName] = React.useState<string>('')
    const [productType, setProductType] = React.useState<ProductName>(props.type)
    const [deviceType, setDeviceType] = React.useState<TargetType | null>(null)
    const [originalDeviceType, setOriginalDeviceType] = React.useState<TargetType | null>(null)
    const [validFrom, setValidFrom] = React.useState<string | null>(null)
    const [validTo, setValidTo] = React.useState<string | null>(null)
    const [audiences, setAudiences] = React.useState<string[]>([])
    const [audienceOptions, setAudienceOptions] = React.useState<SingleSelectOption[]>([])
    const [validToBeyond, setValidToBeyond] = React.useState<boolean>(false)

    React.useEffect(() => {
        getConfigurationInfo()
        const audiencesOps = audiencesList.map((a) => ({
            name: a.name,
            value: a.slug,
        }))
        setAudienceOptions(audiencesOps)
    }, [])

    const getConfigurationInfo = async () => {
        if (!props.id) {
            return
        }
        const data = props.tools.find((t) => t.id === props.id)
        if (!!data) {
            setName(data.name || '')
            setProductType(data.product || 'wonderbar')
            setDeviceType(data.deviceType || 'any')
            setOriginalDeviceType(data.deviceType || 'any')
            setValidFrom(data.validFrom || null)
            setValidTo(data.validTo || null)
            setAudiences(data.audiences || [])
        }
    }

    const isFormDataValid = () => {
        const fromToValid = (validFrom && validTo) || (!validFrom && !validTo)
        return !!name && !!productType && !!deviceType && fromToValid
    }

    const onSave = async () => {
        setValidate(true)
        if (isFormDataValid()) {
            setLoading(true)
            if (!productType) {
                return
            }
            if (props.id && deviceType) {
                if (
                    originalDeviceType !== deviceType &&
                    !validFrom &&
                    !validTo &&
                    !canDefaultBeCreated(props.tools, productType, deviceType)
                ) {
                    props.replaceMessages(
                        'versions_error',
                        'error',
                        `A default version of ${productTitles[productType]} for this device (${deviceType}) already exists.`,
                    )
                    setLoading(false)
                    return
                }
                const responseData = await props.componentsService.updateCampaign(
                    props.accountSlug,
                    productType,
                    deviceType,
                    name,
                    audiences,
                    validFrom,
                    validTo,
                    props.id,
                )
                responseData
                    .ifSuccess(async () => {
                        await setLoading(false)
                        await props.replaceMessages(
                            'server_success',
                            'success',
                            'Your configuration has been updated successfully.',
                        )
                        props.onConfirm()
                        await delay(4000)
                        props.removeAllMessages()
                    })
                    .ifFailure((err: CampaignError) => {
                        props.replaceMessages(err.type, 'error', err.message)
                        setLoading(false)
                    })
            } else {
                if (!deviceType) {
                    return
                }
                if (!validFrom && !validTo && !canDefaultBeCreated(props.tools, productType, deviceType)) {
                    props.replaceMessages(
                        'versions_error',
                        'error',
                        `You have already created a dateless version of ${productTitles[productType]} for this device (${deviceType}).`,
                    )
                    setLoading(false)
                    return
                }
                const responseData = await props.componentsService.createCampaignObject(
                    props.accountSlug,
                    productType,
                    deviceType,
                    name,
                    audiences,
                    validFrom,
                    validTo,
                )
                responseData
                    .ifFailure((err: CampaignError) => {
                        props.replaceMessages(err.type, 'error', err.message)
                        setLoading(false)
                    })
                    .ifSuccess(async () => {
                        props.onConfirm()
                        props.replaceMessages(
                            'server_success',
                            'success',
                            'Your configuration has been created successfully.',
                        )
                        await delay(4000)
                        props.removeAllMessages()
                        setLoading(false)
                    })
            }
            setValidate(false)
        } else {
            props.replaceMessages('validation_error', 'error', 'Please fix the validation errors below.')
            setLoading(false)
        }
    }

    const checkEndDateValidity = (startDate: Date, endDate: Date) => {
        if (startDate && new Date(endDate) < new Date(startDate)) {
            setValidToBeyond(true)
        } else {
            setValidToBeyond(false)
        }
    }

    const onValidFromChanged = (date: Date) => {
        setValidFrom(date ? format(date, 'yyyy-MM-dd') : '')
        if (validTo) {
            checkEndDateValidity(date, new Date(validTo))
        }
    }

    const onValidToChanged = (date: Date) => {
        setValidTo(date ? format(date, 'yyyy-MM-dd') : '')
        if (date) {
            checkEndDateValidity(new Date(date), date)
        }
    }

    return (
        <ModalDialog onDismiss={props.onCancel} interactive fromTop="10%">
            {loading && <ChartDataLoader />}
            <Container>
                <ModalDialogTitle>{props.id ? 'Edit configuration' : 'Add new configuration'}</ModalDialogTitle>
                <FormItem htmlFor="campaign-name" style={{ position: 'relative' }}>
                    <FormItemName>Name</FormItemName>
                    <TextInput
                        id="name"
                        type="text"
                        maxLength={60}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        status={validate && !name ? 'error' : 'normal'}
                        block
                    />
                    {validate && !name && (
                        <ValidationMessage className="validation-message-visible">Name is required</ValidationMessage>
                    )}
                </FormItem>
                <FormItem htmlFor="tool-type" style={{ position: 'relative' }}>
                    <FormItemName>Tool type</FormItemName>
                    <SingleSelect
                        id="tool-type"
                        options={productNameOptions}
                        selected={productType}
                        height="2.5rem"
                        noSelectOption="No type selected"
                        onSelect={(v) => setProductType(v as ProductName)}
                        style={{ marginBottom: '0.5em' }}
                        status={validate && !productType ? 'error' : 'normal'}
                        disabled={!!props.id}
                    />
                    {validate && !productType && (
                        <ValidationMessage className="validation-message-visible">
                            Tool type is required
                        </ValidationMessage>
                    )}
                </FormItem>
                <FormItem htmlFor="device-type" style={{ position: 'relative' }}>
                    <FormItemName>Device(s)</FormItemName>
                    <SingleSelect
                        id="device-type"
                        options={deviceTypeOptions}
                        selected={deviceType}
                        height="2.5rem"
                        noSelectOption="No type selected"
                        onSelect={(v) => setDeviceType(v as TargetType)}
                        style={{ marginBottom: '0.5em' }}
                        status={validate && !deviceType ? 'error' : 'normal'}
                    />
                    {validate && !deviceType && (
                        <ValidationMessage className="validation-message-visible">
                            Device type is required
                        </ValidationMessage>
                    )}
                </FormItem>
                <FormItem htmlFor="startDate" style={{ flex: '1', marginBottom: '0.5em' }}>
                    <FormItemName style={{ display: 'flex' }}>Start date</FormItemName>
                    <DatePicker
                        id="startDate"
                        key={validFrom}
                        date={validFrom ? parseDate(validFrom) : null}
                        onChange={onValidFromChanged}
                        allowNullDate={false}
                        status={validate && !validFrom ? 'error' : 'normal'}
                        positionAbove
                    />
                </FormItem>
                {validate && !validFrom && (
                    <ValidationMessage style={{ marginTop: '1em' }} className="validation-message-visible">
                        {!validFrom && 'Select a start date'}
                    </ValidationMessage>
                )}
                <FormItem htmlFor="endDate" style={{ flex: '1', marginBottom: '0.5em' }}>
                    <FormItemName style={{ display: 'flex' }}>End date</FormItemName>
                    <DatePicker
                        id="endDate"
                        key={validTo}
                        date={validTo ? parseDate(validTo) : null}
                        onChange={onValidToChanged}
                        lowerBoundary={validFrom ? new Date(validFrom) : null}
                        allowNullDate
                        status={validate && (validToBeyond || !validTo) ? 'error' : 'normal'}
                        positionAbove
                    />
                </FormItem>
                {validate && validToBeyond && (
                    <ValidationMessage style={{ marginTop: '1em' }} className="validation-message-visible">
                        {validToBeyond && 'The end date should be later than the start date'}
                    </ValidationMessage>
                )}
                {props.hasFeature('CRMAudiencesPage', props.accountSlug) && (
                    <FormItem htmlFor="audiences" style={{ position: 'relative' }}>
                        <FormItemName>Audiences</FormItemName>
                        <MultiSelect
                            options={audienceOptions}
                            noSelectOption="No audiences selected"
                            height="2.5em"
                            maxHeight="7.5em"
                            selected={audiences}
                            onSelect={setAudiences}
                        />
                    </FormItem>
                )}
                <NavigationSection>
                    <ActionButton size="large" secondary onClick={props.onCancel}>
                        Cancel
                    </ActionButton>
                    <ActionButton
                        id="add-new-campaign-button"
                        size="large"
                        style={{ marginLeft: '2em' }}
                        onClick={onSave}
                    >
                        Save
                    </ActionButton>
                </NavigationSection>
            </Container>
        </ModalDialog>
    )
}
