import React, { useMemo } from 'react'
import styled from 'styled-typed'
import { useField } from 'formik'
import { IndeterminateCheckbox, TableColumn } from 'uiComponents/table/reactTable'
import { calculateOrderPartnerRefundFee } from 'orders/utils/calculateOrderRefund'
import { CheckboxFieldFormik } from 'uiComponents/input'
import { Headline } from 'uiComponents/typography'
import { Order } from 'orders/schema'
import { Currency } from 'uiComponents/money/moneyHoc'
import { useParams } from 'react-router-dom'
import { BaseRouteParams } from 'hocs'
import { getTotalRefundedAmount } from '../utils'

export const DisabledText = styled.div`
    color: ${(props) => props.theme.colors.aluminiumShades[20]};
`

type IsOrderDisabled = (order: Order) => boolean

interface UseBulkRefundTableColumns {
    isOrderDisabled: IsOrderDisabled
    cancellation: boolean
    getCurrency: (accountSlug: string) => Currency
}

const roundFee = (refundFee?: number) => {
    if (!!refundFee) {
        return Math.round(refundFee * 100) / 100
    }

    return refundFee
}

const createColumns = ({ isOrderDisabled, currency }: { isOrderDisabled: IsOrderDisabled; currency: Currency }) => {
    return [
        {
            accessor: 'selectable',
            width: '.5rem',
            disableSortBy: true,
            disableHideColumn: true,
            Header: ({ getToggleAllRowsSelectedProps }: any) => (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => {
                return (
                    <IndeterminateCheckbox
                        {...row.getToggleRowSelectedProps()}
                        disabled={isOrderDisabled(row.original)}
                    />
                )
            },
        },
        {
            accessor: 'id',
            Header: 'ID',
            Cell: ({ value, row }) => {
                if (isOrderDisabled(row.original)) {
                    return <DisabledText>{value}</DisabledText>
                }

                return value
            },
        },
        {
            accessor: 'total',
            Header: 'Subtotal',
            width: '86px',
            Cell: ({ value, row }) => {
                if (isOrderDisabled(row.original)) {
                    return (
                        <DisabledText>
                            {currency.symbol} {value}
                        </DisabledText>
                    )
                }
                return `${currency.symbol} ${value}`
            },
            Footer: [() => <span style={{ textAlign: 'right', fontWeight: 'bold' }}>Total: </span>],
        },
        {
            accessor: 'totalAfterDiscount',
            Header: 'Total',
            width: '86px',
            align: 'left',
            Cell: ({ value, row }) => {
                if (isOrderDisabled(row.original)) {
                    return (
                        <DisabledText>
                            {currency.symbol} {value}
                        </DisabledText>
                    )
                }
                return `${currency.symbol} ${value}`
            },
            Footer: [
                (props) => {
                    const [{ value: partnerRefundFee }] = useField('partnerRefundFee')

                    const roundedAmount = `${currency.symbol} ${getTotalRefundedAmount({
                        orders: props.rows.filter((row) => row.isSelected).map((row) => row.original),
                        partnerRefundFee,
                    })}`

                    return (
                        <div style={{ position: 'relative', height: '1.25rem' }}>
                            <div style={{ position: 'absolute' }}>
                                <span title={roundedAmount}>{roundedAmount}</span>
                            </div>
                        </div>
                    )
                },
            ],
        },
    ] as TableColumn<Order>[]
}

export const useBulkRefundTableColumns = ({
    isOrderDisabled,
    cancellation,
    getCurrency,
}: UseBulkRefundTableColumns) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const currency = getCurrency(accountSlug)

    return useMemo(() => {
        if (!cancellation) {
            return createColumns({ isOrderDisabled, currency }).map((column) => {
                if (column.accessor === 'selectable') {
                    if (!column.Footer) {
                        column.Footer = []
                    }
                    if (Array.isArray(column.Footer)) {
                        column.Footer?.unshift(() => (
                            <div style={{ marginTop: '-31px' }}>
                                <CheckboxFieldFormik name="partnerRefundFee" />
                            </div>
                        ))
                    }
                }

                if (column.accessor === 'id') {
                    if (!column.Footer) {
                        column.Footer = []
                    }
                    if (Array.isArray(column.Footer)) {
                        column.Footer?.unshift(() => <Headline size={6}>Visitor Refund Fee</Headline>)
                        column.Footer?.unshift(() => <Headline size={6}>Convious Refund Fee</Headline>)
                    }
                }

                if (column.accessor === 'total') {
                    if (!column.Footer) {
                        column.Footer = []
                    }
                    if (Array.isArray(column.Footer)) {
                        column.Footer?.unshift(() => null)
                        column.Footer?.unshift(() => null)
                    }
                }

                if (column.accessor === 'totalAfterDiscount') {
                    if (!column.Footer) {
                        column.Footer = []
                    }
                    if (Array.isArray(column.Footer)) {
                        column.Footer?.unshift((props) => {
                            const refundFee = props.rows
                                .filter((row) => row.isSelected)
                                .reduce((acc, row) => acc + calculateOrderPartnerRefundFee({ order: row.original }), 0)

                            return `${currency.symbol} ${roundFee(refundFee)}`
                        })
                    }
                }

                return column
            })
        }

        return createColumns({ isOrderDisabled, currency })
    }, [isOrderDisabled, cancellation, currency])
}
