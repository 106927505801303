import * as React from 'react'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-typed'
import { ActionButtonA } from 'uiComponents/buttons'
import { ModalDialog, ModalDialogTitle } from 'uiComponents/popups/modal'
import { BaseRouteParams } from '../../../hocs'

const ModalDialogBody = styled.div`
    padding-top: 1.2em;
    font-weight: lighter;
    width: 400px;
`

const ModalDialogContent = styled.div`
    line-height: 1.6em;
`

const ModalDialogText = styled.div`
    padding-right: 4em;
`

const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 2em;
`

interface DialogProps {
    onCancel: () => void
    requestSent: boolean
    setRequestSent: (requestSent: boolean) => void
}

const RequestCustomAudiencesDialog = (props: DialogProps) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const EMAIL = 'customersuccess@convious.com'
    const BCC = 'dovile@convious.com'
    const SUBJECT = 'Custom Audiences - contact request'
    const BODY = `Please contact me regarding access to custom audiences. (${accountSlug})`

    const requestCustomAudiences = useCallback(() => {
        props.setRequestSent(true)
    }, [])

    return (
        <ModalDialog onDismiss={props.onCancel} interactive fromTop="20%">
            <ModalDialogBody>
                <ModalDialogTitle>Get your predefined audiences</ModalDialogTitle>
                <ModalDialogContent>
                    <ModalDialogText>
                        Send your request to our Customer Success team. We will be in contact with you soon with the
                        following steps.
                    </ModalDialogText>
                    {props.requestSent ? (
                        <Buttons>
                            <ActionButtonA secondary onClick={props.onCancel}>
                                Close
                            </ActionButtonA>
                        </Buttons>
                    ) : (
                        <Buttons>
                            <ActionButtonA secondary style={{ marginRight: '1em' }} onClick={props.onCancel}>
                                Cancel
                            </ActionButtonA>
                            <ActionButtonA
                                href={`mailto:${EMAIL}?bcc=${BCC}&subject=${SUBJECT}&body=${BODY}`}
                                onClick={requestCustomAudiences}
                            >
                                Send request
                            </ActionButtonA>
                        </Buttons>
                    )}
                </ModalDialogContent>
            </ModalDialogBody>
        </ModalDialog>
    )
}

export default RequestCustomAudiencesDialog
