import * as React from 'react'
import Styled from 'styled-typed'
import { Col } from 'uiComponents/flex'
import Infotip from 'uiComponents/infotip'
import { RichTextInput } from 'uiComponents/input/richTextInput'
import { isValidUrl } from 'utils/formFieldChecks'
import { FieldType } from 'uiComponents/studio/form/fieldComponentSchema'
import {
    CheckboxComponent,
    SelectComponent,
    InputComponent,
    ToggleComponent,
} from 'uiComponents/studio/form/fieldComponents'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { transformLanguagesIntoOptions } from '../languages'
import {
    Translation,
    WonderbarConfiguration,
    Languages,
    OnChangeFunc,
    GetConfigValueFunc,
} from 'engageTools/studio/schema'
import { ErrorMessage } from 'uiComponents/studio/form/errorMessage'

export const COPY_KEYS = {
    TEXT: 'wonder_bar.bar_text',
    BUTTON: 'wonder_bar.button_text',
    URL: 'wonder_bar.button_href',
}

export const WONDERBAR_COPY_SLUGS = ['wonder_bar.bar_text', 'wonder_bar.button_text', 'wonder_bar.button_href']

interface CopySectionProps {
    availableLanguages: string[]
    handleChange: OnChangeFunc
    getConfigValue: GetConfigValueFunc
    activeLanguage: string
    setActiveLanguage: (v: string) => void
    wonderbarConfiguration: WonderbarConfiguration
    wonderbarTranslations: Translation[]
    wonderbarLanguages: Languages
    updateTranslations: (slug: string, language: string, value: string) => void
    resetATranslation: (slug: string) => void
    updateConfiguration: (path: string, value: string | number | boolean | string[]) => void
    setTranslationsValidation: (on: boolean) => void
    translationsValidation: boolean
}

interface CopySectionState {
    buttonTextValid: boolean
    barTextValid: boolean
    barUrlValid: boolean
    wonderbarTranslationsUpdated: boolean
}

export class CopySection extends React.Component<CopySectionProps, CopySectionState> {
    constructor(props: CopySectionProps) {
        super(props)
        this.state = {
            buttonTextValid: true,
            barTextValid: true,
            barUrlValid: true,
            wonderbarTranslationsUpdated: false,
        }
    }

    componentDidMount() {
        if (this.props.translationsValidation) {
            this.checkTranslationValidity(this.props)
        }
        this.props.setTranslationsValidation(false)
    }

    componentDidUpdate(prevProps: CopySectionProps) {
        if (prevProps.wonderbarTranslations !== this.props.wonderbarTranslations) {
            this.setState({ wonderbarTranslationsUpdated: true })
        }
        if (this.props.translationsValidation) {
            this.checkTranslationValidity(this.props)
            this.props.setTranslationsValidation(false)
        } else {
            if (prevProps.activeLanguage !== this.props.activeLanguage) {
                this.resetFormValidity()
            }
        }
    }

    updateValidityInState = (slug: string, valid: boolean): void => {
        switch (slug) {
            case COPY_KEYS.TEXT:
                this.setState({ barTextValid: valid })
                break
            case COPY_KEYS.BUTTON:
                this.setState({ buttonTextValid: valid })
                break
            case COPY_KEYS.URL:
                this.setState({ barUrlValid: valid })
                break
            default:
                break
        }
    }
    checkTranslationValidity = (props: CopySectionProps): void => {
        if (props.wonderbarTranslations.length === 0) {
            this.setState({
                barTextValid: false,
                buttonTextValid: false,
                barUrlValid: false,
            })
            return
        }
        props.wonderbarTranslations.forEach((t) => {
            const validTranslation = !!t.values[props.activeLanguage] && t.values[props.activeLanguage] !== ''
            this.updateValidityInState(t.slug, validTranslation)
        })
    }

    resetFormValidity = () => {
        this.props.wonderbarTranslations.forEach((t) => {
            this.updateValidityInState(t.slug, true)
        })
    }

    getTranslationValue = (item: FieldType): string => {
        const translation = this.props.wonderbarTranslations.find((t) => t.slug === item.path)
        const value =
            typeof translation !== 'undefined'
                ? translation.values[this.props.activeLanguage]
                    ? translation.values[this.props.activeLanguage]
                    : ''
                : ''
        return value
    }

    getNewTabValue = () => {
        const newTabConfig = this.props.wonderbarConfiguration['openLinkInNewTab']
        return typeof newTabConfig === 'undefined' ? true : !!newTabConfig
    }
    handleSendToCheckout = (sendToCheckout: boolean, path: string): void => {
        const value = sendToCheckout ? 'checkout' : 'link'
        this.props.handleChange(value, path)
        if (sendToCheckout) {
            this.props.resetATranslation(COPY_KEYS.URL)
        }
    }
    handleLanguageChange = (v: string, path: string) => {
        this.props.setActiveLanguage(v)
        this.props.handleChange(v, path)
    }
    handleTranslationChange = (e: React.ChangeEvent<HTMLSelectElement>, path: string) => {
        const value = e.target.value
        this.props.updateTranslations(path, this.props.activeLanguage, value)
    }
    onBarTextChange = (html: string, text: string, fontStyles: string[]) => {
        this.props.updateTranslations(COPY_KEYS.TEXT, this.props.activeLanguage, html)
        this.props.updateConfiguration('styling.bar.fontStyles', fontStyles)
    }
    handleNewTabChange = (e: React.ChangeEvent<HTMLSelectElement>, path: string) => {
        const laguagePath = `${path}`
        this.props.handleChange(e, laguagePath)
    }
    checkBarTextValidity = (v: string): void => {
        this.setState({ barTextValid: v !== '' })
    }
    checkButtonTextValidity = (v: string): void => {
        this.setState({ buttonTextValid: v !== '' })
    }
    checkButtonUrlValidity = (v: string): void => {
        this.setState({ barUrlValid: v !== '' && isValidUrl(v) })
    }
    checkIfUrlComplete = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        if (!this.state.barUrlValid) {
            return
        }
        if (!value.includes('https://') && !value.includes('http://')) {
            const newValue = 'https://' + value
            this.props.updateTranslations(COPY_KEYS.URL, this.props.activeLanguage, newValue)
        }
    }

    render() {
        const { activeLanguage, availableLanguages } = this.props
        const sendToCheckoutEnabled = this.props.wonderbarConfiguration.target === 'checkout'
        const activeIsPrimary = activeLanguage === this.props.wonderbarLanguages.primary

        type SelectType = 'select'
        type CheckboxType = 'checkbox'
        type TextAreaType = 'textArea'
        type UrlType = 'url'
        type ToggleType = 'toggle'
        const languageField = {
            name: 'language',
            type: 'select' as SelectType,
            label: '',
            path: '',
            availableOptions: transformLanguagesIntoOptions(availableLanguages),
            defaultValue: {
                title: 'Select language to add text',
                value: '',
            },
            span: 6,
        }
        const barTextField = {
            name: 'wonderbar-text',
            type: 'textArea' as TextAreaType,
            label: 'WonderBar text',
            path: COPY_KEYS.TEXT,
            defaultValue: 'This is how the WonderBar will look on your page',
        }
        const buttonTextField = {
            name: 'button-text',
            type: 'textArea' as TextAreaType,
            label: 'Button text',
            path: COPY_KEYS.BUTTON,
            defaultValue: 'Click me',
        }
        const sendToChekoutField = {
            name: 'send-to-checkout',
            type: 'toggle' as ToggleType,
            label: 'Link to checkout',
            path: 'target',
            defaultValue: true,
            infotip: 'After clicking the button, send your users to the checkout.',
        }
        const hrefField = {
            name: 'button-destination',
            type: 'url' as UrlType,
            label: 'Alternative URL',
            path: COPY_KEYS.URL,
            placeholder: 'https://www.example.com/shop',
            defaultValue: '',
            infotip: 'Destination page your users will be sent to after clicking the button.',
        }
        const checkBoxField = {
            name: 'new-tab',
            type: 'checkbox' as CheckboxType,
            label: 'Open in new tab',
            path: 'openLinkInNewTab',
            defaultValue: true,
        }

        const Note = Styled.span`
      font-style: italic;
      color: ${(props) => props.theme.colors.textLight};
      flex: 1;
      text-align: right;
    `

        return (
            <>
                <FormItem>
                    <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <label htmlFor={languageField.name}>Select language</label>
                    </Col>
                    <Col span={6}>
                        <SelectComponent
                            field={languageField}
                            handleChange={this.handleLanguageChange}
                            value={activeLanguage}
                        />
                    </Col>
                </FormItem>
                {activeLanguage && (
                    <div>
                        <FormItem>
                            <Col span={12}>
                                <label htmlFor={barTextField.name}>
                                    {barTextField.label}
                                    <Note>*max. 100 characters</Note>
                                </label>
                            </Col>
                            <Col span={12}>
                                <RichTextInput
                                    id={barTextField.name}
                                    onChange={this.onBarTextChange}
                                    value={this.getTranslationValue(barTextField)}
                                    maxLength={100}
                                    style={{ fontSize: '0.75rem' }}
                                    onFinishTyping={(v: string) => {
                                        this.checkBarTextValidity(v)
                                    }}
                                    status={!this.state.barTextValid ? 'error' : 'normal'}
                                />
                                {!this.state.barTextValid && <ErrorMessage errType="notEmpty" />}
                            </Col>
                        </FormItem>
                        <FormItem>
                            <Col span={12}>
                                <label htmlFor={buttonTextField.name}>{buttonTextField.label}</label>
                            </Col>
                            <Col span={12}>
                                <InputComponent
                                    field={buttonTextField}
                                    handleChange={this.handleTranslationChange}
                                    value={this.getTranslationValue(buttonTextField)}
                                    checkValidity={(v: string) => {
                                        this.checkButtonTextValidity(v)
                                    }}
                                    error={!this.state.buttonTextValid}
                                    maxLength={50}
                                    locale={activeLanguage}
                                />
                                {!this.state.buttonTextValid && <ErrorMessage errType="notEmpty" />}
                            </Col>
                        </FormItem>
                        <FormItem>
                            {activeIsPrimary && (
                                <>
                                    <Col span={6}>
                                        <label htmlFor={sendToChekoutField.name}>{sendToChekoutField.label}</label>
                                    </Col>
                                    <Col span={6} className="justify-right">
                                        <ToggleComponent
                                            field={sendToChekoutField}
                                            handleChange={this.handleSendToCheckout}
                                            value={sendToCheckoutEnabled}
                                        />
                                    </Col>
                                </>
                            )}
                            {!sendToCheckoutEnabled && (
                                <>
                                    <Col span={12}>
                                        <label htmlFor={hrefField.name}>
                                            {hrefField.label}
                                            <Infotip pointer="left" maxWidth="20em">
                                                {hrefField.infotip}
                                            </Infotip>
                                        </label>
                                    </Col>
                                    <Col span={12}>
                                        <InputComponent
                                            field={hrefField}
                                            handleChange={this.handleTranslationChange}
                                            value={this.getTranslationValue(hrefField)}
                                            checkValidity={(v: string) => {
                                                this.checkButtonUrlValidity(v)
                                            }}
                                            error={!this.state.barUrlValid}
                                            onBlur={this.checkIfUrlComplete}
                                        />
                                        {!this.state.barUrlValid && <ErrorMessage errType="url" />}
                                    </Col>
                                </>
                            )}
                        </FormItem>
                        {activeIsPrimary && !sendToCheckoutEnabled && (
                            <FormItem className="justify-right">
                                <CheckboxComponent
                                    field={checkBoxField}
                                    handleChange={this.handleNewTabChange}
                                    value={this.getNewTabValue()}
                                />
                            </FormItem>
                        )}
                    </div>
                )}
            </>
        )
    }
}

export default CopySection
