import React from 'react'
import { Route } from 'react-router'
import { IntegrationSuccessPage } from 'integrationSuccess'
import MollieAuthCallback from 'settings/accountSettings/integrations/mollie/callback'
import PaypalCallback from 'settings/accountSettings/integrations/paypal/callback'

const INTEGRATION_ROUTES = {
    INTEGRATION_SUCCESS: '/integration/:integration/success',
    MOLLIE_CALBACK: '/integration/mollie/callback',
    PAYPAL_CALBACK: '/integration/paypal/callback',
}

export const ALL_INTEGRATION_ROUTES = Object.values(INTEGRATION_ROUTES)

const IntegrationRoutes = () => {
    return (
        <>
            <Route path={INTEGRATION_ROUTES.PAYPAL_CALBACK} component={PaypalCallback} />
            <Route path={INTEGRATION_ROUTES.MOLLIE_CALBACK} component={MollieAuthCallback} />
            <Route path={INTEGRATION_ROUTES.INTEGRATION_SUCCESS} component={IntegrationSuccessPage} />
        </>
    )
}

export default IntegrationRoutes
