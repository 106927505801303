import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { useHasFeature } from '../../features'
import styled from '../../styled-typed'
import { Headline } from '../../uiComponents/typography'
import AudiencesTabsNavigation from './components/navigation'

const HeaderText = styled.div`
    font-size: 1.1em;
    font-weight: lighter;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
`

const AudiencesContainer: React.FC = ({ children }) => {
    const { pathname } = useLocation()
    const description = pathname.includes('/custom')
        ? 'Create and manage your audiences to reach out to your customers and create customised campaigns.'
        : 'Predefined audiences'
    const hasAccessToPredefinedAudiences = useHasFeature('CRMAudiencesPage')
    const hasAccessToCustomAudiences = useHasFeature('CRMAudiencesCustom')

    return (
        <div style={{ position: 'relative' }} id="audiences">
            <Headline size={1} style={{ marginBottom: '0.5em' }}>
                Audiences
            </Headline>
            {hasAccessToPredefinedAudiences || hasAccessToCustomAudiences ? (
                <>
                    <Header>
                        <HeaderText>{description}</HeaderText>
                    </Header>
                    <AudiencesTabsNavigation />
                </>
            ) : null}
            {children}
        </div>
    )
}

export default AudiencesContainer
