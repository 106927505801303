import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-typed'
import { BaseRouteParams } from 'hocs'
import { history } from 'middleware'
import { NavigationTabs } from 'uiComponents/navigation/tabs'
import { pathUtils } from 'utils/pathnameFormatter'
import SearchWithButtonField from 'uiComponents/search/searchWithButton'
import { Col, Row } from 'uiComponents/flex'
import { CODES_PATHS } from '../paths'

const DiscountCodesStyles = styled.div`
    .tab-element {
        padding: 0 1.5rem;
        line-height: 2rem;
        font-size: 0.875rem;
    }
`

const DiscountCodes: React.FC = ({ children }) => {
    const location = useLocation()
    const routeParams = useParams<BaseRouteParams>()

    return (
        <DiscountCodesStyles>
            <Row align="flex-start" vAlign="center" style={{ padding: '1.25em 1em' }}>
                <Col span={5}>
                    <SearchWithButtonField style={{ width: '12.938rem' }} hideExtendedSearch />
                </Col>
                <Col span="auto">
                    <NavigationTabs
                        tabs={[
                            {
                                current: location.pathname.includes(CODES_PATHS.internalPaths.discountPath.pools),
                                name: 'Pools',
                                onClick: () => {
                                    history.push(pathUtils.populateParams(CODES_PATHS.fullPaths.poolsPage, routeParams))
                                },
                            },
                            {
                                current: location.pathname.includes(
                                    `/discount/${CODES_PATHS.internalPaths.discountPath.codes}`,
                                ),
                                name: 'Codes',
                                onClick: () => {
                                    history.push(
                                        pathUtils.populateParams(
                                            CODES_PATHS.internalPaths.discountPath.codes,
                                            routeParams,
                                        ),
                                    )
                                },
                            },
                        ]}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={12}>{children}</Col>
            </Row>
        </DiscountCodesStyles>
    )
}

export default DiscountCodes
