import * as React from 'react'
import styled from 'styled-typed'

const TooltipContainer = styled.div`
    border-radius: 0.5em;
    box-shadow: 0 24px 28px 2px rgba(0, 0, 0, 0.07), 0 9px 36px 4px rgba(0, 0, 0, 0.06),
        0 11px 10px 0 rgba(0, 0, 0, 0.1);
    background: ${(props) => props.theme.colors.textDark};
    padding: 0.75em 1.25em;
    position: relative;
`

const TooltipPointer = styled.div`
    width: 1.125em;
    height: 1.125em;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    margin-top: -0.53em;
    background: ${(props) => props.theme.colors.textDark};

    ${TooltipContainer}.direction-right & {
        left: -0.53em;
    }

    ${TooltipContainer}.direction-left & {
        right: -0.53em;
    }
`

export interface TooltipProps {
    children: React.ReactNode
    style?: React.CSSProperties
    direction?: 'left' | 'right'
}

export function Tooltip({ children, style, direction }: TooltipProps) {
    const classes = `tooltip direction-${direction || 'right'}`

    return (
        <TooltipContainer style={style} className={classes}>
            {children}
            <TooltipPointer />
        </TooltipContainer>
    )
}
