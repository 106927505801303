import * as React from 'react'
import { OnClickMenu, OnClickMenuItem } from 'uiComponents/menus/'
import { Text } from 'uiComponents/typography'
import { faTrash, faPencilAlt, faPencilPaintbrush, faTimesOctagon } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'

type FunctionType = () => void

interface MenuProps {
    studioHref: string
    onUnpublish: FunctionType | null
    onPublish: FunctionType | null
    onEditInfo: FunctionType | null
    onDelete: FunctionType
}

export function ActionMenu(props: MenuProps) {
    const handlePublish = props.onUnpublish || props.onPublish

    return (
        <OnClickMenu title="Actions" kind="action" width="12em" secondary alignRight>
            {handlePublish && (
                <OnClickMenuItem onClick={handlePublish}>
                    <FontAwesomeIcon icon={faTimesOctagon} style={{ marginRight: '0.5em' }} />
                    {props.onUnpublish ? 'Unpublish' : 'Publish'}
                </OnClickMenuItem>
            )}

            <OnClickMenuItem>
                <UnstyledLink to={props.studioHref}>
                    <>
                        <FontAwesomeIcon icon={faPencilPaintbrush} style={{ marginRight: '0.5em' }} />
                        Studio
                    </>
                </UnstyledLink>
            </OnClickMenuItem>
            {props.onEditInfo && (
                <OnClickMenuItem onClick={props.onEditInfo}>
                    <FontAwesomeIcon icon={faPencilAlt} style={{ marginRight: '0.5em' }} />
                    Edit info
                </OnClickMenuItem>
            )}
            <OnClickMenuItem onClick={props.onDelete}>
                <Text status="error">
                    <FontAwesomeIcon icon={faTrash} style={{ marginRight: '0.5em' }} />
                    Delete
                </Text>
            </OnClickMenuItem>
        </OnClickMenu>
    )
}
