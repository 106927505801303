import { SingleSelectOption } from 'uiComponents/input'
import { endpoints, useGetAllArticlesQuery, useGetAllCategoriesQuery } from './reduxApi'
import { CategoryFromApi } from './types'
import { useParams } from 'react-router-dom'
import { BaseRouteParams } from '../hocs'
import { useMemo } from 'react'
import { State } from '../store'
import { getProductName } from './productTable/utils'

interface CategoryProductType {
    type: 'article' | 'category'
}

const sortByPriority = (a: any, b: any) => a.priority - b.priority
const sortArrayByPriority = <T>(arr: T[]): T[] => {
    return [...arr].sort(sortByPriority)
}

export const useIsLoadingCategoriesOrArticles = () => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const { isFetching: isFetchingCategories } = useGetAllCategoriesQuery({ account: accountSlug })
    const { isFetching: isFetchingArticles } = useGetAllArticlesQuery({ account: accountSlug })

    return isFetchingCategories || isFetchingArticles
}

export const useGetAllCategories = () => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const { data } = useGetAllCategoriesQuery({ account: accountSlug })

    return useMemo(() => sortArrayByPriority(data || []), [data])
}

export const useGetAllArticles = () => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const { data } = useGetAllArticlesQuery({ account: accountSlug })

    return useMemo(() => sortArrayByPriority(data || []), [data])
}

export const isLoadingArticlesAndCategories = (state: State) =>
    endpoints.getAllCategories.select({
        account: state.preferences.activeAccount as string,
    })(state).isLoading ||
    endpoints.getAllArticles.select({
        account: state.preferences.activeAccount as string,
    })(state).isLoading

export const useGetAllParentCategories = () => {
    return useGetAllCategories()?.filter((category) => !category.parentCategory?.id)
}

export const useGetAllCategoriesByParentId = (categoryId?: string) => {
    const categories = useGetAllCategories()

    if (!categoryId) {
        return []
    }

    return categories?.filter((category) => category.parentCategory?.id === categoryId)
}

export const useGetAllArticlesByParentId = (categoryId?: string) => {
    const allArticles = useGetAllArticles()

    if (!categoryId) {
        return []
    }

    return allArticles?.filter((article) => !!article.categories.find((c) => c.id === categoryId))
}

export const useGetAllCategoriesAndArticlesById = (id?: string) => {
    const allParentCategories = useGetAllParentCategories()
    const allParentCategoriesById = useGetAllCategoriesByParentId(id)
    const allArticlesByParentId = useGetAllArticlesByParentId(id)

    if (!id) {
        return allParentCategories
    }

    return [...allParentCategoriesById, ...allArticlesByParentId]
}

export const createGetCategoryAncestors = (categories: CategoryFromApi[]) => {
    const categoryRecords = categories?.reduce((acc, category) => {
        acc[category.id] = category
        return acc
    }, {} as Record<string, CategoryFromApi>)

    const getCategoryAncestors = (id: string): CategoryFromApi[] => {
        const child = categoryRecords[id]

        if (!child) {
            return []
        }

        if (!child?.parentCategory?.id) {
            return [child]
        }

        return [...getCategoryAncestors(child.parentCategory.id), child]
    }

    return getCategoryAncestors
}

export const useGetAllCategoriesForSelect = () => {
    const categories = useGetAllCategories()

    return useMemo(
        () =>
            categories?.map((category) => ({
                name: getProductName(category.name),
                value: category.id,
                parentId: category.parentCategory?.id,
                type: 'category',
            })) || [],
        [categories],
    )
}

export const useGetFullProductsAndArticlesListForSelect = () => {
    const allCategories = useGetAllCategories()
    const allArticles = useGetAllArticles()

    const categoriesById = allCategories?.map((category) => ({
        name: getProductName(category.name),
        value: category.id,
        parentId: category.parentCategory?.id,
        type: 'category',
    }))

    const articlesById = allArticles?.reduce((acc, article) => {
        if (article.categories?.length > 0) {
            article.categories.forEach((category) => {
                acc.push({
                    name: getProductName(article.name),
                    value: article.id,
                    parentId: category.id,
                    type: 'article',
                })
            })
        } else {
            acc.push({
                name: getProductName(article.name),
                value: article.id,
                parentId: undefined,
                type: 'article',
            })
        }

        return acc
    }, [] as (SingleSelectOption & CategoryProductType)[])

    return [...categoriesById, ...articlesById]
}

export const useGetFullProductsAndArticlesNestedTree = () => {
    const allCategories = useGetAllCategories()
    const allArticles = useGetAllArticles()
    const isLoading = useIsLoadingCategoriesOrArticles()

    const categoriesById = allCategories?.map((category) => ({
        name: getProductName(category.name),
        value: category.id,
        parentId: category.parentCategory?.id,
        type: 'category',
        slug: category.id,
    }))

    const articlesById = allArticles?.reduce((acc, article) => {
        if (article.categories?.length > 0 || article.inactiveCategories?.length > 0) {
            ;[...article.categories, ...article.inactiveCategories].forEach((category) => {
                acc.push({
                    name: getProductName(article.name),
                    value: article.id,
                    parentId: category.id,
                    type: 'article',
                })
            })
        } else {
            acc.push({
                name: getProductName(article.name),
                value: article.id,
                parentId: undefined,
                type: 'article',
            })
        }

        return acc
    }, [] as (SingleSelectOption & CategoryProductType)[])

    const nodes: Record<string, any> = {}

    for (const category of categoriesById) {
        nodes[category.value] = {
            id: category.value,
            uuid: category.value,
            name: category.name,
            slug: category.value,
            articles: [],
            children: [],
        }
    }

    for (const article of articlesById) {
        const article_node = {
            id: article.value,
            uuid: article.value,
            name: article.name,
            slug: article.value,
        }

        if (article.parentId && nodes[article.parentId]) {
            nodes[article.parentId].articles.push(article_node)
        }
    }

    const root = []

    for (const category of categoriesById) {
        const node = nodes[category.value]

        if (category.parentId) {
            if (nodes[category.parentId]) {
                nodes[category.parentId].children.push(node)
            }
        } else {
            root.push(node)
        }
    }

    return {
        products: root,
        isLoading,
    }
}
