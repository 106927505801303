import { set } from 'lodash'
import { AnyObjectSchema, ValidationError } from 'yup'

export const errorParser = (obj: any): string | undefined => {
    const objType = typeof obj

    if (!obj) {
        return
    }

    if (objType === 'object') {
        return Object.values(obj).map(errorParser).join(' ')
    }

    return obj
}

export const parseErrorsFromBE = (obj: any, errorsObj?: any): Record<string, string> => {
    const errors = errorsObj || {}

    Object.keys(obj).forEach((key) => {
        const error = obj[key]
        if (typeof error === 'object' && !Array.isArray(error)) {
            errors[key] = parseErrorsFromBE(error)
            return
        }

        return (errors[key] = errorParser(error))
    })

    return errors
}

export const parseYupError = (error: ValidationError, parsedErrors?: any) => {
    let _parsedErrors = parsedErrors || {}

    if (!!error?.inner) {
        error.inner.forEach((err: ValidationError) => parseYupError(err, _parsedErrors))
    }

    if (!!error?.type && !!error.path) {
        _parsedErrors = set(parsedErrors || _parsedErrors, error.path, error.errors)
    }

    return _parsedErrors
}

export const validateYupSchemaSync = (schema: AnyObjectSchema, data: any) => {
    try {
        schema.validateSync(data, { abortEarly: false })

        return {}
    } catch (error) {
        return parseYupError(error)
    }
}

export const createQueryResponseHandling = async (callback: Function) => {
    try {
        const data = await callback()
        return { data }
    } catch (error) {
        return { error }
    }
}

export class DetailedError extends Error {
    constructor(public message: string, public detail: any) {
        super(message)
        Object.setPrototypeOf(this, new.target.prototype)
    }
}
