import styled from 'styled-typed'
import { transparency } from 'utils/css'

export const ProductsCard = styled.div`
    background: ${(props) => transparency(props.theme.colors.status.error, 0.1)};
    border: 1px solid ${(props) => transparency(props.theme.colors.status.error, 0.6)};
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    border-radius: 4px;
    height: fit-content;
    margin-bottom: 1rem;
`

export const Container = styled.div`
    width: 25em;
`
export const NavigationSection = styled.div`
    font-size: 0.875em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2.5em;
`

export const TableHeader = styled.div`
    display: flex;
    font-weight: bold;
    color: ${(p) => p.theme.colors.textLight};
`

export const NameCell = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 14em;
    margin-right: 0.5em;
`

export const Cell = styled.div`
    padding: 0 0.2em;
    width: 4em;
    display: flex;
    justify-content: flex-end;
`

export const TableRow = styled.div`
    margin-top: 0.7em;
    display: flex;
`
