import { faSearch } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import styled from 'styled-typed'
import { IconTextInput } from 'uiComponents/input'

const Container = styled.div`
    width: 100%;
`

const Label = styled.div`
    font-size: 1em;
    font-weight: 500;
    padding: 1em 0;
`

const Content = styled.div`
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors.border};
`

const SearchFieldInput = styled(IconTextInput)`
    width: 100%;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: 4px 0 0 4px;
    margin-bottom: 1em;
    padding-left: 40px
    height: 42px;

    &:focus {
        box-shadow: none;
        border: 1px solid ${(props) => props.theme.colors.boyBlue};
    }
`

interface RuleContainerProps {
    label: string
    searchable?: boolean
    search?: string
    setSearch?: (search: string) => void
}

const RuleContainer: React.FC<RuleContainerProps> = (props) => {
    return (
        <Container>
            <Label>{props.label}</Label>
            {props.searchable && (
                <SearchFieldInput
                    iconLeft={faSearch}
                    placeholder="Search for product ( type at least 3 characters )"
                    value={props.search || ''}
                    onChange={(e) => props.setSearch && props.setSearch(e.target.value)}
                    block={true}
                />
            )}
            <Content>{props.children}</Content>
        </Container>
    )
}

export default RuleContainer
