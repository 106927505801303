import * as React from 'react'
import { Col } from 'uiComponents/flex'
import { NumberField } from 'uiComponents/studio/form/fieldComponentSchema'
import { NumberComponent } from 'uiComponents/studio/form/fieldComponents'
import { ErrorMessage } from 'uiComponents/studio/form/errorMessage'
import { OnChangeFunc } from 'engageTools/studio/schema'

interface NumberFieldProps {
    field: NumberField
    style?: React.CSSProperties
    value: boolean | string | number
    error?: boolean
    handleChange: OnChangeFunc
}

interface NumberFieldState {
    numberInputValid: boolean
}

export class NumberFieldElement extends React.Component<NumberFieldProps, NumberFieldState> {
    private timeoutHandler: any = null
    constructor(props: NumberFieldProps) {
        super(props)
        this.state = {
            numberInputValid: true,
        }
    }

    flashRangeMessage = () => {
        this.setState({ numberInputValid: false }, () => {
            this.timeoutHandler = setTimeout(() => {
                this.setState({ numberInputValid: true })
            }, 3000)
        })
    }

    componentWillUnmount() {
        if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler)
        }
    }

    resetValidation = () => {
        this.setState({ numberInputValid: true })
        clearTimeout(this.timeoutHandler)
    }

    render() {
        const { field, style, value } = this.props
        return (
            <>
                <Col span={field.span} style={style} className="justify-right">
                    <NumberComponent
                        field={field}
                        handleChange={this.props.handleChange}
                        resetValidation={this.resetValidation}
                        value={value as number}
                        checkValidity={this.flashRangeMessage}
                        status={!this.state.numberInputValid || this.props.error ? 'error' : 'normal'}
                    />
                </Col>
                {!this.state.numberInputValid && (
                    <Col span={12}>
                        <ErrorMessage flash errText={`The value should be between ${field.min} and ${field.max}`} />
                    </Col>
                )}
            </>
        )
    }
}
