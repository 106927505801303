import * as React from 'react'
import { Upsell } from 'admin/articleService'
import { Col } from 'uiComponents/flex'
import { ImageUploadResponse } from 'http/imagesService'
import { UploadInput } from 'uiComponents/input/upload'
import { MessageKind } from 'uiComponents/messages'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { SelectComponent } from 'uiComponents/studio/form/fieldComponents'
import { NumberFieldElement } from 'engageTools/studio/form/numberFieldElement'
import { SingleSelectOption } from 'uiComponents/input/singleSelect'
import Infotip from 'uiComponents/infotip'
import { AlignedCol } from './globalSection'
import { ErrorMessage } from 'uiComponents/studio/form/errorMessage'
import { StudioDeleteIcon } from 'uiComponents/studio/icons/deleteIcon'

interface UpsellItemProps {
    upsell: Upsell
    allUpsells: Upsell[]
    enabledUpsells: string[]
    markAllErrors: boolean
    onSelect: (value: string) => void
    onPriorityChange: (value: string) => void
    onDelete: () => void
    onUpload: (file: File) => Promise<any>
    onUploadSuccess: (response: ImageUploadResponse) => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

export function UpsellItem(props: UpsellItemProps) {
    const [upsellOptions, setUpsellOptions] = React.useState<SingleSelectOption[]>([])

    React.useEffect(() => {
        const upsellsToList = props.allUpsells.filter(
            (u) => !props.enabledUpsells.includes(u.id) || u.id === props.upsell.id,
        )
        const options = upsellsToList.map((u) => ({
            name: `(${u.numericId}) ${u.name}`,
            value: u.id,
        }))
        setUpsellOptions(options)
    }, [props.allUpsells])

    type UploadType = 'upload'
    type SelectType = 'select'
    type NumberType = 'number'
    const bannerUploadField = {
        name: 'banner-upload',
        type: 'upload' as UploadType,
        label: 'Upload banner (recommended: square 300x300px)',
        path: '',
        defaultValue: true,
    }
    const priorityField = {
        name: 'priority',
        type: 'number' as NumberType,
        min: 0,
        max: 999,
        label: 'Priority',
        span: 12,
        unit: '',
        path: '',
        defaultValue: null,
        placeholder: '99',
        infotip:
            'Lower number means higher priority. Only two upsells with the highest priority and \
      from the same category as the order items will be shown in the email.',
    }
    const upsellsSelectField = {
        type: 'select' as SelectType,
        name: 'upsells-list',
        label: 'Upsells',
        span: 12,
        path: '',
        defaultValue: null,
        availableOptions: upsellOptions,
        infotip: '',
    }

    return (
        <>
            <FormItem>
                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor={priorityField.name}>
                        {priorityField.label}
                        <Infotip pointer="left" maxWidth="17em" smallBoxText>
                            {priorityField.infotip}
                        </Infotip>
                    </label>
                </Col>
                <Col span={2.2} style={{ padding: '0' }}>
                    <NumberFieldElement
                        field={priorityField}
                        handleChange={props.onPriorityChange}
                        value={props.upsell.preEventEmailPriority || ''}
                        error={props.markAllErrors && props.upsell.preEventEmailPriority === null}
                    />
                </Col>
                <Col span={9.1}>
                    <SelectComponent
                        field={upsellsSelectField}
                        noSelectOption="Select an upsell"
                        handleChange={props.onSelect}
                        value={props.upsell.id || ''}
                        status={props.upsell.enabled ? 'normal' : 'error'}
                    />
                    {!props.upsell.enabled && (
                        <ErrorMessage
                            errText="This upsell is disabled and will not be inluded in the email."
                            style={{ marginBottom: '-1em' }}
                        />
                    )}
                </Col>
                <AlignedCol span={0.5} className="allign-right">
                    <StudioDeleteIcon onDelete={props.onDelete} style={{ right: '0.8em' }} />
                </AlignedCol>
            </FormItem>
            <FormItem>
                <Col span={12}>
                    <label htmlFor={bannerUploadField.name}>{bannerUploadField.label}</label>
                </Col>
                <Col span={12}>
                    <UploadInput
                        type="img"
                        fileSizeLimit={0.25}
                        onUpload={props.onUpload}
                        handleUploadSuccessResponse={(response: ImageUploadResponse) => props.onUploadSuccess(response)}
                        inputId={props.upsell.id}
                        status={props.markAllErrors && !props.upsell.emailImage ? 'error' : 'normal'}
                        replaceTopMessages={props.replaceTopMessages}
                        removeAllMessages={props.removeAllMessages}
                    />
                </Col>
            </FormItem>
        </>
    )
}
