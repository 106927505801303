import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { dateRangeFromQuery } from 'dateRanges'
import { BaseRouteParams, useNavigation } from 'hocs'
import { formatTableState } from 'uiComponents/table/reactTable/tableState'
import { IOrder, IOrderPayment } from 'orders/schema'
import { format } from 'date-fns'
import { useAppSelector } from 'store/hooks'
import { differenceInDays } from 'date-fns'
import { useEnsureTokenNotExpiredMutation } from 'orders/reduxQueries'
import { DateFormats, formatISOString } from 'utils/dates'

interface QueryFilters {
    channels: string
    payment_method: string
    status: string
    email_status: string
    products: string
    locations: string
}

export const useOrdersPageQuery = () => {
    const navigation = useNavigation()
    const { hiddenColumns, ...query } = navigation.query()
    const queryString = JSON.stringify(query)
    const { accountSlug } = useParams<BaseRouteParams>()
    const formatRangeDate = (date: Date) => format(date, 'yyyy-MM-dd')

    return useMemo(() => {
        const _query = JSON.parse(queryString)
        const tableState = formatTableState.toQuery(_query)
        const decodedString = decodeURIComponent(query.filter)
        const keyValuePairs = decodedString.split(';')
        const resultObject = keyValuePairs.reduce((acc, pair) => {
            const [key, value] = pair.split(':')
            acc[key as keyof QueryFilters] = value
            return acc
        }, {} as QueryFilters)

        const dateFilterParams = ((query: Record<string, string>) => {
            const dateRange = dateRangeFromQuery(query, 'today')
            if (query.search || query.q) {
                return {
                    fromCreatedAt: '',
                    toCreatedAt: '',
                    fromEventDate: '',
                    toEventDate: '',
                }
            }
            return {
                fromCreatedAt:
                    query.dateRangeType === 'sale' || !query.dateRangeType ? formatRangeDate(dateRange.from) : '',
                toCreatedAt:
                    query.dateRangeType === 'sale' || !query.dateRangeType ? formatRangeDate(dateRange.to) : '',
                fromEventDate: query.dateRangeType === 'visit' ? formatRangeDate(dateRange.from) : '',
                toEventDate: query.dateRangeType === 'visit' ? formatRangeDate(dateRange.to) : '',
            }
        })(_query)

        return {
            account_slug: accountSlug,
            include: 'location,customer,payments,reseller,discounts',
            sort_by: tableState.sortBy ?? '',
            sort_direction: tableState.dir ?? 'desc',
            search: (_query.search || _query.q) ?? '',
            search_by: _query.search ? _query.searchBy ?? '' : '',
            resellers: resultObject['channels'] !== 'direct_sales' ? resultObject['channels'] ?? '' : '',
            direct_sales: resultObject['channels'] === 'direct_sales' ? 'true' : 'false',
            payment_methods: resultObject['payment_method'] ?? '',
            locations: resultObject['locations'] ?? '',
            from_created_at: dateFilterParams.fromCreatedAt,
            to_created_at: dateFilterParams.toCreatedAt,
            from_event_date: dateFilterParams.fromEventDate,
            to_event_date: dateFilterParams.toEventDate,
            status: resultObject['status'] ?? '',
            email_status: resultObject['email_status'] ?? '',
            products: resultObject['products'] ?? '',
            page_size: tableState.pageSize.toString() ?? '10',
            offset: tableState.cursor ?? '0',
            date_range_type: _query.dateRangeType ?? 'sale',
        }
    }, [accountSlug, queryString])
}

export type OrdersPageQueryResult = ReturnType<typeof useOrdersPageQuery>

export const getLastPayment = (payments: IOrderPayment[]): IOrderPayment | undefined => {
    return payments.reduce((acc, payment) => {
        return new Date(acc.paymentDate) > new Date(payment.paymentDate) ? acc : payment
    }, payments[0])
}

export const formatTimestamp = (timestamp: string, timeZone: string) =>
    formatISOString(timestamp, `${DateFormats.LONG_DATE} ${DateFormats.SHORT_TIME}`, { timeZone })

export const getDiscountCodesString = (order: IOrder) => {
    if (order.discounts.length === 1) {
        return order.discounts[0].code
    }
    return order.discounts.reduce((acc, discount) => {
        if (acc === '') {
            return discount.code
        }
        return `${acc}|${discount.code}`
    }, '')
}

export const isDateRangeMoreThanOneDay = (query: Record<string, string>) => {
    if (query.date_range_type === 'sale') {
        return differenceInDays(new Date(query.to_created_at), new Date(query.from_created_at)) > 1
    }
    return differenceInDays(new Date(query.to_event_date), new Date(query.from_event_date)) > 1
}

export type UseDownloadLinkPayload = OrdersPageQueryResult & { export_format: string }

export const useDownloadLink = () => {
    const ticket = useAppSelector((state) => state.auth.ticket)
    const [ensureTokenNotExpired] = useEnsureTokenNotExpiredMutation()

    return useCallback(async (downloadLink: string, payload: UseDownloadLinkPayload) => {
        if (!ticket) {
            return
        }

        try {
            await ensureTokenNotExpired(ticket)
            let frame = document.getElementById('download-frame') as HTMLIFrameElement
            if (!frame) {
                frame = document.createElement('iframe')
                frame.setAttribute('id', 'download-frame')
                frame.setAttribute('name', 'download-frame')
                frame.style.display = 'none'
                document.body.appendChild(frame)
            }
            const doc = frame.contentDocument || (frame.contentWindow ? frame.contentWindow.document : null)
            if (doc === null) {
                // tslint:disable-next-line no-console
                console.error('Unable to access iframe document')
                return
            }
            doc.body.innerHTML = ''
            const form = doc.createElement('form') as HTMLFormElement
            form.setAttribute('method', 'POST')
            form.setAttribute('action', downloadLink)
            const token = doc.createElement('input') as HTMLInputElement
            token.setAttribute('type', 'hidden')
            token.setAttribute('name', 'token')
            token.setAttribute('value', ticket.accessToken)
            form.appendChild(token)

            Object.entries(payload).forEach(([key, value]) => {
                const input = doc.createElement('input') as HTMLInputElement
                input.setAttribute('type', 'hidden')
                input.setAttribute('name', key)
                input.setAttribute('value', String(value))
                form.appendChild(input)
            })

            doc.body.appendChild(form)
            form.submit()
        } catch (e) {}
    }, [])
}
