import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AppServices } from 'middleware'
import { formatISOString } from 'utils'
import { getGroups, getItems } from '../utils'
import { TimelineGroup, TimelineItem } from '../types'

interface FetchDataParams {
    selectedDate: Date
    accountSlug: string
}

export const initCapacityReport = createAsyncThunk<
    { openingTimes: [string, string]; groups: TimelineGroup[]; items: TimelineItem[] },
    FetchDataParams,
    { rejectValue: string }
>('inventory/fetchData', async ({ selectedDate, accountSlug }, { rejectWithValue }) => {
    if (!accountSlug) {
        return rejectWithValue('Account slug is missing')
    }

    try {
        const [{ openingTime, closingTime }] = await AppServices.openingTimesService.getOpeningTimes(accountSlug)

        const inventoryList = await AppServices.inventoryService.getInventoryList(accountSlug)
        const resourceIds = inventoryList.map((item) => item.id)

        if (!resourceIds.length) {
            return { openingTimes: [openingTime, closingTime], groups: [], items: [] }
        }

        const availabilityList = await AppServices.reservationsService.getReservationsList({
            date_from: `${formatISOString(selectedDate, 'yyyy-MM-dd')}T${openingTime}:00.000Z`,
            date_to: `${formatISOString(selectedDate, 'yyyy-MM-dd')}T${closingTime}:00.000Z`,
            resource_ids: resourceIds.join(','),
        })

        const groups = getGroups(inventoryList)
        const items = getItems(availabilityList)

        return { openingTimes: [openingTime, closingTime], groups, items }
    } catch (error) {
        return rejectWithValue(error instanceof Error ? error.message : 'An unknown error occurred')
    }
})

interface InventoryState {
    isLoading: boolean
    isReady: boolean
    items: TimelineItem[]
    groups: TimelineGroup[]
    openingTimes: [string, string] | []
    error: string | null
}

const initialState: InventoryState = {
    isLoading: false,
    isReady: false,
    items: [],
    groups: [],
    openingTimes: [],
    error: null,
}

const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(initCapacityReport.pending, (state) => {
                state.isLoading = true
                state.isReady = false
                state.items = []
                state.groups = []
                state.error = null
            })
            .addCase(initCapacityReport.fulfilled, (state, action) => {
                state.isLoading = false
                state.isReady = true
                state.openingTimes = action.payload.openingTimes
                state.groups = action.payload.groups
                state.items = action.payload.items
            })
            .addCase(initCapacityReport.rejected, (state, action) => {
                state.isLoading = false
                state.isReady = false
                state.error = action.payload ?? 'An unknown error occurred'
            })
    },
})

export default inventorySlice.reducer
