import { QueryConfig } from 'reports/queryGenerator'
import { TimeseriesStats } from 'reports/schema'
import { parseISODate } from 'utils/dates'

export const queryConfig: QueryConfig = {
    querySetName: 'CustomersTimeseriesChart',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'metric', type: 'StatsMetric' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'groupBy', type: 'String' },
        { name: 'filters', type: '[FilterDictionary]' },
        { name: 'granularity', type: 'StatsGranularity' },
    ],
    queries: [
        {
            name: 'capacity',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'venue_capacity_pools' }],
            presetResult: 'timeseries',
        },
        {
            name: 'salesTotals',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'filters'],
            customVariables: [],
            presetResult: 'timeseries',
        },
        {
            name: 'salesPerItem',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'filters', 'groupBy'],
            customVariables: [],
            presetResult: 'timeseries',
        },
        {
            name: 'revenueTotals',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'item_revenue_with_capacity_limits' }],
            presetResult: 'timeseries',
        },
        {
            name: 'revenuePerItem',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters', 'groupBy'],
            customVariables: [{ name: 'metric', customValue: 'item_revenue_with_capacity_limits' }],
            presetResult: 'timeseries',
        },
    ],
}

export const shouldShowCustomChart = (groupedlData: TimeseriesStats) => {
    if (!groupedlData.timeSeries || groupedlData.timeSeries.length === 0) {
        return false
    }
    const oneTimeslot = groupedlData.timeSeries[0].points.length < 2
    const wholeDayTimeSlot =
        groupedlData.timeSeries[0].points.length > 0
            ? parseISODate(groupedlData.timeSeries[0].points[0].timestamp).getHours() === 0
            : true
    return !oneTimeslot || !wholeDayTimeSlot
}
