import * as React from 'react'
import styled from 'styled-typed'
import { LoginService } from 'http/loginService'
import DownloadLink from 'uiComponents/downloadLink'
import { OnClickMenu, OnClickMenuItem } from 'uiComponents/menus/'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowCircleDown,
    faArrowCircleUp,
    faPlus,
    faFileTimes,
    faTrash,
    faEdit,
} from '@fortawesome/pro-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome'
import { Text } from 'uiComponents/typography'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { RemoveUnusedCodesItem, ReservationCodesPool } from 'venue/bookingCodes/bookingCodesService'
import { EditPoolDialogContext } from './editPoolDialog/context'
import { Feature } from 'features'

const Icon = styled(FontAwesomeIcon)`
    margin-right: 0.5em;
`

interface ActionsProps {
    accountSlug: string
    backofficeEndpoint: string
    poolId: string
    pool?: ReservationCodesPool
    poolHasCodes?: boolean
    batchId?: string
    loginService: LoginService
    onDelete: () => void
    onGenerateCodes?: () => void
    onRemoveUnused: (item: RemoveUnusedCodesItem) => void
}

export function ActionsMenu(props: ActionsProps) {
    const { toggle, selectCampaign } = React.useContext(EditPoolDialogContext)
    const downloadEndpoint = () => {
        return props.batchId
            ? `${props.backofficeEndpoint}api/v1/accounts/${props.accountSlug}/reservation_code_reservoirs/${props.poolId}/batches/${props.batchId}/download/`
            : `${props.backofficeEndpoint}api/v1/accounts/${props.accountSlug}/reservation_code_reservoirs/${props.poolId}/download/`
    }

    const onRemoveUnused = () => {
        props.onRemoveUnused({
            poolId: props.poolId,
            batchId: props.batchId || null,
        })
    }

    const toggleEditDialog = () => {
        if (!!props.pool) {
            toggle()
            selectCampaign(props.pool)
        }
    }

    return (
        <OnClickMenu
            title="Actions"
            kind="action"
            width="13.5em"
            size={props.batchId ? 'small' : 'medium'}
            secondary
            alignRight
        >
            {(props.batchId || props.poolHasCodes) && (
                <DownloadLink loginService={props.loginService} downloadEndpoint={downloadEndpoint()}>
                    <OnClickMenuItem>
                        <Icon icon={faArrowCircleDown as IconProp} />
                        Download codes
                    </OnClickMenuItem>
                </DownloadLink>
            )}
            {!props.batchId && props.onGenerateCodes && (
                <>
                    <OnClickMenuItem onClick={props.onGenerateCodes}>
                        <Icon icon={faPlus as IconProp} />
                        Generate batch
                    </OnClickMenuItem>
                    <OnClickMenuItem>
                        <UnstyledLink
                            to={`/account/${props.accountSlug}/venue/codes/reservations/upload/${props.poolId}`}
                        >
                            <>
                                <Icon icon={faArrowCircleUp as IconProp} />
                                Upload batch
                            </>
                        </UnstyledLink>
                    </OnClickMenuItem>
                </>
            )}
            {(props.batchId || props.poolHasCodes) && (
                <OnClickMenuItem onClick={onRemoveUnused}>
                    <Icon icon={faFileTimes as IconProp} />
                    Remove unused codes
                </OnClickMenuItem>
            )}
            <Feature name="ScannableReservationCodesFeature" accountSlug={props.accountSlug}>
                {!!props.pool && (
                    <OnClickMenuItem onClick={toggleEditDialog}>
                        <Icon icon={faEdit as IconProp} />
                        Edit
                    </OnClickMenuItem>
                )}
            </Feature>
            <OnClickMenuItem onClick={props.onDelete}>
                <Text status="error">
                    <Icon icon={faTrash as IconProp} />
                    {props.batchId ? 'Remove all codes' : 'Delete'}
                </Text>
            </OnClickMenuItem>
        </OnClickMenu>
    )
}
