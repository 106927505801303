import * as React from 'react'
import styled from 'styled-typed'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DisabledItemHoverInfotip } from 'uiComponents/infotip'
import { MenuToggleComponent, Container, OptionsListBox } from './index'
import { FilterToggleContainer } from 'uiComponents/filter/filterComponents'
import { IconProp } from '@fortawesome/fontawesome'

interface FirstLevelProps {
    topPadding: number
}

interface OptionProps {
    height: number
}

const FirstLevel = styled.div<FirstLevelProps>`
    position: absolute;
    top: 2.4em;
    width: max-content;
    min-width: 126px;
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: ${(props) => `${props.topPadding}em 0 1.2em 0`};
    display: none;

    &.visible {
        display: flex;
        flex-direction: column;
    }
`
const FirstLevelOption = styled.div<OptionProps>`
    font-size: 0.875rem;
    position: relative;
    display: flex;
    align-items: center;
    height: ${(props) => `${props.height}em`};
    padding: 0 2.6em 0 1.85em;
    cursor: pointer;

    &.active,
    &:hover {
        background: ${(props) => props.theme.colors.background};
    }
    &.applied {
        color: ${(props) => props.theme.colors.textLight};
    }
`
const Icon = styled(FontAwesomeIcon)`
    position: absolute;
    top: 0.6em;
    right: 1em;
    opacity: 0;
    transition: opacity 0.2s;

    &.visible {
        opacity: 1;
    }
`

interface TwoLevelMenuProps {
    children: React.ReactNode
    menuToggleText: string
    menuToggleIcon?: IconProp
    firstLevelOptions: { name: string; slug: string }[]
    disabledInfotipText: string
    openMenu?: boolean
    closeMenu?: boolean
    disabled?: boolean
    loading?: boolean
    activeOptionOverride: string
    selectedCount?: number
    expandLeft?: boolean
    maxSecondLevelWidth?: string
    onActiveOptionChange: (option: string) => void
}

interface TwoLevelMenuState {
    showFirstLevel: boolean
    showSecondLevel: boolean
    ignoreOutsideClick: boolean
    categoriesBoxWidth: number
    optionsBoxTopOffset: number
    activeOption: string
}

export class TwoLevelMenu extends React.Component<TwoLevelMenuProps, TwoLevelMenuState> {
    container: HTMLDivElement | null = null
    firstLevelBox: HTMLDivElement | null = null
    firstLevelBoxTopPadding = 1.3
    firstLevelOptionHeight = 2.15

    constructor(props: TwoLevelMenuProps) {
        super(props)
        this.state = {
            showFirstLevel: false,
            showSecondLevel: false,
            ignoreOutsideClick: false,
            categoriesBoxWidth: 0,
            optionsBoxTopOffset: 0,
            activeOption: '',
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.outsideClick, false)
    }

    componentDidUpdate(prevProps: TwoLevelMenuProps) {
        if (this.props.openMenu && !prevProps.openMenu) {
            this.openMenu()
        }
        if (this.props.closeMenu && !prevProps.closeMenu) {
            this.closeMenu()
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.outsideClick, false)
    }

    setContainerRef = (node: HTMLDivElement) => {
        this.container = node
    }

    setCategoriesRef = (node: HTMLDivElement) => {
        this.firstLevelBox = node
    }

    outsideClick = (ev: MouseEvent) => {
        if (this.state.ignoreOutsideClick) {
            this.setState({ ignoreOutsideClick: false })
            return
        }
        if (this.container && this.container.contains && !this.container.contains(ev.target as Node)) {
            this.closeMenu()
        }
    }

    openMenu = () => {
        this.setState(
            {
                ignoreOutsideClick: true,
                showFirstLevel: true,
                activeOption: this.props.activeOptionOverride,
            },
            () => {
                if (this.firstLevelBox) {
                    this.setState({
                        categoriesBoxWidth: this.firstLevelBox.clientWidth,
                        showSecondLevel: true,
                    })
                }
            },
        )
    }

    closeMenu = () => {
        this.setState({
            showFirstLevel: false,
            showSecondLevel: false,
            activeOption: '',
        })
    }

    toggleFirstLevel = () => {
        if (this.props.loading || this.props.disabled) {
            return
        }
        this.setState({ showFirstLevel: !this.state.showFirstLevel }, () => {
            if (this.state.showFirstLevel && this.firstLevelBox) {
                this.setState({ categoriesBoxWidth: this.firstLevelBox.clientWidth })
            }
        })
    }

    toggleSecondLevel = (option: string, index: number) => {
        const offset = this.firstLevelBoxTopPadding + this.firstLevelOptionHeight * index
        this.setState({
            showSecondLevel: true,
            activeOption: option,
            optionsBoxTopOffset: offset,
        })
        this.props.onActiveOptionChange(option)
    }

    render() {
        const { showFirstLevel, showSecondLevel, categoriesBoxWidth, activeOption, optionsBoxTopOffset } = this.state
        const { children, firstLevelOptions, menuToggleText, disabledInfotipText, disabled, loading, menuToggleIcon } =
            this.props
        const expanded = !disabled && showFirstLevel

        return (
            <Container className="two__level__menu" ref={this.setContainerRef}>
                <DisabledItemHoverInfotip active={disabled} infotipText={disabledInfotipText} width="20em">
                    <FilterToggleContainer>
                        <MenuToggleComponent
                            menuName={menuToggleText}
                            icon={menuToggleIcon}
                            selectedCount={this.props.selectedCount}
                            loading={!!loading}
                            expanded={expanded}
                            toggleOptionsContainer={this.toggleFirstLevel}
                        />
                    </FilterToggleContainer>
                </DisabledItemHoverInfotip>
                <FirstLevel
                    className={showFirstLevel ? 'visible' : ''}
                    topPadding={this.firstLevelBoxTopPadding}
                    ref={this.setCategoriesRef}
                >
                    {firstLevelOptions.map((c, i) => (
                        <FirstLevelOption
                            key={c.slug}
                            onClick={() => this.toggleSecondLevel(c.slug, i)}
                            className={activeOption === c.slug ? 'active' : ''}
                            height={this.firstLevelOptionHeight}
                        >
                            {c.name}
                            <Icon icon={faCaretRight} className={activeOption === c.slug ? '' : 'visible'} />
                        </FirstLevelOption>
                    ))}
                    <OptionsListBox
                        className={showSecondLevel ? 'visible two__level__menu__second' : ''}
                        offsetLeft={categoriesBoxWidth}
                        offsetTop={optionsBoxTopOffset}
                        expandLeft={this.props.expandLeft}
                        maxWidth={this.props.maxSecondLevelWidth}
                    >
                        {children}
                    </OptionsListBox>
                </FirstLevel>
            </Container>
        )
    }
}
