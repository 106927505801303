import * as React from 'react'
import { ModalDialog, ModalDialogTitle } from 'uiComponents/popups/modal'
import { DownloadElement } from 'uiComponents/input/downloadComponent'
import { UploadInput } from 'uiComponents/input/upload'
import { UploadDetails } from 'venue/bookingCodes/bookingCodesService'
import { MessageKind } from 'uiComponents/messages'

interface DialogProps {
    accountSlug: string
    onCancel: () => void
    onUpload: (file: File) => Promise<UploadDetails>
    onRemoveSuccess: () => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

export function RemovedUnusedCodesDialog(props: DialogProps) {
    const getCsvDownloadHref = () => {
        const csvRows = ['code', '12345678901234567890', '12345678901234567891', '12345678901234567892']
        const csv = csvRows.join('\n')
        return 'data:text/csv;charset=utf-8,' + encodeURI(csv)
    }

    const onRemoveError = () => {
        props.replaceMessages('remove_error', 'error', 'Oops! Unable to upload file. Please try again later.')
        props.onCancel()
    }

    return (
        <ModalDialog onDismiss={props.onCancel} interactive fromTop="20%">
            <div style={{ width: '25em' }}>
                <ModalDialogTitle>Remove unused codes</ModalDialogTitle>
                <DownloadElement exampleCSVHref={getCsvDownloadHref()} exampleFileName="remove_unused_codes_example" />
                <UploadInput
                    type="file"
                    acceptedTypesMsg="CSV"
                    acceptedTypes="text/csv"
                    extensionCheckOnly
                    onUpload={props.onUpload}
                    handleUploadSuccessResponse={props.onRemoveSuccess}
                    handleUploadError={onRemoveError}
                    style={{ height: '5em', margin: '1.5em 0 1em 0' }}
                    uploadArea
                    inputId="upload"
                    fileSizeLimit={16}
                    parseErrorMessage
                    replaceTopMessages={props.replaceMessages}
                    removeAllMessages={props.removeAllMessages}
                />
            </div>
        </ModalDialog>
    )
}
