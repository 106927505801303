import React, { useState, useEffect } from 'react'
import { Row, Col } from 'uiComponents/flex'
import { FormItem, FormItemName } from 'uiComponents/form/formElements'
import { MultiSelect, SingleSelectOption } from 'uiComponents/input'
import { OptionGroup } from 'products/options/optionsService'
import { LinkText } from 'uiComponents/typography'
import { LEFT_SIDE_SPAN, RIGHT_SIDE_SPAN } from './common'

interface BarcodesProps {
    productOptions: string[]
    productOptionsList: OptionGroup[]
    onSelectChange: (options: string[]) => void
    onNavigateToOptionGroups: (e: React.MouseEvent) => void
}

export function Options(props: BarcodesProps) {
    const [articleOptionsSelection, setArticleOptionsSelection] = useState<SingleSelectOption[]>([])

    useEffect(() => {
        const options = props.productOptionsList.map((r) => ({
            name: r.name,
            value: r.uuid,
        }))
        setArticleOptionsSelection(options)
    }, [props.productOptionsList])

    return (
        <Row style={{ marginBottom: '1em' }}>
            <Col span={LEFT_SIDE_SPAN}>
                <FormItem htmlFor="options-multiselect" style={{ position: 'relative' }}>
                    <FormItemName>Product options</FormItemName>
                    {articleOptionsSelection.length === 0 && (
                        <LinkText>
                            Go to the <span onClick={props.onNavigateToOptionGroups}>Option groups tab</span> of this
                            page to create or manage your product options
                        </LinkText>
                    )}
                    {articleOptionsSelection.length > 0 && (
                        <MultiSelect
                            id="options-multiselect"
                            options={articleOptionsSelection}
                            selected={props.productOptions}
                            height="2.5rem"
                            maxHeight="17em"
                            noSelectOption="No options attached"
                            onSelect={props.onSelectChange}
                            style={{ marginBottom: '0.5em' }}
                        />
                    )}
                </FormItem>
            </Col>
            <Col span={RIGHT_SIDE_SPAN} />
        </Row>
    )
}
