import React, { FC } from 'react'
import { InstructionsCard } from 'snippet/instructionsCard'
import gtmIcon from './snippetContainer/wordpress.svg'
import { ActionButton } from 'uiComponents/buttons'
import './wordPressCard.scss'

type WordPressCardProps = {
    detectSnippet: () => void
}

export const WordPressCard: FC<WordPressCardProps> = ({ detectSnippet }) => {
    const closedSubHeader = 'If you use WordPress, all you need to do is follow the steps mentioned here.'
    const openedSubHeader = (
        <>
            If you use WordPress, all you need to do is follow the steps below. For more information check the
            article&nbsp;
            <a
                href="https://support.convious.com/help/convious-script-installation-with-the-word-press-plug-in"
                target="_blank"
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
            >
                Convious script installation with the Word Press Plug-in
            </a>
        </>
    )

    return (
        <InstructionsCard
            iconSrc={gtmIcon}
            header="I use WordPress"
            closedSubHeader={closedSubHeader}
            openedSubHeader={openedSubHeader}
            className="wordpress-card"
        >
            <div className="wordpress-card__instructions">
                <div className="wordpress-card__list">
                    <div className="wordpress-card__list-item">
                        <a href="https://cdn2.hubspot.net/hubfs/4193864/Convious%20WP%20PlugIn%20-%201.0.0.zip">
                            Download
                        </a>
                        &nbsp; the file
                    </div>
                    <div className="wordpress-card__list-item">Log into your WordPress back-end and select Plugins</div>
                    <div className="wordpress-card__list-item">Click &apos;Add New&apos; from the menu</div>
                    <div className="wordpress-card__list-item">
                        Press &apos;Upload Plugin&apos; button on top of screen
                    </div>
                    <div className="wordpress-card__list-item">
                        Select &apos;Convious PlugIn&apos; (.zip file) from your computer and press &apos;Install
                        Now&apos;
                    </div>

                    <div className="wordpress-card__list-item">
                        Click &apos;Activate Plugin&apos; after it&apos;s installed
                    </div>
                    <div className="wordpress-card__list-item">
                        Select &apos;Settings&apos; → &apos;Convious&apos; from the menu
                    </div>
                    <div className="wordpress-card__list-item">
                        Enter your Convious&nbsp;
                        <a
                            href="https://support.convious.com/help/convious-partner-id"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Partner ID
                        </a>
                    </div>
                    <div className="wordpress-card__list-item">Save changes</div>
                </div>
                <div className="wordpress-card__instructions-text">You’re done!</div>
            </div>
            <ActionButton size="large" style={{ marginTop: '1.5em' }} onClick={detectSnippet}>
                Check now
            </ActionButton>
        </InstructionsCard>
    )
}
