import styled, { css } from 'styled-typed'
import { Checkbox } from 'uiComponents/input'

export const Container = styled.div`
    position: relative;
    z-index: 10;
`

const standaloneOptionCss = css`
    font-weight: 500;
    padding-top: 0.4175em;
    padding-bottom: 0.4175em;
    background: ${(props) => props.theme.colors.tableRow};
    border-bottom: 1px solid #fff;
`

export const OptionBox = styled.div`
    cursor: pointer;
    display: flex;
    flex: 1 0 auto;
    padding: 0.5em 1.7em;
    font-weight: 300;
    &.parent {
        font-weight: 500;
    }
    &:hover {
        background: ${(props) => props.theme.colors.background};
    }
`

export const LeafNodeBox = styled.div<{ grade?: number }>`
    display: flex;
    flex: 1 0 auto;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    font-size: 0.75rem;
    font-weight: 400;
    ${(props) => props.onClick && 'cursor: pointer;'}

    margin-left: calc(-1.5rem * ${(props) => props.grade || 0});
    padding-left: calc(0.5rem + 1.5rem * ${(props) => props.grade || 0});
    padding-right: 1rem;

    &.leaf {
        &:hover {
            background: ${(props) => props.theme.colors.background};
        }
        & > div:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &.parent {
        ${standaloneOptionCss}
    }

    &.highlighted {
        background: ${(props) => props.theme.colors.background};
    }
`

export const OptionCheckBox = styled(Checkbox)`
    margin-right: 0.7em;
`

export const SubOptionsList = styled.div`
    margin-left: 1.5rem;
`

export const OptionsList = styled.div<{ maxHeight?: string }>`
    font-size: 0.875rem;
    max-height: ${(props) => props.maxHeight || '22em'};
    overflow: auto;
    padding: 1.2em 0;
    display: flex;
    flex-direction: column;

    &:hover {
        text-decoration: none;
    }
`

interface MenuLinkProps {
    selected?: boolean
}

const selectedCss = css`
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.textDark};
    cursor: default;
`

export const MenuLink = styled.div<MenuLinkProps>`
    cursor: pointer;
    padding: 0.3em 1em;
    ${(props) => (props.selected ? selectedCss : '')}
`

interface OptionsListBoxProps {
    offsetLeft: number
    offsetTop: number
    expandLeft?: boolean
    maxWidth?: string
}

export const OptionsListBox = styled.div<OptionsListBoxProps>`
    position: absolute;
    top: ${(props) => props.offsetTop}em;
    ${(props) => (props.expandLeft ? 'right' : 'left')}: ${(props) => props.offsetLeft}px;
    width: max-content;
    min-width: 100%;
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : '600px')};
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: none;

    &.visible {
        display: flex;
        flex-direction: column;
    }
`
