import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import * as yup from 'yup'
import { BaseRouteParams } from '../../hocs'
import { ActionButton, ActionButtonA } from '../../uiComponents/buttons'
import { FormWrapper } from '../../uiComponents/form/form'
import TextInputFieldFormik from '../../uiComponents/input/textInput/textInputFieldFormik'
import PhonePreview from '../components/phonePreview'
import ProgressBar from '../components/progressBar'
import Translations from '../components/translations'
import { PNLocalizedContent } from '../types'
import { Buttons, Container, ProgressBarContainer, ProgressWrapper } from './target'

const Field = styled.div`
    margin-bottom: 2em;
`

const Label = styled.div`
    font-size: 0.875rem;
    margin: 0.5em 0;
`

export const LeftContainer = styled.div`
    width: 55%;
`

const RightContainer = styled.div`
    width: 45%;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`

interface FormProps {
    data?: any
    onSubmit: (values: any) => void
}

const ContentForm = ({ data, onSubmit }: FormProps) => {
    const history = useHistory()
    const { accountSlug } = useParams<BaseRouteParams>()
    const progressBarItems = [
        {
            name: 'Target audience',
            active: true,
            done: true,
        },
        {
            name: 'Edit content',
            active: true,
        },
        {
            name: 'Schedule and publish',
        },
    ]

    const validationSchema = yup.object().shape({
        url: yup.string().required('Required'),
        content: yup.array(
            yup.object({
                locale: yup.string().required('Required'),
                title: yup.string().required('Required'),
                body: yup.string().required('Required'),
            }),
        ),
    })

    return (
        <Container>
            <FormWrapper
                enableReinitialize
                initialValues={data}
                onSubmit={(values) => {
                    const content: PNLocalizedContent[] = values?.content.map(
                        (content: PNLocalizedContent, index: number) => {
                            return { ...content, default: !index ? true : !!content.default }
                        },
                    )

                    onSubmit({
                        ...values,
                        content,
                    })
                    history.push(`/account/${accountSlug}/engage/push_notifications/schedule`)
                }}
                validationSchema={validationSchema}
            >
                <Wrapper>
                    <LeftContainer>
                        <ProgressWrapper>
                            <ProgressBarContainer>
                                <ProgressBar items={progressBarItems} />
                            </ProgressBarContainer>
                            <Buttons>
                                <ActionButtonA
                                    size="large"
                                    secondary
                                    href={`/account/${accountSlug}/engage/push_notifications/target`}
                                >
                                    Back
                                </ActionButtonA>
                                <ActionButton size="large" type="submit">
                                    Next
                                </ActionButton>
                            </Buttons>
                        </ProgressWrapper>
                        {/* <Col style={{ maxWidth: '25%', paddingRight: '10px' }}>*/}
                        {/*    <Field>*/}
                        {/*        <Label>Type</Label>*/}
                        {/*        <SingleSelectFieldFormik*/}
                        {/*            id="type"*/}
                        {/*            name="type"*/}
                        {/*            options={[*/}
                        {/*                {*/}
                        {/*                    value: 'web',*/}
                        {/*                    name: 'Web',*/}
                        {/*                },*/}
                        {/*                {*/}
                        {/*                    value: 'app',*/}
                        {/*                    name: 'Tap Application',*/}
                        {/*                },*/}
                        {/*            ]}*/}
                        {/*            noSelectOption="Select type"*/}
                        {/*            style={{*/}
                        {/*                backgroundColor: 'transparent',*/}
                        {/*                border: `1px solid ${theme.colors.border}`,*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*    </Field>*/}
                        {/* </Col>*/}
                        <Field>
                            <TextInputFieldFormik id="url" name="url" label="Url" placeholder="https://" />
                        </Field>
                        <Label>Message</Label>
                        <Translations
                            fieldName="content"
                            fields={[
                                { name: 'title', label: 'Title', type: 'input', placeholder: 'title' },
                                {
                                    name: 'body',
                                    label: 'Body',
                                    type: 'textarea',
                                    placeholder: 'body',
                                },
                            ]}
                        />
                    </LeftContainer>
                    <RightContainer>
                        <PhonePreview />
                    </RightContainer>
                </Wrapper>
            </FormWrapper>
        </Container>
    )
}

export default ContentForm
