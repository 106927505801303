import { useEffect, useMemo, useState } from 'react'

import { Navigation } from 'navigation'
import { TableColumn } from './interface'

export const useCreateReactTableColumns = (columns: TableColumn[], navigation: Navigation) => {
    const queryHiddenColumns = (navigation.query()['hiddenColumns'] || '').split('|')
    const [hiddenColumns, setHiddenColumns] = useState<string[]>(queryHiddenColumns)

    useEffect(() => {
        if (hiddenColumns.join('|') !== queryHiddenColumns.join('|')) {
            setHiddenColumns(queryHiddenColumns)
        }
    }, [hiddenColumns, queryHiddenColumns])

    return useMemo(
        () =>
            columns.map((column) => {
                column.getHiddenProps = () => {
                    return {
                        type: 'checkbox',
                        checked: hiddenColumns.indexOf(column.accessor as never) < 0,
                        onChange: () =>
                            setHiddenColumns((_hiddenColumns: string[]) => {
                                const clonedColumns = [..._hiddenColumns]
                                const index = _hiddenColumns.indexOf(column.accessor)

                                if (index >= 0) {
                                    clonedColumns.splice(index, 1)
                                } else {
                                    clonedColumns.push(column.accessor)
                                }

                                navigation.addQuery({ hiddenColumns: clonedColumns.join('|') })

                                return clonedColumns
                            }),
                    }
                }
                column.hiddenColumn = hiddenColumns.indexOf(column.accessor) >= 0

                return column
            }),
        [columns, hiddenColumns],
    )
}

export const getCssSizeFromString = (cssSize?: string | number) => {
    const regExp = /[a-zA-Z]/g
    const isDefaultReactTableWidth = 150 === cssSize

    if (!cssSize || isDefaultReactTableWidth) {
        return
    }

    if (regExp.test(cssSize.toString())) {
        return cssSize
    }

    return `${cssSize}px`
}
