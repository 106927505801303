import React from 'react'
import styled from 'styled-typed'
import TimeField from 'react-simple-timefield'
import { inputFieldStyles, successCss, errorCss } from './textInput'

type status = 'normal' | 'success' | 'error'

const TimeFieldWrapper = styled(TimeField)<TimeFieldProps>`
    ${inputFieldStyles}
    width: 100%;
    ${(props: TimeFieldProps) => (props.status === 'success' ? successCss : '')}
    ${(props: TimeFieldProps) => (props.status === 'error' ? errorCss : '')}
`

interface TimeFieldProps {
    id?: string
    name?: string
    value: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onKeyDown?: (e: KeyboardEvent) => void
    status?: status
    style?: React.CSSProperties
}

export function TimeInput(props: TimeFieldProps) {
    let styles = { width: '100%' }
    if (!!props.style) {
        styles = Object.assign(styles, props.style)
    }
    return (
        <TimeFieldWrapper
            id={props.id}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            style={styles}
            onKeyDown={props.onKeyDown}
            status={props.status}
        />
    )
}
