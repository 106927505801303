import React from 'react'
import classNames from 'classnames'
import ExpandIcon from '../../../commons/expandIcon'

interface ItemSectionProps {
    sectionName: string | React.ReactNode
    isItemSelected: boolean
    isItemCanceledRefunded?: boolean
    expanded?: boolean
}

const ItemSection: React.FC<ItemSectionProps> = ({
    sectionName,
    isItemSelected,
    children,
    expanded,
    isItemCanceledRefunded,
}) => {
    const [isExpanded, setIsExpanded] = React.useState(expanded ?? true)

    return (
        <div
            className={classNames({
                'item-info-section': true,
                selected: isItemSelected,
                canceledRefunded: isItemCanceledRefunded,
            })}
        >
            <div className="header">
                <ExpandIcon isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
                <p className="section-title">{sectionName}</p>
            </div>
            {isExpanded && <div className="section-content">{children}</div>}
        </div>
    )
}

export default ItemSection
