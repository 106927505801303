import React from 'react'
import styled from 'styled-typed'
import { SelectComponent, FormItem, SectionWrapper } from 'ticketTemplates/studio/imports'
import { Col } from 'uiComponents/flex'
import { UploadElement } from '../form/uploadElement'
import { ImagesServiceContext } from 'http/context'
import { MessageKind } from 'uiComponents/messages'
import { PickerComponent } from 'uiComponents/studio/form/fieldComponents'
import Infotip from 'uiComponents/infotip'

const AlignedCol = styled(Col)`
    display: flex;
    align-items: center;

    &.align-right {
        justify-content: flex-end;
    }
`

interface GiftCardGlobalSettingsProps {
    backgroundColor: string
    textColor: string
    productNameSize: string
    setProductNameSize: (value: string) => void
    setBackgroundColour: (value: string) => void
    setTextColour: (value: string) => void
    setImage: (urls: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

export function GiftCardGlobalSettings(props: GiftCardGlobalSettingsProps) {
    const imagesService = React.useContext(ImagesServiceContext)

    const fontSizeOptions = [
        { name: 'Small', value: 'SMALL' },
        { name: 'Medium', value: 'MEDIUM' },
        { name: 'Large', value: 'LARGE' },
    ]

    type UploadType = 'upload'
    type PickerType = 'picker'
    type SelectType = 'select'
    const fontSizeField = {
        type: 'select' as SelectType,
        name: 'font-size',
        label: 'Product name size',
        path: '',
        defaultValue: 'en',
        availableOptions: fontSizeOptions,
    }
    const backgroundColorField = {
        name: 'background-colour',
        type: 'picker' as PickerType,
        label: 'Background colour',
        path: '',
        defaultValue: '#F5F7FA',
    }
    const textColourField = {
        name: 'text-colour',
        type: 'picker' as PickerType,
        label: 'Text colour',
        path: '',
        defaultValue: '#5298F4',
    }
    const uploadField = {
        name: 'image-upload',
        type: 'upload' as UploadType,
        label: 'Upload logo (recommended 400x200px)',
        path: 'imagePath',
        defaultValue: '',
    }

    return (
        <SectionWrapper title="Global settings">
            <FormItem>
                <AlignedCol span={6}>
                    <label htmlFor={backgroundColorField.name}>{backgroundColorField.label}</label>
                </AlignedCol>
                <AlignedCol span={6} className="align-right">
                    <PickerComponent
                        field={backgroundColorField}
                        handleChange={(v: string) => props.setBackgroundColour(v)}
                        value={props.backgroundColor}
                    />
                </AlignedCol>
            </FormItem>
            <FormItem>
                <AlignedCol span={6}>
                    <label htmlFor={textColourField.name}>{textColourField.label}</label>
                </AlignedCol>
                <AlignedCol span={6} className="align-right">
                    <PickerComponent
                        field={textColourField}
                        handleChange={(v: string) => props.setTextColour(v)}
                        value={props.textColor}
                    />
                </AlignedCol>
            </FormItem>
            <FormItem>
                <AlignedCol span={6}>
                    <label htmlFor={fontSizeField.name}>{fontSizeField.label}</label>
                    <Infotip maxWidth="17em" pointer="left">
                        Please remmeber that the name of the product may be long.
                    </Infotip>
                </AlignedCol>
                <Col span={6} className="justify-right">
                    <SelectComponent
                        id="font-size"
                        field={fontSizeField}
                        handleChange={(v: string) => props.setProductNameSize(v)}
                        value={props.productNameSize}
                    />
                </Col>
            </FormItem>
            <FormItem>
                <Col span={12}>
                    <label htmlFor={uploadField.name}>{uploadField.label}</label>
                </Col>
                <Col span={12}>
                    <UploadElement
                        key={uploadField.name}
                        field={uploadField}
                        handleChange={(url) => props.setImage(url as string)}
                        imageOptions={{
                            max_width: 400,
                            max_height: 200,
                        }}
                        value=""
                        imagesService={imagesService}
                        replaceTopMessages={props.replaceMessages}
                        removeAllMessages={props.removeAllMessages}
                        type="img"
                    />
                </Col>
            </FormItem>
        </SectionWrapper>
    )
}
