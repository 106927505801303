import * as React from 'react'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { TextInput } from 'uiComponents/input'
import { Section, SectionForm, InputRow, InputColumn, PageActions } from 'uiComponents/settingsContainer'
import { ValidationCheckmark } from 'uiComponents/validationCheckmark'
import { Profile } from 'settings/schema'
import { AccountSettingsService } from 'settings/settingsService'
import { ActionButton } from 'uiComponents/buttons'
import { delay } from 'utils'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'

interface PasswordSectionProps {
    profile: Profile
    accountSettingsService: AccountSettingsService
    setActiveSection: (section: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

interface PasswordSectionState {
    oldPassword: string
    newPassword: string
    newPasswordRepeat: string
    oldPassMsg: string
    newPassRepeatMsg: string
    passValid: boolean | null
    loading: boolean
}

class PasswordSection extends React.Component<PasswordSectionProps, PasswordSectionState> {
    constructor(props: PasswordSectionProps) {
        super(props)
        this.state = {
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: '',
            oldPassMsg: '',
            newPassRepeatMsg: '',
            passValid: null,
            loading: false,
        }
    }

    componentDidMount() {
        this.props.setActiveSection('password')
    }

    onSave = async () => {
        this.props.removeAllMessages()
        if (!(await this.checkFormValid())) {
            return
        }
        this.setState({ loading: true })
        try {
            const resp = await this.props.accountSettingsService.updatePassword(
                this.state.oldPassword,
                this.state.newPassword,
            )
            if (resp.data && resp.data.changePassword.ok) {
                this.setState({
                    oldPassword: '',
                    newPassword: '',
                    newPasswordRepeat: '',
                    loading: false,
                })
                this.props.replaceMessages('success', 'success', 'Password updated successfully.')
                await delay(5000)
                this.props.removeAllMessages()
            } else if (resp.data && resp.data.changePassword.errCode) {
                this.setErrorMesssage(resp.data.changePassword.errCode)
            } else {
                this.setErrorMesssage('unknown')
            }
        } catch (_) {
            this.setErrorMesssage('unknown')
        }
    }

    setErrorMesssage = (errorMessage: string) => {
        this.setState({ loading: false })
        if (errorMessage === 'passInvalid') {
            this.setState({
                oldPassMsg: 'Current password does not match our records. Please try again.',
            })
        } else {
            this.props.replaceMessages('error', 'error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    checkFormValid = async () => {
        const oldPassProvided = this.checkOldPassProvided()
        const passValid = this.checkValidPass()
        const passMatch = this.checkPassMatch()
        if (!oldPassProvided || !passValid || !passMatch) {
            return false
        }
        return true
    }

    checkOldPassProvided = () => {
        if (!this.state.oldPassword) {
            this.setState({ oldPassMsg: 'Please provide current password.' })
            return false
        } else {
            this.setState({ oldPassMsg: '' })
        }
        return true
    }

    checkValidPass = () => {
        if (this.state.newPassword.length < 12) {
            this.setState({ passValid: false })
            return false
        } else {
            this.setState({ passValid: true })
        }
        if (this.state.newPasswordRepeat) {
            this.checkPassMatch()
        }
        return true
    }

    checkPassMatch = () => {
        if (this.state.newPassword !== this.state.newPasswordRepeat) {
            this.setState({ newPassRepeatMsg: 'Passwords do not match' })
            return false
        } else {
            this.setState({ newPassRepeatMsg: '' })
        }
        return true
    }

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ [event.target.name]: event.target.value } as any)
    }

    render() {
        const { oldPassword, newPassword, newPasswordRepeat, passValid, oldPassMsg, newPassRepeatMsg } = this.state
        return (
            <Section>
                {this.state.loading && <ChartDataLoader />}
                <SectionForm noValidate style={{ minHeight: '23em' }}>
                    <div>
                        <InputRow>
                            <InputColumn>
                                <FormItem htmlFor="oldPassword">
                                    <FormItemName>Current password</FormItemName>
                                    <TextInput
                                        id="oldPassword"
                                        name="oldPassword"
                                        type="password"
                                        value={oldPassword ? oldPassword : ''}
                                        onFinishTyping={this.checkOldPassProvided}
                                        onChange={this.handleInputChange}
                                        status={oldPassMsg ? 'error' : 'normal'}
                                        block={true}
                                    />
                                </FormItem>
                                <ValidationMessage className={oldPassMsg ? 'validation-message-visible' : ''}>
                                    {oldPassMsg || '&nbsp;'}
                                </ValidationMessage>
                            </InputColumn>
                            <InputColumn />
                        </InputRow>
                        <InputRow>
                            <InputColumn>
                                <FormItem htmlFor="newPassword">
                                    <FormItemName>Your new password</FormItemName>
                                    <TextInput
                                        id="newPassword"
                                        name="newPassword"
                                        type="password"
                                        value={newPassword ? newPassword : ''}
                                        onChange={this.handleInputChange}
                                        onFinishTyping={this.checkValidPass}
                                        status={passValid || passValid == null ? 'normal' : 'error'}
                                        block={true}
                                    />
                                </FormItem>
                                <ValidationCheckmark text="At least 12 characters long" valid={passValid} />
                            </InputColumn>
                            <InputColumn>
                                <FormItem htmlFor="newPasswordRepeat">
                                    <FormItemName>Repeat new password</FormItemName>
                                    <TextInput
                                        id="newPasswordRepeat"
                                        name="newPasswordRepeat"
                                        type="password"
                                        value={newPasswordRepeat ? newPasswordRepeat : ''}
                                        onChange={this.handleInputChange}
                                        onFinishTyping={this.checkPassMatch}
                                        status={newPassRepeatMsg ? 'error' : 'normal'}
                                        block={true}
                                    />
                                </FormItem>
                                <ValidationMessage className={newPassRepeatMsg ? 'validation-message-visible' : ''}>
                                    {newPassRepeatMsg || '&nbsp;'}
                                </ValidationMessage>
                            </InputColumn>
                        </InputRow>
                    </div>
                    <PageActions>
                        <ActionButton onClick={this.onSave}>Save</ActionButton>
                    </PageActions>
                </SectionForm>
            </Section>
        )
    }
}

export default PasswordSection
