import { useField, useFormikContext } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import ActionButton from 'uiComponents/buttons'
import { BaseRouteParams } from 'hocs'
import { Language } from 'utils/languages'
import TranslationElement, { FieldProps } from './translationElement'
import { ArticleServiceContext } from 'admin/context'
import { replaceMessage } from 'uiComponents/messages/actions'

const AddTranslationButton = styled(ActionButton)`
    display: flex;
    align-items: center;
    margin-top: 0.8em;
    margin-bottom: 2em;
`

interface Props {
    fields: FieldProps[]
    fieldName: string
}

const MapTranslations = ({ fields, fieldName }: Props) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const articleService = React.useContext(ArticleServiceContext)
    const [locales, setLocales] = useState<Language[] | null>(null)
    const [localeVisibility, setLocaleVisibility] = useState<Array<boolean>>([])
    const [addButtonDisabled, setAddButtonDisabled] = useState<boolean>(false)
    const { setFieldValue } = useFormikContext()
    const dispatch = useDispatch()

    const [{ value }] = useField(fieldName)

    const getLocales = useCallback(async () => {
        try {
            const accountLocales = await articleService.getAccountLocales(accountSlug)
            const locales = accountLocales.locales.map((locale) => ({ name: locale.name, value: locale.code }))
            setLocales(locales)
        } catch {
            dispatch(
                replaceMessage('server_error', 'error', "Oops! We couldn't get translations. Please try again.", 5000),
            )
        }
    }, [accountSlug])

    const setInitialLocales = useCallback((initialVisibility) => {
        const timer = setTimeout(() => {
            const clone = [...initialVisibility]
            value?.forEach((content: any, index: number) => {
                clone[index] = !!content
            })
            setLocaleVisibility(clone)
        }, 0)

        return () => {
            clearTimeout(timer)
        }
    }, [])

    useEffect(() => {
        getLocales()
    }, [])

    useEffect(() => {
        if (locales) {
            const initialVisibility = [
                ...Array(locales.length)
                    .fill(false)
                    .map((_, index) => index === 0),
            ]

            setLocaleVisibility(initialVisibility)
            setInitialLocales(initialVisibility)
        }
    }, [locales])

    useEffect(() => {
        setAddButtonDisabled(localeVisibility.indexOf(false) < 0)
    }, [localeVisibility])

    const updateLocaleVisibility = useCallback(
        (index: number, value: boolean) => {
            const clone = [...localeVisibility]
            clone[index] = value
            setLocaleVisibility(clone)
        },
        [localeVisibility],
    )

    const onNewLocaleClicked = useCallback(() => {
        if (localeVisibility.indexOf(false) < 0) {
            return
        }

        updateLocaleVisibility(localeVisibility.indexOf(false), true)
    }, [localeVisibility])

    const onRemoveLocaleElement = useCallback(
        (index: number) => {
            updateLocaleVisibility(index, false)
            fields.forEach((field) => {
                setFieldValue(`${fieldName}[${index}].locale`, '')
            })
        },
        [localeVisibility],
    )

    return (
        <>
            {locales?.map((locale, index) => (
                <TranslationElement
                    index={index}
                    isVisible={localeVisibility[index]}
                    key={locale.value}
                    onRemoveLocaleElement={onRemoveLocaleElement}
                    localesList={locales}
                    selectedLocales={value}
                    fields={fields}
                    fieldName={fieldName}
                />
            ))}
            <AddTranslationButton secondary kind="action" onClick={onNewLocaleClicked} disabled={addButtonDisabled}>
                + Add translation
            </AddTranslationButton>
        </>
    )
}

export default MapTranslations
