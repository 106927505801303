import React from 'react'
import { DiscountCode } from 'venue/bookingCodes/bookingCodesService'

interface UsageCountProps {
    usage?: DiscountCode['usage']
}

const UsageCount: React.FC<UsageCountProps> = ({ usage }) => {
    if (!usage) {
        return <>Unlimited</>
    }

    return (
        <>
            {usage.used}/{usage.total}
        </>
    )
}

export default UsageCount
