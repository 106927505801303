import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import { ActionButton } from 'uiComponents/buttons'
import { ModalDialog, ModalDialogTitle, NavigationSection } from 'uiComponents/popups/modal'
import { ChartDataLoader } from 'uiComponents/loaders'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { TextInput } from 'uiComponents/input'

const ErrorMessage = styled(ValidationMessage)`
    font-size: 0.8em;
    top: 0em;
`

interface DialogProps {
    accountSlug: string
    showErrors: boolean
    loading: boolean
    onCancel: () => void
    onConfirm: (name: string) => void
}

export function AddRetailerDialog(props: DialogProps) {
    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<boolean>(false)

    useEffect(() => {
        if (props.showErrors) {
            setNameError(!name.trim())
        }
    }, [props.showErrors])

    const onSave = () => {
        if (!!name.trim()) {
            props.onConfirm(name.trim())
        } else {
            setNameError(!name.trim())
        }
    }

    const onFinishTypingName = (value: string) => {
        setNameError(!value.trim())
    }

    return (
        <ModalDialog onDismiss={props.onCancel} interactive fromTop="20%">
            {props.loading && <ChartDataLoader />}
            <div style={{ width: '25em' }}>
                <ModalDialogTitle>Add new retailer</ModalDialogTitle>
                <FormItem htmlFor="retailer-name" style={{ position: 'relative' }}>
                    <FormItemName>Name</FormItemName>
                    <TextInput
                        id="retailer-name"
                        type="text"
                        placeholder="Name"
                        maxLength={100}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onFinishTyping={onFinishTypingName}
                        status={nameError ? 'error' : 'normal'}
                        style={{ width: '100%' }}
                    />
                    <ErrorMessage className={nameError ? 'validation-message-visible' : ''}>
                        Please enter the name
                    </ErrorMessage>
                </FormItem>
                <NavigationSection>
                    <ActionButton size="large" secondary onClick={props.onCancel}>
                        Cancel
                    </ActionButton>
                    <ActionButton
                        id="add-new-retailer-button"
                        size="large"
                        style={{ marginLeft: '2em' }}
                        onClick={onSave}
                    >
                        Save
                    </ActionButton>
                </NavigationSection>
            </div>
        </ModalDialog>
    )
}
