import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { InlineEdit } from 'uiComponents/input'
import { BaseRouteParams } from 'hocs'
import { addMessage, removeMessage } from 'uiComponents/messages/actions'
import { DiscountPool } from 'venue/bookingCodes/bookingCodesService'
import { useEditDiscountPoolMutation } from 'venue/reduxQuery'
import { useParams } from 'react-router-dom'

interface InlineNameProps {
    discountPool: DiscountPool
}

function InlineName({ discountPool }: InlineNameProps) {
    const dispatch = useDispatch()
    const { accountSlug } = useParams<BaseRouteParams>()
    const [updateName] = useEditDiscountPoolMutation()
    const handleEditAccept = useCallback(
        async (name: string) => {
            const isNameValid = name.length
            const errorShowTime = 5000

            if (!isNameValid) {
                dispatch(addMessage('inline-name', 'error', 'Name cannot be empty.'))
            } else {
                try {
                    await updateName({ uuid: discountPool.uuid, name, accountSlug })
                } catch {
                    dispatch(
                        addMessage(
                            'inline-name',
                            'error',
                            `Oops! There was a problem with saving name ${name}. Please try again.`,
                        ),
                    )
                }
            }

            setTimeout(() => {
                dispatch(removeMessage('inline-name'))
            }, errorShowTime)
        },
        [discountPool.uuid],
    )

    return (
        <InlineEdit
            id="pool-name"
            responsiveWidth
            value={discountPool.name}
            onEditAccept={handleEditAccept}
            accountSlug={accountSlug}
        />
    )
}

export default InlineName
