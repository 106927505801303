import * as React from 'react'
import styled from 'styled-typed'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome'
import { faTrash } from '@fortawesome/pro-regular-svg-icons'

const Icon = styled(FontAwesomeIcon)`
    cursor: pointer;
    color: ${(props) => props.theme.colors.textLight};
    font-size: 1.5em;
    position: relative;

    &:hover {
        color: ${(props) => props.theme.colors.status.error};
    }
`

interface Props {
    style?: React.CSSProperties
    onDelete: () => void
}

export function StudioDeleteIcon(props: Props) {
    return <Icon icon={faTrash as IconProp} onClick={props.onDelete} title="Remove" style={props.style} />
}
