import React, { FC, useMemo } from 'react'
import TooltipWrapper from 'uiComponents/tooltip/tooltipWrapper'
import SingleSelectOption, { SingleSelectOptionProps } from 'uiComponents/input/singleSelect/singleSelectOption'
import { useGetPricingModels } from './pricingModels'

const PricingGoalSelectOption: FC<SingleSelectOptionProps> = (props) => {
    const { option, ...rest } = props
    const pricingGoalOptions = useGetPricingModels()

    const tooltip = useMemo<string | undefined>(
        () => pricingGoalOptions.find((pricingGoalOption) => pricingGoalOption.value === option.value)?.description,
        [option, pricingGoalOptions],
    )

    return (
        <TooltipWrapper delayShow={500} tooltipStyle={{ maxWidth: '23.25rem' }} text={tooltip}>
            <SingleSelectOption {...rest} option={option} />
        </TooltipWrapper>
    )
}

export default PricingGoalSelectOption
