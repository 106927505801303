import React from 'react'
import { Feature, FeatureProps, withFeatures } from 'features'

import { SingleSelectOption, SingleSelectFieldFormik, Checkbox, CheckboxFieldFormik } from 'uiComponents/input'
import TextInputFieldFormik from 'uiComponents/input/textInput/textInputFieldFormik'
import FieldSpacingWrapper from 'uiComponents/input/_shared/fieldSpacingWrapper'
import { useFocusInputOnMounting } from 'uiComponents/input/_shared/hooks'
import { Text } from 'uiComponents/typography'

interface ReservationPoolFormProps extends FeatureProps {
    retailerOptions: SingleSelectOption[]
    view?: 'create' | 'edit'
    accountSlug: string
}

const ReservationPoolForm: React.FC<ReservationPoolFormProps> = ({
    retailerOptions,
    view,
    accountSlug,
    hasFeature,
}) => {
    const shouldShowScannable = hasFeature('ScannableReservationCodesFeature', accountSlug)

    useFocusInputOnMounting('name')

    return (
        <>
            <FieldSpacingWrapper>
                <TextInputFieldFormik
                    id="res-codes-pool-name"
                    label="Name"
                    block
                    name="name"
                    placeholder="Campaign name"
                />
            </FieldSpacingWrapper>
            <FieldSpacingWrapper style={{ marginBottom: shouldShowScannable ? 'inherit' : '3rem' }}>
                <SingleSelectFieldFormik
                    label="Retailer"
                    id="res-codes-pool-retailer"
                    height="2.5rem"
                    maxHeight="8em"
                    name="externalChannel"
                    disabled={view === 'edit'}
                    options={retailerOptions}
                    noSelectOption="Select retailer"
                />
            </FieldSpacingWrapper>
            <Feature name="ScannableReservationCodesFeature" accountSlug={accountSlug}>
                <FieldSpacingWrapper>
                    <CheckboxFieldFormik name="isScannable">
                        {(props) => {
                            return (
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingBottom: 8,
                                        }}
                                    >
                                        <label htmlFor={props.id} style={{ fontSize: 14, marginRight: '.625em' }}>
                                            Scannable codes
                                        </label>
                                        <Checkbox disabled={view === 'edit'} {...props} />
                                    </div>
                                    <div>
                                        <Text status="info" style={{ fontSize: 12, color: '#97A5B4' }}>
                                            This function can not be applied to unlimited codes batch.
                                        </Text>
                                    </div>
                                </div>
                            )
                        }}
                    </CheckboxFieldFormik>
                </FieldSpacingWrapper>
            </Feature>
        </>
    )
}

export default withFeatures(ReservationPoolForm)
