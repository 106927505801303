import React, { useContext, useMemo } from 'react'
import classNames from 'classnames'
import styled from 'styled-typed'
import { max } from 'lodash'

import { LoaderWrapper } from 'uiComponents/loaders'
import { CONTAINER_BOX_SHADOW } from 'uiComponents/settingsContainer'
import { DataRow, HeaderRow, ReactTableDataTableComponent, TableHeader, TableHeaderProps } from 'uiComponents/table'
import { ReactTableCell } from './ui'
import { NoResultsRow } from '../noResultsRow'
import { ReactHeaderGroup, ReactTableRows } from './interface'
import RenderRow from './renderRow'
import ReactTablePagination from './pagination'
import ReactTableContext from './reactTableContext'

interface ReactTableComponentProps {
    id?: string
}

const TableContainer = styled.div`
    position: relative;
    box-shadow: ${CONTAINER_BOX_SHADOW};
    border-radius: 0.7em;
`

const ReactTableComponent: React.FC<ReactTableComponentProps> = ({ id }) => {
    const {
        instance,
        sticky,
        pagination,
        loading,
        itemsTotalCount,
        elevation = true,
        displayFooter,
        noResultsRow,
        tableState,
        tableProps,
        bodyProps,
        size,
        cellProps,
        expanded,
        rowContainerProps,
        rowProps,
        backgroundColor,
        bordered,
        headerProps,
        innerTable,
    } = useContext(ReactTableContext)
    const { getTableProps, getTableBodyProps, headerGroups, rows, page, prepareRow, footerGroups } = instance

    const renderNoData = () => {
        if (!noResultsRow) {
            return null
        }

        const noResultType = typeof noResultsRow
        let noResultProps = {}

        if (noResultType === 'function') {
            // @ts-ignore
            return noResultsRow({ tableInstance: instance })
        }

        if (noResultType === 'object') {
            noResultProps = noResultsRow
        }

        return <NoResultsRow style={{ boxShadow: 'unset' }} {...noResultProps} />
    }
    const memorizedId = useMemo(() => id || `table-${Math.random()}`, [id])

    return (
        <TableContainer
            {...tableProps}
            className={classNames(
                {
                    react__table: true,
                    'react__table--stripped': true,
                    'react__table--without_elevation': !elevation,
                },
                tableProps?.className,
            )}
        >
            <div style={{ position: 'relative' }}>
                <LoaderWrapper loading={loading}>
                    <ReactTableDataTableComponent
                        id={memorizedId}
                        className={classNames({
                            table: true,
                            sticky: sticky,
                        })}
                        columnWidths={instance.columns
                            .filter((column) => column.isVisible)
                            .map((column) => column.width as string)}
                        {...getTableProps()}
                        backgroundColor={backgroundColor}
                        bordered={bordered}
                    >
                        <div
                            className={classNames({
                                header: true,
                                'inner-table': innerTable,
                            })}
                        >
                            {headerGroups.map((headerGroup, index) => (
                                <HeaderRow
                                    {...headerGroup.getHeaderGroupProps()}
                                    className="tr"
                                    key={`${headerGroup.headers.toString()}-${index}`}
                                >
                                    {headerGroup.headers.map((column: ReactHeaderGroup) => {
                                        const sortOptions = {} as TableHeaderProps

                                        if (!!column.canSort) {
                                            sortOptions.sortKey = column.id
                                            sortOptions.sorting = {
                                                direction: column.isSortedDesc ? 'desc' : 'asc',
                                                prop: column.isSorted ? column.id : 'default',
                                            }
                                            sortOptions.changeSort = ({ direction }) => {
                                                column.toggleSortBy(direction === 'desc')
                                            }
                                        }

                                        return (
                                            // eslint-disable-next-line react/jsx-key
                                            <TableHeader
                                                nonInteractive={!column.canSort}
                                                className="th"
                                                width={column.width}
                                                {...sortOptions}
                                                {...column.getHeaderProps()}
                                                headerProps={headerProps}
                                            >
                                                {column.render('Header')}
                                            </TableHeader>
                                        )
                                    })}
                                </HeaderRow>
                            ))}
                        </div>
                        <div {...bodyProps}>
                            {!!loading && !rows.length && <div style={{ height: 120 }} />}
                            <div {...getTableBodyProps()} className="body">
                                {!rows.length && !loading && !!noResultsRow
                                    ? renderNoData()
                                    : (page || rows).map((row) => {
                                          prepareRow(row)

                                          return (
                                              // eslint-disable-next-line react/jsx-key
                                              <RenderRow
                                                  rowProps={rowProps}
                                                  cellProps={cellProps}
                                                  {...row.getRowProps()}
                                                  expanded={expanded}
                                                  rowContainerProps={rowContainerProps}
                                                  size={size}
                                                  row={row as ReactTableRows}
                                                  instance={instance}
                                                  tableState={tableState}
                                              />
                                          )
                                      })}
                            </div>
                            {!!displayFooter && (
                                <div className="tfooter">
                                    {footerGroups.map((group) => {
                                        const groupHeaders = { 1: [] } as any
                                        const totalFooterRows = max(
                                            group.headers.map((column: any) => column.Footer?.length || 1),
                                        )

                                        group.headers.map((column) => {
                                            if (totalFooterRows === 1) {
                                                const getFooter = () => {
                                                    if (Array.isArray(column.Footer)) {
                                                        return column.Footer[0]
                                                    }

                                                    return 'Footer'
                                                }

                                                return groupHeaders[1].push(
                                                    <ReactTableCell width={column.width} {...column.getFooterProps()}>
                                                        {column.render(getFooter() as any)}
                                                    </ReactTableCell>,
                                                )
                                            }

                                            for (let i = 0; i < totalFooterRows; i++) {
                                                const index = i + 1
                                                const footer = column.Footer?.[i]
                                                if (!groupHeaders[index]) {
                                                    groupHeaders[index] = []
                                                }
                                                const { key, ...footerRowProps } = column.getFooterProps()

                                                groupHeaders[index].push(
                                                    <ReactTableCell
                                                        {...footerRowProps}
                                                        key={`${key}_${index}`}
                                                        align={column.align || 'left'}
                                                        width={column.width}
                                                    >
                                                        {!!footer && column.render(footer)}
                                                    </ReactTableCell>,
                                                )
                                            }
                                        })

                                        return Object.keys(groupHeaders).map((key, index) => (
                                            <DataRow
                                                narrow={size === 'small'}
                                                {...group.getFooterGroupProps()}
                                                key={index}
                                            >
                                                {groupHeaders[key]}
                                            </DataRow>
                                        ))
                                    })}
                                </div>
                            )}
                        </div>
                    </ReactTableDataTableComponent>
                    <ReactTablePagination
                        instance={instance}
                        itemsTotalCount={itemsTotalCount}
                        pagination={pagination}
                    />
                </LoaderWrapper>
            </div>
        </TableContainer>
    )
}

export default ReactTableComponent
