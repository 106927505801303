import React from 'react'
import { GoogleMap, GroundOverlay } from '@react-google-maps/api'
import styled, { useTheme } from 'styled-components'
import Svg from 'svg'

import { LoaderWrapper } from 'uiComponents/loaders'
import { useParkMapViewerContext } from '../../context/mapViewer'
import useParkMap from '../../hooks/useParkMap'
import conviousLogo from 'images/convious-logo.svg'
import { useSelectedParkMapContext } from 'venue/parkMap/context/selectedParkMap'
import InformationBox from '../informationBox'
import CustomMarkers from '../mapViewer/customMarkers'
import { jsonStyleBgColor } from 'venue/parkMap/helpers'

const Container = styled.div`
    height: 100%;
    display: flex;
    overflow: hidden;
`

const Left = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    padding: 2em 0;
`

const Right = styled.div`
    width: 70%;
`

const SearchInput = styled.input.attrs({
    type: 'text',
    placeholder: 'Search',
})`
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 100%;
    outline: none;
    text-overflow: ellipsis;
    background: #f5f7fa;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    height: 2.2rem;
    border-radius: 0.375em;
    padding: 0.625em 1em;
    width: 90%;
    margin: 0 auto;
`

const Footer = styled.div`
    text-align: center;
    opacity: 0.2;
`

const FooterText = styled.div`
    font-size: 0.8rem;
    padding: 0.3em 0;
`

const Logo = styled(Svg)`
    width: 25%;
`

const Info = styled.div`
    position: absolute;
    top: 40%;
    left: 4%;
    z-index: 100;
    max-width: 280px;
`

const WebPreview = () => {
    const theme = useTheme()
    const { isLoaded, coords, zoom, map, setBounds } = useParkMapViewerContext()
    const { selectedParkMap } = useSelectedParkMapContext()
    const { getBounds } = useParkMap()
    const webPreviewZoom = zoom + 0.5

    return (
        <Container>
            <Left>
                <SearchInput />
                <Info>
                    <InformationBox
                        text="This is a preview of the website version of the park map. Please reach out to your customer success manager if you also want to display the interactive park map on your website."
                        background={theme.colors.white}
                    />
                </Info>
                <Footer>
                    <FooterText>powered by</FooterText>
                    <Logo src={conviousLogo} />
                </Footer>
            </Left>
            <Right>
                <LoaderWrapper loading={!isLoaded}>
                    {isLoaded ? (
                        <GoogleMap
                            mapContainerStyle={{
                                height: '100%',
                                width: '100%',
                                minHeight: map?.getDiv().offsetWidth ?? 0,
                            }}
                            center={coords}
                            zoom={webPreviewZoom}
                            onIdle={() => {
                                const bounds = getBounds()
                                if (bounds) {
                                    setBounds(bounds)
                                }
                            }}
                            options={{
                                disableDefaultUI: true,
                                gestureHandling: 'none',
                                isFractionalZoomEnabled: false,
                                panControl: false,
                                zoomControl: false,
                                styles: selectedParkMap?.overlay?.bgColor
                                    ? jsonStyleBgColor(selectedParkMap?.overlay?.bgColor)
                                    : [],
                            }}
                        >
                            {selectedParkMap?.overlay && selectedParkMap.overlay.image && (
                                <GroundOverlay
                                    url={selectedParkMap.overlay.image}
                                    bounds={
                                        new google.maps.LatLngBounds(
                                            {
                                                lat: selectedParkMap.overlay.southEast.lat,
                                                lng: selectedParkMap.overlay.northWest.lng,
                                            },
                                            {
                                                lat: selectedParkMap.overlay.northWest.lat,
                                                lng: selectedParkMap.overlay.southEast.lng,
                                            },
                                        )
                                    }
                                />
                            )}
                            <CustomMarkers />
                        </GoogleMap>
                    ) : (
                        <></>
                    )}
                </LoaderWrapper>
            </Right>
        </Container>
    )
}

export default WebPreview
