import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AppServices } from 'middleware'
import { replaceMessage } from 'uiComponents/messages/actions'
import { PNApp, PNCampaign } from './types'

export const pushNotificationsApi = createApi({
    reducerPath: 'notificationsApi',
    tagTypes: ['campaigns', 'campaign'],
    baseQuery: fetchBaseQuery({}),
    endpoints: (builder) => ({}),
})

const extendedNotificationsApi = pushNotificationsApi.injectEndpoints({
    endpoints: (builder) => ({
        createApp: builder.mutation({
            queryFn: async ({ app }: { app: PNApp }, api) => {
                try {
                    const data = await AppServices.angeliaNotificationsService.createApp(app)
                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('project', 'error', 'Failed to create app', 5000))

                    return { error }
                }
            },
        }),
        getApp: builder.query({
            queryFn: async ({ slug }: { slug: string }, api) => {
                try {
                    const data = await AppServices.angeliaNotificationsService.getApp(slug)

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('campaigns', 'error', 'Failed to get notifications app', 5000))

                    return { error }
                }
            },
        }),
        deleteApp: builder.mutation({
            queryFn: async ({ slug }: { slug: string }, api) => {
                try {
                    const data = await AppServices.angeliaNotificationsService.deleteApp(slug)

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('project', 'error', 'Failed to delete app', 5000))

                    return { error }
                }
            },
        }),
        listCampaigns: builder.query({
            providesTags: ['campaigns'],
            queryFn: async ({ slug }: { slug: string }, api) => {
                try {
                    const data = await AppServices.angeliaNotificationsService.getCampaignsList(slug)

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('campaigns', 'error', 'Failed to get notifications campaigns', 5000))

                    return { error }
                }
            },
        }),
        getCampaign: builder.query({
            providesTags: ['campaign'],
            queryFn: async (
                {
                    slug,
                    campaignId,
                }: {
                    slug: string
                    campaignId: string
                },
                api,
            ) => {
                try {
                    const data = await AppServices.angeliaNotificationsService.getCampaign(slug, campaignId)

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('campaigns', 'error', 'Failed to get notifications campaigns', 5000))

                    return { error }
                }
            },
        }),
        createCampaign: builder.mutation({
            queryFn: async ({ campaign }: { campaign: PNCampaign }, api) => {
                try {
                    const data = await AppServices.angeliaNotificationsService.createCampaign(campaign)
                    api.dispatch(
                        extendedNotificationsApi.util.updateQueryData(
                            'listCampaigns',
                            { slug: campaign.slug },
                            (draft) => {
                                draft?.push(data)
                                return draft
                            },
                        ),
                    )
                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('campaigns', 'error', 'Failed to create campaign', 5000))

                    return { error }
                }
            },
        }),
        updateCampaign: builder.mutation({
            invalidatesTags: ['campaigns'],
            queryFn: async ({ campaign }: { campaign: PNCampaign }, api) => {
                try {
                    const data = await AppServices.angeliaNotificationsService.updateCampaign(campaign)
                    api.dispatch(
                        extendedNotificationsApi.util.updateQueryData(
                            'listCampaigns',
                            { slug: campaign.slug },
                            (draft) => {
                                return draft?.map((map: PNCampaign) => {
                                    if (map.id === campaign.id) {
                                        return data
                                    }
                                    return map
                                })
                            },
                        ),
                    )

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('campaigns', 'error', 'Failed to update campaign', 5000))

                    return { error }
                }
            },
        }),
        deleteCampaign: builder.mutation({
            queryFn: async (
                {
                    slug,
                    campaignId,
                }: {
                    slug: string
                    campaignId: string
                },
                api,
            ) => {
                try {
                    const data = await AppServices.angeliaNotificationsService.deleteCampaign(slug, campaignId)
                    api.dispatch(
                        extendedNotificationsApi.util.updateQueryData('listCampaigns', { slug: slug }, (draft) => {
                            return draft?.filter((item: PNCampaign) => item.id !== campaignId)
                        }),
                    )

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('parkMap', 'error', 'Failed to delete notification campaign', 5000))

                    return { error }
                }
            },
        }),
    }),
})

export const { reducer, reducerPath } = pushNotificationsApi
export const {
    useCreateAppMutation,
    useGetAppQuery,
    useDeleteAppMutation,
    useLazyListCampaignsQuery,
    useGetCampaignQuery,
    useCreateCampaignMutation,
    useUpdateCampaignMutation,
    useDeleteCampaignMutation,
    endpoints,
} = extendedNotificationsApi
