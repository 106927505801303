import React from 'react'
import styled from 'styled-typed'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { OptionsList, OptionBox, OptionCheckBox } from 'uiComponents/menus/'
import {
    SelectElement,
    PopupArea,
    MinimizedView,
    IconContainer,
    Icon,
    SelectedOptionDisplay,
} from 'uiComponents/input/singleSelect'
import isEqual from 'lodash/isEqual'
import { MultiSelectProps } from './interface'

export const OptionsArea = styled(PopupArea)`
    background: ${(props) => props.theme.colors.white};
    top: auto;

    &.visible {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    }
`

interface MultiSelectState {
    expanded: boolean
    selected: string[]
}

export class MultiSelect extends React.Component<MultiSelectProps, MultiSelectState> {
    popup: HTMLDivElement | null = null
    icon: HTMLDivElement | null = null

    constructor(props: MultiSelectProps) {
        super(props)
        this.state = {
            expanded: false,
            selected: this.props.selected,
        }
    }

    setPopupRef = (node: HTMLDivElement) => {
        this.popup = node
    }

    setIconRef = (node: HTMLDivElement) => {
        this.icon = node
    }

    componentDidMount() {
        document.addEventListener('click', this.outsideClick, false)
    }

    componentDidUpdate(prevProps: MultiSelectProps) {
        if (!isEqual([...prevProps.selected].sort(), [...this.props.selected].sort())) {
            this.setState({ selected: this.props.selected })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.outsideClick, false)
    }

    outsideClick = (ev: MouseEvent) => {
        if (this.popup && this.popup.contains && !this.popup.contains(ev.target as Node) && this.state.expanded) {
            this.setState({ expanded: false, selected: this.props.selected })
        }
    }

    onFocus = (e: React.FocusEvent | React.MouseEvent) => {
        if (this.state.expanded && this.icon && this.icon.contains(e.target as Node)) {
            return
        }
        if (!this.state.expanded && !this.props.disabled) {
            this.setState({ expanded: true })
        }
    }

    onBlur = (e: React.FocusEvent | React.MouseEvent) => {
        if (this.state.expanded && this.icon && this.icon.contains(e.target as Node)) {
            this.setState({ expanded: false, selected: this.props.selected })
        }
    }

    onCheckBoxClick = (value: string) => {
        if (this.props.viewOnly) {
            return
        }
        const existing = this.state.selected.indexOf(value) > -1
        const updatedList = existing
            ? [...this.state.selected].filter((s) => s !== value)
            : [...this.state.selected, value]
        this.setState({ selected: updatedList })
        this.props.onSelect(updatedList)
    }

    render() {
        const {
            id,
            options,
            noSelectOption,
            width,
            height,
            lineHeight,
            disabled,
            style,
            status,
            widthWhenOpen,
            border,
        } = this.props
        const { expanded } = this.state
        const selectedName = this.props.selected.length > 0 ? `${this.props.selected.length} selected` : null

        return (
            <SelectElement
                id={id}
                ref={this.setPopupRef}
                width={width}
                widthWhenOpen={widthWhenOpen}
                style={style}
                onClick={this.onFocus}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                lineHeight={lineHeight}
                className={expanded ? 'open' : ''}
            >
                <select
                    hidden
                    role="textbox"
                    defaultValue={this.state.selected?.join(',') as string}
                    name={this.props.name}
                />
                <MinimizedView
                    height={height}
                    disabled={disabled}
                    tabIndex={0}
                    status={status ? status : 'normal'}
                    border={border}
                >
                    <SelectedOptionDisplay>{selectedName ? selectedName : noSelectOption}</SelectedOptionDisplay>
                    <IconContainer>
                        <Icon icon={faChevronDown} className={expanded ? '' : 'visible'} />
                        <div onClick={this.onBlur} ref={this.setIconRef}>
                            <Icon icon={faChevronUp} className={expanded ? 'visible' : ''} />
                        </div>
                    </IconContainer>
                </MinimizedView>
                <OptionsArea className={expanded ? 'visible' : ''} border={border}>
                    <OptionsList className="options-list" maxHeight={this.props.maxHeight}>
                        {options.map((o, i) => (
                            <OptionBox id={o.value + i} key={o.value + i} onClick={() => this.onCheckBoxClick(o.value)}>
                                <OptionCheckBox
                                    name={o.value}
                                    checked={this.state.selected.indexOf(o.value) > -1}
                                    onChange={() => {}}
                                />
                                <span>{o.name}</span>
                            </OptionBox>
                        ))}
                    </OptionsList>
                </OptionsArea>
            </SelectElement>
        )
    }
}
