import * as React from 'react'
import styled, { keyframes } from 'styled-typed'

const flash = keyframes`
  0% { opacity: 0; }
  10% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
`

const MessageElement = styled.div`
    margin: 0.8em 0.5em;
    color: ${(props) => props.theme.colors.status.error};
    &.flash {
        opacity: 0;
        animation: ${flash} linear 3s 1;
    }
`

interface ErrorMessageProps {
    errType?: string
    errText?: string
    flash?: boolean
    style?: React.CSSProperties
}

export const ErrorMessage = (props: ErrorMessageProps) => {
    function getErrorText() {
        switch (props.errType) {
            case 'minOne':
                return 'You have to select at least one option'
            case 'url':
                return 'Please enter a valid URL'
            case 'notEmpty':
                return 'Please enter something'
            case 'partOfUrl':
                return 'Empty spaces or special characters are not allowed'
            case 'outOfRange':
                return 'The number is out of range'
            default:
                return 'select a valid value'
        }
    }
    return (
        <MessageElement className={props.flash ? 'flash' : ''} style={props.style}>
            {props.errText || getErrorText()}
        </MessageElement>
    )
}
