import React, { FC } from 'react'
import { Alert, AlertTitle } from '@mui/material'
import { RtpCalendar } from '@convious/rtp-calendar'
import { ChartDataLoader } from 'uiComponents/loaders'
import { Price } from 'products/utils'
import './calendarBlock.scss'
import { getIsUSPartner } from 'auth/selectors'
import { useAppSelector } from 'store/hooks'

interface CalendarBlockProps {
    calendarData: Price[] | null
    loading: boolean
    now: Date
    onMonthChange: (date: Date) => void
}

const CalendarBlock: FC<CalendarBlockProps> = ({ calendarData, loading, now, onMonthChange }) => {
    const isUSPartner = useAppSelector(getIsUSPartner)

    return (
        <div className="product-calendarBlock">
            {calendarData ? (
                <>
                    <div>
                        {loading && (
                            <div className="product-calendarBlock-loaderContainer">
                                <ChartDataLoader />
                            </div>
                        )}
                        <RtpCalendar
                            locale="en"
                            prices={calendarData}
                            fromMonth={now}
                            onMonthChange={onMonthChange}
                            modifiers={{
                                before: now,
                                past: { before: now },
                            }}
                            firstDayOfWeek={isUSPartner ? 0 : 1}
                        />
                    </div>

                    <Alert severity="info">
                        <AlertTitle>Calendar preview</AlertTitle>
                        The calendar above calculates prices in real-time by using your configured pricing settings and
                        pricing exceptions, product validity, and validity exceptions.
                    </Alert>
                </>
            ) : (
                <>
                    {loading && (
                        <div>
                            <div className="product-calendarBlock-loaderContainer">
                                <ChartDataLoader topOffset="2em" />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default CalendarBlock
