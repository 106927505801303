import React from 'react'
import styled, { css } from 'styled-typed'
import { TextInput, TextInputProps } from './textInput'
import { IconProp } from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const blockCss = css`
    display: block;
    width: 100%;
`

const IconTextInputField = styled(TextInput)`
    margin-bottom: 0;

    &.with-left-icon {
        padding-left: 2.625em;
    }

    &.with-right-icon {
        padding-right: 2.625em;
    }
`

const IconTextInputContainer = styled.div<{
    block?: boolean
    textColorIcons?: boolean
}>`
    position: relative;
    ${(props) => (props.block ? blockCss : 'display: inline-block;')}

    & .text-input-icon-left,
  & .text-input-icon-right {
        position: absolute;
        top: 50%;
        font-size: 1em;
        line-height: 100%;
        margin-top: -0.5em;
        transition: color 0.2s ease-in;
        color: ${(props) => (props.textColorIcons ? props.theme.colors.textDark : props.theme.colors.boyBlue)};
    }

    & .text-input-icon-left {
        left: 1em;
    }

    & .text-input-icon-right {
        right: 1em;
    }
`

interface IconTextInputProps extends TextInputProps {
    iconLeft?: IconProp
    iconRight?: IconProp
    textColorIcons?: boolean
}

export function IconTextInput({
    iconLeft,
    iconRight,
    textColorIcons,
    block,
    className,
    ...rest
}: IconTextInputProps & React.InputHTMLAttributes<HTMLInputElement>) {
    const classNames = []
    if (className) {
        classNames.push(className)
    }

    if (iconLeft) {
        classNames.push('with-left-icon')
    }

    if (iconRight) {
        classNames.push('with-right-icon')
    }

    return (
        <IconTextInputContainer block={block} textColorIcons={textColorIcons}>
            <IconTextInputField {...rest} className={classNames.join(' ')} block />
            {iconLeft && <FontAwesomeIcon icon={iconLeft} className="text-input-icon-left" />}
            {iconRight && <FontAwesomeIcon icon={iconRight} className="text-input-icon-right" />}
        </IconTextInputContainer>
    )
}
