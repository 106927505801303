import React from 'react'
import { GoogleMap, GroundOverlay } from '@react-google-maps/api'
import styled from 'styled-components'

import { LoaderWrapper } from 'uiComponents/loaders'
import PhoneFrameSvg from '../../assets/phone-frame.svg'
import { useParkMapViewerContext } from '../../context/mapViewer'
import InformationSvg from '../../assets/information.svg'
import DimImage from '../../assets/dim.png'
import { useSelectedParkMapContext } from 'venue/parkMap/context/selectedParkMap'
import CustomMarkers from '../mapViewer/customMarkers'
import { jsonStyleBgColor } from 'venue/parkMap/helpers'
import { PHONE } from './consts'

const Container = styled.div`
    height: 100%;
    display: flex;
`

const PhoneFrame = styled(PhoneFrameSvg)`
    position: absolute;
    height: 90%;
    z-index: 3;
    left: 50%;
    transform: translate(-50%, 0);
    top: 5%;
`

const InfoBox = styled.div`
    position: absolute;
    background: ${(props) => props.theme.colors.white};
    padding: 0.6em;
    border-radius: 4px;
    font-size: 0.8rem;
    top: 50%;
    right: 15%;
    display: flex;
    justify-content: center;
    z-index: 2;
`

const InformationIcon = styled(InformationSvg)`
    width: 12px;
    fill: ${(props) => props.theme.colors.textDark};
    margin-right: 0.4em;
`

const DimA = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-repeat: no-repeat;
    background-image: url(${DimImage});
    background-position: center;
    background-size: cover;
    background-position: center;
`

const PhonePreview = () => {
    const { isLoaded, coords, zoom } = useParkMapViewerContext()
    const { selectedParkMap } = useSelectedParkMapContext()
    const phonePreviewZoom = zoom - 1

    return (
        <Container>
            <PhoneFrame />
            <InfoBox>
                <InformationIcon />
                This is just demo version to reference
            </InfoBox>
            <DimA />
            <LoaderWrapper loading={!isLoaded}>
                {isLoaded ? (
                    <GoogleMap
                        mapContainerStyle={{ height: '100%', width: '100%' }}
                        center={coords}
                        zoom={phonePreviewZoom}
                        options={{
                            disableDefaultUI: true,
                            gestureHandling: 'none',
                            isFractionalZoomEnabled: false,
                            panControl: false,
                            zoomControl: false,
                            styles: selectedParkMap?.overlay?.bgColor
                                ? jsonStyleBgColor(selectedParkMap?.overlay?.bgColor)
                                : [],
                        }}
                    >
                        {selectedParkMap?.overlay && selectedParkMap.overlay.image && (
                            <GroundOverlay
                                url={selectedParkMap.overlay.image}
                                bounds={
                                    new google.maps.LatLngBounds(
                                        {
                                            lat: selectedParkMap.overlay.southEast.lat,
                                            lng: selectedParkMap.overlay.northWest.lng,
                                        },
                                        {
                                            lat: selectedParkMap.overlay.northWest.lat,
                                            lng: selectedParkMap.overlay.southEast.lng,
                                        },
                                    )
                                }
                            />
                        )}
                        <CustomMarkers previewType={PHONE} />
                    </GoogleMap>
                ) : (
                    <></>
                )}
            </LoaderWrapper>
        </Container>
    )
}

export default PhonePreview
