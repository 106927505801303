import * as React from 'react'
import { useAccountChange } from 'reactUtils'
import Filter from 'uiComponents/filter'
import { MetaData, FilterCategory } from 'uiComponents/filter/schema'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { withFeatures } from 'features'

interface FiltersProps {
    navigation: Navigation
    match: RouteMatch<any>
    accountSlug: string
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function Filters(props: FiltersProps) {
    const [metaData, setMetaData] = React.useState<MetaData[]>([])

    React.useEffect(() => {
        getMetaData()
    }, [])

    useAccountChange(props.accountSlug, () => props.navigation.addQueryWithReplace({ filter: null }))

    const allRoles = [
        { name: 'Admin', slug: 'admin' },
        { name: 'Reseller', slug: 'reseller' },
        { name: 'Regular user', slug: 'user' },
    ]

    const getMetaData = () => {
        const roles = props.hasFeature('ResellersFeature', props.accountSlug)
            ? allRoles
            : allRoles.filter((r) => r.slug !== 'reseller')
        const data = [
            {
                category: 'user_role' as FilterCategory,
                options: roles.map((role) => {
                    return {
                        name: role.name,
                        slug: role.slug,
                        category: 'user_role' as FilterCategory,
                        children: [],
                        parents: [],
                        leafNode: true,
                    }
                }),
            },
        ]
        setMetaData(data)
    }

    return (
        <Filter
            accountSlug={props.accountSlug}
            metaData={metaData}
            applicableFilters={['user_role']}
            standaloneFilters={['user_role']}
            singleSelectCategories={[]}
        />
    )
}

export default withFeatures(withNavigation(Filters))
