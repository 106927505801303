import * as React from 'react'
import styled from 'styled-typed'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { ModalDialogTitle } from 'uiComponents/popups/modal'
import { ActionButton } from 'uiComponents/buttons'
import { SingleSelect, SingleSelectOption } from 'uiComponents/input/singleSelect'
import { Card } from 'uiComponents/cards'
import { TextArea, TextInputProps, TextInput } from 'uiComponents/input'
import Svg from 'svg'
import { withUserAndProfile } from 'hocs'
import { User } from 'auth/state'
import { Profile } from 'settings/schema'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`
const ContainerCard = styled(Card)`
    flex: 1;
    max-width: 45em;
    padding: 2em 3em 2.8em 3em;
`
const Form = styled.form`
    width: 35em;
    padding: 0 5em;
`
const FormTextArea = styled(TextArea)<TextInputProps>`
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    font-weight: 400;
`
export const Icon = styled(Svg)`
    width: 3.5em;
    margin-bottom: 1.5em;
`
const SuccessMessage = styled.div`
    text-align: center;
    font-size: 0.875em;
    max-width: 28.6em;
    min-width: 35em;
`

interface RequestFormProps {
    accountSlug: string
    profile: Profile
    user: User
    iconSrc: SVGAElement
    title: string
    categories?: SingleSelectOption[]
    successMessage: string
    requirementsDescription: string
    returnButtonText: string
    onDismiss?: () => void
    onRequestSubmit: (request: string, account: string, category?: string) => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

interface RequestFormState {
    request: string
    category: string
    categoryMsg: string
    requestMsg: string
    sending: boolean
    sent: boolean
}

class RequestContent extends React.Component<RequestFormProps, RequestFormState> {
    constructor(props: RequestFormProps) {
        super(props)
        this.state = {
            request: '',
            category: '',
            categoryMsg: '',
            requestMsg: '',
            sending: false,
            sent: false,
        }
    }

    onSubmit = async (evt: React.FormEvent<HTMLElement>) => {
        evt.preventDefault()
        this.props.removeAllMessages()
        if (!this.checkValidForm()) {
            return
        }
        this.setState({ sending: true })
        try {
            const account =
                this.props.user.accounts.find((x) => x.slug === this.props.accountSlug) || this.props.user.accounts[0]
            await this.props.onRequestSubmit(this.state.request, account.name, this.state.category)
            this.setState({ sending: false, sent: true })
        } catch {
            this.setState({ sending: false })
            this.props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! We could not send your request. Please try again.',
            )
        }
    }

    checkValidForm = () => {
        let valid = true
        if (this.props.categories && !this.state.category) {
            this.setState({ categoryMsg: 'Please select a category' })
            valid = false
        }
        if (!this.state.request) {
            this.setState({ requestMsg: 'Please provide request requirements' })
            valid = false
        }
        return valid
    }

    handleSelectChange = (value: string) => {
        this.setState({ category: value, categoryMsg: '' })
    }

    handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ request: event.target.value, requestMsg: '' })
    }

    render() {
        const { request, category, categoryMsg, requestMsg, sending, sent } = this.state
        const { user, profile, iconSrc, title, categories, successMessage, returnButtonText, requirementsDescription } =
            this.props
        const name = profile && profile.firstName ? `${profile.firstName} ${profile.lastName}` : user.username

        return (
            <Container>
                {sending && <ChartDataLoader />}
                <Icon src={iconSrc} />
                <ModalDialogTitle>{sent ? 'Success' : title}</ModalDialogTitle>
                {!sent && (
                    <Form noValidate onSubmit={this.onSubmit}>
                        <FormItem htmlFor="name" style={{ marginBottom: '2em' }}>
                            <FormItemName>Name</FormItemName>
                            <TextInput id="name" name="name" type="text" value={name} block disabled />
                        </FormItem>
                        {categories && (
                            <>
                                <FormItem htmlFor="category">
                                    <FormItemName>Category</FormItemName>
                                    <SingleSelect
                                        id="category"
                                        options={categories}
                                        selected={category}
                                        noSelectOption="Select a category"
                                        height="2.8em"
                                        onSelect={this.handleSelectChange}
                                        style={{ marginBottom: '.2em' }}
                                        status={categoryMsg ? 'error' : 'normal'}
                                    />
                                </FormItem>
                                <ValidationMessage className={categoryMsg ? 'validation-message-visible' : ''}>
                                    {categoryMsg || '&nbsp;'}
                                </ValidationMessage>
                            </>
                        )}
                        <FormItem htmlFor="request">
                            <FormItemName>{requirementsDescription}</FormItemName>
                            <FormTextArea
                                id="request"
                                name="request"
                                value={request ? request : ''}
                                onChange={this.handleTextAreaChange}
                                status={requestMsg ? 'error' : 'normal'}
                            />
                        </FormItem>
                        <ValidationMessage className={requestMsg ? 'validation-message-visible' : ''}>
                            {requestMsg || '&nbsp;'}
                        </ValidationMessage>
                        <div style={{ textAlign: 'center' }}>
                            <ActionButton size="large" onClick={this.onSubmit} style={{ marginTop: '1.5em' }}>
                                Send request
                            </ActionButton>
                        </div>
                    </Form>
                )}
                {sent && (
                    <SuccessMessage>
                        <div>{successMessage}</div>
                        {this.props.onDismiss && (
                            <ActionButton onClick={this.props.onDismiss} style={{ marginTop: '3em' }}>
                                {returnButtonText}
                            </ActionButton>
                        )}
                    </SuccessMessage>
                )}
            </Container>
        )
    }
}

export const RequestForm = withUserAndProfile(RequestContent)

interface RequestCardProps {
    children: React.ReactNode
    userpilot?: string
    style?: React.CSSProperties
}

export function RequestCard(props: RequestCardProps) {
    return (
        <Container style={props.style}>
            <ContainerCard data-userpilot={props.userpilot}>{props.children}</ContainerCard>
        </Container>
    )
}
