import * as React from 'react'
import { ActionButton } from 'uiComponents/buttons'
import { ModalDialog, ModalDialogTitle, NavigationSection } from 'uiComponents/popups/modal'
import { ChartDataLoader } from 'uiComponents/loaders'
import { SingleSelectFieldFormik } from 'uiComponents/input'
import { BarcodePoolFormat, BarcodePoolType } from 'venue/bookingCodes/bookingCodesService'
import TextInputFieldFormik from '../../../uiComponents/input/textInput/textInputFieldFormik'
import { barcodeFormatOptions, poolTypeOptions } from './common'
import { FormWrapper } from '../../../uiComponents/form/form'
import { validationSchema } from './validationSchema'

function isPrefixFieldVisible(allowPrefix: boolean, type: BarcodePoolType | null) {
    return type === 'Generated' && allowPrefix
}

type FormValues = {
    barcodePoolName: string
    barcodeFormatOption: { type: BarcodePoolFormat; allowPrefix: boolean; allowGenerated: boolean } | null
    poolTypeOption: BarcodePoolType | null
    barcodePrefix: string
}

interface DialogProps {
    accountSlug: string
    loading: boolean
    onCancel: () => void
    onConfirm: (name: string, format: BarcodePoolFormat, type: BarcodePoolType, prefix: string) => void
}

export function NewPoolDialog({ onConfirm, loading, onCancel }: DialogProps) {
    const onSubmit = (values: FormValues) => {
        const { barcodePoolName, barcodeFormatOption, poolTypeOption, barcodePrefix } = values
        onConfirm(barcodePoolName, barcodeFormatOption!.type, poolTypeOption!, barcodePrefix)
    }

    return (
        <ModalDialog onDismiss={onCancel} interactive fromTop="20%">
            {loading && <ChartDataLoader />}
            <div style={{ width: '25em' }}>
                <ModalDialogTitle>Add new barcode pool</ModalDialogTitle>
                <FormWrapper
                    enableReinitialize={true}
                    initialValues={{
                        barcodePoolName: '',
                        barcodePrefix: '',
                        poolTypeOption: null,
                        barcodeFormatOption: null,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ values, isSubmitting }) => (
                        <>
                            <TextInputFieldFormik
                                id="barcodePoolName"
                                name="barcodePoolName"
                                label="Name"
                                maxLength={100}
                                placeholder="Barcode pool name"
                                type="text"
                            />
                            <SingleSelectFieldFormik
                                id="barcodeFormatOption"
                                name="barcodeFormatOption"
                                label="Barcode format"
                                options={barcodeFormatOptions}
                                height="2.5rem"
                                noSelectOption="Select format"
                            />
                            {isPrefixFieldVisible(
                                values.barcodeFormatOption?.allowPrefix ?? false,
                                values.poolTypeOption,
                            ) && (
                                <TextInputFieldFormik
                                    id="barcodePrefix"
                                    name="barcodePrefix"
                                    label="Barcode prefix"
                                    height="2.5rem"
                                    placeholder="Barcode prefix"
                                    maxLength={8}
                                />
                            )}
                            <SingleSelectFieldFormik
                                id="poolTypeOption"
                                name="poolTypeOption"
                                label="Pool type"
                                options={poolTypeOptions}
                                height="2.5rem"
                                noSelectOption="Select type"
                            />
                            <NavigationSection>
                                <ActionButton size="large" secondary onClick={onCancel}>
                                    Cancel
                                </ActionButton>
                                <ActionButton
                                    disabled={isSubmitting}
                                    id="add-new-barcode-pool-button"
                                    size="large"
                                    type="submit"
                                >
                                    Save
                                </ActionButton>
                            </NavigationSection>
                        </>
                    )}
                </FormWrapper>
            </div>
        </ModalDialog>
    )
}
