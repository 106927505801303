import React from 'react'
import classNames from 'classnames'

type GridSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

interface ConstructAssociatedClassProps {
    type: 'grid' | 'col'
    item?: boolean | GridSize
    container?: boolean | GridSize
    lg?: GridSize
    md?: GridSize
    sm?: GridSize
    xs?: GridSize
}

const constructAssociatedClass = ({ type, container, item, lg, md, sm, xs }: ConstructAssociatedClassProps) => {
    let initialString = ''

    if (['number', 'string'].includes(typeof item)) {
        initialString += item
    }

    if (['number', 'string'].includes(typeof container)) {
        initialString += container
    }

    if (!!lg) {
        initialString += `_lg-${lg}`
    }
    if (!!md) {
        initialString += `_md-${md}`
    }
    if (!!sm) {
        initialString += `_sm-${sm}`
    }
    if (!!xs) {
        initialString += `_xs-${xs}`
    }

    if (initialString) {
        return type + '-' + initialString
    }

    return type
}

interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
    container?: boolean | GridSize
    item?: boolean | GridSize
    lg?: GridSize
    md?: GridSize
    sm?: GridSize
    xs?: GridSize
}

const Grid: React.FC<GridProps> = ({ children, container, item, lg, md, sm, xs, className, ...props }) => {
    const gridClassName = constructAssociatedClass({
        type: container ? 'grid' : 'col',
        container,
        item,
        lg,
        md,
        sm,
        xs,
    })

    return (
        <div className={classNames(gridClassName, className)} {...props}>
            {children}
        </div>
    )
}

export default Grid
