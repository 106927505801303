import React, { createContext, forwardRef, HTMLAttributes, ReactElement, useContext, useEffect, useRef } from 'react'
import { SingleSelectOption } from '../singleSelect'
import { VariableSizeList } from 'react-window'
import { renderComboboxRow } from './comboboxListItem'

export interface Props {
    options: SingleSelectOption[]
    label?: string
    nested?: boolean
}

function useResetCache(data: any) {
    const ref = useRef<VariableSizeList>(null)
    useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true)
        }
    }, [data])
    return ref
}

const OuterElementContext = createContext({})
const LISTBOX_PADDING = 8

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = useContext(OuterElementContext)
    return <div ref={ref} {...props} {...outerProps} />
})

OuterElementType.displayName = 'OuterElementType'

const ComboboxListItem = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(function ListboxComponent(
    { children, ...other },
    ref,
) {
    const itemData: ReactElement[] = []
    ;(children as ReactElement[]).forEach(
        (
            item: ReactElement & {
                children?: ReactElement[]
            },
        ) => {
            itemData.push(item)
            itemData.push(...(item.children || []))
        },
    )

    const itemCount = itemData.length
    const itemSize = 48
    const itemGroupSize = 48

    const getChildSize = (child: ReactElement) => {
        if (child.hasOwnProperty('group')) {
            return itemGroupSize
        }

        return itemSize
    }

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
    }

    const gridRef = useResetCache(itemCount)

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderComboboxRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    )
})

export default ComboboxListItem
