import * as React from 'react'
import styled from 'styled-typed'
import { DateRange } from 'dateRanges'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { Body } from 'uiComponents/typography'
import { DateFormats } from 'utils/dates'

const DatePickerToggle = styled.div`
    position: relative;
    cursor: pointer;
`
const ToggleDateRange = styled.div`
    height: 2rem;
    display: flex;
    align-items: center;
    padding: 0 2.1em 0 0.8em;
    border: 1px solid transparent;
    &.with-background {
        background: ${(props) => props.theme.colors.white};
        border-radius: 4px;
        border: 1px solid ${(props) => props.theme.colors.border};
    }
    transition: all 0.1s;
    .disabled & {
        background: ${(props) => props.theme.colors.border};
        color: ${(props) => props.theme.colors.textLight};
    }
`
const ToggleText = styled.div`
    color: ${(props) => props.theme.colors.textLight};
    margin: 0.2em 0 0.1em 1.2em;
`
const CompareDateRange = styled.div`
    display: none;
    &.visible {
        display: inherit;
    }
`
const Icon = styled(FontAwesomeIcon)`
    font-size: 0.875rem;
    position: absolute;
    right: 1em;
    opacity: 0;
    transition: opacity 0.1s ease-in;

    &.visible {
        opacity: 1;
    }
`

interface PickerToggleProps {
    range: DateRange
    compareRange: DateRange
    showPickerContainer: boolean
    showToggleCompareRange: boolean
    toggleContainer: () => void
    disabled?: boolean
}

export function PickerToggle(props: PickerToggleProps) {
    const { range, compareRange, toggleContainer, showToggleCompareRange, showPickerContainer, disabled } = props
    return (
        <DatePickerToggle onClick={toggleContainer} className={disabled ? 'disabled' : ''}>
            <ToggleDateRange className={!showPickerContainer ? 'with-background' : ''}>
                <Body size={2}>
                    {format(range.from, DateFormats.LONG_DATE)} - {format(range.to, DateFormats.LONG_DATE)}
                </Body>
                <Icon icon={faCaretDown} className={!showPickerContainer ? 'visible' : ''} />
                <Icon icon={faCaretUp} className={showPickerContainer ? 'visible' : ''} />
            </ToggleDateRange>
            <CompareDateRange className={showToggleCompareRange ? 'visible' : ''}>
                <ToggleText>Compare to:</ToggleText>
                <ToggleDateRange style={{ paddingTop: '0' }}>
                    <Body size={2}>
                        {format(compareRange.from, DateFormats.LONG_DATE)} -{' '}
                        {format(compareRange.to, DateFormats.LONG_DATE)}
                    </Body>
                </ToggleDateRange>
            </CompareDateRange>
        </DatePickerToggle>
    )
}
