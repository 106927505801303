import React, { InputHTMLAttributes } from 'react'
import FieldWrapper, { FieldStatus } from '../fieldWrapper'
import { useCustomId } from '../_shared/hooks'

import { NumberInputProps, NumberInput } from './numberInput'

export interface NumberInputFieldProps extends NumberInputProps, Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
    name: string
    id?: string
    label?: string
    state?: FieldStatus
    error?: string
    value?: string
}

const getState = (state: FieldStatus, error?: string) => {
    if (!!error) {
        return 'error'
    }

    if (state === 'highlight') {
        return 'normal'
    }

    return state
}

const NumberInputField: React.FC<NumberInputFieldProps> = ({ name, label, id, error, state = 'normal', ...props }) => {
    const innerId = useCustomId(id)
    const _state = getState(state, error)

    return (
        <FieldWrapper label={label} error={error} id={innerId} status={_state}>
            <NumberInput {...props} id={innerId} status={_state} name={name} />
        </FieldWrapper>
    )
}

export default NumberInputField
