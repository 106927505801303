import * as React from 'react'
import { Pagination } from 'uiComponents/table'

export const pageSizeOptions = ['10', '20', '50', '100']
export const defaultPageSize = 10

interface PaginationSectionProps {
    pagination: Pagination
    totalItemsCount: number
    pageSizeOptions?: string[]
    onPaginationChanged: (p: Pagination) => void
    children: (props: {
        pageNumbers: (number | string)[]
        currentPage: number
        onPageChange: (page: number) => void
    }) => any
}

interface PaginationSectionState {
    inputPage: number | string
}

class PaginationContainer extends React.Component<PaginationSectionProps, PaginationSectionState> {
    constructor(props: PaginationSectionProps) {
        super(props)
        this.state = {
            inputPage: this.props.pagination.page,
        }
    }

    getNumberOfPages = () => {
        return Math.ceil(this.props.totalItemsCount / this.props.pagination.pageSize) || 1
    }

    getPageNumbersList = (numberOfPages: number, currentPage: number) => {
        const list: (number | string)[] = []
        for (let i = 1; i <= numberOfPages; i++) {
            list.push(i)
        }
        const pageIndex = currentPage - 1

        if (list.length < 5 || (list.length === 5 && pageIndex === 2)) {
            return list
        }

        if (pageIndex === 0 || pageIndex === list.length - 1) {
            list.splice(2, list.length - 4, '...')
            return list
        }

        if (pageIndex > 0 && pageIndex < 3) {
            const listStart = list.splice(0, pageIndex + 2)
            return [...listStart, '...', list[list.length - 1]]
        }

        if (pageIndex > list.length - 3 && pageIndex < list.length - 1) {
            const listEnd = list.splice(pageIndex - 1)
            return [list[0], '...', ...listEnd]
        }

        const listMiddle = list.splice(pageIndex - 1, 3)
        return [list[0], '...', ...listMiddle, '...', list[list.length - 1]]
    }

    componentDidUpdate() {
        const totalPage = this.getNumberOfPages()

        if (totalPage < this.props.pagination.page) {
            this.props.onPaginationChanged({
                page: 0,
                pageSize: this.props.pagination.pageSize,
            })
        }
    }

    render() {
        const numberOfPages = this.getNumberOfPages()
        const pageNumbers: (number | string)[] = this.getPageNumbersList(numberOfPages, this.props.pagination.page)
        const ChildComponent = this.props.children

        return (
            <ChildComponent
                onPageChange={(page: number) => {
                    this.props.onPaginationChanged({
                        page: page,
                        pageSize: this.props.pagination.pageSize,
                    })
                }}
                currentPage={this.props.pagination.page}
                pageNumbers={pageNumbers}
            />
        )
    }
}

export default PaginationContainer
