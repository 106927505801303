import * as styledComponents from 'styled-components'
import { ThemedStyledComponentsModule } from 'styled-components'

import { DashboardTheme } from './theme'

declare module 'styled-components' {
    export interface DefaultTheme extends DashboardTheme {}
}

const { default: styled } = styledComponents as ThemedStyledComponentsModule<DashboardTheme>

export * from 'styled-components'
export default styled
