import styled from 'styled-typed'
import { Col } from 'uiComponents/flex'

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const Form = styled.form`
    display: flex;
`

export const FormContent = styled.div`
    height: calc(100vh - 6em);
    display: flex;
    flex: 1;
    min-height: 0;
    margin-bottom: 6em;
    &.full-height {
        margin-bottom: 0;
    }
`

export const SidebarMenu = styled.div`
    background: ${(props) => props.theme.colors.aluminiumShades[5]};
    flex: 0 0 10em;
    width: 10em;
`

export const SidebarTitle = styled.h1`
    margin: 1.67em auto 1.17em;
    font-weight: normal;
    font-size: 0.75em;
    color: ${(props) => props.theme.colors.textDark};
    text-align: center;
    text-transform: uppercase;
    padding: 0 0.5em;
`

export const Menu = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`

export const FormBody = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.colors.white};
    width: 20em;
`

export const ScrollArea = styled.div`
    overflow: auto;
    flex: 1;
`

export const AlignedCol = styled(Col)`
    display: flex;
    align-items: center;

    &.align-right {
        justify-content: flex-end;
    }
`

export const FormLabel = styled.label`
    font-size: 1.2em;
`
