import React from 'react'
import styled from 'styled-typed'
import { DateRangeName, DateRangePeriod, DateRange } from 'dateRanges'
import { format } from 'date-fns'
import { FormItem, FormItemName } from 'uiComponents/form/formElements'
import { SingleSelect } from 'uiComponents/input/singleSelect'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { WeekdayPattern, PatternType } from 'uiComponents/weekdayPattern'
import { Row, Column, ColumnGap } from 'uiComponents/pageElements'
import { SecondaryText } from 'uiComponents/typography'
import { ValidationNotice } from 'products/components/validationNotice'
import { Recurrence } from 'products/articleConfigurationService'
import { parseDate } from 'utils'

interface ExceptionDayTimeFormItemProps {
    handleCalendarInputChange: (from: string | null, to: string | null) => void
    handleWeekdayToggleChange: (days: string[]) => void
    handleRecurrenceSelectChange: (recurrence: Recurrence) => void
    handleDayTimeInputChange: (startTime: string | null, endTime: string | null) => void
    recurrence: string | null
    weekdays: string | null
    startTime: string | null
    endTime: string | null
    startDate: string | null
    endDate: string | null
    validate: boolean
    patternType?: PatternType
}

interface ExceptionDayTimeFormItemState {
    openAllDay: boolean
    startTime: string | null
    endTime: string | null
    startDate: string | null
    endDate: string | null
    endDateBeyond: boolean
}

const DayTimeWeekdayPattern = styled(WeekdayPattern)`
    width: 100%;
`

export const recurrenceOptions = [
    { value: 'WEEKLY', name: 'Weekly' },
    { value: 'BIWEEKLY', name: 'Every two weeks' },
    { value: 'FIRST_WEEK_OF_THE_MONTH', name: 'First week of the month' },
    { value: 'LAST_WEEK_OF_THE_MONTH', name: 'Last week of the month' },
]

export class ExceptionDayTimeFormItem extends React.Component<
    ExceptionDayTimeFormItemProps,
    ExceptionDayTimeFormItemState
> {
    constructor(props: ExceptionDayTimeFormItemProps) {
        super(props)
        this.state = {
            openAllDay: !this.props.startTime || !this.props.endTime,
            startTime: this.props.startTime,
            endTime: this.props.endTime,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            endDateBeyond: false,
        }
    }

    compontentDidUpdate(prevProps: ExceptionDayTimeFormItemProps) {
        if (prevProps.validate !== this.props.validate && this.props.validate) {
            if (this.state.endDate) {
                this.checkEndDateValidity(this.state.endDate)
            }
        }
    }

    formatDateRange = (startDate: string | null, endDate: string | null): DateRange | null => {
        if (startDate && endDate) {
            const range: DateRange = {
                name: 'selectedRange' as DateRangeName,
                period: 'day' as DateRangePeriod,
                from: new Date(startDate),
                to: new Date(endDate),
            }
            return range
        }
        return null
    }

    checkEndDateValidity = (endDate: string) => {
        if (this.state.startDate) {
            if (new Date(endDate) < new Date(this.state.startDate)) {
                this.setState({ endDateBeyond: true })
                return
            }
        }
        this.setState({ endDateBeyond: false })
    }

    onDateChanged = (date: Date, type: 'startDate' | 'endDate') => {
        if (type === 'startDate') {
            this.setState({ startDate: date ? date.toString() : null }, () => {
                if (this.state.endDate) {
                    this.checkEndDateValidity(this.state.endDate)
                }
            })
            this.props.handleCalendarInputChange(
                format(date, 'yyyy-MM-dd'),
                this.state.endDate ? format(this.state.endDate, 'yyyy-MM-dd') : null,
            )
        }
        if (type === 'endDate') {
            this.setState({ endDate: date ? date.toString() : null })
            this.checkEndDateValidity(date.toString())
            this.props.handleCalendarInputChange(
                this.state.startDate ? format(this.state.startDate, 'yyyy-MM-dd') : null,
                format(date, 'yyyy-MM-dd'),
            )
        }
    }

    onStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        this.setState({ openAllDay: false, startTime: value })
        this.props.handleDayTimeInputChange(this.state.endTime ? value : null, this.state.endTime)
    }

    onEndTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        this.setState({ openAllDay: false, endTime: value })
        this.props.handleDayTimeInputChange(this.state.startTime, this.state.startTime ? value : null)
    }

    handleAllDayInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked
        if (value) {
            this.setState({
                startTime: null,
                endTime: null,
            })
        }
        this.setState({ openAllDay: value })
        this.props.handleDayTimeInputChange(null, null)
    }

    getCurrentStartTimeValue = () => {
        if (!this.state.startTime) {
            return this.state.openAllDay ? '00:00' : ''
        }
        return this.state.startTime
    }
    getCurrentEndTimeValue = () => {
        if (!this.state.endTime) {
            return this.state.openAllDay ? '23:59' : ''
        }
        return this.state.endTime
    }

    render() {
        const { weekdays, validate, patternType } = this.props
        const startDateNotValid = !this.state.startDate && validate
        const endDateNotValid = (!this.state.endDate || this.state.endDateBeyond) && validate
        return (
            <Row>
                <Column>
                    <Row>
                        <Column>
                            <FormItem htmlFor="startDate" style={{ flex: '1' }}>
                                <FormItemName style={{ display: 'flex' }}>Exception start date</FormItemName>
                                <DatePicker
                                    id="exceptionStartDate"
                                    date={this.state.startDate ? parseDate(this.state.startDate) : null}
                                    onChange={(value: Date) => this.onDateChanged(value, 'startDate')}
                                    status={startDateNotValid ? 'error' : 'normal'}
                                />
                            </FormItem>
                            <ValidationNotice className={startDateNotValid ? 'validation-message-visible' : ''}>
                                Select a start date
                            </ValidationNotice>
                        </Column>
                    </Row>
                    <Row style={{ marginBottom: '1em' }}>
                        <Column>
                            <FormItem htmlFor="endDate" style={{ flex: '1' }}>
                                <FormItemName style={{ display: 'flex' }}>Exception end date</FormItemName>
                                <DatePicker
                                    id="exceptionEndDate"
                                    status={endDateNotValid ? 'error' : 'normal'}
                                    date={this.state.endDate ? parseDate(this.state.endDate) : null}
                                    onChange={(value: Date) => this.onDateChanged(value, 'endDate')}
                                    lowerBoundary={this.state.startDate ? parseDate(this.state.startDate) : null}
                                />
                            </FormItem>
                            <ValidationNotice className={endDateNotValid ? 'validation-message-visible' : ''}>
                                {!this.state.endDate && 'Select an end date'}
                                {this.state.endDateBeyond && 'The end date should be later than the start date'}
                            </ValidationNotice>
                        </Column>
                    </Row>
                </Column>
                <ColumnGap>
                    <Row>
                        <SecondaryText style={{ marginTop: '3em', flex: '1', textAlign: 'center' }}>and</SecondaryText>
                    </Row>
                </ColumnGap>
                <Column>
                    <Row style={{ marginBottom: '1.6em' }}>
                        <FormItem htmlFor="recurrence" style={{ flex: '1' }}>
                            <FormItemName style={{ display: 'flex' }}>Define daily pattern</FormItemName>
                            <DayTimeWeekdayPattern
                                id="exceptionPattern"
                                weekdays={weekdays ? weekdays : '0,1,2,3,4,5,6'}
                                large
                                handleWeekdayToggleChange={this.props.handleWeekdayToggleChange}
                                type={patternType}
                                invert
                            />
                        </FormItem>
                    </Row>
                    <Row style={{ marginBottom: '1em' }}>
                        <Column>
                            <FormItem htmlFor="recurrence">
                                <FormItemName style={{ display: 'flex' }}>Recurrence</FormItemName>
                                <SingleSelect
                                    id="exceptionRecurrence"
                                    noSelectOption="Select recurrence"
                                    options={recurrenceOptions}
                                    selected={this.props.recurrence}
                                    height="2.8em"
                                    onSelect={this.props.handleRecurrenceSelectChange}
                                />
                            </FormItem>
                        </Column>
                    </Row>
                </Column>
            </Row>
        )
    }
}
