import React from 'react'
import styled from 'styled-typed'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { OptionsList } from 'uiComponents/menus/'
import {
    SelectElement,
    PopupArea,
    MinimizedView,
    IconContainer,
    Icon,
    SelectedOptionDisplay,
} from 'uiComponents/input/singleSelect'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import { ActionsSection, ActionText } from 'uiComponents/filter/filterComponents'
import { ActionButton } from 'uiComponents/buttons'
import { FilterOptionsItem, CheckBoxesData } from 'uiComponents/filter/filterOptionsItem'
import { FilterOption } from 'uiComponents/filter/schema'
import { getAllLevelsChildOptions, getSelectedCheckboxes } from 'uiComponents/filter'
import { checkOptionsMatch } from 'uiComponents/filter/filterOptionsItem'

type Status = 'error' | 'normal' | 'success' | 'highlight'

const OptionsArea = styled(PopupArea)`
    background: ${(props) => props.theme.colors.white};
    top: auto;
    &.visible {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    }
`

interface MultiSelectProps {
    id?: string
    options: FilterOption[]
    noSelectOption?: string
    selectedItemName?: string
    applied: string[]
    width?: string
    height?: string
    maxHeight?: string
    lineHeight?: string
    disabled?: boolean
    style?: {}
    status?: Status
    border?: boolean
    widthWhenOpen?: string
    onApply: (values: string[]) => void
}

interface MultiSelectState {
    expanded: boolean
    selected: FilterOption[]
    applied: FilterOption[]
    allOptions: FilterOption[]
    checkBoxesData: CheckBoxesData
}

export class MultiSelect extends React.Component<MultiSelectProps, MultiSelectState> {
    popup: HTMLDivElement | null = null
    icon: HTMLDivElement | null = null

    constructor(props: MultiSelectProps) {
        super(props)
        this.state = {
            expanded: false,
            selected: [],
            applied: [],
            allOptions: this.props.options,
            checkBoxesData: { selected: [], indeterminate: [] },
        }
    }

    setPopupRef = (node: HTMLDivElement) => {
        this.popup = node
    }

    setIconRef = (node: HTMLDivElement) => {
        this.icon = node
    }

    componentDidMount() {
        document.addEventListener('click', this.outsideClick, false)
        this.setData()
    }

    componentDidUpdate(prevProps: MultiSelectProps) {
        if (
            !isEqual([...prevProps.options].sort(), [...this.props.options].sort()) ||
            !isEqual([...prevProps.applied].sort(), [...this.props.applied].sort())
        ) {
            this.setData()
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.outsideClick, false)
    }

    outsideClick = (ev: MouseEvent) => {
        if (this.popup && this.popup.contains && !this.popup.contains(ev.target as Node) && this.state.expanded) {
            this.setState({ expanded: false })
            this.setData()
        }
    }

    onFocus = (e: React.FocusEvent | React.MouseEvent) => {
        if (this.state.expanded && this.icon && this.icon.contains(e.target as Node)) {
            return
        }
        if (!this.state.expanded && !this.props.disabled) {
            this.setState({ expanded: true })
        }
    }

    onBlur = (e: React.FocusEvent | React.MouseEvent) => {
        if (this.state.expanded && this.icon && this.icon.contains(e.target as Node)) {
            this.setState({ expanded: false })
            this.setData()
        }
    }

    setData = () => {
        let allLevelOptionsList: FilterOption[] = []
        this.props.options.forEach((o) => {
            allLevelOptionsList = allLevelOptionsList.concat(getAllLevelsChildOptions(o))
        })
        const applied = allLevelOptionsList.filter((o) => this.props.applied.indexOf(o.slug) > -1)
        this.setState({
            allOptions: allLevelOptionsList,
            applied,
            selected: applied,
            checkBoxesData: getSelectedCheckboxes(applied, allLevelOptionsList),
        })
    }

    onOptionSelect = (option: FilterOption) => {
        const { selected, checkBoxesData, allOptions } = this.state
        const wasSelected = !!checkBoxesData.selected.find((f) => checkOptionsMatch(f, option))
        let updatedSelection = cloneDeep(selected)
        const allRelatedOptions = this.getAllChildFilters(option)

        if (wasSelected) {
            const allRelatedIds = allRelatedOptions.map((o) => o.slug)
            updatedSelection = updatedSelection.filter((o) => allRelatedIds.indexOf(o.slug) < 0)
        } else {
            const allAlreadySelected = updatedSelection.map((o) => o.slug)
            const allRelatedNotSelected = allRelatedOptions.filter((f) => allAlreadySelected.indexOf(f.slug) < 0)
            allRelatedNotSelected.forEach((o) => updatedSelection.push(o))
        }
        this.setState({
            selected: updatedSelection,
            checkBoxesData: getSelectedCheckboxes(updatedSelection, allOptions),
        })
    }

    getAllChildFilters(option: FilterOption) {
        return getAllLevelsChildOptions(option)
            .filter((o) => o.children.length === 0)
            .map((o) => ({ ...o }))
    }

    onToggleAllCheckboxes = () => {
        let updatedSelection = cloneDeep(this.state.selected)
        if (this.state.checkBoxesData.selected.length === 0) {
            this.state.allOptions
                .filter((o) => o.leafNode)
                .forEach((i) => {
                    if (!updatedSelection.find((f) => f.slug === i.slug)) {
                        updatedSelection.push(i)
                    }
                })
        } else {
            updatedSelection = []
        }
        this.setState({
            selected: updatedSelection,
            checkBoxesData: getSelectedCheckboxes(updatedSelection, this.state.allOptions),
        })
    }

    onApply = () => {
        this.setState({ expanded: false })
        this.props.onApply(this.state.selected.map((s) => s.slug))
    }

    render() {
        const {
            id,
            options,
            noSelectOption,
            width,
            height,
            lineHeight,
            disabled,
            style,
            status,
            widthWhenOpen,
            border,
            selectedItemName,
        } = this.props
        const { expanded } = this.state
        const selectedName =
            this.props.applied.length > 0 ? `${this.props.applied.length} ${selectedItemName || 'selected'}` : null

        return (
            <SelectElement
                id={id}
                ref={this.setPopupRef}
                width={width}
                widthWhenOpen={widthWhenOpen}
                style={style}
                onClick={this.onFocus}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                lineHeight={lineHeight}
                className={expanded ? 'open' : ''}
            >
                <MinimizedView
                    height={height}
                    disabled={disabled}
                    tabIndex={0}
                    status={status ? status : 'normal'}
                    border={border}
                >
                    <SelectedOptionDisplay>{selectedName ? selectedName : noSelectOption}</SelectedOptionDisplay>
                    <IconContainer>
                        <Icon icon={faChevronDown} className={expanded ? '' : 'visible'} />
                        <div onClick={this.onBlur} ref={this.setIconRef}>
                            <Icon icon={faChevronUp} className={expanded ? 'visible' : ''} />
                        </div>
                    </IconContainer>
                </MinimizedView>
                {expanded && (
                    <OptionsArea className={expanded ? 'visible' : ''} border={border}>
                        <MemoizedOptionsList
                            options={options}
                            checkBoxesData={this.state.checkBoxesData}
                            onOptionSelect={this.onOptionSelect}
                        />
                        <ActionsSection>
                            <ActionText onClick={this.onToggleAllCheckboxes}>
                                {this.state.selected.length === 0 ? 'Select all' : 'Clear all'}
                            </ActionText>
                            <ActionButton
                                onClick={this.onApply}
                                size="small"
                                kind="action"
                                style={{ marginLeft: '1.5em' }}
                                id="apply-button"
                            >
                                Apply
                            </ActionButton>
                        </ActionsSection>
                    </OptionsArea>
                )}
            </SelectElement>
        )
    }
}

interface OptionsListProps {
    options: FilterOption[]
    checkBoxesData: CheckBoxesData
    onOptionSelect: (option: FilterOption) => void
}

const MemoizedOptionsList = React.memo(OptionsListElement, (prev: OptionsListProps, next: OptionsListProps) => {
    return isEqual(prev.options, next.options) && isEqual(prev.checkBoxesData, next.checkBoxesData)
})

function OptionsListElement(props: OptionsListProps) {
    return (
        <OptionsList className="options-list">
            {props.options.map((f, i) => (
                <FilterOptionsItem
                    key={f.name + i}
                    option={f}
                    onSelect={props.onOptionSelect}
                    checkBoxesData={props.checkBoxesData}
                />
            ))}
        </OptionsList>
    )
}
