import { faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useField } from 'formik'
import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { Row } from 'uiComponents/flex'
import { Radio, SingleSelectFieldFormik, SingleSelectOption, TimePicker } from 'uiComponents/input'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { PNFrequency } from '../types'
import { Field } from './schedule'
import { parseDate } from 'utils'

const FormItemName = styled.div`
    font-size: 0.9em;
    color: ${(props) => props.theme.colors.textDark};
    padding-bottom: 1em;
`

const DateRow = styled.div`
    display: flex;
    flex-direction: row;
`

const TimeWrapper = styled.div`
    position: relative;
    width: 100px;
    margin-left: 10px;
`

const Icon = styled(FontAwesomeIcon)`
    position: absolute;
    top: 10px;
    right: 10px;
`

const Wrapper = styled.div`
    padding-top: 1em;
`

const RadioWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
`

const RadioLabel = styled.div`
    padding-right: 1em;
`

interface ScheduleTimeFormProps {
    startDate: string | null
    setStartDate: (date: string | null) => void
    startTime: string
    setStartTime: (time: string) => void
    endDate: string | null
    setEndDate: (date: string | null) => void
    endTime: string
    setEndTime: (time: string) => void
    isRecurring: boolean
    setIsRecurring: (isRecurring: boolean) => void
    validated: boolean
    frequency: PNFrequency
    setFrequency: (frequency: PNFrequency) => void
}

const ScheduleTimeForm = ({
    startDate,
    setStartDate,
    startTime,
    setStartTime,
    endDate,
    setEndDate,
    endTime,
    setEndTime,
    isRecurring,
    setIsRecurring,
    validated,
    frequency,
    setFrequency,
}: ScheduleTimeFormProps) => {
    const theme = useTheme()
    const frequencyOptions: SingleSelectOption[] = [
        { name: 'hourly', value: PNFrequency.hourly },
        { name: 'daily', value: PNFrequency.daily },
        { name: 'weekly', value: PNFrequency.weekly },
        { name: 'monthly', value: PNFrequency.monthly },
    ]

    const [{ value }] = useField('frequency')
    const [frequencyError, setfrequencyError] = useState<boolean>(false)
    useEffect(() => {
        setFrequency(value)
        if (!validated) {
            return
        }

        setfrequencyError(!value || value === PNFrequency.once)
    }, [value, validated])

    return (
        <>
            <Row style={{ paddingBottom: '2em' }}>
                <RadioWrapper onClick={() => setIsRecurring(false)}>
                    <Radio id="isRecurring" name="isRecurring" onChange={() => {}} checked={!isRecurring} value={0} />
                    <RadioLabel>One time</RadioLabel>
                </RadioWrapper>
                <RadioWrapper onClick={() => setIsRecurring(true)}>
                    <Radio id="isRecurring" name="isRecurring" onChange={() => {}} checked={isRecurring} value={1} />
                    <RadioLabel>isRecurring</RadioLabel>
                </RadioWrapper>
            </Row>
            {!isRecurring ? (
                <DateRow>
                    <DatePicker
                        id="startDate"
                        date={startDate ? parseDate(startDate) : null}
                        onChange={(value: Date) => setStartDate(value ? format(value, 'yyyy-MM-dd') : null)}
                        status={validated && !startDate ? 'error' : 'normal'}
                        allowNullDate={false}
                    />
                    <TimeWrapper>
                        <TimePicker
                            name="startTime"
                            value={startTime}
                            onChange={(time) => time && setStartTime(time)}
                        />
                        <Icon icon={faClock} />
                    </TimeWrapper>
                </DateRow>
            ) : (
                <>
                    <Row>
                        <DateRow style={{ paddingRight: '2em' }}>
                            <Field>
                                <FormItemName>Start</FormItemName>
                                <DatePicker
                                    id="startDate"
                                    date={startDate ? parseDate(startDate) : null}
                                    onChange={(value: Date) => setStartDate(value ? format(value, 'yyyy-MM-dd') : null)}
                                    status={
                                        validated && (!startDate || (startDate && endDate && endDate < startDate))
                                            ? 'error'
                                            : 'normal'
                                    }
                                    allowNullDate={false}
                                    positionAbove
                                />
                            </Field>
                            <TimeWrapper style={{ marginTop: '31px' }}>
                                <TimePicker
                                    name="startTime"
                                    value={startTime}
                                    onChange={(time) => time && setStartTime(time)}
                                />
                                <Icon icon={faClock} />
                            </TimeWrapper>
                        </DateRow>
                        <DateRow>
                            <Field>
                                <FormItemName>End</FormItemName>
                                <DatePicker
                                    id="endDate"
                                    date={endDate ? parseDate(endDate) : null}
                                    onChange={(value: Date) => setEndDate(value ? format(value, 'yyyy-MM-dd') : null)}
                                    status={
                                        validated && (!endDate || (startDate && endDate && endDate < startDate))
                                            ? 'error'
                                            : 'normal'
                                    }
                                    allowNullDate
                                    lowerBoundary={startDate ? parseDate(startDate) : null}
                                />
                            </Field>
                            <TimeWrapper style={{ marginTop: '31px' }}>
                                <TimePicker
                                    name="endTime"
                                    value={endTime}
                                    onChange={(time) => time && setEndTime(time)}
                                />
                                <Icon icon={faClock} />
                            </TimeWrapper>
                        </DateRow>
                    </Row>
                    <Wrapper>
                        <FormItemName>frequency</FormItemName>
                        <Row>
                            <SingleSelectFieldFormik
                                name="frequency"
                                options={frequencyOptions}
                                noSelectOption="Select"
                                width="100px"
                                style={{ border: frequencyError ? `1px solid ${theme.colors.status.error}` : 'none' }}
                            />
                        </Row>
                    </Wrapper>
                </>
            )}
        </>
    )
}

export default ScheduleTimeForm
