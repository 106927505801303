import * as React from 'react'
import styled from 'styled-typed'
import { Debounce } from 'debounce'
import { Currency } from 'uiComponents/money/moneyHoc'
import { inputFieldStyles, TextInputProps, restrictToMaxLength, PrefixText, PostfixText } from '../textInput'

interface AdditionalProps {
    align?: 'left' | 'right'
}

const NumberInputField = styled.input.attrs<React.InputHTMLAttributes<HTMLInputElement>>((props) => ({
    type: props.type ? props.type : 'number',
}))<AdditionalProps>`
    ${inputFieldStyles}
    ${(props) =>
        props.align === 'right' &&
        `text-align: right;
    padding-right: 1.7em;
    ::placeholder {
      padding-right: .1em;
    }`}
    ${(props) => props.align === 'left' && 'padding-left: 1.7em;'}
`

export function restrictToMax(value: number | string | string[], max: number | string): number | string | string[] {
    if (value === '') {
        return value
    }
    if (+value > +max) {
        const adjusted = Math.min(+value, +max)
        return adjusted
    }
    return value
}

export function restrictToMin(value: number | string | string[], min: number | string): number | string | string[] {
    if (value === '') {
        return value
    }
    if (+value < +min) {
        const adjusted = Math.max(+value, +min)
        return adjusted
    }
    return value
}

export function restrictDecimals(value: number | string | string[], amount: number): number | string | string[] {
    if (value === '') {
        return value
    }
    const newValue = +value
    return newValue.toFixed(amount)
}

function checkBoundaries(
    value?: string | string[] | number,
    max?: number | string,
    min?: number | string,
    maxLength?: number,
    currency?: Currency,
) {
    let newValue = value
    if (!!newValue || newValue === 0) {
        if (typeof max !== 'undefined') {
            newValue = restrictToMax(newValue, max)
        }
        if (typeof min !== 'undefined') {
            newValue = restrictToMin(newValue, min)
        }
        if (typeof maxLength !== 'undefined') {
            newValue = restrictToMaxLength(newValue, maxLength)
        }
        if (typeof currency !== 'undefined') {
            const currencyDecimals = currency.decimalPoints
            newValue = restrictDecimals(newValue, currencyDecimals)
        }
    }
    return newValue
}

interface NumberInputPropsInterface extends TextInputProps {
    integerOnly?: boolean
    currency?: Currency
    align?: 'left' | 'right'
    whiteBackground?: boolean
    inputRef?: React.MutableRefObject<null>
    resetToZero?: boolean
    restrictInput?: boolean
    autoCorrectInput?: boolean
}

export type NumberInputProps = NumberInputPropsInterface & React.InputHTMLAttributes<HTMLInputElement>

export class NumberInput extends React.PureComponent<NumberInputProps> {
    private finishTypingLater: Debounce<string> | undefined

    constructor(props: NumberInputProps) {
        super(props)

        if (this.props.onFinishTyping) {
            this.finishTypingLater = new Debounce(this.props.onFinishTyping, this.props.finishTypingDelay || 500)
        }
    }

    componentWillUnmount() {
        if (this.finishTypingLater) {
            this.finishTypingLater.clear()
        }
    }

    onChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.onChange) {
            this.props.onChange(e)
        }

        if (this.finishTypingLater) {
            this.finishTypingLater.trigger(e.target.value)
        }
    }

    onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { max, min, maxLength, currency, resetToZero, autoCorrectInput = true } = this.props
        const event = e
        if (e.target.value === '' && resetToZero) {
            event.target.value = '0'
        }
        if (autoCorrectInput) {
            const newValue = checkBoundaries(e.target.value, max, min, maxLength, currency) as string
            event.target.value = this.roundDecimals(newValue)
        }
        if (this.props.onChange) {
            this.props.onChange(event)
        }
        if (this.props.onBlur) {
            this.props.onBlur(event)
        }
    }

    onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select()
    }

    onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { min, integerOnly, restrictInput = true } = this.props
        if (!restrictInput) {
            return
        }
        const allowedKeyList = [
            'Backspace',
            'Tab',
            'Enter',
            'Delete',
            'ArrowDown',
            'ArrowUp',
            'ArrowLeft',
            'ArrowRight',
        ]
        const negativeAllowed = typeof min !== 'number' || min < 0
        if (negativeAllowed) {
            allowedKeyList.push('-')
        }
        const keyAllowed = allowedKeyList.includes(e.key) || e.ctrlKey || e.metaKey
        const isNumber = !isNaN(parseInt(e.key, 10))
        if (integerOnly && !isNumber && !keyAllowed) {
            e.preventDefault()
        }
    }

    getStep = (): string => {
        const { step, integerOnly, currency } = this.props
        if (!!step) {
            return typeof step === 'number' ? step.toString() : step
        }
        if (!!integerOnly) {
            return '1'
        }
        if (!!currency) {
            switch (currency.decimalPoints) {
                case 0:
                    return '1'
                case 1:
                    return '0.1'
                case 2:
                    return '0.01'
                case 3:
                    return '0.001'
                default:
                    return '0.01'
            }
        } else {
            return '0.01'
        }
    }

    roundDecimals = (value: string) => {
        const requiredDecPoints = this.props.currency ? this.props.currency.decimalPoints : 2
        const newValue = value.toString()
        if (newValue.includes('.')) {
            const decPoints = newValue.split('.')[1]
            if (decPoints && decPoints.length > requiredDecPoints) {
                return Number(value).toFixed(requiredDecPoints)
            }
        }
        return value
    }

    render() {
        const {
            inputRef,
            onChange,
            onFinishTyping,
            value,
            maxLength,
            onBlur,
            min,
            max,
            placeholder,
            step,
            align,
            prefix,
            postfix,
            ...rest
        } = this.props
        return (
            <>
                {!!prefix && (
                    <div className="prefix">
                        <PrefixText>{this.props.prefix}</PrefixText>
                    </div>
                )}
                <NumberInputField
                    ref={inputRef}
                    placeholder={placeholder ?? '9.99'}
                    lang="en"
                    onKeyDown={this.onKeyDown}
                    onChange={this.onChanged}
                    value={value}
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                    max={max}
                    min={min}
                    step={this.getStep()}
                    align={align}
                    {...rest}
                />
                {!!postfix && <PostfixText className="postfix">{this.props.postfix}</PostfixText>}
            </>
        )
    }
}
