import React, { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IconProp } from '@fortawesome/fontawesome'
import { faPencil, faTrash, faCalendar, faTag, faClone } from '@fortawesome/pro-solid-svg-icons'

import { ActionIcon } from 'uiComponents/buttons/actionIcon'
import { Col, Row } from 'uiComponents/flex'
import { BaseRouteParams } from 'hocs'
import { ProductTableActionKind, productTableContext } from './provider'
import { ArticleFromApi, CategoryFromApi } from '../types'
import { isArticle } from './utils'

interface Props {
    product: CategoryFromApi | ArticleFromApi
}

const ProductListActions: React.FC<Props> = ({ product }) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const { dispatch } = useContext(productTableContext)
    const article = isArticle(product)

    const getEditUrl = () => {
        if (article) {
            return `/account/${accountSlug}/products/crud/${product.id}?from=nested`
        }

        return `/account/${accountSlug}/products/crud/productList/${product.id}?from=nested`
    }

    const getValidityUrl = () => {
        return `/account/${accountSlug}/products/validity/${product.id}/detail?from=nested`
    }

    const getSettingsUrl = () => {
        return `/account/${accountSlug}/products/pricing/${product.id}/detail?from=nested`
    }

    return (
        <Row gutter={16}>
            <Col span="auto">
                <Link to={getEditUrl()}>
                    <ActionIcon size="large" title="Edit" icon={faPencil as IconProp} />
                </Link>
            </Col>
            {article && (
                <>
                    <Col span="auto">
                        <Link to={getValidityUrl()}>
                            <ActionIcon size="large" title="Validity settings" icon={faCalendar as IconProp} />
                        </Link>
                    </Col>
                    <Col span="auto">
                        <Link to={getSettingsUrl()}>
                            <ActionIcon size="large" title="Pricing settings" icon={faTag as IconProp} />
                        </Link>
                    </Col>
                    <Col span="auto">
                        <ActionIcon
                            size="large"
                            title="Clone product"
                            icon={faClone as IconProp}
                            onClick={() => {
                                dispatch({ type: ProductTableActionKind.SET_PRODUCT_FOR_DUPLICATING, payload: product })
                            }}
                        />
                    </Col>
                </>
            )}
            <Col>
                <ActionIcon
                    size="large"
                    title="Delete category"
                    icon={faTrash as IconProp}
                    onClick={() => {
                        dispatch({ type: ProductTableActionKind.SET_PRODUCT_FOR_DELETING, payload: product })
                    }}
                />
            </Col>
        </Row>
    )
}

export default ProductListActions
