import { faPauseCircle, faPencilAlt, faPlay, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import * as React from 'react'
import { useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { TableLoader } from 'uiComponents/loaders'
import { Container, ContainerHeading, HeadingSectionName } from 'uiComponents/settingsContainer'
import { InlineDataTable, MultipleStatus, NameAndStatus } from 'uiComponents/table'
import { Icon } from '../../crm/audiences/pages/customList'
import { BaseRouteParams } from '../../hocs'
import { StatusAndText } from '../../notificationCampaigns/components/ui'
import styled from '../../styled-typed'
import { useVenueLocations } from '../../tapPlacements/studio/helpers'
import { ActionButtonA } from '../../uiComponents/buttons'
import { OnClickMenu, OnClickMenuItem } from '../../uiComponents/menus'
import { ReactTable, TableColumn } from '../../uiComponents/table/reactTable'
import Header from '../components/header'
import { usePushNotificationsContext } from '../context/PushNotifications'
import { decodeDateTimeStr } from '../helpers'
import usePNActions from '../hooks/usePNActions'
import usePNApps from '../hooks/usePNApps'
import { useLazyListCampaignsQuery } from '../reduxQuery'
import { PNCampaign } from '../types'

const IconDelete = styled(Icon)`
    color: ${(props) => props.theme.colors.status.error};
`

const SearchContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1em;
`

const CampaignsPage = () => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const { checkPNApp } = usePNApps(accountSlug)
    const venueLocations = useVenueLocations(accountSlug)
    const { deletePN, startPN, stopPN, isLoading } = usePNActions()
    const history = useHistory()
    const { appValidated, setAppValidated } = usePushNotificationsContext()

    const initApp = useCallback(async () => {
        await checkPNApp()
        setAppValidated(true)
    }, [venueLocations])

    useEffect(() => {
        if (!appValidated) {
            initApp()
        }
    }, [])

    const getLocation = (item: PNCampaign) => {
        const location = venueLocations.find((location) => location.value === item.location_id)
        return location?.name || '-'
    }

    const getStatus = (item: PNCampaign) => {
        return item.active ? 'success' : 'error'
    }

    const columns = React.useMemo(() => {
        return [
            {
                accessor: 'name',
                Header: 'Name',
            },
            {
                accessor: 'location',
                Header: 'Location',
                disableSortBy: true,
                Cell: ({ row: { original } }) => getLocation(original),
            },
            {
                accessor: 'start',
                Header: 'Start',
                disableSortBy: true,
                Cell: ({ row: { original } }) => decodeDateTimeStr(original.schedule?.start_time).date,
            },
            {
                accessor: 'end',
                Header: 'End',
                disableSortBy: true,
                Cell: ({ row: { original } }) => decodeDateTimeStr(original.schedule?.end_time).date,
            },
            {
                accessor: 'state',
                Header: 'State',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    return (
                        <NameAndStatus>
                            <StatusAndText>
                                <MultipleStatus
                                    status={getStatus(original)}
                                    withText
                                    successText="Active"
                                    errorText="Inactive"
                                    blueText="Draft"
                                />
                            </StatusAndText>
                        </NameAndStatus>
                    )
                },
            },
            {
                accessor: 'action',
                disableHideColumn: true,
                disableSortBy: true,
                width: '10em',
                align: 'right',
                style: { overflow: 'unset' },
                Cell: ({ row: { original } }) => (
                    <OnClickMenu title="Actions" kind="action" width="15em" secondary alignRight>
                        {original.active && (
                            <OnClickMenuItem onClick={() => stopPN(original)}>
                                <Icon icon={faPauseCircle} /> Pause
                            </OnClickMenuItem>
                        )}
                        {!original.active && (
                            <OnClickMenuItem onClick={() => startPN(original)}>
                                <Icon icon={faPlay} /> Start
                            </OnClickMenuItem>
                        )}
                        <OnClickMenuItem
                            onClick={() => {
                                history.push(`/account/${accountSlug}/engage/push_notifications/target/${original.id}`)
                            }}
                        >
                            <Icon icon={faPencilAlt} /> Edit
                        </OnClickMenuItem>
                        <OnClickMenuItem onClick={() => deletePN(original)}>
                            <IconDelete icon={faTrashAlt} /> Delete
                        </OnClickMenuItem>
                    </OnClickMenu>
                ),
            },
        ] as TableColumn<PNCampaign>[]
    }, [venueLocations])

    const [listCampaigns, { data: campaigns, isFetching }] = useLazyListCampaignsQuery()

    const fetchCampaigns = async () => {
        await listCampaigns({ slug: accountSlug })
    }

    useEffect(() => {
        fetchCampaigns()
    }, [])

    const TableContent = useCallback(() => {
        return (
            <>
                {(isFetching || isLoading) && <TableLoader />}
                {!(isFetching || isLoading) && campaigns && (
                    <ReactTable
                        size="small"
                        sort
                        loading={isFetching}
                        data={campaigns || []}
                        columns={columns}
                        noResultsRow
                    />
                )}
            </>
        )
    }, [campaigns, isFetching, isLoading])

    return (
        <div>
            <Header />
            <Container>
                <ContainerHeading style={{ paddingLeft: '1.5em' }}>
                    <HeadingSectionName className="active" to="">
                        App Campaigns
                    </HeadingSectionName>
                </ContainerHeading>
                <SearchContainer style={{ margin: '1.5em' }}>
                    {/* <SearchField placeholder="Search by name" />*/}
                    <ActionButtonA
                        kind="action"
                        size="medium"
                        id="add-new-campaign"
                        href={`/account/${accountSlug}/engage/push_notifications/target`}
                    >
                        + Add new
                    </ActionButtonA>
                </SearchContainer>
                <InlineDataTable id="list">
                    <TableContent />
                </InlineDataTable>
            </Container>
        </div>
    )
}

export default CampaignsPage
