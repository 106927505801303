import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { LoaderWrapper } from 'uiComponents/loaders'
import { POI } from 'venue/parkMap/models'
import { ParkMapPagesParams } from 'venue/parkMap/paths'
import { useParkMapCategoriesQuery, useParkMapPoiQuery, useUpdatePoiMutation } from 'venue/parkMap/reduxQuery'
import { useSelectedParkMapContext } from '../../../context/selectedParkMap'
import useMarkers from '../../../hooks/useMarkers'
import PoiForm from './poiForm'

const EditPoi = () => {
    const { poiId } = useParams<ParkMapPagesParams>()
    const { data, isLoading } = useParkMapPoiQuery({ poiId })
    const { selectedParkMap } = useSelectedParkMapContext()
    const categories = useParkMapCategoriesQuery({ locationId: selectedParkMap?.locationId })
    const [updatePoi] = useUpdatePoiMutation()
    const history = useHistory()
    const { displayMarkers } = useMarkers()
    const iconCategory = categories?.data?.find((category) => category.id === data?.categoryId)?.icon

    useEffect(() => {
        if (categories.data?.length) {
            setTimeout(() => displayMarkers(categories.data), 100)
        }
    }, [categories.data])

    const poi = (): POI => {
        return {
            ...data,
            icon: data?.icon || iconCategory,
        } as POI
    }

    return (
        <LoaderWrapper loading={isLoading}>
            <PoiForm
                pageTitle="Edit Poi"
                data={poi()}
                onSubmit={(poi: POI) => {
                    updatePoi({ poi: poi })
                        .unwrap()
                        .then(() => history.goBack())
                }}
            />
        </LoaderWrapper>
    )
}

export default React.memo(EditPoi)
