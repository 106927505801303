export interface CustomerProfileListPage {
    entries: CustomerProfileListItem[]
    currentPage: number
    pageSize: number
    totalCount: number
}

export interface CustomerProfileListItem {
    accountSlug: string
    averageOrderValue: ValueWithCurrency[]
    cookieId: string
    email: string | null
    lastSeen: string
    name: string | null
    paymentsCount: number
}

export interface CustomerDetails {
    cookieId: string
    activity: Activity[]
    profile: CustomerProfile | null
}

export interface Audience {
    name: string
    slug: string
}

export interface PreferredDeviceTypes {
    deviceType: string
    usage: number
}

export interface CustomerProfile {
    email: string | null
    name: string | null
    preferredDeviceTypes: PreferredDeviceTypes[] | null
    firstPurchaseAt: Date | null
    lastPurchaseAt: Date | null
    firstVenueVisitedAt: Date | null
    lastVenueVisitedAt: Date | null
    upcomingVisitsCount: number | null
    lastActivityAction: string | null
    isSeasonpassHolder: boolean
    lifetimeValue: ValueWithCurrency[] | null
    totalOrderValue: ValueWithCurrency[] | null
    totalVisitsCount: number | null
    location: string | null
    acquisitionChannels: string[] | null
    hasTapApp: boolean | null
    marketingOptIn: boolean | null
    averageOrderValue: ValueWithCurrency[] | null
    averageTransactionValue: ValueWithCurrency[] | null
    ordersCount: number | null
    paymentsCount: number | null
    firstSeen: Date | null
    lastSeen: Date | null
    language: string | null
    products: ProductsBought[] | null
    lastModified: Date
    audiences: Audience[]
}

export interface ValueWithCurrency {
    currency: string
    amount: number
}

interface ProductsBought {
    product_name: string
    amount: number
}

export interface Activity {
    activityName: string
    activityStartTime: string
    activityEvents: ActivityEvents[]
    activityDeviceType: string
    sessionTime: string | null
    device: string | null
}

interface ActivityEvents {
    event: string
    timestamp: string
    properties: ActivityEventProperties
}

export interface ActivityEventProduct {
    amount: number
    article_uuid?: string
    original_price: string
    product_name: string
    total_discount: string
    total_price: string
}

export interface ActivityEventProperties {
    event_type?: string | null
    event?: string | null
    timestamp?: string | null
    order_id?: number | null
    order_uuid?: string | null
    cart_id?: string | null
    amount: number | null
    currency: string | null
    landing_page: string | null
    order_number: string | null
    payment_method: string | null
    payment_provider: string | null
    products: ActivityEventProduct[]
    referrer: string | null
    google_analytics_cid: string[] | null
}

export interface ActivityEventMappedProperty {
    name: string
    value: string | ActivityEventProduct[] | React.ReactNode
}

export interface GroupedActivities {
    [key: string]: Activity[]
}

export interface FieldConfigItem {
    name: string
    slug: string
    array: boolean
    tooltipText: string
}

export interface AudienceAvailableResponse {
    available: Audience[]
}

export interface ExportAudienceBody {
    token: string
}

export const profileActivityConfig: FieldConfigItem[] = [
    {
        name: 'Returning/new',
        slug: 'firstSeen',
        array: false,
        tooltipText: 'A returning or a new customer on the Convious platform.',
    },
    {
        name: 'Has TAP app',
        slug: 'hasTapApp',
        array: false,
        tooltipText: '"True" for customers who have installed the TAP app.',
    },
    {
        name: 'Preferred device',
        slug: 'preferredDeviceTypes',
        array: false,
        tooltipText: 'Mobile or Desktop (includes laptops).',
    },
    {
        name: 'Language',
        slug: 'language',
        array: false,
        tooltipText: 'Customer selected language on the Convious checkout.',
    },
    {
        name: 'First website visit date',
        slug: 'firstSeen',
        array: false,
        tooltipText: 'Date and time of the first customer action detected on the Convious platform.',
    },
    {
        name: 'Last website visit date',
        slug: 'lastSeen',
        array: false,
        tooltipText: 'Date and time of the last customer action detected on the Convious platform.',
    },
    {
        name: 'Acquisition channels',
        slug: 'acquisitionChannels',
        array: true,
        tooltipText: 'A list of acquisition channels a customer used to get to your website.',
    },
    {
        name: 'Marketing OPT in',
        slug: 'marketingOptIn',
        array: false,
        tooltipText: '"Yes" for customers who have subscribed to Convious marketing newsletters.',
    },
    {
        name: 'First purchase',
        slug: 'firstPurchaseAt',
        array: false,
        tooltipText: 'Date and time (UTC) of the first purchase on Convious checkout.',
    },
    {
        name: 'Last purchase',
        slug: 'lastPurchaseAt',
        array: false,
        tooltipText: 'Date and time (UTC) of the last purchase on Convious checkout.',
    },
    {
        name: 'Last activity (any)',
        slug: 'lastActivityAction',
        array: false,
        tooltipText: 'Last activity detected on the Convious platform.',
    },
    {
        name: 'First venue visit',
        slug: 'firstVenueVisitedAt',
        array: false,
        tooltipText: 'The earliest visit date assigned to the purchased tickets.',
    },
    {
        name: 'Last venue visit',
        slug: 'lastVenueVisitedAt',
        array: false,
        tooltipText: 'The latest visit date assigned to the purchased tickets.',
    },
    {
        name: 'Upcoming visits',
        slug: 'upcomingVisitsCount',
        array: false,
        tooltipText: 'A number of unique upcoming visit dates.',
    },
    {
        name: 'Is season pass holder',
        slug: 'isSeasonpassHolder',
        array: false,
        tooltipText: '"Yes" for customers who have purchased a season pass.',
    },
    {
        name: 'Lifetime value',
        slug: 'totalOrderValue',
        array: true,
        tooltipText:
            'Total revenue of all transactions (The number in the brackets shows the total purchased order value before discounting).',
    },
    {
        name: 'Average order value',
        slug: 'averageTransactionValue',
        array: true,
        tooltipText:
            'Total revenue of all transactions divided by the number of transactions (The number in the brackets shows the total purchased order value before discounting divided by the number of transactions).',
    },
]
