import * as React from 'react'
import { Bold } from 'uiComponents/typography'
import { Cell, ExpandCell, Status, DynamicRow, NameAndStatus, Name } from 'uiComponents/table'
import { ArticleListItem } from 'admin/articleService'
import { NestedElement } from 'products/components/nestedContainer'
import { pricingTypes } from 'products/articleConfigurationService'
import { CopyIcon } from 'uiComponents/buttons/copyIcon'

interface ProductRowProps {
    element: NestedElement
    expanded: boolean
    expandable: boolean
    accountSlug: string
    narrow?: boolean
    toggleExpanded: () => void
}

export function ProductRow(props: ProductRowProps & React.HTMLAttributes<HTMLDivElement>) {
    const { element, expanded, toggleExpanded, style, narrow, expandable } = props
    const elementAsArticle = element as ArticleListItem
    const isArticle = elementAsArticle.id ? true : false
    const pricingType = pricingTypes[elementAsArticle.pricingType]

    return (
        <DynamicRow
            interactive={expandable}
            narrow={narrow}
            highlighted={expanded}
            className={expanded ? 'expanded article' : 'article'}
            onClick={toggleExpanded}
        >
            {!!expandable ? <ExpandCell className="expand" expanded={expanded} /> : <Cell />}
            <Cell title={element.name} style={style} noPadding>
                {narrow ? (
                    <>
                        <Status active={element.enabled} />
                        <Name className="name">{element.name}</Name>
                    </>
                ) : (
                    <NameAndStatus>
                        <Bold className="name">{element.name}</Bold>
                        <Status active={element.enabled} withText />
                    </NameAndStatus>
                )}
            </Cell>
            {isArticle ? (
                <>
                    <Cell className="type">{pricingType}</Cell>
                    <Cell className="withName" title={elementAsArticle.numericId} onClick={(e) => e.stopPropagation()}>
                        <span>{elementAsArticle.numericId}</span>
                        <CopyIcon text={elementAsArticle.numericId} style={{ marginLeft: '1em' }} />
                    </Cell>
                </>
            ) : (
                <>
                    <Cell className="type">List</Cell>
                    <Cell>-</Cell>
                </>
            )}
        </DynamicRow>
    )
}
