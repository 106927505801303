import React, { useState, useEffect, useContext, useRef } from 'react'
import { usePrevious } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { format } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import { QueryConfig } from 'reports/queryGenerator'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { DateRange, DateRangeName } from 'dateRanges'
import {
    areDateRangeDatesEqual,
    comparisonCopyMap,
    getComparisonColorFromPercentString,
    getTooltipFooter,
    getTooltipHeader,
    getTooltipRow,
} from 'reports/helpers'
import MiniChart from 'uiComponents/charts/miniChart'
import { Filters } from 'uiComponents/filter/schema'
import { isEqual } from 'lodash'
import { DataNumber } from 'uiComponents/charts/dataNumber'
import { toPercent, UseComparisonMetric } from './utils'
import { differenceInCalendarDays } from 'date-fns'
import { TimeseriesBarChartSource } from './miniChartSource'
import styled from 'styled-typed'
import { Row, Col } from 'uiComponents/flex'
import { NoDataNotice } from 'uiComponents/charts/styleComponents'

type ContainerProps = {
    withBorder: boolean
}

const Container = styled.div<ContainerProps>`
    ${(props) =>
        props.withBorder
            ? `
    border-right: 1px solid ${props.theme.colors.border};
    `
            : ''}
`

const queryConfig: QueryConfig = {
    querySetName: 'CheckoutNavigations',
    variablesConfig: [
        { name: 'accountSlug', type: 'String' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'comparisonType', type: 'ComparisonTypes' },
        { name: 'delayType', type: 'DelayTypes' },
        { name: 'delay', type: 'Int' },
        { name: 'filters', type: '[FilterDictionary]' },
    ],
    queries: [
        {
            name: 'checkoutScreenTimeSpent',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'dateFrom', 'dateTo', 'comparisonType', 'delayType', 'delay', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'checkout_screen_time_spent' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'checkoutScreenReachRatio',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'dateFrom', 'dateTo', 'comparisonType', 'delayType', 'delay', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'checkout_screen_reach_ratio' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'checkoutScreenDropRatio',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'dateFrom', 'dateTo', 'comparisonType', 'delayType', 'delay', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'checkout_screen_drop_ratio' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'checkoutScreenConversionRate',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'dateFrom', 'dateTo', 'comparisonType', 'delayType', 'delay', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'checkout_screen_conversion_rate' }],
            presetResult: 'totalsComparison',
        },
    ],
}

const timeSerieQueryConfig: QueryConfig = {
    querySetName: 'CheckoutNavigations',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'granularity', type: 'StatsGranularity' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'filters', type: '[FilterDictionary]' },
    ],
    queries: [
        {
            name: 'checkoutScreenTimeSpentTimeSerie',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'checkout_screen_time_spent' }],
            presetResult: 'timeseries',
        },
        {
            name: 'checkoutScreenReachRatioTimeSerie',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'checkout_screen_reach_ratio' }],
            presetResult: 'timeseries',
        },
        {
            name: 'checkoutScreenDropRatioTimeSerie',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'checkout_screen_drop_ratio' }],
            presetResult: 'timeseries',
        },
        {
            name: 'checkoutScreenConversionRateTimeSerie',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'checkout_screen_conversion_rate' }],
            presetResult: 'timeseries',
        },
    ],
}

interface ChartsProps {
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    dateRange: DateRange
    filters: Filters[]
    checkoutScreenTimeSpentChart: TimeseriesBarChartSource
    checkoutScreenReachRatioChart: TimeseriesBarChartSource
    checkoutScreenDropRatioChart: TimeseriesBarChartSource
    checkoutScreenConversionRateChart: TimeseriesBarChartSource
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

function Chart(props: ChartsProps) {
    const _isMounted = useRef(false)
    const _lastRequest = useRef<number>()

    const statsService = useContext(StatsServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const checkoutScreenTimeSpentMetrics = UseComparisonMetric({
        initialValue: '0',
        initialComparisonValue: '-',
    })
    const checkoutScreenReachRatioMetrics = UseComparisonMetric({
        initialValue: '0',
        initialComparisonValue: '-',
    })
    const checkoutScreenDropRatioMetrics = UseComparisonMetric({
        initialValue: '0',
        initialComparisonValue: '-',
    })
    const checkoutScreenConversionRateMetrics = UseComparisonMetric({
        initialValue: '0',
        initialComparisonValue: '-',
    })
    const [timePeriod, setTimePeriod] = React.useState<DateRangeName>('yesterday')
    const [timePeriodLength, setTimePeriodLength] = React.useState<number>(0)

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    const prevFilters = usePrevious(props.filters)

    const infotipContentMap = {
        checkoutScreenTimeSpent: {
            pageName: 'Average time spent',
            itemName: 'Average time spent',
            more: 'higher',
            less: 'lower',
            metricText: 'The average time Checkout visitors spent on this page.',
        },
        checkoutScreenReachRatio: {
            pageName: '% of visitors reached',
            itemName: '% of visitors reached',
            more: 'higher',
            less: 'lower',
            metricText: 'The rate of Checkout visitors who  reached this page.',
        },
        checkoutScreenDropRatio: {
            pageName: 'Checkout exit',
            itemName: 'number of checkout exits',
            more: 'higher',
            less: 'lower',
            metricText: 'The rate of visitors who exit your Checkout after reaching this page.',
        },
        checkoutScreenConversionRate: {
            pageName: 'Overall page conversion rate',
            itemName: 'page conversion rate',
            more: 'higher',
            less: 'lower',
            metricText: 'The page conversion rate shows how many of those who visited this page  converted.',
        },
    }

    useEffect(() => {
        _isMounted.current = true
        if (
            prevAccountSlug !== props.accountSlug ||
            !areDateRangeDatesEqual(prevDateRange, props.dateRange) ||
            !isEqual(prevFilters, props.filters)
        ) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug, props.dateRange])

    const getData = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        setLoading(true)
        if (props.filters.length === 0) {
            return
        }

        await getTimeSerie()
        const timePeriods = ['today', 'yesterday', 'thisWeek', 'thisMonth']
        const timePeriodForMap = timePeriods.indexOf(props.dateRange.name) > -1 ? props.dateRange.name : 'custom'
        setTimePeriod(timePeriodForMap)
        const dateRangeLength = differenceInCalendarDays(props.dateRange.to, props.dateRange.from) + 1
        setTimePeriodLength(dateRangeLength)

        const dateFrom = format(props.dateRange.from || new Date(), 'yyyy-MM-dd')
        const dateTo = format(props.dateRange.to || new Date(), 'yyyy-MM-dd')
        try {
            const variables = {
                accountSlug: props.accountSlug,
                comparisonType: 'percentage_growth',
                delayType:
                    timePeriodForMap === 'thisMonth' ? 'months' : dateRangeLength <= 7 ? 'weeks' : 'previous_stretch',
                delay: 1,
                dateFrom,
                dateTo,
                filters: props.filters,
            }

            const data = await statsService.getStats(queryConfig, variables)

            if (_lastRequest.current !== requestTime) {
                return
            }

            checkoutScreenTimeSpentMetrics.setData({
                value: data.checkoutScreenTimeSpent.totals.value,
                comparison: data.checkoutScreenTimeSpent.totals.change,
            })

            checkoutScreenReachRatioMetrics.setData({
                value: data.checkoutScreenReachRatio.totals.value,
                comparison: data.checkoutScreenReachRatio.totals.change,
            })

            checkoutScreenDropRatioMetrics.setData({
                value: data.checkoutScreenDropRatio.totals.value,
                comparison: data.checkoutScreenDropRatio.totals.change,
            })

            checkoutScreenConversionRateMetrics.setData({
                value: data.checkoutScreenConversionRate.totals.value,
                comparison: data.checkoutScreenConversionRate.totals.change,
            })

            if (_isMounted.current) {
                setLoading(false)
            }
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Screens overview chart could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }

    const getTimeSerie = async () => {
        const dateFrom = format(props.dateRange.from || new Date(), 'yyyy-MM-dd')
        const dateTo = format(props.dateRange.to || new Date(), 'yyyy-MM-dd')
        try {
            const variables = {
                widget: props.accountSlug,
                granularity: 'day',
                dateFrom,
                dateTo,
                filters: props.filters,
            }

            props.checkoutScreenTimeSpentChart.clearSeries()
            props.checkoutScreenReachRatioChart.clearSeries()
            props.checkoutScreenDropRatioChart.clearSeries()
            props.checkoutScreenConversionRateChart.clearSeries()
            const data = await statsService.getStats(timeSerieQueryConfig, variables)

            data.checkoutScreenTimeSpentTimeSerie.timeSeries.forEach((timeSerie) => {
                props.checkoutScreenTimeSpentChart.formatLineSeries([timeSerie], 'number')
            })

            data.checkoutScreenReachRatioTimeSerie.timeSeries.forEach((timeSerie) => {
                props.checkoutScreenReachRatioChart.formatLineSeries([timeSerie])
            })

            data.checkoutScreenDropRatioTimeSerie.timeSeries.forEach((timeSerie) => {
                props.checkoutScreenDropRatioChart.formatLineSeries([timeSerie])
            })

            data.checkoutScreenConversionRateTimeSerie.timeSeries.forEach((timeSerie) => {
                props.checkoutScreenConversionRateChart.formatLineSeries([timeSerie])
            })
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Screens overview chart could not be loaded, please try again later.',
            )
        }
    }

    const getMarker = (color: string) => {
        return `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color};"></span>`
    }

    const chartTooltipFormatter = (params: TooltipParams[], itemType: string) => {
        const xAxisLabel = params[0].axisValue

        let tooltip = getTooltipHeader(xAxisLabel)
        params.forEach((item) => {
            tooltip += getTooltipRow([`${getMarker(item.color)} ${item.data}${itemType === 'percentage' ? '%' : ''}`])
        })

        tooltip += getTooltipFooter()
        return tooltip
    }

    const getPlusSign = (change: string) => {
        const value = Number(change.split('%')[0])
        return value > 0 ? '+' : ''
    }

    const formatChangeText = (stats: string, change: string) => {
        const value = Number(change.split('%')[0])
        return value >= 0
            ? `${getPlusSign(change)}${change} ${infotipContentMap[stats].more}`
            : `${change} ${infotipContentMap[stats].less}`
    }

    const formatInfotipText = (stats: string, change: string) => {
        const textPart = timePeriod === 'yesterday' || timePeriod === 'custom' ? 'had' : 'are expected to have'
        return (
            <>
                {comparisonCopyMap[timePeriod].infotipStart} you {textPart}
                &nbsp;{formatChangeText(stats, change)} {infotipContentMap[stats].itemName}
                &nbsp;than the {comparisonCopyMap[timePeriod].infotipEnd}.
            </>
        )
    }

    const getComparisonText = () => {
        return timePeriod === 'thisMonth' ? 'MoM' : timePeriodLength <= 7 ? 'WoW' : 'prev. period'
    }

    const allItems = () => {
        return [
            {
                key: 'checkoutScreenTimeSpent',
                data: checkoutScreenTimeSpentMetrics,
                type: 'number',
                chart: props.checkoutScreenTimeSpentChart,
            },
            {
                key: 'checkoutScreenReachRatio',
                data: checkoutScreenReachRatioMetrics,
                type: 'percentage',
                chart: props.checkoutScreenReachRatioChart,
            },
            {
                key: 'checkoutScreenDropRatio',
                data: checkoutScreenDropRatioMetrics,
                type: 'percentage',
                chart: props.checkoutScreenDropRatioChart,
            },
            {
                key: 'checkoutScreenConversionRate',
                data: checkoutScreenConversionRateMetrics,
                type: 'percentage',
                chart: props.checkoutScreenConversionRateChart,
            },
        ]
    }

    return (
        <Row style={{ marginTop: 10, position: 'relative' }}>
            {loading && <ChartDataLoader />}
            {allItems().map((item, index) => {
                const axisData = item.chart.props().xAxisData
                const series = item.chart.props().series
                const lastItem = index < allItems().length - 1
                const value =
                    item.type === 'number' ? `${Number(item.data.value).toFixed(2)}s` : `${toPercent(item.data.value)}%`
                return (
                    <Col span={3} key={index}>
                        <Container withBorder={lastItem} className="test">
                            <NoDataNotice
                                dataLength={series[0]?.data?.length || 0}
                                loading={loading}
                                style={{ marginTop: '-0.5em' }}
                            >
                                <DataNumber
                                    key={item.key}
                                    title={infotipContentMap[item.key].pageName}
                                    value={value}
                                    comparisonItem={`${getPlusSign(item.data.comparison)}${
                                        item.data.comparison
                                    } ${getComparisonText()}`}
                                    comparisonItemColor={getComparisonColorFromPercentString(item.data.comparison)}
                                    infotipText={formatInfotipText(item.key, item.data.comparison)}
                                    metricInfotipText={infotipContentMap[item.key].metricText}
                                    tooltipType={getComparisonText()}
                                    accountSlug={props.accountSlug}
                                    compact
                                    id={item.key}
                                    nthChildStyle="margin-right: -3.5em"
                                    infotipPosition={lastItem ? 'left' : 'right'}
                                />
                                <MiniChart
                                    axisData={axisData}
                                    series={series}
                                    chartHeight={50}
                                    loading={loading}
                                    tooltipFormatter={(params) => chartTooltipFormatter(params, item.type)}
                                />
                            </NoDataNotice>
                        </Container>
                    </Col>
                )
            })}
        </Row>
    )
}

export default withNavigation(Chart)
