import { Field, FieldProps } from 'formik'
import { useGetFullProductsAndArticlesListForSelect } from 'products/redux'
import React, { useMemo } from 'react'
import { Col, Row } from 'uiComponents/flex'
import ComboboxFieldFormik from 'uiComponents/input/combobox/comboboxFieldFormik'
import NumberInputFieldFormik from 'uiComponents/input/numberInput/numberInputFieldFormik'

const ProductSection = () => {
    const articles = useGetFullProductsAndArticlesListForSelect()
    const formattedArticles = useMemo(() => {
        return articles.map((article) => ({
            ...article,
            disabled: article.type === 'category',
        }))
    }, [])

    return (
        <Field name="type">
            {({ field }: FieldProps) => {
                if (field.value === 'productId') {
                    return (
                        <Row style={{ paddingBottom: '1.5em', justifyContent: 'space-between' }}>
                            <Col span={9}>
                                <ComboboxFieldFormik
                                    nested
                                    multiple
                                    options={formattedArticles}
                                    name="products.uuids"
                                    label="Product"
                                />
                            </Col>
                            <Col span="auto">
                                <NumberInputFieldFormik
                                    style={{ width: '92px', textAlign: 'right' }}
                                    name="products.amount"
                                    label="Amount"
                                    placeholder=""
                                />
                            </Col>
                        </Row>
                    )
                }

                return null
            }}
        </Field>
    )
}

export default ProductSection
