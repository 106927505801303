import React, { useState, useEffect, useContext, useRef } from 'react'
import { withTheme } from 'styled-typed'
import { DashboardTheme } from 'theme'
import { StatsServiceContext } from 'http/context'
import TimeseriesBarChart from 'uiComponents/charts/timeseriesBarChart'
import { format } from 'date-fns'
import {
    AxisTitle,
    getTooltipHeader,
    getTooltipFooter,
    getTooltipRow,
    areDateRangeDatesEqual,
    mapTimeseriesForTooltip,
    getTooltipXAxisLabel,
} from 'reports/helpers'
import { XAxisData } from 'uiComponents/charts/timeseriesBarChartSource'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import { ChartHeadline } from 'uiComponents/charts/styleComponents'
import Infotip from 'uiComponents/infotip'
import { QueryConfig } from 'reports/queryGenerator'
import { DataPoint, TimeSeries } from 'reports/schema'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { withFeatures } from 'features'
import { DateRange } from 'dateRanges'
import { usePrevious } from 'reactUtils'
import { TimeseriesBarChartSource } from './conversionTrendChartSource'
import { isEqual } from 'lodash'
import { Filters } from 'uiComponents/filter/schema'
import { sortByScreenName, SreenType } from './utils'

const queryConfig: QueryConfig = {
    querySetName: 'FunnelReportLineChart',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'granularity', type: 'StatsGranularity' },
        { name: 'metric', type: 'StatsMetric' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'filters', type: '[FilterDictionary]' },
    ],
    queries: [
        {
            name: 'checkoutReachRatio',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'filters'],
            customVariables: [],
            presetResult: 'timeseries',
        },
        {
            name: 'checkoutReach',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'checkout_funnel_reach_summary' }],
            presetResult: 'timeseries',
        },
    ],
}

interface ChartsProps {
    theme: DashboardTheme
    navigation: Navigation
    match: RouteMatch<{}>
    accountSlug: string
    dateRange: DateRange
    chart: TimeseriesBarChartSource
    filters: Filters[]
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function ConversionTrendChart(props: ChartsProps) {
    const _isMounted = useRef(false)
    const _lastRequest = useRef<number>()
    const statsService = useContext(StatsServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [mappedTicketSelection, setMappedTicketSelection] = React.useState<DataPoint[]>([])
    const [mappedCartReview, setMappedCartReview] = React.useState<DataPoint[]>([])
    const [mappedConversion, setMappedConversion] = React.useState<DataPoint[]>([])

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    const prevFilters = usePrevious(props.filters)

    useEffect(() => {
        _isMounted.current = true
        if (
            prevAccountSlug !== props.accountSlug ||
            !areDateRangeDatesEqual(prevDateRange, props.dateRange) ||
            !isEqual(prevFilters, props.filters)
        ) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.dateRange, props.accountSlug])

    const getData = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        setLoading(true)
        if (props.filters.length === 0) {
            return
        }
        const dateFrom = format(props.dateRange.from, 'yyyy-MM-dd')
        const dateTo = format(props.dateRange.to, 'yyyy-MM-dd')
        try {
            const variables = {
                widget: props.accountSlug,
                metric: 'checkout_funnel_reach_ratio_summary',
                dateFrom,
                dateTo,
                granularity: 'day',
                filters: props.filters,
            }

            props.chart.clearSeries()
            const data = await statsService.getStats(queryConfig, variables)

            if (_lastRequest.current !== requestTime) {
                return
            }

            data.checkoutReachRatio.timeSeries.forEach((timeSerie) => {
                props.chart.formatLineSeries([timeSerie])
            })
            if (!!data.checkoutReach.timeSeries.length) {
                const xAxisCategories = props.chart.props().xAxisDataRaw
                setMappedData(xAxisCategories, data.checkoutReach.timeSeries)
            }
        } catch {
            props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Conversion trend chart could not be loaded, please try again later.',
            )
        }
        setLoading(false)
    }

    const setMappedData = (xAxisCategories: XAxisData[], timeSeries: TimeSeries[]) => {
        const ticketSelectionPoints = timeSeries.find((t) => t.label === SreenType.TICKET_SELECTION)?.points || []
        const cartReviewPoints = timeSeries.find((t) => t.label === SreenType.CART_REVIEW)?.points || []
        const conversionPoints = timeSeries.find((t) => t.label === SreenType.CONVERSION)?.points || []
        const mappedTicketSelectionData = mapTimeseriesForTooltip(xAxisCategories, ticketSelectionPoints, 'day')
        const mappedCartReviewData = mapTimeseriesForTooltip(xAxisCategories, cartReviewPoints, 'day')
        const mappedConversionData = mapTimeseriesForTooltip(xAxisCategories, conversionPoints, 'day')
        setMappedTicketSelection(mappedTicketSelectionData)
        setMappedCartReview(mappedCartReviewData)
        setMappedConversion(mappedConversionData)
    }

    const getMarker = (color: string) => {
        return `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color};"></span>`
    }

    const chartTooltipFormatter = (params: TooltipParams[]) => {
        const ticketSelectionPoint = mappedTicketSelection[params[0].dataIndex]
        const ticketSelectionData = ticketSelectionPoint?.value || '0'
        const cartReviewPoint = mappedCartReview[params[0].dataIndex]
        const cartReviewData = cartReviewPoint?.value || '0'
        const conversionPoint = mappedConversion[params[0].dataIndex]
        const conversionData = conversionPoint?.value || '0'

        const absoluteDataMap = {
            [SreenType.TICKET_SELECTION]: ticketSelectionData,
            [SreenType.CART_REVIEW]: cartReviewData,
            [SreenType.CONVERSION]: conversionData,
        }
        const xAxisLabel = ticketSelectionPoint
            ? getTooltipXAxisLabel(ticketSelectionPoint.timestamp, props.chart.granularity)
            : params[0].axisValue

        let tooltip = getTooltipHeader(xAxisLabel)
        params.forEach((item) => {
            tooltip += getTooltipRow([
                `${getMarker(item.color)} ${item.seriesName}: ${absoluteDataMap[item.seriesName]} visitors, ${Number(
                    item.data,
                ).toFixed(0)}%`,
            ])
        })

        tooltip += getTooltipFooter()
        return tooltip
    }

    const axisData = props.chart.props().xAxisData
    const series = props.chart.props().series.sort(sortByScreenName)
    const legendData = props.chart.props().legendData
    const colors = [...props.theme.colors.chartRotatingColors]
    colors.shift()

    return (
        <div style={{ position: 'relative' }}>
            {loading && <ChartDataLoader />}
            <ChartHeadline size={4}>
                <span>Checkout steps and conversion during time</span>
                <Infotip pointer="left" fixedMaxSize>
                    Here, you see the reach and conversion trend over your selected time range.
                </Infotip>
            </ChartHeadline>
            <AxisTitle title="Visitors %" left="0.2em" top="7.4em" />
            <AxisTitle title="Visit date" left="50%" bottom="-2em" style={{ transform: 'translateX(-50%)' }} />
            <TimeseriesBarChart
                axisData={axisData}
                series={series}
                legendWidth={525}
                legendLeft={55}
                loading={loading}
                tooltipFormatter={chartTooltipFormatter}
                height="380px"
                titleOffset={-3}
                legendData={legendData}
                customColors={colors}
                toolTipAxisPointer={{
                    type: 'line',
                    z: 0,
                    lineStyle: {
                        type: 'dashed',
                    },
                }}
            />
        </div>
    )
}

export default withFeatures(withNavigation(withTheme(ConversionTrendChart)))
