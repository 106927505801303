import React, { useState, useEffect, useContext, useRef } from 'react'
import { usePrevious } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { format } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { ChartHeadline, NoDataNotice } from 'uiComponents/charts/styleComponents'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import { QueryConfig } from 'reports/queryGenerator'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { DateRange } from 'dateRanges'
import { areDateRangeDatesEqual, getTooltipFooter, getTooltipRow, AxisTitle, getMarker } from 'reports/helpers'
import SankeyChart, { SerieLink, SerieData } from 'uiComponents/charts/sankeyChart'
import { Filters } from 'uiComponents/filter/schema'
import { find, isEqual } from 'lodash'
import { withTheme } from 'styled-typed'
import { DashboardTheme } from 'theme'
import Infotip from 'uiComponents/infotip'

const queryConfig: QueryConfig = {
    querySetName: 'CheckoutNavigations',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'metric', type: 'StatsMetric' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'filters', type: '[FilterDictionary]' },
    ],
    queries: [
        {
            name: 'stats',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'filters'],
            customVariables: [],
            presetResult: 'dataSeries',
        },
    ],
}

interface ChartsProps {
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    dateRange: DateRange
    filters: Filters[]
    theme: DashboardTheme
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

function Chart(props: ChartsProps) {
    const _isMounted = useRef(false)
    const _lastRequest = useRef<number>()
    const statsService = useContext(StatsServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [serieData, setSerieData] = useState<SerieData[]>([])
    const [serieLinks, setSerieLinks] = useState<SerieLink[]>([])

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    const prevFilters = usePrevious(props.filters)

    useEffect(() => {
        _isMounted.current = true
        if (
            prevAccountSlug !== props.accountSlug ||
            !areDateRangeDatesEqual(prevDateRange, props.dateRange) ||
            !isEqual(prevFilters, props.filters)
        ) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug, props.dateRange, props.filters])

    const getUniqueColumNames = (data: (string | number)[] | (string | number)[][]) => {
        const array: SerieData[] = []

        data.forEach((item: (string | number) | (string | number)[]) => {
            if (!find(array, { name: item[0] })) {
                array.push({
                    name: item[0],
                    label: item[2],
                })
            }

            if (!find(array, { name: item[1] })) {
                array.push({
                    name: item[1],
                    label: item[3],
                })
            }
        })
        return array
    }

    const getSerieLinks = (data: (string | number)[] | (string | number)[][]) =>
        data.map((item: (string | number) | (string | number)[]) => {
            return {
                source: item[0],
                sourceName: item[2],
                target: item[1],
                targetName: item[3],
                value: item[4],
                emphasis: {
                    lineStyle: { opacity: 0.3 },
                },
            }
        })

    const getData = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime
        setLoading(true)
        if (props.filters.length === 0) {
            return
        }

        const dateFrom = format(props.dateRange.from || new Date(), 'yyyy-MM-dd')
        const dateTo = format(props.dateRange.to || new Date(), 'yyyy-MM-dd')
        try {
            const variables = {
                widget: props.accountSlug,
                metric: 'checkout_funnel_page_navigations',
                dateFrom,
                dateTo,
                filters: props.filters,
            }

            const data = await statsService.getStats(queryConfig, variables)

            if (_lastRequest.current !== requestTime) {
                return
            }

            const stats = data.stats.dataSeries || []

            setSerieData(getUniqueColumNames(stats.data))
            setSerieLinks(getSerieLinks(stats.data))

            if (_isMounted.current) {
                setLoading(false)
            }
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Screens overview chart could not be loaded, please try again later.',
            )
            setLoading(false)
            setSerieData([])
        }
    }

    const chartTooltipFormatter = (params: TooltipParams) => {
        let tooltip = ''
        if (params.dataType === 'node') {
            tooltip += getTooltipRow([
                `${getMarker(params.color)} ${nameForTooltip(params.data.label)}: ${params.value}`,
            ])
        } else if (params.dataType === 'edge') {
            tooltip += getTooltipRow([
                `${Number(params.data.value).toFixed(0)} visitors: ${nameForTooltip(
                    params.data.sourceName,
                )} <b>&rarr;</b> ${nameForTooltip(params.data.targetName)}`,
            ])
        } else {
            return ''
        }

        tooltip += getTooltipFooter()
        return tooltip
    }

    const nameForTooltip = (text: string) => {
        const parts = text.split('\n')
        return parts[1] ? `<b>${parts[0]}</b> <span style="font-size: 12px;">${parts[1]}</span>` : `<b>${parts[0]}</b>`
    }

    const labelFormatter = (params: any) => {
        const parts = params.data.label.split('\n')
        return parts[1] ? `{a|${parts[0]}}\n${parts[1]}` : `{a|${parts[0]}}`
    }

    const colors = ['90', '70', '50', '40', '20', '10'].map((shade) => props.theme.colors.boyBlueShades[shade])
    const levels = colors.map((color, index) => {
        return {
            depth: index,
            itemStyle: {
                color: color,
            },
            lineStyle: {
                color: 'source',
                opacity: 0.15,
                curveness: 0.75,
            },
        }
    })

    const series = [
        {
            top: '10%',
            height: '80%',
            left: 5,
            right: 180,
            nodeGap: 10,
            nodeWidth: 22,
            name: 'screen_overview',
            type: 'sankey',
            layout: 'none',
            layoutIterations: 8,
            draggable: false,
            data: serieData.map((dataItem, index) => {
                return {
                    ...dataItem,
                    itemStyle: {
                        color: dataItem.name.toLowerCase().includes('exit') ? props.theme.colors.sunrise : undefined,
                    },
                }
            }),
            links: serieLinks,
            label: {
                formatter: labelFormatter,
                fontFamily: 'Roboto',
                width: 180,
                overflow: 'break',
                rich: {
                    a: {
                        fontWeight: 'bold',
                    },
                    b: {
                        fontWeight: 'regular',
                    },
                },
            },
            labelLayout: {
                hideOverlap: true,
            },
            itemStyle: {
                borderWidth: 0,
            },
            levels,
        },
    ]

    return (
        <NoDataNotice dataLength={serieData.length} loading={loading}>
            <>
                {loading && <ChartDataLoader />}
                <ChartHeadline size={4}>
                    <span>Visitor behaviour flow</span>
                    <Infotip pointer="left" fixedMaxSize maxWidth="40em">
                        Understand your visitors’ journey across your pages. Please select a page to view where people
                        came from before visiting it and where they went next.
                    </Infotip>
                </ChartHeadline>
                <AxisTitle title="Coming from" left="0.5em" top="4.2em" className="sankey-chart" />
                <AxisTitle title="Selected screen" right="54.4em" top="4.2em" className="sankey-chart" />
                <AxisTitle title="Going to" right="16.2em" top="4.2em" className="sankey-chart" />
                <SankeyChart
                    series={series}
                    chartHeight="450px"
                    loading={loading}
                    tooltipFormatter={chartTooltipFormatter}
                    colors={colors}
                />
            </>
        </NoDataNotice>
    )
}

export default withTheme(withNavigation(Chart))
