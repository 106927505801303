import * as React from 'react'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { UseDateInput, UseFormInput, VenueLocation } from '../schema'
import { datePickerComponent, renderSelectComponent } from './components'
import { SelectField } from 'uiComponents/studio/form/fieldComponentSchema'

interface Props {
    startDate: UseDateInput
    endDate: UseDateInput
    active: UseFormInput
    venueLocations: VenueLocation[]
    selectedVenueLocation: UseFormInput
    hasPlacementLocationFeature: boolean
}

const ScheduleSection = ({
    startDate,
    endDate,
    active,
    venueLocations,
    selectedVenueLocation,
    hasPlacementLocationFeature,
}: Props) => {
    const venueLocationOptions = React.useMemo(() => {
        const mappedLocations = [
            {
                name: 'All available locations',
                value: '',
            },
        ].concat(venueLocations)

        return mappedLocations
    }, [venueLocations])

    const endDateField: SelectField = {
        name: 'end-date',
        type: 'select',
        label: 'End date',
        path: '',
        defaultValue: 'never',
        availableOptions: [
            { name: 'Never', value: 'never' },
            { name: 'Date', value: 'date' },
        ],
        span: 6,
    }

    const startDateField: SelectField = {
        name: 'start-date',
        type: 'select',
        label: 'Start date',
        path: '',
        defaultValue: 'now',
        availableOptions: [
            { name: 'Now', value: 'now' },
            { name: 'Date', value: 'date' },
        ],
        span: 6,
    }
    const activeField: SelectField = {
        name: 'active',
        type: 'select',
        label: 'Active',
        path: '',
        defaultValue: 'ACTIVE',
        availableOptions: [
            { name: 'Active', value: 'ACTIVE' },
            { name: 'Inactive', value: 'INACTIVE' },
        ],
        span: 6,
    }

    const venueLocationField: SelectField = {
        name: 'venueLocation',
        type: 'select',
        label: 'Venue location',
        path: '',
        defaultValue: '',
        availableOptions: venueLocationOptions,
        span: 6,
    }

    return (
        <SectionWrapper title="Scheduling">
            {renderSelectComponent(startDateField, startDate)}
            {startDate.value === 'date' && datePickerComponent(startDate)}
            {renderSelectComponent(endDateField, endDate)}
            {endDate.value === 'date' && datePickerComponent(endDate)}
            {renderSelectComponent(activeField, active)}
            {venueLocations.length > 1 &&
                hasPlacementLocationFeature &&
                renderSelectComponent(venueLocationField, selectedVenueLocation, 'All available locations')}
        </SectionWrapper>
    )
}

export default ScheduleSection
