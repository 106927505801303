import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { BaseRouteParams } from '../../hocs'
import ActionButton, { ActionButtonA } from '../../uiComponents/buttons'
import { FormWrapper } from '../../uiComponents/form/form'
import ActionButtons from '../components/actionButtons'
import ProgressBar from '../components/progressBar'
import { Buttons } from '../pages/schedule'
import { PNFrequency } from '../types'
import { LeftContainer } from './content'
import ScheduleTimeForm from './scheduleTime'
import { Container, ProgressBarContainer, ProgressWrapper } from './target'

export const Label = styled.div`
    font-size: 0.875rem;
    margin: 0.5em 0;
`

export const Field = styled.div`
    margin-bottom: 1em;
`

export const Col = styled.div`
    flex: 1;
`

const FormItemWithBackground = styled(FormItem)`
    background-color: ${(props) => props.theme.colors.background};
    border-radius: 5px;
    padding: 2.5em 2em;
    display: block;
`

const ItemName = styled.div`
    width: 100%;
    font-size: 1.8em;
    font-weight: 500;
    color: ${(props) => props.theme.colors.textDark};
    padding-bottom: 1em;
`

const OptionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1em 0;
    cursor: pointer;
`

const OptionText = styled.div`
    font-size: 1.4em;
    line-height: 1.5em;
`
const OptionDesc = styled.div`
    font-size: 1.2em;
    font-weight: 300;
    width: 100%;
    padding: 0.5em 0 0 0;
`

const DetailsForms = styled.div`
    margin-top: 3em;
`

interface FormProps {
    data?: any
    onSubmit: (values: any) => void
}

const ScheduleForm = ({ data, onSubmit }: FormProps) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const [startDate, setStartDate] = useState<string | null>(data.startDate || null)
    const [startTime, setStartTime] = useState<string>(data.startTime || '12:00')
    const [endDate, setEndDate] = useState<string | null>(data.endDate || null)
    const [endTime, setEndTime] = useState<string>(data.endTime || '12:00')
    const [isRecurring, setIsRecurring] = useState<boolean>(data.recurring || false)
    const [frequency, setFrequency] = useState<PNFrequency>(data.frequency || PNFrequency.once)
    const [validated, setValidated] = useState<boolean>(false)
    const progressBarItems = [
        {
            name: 'Target audience',
            done: true,
        },
        {
            name: 'Edit content',
            done: true,
        },
        {
            name: 'Schedule and publish',
            active: true,
        },
    ]

    return (
        <Container>
            <FormWrapper
                enableReinitialize
                initialValues={data}
                onSubmit={(values) => {
                    if (
                        !(startDate && startTime) ||
                        (isRecurring && !(endDate && endTime)) ||
                        (isRecurring && !frequency) ||
                        (isRecurring && frequency === PNFrequency.once) ||
                        (isRecurring && startDate && endDate && endDate < startDate)
                    ) {
                        return
                    }

                    const formValues = {
                        ...values,
                        startDate,
                        startTime,
                        endDate,
                        endTime: isRecurring ? endTime : undefined,
                        recurring: isRecurring,
                        frequency: isRecurring ? frequency : PNFrequency.once,
                    }

                    onSubmit(formValues)
                }}
                validate={() => {
                    setValidated(true)
                }}
            >
                <LeftContainer>
                    <ProgressWrapper>
                        <ProgressBarContainer>
                            <ProgressBar items={progressBarItems} />
                        </ProgressBarContainer>
                        <Buttons>
                            <ActionButtonA
                                size="large"
                                secondary
                                href={`/account/${accountSlug}/engage/push_notifications/content`}
                            >
                                Back
                            </ActionButtonA>
                            <ActionButton size="large" kind="action" disabled={true}>
                                Next
                            </ActionButton>
                        </Buttons>
                    </ProgressWrapper>
                    <FormItemWithBackground>
                        <ItemName>Schedule based on: </ItemName>
                        {/* <OptionWrapper onClick={() => setType(typeType.EVENT)}>*/}
                        {/*    <Radio*/}
                        {/*        id="type"*/}
                        {/*        name="type"*/}
                        {/*        value="event"*/}
                        {/*        onChange={() => {}}*/}
                        {/*        checked={type === typeType.EVENT}*/}
                        {/*    />*/}
                        {/*    <OptionText>Event</OptionText>*/}
                        {/*    <OptionDesc>*/}
                        {/*        Time sensitive notifications that use users action as a starting point.*/}
                        {/*    </OptionDesc>*/}
                        {/* </OptionWrapper>*/}
                        <OptionWrapper>
                            {/* <Radio*/}
                            {/*    id="type"*/}
                            {/*    name="type"*/}
                            {/*    value="time"*/}
                            {/*    onChange={() => {}}*/}
                            {/*    checked={type === typeType.TIME}*/}
                            {/*/ >*/}
                            <OptionText>Time</OptionText>
                            <OptionDesc>Notifications based on fixed dates for recurring periods</OptionDesc>
                        </OptionWrapper>
                    </FormItemWithBackground>
                    <DetailsForms>
                        {/* {type === typeType.EVENT ? (*/}
                        {/*    <ScheduleEventForm*/}
                        {/*        time={time}*/}
                        {/*        setTime={setTime}*/}
                        {/*        startDate={startDate}*/}
                        {/*        setStartDate={setStartDate}*/}
                        {/*        startTime={startTime}*/}
                        {/*        setStartTime={setStartTime}*/}
                        {/*        endDate={endDate}*/}
                        {/*        setEndDate={setEndDate}*/}
                        {/*        endTime={endTime}*/}
                        {/*        setEndTime={setEndTime}*/}
                        {/*        limited={limited}*/}
                        {/*        setLimited={setLimited}*/}
                        {/*    />*/}
                        {/* ) : (*/}
                        <ScheduleTimeForm
                            startDate={startDate}
                            setStartDate={setStartDate}
                            startTime={startTime}
                            setStartTime={setStartTime}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            endTime={endTime}
                            setEndTime={setEndTime}
                            isRecurring={isRecurring}
                            setIsRecurring={setIsRecurring}
                            validated={validated}
                            frequency={frequency}
                            setFrequency={setFrequency}
                        />
                        {/* )}*/}
                    </DetailsForms>
                </LeftContainer>
                <ActionButtons canPublish={true} />
            </FormWrapper>
        </Container>
    )
}

export default ScheduleForm
