import React, { useEffect, useState, useRef } from 'react'
import styled, { css } from 'styled-typed'
import { Row } from 'uiComponents/flex'
import { NumberInput } from 'uiComponents/input'
import { InfoBox } from 'uiComponents/infotip'

const trackCss = css`
    width: 100%;
    height: 8px;
    cursor: pointer;
    box-shadow: none;
    border-radius: 10px;
    border: 0px solid #010101;
`

const thumbCss = css`
    box-shadow: none;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    height: 22px;
    width: 22px;
    border-radius: 22px;
    border: 2px solid white;
    cursor: pointer;
    -webkit-appearance: none;
`

interface RangeProps {
    width: string | number
    vertical?: boolean
}

const ValueBox = styled.div<RangeProps>`
    margin-top: 0.5em;
    width: ${(p) => p.width};
    display: flex;
    justify-content: flex-end;
    font-size: 0.8rem;
    color: ${(p) => p.theme.colors.boyBlue};
`

const VerticalSliderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 1.2rem;
    border-radius: 0.375em;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
`

const ValueInput = styled(NumberInput)`
    margin-left: 1em;
    border: 1px solid ${(props) => props.theme.colors.border};
`

interface VerticalWrapperProps {
    height: string
}

const Wrapper = styled.div<VerticalWrapperProps>`
    display: inline-block;
    height: ${(p) => p.height};
    padding: 0;
    padding-left: 6px;
    width: 1rem;

    @-moz-document url-prefix() {
        padding-left: 0;
    }
`

interface WrapperProps {
    width: string
}
const SliderWrapper = styled.div<WrapperProps>`
    display: inline-block;
    width: ${(p) => p.width};
`

interface RangeInputProps {
    width: number
    status: 'success' | 'normal'
    vertical?: boolean
    striped?: boolean
    head?: boolean
    midhead?: boolean
    tail?: boolean
    midtail?: boolean
}
const RangeInput = styled.input<RangeInputProps>`
    width: ${(p) => p.width}rem;
    background-color: transparent;
    -webkit-appearance: none;

    &:focus {
        outline: none;
    }
    &::-webkit-slider-runnable-track {
        ${(p) =>
            p.striped
                ? `background: repeating-linear-gradient(
          to right,
          ${p.theme.colors.boyBlue},
          ${p.theme.colors.boyBlue} ${p.width / 4}em,
          #fff ${p.width / 4}em,
          #fff ${p.width / 4 + 0.05}em
      );`
                : `background: ${p.theme.colors.boyBlue};`}
        ${trackCss}
    }
    &::-moz-range-track {
        ${(p) =>
            p.striped
                ? `background: repeating-linear-gradient(
          to right,
          ${p.theme.colors.boyBlue},
          ${p.theme.colors.boyBlue} ${p.width / 4}em,
          #fff ${p.width / 4}em,
          #fff ${p.width / 4 + 0.05}em
      );`
                : `background: ${p.theme.colors.boyBlue};`}
        ${trackCss}
    }

    &::-webkit-slider-thumb {
        background: ${(p) => p.theme.colors.boyBlue};
        ${thumbCss}
        ${(p) =>
            p.vertical
                ? ` ${p.head && 'margin-left: -.5em;'};
        ${p.tail && 'margin-left: .5em;'};
        ${p.midhead && 'margin-left: -.35em;'};
        ${p.midtail && 'margin-left: .35em;'};
      `
                : ` ${p.head && 'margin-left: -.5em;'};
        ${p.tail && 'margin-left: .5em;'};
        ${p.midhead && 'margin-left: -.35em;'};
        ${p.midtail && 'margin-left: .35em;'};`}
        margin-top: -7px;
    }
    &::-moz-range-thumb {
        background: ${(p) => p.theme.colors.boyBlue};
        ${thumbCss}
    }
    &::-moz-focus-outer {
        border: 0;
    }
    margin: 0;
    ${(p) =>
        p.vertical &&
        `
    transform-origin: ${p.width / 2}rem ${p.width / 2}rem;
    transform: rotate(-90deg) ;
  `}
`

const Infotip = styled(InfoBox)`
    position: relative;
    display: flex;
    align-items: center;
    font-size: 0.75em;
    top: 0;
    bottom: inherit;
    right: 4em;
    min-width: 2.7em;
    padding: 0.4em 0.8em;
    opacity: 0;
    transition: opacity 0.2s;
    z-index: 1;

    &.visible {
        opacity: 1;
    }
    &:before {
        content: '';
        display: block;
        position: absolute;
        margin-left: 23px;
        top: 9px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-bottom: 6px solid transparent;
        border-top: 6px solid transparent;
        border-left: 6px solid ${(props) => props.theme.colors.textDark};
        border-right: 6px solid transparent;
    }
`

interface PointerProps {
    value?: string | null
    vertical?: boolean
}

const marginTopMap = {
    1: '9.7rem',
    2: '7.1rem',
    3: '4.4rem',
    4: '1.5rem',
    5: '-1rem',
}

const marginLeftMap = {
    1: '-0.3rem',
    2: '2.4rem',
    3: '5.2rem',
    4: '8rem',
    5: '10.5rem',
}

const DefaultPointer = styled.div<PointerProps>`
    ${(p) => !p.vertical && 'margin-bottom: -0.6em;'}
    visibility: hidden;
    &:before {
        content: '';
        display: block;
        ${(p) =>
            p.vertical
                ? `margin-left: 4px;
       border-bottom: 6px solid transparent;
       border-top: 6px solid transparent;
       border-left: 6px solid transparent;
       border-right: 6px solid #7DCACF;
      `
                : `margin-top: 0;
       border-bottom: 6px solid transparent;
       border-top: 6px solid #7DCACF;
       border-left: 6px solid transparent;
       border-right: 6px solid transparent;
       `}
        width: 0;
        height: 0;
    }
    ${(p) =>
        p.value &&
        `
    visibility: visible;
    &:before {
      ${p.vertical ? `margin-top: ${marginTopMap[p.value]};` : `margin-left: ${marginLeftMap[p.value]};`}
    }`}
`

const Label = styled.div`
    width: 5em;
    color: ${(p) => p.theme.colors.textLight};
    padding-right: 0;
    padding-left: 1.5em;
    font-size: 0.75rem;
    padding-top: 0.25rem;
    &.start {
        text-align: end;
        padding-right: 1.5em;
        padding-left: 0;
    }
`

const MetricLabel = styled.div`
    font-size: 0.75rem;
    color: ${(p) => p.theme.colors.textLight};
    margin-top: 1.8em;
`

const VerticalLabels = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    height: 100%;
    > ${Label} {
        padding-left: 0.5rem;
        width: auto;
    }
`

const WrapperRow = styled(Row)`
    align-items: center;
`

interface SliderProps {
    value: string
    onChange: (v: string) => void
    step?: number
    min?: number
    max?: number
    stretch?: number
    labels?: string[]
    showLabels?: boolean
    steps?: { value: number; name: string }[]
    vertical?: boolean
    showCurrentValue?: boolean
    status?: 'success' | 'normal'
    metric?: string
    striped?: boolean
    id?: string
    defaultValue?: string | null
}

export function Slider(props: SliderProps) {
    const {
        min,
        max,
        step,
        stretch,
        labels,
        vertical,
        showCurrentValue,
        steps,
        status,
        showLabels,
        metric,
        striped,
        defaultValue,
    } = props
    const inputRef = useRef(null)

    const MAX_VALUE = typeof max !== 'undefined' ? max : 200
    const sliderStretch = stretch || 11
    const minimum = !!steps && steps.length > 1 ? steps[0].value : min ? min : 0
    const maximum = !!steps && steps.length > 1 ? steps[steps.length - 1].value : MAX_VALUE
    const labelFrom = !!labels && labels.length > 1 ? labels[0] : !!steps && steps.length > 1 ? steps[0].name : minimum
    const labelTo =
        !!labels && labels.length > 1 ? labels[1] : !!steps && steps.length > 1 ? steps[steps.length - 1].name : maximum
    const [currentStepName, setCurrentStepName] = useState<string>('')
    const [infotipVisible, setInfotipVisible] = useState<boolean>(false)

    useEffect(() => {
        let name = props.value
        if (!!steps && steps.length > 1) {
            const foundStep = steps.find((s) => s.value === +props.value)
            if (foundStep) {
                name = foundStep.name
            }
        }
        setCurrentStepName(name)
    }, [props.value])

    const head = striped && +props.value === minimum
    const tail = striped && +props.value === maximum
    const midhead = striped && minimum === 1 && maximum === 5 && +props.value === 2
    const midtail = striped && minimum === 1 && maximum === 5 && +props.value === 4
    return (
        <Row>
            {vertical ? (
                <VerticalSliderWrapper>
                    <Row>
                        <Wrapper height={`${sliderStretch}rem`}>
                            <RangeInput
                                id={props.id}
                                width={sliderStretch}
                                type="range"
                                value={props.value}
                                min={minimum}
                                max={maximum}
                                step={step || 1}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value)}
                                vertical
                                status={status || 'normal'}
                                striped={striped}
                                ref={inputRef}
                                onMouseOver={() => setInfotipVisible(true)}
                                onMouseOut={() => setInfotipVisible(false)}
                                head={head}
                                tail={tail}
                                midhead={midhead}
                                midtail={midtail}
                            />
                            {showCurrentValue && (
                                <Infotip className={infotipVisible ? 'visible' : ''}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flex: '1',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {props.value}
                                    </div>
                                </Infotip>
                            )}
                            <DefaultPointer value={defaultValue} vertical />
                        </Wrapper>
                        {!!showLabels && (
                            <VerticalLabels>
                                <Label>{labelFrom}</Label>
                                <Label>{labelTo}</Label>
                            </VerticalLabels>
                        )}
                    </Row>
                    {metric && <MetricLabel>{metric}</MetricLabel>}
                </VerticalSliderWrapper>
            ) : (
                <>
                    {!!showLabels && <Label className="start">{labelFrom}</Label>}
                    <SliderWrapper width={`${sliderStretch}rem`}>
                        <DefaultPointer value={defaultValue} />
                        <RangeInput
                            id={props.id}
                            width={sliderStretch}
                            type="range"
                            value={props.value}
                            min={minimum}
                            max={maximum}
                            step={step || 1}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value)}
                            status={status || 'normal'}
                            striped={striped}
                            head={head}
                            tail={tail}
                            midhead={midhead}
                            midtail={midtail}
                        />
                        {showCurrentValue && (
                            <ValueBox width={`${sliderStretch}rem`}>
                                <span>{currentStepName}</span>
                            </ValueBox>
                        )}
                    </SliderWrapper>
                    {(!!labels || !!steps) && <Label>{labelTo}</Label>}
                </>
            )}
        </Row>
    )
}

interface SliderWithInputProps {
    value: string
    onChange: (v: string) => void
    step?: number
    min?: number
    max?: number
    stretch?: number
    status?: 'success' | 'normal'
    striped?: boolean
}
export function SliderWithInput(props: SliderWithInputProps) {
    const { min, max, step, stretch, status, striped } = props
    const MAX_VALUE = typeof max !== 'undefined' ? max : 200
    const sliderStretch = stretch || 10
    const minimum = min || 0

    return (
        <WrapperRow>
            <SliderWrapper width={`${sliderStretch}rem`}>
                <RangeInput
                    width={sliderStretch}
                    type="range"
                    value={props.value}
                    min={minimum}
                    max={MAX_VALUE}
                    step={step || 1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value)}
                    status={status || 'normal'}
                    striped={striped}
                />
            </SliderWrapper>
            <ValueInput
                value={props.value}
                min={minimum}
                max={MAX_VALUE}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value)}
                integerOnly
            />
        </WrapperRow>
    )
}
