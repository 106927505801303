import * as React from 'react'
import styled from 'styled-typed'
import { maskPersonalData } from 'utils'
import { ActionIcon } from 'uiComponents/buttons/actionIcon'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'

const MaskComponentContainer = styled.span`
    display: flex;
    align-items: center;
`

const Text = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

interface MaskedDataProps {
    value: string
    mask: boolean
}

export function MaskedData(props: MaskedDataProps) {
    const [unmasked, setUnmasked] = React.useState<boolean>(!props.mask)

    React.useEffect(() => {
        setUnmasked(!props.mask)
    }, [props.mask])

    return (
        <MaskComponentContainer>
            <Text>{!unmasked && !!props.value ? maskPersonalData(props.value) : props.value}</Text>
            {!!props.value && unmasked && (
                <ActionIcon
                    onClick={() => setUnmasked(false)}
                    title="Hide details"
                    className="first-icon"
                    icon={faEyeSlash}
                />
            )}
            {!!props.value && !unmasked && (
                <ActionIcon
                    onClick={() => setUnmasked(true)}
                    title="Show details"
                    className="first-icon"
                    icon={faEye}
                />
            )}
        </MaskComponentContainer>
    )
}
