import * as React from 'react'
import styled from 'styled-typed'
import { ChannelsServiceContext } from 'channels/context'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { RetailerResponseItem, CreateNewType } from 'channels/channelsService'
import { InlineDataTable, HeaderRow, TableHeader, Cell, DynamicRow, ExpandCell } from 'uiComponents/table'
import { TableLoader, ChartDataLoader } from 'uiComponents/loaders'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { InlineEdit } from 'uiComponents/input'
import { delay } from 'utils'
import { AddRetailerDialog } from 'channels/addRetailerDialog'
import { AddNewPoolDialog } from 'venue/bookingCodes/reservationCodePools/addNewPoolDialog'
import { useDispatch } from 'react-redux'
import { addMessage, removeAllMessages } from 'uiComponents/messages/actions'
import { DisabledItemHoverInfotip } from 'uiComponents/infotip'
import { faFileTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome'
import { NestedContainerWrapper } from 'uiComponents/table/nestedContainer'
import { NestedTableRow, NestedTable } from 'uiComponents/table/nestedContainer'
import { ActionButtonA } from 'uiComponents/buttons'
import { BookingCodesService } from 'venue/bookingCodes/bookingCodesService'

const Icon = styled(FontAwesomeIcon)`
    font-size: 1.2em;
    opacity: 0.5;
    color: ${(props) => props.theme.colors.status.error};
`

interface RetailersPageProps {
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    creatingNew: CreateNewType
    search?: string
    bookingCodesService: BookingCodesService
    showAddCampaignButton: (value: boolean) => void
    onSectionChange: (section: string) => void
    resetCreatingMode: () => void
}

function RetailersPage(props: RetailersPageProps) {
    const channelsService = React.useContext(ChannelsServiceContext)
    const dispatch = useDispatch()

    const [loading, setLoading] = React.useState<boolean>(true)
    const [updating, setUpdating] = React.useState<boolean>(false)
    const [showErrors, setShowErrors] = React.useState<boolean>(false)
    const [retailersList, setRetailersList] = React.useState<RetailerResponseItem[]>([])
    const [currentExpanded, setCurrentExpanded] = React.useState<string>('')

    React.useEffect(() => {
        props.onSectionChange('retailers')
        !!retailersList.length ? getListData('update') : getListData('load')
    }, [props.accountSlug, props.search])

    const updateLoaders = (action: 'load' | 'update' | null, state: boolean) => {
        if (action === 'load') {
            setLoading(state)
        } else if (action === 'update') {
            setUpdating(state)
        }
    }

    async function getListData(action: 'load' | 'update' | null = null) {
        updateLoaders(action, true)
        try {
            const query = props.search ? `?search=${props.search}` : ''
            const data = await channelsService.getRetailersList(props.accountSlug, query)
            setRetailersList(data)
            props.showAddCampaignButton(!!data.length)
            updateLoaders(action, false)
        } catch {
            updateLoaders(action, false)
            dispatch(addMessage('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.'))
        }
    }

    const onSaveNewRetailer = async (name: string) => {
        if (!name) {
            setShowErrors(true)
            return
        }
        updateLoaders('update', true)
        try {
            await channelsService.createRetailer(props.accountSlug, name)
            await getListData('update')
            props.resetCreatingMode()
            dispatch(addMessage('success', 'success', `Retailer ${name} was created successfully.`))
            await delay(5000)
            dispatch(removeAllMessages())
        } catch {
            props.resetCreatingMode()
            dispatch(
                addMessage(
                    'unknown_error',
                    'error',
                    'Oops! We could not create a new retailer. Please try again later.',
                ),
            )
        } finally {
            updateLoaders('update', false)
        }
    }

    const onSaveNewCampaign = async (name: string, retailer: string, isScannable: boolean) => {
        try {
            await props.bookingCodesService.createReservationCodesPool(props.accountSlug, name, retailer, isScannable)
            await getListData('update')
            props.resetCreatingMode()
            updateLoaders('update', false)
            dispatch(addMessage('success', 'success', `Campaign ${name} was created successfully.`))
            await delay(5000)
            dispatch(removeAllMessages())
        } catch {
            props.resetCreatingMode()
            dispatch(
                addMessage(
                    'unknown_error',
                    'error',
                    'Oops! We could not create a new campaign. Please try again later.',
                ),
            )
        } finally {
            updateLoaders('update', false)
        }
    }

    const onNameEditAccept = async (resellerId: string, name: string) => {
        updateLoaders('update', true)
        try {
            await channelsService.updateRetailer(props.accountSlug, name, resellerId)
            await getListData('update')
        } catch {
            dispatch(
                addMessage('unknown_error', 'error', 'Oops! We could not update the name. Please try again later.'),
            )
        } finally {
            updateLoaders('update', false)
        }
    }

    const toggleExpanded = (id: string) => {
        const expanded = currentExpanded === id
        expanded ? setCurrentExpanded('') : setCurrentExpanded(id)
        props.navigation.addQueryWithReplace({ poolId: expanded ? null : id })
    }

    const columnWidths = ['3.5em', null]

    return (
        <>
            {props.creatingNew === ('retailers' as CreateNewType) && (
                <AddRetailerDialog
                    accountSlug={props.accountSlug}
                    showErrors={showErrors}
                    loading={updating}
                    onCancel={() => props.resetCreatingMode()}
                    onConfirm={onSaveNewRetailer}
                />
            )}
            {props.creatingNew === ('campaign' as CreateNewType) && (
                <AddNewPoolDialog
                    accountSlug={props.accountSlug}
                    loading={updating}
                    onCancel={() => props.resetCreatingMode()}
                    onConfirm={onSaveNewCampaign}
                    retailerOptions={retailersList.map((r) => ({
                        value: r.id,
                        name: r.name,
                    }))}
                />
            )}
            {loading && <TableLoader />}
            {!loading && (
                <>
                    <InlineDataTable columnWidths={columnWidths} bordered id="retailers-list">
                        <HeaderRow>
                            <TableHeader nonInteractive noPadding />
                            <TableHeader>Name</TableHeader>
                        </HeaderRow>
                        {updating && !loading && <ChartDataLoader />}
                        {retailersList.length === 0 && <NoResultsRow text="There are no retailers yet." />}
                        {retailersList.length > 0 &&
                            retailersList.map((item, i) => {
                                const expanded = item.id === currentExpanded
                                const hasCampaigns = !!item.campaigns && !!item.campaigns.length
                                return (
                                    <div key={item.id}>
                                        <DynamicRow
                                            interactive={hasCampaigns}
                                            highlighted={expanded}
                                            onClick={() => (hasCampaigns ? toggleExpanded(item.id) : () => {})}
                                            className={expanded ? 'expanded' : ''}
                                        >
                                            {hasCampaigns ? (
                                                <ExpandCell className="expand" expanded={expanded} />
                                            ) : (
                                                <Cell align="center" style={{ overflow: 'visible' }}>
                                                    <DisabledItemHoverInfotip
                                                        active
                                                        infotipText="No campaigns created yet"
                                                        offsetLeft="-1em"
                                                        width="14em"
                                                    >
                                                        <Icon icon={faFileTimes as IconProp} />
                                                    </DisabledItemHoverInfotip>
                                                </Cell>
                                            )}
                                            <Cell title={item.name}>
                                                <InlineEdit
                                                    id="retailer-name"
                                                    value={item.name}
                                                    maxLength={100}
                                                    onEditAccept={(v) => onNameEditAccept(item.id, v)}
                                                    responsiveWidth
                                                />
                                            </Cell>
                                        </DynamicRow>
                                        {expanded && (
                                            <NestedContainerWrapper grade={0} className="expanded">
                                                <NestedTable columnWidths={['30em', null, '10em']} id="campaigns-list">
                                                    <HeaderRow style={{ paddingLeft: '3rem' }}>
                                                        <TableHeader>Campaign name</TableHeader>
                                                        <TableHeader nonInteractive />
                                                        <TableHeader nonInteractive />
                                                    </HeaderRow>
                                                    {item.campaigns.map((c) => {
                                                        return (
                                                            <div key={c.id}>
                                                                <NestedTableRow style={{ paddingLeft: '3rem' }}>
                                                                    <Cell title={c.name}>{c.name}</Cell>
                                                                    <Cell />
                                                                    <Cell>
                                                                        <ActionButtonA
                                                                            kind="action"
                                                                            secondary
                                                                            size="small"
                                                                            href={`/account/${props.accountSlug}/venue/codes/reservations/list/?expandedPool=${c.id}#${c.id}`}
                                                                        >
                                                                            Codes page
                                                                        </ActionButtonA>
                                                                    </Cell>
                                                                </NestedTableRow>
                                                            </div>
                                                        )
                                                    })}
                                                </NestedTable>
                                            </NestedContainerWrapper>
                                        )}
                                    </div>
                                )
                            })}
                    </InlineDataTable>
                </>
            )}
        </>
    )
}

export default withNavigation(RetailersPage)
