export const generateOnRefundErrorMessage = (error: Error, isCancellation: boolean, orderId: string) => {
    if (error.message) {
        if (error.message === 'incomplete_payment_data') {
            return `We cannot ${
                isCancellation ? 'cancel' : 'refund'
            } order ${orderId} due to incomplete payment data, please contact support.`
        }
        return `[Order ID: ${orderId}] -> ${error.message}`
    }

    return `Oops! There was a problem with ${isCancellation ? 'cancelling' : 'refunding'} the order ${orderId}`
}
