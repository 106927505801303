import React from 'react'
import { Route } from 'react-router'
import dashboardComponentsPage from 'styleguide/dashboardComponentsPage'
import { TypographyComponents } from 'styleguide/typography'

const DEVELOPER_ROUTES = {
    DASHBOARD_COMPONENTS: '/dashboard_components',
    STYLEGUIDE_COMPONENTS: '/styleguide/typography',
}

export const ALL_DEVELOPER_ROUTES = Object.values(DEVELOPER_ROUTES)

const DeveloperRoutes = () => {
    return (
        <>
            <Route exact path={DEVELOPER_ROUTES.DASHBOARD_COMPONENTS} component={dashboardComponentsPage} />
            <Route exact path={DEVELOPER_ROUTES.STYLEGUIDE_COMPONENTS} component={TypographyComponents} />
        </>
    )
}

export default DeveloperRoutes
