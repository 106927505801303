import * as React from 'react'
import styled from 'styled-typed'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome'
import { transparency } from 'utils/css'

interface IconBoxProps {
    size: 'small' | 'medium' | 'large'
}

const inconBoxSizes = {
    small: '1.3em',
    medium: '1.5em',
    large: '1.75em',
}

const iconFontSizes = {
    small: '0.6875em',
    medium: '0.8em;',
    large: '1em',
}

const Icon = styled(FontAwesomeIcon)`
    color: ${(p) => p.theme.colors.boyBlue};
`

const IconBox = styled.div<IconBoxProps>`
    border-radius: 50%;
    min-width: ${(p) => inconBoxSizes[p.size]};
    height: ${(p) => inconBoxSizes[p.size]};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    align-self: center;
    &:hover {
        background: ${(props) => transparency(props.theme.colors.boyBlue, 0.3)};
    }
    &.first-icon {
        margin-left: 0.5em;
    }
    > ${Icon} {
        font-size: ${(p) => iconFontSizes[p.size]};
    }
`

interface ActionIconProps {
    title: string
    icon: IconProp
    className?: string
    size?: 'small' | 'medium' | 'large'
    style?: React.CSSProperties
    onClick?: (e?: React.MouseEvent<any>) => void
}

export function ActionIcon(props: ActionIconProps) {
    return (
        <IconBox
            size={props.size || 'medium'}
            onClick={props.onClick}
            title={props.title}
            className={props.className}
            style={props.style}
        >
            <Icon icon={props.icon} />
        </IconBox>
    )
}
