import cloneDeep from 'lodash/cloneDeep'
import { DiscountRule } from 'products/discountRules/discountRulesService'
import { Sorting } from 'uiComponents/table'

const desc = (sortingFunc: (item1: DiscountRule, item2: DiscountRule) => {}) => {
    return (item1: DiscountRule, item2: DiscountRule) => -sortingFunc(item1, item2)
}

const sortByValue = (sortBy: string) => {
    return (item1: DiscountRule, item2: DiscountRule, prop = sortBy) => {
        const sortKeyA = item1[prop]
        const sortKeyB = item2[prop]
        if (sortKeyA < sortKeyB) {
            return -1
        }
        if (sortKeyA > sortKeyB) {
            return 1
        }
        return item1.id - item2.id
    }
}

const sortByValidity = (item1: DiscountRule, item2: DiscountRule, prop: string = 'validFrom'): number => {
    const sortKeyA = item1[prop]
    const sortKeyB = item2[prop]
    if (sortKeyA < sortKeyB) {
        return -1
    }
    if (sortKeyA > sortKeyB) {
        return 1
    }
    return prop === 'validFrom' ? sortByValidity(item1, item2, 'validTo') : item1.id - item2.id
}

export const getSortedRules = (rules: DiscountRule[], sort: Sorting) => {
    const updatedRules = cloneDeep(rules)
    const sortFunction = sort.prop === 'validity' ? sortByValidity : sortByValue(sort.prop)
    const sortFunctionWithDirection = sort.direction === 'desc' ? desc(sortFunction) : sortFunction
    return updatedRules.sort(sortFunctionWithDirection)
}
