import React, { useState } from 'react'
import styled from 'styled-typed'
import { Radio } from 'uiComponents/input/radio'
import { IOption } from '../../types'
import RuleContainer from './container'

const Container = styled.div`
    & > div:not(:last-child) {
        border-bottom: 1px solid ${(props) => props.theme.colors.border};
    }
`

const Element = styled.div`
    padding: 1.5em 1.2em;
    cursor: pointer;
`

const Label = styled.div`
    font-size: 0.9em;
    font-weight: 500;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Desc = styled.div`
    padding: 0.5em 1em 0 2em;
    font-size: 0.9em;
    color: ${(props) => props.theme.colors.textLight};
`

export interface BasicRadioOptions {
    caption: string
    description?: string
    value: string | null
}

interface FilterRadioProps {
    label: string
    options: BasicRadioOptions[]
    onChange: (value: IOption) => void
    value?: IOption | null
}

const FilterRadio = (props: FilterRadioProps) => {
    const [value, setValue] = useState<IOption | null>(props.value || null)

    const onChange = (value: IOption) => {
        setValue(value)
        props.onChange(value)
    }

    return (
        <RuleContainer label={props.label}>
            <Container>
                {props.options.map((option, index) => (
                    <Element onClick={() => onChange(option)} key={index}>
                        <Row>
                            <Radio checked={value === option} onChange={() => {}} />
                            <Label>{option.caption}</Label>
                        </Row>
                        <Desc>{option.description}</Desc>
                    </Element>
                ))}
            </Container>
        </RuleContainer>
    )
}

export default FilterRadio
