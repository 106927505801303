import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import StyleDetails, { TabContainer } from './details'
import { useJsApiLoader } from '@react-google-maps/api'
import * as config from '../../../../config'

const TabStyles = () => {
    const { path } = useRouteMatch()
    const { isLoaded } = useJsApiLoader({
        id: config.getRequired('google-maps-id'),
        googleMapsApiKey: config.getRequired('google-maps-api-key'),
        libraries: ['places'],
    })

    return (
        <TabContainer>
            <Switch>
                <Route exact path={path} component={() => (isLoaded ? <StyleDetails /> : <div />)} />
            </Switch>
        </TabContainer>
    )
}

export default TabStyles
