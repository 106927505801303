import React from 'react'

import { MessageProps, withMessages } from 'hocs'
import { Messages } from 'uiComponents/messages'
import { PageTitle } from 'uiComponents/typography'

interface PageLayoutProps extends MessageProps {
    title: string
}

const PageLayout: React.FC<PageLayoutProps> = ({ title, children, ...props }) => {
    return (
        <>
            <Messages messages={props.messages} hideMessage={props.hideMessage} />
            <PageTitle>Audiences</PageTitle>
            {children}
            <div style={{ height: '6rem' }} />
        </>
    )
}

export default withMessages(PageLayout)
