import React, { useEffect } from 'react'
import { Field, FieldProps, FormikHelpers } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import { startOfMonth, endOfMonth } from 'date-fns'
import ActionButton from 'uiComponents/buttons'
import { Col, Row } from 'uiComponents/flex'
import { FormWrapper } from 'uiComponents/form/form'
import { CheckboxFieldFormik } from 'uiComponents/input'
import RadioFieldFormik from 'uiComponents/input/radio/radioFieldFormik'
import { CodePageParams, CODES_PATHS } from 'venue/bookingCodes/paths'
import { useAddCodeByFormMutation } from 'venue/reduxQuery'
import { discountCodeFormatter, DiscountCodeFormSchema, discountCodeSchema } from '../_shared/formUtils'
import { useFocusInputOnMounting } from 'uiComponents/input/_shared/hooks'
import { ErrorResponse } from '../../../../../types'
import { DiscountCodeDetails } from 'venue/bookingCodes/types'
import CodeSection from '../_shared/form/codeSection'
import ProductSection from '../_shared/form/productSection'
import UsesSection from '../_shared/form/usesSection'
import ReductionSection from '../_shared/form/reductionSection'
import { pathUtils } from 'utils/pathnameFormatter'
import { FormItemName } from 'uiComponents/form/formElements'

const initialValues = {
    type: 'cart',
    rate: 'percentage',
    uses: 'unlimited',
    code: undefined,
    usesCount: undefined,
    reductionRate: undefined,
    products: {
        uuids: undefined,
    },
    validity: {
        from: startOfMonth(new Date()).toISOString(),
        to: endOfMonth(new Date()).toISOString(),
    },
} as Partial<DiscountCodeFormSchema>

const AddByCode = () => {
    const [submitForm, { isLoading }] = useAddCodeByFormMutation()
    const history = useHistory()
    const { accountSlug, id } = useParams<CodePageParams>()

    const closeForm = () =>
        history.push(
            pathUtils.addQueryString(
                pathUtils.populateParams(CODES_PATHS.fullPaths.poolsPage, { accountSlug }),
                history.location.search,
            ),
        )

    useFocusInputOnMounting('code')

    const onSubmit = (values: DiscountCodeFormSchema, { setErrors }: FormikHelpers<DiscountCodeFormSchema>) => {
        const formattedData = discountCodeFormatter.toDomain(values)

        return submitForm({ formData: formattedData, accountSlug, uuid: id })
            .unwrap()
            .then((data: ErrorResponse<DiscountCodeDetails>) => {
                if (!data || !data.error) {
                    return closeForm()
                }

                if (data) {
                    const mappedErrors = discountCodeFormatter.formatErrors(data?.error?.errors)

                    setErrors(mappedErrors)
                }
            })
    }

    return (
        <FormWrapper validationSchema={discountCodeSchema} initialValues={initialValues} onSubmit={onSubmit}>
            <Field name="type">
                {({ field: { value }, form: { setFieldValue } }: FieldProps) => {
                    useEffect(() => {
                        setFieldValue('productId', undefined)
                        setFieldValue('amount', undefined)
                    }, [value])
                    return null
                }}
            </Field>
            <Row>
                <FormItemName weight="medium">Applies to</FormItemName>
            </Row>
            <Row style={{ marginBottom: '1.5em' }}>
                <Col span="auto">
                    <RadioFieldFormik checkMarkSize="small" name="type" value="cart" label="All products" />
                </Col>
                <Col span="auto">
                    <RadioFieldFormik checkMarkSize="small" name="type" value="productId" label="Specific product" />
                </Col>
            </Row>
            <Row style={{ marginBottom: '1.5em' }} gutter={0}>
                <Col span={12}>
                    <CodeSection />
                </Col>
            </Row>
            <ProductSection />
            <Row align="space-between" style={{ marginBottom: '1.5em', flexWrap: 'nowrap' }} gutter={0}>
                <Col span="auto">
                    <ReductionSection accountSlug={accountSlug} />
                </Col>
                <Col span="auto">
                    <UsesSection />
                </Col>
            </Row>
            <Row style={{ marginBottom: '1.5rem' }}>
                <Col span={12}>
                    <CheckboxFieldFormik variant="inline" name="remainderUsable" label="Remainder usable" />
                </Col>
            </Row>
            <Row align="flex-end">
                <Col span="auto">
                    <ActionButton size="large" secondary onClick={closeForm}>
                        Cancel
                    </ActionButton>
                </Col>
                <Col span="auto">
                    <ActionButton size="large" type="submit" kind="default" disabled={isLoading}>
                        Add Codes
                    </ActionButton>
                </Col>
            </Row>
        </FormWrapper>
    )
}

export default AddByCode
