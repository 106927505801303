import React, { ClassAttributes, InputHTMLAttributes } from 'react'
import styled from 'styled-typed'

interface ListItemProps extends InputHTMLAttributes<HTMLInputElement>, ClassAttributes<HTMLInputElement> {
    component?: string | React.FunctionComponent
}

const ListItem: React.FC<ListItemProps> = ({ component = 'li', children, ...props }) => {
    return React.createElement(component, props, children)
}

export default styled(ListItem)``
