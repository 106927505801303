import * as React from 'react'
import { usePrevious, useIsMounted } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { format } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { getComparisonColorFromPercentString, chartRowsGap, comparisonCopyMap } from 'reports/helpers'
import { DataNumber } from 'uiComponents/charts/dataNumber'
import { ChartRow, ChartContainer } from 'uiComponents/charts/styleComponents'
import { QueryConfig } from 'reports/queryGenerator'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { DateRange, DateRangeName } from 'dateRanges'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { abbreviateNumber } from 'utils'
import { isBefore, startOfToday, differenceInCalendarDays } from 'date-fns'
import { Filters } from 'uiComponents/filter/schema'
import isEqual from 'lodash/isEqual'

const queryConfig: QueryConfig = {
    querySetName: 'SingleDataItems',
    variablesConfig: [
        { name: 'accountSlug', type: 'String' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'comparisonType', type: 'ComparisonTypes' },
        { name: 'timeFilter', type: 'StatsTimeFilter' },
        { name: 'delayType', type: 'DelayTypes' },
        { name: 'delay', type: 'Int' },
        { name: 'filters', type: '[FilterDictionary]' },
    ],
    queries: [
        {
            name: 'codesUsed',
            type: 'comparisonMetrics',
            configVariables: [
                'accountSlug',
                'dateFrom',
                'dateTo',
                'comparisonType',
                'timeFilter',
                'delayType',
                'delay',
                'filters',
            ],
            customVariables: [{ name: 'metric', customValue: 'discount_coupons_used' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'revenue',
            type: 'comparisonMetrics',
            configVariables: [
                'accountSlug',
                'dateFrom',
                'dateTo',
                'comparisonType',
                'timeFilter',
                'delayType',
                'delay',
                'filters',
            ],
            customVariables: [{ name: 'metric', customValue: 'discount_coupons_collected_revenue' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'cartValue',
            type: 'comparisonMetrics',
            configVariables: [
                'accountSlug',
                'dateFrom',
                'dateTo',
                'comparisonType',
                'timeFilter',
                'delayType',
                'delay',
                'filters',
            ],
            customVariables: [{ name: 'metric', customValue: 'discount_coupons_cart_value' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'avgDiscount',
            type: 'comparisonMetrics',
            configVariables: [
                'accountSlug',
                'dateFrom',
                'dateTo',
                'comparisonType',
                'timeFilter',
                'delayType',
                'delay',
                'filters',
            ],
            customVariables: [{ name: 'metric', customValue: 'discount_coupons_average_discount' }],
            presetResult: 'totalsComparison',
        },
    ],
}

const infotipContentMap = {
    codesUsed: {
        pageName: 'Discount codes used',
        itemName: 'discount codes used',
        more: 'more',
        less: 'less',
        metricText: 'The total amount of codes used in your Checkout.',
    },
    revenue: {
        pageName: 'Collected revenue',
        itemName: 'collected revenue',
        more: 'more',
        less: 'less',
        metricText: 'The revenue received from those orders placed using a discount/voucher code.',
    },
    cartValue: {
        pageName: 'Original value of tickets sold',
        itemName: 'original value of tickets sold',
        more: 'higher',
        less: 'lower',
        metricText:
            'Total value of tickets sold with the orders where discount/voucher code were used. Ticket value corresponds to the ticket List Price (before discounts).',
    },
    avgDiscount: {
        pageName: 'Average discount',
        itemName: 'average discount',
        more: 'higher',
        less: 'lower',
        metricText:
            'The average discounts given by the codes, indicating the average percentage given as a discount on the original product list prices.',
    },
}

interface SingleDataItemsProps {
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    dateRange: DateRange
    filters: Filters[]
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

function SingleDataItems(props: SingleDataItemsProps) {
    const isMounted = useIsMounted()
    const _lastRequest = React.useRef<number>()
    const statsService = React.useContext(StatsServiceContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [timePeriod, setTimePeriod] = React.useState<DateRangeName>('yesterday')
    const [timePeriodLength, setTimePeriodLength] = React.useState<number>(0)
    const [revenue, setRevenue] = React.useState<string>('0')
    const [codesUsed, setCodesUsed] = React.useState<string>('0')
    const [cartValue, setCartValue] = React.useState<string>('0')
    const [avgDiscount, setAvgDiscount] = React.useState<string>('0')
    const [comparisonRevenue, setComparisonRevenue] = React.useState<string>('-')
    const [comparisonCodesUsed, setComparisonCodesUsed] = React.useState<string>('-')
    const [comparisonCartValue, setComparisonCartValue] = React.useState<string>('-')
    const [comparisonAvgDiscount, setComparisonAvgDiscount] = React.useState<string>('-')

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    const prevFilters = usePrevious(props.filters)
    React.useEffect(() => {
        if (
            prevAccountSlug !== props.accountSlug ||
            !areDateRangeDatesEqual(prevDateRange, props.dateRange) ||
            !isEqual(prevFilters, props.filters)
        ) {
            getData()
        }
    }, [props.accountSlug, props.dateRange, props.filters])

    const getData = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        setLoading(true)
        const timePeriods = ['today', 'yesterday', 'thisWeek', 'thisMonth']
        const timePeriodForMap = timePeriods.indexOf(props.dateRange.name) > -1 ? props.dateRange.name : 'custom'
        setTimePeriod(timePeriodForMap)
        const dateRangeLength = differenceInCalendarDays(props.dateRange.to, props.dateRange.from) + 1
        setTimePeriodLength(dateRangeLength)
        try {
            const todayIncluded = isBefore(startOfToday(), props.dateRange.to)
            const variables = {
                accountSlug: props.accountSlug,
                dateFrom: format(props.dateRange.from || new Date(), 'yyyy-MM-dd'),
                dateTo: format(props.dateRange.to || new Date(), 'yyyy-MM-dd'),
                comparisonType: 'percentage_growth',
                timeFilter: todayIncluded ? 'this_time_so_far' : null,
                delayType:
                    timePeriodForMap === 'thisMonth' ? 'months' : dateRangeLength <= 7 ? 'weeks' : 'previous_stretch',
                filters: props.filters,
                delay: 1,
            }
            const data = await statsService.getStats(queryConfig, variables)
            if (!isMounted.current || _lastRequest.current !== requestTime) {
                return
            }
            setRevenue(data.revenue.totals?.value || '0')
            setCodesUsed(data.codesUsed.totals?.value || '0')
            setCartValue(data.cartValue.totals?.value || '0')
            setAvgDiscount(data.avgDiscount.totals?.value || '0')
            setComparisonRevenue(data.revenue.totals?.change || '-')
            setComparisonCodesUsed(data.codesUsed.totals?.change || '-')
            setComparisonCartValue(data.cartValue.totals?.change || '-')
            setComparisonAvgDiscount(data.avgDiscount.totals?.change || '-')
            setLoading(false)
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Some data could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }

    const getPlusSign = (change: string) => {
        const value = Number(change.split('%')[0])
        return value > 0 ? '+' : ''
    }

    const formatChangeText = (stats: string, change: string) => {
        const value = Number(change.split('%')[0])
        return value >= 0
            ? `${getPlusSign(change)}${change} ${infotipContentMap[stats].more}`
            : `${change} ${infotipContentMap[stats].less}`
    }

    const formatInfotipText = (stats: string, change: string) => {
        const textPart = timePeriod === 'yesterday' || timePeriod === 'custom' ? 'had' : 'are expected to have'
        return (
            <>
                {comparisonCopyMap[timePeriod].infotipStart} you {textPart}
                &nbsp;{formatChangeText(stats, change)} {infotipContentMap[stats].itemName}
                &nbsp;than the {comparisonCopyMap[timePeriod].infotipEnd}.
            </>
        )
    }

    const getComparisonText = () => {
        return timePeriod === 'thisMonth' ? 'MoM' : timePeriodLength <= 7 ? 'WoW' : 'prev. period'
    }

    return (
        <ChartContainer style={{ marginTop: chartRowsGap, marginBottom: '2em' }}>
            {loading && <ChartDataLoader />}
            <ChartRow>
                <DataNumber
                    title={infotipContentMap['codesUsed'].pageName}
                    value={Number(codesUsed) >= 10000 ? abbreviateNumber(Number(codesUsed), 1) : codesUsed}
                    comparisonItem={`${getPlusSign(comparisonCodesUsed)}${comparisonCodesUsed} ${getComparisonText()}`}
                    comparisonItemColor={getComparisonColorFromPercentString(comparisonCodesUsed)}
                    infotipText={formatInfotipText('codesUsed', comparisonCodesUsed)}
                    metricInfotipText={infotipContentMap['codesUsed'].metricText}
                    tooltipType={getComparisonText()}
                    accountSlug={props.accountSlug}
                    compact
                    id="1"
                />
                <DataNumber
                    title={infotipContentMap['revenue'].pageName}
                    value={Number(revenue) >= 10000 ? abbreviateNumber(Number(revenue), 1) : Number(revenue).toFixed(2)}
                    comparisonItem={`${getPlusSign(comparisonCartValue)}${comparisonRevenue} ${getComparisonText()}`}
                    comparisonItemColor={getComparisonColorFromPercentString(comparisonRevenue)}
                    infotipText={formatInfotipText('revenue', comparisonRevenue)}
                    metricInfotipText={infotipContentMap['revenue'].metricText}
                    withCurrency
                    accountSlug={props.accountSlug}
                    tooltipType={getComparisonText()}
                    compact
                    id="2"
                />
                <DataNumber
                    title={infotipContentMap['cartValue'].pageName}
                    value={
                        Number(revenue) >= 10000 ? abbreviateNumber(Number(cartValue), 1) : Number(cartValue).toFixed(2)
                    }
                    comparisonItem={`${getPlusSign(comparisonCartValue)}${comparisonCartValue} ${getComparisonText()}`}
                    comparisonItemColor={getComparisonColorFromPercentString(comparisonCartValue)}
                    infotipText={formatInfotipText('cartValue', comparisonCartValue)}
                    metricInfotipText={infotipContentMap['cartValue'].metricText}
                    withCurrency
                    infotipPosition="right"
                    tooltipType={getComparisonText()}
                    compact
                    id="3"
                    nthChildStyle="margin-right: 3em"
                />
                <DataNumber
                    title={infotipContentMap['avgDiscount'].pageName}
                    value={`${(Number(avgDiscount) * 100).toFixed(2)}%`}
                    comparisonItem={`${getPlusSign(
                        comparisonAvgDiscount,
                    )}${comparisonAvgDiscount} ${getComparisonText()}`}
                    comparisonItemColor={getComparisonColorFromPercentString(comparisonAvgDiscount)}
                    infotipText={formatInfotipText('avgDiscount', comparisonAvgDiscount)}
                    metricInfotipText={infotipContentMap['avgDiscount'].metricText}
                    withCurrency
                    infotipPosition="right"
                    tooltipType={getComparisonText()}
                    compact
                    id="4"
                    nthChildStyle="margin-right: -2em"
                />
            </ChartRow>
        </ChartContainer>
    )
}

export default withNavigation(SingleDataItems)
