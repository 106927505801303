import { useField } from 'formik'
import React from 'react'
import ColorPicker, { ColorPickerProps } from 'uiComponents/colorPicker'
import FieldWrapper from '../fieldWrapper'
import { useCustomId, useGetComponentState } from '../_shared/hooks'

interface ColorPickerFieldFormikProps extends Partial<ColorPickerProps> {
    name: string
    label?: string
}

const ColorPickerFieldFormik: React.FC<ColorPickerFieldFormikProps> = ({ name, label, id, onChange, ...props }) => {
    const [{ value, ...field }, { error }, { setValue }] = useField(name)
    const innerId = useCustomId(id)
    const state = useGetComponentState(name)

    return (
        <FieldWrapper
            label={label}
            error={error}
            id={innerId}
            status={state}
            validationMessageProps={{
                style: { top: '-.725em', position: 'relative' },
            }}
        >
            <ColorPicker
                id={innerId}
                {...props}
                {...field}
                style={{ width: 40, height: 40, borderRadius: 10 }}
                color={value}
                onChange={(col) => {
                    setValue(col)
                    if (onChange) {
                        onChange(col)
                    }
                }}
            />
        </FieldWrapper>
    )
}

export default ColorPickerFieldFormik
