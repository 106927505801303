import React from 'react'
import { ModalDialog, ModalDialogTitle } from 'uiComponents/popups/modal'
import { PricingType } from 'products/articleConfigurationService'
import { BulkPricingSettingsError } from 'products/pricing/pricingService'
import { ChartDataLoader } from 'uiComponents/loaders'
import { ActionButton } from 'uiComponents/buttons'
import { MessageKind } from 'uiComponents/messages'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { FormItem, FormItemName, WrapWithSymbol } from 'uiComponents/form/formElements'
import { ValidationNotice } from 'products/components/validationNotice'
import { Validities } from 'products/pricing/common'
import { PricingServiceContext } from 'products/pricing/context'
import { NumberInput } from 'uiComponents/input'
import { Bold } from 'uiComponents/typography'
import { delay } from 'utils'
import {
    ProductsCard,
    NavigationSection,
    Container,
    TableHeader,
    NameCell,
    Cell,
    TableRow,
} from 'products/bulkEditDialogs/common'

interface ProductInfo {
    name: string
    max: string
    min: string
    avg: string
    gate: string
    id: string
    version: string
}

interface DialogProps {
    onDismiss: () => void
    pricingType: PricingType
    getCurrency: (accountSlug: string) => Currency
    accountSlug: string
    replaceTopMessages?: (id: string, status: MessageKind, text: string) => void
    removeAllMessages?: () => void
    affectedProductInfo: ProductInfo[]
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    onSave: () => void
}

const initialValidities = {
    BOX: {
        valid: true,
        message: '',
    },
    MIN: {
        valid: true,
        message: '',
    },
    AVG: {
        valid: true,
        message: '',
    },
    GATE: {
        valid: true,
        message: '',
    },
}

export function BulkEditPricingSettingsDialog(props: DialogProps) {
    const pricingService = React.useContext(PricingServiceContext)
    const currency = props.getCurrency(props.accountSlug)
    const [maximum, setMaximum] = React.useState<number | null>(null)
    const [minimum, setMinimum] = React.useState<number | null>(null)
    const [average, setAverage] = React.useState<number | null>(null)
    const [gate, setGate] = React.useState<number | null>(null)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [validities, setValidities] = React.useState<Validities>(initialValidities)

    const staticOrUpsell = ['static', 'upsell'].includes(props.pricingType)

    const maximumRef = React.useRef<number | null>(null)
    const minimumRef = React.useRef<number | null>(null)
    const averageRef = React.useRef<number | null>(null)
    const gateRef = React.useRef<number | null>(null)
    maximumRef.current = maximum
    minimumRef.current = minimum
    averageRef.current = average
    gateRef.current = gate

    const onSave = async () => {
        if (!checkValidities() && props.replaceTopMessages) {
            props.replaceTopMessages('validation_error', 'error', 'Please check the validation errors below.')
            return
        }
        if (
            !maximum &&
            maximum !== 0 &&
            !minimum &&
            minimum !== 0 &&
            !average &&
            average !== 0 &&
            !gate &&
            props.replaceTopMessages
        ) {
            props.replaceTopMessages('warning', 'warn', 'Please fill at least one field.')
            return
        }
        setLoading(true)
        const responseData = await pricingService.sendBulkPricingSettings(
            props.affectedProductInfo.map((p) => ({
                productId: p.id,
                version: p.version,
            })),
            props.accountSlug,
            maximum,
            minimum,
            average,
            gate,
            props.pricingType,
        )
        responseData
            .ifFailure((err: BulkPricingSettingsError) => {
                let additionalMessage = ''
                if (!!err.productIds) {
                    const productNames = props.affectedProductInfo
                        .filter((pi) => err.productIds!.indexOf(pi.id) > -1)
                        .map((pi) => pi.name)
                    additionalMessage = err.productIds ? `Please check the product(s): ${productNames.join(', ')}.` : ''
                }
                if (props.replaceTopMessages) {
                    props.replaceTopMessages(err.type, 'error', `${err.message}. ${additionalMessage}`)
                }
                setLoading(false)
            })
            .ifSuccess(async () => {
                props.onSave()
                props.onDismiss()
                setLoading(false)
                if (props.replaceTopMessages && props.removeAllMessages) {
                    props.replaceTopMessages('success', 'success', 'Settings have been saved successfully.')
                    await delay(3000)
                    props.removeAllMessages()
                }
            })
    }

    const validateMaxAcceptancePrice = () => {
        const value = maximumRef.current
        if (!!value && value < 0) {
            return {
                valid: false,
                message: 'Maximum Accepted price should be higher than 0.',
            }
        }
        return {
            valid: true,
            message: '',
        }
    }

    const validateMinAcceptancePrice = () => {
        const value = minimumRef.current
        let validity = {
            valid: true,
            message: '',
        }
        if (value === null) {
            return validity
        } else if (maximumRef.current !== null && value > maximumRef.current) {
            validity = {
                valid: false,
                message: 'Minimum Accepted price should be lower than the Maximum Accepted Price.',
            }
        } else if (value < 0) {
            validity = {
                valid: false,
                message: 'Minimum Accepted price should be higher than 0.',
            }
        }
        return validity
    }

    const validateAvgTargetPrice = () => {
        const value = averageRef.current
        let validity = {
            valid: true,
            message: '',
        }
        if (value === null) {
            return validity
        } else if (maximumRef.current !== null && value > maximumRef.current) {
            validity = {
                valid: false,
                message: 'Average price should be lower than the Maximum Accepted Price.',
            }
        } else if (minimumRef.current !== null && value < minimumRef.current) {
            validity = {
                valid: false,
                message: 'Average price should be higher than the Minimum Accepted Price.',
            }
        } else if (value < 0) {
            validity = {
                valid: false,
                message: 'Average price should be higher than 0.',
            }
        }
        return validity
    }

    const validateGatePrice = () => {
        const value = gateRef.current
        let validity = {
            valid: true,
            message: '',
        }
        if (value === null) {
            return validity
        } else if (maximumRef.current !== null && value < maximumRef.current) {
            validity = {
                valid: false,
                message: staticOrUpsell
                    ? 'Gate price should be higher than the Price.'
                    : 'Gate price should be higher than the Maximum Accepted Price.',
            }
        } else if (value < 0) {
            validity = {
                valid: false,
                message: 'Gate price should be higher than 0.',
            }
        }

        return validity
    }

    const checkValidities = () => {
        if (props.removeAllMessages) {
            props.removeAllMessages()
        }
        const maxValidity = validateMaxAcceptancePrice()
        const minValidity = validateMinAcceptancePrice()
        const avgValidity = validateAvgTargetPrice()
        const gateValidity = validateGatePrice()
        setValidities({
            BOX: maxValidity,
            MIN: minValidity,
            AVG: avgValidity,
            GATE: gateValidity,
        })
        return maxValidity.valid && minValidity.valid && avgValidity.valid && gateValidity.valid
    }

    return (
        <ModalDialog onDismiss={props.onDismiss} interactive fromTop="10%" className={loading ? 'noscroll' : ''}>
            {loading && <ChartDataLoader />}
            <Container>
                <ModalDialogTitle>Overwrite pricing settings</ModalDialogTitle>
                {!staticOrUpsell && (
                    <>
                        <FormItem htmlFor="maximum" style={{ position: 'relative' }}>
                            <FormItemName>Maximum Accepted price</FormItemName>
                            <WrapWithSymbol symbol={currency.symbol} position="left">
                                <NumberInput
                                    align="left"
                                    id="maximum"
                                    name="maximum"
                                    maxLength={7}
                                    max={9999}
                                    value={maximum !== null ? maximum : ''}
                                    onChange={(e) => {
                                        setMaximum(e.target.value === '' ? null : +e.target.value)
                                    }}
                                    onFinishTyping={checkValidities}
                                    status={validities['BOX'].valid ? 'normal' : 'error'}
                                    block
                                />
                            </WrapWithSymbol>
                            <ValidationNotice className={!validities['BOX'].valid ? 'validation-message-visible' : ''}>
                                {validities['BOX'].message}
                            </ValidationNotice>
                        </FormItem>
                        <FormItem htmlFor="minimum" style={{ position: 'relative' }}>
                            <FormItemName>Minimum Accepted Price</FormItemName>
                            <WrapWithSymbol symbol={currency.symbol} position="left">
                                <NumberInput
                                    align="left"
                                    id="minimum"
                                    name="minimum"
                                    maxLength={7}
                                    max={9999}
                                    value={minimum !== null ? minimum : ''}
                                    onChange={(e) => setMinimum(e.target.value === '' ? null : +e.target.value)}
                                    onFinishTyping={checkValidities}
                                    status={validities['MIN'].valid ? 'normal' : 'error'}
                                    block
                                />
                            </WrapWithSymbol>
                            <ValidationNotice className={!validities['MIN'].valid ? 'validation-message-visible' : ''}>
                                {validities['MIN'].message}
                            </ValidationNotice>
                        </FormItem>
                        <FormItem htmlFor="average" style={{ position: 'relative' }}>
                            <FormItemName>Average Price</FormItemName>
                            <WrapWithSymbol symbol={currency.symbol} position="left">
                                <NumberInput
                                    align="left"
                                    id="average"
                                    name="average"
                                    maxLength={7}
                                    max={9999}
                                    value={average !== null ? average : ''}
                                    onChange={(e) => setAverage(e.target.value === '' ? null : +e.target.value)}
                                    onFinishTyping={checkValidities}
                                    status={validities['AVG'].valid ? 'normal' : 'error'}
                                    block
                                />
                            </WrapWithSymbol>
                            <ValidationNotice className={!validities['AVG'].valid ? 'validation-message-visible' : ''}>
                                {validities['AVG'].message}
                            </ValidationNotice>
                        </FormItem>
                    </>
                )}
                {staticOrUpsell && (
                    <FormItem htmlFor="maximum" style={{ position: 'relative' }}>
                        <FormItemName>Price</FormItemName>
                        <WrapWithSymbol symbol={currency.symbol} position="left">
                            <NumberInput
                                align="left"
                                id="maximum"
                                name="maximum"
                                maxLength={7}
                                max={9999}
                                value={maximum !== null ? maximum : ''}
                                onChange={(e) => {
                                    setMaximum(e.target.value === '' ? null : +e.target.value)
                                }}
                                onFinishTyping={checkValidities}
                                status={validities['BOX'].valid ? 'normal' : 'error'}
                                block
                            />
                        </WrapWithSymbol>
                        <ValidationNotice className={!validities['BOX'].valid ? 'validation-message-visible' : ''}>
                            {validities['BOX'].message}
                        </ValidationNotice>
                    </FormItem>
                )}
                <FormItem htmlFor="gate" style={{ position: 'relative' }}>
                    <FormItemName>Gate Price</FormItemName>
                    <WrapWithSymbol symbol={currency.symbol} position="left">
                        <NumberInput
                            align="left"
                            id="gate"
                            name="gate"
                            maxLength={7}
                            max={9999}
                            value={gate !== null ? gate : ''}
                            onChange={(e) => setGate(e.target.value === '' ? null : +e.target.value)}
                            onFinishTyping={checkValidities}
                            status={validities['GATE'].valid ? 'normal' : 'error'}
                            block
                        />
                    </WrapWithSymbol>
                    <ValidationNotice className={!validities['GATE'].valid ? 'validation-message-visible' : ''}>
                        {validities['GATE'].message}
                    </ValidationNotice>
                </FormItem>
                <ProductsList
                    affectedProductInfo={props.affectedProductInfo}
                    formatCurrencyString={props.formatCurrencyString}
                    accountSlug={props.accountSlug}
                    staticOrUpsell={staticOrUpsell}
                />
                <NavigationSection>
                    <ActionButton size="large" secondary onClick={props.onDismiss}>
                        Cancel
                    </ActionButton>
                    <ActionButton
                        id="save-settings"
                        size="large"
                        kind="destructive"
                        style={{ marginLeft: '2em' }}
                        onClick={onSave}
                    >
                        Save
                    </ActionButton>
                </NavigationSection>
            </Container>
        </ModalDialog>
    )
}

export default withCurrency(BulkEditPricingSettingsDialog)

interface ListProps {
    affectedProductInfo: ProductInfo[]
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    accountSlug: string
    staticOrUpsell: boolean
}

function ProductsList(props: ListProps) {
    return (
        <ProductsCard>
            <div style={{ marginBottom: '1em' }}>Changes will affect below listed products&apos; pricing settings:</div>
            <TableHeader>
                <NameCell>Product name</NameCell>
                {props.staticOrUpsell ? (
                    <>
                        <Cell style={{ width: '6em' }}>Price</Cell>
                        <Cell style={{ width: '6em' }}>Gate Price</Cell>
                    </>
                ) : (
                    <>
                        <Cell>Max.</Cell>
                        <Cell>Min.</Cell>
                        <Cell>Avg.</Cell>
                        <Cell>Gate</Cell>
                    </>
                )}
            </TableHeader>
            {props.affectedProductInfo.map((el, i) => (
                <TableRow key={i}>
                    <NameCell>
                        <Bold>{el.name}</Bold>
                    </NameCell>
                    {props.staticOrUpsell ? (
                        <>
                            <Cell style={{ width: '6em' }}>
                                {el.max ? props.formatCurrencyString(Number(el.max), props.accountSlug) : '-'}
                            </Cell>
                            <Cell style={{ width: '6em' }}>
                                {el.gate ? props.formatCurrencyString(Number(el.gate), props.accountSlug) : '-'}
                            </Cell>
                        </>
                    ) : (
                        <>
                            <Cell>{el.max ? props.formatCurrencyString(Number(el.max), props.accountSlug) : '-'}</Cell>
                            <Cell>{el.min ? props.formatCurrencyString(Number(el.min), props.accountSlug) : '-'}</Cell>
                            <Cell>{el.avg ? props.formatCurrencyString(Number(el.avg), props.accountSlug) : '-'}</Cell>
                            <Cell>
                                {el.gate ? props.formatCurrencyString(Number(el.gate), props.accountSlug) : '-'}
                            </Cell>
                        </>
                    )}
                </TableRow>
            ))}
        </ProductsCard>
    )
}
