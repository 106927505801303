export type TemplateType =
    | 'cart_pay_later'
    | 'cart_pay_bank_transfer'
    | 'cart_paid'
    | 'cart_paid_apf'
    | 'cart_reminder'
    | 'cart_reminder_2'
    | 'rtp_cart_reminder'
    | 'pre_event'
    | 'post_event'
    | 'reservation'
    | 'reservation_apf'
    | 'refund'
    | 'order_date_change'

export type FormItemType =
    | 'defaultLanguage'
    | 'activeLanguage'
    | 'title'
    | 'paragraphText'
    | 'textline'
    | 'footerText'
    | 'ctaUrl'
    | 'discountReservoir'
    | 'themeColor'
    | 'backgroundColor'
    | 'signatureEnabled'
    | 'socialIconsEnabled'
    | 'logo'
    | 'agentPhoto'
    | 'subject'
    | 'preheader'
    | 'vatEnabled'
    | 'upsells'
    | 'upsellsEnabled'
    | 'requiresOptIn'

export type TemplateStatusType = 'enabled' | 'disabled'
export type MenuStepType = 'settings' | 'text'

export const emailNameMap = {
    CART_PAID: 'Purchase confirmation email',
    CART_PAID_INPUT_REQUIRED: 'Required input to complete order email',
    CART_PAY_LATER: 'Pay by payment link email',
    CART_PAY_BANK_TRANSFER: 'Pay by bank transfer email',
    CART_RESERVATION: 'Reservation confirmation email',
    CART_RESERVATION_INPUT_REQUIRED: 'Required input to complete reservation email',
    CART_REMINDER: 'Cart abandonment email NYOP',
    CART_REMINDER_II: 'Cart abandonment email NYOP II',
    CART_REMINDER_RTP: 'Abandoned Shopping Cart email',
    CART_PAID_INPUT_REQUIRED_REMINDER: 'Required input to complete reservation reminder email',
    CART_REFUND: 'Refund email',
    CART_PRE_EVEN_NOTIFICATION: 'Pre-event email',
    CART_POST_EVENT_NOTIFICATION: 'Post-event email',
    ORDER_DATE_CHANGE: 'Order date change',
} as const

export const cartPaidTemplates = ['cart_paid', 'cart_paid_apf', 'reservation', 'reservation_apf', 'order_date_change']
export const reminderTemplates = ['cart_reminder', 'cart_reminder_2', 'rtp_cart_reminder']

export const payLaterTemplates = ['cart_pay_later', 'cart_pay_bank_transfer']

export interface Template {
    type: TemplateType
    status: TemplateStatusType
    custom: boolean
}

export interface Copy {
    [languageCode: string]: string
}

export interface TemplateSettings {
    subject: Copy
    preheader: Copy
    title: Copy
    paragraphText: Copy
    textline: Copy
    footerText: Copy
    ctaUrl: Copy
    themeColor: string
    backgroundColor: string
    signatureEnabled: boolean
    socialIconsEnabled: boolean
    upsellsEnabled: boolean
    vatEnabled: boolean
    requiresOptIn: boolean | null
    logo: string | null
    agentPhoto: string | null
    discountReservoir: string | null
}

export interface TemplateSettingsWithVariables extends TemplateSettings {
    variables: string[]
}

interface PayloadUpsell {
    uuid: string
    priority: number
    image: string
}

export interface PayloadTemplateSettings extends TemplateSettings {
    upsells?: PayloadUpsell[]
}

interface TemplateConfig {
    [type: string]: {
        name: string
        description: string
        customFields: FormItemType[]
    }
}

export const globalFieldList: FormItemType[] = [
    'subject',
    'preheader',
    'title',
    'themeColor',
    'backgroundColor',
    'signatureEnabled',
    'socialIconsEnabled',
    'logo',
    'agentPhoto',
]
export const optionalFieldList: FormItemType[] = [
    'paragraphText',
    'textline',
    'footerText',
    'ctaUrl',
    'discountReservoir',
    'upsells',
    'requiresOptIn',
]
export const translationFieldList: FormItemType[] = [
    'subject',
    'preheader',
    'title',
    'paragraphText',
    'textline',
    'footerText',
    'ctaUrl',
]

export const templatesConfig: TemplateConfig = {
    cart_paid: {
        name: emailNameMap['CART_PAID'],
        description: 'Sent to customers after purchase.',
        customFields: ['paragraphText', 'textline', 'footerText', 'vatEnabled'],
    },
    cart_pay_later: {
        name: emailNameMap['CART_PAY_LATER'],
        description: 'Sent to customers when a cart is reserved for making a payment later.',
        customFields: ['paragraphText'],
    },
    cart_pay_bank_transfer: {
        name: emailNameMap['CART_PAY_BANK_TRANSFER'],
        description: 'Sent to customer when a customer want to pay by bank transfer.',
        customFields: ['paragraphText', 'textline'],
    },
    cart_paid_apf: {
        name: emailNameMap['CART_PAID_INPUT_REQUIRED'],
        description: 'Sent to customers when APF is used.',
        customFields: ['paragraphText', 'textline', 'footerText', 'vatEnabled'],
    },
    reservation: {
        name: emailNameMap['CART_RESERVATION'],
        description: 'Sent to customers after reservation',
        customFields: ['paragraphText', 'textline', 'footerText', 'vatEnabled'],
    },
    reservation_apf: {
        name: emailNameMap['CART_RESERVATION_INPUT_REQUIRED'],
        description: 'Sent to customers when APF is used for a reservation.',
        customFields: ['paragraphText', 'textline', 'footerText', 'vatEnabled'],
    },
    order_date_change: {
        name: emailNameMap['ORDER_DATE_CHANGE'],
        description: 'Sent to customers when order or reservation date is changed.',
        customFields: ['paragraphText', 'textline', 'footerText', 'vatEnabled'],
    },
    refund: {
        name: emailNameMap['CART_REFUND'],
        description: 'Sent to customers when an order is refunded.',
        customFields: ['paragraphText', 'footerText'],
    },
    cart_reminder: {
        name: emailNameMap['CART_REMINDER'],
        description: 'Sent to customers if order was not placed.',
        customFields: ['textline', 'requiresOptIn'],
    },
    cart_reminder_2: {
        name: emailNameMap['CART_REMINDER_II'],
        description: 'Sent to customers if order was not placed.',
        customFields: ['textline', 'requiresOptIn'],
    },
    rtp_cart_reminder: {
        name: emailNameMap['CART_REMINDER_RTP'],
        description: 'Sent to customers if order was not placed.',
        customFields: ['paragraphText', 'textline', 'requiresOptIn'],
    },
    pre_event: {
        name: emailNameMap['CART_PRE_EVEN_NOTIFICATION'],
        description: 'Sent to customers before their visit.',
        customFields: ['paragraphText', 'footerText', 'upsellsEnabled', 'requiresOptIn'],
    },
    post_event: {
        name: emailNameMap['CART_POST_EVENT_NOTIFICATION'],
        description: 'Sent to customers after their visit took place.',
        customFields: ['paragraphText', 'textline', 'ctaUrl', 'discountReservoir', 'requiresOptIn'],
    },
}

export const dummySettings: TemplateSettingsWithVariables = {
    variables: [],
    subject: { en: '' },
    preheader: { en: '' },
    title: { en: '' },
    paragraphText: { en: '' },
    textline: { en: '' },
    footerText: { en: '' },
    ctaUrl: { en: '' },
    themeColor: '#5298F4',
    backgroundColor: '#F0F0F0',
    signatureEnabled: true,
    socialIconsEnabled: true,
    vatEnabled: false,
    upsellsEnabled: false,
    requiresOptIn: false,
    logo: '',
    agentPhoto: '',
    discountReservoir: '',
}
