import * as React from 'react'
import styled from 'styled-typed'
import { Checkbox } from 'uiComponents/input'
import { Cell, ExpandCell, DataRow } from 'uiComponents/table'
import { TicketForRefund, TicketEntity } from 'orders/schema'
import Money from 'uiComponents/money'
import Infotip from 'uiComponents/infotip'
import { flatMap } from 'utils'
import classNames from 'classnames'

const Barcodes = styled.div`
    display: none;

    &.expanded {
        display: inherit;
    }
`
const Status = styled.div`
    font-size: 0.9em;
    text-align: right;
    white-space: initial;
    text-transform: capitalize;
`
export const RefundDataRow = styled(DataRow)`
    height: 2.5rem;

    & label,
    &.expandable {
        cursor: pointer;
    }
    &.disabled {
        opacity: 0.5;
    }
`

interface AmountCellProps {
    accountSlug: string
    ticket: TicketForRefund
    ticketEntities: TicketEntity[]
    amount: number
    status: string
    cancellation: boolean
}

function AmountCell({ accountSlug, ticket, ticketEntities, amount, status, cancellation }: AmountCellProps) {
    const infotipStatuses = ticketEntities
        .map((t) => (t.barcode ? `${t.barcode.barcode}: ${!!t.barcode.redeemed ? 'redeemed' : 'not redeemed'}` : '-'))
        .join(', ')
    switch (status) {
        case 'refunded':
            return cancellation && amount === 0 ? <Status>cancelled</Status> : <Status>refunded</Status>
        case 'cancelled':
            return <Status>cancelled</Status>
        case 'redeemed':
        case 'partially redeemed':
            return (
                <>
                    <Money accountSlug={accountSlug} amount={amount.toFixed(2)} />
                    <Infotip pointer="right" maxWidth="18em">
                        {infotipStatuses}
                    </Infotip>
                </>
            )
        default:
            return <Money accountSlug={accountSlug} amount={amount.toFixed(2)} />
    }
}

interface RefundRowProps {
    accountSlug: string
    ticket: TicketForRefund
    selected: boolean
    selectedTickets: string[]
    confirmRefundView: boolean
    fullRefundOnly: boolean
    cancellation: boolean
    updateHeight: () => void
    handleOrderItemSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleTicketSelect: (orderItemUuid: string, ticketUuids: string[]) => void
}

interface RefundProductState {
    expanded: boolean
}

class RefundRow extends React.Component<RefundRowProps, RefundProductState> {
    constructor(props: RefundRowProps) {
        super(props)
        this.state = {
            expanded: false,
        }
    }

    toggleExpanded = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        let target = e.target as HTMLInputElement
        if (target.type !== 'checkbox') {
            this.setState({ expanded: !this.state.expanded }, () => this.props.updateHeight())
        }
    }

    render() {
        const {
            accountSlug,
            ticket,
            selectedTickets,
            handleOrderItemSelect,
            handleTicketSelect,
            confirmRefundView,
            fullRefundOnly,
            cancellation,
        } = this.props
        const allTicketBarcodes = flatMap(
            ticket.items.filter((i) => i.itemRefundable),
            (x) => x.ticketUuids,
        )
        const selected = allTicketBarcodes.length === selectedTickets.length
        const refundableItems = ticket.items.filter((i) => i.itemRefundable)
        const ticketTotal = refundableItems.reduce((s, i) => s + i.price, 0).toFixed(2)

        return (
            <div>
                <RefundDataRow
                    onClick={(e) => {
                        if ((e.target as Element).tagName !== 'LABEL') {
                            this.toggleExpanded(e)
                        }
                    }}
                    className={classNames('refund-ticket-row expandable', { disabled: !ticket.canRefund })}
                >
                    {!confirmRefundView && !fullRefundOnly && ticket.canRefund && (
                        <Cell>
                            <Checkbox
                                id={`${ticket.orderItemUuid}`}
                                name={`${ticket.orderItemUuid}`}
                                checked={selectedTickets.length > 0}
                                onChange={handleOrderItemSelect}
                                indeterminateState={!selected}
                                data-testid="refund-checkbox"
                            />
                        </Cell>
                    )}
                    {!ticket.canRefund && !fullRefundOnly && <Cell />}
                    <Cell title={ticket.product}>
                        <label htmlFor={`${ticket.orderItemUuid}`}>{ticket.product}</label>
                    </Cell>
                    <Cell align="right">{ticket.items.filter((i) => i.itemRefundable).length}&times;</Cell>
                    <Cell align="right">
                        {ticket.canRefund && !fullRefundOnly && (
                            <Money accountSlug={accountSlug} amount={ticketTotal} />
                        )}
                        {(!ticket.canRefund || (fullRefundOnly && ticket.status !== 'not redeemed')) && (
                            <Status>{ticket.status}</Status>
                        )}
                    </Cell>
                    <ExpandCell expanded={this.state.expanded} />
                </RefundDataRow>
                <Barcodes className={this.state.expanded ? 'expanded' : ''}>
                    {ticket.items.map((item, i) => {
                        const barcodesString = item.barcodeList.join(', ') || '-'
                        const truncatedBarcodeString =
                            barcodesString.length > 4 ? `...${barcodesString.slice(-4)}` : barcodesString
                        return (
                            <RefundDataRow key={item.ticketUuids[0]} className={!item.itemRefundable ? 'disabled' : ''}>
                                {!confirmRefundView && !fullRefundOnly && <Cell />}
                                <Cell>
                                    {!confirmRefundView && !fullRefundOnly && item.itemRefundable && (
                                        <Checkbox
                                            className="barcode-checkbox"
                                            id={item.ticketUuids[0].toString()}
                                            name={item.ticketUuids[0].toString()}
                                            checked={selectedTickets.indexOf(item.ticketUuids[0]) > -1}
                                            onChange={() => handleTicketSelect(ticket.orderItemUuid, item.ticketUuids)}
                                            style={{ marginRight: '1.2em' }}
                                        />
                                    )}
                                    {(!item.itemRefundable || fullRefundOnly) && <Cell />}
                                    <label
                                        htmlFor={item.ticketUuids[0].toString()}
                                        style={{ marginLeft: confirmRefundView ? '2.5em' : '' }}
                                        title={ticket.product}
                                    >
                                        {ticket.product}
                                    </label>
                                </Cell>
                                <Cell align="right" title={barcodesString}>
                                    <span>{truncatedBarcodeString}</span>
                                </Cell>
                                <Cell align="right" className="overflow-allowed">
                                    {!fullRefundOnly && (
                                        <AmountCell
                                            accountSlug={accountSlug}
                                            ticket={ticket}
                                            ticketEntities={item.ticketEntities}
                                            amount={item.price}
                                            status={item.status}
                                            cancellation={cancellation}
                                        />
                                    )}
                                    {fullRefundOnly && item.status !== 'not redeemed' && <Status>{item.status}</Status>}
                                </Cell>
                                <Cell />
                            </RefundDataRow>
                        )
                    })}
                </Barcodes>
            </div>
        )
    }
}

export default RefundRow
