import styleIcon from 'uiComponents/studio/icons/style.svg'
import { SectionObject } from 'uiComponents/studio/menuItem'
import restrictionsIcon from 'uiComponents/studio/icons/target.svg'
import { fontOptions } from '../shared'

export interface FormDefinitionSchema {
    style: SectionObject
    targeting: SectionObject
}

export const CheckoutFormDefinition: FormDefinitionSchema = {
    style: {
        name: 'style',
        label: 'Style',
        header: 'Design your Look & Feel',
        icon: styleIcon,
        description:
            "To start, we use a default style and default colors for the Checkout, but you can customize them below to match your website's look and feel.",
        sections: [
            {
                name: 'Global settings',
                fields: [
                    {
                        name: 'logo',
                        type: 'upload',
                        label: 'Upload a logo',
                        span: 12,
                        path: 'logo',
                        defaultValue: 'true',
                    },
                    {
                        name: 'brand_color',
                        type: 'picker',
                        label: 'Theme color',
                        span: 6,
                        path: 'brandColor',
                        defaultValue: '',
                    },
                ],
            },
            {
                name: 'Button settings',
                fields: [
                    {
                        name: 'border_radius',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'Square',
                                value: '0',
                            },
                            {
                                name: 'Slightly rounded',
                                value: '6',
                            },
                            {
                                name: 'Rounded',
                                value: '10',
                            },
                        ],
                        label: 'Button corners',
                        span: 8,
                        path: 'borderRadius',
                        defaultValue: '6',
                    },
                    {
                        name: 'button_style',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'Solid',
                                value: 'solid',
                            },
                            {
                                name: 'Gradient',
                                value: 'gradient',
                            },
                        ],
                        label: 'Button style',
                        span: 8,
                        path: '',
                        defaultValue: 'solid',
                    },
                    {
                        name: 'primary_color',
                        type: 'picker',
                        label: 'Button color',
                        span: 6,
                        path: 'primaryColor',
                        defaultValue: '#000000',
                    },
                    {
                        name: 'primary_color_gradient_stop',
                        type: 'picker',
                        label: 'Button color gradient stop',
                        span: 6,
                        path: 'primaryColorGradientStop',
                        defaultValue: '#000000',
                    },
                    {
                        name: 'secondary_color',
                        type: 'picker',
                        label: 'Notice button color',
                        span: 6,
                        path: 'secondaryColor',
                        defaultValue: '#000000',
                        infotip:
                            'This button will appear on the 15-minute reservation notification window at the end of the Checkout flow.',
                    },
                    {
                        name: 'secondary_color_gradient_stop',
                        type: 'picker',
                        label: 'Notice button color gradient stop',
                        span: 6,
                        path: 'secondaryColorGradientStop',
                        defaultValue: '#000000',
                    },
                ],
            },
            {
                name: 'Typography',
                fields: [
                    {
                        name: 'primary_font',
                        type: 'select',
                        availableOptions: [...fontOptions],
                        label: 'Primary font-family',
                        span: 8,
                        path: 'primaryFont',
                        defaultValue: 'Roboto',
                    },
                    {
                        name: 'custom_font_name_primary',
                        type: 'text',
                        label: 'Custom primary font-family',
                        span: 6,
                        path: 'primaryFontCustomName',
                        defaultValue: '',
                    },
                    {
                        name: 'secondary_font',
                        type: 'select',
                        availableOptions: [...fontOptions],
                        label: 'Secondary font-family',
                        span: 8,
                        path: 'secondaryFont',
                        defaultValue: 'Roboto',
                    },
                    {
                        name: 'custom_font_name_secondary',
                        type: 'text',
                        label: 'Custom secondary font-family',
                        span: 6,
                        path: 'secondaryFontCustomName',
                        defaultValue: '',
                    },
                    {
                        name: 'custom_font',
                        type: 'textArea',
                        label: 'Custom font-family import (Css)',
                        span: 12,
                        path: 'customFont',
                        defaultValue: '',
                        infotip: 'To use your brands’ font, enter its Css script below.',
                    },
                ],
            },
        ],
    },
    targeting: {
        name: 'targeting',
        label: 'Targeting',
        header: 'Set your Restrictions',
        icon: restrictionsIcon,
        feature: 'CRMAudiencesPage',
        description: 'Choose the pages you want your Checkout to be shown and/or excluded.',
        sections: [
            {
                name: 'Audience',
                fields: [],
            },
        ],
    },
}
