import React, { CSSProperties, FC } from 'react'
import { format } from 'date-fns'
import { PricingTimeslot } from './types'
import { Card } from '@mui/material'
import './timeslots.scss'
import { convertTimeFormat, DateFormats } from 'utils'

interface Props {
    timeslots: PricingTimeslot[]
    selectedDate: Date
    style?: CSSProperties
}

const Timeslots: FC<Props> = ({ timeslots, selectedDate, style }) => {
    const formatTimes = (timeSlot: PricingTimeslot) =>
        `${convertTimeFormat(timeSlot.startTime!)} - ${timeSlot.endTime ? convertTimeFormat(timeSlot.endTime) : ''}`

    return (
        <Card variant="outlined" style={style} className="gps-timeslots-card">
            <h6>{`${format(selectedDate, DateFormats.LONG_DATE)} Timeslots`}</h6>
            <div className="timeslots">
                {timeslots.map((timeslot, index) => (
                    <div className="timeslot" key={index}>
                        <span className="label">{formatTimes(timeslot)}</span>
                        <span style={{ color: timeslot.color }}>{timeslot.price ?? 'n/a'}</span>
                    </div>
                ))}
            </div>
        </Card>
    )
}

export default Timeslots
