import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import { InlineEdit } from 'uiComponents/input'
import { ActionButtonA } from 'uiComponents/buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { History } from 'history'
import { NestedContainerWrapper } from 'uiComponents/table/nestedContainer'
import { DisabledItemHoverInfotip } from 'uiComponents/infotip'
import { SmallText, SecondaryText } from 'uiComponents/typography'
import { AttachedProduct } from 'products/attachedCategoriesList'
import AttachedProductsTreeList from 'products/attachedProductsTreeList'
import { Template } from 'ticketTemplates/ticketTemplatesService'
import { DateFormats, formatISOString } from 'utils/dates'

const BottomContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25em 1.75em;
    border-bottom: 2px solid ${(props) => props.theme.colors.background};

    &:last-child {
        border: none;
    }
`
const TemplateName = styled.div`
    font-weight: bold;
    margin-bottom: 0.9em;
`
const ActionSection = styled.div`
    display: flex;
    align-items: center;
    height: 2em;
`
const TrashIconWrapper = styled.div`
    border-radius: 1em;
    background-color: ${(props) => props.theme.colors.tableRow};
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

const TrashIcon = styled(FontAwesomeIcon)`
    font-size: 0.75em;
    color: ${(props) => props.theme.colors.textLight};
`

export const ContainerHeader = styled.div`
    background-color: ${(props) => props.theme.colors.tableRow};
    padding: 0.4em 1.875rem;
    margin-bottom: 0.9em;
`

const LighterText = styled(SecondaryText)`
    font-weight: normal;
`

const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const ShowAllLink = styled.span`
    color: ${(props) => props.theme.colors.boyBlue};
    text-decoration: underline;
    cursor: pointer;
`

interface ListItemProps {
    template: Template
    onPreview: () => void
    onNameEdit: (v: string) => void
    onDelete: () => void
    accountSlug: string
    history: History
    path: string
}

export function ListItem(props: ListItemProps) {
    const [showProducts, setShowProducts] = useState<boolean>(false)
    const [attachedProducts, setAttachedProducts] = useState<AttachedProduct[]>([])

    useEffect(() => {
        setAttachedProducts(props.template.products || [])
    }, [])

    return (
        <>
            <ContainerHeader>
                {!!props.template.modifiedAt && (
                    <>
                        {props.template.modifiedBy && (
                            <>
                                <LighterText>
                                    <SmallText>Modified by </SmallText>
                                </LighterText>
                                <SmallText>{props.template.modifiedBy}, </SmallText>
                            </>
                        )}
                        <SmallText>{formatISOString(props.template.modifiedAt, DateFormats.LONG_DATE)}</SmallText>
                    </>
                )}
            </ContainerHeader>
            <BottomContainer>
                <div>
                    <TemplateName>
                        <InlineEdit
                            id="template-name"
                            value={props.template.name}
                            maxLength={100}
                            onEditAccept={props.onNameEdit}
                            responsiveWidth
                        />
                    </TemplateName>
                    <ActionSection>
                        <DisabledItemHoverInfotip
                            active={false}
                            infotipText="This is a custom template. Please contact Convious customer support team to make any changes."
                            width="30em"
                        >
                            <ActionButtonA
                                kind="action"
                                secondary
                                href={`/account/${props.accountSlug}/engage/${props.path}/${props.template.id}`}
                            >
                                Edit
                            </ActionButtonA>
                        </DisabledItemHoverInfotip>
                    </ActionSection>
                </div>
                <RightSide>
                    <TrashIconWrapper onClick={() => props.onDelete()}>
                        <TrashIcon icon={faTrash} />
                    </TrashIconWrapper>
                    <ActionSection>
                        <SecondaryText>
                            {attachedProducts.length} product(s) assigned&nbsp;
                            {showProducts && (
                                <ShowAllLink onClick={(e) => setShowProducts(false)}>Hide all</ShowAllLink>
                            )}
                            {!showProducts && attachedProducts.length > 0 && (
                                <ShowAllLink onClick={(e) => setShowProducts(true)}>Show all</ShowAllLink>
                            )}
                        </SecondaryText>
                    </ActionSection>
                </RightSide>
            </BottomContainer>
            {showProducts && attachedProducts.length > 0 && (
                <NestedContainerWrapper grade={0} className="expanded">
                    <AttachedProductsTreeList
                        products={attachedProducts || []}
                        accountSlug={props.accountSlug}
                        history={props.history}
                    />
                </NestedContainerWrapper>
            )}
        </>
    )
}
