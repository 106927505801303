import * as React from 'react'
import { PageTitle, PageHeading } from 'uiComponents/typography'
import './index.scss'
import { RELEASED_PAGE } from './htmlComponents'

function ReleaseNotes() {
    return (
        <div>
            <PageTitle>What&apos;s New</PageTitle>
            <PageHeading style={{ marginBottom: '2rem' }}>
                <div>See the latest product release notes or read the old news.</div>
            </PageHeading>
            <div
                className="content"
                dangerouslySetInnerHTML={{
                    __html: RELEASED_PAGE,
                }}
             />
        </div>
    )
}

export default ReleaseNotes
