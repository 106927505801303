import React from 'react'
import FieldWrapper, { FieldWrapperProps } from '../fieldWrapper'
import SliderInput, { SliderInputProps } from './sliderInput'

export interface SliderFieldProps extends Omit<FieldWrapperProps, 'variant'>, SliderInputProps {}

const SliderField: React.FC<SliderFieldProps> = ({
    status,
    error,
    id,
    label,
    validationMessageProps,
    ...sliderProps
}) => {
    return (
        <FieldWrapper
            status={status}
            error={error}
            id={id}
            label={label}
            validationMessageProps={validationMessageProps}
        >
            <SliderInput {...sliderProps} />
        </FieldWrapper>
    )
}

export default SliderField
