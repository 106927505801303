import { State } from 'store'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface ContractsState {
    isEditable: boolean
}

const contracts = createSlice({
    name: 'contracts',
    initialState: {
        isEditable: false,
    } as ContractsState,
    reducers: {
        setIsEditable(state: ContractsState, action: PayloadAction<boolean>) {
            state.isEditable = action.payload
        },
    },
})

export const isContractFormEditable = (state: State) => state.contracts.isEditable

export const {
    actions: { setIsEditable },
    name,
    getInitialState,
} = contracts

export default contracts.reducer
