import * as React from 'react'
import { FieldType } from 'uiComponents/studio/form/fieldComponentSchema'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { ConfigurationUnit, SectionObject } from 'uiComponents/studio/menuItem'
import FormField from './formField'
import { usePromotion } from './context'
import { PromotionConfigPaths } from './types'
import ConsentField from './consentField'

interface Props {
    section: SectionObject
}

const Section = (props: Props) => {
    const { getValueForPath } = usePromotion()

    const renderField = (field: FieldType) => {
        return (
            <FormField
                key={field.name}
                field={field}
                initialValue={getValueForPath(field.path as PromotionConfigPaths)}
            />
        )
    }

    const renderConsentField = (field: FieldType) => <ConsentField key={field.name} field={field} initialValue="" />

    const renderSectionWrapper = (section: ConfigurationUnit) => (
        <SectionWrapper title={section.name} key={section.name}>
            {section.fields?.map((field) => {
                if (field.path === 'marketingConsent') {
                    return renderConsentField(field)
                } else {
                    return renderField(field)
                }
            })}
        </SectionWrapper>
    )

    return <>{props.section.sections.map((section) => renderSectionWrapper(section))}</>
}

export default Section
