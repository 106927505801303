import { IOption } from 'crm/audiences/types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-typed'
import ProductSelectionBreadcrumb from './breadcrumb'
import ProductElement from './productElement'
import ProductSearch from './search'

const Container = styled.div`
    width: 100%;
`

const Label = styled.div`
    font-size: 1em;
    font-weight: 500;
    padding: 1em 0;
`

const Content = styled.div`
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors.border};
`

interface IProductValue {
    products: string[]
    product_list_path: string[]
}

interface FilterProductProps {
    label: string
    options: IOption[]
    onChange: (value: IProductValue) => void
    value: IProductValue
}

const ProductSelection = (props: FilterProductProps) => {
    const [shownOptions, setShownOptions] = useState<IOption[]>(props.options)
    const [productListBreadcrumb, setProductListBreadcrumb] = useState<IOption[]>([])
    const [selectedProductsUUID, setSelectedProductsUUID] = useState<string[]>(props.value.products)
    const [selectedProductListPathHash, setSelectedProductListPathHash] = useState<string[]>(
        props.value.product_list_path,
    )

    useEffect(() => {
        setSelectedProductsUUID(props.value.products)
    }, [props.value.products])

    useEffect(() => {
        setSelectedProductListPathHash(props.value.product_list_path)
    }, [props.value.product_list_path])

    useEffect(() => {
        const findSelectedProducts = (options: IOption[]) => {
            options.forEach((option) => {
                if (option.paths_id!.includes(props.value.product_list_path[0]) && !option.is_product) {
                    setProductListBreadcrumb((prev) => [...prev, option])
                    if (option.is_last) {
                        setShownOptions(option.children!)
                    }
                    if (option.children && option.children.length > 0) {
                        findSelectedProducts(option.children)
                    }
                }
            })
        }
        if (props.value.products.length > 0) {
            findSelectedProducts(props.options)
        } else {
            setProductListBreadcrumb([])
            setShownOptions(props.options)
        }
    }, [props.value.products, props.value.product_list_path])

    useEffect(() => {
        props.onChange({
            products: selectedProductsUUID,
            product_list_path: selectedProductListPathHash,
        })
    }, [selectedProductsUUID, selectedProductListPathHash])

    const onChange = (product: IOption) => {
        if (product.is_product) {
            const isProductSelected = selectedProductsUUID.includes(product.value as string)
            if (isProductSelected) {
                setSelectedProductsUUID((prev) => prev.filter((p) => p !== product.value))
                setSelectedProductListPathHash((prev) => (selectedProductsUUID.length === 1 ? [] : prev))
            } else {
                setSelectedProductsUUID((prev) => [...prev, product.value as string])
                setSelectedProductListPathHash(product.paths_id!)
            }
        } else {
            setShownOptions(product.children!)
            setProductListBreadcrumb((prev) => [...prev, product])
        }
    }

    const onGoBackToParentProductList = () => {
        if (productListBreadcrumb.length === 1) {
            setShownOptions(props.options)
            setProductListBreadcrumb([])
            return
        }
        setSelectedProductListPathHash([])
        setSelectedProductsUUID([])
        setShownOptions(productListBreadcrumb[productListBreadcrumb.length - 2].children!)
        setProductListBreadcrumb((prev) => prev.slice(0, prev.length - 1))
    }

    return (
        <Container>
            <Label>{props.label}</Label>
            <ProductSearch
                onChange={onChange}
                options={props.options}
                selectedProductListPathHash={selectedProductListPathHash}
                selectedProductsUUID={selectedProductsUUID}
            >
                <>
                    <ProductSelectionBreadcrumb
                        selectedParentProductList={productListBreadcrumb[productListBreadcrumb.length - 1]}
                        onGoBackToParentProductList={onGoBackToParentProductList}
                    />
                    <Content>
                        {shownOptions.map((option) => (
                            <ProductElement
                                key={option.value as string}
                                product={option}
                                onChange={onChange}
                                selectedValues={selectedProductsUUID}
                            />
                        ))}
                    </Content>
                </>
            </ProductSearch>
        </Container>
    )
}

export default ProductSelection
