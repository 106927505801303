import { getOrderStatus, OrderStatusKeys } from 'orders/schema'
import React from 'react'
import { Text } from 'uiComponents/typography'

interface OrderStatusProps {
    value: OrderStatusKeys
}

const OrderStatus: React.FC<OrderStatusProps> = ({ value }) => {
    const status = getOrderStatus(value)

    return <Text status={status.style}>{status.text}</Text>
}

export default OrderStatus
