import { createApi } from '@reduxjs/toolkit/query/react'
import * as config from 'config'
import { authenticatedHttpServiceQuery } from 'utils/reduxQuery'
import { FundBalance, PartnerFundLogParams, PartnerFundParams } from './types'

interface PerformFundTransferRequestBody {
    accountSlug: string
    amount: FundBalance
    capturedPaymentReference: string
}

export const adminApi = createApi({
    reducerPath: 'adminApi',
    tagTypes: ['listPartnerRefund', 'getPartnerRefundLog'],
    baseQuery: authenticatedHttpServiceQuery({
        baseUrl: config.getRequired('moneta-endpoint'),
    }),
    endpoints: () => ({}),
})

export interface FundQueryParams {
    accountSlug: string
}

const extendedAdminApi = adminApi.injectEndpoints({
    endpoints: (builder) => ({
        getPartnerFundList: builder.query<PartnerFundParams[], any>({
            providesTags: ['listPartnerRefund'],
            query: () => ({
                url: 'api/v1/fund_transfers/accounts/',
            }),
        }),
        getPartnerFundLog: builder.query<PartnerFundLogParams[], FundQueryParams>({
            providesTags: ['getPartnerRefundLog'],
            query: ({ accountSlug }) => ({
                url: `api/v1/fund_transfers/accounts/${accountSlug}/fund_transfers/`,
            }),
        }),
        performFundTransfer: builder.mutation<any, PerformFundTransferRequestBody>({
            invalidatesTags: ['listPartnerRefund', 'getPartnerRefundLog'],
            query: (params) => ({
                url: `api/v1/fund_transfers/accounts/${params.accountSlug}/`,
                method: 'POST',
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),
        cancelFundTransfer: builder.mutation({
            invalidatesTags: ['listPartnerRefund', 'getPartnerRefundLog'],
            query: ({ accountSlug, fundTransferId }) => ({
                url: `api/v1/fund_transfers/accounts/${accountSlug}/fund_transfers/${fundTransferId}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),
    }),
})

export const { reducer, reducerPath } = adminApi
export const {
    useGetPartnerFundListQuery,
    useGetPartnerFundLogQuery,
    usePerformFundTransferMutation,
    useCancelFundTransferMutation,
} = extendedAdminApi
