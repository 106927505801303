import React from 'react'
import styled from 'styled-typed'
import { Pointer, InfoBox } from 'uiComponents/infotip'

const Wrapper = styled.div`
    width: 3em;
    margin: 0 0.15em;
    display: flex;
    position: relative;
`

const BoldText = styled.span`
    font-weight: bold;
`

interface PriceTypeBoxInfotipProps {
    show: boolean
    children: React.ReactNode
    text: string
}

interface PriceTypeBoxInfotipState {
    visible: boolean
}

export class PriceSettingInfotip extends React.Component<PriceTypeBoxInfotipProps, PriceTypeBoxInfotipState> {
    popup: HTMLDivElement | null = null
    constructor(props: PriceTypeBoxInfotipProps) {
        super(props)
        this.state = {
            visible: false,
        }
    }

    componentDidUpdate(prevProps: PriceTypeBoxInfotipProps) {
        if (this.props.show !== this.state.visible) {
            this.setState({ visible: this.props.show })
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.outsideClick)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.outsideClick)
    }

    setPopupRef = (node: HTMLDivElement) => {
        this.popup = node
    }

    outsideClick = (ev: MouseEvent) => {
        if (this.state.visible) {
            ev.stopPropagation()
            if (this.popup && this.popup.contains && !this.popup.contains(ev.target as Node)) {
                this.setState({
                    visible: false,
                })
            }
        }
    }
    render() {
        return (
            <Wrapper ref={this.setPopupRef}>
                {this.props.children}
                <InfoBox
                    bottom={false}
                    pointer="left"
                    maxWidth="16em"
                    className={this.state.visible ? 'message-visible tooltip-box' : 'tooltip-box'}
                    style={{ bottom: '2.8em', left: '.2em' }}
                >
                    <Pointer pointer="left" />
                    <div>
                        <BoldText>{this.props.text ? this.props.text : ''}</BoldText>
                    </div>
                </InfoBox>
            </Wrapper>
        )
    }
}
