import { AsyncAction } from 'store'
import { loadAccountFeatures } from 'features/actions.async'
import { activeAccountChanged, dateLocaleChanged } from './actions'
import { getIsUSPartner } from 'auth/selectors'

export function changeActiveAccount(slug: string | null): AsyncAction<Promise<void>> {
    return async (dispatch): Promise<void> => {
        if (slug) {
            await dispatch(loadAccountFeatures(slug))
        }
        await dispatch(activeAccountChanged(slug))
        await dispatch(changeDateLocale())
    }
}

export function changeDateLocale(): AsyncAction<Promise<void>> {
    return async (dispatch, getState): Promise<void> => {
        const dateLocale = getIsUSPartner(getState()) ? 'US' : 'INTL'
        await dispatch(dateLocaleChanged(dateLocale))
    }
}
