import * as React from 'react'
import { withPermission } from './hocs'

interface PermissionProps {
    name: string
    invert?: boolean
    children: React.ReactNode
    accountSlug: string
    hasPermission: (permission: string, accountSlug: string) => boolean
}

function PermissionRequired({ name, invert, children, accountSlug, hasPermission }: PermissionProps) {
    let permitted = hasPermission(name, accountSlug)
    if (invert) {
        permitted = !permitted
    }

    if (!permitted || !children) {
        return null
    }

    return <>{children}</>
}

export default withPermission(PermissionRequired)
