import React, { useState, useContext } from 'react'
import { TableLoader } from 'uiComponents/loaders'
import { InlineDataTable, HeaderRow, TableHeader, Cell, ExpandCell, DynamicRow } from 'uiComponents/table'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { NestedContainerWrapper } from 'uiComponents/table/nestedContainer'
import DiscountRulesTableActions from './actions'
import InlineName from './inlineName'
import StatusCell from './status'
import RuleDefinition from '../ruleDefinition'
import { DiscountRulesContext } from 'products/discountRules/table/tableContext'
import { MetadataContext } from 'products/discountRules/metadataContext'
import { DiscountRule } from 'products/discountRules/discountRulesService'
import { DateFormats, formatISOString } from 'utils/dates'

function DiscountRulesList() {
    const { rulesList, updating, sort, onSortChanged } = useContext(DiscountRulesContext)
    const { navigation } = useContext(MetadataContext)
    const [currentExpanded, setCurrentExpanded] = useState<number | null>(null)

    const toggleExpanded = (id: number) => {
        const expanded = currentExpanded === id
        expanded ? setCurrentExpanded(null) : setCurrentExpanded(id)
        navigation.addQueryWithReplace({
            discountRule: expanded ? null : id.toString(),
        })
    }

    const formatValidityCell = (rule: DiscountRule) => {
        if (!!rule.validFrom && !!rule.validTo) {
            return `${formatISOString(rule.validFrom, DateFormats.LONG_DATE)} - ${formatISOString(
                rule.validTo,
                DateFormats.LONG_DATE,
            )}`
        }
        if (!rule.validFrom && !!rule.validTo) {
            return `Until ${formatISOString(rule.validTo, DateFormats.LONG_DATE)}`
        }
        if (!!rule.validFrom && !rule.validTo) {
            return `From ${formatISOString(rule.validFrom, DateFormats.LONG_DATE)}`
        }
        return 'Unlimited validity'
    }

    const columnWidths = ['4em', '20em', null, '27em', '11em']

    return (
        <InlineDataTable columnWidths={columnWidths} bordered style={{ position: 'relative' }}>
            <HeaderRow>
                <TableHeader nonInteractive noPadding />
                <TableHeader sortKey="enabled" sorting={sort} changeSort={onSortChanged}>
                    Status
                </TableHeader>
                <TableHeader sortKey="name" sorting={sort} changeSort={onSortChanged}>
                    Rule name
                </TableHeader>
                <TableHeader sortKey="validity" sorting={sort} changeSort={onSortChanged}>
                    Validity
                </TableHeader>
                <TableHeader nonInteractive />
            </HeaderRow>
            {updating && <TableLoader />}
            {!updating && (
                <>
                    {!updating && rulesList.length === 0 && <NoResultsRow text="There are no discount rules yet." />}
                    {rulesList.map((rule) => {
                        const expanded = rule.id === currentExpanded
                        return (
                            <div key={rule.id}>
                                <DynamicRow
                                    interactive
                                    highlighted={expanded}
                                    onClick={() => toggleExpanded(rule.id)}
                                    className={expanded ? 'expanded' : ''}
                                >
                                    <ExpandCell className="expand" expanded={expanded} />
                                    <Cell>
                                        <StatusCell rule={rule} />
                                    </Cell>
                                    <Cell title={rule.name}>
                                        <InlineName rule={rule} />
                                    </Cell>
                                    <Cell>{formatValidityCell(rule)}</Cell>
                                    <Cell align="right" onClick={(e) => e.stopPropagation()}>
                                        <DiscountRulesTableActions rule={rule} />
                                    </Cell>
                                </DynamicRow>
                                {expanded && (
                                    <NestedContainerWrapper grade={1} className="expanded">
                                        <RuleDefinition rule={rule} />
                                    </NestedContainerWrapper>
                                )}
                            </div>
                        )
                    })}
                </>
            )}
        </InlineDataTable>
    )
}

export default DiscountRulesList
