import * as React from 'react'
import styled from 'styled-typed'

export const RadioWrapper = styled.label`
    display: flex;
    align-items: center;
    margin: 1em 0;
    width: fit-content;
    cursor: pointer;
`

export const RadioText = styled.div`
    font-size: 0.875em;
    line-height: 1.5em;

    &.disabled {
        opacity: 0.5;
        cursor: default;
    }
`

export interface RadioProps {
    disabled?: boolean
    component?: any
    checkMarkSize?: checkMarkSizes
}

export const RadioInput = styled.input.attrs<React.InputHTMLAttributes<HTMLInputElement>>((props) => ({
    type: 'radio',
}))`
    position: absolute;
    opacity: 0;
    cursor: pointer;
`

const checkMarkSize = {
    small: '0.875rem',
    medium: '1rem',
    large: '1.25rem',
}

type checkMarkSizes = keyof typeof checkMarkSize

const Checkmark = styled.span<RadioProps>`
    height: ${(props) => checkMarkSize[props.checkMarkSize ?? 'large']};
    width: ${(props) => checkMarkSize[props.checkMarkSize ?? 'large']};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
    border: 1px solid ${(p) => p.theme.colors.aluminium};

    &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: none;
        width: calc(${(props) => checkMarkSize[props.checkMarkSize ?? 'large']} - 0.375rem);
        height: calc(${(props) => checkMarkSize[props.checkMarkSize ?? 'large']} - 0.375rem);
        border-radius: 50%;
        background: ${(p) => p.theme.colors.boyBlue};
    }

    ${RadioInput}:checked + & {
        border-color: ${(p) => p.theme.colors.boyBlue};

        &:after {
            display: block;
        }
    }
`

const RadioButtonContainer = styled.div<RadioProps>`
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    user-select: none;
    margin-right: 0.5rem;

    &.disabled {
        opacity: 0.5;
        cursor: default;
    }
`

const DefaultRadio = (props: Omit<RadioProps, 'component'> & React.InputHTMLAttributes<HTMLInputElement>) => {
    return (
        <RadioButtonContainer className={props.disabled ? 'disabled' : ''}>
            <RadioInput {...props} />
            <Checkmark checkMarkSize={props.checkMarkSize} />
        </RadioButtonContainer>
    )
}

export function Radio({
    component: RadioComponent = DefaultRadio,
    ...props
}: RadioProps & React.InputHTMLAttributes<HTMLInputElement>) {
    return <RadioComponent {...props} />
}
