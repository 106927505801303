import React, { createContext, useState, useContext, useEffect, useCallback, useMemo } from 'react'
import { match as RouteMatch } from 'react-router-dom'
import { withNavigation } from 'hocs'
import { WithNavigationProps } from 'hocs'
import { CopyEditorServiceContext } from 'copyEditor/context'
import { DiscountRulesServiceContext } from 'products/discountRules/context'
import { MetadataContext } from 'products/discountRules/metadataContext'
import { DiscountRule } from 'products/discountRules/discountRulesService'
import { ArticleServiceContext } from 'admin/context'
import { Locale, Translations } from 'admin/articleService'
import { SingleSelectOption } from 'uiComponents/input'
import { useGetAllArticles, useGetAllCategories } from '../../redux'
import { ArticleFromApi } from '../../types'

const dummyRule: DiscountRule = {
    categoryUuid: '',
    enabled: false,
    id: 0,
    name: '',
    productUuid: '',
    rulePayload: {
        cmsKey: '',
        type: 'BUY_X_GET_Y_FREE',
        numberOfItemsPaid: 1,
        numberOfItemsFree: 1,
        productUuidPaid: '',
        productUuidFree: '',
    },
    validFrom: null,
    validTo: null,
}

interface RuleFormContextProps {
    loading: boolean
    markErrors: boolean
    activeLangIndex: number
    rule: DiscountRule
    formTranslations: Translations
    catOptions: SingleSelectOption[]
    articles: ArticleFromApi[]
    locales: Locale[]
    match: RouteMatch<{ ruleId: string }>
    setLoading: (value: boolean) => void
    setMarkErrors: (value: boolean) => void
    setActiveLangIndex: (value: number) => void
    updateRule: (updatedRule: DiscountRule) => void
    updateTranslations: (updatedTranslations: Translations) => void
}

export const RuleFormContext = createContext<RuleFormContextProps>(undefined as any)

interface StateProps extends WithNavigationProps<{ ruleId: string }> {}

const RulesFormContextState: React.FC<StateProps> = ({ children, match }) => {
    const articleService = useContext(ArticleServiceContext)
    const copyEditorService = useContext(CopyEditorServiceContext)
    const discountRulesService = useContext(DiscountRulesServiceContext)
    const { accountSlug, replaceMessages } = useContext(MetadataContext)
    const [loading, setLoading] = useState<boolean>(true)
    const [markErrors, setMarkErrors] = useState<boolean>(false)
    const [activeLangIndex, setActiveLangIndex] = useState<number>(0)
    const [rule, setRule] = useState<DiscountRule>(dummyRule)
    const [locales, setLocales] = useState<Locale[]>([{ code: 'en', name: 'English' }])
    const [formTranslations, setFormTranslations] = useState<Translations>({
        key: '',
        text: { en: '' },
    })
    const allCategories = useGetAllCategories()
    const catOptions = useMemo(() => {
        const parentCatIds = allCategories.map((cat) => cat.parentCategory?.id)

        return allCategories.map((category) => ({
            value: category.id,
            name: category.name['en'],
            disabled: parentCatIds?.includes(category.id),
            parentId: category?.parentCategory?.id,
        })) as SingleSelectOption[]
    }, [allCategories])
    const articles = useGetAllArticles()

    async function setTranslationsFromCopy(cmsKey: string, languages: Locale[]) {
        const copyDetails = await copyEditorService.getCopyDetails(accountSlug, cmsKey)
        const copyTranslations = {}
        languages.forEach((l) => {
            const text = copyDetails.custom.find((x) => x.locale === l.code)?.text
            copyTranslations[l.code] = text || ''
        })
        setFormTranslations({
            key: cmsKey,
            text: copyTranslations,
        })
    }

    async function getDetails() {
        setLoading(true)
        try {
            const locales = await articleService.getAccountLocales(accountSlug)
            if (match.params.ruleId !== 'new') {
                const ruleData = await discountRulesService.getDiscountRuleDetails(accountSlug, match.params.ruleId)
                delete ruleData.description
                if (ruleData.rulePayload.cmsKey) {
                    await setTranslationsFromCopy(ruleData.rulePayload.cmsKey, locales.locales)
                }
                setRule(ruleData)
            }
            setLocales(locales.locales)
            setLoading(false)
        } catch {
            setLoading(false)
            replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getDetails()
    }, [])

    const updateRule = useCallback((updatedRule: DiscountRule) => {
        setRule(updatedRule)
    }, [])

    const updateTranslations = useCallback((updatedTranslations: Translations) => {
        setFormTranslations(updatedTranslations)
    }, [])

    return (
        <RuleFormContext.Provider
            value={{
                loading,
                markErrors,
                activeLangIndex,
                rule,
                formTranslations,
                catOptions,
                articles,
                locales,
                match,
                setLoading,
                setMarkErrors,
                setActiveLangIndex,
                updateRule,
                updateTranslations,
            }}
        >
            {children}
        </RuleFormContext.Provider>
    )
}

export default withNavigation(RulesFormContextState)
