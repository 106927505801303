import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import Svg from 'svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faBan, faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { Tooltip } from './tooltip'

const DropDownContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5em 0.75em;
    gap: 0.625em;
    border: 0.063em ${(props) => props.theme.colors.border} solid;
    border-radius: 0.25em;
    cursor: pointer;
`

export const IconContainer = styled.div`
    width: 2em;
    height: 2em;
    border-radius: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: none;
`

const Icon = styled(Svg)`
    width: 1.5em;
    height: 1.5em;
    color: white;
`

const Chevron = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.colors.aluminiumShades[70]};
`

const IconsGridContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    width: 15.75em;
    padding: 1em;
    gap: 0.25em;
    border-radius: 0.25em;
    border: 0.063em ${(props) => props.theme.colors.border} solid;
    background-color: white;
    margin: 0.5em 0 0 0;
    z-index: 3;
`

const IconsGridItem = styled.div`
    width: 2.5em;
    height: 2.5em;
    border: 0.063em ${(props) => props.theme.colors.border} solid;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25em;
    cursor: pointer;
    &:hover {
        background: ${(props) => props.theme.colors.background};
    }
`

const TooltipText = styled.div`
    color: ${(props) => props.theme.colors.white};
    font-size: 0.8em;
`

type MapIcons = {
    name: string
    icon: any
}

interface IconSelectorProps {
    icons: MapIcons[]
    selectedIcon: MapIcons
    onIconSelect(icon: MapIcons): void
    color?: string
}

const IconSelector = ({ selectedIcon, icons, onIconSelect, color }: IconSelectorProps) => {
    const theme = useTheme()
    const [showIcons, setShowIcons] = useState<boolean>(false)
    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)

    return (
        <div>
            <DropDownContainer onClick={() => setShowIcons((prev) => !prev)}>
                <IconContainer style={{ backgroundColor: selectedIcon.icon && (color || theme.colors.convious) }}>
                    {selectedIcon.icon ? (
                        <Icon src={selectedIcon.icon} />
                    ) : (
                        <FontAwesomeIcon icon={faBan as IconProp} size="sm" />
                    )}
                </IconContainer>
                <Chevron icon={(showIcons ? faChevronUp : faChevronDown) as IconProp} />
            </DropDownContainer>
            {showIcons && (
                <IconsGridContainer>
                    {icons.map((icon) => (
                        <IconsGridItem
                            key={icon.name}
                            onClick={() => {
                                onIconSelect(icon)
                                setShowIcons(false)
                            }}
                        >
                            <IconContainer
                                style={{
                                    backgroundColor: icon.icon && (color || theme.colors.convious),
                                    position: 'relative',
                                }}
                                onMouseEnter={() => !icon.icon && setTooltipVisible(true)}
                                onMouseLeave={() => setTooltipVisible(false)}
                            >
                                {tooltipVisible && !icon.icon && (
                                    <Tooltip
                                        style={{ position: 'absolute', left: -95, bottom: -8, whiteSpace: 'nowrap' }}
                                        direction="left"
                                    >
                                        <TooltipText>no icon</TooltipText>
                                    </Tooltip>
                                )}
                                {icon.icon ? (
                                    <Icon src={icon.icon} />
                                ) : (
                                    <FontAwesomeIcon icon={faBan as IconProp} size="sm" />
                                )}
                            </IconContainer>
                        </IconsGridItem>
                    ))}
                </IconsGridContainer>
            )}
        </div>
    )
}

export default IconSelector
