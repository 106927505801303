import * as React from 'react'
import { withCurrency, Currency } from './moneyHoc'

interface MoneyProps {
    amount: number | string
    accountSlug: string
    formatCurrency: (amount: number | string, accountSlug: string) => React.ReactNode
    getCurrency: (accountSlug: string) => Currency
}

function Money({ amount, accountSlug, formatCurrency }: MoneyProps) {
    return <>{formatCurrency(amount, accountSlug)}</>
}

export default withCurrency(Money)
