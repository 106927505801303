import React from 'react'
import { useField } from 'formik'

import FieldWrapper, { FieldVariant } from '../fieldWrapper'
import { useCustomId, useGetComponentState } from '../_shared/hooks'
import { Toggle, ToggleComponentProps } from './toggle'

interface ToggleFieldFormikProps extends Omit<ToggleComponentProps, 'isOn' | 'onClick'> {
    name: string
    id?: string
    label?: string | JSX.Element
    variant?: FieldVariant
    onClick?: (value: boolean) => void
    children?: (props: ToggleComponentProps) => React.ReactElement
}

const ToggleFieldFormik: React.FC<ToggleFieldFormikProps> = ({
    name,
    label,
    id,
    children: Children,
    onClick,
    ...props
}) => {
    const [{ onChange, value, ...field }, { error }, { setValue }] = useField({
        name,
        type: 'checkbox',
    })
    const state = useGetComponentState(name)
    const innerId = useCustomId(id)

    const handleOnClick = (value: boolean) => (onClick ? onClick(value) : setValue(value))

    const customProps = {
        ...props,
        isOn: value,
        onClick: handleOnClick,
        ...field,
        id: innerId,
    }

    return (
        <FieldWrapper label={label} error={error} id={innerId} status={state} variant={props.variant}>
            {!!Children ? <Children {...customProps} /> : <Toggle {...customProps} />}
        </FieldWrapper>
    )
}

export default ToggleFieldFormik
