import React from 'react'
import { ActionButton, ActionButtonA } from 'uiComponents/buttons'
import { Col } from 'uiComponents/flex'
import { ButtonWrapper } from 'uiComponents/pageElements'
import { withPermission } from 'admin/hocs'

interface FormActionsProps {
    accountSlug: string
    viewMode: 'flat' | 'nested' | ''
    hasPermission: (permission: string, accountSlug: string) => boolean
}

function FormActions(props: FormActionsProps) {
    return (
        <Col span={12}>
            <ButtonWrapper>
                <ActionButtonA
                    size="large"
                    secondary
                    href={`/account/${props.accountSlug}/products/home/${props.viewMode}`}
                    style={{ marginRight: '1em' }}
                >
                    Cancel
                </ActionButtonA>
                <ActionButton
                    id="save-product"
                    size="large"
                    type="submit"
                    style={{ marginRight: '1.5em' }}
                    disabled={!props.hasPermission('partner_admin', props.accountSlug)}
                >
                    Save
                </ActionButton>
            </ButtonWrapper>
        </Col>
    )
}

export default withPermission(FormActions)
