import * as React from 'react'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { TextArea, Checkbox } from 'uiComponents/input'
import { OrderRefundDetails, SelectedForRefund, TicketsForRefund } from './types'
import { DataTable, Cell, DataRow } from 'uiComponents/table'
import { ModalDialogTitle, NavigationSection } from 'uiComponents/popups/modal'
import TicketRow from './refundDialogRow'
import Money from 'uiComponents/money'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { User } from 'auth/state'
import { RefundPaypalDetails } from 'orders/paypalDisputes/schema'
import { AccountNameAndLocation } from 'orders/paypalDisputes/disputesDialog'
import classNames from 'classnames'
import { Button } from '@mui/material'
import './styles.scss'
import { DateFormats, formatISOString } from 'utils/dates'

interface RefundDialogTableProps {
    user: User
    accountSlug: string
    order: OrderRefundDetails
    ticketList: TicketsForRefund[]
    selectedTickets: SelectedForRefund[]
    totalAmount: string
    partnerRefundFeeAmount: number
    refundReason: string
    noneSelectedMsg: string
    fullRefund: boolean
    partnerRefundFeeOff: boolean
    confirmRefundView: boolean
    refunding: boolean
    showPartialRedeemWarning: boolean
    loading: boolean
    fullRefundOnly: boolean
    cancellation: boolean
    paypalRefund?: RefundPaypalDetails
    toggleFullRefund: () => void
    updateHeight: () => void
    handleOrderItemSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleTicketSelect: (orderItemUuid: string, ticketUuidsList: string[]) => void
    togglePartnerRefundFee: () => void
    handleTextAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    onNext: () => void
    onCancel: () => void
    formatCurrency: (amount: number | string, accountSlug: string) => React.ReactNode
    getCurrency: (accountSlug: string) => Currency
}

function RefundDialog(props: RefundDialogTableProps) {
    const {
        accountSlug,
        formatCurrency,
        user,
        order,
        confirmRefundView,
        loading,
        fullRefund,
        toggleFullRefund,
        showPartialRedeemWarning,
        totalAmount,
        ticketList,
        selectedTickets,
        updateHeight,
        handleOrderItemSelect,
        handleTicketSelect,
        partnerRefundFeeOff,
        togglePartnerRefundFee,
        partnerRefundFeeAmount,
        refundReason,
        handleTextAreaChange,
        noneSelectedMsg,
        onNext,
        onCancel,
        refunding,
        fullRefundOnly,
        cancellation,
        paypalRefund,
    } = props

    const columnWidths =
        confirmRefundView || fullRefundOnly ? [null, '6em', '6.8em', '3em'] : ['0', null, '6em', '6.5em', '3em']
    const totalRefundFee = partnerRefundFeeAmount
    const totalRefundAmount = confirmRefundView ? Number(totalAmount) - totalRefundFee : totalAmount
    const refundDisallowed = totalRefundFee > Number(totalAmount) && !cancellation
    const disableRefundButton = confirmRefundView && (refunding || refundDisallowed)
    const disallowedRefundMsg =
        Number(totalAmount) === 0
            ? 'Total refund amount should be more than a 0.'
            : 'Refund fee should be less than the total.'

    return (
        <div style={{ maxHeight: '80vh' }}>
            <ModalDialogTitle>{cancellation ? 'Cancel items' : 'Refund items'}</ModalDialogTitle>
            {paypalRefund && (
                <>
                    <div className="info-row">
                        <span>Case ID:</span>
                        <strong>{paypalRefund.id}</strong>
                    </div>
                    {user.isAdmin && (
                        <div className="info-row">
                            <span>Partner:</span>
                            <AccountNameAndLocation>
                                <span>{paypalRefund.accountName}</span>
                                <span>{paypalRefund.location}</span>
                            </AccountNameAndLocation>
                        </div>
                    )}
                    <div className="info-row">
                        <span>Reason:</span>
                        <strong>{paypalRefund.reason}</strong>
                    </div>
                    <div className="info-row">
                        <span>Requested refund:</span>
                        <strong>
                            <Money amount={paypalRefund.refundAmount.toFixed(2)} accountSlug={props.accountSlug} />
                        </strong>
                    </div>
                </>
            )}
            <div className="info-row">
                <span>Order ID:</span>
                <strong id="order-id">{order.number}</strong>
            </div>
            <div className="info-row">
                <span>Email:</span>
                <strong id="email">{order.customer.email}</strong>
            </div>
            {order.visitDate && (
                <div className="info-row">
                    <span>Visit date:</span>
                    <strong>{formatISOString(order.visitDate, DateFormats.EXTRA_LONG_DATE)}</strong>
                </div>
            )}
            <div />
            <DataTable className="product-list" columnWidths={columnWidths}>
                {!loading && (fullRefund || (!fullRefund && !confirmRefundView)) && (
                    <DataRow className="refund-header-row">
                        {!confirmRefundView && !fullRefundOnly && (
                            <Cell>
                                <Checkbox
                                    id="full-refund-checkbox"
                                    data-testid="orders-full-refund-checkbox"
                                    name="fullRefund"
                                    checked={selectedTickets.length > 0}
                                    onChange={toggleFullRefund}
                                    indeterminateState={!fullRefund}
                                />
                            </Cell>
                        )}
                        <Cell>
                            <strong>{cancellation ? 'Full cancellation' : 'Full refund'}</strong>
                            &nbsp;
                            {showPartialRedeemWarning ? '(includes redeemed tickets)' : ''}
                        </Cell>
                        <Cell />
                        <Cell align="right">
                            <strong>
                                <Money amount={totalAmount} accountSlug={accountSlug} />
                            </strong>
                        </Cell>
                        <Cell />
                    </DataRow>
                )}
                {ticketList.map((ticket) => {
                    const selected = selectedTickets.find((st) => st.uuid === ticket.orderItemId)
                    return (
                        <TicketRow
                            key={ticket.orderItemId}
                            accountSlug={accountSlug}
                            ticket={ticket}
                            selected={!!selected}
                            selectedTickets={selected ? selected.ticketUuids : []}
                            confirmRefundView={confirmRefundView}
                            updateHeight={updateHeight}
                            handleOrderItemSelect={handleOrderItemSelect}
                            handleTicketSelect={handleTicketSelect}
                            fullRefundOnly={fullRefundOnly}
                            cancellation={cancellation}
                        />
                    )
                })}
                {confirmRefundView && !cancellation && !!order.partnerRefundFee && (
                    <DataRow className="refund-fee-row refund-data-row">
                        <Cell>
                            <Checkbox
                                id="partner-refund-fee-checkbox"
                                name="partnerRefundFee"
                                checked={!partnerRefundFeeOff}
                                onChange={togglePartnerRefundFee}
                                style={{ marginRight: '.5em' }}
                            />
                            <label className="refund-fee-text" htmlFor="partner-refund-fee-checkbox">
                                Visitor Refund Fee
                            </label>
                        </Cell>
                        <Cell />
                        <Cell align="right">
                            <label className="refund-fee-text">
                                {!!partnerRefundFeeAmount && <>-</>}
                                <Money amount={Number(partnerRefundFeeAmount).toFixed(2)} accountSlug={accountSlug} />
                            </label>
                        </Cell>
                        <Cell />
                    </DataRow>
                )}
            </DataTable>
            <ValidationMessage
                className={classNames('error-message', {
                    'validation-message-visible': noneSelectedMsg || (confirmRefundView && refundDisallowed),
                })}
            >
                {confirmRefundView && refundDisallowed ? disallowedRefundMsg : noneSelectedMsg || '&nbsp;'}
            </ValidationMessage>
            <div className={classNames('instructions', { visible: !confirmRefundView })}>
                {fullRefundOnly
                    ? `Only full ${cancellation ? 'cancellation' : 'refund'} is allowed`
                    : `Uncheck items if you want to make a partial ${cancellation ? 'cancellation' : 'refund'}`}
            </div>
            <FormItem className="request-form" htmlFor="request">
                <FormItemName>Description</FormItemName>
                <TextArea
                    className="form-text-area"
                    id="request"
                    name="request"
                    maxLength={4000}
                    placeholder={`Please write a reason why items requires a ${
                        cancellation ? 'cancellation' : 'refund'
                    }`}
                    value={refundReason ? refundReason : ''}
                    onChange={handleTextAreaChange}
                />
            </FormItem>
            <div id="refund-conf-msg" className={classNames('confirmation', { visible: confirmRefundView })}>
                Are you sure you want to {cancellation ? 'cancel' : 'refund'} this items?
            </div>
            <NavigationSection>
                <Button variant="outlined" size="large" onClick={onCancel} color="secondary">
                    {confirmRefundView ? (cancellation ? 'Edit cancellation' : 'Edit refund') : 'Cancel'}
                </Button>
                <Button
                    variant="outlined"
                    size="large"
                    id="refund-next-button"
                    onClick={onNext}
                    color="error"
                    disabled={disableRefundButton}
                >
                    {confirmRefundView ? (
                        cancellation ? (
                            'Cancel items'
                        ) : (
                            <>Refund {formatCurrency(Number(totalRefundAmount).toFixed(2), accountSlug)}</>
                        )
                    ) : cancellation ? (
                        'Review cancellation'
                    ) : (
                        'Review refund'
                    )}
                </Button>
            </NavigationSection>
        </div>
    )
}

export default withCurrency(RefundDialog)
