import React from 'react'
import { match as RouteMatch } from 'react-router-dom'
import { TableLoader } from 'uiComponents/loaders'
import { ArticleService, ArticleListItem, ProductList } from 'admin/articleService'
import { History } from 'history'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { Headline } from 'uiComponents/typography'
import { MessageKind } from 'uiComponents/messages'
import ProductListsTable from 'venue/bookingCodes/productsList'

interface ProductPricingListPageParams {
    accountSlug: string
}

interface ProductIdsPageProps {
    history: History
    accountSlug: string
    articleService: ArticleService
    match: RouteMatch<ProductPricingListPageParams>
    navigation: Navigation
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

interface ProductPricingListPageState {
    articles: ArticleListItem[] | null
    orphanArticles: ArticleListItem[] | null
    productLists: ProductList[] | null
    loading: boolean
}

class ProductPricingListPage extends React.Component<ProductIdsPageProps, ProductPricingListPageState> {
    constructor(props: ProductIdsPageProps) {
        super(props)
        this.state = {
            articles: null,
            orphanArticles: null,
            productLists: null,
            loading: false,
        }
    }

    async componentDidMount() {
        await this.getArticlesList()
    }

    async componentDidUpdate(prevProps: ProductIdsPageProps) {
        if (prevProps.match.params.accountSlug !== this.props.match.params.accountSlug) {
            await this.getArticlesList()
        }
    }

    getArticlesList = async () => {
        this.setState({ loading: true })
        try {
            const articles = await this.props.articleService.listArticles(this.props.match.params.accountSlug)
            const pLists = await this.props.articleService.getProductLists(this.props.match.params.accountSlug)
            if (pLists.length > 0) {
                const orphanArticles = articles.filter((a) => a.productListUuids?.length === 0)
                this.setState({
                    productLists: pLists,
                    articles: null,
                    orphanArticles: orphanArticles.length > 0 ? orphanArticles : null,
                })
            } else {
                this.setState({
                    articles,
                    productLists: null,
                    orphanArticles: null,
                })
            }
        } catch {
            this.props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            this.setState({ loading: false })
        }
    }

    render() {
        const accountSlug = this.props.match.params.accountSlug
        const { articles, orphanArticles, productLists, loading } = this.state
        return (
            <>
                {loading && <TableLoader />}
                {!loading && !productLists && !!articles && (
                    <ProductListsTable
                        elements={articles}
                        accountSlug={accountSlug}
                        history={this.props.history}
                        flat
                        navigation={this.props.navigation}
                    />
                )}
                {!loading && !!productLists && !articles && (
                    <ProductListsTable
                        elements={productLists}
                        accountSlug={accountSlug}
                        history={this.props.history}
                        navigation={this.props.navigation}
                    />
                )}
                {!loading && !!productLists && !!orphanArticles && (
                    <>
                        <Headline size={4} style={{ marginLeft: '2rem', marginTop: '2em' }}>
                            Products without a category
                        </Headline>
                        <ProductListsTable
                            elements={orphanArticles}
                            accountSlug={accountSlug}
                            history={this.props.history}
                            navigation={this.props.navigation}
                            flat
                        />
                    </>
                )}
            </>
        )
    }
}

export default withNavigation(ProductPricingListPage)
