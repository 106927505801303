import { HttpService } from 'http/httpService'
import { Result, success, failure } from 'result'
import { LoggingService, EventType, ActionEventData } from 'http/loggingService'
import { format } from 'date-fns'
import { formatISOString } from 'utils/dates'

export type Recurrence = 'WEEKLY' | 'BIWEEKLY' | 'FIRST_WEEK_OF_THE_MONTH' | 'LAST_WEEK_OF_THE_MONTH'
export type PricingType = 'static' | 'rtp' | 'nyop' | 'upsell'
export const pricingTypesValues = ['rtp', 'nyop', 'upsell', 'static']
export const pricingTypesNames = {
    rtp: 'RTP - Real Time Pricing',
    nyop: 'NYOP - Name Your Own Price',
    upsell: 'Upsell',
    static: 'Static',
}

export const pricingTypes = {
    rtp: 'Regular',
    nyop: 'NYOP',
    upsell: 'Upsell',
    static: 'Static',
}

export interface ArticleValidityItem {
    id: string
    name: string
    validFrom: string
    validTo: string
    showFrom: string
    showTo: string
    enabled: boolean
    validityOverrides: ValidityException[]
    pricingType: PricingType
}

export interface Timeslot {
    startTime: string
    endTime: string | null
    capacity: number | null
}

export interface AvailabilityDates {
    [key: string]: {
        available: boolean
        timeslots: Timeslot[][]
    }
}

export interface ValidityException {
    id: string
    name: string
    appliesFrom: string
    appliesTo: string
    weekday: string
    recurrence: Recurrence
    articles: { id: string }[]
    productNames: string
}

export interface ValidityExceptionData {
    account: string
    id: string | null
    name: string
    appliesFrom: string | null
    appliesTo: string | null
    weekday: string | null
    recurrence: string
    articles: string[]
}

interface SaveOverrideResponse {
    ok: boolean
    override: { id: string }
}

interface CreateExceptionServerError {
    type: 'server_error'
    statusCode: number
}

interface OverrideForAvailability {
    appliesFrom: string
    appliesTo: string
    weekday: string
    recurrence: string
}

export interface BulkValidityUpdateError {
    type:
        | 'articles_valid_from_after_valid_to'
        | 'articles_valid_to_before_valid_from'
        | 'articles_show_from_after_show_to'
        | 'articles_show_to_before_show_from'
    ids: string[]
}

export class ArticleConfigurationService {
    constructor(
        private httpService: HttpService,
        private loggingService: LoggingService,
        private backofficeEndpoint: string,
    ) {}

    async getArticleValidity(slug: string, id: string): Promise<ArticleValidityItem> {
        const query = `query ArticleValidity($slug: ID, $id: ID) {
      account(slug: $slug) {
        article(id: $id) {
          name,
          validFrom,
          validTo,
          showFrom,
          showTo,
          enabled,
          pricingType,
          validityOverrides{
            id,
            name,
            appliesFrom,
            appliesTo,
            weekday,
            recurrence,
            articles {
              id
            }
          }
        }
      }
    }`

        const body = JSON.stringify({
            operationName: 'ArticleValidity',
            query,
            variables: { slug, id },
        })

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        return payload.data.account.article
    }

    async updateArticleValidityAndStatus(
        account: string,
        id: string,
        validFrom: String,
        validTo: String,
        showFrom: String,
        showTo: String,
        enabled: boolean,
    ): Promise<void> {
        const query = `mutation UpdateArticleValidityAndStatus
        ($account: String, $id: String, $validFrom: Date, $validTo: Date,
          $showFrom: Date, $showTo: Date,
      $enabled: Boolean) {
      updateArticleValidityAndStatus
        (account: $account, id: $id,
          validFrom: $validFrom, validTo: $validTo,
          showFrom: $showFrom, showTo: $showTo,
          enabled: $enabled) {
        ok
        errorCode
        message
      }
    }`

        const body = JSON.stringify({
            operationName: 'UpdateArticleValidityAndStatus',
            query,
            variables: { account, id, validFrom, validTo, showFrom, showTo, enabled },
        })

        const logEventType: EventType = 'product_validity_updated'
        const logEventData: ActionEventData = {
            category: 'validity',
            payload: { id, validFrom, validTo, showFrom, showTo, enabled },
        }

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        if (!payload.data.updateArticleValidityAndStatus.ok) {
            this.loggingService.logError(payload, logEventType, logEventData)
            throw new Error(
                payload.data.updateArticleValidityAndStatus.message
                    ? payload.data.updateArticleValidityAndStatus.message
                    : `Backoffice has returned error: ${payload}`,
            )
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    async bulkUpdateValidity(
        accountSlug: string,
        ids: string[],
        validFrom: string,
        validTo: string,
        showFrom: string,
        showTo: string,
    ): Promise<Result<null, BulkValidityUpdateError>> {
        const validStart = validFrom ? formatISOString(validFrom, 'yyyy-MM-dd') : null
        const validEnd = validTo ? formatISOString(validTo, 'yyyy-MM-dd') : null
        const showStart = showFrom ? formatISOString(showFrom, 'yyyy-MM-dd') : null
        const showEnd = showTo ? formatISOString(showTo, 'yyyy-MM-dd') : null
        const logEventType: EventType = 'product_validity_updated'
        const logEventData: ActionEventData = {
            category: 'validity',
            payload: {
                ids,
                validFrom: validStart,
                validTo: validEnd,
                showFrom: showStart,
                showTo: showEnd,
            },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/articles/bulk_validity_edit/`,
            {
                method: 'PATCH',
                body: JSON.stringify({
                    articleIds: ids,
                    validFrom: validStart,
                    validTo: validEnd,
                    showFrom: showStart,
                    showTo: showEnd,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            const body = await response.json()
            this.loggingService.logResponseError(response, logEventType, logEventData)
            return failure({
                type: body.error.code,
                ids: body.error.context.articleIds,
            })
        }
        this.loggingService.logAction(logEventType, logEventData)
        return success(null)
    }

    async getValidityExceptionsList(
        account: string,
        sortBy: string,
        sortDirection: string,
    ): Promise<ValidityException[]> {
        const exceptionListQuery = `
      query ValidityOverrides($account: ID, $sortBy: ValidityOverrideSort, $sortDirection: SortDirection) {
        account(slug: $account){
          validityOverrides(sortBy: $sortBy, sortDirection: $sortDirection){
            id,
            name,
            appliesFrom,
            appliesTo,
            weekday,
            recurrence,
            articles {
              id
            }
          }
        }
      }
    `
        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                operationName: 'ValidityOverrides',
                query: exceptionListQuery,
                variables: { account, sortBy, sortDirection },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Pricing setting service returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body.data.account.validityOverrides
    }

    async getValidityException(account: string, id: string): Promise<ValidityException> {
        const exceptionQuery = `
      query ValidityOverride($account: ID, $id: ID!) {
        account(slug: $account){
          validityOverride(id: $id){
            id,
            name,
            appliesFrom,
            appliesTo,
            weekday,
            recurrence,
            articles {
              id
            }
          }
        }
      }
    `
        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                query: exceptionQuery,
                variables: { account, id },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Pricing setting service returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body.data.account.validityOverride
    }

    async saveValidityExeption(
        exceptionData: ValidityExceptionData,
    ): Promise<Result<SaveOverrideResponse, CreateExceptionServerError>> {
        const mutation = `
      mutation SaveValidityOverride($id: ID, $name: String!, $account: String!, $articles: [String],
        $recurrence: String, $weekday: String, $appliesFrom: Date!, $appliesTo: Date!) {
        saveValidityOverride(
          account: $account,
          id: $id,
          name: $name,
          recurrence: $recurrence,
          weekday: $weekday,
          appliesFrom: $appliesFrom,
          appliesTo: $appliesTo,
          articles: $articles) {
            ok
            errorCode
            override {
              id
            }
          }
      }`
        const { id, name, account, articles, recurrence, weekday, appliesFrom, appliesTo } = exceptionData

        const logEventType: EventType = id ? 'validity_exception_updated' : 'validity_exception_created'
        const logEventData: ActionEventData = {
            category: 'validity',
            payload: exceptionData,
        }

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                operationName: 'SaveValidityOverride',
                query: mutation,
                variables: {
                    id,
                    name,
                    account,
                    articles,
                    recurrence,
                    weekday,
                    appliesFrom,
                    appliesTo,
                },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            return failure({
                type: 'server_error',
                statusCode: response.status,
            })
        }

        const payload = await response.json()
        if (!payload.data.saveValidityOverride.ok) {
            this.loggingService.logError(payload, logEventType, logEventData)
            throw new Error(`Pricing setting service returned has returned error: ${payload}`)
        }
        const exceptionId = id ? id : payload.data.saveValidityOverride.override.id
        this.loggingService.logAction(logEventType, {
            ...logEventData,
            payload: { ...logEventData.payload, id: exceptionId },
        })
        return success(payload.data.saveValidityOverride)
    }

    async deleteValidityException(id: string): Promise<boolean> {
        const query = `mutation DeleteValidityOverride($id: ID!) {
      deleteValidityOverride(id: $id) {
        ok
        errorCode
      }
    }`

        const logEventType: EventType = 'validity_exception_deleted'
        const logEventData: ActionEventData = {
            category: 'validity',
            payload: { id },
        }

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                query: query,
                variables: { id },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Pricing setting service returned status code: ${response.status}`)
        }
        const payload = await response.json()
        if (!payload.data.deleteValidityOverride.ok) {
            this.loggingService.logError(payload, logEventType, logEventData)
            throw new Error(`Pricing setting service returned has returned error: ${payload}`)
        }

        this.loggingService.logAction(logEventType, logEventData)
        return payload.data
    }

    async getAvailability(
        slug: string,
        articleId: string,
        categoryId: string | null,
        from: Date,
        to: Date,
        validFrom?: string | null,
        validTo?: string | null,
        override?: OverrideForAvailability,
    ): Promise<AvailabilityDates> {
        const payload = {
            articleId,
            productsListId: categoryId,
            dateFrom: format(from, 'yyyy-MM-dd'),
            dateTo: format(to, 'yyyy-MM-dd'),
        }
        if (!!validFrom && !!validTo) {
            payload['articleValidity'] = {
                validFrom: formatISOString(validFrom, 'yyyy-MM-dd'),
                validTo: formatISOString(validTo, 'yyyy-MM-dd'),
            }
        }
        if (!!override) {
            payload['override'] = override
        }
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}dashboard_api/accounts/${slug}/availability/calendar/preview/`,
            {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        const data = await response.json()
        return data.dates
    }
}
