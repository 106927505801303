import { SingleSelectOption } from 'uiComponents/input'

export const googleTTDCategories: SingleSelectOption[] = [
    { name: 'Adults only', value: 'adults-only' },
    { name: 'Animals', value: 'animals' },
    { name: 'Audio guide', value: 'audio-guide' },
    { name: 'Beaches', value: 'beaches' },
    { name: 'Bike tours', value: 'bike-tours' },
    { name: 'Boat tours', value: 'boat-tours' },
    { name: 'City cards', value: 'city-cards' },
    { name: 'Classes', value: 'classes' },
    { name: 'Day trips', value: 'day-trips' },
    { name: 'Family friendly', value: 'family-friendly' },
    { name: 'Fast track', value: 'fast-track' },
    { name: 'Food', value: 'food' },
    { name: 'Guided tours', value: 'guided-tours' },
    { name: 'History', value: 'history' },
    { name: 'Hop-on hop-off', value: 'hop-on-hop-off' },
    { name: 'Literature', value: 'literature' },
    { name: 'Live music', value: 'live-music' },
    { name: 'Nightlife', value: 'nightlife' },
    { name: 'Outdoors', value: 'outdoors' },
    { name: 'Private tours', value: 'private-tours' },
    { name: 'Romantic', value: 'romantic' },
    { name: 'Self guided', value: 'self-guided' },
    { name: 'Small group tours', value: 'small-group-tours' },
    { name: 'Sports', value: 'sports' },
    { name: 'Theme parks', value: 'theme-parks' },
    { name: 'Walking tours', value: 'walking-tours' },
    { name: 'Wheelchair accessible', value: 'wheelchair-accessible' },
]
