import React, { useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Location, History } from 'history'
import BarcodesReportPage from 'reports/barcodes'
import DiscountsReportPage from 'reports/discounts'
import SalesReportPage from 'reports/sales'
import CustomersReportsPage from 'reports/customers'
import TrafficReportsPage from 'reports/traffic'
import EmailsReportsPage from 'reports/emails'
import ReportsOverviewPage from 'reports/overview'
import ForecastReportPage from 'reports/forecast/forecastPage'
import EngageToolsStatsPage from 'engageTools/stats/index'
import PricingReportsPage from 'reports/pricing'
import { withFeatures } from 'features'
import { StatsService } from 'http/statsService'
import { EngageToolsService } from 'engageTools/engageToolsService'
import { RequestService } from 'requests/requestService'
import { ComponentsServiceContext } from 'engageTools/studio/context'
import { LoginService } from 'http/loginService'
import { LoggingService } from 'http/loggingService'
import { InventoryServiceContext } from 'inventory/context'
import NotificationCampaigns from './notificationCampaigns'
import CheckoutFunnelReports from './checkoutFunnel'
import Superset from './superset'

interface ReportsHomeProps {
    accountSlug: string
    history: History
    location: Location
    statsService: StatsService
    engageToolsService: EngageToolsService
    requestService: RequestService
    backofficeEndpoint: string
    loginService: LoginService
    loggingService: LoggingService
    hasFeature: (feature: string, accountSlug?: string) => boolean
}

function ReportsHome(props: ReportsHomeProps) {
    const componentsService = useContext(ComponentsServiceContext)
    const inventoryService = useContext(InventoryServiceContext)

    return (
        <Switch location={props.location}>
            <Route
                path="/account/:accountSlug/reports/overview"
                render={() => <ReportsOverviewPage accountSlug={props.accountSlug} history={props.history} />}
            />
            <Route
                path="/account/:accountSlug/reports/forecast"
                render={() => <ForecastReportPage statsService={props.statsService} accountSlug={props.accountSlug} />}
            />
            <Route path="/account/:accountSlug/reports/superset/:dashboardId" render={() => <Superset />} />
            <Route
                path="/account/:accountSlug/reports/sales"
                render={() => (
                    <SalesReportPage
                        statsService={props.statsService}
                        loginService={props.loginService}
                        loggingService={props.loggingService}
                        accountSlug={props.accountSlug}
                    />
                )}
            />
            <Route
                path="/account/:accountSlug/reports/pricing"
                render={() => <PricingReportsPage statsService={props.statsService} accountSlug={props.accountSlug} />}
            />
            <Route
                path="/account/:accountSlug/reports/barcodes"
                render={() => (
                    <BarcodesReportPage
                        statsService={props.statsService}
                        accountSlug={props.accountSlug}
                        inventoryService={inventoryService}
                    />
                )}
            />
            <Route
                path="/account/:accountSlug/reports/discount_codes"
                render={() => (
                    <DiscountsReportPage
                        statsService={props.statsService}
                        backofficeEndpoint={props.backofficeEndpoint}
                        loginService={props.loginService}
                        loggingService={props.loggingService}
                        accountSlug={props.accountSlug}
                    />
                )}
            />
            <Route
                path="/account/:accountSlug/reports/customers"
                render={() => <CustomersReportsPage accountSlug={props.accountSlug} />}
            />
            <Route
                path="/account/:accountSlug/reports/traffic"
                render={() => (
                    <TrafficReportsPage
                        statsService={props.statsService}
                        accountSlug={props.accountSlug}
                        inventoryService={inventoryService}
                    />
                )}
            />
            <Route
                path="/account/:accountSlug/reports/emails"
                render={() => <EmailsReportsPage statsService={props.statsService} accountSlug={props.accountSlug} />}
            />
            <Route
                path="/account/:accountSlug/reports/engage_tools"
                render={() => (
                    <EngageToolsStatsPage
                        accountSlug={props.accountSlug}
                        engageToolsService={props.engageToolsService}
                        componentsService={componentsService}
                    />
                )}
            />
            <Route
                path="/account/:accountSlug/reports/notification_campaigns"
                render={() => (
                    <NotificationCampaigns
                        accountSlug={props.accountSlug}
                        statsService={props.statsService}
                        inventoryService={inventoryService}
                    />
                )}
            />
            <Route
                path="/account/:accountSlug/reports/checkout_funnel"
                render={() => (
                    <CheckoutFunnelReports accountSlug={props.accountSlug} statsService={props.statsService} />
                )}
            />
            <Route render={() => <Redirect to={`/account/${props.accountSlug}/reports/overview`} />} />
        </Switch>
    )
}

export default withFeatures(ReportsHome)
