import React from 'react'
import styled from 'styled-typed'
import {
    Headline,
    Subtitle,
    Caption,
    Body,
    SecondaryText,
    Bold,
    Italic,
    LinkText,
    LightText,
    RegularText,
    SmallText,
    StyledATag,
} from 'uiComponents/typography'

const Container = styled.div`
    margin: 5em 0 5em 7em;
    padding: 2em 4em;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    box-sizing: border-box;
    background: #fff;
    width: 90%;
    height: fit-content;
`
const ComponentBlock = styled.div`
    margin: 1em 0 5em 0.5em;
`
const Subsection = styled.div`
    margin: 2em 0;
`
const Component = styled.div`
    margin: 1em 0;
`

export function TypographyComponents() {
    return (
        <Container>
            <Typography />
        </Container>
    )
}

export function Typography() {
    return (
        <>
            <ComponentBlock>
                <Subsection>
                    <Headline size={1}>Headline 1 Medium (42/56 500)</Headline>
                    <Headline size={2}>Headline 2 Medium (34/48 500)</Headline>
                    <Headline size={3} bold>
                        Headline 3 Bold (28/40 700)
                    </Headline>
                    <Headline size={3}>Headline 3 Medium (28/40 500)</Headline>
                    <Headline size={4}>Headline 4 Medium (22/32 500)</Headline>
                    <Headline size={5}>Headline 5 Medium (18/28 500)</Headline>
                    <Headline size={6}>Headline 6 Medium (14/24 500)</Headline>
                </Subsection>
                <Subsection>
                    <Subtitle>Subtitle (18/28 300)</Subtitle>
                </Subsection>
                <Subsection>
                    <Caption>Caption (12/16 400)</Caption>
                </Subsection>
                <Subsection>
                    <Body size={1} weight="light">
                        Body 1 Light (14/24 300): The quick brown fox jumps over the lazy dog
                    </Body>
                    <Body size={1}>Body 1 Regular (14/24 400): The quick brown fox jumps over the lazy dog</Body>
                    <Body size={1} weight="bold">
                        Body 1 Bold (14/24 700): The quick brown fox jumps over the lazy dog
                    </Body>
                    <Body size={2}>Body 2 Regular (12/20 400): The quick brown fox jumps over the lazy dog</Body>
                    <Body size={2} weight="medium">
                        Body 2 Medium (12/20 500): The quick brown fox jumps over the lazy dog
                    </Body>
                    <Body size={3}>Body 3 Regular (10/16 400):The quick brown fox jumps over the lazy dog</Body>
                </Subsection>
            </ComponentBlock>
            <ComponentBlock>
                <Component>
                    <SecondaryText>Secondary text</SecondaryText>
                </Component>
                <Component>
                    <LightText>Light text</LightText>
                </Component>
                <Component>
                    <Bold>Bold</Bold>
                </Component>
                <Component>
                    <Italic>Italic</Italic>
                </Component>
                <Component>
                    <RegularText>Regular text</RegularText>
                </Component>
                <Component>
                    <SmallText>Small text</SmallText>
                </Component>
                <Component>
                    <LinkText>Link text</LinkText>
                </Component>
                <Component>
                    <StyledATag target="_blank" href="https://support.convious.com/help/how-to-use-the-copy-editor">
                        Styled a tag
                    </StyledATag>
                </Component>
            </ComponentBlock>
        </>
    )
}
