import React, { useState, useEffect, useRef } from 'react'
import { useAccountChange, useIsMounted } from 'reactUtils'
import { delay } from 'utils'
import { NestedRow, NestedRowProps } from 'products/components/nestedRow'
import { ProductList, ArticleListItem, ProductListMinimum, OrphanArticle } from 'admin/articleService'
import { ProductData } from 'products/pricing/pricingService'
import { PricingType } from 'products/articleConfigurationService'
import { InfoCard } from 'uiComponents/cards'
import { NestedContainerWrapper } from 'uiComponents/table/nestedContainer'
import { AttachedCategoriesList } from 'products/attachedCategoriesList'
import { addCategoryBranchToList } from 'products/crud/common'
import { ArticleServiceContext } from 'admin/context'
import { TableLoader, PageLoader } from 'uiComponents/loaders'
import BulkEditPricingSettingsDialog from 'products/bulkEditDialogs/bulkEditPricingSettingsDialog'
import { BulkEditValiditySettingsDialog } from 'products/bulkEditDialogs/bulkEditValiditySettingsDialog'
import { DifferentPricingTypesDialog } from 'products/bulkEditDialogs/differentPricingTypesDialog'
import { fetchNewPriorities, buildCheckedProductInfo } from 'products/utils'
import { NestedContainerProps } from 'products/components/nestedContainerProps'
import { ProductRowProps } from 'products/tableRow'
import isEqual from 'lodash/isEqual'

export type NestedElement = ProductList | ArticleListItem | ProductListMinimum | OrphanArticle

type RowProps = NestedRowProps & ProductRowProps & React.HTMLAttributes<HTMLDivElement>

const MemoRenderRow = React.memo(NestedRow, (prev: RowProps, next: RowProps) => {
    return (
        prev.expandable === next.expandable &&
        isEqual(prev.pricingData, next.pricingData) &&
        prev.expanded === next.expanded &&
        prev.checkedArticlesIds === next.checkedArticlesIds &&
        prev.priorities === next.priorities
    )
})

export function NestedContainer(props: NestedContainerProps & React.HTMLAttributes<HTMLDivElement>) {
    const isMounted = useIsMounted()
    const articleService = React.useContext(ArticleServiceContext)
    const [expandedElement, setExpandedElement] = useState<string>('')
    const [priorities, setPriorities] = useState<{ [index: number]: string[] }>({})
    const [knownCats, setKnownCats] = useState<ProductList[]>(props.knownCategories || [])
    const [knownPricing, setKnownPricing] = useState<ProductData[]>([])
    const [loadingPlId, setLoadingPlId] = useState<string>('')
    const [loadingArticleId, setLoadingArticleId] = useState<string>('')
    const knownProductCategoriesRef = useRef<{ [key: string]: string[] }>({})
    const [checkedArticlesIds, setCheckedArticlesIds] = useState<string[]>([])
    const [differentPricingTypesDialog, setDifferentPricingTypesDialog] = useState<boolean>(false)
    const [bulkEditPricingDialog, setBulkEditPricingDialog] = useState<boolean>(false)
    const [bulkEditValidityDialog, setBulkEditValidityDialog] = useState<boolean>(false)
    const identationRow = `${props.grade * 1.5 + 1.25}em`

    useEffect(() => {
        if (props.elements.length === 0 || !isMounted.current) {
            return
        }
        const prios = {}
        props.elements.forEach((e) => {
            const elementAs = e as ProductList
            const id = elementAs.uuid ? elementAs.uuid : (e as ArticleListItem).id
            if (!prios[e.priority]) {
                prios[e.priority] = [id]
            } else {
                prios[e.priority].push(id)
            }
        })
        setPriorities(prios)
        getPricing()
    }, [props.elements])

    useEffect(() => {
        if (!isMounted.current) {
            return
        }
        if (props.allChecked && props.elements.length !== checkedArticlesIds.length) {
            const ids = props.elements.map((el) => (el as ArticleListItem).id)
            setCheckedArticlesIds(ids)
        } else if (checkedArticlesIds.length === props.elements.length) {
            setCheckedArticlesIds([])
        }
    }, [props.allChecked])

    useEffect(() => {
        if (!isMounted.current) {
            return
        }
        setCheckedArticlesIds([])
        if (props.setAllChecked && props.showBulkEditButtons && isMounted.current) {
            props.setAllChecked(false)
            props.showBulkEditButtons(false)
        }
    }, [props.navigation.query().pricingTypes])

    useEffect(() => {
        if (props.removeAllMessages) {
            props.removeAllMessages()
        }
    }, [bulkEditPricingDialog, bulkEditValidityDialog])

    useEffect(() => {
        if (!isMounted.current) {
            return
        }
        if (props.bulkEditPricing) {
            const pricingTypes = new Set()
            props.elements.forEach((el) => {
                if (checkedArticlesIds.indexOf((el as ArticleListItem).id) > -1) {
                    pricingTypes.add((el as ArticleListItem).pricingType)
                    if (pricingTypes.size === 2 && isMounted.current) {
                        setDifferentPricingTypesDialog(true)
                        return
                    }
                }
            })
            if (pricingTypes.size === 1 && isMounted.current) {
                setBulkEditPricingDialog(true)
            }
            if (props.cancelBulkEditPricing) {
                props.cancelBulkEditPricing()
            }
        }
    }, [props.bulkEditPricing])

    useEffect(() => {
        if (props.bulkEditValidity && isMounted.current) {
            setBulkEditValidityDialog(true)
            if (props.cancelBulkEditValidity) {
                props.cancelBulkEditValidity()
            }
        }
    }, [props.bulkEditValidity])

    useEffect(() => {
        if (!isMounted.current) {
            return
        }
        setKnownPricing(props.knownPricingData || [])
    }, [props.knownPricingData])

    useAccountChange(props.accountSlug, () => {
        setKnownCats([])
        setKnownPricing([])
    })

    const getPricing = () => {
        setKnownPricing(props.knownPricingData || [])
        if (!!props.flat) {
            const articlesIds = props.elements.map((a) => (a as ArticleListItem | OrphanArticle).id)
            getPricingData(articlesIds)
        }
    }

    const getPricingData = async (ids: string[]) => {
        try {
            const idsWithUnknownPricing = ids.filter((id) => !knownPricing.find((p) => p.productId === id))
            if (!!props.pricingService) {
                const pricing = await props.pricingService.getProductPricing(idsWithUnknownPricing)
                const newKnownPricing = knownPricing.concat(pricing)
                setKnownPricing(newKnownPricing)
            }
        } catch {
            if (!!props.replaceTopMessages) {
                props.replaceTopMessages('server_error', 'error', 'Could not fetch pricing data.')
            }
        }
    }

    const getAllPricing = async () => {
        setKnownPricing([])
        const articlesIds = props.elements.map((a) => (a as ArticleListItem | OrphanArticle).id)
        if (!!props.pricingService) {
            try {
                const pricing = await props.pricingService.getProductPricing(articlesIds)
                if (isMounted.current) {
                    setKnownPricing(pricing)
                }
            } catch {
                if (!!props.replaceTopMessages) {
                    props.replaceTopMessages('server_error', 'error', 'Could not fetch pricing data.')
                }
            }
        }
    }

    function updateExpandedElement(id: string) {
        setExpandedElement(expandedElement === id ? '' : id)
        setLoadingArticleId('')
    }

    async function setExpandedCategory(id: string) {
        if (expandedElement !== id) {
            if (props.grade === 0 && !knownCats.find((cat) => cat.uuid === id)) {
                setLoadingPlId(id)
                try {
                    const pl = await articleService.getProductListDescendants(props.accountSlug, id)
                    const all = addCategoryBranchToList(knownCats, pl)
                    let idsWithUnknownPricing: string[] = []
                    all.forEach((cat) => {
                        if (cat.articles && cat.articles.length > 0) {
                            const ids = cat.articles.map((a) => a.id)
                            idsWithUnknownPricing = idsWithUnknownPricing.concat(ids)
                        }
                    })
                    if (isMounted.current) {
                        getPricingData(idsWithUnknownPricing)
                        setKnownCats(all)
                    }
                } catch {
                    if (!!props.replaceTopMessages) {
                        props.replaceTopMessages('server_error', 'error', 'Could not fetch descendant elements.')
                    }
                } finally {
                    setLoadingPlId('')
                }
            }
        }
        updateExpandedElement(id)
    }

    async function setExpandedProduct(expandedElementId: string) {
        if (props.containerType === 'productIds') {
            return
        }
        setLoadingArticleId(expandedElementId)
        if (expandedElement !== expandedElementId) {
            try {
                if (props.accountCategories.length === 0) {
                    const pl = await articleService.getProductLists(props.accountSlug)
                    if (!!props.setAccountCategories && isMounted.current) {
                        props.setAccountCategories(pl)
                    }
                }
                if (!knownProductCategoriesRef.current[expandedElementId] && isMounted.current) {
                    const categoryIds = await articleService.fetchArticleCategoryIds(
                        props.accountSlug,
                        expandedElementId,
                    )
                    knownProductCategoriesRef.current = {
                        ...knownProductCategoriesRef.current,
                        [expandedElementId]: categoryIds || [],
                    }
                }
            } catch {
                if (!!props.replaceTopMessages) {
                    props.replaceTopMessages('server_error', 'error', 'Could not fetch categories.')
                }
            }
        }
        updateExpandedElement(expandedElementId)
    }

    const checkForDuplicatePriorities = (elementId: string, priority: number, formerPriority: string) => {
        const newPriorities = fetchNewPriorities(elementId, priority, formerPriority, priorities)
        setPriorities(newPriorities)
        return newPriorities[priority].length > 1
    }

    const updatePriority = async (id: string, priority: number) => {
        if (!!props.updateArticlePriority) {
            const formerPrio = getFormerPriority(id)
            const samePriorityFound = checkForDuplicatePriorities(id, priority, formerPrio)
            if (samePriorityFound && !!props.replaceTopMessages) {
                props.replaceTopMessages(
                    'duplicate_warn',
                    'warn',
                    'Please note, that there already are articles with this priority.',
                )
            }
            props.updateArticlePriority(id, priority)
            if (!!props.hideMessage) {
                await delay(6000)
                props.hideMessage('duplicate_warn')
            }
        }
    }

    const updatePLPriority = async (id: string, enabled: boolean, priority: number) => {
        if (!!props.updateProductListStatusAndPriority) {
            const formerPrio = getFormerPriority(id)

            const samePriorityFound = checkForDuplicatePriorities(id, priority, formerPrio)
            if (samePriorityFound && !!props.replaceTopMessages) {
                props.replaceTopMessages(
                    'duplicate_warn',
                    'warn',
                    'Please note, that there already are categories with this priority.',
                )
            }
            props.updateProductListStatusAndPriority(id, enabled, priority)
            if (!!props.hideMessage) {
                await delay(6000)
                props.hideMessage('duplicate_warn')
            }
        }
    }

    const getFormerPriority = (elementId: string): string => {
        let formerPriority = ''
        for (let key in priorities) {
            if (priorities.hasOwnProperty(key)) {
                if (priorities[key].indexOf(elementId) > -1) {
                    formerPriority = key
                }
            }
        }
        return formerPriority
    }

    const updateCheckedArticleList = (id: string, checked: boolean) => {
        if (checked) {
            if (checkedArticlesIds.indexOf(id) > -1) {
                return
            }
            const updatedCheckedArticleIds = [...checkedArticlesIds]
            updatedCheckedArticleIds.push(id)
            setCheckedArticlesIds(updatedCheckedArticleIds)
            if (props.elements.length === updatedCheckedArticleIds.length && props.setAllChecked) {
                props.setAllChecked(true)
            }
            if (props.showBulkEditButtons) {
                props.showBulkEditButtons(true)
            }
        } else {
            const updatedCheckedArticleIds = checkedArticlesIds.filter((articleId) => articleId !== id)
            setCheckedArticlesIds(updatedCheckedArticleIds)
            if (props.allChecked && props.setAllChecked) {
                props.setAllChecked(false)
            }
            if (props.showBulkEditButtons && updatedCheckedArticleIds.length === 0) {
                props.showBulkEditButtons(false)
            }
        }
    }

    let pricingType = ''
    const elementForPricingType = props.elements.find((e) => (e as ArticleListItem).id === checkedArticlesIds[0])
    if (elementForPricingType) {
        pricingType = (elementForPricingType as ArticleListItem).pricingType
    }

    const expandableArticle =
        !props.orphans && props.grade === 0 && !props.backgroundLoading && props.containerType !== 'productIds'

    return (
        <>
            {differentPricingTypesDialog && (
                <DifferentPricingTypesDialog
                    onDismiss={() => {
                        if (props.cancelBulkEditPricing) {
                            props.cancelBulkEditPricing()
                        }
                        setDifferentPricingTypesDialog(false)
                    }}
                />
            )}
            {bulkEditValidityDialog && (
                <BulkEditValiditySettingsDialog
                    accountSlug={props.accountSlug}
                    onDismiss={() => {
                        if (props.cancelBulkEditValidity) {
                            props.cancelBulkEditValidity()
                        }
                        setBulkEditValidityDialog(false)
                        if (props.removeAllMessages) {
                            props.removeAllMessages()
                        }
                    }}
                    replaceTopMessages={props.replaceTopMessages}
                    removeAllMessages={props.removeAllMessages}
                    affectedProducts={props.elements
                        .filter((el) => checkedArticlesIds.indexOf((el as ArticleListItem).id) > -1)
                        .map((el) => ({ id: (el as ArticleListItem).id, name: el.name }))}
                    onSave={() => (props.refreshArticles ? props.refreshArticles() : {})}
                />
            )}
            {bulkEditPricingDialog && !!pricingType && knownPricing.length > 0 && (
                <BulkEditPricingSettingsDialog
                    accountSlug={props.accountSlug}
                    onDismiss={() => {
                        if (props.cancelBulkEditPricing) {
                            props.cancelBulkEditPricing()
                        }
                        setBulkEditPricingDialog(false)
                        if (props.removeAllMessages) {
                            props.removeAllMessages()
                        }
                    }}
                    pricingType={pricingType as PricingType}
                    replaceTopMessages={props.replaceTopMessages}
                    removeAllMessages={props.removeAllMessages}
                    affectedProductInfo={buildCheckedProductInfo(props.elements, checkedArticlesIds, knownPricing)}
                    onSave={() => getAllPricing()}
                />
            )}
            {!!loadingArticleId && <PageLoader style={{ position: 'fixed', top: '40%', left: '40%', zIndex: 1 }} />}
            {(props.expanded || props.grade === 0) && (
                <NestedContainerWrapper
                    grade={props.grade}
                    className={props.grade === 0 ? 'expanded' : props.className}
                    flat={props.flat}
                >
                    {props.elements.map((element, i) => {
                        const elementAsList = element as ProductList
                        const currentId = elementAsList.uuid ? elementAsList.uuid : (element as ArticleListItem).id
                        const isExpanded = expandedElement === currentId
                        const elementAsArticle = element as ArticleListItem
                        const isArticle = !!elementAsArticle.id

                        let descendants: NestedElement[]
                        if (!!isArticle) {
                            descendants = []
                        } else {
                            const found = knownCats.find(
                                (cat) => cat.uuid === (element as ProductList | ProductListMinimum).uuid,
                            )
                            // temporarily we display ascendants based on childrenType
                            descendants = !!found
                                ? found.childrenType === 'LIST'
                                    ? found.children || []
                                    : found.articles
                                : []
                        }
                        return (
                            <div key={i} className="articleRow">
                                <MemoRenderRow
                                    history={props.history}
                                    rowType={props.containerType}
                                    style={{ paddingLeft: identationRow }}
                                    pricingData={
                                        isArticle ? knownPricing.find((pd) => pd.productId === currentId) : null
                                    }
                                    element={element}
                                    expanded={isExpanded}
                                    expandable={isArticle ? expandableArticle : true}
                                    accountSlug={props.accountSlug}
                                    toggleExpanded={() =>
                                        isArticle ? setExpandedProduct(currentId) : setExpandedCategory(currentId)
                                    }
                                    hasPermission={props.hasPermission}
                                    narrow={props.grade > 0}
                                    deleteArticle={props.deleteArticle}
                                    deleteProductList={props.deleteProductList}
                                    duplicateProduct={props.duplicateProduct}
                                    updateProductListStatusAndPriority={updatePLPriority}
                                    updateArticlePriority={updatePriority}
                                    updateArticleStatus={props.updateArticleStatus}
                                    priorities={priorities}
                                    hasFeature={props.hasFeature}
                                    origin={props.origin}
                                    checkedArticlesIds={checkedArticlesIds}
                                    toggleArticleCheck={(checked) =>
                                        isArticle ? updateCheckedArticleList(currentId, checked) : () => {}
                                    }
                                    updateArticleOriginalPrice={props.updateArticleOriginalPrice}
                                />
                                {descendants.length > 0 && (
                                    <NestedContainer
                                        history={props.history}
                                        containerType={props.containerType}
                                        className={isExpanded ? 'expanded' : ''}
                                        expanded={isExpanded}
                                        knownPricingData={knownPricing}
                                        pricingService={props.pricingService}
                                        elements={descendants}
                                        accountSlug={props.accountSlug}
                                        hasPermission={props.hasPermission}
                                        grade={props.grade + 1}
                                        deleteArticle={props.deleteArticle}
                                        deleteProductList={props.deleteProductList}
                                        duplicateProduct={props.duplicateProduct}
                                        updateProductListStatusAndPriority={props.updateProductListStatusAndPriority}
                                        updateArticlePriority={props.updateArticlePriority}
                                        updateArticleStatus={props.updateArticleStatus}
                                        replaceTopMessages={props.replaceTopMessages}
                                        hideMessage={props.hideMessage}
                                        removeAllMessages={props.removeAllMessages}
                                        openRowsElementIds={props.openRowsElementIds}
                                        hasFeature={props.hasFeature}
                                        knownCategories={knownCats}
                                        accountCategories={props.accountCategories}
                                        origin={props.origin}
                                        updateArticleOriginalPrice={props.updateArticleOriginalPrice}
                                        refreshArticles={props.refreshArticles}
                                        navigation={props.navigation}
                                    />
                                )}
                                {loadingPlId === currentId && (
                                    <NestedContainerWrapper grade={props.grade + 1} className="expanded">
                                        <TableLoader />
                                    </NestedContainerWrapper>
                                )}
                                {isArticle &&
                                    (!!knownProductCategoriesRef.current[elementAsArticle.id] || !!isExpanded) &&
                                    props.grade === 0 &&
                                    props.containerType !== 'productIds' && (
                                        <NestedContainerWrapper grade={0} className="expanded">
                                            <AttachedCategoriesList
                                                visible={isExpanded}
                                                productListUuids={
                                                    knownProductCategoriesRef.current[elementAsArticle.id] || []
                                                }
                                                onEditDetailsClick={() =>
                                                    props.history.push(
                                                        `/account/${props.accountSlug}/products/crud/${elementAsArticle.id}${props.origin}`,
                                                    )
                                                }
                                                accountSlug={props.accountSlug}
                                                origin={props.origin}
                                                hasPermission={props.hasPermission}
                                            />
                                        </NestedContainerWrapper>
                                    )}
                                {!isArticle && isExpanded && descendants.length === 0 && (
                                    <NestedContainerWrapper
                                        grade={0}
                                        className="expanded"
                                        style={{ padding: '1em 1em 0 1em' }}
                                    >
                                        <InfoCard
                                            active
                                            type="info"
                                            cardHeader="No active children categories or articles"
                                            cardText=""
                                            small
                                        />
                                    </NestedContainerWrapper>
                                )}
                            </div>
                        )
                    })}
                </NestedContainerWrapper>
            )}
        </>
    )
}
