import React, { CSSProperties, FC, PropsWithChildren, useRef } from 'react'
import Tooltip from './tooltip'
import uuid from 'uuid/v4'
import { ITooltip } from 'react-tooltip'

interface Props extends Omit<ITooltip, 'style' | 'className'> {
    text?: string
    tooltipStyle?: CSSProperties
    style?: CSSProperties
    className?: string
}

const TooltipWrapper: FC<PropsWithChildren<Props>> = (props) => {
    const { children, text, tooltipStyle, style, className, ...rest } = props
    const ref = useRef<string>(uuid())

    return (
        <>
            {!!text && (
                <Tooltip
                    closeOnScroll
                    className="tooltip"
                    style={tooltipStyle}
                    positionStrategy="fixed"
                    id={ref.current}
                    text={text}
                    {...rest}
                />
            )}
            <div className={className} style={style} data-tooltip-id={ref.current}>
                {children}
            </div>
        </>
    )
}

export default TooltipWrapper
