import React from 'react'
import styled from 'styled-typed'
import { Headline } from 'uiComponents/typography'
import { ContentSection } from 'uiComponents/pageElements'
import { ValidationMessage } from 'uiComponents/form/formElements'
import { Radio, RadioWrapper, RadioText } from 'uiComponents/input/radio'
import { MultiSelect } from 'uiComponents/input/multiSelectTree'
import { FilterOption } from 'uiComponents/filter/schema'

export type OrderAlertStatus = 'selectedProducts' | 'allProducts' | null

const RadioButtonContainer = styled.div`
    display: flex;
`
const RadioButtonWrapper = styled(RadioWrapper)`
    margin: 0.4375em 0;
`

interface ComponentProps {
    orderAlerts: OrderAlertStatus
    products: string[]
    productOptions: FilterOption[]
    showErrors: boolean
    onStatusChange: (value: OrderAlertStatus) => void
    onProductsChange: (value: string[]) => void
}

export function TransactionNotifications(props: ComponentProps) {
    const showProductsError = props.showErrors && props.orderAlerts === 'selectedProducts' && !props.products.length

    return (
        <ContentSection>
            <Headline size={4}>Transaction notifications</Headline>
            <RadioButtonContainer>
                <RadioButtonWrapper>
                    <Radio
                        id="selected-products"
                        onChange={() => props.onStatusChange('selectedProducts')}
                        checked={props.orderAlerts === 'selectedProducts'}
                        checkMarkSize="small"
                    />
                    <RadioText>Selected products</RadioText>
                </RadioButtonWrapper>
                <RadioButtonWrapper style={{ marginLeft: '1.5em' }}>
                    <Radio
                        id="all-products"
                        onChange={() => props.onStatusChange('allProducts')}
                        checked={props.orderAlerts === 'allProducts'}
                        checkMarkSize="small"
                    />
                    <RadioText>All products</RadioText>
                </RadioButtonWrapper>
                <RadioButtonWrapper style={{ marginLeft: '1.5em' }}>
                    <Radio
                        id="no-alerts"
                        onChange={() => props.onStatusChange(null)}
                        checked={!props.orderAlerts}
                        checkMarkSize="small"
                    />
                    <RadioText>None</RadioText>
                </RadioButtonWrapper>
            </RadioButtonContainer>
            <MultiSelect
                id="products"
                options={props.productOptions}
                applied={props.products}
                onApply={props.onProductsChange}
                noSelectOption="Select products"
                disabled={props.orderAlerts !== 'selectedProducts'}
                selectedItemName={props.products.length === 1 ? 'product' : 'products'}
                status={showProductsError ? 'error' : 'normal'}
            />
            <ValidationMessage className={showProductsError ? 'validation-message-visible' : ''} style={{ top: '0' }}>
                Please select at least one product
            </ValidationMessage>
        </ContentSection>
    )
}
