import * as React from 'react'
import ReactEcharts from 'echarts-for-react'
import { withTheme } from 'styled-typed'
import { DashboardTheme } from 'theme'
import { NoDataNotice, tooltipStyle } from 'uiComponents/charts/styleComponents'
import isEqual from 'lodash/isEqual'
import { TooltipParams } from './timeseriesBarChart'
import { EChartOptionGrid, EChartOptionLegend } from './types'

interface ChartData {
    name: string
    value: number
}

export interface BarChartSeries {
    name: string
    type: string
    barWidth?: string
    barGap?: number
    data: ChartData[]
    stack?: number
}

interface BarChartProps {
    loading: boolean
    title?: string
    series: BarChartSeries[]
    theme: DashboardTheme
    legentValues?: string[]
    legend?: EChartOptionLegend
    percent?: boolean
    chartHeight?: string
    gridTop?: number
    gridBottom?: number
    grid?: EChartOptionGrid
    legendHeigh?: number
    legendFromTop?: number
    colors?: string[]
    xAxisFormatter?: string
    tooltipFormatter?: (params: TooltipParams[]) => string
}

interface BarChartState {
    chartOption: any
}

class BarChartYAxis extends React.Component<BarChartProps & React.HTMLAttributes<HTMLElement>, BarChartState> {
    constructor(props: BarChartProps & React.HTMLAttributes<HTMLElement>) {
        super(props)
        this.state = {
            chartOption: {},
        }
    }

    fetchNamesFromData = () => {
        return this.props.series[0].data.map((d) => d.name)
    }

    formatLegend = () => {
        return this.props.series.length > 0 ? this.props.series.map((d) => d.name) : []
    }

    loadBarChartData = () => {
        const barChartOption = {
            textStyle: {
                fontFamily: 'Roboto',
            },
            title: {
                text: this.props.title || '',
                textStyle: {
                    fontSize: 20,
                    fontWeight: 'normal',
                },
                left: 'left',
                padding: [10, 0, 0, 0],
            },
            tooltip: {
                confine: true,
                formatter: this.props.tooltipFormatter
                    ? this.props.tooltipFormatter
                    : this.props.series.length > 1
                    ? ''
                    : this.props.percent
                    ? '{b}: {c}%'
                    : '{b}: {c}',
                trigger: 'axis',
                axisPointer: {
                    type: 'none',
                },
                padding: [5, 10],
                ...tooltipStyle,
            },
            xAxis: {
                axisLabel: {
                    color: '#A4AFB2',
                    formatter: this.props.xAxisFormatter,
                },
                axisLine: { show: false },
                axisTick: { show: false },
                splitLine: {
                    lineStyle: {
                        color: 'rgba(164, 175, 178, 0.3)',
                    },
                },
            },
            yAxis: {
                inverse: true,
                data: this.fetchNamesFromData(),
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: { show: false },
            },
            legend: {
                type: 'scroll',
                orient: 'vertical',
                top: this.props.legendFromTop || 340,
                left: 10,
                height: this.props.legendHeigh || 118,
                itemHeight: 14.5,
                itemGap: 8,
                data: this.props.legentValues ? this.props.legentValues : this.formatLegend(),
                selectedMode: false,
                ...this.props.legend,
            },
            grid: {
                top: this.props.gridTop || 40,
                bottom: this.props.gridBottom || 160,
                left: 20,
                right: 20,
                ...this.props.grid,
            },
            series: this.props.series,
            color: this.props.colors ? this.props.colors : this.props.theme.colors.chartRotatingColors,
        }
        this.setState({
            chartOption: barChartOption,
        })
    }

    componentDidMount() {
        this.loadBarChartData()
    }

    componentDidUpdate(prevProps: BarChartProps) {
        if (!isEqual(prevProps.series, this.props.series)) {
            this.loadBarChartData()
        }
    }

    getDataLength = () => {
        const withData = this.props.series.find((s) => !!s.data.find((d) => d.value > 0))
        return withData ? 999 : 0
    }

    render() {
        return (
            <NoDataNotice dataLength={this.getDataLength()} loading={this.props.loading}>
                <ReactEcharts
                    option={this.state.chartOption}
                    style={{
                        height: this.props.chartHeight ? this.props.chartHeight : '300px',
                        ...this.props.style,
                    }}
                    notMerge={true}
                    lazyUpdate={true}
                    onChartReady={() => {}}
                />
            </NoDataNotice>
        )
    }
}

export default withTheme(BarChartYAxis)
