import React from 'react'
import styled from 'styled-typed'
import { BaseRouteParams, withNavigation, WithNavigationProps } from 'hocs'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { IOrder, Order } from 'orders/schema'
import { useDispatch } from 'react-redux'
import { selectOrder } from 'orders/orders/redux'
import { renderSearch } from 'navigation'
import { useHasFeature } from 'features'
import { useLocation } from 'react-router-dom'

interface OrderIdProps extends WithNavigationProps<BaseRouteParams> {
    order: IOrder | Order
    onClick?: () => void
}

export const Link = styled.div`
    color: ${(props) => props.theme.colors.boyBlue};
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`

function OrderId({ order, match, onClick, navigation }: OrderIdProps) {
    const { accountSlug } = match.params
    const dispatch = useDispatch()
    const location = useLocation()
    const canUseNewOrderDetails = useHasFeature('use_new_order_details')

    const { id, number: num } = order

    const getLinkToOrderDetails = () => {
        if (canUseNewOrderDetails) {
            return `/account/${accountSlug}/orders/${id}/details`
        }

        const query = navigation.query()
        query.prevSortBy = query.sortBy
        query.prevSortDirection = query.sortDirection
        delete query.sortBy
        delete query.sortDirection
        query.prevPage = query.page
        query.page = '1'
        query.prevQ = query.q
        query.backTo = 'transactions_page'
        const search = renderSearch({ ...query, q: num })
        return `/account/${accountSlug}/orders/details${search}`
    }

    return (
        <Link>
            <UnstyledLink
                onClick={() => {
                    dispatch(selectOrder(order as IOrder))
                    if (onClick) {
                        onClick()
                    }
                }}
                to={{ pathname: getLinkToOrderDetails(), state: { ordersListParams: location.search } }}
            >
                {num}
            </UnstyledLink>
        </Link>
    )
}

export default withNavigation(OrderId)
