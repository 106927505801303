import * as React from 'react'
import styled from 'styled-typed'
import StatsContainer from './statsContainer'
import { format } from 'date-fns'
import { DateRange } from 'dateRanges'
import { EngageToolsService, EngageToolStats } from 'engageTools/engageToolsService'
import { startOfToday, addDays } from 'date-fns'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { withPermission } from 'admin/hocs'
import { MessageKind } from 'uiComponents/messages'
import { ProductName, ConfigurationVersions } from 'engageTools/studio/componentsService'
import CheckoutStatsContainer from './statsContainerCheckout'
import { WonderBarIcon, ModalWindowIcon, TriggerButtonIcon } from '../icons'
import { withFeatures } from 'features'
import isEqual from 'lodash/isEqual'

export const EngageToolName = styled.div`
    margin-bottom: 1.56em;
    font-weight: bold;
    display: flex;
    align-items: center;

    & span {
        margin-left: 1em;
    }
`
export const PermissionInfo = styled.div`
    font-size: 0.875em;
`
const Container = styled.div`
    margin-bottom: 3em;
`
interface ProductContainerProps {
    product: ProductName
    activeAccount: string
    versions: ConfigurationVersions[] | null
    engageToolsService: EngageToolsService
    dateRange: DateRange
    currentOrNextVersion: boolean
    hasPermission: (permission: string, accountSlug: string) => boolean
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hasFeature: (feature: string, accountSlug: string) => boolean
}

export type StatsData = { [id: string]: EngageToolStats[] }

interface ProductContainerState {
    data: StatsData
    loading: boolean
    checkoutTotals: EngageToolStats[] | null
}

class ProductContainer extends React.Component<ProductContainerProps, ProductContainerState> {
    productMap = {
        wonderbar: {
            name: 'WonderBar',
            icon: <WonderBarIcon />,
            statsMetric: 'wonderbar_stats',
        },
        modal_window: {
            name: 'Pop Up',
            icon: <ModalWindowIcon />,
            statsMetric: 'modal_window_stats',
        },
        trigger_button: {
            name: 'Trigger Button',
            icon: <TriggerButtonIcon />,
            statsMetric: 'trigger_button_stats',
        },
        checkout: {
            name: 'Checkout',
            icon: <ModalWindowIcon />,
            statsMetric: 'checkout_stats',
        },
    }
    constructor(props: ProductContainerProps) {
        super(props)
        this.state = {
            data: {},
            loading: true,
            checkoutTotals: null,
        }
    }

    async componentDidMount() {
        await this.getData()
    }

    async componentDidUpdate(prevProps: ProductContainerProps) {
        if (
            !areDateRangeDatesEqual(prevProps.dateRange, this.props.dateRange) ||
            prevProps.activeAccount !== this.props.activeAccount ||
            !isEqual(prevProps.versions, this.props.versions)
        ) {
            await this.getData()
        }
    }

    getData = async () => {
        if (!this.props.versions || this.props.versions.length === 0) {
            return
        }
        this.setState({ loading: true })
        try {
            const dateFrom = format(this.props.dateRange.from || new Date(0), 'yyyy-MM-dd')
            const dateTo = format(this.props.dateRange.to || addDays(startOfToday(), 1), 'yyyy-MM-dd')
            const allIds: string[] = this.props.versions.filter((v) => !!v.id).map((v) => v.id as string)
            const combinedStats = await this.props.engageToolsService.getEngageToolStatsCombined(
                this.props.activeAccount,
                this.productMap[this.props.product].statsMetric,
                dateFrom,
                dateTo,
                allIds,
            )
            const data: StatsData = {}
            Object.entries(combinedStats).forEach(([k, v]) => {
                Object.assign(data, {
                    [this.getIdFromStatsName(k)]: v.dataSeriesDictionary.details,
                })
            })
            this.setState({ data, loading: false })

            if (this.props.product === 'checkout') {
                const stats = await this.props.engageToolsService.getEngageToolStats(
                    this.props.activeAccount,
                    this.productMap[this.props.product].statsMetric,
                    dateFrom,
                    dateTo,
                    allIds,
                )
                this.setState({ checkoutTotals: stats, loading: false })
            }
        } catch {
            this.props.replaceTopMessages(
                'server_error',
                'error',
                `Oops! ${this.productMap[this.props.product].name} \
        stats could not be loaded, please try again later.`,
            )
            this.setState({ data: {}, loading: false })
        }
    }

    getIdFromStatsName = (name: string) => {
        return name.substring(1).replace(/_+/g, '-')
    }

    sortByDeviceType = (a: ConfigurationVersions, b: ConfigurationVersions) => {
        if (!a.deviceType && b.deviceType) {
            return 0
        }
        if (!a.deviceType) {
            return -1
        }
        if (!b.deviceType) {
            return 1
        }
        if (a.deviceType < b.deviceType) {
            return 1
        }
        return -1
    }

    render() {
        const { product, activeAccount, versions, currentOrNextVersion } = this.props
        const atLeastOneCurrentVersion = versions && versions.find((v) => !!v.current)

        return (
            <Container>
                {currentOrNextVersion && (
                    <>
                        <EngageToolName>
                            {this.productMap[product].icon}
                            <span>{this.productMap[product].name}</span>
                        </EngageToolName>
                        {versions &&
                            versions.sort(this.sortByDeviceType).map((v, i) => (
                                <div key={i} style={{ marginBottom: '1em' }}>
                                    {product === 'checkout' && (
                                        <CheckoutStatsContainer
                                            product={product}
                                            data={v.id ? this.state.data[v.id] : null}
                                            versions={v}
                                            activeAccount={activeAccount}
                                            loading={this.state.loading}
                                            disabled={!v.current}
                                        />
                                    )}
                                    {product !== 'checkout' && (
                                        <StatsContainer
                                            product={product}
                                            data={v.id ? this.state.data[v.id] : null}
                                            versions={v}
                                            activeAccount={activeAccount}
                                            loading={this.state.loading}
                                            disabled={!v.current}
                                        />
                                    )}
                                </div>
                            ))}
                        {product === 'checkout' && versions && versions.length > 1 && (
                            <CheckoutStatsContainer
                                product={product}
                                data={this.state.checkoutTotals || null}
                                versions={null}
                                activeAccount={activeAccount}
                                loading={this.state.loading}
                                disabled={!atLeastOneCurrentVersion}
                            />
                        )}
                    </>
                )}
            </Container>
        )
    }
}

export default withFeatures(withPermission(ProductContainer))
