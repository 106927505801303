import * as React from 'react'
import { Order } from '../schema'
import { User } from 'auth/state'
import { withCurrentUser } from 'hocs'
import { OnClickMenu, OnClickMenuItem } from 'uiComponents/menus/'
import Permission from 'admin/permissionRequired'
import Feature from 'features/feature'
import { ModalDialog } from 'uiComponents/popups/modal'
import RefundDialog from 'orders/transactions/refunds/refundDialog'
import { OrdersService } from '../ordersService'
import { delay } from 'utils'
import { MessageKind } from 'uiComponents/messages'
import { LoggingService } from 'http/loggingService'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { withFeatures } from 'features'
import { generateOnRefundErrorMessage } from 'orders/utils/orderRefundErrorMessage'

interface ActionsProps {
    user: User
    order: Order
    accountSlug: string
    ordersService: OrdersService
    loggingService: LoggingService
    rowIndex: number
    rowCount: number
    reloadOrderList: () => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    hideMessage: (id: string) => void
    hasFeature: (feature: string, slug: string) => boolean
}

interface ActionsState {
    cancellation: boolean
    showEmailResendDialog: boolean
    showRefundDialog: boolean
    refundDialogContentHeight: number
    loading: boolean
}

class ActionsMenu extends React.Component<ActionsProps, ActionsState> {
    constructor(props: ActionsProps) {
        super(props)
        this.state = {
            cancellation: false,
            showEmailResendDialog: false,
            showRefundDialog: false,
            refundDialogContentHeight: 0,
            loading: false,
        }
    }

    onRefund = async (cancellation: boolean, e?: Error) => {
        this.setState({ showRefundDialog: false })
        if (e) {
            this.props.replaceMessages(
                'unknown_error',
                'error',
                generateOnRefundErrorMessage(e, cancellation, this.props.order.id),
            )
        } else {
            this.props.reloadOrderList()
            const message = cancellation
                ? `Order ${this.props.order.id} cancellation request was successful`
                : `Order ${this.props.order.id} refund request was successfuly placed and is being processed`
            this.props.replaceMessages('refunded_successfully', 'success', message)
            await delay(5000)
            this.props.hideMessage('refunded_successfully')
        }
    }

    onRefundDialogHeightChange = (height: number) => {
        this.setState({ refundDialogContentHeight: height })
    }

    resendEmail = async () => {
        try {
            this.setState({ loading: true })
            await this.props.ordersService.resendEmail(this.props.order.id)
            this.setState({ showEmailResendDialog: false, loading: false })
            this.props.replaceMessages(
                'resent_successfully',
                'success',
                `Email to ${this.props.order.customer.email} sent successfully`,
            )
            await delay(5000)
            this.props.hideMessage('resent_successfully')
        } catch {
            this.setState({ showEmailResendDialog: false, loading: false })
            this.props.replaceMessages('unknown_error', 'error', 'Oops! We could not send the email. Please try again.')
        }
    }

    downloadTicket = (e: React.MouseEvent<any>) => {
        const eventData = {
            category: 'orders_transactions',
            order: this.props.order.id,
        }
        this.props.loggingService.logAction('order_list_ticket_download', eventData)
    }

    openRefundDialog = (cancellation: boolean = false) => {
        this.props.hideMessage('unknown_error')
        this.setState({ cancellation, showRefundDialog: true })
    }

    closeRefundDialog = () => {
        this.setState({ showRefundDialog: false })
    }

    getBottomOffset = () => {
        if (this.props.rowCount === 1) {
            return '-2em'
        }
        const numberFromBottom = this.props.rowCount - this.props.rowIndex
        if (numberFromBottom === 1) {
            return '-0.7em'
        }
        if (numberFromBottom < 4) {
            return '-3.4em'
        }
        return ''
    }

    render() {
        const { accountSlug, order, ordersService } = this.props
        const { showRefundDialog, loading, refundDialogContentHeight, showEmailResendDialog } = this.state

        const nonRefundableStatuses = ['refunded', 'refunding', 'pending']
        const showRefundOption =
            !nonRefundableStatuses.includes(order.paymentStatus) &&
            order.canRefund &&
            order.total > 0 &&
            order.paymentProvider !== 'Settled externally'
        const showCancelOption =
            order.orderStatus !== 'cancelled' &&
            order.paymentStatus !== 'refunded' &&
            order.paymentStatus !== 'refunding'
        const canGetTickets = order.orderStatus !== 'cancelled'

        return (
            <OnClickMenu
                className="actions-button"
                title="Actions"
                kind="action"
                secondary
                customFromTop="2.6em"
                width="11.3em"
                alignRight
                customFromBottom={this.getBottomOffset()}
            >
                {showRefundDialog && (
                    <ModalDialog
                        onDismiss={this.closeRefundDialog}
                        interactive
                        fromTop="10%"
                        dynamicHeight={refundDialogContentHeight}
                    >
                        <RefundDialog
                            accountSlug={accountSlug}
                            orderNumber={order.id}
                            cancellation={this.state.cancellation}
                            onRefund={this.onRefund}
                            cancelRefund={this.closeRefundDialog}
                            refunding={loading}
                            onHeightChange={this.onRefundDialogHeightChange}
                            ordersService={ordersService}
                            replaceMessages={this.props.replaceMessages}
                        />
                    </ModalDialog>
                )}
                {showEmailResendDialog && (
                    <ConfirmationDialog
                        title="Resend order confirmation?"
                        text={`To: ${order.customer.email}`}
                        buttonText="Resend"
                        onCancel={() => this.setState({ showEmailResendDialog: false })}
                        onConfirm={this.resendEmail}
                        loading={loading}
                    />
                )}
                <Feature name="OrderRefunds" accountSlug={accountSlug}>
                    <Permission name="refund_orders" accountSlug={accountSlug}>
                        {showRefundOption && (
                            <OnClickMenuItem onClick={() => this.openRefundDialog()} className="refund-button">
                                Refund
                            </OnClickMenuItem>
                        )}
                    </Permission>
                </Feature>
                <Permission name="refund_orders" accountSlug={accountSlug}>
                    {showCancelOption && (
                        <OnClickMenuItem onClick={() => this.openRefundDialog(true)} className="cancel-button">
                            Cancel {order.total > 0 ? '(no refund)' : ''}
                        </OnClickMenuItem>
                    )}
                </Permission>
                {canGetTickets && (
                    <>
                        {!!order.customer?.email && (
                            <OnClickMenuItem onClick={() => this.setState({ showEmailResendDialog: true })}>
                                Resend email
                            </OnClickMenuItem>
                        )}
                        <OnClickMenuItem>
                            <a
                                href={order.ticketDownloadUrl}
                                onClick={this.downloadTicket}
                                target={this.props.order.unfilledApf ? '_blank' : ''}
                                rel="noreferrer"
                            >
                                Download ticket
                            </a>
                        </OnClickMenuItem>
                    </>
                )}
            </OnClickMenu>
        )
    }
}

export default withFeatures(withCurrentUser(ActionsMenu))
