import React, { useCallback, useContext, useMemo } from 'react'
import { get } from 'lodash'

import { StudioFormContext } from 'engageTools/studio/context'
interface StudioFieldWrapperProps {
    component: any
    multi?: boolean
    name: string
    normalize?: (value: any) => any
    type?: 'input' | 'checkbox'
    [key: string]: any
}

const StudioFieldWrapper: React.FC<StudioFieldWrapperProps> = ({
    component: Component,
    name,
    normalize,
    type = 'input',
    multi,
    ...props
}) => {
    const { values, handleFieldChange } = useContext(StudioFormContext)
    const value = useMemo(() => {
        const _value = get(values, name)

        if (!!multi && !Array.isArray(_value)) {
            if (!!_value) {
                return [_value]
            }

            return []
        }

        return _value
    }, [values, name, multi])

    const onChange = useCallback(
        (innerValue: any) => {
            if (normalize) {
                return handleFieldChange(name, normalize(innerValue))
            }

            return handleFieldChange(name, innerValue)
        },
        [handleFieldChange, normalize],
    )

    const customProps = { onChange } as any

    if (type === 'checkbox') {
        customProps.checked = value
    } else {
        customProps.value = value
    }

    return <Component type={type} name={name} {...customProps} {...props} />
}

export default StudioFieldWrapper
