import React, { useState } from 'react'
import { delay } from 'utils'
import styled from 'styled-typed'
import DropToUpload from 'react-drop-to-upload'
import { MessageKind } from 'uiComponents/messages'
import { UploadedFile } from 'orders/ordersService'
import { ImageUploadResponse, CropOptions } from 'http/imagesService'
import { PartnerIdUploadResult } from 'settings/settingsService'
import { ImageUrl } from 'engageTools/studio/componentsService'
import { UploadLoader } from 'uiComponents/loaders'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleUp } from '@fortawesome/pro-light-svg-icons'
import { UploadDetails } from 'venue/bookingCodes/bookingCodesService'
import { ModalDialog } from 'uiComponents/popups/modal'
import { ImageCropper } from 'uiComponents/image/imageCropper'
import { ActionButton } from 'uiComponents/buttons'

interface UploadProps {
    uploaded?: boolean
    success?: boolean
}

const Container = styled.div`
    position: relative;
    height: 2.8125em;
    width: 100%;
`

export const ProgressBar = styled.div<UploadProps>`
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 0;
    background: ${(props) => (props.success ? props.theme.colors.status.success : props.theme.colors.status.error)};
    box-shadow: 0 5px 15px 0 rgba(0, 162, 11, 0.5);
    border-radius: 0.375em;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: width 0s 1s, opacity 0.5s ease-in, visibility 0s 0.5s;

    &.visible {
        width: ${(p) => (p.uploaded ? '100%' : '45%')};
        visibility: visible;
        opacity: 1;
        transition: width 1s ease-in;
    }
`

export const UploadContainer = styled(DropToUpload)<{ over: boolean }>`
    padding: 0 1em;
    height: 100%;
    display: flex;
    align-items: center;
    border: 1px dashed ${(props) => props.theme.colors.textLight};
    background: ${(props) => (props.over ? '#FFF' : props.theme.colors.background)};
    border-radius: 0.375em;
    font-weight: lighter;
    transition: background 0.2s ease-in;

    &.area {
        flex-direction: column;
        justify-content: center;
        position: relative;
    }
    &.error {
        border: 1px dashed ${(props) => props.theme.colors.status.error};
    }
    &.success {
        border: 1px dashed ${(props) => props.theme.colors.status.success};
    }
`
export const UploadLink = styled.label`
    color: ${(props) => props.theme.colors.textDark};
    font-weight: normal;
    cursor: pointer;
    text-decoration: underline;
`

export const UploadText = styled.div`
    opacity: 0;
    transition: opacity 0.1s ease-in;

    &.visible {
        opacity: 1;
        transition: opacity 0.1s 0.3s ease-in;
    }
`
export const UploadAreaText = styled.div`
    font-size: 0.9em;
`
export const FileDialogLink = styled.label`
    cursor: pointer;
    text-decoration: underline;
`
export const ProgressBarContainer = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 0.375em;
    overflow: hidden;
    visibility: hidden;

    &.visible {
        visibility: visible;
    }
`
const ActionWrapper = styled.div`
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
`

export interface ImgDimentions {
    width: number
    height: number
}

export function retrieveImgDimentions(file: File): Promise<ImgDimentions | undefined> | ImgDimentions | undefined {
    const windowObject = window as any
    if (!windowObject.FileReader || !windowObject.FileReader.prototype.readAsDataURL) {
        return { width: 0, height: 0 }
    }
    return new Promise<ImgDimentions | undefined>((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.onloadend = function (e: ProgressEvent) {
            if (fileReader.result) {
                let width = null
                let height = null
                const img = new Image()
                img.src = fileReader.result as string
                img.onload = function () {
                    width = img.width
                    height = img.height
                    resolve({ width, height })
                }
                img.onerror = function () {
                    resolve(undefined)
                }
            } else {
                resolve({ width: 0, height: 0 })
            }
        }
        fileReader.readAsDataURL(file)
    })
}

export function retrieveFileType(file: File): Promise<string> | string {
    const windowObject = window as any
    if (!windowObject.FileReader || !windowObject.FileReader.prototype.readAsArrayBuffer) {
        return file.type
    }
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.onloadend = function (e: ProgressEvent) {
            let type
            if (!fileReader.result) {
                type = file.type
                resolve(type)
            }
            const arr = new Uint8Array(fileReader.result as SharedArrayBuffer).subarray(0, 4)
            let header = ''
            for (let i = 0; i < arr.length; i++) {
                header += arr[i].toString(16)
            }
            switch (header) {
                case '89504e47':
                    type = 'image/png'
                    break
                case '47494638':
                    type = 'image/gif'
                    break
                case 'ffd8ffe0':
                case 'ffd8ffe1':
                case 'ffd8ffe2':
                case 'ffd8ffe3':
                case 'ffd8ffe8':
                    type = 'image/jpeg'
                    break
                case 'd0cf11e0':
                case '504b34':
                    type = 'file/xls'
                    break
                default:
                    type = file.type
                    break
            }
            resolve(type)
        }
        fileReader.readAsArrayBuffer(file)
    })
}

export type UploadResponse = UploadedFile | ImageUrl | PartnerIdUploadResult | ImageUploadResponse | UploadDetails
export type UploadStatusType = 'error' | 'success' | 'normal'

interface UploadInputProps {
    inputId: string
    acceptSvg?: boolean
    type: 'icon' | 'img' | 'file'
    status?: UploadStatusType
    acceptedTypes?: string
    acceptedTypesMsg?: string
    extensionCheckOnly?: boolean
    style?: React.CSSProperties
    fileSizeLimit?: number
    uploadArea?: boolean
    parseErrorMessage?: boolean
    enableCropping?: boolean
    onUpload: (file: File, cropArea?: CropOptions) => Promise<UploadResponse>
    handleUploadSuccessResponse: (response: UploadResponse) => void
    handleUploadError?: () => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

export function UploadInput(props: UploadInputProps) {
    const [overDropArea, setOverDropArea] = useState<boolean>(false)
    const [uploading, setUploading] = useState<boolean>(false)
    const [uploaded, setUploaded] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(true)
    const [fileString, setFileString] = useState('')
    const [file, setFile] = useState<File>()
    const [showCropper, setShowCropper] = useState(false)

    async function checkErrors(files: File[] | FileList): Promise<boolean> {
        const acceptedFileFormats = props.acceptedTypes
            ? props.acceptedTypes.split(', ')
            : props.type === 'icon'
            ? ['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml']
            : ['image/jpg', 'image/jpeg', 'image/png']
        const acceptedExtentions = acceptedFileFormats.map((f) => f.split('/')[1])
        const fileNameParts = files[0].name.split('.')
        const extension = fileNameParts[fileNameParts.length - 1]

        if (files.length !== 1) {
            flashErrorMessage('Please drop one file only.')
            return false
        }

        const type = await retrieveFileType(files[0])
        const checkExtensionOnly = !type || props.extensionCheckOnly
        if (
            (checkExtensionOnly && acceptedExtentions.indexOf(extension) === -1) ||
            (!checkExtensionOnly && acceptedFileFormats.indexOf(type) === -1)
        ) {
            const messageText = props.acceptedTypesMsg
                ? `Please upload ${props.acceptedTypesMsg} file.`
                : acceptedFileFormats.indexOf('image/svg+xml') === -1
                ? 'Please upload a JPG/JPEG or PNG file.'
                : 'Please upload a JPG/JPEG, PNG or SVG file.'
            flashErrorMessage(messageText)
            return false
        }

        const fileSizeLimit = props.fileSizeLimit ? props.fileSizeLimit : 1
        if (files[0].size > fileSizeLimit * 1000000) {
            flashErrorMessage(`File size should not exceed ${fileSizeLimit}MB.`)
            return false
        }
        setOverDropArea(false)
        return true
    }

    async function flashErrorMessage(text: string) {
        props.replaceTopMessages('validation_error', 'error', text)
        setOverDropArea(false)
        await delay(6000)
        props.removeAllMessages()
    }

    async function handleDropUpload(files: File[]) {
        if (await checkErrors(files)) {
            if (!props.enableCropping) {
                uploadFile(files[0])
            } else {
                setFile(files[0])
                onFileAdded(files[0])
            }
        }
    }

    async function handleBrowseUpload(e: React.ChangeEvent<HTMLInputElement>) {
        e.persist()
        if (e.target.files && e.target.files.length > 0) {
            if (await checkErrors(e.target.files)) {
                if (!props.enableCropping) {
                    uploadFile(e.target.files[0])
                } else {
                    setFile(e.target.files[0])
                    onFileAdded(e.target.files[0])
                }
            }
            e.target.value = ''
        }
    }

    const onFileAdded = (newFile: File) => {
        const reader = new FileReader()
        reader.onload = (event: ProgressEvent<FileReader>) => {
            if (typeof event.target?.result === 'string') {
                setFileString(event.target.result)
                setShowCropper(true)
            } else {
                props.replaceTopMessages('validation_error', 'error', 'The selected file could not be read')
            }
        }
        reader.readAsDataURL(newFile)
    }

    async function uploadFile(inputFile: File, cropOptions?: CropOptions) {
        setShowCropper(false)
        setUploading(true)
        try {
            const response = await props.onUpload(inputFile, cropOptions)
            setUploading(false)
            setUploaded(true)
            props.handleUploadSuccessResponse(response)
            setTimeout(() => setUploaded(false), 2000)
            setSuccess(true)
        } catch (e) {
            const message =
                props.parseErrorMessage && !(e instanceof TypeError) && e.message
                    ? e.message
                    : 'Oops! Something went wrong with uploading the file, please try again.'
            props.replaceTopMessages('unknown_error', 'error', message)
            setUploading(false)
            setTimeout(() => {
                setUploaded(false)
                props.removeAllMessages()
            }, 5000)
            setSuccess(false)
            props.handleUploadError?.()
        }
    }

    const renderCropper = () => {
        if (fileString.length <= 0 || !showCropper) {
            return null
        }

        return (
            <Cropper
                imageSrc={fileString}
                onDismiss={() => setShowCropper(false)}
                cropAccept={(cropOptions) => {
                    if (file) {
                        uploadFile(file, cropOptions)
                    }
                }}
                cropCancel={() => setShowCropper(false)}
            />
        )
    }

    function renderAcceptedImageTypes(): string {
        const baseImageTypes = 'image/png, image/jpeg, image/jpg'
        return props.acceptSvg ? baseImageTypes + ', image/svg+xml' : baseImageTypes
    }

    return (
        <Container style={props.style}>
            <UploadContainer
                className={props.uploadArea ? `area ${props.status}` : props.status}
                over={overDropArea}
                onDrop={handleDropUpload}
                onOver={() => setOverDropArea(true)}
                onLeave={() => setOverDropArea(false)}
            >
                {!props.uploadArea && (
                    <UploadText className={uploading || uploaded ? '' : 'visible'}>
                        Drag and drop to upload or <UploadLink htmlFor={props.inputId}>browse</UploadLink>
                    </UploadText>
                )}
                {props.uploadArea && (
                    <>
                        {uploading && <UploadLoader />}
                        <UploadAreaText>
                            <FontAwesomeIcon icon={faArrowCircleUp} style={{ marginRight: '.5em' }} />
                            Drag and drop to upload or&nbsp;
                            <FileDialogLink htmlFor={props.inputId}>choose a file</FileDialogLink>
                        </UploadAreaText>
                    </>
                )}
                <input
                    onChange={handleBrowseUpload}
                    type="file"
                    accept={props.acceptedTypes ? props.acceptedTypes : renderAcceptedImageTypes()}
                    id={props.inputId}
                    hidden
                />
            </UploadContainer>
            {!props.uploadArea && (
                <ProgressBarContainer className={uploading || uploaded ? 'visible' : ''}>
                    <ProgressBar
                        className={uploading || uploaded ? 'visible' : ''}
                        uploaded={uploaded}
                        success={success}
                    >
                        <UploadText className={uploaded ? 'visible' : ''} style={{ marginLeft: '1em' }}>
                            {success ? 'Success!' : 'Error!'}
                        </UploadText>
                    </ProgressBar>
                </ProgressBarContainer>
            )}
            {renderCropper()}
        </Container>
    )
}

type CropperProps = {
    imageSrc: string
    aspectRatio?: number
    onDismiss(): void
    cropAccept(cropArea: CropOptions): void
    cropCancel(): void
}

type CropArea = {
    width: number
    height: number
    x: number
    y: number
}

const Cropper = ({ imageSrc, aspectRatio = 16 / 9, onDismiss, cropAccept, cropCancel }: CropperProps) => {
    const [cropArea, setCropArea] = useState<CropArea>()

    const cropAreaMoved = (event: CropArea) => {
        setCropArea(event)
    }

    const convertCropAreaToCropOptions = (input: CropArea): CropOptions => {
        return {
            crop_x: Math.floor(Math.max(0, input.x)),
            crop_y: Math.floor(Math.max(0, input.y)),
            crop_height: Math.floor(Math.max(0, input.height)),
            crop_width: Math.floor(Math.max(0, input.width)),
        }
    }

    return (
        <ModalDialog onDismiss={onDismiss} interactive>
            <ActionWrapper>
                <ActionButton onClick={cropCancel}>Cancel</ActionButton>
                <ActionButton
                    onClick={() => {
                        if (cropArea) {
                            const cropOptions = convertCropAreaToCropOptions(cropArea)
                            cropAccept(cropOptions)
                        }
                    }}
                >
                    Save
                </ActionButton>
            </ActionWrapper>
            <ImageCropper
                id="cropper"
                src={imageSrc}
                cropOptions={{
                    aspectRatio: aspectRatio,
                    initialAspectRatio: aspectRatio,
                    checkCrossOrigin: true,
                    viewMode: 1,
                    responsive: false,
                }}
                onCrop={(e, _id) => cropAreaMoved(e)}
            />
        </ModalDialog>
    )
}
