import * as React from 'react'
import styled, { css } from 'styled-typed'
import { Debounce } from 'debounce'
import { rtlLanguages } from 'utils/languages'
import { TextInputProps } from './interface'
import { commonStyles, blockCss, successCss, errorCss } from './styleComponents'
import InputMask from 'react-input-mask'
import { InputHTMLAttributes } from 'react'

export const inputFieldStyles = css`
    ${commonStyles}
    border: 1px solid transparent;
    ${(props: TextInputProps) => (props.block ? blockCss : '')}
    ${(props: TextInputProps) => (props.status === 'success' ? successCss : '')}
    ${(props: TextInputProps) => (props.status === 'error' ? errorCss : '')}

    &:focus {
        ${(props: TextInputProps) => (props.status === 'success' ? successCss : '')}
        ${(props: TextInputProps) => (props.status === 'error' ? errorCss : '')}
    }
    ${(props) => (props.whiteBackground ? 'background-color: #FFF;' : '')};
    ${(props) => (props.border ? `border: 1px solid ${props.theme.colors.textLight};` : '')};
    ${(props) => (props.rtlInput ? 'direction: rtl;' : '')}

    ${(props) => (props.prefix ? 'padding: 0 0.5em 0 2.25em;' : '')}
`

export const PrefixText = styled.span`
    position: absolute;
    bottom: 50%;
    transform: translateY(8px);
    left: 1rem;
    color: ${(props) => props.theme.colors.textDark};
    z-index: 2;
`

export const PostfixText = styled.span`
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    color: ${(props) => props.theme.colors.textDark};
`

export const TextInputField = styled.input.attrs<React.InputHTMLAttributes<HTMLInputElement>>((props) => ({
    type: props.type ? props.type : 'text',
}))`
    ${inputFieldStyles}
`

export function restrictToMaxLength(value: number | string | string[], max: number): number | string | string[] {
    let newValue = value.toString()
    if (newValue.length > max) {
        newValue = newValue.slice(0, max)
        return newValue
    }
    return value
}

type InputMaskWrapperProps = {
    mask?: string
    value?: string | number | readonly string[] | undefined
    component?: any
    inputRef?: any
}

const InputMaskWrapper: React.FC<InputMaskWrapperProps & Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'>> = ({
    component: InputComponent,
    mask,
    value,
    onChange,
    onFocus,
    onBlur,
    disabled,
    inputRef,
    ...rest
}) => {
    if (!mask) {
        return (
            <InputComponent
                disabled={disabled}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                ref={inputRef}
                {...rest}
            />
        )
    }

    return (
        <InputMask mask={mask} value={value} onChange={onChange} disabled={disabled} onFocus={onFocus} onBlur={onBlur}>
            {(inputProps: any) => <InputComponent ref={inputRef} {...inputProps} {...rest} disabled={disabled} />}
        </InputMask>
    )
}

export class TextInput extends React.PureComponent<
    TextInputProps & Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'>,
    {}
> {
    private finishTypingLater: Debounce<string> | undefined

    constructor(props: TextInputProps & React.InputHTMLAttributes<HTMLInputElement>) {
        super(props)

        if (this.props.onFinishTyping) {
            this.finishTypingLater = new Debounce(this.props.onFinishTyping, this.props.finishTypingDelay || 500)
        }
    }

    componentWillUnmount() {
        if (this.finishTypingLater) {
            this.finishTypingLater.clear()
        }
    }

    onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target.cloneNode() as HTMLInputElement
        if (!!target.value) {
            if (typeof this.props.maxLength !== 'undefined') {
                target.value = restrictToMaxLength(target.value, this.props.maxLength) as string
            }
        }
        event.target = target

        if (this.props.onChange) {
            this.props.onChange(event)
        }
        if (this.finishTypingLater) {
            this.finishTypingLater.trigger(target.value)
        }
    }

    onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const event = e
        if (this.props.onBlur) {
            this.props.onBlur(event)
        }
    }

    render() {
        const { onChange, value, maxLength, onFinishTyping, onBlur, inputRef, locale, onFocus, ...rest } = this.props
        let newValue = value
        if (!!newValue && typeof maxLength !== 'undefined') {
            newValue = restrictToMaxLength(newValue as number | string | string[], maxLength)
        }
        const rtlInput = rtlLanguages.includes(locale || 'en')
        return (
            <div style={{ position: 'relative' }}>
                {!!this.props.prefix && <PrefixText>{this.props.prefix}</PrefixText>}
                <InputMaskWrapper
                    component={TextInputField}
                    value={newValue}
                    onChange={this.onChanged}
                    onFocus={onFocus}
                    onBlur={this.onBlur}
                    inputRef={inputRef}
                    rtlInput={rtlInput}
                    {...rest}
                />
                {!!this.props.postfix && <PostfixText className="postfix">{this.props.postfix}</PostfixText>}
            </div>
        )
    }
}
