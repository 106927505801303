const useExpandedCategory = () => {
    const getExpandedIndex = (): number => {
        return Number(localStorage.getItem('expanded-parkmap-category'))
    }

    const saveExpandedIndex = (index: string | number): void => {
        localStorage.setItem('expanded-parkmap-category', String(index))
    }

    return {
        getExpandedIndex,
        saveExpandedIndex,
    }
}

export default useExpandedCategory
