import * as React from 'react'
import { isValidEmail, isValidContactNumber } from 'utils/formFieldChecks'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { TextInput } from 'uiComponents/input'
import {
    Section,
    SectionForm,
    InputColumn,
    PageActions,
    InputRow,
    EmailConfirmationMessage,
    EmailResendMessage,
} from 'uiComponents/settingsContainer'
import { ValidationCheckmark } from 'uiComponents/validationCheckmark'
import { ActionButton } from 'uiComponents/buttons'
import { Profile } from 'settings/schema'
import { UpdatedAccountDetails } from 'settings/schema'
import { delay } from 'utils'
import { ChartDataLoader } from 'uiComponents/loaders'

interface PersonalSectionProps {
    profile: Profile
    onSave: (newDetails: UpdatedAccountDetails, password: string) => void
    resendEmail: () => void
    loading: boolean
    welcome: boolean
    setActiveSection: (section: string) => void
}

interface PersonalSectionState {
    firstName: string
    lastName: string
    email: string
    role: string
    contactNumber: string
    password: string
    passRepeat: string
    firstNameMsg: string
    lastNameMsg: string
    emailMessage: string
    numberMessage: string
    passRepeatMsg: string
    passValid: boolean | null
    emailConfirmed: boolean
    emailResent: boolean
}

class PersonalSection extends React.Component<PersonalSectionProps, PersonalSectionState> {
    constructor(props: PersonalSectionProps) {
        super(props)
        this.state = {
            firstName: this.props.profile.firstName || '',
            lastName: this.props.profile.lastName || '',
            email: this.props.profile.email || '',
            role: this.props.profile.company.role || '',
            contactNumber: this.props.profile.contactNo || '',
            password: '',
            passRepeat: '',
            firstNameMsg: '',
            lastNameMsg: '',
            emailMessage: '',
            numberMessage: '',
            passValid: null,
            passRepeatMsg: '',
            emailConfirmed: this.props.profile.emailConfirmed,
            emailResent: false,
        }
    }

    componentDidMount() {
        this.props.setActiveSection('personal')
    }

    componentDidUpdate(prevProps: PersonalSectionProps) {
        if (this.props.profile.emailConfirmed !== prevProps.profile.emailConfirmed) {
            this.setState({ emailConfirmed: this.props.profile.emailConfirmed })
        }
    }

    onSave = async () => {
        if (!(await this.checkFormValid())) {
            return
        }
        const noChanges =
            this.state.firstName === this.props.profile.firstName &&
            this.state.lastName === this.props.profile.lastName &&
            this.state.contactNumber === this.props.profile.contactNo &&
            this.state.email === this.props.profile.email &&
            this.state.role === this.props.profile.company.role
        if (noChanges) {
            return
        }
        const newPersonalDetails = {
            firstName: this.state.firstName.trim(),
            lastName: this.state.lastName.trim(),
            contactNo: this.state.contactNumber,
            email: this.state.email !== this.props.profile.email ? this.state.email : undefined,
            companyRole: this.state.role,
        }
        this.props.onSave(newPersonalDetails, this.state.password)
    }

    checkFormValid = async () => {
        const firstNameProvided = this.checkFirstName()
        const lastNameProvided = this.checkLastName()
        const emailValid = await this.checkValidEmail()
        const contactNumberValid = this.checkValidNumber()
        const passValid = this.checkValidPass()
        const passMatch = this.checkPassMatch()
        const formValid = this.props.welcome
            ? firstNameProvided && lastNameProvided && emailValid && contactNumberValid && passValid && passMatch
            : firstNameProvided && lastNameProvided && emailValid && contactNumberValid
        return formValid
    }

    checkFirstName = () => {
        if (!this.state.firstName.trim()) {
            this.setState({ firstNameMsg: 'Please enter your first name' })
        } else if (this.state.firstName.trim().length > 30) {
            this.setState({
                firstNameMsg: 'Please enter no more than 30 characters',
            })
        } else {
            this.setState({ firstNameMsg: '' })
        }
        return !!this.state.firstName && this.state.firstName.length < 31
    }

    checkLastName = () => {
        if (!this.state.lastName.trim()) {
            this.setState({ lastNameMsg: 'Please enter your last name' })
        } else if (this.state.lastName.trim().length > 50) {
            this.setState({ lastNameMsg: 'Please enter no more than 50 characters' })
        } else {
            this.setState({ lastNameMsg: '' })
        }
        return !!this.state.lastName && this.state.lastName.length < 51
    }

    checkValidEmail = async () => {
        if (!(await isValidEmail(this.state.email))) {
            this.setState({ emailMessage: 'Please enter a valid email address' })
            return false
        } else {
            this.setState({ emailMessage: '' })
        }
        return true
    }

    checkValidNumber = () => {
        if (!!this.state.contactNumber && !isValidContactNumber(this.state.contactNumber)) {
            this.setState({ numberMessage: 'Please enter a valid contact number' })
            return false
        } else {
            this.setState({ numberMessage: '' })
        }
        return true
    }

    checkValidPass = () => {
        if (this.state.password.length < 12) {
            this.setState({ passValid: false })
            return false
        } else {
            this.setState({ passValid: true })
        }
        if (this.state.passRepeat) {
            this.checkPassMatch()
        }
        return true
    }

    checkPassMatch = () => {
        if (this.state.password !== this.state.passRepeat) {
            this.setState({ passRepeatMsg: 'Passwords do not match' })
            return false
        } else {
            this.setState({ passRepeatMsg: '' })
        }
        return true
    }

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            [event.target.name]: event.target.value,
            showMessage: false,
        } as any)
    }

    resendEmail = () => {
        this.props.resendEmail()
        this.setState({ emailResent: true }, async () => {
            await delay(2000)
            this.setState({ emailResent: false })
        })
    }

    render() {
        const {
            firstName,
            lastName,
            email,
            contactNumber,
            role,
            emailMessage,
            numberMessage,
            passRepeatMsg,
            firstNameMsg,
            lastNameMsg,
            emailConfirmed,
            password,
            passRepeat,
            passValid,
            emailResent,
        } = this.state
        const { welcome } = this.props

        return (
            <Section style={{ position: 'relative' }}>
                {this.props.loading && <ChartDataLoader />}
                <SectionForm noValidate style={welcome ? { minHeight: '30.5em' } : {}}>
                    <div>
                        <InputRow>
                            <InputColumn>
                                <FormItem htmlFor="firstName">
                                    <FormItemName>First name</FormItemName>
                                    <TextInput
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        value={firstName ? firstName : ''}
                                        onChange={this.handleInputChange}
                                        onFinishTyping={this.checkFirstName}
                                        status={firstNameMsg ? 'error' : 'normal'}
                                        block={true}
                                    />
                                </FormItem>
                                <ValidationMessage className={firstNameMsg ? 'validation-message-visible' : ''}>
                                    {firstNameMsg || '&nbsp;'}
                                </ValidationMessage>
                            </InputColumn>
                            <InputColumn>
                                <FormItem htmlFor="lastName">
                                    <FormItemName>Last name</FormItemName>
                                    <TextInput
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        value={lastName ? lastName : ''}
                                        onChange={this.handleInputChange}
                                        onFinishTyping={this.checkLastName}
                                        status={lastNameMsg ? 'error' : 'normal'}
                                        block={true}
                                    />
                                </FormItem>
                                <ValidationMessage className={lastNameMsg ? 'validation-message-visible' : ''}>
                                    {lastNameMsg || '&nbsp;'}
                                </ValidationMessage>
                            </InputColumn>
                        </InputRow>
                        <InputRow>
                            <InputColumn>
                                <FormItem htmlFor="email">
                                    <FormItemName>Email address</FormItemName>
                                    <TextInput
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={email ? email : ''}
                                        onChange={this.handleInputChange}
                                        onFinishTyping={this.checkValidEmail}
                                        status={this.state.emailMessage ? 'error' : 'normal'}
                                        block={true}
                                        disabled
                                    />
                                </FormItem>
                                <ValidationMessage className={emailMessage ? 'validation-message-visible' : ''}>
                                    {emailMessage || '&nbsp;'}
                                </ValidationMessage>
                                <EmailConfirmationMessage confirmed={emailConfirmed}>
                                    {emailConfirmed ? (
                                        'Confirmed'
                                    ) : (
                                        <>
                                            Not confirmed
                                            <EmailResendMessage className={emailResent ? 'visible done' : ''}>
                                                Sent!
                                            </EmailResendMessage>
                                            <EmailResendMessage
                                                className={emailResent ? '' : 'visible'}
                                                onClick={this.resendEmail}
                                            >
                                                Resend
                                            </EmailResendMessage>
                                        </>
                                    )}
                                </EmailConfirmationMessage>
                            </InputColumn>
                            <InputColumn>
                                <FormItem htmlFor="contactNumber">
                                    <FormItemName>Contact number</FormItemName>
                                    <TextInput
                                        id="contactNumber"
                                        name="contactNumber"
                                        type="text"
                                        value={contactNumber ? contactNumber : ''}
                                        onChange={this.handleInputChange}
                                        onFinishTyping={this.checkValidNumber}
                                        status={numberMessage ? 'error' : 'normal'}
                                        block={true}
                                    />
                                </FormItem>
                                <ValidationMessage className={numberMessage ? 'validation-message-visible' : ''}>
                                    {numberMessage || '&nbsp;'}
                                </ValidationMessage>
                            </InputColumn>
                        </InputRow>
                        <InputRow>
                            <InputColumn>
                                <FormItem htmlFor="role">
                                    <FormItemName>Your role within the company</FormItemName>
                                    <TextInput
                                        id="role"
                                        name="role"
                                        type="text"
                                        value={role ? role : ''}
                                        onChange={this.handleInputChange}
                                        status="normal"
                                        block={true}
                                    />
                                </FormItem>
                                <ValidationMessage>&nbsp;</ValidationMessage>
                            </InputColumn>
                            <InputColumn />
                        </InputRow>
                        {welcome && (
                            <InputRow>
                                <InputColumn>
                                    <FormItem htmlFor="password">
                                        <FormItemName>Set your password</FormItemName>
                                        <TextInput
                                            id="password"
                                            name="password"
                                            type="password"
                                            value={password ? password : ''}
                                            onChange={this.handleInputChange}
                                            onFinishTyping={this.checkValidPass}
                                            status={passValid || passValid == null ? 'normal' : 'error'}
                                            block={true}
                                        />
                                    </FormItem>
                                    <ValidationCheckmark text="At least 12 characters long" valid={passValid} />
                                </InputColumn>
                                <InputColumn>
                                    <FormItem htmlFor="passRepeat">
                                        <FormItemName>Repeat your password</FormItemName>
                                        <TextInput
                                            id="passRepeat"
                                            name="passRepeat"
                                            type="password"
                                            value={passRepeat ? passRepeat : ''}
                                            onChange={this.handleInputChange}
                                            onFinishTyping={this.checkPassMatch}
                                            status={passRepeatMsg ? 'error' : 'normal'}
                                            block={true}
                                        />
                                    </FormItem>
                                    <ValidationMessage className={passRepeatMsg ? 'validation-message-visible' : ''}>
                                        {passRepeatMsg || '&nbsp;'}
                                    </ValidationMessage>
                                </InputColumn>
                            </InputRow>
                        )}
                    </div>
                    <PageActions>
                        <ActionButton onClick={this.onSave} id="save">
                            Save
                        </ActionButton>
                    </PageActions>
                </SectionForm>
            </Section>
        )
    }
}

export default PersonalSection
