const conversionCelsiusToFahrenheit = (celsius: number) => {
    return Math.round((celsius * 9) / 5 + 32)
}

const conversionFahrenheitToCelsius = (fahrenheit: number) => {
    return Math.round(((fahrenheit - 32) * 5) / 9)
}

export const getPartnersUnitTemperature = (degree: number, isUSPartner: boolean, isCelsius = true) => {
    if (isUSPartner) {
        return isCelsius ? `${conversionCelsiusToFahrenheit(degree)}&deg;F` : `${Math.round(degree)}&deg;F`
    }
    return isCelsius ? `${Math.round(degree)}&deg;C` : `${conversionFahrenheitToCelsius(degree)}&deg;C`
}
