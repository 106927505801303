import * as React from 'react'
import Styled from 'styled-typed'
import { Col, Row } from 'uiComponents/flex'
import Infotip from 'uiComponents/infotip'
import { isValidUrl } from 'utils/formFieldChecks'
import { FieldType } from 'uiComponents/studio/form/fieldComponentSchema'
import { InputComponent, TextAreaComponent, ToggleComponent } from 'uiComponents/studio/form/fieldComponents'
import { FormItem } from 'uiComponents/studio/form/formItem'
import {
    ModalWindowConfiguration,
    Languages,
    OnChangeFunc,
    GetConfigValueFunc,
    Translation,
} from 'engageTools/studio/schema'
import { ErrorMessage } from 'uiComponents/studio/form/errorMessage'

export const COPY_KEYS = {
    TITLE: 'modal_window.no_bid_title',
    PARAGRAPH: 'modal_window.no_bid_description',
    BUTTON: 'modal_window.no_bid_cta',
    URL: 'modal_window.cta_link',
    DISMISS: 'modal_window.no_bid_cancel',
}

export const MODAL_COPY_SLUGS = [
    'modal_window.no_bid_title',
    'modal_window.no_bid_description',
    'modal_window.no_bid_cta',
    'modal_window.cta_link',
    'modal_window.no_bid_cancel',
]

export const defaultTranslationValues = {
    TITLE: 'Title',
    PARAGRAPH: 'Description',
    BUTTON: 'Go',
    URL: '',
    DISMISS: 'Cancel',
}

interface CopySectionProps {
    handleChange: OnChangeFunc
    getConfigValue: GetConfigValueFunc
    configuration: ModalWindowConfiguration
    translations: Translation[]
    languages: Languages
    updateConfiguration: (path: string, value: string | number | boolean | string[]) => void
    updateTranslations: (slug: string, language: string, value: string) => void
    resetATranslation: (slug: string) => void
    setActiveLanguage: (v: string) => void
    activeLanguage: string
    translationsValidation: boolean
    setTranslationsValidation: (on: boolean) => void
    activeGlobalLanguage: string
}

interface CopySectionState {
    buttonTextValid: boolean
    titleTextValid: boolean
    buttonUrlValid: boolean
    descriptionTextValid: boolean
    dismissTextValid: boolean
    modalTranslationsUpdated: boolean
}

export class CopySection extends React.Component<CopySectionProps, CopySectionState> {
    constructor(props: CopySectionProps) {
        super(props)
        this.state = {
            buttonTextValid: true,
            titleTextValid: true,
            buttonUrlValid: true,
            descriptionTextValid: true,
            dismissTextValid: true,
            modalTranslationsUpdated: false,
        }
    }

    componentDidMount() {
        if (this.props.translationsValidation) {
            this.checkTranslationValidity()
            this.props.setTranslationsValidation(false)
        }
    }

    componentDidUpdate(prevProps: CopySectionProps) {
        if (prevProps.translations !== this.props.translations) {
            this.setState({ modalTranslationsUpdated: true })
        }
        if (this.props.translationsValidation) {
            this.checkTranslationValidity()
            this.props.setTranslationsValidation(false)
        } else {
            if (prevProps.activeLanguage !== this.props.activeLanguage) {
                this.resetFormValidity()
            }
        }
    }

    checkIfUrlComplete = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        if (!this.state.buttonUrlValid) {
            return
        }
        if (!value.includes('https://') && !value.includes('http://')) {
            const newValue = 'https://' + value
            this.props.updateTranslations('modal_window.cta_link', this.props.activeLanguage, newValue)
        }
    }

    updateValidityInState = (slug: string, valid: boolean): void => {
        switch (slug) {
            case COPY_KEYS.TITLE:
                this.setState({ titleTextValid: valid })
                break
            case COPY_KEYS.BUTTON:
                this.setState({ buttonTextValid: valid })
                break
            case COPY_KEYS.PARAGRAPH:
                this.setState({ descriptionTextValid: valid })
                break
            case COPY_KEYS.URL:
                this.setState({ buttonUrlValid: valid })
                break
            case COPY_KEYS.DISMISS:
                this.setState({ dismissTextValid: valid })
                break
            default:
                break
        }
    }
    checkTranslationValidity = (): void => {
        this.props.translations.forEach((t) => {
            if (t.slug === COPY_KEYS.URL && this.props.configuration.linkToCheckout) {
                this.updateValidityInState(t.slug, true)
            } else if (t.slug === COPY_KEYS.DISMISS && !this.props.configuration.showDismissButton) {
                this.updateValidityInState(t.slug, true)
            } else {
                const validTranslation =
                    !!t.values[this.props.activeLanguage] && t.values[this.props.activeLanguage] !== ''
                this.updateValidityInState(t.slug, validTranslation)
            }
        })
    }

    resetFormValidity = () => {
        this.props.translations.forEach((t) => {
            this.updateValidityInState(t.slug, true)
        })
    }

    getTranslationValue = (item: FieldType): string => {
        const translation = this.props.translations.find((t) => t.slug === item.path)
        const value =
            typeof translation !== 'undefined'
                ? translation.values[this.props.activeLanguage]
                    ? translation.values[this.props.activeLanguage]
                    : ''
                : ''
        return value
    }

    getNewTabValue = () => {
        const newTabConfig = this.props.configuration['openLinkInNewTab']
        return typeof newTabConfig === 'undefined' ? true : !!newTabConfig
    }

    handleTranslationChange = (e: React.ChangeEvent<HTMLSelectElement>, path: string) => {
        const value = e.target.value
        this.props.updateTranslations(path, this.props.activeLanguage, value)
    }
    onDescriptionTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value
        this.props.updateTranslations(COPY_KEYS.PARAGRAPH, this.props.activeLanguage, value)
    }
    handleSendToCheckout = (on: boolean, path: string): void => {
        this.props.handleChange(on, path)
        if (on) {
            this.props.resetATranslation(COPY_KEYS.URL)
        }
        this.handleActiveLanguage()
    }
    handleDisplayDismissText = (on: boolean, path: string) => {
        this.props.handleChange(on, path)
        if (!on) {
            this.props.resetATranslation(COPY_KEYS.DISMISS)
        }
        this.handleActiveLanguage()
    }

    handleDisplayButtonChange = (on: boolean, path: string) => {
        this.props.handleChange(on, path)
        if (!on) {
            this.props.resetATranslation(COPY_KEYS.BUTTON)
        }
        this.handleActiveLanguage()
    }
    checkDescriptionTextValidity = (v: string): void => {
        this.setState({ descriptionTextValid: v !== '' })
    }
    checkButtonTextValidity = (v: string): void => {
        this.setState({ buttonTextValid: v !== '' })
    }
    checkTitleTextValidity = (v: string): void => {
        this.setState({ titleTextValid: v !== '' })
    }
    checkButtonUrlValidity = (v: string): void => {
        this.setState({ buttonUrlValid: v !== '' && isValidUrl(v) })
    }
    checkDismissTextValidity = (v: string): void => {
        if (this.props.configuration && this.props.configuration.showDismissButton) {
            this.setState({ dismissTextValid: v !== '' })
        } else {
            this.setState({ dismissTextValid: true })
        }
    }

    handleActiveLanguage = (e?: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (this.props.activeGlobalLanguage !== this.props.activeLanguage) {
            this.props.setActiveLanguage(this.props.activeLanguage)
        }
    }

    render() {
        const { activeLanguage } = this.props
        const dismissTextEnabled = this.props.configuration.showDismissButton
        const proceedButtonShown = !this.props.configuration.hideProceedButton
        const sendToCheckoutEnabled = this.props.configuration.linkToCheckout
        const activeIsPrimary = activeLanguage === this.props.languages.primary

        type TextAreaType = 'textArea'
        type UrlType = 'url'
        type ToggleType = 'toggle'
        type TextField = 'text'
        const titleTextField = {
            name: 'title-text',
            type: 'text' as TextField,
            label: 'Title text',
            path: COPY_KEYS.TITLE,
            defaultValue: 'Title text',
        }
        const descriptionTextField = {
            name: 'description-text',
            type: 'text' as TextAreaType,
            label: 'Paragraph text',
            path: COPY_KEYS.PARAGRAPH,
            defaultValue: 'Modal window text',
        }
        const buttonTextField = {
            name: 'button-text',
            type: 'textArea' as TextField,
            label: 'Button text',
            path: COPY_KEYS.BUTTON,
            defaultValue: 'Click me',
        }
        const sendToChekoutField = {
            name: 'send-to-checkout',
            type: 'toggle' as ToggleType,
            label: 'Link to checkout',
            path: 'linkToCheckout',
            defaultValue: true,
            infotip: 'After clicking the button, send your users to the checkout.',
        }
        const hrefField = {
            name: 'button-destination',
            type: 'url' as UrlType,
            label: 'Alternative URL',
            path: COPY_KEYS.URL,
            placeholder: 'https://www.example.com/shop',
            defaultValue: '',
            infotip: 'Destination page your users will be sent to after clicking the button.',
        }
        const showDismissTextField = {
            name: 'show-dismiss-button',
            type: 'toggle' as ToggleType,
            label: 'Display dismiss text',
            path: 'showDismissButton',
            defaultValue: true,
            infotip:
                'When enabled, your Pop Up will have an additional text under the button. User can click on it and close the Pop Up.', // tslint:disable-line
        }
        const dismissTextField = {
            name: 'dismiss-text',
            type: 'text' as TextField,
            label: 'Dismiss text',
            path: COPY_KEYS.DISMISS,
            placeholder: 'Dismiss',
            defaultValue: 'Dismiss',
        }

        const Note = Styled.span`
      font-style: italic;
      color: ${(props) => props.theme.colors.textLight};
      flex: 1;
      text-align: right;
    `

        return (
            <>
                {activeLanguage && (
                    <div>
                        <FormItem>
                            <Col span={12}>
                                <label htmlFor={titleTextField.name}>{titleTextField.label}</label>
                            </Col>
                            <Col span={12}>
                                <InputComponent
                                    field={titleTextField}
                                    handleChange={this.handleTranslationChange}
                                    value={this.getTranslationValue(titleTextField)}
                                    checkValidity={(v: string) => {
                                        this.checkTitleTextValidity(v)
                                    }}
                                    error={!this.state.titleTextValid}
                                    maxLength={50}
                                    onFocus={this.handleActiveLanguage}
                                    locale={activeLanguage}
                                />
                                {!this.state.titleTextValid && <ErrorMessage errType="notEmpty" />}
                            </Col>
                        </FormItem>
                        <FormItem>
                            <Col span={12}>
                                <label htmlFor={descriptionTextField.name}>
                                    {descriptionTextField.label}
                                    <Note>*max. 100 characters</Note>
                                </label>
                            </Col>
                            <Col span={12}>
                                <TextAreaComponent
                                    field={descriptionTextField}
                                    handleChange={this.onDescriptionTextChange}
                                    value={this.getTranslationValue(descriptionTextField)}
                                    maxLength={300}
                                    checkValidity={(v: string) => {
                                        this.checkDescriptionTextValidity(v)
                                    }}
                                    error={!this.state.descriptionTextValid}
                                    onFocus={this.handleActiveLanguage}
                                    locale={activeLanguage}
                                />
                                {!this.state.descriptionTextValid && <ErrorMessage errType="notEmpty" />}
                            </Col>
                        </FormItem>
                        {proceedButtonShown && (
                            <FormItem>
                                <Col span={12}>
                                    <label htmlFor={buttonTextField.name}>{buttonTextField.label}</label>
                                </Col>
                                <Col span={12}>
                                    <InputComponent
                                        field={buttonTextField}
                                        handleChange={this.handleTranslationChange}
                                        value={this.getTranslationValue(buttonTextField)}
                                        checkValidity={(v: string) => {
                                            this.checkButtonTextValidity(v)
                                        }}
                                        error={!this.state.buttonTextValid}
                                        maxLength={50}
                                        onFocus={this.handleActiveLanguage}
                                        locale={activeLanguage}
                                    />
                                    {!this.state.buttonTextValid && <ErrorMessage errType="notEmpty" />}
                                </Col>
                            </FormItem>
                        )}
                        <FormItem>
                            {activeIsPrimary && (
                                <>
                                    <Col span={6}>
                                        <label htmlFor={sendToChekoutField.name}>{sendToChekoutField.label}</label>
                                    </Col>
                                    <Col span={6} className="justify-right">
                                        <ToggleComponent
                                            field={sendToChekoutField}
                                            handleChange={this.handleSendToCheckout}
                                            value={
                                                this.props.getConfigValue(
                                                    sendToChekoutField,
                                                    this.props.configuration,
                                                ) as boolean
                                            }
                                        />
                                    </Col>
                                </>
                            )}
                            {!sendToCheckoutEnabled && (
                                <>
                                    <Col span={12}>
                                        <label htmlFor={hrefField.name}>
                                            {hrefField.label}
                                            <Infotip pointer="left" maxWidth="20em">
                                                {hrefField.infotip}
                                            </Infotip>
                                        </label>
                                    </Col>
                                    <Col span={12}>
                                        <InputComponent
                                            field={hrefField}
                                            handleChange={this.handleTranslationChange}
                                            value={this.getTranslationValue(hrefField)}
                                            checkValidity={(v: string) => {
                                                this.checkButtonUrlValidity(v)
                                            }}
                                            error={!this.state.buttonUrlValid}
                                            onFocus={this.handleActiveLanguage}
                                            onBlur={this.checkIfUrlComplete}
                                            locale={activeLanguage}
                                        />
                                        {!this.state.buttonUrlValid && <ErrorMessage errType="url" />}
                                    </Col>
                                </>
                            )}
                        </FormItem>
                        <FormItem>
                            <Col span={12}>
                                {activeIsPrimary && (
                                    <Row>
                                        <Col span={6}>
                                            <label htmlFor={showDismissTextField.name}>
                                                {showDismissTextField.label}
                                            </label>
                                        </Col>
                                        <Col span={6} className="justify-right">
                                            <ToggleComponent
                                                field={showDismissTextField}
                                                handleChange={this.handleDisplayDismissText}
                                                value={
                                                    this.props.getConfigValue(
                                                        showDismissTextField,
                                                        this.props.configuration,
                                                    ) as boolean
                                                }
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {dismissTextEnabled && (
                                    <Row style={{ marginTop: '.5em' }}>
                                        {!activeIsPrimary && (
                                            <Col span={12}>
                                                <label htmlFor={dismissTextField.name}>{dismissTextField.label}</label>
                                            </Col>
                                        )}
                                        <Col span={12}>
                                            <InputComponent
                                                field={dismissTextField}
                                                handleChange={this.handleTranslationChange}
                                                value={this.getTranslationValue(dismissTextField)}
                                                checkValidity={(v: string) => {
                                                    this.checkDismissTextValidity(v)
                                                }}
                                                error={!this.state.dismissTextValid}
                                                maxLength={50}
                                                onFocus={this.handleActiveLanguage}
                                                locale={activeLanguage}
                                            />
                                            {!this.state.dismissTextValid && <ErrorMessage errType="notEmpty" />}
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </FormItem>
                    </div>
                )}
            </>
        )
    }
}

export default CopySection
