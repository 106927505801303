import { createContext } from 'react'
import {
    DivProps,
    PaginationType,
    ReactTableInstance,
    ReactTableNoResultsRow,
    ReactTableRows,
    ReactTableState,
    RowProps,
    TableRowSize,
} from './interface'

interface Props {
    instance: ReactTableInstance
    pagination?: PaginationType
    sticky?: boolean
    loading?: boolean
    elevation?: boolean
    displayFooter?: boolean
    itemsTotalCount?: number
    noResultsRow?: ReactTableNoResultsRow
    tableState?: ReactTableState
    tableProps?: React.HTMLAttributes<HTMLDivElement>
    bodyProps?: React.HTMLAttributes<HTMLDivElement>
    expanded?: boolean | ((props: { row: ReactTableRows }) => React.ReactNode)
    size?: TableRowSize
    rowContainerProps?: ((row: ReactTableRows) => DivProps) | DivProps
    rowProps?: RowProps
    cellProps?: any
    backgroundColor?: string
    bordered?: boolean
    headerProps?: React.HTMLAttributes<HTMLDivElement>
    innerTable?: boolean
}

const ReactTableContext = createContext<Props>({} as any)

export default ReactTableContext
