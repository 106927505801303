import React from 'react'

import { PaginationType, ReactTableInstance } from '../interface'
import CursorPagination from './cursorPagination'
import Pagination from './pagination'
import StandardPagination from './standardPagination'

interface ReactTablePaginationProps {
    pagination?: PaginationType
    itemsTotalCount?: number
    instance: ReactTableInstance
}

const ReactTablePagination: React.FC<ReactTablePaginationProps> = ({ pagination, instance, itemsTotalCount }) => {
    if (!pagination) {
        return null
    }
    const isCursorPagination = typeof pagination === 'object' && 'next' in pagination

    if (isCursorPagination) {
        return <CursorPagination instance={instance} pagination={pagination} />
    }

    if (pagination === 'new') {
        return <Pagination instance={instance} itemsTotalCount={itemsTotalCount} />
    }

    if (pagination) {
        return <StandardPagination instance={instance} itemsTotalCount={itemsTotalCount} />
    }

    return null
}

export default ReactTablePagination
