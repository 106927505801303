import { AuthenticatedHttpService } from 'http/httpService'
import { LoggingService, EventType, ActionEventData } from 'http/loggingService'

type CopyEditorType = 'TEXT' | 'RICH_TEXT'

interface CopyListItem {
    name: string
    key: string
    text: string
    locale: string
    editorType: CopyEditorType
    isDefaultCopyset: boolean
}

export interface CopyWithLanguage extends CopyListItem {
    language: string
    formatedText: JSX.Element
}

export interface Copy {
    text: string
    locale: string
}

export interface CopyDetails {
    name: string
    key: string
    custom: Copy[]
    default: Copy[]
    locales: string[]
    defaultLocale: string
    editorType: CopyEditorType
}

export interface CreateCopyPayload {
    categorySlug: string
    copySlug: string
    translations: Copy[]
}

export const dummyCopyDetails: CopyDetails = {
    name: '',
    key: '',
    custom: [],
    default: [],
    locales: [],
    defaultLocale: '',
    editorType: 'TEXT',
}

export class CopyEditorService {
    constructor(
        private httpService: AuthenticatedHttpService,
        private loggingService: LoggingService,
        private backofficeEndpoint: string,
    ) {}

    async getCopySearchResult(accountSlug: string, search: string, searchBy: string): Promise<CopyListItem[]> {
        const logEventType: EventType = 'copy_editor_search'
        const logEventData: ActionEventData = {
            category: 'copy_editor',
            payload: { search, searchBy },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/cms/accounts/${accountSlug}/copies/?search=${search}&search_by=${searchBy}`,
            {
                method: 'GET',
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async getCopyDetails(accountSlug: string, copysetSlug: string): Promise<CopyDetails> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/cms/accounts/${accountSlug}/copies/${copysetSlug}/`,
            {
                method: 'GET',
            },
        )

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body
    }

    async createCopy(accountSlug: string, payload: CreateCopyPayload): Promise<string> {
        const logEventType: EventType = 'copy_created'
        const logEventData: ActionEventData = {
            category: 'copy_editor',
            payload: { ...payload },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/cms/accounts/${accountSlug}/copies/`,
            {
                method: 'POST',
                body: JSON.stringify({ ...payload }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
        const body = await response.json()
        return body.cmsKey
    }

    async updateCopy(accountSlug: string, copysetSlug: string, customCopies: Copy[]): Promise<CopyDetails> {
        const logEventType: EventType = 'copy_updated'
        const logEventData: ActionEventData = {
            category: 'copy_editor',
            payload: { copysetSlug, customCopies },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/cms/accounts/${accountSlug}/copies/${copysetSlug}/`,
            {
                method: 'PUT',
                body: JSON.stringify({ custom: customCopies }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
        const body = await response.json()
        return body
    }

    async deleteCopyset(accountSlug: string, copysetSlug: string): Promise<void> {
        const logEventType: EventType = 'copy_deleted'
        const logEventData: ActionEventData = {
            category: 'copy_editor',
            payload: { copysetSlug },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/cms/accounts/${accountSlug}/copies/${copysetSlug}/`,
            {
                method: 'DELETE',
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
    }
}
