import { useCallback, useState } from 'react'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { ModalDialog, ModalDialogTitle } from 'uiComponents/popups/modal'
import styled from 'styled-typed'
import { ActionButtonA } from 'uiComponents/buttons'
import { BaseRouteParams } from '../../../../hocs'

const ModalDialogBody = styled.div`
    padding-top: 1.2em;
    font-weight: lighter;
    width: 400px;
`

const ModalDialogContent = styled.div`
    line-height: 1.6em;
`

const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 2em;
`

interface DialogProps {
    onCancel: () => void
}

const RequestMapDialog = (props: DialogProps) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const [requestSent, setRequestSent] = useState<boolean>(false)
    const EMAIL = 'customersuccess@convious.com'
    const BCC = 'ronald@convious.com'
    const SUBJECT = 'Custom map - contact request'
    const BODY = `Please contact me regarding creating a custom map. (${accountSlug})`

    const requestMap = useCallback(() => {
        setRequestSent(true)
    }, [])

    return (
        <ModalDialog onDismiss={props.onCancel} interactive fromTop="20%">
            <ModalDialogBody>
                <ModalDialogTitle>Custom Map request</ModalDialogTitle>
                {requestSent ? (
                    <ModalDialogContent>
                        Your request has been redirected to your email client.
                        <br />
                        If you sent email our customer support will contact you soon.
                        <Buttons>
                            <ActionButtonA secondary onClick={props.onCancel}>
                                Close
                            </ActionButtonA>
                        </Buttons>
                    </ModalDialogContent>
                ) : (
                    <ModalDialogContent>
                        You can request to create custom map for you.
                        <br />
                        To do that please click the button below and our customer support will contact you.
                        <Buttons>
                            <ActionButtonA secondary style={{ marginRight: '1em' }} onClick={props.onCancel}>
                                Cancel
                            </ActionButtonA>
                            <ActionButtonA
                                href={`mailto:${EMAIL}?bcc=${BCC}&subject=${SUBJECT}&body=${BODY}`}
                                onClick={requestMap}
                            >
                                Send request
                            </ActionButtonA>
                        </Buttons>
                    </ModalDialogContent>
                )}
            </ModalDialogBody>
        </ModalDialog>
    )
}

export default RequestMapDialog
