import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, AnyAction } from 'redux'
import { match as RouteMatch } from 'react-router-dom'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { History } from 'history'
import { Checkbox, TextInput } from 'uiComponents/input'
import { SignUpService } from 'signUp/signUpService'
import { ReCaptchaService } from 'auth/reCaptchaService'
import { ReCaptcha, loadReCaptcha } from 'auth/reCaptcha'
import { changeActiveAccount } from 'preferences/actions.async'
import { isValidEmail, isValidUrl } from 'utils/formFieldChecks'
import {
    SignUpFlowBackground,
    SignUpContainer,
    SignUpForm,
    PageHeader,
    NextContainer,
    TermsAgreement,
    ErrorMessageContainer,
} from 'uiComponents/signUpContainer'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { Separator } from 'auth/loginPage'
import { ValidationCheckmark } from 'uiComponents/validationCheckmark'
import { PageTopMessage } from 'uiComponents/messages'
import { ActionButton } from 'uiComponents/buttons'
import { SsoService } from 'auth/ssoService'
import { LinkedInButton } from 'uiComponents/buttons/linkedInButton'
import { GoogleButton } from 'uiComponents/buttons/googleButton'
import { withFeatures } from 'features'
import { delay } from 'utils'

interface CreateAccountParams {
    url: string
}

interface CreateAccountProps {
    history: History
    navigation: Navigation
    match: RouteMatch<CreateAccountParams>
    signUpService: SignUpService
    reCaptchaService: ReCaptchaService
    reCaptchaSiteKey: string
    changeActiveAccount: typeof changeActiveAccount
    ssoService: SsoService
    hasFeature: (feature: string) => boolean
}

interface CreateAccountState {
    url: string
    email: string
    password: string
    termsAgreement: boolean
    generalMessage: string
    generalMessageVisible: boolean
    emailMessage: string
    urlMessage: string
    passValid: boolean | null
    reCaptchaVerified: boolean
    nextStep: boolean
}

class CreateAccountPage extends React.Component<CreateAccountProps, CreateAccountState> {
    app = document.getElementById('app') as HTMLElement

    constructor(props: CreateAccountProps) {
        super(props)
        this.state = {
            reCaptchaVerified: false,
            url: this.props.navigation.query().url
                ? decodeURIComponent(this.props.navigation.query().url)
                : sessionStorage.getItem('signUpUrl') || '',
            email: '',
            password: '',
            termsAgreement: false,
            generalMessage: '',
            generalMessageVisible: false,
            emailMessage: '',
            urlMessage: '',
            passValid: null,
            nextStep: false,
        }
    }

    componentDidMount() {
        loadReCaptcha(this.props.reCaptchaSiteKey)
        this.app.classList.add('show-recaptcha')
        if (this.state.url.length > 0) {
            sessionStorage.setItem('signUpUrl', this.state.url)
        }
        if (this.props.navigation.query().sign_up_error) {
            this.setGeneralErrorMesssage(this.props.navigation.query().sign_up_error)
        }
    }

    componentWillUnmount() {
        this.app.classList.remove('show-recaptcha')
    }

    onSubmit = async (evt: any) => {
        evt.preventDefault()
        this.setState({ emailMessage: '', generalMessageVisible: false })

        if (!(await this.checkFormValid())) {
            return
        }

        const newAccount = {
            email: this.state.email,
            password: this.state.password,
            url: this.state.url,
            companyName: 'Company name',
            termsAccepted: this.state.termsAgreement,
        }

        try {
            const response = await this.props.signUpService.createAccount(newAccount)

            if (response.new_account) {
                this.setState({ nextStep: true })
                await delay(700)
                await this.props.changeActiveAccount(response.new_account.widget_slug)
                this.props.history.push('/sign_up/landing')
            }
        } catch (error) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            this.setGeneralErrorMesssage(error.message)
        }
    }

    setGeneralErrorMesssage = (errorMessage: string) => {
        if (errorMessage === 'email_registered') {
            this.setState({
                generalMessage: 'Email provided is already registered.',
                generalMessageVisible: true,
            })
        } else {
            this.setState({
                generalMessage: 'Oops! Something went wrong. Please try again later.',
                generalMessageVisible: true,
            })
        }
    }

    checkFormValid = async () => {
        if (
            !this.checkValidUrl() ||
            !(await this.checkValidEmail()) ||
            !this.checkValidPass() ||
            !this.checkMandatoryFields()
        ) {
            return false
        }
        return true
    }

    checkMandatoryFields = () => {
        if (!this.state.termsAgreement) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            this.setState({
                generalMessage:
                    'Check the box to confirm you agree with our Privacy Policy before creating your account.',
                generalMessageVisible: true,
            })
            return false
        }
        if (!this.state.reCaptchaVerified) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            this.setState({
                generalMessage: 'Oops! Something went wrong. Please try again later.',
                generalMessageVisible: true,
            })
            return false
        }
        return true
    }

    checkValidUrl = () => {
        if (!isValidUrl(this.state.url)) {
            this.setState({ urlMessage: 'Please enter a valid URL' })
            return false
        } else {
            this.setState({ urlMessage: '' })
        }
        return true
    }

    checkValidEmail = async () => {
        if (!(await isValidEmail(this.state.email))) {
            this.setState({ emailMessage: 'Please enter a valid email address' })
            return false
        } else {
            this.setState({ emailMessage: '' })
        }
        return true
    }

    checkValidPass = () => {
        if (!this.state.password || this.state.password.length < 12) {
            this.setState({ passValid: false })
            return false
        } else {
            this.setState({ passValid: true })
        }
        return true
    }

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        this.setState({ [event.target.name]: value, showMessage: false } as any)
        if (event.target.name === 'url') {
            sessionStorage.setItem('signUpUrl', event.target.value)
        }
    }

    verifyReCaptcha = async (recaptchaToken: string) => {
        const response = await this.props.reCaptchaService.verifyReCaptchaToken(recaptchaToken)
        if (response.action === 'create_account' && response.success) {
            this.setState({ reCaptchaVerified: true })
        }
    }

    signupUsingSso = (provider: string) => {
        this.setState({
            passValid: null,
            emailMessage: '',
        })
        if (!this.checkValidUrl() || !this.checkMandatoryFields()) {
            return
        }
        this.props.ssoService.initiateAuth(provider, {
            companyUrl: this.state.url,
            companyName: 'Company name',
        })
    }

    render() {
        const {
            url,
            email,
            password,
            termsAgreement,
            urlMessage,
            emailMessage,
            generalMessage,
            generalMessageVisible,
            passValid,
            nextStep,
        } = this.state
        const linkedInLoginEnabled = this.props.hasFeature('LinkedInLogin')
        const googleLoginEnabled = this.props.hasFeature('GoogleLogin')
        const hasSsoButtons = linkedInLoginEnabled || googleLoginEnabled

        return (
            <SignUpFlowBackground>
                <SignUpContainer id="create-acount-page" className={nextStep ? 'slide-out' : ''}>
                    <ErrorMessageContainer>
                        <PageTopMessage
                            kind="error"
                            showMessage={generalMessageVisible}
                            onDismiss={() => this.setState({ generalMessageVisible: false })}
                        >
                            {generalMessage}
                        </PageTopMessage>
                    </ErrorMessageContainer>
                    <SignUpForm id="create-account-form" noValidate onSubmit={this.onSubmit}>
                        <PageHeader>Create your account</PageHeader>
                        <FormItem htmlFor="url">
                            <FormItemName>Your website</FormItemName>
                            <TextInput
                                id="url"
                                name="url"
                                type="text"
                                value={url ? url : ''}
                                onFinishTyping={this.checkValidUrl}
                                onChange={this.handleInputChange}
                                status={this.state.urlMessage ? 'error' : 'normal'}
                                block={true}
                            />
                        </FormItem>
                        <ValidationMessage className={urlMessage ? 'validation-message-visible' : ''}>
                            {urlMessage || '&nbsp;'}
                        </ValidationMessage>
                        <FormItem htmlFor="email">
                            <FormItemName>Your work email</FormItemName>
                            <TextInput
                                id="email"
                                name="email"
                                type="email"
                                value={email ? email : ''}
                                onChange={this.handleInputChange}
                                onFinishTyping={this.checkValidEmail}
                                status={this.state.emailMessage ? 'error' : 'normal'}
                                block={true}
                            />
                        </FormItem>
                        <ValidationMessage className={emailMessage ? 'validation-message-visible' : ''}>
                            {emailMessage || '&nbsp;'}
                        </ValidationMessage>
                        <FormItem htmlFor="password">
                            <FormItemName>Password</FormItemName>
                            <TextInput
                                id="password"
                                name="password"
                                type="password"
                                value={password ? password : ''}
                                onChange={this.handleInputChange}
                                onFinishTyping={this.checkValidPass}
                                status={passValid || passValid == null ? 'normal' : 'error'}
                                block={true}
                            />
                        </FormItem>
                        <ValidationCheckmark text="At least 12 characters long" valid={passValid} />
                        <FormItem htmlFor="termsAgreement" style={{ display: 'inline-block', marginTop: '0' }}>
                            <Checkbox
                                id="termsAgreement"
                                name="termsAgreement"
                                checked={termsAgreement}
                                onChange={this.handleInputChange}
                            />
                            <TermsAgreement>
                                I agree with the Convious
                                <a href="https://www.convious.com/privacy-policy/" target="_blank" rel="noreferrer">
                                    {' '}
                                    Privacy Policy
                                </a>{' '}
                                and
                                <a href="https://www.convious.com/terms-of-service/" target="_blank" rel="noreferrer">
                                    {' '}
                                    Terms of Service
                                </a>
                            </TermsAgreement>
                        </FormItem>
                        <NextContainer>
                            <ActionButton id="create-account-submit" size="large" type="submit" kind="action">
                                Create free account
                            </ActionButton>
                            {hasSsoButtons && (
                                <>
                                    <Separator style={{ margin: '3em 0 2em 0' }}>OR</Separator>
                                    <div style={{ width: '18em', margin: 'auto' }}>
                                        {linkedInLoginEnabled && (
                                            <LinkedInButton
                                                onClick={() => this.signupUsingSso('linkedin')}
                                                buttonText="Use LinkedIn account"
                                            />
                                        )}
                                        {googleLoginEnabled && (
                                            <GoogleButton
                                                onClick={() => this.signupUsingSso('google')}
                                                buttonText="Use Google account"
                                                style={{ marginTop: '0.8175em' }}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                        </NextContainer>
                        <ReCaptcha
                            sitekey={this.props.reCaptchaSiteKey}
                            action="create_account"
                            verifyCallback={this.verifyReCaptcha}
                        />
                    </SignUpForm>
                </SignUpContainer>
            </SignUpFlowBackground>
        )
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators(
        {
            changeActiveAccount,
        },
        dispatch,
    )
}

export default withFeatures(withNavigation(connect(null, mapDispatchToProps)(CreateAccountPage)))
