import * as React from 'react'
import styled from 'styled-typed'
import { ActionButton } from 'uiComponents/buttons'
import { History } from 'history'
import { PageHeading, LightText, SmallText } from 'uiComponents/typography'

const HeaderRow = styled.div`
    position: fixed;
    width: calc(100vw - 4.4em);
    left: 4.4em;
    font-family: ${(props) => props.theme.fonts.primary};
    background: ${(props) => props.theme.colors.white};
    flex: 0 0 6em;
    height: 6em;
    display: flex;
    z-index: 13;
`

const Title = styled(PageHeading)`
    font-weight: 400;
`

const Titles = styled.div`
    flex: 1;
    padding: 0 1.875em;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`

const Spacer = styled.div`
    flex: 1;
    padding: 0 1.875em;
`

const Controls = styled.div`
    flex: 1;
    padding: 0 1.875em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${(props) => props.theme.colors.textLight};
`

interface HeaderProps {
    history: History
    accountSlug: string
    onSave: () => void
    hasPermissionToSave: boolean
}

export function Header(props: HeaderProps) {
    return (
        <HeaderRow>
            <Titles>
                <Title noPadding>Edit Your Template</Title>
                <div style={{ marginTop: '.5em' }}>
                    <LightText>
                        <SmallText>Edit your template content and save it for changes</SmallText>
                    </LightText>
                </div>
            </Titles>
            <Spacer />
            <Controls>
                <ActionButton
                    secondary
                    size="large"
                    id="close-button"
                    onClick={() => {
                        props.history.push(
                            `/account/${props.accountSlug}/engage/${
                                location.pathname.includes('gift_card_templates')
                                    ? 'gift_card_templates'
                                    : 'ticket_templates'
                            }/`,
                        )
                    }}
                >
                    Exit
                </ActionButton>
                <ActionButton
                    id="save-button"
                    size="large"
                    style={{ marginLeft: '1em' }}
                    disabled={!props.hasPermissionToSave}
                    onClick={props.onSave}
                >
                    Save
                </ActionButton>
            </Controls>
        </HeaderRow>
    )
}
