export type MenuStepType = 'activate' | 'assign' | 'translations' | 'enable'
export type FieldType = 'text' | 'email' | 'image' | 'phone' | 'date' | 'state' | 'country' | 'bool' | 'choice'
export type FieldCategory = 'buyer' | 'ticket_holder'

export interface Translations {
    [key: string]: {
        label: string
        placeholder: string
    }
}

interface RawCopy {
    key: string
    value: string
}

export interface RawTranslations {
    [key: string]: RawCopy[]
}

export interface ConfigField {
    name: string
    slug: string
    buyer: boolean
    custom: boolean
    active: boolean
    optional: boolean
    type: FieldType
    translations: Translations
    products: string[]
}

export interface ConfigFieldRaw {
    name: string
    type: FieldType
    repeatable: boolean
    required: boolean
    articles: string[] | null
    translations: Translations
}

export interface ConfigResponse {
    fieldsConfig: ConfigFieldRaw[]
    allTranslations: RawTranslations
}

export interface ConfigPayloadField {
    name: string
    type: FieldType
    repeatable: boolean
    required: boolean
    articles: string[] | null
    label: { [key: string]: string }
    placeholder: { [key: string]: string }
}

interface DefaultField {
    name: string
    slug: string
    type: FieldType
}

export const buyerPersonalFields: DefaultField[] = [
    {
        name: 'First name',
        slug: 'buyer_first_name',
        type: 'text',
    },
    {
        name: 'Last name',
        slug: 'buyer_last_name',
        type: 'text',
    },
    {
        name: 'Company name',
        slug: 'buyer_company_name',
        type: 'text',
    },
]

export const ticketHolderPersonalFields: DefaultField[] = [
    {
        name: 'First name',
        slug: 'ticket_holder_first_name',
        type: 'text',
    },
    {
        name: 'Last name',
        slug: 'ticket_holder_last_name',
        type: 'text',
    },
    {
        name: 'Company name',
        slug: 'ticket_holder_company_name',
        type: 'text',
    },
    {
        name: 'Date of birth',
        slug: 'ticket_holder_dob',
        type: 'date',
    },
    {
        name: 'Profile image',
        slug: 'ticket_holder_photo',
        type: 'image',
    },
]

export const buyerContactFields: DefaultField[] = [
    {
        name: 'Street address',
        slug: 'buyer_street_name',
        type: 'text',
    },
    {
        name: 'Post code',
        slug: 'buyer_zip_code',
        type: 'text',
    },
    {
        name: 'City',
        slug: 'buyer_city',
        type: 'text',
    },
    {
        name: 'State',
        slug: 'buyer_state',
        type: 'state',
    },
    {
        name: 'Country',
        slug: 'buyer_country',
        type: 'country',
    },
    {
        name: 'Phone number',
        slug: 'buyer_phone_number',
        type: 'phone',
    },
]

export const ticketHolderContactFields: DefaultField[] = [
    {
        name: 'Street address',
        slug: 'ticket_holder_street_name',
        type: 'text',
    },
    {
        name: 'Post code',
        slug: 'ticket_holder_zip_code',
        type: 'text',
    },
    {
        name: 'City',
        slug: 'ticket_holder_city',
        type: 'text',
    },
    {
        name: 'State',
        slug: 'ticket_holder_state',
        type: 'state',
    },
    {
        name: 'Country',
        slug: 'ticket_holder_country',
        type: 'country',
    },
    {
        name: 'Phone number',
        slug: 'ticket_holder_phone_number',
        type: 'phone',
    },
]

export const otherFields: DefaultField[] = [
    {
        name: 'Comments',
        slug: 'buyer_comments',
        type: 'text',
    },
]

export const allDefaultFields: DefaultField[] = [
    ...buyerPersonalFields,
    ...ticketHolderPersonalFields,
    ...buyerContactFields,
    ...ticketHolderContactFields,
    ...otherFields,
]
