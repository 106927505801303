import * as React from 'react'
import styled from 'styled-typed'
import { State } from 'store'
import { connect } from 'react-redux'
import OrderSearchField from 'uiComponents/search/searchWithButton'
import ExportMenu from 'orders/export'
import { DateRange } from 'dateRanges'
import { LoginService } from 'http/loginService'
import { LoggingService } from 'http/loggingService'
import { OrdersService, CreateExceptionBarcodeError } from 'orders/ordersService'
import { OrderDetailsPage, ExportQuery, UserField, OrderDetails } from 'orders/schema'
import { InfoBox } from 'uiComponents/infotip'
import Permission from 'admin/permissionRequired'
import { delay } from 'utils'
import { MessageKind } from 'uiComponents/messages'
import { OnClickMenu, OnClickMenuItem } from 'uiComponents/menus/'
import printJS from 'print-js'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import OrdersFilter from '../ordersFilter'
import { withFeatures } from 'features'
import { User } from 'auth/state'
import { withPermission } from 'admin/hocs'
import { format, startOfToday } from 'date-fns'
import { RedeemModeDialog } from '../redeemModeDialog'

const PageActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 1em;
`
const RightSideFeatures = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
`
const LeftSideFeatures = styled.div`
    display: flex;
    align-items: baseline;
`
const SearchContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const SearchResults = styled.div`
    font-style: italic;
    font-size: 0.875em;
    color: ${(props) => props.theme.colors.textLight};
    margin-left: 1em;
    margin-top: 0.5em;
    margin-bottom: 0.3em;
    font-weight: lighter;
    height: 1.35em;
`
const MoreActionsContainer = styled.div`
    position: relative;
`
const MenuItemContainer = styled.div`
    &.disabled:hover span {
        opacity: 0.5;
        font-weight: normal;
    }

    &.border-bottom {
        position: relative;
        padding-bottom: 0.8em;
        &:after {
            content: '';
            position: absolute;
            bottom: 0.4em;
            width: 100%;
            border-bottom: 1px solid ${(props) => props.theme.colors.tableRow};
        }
    }
`
const Infotip = styled(InfoBox)`
    position: absolute;
    display: flex;
    align-items: center;
    width: 23.7em;
    font-size: 0.75em;
    top: -5.2em;
    bottom: inherit;
    left: 0;
    padding: 0.4em 1em;
    opacity: 0;
    transition: opacity 0.2s;

    ${MenuItemContainer}:hover &.visible {
        opacity: 1;
    }
    &&&:hover {
        opacity: 0;
    }
`

interface PageActionsSectionProps {
    search?: string
    dateRange: DateRange
    accountSlug: string
    loginService: LoginService
    loggingService: LoggingService
    ordersService: OrdersService
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    hideMessage: (id: string) => void
    onRedeemComplete: () => void
    selectedBarcodes: string[]
    ordersPageData: OrderDetailsPage
    backofficeEndpoint: string
    exportQuery: ExportQuery
    showRedeemDialog: boolean
    loading: boolean
    refreshTableData: () => void
    navigation: Navigation
    match: RouteMatch<{}>
    user: User | null
    hasFeature: (feature: string, accountSlug: string) => boolean
    hasPermission: (permission: string, accountSlug: string) => boolean
}

interface PageActionsSectionState {
    selectedUnredeemedBarcodes: string[]
    showRedeemDialog: boolean
    redeemMode: boolean
}

class PageActionsSection extends React.Component<PageActionsSectionProps, PageActionsSectionState> {
    constructor(props: PageActionsSectionProps) {
        super(props)
        this.state = {
            selectedUnredeemedBarcodes: this.getSelectedNotRedeemedBarcodes(),
            showRedeemDialog: false,
            redeemMode: false,
        }
    }

    componentDidUpdate(prevProps: PageActionsSectionProps) {
        if (prevProps.selectedBarcodes !== this.props.selectedBarcodes) {
            this.setState({
                selectedUnredeemedBarcodes: this.getSelectedNotRedeemedBarcodes(),
            })
        }
    }

    getSelectedNotRedeemedBarcodes = () => {
        return !this.props.ordersPageData
            ? []
            : this.props.ordersPageData.entries
                  .filter(
                      (b) => !b.redeemed && b.status === 'paid' && this.props.selectedBarcodes.indexOf(b.barcode) > -1,
                  )
                  .map((b) => b.barcode)
    }

    hasInvalidVisitDate = (detailsItem: OrderDetails, today: string) => {
        if (!detailsItem.visitDate) {
            return false
        }
        const inSelection = this.state.selectedUnredeemedBarcodes.includes(detailsItem.barcode)
        return inSelection && format(detailsItem.visitDate, 'yyyy-MM-dd') > today
    }

    allBarcodesValid = () => {
        const today = format(startOfToday(), 'yyyy-MM-dd')
        const invalidBarcodes = this.props.ordersPageData.entries.filter((b) => this.hasInvalidVisitDate(b, today))
        if (invalidBarcodes.length > 0) {
            const message =
                'There are dated tickets in your selection with the visit date in the future. If you still wish to redeem them, \
        please change the order visit date to today.'
            this.props.replaceMessages('invalid_visit-date', 'error', message)
        }
        return invalidBarcodes.length === 0
    }

    onRedeem = () => {
        if (this.state.selectedUnredeemedBarcodes.length > 0 && this.allBarcodesValid()) {
            this.setState({ showRedeemDialog: true })
        }
    }

    redeemBarcodes = async () => {
        const s = this.state.selectedUnredeemedBarcodes.length === 1 ? '' : 's'
        const responseData = await this.props.ordersService.redeemBarcodes(
            this.props.accountSlug,
            this.state.selectedUnredeemedBarcodes,
        )

        responseData
            .ifFailure((error: CreateExceptionBarcodeError) => this.handleBarcodeRedeemFailure(error, s))
            .ifSuccess(async () => await this.handleBarcodeRedeemSuccess(s))
    }

    handleBarcodeRedeemFailure = (error: CreateExceptionBarcodeError, s: string) => {
        this.setState({ showRedeemDialog: false })
        const getErrorKeys = () => Object.keys(error).filter((key) => !['type', 'barcodes'].includes(key))
        const hasErrors = () => getErrorKeys().filter((key) => !!error[key])
        const getErrorBarcodes = () =>
            getErrorKeys()
                .map((key) => error[key])
                .filter((value) => !!value)
                .join(', ')

        if (hasErrors()) {
            this.props.replaceMessages(
                error.type,
                'error',
                `Oops! Barcode${s} were not redeemed. There was an issue with the following item${s}:
        ${getErrorBarcodes()}`,
            )
        } else {
            this.props.replaceMessages(
                error.type,
                'error',
                `Oops! There was an issue with redeeming barcode${s}. Please try again later.`,
            )
        }
    }

    handleBarcodeRedeemSuccess = async (s: string) => {
        const barcodesString = this.state.selectedUnredeemedBarcodes.join(', ')
        this.props.onRedeemComplete()
        this.setState({ showRedeemDialog: false })
        this.props.replaceMessages(
            'redeemed_successfully',
            'success',
            `The barcode${s} ${barcodesString} ${s ? 'were' : 'was'} redeemed successfully`,
        )
        await delay(3000)
        this.props.hideMessage('redeemed_successfully')
    }

    getDownloadLink = () => {
        const { selectedBarcodes, ordersPageData, backofficeEndpoint } = this.props
        const orders = ordersPageData.entries
            .filter((o) => selectedBarcodes.indexOf(o.barcode) > -1)
            .map((o) => o.orderUuid)
        const ordersString = orders.join(',')
        const barcodesString = selectedBarcodes.join(',')
        return `${backofficeEndpoint}api/v1/cart/tickets/download?orders=${ordersString}&barcodes=${barcodesString}`
    }

    onPrint = async () => {
        this.props.hideMessage('print_error')
        const userFieldsMissing = this.checkSelectionFieldsMissing()
        if (userFieldsMissing || this.props.selectedBarcodes.length < 1 || this.props.selectedBarcodes.length > 10) {
            return
        }
        const endpoint = this.getDownloadLink()
        printJS({
            printable: `${endpoint}&printing=1`,
            onLoadingStart: this.setInfoMessage,
            onLoadingEnd: this.handlePrintSuccess,
            onError: this.handlePrintError,
        })
    }

    onDownload = (e: React.MouseEvent) => {
        if (this.checkSelectionFieldsMissing()) {
            e.preventDefault()
        }
    }

    checkSelectionFieldsMissing = () => {
        const { selectedBarcodes, ordersPageData } = this.props
        const barcodesMissingFields = ordersPageData.entries
            .filter((o) => selectedBarcodes.indexOf(o.barcode) > -1 && this.checkTicketFieldsMissing(o.userFields))
            .map((o) => o.barcode)
        if (barcodesMissingFields.length > 0) {
            const s = barcodesMissingFields.length === 1 ? '' : 's'
            const barcodesString = barcodesMissingFields.join(', ')
            this.props.replaceMessages(
                'userfields_missing',
                'error',
                `Please make sure barcode${s} ${barcodesString} ${s ? 'have' : 'has'} all \
        required after payment form fields completed before printing.`,
            )
        }
        return barcodesMissingFields.length > 0
    }

    checkTicketFieldsMissing = (userFields: UserField[]) => {
        const incompleteUserFields = userFields.filter((f) => f.isRequired && !f.value)
        return incompleteUserFields.length > 0
    }

    setInfoMessage = async () => {
        this.props.replaceMessages(
            'print_start',
            'success',
            'We are preparing the tickets. Print dialog will open automatically in a moment.',
        )
    }

    handlePrintSuccess = () => {
        this.props.hideMessage('print_start')
        this.props.refreshTableData()
    }

    handlePrintError = () => {
        this.props.replaceMessages(
            'print_error',
            'error',
            'Oops! Something went wrong. Please check that all selected tickets have the same \
      template type (e.g. season pass vs regular ticket). Alternatively try printing tickets individually.',
        )
    }

    onRedeemModeClose = () => {
        this.setState({ redeemMode: false })
        this.props.refreshTableData()
    }

    render() {
        const {
            dateRange,
            accountSlug,
            loginService,
            replaceMessages,
            loggingService,
            ordersPageData,
            selectedBarcodes,
            exportQuery,
            loading,
            hideMessage,
            ordersService,
        } = this.props

        const showRedeemInfotip = this.state.selectedUnredeemedBarcodes.length < 1
        const showPrintInfotip = selectedBarcodes.length < 1 || selectedBarcodes.length > 10
        const printInfotipText =
            selectedBarcodes.length < 1
                ? 'Please select at least one ticket to print'
                : 'You can only select up to 10 tickets to print'
        const downloadInfotipText =
            selectedBarcodes.length < 1
                ? 'Please select at least one ticket to download'
                : 'You can only select up to 10 tickets to download'
        const redeemDialogS = this.state.selectedUnredeemedBarcodes.length === 1 ? '' : 's'
        const query = this.props.navigation.query()
        const searchPlaceholder =
            query.searchType === 'extended'
                ? 'Search by ID, email, product name, barcode, discount code or buyer info'
                : 'Search by order ID or email'
        const downloadLink = this.getDownloadLink()
        const userIsReseller = !!this.props.user?.resellerId

        return (
            <>
                <PageActions>
                    <LeftSideFeatures>
                        <SearchContainer>
                            <OrderSearchField
                                placeholder={searchPlaceholder}
                                style={{ width: '33.5em' }}
                                disabled={loading}
                            />
                            <SearchResults>{!loading ? `${ordersPageData.totalCount} results` : ''}</SearchResults>
                        </SearchContainer>
                    </LeftSideFeatures>
                    <RightSideFeatures>
                        <OrdersFilter
                            page="tickets"
                            accountSlug={accountSlug}
                            ordersService={this.props.ordersService}
                            replaceMessages={this.props.replaceMessages}
                            hideMessage={this.props.hideMessage}
                        />
                        <MoreActionsContainer style={{ marginLeft: '1em' }}>
                            {this.state.showRedeemDialog && (
                                <ConfirmationDialog
                                    title={`Redeem ${this.state.selectedUnredeemedBarcodes.length} barcode${redeemDialogS}?`}
                                    text="Redeeming can’t be undone!"
                                    buttonText="Redeem"
                                    onCancel={() => this.setState({ showRedeemDialog: false })}
                                    onConfirm={this.redeemBarcodes}
                                />
                            )}
                            {this.state.redeemMode && <RedeemModeDialog onCancel={this.onRedeemModeClose} />}
                            <OnClickMenu title="More actions" kind="action" secondary fitContent style={{ zIndex: 1 }}>
                                {!userIsReseller && (
                                    <>
                                        <MenuItemContainer className="border-bottom">
                                            <OnClickMenuItem onClick={() => this.setState({ redeemMode: true })}>
                                                <span>Redeem mode</span>
                                            </OnClickMenuItem>
                                        </MenuItemContainer>
                                        <MenuItemContainer className={showRedeemInfotip ? 'disabled' : ''}>
                                            <Infotip className={showRedeemInfotip ? 'visible' : ''}>
                                                Please select at least one not redeemed barcode
                                            </Infotip>
                                            <OnClickMenuItem onClick={this.onRedeem}>
                                                <span>Redeem ticket(s)</span>
                                            </OnClickMenuItem>
                                        </MenuItemContainer>
                                    </>
                                )}
                                <MenuItemContainer className={showPrintInfotip ? 'disabled' : ''}>
                                    <Infotip className={showPrintInfotip ? 'visible' : ''}>{printInfotipText}</Infotip>
                                    <OnClickMenuItem onClick={this.onPrint}>
                                        <span>Print ticket(s)</span>
                                    </OnClickMenuItem>
                                </MenuItemContainer>
                                <MenuItemContainer className={showPrintInfotip ? 'disabled' : ''}>
                                    <Infotip className={showPrintInfotip ? 'visible' : ''}>
                                        {downloadInfotipText}
                                    </Infotip>
                                    <OnClickMenuItem>
                                        <a
                                            href={downloadLink}
                                            target="_blank"
                                            onClick={(e) =>
                                                showPrintInfotip ? e.preventDefault() : this.onDownload(e)
                                            }
                                            rel="noreferrer"
                                        >
                                            Download ticket(s)
                                        </a>
                                    </OnClickMenuItem>
                                </MenuItemContainer>
                            </OnClickMenu>
                        </MoreActionsContainer>
                        <Permission name="export_orders" accountSlug={accountSlug}>
                            <ExportMenu
                                ordersService={ordersService}
                                ordersNumber={ordersPageData.totalCount}
                                ordersQuery={exportQuery}
                                loginService={loginService}
                                loggingService={loggingService}
                                activeSlug={accountSlug}
                                replaceMessages={replaceMessages}
                                hideMessage={hideMessage}
                                dateRange={dateRange}
                                page="details"
                                backofficeEndpoint={this.props.backofficeEndpoint}
                            />
                        </Permission>
                    </RightSideFeatures>
                </PageActions>
            </>
        )
    }
}

function mapStateToProps(state: State) {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
        user: state.auth.user,
    }
}

export default withPermission(withFeatures(withNavigation(connect(mapStateToProps)(PageActionsSection))))
