import React, { useState } from 'react'
import styled from 'styled-typed'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Location, History } from 'history'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { PageTitle, PageHeading } from 'uiComponents/typography'
import { Sorting, Pagination } from 'uiComponents/table'
import { CompareRangeQuery } from 'uiComponents/popups/comparisonDateRangePicker/schema'
import { Messages } from 'uiComponents/messages'
import { withMessages, MessageProps } from 'hocs'
import { PaypalService } from 'orders/paypalService'
import DisputesPage from './disputesPage'
import { Container, ContainerHeading, FullWidthContainerBody, HeadingSectionName } from 'uiComponents/settingsContainer'
import { NavigationBack } from 'uiComponents/navigation/navigationBack'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { Checkbox } from 'uiComponents/input'
import { withCurrentUser } from 'hocs'
import { User } from 'auth/state'
import { OrdersService } from 'orders/ordersService'
import PresetDateRangeElement, { PresetType } from 'uiComponents/popups/presetsElement'
import { dateRangeWithName, DateRange, DateRangeName, last30days } from 'dateRanges'

const AllPartnersToggle = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 1.5em;
    top: -2em;
    span {
        margin-left: 0.2em;
        font-size: 0.75em;
    }
`
const ToggleWrapper = styled.div`
    position: relative;
    background: red;
`
const PresetWrapper = styled.div`
    position: absolute;
    right: -0.8em;
    top: 3.5em;
`

interface PaypalDisputesPageProps {
    user: User
    history: History
    location: Location
    dateRange: DateRange
    onDateRangeChanged: (dr: DateRange, compareRange: CompareRangeQuery) => void
    sort: Sorting
    onSortChanged: (s: Sorting) => void
    pagination: Pagination
    onPaginationChanged: (p: Pagination) => void
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    paypalService: PaypalService
    ordersService: OrdersService
}

function PaypalDisputesPage(props: PaypalDisputesPageProps & MessageProps) {
    const query = props.navigation.query()
    const [activeSection, setActiveSection] = useState<string>('')
    const [allPartners, setAllPartners] = useState<boolean>(!!query.showAllPartners)

    const onAllPartnersChange = () => {
        props.navigation.addQueryWithReplace({
            showAllPartners: allPartners ? null : 'true',
        })
        setAllPartners(!allPartners)
    }

    const navigateToOrders = () => {
        props.history.push(`/account/${props.accountSlug}/orders/transactions/${location.search}`)
    }

    const presetName = 'activeDisputesDateRange'
    const defaultPreset: PresetType = 'last30days'
    const presetOptions: PresetType[] = ['last30days', 'last90days', 'lifetime']

    const range = query.activeDisputesDateRange || 'last30days'
    const activeDateRange = dateRangeWithName(range as DateRangeName) || last30days()

    return (
        <div style={{ position: 'relative' }}>
            <Messages messages={props.messages} hideMessage={props.hideMessage} />
            {activeSection === 'resolved' && (
                <DateRangePicker range={props.dateRange} onChange={props.onDateRangeChanged} />
            )}
            {activeSection === 'active' && (
                <PresetWrapper>
                    <PresetDateRangeElement
                        presetName={presetName}
                        default={defaultPreset}
                        options={presetOptions}
                        style={{ marginRight: '1em' }}
                    />
                </PresetWrapper>
            )}
            <PageTitle>PayPal Disputes</PageTitle>
            <PageHeading style={{ marginBottom: '3em' }}>Manage and control your refunds</PageHeading>
            <NavigationBack onClick={navigateToOrders} text="Back to orders" topOffset="-2.3em" />
            {props.user.isAdmin && (
                <ToggleWrapper>
                    <AllPartnersToggle>
                        <Checkbox checked={!!query.showAllPartners} onChange={onAllPartnersChange} />
                        <span>Show all partners&quot; disputes</span>
                    </AllPartnersToggle>
                </ToggleWrapper>
            )}
            <Container>
                <ContainerHeading style={{ paddingLeft: '1.5em' }}>
                    <HeadingSectionName
                        className={activeSection === 'active' ? 'active' : ''}
                        to={`/account/${props.accountSlug}/orders/paypal/disputes/active/${location.search}`}
                    >
                        Active disputes
                    </HeadingSectionName>
                    <HeadingSectionName
                        className={activeSection === 'resolved' ? 'active' : ''}
                        to={`/account/${props.accountSlug}/orders/paypal/disputes/resolved/${location.search}`}
                    >
                        Resolved disputes
                    </HeadingSectionName>
                </ContainerHeading>
                <Switch location={props.location}>
                    <Route
                        path="/account/:accountSlug/orders/paypal/disputes/active"
                        render={() => (
                            <FullWidthContainerBody>
                                <DisputesPage
                                    section="active"
                                    onSectionChange={(s) => setActiveSection(s)}
                                    history={props.history}
                                    accountSlug={props.accountSlug}
                                    sort={props.sort}
                                    onSortChanged={props.onSortChanged}
                                    pagination={props.pagination}
                                    onPaginationChanged={props.onPaginationChanged}
                                    dateRange={activeDateRange}
                                    paypalService={props.paypalService}
                                    ordersService={props.ordersService}
                                    showAllPartners={!!query.showAllPartners && props.user.isAdmin}
                                    replaceMessages={props.replaceMessages}
                                    removeAllMessages={props.removeAllMessages}
                                />
                            </FullWidthContainerBody>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/orders/paypal/disputes/resolved"
                        render={() => (
                            <FullWidthContainerBody>
                                <DisputesPage
                                    section="resolved"
                                    onSectionChange={(s) => setActiveSection(s)}
                                    history={props.history}
                                    accountSlug={props.accountSlug}
                                    sort={props.sort}
                                    onSortChanged={props.onSortChanged}
                                    pagination={props.pagination}
                                    onPaginationChanged={props.onPaginationChanged}
                                    dateRange={props.dateRange}
                                    paypalService={props.paypalService}
                                    ordersService={props.ordersService}
                                    showAllPartners={!!query.showAllPartners && props.user.isAdmin}
                                    replaceMessages={props.replaceMessages}
                                    removeAllMessages={props.removeAllMessages}
                                />
                            </FullWidthContainerBody>
                        )}
                    />
                    <Route
                        render={() => <Redirect to={`/account/${props.accountSlug}/orders/paypal/disputes/active`} />}
                    />
                </Switch>
            </Container>
        </div>
    )
}

export default withNavigation(withCurrentUser(withMessages(PaypalDisputesPage)))
