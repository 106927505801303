import * as React from 'react'
import styled from 'styled-typed'
import Svg from 'svg'
import noDataIcon from './noData.svg'
import { SecondaryText, Headline } from 'uiComponents/typography'

export const tooltipStyle = {
    backgroundColor: 'rgba(0,0,0,0.6)',
    borderWidth: 0,
    textStyle: {
        color: '#FFFFFF',
    },
}

export const ChartHeadline = styled(Headline)`
    display: flex;
    align-items: center;
    margin-bottom: 0;
`

export const ChartContainer = styled.div<{ withBanner?: boolean }>`
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);
    border-radius: 0.5em;
    padding: 2em 2.5em 2.5em 2.5em;
    ${(props) => (props.withBanner ? 'padding-top: 2.7em;' : '')}
    position: relative;
    cursor: ${(props) => (!!props.onClick ? 'pointer' : 'auto')};

    &.clickable-title:hover {
        ${ChartHeadline} span:not(.link) {
            text-decoration: underline;
            text-decoration-color: ${(props) => props.theme.colors.textLight};
            cursor: pointer;
        }
    }
`

export const ChartContainerInfoBanner = styled.div`
    background: ${(props) => props.theme.colors.tableRow};
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 0.5rem 0.5rem 0 0;
    font-size: 0.875em;
    padding: 0.4em 1.875em 0.4em 2.8em;

    span {
        color: ${(props) => props.theme.colors.textLight};
        font-weight: lighter;
    }
`

export const DateRangeNotice = styled(SecondaryText)`
    letter-spacing: 0.2em;
    font-size: 0.61em;
`

export const ChartRow = styled.div`
    display: flex;
    justify-content: space-between;
`

export const NoDataNoticeWrapper = styled.div<{ topOffset?: string }>`
    position: absolute;
    top: ${(props) => (props.topOffset ? props.topOffset : '2em')};
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.tableRow};
`

export const NoDataNoticeElement = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10em;
    height: 10em;
    margin-top: 5.5em;
`

export const AdditionalDataNotice = styled.div`
    font-size: 0.875em;
    padding: 0.28em 0.78em;
    border-radius: 1.85em;
    background: rgba(74, 165, 219, 0.1);

    span {
        font-weight: 500;
        color: ${(props) => props.theme.colors.boyBlue};
    }
`

export const CustomDataItem = styled(AdditionalDataNotice)`
    position: absolute;
    right: 1.5em;
    top: 2.7em;
`

interface NoDataNoticeProps {
    children: React.ReactNode
    dataLength: number
    loading?: boolean
    style?: React.CSSProperties
    topOffset?: string
}

export function NoDataNotice(props: NoDataNoticeProps) {
    return (
        <div style={{ position: 'relative' }}>
            {props.children}
            {!props.loading && props.dataLength === 0 && (
                <NoDataNoticeWrapper topOffset={props.topOffset}>
                    <NoDataNoticeElement style={props.style}>
                        <Svg src={noDataIcon} style={{ width: '6em' }} />
                        No data available
                    </NoDataNoticeElement>
                </NoDataNoticeWrapper>
            )}
        </div>
    )
}
