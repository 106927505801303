import { AppServices } from 'middleware'

export const downloadFile = (
    url: string,
    fileName: string,
    options?: RequestInit,
    onReject?: (response: Response) => void,
) => {
    return AppServices.httpService
        .fetch(url, options)
        .then((response) => {
            if (response.ok) {
                return response.blob()
            }

            if (onReject) {
                throw onReject(response)
            } else {
                throw new Error('File download failed')
            }
        })
        .then((blob) => {
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = fileName
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            document.body.removeChild(a)
        })
}
