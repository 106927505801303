import React from 'react'
import styled from 'styled-components'
import ParkMapPoiRoutes from 'routes/appRoutes/parkMapPoiRoutes'

export const TabContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    padding-top: 2em;
`

const TabPois = () => {
    return (
        <TabContainer>
            <ParkMapPoiRoutes />
        </TabContainer>
    )
}

export default TabPois
