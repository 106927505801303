import React from 'react'
import { ArticleFromApi, CategoryFromApi } from '../types'
import { DateFormatter } from 'uiComponents/date'
import { DateFormats } from 'utils'

interface Props {
    product: CategoryFromApi | ArticleFromApi
}
const Validity: React.FC<Props> = ({ product }) => {
    if (Object.keys(product).includes('categories')) {
        product = product as ArticleFromApi
        return (
            <span style={{ width: '10em', textOverflow: 'clip' }}>
                <DateFormatter value={product?.validFrom} format={DateFormats.EXTRA_SHORT_DATE} />
                &nbsp;-&nbsp;
                <DateFormatter value={product?.validTo} format={DateFormats.EXTRA_SHORT_DATE} />
            </span>
        )
    }

    return null
}

export default Validity
