import React, { useState } from 'react'
import { BaseRouteParams, withNavigation, WithNavigationProps } from 'hocs'
import { withPermission, PermissionProps } from 'admin/hocs'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { useUpdateVisitDateAndTimeMutation } from 'orders/reduxQueries'
import { useDispatch } from 'react-redux'
import { addMessage } from 'uiComponents/messages/actions'
import { IOrder } from 'orders/schema'
import { DateFormats, formatOrInvalid, parseDate } from 'utils/dates'

interface InlineVisitDateProps extends PermissionProps, WithNavigationProps<BaseRouteParams> {
    order: IOrder
    onUpdated?: (order: IOrder) => void
}

function InlineVisitDate({ order, match, hasPermission, onUpdated }: InlineVisitDateProps) {
    const [open, setOpen] = useState(false)
    const [date, setDate] = useState<Date>()
    const { accountSlug } = match.params
    const [updateDateTime] = useUpdateVisitDateAndTimeMutation()
    const dispatch = useDispatch()

    const canEditOrders =
        hasPermission('edit_orders', accountSlug) && order.status !== 'refunded' && order.status !== 'refunding'

    const close = () => {
        setDate(undefined)
        setOpen(false)
    }

    const saveDate = async () => {
        try {
            await updateDateTime({
                orderUuid: order.id,
                dateTime: formatOrInvalid(date as Date, 'yyyy-MM-dd'),
                visitTime: null,
            }).unwrap()
            if (onUpdated) {
                onUpdated({ ...order, visitDate: formatOrInvalid(date as Date, 'yyyy-MM-dd') })
            }
        } catch (e) {
            if (e.message === 'no_availability') {
                dispatch(
                    addMessage('no_availability_error', 'error', 'No availability left for selected date or time.'),
                )
            } else if (e.message === 'order_already_redeemed') {
                dispatch(
                    addMessage(
                        'already_redeemed_error',
                        'error',
                        'Cannot change date because order is already redeemed.',
                    ),
                )
            } else if (e.message === 'date_change_not_possible') {
                dispatch(addMessage('date_change_not_possible', 'error', 'Cannot change date for this order.'))
            } else {
                dispatch(
                    addMessage(
                        'visit_date_error',
                        'error',
                        'Oops! There was a problem with saving new visit details. Please try again.',
                    ),
                )
            }
        }
        close()
    }

    if (!order.visitDate) {
        return <>Undated</>
    }

    return (
        <>
            {!!open && (
                <ConfirmationDialog
                    title="Update visit date?"
                    text={`Have you checked the availability on ${formatOrInvalid(
                        date as Date,
                        DateFormats.EXTRA_LONG_DATE,
                    )}?`}
                    buttonText="Update"
                    onCancel={close}
                    onConfirm={saveDate}
                />
            )}
            <DatePicker
                date={order.visitDate ? parseDate(order.visitDate) : null}
                inlineEdit
                modalDatePicker
                onInlineEditAccept={(newDate) => {
                    setDate(newDate)
                    setOpen(true)
                }}
                disabled={!canEditOrders}
                inlineEditDateFormat={DateFormats.LONG_DATE}
            />
        </>
    )
}

export default withPermission(withNavigation(InlineVisitDate))
