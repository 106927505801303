import cloneDeep from 'lodash/cloneDeep'
import { ICustomAudience, IFilter } from './types'
import { find } from 'lodash'

export const parseJsonValues = (data: ICustomAudience): ICustomAudience => {
    const newAudience = cloneDeep(data)

    newAudience.filters.forEach((filter) => {
        filter.rules.forEach((rule) => {
            if (rule.value && typeof rule.value === 'string') {
                rule.value = JSON.parse(rule.value)

                // fallback if value is still json
                if (typeof rule.value === 'string') {
                    try {
                        const parsed = JSON.parse(rule.value)
                        rule.value = parsed
                    } catch (e) {}
                }
            }
        })
    })

    return newAudience
}

export const removeNullValues = (data: ICustomAudience): ICustomAudience => {
    const newAudience = cloneDeep(data)

    newAudience.filters.forEach((filter) => {
        filter.rules = filter.rules.filter((rule) => {
            return rule.value !== null
        })
    })

    return newAudience
}

export const mapProductsRuleValues = (data: ICustomAudience, filters: IFilter[]): ICustomAudience => {
    const productFilter = find(filters, (filter) => filter.filter_id === 3)
    const productOptions = find(productFilter!.rules, (rule) => rule.rule_id === 4)
    if (!productOptions) {
        return data
    }
    return data
}
