import React, { useMemo, useState } from 'react'
import { delay } from 'utils'
import { addMessage, removeMessage } from 'uiComponents/messages/actions'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { useResendEmailMutation } from 'orders/reduxQueries'
import { getListOrderData, getSelectedOrdersList } from 'orders/orders/redux'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Button } from '@mui/material'
import { MailSolid } from '@convious/icons'

const Email = () => {
    const selectedOrderRowsIds = useAppSelector(getSelectedOrdersList)
    const currentData = useAppSelector(getListOrderData)?.data
    const selectedOrder = useAppSelector((state) => state.orderTable.selectedOrder)
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
    const [resendEmail, { isLoading }] = useResendEmailMutation()
    const dispatch = useAppDispatch()
    const selectedRows = useMemo(() => {
        const ordersData = [...(currentData?.results ?? [])]
        if (selectedOrder && ordersData.length === 0) {
            ordersData.push(selectedOrder)
        }

        return ordersData.filter((order) => selectedOrderRowsIds.includes(order.id))
    }, [currentData, selectedOrderRowsIds, selectedOrder])

    if (selectedRows.length !== 1) {
        return null
    }

    const canGetTickets = selectedRows[0].status !== 'cancelled'
    if (!canGetTickets || !selectedRows[0].customer?.email) {
        return null
    }

    const onResendEmail = async () => {
        try {
            await resendEmail({ orderNumber: selectedRows[0].number })
            setShowConfirmationDialog(false)
            dispatch(
                addMessage(
                    'resent_successfully',
                    'success',
                    `Email to ${selectedRows[0].customer.email} sent successfully`,
                ),
            )
            await delay(5000)
            dispatch(removeMessage('resent_successfully'))
        } catch {
            setShowConfirmationDialog(false)
            dispatch(addMessage('unknown_error', 'error', 'Oops! We could not send the email. Please try again.'))
        }
    }

    return (
        <>
            {showConfirmationDialog && (
                <ConfirmationDialog
                    title="Resend order confirmation?"
                    text={`To: ${selectedRows[0].customer.email}`}
                    buttonText="Resend"
                    onCancel={() => setShowConfirmationDialog(false)}
                    onConfirm={onResendEmail}
                    loading={isLoading}
                />
            )}
            <Button
                size="medium"
                variant="outlined"
                color="primary"
                onClick={() => setShowConfirmationDialog(true)}
                id="resend-email-button"
                data-testid="orders-resend-email-button"
                startIcon={<MailSolid />}
                className="action-button"
            >
                Resend email
            </Button>
        </>
    )
}

export default Email
