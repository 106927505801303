import * as React from 'react'
import { usePrevious, useIsMounted } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { format } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { getComparisonColorFromPercentString, comparisonCopyMap } from 'reports/helpers'
import { DataNumber } from 'uiComponents/charts/dataNumber'
import { ChartRow, ChartContainer } from 'uiComponents/charts/styleComponents'
import { QueryConfig } from 'reports/queryGenerator'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { DateRange, DateRangeName } from 'dateRanges'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { abbreviateNumber } from 'utils'
import { differenceInCalendarDays, startOfToday, addDays } from 'date-fns'
import { toPercent, UseComparisonMetric } from './utils'
import { ComparisonMetricHook } from './types'

const queryConfig: QueryConfig = {
    querySetName: 'SingleDataItems',
    variablesConfig: [
        { name: 'accountSlug', type: 'String' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'comparisonType', type: 'ComparisonTypes' },
        { name: 'delayType', type: 'DelayTypes' },
        { name: 'delay', type: 'Int' },
    ],
    queries: [
        {
            name: 'websiteSessions',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'dateFrom', 'dateTo', 'comparisonType', 'delayType', 'delay'],
            customVariables: [{ name: 'metric', customValue: 'website_sessions' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'checkoutSessions',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'dateFrom', 'dateTo', 'comparisonType', 'delayType', 'delay'],
            customVariables: [{ name: 'metric', customValue: 'checkout_funnel_checkout_reach' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'checkoutVisitRate',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'dateFrom', 'dateTo', 'comparisonType', 'delayType', 'delay'],
            customVariables: [{ name: 'metric', customValue: 'checkout_funnel_tickets_reach_ratio' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'websiteConversionRate',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'dateFrom', 'dateTo', 'comparisonType', 'delayType', 'delay'],
            customVariables: [{ name: 'metric', customValue: 'checkout_funnel_cart_reach_ratio' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'checkoutConversionRate',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'dateFrom', 'dateTo', 'comparisonType', 'delayType', 'delay'],
            customVariables: [
                {
                    name: 'metric',
                    customValue: 'checkout_funnel_conversion_reach_ratio',
                },
            ],
            presetResult: 'totalsComparison',
        },
    ],
}

const infotipContentMap = {
    websiteSessions: {
        pageName: 'Website sessions',
        itemName: 'number of website sessions',
        more: 'higher',
        less: 'lower',
        metricText:
            'One session includes the collection of interactions a single user takes within a given time frame on your website. \
      Convious defaults that time frame to 30 minutes. So, all actions a user takes on your website within 30 minutes equals \
      one session. Please note that this also includes bounced sessions and visits to any page on your domain, including pages \
      where your Checkout may be hidden. ',
    },
    checkoutSessions: {
        pageName: 'Checkout sessions',
        itemName: 'number of checkout sessions',
        more: 'higher',
        less: 'lower',
        metricText:
            'The number of sessions in which users, among other actions, entered the Checkout. Convious defaults a session to 30 minutes.',
    },
    checkoutVisitRate: {
        pageName: 'Ticket selection reach rate',
        itemName: 'number of ticket selection reach rates',
        more: 'higher',
        less: 'lower',
        metricText: 'The rate of Checkout visitors who reached any ticket selection page within your Checkout.',
    },
    websiteConversionRate: {
        pageName: 'Cart reach rate',
        itemName: 'number of cart reach rates',
        more: 'higher',
        less: 'lower',
        metricText: 'The rate of Checkout visitors who reached the cart page within your Checkout.',
    },
    checkoutConversionRate: {
        pageName: 'Overall Checkout Conversion rate',
        itemName: 'number of checkout conv. rates',
        more: 'higher',
        less: 'lower',
        metricText:
            'The overall conversion rate includes all new purchase conversions and reservation conversions generated \
      from visitors entering Checkout to finalizing the purchase/reservation.',
    },
}

interface SingleDataItemsProps {
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    dateRange: DateRange
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

function SingleDataItems(props: SingleDataItemsProps) {
    const isMounted = useIsMounted()
    const _lastRequest = React.useRef<number>()
    const statsService = React.useContext(StatsServiceContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [timePeriod, setTimePeriod] = React.useState<DateRangeName>('yesterday')
    const [timePeriodLength, setTimePeriodLength] = React.useState<number>(0)
    const websiteSessions = UseComparisonMetric({
        initialValue: '0',
        initialComparisonValue: '-',
    })
    const checkoutSessions = UseComparisonMetric({
        initialValue: '0',
        initialComparisonValue: '-',
    })
    const checkoutVisitRate = UseComparisonMetric({
        initialValue: '0',
        initialComparisonValue: '-',
    })
    const checkoutConversionRate = UseComparisonMetric({
        initialValue: '0',
        initialComparisonValue: '-',
    })
    const websiteConversionRate = UseComparisonMetric({
        initialValue: '0',
        initialComparisonValue: '-',
    })

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)

    React.useEffect(() => {
        if (prevAccountSlug !== props.accountSlug || !areDateRangeDatesEqual(prevDateRange, props.dateRange)) {
            getData()
        }
    }, [props.accountSlug, props.dateRange])

    const getData = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        setLoading(true)
        const timePeriods = ['today', 'yesterday', 'thisWeek', 'thisMonth']
        const timePeriodForMap = timePeriods.indexOf(props.dateRange.name) > -1 ? props.dateRange.name : 'custom'
        setTimePeriod(timePeriodForMap)
        const dateRangeLength = differenceInCalendarDays(props.dateRange.to, props.dateRange.from) + 1
        setTimePeriodLength(dateRangeLength)
        try {
            const dateFrom = format(props.dateRange.from || new Date(0), 'yyyy-MM-dd')
            const dateTo = format(props.dateRange.to || addDays(startOfToday(), 1), 'yyyy-MM-dd')

            const variables = {
                accountSlug: props.accountSlug,
                dateFrom,
                dateTo,
                comparisonType: 'percentage_growth',
                delayType:
                    timePeriodForMap === 'thisMonth' ? 'months' : dateRangeLength <= 7 ? 'weeks' : 'previous_stretch',
                delay: 1,
            }
            const data = await statsService.getStats(queryConfig, variables)
            if (!isMounted.current || _lastRequest.current !== requestTime) {
                return
            }

            websiteSessions.setData({
                value: data.websiteSessions.totals?.value,
                comparison: data.websiteSessions.totals?.change,
            })

            checkoutSessions.setData({
                value: data.checkoutSessions.totals?.value,
                comparison: data.checkoutSessions.totals?.change,
            })

            checkoutVisitRate.setData({
                value: data.checkoutVisitRate.totals?.value,
                comparison: data.checkoutVisitRate.totals?.change,
            })

            checkoutConversionRate.setData({
                value: data.checkoutConversionRate.totals?.value,
                comparison: data.checkoutConversionRate.totals?.change,
            })

            websiteConversionRate.setData({
                value: data.websiteConversionRate.totals?.value,
                comparison: data.websiteConversionRate.totals?.change,
            })

            setLoading(false)
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Some data could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }

    const getPlusSign = (change: string) => {
        const value = Number(change.split('%')[0])
        return value > 0 ? '+' : ''
    }

    const formatChangeText = (stats: string, change: string) => {
        const value = Number(change.split('%')[0])
        return value >= 0
            ? `${getPlusSign(change)}${change} ${infotipContentMap[stats].more}`
            : `${change} ${infotipContentMap[stats].less}`
    }

    const formatInfotipText = (stats: string, change: string) => {
        const textPart = timePeriod === 'yesterday' || timePeriod === 'custom' ? 'had' : 'are expected to have'
        return (
            <>
                {comparisonCopyMap[timePeriod].infotipStart} you {textPart}
                &nbsp;{formatChangeText(stats, change)} {infotipContentMap[stats].itemName}
                &nbsp;than the {comparisonCopyMap[timePeriod].infotipEnd}.
            </>
        )
    }

    const getComparisonText = () => {
        return timePeriod === 'thisMonth' ? 'MoM' : timePeriodLength <= 7 ? 'WoW' : 'prev. period'
    }

    const allItems = () => {
        return [
            {
                key: 'websiteSessions',
                data: websiteSessions,
            },
            {
                key: 'checkoutSessions',
                data: checkoutSessions,
            },
            {
                key: 'checkoutVisitRate',
                data: checkoutVisitRate,
                type: 'percentage',
            },
            {
                key: 'websiteConversionRate',
                data: websiteConversionRate,
                type: 'percentage',
            },
            {
                key: 'checkoutConversionRate',
                data: checkoutConversionRate,
                type: 'percentage',
            },
        ]
    }

    const renderItem = (key: string, metrics: ComparisonMetricHook, type: string = 'number', index: number) => {
        const value =
            type === 'number'
                ? Number(metrics.value) >= 10000
                    ? abbreviateNumber(Number(metrics.value), 1)
                    : metrics.value
                : `${toPercent(metrics.value)}%`

        return (
            <DataNumber
                key={key}
                title={infotipContentMap[key].pageName}
                value={value}
                comparisonItem={`${getPlusSign(metrics.comparison)}${metrics.comparison} ${getComparisonText()}`}
                comparisonItemColor={getComparisonColorFromPercentString(metrics.comparison)}
                infotipText={formatInfotipText(key, metrics.comparison)}
                metricInfotipText={infotipContentMap[key].metricText}
                tooltipType={getComparisonText()}
                accountSlug={props.accountSlug}
                compact
                id={String(index + 1)}
                nthChildStyle={key === 'websiteConversionRate' ? 'margin-right: -2em' : ''}
                infotipPosition={allItems().length - 2 <= index ? 'right' : 'left'}
            />
        )
    }

    return (
        <ChartContainer style={{ marginBottom: '2em' }}>
            {loading && <ChartDataLoader />}
            <ChartRow>{allItems().map((item, index) => renderItem(item.key, item.data, item.type, index))}</ChartRow>
        </ChartContainer>
    )
}

export default withNavigation(SingleDataItems)
