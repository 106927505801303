import React, { useEffect } from 'react'
import { MessageProps, withMessages } from 'hocs'
import { PageHeading, PageTitle, StyledATag } from 'uiComponents/typography'
import { resetState } from './redux'
import { FeatureProps, withFeatures } from 'features'
import OrdersTable from './ordersTable'
import OrderFilters from './filters/searchAndDate'
import './index.css'
import { Messages } from 'uiComponents/messages'
import { useDispatch } from 'react-redux'
import OrderTableEventHandler from './orderTableEventHandler'
import { useHistory } from 'react-router-dom'

interface OrdersProps extends MessageProps, FeatureProps {}

const Orders: React.FC<OrdersProps> = ({ messages, hideMessage, replaceMessages, children }) => {
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(resetState())
            history.replace({ search: '' })
        }
    }, [])

    return (
        <div id="orders__elem">
            <PageTitle>Orders</PageTitle>
            <Messages messages={messages} hideMessage={hideMessage} />
            <PageHeading>
                View, edit, and refund orders, resend emails, and download e-tickets. You can view a short introduction
                to order management{' '}
                <StyledATag target="_blank" href="https://support.convious.com/help/how-to-manage-orders">
                    here
                </StyledATag>
                .
            </PageHeading>
            <OrderTableEventHandler>
                <OrderFilters />
                <OrdersTable replaceMessages={replaceMessages} hideMessage={hideMessage} />
            </OrderTableEventHandler>
            {children}
        </div>
    )
}

export default withMessages(withFeatures(Orders))
