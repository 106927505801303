import React from 'react'
import styled from 'styled-typed'
import Infotip from 'uiComponents/infotip'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { replaceSpaces } from 'utils/stringUtils'

const Column = styled.div`
    display: flex;
    flex-direction: column;
`

const StatsColumn = styled(Column)`
    height: 8.5em;
    font-size: 0.875rem;
`

const Data = styled.div`
    font-size: 2.3em;
    padding: 0.1em 0 0.1em 0.6rem;
    font-weight: lighter;
`

const Name = styled.div`
    display: flex;
    background: linear-gradient(
        to right,
        ${(props) => props.theme.colors.background},
        ${(props) => props.theme.colors.white}
    );
    border-radius: 0.8em;
    padding: 0.3em 0.7em;
    margin-bottom: 0.2em;
`

interface StatsSectionProps {
    name: string
    data: string
    currency: boolean
    comparison: React.ReactNode
    infoText: string | React.ReactNode
    formatCurrency: (amount: number, accountSlug: string) => React.ReactNode
    getCurrency: (accountSlug: string) => Currency
    accountSlug: string
}

function StatsSection(props: StatsSectionProps) {
    const { name, data, currency, infoText, comparison, formatCurrency, accountSlug } = props
    return (
        <>
            {data && (
                <StatsColumn data-testid={replaceSpaces(`cp-dashboard-${name}`)}>
                    <Name>
                        <span>{name}</span>
                        <Infotip pointer="right" maxWidth="28em">
                            {infoText}
                        </Infotip>
                    </Name>
                    <Data>{currency ? formatCurrency(+data, accountSlug) : data}</Data>
                    <div style={{ marginLeft: '.6rem' }}>{comparison}</div>
                </StatsColumn>
            )}
        </>
    )
}

export default withCurrency(StatsSection)
