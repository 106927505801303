import React, { useState, useEffect, MouseEvent } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { Row, Col } from 'uiComponents/flex'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { SingleSelect, SingleSelectOption } from 'uiComponents/input'
import { BarcodeReservoir } from 'admin/articleService'
import { LinkText } from 'uiComponents/typography'
import { LEFT_SIDE_SPAN, RIGHT_SIDE_SPAN } from './common'
import { ArticleCrudData } from 'admin/articleService'
import { ScanningOptionsEditor } from './scanningOptionsEditor'

interface BarcodesProps {
    accountSlug: string
    productData: ArticleCrudData
    barcodeReservoirs: BarcodeReservoir[]
    setProductData: (data: ArticleCrudData) => void
    onExternalNavigate?: (event: MouseEvent, destination: string) => void
    validate: boolean
}

export function Barcodes(props: BarcodesProps) {
    const { productData, setProductData, onExternalNavigate } = props
    const [barcodePoolOptions, setBarcodePoolOptions] = useState<SingleSelectOption[]>([])
    const [markErrors, setMarkErrors] = useState<boolean>(false)
    const barcodesLink = `/account/${props.accountSlug}/venue/codes/tickets/list`

    useEffect(() => {
        const options = props.barcodeReservoirs.map((r) => ({
            name: r.name,
            value: r.id.toString(),
        }))
        setBarcodePoolOptions(options)
    }, [props.barcodeReservoirs])

    useEffect(() => {
        if (props.validate) {
            setMarkErrors(true)
        }
    }, [props.validate])

    const onBarcodePoolSelectChange = (value: string) => {
        const updatedProductData = cloneDeep(productData)
        updatedProductData.barcodePoolId = value
        setProductData(updatedProductData)
    }

    const barcodePoolError =
        markErrors && !productData.barcodePoolId && productData.businessType !== 'service_date_change'

    return (
        <>
            <Row style={{ marginBottom: '1.2em' }}>
                <Col span={LEFT_SIDE_SPAN}>
                    <FormItem htmlFor="barcode-pool" style={{ position: 'relative' }}>
                        <FormItemName>Barcode pool</FormItemName>
                        <SingleSelect
                            id="barcode-pool"
                            options={barcodePoolOptions}
                            selected={productData.barcodePoolId ? productData.barcodePoolId.toString() : null}
                            height="2.5rem"
                            maxHeight="25em"
                            noSelectOption="Select barcode pool"
                            status={barcodePoolError ? 'error' : 'normal'}
                            onSelect={onBarcodePoolSelectChange}
                            style={{ marginBottom: '0.625em' }}
                        />
                    </FormItem>
                    {barcodePoolError && (
                        <ValidationMessage className="validation-message-visible">Select a pool</ValidationMessage>
                    )}
                    <LinkText>
                        Go to the&nbsp;
                        <a
                            href={barcodesLink}
                            onClick={(event) => {
                                if (onExternalNavigate) {
                                    onExternalNavigate(event, barcodesLink)
                                }
                            }}
                        >
                            Codes page
                        </a>
                        &nbsp; to create or manage your barcode pools
                    </LinkText>
                </Col>
                <Col span={RIGHT_SIDE_SPAN} />
            </Row>
            <ScanningOptionsEditor />
        </>
    )
}
