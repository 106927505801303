import { generalIcons, animalsIcons } from '@convious/tap-icons'
import { OverlayView } from '@react-google-maps/api'
import { find } from 'lodash'
import React, { useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { useParkMapViewerContext } from '../../context/mapViewer'
import { PHONE } from '../mapPreview/consts'

const IconContainer = styled.a`
    overflow: auto;
    cursor: pointer;
`

const IconWeb = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const IconPhone = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const IconCircle = styled.div`
    width: 14px;
    height: 14px;
    border-radius: 7px;
    border: 1.5px solid black;
`

const getPixelPositionOffset = (width: number, height: number) => ({
    x: -(width / 2),
    y: -(height / 2),
})

interface Props {
    limited?: boolean
    previewType?: string
}

const CustomMarkers = ({ limited, previewType }: Props) => {
    const history = useHistory()
    const { url } = useRouteMatch()
    const { map, poisToDisplay, categories } = useParkMapViewerContext()
    const Icon = previewType === PHONE ? IconPhone : IconWeb

    const goToPoiEdit = useCallback((categoryId: string) => {
        return history.push(`${url}/poi/${categoryId}`)
    }, [])

    return (
        <>
            {poisToDisplay.map((poi) => {
                const position = new google.maps.LatLng(poi.lat, poi.lng)
                const category = categories.find((category) => category.pois?.find((poiItem) => poiItem.id === poi.id))

                if (!position || !category || !map) {
                    return
                }

                const categoryIcon = find([...generalIcons, ...animalsIcons], { name: poi.icon || category.icon })

                return (
                    <OverlayView
                        key={poi.id}
                        position={{
                            lat: position?.lat(),
                            lng: position?.lng(),
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset={getPixelPositionOffset}
                    >
                        {limited ? (
                            <IconContainer
                                title={`${category.content[0].name} / ${poi.content[0].name}`}
                                onClick={() => goToPoiEdit(poi.id)}
                            >
                                <IconCircle style={{ background: category.color }} />
                            </IconContainer>
                        ) : (
                            <Icon style={{ background: category.color }}>
                                {categoryIcon && <categoryIcon.icon style={{ color: 'white', width: '70%' }} />}
                            </Icon>
                        )}
                    </OverlayView>
                )
            })}
        </>
    )
}

export default CustomMarkers
