import { useGetFeatureValue } from 'utils/useHasFeature'

export const useHasAnyIntegrations = () => {
    const integrations = useGetFeatureValue('integrations')

    if (!integrations) {
        return false
    }

    for (const key in integrations) {
        if (Object.prototype.hasOwnProperty.call(integrations, key)) {
            if (integrations[key] === true) {
                return true
            }
        }
    }
    return false
}
