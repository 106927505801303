import React from 'react'
import { UploadInput, UploadStatusType } from 'uiComponents/input/upload'
import { ImagesService, ImageUploadResponse } from 'http/imagesService'
import { MessageKind } from 'uiComponents/messages'

interface Field {
    name: string
    label: string
    path: string
    defaultValue: any
    span?: number
    premium?: boolean
    placeholder?: string
    infotip?: string
}

export interface ImageUrl {
    img_url: string
}

interface UploadField extends Field {
    type: 'upload'
}

interface GeneralSectionProps {
    field: UploadField
    style?: React.CSSProperties
    value: boolean | string | number
    handleChange: (value: string) => void
    imagesService: ImagesService
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    type: 'icon' | 'img'
    imageOptions?: any
    status?: UploadStatusType
    fileSizeLimit?: number
}

interface UploadResponse {
    url: string
    img_url: string
}

export function UploadElement(props: GeneralSectionProps) {
    async function handleNewImgUrl(response: UploadResponse) {
        const url = response.url || response.img_url
        props.handleChange(url)
    }

    const imageOptions = {
        max_height: 50,
    }

    async function uploadFile(file: File): Promise<ImageUploadResponse | ImageUrl> {
        return await props.imagesService.uploadImage(file, props.imageOptions || imageOptions)
    }

    return (
        <UploadInput
            type={props.type}
            onUpload={uploadFile}
            handleUploadSuccessResponse={handleNewImgUrl}
            inputId={props.field.name}
            status={props.status || 'normal'}
            fileSizeLimit={props.fileSizeLimit}
            replaceTopMessages={props.replaceTopMessages}
            removeAllMessages={props.removeAllMessages}
        />
    )
}
