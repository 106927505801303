import styled from 'styled-typed'

export const Card = styled.div`
    padding: 2em 1.875em 0.1em 1.875em;
    border: 1px solid ${(props) => props.theme.colors.border};
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.625em;
`

export const InputCard = styled.div`
    padding: 1.5rem 1.5rem 0.75rem 1.5rem;
    border-radius: 0.375em;
    background-color: #fcfdfe;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    color: ${(p) => p.theme.colors.textDark};
`
