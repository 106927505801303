import React, { createContext } from 'react'
import { BaseRouteParams, MessageProps, WithNavigationProps } from 'hocs'
import { MessageKind } from 'uiComponents/messages'
import { Navigation } from 'navigation'
import { History } from 'history'

interface Props {
    accountSlug: string
    history: History
    navigation: Navigation
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

export const MetadataContext = createContext<Props>(undefined as any)

interface StateProps
    extends WithNavigationProps<BaseRouteParams>,
        Omit<MessageProps, 'messages' | 'addMessage' | 'removeAllMessages' | 'hideMessage'> {
    history: History
}

const MetadataContextState: React.FC<StateProps> = ({ children, match, navigation, history, replaceMessages }) => {
    const { accountSlug } = match.params

    return (
        <MetadataContext.Provider
            value={{
                accountSlug,
                history,
                navigation,
                replaceMessages,
            }}
        >
            {children}
        </MetadataContext.Provider>
    )
}

export default MetadataContextState
