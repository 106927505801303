const longHexRegex = /^#([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})$/i
const shortHexRegex = /^#([A-Fa-f0-9])([A-Fa-f0-9])([A-Fa-f0-9])$/i
const rgbRegex = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/
const rgbaRegex = /^rgba\((\d+),\s*(\d+),\s*(\d+),\s*((0(\.\d+)?)|1)\)$/

export class Color {
    constructor(
        public readonly r: number,
        public readonly g: number,
        public readonly b: number,
        public readonly a: number,
    ) {}

    transparency(a: number) {
        return new Color(this.r, this.g, this.b, a)
    }

    css(): string {
        if (this.a === 1) {
            return `#${this.r.toString(16).padStart(2, '0')}${this.g.toString(16).padStart(2, '0')}${this.b
                .toString(16)
                .padStart(2, '0')}`
        }
        return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`
    }

    static fromCss(css: string): Color {
        let match = longHexRegex.exec(css)
        if (match) {
            return new Color(parseInt(match[1], 16), parseInt(match[2], 16), parseInt(match[3], 16), 1)
        }

        match = shortHexRegex.exec(css)
        if (match) {
            return new Color(
                parseInt(match[1] + match[1], 16),
                parseInt(match[2] + match[2], 16),
                parseInt(match[3] + match[3], 16),
                1,
            )
        }

        match = rgbRegex.exec(css)
        if (match) {
            return new Color(parseInt(match[1], 10), parseInt(match[2], 10), parseInt(match[3], 10), 1)
        }

        match = rgbaRegex.exec(css)
        if (match) {
            return new Color(
                parseInt(match[1], 10),
                parseInt(match[2], 10),
                parseInt(match[3], 10),
                parseFloat(match[4]),
            )
        }

        throw new Error(`Invalid color: ${css}`)
    }
}

export function transparency(color: string, value: number): string {
    return Color.fromCss(color).transparency(value).css()
}

export function parsePixels(value: string): number {
    return Number(value.replace(/px$/, ''))
}

export function getCssVariable(name: string): string {
    return getComputedStyle(document.documentElement).getPropertyValue(name)
}

export function hexToRGBA(hex: string, opacity: number): string {
    let r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16)

    return `rgba(${r}, ${g}, ${b}, ${opacity})`
}
