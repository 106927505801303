import * as React from 'react'
import { usePrevious } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { format } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { getComparisonColorFromPercentString } from 'reports/helpers'
import { DataNumber } from 'uiComponents/charts/dataNumber'
import { ChartRow, ChartContainer } from 'uiComponents/charts/styleComponents'
import visitorsIcon from './icons/visitors.svg'
import revenueIcon from './icons/revenue.svg'
import ordersIcon from './icons/orders.svg'
import { QueryConfig } from 'reports/queryGenerator'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { chartRowsGap } from 'reports/helpers'
import { DateRange, DateRangeName } from 'dateRanges'
import { areDateRangeDatesEqual, comparisonCopyMap } from 'reports/helpers'
import { isBefore, startOfToday, differenceInCalendarDays } from 'date-fns'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'

const queryConfig: QueryConfig = {
    querySetName: 'GeneralKPIs',
    variablesConfig: [
        { name: 'accountSlug', type: 'String' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'comparisonType', type: 'ComparisonTypes' },
        { name: 'timeFilter', type: 'StatsTimeFilter' },
        { name: 'delayType', type: 'DelayTypes' },
        { name: 'delay', type: 'Int' },
    ],
    queries: [
        {
            name: 'visitors',
            type: 'comparisonMetrics',
            configVariables: [
                'accountSlug',
                'dateFrom',
                'dateTo',
                'comparisonType',
                'timeFilter',
                'delayType',
                'delay',
            ],
            customVariables: [{ name: 'metric', customValue: 'visitors' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'revenue',
            type: 'comparisonMetrics',
            configVariables: [
                'accountSlug',
                'dateFrom',
                'dateTo',
                'comparisonType',
                'timeFilter',
                'delayType',
                'delay',
            ],
            customVariables: [{ name: 'metric', customValue: 'revenue' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'orders',
            type: 'comparisonMetrics',
            configVariables: [
                'accountSlug',
                'dateFrom',
                'dateTo',
                'comparisonType',
                'timeFilter',
                'delayType',
                'delay',
            ],
            customVariables: [{ name: 'metric', customValue: 'transactions' }],
            presetResult: 'totalsComparison',
        },
    ],
}

const infotipContentMap = {
    customers: {
        pageName: 'Visitors',
        reportName: 'Customers',
        itemName: 'visitors',
    },
    revenue: {
        pageName: 'Revenue',
        reportName: 'Revenue',
        itemName: 'revenue',
    },
    sales: {
        pageName: 'Number of orders',
        reportName: 'Sales',
        itemName: 'orders',
    },
}

interface SingleDataItemsProps {
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    dateRange: DateRange
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

function SingleDataItems(props: SingleDataItemsProps) {
    const _isMounted = React.useRef(false)
    const _lastRequest = React.useRef<number>()
    const statsService = React.useContext(StatsServiceContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [timePeriod, setTimePeriod] = React.useState<DateRangeName>('yesterday')
    const [timePeriodLength, setTimePeriodLength] = React.useState<number>(0)
    const [visitors, setVisitors] = React.useState<string>('0')
    const [revenue, setRevenue] = React.useState<string>('0')
    const [orders, setOrders] = React.useState<string>('0')
    const [comparisonVisitors, setComparisonVisitors] = React.useState<string>('-')
    const [comparisonRevenue, setComparisonRevenue] = React.useState<string>('-')
    const [comparisonOrders, setComparisonOrders] = React.useState<string>('-')

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    React.useEffect(() => {
        _isMounted.current = true
        if (prevAccountSlug !== props.accountSlug || !areDateRangeDatesEqual(prevDateRange, props.dateRange)) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug, props.dateRange])

    const getData = async () => {
        setLoading(true)
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        const timePeriods = ['today', 'yesterday', 'thisWeek', 'thisMonth']
        const timePeriodForMap = timePeriods.indexOf(props.dateRange.name) > -1 ? props.dateRange.name : 'custom'
        setTimePeriod(timePeriodForMap)
        const dateRangeLength = differenceInCalendarDays(props.dateRange.to, props.dateRange.from) + 1
        setTimePeriodLength(dateRangeLength)
        try {
            const todayIncluded = isBefore(startOfToday(), props.dateRange.to)
            const variables = {
                accountSlug: props.accountSlug,
                dateFrom: format(props.dateRange.from, 'yyyy-MM-dd'),
                dateTo: format(props.dateRange.to, 'yyyy-MM-dd'),
                comparisonType: 'percentage_growth',
                timeFilter: todayIncluded ? 'this_time_so_far' : null,
                delayType:
                    timePeriodForMap === 'thisMonth' ? 'months' : dateRangeLength <= 7 ? 'weeks' : 'previous_stretch',
                delay: 1,
            }
            const data = await statsService.getStats(queryConfig, variables)

            if (_isMounted.current && _lastRequest.current === requestTime) {
                setVisitors(data.visitors.totals?.value || '0')
                setRevenue(data.revenue.totals?.value || '0')
                setOrders(data.orders.totals?.value || '0')
                setComparisonVisitors(data.visitors.totals?.change || '-')
                setComparisonRevenue(data.revenue.totals?.change || '-')
                setComparisonOrders(data.orders.totals?.change || '-')
                setLoading(false)
            }
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Some data could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }

    const getPlusSign = (change: string) => {
        const value = Number(change.split('%')[0])
        return value > 0 ? '+' : ''
    }

    const formatChangeText = (change: string) => {
        const value = Number(change.split('%')[0])
        return value >= 0 ? `${getPlusSign(change)}${change} more` : `${change} less`
    }

    const getNavigationLink = (page: string) => {
        return page === 'sales'
            ? `/account/${props.accountSlug}/reports/sales/${location.search || '?'}&metric=transactions`
            : `/account/${props.accountSlug}/reports/${page === 'revenue' ? 'sales' : page}/${location.search}`
    }

    const formatInfotipText = (page: string, change: string) => {
        const textPart = timePeriod === 'yesterday' || timePeriod === 'custom' ? 'had' : 'are expected to have'
        return (
            <>
                {comparisonCopyMap[timePeriod].infotipStart} you {textPart}
                &nbsp;{formatChangeText(change)} {infotipContentMap[page].itemName} than the{' '}
                {comparisonCopyMap[timePeriod].infotipEnd}. See more insights in your&nbsp;
                <span className="link">
                    <UnstyledLink to={getNavigationLink(page)}>
                        <>{infotipContentMap[page].reportName} report</>
                    </UnstyledLink>
                </span>
                .
            </>
        )
    }

    const getComparisonText = () => {
        if (timePeriod !== 'custom') {
            return comparisonCopyMap[timePeriod].comparisonText
        }
        return timePeriodLength <= 7 ? 'WoW' : 'prev. period'
    }

    const getTooltipType = () => {
        return timePeriod === 'thisMonth' ? 'MoM' : timePeriodLength <= 7 ? 'WoW' : 'prev. period'
    }

    return (
        <ChartContainer style={{ marginTop: chartRowsGap }} className="clickable-title">
            {loading && <ChartDataLoader />}
            <ChartRow style={{ overflow: 'hidden' }}>
                <DataNumber
                    icon={visitorsIcon}
                    title="Visitors"
                    value={Number(visitors).toFixed(0)}
                    comparisonItem={`${getPlusSign(comparisonVisitors)}${comparisonVisitors} ${getComparisonText()}`}
                    comparisonItemColor={getComparisonColorFromPercentString(comparisonVisitors)}
                    infotipText={formatInfotipText('customers', comparisonVisitors)}
                    tooltipType={getTooltipType()}
                    navigationLink={getNavigationLink('customers')}
                />
                <DataNumber
                    icon={revenueIcon}
                    title="Revenue"
                    value={Number(revenue).toFixed(2)}
                    comparisonItem={`${getPlusSign(comparisonRevenue)}${comparisonRevenue} ${getComparisonText()}`}
                    comparisonItemColor={getComparisonColorFromPercentString(comparisonRevenue)}
                    infotipText={formatInfotipText('revenue', comparisonRevenue)}
                    withCurrency
                    accountSlug={props.accountSlug}
                    tooltipType={getTooltipType()}
                    navigationLink={getNavigationLink('revenue')}
                />
                <DataNumber
                    icon={ordersIcon}
                    title="Orders"
                    value={Number(orders).toFixed(0)}
                    comparisonItem={`${getPlusSign(comparisonOrders)}${comparisonOrders} ${getComparisonText()}`}
                    comparisonItemColor={getComparisonColorFromPercentString(comparisonOrders)}
                    infotipText={formatInfotipText('sales', comparisonOrders)}
                    infotipPosition="right"
                    tooltipType={getTooltipType()}
                    navigationLink={getNavigationLink('sales')}
                />
            </ChartRow>
        </ChartContainer>
    )
}

export default withNavigation(SingleDataItems)
