import * as React from 'react'
import styled from 'styled-typed'
import { LoginService } from 'http/loginService'
import DownloadLink from 'uiComponents/downloadLink'
import { OnClickMenu, OnClickMenuItem } from 'uiComponents/menus/'
import { MessageKind, MessageButton } from 'uiComponents/messages'
import { delay } from 'utils'
import * as config from 'config'
import { LoggingService, ActionEventData } from 'http/loggingService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport, faArrowCircleDown } from '@fortawesome/pro-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome'
import { DownloadData } from 'reports/schema'

const Container = styled.div`
    margin-left: -5.3em;

    &.in-chart-wrapper {
        position: absolute;
        top: 1.6em;
        right: 2em;
        z-index: 1;
    }
`
const Icon = styled(FontAwesomeIcon)`
    margin-right: 0.5em;
`

interface ExportButtonProps {
    accountSlug: string
    downloadData: DownloadData
    loginService: LoginService
    loggingService: LoggingService
    withinChartWrapper?: boolean
    replaceMessages: (id: string, status: MessageKind, text: string, actionButton?: MessageButton) => void
    hideMessage: (id: string) => void
}

function ExportMenu(props: ExportButtonProps) {
    const backofficeEndpoint = config.getRequired('backoffice-endpoint')
    const setMessage = async () => {
        props.replaceMessages('export_starting', 'success', 'Report will download automatically.')
        await delay(2000)
        props.hideMessage('export_starting')
    }

    const logDownloadInitiated = (endpoint: string, data: DownloadData) => {
        const eventData: ActionEventData = {
            category: 'reports',
            payload: {
                endpoint,
                exported_data: data,
            },
        }
        props.loggingService.logAction('report_export_requested', eventData)
    }

    const downloadLink = `${backofficeEndpoint}dashboard_api/reports/export/`
    return (
        <Container className={props.withinChartWrapper ? 'in-chart-wrapper' : ''}>
            <OnClickMenu iconTrigger={faFileExport as IconProp} width="8.3em" alignRight>
                <DownloadLink
                    loginService={props.loginService}
                    downloadEndpoint={downloadLink}
                    onDownloadInitiated={logDownloadInitiated}
                    downloadData={Object.assign({ ...props.downloadData }, { export_format: 'csv' })}
                >
                    <OnClickMenuItem onClick={setMessage}>
                        <Icon icon={faArrowCircleDown as IconProp} />
                        Export .CSV
                    </OnClickMenuItem>
                </DownloadLink>
                <DownloadLink
                    loginService={props.loginService}
                    downloadEndpoint={downloadLink}
                    onDownloadInitiated={logDownloadInitiated}
                    downloadData={Object.assign({ ...props.downloadData }, { export_format: 'xlsx' })}
                >
                    <OnClickMenuItem onClick={setMessage}>
                        <Icon icon={faArrowCircleDown as IconProp} />
                        Export .XLSX
                    </OnClickMenuItem>
                </DownloadLink>
            </OnClickMenu>
        </Container>
    )
}

export default ExportMenu
