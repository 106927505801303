import React, { useState, useEffect, ChangeEvent } from 'react'
import styled from 'styled-typed'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { Col } from 'uiComponents/flex'
import { InputComponent } from 'uiComponents/studio/form/fieldComponents'
import { ErrorMessage } from 'uiComponents/studio/form/errorMessage'
import { RichTextInput } from 'uiComponents/input/richTextInput'
import {
    FormItemType,
    templatesConfig,
    TemplateType,
    TemplateSettingsWithVariables,
    translationFieldList,
} from 'emailTemplates/schema'
import { isValidUrlIncludingHttp } from 'utils/formFieldChecks'
import Infotip from 'uiComponents/infotip'

const CollapsingWrapper = styled.div`
    overflow: visible;
    transition: all 0.5s ease;
    position: relative;

    &.collapsed {
        padding-bottom: 0;
        max-height: 0;
        opacity: 0;
        height: 0;
        * {
            display: none;
        }
    }
    &.expanded {
        max-height: 50em;
        opacity: 1;
        height: 100%;
    }
`
const TextlineToggle = styled.div`
    position: relative;
    top: -1.3em;
    cursor: pointer;
    font-size: 0.7em;
    color: ${(props) => props.theme.colors.textLight};
    display: flex;
    align-items: center;
    span {
        margin-bottom: 0.1em;
    }
`
export const checkVariableErrors = (value: string, templateVariables: string[]) => {
    if (value.split('{').length - 1 !== value.split('}').length - 1) {
        return 'Please check variable sybmols "{" and "}" are used correctly'
    }
    if (value.indexOf('{') > -1) {
        const variables = collectVariables(value)
        const allValid = variables.every((v) => templateVariables.indexOf(v) > -1)
        return allValid ? '' : 'Please use variables from the list'
    }
    return ''
}

const collectVariables = (value: string) => {
    const variables: string[] = []
    while (value.indexOf('{') > -1) {
        const start = value.indexOf('{')
        const end = value.indexOf('}')
        const variable = value.substring(start + 1, end)
        variables.push(variable)
        value = value.substring(end + 1)
    }
    return variables
}

interface CopySectionProps {
    templateType: TemplateType
    activeLanguage: string
    templateSettings: TemplateSettingsWithVariables
    unusedFields: FormItemType[]
    markAllErrors: boolean
    onFinishTyping: (value: string, item: FormItemType) => void
    handleChange: (value: string, item: FormItemType) => void
    toggleUnusedField: (item: FormItemType, add: boolean) => void
}

function CopySection(props: CopySectionProps) {
    const [subjectError, setSubjectError] = useState<string>('')
    const [preheaderError, setPreheaderError] = useState<string>('')
    const [titleError, setTitleError] = useState<string>('')
    const [paragraphTextError, setParagraphTextError] = useState<string>('')
    const [ctaUrlError, setCtaUrlTextError] = useState<string>('')
    const [textlineError, setTextlineError] = useState<string>('')
    const [footerTextError, setFooterTextError] = useState<string>('')

    useEffect(() => {
        if (!props.markAllErrors) {
            resetValidityErrors()
        } else {
            checkAllValidity()
        }
    }, [props.activeLanguage])

    useEffect(() => {
        if (props.markAllErrors) {
            checkAllValidity()
        }
    }, [props.markAllErrors])

    const resetValidityErrors = () => {
        setSubjectError('')
        setPreheaderError('')
        setTitleError('')
        setParagraphTextError('')
        setCtaUrlTextError('')
        setTextlineError('')
        setFooterTextError('')
    }

    const checkAllValidity = () => {
        translationFieldList.forEach((f) => {
            checkValidity(props.templateSettings[f][props.activeLanguage], f)
        })
    }

    const checkUrlValid = (value: string) => {
        return isValidUrlIncludingHttp(value)
            ? ''
            : 'Please ensure the URL is valid (including "http(s)://"). Alternatively leave blank to hide the feedback button.'
    }

    const checkValidity = (value: string, item: FormItemType) => {
        const emptyError = 'Please enter some text'
        const variables = props.templateSettings.variables

        switch (item) {
            case 'subject':
                value.trim() ? setSubjectError(checkVariableErrors(value, variables)) : setSubjectError(emptyError)
                break
            case 'preheader':
                value.trim() ? setPreheaderError(checkVariableErrors(value, variables)) : setPreheaderError(emptyError)
                break
            case 'title':
                value.trim() ? setTitleError(checkVariableErrors(value, variables)) : setTitleError(emptyError)
                break
            case 'paragraphText':
                value.trim()
                    ? setParagraphTextError(checkVariableErrors(value, variables))
                    : setParagraphTextError(emptyError)
                break
            case 'textline':
                value.trim() ? setTextlineError(checkVariableErrors(value, variables)) : setTextlineError(emptyError)
                break
            case 'footerText':
                value.trim()
                    ? setFooterTextError(checkVariableErrors(value, variables))
                    : setFooterTextError(emptyError)
                break
            case 'ctaUrl':
                value.trim() ? setCtaUrlTextError(checkUrlValid(value)) : setCtaUrlTextError('')
                break
            default:
                return
        }
    }

    const onFinishTyping = (value: string, item: FormItemType) => {
        checkValidity(value, item)
        props.onFinishTyping(value, item)
    }

    const toggleTextlineInput = () => {
        props.toggleUnusedField('textline', showTextline)
    }

    type TextAreaType = 'textArea'
    type TextField = 'text'
    const subjectTextField = {
        name: 'subject-text',
        type: 'text' as TextField,
        label: 'Subject text',
        path: '',
        defaultValue: 'Subject text',
    }
    const preheaderTextField = {
        name: 'title-text',
        type: 'text' as TextField,
        label: 'Preview text',
        path: '',
        defaultValue: 'Preview text',
    }
    const titleTextField = {
        name: 'title-text',
        type: 'text' as TextField,
        label: 'Title text',
        path: '',
        defaultValue: 'Title text',
    }
    const paragraphTextField = {
        name: 'paragraph-text',
        type: 'text' as TextAreaType,
        label: 'Paragraph text',
        path: '',
        defaultValue: 'Paragraph text',
    }
    const textlineTextField = {
        name: 'textline-text',
        type: 'text' as TextAreaType,
        label: 'Textline',
        path: '',
        defaultValue: 'Textline',
    }
    const footerTextField = {
        name: 'footer-text',
        type: 'text' as TextAreaType,
        label: 'Footer text',
        path: '',
        defaultValue: 'Footer text',
    }
    const ctaUrlField = {
        name: 'cta-url',
        type: 'text' as TextField,
        label: 'CTA URL',
        path: '',
        defaultValue: 'https://www.example.com/',
        infotip:
            'The CTA is the button that guides your customer to your review page. If URLs in all of the languages are left blank, no button will be shown.',
    }

    const showTextline = props.unusedFields.indexOf('textline') === -1
    const inputVariables = !props.templateSettings.discountReservoir
        ? props.templateSettings.variables.filter((v) => v !== 'discount_code')
        : props.templateSettings.variables
    const customVariables = inputVariables.map((v) => `{${v}}`)

    return (
        <>
            <FormItem>
                <Col span={12}>
                    <label htmlFor={subjectTextField.name}>{subjectTextField.label}</label>
                </Col>
                <Col span={12}>
                    <InputComponent
                        field={subjectTextField}
                        handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                            props.handleChange(e.target.value, 'subject')
                        }
                        value={props.templateSettings.subject[props.activeLanguage]}
                        checkValidity={(v) => onFinishTyping(v, 'subject')}
                        maxLength={100}
                        error={!!subjectError}
                        style={{ fontWeight: 'normal' }}
                        locale={props.activeLanguage}
                    />
                    {!!subjectError && <ErrorMessage errText={subjectError} />}
                </Col>
            </FormItem>
            <FormItem>
                <Col span={12}>
                    <label htmlFor={preheaderTextField.name}>{preheaderTextField.label}</label>
                </Col>
                <Col span={12}>
                    <InputComponent
                        field={preheaderTextField}
                        handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                            props.handleChange(e.target.value, 'preheader')
                        }
                        value={props.templateSettings.preheader[props.activeLanguage]}
                        checkValidity={(v) => onFinishTyping(v, 'preheader')}
                        maxLength={100}
                        error={!!preheaderError}
                        style={{ fontWeight: 'normal' }}
                        locale={props.activeLanguage}
                    />
                    {!!preheaderError && <ErrorMessage errText={preheaderError} />}
                </Col>
            </FormItem>
            <FormItem>
                <Col span={12}>
                    <label htmlFor={titleTextField.name}>{titleTextField.label}</label>
                </Col>
                <Col span={12}>
                    <InputComponent
                        field={titleTextField}
                        handleChange={(e: ChangeEvent<HTMLInputElement>) => props.handleChange(e.target.value, 'title')}
                        value={props.templateSettings.title[props.activeLanguage]}
                        checkValidity={(v) => onFinishTyping(v, 'title')}
                        maxLength={100}
                        error={!!titleError}
                        style={{ fontWeight: 'normal' }}
                        locale={props.activeLanguage}
                    />
                    {!!titleError && <ErrorMessage errText={titleError} />}
                </Col>
            </FormItem>
            {templatesConfig[props.templateType].customFields.indexOf('paragraphText') > -1 && (
                <FormItem>
                    <Col span={12}>
                        <label htmlFor={paragraphTextField.name}>{paragraphTextField.label}</label>
                    </Col>
                    <Col span={12}>
                        <RichTextInput
                            id={paragraphTextField.name}
                            onChange={(v: string) => props.handleChange(v, 'paragraphText')}
                            value={props.templateSettings.paragraphText[props.activeLanguage]}
                            withLink
                            customVariables={customVariables}
                            maxLength={500}
                            onFinishTyping={(v) => onFinishTyping(v, 'paragraphText')}
                            status={!!paragraphTextError ? 'error' : 'normal'}
                        />
                        {!!paragraphTextError && <ErrorMessage errText={paragraphTextError} />}
                    </Col>
                </FormItem>
            )}
            {templatesConfig[props.templateType].customFields.indexOf('ctaUrl') > -1 && (
                <FormItem>
                    <Col span={12}>
                        <label htmlFor={ctaUrlField.name}>
                            {ctaUrlField.label}
                            <Infotip pointer="left" maxWidth="16em" smallBoxText>
                                {ctaUrlField.infotip}
                            </Infotip>
                        </label>
                    </Col>
                    <Col span={12}>
                        <InputComponent
                            field={titleTextField}
                            handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                                props.handleChange(e.target.value, 'ctaUrl')
                            }
                            value={props.templateSettings.ctaUrl[props.activeLanguage]}
                            checkValidity={(v) => onFinishTyping(v, 'ctaUrl')}
                            maxLength={100}
                            error={!!ctaUrlError}
                            style={{ fontWeight: 'normal' }}
                        />
                        {!!ctaUrlError && <ErrorMessage errText={ctaUrlError} />}
                    </Col>
                </FormItem>
            )}
            {templatesConfig[props.templateType].customFields.indexOf('textline') > -1 && (
                <>
                    <CollapsingWrapper className={showTextline ? 'expanded' : 'collapsed'}>
                        <FormItem>
                            <Col span={12}>
                                <label htmlFor={textlineTextField.name}>{textlineTextField.label}</label>
                            </Col>
                            <Col span={12}>
                                <RichTextInput
                                    id={textlineTextField.name}
                                    onChange={(v: string) => props.handleChange(v, 'textline')}
                                    value={
                                        props.templateSettings.textline
                                            ? props.templateSettings.textline[props.activeLanguage]
                                            : ''
                                    }
                                    withLink
                                    customVariables={customVariables}
                                    maxLength={500}
                                    onFinishTyping={(v) => onFinishTyping(v, 'textline')}
                                    status={!!textlineError ? 'error' : 'normal'}
                                />
                                {!!textlineError && <ErrorMessage errText={textlineError} />}
                            </Col>
                        </FormItem>
                    </CollapsingWrapper>
                    <TextlineToggle onClick={toggleTextlineInput}>
                        {showTextline ? (
                            <>
                                <span>&times;</span>&nbsp;Remove textline
                            </>
                        ) : (
                            '+ Add textline below CTA'
                        )}
                    </TextlineToggle>
                </>
            )}
            {templatesConfig[props.templateType].customFields.indexOf('footerText') > -1 && (
                <FormItem>
                    <Col span={12}>
                        <label htmlFor={footerTextField.name}>{footerTextField.label}</label>
                    </Col>
                    <Col span={12}>
                        <RichTextInput
                            id={footerTextField.name}
                            onChange={(v: string) => props.handleChange(v, 'footerText')}
                            value={
                                props.templateSettings.footerText
                                    ? props.templateSettings.footerText[props.activeLanguage]
                                    : ''
                            }
                            withLink
                            customVariables={customVariables}
                            maxLength={500}
                            onFinishTyping={(v) => onFinishTyping(v, 'footerText')}
                            status={!!footerTextError ? 'error' : 'normal'}
                        />
                        {!!footerTextError && <ErrorMessage errText={footerTextError} />}
                    </Col>
                </FormItem>
            )}
        </>
    )
}

export default CopySection
