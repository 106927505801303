import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { User } from 'auth/state'
import { State } from 'store'

type UserRole = 'admin' | 'convious_admin' | 'user' | 'reseller'

const getUserActivePermissions = (user: User, accountSlug: string | null) => {
    const accountPermissions = user?.permissions.filter((a) => a.account === accountSlug) || []

    return accountPermissions.length > 0 ? accountPermissions[0].permissions : []
}

const getUserRole = (user: Readonly<User>, accountSlug: string | null): UserRole => {
    if (user?.isAdmin) {
        return 'convious_admin'
    }

    if (user?.resellerId) {
        return 'reseller'
    }

    const permissionsList = getUserActivePermissions(user, accountSlug)

    if (permissionsList.includes('partner_admin')) {
        return 'admin'
    }

    return 'user'
}

const UnlessWidget = () => {
    const user = useSelector((state: State) => state.auth.user)
    const profile = useSelector((state: State) => state.profile.profile)
    const accountSlug = useSelector((state: State) => state.preferences.activeAccount)

    useEffect(() => {
        if (typeof window['Txt'] !== 'undefined' && user && profile) {
            try {
                const options = [
                    { key: 'firstName', value: profile?.firstName || '', type: 'string', custom: true },
                    { key: 'lastName', value: profile?.lastName || '', type: 'string', custom: true },
                    { key: 'accountSlug', value: accountSlug || '', type: 'string', custom: true },
                    { key: 'roleInCompany', value: profile?.company.role || '', type: 'string', custom: true },
                    { key: 'role', value: getUserRole(user, accountSlug) || '', type: 'string', custom: true },
                    {
                        key: 'permissions',
                        value: getUserActivePermissions(user, accountSlug).join(', ') || '',
                        type: 'string',
                        custom: true,
                    },
                ]

                window['Txt'].optInTracking()
                window['Txt'].identify(user?.username, options)
            } catch (error) {
                console.warn('Unless identify failed')
            }
        }
    }, [user, accountSlug, profile])

    return null
}

export default UnlessWidget
