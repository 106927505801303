import { useCallback, useEffect } from 'react'
import { REGION_PADDING } from '../components/mapViewer/regionOverlay'
import { useParkMapViewerContext } from '../context/mapViewer'

const useParkMap = () => {
    const { map, zoom, setZoom } = useParkMapViewerContext()
    let overlay: google.maps.OverlayView

    useEffect(() => {
        if (map) {
            overlay = new google.maps.OverlayView()
            overlay.draw = function () {}
            overlay.setMap(map)
            overlay.getProjection()
        }
    }, [map])

    const getBounds = useCallback(() => {
        const mapDiv = map?.getDiv()
        if (!mapDiv) {
            return
        }

        if (!overlay) {
            overlay = new google.maps.OverlayView()
            overlay.draw = function () {}
            overlay.setMap(map)
        }

        const projection = overlay.getProjection()
        if (!projection) {
            return
        }

        const northEast = projection.fromContainerPixelToLatLng(
            new google.maps.Point(mapDiv.offsetWidth - REGION_PADDING, REGION_PADDING),
        )
        const southWest = projection.fromContainerPixelToLatLng(
            new google.maps.Point(REGION_PADDING, mapDiv.offsetWidth - REGION_PADDING),
        )

        const bounds = new google.maps.LatLngBounds(southWest, northEast)
        return bounds
    }, [map])

    const getRegionDimensions = useCallback(() => {
        const mapDiv = map?.getDiv()
        if (!mapDiv) {
            return
        }

        return mapDiv.offsetWidth - REGION_PADDING * 2
    }, [map])

    const zoomIn = useCallback(() => setZoom(zoom + 1), [zoom])
    const zoomOut = useCallback(() => setZoom(zoom - 1), [zoom])

    return {
        getBounds,
        getRegionDimensions,
        zoomIn,
        zoomOut,
    }
}

export default useParkMap
