import React, { useCallback, useState } from 'react'

import ActionButton from 'uiComponents/buttons'
import { SingleSelectOption } from 'uiComponents/input'
import { LoaderWrapper } from 'uiComponents/loaders'
import { ModalDialog, ModalDialogTitle, NavigationSection } from 'uiComponents/popups/modal'
import { BookingCodesService, ReservationCodesPool } from 'venue/bookingCodes/bookingCodesService'
import { ReservationPoolForm, reservationPoolValidationSchema } from '../_shared/form'
import { EditPoolDialogContext } from './context'
import { FormWrapper } from 'uiComponents/form/form'
import { delay } from 'utils'
import { MessageKind } from 'uiComponents/messages'

interface EditPoolDialogProps {
    retailerOptions: SingleSelectOption[]
    getResCodePoolsList(): void
    bookingCodesService: BookingCodesService
    accountSlug: string
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    updateLoaders: (action: 'load' | 'update' | null, state: boolean) => void
}

const EditPoolDialog: React.FC<EditPoolDialogProps> = ({
    children,
    retailerOptions,
    getResCodePoolsList,
    bookingCodesService,
    accountSlug,
    updateLoaders,
    replaceMessages,
    removeAllMessages,
}) => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedCampaign, selectCampaign] = useState<ReservationCodesPool>({} as any)

    const close = useCallback(() => setOpen(false), [])
    const toggle = useCallback(() => setOpen((_open) => !_open), [])

    const handleSubmit = async (values: ReservationCodesPool) => {
        updateLoaders('update', true)
        setLoading(true)

        try {
            await bookingCodesService.editReservationCodesPool(accountSlug, values.id, values)
            setLoading(false)
            close()
            replaceMessages('success', 'success', 'Campaign was edited successfully.')
            await getResCodePoolsList()
            await delay(5000)
            removeAllMessages()
        } catch {
            setLoading(false)
            replaceMessages('unknown_error', 'error', 'Oops! We could not edit a campaign. Please try again later.')
        }

        updateLoaders('update', false)
    }

    return (
        <>
            {!!open && (
                <ModalDialog onDismiss={close} interactive fromTop="20%">
                    <LoaderWrapper loading={loading}>
                        <div style={{ width: '25em' }}>
                            <ModalDialogTitle>Edit campaign</ModalDialogTitle>
                            <FormWrapper
                                enableReinitialize
                                validationSchema={reservationPoolValidationSchema}
                                initialValues={selectedCampaign}
                                onSubmit={handleSubmit}
                            >
                                <ReservationPoolForm
                                    accountSlug={accountSlug}
                                    view="edit"
                                    retailerOptions={retailerOptions}
                                />
                                <NavigationSection>
                                    <ActionButton size="large" secondary onClick={close}>
                                        Cancel
                                    </ActionButton>
                                    <ActionButton id="edit-new-res-codes-pool-button" size="large" type="submit">
                                        Save
                                    </ActionButton>
                                </NavigationSection>
                            </FormWrapper>
                        </div>
                    </LoaderWrapper>
                </ModalDialog>
            )}

            <EditPoolDialogContext.Provider
                value={{
                    selectCampaign,
                    toggle,
                }}
            >
                {children}
            </EditPoolDialogContext.Provider>
        </>
    )
}

export default EditPoolDialog
