import React from 'react'
import styled from 'styled-typed'
import linkedInLogo from 'auth/loginPage/linkedin-logo.png'
import { ActionButton } from 'uiComponents/buttons'

interface LinkedInButtonProps {
    onClick: () => void
    buttonText: string
    style?: React.CSSProperties
}

const LinkedInLogo = styled.img`
    width: 3.5625em;
    height: 2.8125em;
    flex: 0 0 3.5625em;
    border-right: 1px solid #fff;
    padding: 0.3125em 0.5628em 0.375em 0.8125em;
`

const LinkedInButtonText = styled.div`
    width: 17em;
    line-height: 2.875em;
`
const Button = styled(ActionButton)`
    box-shadow: 0 1px 15px 0 rgba(35, 48, 57, 0.25);
    background: #f8f9fb;

    && {
        padding: 0;
        display: flex;
    }
`

export function LinkedInButton({ onClick, buttonText, style }: LinkedInButtonProps) {
    const overrideStyle = {
        background: '#0077B5',
        padding: '0',
        display: 'flex',
        ...style,
    }

    return (
        <Button id="linkedIn" size="large" kind="action" block={true} onClick={onClick} style={overrideStyle}>
            <div style={{ maxHeight: '2.875em' }}>
                <LinkedInLogo src={linkedInLogo} />
            </div>
            <LinkedInButtonText>{buttonText}</LinkedInButtonText>
        </Button>
    )
}
