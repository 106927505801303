import * as React from 'react'
import { State } from 'store'
import { connect } from 'react-redux'
import styled from 'styled-typed'
import { History } from 'history'
import { Navigation } from 'navigation'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { PageTitle, PageHeading, Headline } from 'uiComponents/typography'
import { NavigationBack } from 'uiComponents/navigation/navigationBack'
import { Container } from 'uiComponents/settingsContainer'
import { User } from 'auth/state'
import { AuthTicket } from 'http/oauthService'
import { LoginService } from 'http/loginService'
import * as config from 'config'
import { withFeatures } from 'features'
import { usePrevious } from 'reactUtils'

const Wrapper = styled(Container)`
    margin-bottom: 15em;
    border-radius: 6px;
`
const CheckoutContainer = styled.div`
    border-radius: 6px;
    overflow: hidden;
`

interface InlineCheckoutProps {
    history: History
    accountSlug: string
    user: User
    ticket: AuthTicket
    loginService: LoginService
    match: RouteMatch<{ accountSlug: string }>
    navigation: Navigation
    impersonating: boolean
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function InlineCheckout(props: InlineCheckoutProps) {
    const [key, setKey] = React.useState<number>(Math.random())
    const isResellerPage = !!props.user.resellerId && props.user.accounts.length > 1
    const activeAccount = props.user.accounts.find((a) => a.slug === props.accountSlug)
    const accountName = activeAccount?.name || ''
    const showAccountName = isResellerPage && !!accountName

    // @ts-ignore
    window.getResellerJwt = async () => {
        const ticket = await props.loginService.ensureTokenNotExpired(props.ticket)
        return ticket.accessToken
    }

    const removeElement = (element: Element | null) => {
        if (element && element.parentElement) {
            element.parentElement.removeChild(element)
        }
    }

    React.useEffect(() => {
        if (!lastImpersonatingValue || lastImpersonatingValue === props.impersonating) {
            const scriptTag = document.getElementById('convious_loader')
            if (scriptTag) {
                setKey(Math.random())
                removeElement(scriptTag)
            }
            const loaderEndpoint = config.getRequired('convious-loader-endpoint')
            const script = document.createElement('script')
            script.id = 'convious_loader'
            const userAuth = !!props.user.resellerId ? `&reseller_id=${props.user.resellerId}` : '&use-cp-auth=true'
            script.src = `${loaderEndpoint}loader.js?k=${props.match.params.accountSlug}&type=inline${userAuth}`
            document.head!.appendChild(script)
        }
    }, [props.match.params.accountSlug, props.user.resellerId, props.impersonating])

    const lastImpersonatingValue = usePrevious(props.impersonating)

    return (
        <>
            {showAccountName ? (
                <>
                    <PageTitle>Checkout</PageTitle>
                    <PageHeading style={{ marginBottom: '2em', fontWeight: 500 }}>{accountName}</PageHeading>
                </>
            ) : (
                <Headline size={1}>Checkout</Headline>
            )}
            {!isResellerPage && (
                <NavigationBack
                    onClick={() => props.history.push(`/account/${props.accountSlug}/orders/orders${location.search}`)}
                    text="Back to order list"
                    topOffset="-1.7em"
                />
            )}
            <Wrapper>
                <CheckoutContainer key={key} id="convious-inline-checkout" />
            </Wrapper>
        </>
    )
}

const mapStateToProps = (state: State) => {
    return {
        user: state.auth.user,
        ticket: state.auth.ticket,
        impersonating: !!state.auth.user?.impersonatedBy,
    }
}

export default withFeatures(withNavigation(connect(mapStateToProps)(InlineCheckout)))
