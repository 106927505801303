import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { LoaderWrapper } from 'uiComponents/loaders'
import { useSelectedParkMapContext } from 'venue/parkMap/context/selectedParkMap'
import { BaseRouteParams } from '../../../../hocs'
import { useVenueLocations } from '../../../../tapPlacements/studio/helpers'
import { useParkMapViewerContext } from '../../context/mapViewer'
import useCreateMap from '../../hooks/useCreateMap'
import { Parkmap, Region } from '../../models'
import MapForm from './mapForm'

const TabMapCreate = () => {
    const { isLoading, createMap } = useCreateMap()
    const { setIsRegionChanged, coords, zoom } = useParkMapViewerContext()
    const { selectedParkMap, setSelectedParkMap } = useSelectedParkMapContext()
    const { accountSlug } = useParams<BaseRouteParams>()
    const venueLocations = useVenueLocations(accountSlug)

    const data = {
        id: '',
        enabled: false,
        name: '',
        region: {
            zoom,
            center: coords,
            north: 0,
            east: 0,
            south: 0,
            west: 0,
        } as Region,
        slug: accountSlug,
        locationId: venueLocations.length > 1 ? '' : venueLocations[0]?.value,
        mapType: 'basic',
        googleMapType: 'roadmap',
    }

    const onSubmit = useCallback(async (values: Parkmap) => {
        createMap(values)
        setIsRegionChanged(false)
    }, [])

    useEffect(() => {
        setSelectedParkMap(data)
    }, [venueLocations.length])

    return (
        <LoaderWrapper loading={isLoading}>
            <MapForm data={selectedParkMap} onSubmit={onSubmit} />
        </LoaderWrapper>
    )
}

export default TabMapCreate
