import * as React from 'react'

interface ReCaptchaProps {
    sitekey: string
    verifyCallback: any
    action: string
    verifyCallbackName?: any
}

interface ReCaptchaState {
    ready: boolean
}

export const loadReCaptcha = (siteKey: string) => {
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`
    document.body.appendChild(script)
}

export class ReCaptcha extends React.Component<ReCaptchaProps, ReCaptchaState> {
    readyCheck: any

    constructor(props: any) {
        super(props)
        this.state = {
            ready: this.isReady(),
        }

        if (!this.state.ready) {
            this.readyCheck = setInterval(this.updateReadyState, 1000)
        }
    }

    componentDidMount() {
        if (!!this.state.ready) {
            this.execute()
        }
    }

    componentDidUpdate(prevProps: ReCaptchaProps, prevState: ReCaptchaState) {
        if (this.state.ready && !prevState.ready) {
            this.execute()
        }
    }

    componentWillUnmount() {
        clearInterval(this.readyCheck)
    }

    isReady = () =>
        typeof window !== 'undefined' &&
        typeof (window as any).grecaptcha !== 'undefined' &&
        typeof (window as any).grecaptcha.execute !== 'undefined'

    updateReadyState = () => {
        if (this.isReady()) {
            this.setState({ ready: true })
            clearInterval(this.readyCheck)
        }
    }

    execute() {
        const { sitekey, verifyCallback, action } = this.props

        if (this.state.ready) {
            ;(window as any).grecaptcha.execute(sitekey, { action }).then((token: string) => {
                verifyCallback(token)
            })
        }
    }

    render() {
        return <div className="g-recaptcha" />
    }
}
