import React from 'react'
import styled from 'styled-typed'
import { DataTable, HeaderRow, TableHeader, Cell } from 'uiComponents/table'
import { SecondaryText } from 'uiComponents/typography'
import { Row } from 'uiComponents/pageElements'
import Infotip from 'uiComponents/infotip'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { PricingType } from 'products/articleConfigurationService'
import { ArticleListItem } from 'admin/articleService'
import { OverviewIntervalData, DynamicOverviewInterval } from '../pricingService'
import { Error, Success, Highlighted, LinkText, TableRow } from './regularExceptionOverviewTable'

const Header = styled(TableHeader)`
    padding-right: 0.85em;
    padding-left: 0.85em;
`

const TableWrapper = styled.div`
    overflow-x: scroll;
    max-width: 1100px;
    & ${Cell} {
        flex: none;
    }
`

const OutCell = styled.div`
    width: 26em;
    margin-left: 34em;
    padding-left: 0.85em;
    color: ${(p) => p.theme.colors.textLight};
`

export function fetchOkStatus(data: OverviewIntervalData): boolean | null {
    if (data.priceIntervals.length < 1) {
        return null
    }
    if (data.priceIntervals.filter((i) => !!i.error).length > 0) {
        return false
    }
    return true
}

interface ExceptionOverviewTableProps {
    overviewData: OverviewIntervalData[]
    pricingType: PricingType | null
    exceptionProducts: ArticleListItem[]
    accountSlug: string
    formatCurrency: (amount: number, accountSlug: string) => React.ReactNode
    getCurrency: (accountSlug: string) => Currency
}

export function DynamicExceptionOverviewTable(props: ExceptionOverviewTableProps) {
    const columnWidths = ['18em', '8em', '8em', '8em', '8em', '8em', '8em', '8em', '8em', '8em', '8em']

    function fetchStatusDisplay(data: OverviewIntervalData): JSX.Element | null {
        const status = fetchOkStatus(data)
        if (status === null) {
            return <>-</>
        }
        if (!!status) {
            return (
                <Highlighted>
                    <Success>OK</Success>
                </Highlighted>
            )
        }
        const withErrors = data.priceIntervals.filter((i) => !!i.error)
        if (withErrors.length > 0) {
            return (
                <>
                    <Highlighted>
                        <Error>Error</Error>
                    </Highlighted>
                    <Infotip pointer="left" maxWidth="31em">
                        {withErrors.map((e, i) => (
                            <div key={i}>{e.error}</div>
                        ))}
                    </Infotip>
                </>
            )
        }
        return null
    }

    function priceDisplay(interval: DynamicOverviewInterval): JSX.Element {
        const price = props.formatCurrency(interval.new, props.accountSlug)
        return <Highlighted>{!interval.new ? '-' : interval.error ? <Error>{price}</Error> : price}</Highlighted>
    }

    function getProductName(id: string): JSX.Element {
        const product = props.exceptionProducts.find((p) => p.id === id)
        const name = product ? (product.name ? product.name : id) : id
        return (
            <LinkText href={`/account/${props.accountSlug}/products/pricing/${id}/detail`} target="_blank">
                {name}
            </LinkText>
        )
    }

    return (
        <>
            {props.overviewData[0].priceIntervals.length > 0 && (
                <div style={{ margin: '1em 0 4em 0' }}>
                    <div style={{ margin: '1em 0' }}>
                        <SecondaryText style={{ fontStretch: 'expanded' }}>
                            HOW YOUR EXCEPTION AFFECTS PRICING SETTINGS
                        </SecondaryText>
                    </div>
                    <TableWrapper>
                        <Row>
                            <OutCell>Intervals (days before the event)</OutCell>
                        </Row>
                        <DataTable
                            columnWidths={columnWidths}
                            style={{ tableLayout: 'fixed', width: 'fit-content', minWidth: '100%' }}
                        >
                            <HeaderRow>
                                <Header>Product</Header>
                                <Header>Status</Header>
                                <Header>Regular</Header>
                                {props.overviewData[0].priceIntervals.map((i, index) => {
                                    return (
                                        <Header key={index}>
                                            {i.endDay} - {i.startDay}
                                        </Header>
                                    )
                                })}
                            </HeaderRow>
                            {props.overviewData.map((od, i) => (
                                <TableRow key={i} status={fetchOkStatus(od) ? 'normal' : 'error'}>
                                    <Cell className="withName">
                                        <span>{getProductName(od.productId)}</span>
                                    </Cell>
                                    <Cell style={{ overflow: 'visible' }}>{fetchStatusDisplay(od)}</Cell>
                                    <Cell>
                                        {props.formatCurrency(od.priceIntervals[0].previous, props.accountSlug)}
                                    </Cell>
                                    {od.priceIntervals.map((interval, j) => (
                                        <Cell key={j}>{priceDisplay(interval)}</Cell>
                                    ))}
                                </TableRow>
                            ))}
                        </DataTable>
                    </TableWrapper>
                </div>
            )}
        </>
    )
}

export default withCurrency(DynamicExceptionOverviewTable)
