import { isAfter, parse } from 'date-fns'

export const getLowestValidToDay = ({ validTo, validFrom }: { validTo: string; validFrom: string }) => {
    const today = new Date()

    if (!validTo) {
        return null
    }

    if (validFrom && isAfter(validFrom, today)) {
        return parse(validFrom, 'yyyy-MM-dd', new Date())
    }

    return today
}
