import { AsyncAction } from 'store'
import { defaultFeaturesLoaded, accountFeaturesLoaded } from './actions'
import { FeatureToggle } from './featureService'

export function loadFeatureDefaults(): AsyncAction<Promise<FeatureToggle[]>> {
    return async (dispatch, getState, { featureService }): Promise<FeatureToggle[]> => {
        const features = await featureService.getDefaultFeatures()
        await dispatch(defaultFeaturesLoaded(features))
        return features
    }
}

export function loadAccountFeatures(slug: string | null): AsyncAction<Promise<FeatureToggle[]>> {
    return async (dispatch, getState, { featureService }): Promise<FeatureToggle[]> => {
        if (!slug) {
            return []
        }

        const state = getState()
        const existing = state.features.forAccount[slug]
        if (existing) {
            return existing as FeatureToggle[]
        }

        const features = await featureService.getAccountFeatures(slug)
        await dispatch(accountFeaturesLoaded(slug, features))
        return features
    }
}
