import { useFetchAudiences } from 'crm/audiences/hooks/useFetchAudiences'
import React, { useEffect, useMemo } from 'react'
import { MultiSelectField } from 'uiComponents/input'

import { LoaderWrapper } from 'uiComponents/loaders'
import StudioFieldWrapper from './studioFormWrapper'

interface AudienceProps {}

const Audience: React.FC<AudienceProps> = () => {
    const { loading, data, fetch } = useFetchAudiences()

    useEffect(() => {
        fetch()
    }, [])

    const audiences = useMemo(
        () =>
            data.map((audience) => ({
                name: audience.name,
                value: audience.slug,
            })),
        [data],
    )

    return (
        <LoaderWrapper loading={loading}>
            <StudioFieldWrapper
                multi
                component={MultiSelectField}
                name="productConfig.audiences"
                label="Audience"
                options={audiences}
                noSelectOption="Select audience"
            />
        </LoaderWrapper>
    )
}

export default Audience
