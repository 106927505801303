import * as React from 'react'
import styled from 'styled-typed'
import { AdditionalDataNotice } from 'uiComponents/charts/styleComponents'
import { ActionButton } from 'uiComponents/buttons'
import { SearchContainer } from 'uiComponents/search/searchField'
import SearchWithButtonField from 'uiComponents/search/searchWithButton'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

interface SearchSectionProps {
    barcodePoolsCount: number
    depletedPools: number
    onAddNew: () => void
}

export function SearchSection(props: SearchSectionProps) {
    const depletesNoteTextPart = props.depletedPools > 1 ? 's are' : ' is'

    return (
        <SearchContainer style={{ margin: '1.25rem' }}>
            <SearchWithButtonField hideExtendedSearch />
            <Container>
                <AdditionalDataNotice style={{ maxWidth: '33em' }}>
                    You have <span>{props.barcodePoolsCount}</span> available barcode pool
                    {props.barcodePoolsCount === 1 ? '' : 's'}.
                    {!!props.depletedPools && (
                        <>
                            {' '}
                            <span>{props.depletedPools}</span> barcode pool
                            {depletesNoteTextPart} depleted.
                        </>
                    )}
                </AdditionalDataNotice>
                <ActionButton
                    kind="action"
                    size="medium"
                    style={{ marginLeft: '1.5em' }}
                    onClick={props.onAddNew}
                    id="add-new-pool"
                    data-userpilot="barcodes-add-new-pool"
                >
                    + Add new
                </ActionButton>
            </Container>
        </SearchContainer>
    )
}
