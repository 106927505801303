import { useState } from 'react'
import { ComparisonMetricHook } from './types'
import { roundNumber } from 'utils'

type Props = {
    initialValue: string
    initialComparisonValue: string
}

export function UseComparisonMetric(props: Props): ComparisonMetricHook {
    const [value, setValue] = useState(props.initialValue)
    const [comparison, setComparison] = useState(props.initialComparisonValue)

    const setData = (data: { value?: string; comparison?: string }) => {
        if (data.value) {
            setValue(data.value)
        }

        if (data.comparison) {
            setComparison(data.comparison)
        }
    }

    return {
        value,
        comparison,
        setData,
    }
}

export function toPercent(value: number | string, decimals: number = 2) {
    if (typeof value === 'string') {
        return roundNumber(parseFloat(value) * 100, decimals)
    }

    return roundNumber(value * 100, decimals)
}

export enum SreenType {
    FIRST_SCREEN = 'First Screen',
    TICKET_SELECTION = 'Ticket Selection',
    CART_REVIEW = 'Cart Review',
    CONVERSION = 'Conversion',
}

const screenIndexMap = {
    'first screen': 0,
    'ticket selection': 1,
    'cart review': 2,
    conversion: 3,
}

const getScreenIndex = (screenName: string) => {
    return screenIndexMap[screenName.toLowerCase()] || 0
}

export const sortByScreenName = (item1: any, item2: any) => {
    const sortKeyA = getScreenIndex(item1.name)
    const sortKeyB = getScreenIndex(item2.name)
    if (sortKeyA < sortKeyB) {
        return -1
    }
    if (sortKeyA > sortKeyB) {
        return 1
    }
    return 0
}
