import React, { createContext, useCallback, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BaseRouteParams } from '../../hocs'
import { PNCampaign, PNContentForm, PNFrequency, PNScheduleForm, PNScheduleType, PNTargetForm } from '../types'

interface PushNotificationsContextProps {
    appValidated: boolean
    setAppValidated: (data: boolean) => void
    campaign: PNCampaign | undefined
    setCampaign: (data: PNCampaign | undefined) => void
    targetForm: PNTargetForm
    setTargetForm: (data: PNTargetForm) => void
    contentForm: PNContentForm
    setContentForm: (data: PNContentForm) => void
    scheduleForm: PNScheduleForm
    setScheduleForm: (data: PNScheduleForm) => void
    isFormFilled: () => void
}

type Props = {
    children: JSX.Element
}

export const PushNotificationsContext = createContext<PushNotificationsContextProps>(
    {} as PushNotificationsContextProps,
)

const PushNotificationsProvider = ({ children }: Props) => {
    const { accountSlug } = useParams<BaseRouteParams>()

    const [appValidated, setAppValidated] = useState<boolean>(false)

    const [campaign, setCampaign] = useState<PNCampaign | undefined>()

    const [targetForm, setTargetForm] = useState<PNTargetForm>({
        name: '',
        segment_id: '',
        location_id: '',
    })

    const [contentForm, setContentForm] = useState<PNContentForm>({
        url: '',
        content: [
            {
                locale: 'en',
                title: '',
                body: '',
            },
        ],
    })

    const [scheduleForm, setScheduleForm] = useState<PNScheduleForm>({
        type: PNScheduleType.time,
        recurring: false,
        frequency: PNFrequency.once,
    })

    const isFormFilled = useCallback(() => {
        if (!targetForm.name) {
            window.location.href = `/account/${accountSlug}/engage/push_notifications/target`
        }
    }, [targetForm.name])

    return (
        <PushNotificationsContext.Provider
            value={{
                appValidated,
                setAppValidated,
                campaign,
                setCampaign,
                targetForm,
                setTargetForm,
                contentForm,
                setContentForm,
                scheduleForm,
                setScheduleForm,
                isFormFilled,
            }}
        >
            {children}
        </PushNotificationsContext.Provider>
    )
}

export default PushNotificationsProvider
export const usePushNotificationsContext = () => useContext(PushNotificationsContext)
