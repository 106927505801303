import React, { useState, useEffect, MouseEvent } from 'react'
import styled from 'styled-typed'
import { Row, Col } from 'uiComponents/flex'
import { DisabledItemHoverInfotip } from 'uiComponents/infotip'
import { ArticleCrudData } from 'admin/articleService'
import { SingleSelect, SingleSelectOption, Toggle } from 'uiComponents/input'
import { LinkText } from 'uiComponents/typography'
import { InfoCard } from 'uiComponents/cards'
import { Template } from 'ticketTemplates/ticketTemplatesService'
import { FormItemName } from 'uiComponents/form/formElements'
import { LEFT_SIDE_SPAN, RIGHT_SIDE_SPAN } from './common'

const TogglerCol = styled(Col)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const Toggler = styled(Toggle)`
    font-size: 0.875em;
`

interface ProductFormProps {
    accountSlug: string
    ticketTemplatesList: Template[]
    productData: ArticleCrudData
    onTicketTemplateSelect: (id: string) => void
    onPrintTicketTemplateSelect: (id: string | null) => void
    onExternalNavigate: (e: MouseEvent, destination: string) => void
}

export function ETicketsSection(props: ProductFormProps) {
    const [ticketTemplatesList, setTicketTemplatesList] = useState<SingleSelectOption[]>([])
    const [cardTemplatesList, setCardTemplatesList] = useState<SingleSelectOption[]>([])
    const [showCardTemplates, setShowCardTemplates] = useState<boolean>(false)
    const ticketTemplatesLink = `/account/${props.accountSlug}/engage/ticket_templates`

    const mapTemplatesLists = () => {
        const ticketOptions = props.ticketTemplatesList
            .filter((t) => ['TICKET', 'VOUCHER'].includes(t.templateType))
            .map((t) => ({
                name: t.name,
                value: t.id,
            }))
        ticketOptions.unshift({ value: '', name: 'None' })
        setTicketTemplatesList(ticketOptions)
        const cardOptions = props.ticketTemplatesList
            .filter((t) => t.templateType === 'CARD')
            .map((t) => ({
                name: t.name,
                value: t.id,
            }))
        cardOptions.unshift({ value: '', name: 'None' })
        setCardTemplatesList(cardOptions)
    }

    useEffect(() => {
        mapTemplatesLists()
    }, [props.ticketTemplatesList])

    useEffect(() => {
        if (
            !!props.productData.ticketPrintTemplateConfig &&
            !!props.productData.ticketPrintTemplateConfig !== showCardTemplates
        ) {
            setShowCardTemplates(!!props.productData.ticketPrintTemplateConfig)
        }
    }, [props.productData])

    const togglePrintTemplateSelect = () => {
        setShowCardTemplates(!showCardTemplates)
        if (showCardTemplates) {
            props.onPrintTicketTemplateSelect(null)
        }
    }

    return (
        <>
            <Row>
                <Col span={LEFT_SIDE_SPAN}>
                    <DisabledItemHoverInfotip
                        active={ticketTemplatesList.length === 0}
                        infotipText="A product-specific ticket template is still being used, in order to create a new generic template configuration, please click the below link."
                        width="30em"
                    >
                        <SingleSelect
                            options={ticketTemplatesList}
                            selected={props.productData.ticketTemplateConfig || null}
                            noSelectOption="Select a template"
                            height="2.8em"
                            maxHeight="50em"
                            onSelect={props.onTicketTemplateSelect}
                            width="100%"
                        />
                    </DisabledItemHoverInfotip>
                    <LinkText>
                        Go to the&nbsp;
                        <a href={ticketTemplatesLink} onClick={(e) => props.onExternalNavigate(e, ticketTemplatesLink)}>
                            Ticket templates
                        </a>
                        &nbsp;to create or manage your templates
                    </LinkText>
                </Col>
                <Col span={RIGHT_SIDE_SPAN}>
                    {!props.productData.ticketTemplateConfig && ticketTemplatesList.length > 0 && (
                        <InfoCard
                            active
                            type="info"
                            cardHeader=""
                            cardText="A product-specific ticket template is still being used, please select a template configuration from the list."
                        />
                    )}
                </Col>
            </Row>
            <Row style={{ marginTop: '1.5em' }}>
                <TogglerCol span={LEFT_SIDE_SPAN}>
                    <FormItemName>Season pass print version</FormItemName>
                    <Toggler
                        id="print-template"
                        isOn={showCardTemplates || !!props.productData.ticketPrintTemplateConfig}
                        onClick={togglePrintTemplateSelect}
                    />
                </TogglerCol>
                <Col span={RIGHT_SIDE_SPAN} />
            </Row>
            {showCardTemplates && (
                <Row style={{ marginTop: '0.5em' }}>
                    <Col span={LEFT_SIDE_SPAN}>
                        <DisabledItemHoverInfotip
                            active={cardTemplatesList.length === 0}
                            infotipText="There are no season pass template configurations, please contact Convious support."
                            width="30em"
                        >
                            <SingleSelect
                                options={cardTemplatesList}
                                selected={props.productData.ticketPrintTemplateConfig || null}
                                noSelectOption="Select a template"
                                height="2.8em"
                                maxHeight="50em"
                                onSelect={props.onPrintTicketTemplateSelect}
                                width="100%"
                            />
                        </DisabledItemHoverInfotip>
                    </Col>
                    <Col span={RIGHT_SIDE_SPAN} />
                </Row>
            )}
        </>
    )
}
