import * as React from 'react'
import TimeseriesBarChart from 'uiComponents/charts/timeseriesBarChart'
import { StatsService } from 'http/statsService'
import { format } from 'date-fns'
import { TimeseriesBarChartSource } from 'uiComponents/charts/timeseriesBarChartSource'
import { ChartWrapperWithTotals } from '../helpers'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import {
    areDateRangeDatesEqual,
    mapTimeseriesForTooltip,
    getTooltipRow,
    getTooltipXAxisLabel,
    getTooltipHeader,
    getTooltipFooter,
} from 'reports/helpers'
import { DateRange } from 'dateRanges'
import { Filters } from 'uiComponents/filter/schema'
import isEqual from 'lodash/isEqual'
import { DataPoint, dummyTimeSeries, BarcodesChartData } from 'reports/schema'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { TimeSeries } from 'reports/schema'
import { differenceInDays } from 'date-fns'
import { parseISODate } from 'utils/dates'

interface DatedBarcodesChartProps {
    statsService: StatsService
    filters: Filters[]
    accountSlug: string
    chart: TimeseriesBarChartSource
    dateRange: DateRange
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    getCurrency: (accountSlug: string) => Currency
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
}

interface DatedBarcodesChartState {
    total: string
    totalRedeemed: string
    totalNotRedeemed: string
    loading: boolean
    mappedTotalBarcodes: DataPoint[]
    mappedTotalRevenue: DataPoint[]
    mappedRedeemedRevenue: DataPoint[]
}

class DatedBarcodesChart extends React.Component<DatedBarcodesChartProps, DatedBarcodesChartState> {
    _isMounted = false
    private _lastRequest?: number = undefined

    constructor(props: DatedBarcodesChartProps) {
        super(props)
        this.state = {
            total: '0',
            totalRedeemed: '0',
            totalNotRedeemed: '0',
            loading: true,
            mappedTotalBarcodes: [],
            mappedTotalRevenue: [],
            mappedRedeemedRevenue: [],
        }
    }

    async componentDidMount() {
        this._isMounted = true
        await this.getData()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    async componentDidUpdate(prevProps: DatedBarcodesChartProps) {
        if (
            !areDateRangeDatesEqual(prevProps.dateRange, this.props.dateRange) ||
            prevProps.accountSlug !== this.props.accountSlug ||
            !isEqual(prevProps.filters, this.props.filters)
        ) {
            await this.getData()
        }
    }

    shouldShowCustomChart = (timeSeries: TimeSeries[]) => {
        const oneTimeslot = timeSeries[0].points.length < 2
        const wholeDayTimeSlot =
            timeSeries[0].points.length > 0 ? parseISODate(timeSeries[0].points[0].timestamp).getHours() === 0 : true
        return !oneTimeslot || !wholeDayTimeSlot
    }

    getData = async () => {
        const requestTime = new Date().valueOf()
        this._lastRequest = requestTime
        this.setState({ loading: true })
        const dateFrom = format(this.props.chart.from(), 'yyyy-MM-dd')
        const dateTo = format(this.props.chart.to(), 'yyyy-MM-dd')
        const oneDayPeriod = differenceInDays(parseISODate(dateTo), parseISODate(dateFrom)) < 1
        try {
            const dated = true
            const data = await this.props.statsService.getBarcodesChartData(
                this.props.accountSlug,
                dateFrom,
                dateTo,
                this.props.chart.granularity,
                this.props.filters,
                dated,
            )

            if (this._lastRequest !== requestTime) {
                return
            }

            const allBarcodes =
                data.allBarcodes.timeSeries.length > 0 ? data.allBarcodes.timeSeries[0] : dummyTimeSeries
            const redeemedBarcodes =
                data.redeemedBarcodes.timeSeries.length > 0 ? data.redeemedBarcodes.timeSeries[0] : dummyTimeSeries
            const notReddemedTimeSeries = {
                label: 'Not redeemed',
                points: allBarcodes.points.map((p) => {
                    const redeemedPoint = redeemedBarcodes.points.find((r) => r.timestamp === p.timestamp)
                    const redeemedValue = redeemedPoint ? redeemedPoint.value : 0
                    return { timestamp: p.timestamp, value: p.value - redeemedValue }
                }),
            }
            const reddemedTimeSeries = {
                label: 'Redeemed',
                points: allBarcodes.points.map((p) => {
                    const redeemedPoint = redeemedBarcodes.points.find((r) => r.timestamp === p.timestamp)
                    const redeemedValue = redeemedPoint ? redeemedPoint.value : 0
                    return { timestamp: p.timestamp, value: redeemedValue }
                }),
            }
            if (oneDayPeriod && this.shouldShowCustomChart([notReddemedTimeSeries, reddemedTimeSeries])) {
                this.props.chart.formatCustomSeries([notReddemedTimeSeries, reddemedTimeSeries])
            } else {
                this.props.chart.formatSeries([notReddemedTimeSeries, reddemedTimeSeries])
            }

            this.setData(data)
        } catch {
            this.props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Dated barcodes chart could not be loaded, please try again later.',
            )
        }
    }

    setData = (data: BarcodesChartData) => {
        const total = data.allBarcodes.totals.filter((t) => t.name === 'total_value')[0]
        const totalRedeemed = data.redeemedBarcodes.totals.filter((t) => t.name === 'total_value')[0]
        const totalNotRedeemed = total.value - totalRedeemed.value
        const xAxisCategories = this.props.chart.props().xAxisDataRaw
        const granularity = this.props.chart.customGranularity ? 'customTimeseries' : this.props.chart.granularity
        const mappedTotalBarcodes =
            data.allBarcodes.timeSeries.length > 0
                ? mapTimeseriesForTooltip(xAxisCategories, data.allBarcodes.timeSeries[0].points, granularity)
                : []
        const mappedTotalRevenue =
            data.allBarcodesRevenue.timeSeries.length > 0
                ? mapTimeseriesForTooltip(xAxisCategories, data.allBarcodesRevenue.timeSeries[0].points, granularity)
                : []
        const allBarcodes = data.allBarcodes.timeSeries.length > 0 ? data.allBarcodes.timeSeries[0] : dummyTimeSeries
        const redeemedRevenue =
            data.redeemedBarcodesRevenue.timeSeries.length > 0
                ? data.redeemedBarcodesRevenue.timeSeries[0]
                : dummyTimeSeries
        const redeemedRevenueTimeSeries = {
            points: allBarcodes.points.map((p) => {
                const redeemedPoint = redeemedRevenue.points.find((r) => r.timestamp === p.timestamp)
                const redeemedValue = redeemedPoint ? redeemedPoint.value : 0
                return { timestamp: p.timestamp, value: redeemedValue }
            }),
        }
        const mappedRedeemedRevenue = mapTimeseriesForTooltip(
            xAxisCategories,
            redeemedRevenueTimeSeries.points,
            granularity,
        )

        if (this._isMounted) {
            this.setState({
                mappedTotalBarcodes,
                mappedTotalRevenue,
                mappedRedeemedRevenue,
                total: total.value.toFixed(0),
                totalRedeemed: totalRedeemed.value.toFixed(0),
                totalNotRedeemed: totalNotRedeemed.toFixed(0),
                loading: false,
            })
        }
    }

    formatMoney = (amount: string | number) => {
        return this.props.formatCurrencyString(amount, this.props.accountSlug)
    }

    chartTooltipFormatter = (params: TooltipParams[]) => {
        const totalBarcodesPoint = this.state.mappedTotalBarcodes[params[0].dataIndex]
        const totalBarcodes = totalBarcodesPoint ? totalBarcodesPoint.value : '0'
        const totalRevenuePoint = this.state.mappedTotalRevenue[params[0].dataIndex]
        const totalRevenue = totalRevenuePoint ? totalRevenuePoint.value.toFixed(2) : '0'
        const redeemedRevenuePoint = this.state.mappedRedeemedRevenue[params[0].dataIndex]
        const redeemedRevenue = redeemedRevenuePoint ? redeemedRevenuePoint.value.toFixed(2) : '0'
        const unredeemedRevenue = (Number(totalRevenue) - Number(redeemedRevenue)).toFixed(2)
        const redeemed = params.find((p) => p.seriesName === 'Redeemed')
        const notRedeemed = params.find((p) => p.seriesName === 'Not redeemed')
        const granularity = this.props.chart.customGranularity ? 'customTimeseries' : this.props.chart.granularity
        const xAxisLabel = totalBarcodesPoint
            ? getTooltipXAxisLabel(totalBarcodesPoint.timestamp, granularity)
            : params[0].axisValue

        let tooltip = getTooltipHeader(xAxisLabel)
        tooltip += getTooltipRow(['Total:', totalBarcodes, `(${this.formatMoney(totalRevenue)})`], true)
        tooltip += getTooltipRow([
            `${redeemed ? redeemed.marker : ''} Redeemed:`,
            redeemed ? redeemed.value : '0',
            `(${this.formatMoney(redeemedRevenue)})`,
        ])
        tooltip += getTooltipRow([
            `${notRedeemed ? notRedeemed.marker : ''} Not redeemed:`,
            notRedeemed ? notRedeemed.value : '0',
            `(${this.formatMoney(unredeemedRevenue)})`,
        ])
        tooltip += getTooltipFooter()

        return tooltip
    }

    render() {
        const series = this.props.chart.props().series
        if (series.length > 0) {
            series[0].color = '#F77461'
            series[1].color = '#5298F4'
        }
        const axisData = this.props.chart.props().xAxisData
        const { loading } = this.state
        const legendData = this.props.chart.props().legendData

        return (
            <div style={{ position: 'relative' }}>
                {loading && <ChartDataLoader />}
                <ChartWrapperWithTotals
                    title="Dated Barcodes"
                    total={this.state.total}
                    redeemed={this.state.totalRedeemed}
                    notRedeemed={this.state.totalNotRedeemed}
                >
                    <TimeseriesBarChart
                        axisData={axisData}
                        series={series}
                        legendData={legendData.length > 1 ? legendData : null}
                        loading={loading}
                        tooltipFormatter={this.chartTooltipFormatter}
                        legendDisabled
                    />
                </ChartWrapperWithTotals>
            </div>
        )
    }
}

export default withCurrency(DatedBarcodesChart)
