import * as React from 'react'
import ReactEcharts from 'echarts-for-react'
import { withTheme } from 'styled-typed'
import { DashboardTheme } from 'theme'
import { tooltipStyle } from 'uiComponents/charts/styleComponents'
import { TooltipParams } from './timeseriesBarChart'

export type SerieLink = {
    source: string
    target: string
    value: string
}

export type SerieData = {
    name: string
    label: string
}

export interface Series {
    name: string
    type: string
    layout?: string
    barWidth?: string
    barGap?: number
    data: string[]
    label?: {
        formatter(params: any): void
    }
}

interface Props {
    loading: boolean
    title?: string
    series: Series[]
    axisData: string[]
    theme: DashboardTheme
    yAxisName?: string
    xAxisInterval?: number | string
    rotateNames?: boolean
    percent?: boolean
    chartHeight?: number
    gridTop?: number
    gridBottom?: number
    gridLeft?: number
    gridRight?: number
    tooltipFormatter?: (params: TooltipParams[]) => string
    xAxisFormatter?: (value: string, index: number) => string
}

interface ChartState {
    chartOption: any
}

class Chart extends React.Component<Props & React.HTMLAttributes<HTMLElement>, ChartState> {
    CHART_TOP_SPACING = 10

    constructor(props: Props & React.HTMLAttributes<HTMLElement>) {
        super(props)
        this.state = {
            chartOption: {},
        }
    }

    loadBarChartData = () => {
        return {
            tooltip: {
                formatter: this.props.tooltipFormatter,
                trigger: 'axis',
                axisPointer: {
                    type: 'none',
                },
                confine: true,
                padding: [5, 10],
                ...tooltipStyle,
            },
            grid: {
                left: 10,
                top: this.CHART_TOP_SPACING,
                height: this.props.chartHeight,
            },
            xAxis: {
                boundaryGap: false,
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: {
                    color: '#A4AFB2',
                    lineHeight: 15,
                },
                type: 'category',
                data: this.props.axisData,
                show: false,
            },
            yAxis: {
                show: false,
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: {
                    color: '#A4AFB2',
                },
                splitLine: {
                    lineStyle: {
                        color: 'rgba(164, 175, 178, 0.3)',
                    },
                },
            },
            series: this.props.series,
            textStyle: {
                fontFamily: 'Roboto',
            },
            color: this.props.theme.colors.chartRotatingColors,
        }
    }

    getDataLength = () => {
        if (this.props.series.length) {
            return this.props.series[0].data && this.props.series[0].data.length
        }
        return this.props.series.length
    }

    render() {
        return (
            <ReactEcharts
                option={this.loadBarChartData()}
                style={{
                    height:
                        this.props.chartHeight && this.getDataLength()
                            ? this.props.chartHeight + this.CHART_TOP_SPACING
                            : '60px',
                    ...this.props.style,
                }}
                lazyUpdate={false}
                onChartReady={() => {}}
                notMerge={true}
            />
        )
    }
}

export default withTheme(Chart)
