import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AppServices } from 'middleware'
import { replaceMessage } from 'uiComponents/messages/actions'
import { createQueryResponseHandling } from 'utils/errorHandler'
import { DiscountCodeBEForm } from './bookingCodes/types'

export const bookingCodeApi = createApi({
    reducerPath: 'codesApi',
    tagTypes: ['listDiscountPools', 'listDiscountCodes', 'getDiscountUpload', 'getDiscountCode'],
    baseQuery: fetchBaseQuery({}),
    endpoints: (builder) => ({}),
})

const extendedbookingCodeApi = bookingCodeApi.injectEndpoints({
    endpoints: (builder) => ({
        listDiscountPools: builder.query({
            providesTags: ['listDiscountPools'],
            queryFn: async ({ accountSlug, query }: { accountSlug: string; query?: string }, api) => {
                try {
                    const data = await AppServices.bookingCodesService.getDiscountPools({ accountSlug, query })
                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('listDiscountPools', 'error', 'Failed to get discount pools', 5000))

                    return { error }
                }
            },
        }),
        listDiscountCodes: builder.query({
            providesTags: ['listDiscountCodes'],
            queryFn: async ({ accountSlug, query }: { accountSlug: string; query?: string }, api) => {
                try {
                    const data = await AppServices.bookingCodesService.getDiscountCodes({ accountSlug, query })

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('listDiscountCodes', 'error', 'Failed to get discount codes', 5000))

                    return { error }
                }
            },
        }),
        listDiscountCodesCursorPaginated: builder.query({
            providesTags: ['listDiscountCodes'],
            queryFn: async ({ accountSlug, query }: { accountSlug: string; query?: string }, api) => {
                try {
                    const data = await AppServices.bookingCodesService.getDiscountCodesCursorPaginated({
                        accountSlug,
                        query,
                    })

                    return { data }
                } catch (error) {
                    api.dispatch(replaceMessage('listDiscountCodes', 'error', 'Failed to get discount codes', 5000))

                    return { error }
                }
            },
        }),
        addPool: builder.mutation({
            invalidatesTags: ['listDiscountPools'],
            queryFn: async ({ accountSlug, name }: { accountSlug: string; name: string }) => {
                try {
                    const data = await AppServices.bookingCodesService.addDiscountPool({ accountSlug, name })

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        addCodeByForm: builder.mutation({
            invalidatesTags: ['listDiscountPools'],
            queryFn: async ({
                accountSlug,
                formData,
                uuid,
            }: {
                accountSlug: string
                uuid: string
                formData: DiscountCodeBEForm
            }) => {
                try {
                    const data = await AppServices.bookingCodesService.addCodeByForm({ accountSlug, formData, uuid })

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        uploadDiscountCode: builder.mutation({
            invalidatesTags: ['listDiscountPools'],
            queryFn: async ({ accountSlug, file, uuid }: { accountSlug: string; uuid: string; file: File }) => {
                return createQueryResponseHandling(() =>
                    AppServices.bookingCodesService.uploadDiscountCode({
                        accountSlug,
                        file,
                        uuid,
                    }),
                )
            },
        }),
        deleteDiscountPool: builder.mutation({
            invalidatesTags: ['listDiscountPools'],
            queryFn: async ({ accountSlug, uuid }: { accountSlug: string; uuid: string }) => {
                try {
                    const data = await AppServices.bookingCodesService.deleteDiscountPool({
                        accountSlug,
                        uuid,
                    })

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        editDiscountPool: builder.mutation({
            invalidatesTags: ['listDiscountPools'],
            queryFn: async ({ accountSlug, uuid, name }: { accountSlug: string; uuid: string; name: string }) => {
                try {
                    const data = await AppServices.bookingCodesService.editDiscountPool({
                        accountSlug,
                        uuid,
                        name,
                    })

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        getDiscountUploadInfo: builder.query({
            providesTags: ['getDiscountUpload'],
            extraOptions: {},
            queryFn: async ({ accountSlug, id }: { accountSlug: string; id: string }) => {
                try {
                    const data = await AppServices.bookingCodesService.getDiscountUploadDetails({
                        accountSlug,
                        id,
                    })

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        getDiscountCodeDetails: builder.query({
            providesTags: (_, __, { codeUuid }) => [{ type: 'getDiscountCode', id: codeUuid }],
            queryFn: async ({
                accountSlug,
                poolUuid,
                codeUuid,
            }: {
                accountSlug: string
                poolUuid: string
                codeUuid: string
            }) => {
                try {
                    const data = await AppServices.bookingCodesService.getDiscountCodeDetails({
                        accountSlug,
                        poolUuid,
                        codeUuid,
                    })

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        editDiscountCode: builder.mutation({
            invalidatesTags: (_, __, { codeUuid }) => ['listDiscountPools', { type: 'getDiscountCode', id: codeUuid }],
            queryFn: async ({
                accountSlug,
                poolUuid,
                codeUuid,
                data,
            }: {
                accountSlug: string
                poolUuid: string
                codeUuid: string
                data: DiscountCodeBEForm
            }) => {
                try {
                    const response = await AppServices.bookingCodesService.editDiscountCode({
                        accountSlug,
                        poolUuid,
                        codeUuid,
                        data,
                    })

                    return { data: response }
                } catch (error) {
                    return { error }
                }
            },
        }),
        redeemUnusedDiscountCodesFromUploads: builder.mutation({
            invalidatesTags: ['listDiscountPools'],
            queryFn: async ({ accountSlug, uuid }: { accountSlug: string; uuid: string }) => {
                try {
                    const data = await AppServices.bookingCodesService.redeemUnusedDiscountCodesFromUploads({
                        accountSlug,
                        uuid,
                    })

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        redeemUnusedDiscountCodesFromCode: builder.mutation({
            invalidatesTags: (_, __, { uuid }) => ['listDiscountPools', { type: 'getDiscountCode', id: uuid }],
            queryFn: async ({ accountSlug, uuid }: { accountSlug: string; uuid: string }) => {
                try {
                    const data = await AppServices.bookingCodesService.redeemUnusedDiscountCodesFromCode({
                        accountSlug,
                        uuid,
                    })

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
    }),
})

export const { reducer, reducerPath } = bookingCodeApi
export const {
    useLazyListDiscountPoolsQuery,
    useAddPoolMutation,
    useLazyListDiscountCodesQuery,
    useLazyListDiscountCodesCursorPaginatedQuery,
    useAddCodeByFormMutation,
    useUploadDiscountCodeMutation,
    useDeleteDiscountPoolMutation,
    useEditDiscountPoolMutation,
    useGetDiscountCodeDetailsQuery,
    useEditDiscountCodeMutation,
    useRedeemUnusedDiscountCodesFromUploadsMutation,
    useRedeemUnusedDiscountCodesFromCodeMutation,
    endpoints,
} = extendedbookingCodeApi
