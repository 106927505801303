import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { faTrash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { usePublishCampaignMutation, useUnpublishCampaignMutation } from 'engageTools/reduxApi'
import { ConfigurationVersions, ProductName, PublishDraftErrorContext, ResponseError } from 'engageTools/studio/schema'
import { BaseRouteParams } from 'hocs'
import ActionButton from 'uiComponents/buttons'
import { Toggle } from 'uiComponents/input'
import { ReactTable, ReactTableCellProps } from 'uiComponents/table/reactTable'
import { getStatus } from '../cells/utils'
import { MultipleStatusIndicator, StatusName } from 'uiComponents/table'
import { Body } from 'uiComponents/typography'
import styled from 'styled-components'
import InlineMessage from 'uiComponents/messages/inlineMessage'

const Toggler = styled(Toggle)`
    font-size: 0.775em;
`

interface DuplicatesTableProps {
    data: ConfigurationVersions[]
    type: ProductName
    onDelete(config: ConfigurationVersions): void
}

interface ErrorMessageProps {
    publishError: ResponseError<PublishDraftErrorContext>['error'] | string
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ publishError }) => {
    const detailedError = publishError as ResponseError<PublishDraftErrorContext>['error']
    if (!publishError as any) {
        return null
    }

    if (detailedError && typeof detailedError === 'object') {
        return (
            <>
                <div>{detailedError.message}</div>
                <ul>
                    {detailedError?.context?.rules?.map((rule) => {
                        return (
                            <li key={rule.url}>
                                {rule.type} - {rule.url}
                            </li>
                        )
                    })}
                </ul>
            </>
        )
    }

    return <>{publishError}</>
}

const DuplicatesTable: React.FC<DuplicatesTableProps> = ({ data, type, onDelete }) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const [publish] = usePublishCampaignMutation()
    const [unpulish] = useUnpublishCampaignMutation()
    const [publishError, setPublishError] = useState<ResponseError<PublishDraftErrorContext>['error'] | string>()

    return (
        <>
            {!!publishError && (
                <div style={{ padding: '1rem' }}>
                    <InlineMessage type="error" header="Publish error" onClose={() => setPublishError(undefined)}>
                        <ErrorMessage publishError={publishError} />
                    </InlineMessage>
                </div>
            )}
            <ReactTable
                elevation={false}
                size="small"
                columns={[
                    {
                        accessor: 'status',
                        width: '3em',
                        Header: 'Name',
                        style: { paddingRight: 0 },
                        Cell: ({ row }: ReactTableCellProps<ConfigurationVersions>) => {
                            const status = getStatus(row.original)

                            return <MultipleStatusIndicator status={status.status as StatusName} />
                        },
                    },
                    {
                        accessor: 'name',
                        style: { paddingLeft: 0 },
                        Cell: ({ row }: ReactTableCellProps<ConfigurationVersions>) => {
                            return (
                                <>
                                    <Body weight="light" style={{ paddingLeft: 4 }} size={1}>
                                        {row.original.name}
                                    </Body>
                                </>
                            )
                        },
                    },
                    {
                        accessor: 'published',
                        disableSortBy: true,
                        width: '6rem',
                        Cell: ({ row }: ReactTableCellProps<ConfigurationVersions>) => {
                            const status = getStatus(row.original)

                            return (
                                <Toggler
                                    isOn={status.publishState}
                                    onClick={async () => {
                                        setPublishError(undefined)
                                        if (status.publishState) {
                                            unpulish({
                                                accountSlug,
                                                type,
                                                id: row.original.id as string,
                                            }).catch((err) => {
                                                setPublishError('Something wrong happened.')
                                            })
                                        } else {
                                            publish({
                                                accountSlug,
                                                type,
                                                id: row.original.id as string,
                                                tool: row.original,
                                            }).then((response: any) => {
                                                if (response?.error) {
                                                    setPublishError(
                                                        response?.error?.error ||
                                                            response?.error?.message ||
                                                            'Something wrong happened.',
                                                    )
                                                }
                                            })
                                        }
                                    }}
                                />
                            )
                        },
                    },
                    {
                        accessor: 'createdOn',
                        Header: 'Valid date',
                        disableSortBy: true,
                        Cell: ({ row }: ReactTableCellProps<ConfigurationVersions>) => {
                            if (!!row.original.validFrom && !!row.original.validTo) {
                                return `${row.original.validFrom} - ${row.original.validTo}`
                            }

                            return '-'
                        },
                        width: '12.125rem',
                    },
                    {
                        accessor: 'actions',
                        Header: 'Actions',
                        disableSortBy: true,
                        width: '9rem',
                        Cell: ({ row }: ReactTableCellProps<ConfigurationVersions>) => (
                            <>
                                <Link
                                    to={`/account/${accountSlug}/engage/tools/${type}/${
                                        row.original.id as string
                                    }/style?device=${row.original.deviceType as string}&from=${type}`}
                                >
                                    <ActionButton style={{ marginRight: '.5rem' }} kind="action" secondary>
                                        Edit
                                    </ActionButton>
                                </Link>
                                <ActionButton kind="action" secondary onClick={() => onDelete(row.original)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </ActionButton>
                            </>
                        ),
                    },
                ]}
                data={data as any}
            />
        </>
    )
}

export default DuplicatesTable
