import * as yup from 'yup'

export const poiValidationSchema = yup.object().shape({
    status: yup.string().required('Required'),
    lat: yup.number().required('Required'),
    lng: yup.number().required('Required'),
    content: yup.array(
        yup.object({
            locale: yup.string().required('Required'),
            name: yup.string().required('Required'),
        }),
    ),
})
