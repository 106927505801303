import React, { useContext, useState } from 'react'
import { BaseRouteParams, withNavigation, WithNavigationProps } from 'hocs'
import { ModalDialog } from 'uiComponents/popups/modal'
import { delay } from 'utils'
import { IOrder, OrdersListRequestPayload } from 'orders/schema'
import { OrdersServiceContext } from 'orders/context'
import { useLazyGetListOrdersQuery } from 'orders/reduxQueries'
import { addMessage, removeMessage } from 'uiComponents/messages/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getListOrderData } from 'orders/orders/redux'
import { generateOnRefundErrorMessage } from 'orders/utils/orderRefundErrorMessage'
import { getLastPayment } from 'orders/orders/utils'
import { Button } from '@mui/material'
import { ReplySolid, TimesSolid } from '@convious/icons'
import RefundDialog from './refundDialog'

interface RefundActionProps extends WithNavigationProps<BaseRouteParams> {
    order: IOrder
    cancellation?: boolean
}

const SingleOrderCancelRefund: React.FC<RefundActionProps> = ({ order, match, cancellation = false }) => {
    const [open, setOpen] = useState(false)
    const ordersService = useContext(OrdersServiceContext)
    const lastReq = useSelector(getListOrderData)
    const [listOrders, status] = useLazyGetListOrdersQuery()
    const dispatch = useDispatch()
    const { accountSlug } = match.params
    const [refundDialogHeight, setDialogHeight] = useState(100)
    const nonRefundableStatuses = ['refunded', 'refunding', 'pending']
    const lastPayment = getLastPayment(order.payments)
    const showRefundOption =
        lastPayment &&
        !nonRefundableStatuses.includes(lastPayment.paymentStatus) &&
        Number(order.totalPriceInclTax) > 0 &&
        lastPayment.paymentProvider !== 'Settled externally'
    const showCancelOption =
        order.status !== 'cancelled' && !['refunded', 'refunding'].includes(lastPayment?.paymentStatus ?? '')

    const onRefund = async (_cancellation: boolean, e: Error | undefined = undefined) => {
        setOpen(false)
        if (e) {
            dispatch(addMessage('unknown_error', 'error', generateOnRefundErrorMessage(e, _cancellation, order.id)))
        } else {
            listOrders(lastReq.originalArgs as OrdersListRequestPayload)
            const message = _cancellation
                ? `Order ${order.id} cancellation request was successful`
                : `Order ${order.id} refund request was successfully placed and is being processed`
            dispatch(addMessage('refunded_successfully', 'success', message))
            await delay(5000)
            dispatch(removeMessage('refunded_successfully'))
        }
    }

    if ((!cancellation && !showRefundOption) || (cancellation && !showCancelOption)) {
        return null
    }

    return (
        <>
            {open && (
                <ModalDialog
                    onDismiss={() => setOpen(false)}
                    interactive
                    fromTop="10%"
                    dynamicHeight={refundDialogHeight}
                >
                    <RefundDialog
                        accountSlug={accountSlug}
                        orderNumber={order.id}
                        cancellation={cancellation}
                        onRefund={onRefund}
                        cancelRefund={() => setOpen(false)}
                        refunding={status.isLoading}
                        onHeightChange={(height) => setDialogHeight(height)}
                        ordersService={ordersService}
                        replaceMessages={(...args) => dispatch(addMessage(...args))}
                    />
                </ModalDialog>
            )}
            <Button
                onClick={() => setOpen(true)}
                size="medium"
                color="error"
                variant="outlined"
                startIcon={cancellation ? <TimesSolid /> : <ReplySolid />}
                data-testid={cancellation ? 'order-cancel-order-button' : 'orders-refund-order-button'}
                className="action-button"
            >
                {cancellation ? 'Cancel' : 'Refund'}
            </Button>
        </>
    )
}

export default withNavigation(SingleOrderCancelRefund)
