import styleIcon from 'uiComponents/studio/icons/style.svg'
import copyIcon from 'uiComponents/studio/icons/copy.svg'
import restrictionsIcon from 'uiComponents/studio/icons/target.svg'
import settingsIcon from 'uiComponents/studio/icons/settings.svg'
import { SectionObject } from 'uiComponents/studio/menuItem'
import { fontOptions } from '../shared'

export interface FormDefinitionSchema {
    text: SectionObject
    style: SectionObject
    targeting: SectionObject
    settings: SectionObject
}

export const ModalWindowFormDefinition: FormDefinitionSchema = {
    text: {
        name: 'text',
        label: '2. Text',
        header: 'Create your Message',
        icon: copyIcon,
        description: 'Create and edit your Pop Up text and all corresponding translations.',
        sections: [
            {
                name: 'Languages',
                fields: [],
            },
        ],
    },
    style: {
        name: 'style',
        label: '1. Style',
        header: 'Design your Look & Feel',
        icon: styleIcon,
        description:
            "To start, we use a default style and default colors for your Pop Up, but you can customize them below to match your website's look and feel.",
        sections: [
            {
                name: 'General',
                fields: [
                    {
                        name: 'header-logo',
                        type: 'upload',
                        label: 'Logo',
                        span: 12,
                        path: 'styling.modal.headerLogo',
                        defaultValue: 'true',
                    },
                    {
                        name: 'corners',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'Rounded',
                                value: 'rounded',
                            },
                            {
                                name: 'Square',
                                value: 'square',
                            },
                        ],
                        label: 'Corners',
                        span: 8,
                        path: 'styling.modal.cornersRounded',
                        defaultValue: 'square',
                    },
                    {
                        name: 'font-family',
                        type: 'select',
                        availableOptions: [...fontOptions],
                        label: 'Font-family',
                        span: 8,
                        path: 'styling.fontFamily',
                        defaultValue: 'Roboto',
                    },
                    {
                        name: 'header-color',
                        type: 'picker',
                        label: 'Header color',
                        span: 6,
                        path: 'styling.modal.headerBGColor',
                        defaultValue: '',
                    },
                    {
                        name: 'overlay-color',
                        type: 'picker',
                        label: 'Overlay color',
                        span: 6,
                        path: 'styling.modal.overlayColor',
                        defaultValue: '#000000',
                    },
                ],
            },
            {
                name: 'Title',
                fields: [
                    {
                        name: 'text-size',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'Small',
                                value: 'small',
                            },
                            {
                                name: 'Medium',
                                value: 'medium',
                            },
                            {
                                name: 'Large',
                                value: 'large',
                            },
                        ],
                        label: 'Text size',
                        span: 8,
                        path: 'styling.title.size',
                        defaultValue: 'medium',
                    },
                    {
                        name: 'text-color',
                        type: 'picker',
                        label: 'Text color',
                        span: 6,
                        path: 'styling.title.fontColor',
                        defaultValue: '#999999',
                    },
                ],
            },
            {
                name: 'Paragraph',
                fields: [
                    {
                        name: 'text-size',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'Small',
                                value: 'small',
                            },
                            {
                                name: 'Medium',
                                value: 'medium',
                            },
                            {
                                name: 'Large',
                                value: 'large',
                            },
                        ],
                        label: 'Text size',
                        span: 8,
                        path: 'styling.paragraph.size',
                        defaultValue: 'medium',
                    },
                    {
                        name: 'text-color',
                        type: 'picker',
                        label: 'Text color',
                        span: 6,
                        path: 'styling.paragraph.fontColor',
                        defaultValue: '#999999',
                    },
                ],
            },
            {
                name: 'Icon',
                fields: [
                    {
                        name: 'show-icon',
                        type: 'toggle',
                        label: 'Show icon',
                        path: 'hideIconButton',
                        defaultValue: false,
                        infotip: 'When disabled, the icon will not be shown.',
                        span: 6,
                    },
                    {
                        name: 'icon-url',
                        type: 'upload',
                        label: '',
                        span: 12,
                        path: 'styling.iconButton.iconSource',
                        defaultValue: '',
                    },
                    {
                        name: 'background-color',
                        type: 'picker',
                        label: 'Background color',
                        span: 6,
                        path: 'styling.iconButton.bgColor',
                        defaultValue: '#DDDDDD',
                    },
                ],
            },
            {
                name: 'Main button',
                fields: [
                    {
                        name: 'show-button',
                        type: 'toggle',
                        label: 'Show proceed button',
                        path: 'hideProceedButton',
                        defaultValue: false,
                        infotip: 'When disabled, the proceed button will not be shown.',
                        span: 6,
                    },
                    {
                        name: 'button-size',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'Small',
                                value: 'small',
                            },
                            {
                                name: 'Medium',
                                value: 'medium',
                            },
                            {
                                name: 'Large',
                                value: 'large',
                            },
                        ],
                        label: 'Button size',
                        span: 8,
                        path: 'styling.proceedButton.size',
                        defaultValue: 'medium',
                    },
                    {
                        name: 'background-color',
                        type: 'picker',
                        label: 'Background color',
                        span: 6,
                        path: 'styling.proceedButton.bgColor',
                        defaultValue: '#FFFF00',
                    },
                    {
                        name: 'text-color',
                        type: 'picker',
                        label: 'Text color',
                        span: 6,
                        path: 'styling.proceedButton.fontColor',
                        defaultValue: '#999999',
                    },
                ],
            },
            {
                name: 'Dismiss text',
                fields: [
                    {
                        name: 'dismiss-text',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'Small',
                                value: 'small',
                            },
                            {
                                name: 'Medium',
                                value: 'medium',
                            },
                            {
                                name: 'Large',
                                value: 'large',
                            },
                        ],
                        label: 'Text size',
                        span: 8,
                        path: 'styling.dismissButton.size',
                        defaultValue: 'medium',
                    },
                    {
                        name: 'dismiss-text-color',
                        type: 'picker',
                        label: 'Text color',
                        span: 6,
                        path: 'styling.dismissButton.fontColor',
                        defaultValue: '#999999',
                    },
                ],
            },
            {
                name: "Dismiss icon 'x'",
                fields: [
                    {
                        name: 'icon-size',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'Small',
                                value: 'small',
                            },
                            {
                                name: 'Medium',
                                value: 'medium',
                            },
                            {
                                name: 'Large',
                                value: 'large',
                            },
                        ],
                        label: 'Icon size',
                        span: 8,
                        path: 'styling.xButton.size',
                        defaultValue: 'small',
                    },
                    {
                        name: 'icon-color',
                        type: 'picker',
                        label: 'Icon color',
                        span: 6,
                        path: 'styling.xButton.fontColor',
                        defaultValue: '#FFFFFF',
                    },
                ],
            },
        ],
    },
    targeting: {
        name: 'targeting',
        label: '4. Targeting',
        header: 'Set your Restrictions',
        icon: restrictionsIcon,
        description: 'Choose the pages you want your Pop Up to be shown and/or excluded.',
        sections: [
            {
                name: 'Audience',
                fields: [],
            },
            {
                name: 'Display on',
                infotip: 'Decide on which devices you want to show your Pop Up.',
                fields: [
                    {
                        name: 'display',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'all devices',
                                value: 'all',
                            },
                            {
                                name: 'only mobile/tablet',
                                value: 'mobile',
                            },
                            {
                                name: 'only desktop',
                                value: 'desktop',
                            },
                        ],
                        label: '',
                        span: 12,
                        path: 'target',
                        defaultValue: 'all',
                    },
                ],
            },
            {
                name: 'Blacklist',
                label: 'Hide your Pop Up on (optional)',
                infotip: 'Define which pages of your website will not show the Pop Up.',
                fields: [],
            },
        ],
    },
    settings: {
        name: 'settings',
        label: '3. Settings',
        header: 'Set your Visibility Rules',
        icon: settingsIcon,
        description: 'Define and apply simple rules on how your Pop Up displays to your audience.',
        sections: [
            {
                name: 'Display rules',
                fields: [
                    {
                        name: 'display-trigger',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'After time spent',
                                value: 'afterDelay',
                            },
                            {
                                name: 'On specific URL',
                                value: 'onSpecificUrl',
                            },
                            {
                                name: 'On page load',
                                value: 'onPageLoad',
                            },
                            {
                                name: 'On exit intent',
                                value: 'exitIntent',
                            },
                            {
                                name: 'On 50% scroll down',
                                value: 'scrollDown50',
                            },
                            {
                                name: 'On scroll up',
                                value: 'scrollUp',
                            },
                        ],
                        label: 'Display',
                        span: 8,
                        path: 'trigger',
                        defaultValue: 'afterDelay',
                    },
                    {
                        name: 'show-after',
                        type: 'number',
                        min: 0,
                        max: 120,
                        unit: 'sec',
                        label: 'Show after',
                        span: 6,
                        path: 'displayDelayPeriod',
                        defaultValue: 5,
                    },
                    {
                        name: 'minimum-stay',
                        type: 'number',
                        min: 0,
                        max: 500,
                        unit: 'sec',
                        label: 'Minimum stay',
                        span: 6,
                        path: 'minStayOnSite',
                        defaultValue: 180,
                        infotip: 'Minimum time spent on the website before displaying the Pop Up.',
                    },
                    {
                        name: 'user-converted-timeout',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'None',
                                value: 0,
                            },
                            {
                                name: '1 day',
                                value: 86400,
                            },
                            {
                                name: '2 days',
                                value: 172800,
                            },
                            {
                                name: '3 days',
                                value: 259200,
                            },
                            {
                                name: '4 days',
                                value: 345600,
                            },
                            {
                                name: '5 days',
                                value: 432000,
                            },
                            {
                                name: '6 days',
                                value: 518400,
                            },
                            {
                                name: '7 days',
                                value: 604800,
                            },
                            {
                                name: '14 days',
                                value: 1209600,
                            },
                            {
                                name: '30 days',
                                value: 2592000,
                            },
                        ],
                        label: 'Silence after purchase',
                        span: 8,
                        path: 'timeoutAfterUserConverted',
                        defaultValue: '1 day',
                        infotip: 'After a visitor has completed a purchase, the Pop Up doesn’t show again for:',
                    },
                    {
                        name: 'cta-clicked-timeout',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'None',
                                value: 0,
                            },
                            {
                                name: '5 minutes',
                                value: 300,
                            },
                            {
                                name: '10 minutes',
                                value: 600,
                            },
                            {
                                name: '15 minutes',
                                value: 900,
                            },
                            {
                                name: '30 minutes',
                                value: 1800,
                            },
                            {
                                name: '1 hour',
                                value: 3600,
                            },
                            {
                                name: '6 hours',
                                value: 21600,
                            },
                            {
                                name: '12 hours',
                                value: 43200,
                            },
                            {
                                name: '24 hours',
                                value: 86400,
                            },
                            {
                                name: '48 hours',
                                value: 172800,
                            },
                        ],
                        label: 'Silence after click',
                        span: 8,
                        path: 'timeoutAfterCTAClicked',
                        defaultValue: 1800,
                        infotip: 'After a visitor has clicked on the main button, the Pop Up doesn’t show again for:',
                    },
                    {
                        name: 'modal-closed-timeout',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'None',
                                value: 0,
                            },
                            {
                                name: '5 minutes',
                                value: 300,
                            },
                            {
                                name: '10 minutes',
                                value: 600,
                            },
                            {
                                name: '15 minutes',
                                value: 900,
                            },
                            {
                                name: '30 minutes',
                                value: 1800,
                            },
                            {
                                name: '1 hour',
                                value: 3600,
                            },
                            {
                                name: '6 hours',
                                value: 21600,
                            },
                            {
                                name: '12 hours',
                                value: 43200,
                            },
                            {
                                name: '24 hours',
                                value: 86400,
                            },
                            {
                                name: '48 hours',
                                value: 172800,
                            },
                        ],
                        label: 'Silence after close',
                        span: 8,
                        path: 'timeoutAfterModalClosed',
                        defaultValue: 1800,
                        infotip: 'After a visitor has dismissed the Pop Up, it doesn’t show again for:',
                    },
                    {
                        name: 'outside-dismiss',
                        type: 'toggle',
                        label: 'Enable outside-click',
                        span: 7,
                        path: 'dismissOnOutsideClick',
                        defaultValue: true,
                        infotip: 'When enabled, the viewer can close or dismiss the Pop Up by clicking outside of it.',
                    },
                ],
            },
            {
                name: 'Frequency limit',
                fields: [],
            },
        ],
    },
}
