import * as React from 'react'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation, QueryData } from 'navigation'
import { History } from 'history'
import { MessageKind } from 'uiComponents/messages'
import {
    BookingCodesService,
    ReservationCodesPool,
    GenerateReservationCodespayload,
    RemoveUnusedCodesItem,
} from 'venue/bookingCodes/bookingCodesService'
import { RetailerResponseItem } from 'channels/channelsService'
import { LoginService } from 'http/loginService'
import { SearchSection } from './searchSection'
import { Sorting, Pagination } from 'uiComponents/table'
import { TableLoader } from 'uiComponents/loaders'
import { PaginationSection } from 'uiComponents/table/pagination'
import { SingleSelectOption } from 'uiComponents/input'
import { AddNewPoolDialog } from './addNewPoolDialog'
import { GenerateCodesDialog } from './generateCodesDialog'
import { RemovedUnusedCodesDialog } from './removeUnusedCodesDialog'
import { delay } from 'utils'
import { ChannelsServiceContext } from 'channels/context'
import ResCodePoolsTable from './poolsTable'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import cloneDeep from 'lodash/cloneDeep'
import EditPoolDialog from './editPoolDialog'

interface ReservationCodePoolsPageProps {
    accountSlug: string
    bookingCodesService: BookingCodesService
    loginService: LoginService
    navigation: Navigation
    match: RouteMatch<{}>
    backofficeEndpoint: string
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    history: History
}

function ReservationCodePoolsPage(props: ReservationCodePoolsPageProps) {
    const _initLoadDone = React.useRef(false)
    const channelsService = React.useContext(ChannelsServiceContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [loadingMetadata, setLoadingMetadata] = React.useState<boolean>(false)
    const [loadingPoolDetails, setLoadingPoolDetails] = React.useState<boolean>(false)
    const [updating, setUpdating] = React.useState<boolean>(false)
    const [creating, setCreating] = React.useState<boolean>(false)
    const [poolToDelete, setPoolToDelete] = React.useState<string>('')
    const [batchToDelete, setBatchToDelete] = React.useState<string>('')
    const [poolToGenerate, setPoolToGenerate] = React.useState<string>('')
    const [itemToRemoveUnused, setItemToRemoveUnused] = React.useState<RemoveUnusedCodesItem | null>(null)
    const [resCodePoolsList, setResCodePoolsList] = React.useState<ReservationCodesPool[]>([])
    const [resCodePoolDetailsList, setResCodePoolDetailsList] = React.useState<ReservationCodesPool[]>([])
    const [sort, setSort] = React.useState<Sorting>({
        prop: 'name',
        direction: 'asc',
    })
    const [pagination, setPagination] = React.useState<Pagination>({
        page: 1,
        pageSize: 10,
    })
    const [totalPools, setTotalPools] = React.useState<number>(0)
    const [retailersList, setRetailersList] = React.useState<RetailerResponseItem[]>([])
    const [retailerOptions, setRetailerOptions] = React.useState<SingleSelectOption[]>([])
    const [currentExpanded, setCurrentExpanded] = React.useState<string>('')
    const [poolBatchPending, setPoolBatchPending] = React.useState<string>('')

    function getFormattedStringFromQuery(queryData: QueryData) {
        const pageQuery = `page=${queryData.page || 1}&page_size=${queryData.pageSize || 10}`
        const sortQuery = `sort_by=${queryData.sortBy || 'name'}&sort_direction=${queryData.sortDirection || 'asc'}`
        const searchQuery = queryData.q ? `&search=${queryData.q}` : ''
        return `?${pageQuery}&${sortQuery}${searchQuery}`
    }

    async function scrollToPool() {
        await delay(1500)
        const hash = location.hash
        if (hash) {
            const element = document.getElementById(hash.slice(1))
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }

    async function getResCodePoolsList() {
        const query = props.navigation.query()
        const queryString = getFormattedStringFromQuery(props.navigation.query())
        const data = await props.bookingCodesService.getReservationCodePoolsList(props.accountSlug, queryString)
        setTotalPools(data.totalCount)
        setPagination({ page: data.currentPage, pageSize: data.pageSize })
        setSort({ prop: query.sortBy, direction: query.sortDirection } as Sorting)
        setResCodePoolsList(data.entries)
    }

    const updateLoaders = (action: 'load' | 'update' | null, state: boolean) => {
        if (action === 'load') {
            setLoading(state)
            _initLoadDone.current = !state
        } else if (action === 'update') {
            setUpdating(state)
        }
    }

    async function getListData(action: 'load' | 'update' | null = null) {
        updateLoaders(action, true)
        try {
            await getResCodePoolsList()
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            updateLoaders(action, false)
        }
    }

    async function getPoolDetailsCall(id: string) {
        const poolDetails = await props.bookingCodesService.getReservationCodePoolDetails(props.accountSlug, id)
        const detailsListCopy = cloneDeep(resCodePoolDetailsList)
        const updatedDetailsList = detailsListCopy.filter((p) => p.id !== id)
        updatedDetailsList.push(poolDetails)
        setResCodePoolDetailsList(updatedDetailsList)
        poolDetails.importRequests?.find((p) => p.status === 'pending')
            ? setPoolBatchPending(id)
            : setPoolBatchPending('')
    }

    async function getPoolDetails(id: string) {
        setLoadingPoolDetails(true)
        try {
            await getPoolDetailsCall(id)
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            setLoadingPoolDetails(false)
        }
    }

    async function getMetadata() {
        setLoadingMetadata(true)
        try {
            const query = '?include_archived=true'
            const retailers = await channelsService.getRetailersList(props.accountSlug, query)
            const retailerOpts = retailers.filter((r) => !r.archived).map((r) => ({ name: r.name, value: r.id }))
            setRetailerOptions(retailerOpts)
            setRetailersList(retailers)
        } catch {
            props.replaceMessages('server_error', 'error', 'Could not fetch the categories. Please try again later.')
        } finally {
            setLoadingMetadata(false)
        }
    }

    React.useEffect(() => {
        getMetadata()
        getListData('load')
    }, [props.accountSlug])

    React.useEffect(() => {
        if (_initLoadDone.current) {
            getListData('update')
        }
    }, [
        props.navigation.query().q,
        props.navigation.query().page,
        props.navigation.query().pageSize,
        props.navigation.query().sortBy,
        props.navigation.query().sortDirection,
    ])

    React.useEffect(() => {
        scrollToPool()
    }, [resCodePoolsList])

    React.useEffect(() => {
        const id = props.navigation.query().expandedPool
        setCurrentExpanded(id || '')
        if (!id) {
            setPoolBatchPending('')
            return
        }
        const noPoolDetails = !resCodePoolDetailsList.find((p) => p.id === id)
        const hasPendingBatch = !!resCodePoolDetailsList.find(
            (p) => p.id === id && !!p.importRequests?.find((r) => r.status === 'pending'),
        )
        if (noPoolDetails || hasPendingBatch) {
            getPoolDetails(id)
            setPoolBatchPending(currentExpanded)
        }
    }, [props.navigation.query().expandedPool])

    React.useEffect(() => {
        const interval = setInterval(() => {
            getPoolDetailsCall(currentExpanded)
        }, 30000)
        if (!poolBatchPending) {
            clearInterval(interval)
        }
        return () => clearInterval(interval)
    }, [poolBatchPending])

    const onPaginationChanged = (newPagination: Pagination) => {
        props.navigation.addQueryWithReplace({
            page: newPagination.page.toString(),
            pageSize: newPagination.pageSize.toString(),
        })
    }

    const onSaveNewPool = async (name: string, retailer: string, isScannable: boolean) => {
        updateLoaders('update', true)
        try {
            await props.bookingCodesService.createReservationCodesPool(props.accountSlug, name, retailer, isScannable)
            await getResCodePoolsList()
            setCreating(false)
            updateLoaders('update', false)
            props.replaceMessages('success', 'success', 'Campaign was created successfully.')
            await delay(5000)
            props.removeAllMessages()
        } catch {
            setCreating(false)
            updateLoaders('update', false)
            props.replaceMessages(
                'unknown_error',
                'error',
                'Oops! We could not create a new campaign. Please try again later.',
            )
        }
    }

    const onNameEditAccept = async (poolId: string, name: string, retailer: string) => {
        updateLoaders('update', true)
        try {
            await props.bookingCodesService.updateReservationCodesPoolName(props.accountSlug, poolId, name, retailer)
            await getResCodePoolsList()
        } catch {
            props.replaceMessages(
                'unknown_error',
                'error',
                'Oops! We could not update the name. Please try again later.',
            )
        } finally {
            updateLoaders('update', false)
        }
    }

    const onDeletePoolConfirm = async () => {
        updateLoaders('update', true)
        try {
            await props.bookingCodesService.deleteReservationPool(props.accountSlug, poolToDelete)
            await getResCodePoolsList()
        } catch {
            props.replaceMessages(
                'unknown_error',
                'error',
                'Oops! We could not delete the campaign. Please try again later.',
            )
        } finally {
            updateLoaders('update', false)
            setPoolToDelete('')
        }
    }

    const onDeleteBatchConfirm = async () => {
        updateLoaders('update', true)
        try {
            await props.bookingCodesService.deleteUploadedCodes(props.accountSlug, 'reservations', batchToDelete)
            const updatedPool = resCodePoolDetailsList.find(
                (p) => !!p.importRequests?.find((b) => b.uploadId === batchToDelete),
            )
            if (updatedPool) {
                await getPoolDetails(updatedPool.id)
            }
        } catch {
            props.replaceMessages(
                'unknown_error',
                'error',
                'Oops! We could not delete the codes batch. Please try again later.',
            )
        } finally {
            updateLoaders('update', false)
            setBatchToDelete('')
        }
    }

    const onGenerateConfirm = async (payload: GenerateReservationCodespayload) => {
        updateLoaders('update', true)
        try {
            await props.bookingCodesService.generateReservationCodes(props.accountSlug, poolToGenerate, payload)
            await getResCodePoolsList()
            await getPoolDetails(poolToGenerate)
            if (currentExpanded) {
                setPoolBatchPending(poolToGenerate)
            }
        } catch {
            props.replaceMessages(
                'unknown_error',
                'error',
                'Oops! We could not generate codes. Please try again later.',
            )
        } finally {
            updateLoaders('update', false)
            setPoolToGenerate('')
        }
    }

    const onRemoveCodesUpload = async (file: File) => {
        if (!itemToRemoveUnused) {
            return Promise.reject({ filename: file.name })
        }
        return await props.bookingCodesService.removeUnusedCodes(
            file,
            props.accountSlug,
            itemToRemoveUnused.poolId,
            itemToRemoveUnused.batchId,
        )
    }

    const onRemoveCodesSuccess = async () => {
        if (itemToRemoveUnused?.poolId) {
            await getPoolDetails(itemToRemoveUnused.poolId)
        }
        props.replaceMessages('success', 'success', 'Codes were removed successfully.')
        setItemToRemoveUnused(null)
        await delay(5000)
        props.removeAllMessages()
    }

    return (
        <>
            <EditPoolDialog
                retailerOptions={retailerOptions}
                getResCodePoolsList={getResCodePoolsList}
                bookingCodesService={props.bookingCodesService}
                accountSlug={props.accountSlug}
                updateLoaders={updateLoaders}
                replaceMessages={props.replaceMessages}
                removeAllMessages={props.removeAllMessages}
            >
                {creating && (
                    <AddNewPoolDialog
                        accountSlug={props.accountSlug}
                        loading={updating}
                        onCancel={() => setCreating(false)}
                        onConfirm={onSaveNewPool}
                        retailerOptions={retailerOptions}
                    />
                )}
                {poolToGenerate && (
                    <GenerateCodesDialog
                        accountSlug={props.accountSlug}
                        loading={updating}
                        onCancel={() => setPoolToGenerate('')}
                        onConfirm={onGenerateConfirm}
                    />
                )}
                {itemToRemoveUnused && (
                    <RemovedUnusedCodesDialog
                        accountSlug={props.accountSlug}
                        onCancel={() => setItemToRemoveUnused(null)}
                        onUpload={onRemoveCodesUpload}
                        onRemoveSuccess={onRemoveCodesSuccess}
                        replaceMessages={props.replaceMessages}
                        removeAllMessages={props.removeAllMessages}
                    />
                )}
                {!!poolToDelete && (
                    <ConfirmationDialog
                        title="Are you sure you want to delete this campaign from the list?"
                        text="The codes will not be removed."
                        buttonText="Delete"
                        onCancel={() => setPoolToDelete('')}
                        onConfirm={onDeletePoolConfirm}
                    />
                )}
                {!!batchToDelete && (
                    <ConfirmationDialog
                        title="Are you sure you want to remove all codes?"
                        text="All the codes, including the redeemed, will be removed."
                        buttonText="Delete"
                        onCancel={() => setBatchToDelete('')}
                        onConfirm={onDeleteBatchConfirm}
                    />
                )}
                {(loading || loadingMetadata) && <TableLoader />}
                {!loading && !loadingMetadata && (
                    <>
                        <SearchSection poolsCount={totalPools} onAddNew={() => setCreating(true)} />
                        <ResCodePoolsTable
                            accountSlug={props.accountSlug}
                            poolsList={resCodePoolsList}
                            poolDetailsList={resCodePoolDetailsList}
                            retailersList={retailersList}
                            sort={sort}
                            setSort={setSort}
                            loading={loading}
                            updating={updating}
                            creating={creating}
                            loadingPoolDetails={loadingPoolDetails}
                            onNameEditAccept={onNameEditAccept}
                            backofficeEndpoint={props.backofficeEndpoint}
                            loginService={props.loginService}
                            onDeletePool={(id) => setPoolToDelete(id)}
                            onDeleteBatch={(id) => setBatchToDelete(id)}
                            onGenerateCodes={(id) => setPoolToGenerate(id)}
                            onRemoveUnused={(item) => setItemToRemoveUnused(item)}
                            currentExpanded={currentExpanded}
                        />
                        <PaginationSection
                            pagination={pagination}
                            onPaginationChanged={onPaginationChanged}
                            totalItemsCount={totalPools}
                            pageSizeOptions={['10', '20', '50']}
                            style={{ margin: '1em' }}
                            onWhiteBackground
                        />
                    </>
                )}
            </EditPoolDialog>
        </>
    )
}

export default withNavigation(ReservationCodePoolsPage)
