import * as React from 'react'
import styled from 'styled-typed'
import { ActionButton, ButtonKind, ButtonSize } from 'uiComponents/buttons'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome'
import { delay } from 'utils'

interface MenuContainerProps {
    width?: string
    alignRight?: boolean
    leftOffset?: string
}

interface DropdownProps {
    top?: string
    bottom?: string
}

export const OnClickMenuItem = styled.div`
    font-size: 0.875rem;
    color: ${(props) => props.theme.colors.textDark};
    text-decoration: none;
    cursor: pointer;
    transition: color 0.2s ease-in;
    line-height: 2em;
    padding: 0 1em;
    font-weight: normal;

    &:hover {
        background: ${(props) => props.theme.colors.background};
    }
    > a {
        color: inherit;
        text-decoration: none;
        padding-bottom: 0.1em;
    }
`
const DropdownContainer = styled.div<DropdownProps>`
    width: max-content;
    position: absolute;
    top: ${(props) => (props.bottom ? '' : props.top || '2.3em')};
    bottom: ${(props) => props.bottom};
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-in;
    z-index: 7;

    &.visible {
        visibility: visible;
        opacity: 1;
    }
`
const MenuItemContainer = styled.div<MenuContainerProps>`
    padding: 0.4em 0;
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    white-space: initial;
    text-align: ${(props) => (props.alignRight ? 'right' : '')};
`
const OnClickMenuContainer = styled.div<MenuContainerProps>`
    width: ${(p) => (p.width ? p.width : 'auto')};
    position: relative;
    display: flex;
    justify-content: ${(props) => (props.alignRight ? 'flex-end' : 'flex-start')};
    margin-left: ${(props) => (props.leftOffset ? props.leftOffset : '')};
`
const DropdownCaret = styled(FontAwesomeIcon)`
    margin-left: 0.5em;
    font-size: 0.75rem;
`
const IconBox = styled.div`
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors.border};
    background: ${(props) => props.theme.colors.white};
    &.expanded {
        border: 1px solid ${(props) => props.theme.colors.boyBlue};
    }
`

interface OnClickMenuProps {
    children: React.ReactNode
    kind?: ButtonKind
    size?: ButtonSize
    secondary?: boolean
    title?: string
    width?: string
    expandOnHover?: boolean
    fitContent?: boolean
    customFromBottom?: string
    customFromTop?: string
    alignRight?: boolean
    style?: React.CSSProperties
    id?: string
    triggerLeftOffset?: string
    disabled?: boolean
    iconTrigger?: IconProp
}

interface OnClickMenuState {
    expanded: boolean
}

export class OnClickMenu extends React.Component<
    OnClickMenuProps & React.HTMLAttributes<HTMLElement>,
    OnClickMenuState
> {
    _isMounted = false
    menu: HTMLDivElement | null = null
    constructor(props: OnClickMenuProps) {
        super(props)
        this.state = {
            expanded: false,
        }
    }

    componentDidMount() {
        this._isMounted = true
        document.addEventListener('click', this.outsideClick, false)
    }

    componentWillUnmount() {
        this._isMounted = false
        document.removeEventListener('click', this.outsideClick, false)
    }

    outsideClick = (ev: MouseEvent) => {
        if (this.menu && this.menu.contains && !this.menu.contains(ev.target as Node) && this.state.expanded) {
            this.setState({ expanded: false })
        }
    }

    toggleExpand = () => {
        if (!this.props.disabled) {
            this.setState({ expanded: !this.state.expanded })
        }
    }

    onMouseOver = () => {
        if (this.props.expandOnHover && !this.state.expanded && this._isMounted && !this.props.disabled) {
            this.setState({ expanded: true })
        }
    }

    onMouseLeave = () => {
        if (this.props.expandOnHover && this.state.expanded && this._isMounted) {
            this.setState({ expanded: false })
        }
    }

    onMenuItemClick = async () => {
        await delay(1000)
        if (this._isMounted) {
            this.setState({ expanded: false })
        }
    }

    render() {
        const {
            children,
            kind,
            size,
            secondary,
            title,
            width,
            fitContent,
            customFromBottom,
            customFromTop,
            className,
            alignRight,
            style,
            id,
            triggerLeftOffset,
        } = this.props
        return (
            <OnClickMenuContainer
                id={id}
                width={width}
                alignRight={alignRight}
                leftOffset={triggerLeftOffset}
                className={this.state.expanded ? `expanded ${className}` : className}
                ref={(node: HTMLDivElement) => (this.menu = node)}
                style={style}
                onMouseOver={this.onMouseOver}
                onMouseLeave={this.onMouseLeave}
            >
                {!this.props.iconTrigger && (
                    <ActionButton
                        kind={kind}
                        width={fitContent ? width : undefined}
                        size={size}
                        secondary={secondary}
                        onClick={this.toggleExpand}
                    >
                        {title}
                        <DropdownCaret icon={this.state.expanded ? faCaretUp : faCaretDown} />
                    </ActionButton>
                )}
                {!!this.props.iconTrigger && (
                    <IconBox onClick={this.toggleExpand} className={this.state.expanded ? 'expanded' : ''}>
                        <FontAwesomeIcon icon={this.props.iconTrigger} />
                    </IconBox>
                )}
                <DropdownContainer
                    bottom={customFromBottom}
                    top={customFromTop}
                    className={this.state.expanded ? 'visible' : ''}
                >
                    <MenuItemContainer
                        alignRight={alignRight}
                        className={customFromBottom ? 'expand-up optionsWrapper' : 'optionsWrapper'}
                        onClick={this.onMenuItemClick}
                    >
                        {children}
                    </MenuItemContainer>
                </DropdownContainer>
            </OnClickMenuContainer>
        )
    }
}
