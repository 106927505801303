import React, { useRef, useState, useEffect, useContext } from 'react'
import styled from 'styled-typed'
import { History } from 'history'
import { TableLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { DataTable, HeaderRow, TableHeader, Cell, DataRow } from 'uiComponents/table'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { ActionButtonA } from 'uiComponents/buttons'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { CopyWithLanguage } from 'copyEditor/copyEditorService'
import { CopyEditorServiceContext } from 'copyEditor/context'
import { languagesDict, rtlLanguages } from 'utils/languages'

const RichTextDiv = styled.div`
    white-space: normal;
`
const ResultsContainer = styled.div`
    max-height: 49em;
    overflow-y: auto;
    background: ${(props) => props.theme.colors.white};
`

function formatCopyText(text: string, search: string, searchBy: string) {
    const sentences = text.split(/\n|\r|\.|\!|<br|<div>|<\/div>|<p>|<\/p>|<ul>/)
    let searchSentence =
        searchBy === 'text'
            ? sentences.find((s) => s.toLowerCase().indexOf(decodeURIComponent(search).toLowerCase()) > -1)
            : null
    searchSentence = searchSentence || sentences[0] || sentences[1]
    if (!searchSentence) {
        return <span>**Please hover over this text or click &quot;edit&quot; to see the copy text**</span>
    }
    const sentenceIndex = sentences.indexOf(searchSentence)
    const frontSentenceEmpty = sentenceIndex === 1 && !sentences[0]
    const frontMarker = sentenceIndex > 0 && !frontSentenceEmpty ? '... ' : ''
    const lastSentenceEmpty = sentenceIndex === sentences.length - 2 && !sentences[sentences.length - 1]
    const endMarker = sentenceIndex < sentences.length - 1 && !lastSentenceEmpty ? ' ...' : ''
    let visibleText = frontMarker + searchSentence.trim() + endMarker
    if (visibleText.indexOf('<script>') > -1 || visibleText.indexOf('<iframe') > -1) {
        return <span>{visibleText}</span>
    }
    if (searchBy === 'text') {
        const regex = new RegExp(search, 'gi')
        visibleText = visibleText.replace(regex, (match) => `<strong>${match}</strong>`)
    }
    return <RichTextDiv dangerouslySetInnerHTML={{ __html: visibleText }} />
}

interface CopySearchListPageProps {
    history: History
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<any>
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

function CopySearchListPage(props: CopySearchListPageProps) {
    const didMountRef = useRef(false)
    const copyEditorService = useContext(CopyEditorServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [resultsList, setResultsList] = useState<CopyWithLanguage[]>([])

    async function getSearchResults() {
        setLoading(true)
        try {
            const search = props.navigation.query().search
            const searchBy = props.navigation.query().searchBy || 'text'
            if (search && searchBy) {
                const encodedSearch = encodeURIComponent(search)
                const searchResults = await copyEditorService.getCopySearchResult(
                    props.accountSlug,
                    encodedSearch,
                    searchBy,
                )
                const mappedResults = searchResults.map((sr) => {
                    const language = languagesDict.find((l) => l.value === sr.locale)
                    return {
                        ...sr,
                        language: language ? language.name : sr.locale,
                        formatedText: formatCopyText(sr.text, search, searchBy),
                    }
                })
                setResultsList(mappedResults)
            } else {
                setResultsList([])
            }
            setLoading(false)
        } catch {
            setLoading(false)
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    useEffect(() => {
        getSearchResults()
    }, [props.navigation.query().search, props.navigation.query().searchBy, props.accountSlug])

    useEffect(() => {
        didMountRef.current ? getSearchResults() : (didMountRef.current = true)
    }, [props.accountSlug])

    const columnWidths = ['25em', null, '10em', '7em']

    return (
        <div style={{ maxWidth: '75em' }}>
            <DataTable bordered columnWidths={columnWidths}>
                <HeaderRow>
                    <TableHeader>Copy Key</TableHeader>
                    <TableHeader>Copy Text</TableHeader>
                    <TableHeader style={{ marginLeft: '-1.2em' }}>Language</TableHeader>
                    <TableHeader nonInteractive />
                </HeaderRow>
                {loading && <TableLoader />}
                {!loading && (
                    <ResultsContainer>
                        {resultsList.length === 0 && <NoResultsRow text="Enter/amend search criteria." />}
                        {resultsList.length > 0 &&
                            resultsList.map((item, i) => {
                                const rtl = rtlLanguages.includes(item.locale)
                                return (
                                    <DataRow key={i} className="search-result">
                                        <Cell
                                            title={item.name ? `${item.name} / ${item.key}` : item.key}
                                            className="withName copy-name"
                                            style={{ lineHeight: '1.5em' }}
                                        >
                                            <span>
                                                {item.name ? (
                                                    <>
                                                        {item.name}
                                                        <div>({item.key})</div>
                                                    </>
                                                ) : (
                                                    item.key
                                                )}
                                            </span>
                                        </Cell>
                                        <Cell
                                            title={item.text || '**This copy is empty**'}
                                            className={rtl ? 'rtl withName copy-text' : 'withName copy-text'}
                                        >
                                            {item.formatedText}
                                        </Cell>
                                        <Cell className="copy-language wrapContent">{item.language}</Cell>
                                        <Cell>
                                            <ActionButtonA
                                                className="copy-edit-button"
                                                kind="action"
                                                secondary
                                                href={`/account/${props.accountSlug}/engage/copy_editor/edit/${item.key}${location.search}`}
                                                style={{ marginRight: '1em' }}
                                            >
                                                Edit
                                            </ActionButtonA>
                                        </Cell>
                                    </DataRow>
                                )
                            })}
                    </ResultsContainer>
                )}
            </DataTable>
        </div>
    )
}

export default withNavigation(CopySearchListPage)
