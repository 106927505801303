import React from 'react'
import { useTheme } from 'styled-components'
import styled from 'styled-typed'
import { SingleSelect, SingleSelectOption } from 'uiComponents/input'
import { OperatorTypes } from '../types'

const Container = styled.div`
    width: 100%;
    margin: 1.2em 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const DashedSpacer = styled.div`
    flex: 1;
    border-bottom: 1px dashed ${(props) => props.theme.colors.border};
    height: 1px;
    margin-left: 4px;
`

interface AudienceOperatorProps {
    operator: string
    onSelect: (value: OperatorTypes) => void
}

const AudienceOperator = (props: AudienceOperatorProps) => {
    const theme = useTheme()

    const OperatorOptions: SingleSelectOption[] = [
        { name: 'AND', value: 'and' },
        { name: 'OR', value: 'or' },
        { name: 'NOT', value: 'not' },
    ]

    return (
        <>
            <Container>
                <SingleSelect
                    options={OperatorOptions}
                    style={{
                        border: `1px solid ${theme.colors.border}`,
                        backgroundColor: theme.colors.white,
                        width: '80px',
                    }}
                    onSelect={(value) => props.onSelect(value as OperatorTypes)}
                    selected={props.operator}
                />
                <DashedSpacer />
            </Container>
        </>
    )
}

export default AudienceOperator
