import * as React from 'react'
import { useEffect } from 'react'
import styled from '../../styled-typed'
import ActionButtons from '../components/actionButtons'
import Header from '../components/header'
import { usePushNotificationsContext } from '../context/PushNotifications'
import TargetForm from '../forms/target'
import usePNClearForms from '../hooks/usePNClearForms'

export const TemplateContainer = styled.div`
    padding: 2.5em 2.5em 5em;
    background: ${(props) => props.theme.colors.white};
    position: relative;
    border-radius: 8px;
    margin-bottom: 4em;
`
export const TemplateName = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 1.4em;
    font-weight: 500;
    margin-bottom: 0.2em;
`
export const TemplateDescription = styled.div`
    font-size: 0.8em;
    font-weight: 400;
    color: ${(props) => props.theme.colors.textLight};
`

export const TargetPageHeader = () => {
    return (
        <>
            <ActionButtons />
            <TemplateName>Define your target audience</TemplateName>
            <TemplateDescription>Use special conditions to define your target audience</TemplateDescription>
        </>
    )
}

const TargetPage = () => {
    const { targetForm, setTargetForm } = usePushNotificationsContext()
    const { clearForms } = usePNClearForms()

    useEffect(() => {
        clearForms()
    }, [])

    return (
        <div>
            <Header />
            <TemplateContainer>
                <TargetPageHeader />
                <TargetForm
                    onSubmit={(data) => {
                        setTargetForm(data)
                    }}
                    data={targetForm}
                />
            </TemplateContainer>
        </div>
    )
}

export default TargetPage
