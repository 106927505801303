import { User } from 'auth/state'
import { BaseRouteParams } from 'hocs'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { State } from 'store'

export type UserRole = 'partner_admin' | 'convious_admin' | 'user' | 'reseller' | 'unauthenticated'

const getUserActivePermissions = (user: User, accountSlug?: string | null) => {
    const accountPermissions = user?.permissions.filter((a) => a.account === accountSlug) || []

    return accountPermissions[0]?.permissions || []
}

export const getUserRole = (user?: User, accountSlug?: string | null): UserRole => {
    if (!user) {
        return 'unauthenticated'
    }

    if (user?.isAdmin) {
        return 'convious_admin'
    }

    if (user?.resellerId) {
        return 'reseller'
    }

    const permissionsList = getUserActivePermissions(user, accountSlug)

    if (permissionsList.includes('partner_admin')) {
        return 'partner_admin'
    }

    return 'user'
}

export const useGetPermission = () => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const user = useSelector((state: State) => state.auth.user)
    const activeAccount = useSelector((state: State) => state.preferences.activeAccount)

    return getUserRole(user as User, accountSlug || activeAccount)
}

export const isPartnerAdmin = (user?: User, accountSlug?: string | null): boolean => {
    return getUserRole(user, accountSlug) === 'partner_admin'
}

export const isConviousAdmin = (user?: User, accountSlug?: string | null): boolean => {
    return getUserRole(user, accountSlug) === 'convious_admin'
}
