import { useLazyGetPricesForCalendarQuery, useLazyGetProductPricingQuery } from 'products/pricing/reduxQueries'
import { useLazyGetAvailabilityQuery } from 'products/reduxApi'
import { Price, transformPricesToCalendarData } from 'products/utils'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import { State } from 'store'
import { GlobalAccountSettings } from 'products/pricing/pricingService'
import { useCreateFormatCurrencyString } from 'uiComponents/money/moneyHoc'
import { useEffect, useMemo } from 'react'
import { parseISODate } from 'utils/dates'

interface Props {
    articleId?: string
    categoryId?: string
    startAt?: string
    endAt?: string
    accountSettings?: GlobalAccountSettings
}

export const useGetCalendarData = ({ articleId, categoryId, startAt, endAt, accountSettings }: Props) => {
    const [getProductPricing, { isFetching: fetchingProductPricing }] = useLazyGetProductPricingQuery()
    const [getAvailability, { data: availabilities, isFetching: fetchingAvailability }] = useLazyGetAvailabilityQuery()
    const [getPricesForCalendar, { data: prices, isFetching: fetchingPrices }] = useLazyGetPricesForCalendarQuery()
    const user = useSelector((state: State) => state.auth.user)
    const accountSlug = useSelector((state: State) => state.preferences.activeAccount as string)
    const formatCurrencyString = useCreateFormatCurrencyString()

    const fetchData = async () => {
        if (!articleId || !categoryId || !startAt || !endAt) {
            return
        }

        const startAtDate = parseISODate(startAt)
        const endAtDate = parseISODate(endAt)
        const productPricingDetails = await getProductPricing({ ids: [articleId] }).unwrap()

        if (!productPricingDetails?.length) {
            // todo: show error notification

            return
        }

        const originalPrice = productPricingDetails[0].originalPrice
        const minAcceptedPrice = productPricingDetails[0].minAcceptedPrice
        const avgTargetPrice = productPricingDetails[0].avgTargetPrice
        const stepSize = productPricingDetails[0].stepSize
        const charmPricing = productPricingDetails[0].charmPricing

        const productData = {
            id: articleId,
            priceSettings: {
                productId: articleId,
                account: accountSlug,
                originalPrice,
                avgTargetPrice,
                minAcceptedPrice,
                stepSize,
                charmPricing,
            },
            override: {},
        }

        getAvailability({
            accountSlug,
            articleId,
            categoryId: categoryId,
            startAt: startAtDate,
            endAt: endAtDate,
        }).unwrap()

        getPricesForCalendar({
            accountSlug,
            userInfo: user,
            productsList: categoryId,
            productData,
            dateFrom: format(startAtDate, 'yyyy-MM-dd'),
            dateTo: format(endAtDate, 'yyyy-MM-dd'),
            accountSettings,
        })
    }

    useEffect(() => {
        fetchData()
    }, [articleId, categoryId, startAt, endAt, accountSettings])

    const loading = fetchingProductPricing || fetchingAvailability || fetchingPrices

    const data = useMemo<Price[] | undefined>(
        () =>
            prices &&
            availabilities &&
            transformPricesToCalendarData(prices, availabilities, accountSlug, formatCurrencyString),
        [prices, availabilities, accountSlug, formatCurrencyString],
    )

    return {
        data,
        loading,
    }
}
