import * as React from 'react'
import styled from 'styled-typed'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { PricingService, ExceptionResponse } from '../pricingService'
import { ArticleService as AdminArticleService } from 'admin/articleService'
import {
    InlineDataTable,
    HeaderRow,
    TableHeader,
    Cell,
    Sorting,
    Status,
    NameAndStatus,
    DataRow,
} from 'uiComponents/table'
import { ActionButtonA } from 'uiComponents/buttons'
import { DateRange } from 'uiComponents/date'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { TableLoader } from 'uiComponents/loaders'
import { WeekdayPattern } from 'uiComponents/weekdayPattern'
import { PriceSettingBoxElement } from './priceSettingBox'
import { withFeatures } from 'features'
import Feature from 'features/feature'
import { ChannelsService, ResellerResponseItem } from 'channels/channelsService'
import { useGetAllArticles } from 'products/redux'
import { ArticleFromApi } from '../../types'

const NoBarText = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
`

export const ExceptionDataRow = styled(DataRow)`
    &.inactive {
        .priceTypeBoxes {
            pointer-events: none;
        }
        opacity: 0.5;
    }
`

interface ExceptionsListPageProps {
    pricingService: PricingService
    articleService: AdminArticleService
    channelsService: ChannelsService
    accountSlug: string
    productId?: string
    navigation: Navigation
    match: RouteMatch<{}>
    handleError: (message?: string) => void
    hasPermission: (permission: string, accountSlug: string) => boolean
    getCurrency: (accountSlug: string) => Currency
    hasFeature: (feature: string, accountSlug: string) => boolean
}

interface ExceptionsListPageState {
    loading: boolean
    exceptions: ExceptionResponse[]
    productId: string | undefined
}

interface Props extends ExceptionsListPageProps, React.HTMLAttributes<HTMLDivElement> {
    articles: ArticleFromApi[]
}

class ExceptionsTable extends React.Component<Props, ExceptionsListPageState> {
    constructor(
        props: ExceptionsListPageProps & React.HTMLAttributes<HTMLDivElement> & { articles: ArticleFromApi[] },
    ) {
        super(props)
        this.state = {
            loading: true,
            exceptions: [],
            productId: this.props.productId,
        }
    }

    async componentDidMount() {
        const query = this.props.navigation.query()
        await this.getExceptions(query.sortBy || 'name', query.sortDirection || 'desc')
    }

    async componentDidUpdate(prevProps: ExceptionsListPageProps) {
        const prevQuery = prevProps.navigation.query()
        const query = this.props.navigation.query()
        const sortChanged = prevQuery.sortBy !== query.sortBy || prevQuery.sortDirection !== query.sortDirection
        const accountChanged = prevProps.accountSlug !== this.props.accountSlug
        if (sortChanged || accountChanged) {
            await this.getExceptions(query.sortBy, query.sortDirection)
        }
    }

    getExceptions = async (sortBy: string, sortDirection: string) => {
        try {
            const exceptions = await this.props.pricingService.getExceptionsList(
                this.props.accountSlug,
                sortBy,
                sortDirection,
            )
            let resellersList: ResellerResponseItem[] = []
            if (this.props.hasFeature('ResellersFeature', this.props.accountSlug)) {
                const query = '?include_archived=true'
                resellersList = await this.props.channelsService.getResellersList(this.props.accountSlug, query)
            }
            exceptions.forEach((e) => {
                const productIds = e.priceSettings.map((p) => p.productId)
                const productNames = this.props.articles
                    .filter((a) => productIds.indexOf(a.id) > -1)
                    .map((a) => a.name['en'] || a.name[Object.keys(a.name)[0]])
                e['productNames'] = productNames.join(', ')
                const resellers = resellersList
                    .filter((r) => (e.forResellers || []).indexOf(r.resellerId) > -1)
                    .map((r) => (r.archived ? `ARCHIVED - ${r.name}` : r.name))
                e['resellersNames'] = resellers.join(', ')
            })
            if (this.props.productId) {
                const productExceptions = exceptions.filter(
                    (e) => e.priceSettings.filter((p) => p.productId === this.props.productId).length > 0,
                )
                this.setState({ exceptions: productExceptions, loading: false })
            } else {
                this.setState({ exceptions, loading: false })
            }
        } catch {
            this.props.handleError('Oops! Something went wrong, please try again.')
        }
    }

    onSortChanged = (sorting: Sorting) => {
        this.setState({ loading: true })
        this.props.navigation.addQueryWithReplace({
            sortBy: sorting.prop,
            sortDirection: sorting.direction,
        })
    }

    render() {
        const query = this.props.navigation.query()
        const sort = {
            prop: query.sortBy || 'name',
            direction: query.sortDirection || 'desc',
        }

        const resellersFeature = this.props.hasFeature('ResellersFeature', this.props.accountSlug)

        const columnWidths = resellersFeature
            ? ['13em', null, '9em', '12em', '8em', '9.5em', '7em', '7.5em']
            : ['15em', null, '12em', '8em', '9.5em', '7em', '7.5em']

        const effectiveStyle = { boxShadow: 'none', ...this.props.style }
        return (
            <InlineDataTable style={effectiveStyle} columnWidths={columnWidths} id="exceptions-list-page">
                <HeaderRow>
                    <TableHeader sortKey="name" sorting={sort as Sorting} changeSort={this.onSortChanged}>
                        Name
                    </TableHeader>
                    <TableHeader>Product(s)</TableHeader>
                    <Feature name="ResellersFeature" accountSlug={this.props.accountSlug}>
                        <TableHeader>Reseller(s)</TableHeader>
                    </Feature>
                    <TableHeader>Price Settings</TableHeader>
                    <TableHeader>Pattern</TableHeader>
                    <TableHeader sortKey="dateRange" sorting={sort as Sorting} changeSort={this.onSortChanged}>
                        Date range
                    </TableHeader>
                    <TableHeader nonInteractive />
                </HeaderRow>
                {this.state.loading && <TableLoader />}
                {!this.state.loading && (
                    <>
                        {this.props.articles.length === 0 ? (
                            <NoResultsRow text="Neither categories nor products were found. Exception creation is not available." />
                        ) : (
                            <>
                                {this.state.exceptions.length === 0 && (
                                    <NoResultsRow text="There are no exceptions yet." />
                                )}
                                {this.state.exceptions.map((e, key) => (
                                    <ExceptionDataRow
                                        key={key}
                                        className={e.active ? 'exceptionRow' : 'inactive exceptionRow'}
                                    >
                                        <Cell title={e.name}>
                                            <NameAndStatus>
                                                <div className="exceptionName">{e.name}</div>
                                                <Status active={e.active} withText={true} />
                                            </NameAndStatus>
                                        </Cell>
                                        <Cell className="exceptionProducts withName">
                                            <span title={e.productNames}>{e.productNames}</span>
                                        </Cell>
                                        <Feature name="ResellersFeature" accountSlug={this.props.accountSlug}>
                                            <Cell className="withName">
                                                <span title={e.resellersNames}>{e.resellersNames || '-'}</span>
                                            </Cell>
                                        </Feature>
                                        <Cell style={{ overflow: 'visible' }}>
                                            <PriceSettingBoxElement
                                                key={key}
                                                maxAdjustmentType={e.originalPriceAdjustmentType}
                                                maxAdjustment={e.originalPriceAdjustment}
                                                minAdjustmentType={e.minAcceptedPriceAdjustmentType}
                                                minAdjustment={e.minAcceptedPriceAdjustment}
                                                targetAdjustmentType={e.targetAvgPriceAdjustmentType}
                                                targetAdjustment={e.targetAvgPriceAdjustment}
                                                pricingType={e.pricingType}
                                                currency={this.props.getCurrency(this.props.accountSlug)}
                                                enforceFrom={e.overrideType === 'enforce' ? e.enforceFrom : null}
                                            />
                                        </Cell>
                                        <Cell>
                                            {!e.weekdays && e.recurrence === 'WEEKLY' && (
                                                <WeekdayPattern weekdays="0, 1, 2, 3, 4, 5, 6" />
                                            )}
                                            {e.recurrence !== 'WEEKLY' && <NoBarText>custom</NoBarText>}
                                            {e.weekdays && e.recurrence === 'WEEKLY' && (
                                                <WeekdayPattern weekdays={e.weekdays} />
                                            )}
                                        </Cell>
                                        <Cell className="exceptionDates">
                                            <DateRange
                                                startDateString={e.startDate}
                                                endDateString={e.endDate}
                                                fallback=""
                                            />
                                        </Cell>
                                        <Cell>
                                            <ActionButtonA
                                                className="editException"
                                                kind="action"
                                                secondary
                                                href={`/account/${this.props.accountSlug}/products/pricing/exceptions/${e.id}`}
                                            >
                                                {this.props.hasPermission(
                                                    'edit_pricing_settings',
                                                    this.props.accountSlug,
                                                )
                                                    ? 'Edit'
                                                    : 'View'}
                                            </ActionButtonA>
                                        </Cell>
                                    </ExceptionDataRow>
                                ))}
                            </>
                        )}
                    </>
                )}
            </InlineDataTable>
        )
    }
}

const ArticleWrapper = (props: Omit<Props, 'articles'>) => {
    const articles = useGetAllArticles()

    return <ExceptionsTable {...props} articles={articles} />
}

export default withFeatures(withCurrency(withNavigation(ArticleWrapper)))
