import { BaseRouteParams } from 'hocs'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { withFeatures } from './hocs'

interface FeatureProps {
    name: string
    invert?: boolean
    children: React.ReactNode
    accountSlug?: string
    hasFeature: (feature: string, accountSlug?: string) => boolean
}

function Feature({ name, invert, children, accountSlug, hasFeature }: FeatureProps) {
    const { accountSlug: accountSlugFromParams } = useParams<BaseRouteParams>()
    let enabled = hasFeature(name, accountSlugFromParams || accountSlug)
    if (invert) {
        enabled = !enabled
    }

    if (!enabled || !children) {
        return null
    }

    return <>{children}</>
}

export default withFeatures(Feature)
