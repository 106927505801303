/*
 * These are utilities for displaying warning if in sales, traffic or checkout funnel reports that there was a data
 * collection incident in Charon service which prevents correct reports from 2024-02-20 to 2024-02-32.
 * Postmortem of incident: https://tapperuse.atlassian.net/wiki/x/AYBhBg
 *
 * TODO: This code should be removed when data from 2024-02-20
 *  to 2024-02-32 will become globally irrelevant for all partners
 */
import React, { PropsWithChildren } from 'react'
import { DateRange } from '../../../dateRanges'
import { isBefore, isAfter, isEqual } from 'date-fns'
import { Alert } from '@mui/material'
import './charonIncidentWarning.scss'

const CHARON_INCIDENT_INTERVAL = { from: new Date('2024-02-20'), to: new Date('2024-02-23') }

function areRangesOverlapping(startDate1: Date, endDate1: Date, startDate2: Date, endDate2: Date): boolean {
    return (
        (isBefore(startDate1, endDate2) || isEqual(startDate1, endDate2)) &&
        (isAfter(endDate1, startDate2) || isEqual(endDate1, startDate2))
    )
}

function dateRangeOverlapsCharonIncident({ from, to }: DateRange) {
    return areRangesOverlapping(from, to, CHARON_INCIDENT_INTERVAL.from, CHARON_INCIDENT_INTERVAL.to)
}

type Props = PropsWithChildren<{ dateRange: DateRange }>

export function CharonIncidentWarning({ dateRange, children }: Props) {
    if (!dateRangeOverlapsCharonIncident(dateRange)) {
        return null
    }
    return (
        <div className="charonIncidentWarningContainer">
            <Alert severity="warning">{children}</Alert>
        </div>
    )
}
