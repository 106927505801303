import React from 'react'
import Svg from 'svg'
import styled from 'styled-typed'
import conviousLogo from 'images/convious-logo-pink.svg'
import { LoginPageContainerProps } from './schema'
import loginPhoto from 'auth/loginPage/loginPhoto.jpg'
import { Body, StyledATag } from 'uiComponents/typography'

const LoginContainer = styled.div`
    background: ${(props) => props.theme.colors.white};
    font-family: ${(props) => props.theme.fonts.primary};
    color: ${(props) => props.theme.colors.textDark};
    width: 100%;
    min-height: 100vh;
    display: flex;

    flex-direction: column-reverse;

    @media screen and (min-width: 890px) {
        min-width: 950px;
        flex-direction: row;
    }
    @media screen and (min-height: 1400px) {
        flex-direction: column-reverse;
        @media screen and (min-width: 1100px) {
            min-width: 950px;
            flex-direction: row;
        }
    }
    @media screen and (min-height: 1800px) {
        flex-direction: column-reverse;
        @media screen and (min-width: 1200px) {
            min-width: 950px;
            flex-direction: row;
        }
    }
    @media screen and (min-height: 2100px) {
        flex-direction: column-reverse;
        @media screen and (min-width: 1400px) {
            min-width: 950px;
            flex-direction: row;
        }
    }
`
const LeftSide = styled.div`
    position: relative;
    width: 100%;
    min-width: 476px;
    line-height: 0;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    @media screen and (min-width: 890px) {
        width: unset;
        height: 100vh;
        min-height: 800px;
    }

    @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
        display: none;
    }
`
const Photo = styled.img`
    align-self: flex-start;
    width: 100%;
    display: none;
    @media screen and (min-width: 890px) {
        display: block;
        width: unset;
        height: 100%;
        min-height: 900px;
    }
    @media screen and (min-height: 1180px) {
        display: block;
    }
`
const RightSide = styled.div`
    flex: 1;
    height: 100%;
    min-width: 320px;
    min-height: 800px;
    background: #ffffff;
    padding: 5em 1em 3em 1em;

    @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
        padding: 2.5em 1.25em 1.5em 1.25em;
    }

    @media screen and (min-width: 890px) {
        width: 100%;
    }
    @media screen and (min-width: 965px) {
        min-width: 50vw;
    }
    @media screen and (min-height: 1400px) {
        min-height: 900px;
    }
`
const LeftSideText = styled.div`
    position: absolute;
    color: #ffffff;
    font-family: Raleway;
    font-weight: 800;
    font-size: 65px;
    line-height: 67px;
    width: 6em;
    z-index: 100;
    padding-left: 1em;
    padding-bottom: 1.25em;
`
const ContentWrapper = styled.div`
    height: 100%;
    width: 100%;
    min-width: 280px;
    max-width: 428px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: auto;
`
const Footer = styled.div`
    width: 100%;
    align-self: baseline;
`
const LogoWrapper = styled.div`
    width: 8em;
    align-self: baseline;
`
const Copy = styled(Body)`
    text-align: center;
    color: ${(props) => props.theme.colors.textLight};
`
const LinksRow = styled.div`
    margin-bottom: 0.5em;
    display: flex;
    justify-content: space-between;

    @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
        display: none;
    }
`
const LinkWrapper = styled(Body)`
    display: inline-block;

    a {
        text-decoration: none;
    }

    &:last-child {
        margin-right: 0;
    }
`

export function LoginPageContainer(props: LoginPageContainerProps) {
    const currentYear = new Date().getFullYear()

    return (
        <LoginContainer>
            <LeftSide>
                <Photo src={loginPhoto} />
                <LeftSideText>You are in control</LeftSideText>
            </LeftSide>
            <RightSide>
                <ContentWrapper>
                    <LogoWrapper>
                        <Svg src={conviousLogo} />
                    </LogoWrapper>
                    {props.children}
                    <Footer>
                        <LinksRow>
                            <LinkWrapper size={1}>
                                <StyledATag href="https://www.convious.com/partner-terms-of-services" target="_blank">
                                    Terms of Service
                                </StyledATag>
                            </LinkWrapper>
                            <LinkWrapper size={1}>
                                <StyledATag href="https://www.convious.com/privacy-policy" target="_blank">
                                    Privacy Policy
                                </StyledATag>
                            </LinkWrapper>
                            <LinkWrapper size={1}>
                                <StyledATag
                                    href="https://www.convious.com/contact/?utm_source=new_dashboard_login_page"
                                    target="_blank"
                                >
                                    Need help?
                                </StyledATag>
                            </LinkWrapper>
                            <LinkWrapper size={1}>
                                <StyledATag
                                    href="https://www.convious.com/book-a-demo?utm_source=new_dashboard_login_page"
                                    target="_blank"
                                >
                                    Request Demo
                                </StyledATag>
                            </LinkWrapper>
                        </LinksRow>
                        <Copy size={2}>&copy; {currentYear} Convious. All Rights Reserved.</Copy>
                    </Footer>
                </ContentWrapper>
            </RightSide>
        </LoginContainer>
    )
}
