import * as React from 'react'
import styled from 'styled-typed'
import { Checkbox } from 'uiComponents/input'
import { SingleSelect } from 'uiComponents/input/singleSelect'
import { transparency } from 'utils/css'
import { Option } from './schema'
import { DateRangeInputs, InputBox, InputSeparator, DateTextInput, ValidationNotice } from './dateRangeSection'

const ComparisonContainer = styled.div`
    max-height: 2.2em;
    transition: max-height 0.2s;

    &.compare {
        max-height: 6em;
    }
`
const DaterRangeSelect = styled.div`
    display: flex;
    align-items: flex-end;
    position: relative;
    justify-content: space-between;
    margin-bottom: 0.8em;
`
const CompareSelect = styled(DaterRangeSelect)`
    margin-top: 2.5em;
`
const SelectCompare = styled.div`
    margin-left: 1em;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in, visibility 0s 0.2s;

    &.visible {
        opacity: 1;
        visibility: visible;
        transition: visibility 0s;
    }
`
const CheckBoxWrapper = styled.div`
    display: flex;
`
const CompareText = styled.div`
    margin-left: 0.3em;
    & span {
        opacity: 0;
        transition: opacity 0.15s ease-in, visibility 0s 0.15s;
        position: absolute;
        left: 7.3em;
    }
    &.visible {
        span {
            opacity: 15;
            visibility: visible;
            transition: visibility 0s;
        }
    }
`
const CompareDateText = styled(DateTextInput)`
    background: ${(props) => transparency(props.theme.colors.status.warn, 0.4)};
    &:disabled {
        color: ${(props) => props.theme.colors.textDark};
    }
`

interface ComparisonSectionProps {
    inputCompareFrom: string
    inputCompareTo: string
    inputCompareFromError: string
    inputCompareToError: string
    compare: boolean
    compareTo: string
    compareOptions: Option[]
    toggleCompare: () => void
    handleCompareToChange: (option: string) => void
    handleDateInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleFinishDateEditing: () => void
    validateInputDate: (value: string, input: string) => void
}

export function ComparisonSection(props: ComparisonSectionProps) {
    const {
        compare,
        compareTo,
        compareOptions,
        toggleCompare,
        handleCompareToChange,
        handleDateInputChange,
        handleFinishDateEditing,
        inputCompareFrom,
        inputCompareTo,
        inputCompareFromError,
        inputCompareToError,
        validateInputDate,
    } = props

    return (
        <ComparisonContainer className={compare ? 'compare' : ''}>
            <CompareSelect>
                <CheckBoxWrapper>
                    <Checkbox id="compareToggle" name="compare" checked={compare} onChange={toggleCompare} />
                    <CompareText className={compare ? '' : 'visible'}>
                        Compare to <span>another date range</span>
                    </CompareText>
                </CheckBoxWrapper>
                <SelectCompare className={compare ? 'visible' : ''}>
                    <SingleSelect
                        options={compareOptions}
                        selected={compareTo}
                        noSelectOption="Select period"
                        height="2.25em"
                        width="10em"
                        onSelect={handleCompareToChange}
                    />
                </SelectCompare>
            </CompareSelect>
            <DateRangeInputs className={compare ? 'visible' : ''}>
                <InputBox>
                    <label>
                        <CompareDateText
                            name="inputCompareFrom"
                            value={inputCompareFrom}
                            onChange={handleDateInputChange}
                            onFinishTyping={(v) => validateInputDate(v, 'inputCompareFrom')}
                            onBlur={handleFinishDateEditing}
                            status={inputCompareFromError ? 'error' : 'normal'}
                        />
                    </label>
                    <ValidationNotice className="validation-message-visible">
                        {inputCompareFromError}&nbsp;
                    </ValidationNotice>
                </InputBox>
                <InputSeparator>&#8211;</InputSeparator>
                <InputBox>
                    <label>
                        <CompareDateText
                            name="inputCompareTo"
                            value={inputCompareTo}
                            onChange={handleDateInputChange}
                            onFinishTyping={(v) => validateInputDate(v, 'inputCompareTo')}
                            onBlur={handleFinishDateEditing}
                            status={inputCompareToError ? 'error' : 'normal'}
                        />
                    </label>
                    <ValidationNotice className="validation-message-visible">
                        {inputCompareToError}&nbsp;
                    </ValidationNotice>
                </InputBox>
            </DateRangeInputs>
        </ComparisonContainer>
    )
}
