import React from 'react'
import { IOrder } from 'orders/schema'
import InlineEmail from '../commons/inlineEmail'
import InlineVisitDate from '../commons/inlineVisitDate'
import InlineVisitTime from '../commons/inlineVisitTime'
import OrderStatus from '../commons/orderStatus'
import { DiscountCodesElement } from 'orders/helpers'
import DataItem from './components/dataItem'
import { formatTimestamp, getDiscountCodesString, getLastPayment } from '../utils'
import { useAppDispatch } from 'store/hooks'
import { selectOrder } from '../redux'

const OrderInfo = ({ order }: { order: IOrder }) => {
    const lastPayment = getLastPayment(order.payments)
    const dispatch = useAppDispatch()
    return (
        <div className="content">
            <div className="order-info">
                <div className="column">
                    <DataItem className="row" name="Order ID:">
                        {order.number}
                    </DataItem>
                    <DataItem className="row" name="Email:">
                        <InlineEmail
                            order={order}
                            onUpdated={(order) => {
                                dispatch(selectOrder(order))
                            }}
                        />
                    </DataItem>
                    <DataItem className="row" name="Pay date:">
                        {lastPayment?.paymentDate ? formatTimestamp(lastPayment.paymentDate, order.timezone) : '-'}
                    </DataItem>
                    <DataItem className="row" name="Visit date:">
                        <InlineVisitDate
                            order={order}
                            onUpdated={(order) => {
                                dispatch(selectOrder(order))
                            }}
                        />
                    </DataItem>
                    <DataItem className="row" name="Visit time:">
                        <InlineVisitTime
                            order={order}
                            onUpdated={(order) => {
                                dispatch(selectOrder(order))
                            }}
                        />
                    </DataItem>
                    <DataItem className="row" name="Discount codes:">
                        <DiscountCodesElement discountsString={getDiscountCodesString(order)} />
                    </DataItem>
                </div>
                <div className="column">
                    <DataItem className="row" name="Order status:">
                        <OrderStatus value={order.status} />
                    </DataItem>
                    <DataItem className="row" name="Payment status:">
                        <OrderStatus value={lastPayment?.paymentStatus ?? '-'} />
                    </DataItem>
                    <DataItem className="row" name="Payment method:">
                        {lastPayment?.paymentMethod ?? '-'}
                    </DataItem>
                    <DataItem className="row" name="Email status:">
                        {order.emailStatus}
                    </DataItem>
                    <DataItem className="row" name="Downloaded:">
                        {order.downloaded ? formatTimestamp(order.downloaded, order.timezone) : '-'}
                    </DataItem>
                    <DataItem className="row" name="Reseller:">
                        {order.reseller.name ? order.reseller.name : '-'}
                    </DataItem>
                </div>
            </div>
        </div>
    )
}

export default OrderInfo
