import * as React from 'react'
import ReactEcharts from 'echarts-for-react'
import { withTheme } from 'styled-typed'
import { DashboardTheme } from 'theme'
import { NoDataNotice, tooltipStyle } from 'uiComponents/charts/styleComponents'
import isEqual from 'lodash/isEqual'
import { TooltipParams } from './timeseriesBarChart'

export type SerieLink = {
    source: string
    target: string
    value: string
}

export type SerieData = {
    name: string
    label: string
}

export interface Series {
    name: string
    type: string
    layout?: string
    focusNodeAdjacency?: string
    links: SerieLink[]
    barWidth?: string
    barGap?: number
    data: SerieData[]
    label?: {
        formatter(params: any): void
    }
}

interface Props {
    loading: boolean
    title?: string
    series: Series[]
    theme: DashboardTheme
    percent?: boolean
    chartHeight?: string
    colors?: string[]
    tooltipFormatter: (params: TooltipParams) => string
}

interface ChartState {
    chartOption: any
}

class Chart extends React.Component<Props & React.HTMLAttributes<HTMLElement>, ChartState> {
    constructor(props: Props & React.HTMLAttributes<HTMLElement>) {
        super(props)
        this.state = {
            chartOption: {},
        }
    }

    loadBarChartData = () => {
        const sankeyChartOption = {
            textStyle: {
                fontFamily: 'Roboto',
            },
            title: {
                text: this.props.title || '',
                textStyle: {
                    fontSize: 20,
                    fontWeight: 'normal',
                },
                left: 'left',
                padding: [10, 0, 0, 0],
            },
            series: this.props.series,
            tooltip: {
                formatter: this.props.tooltipFormatter,
                trigger: 'item',
                triggerOn: 'mousemove',
                axisPointer: {
                    type: 'none',
                },
                padding: [5, 10],
                ...tooltipStyle,
            },
            color: this.props.colors ? this.props.colors : this.props.theme.colors.chartRotatingColors,
        }
        this.setState({
            chartOption: sankeyChartOption,
        })
    }

    componentDidMount() {
        this.loadBarChartData()
    }

    componentDidUpdate(prevProps: Props) {
        if (!isEqual(prevProps.series, this.props.series)) {
            this.loadBarChartData()
        }
    }

    getDataLength = () => {
        return this.props.series.length
    }

    render() {
        return (
            <NoDataNotice dataLength={this.getDataLength()} loading={this.props.loading}>
                <ReactEcharts
                    option={this.state.chartOption}
                    style={{
                        height: this.props.chartHeight ? this.props.chartHeight : '300px',
                        ...this.props.style,
                    }}
                    notMerge={true}
                    lazyUpdate={true}
                    onChartReady={() => {}}
                />
            </NoDataNotice>
        )
    }
}

export default withTheme(Chart)
