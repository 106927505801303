import * as React from 'react'
import styled from 'styled-typed'
import { OptionCheckBox, LeafNodeBox, SubOptionsList } from 'uiComponents/menus/'
import { FilterOption } from './schema'
import { faLevelDownAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome'

export function checkOptionsMatch(a: FilterOption, b: FilterOption) {
    return a.slug === b.slug && a.category === b.category
}

export interface CheckBoxesData {
    selected: FilterOption[]
    indeterminate: string[]
}

interface FilterOptionsItemProps {
    children?: React.ReactNode
    option: FilterOption
    checkBoxesData: CheckBoxesData
    onSelect: (option: FilterOption) => void
    disabled?: boolean
    grade?: number
    isCategoriesSelector?: boolean
    singleSelectCategory?: boolean
}

export const CheckboxPlaceholder = styled.div`
    width: 1em;
`

export const NameWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const ArrowIcon = styled(FontAwesomeIcon)`
    margin-left: 0.3rem;
    font-size: 0.625rem;
`

export function ArrowDown() {
    return <ArrowIcon icon={faLevelDownAlt as IconProp} />
}

export function FilterOptionsItem(props: FilterOptionsItemProps) {
    const selected = !!props.checkBoxesData.selected.find((c) => checkOptionsMatch(c, props.option))
    const hasChildrenSelected = props.checkBoxesData.indeterminate.indexOf(props.option.slug) > -1
    const indeterminateState = !selected && hasChildrenSelected
    const grade = props.grade || 1

    const optionDisabled = () => {
        return (
            (props.option.category !== 'categories' && !props.option.leafNode && !props.option.children.length) ||
            (props.option.category === 'categories' && props.option.children.length > 0)
        )
    }

    const hideCheckbox = () => {
        return props.singleSelectCategory || optionDisabled()
    }

    const optionBoxContent = () => (
        <div style={{ display: 'flex', minHeight: '1.25em' }}>
            {hideCheckbox() ? (
                <CheckboxPlaceholder />
            ) : (
                <OptionCheckBox
                    name={props.option.slug}
                    indeterminateState={indeterminateState}
                    checked={selected || indeterminateState}
                />
            )}
            <NameWrapper>
                <span>{props.option.name}</span>
                {props.disabled && <ArrowDown />}
            </NameWrapper>
        </div>
    )

    const getNodeClassName = () => {
        const className = props.option.leafNode ? 'leaf' : 'parent'
        return props.singleSelectCategory && selected ? className + ' highlighted' : className
    }

    return (
        <>
            <LeafNodeBox
                id={props.option.slug}
                className={getNodeClassName()}
                onClick={() => !optionDisabled() && props.onSelect(props.option)}
                grade={grade}
            >
                {optionBoxContent()}
            </LeafNodeBox>
            {props.option.children.length > 0 && (
                <SubOptionsList>
                    {props.option.children.map((c, i) => (
                        <FilterOptionsItem
                            key={props.option.name + i}
                            option={c}
                            checkBoxesData={props.checkBoxesData}
                            onSelect={props.onSelect}
                            grade={grade + 1}
                            singleSelectCategory={props.singleSelectCategory}
                        />
                    ))}
                </SubOptionsList>
            )}
        </>
    )
}
