import * as React from 'react'
import styled from 'styled-typed'
import Infotip from 'uiComponents/infotip'

export const FormSectionWrapper = styled.div<{ unlimitedHeight?: boolean }>`
    padding: 0 1em 0 1em;
    border-top: 1px solid #ccc;
    .sectionBody {
        transition: all 0.5s ease;
        overflow: visible;
        position: relative;
    }
    &.collapsed {
        padding-bottom: 0;
        > .sectionBody {
            max-height: 0;
            opacity: 0;
            height: 0;
            * {
                display: none;
            }
        }
    }
    &.expanded {
        > .sectionBody {
            max-height: ${(props) => (props.unlimitedHeight ? 'unset' : '100em')};
            opacity: 1;
            height: 100%;
        }
    }
    & .justify-right {
        justify-content: flex-end;
        display: flex;
    }
`

export const SectionTitleWrapper = styled.div`
    cursor: pointer;
    display: flex;
    > h5 {
        flex: 1;
        font-weight: normal;
    }
`

const TogglerEl = styled.div`
    float: right;
    margin-right: 1.2em;
`

interface TogglerProps {
    expand: boolean
    onClick: (e: React.MouseEvent<HTMLElement>) => void
}
const Toggler = (props: TogglerProps) => {
    if (props.expand) {
        return <TogglerEl onClick={props.onClick}>&#65291;</TogglerEl>
    }
    return <TogglerEl onClick={props.onClick}>&#8212;</TogglerEl>
}

const SectionTitleContent = styled.h5`
    display: flex;
    justify-content: space-between;
`

interface TitleProps {
    onClick: (e: React.MouseEvent<HTMLElement>) => void
    name: string
    willExpand: boolean
    infotip?: string
}
export const SectionTitle = (props: TitleProps) => {
    const { onClick, name, willExpand, infotip } = props
    return (
        <SectionTitleWrapper>
            <SectionTitleContent>
                <div style={{ display: 'flex' }}>
                    {name}
                    {infotip && infotip !== '' && (
                        <Infotip pointer="right" maxWidth="18em" smallBoxText>
                            {infotip}
                        </Infotip>
                    )}
                </div>
                <Toggler expand={willExpand} onClick={onClick} />
            </SectionTitleContent>
        </SectionTitleWrapper>
    )
}

interface SectionWrapperProps {
    children: React.ReactNode
    title: string
    infotip?: string
    defaultCollapsed?: boolean
    unlimitedHeight?: boolean
    onClick?: (value: boolean) => void
}
interface SectionWrapperState {
    collapsed: boolean
}
export class SectionWrapper extends React.Component<SectionWrapperProps, SectionWrapperState> {
    constructor(props: SectionWrapperProps) {
        super(props)
        this.state = {
            collapsed: !!props.defaultCollapsed,
        }
    }

    componentDidUpdate = (prevProps: SectionWrapperProps) => {
        if (
            prevProps.defaultCollapsed !== this.props.defaultCollapsed &&
            this.props.defaultCollapsed !== this.state.collapsed
        ) {
            this.setState({ collapsed: !!this.props.defaultCollapsed })
        }
    }

    toggleAppearance = (event: React.MouseEvent<HTMLElement>): void => {
        if (this.props.onClick) {
            this.props.onClick(!this.state.collapsed)
        }
        this.setState({ collapsed: !this.state.collapsed })
    }

    render() {
        return (
            <FormSectionWrapper
                className={this.state.collapsed ? 'collapsed' : 'expanded'}
                unlimitedHeight={this.props.unlimitedHeight}
            >
                <SectionTitle
                    onClick={this.toggleAppearance}
                    name={this.props.title}
                    willExpand={this.state.collapsed}
                    infotip={this.props.infotip}
                />
                <div className="sectionBody">{this.props.children}</div>
            </FormSectionWrapper>
        )
    }
}
