import { generalIcons, animalsIcons } from '@convious/tap-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useField } from 'formik'
import React, { memo, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import IconSelector from 'uiComponents/iconsSelector'
import { Headline } from 'uiComponents/typography'
import { Tooltip } from 'uiComponents/tooltip'
const tapIcons = [...generalIcons, ...animalsIcons]

const IconSection = styled.div`
    display: flex;
    flex: 0;
    flex-direction: row;
    align-items: center;
`

const IconSectionText = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 0.5em;
    padding-right: 0.5em;
    position: relative;
`

const TooltipText = styled.div`
    color: ${(props) => props.theme.colors.white};
    font-size: 0.8em;
`

interface Props {
    field: string
    color?: string
    tooltip?: string
    allowEmptySelection?: boolean
}

const IconSelection = ({ field, color, tooltip, allowEmptySelection }: Props) => {
    const theme = useTheme()
    const [{ value }, {}, { setValue }] = useField(field)
    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)
    const [selectedColor] = useField('color')
    const Icons = [...tapIcons]

    if (allowEmptySelection) {
        Icons.unshift({ name: 'no icon', icon: null, stringSvg: '' })
    }

    return (
        <IconSection>
            <IconSectionText onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
                {tooltipVisible && tooltip && (
                    <Tooltip style={{ position: 'absolute', left: 160, bottom: -8, whiteSpace: 'nowrap' }}>
                        <TooltipText>{tooltip}</TooltipText>
                    </Tooltip>
                )}
                <Headline size={6} style={{ margin: '0 0.25em 0 0' }}>
                    Icon
                </Headline>
                <FontAwesomeIcon icon={faInfoCircle} color={theme.colors.textLight} size="sm" />
            </IconSectionText>
            <IconSelector
                icons={Icons}
                selectedIcon={Icons.find((icon) => icon.name === value) ?? Icons[0]}
                onIconSelect={(icon) => {
                    setValue(icon.name)
                }}
                color={selectedColor.value || color}
            />
        </IconSection>
    )
}

export default memo(IconSelection)
