import * as React from 'react'
import { DateFormats, parseISODate } from '../../utils/dates'
import { format, isValid, parse } from 'date-fns'

interface DateRangeProps {
    startDateString?: string | null
    endDateString?: string | null
    fromFormat?: string
    toFormat?: string
    fallback?: string
}

const parseDate = (dateString: string, fromFormat?: string) =>
    fromFormat ? parse(dateString, fromFormat, new Date()) : parseISODate(dateString)

export const DateRange = ({
    startDateString,
    endDateString,
    fromFormat,
    toFormat = DateFormats.LONG_DATE,
    fallback = '-',
}: DateRangeProps) => {
    if (!startDateString || !endDateString) {
        return <>{fallback}</>
    }

    const startDate = parseDate(startDateString, fromFormat)
    const endDate = parseDate(endDateString, fromFormat)

    if (!isValid(startDate) || !isValid(endDate)) {
        return <>Invalid date</>
    }

    return (
        <span>
            <div>{format(startDate, toFormat)} -</div>
            <div>{format(endDate, toFormat)}</div>
        </span>
    )
}

export const TimeRange = ({
    startDateString,
    endDateString,
    fromFormat = 'HH:mm',
    toFormat = DateFormats.SHORT_TIME,
    fallback = '-',
}: DateRangeProps) => (
    <DateRange
        startDateString={startDateString}
        endDateString={endDateString}
        fromFormat={fromFormat}
        toFormat={toFormat}
        fallback={fallback}
    />
)
