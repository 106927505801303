import React from 'react'
import { matchPath, useHistory } from 'react-router-dom'
import { CODES_PATHS } from '../paths'

const DiscountCodesPageTitle = () => {
    const { location } = useHistory()

    if (matchPath(location.pathname, CODES_PATHS.fullPaths.editCode)?.isExact) {
        return <>Edit code</>
    }

    return <>Discount codes</>
}

export default DiscountCodesPageTitle
