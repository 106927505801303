import * as React from 'react'
import styled from 'styled-typed'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, AnyAction } from 'redux'
import { State } from 'store'
import logo from 'images/convious-logo-pink.svg'
import { ActionButton } from 'uiComponents/buttons'
import { LightText, Headline } from 'uiComponents/typography'
import { profileLoaded } from 'settings/actions'
import { History } from 'history'
import { Profile } from 'settings/schema'
import { RequestCard } from 'requests/requestCard'
import { AccountSettingsService } from 'settings/settingsService'
import Svg from 'svg'

const Row = styled.div`
    margin-bottom: 2em;
`
const Logo = styled(Svg)`
    width: 9em;
    margin-bottom: 1em;
    margin-top: 1em;
`

interface WelcomePopupProps {
    history: History
    accountSlug: string
    accountSettingsService: AccountSettingsService
    profile: Profile
    profileLoaded: typeof profileLoaded
}

const WelcomePopup = (props: WelcomePopupProps) => {
    async function handleAccept() {
        await props.accountSettingsService.updateWelcomeSeen()
        const profile = { ...props.profile }
        profile.welcomeSeen = true
        props.profileLoaded(profile)
        props.history.push(`/account/${props.accountSlug}/dashboard/overview`)
    }
    return (
        <RequestCard style={{ marginLeft: '-10em' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '30em',
                    textAlign: 'center',
                }}
            >
                <Row>
                    <Logo src={logo} />
                </Row>
                <Headline size={2}>Welcome to Convious!</Headline>
                <Row>
                    <LightText>
                        By using the Convious Marketing Platform you agree with the&nbsp;
                        <a href="https://www.convious.com/partner-terms-of-services" target="_blank" rel="noreferrer">
                            ‘Terms of Service’
                        </a>
                        ,&nbsp;
                        <a href="https://www.convious.com/data-processing-agreement/" target="_blank" rel="noreferrer">
                            ‘Data Processing Agreement’
                        </a>
                        , and&nbsp;
                        <a href="https://www.convious.com/privacy-policy" target="_blank" rel="noreferrer">
                            ‘Privacy Policy’
                        </a>
                        .
                    </LightText>
                </Row>
                <Row>
                    <ActionButton
                        style={{ marginTop: '1.5em' }}
                        onClick={() => {
                            handleAccept()
                        }}
                    >
                        Accept and Continue
                    </ActionButton>
                </Row>
            </div>
        </RequestCard>
    )
}

const mapStateToProps = (state: State) => {
    return {
        profile: state.profile.profile,
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators(
        {
            profileLoaded,
        },
        dispatch,
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePopup)
