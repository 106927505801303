import { useField } from 'formik'
import React from 'react'
import { Combobox } from './index'
import { Props as NewComboboxProps } from './combobox.new'
import { Props as OldComboboxProps } from './combobox'

interface Props {
    name: string
}

const ComboboxFieldFormik: React.FC<(NewComboboxProps | OldComboboxProps) & Props> = (props) => {
    const [{ value }, _, { setValue }] = useField(props.name)

    return <Combobox {...props} onChange={setValue} value={value} />
}

export default ComboboxFieldFormik
