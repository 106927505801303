/* eslint-disable id-denylist */
import * as yup from 'yup'

yup.setLocale({
    mixed: {
        default: 'Invalid field',
        required: 'Required',
    },
    number: {
        min: ({ min }) => `Minimum value ${min}`,
        max: ({ max }) => `Maximum value ${max}`,
    },
    string: {
        email: 'Wrong email format',
        max: ({ max }) => `The maximum number of characters is ${max}`,
        min: ({ min }) => `The minimum number of characters is ${min}`,
    },
    array: {
        max: ({ max }) => `Max ${max} items`,
        min: ({ min }) => `Min ${min} items`,
    },
})

yup.addMethod(yup.object, 'uniqueProperty', function (propertyName: string, message: string = 'Should be unique') {
    return this.test('unique', message, function (value: any) {
        if (!value || !value[propertyName]) {
            return true
        }

        const { path } = this
        const options = [...this.parent]
        const currentIndex = options.indexOf(value)

        const subOptions = options.slice(0, currentIndex)

        if (subOptions.some((option) => option[propertyName] === value[propertyName])) {
            throw this.createError({
                path: `${path}.${propertyName}`,
                message,
            })
        }

        return true
    })
})
