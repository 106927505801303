import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useField } from 'formik'
import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { ActionIcon } from 'uiComponents/buttons/actionIcon'
import SingleSelectFieldFormik from 'uiComponents/input/singleSelect/singleSelectFieldFormik'
import TextAreaFieldFormik from 'uiComponents/input/textInput/textAreaFieldFormik'
import TextInputFieldFormik from 'uiComponents/input/textInput/textInputFieldFormik'
import { Language } from '../../utils/languages'
import { CategoryContent } from '../../venue/parkMap/models'

const Container = styled.div`
    position: relative;
    border: 1px solid #d1d8df;
    border-radius: 0.375em;
    padding: 0.2em 0.6em;
    margin-bottom: 0.5em;

    table {
        width: 100%;
        border-spacing: 6px 0px;
        margin: 1em 0;

        thead {
            th {
                text-align: left;
                font-weight: normal;
                font-size: 0.9em;
            }
        }
    }
`

const DeleteIcon = styled(ActionIcon)`
    align-self: flex-start;
    border: 1px solid #de4545;
    border-radius: 4px;
    padding: 10px;
    height: 34px;

    & > svg {
        color: #de4545;
    }
`

const DefaultLabel = styled.div`
    position: absolute;
    top: 1em;
    right: 1em;
    font-size: 0.8em;
    color: ${(p) => p.theme.colors.emerald};
    font-style: italic;
`

export interface FieldProps {
    name: string
    label: string
    type: 'input' | 'textarea'
    placeholder: string
}

interface Props {
    index: number
    isVisible: boolean
    localesList: Language[]
    selectedLocales?: CategoryContent[]
    onRemoveLocaleElement: (index: number) => void
    fields: FieldProps[]
    fieldName: string
}

const TranslationsElement = ({
    index,
    isVisible,
    localesList,
    selectedLocales,
    onRemoveLocaleElement,
    fields,
    fieldName,
}: Props) => {
    const theme = useTheme()
    const name = `${fieldName}[${index}].locale`
    const [{ value }] = useField(name)
    const [selectableLocales, setSelectableLocales] = useState<Language[]>(localesList)
    const isDefault = index === 0

    useEffect(() => {
        if (selectedLocales) {
            const selectableLocales = localesList.filter(
                (locale: Language) =>
                    !selectedLocales?.find((selected: CategoryContent) => selected?.locale === locale.value) ||
                    (value && locale.value === value),
            )
            setSelectableLocales(selectableLocales)
        }
    }, [selectedLocales, value])

    if (!isVisible) {
        return <></>
    }

    return (
        <Container>
            {isDefault && <DefaultLabel>Default</DefaultLabel>}
            <table>
                <thead>
                    <tr>
                        <th>Language</th>
                        {fields
                            .filter((field) => field.type === 'input')
                            .map((field) => (
                                <th key={field.label}>{field.label}</th>
                            ))}
                        {!isDefault && <th />}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ width: '150px' }}>
                            <SingleSelectFieldFormik
                                id={`locale${index}`}
                                name={name}
                                options={selectableLocales}
                                noSelectOption="Select language"
                                style={{ backgroundColor: 'transparent', border: `1px solid ${theme.colors.border}` }}
                            />
                        </td>
                        {fields
                            .filter((field) => field.type === 'input')
                            .map((field) => (
                                <td key={field.name}>
                                    <TextInputFieldFormik
                                        id={`names${index}`}
                                        name={`${fieldName}[${index}][${field.name}]`}
                                        placeholder={field.placeholder}
                                        key={field.name}
                                    />
                                </td>
                            ))}
                        {!isDefault && (
                            <td style={{ width: '40px' }}>
                                <DeleteIcon
                                    onClick={() => onRemoveLocaleElement(index)}
                                    title="Delete"
                                    icon={faTrash}
                                    size="medium"
                                />
                            </td>
                        )}
                    </tr>
                </tbody>
            </table>
            {!!fields.filter((field) => field.type === 'textarea').length && (
                <table>
                    <thead>
                        <tr>
                            {fields
                                .filter((field) => field.type === 'textarea')
                                .map((field) => (
                                    <th key={field.label}>{field.label}</th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {fields
                                .filter((field) => field.type === 'textarea')
                                .map((field) => (
                                    <td key={field.name}>
                                        <TextAreaFieldFormik
                                            id={`description${index}`}
                                            name={`${fieldName}[${index}][${field.name}]`}
                                            placeholder={field.placeholder}
                                            key={field.name}
                                            style={{ width: '100%' }}
                                        />
                                    </td>
                                ))}
                        </tr>
                    </tbody>
                </table>
            )}
        </Container>
    )
}

export default TranslationsElement
