import React from 'react'

import { useGetPermission, UserRole } from 'utils/useGetPermission'
import { AccessDeniedPage } from 'admin/accessDeniedPage'

interface RequiredRoleProps {
    role: UserRole
}

const RequiredRole: React.FC<RequiredRoleProps> = ({ role, children }) => {
    const userRole = useGetPermission()

    if (userRole === 'convious_admin') {
        return <>{children}</>
    }

    return userRole === role ? <>{children}</> : <AccessDeniedPage />
}

export default RequiredRole
