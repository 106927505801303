import * as React from 'react'
import { connect } from 'react-redux'
import { State } from 'store'
import { Account } from 'auth/state'
import { withFeatures } from 'features'
import { History } from 'history'
import { match as RouteMatch } from 'react-router-dom'
import { withMessages, MessageProps, withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { chartRowsGap } from 'reports/helpers'
import { Messages, MessageKind } from 'uiComponents/messages'
import SingleDataItems from 'reports/overview/singleDataItems'
import MiniVisitorsChart from 'reports/overview/miniForecastChart'
import PopularDaysChart from 'reports/overview/popularDaysChart'
import PopularHoursChart from 'reports/overview/popularHoursChart'
import ProductsSoldByTypeChart from 'reports/overview/productsByTypeChart'
import TopSellingChart from 'reports/overview/topSellingChart'
import ConversionRateChart from 'reports/overview/convRateChart'
import { Row, Col } from 'uiComponents/flex'
import { PageTitle, PageHeading } from 'uiComponents/typography'
import Feature from 'features/feature'
import PresetDateRangeElement, { PresetType } from 'uiComponents/popups/presetsElement'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { dateRangeToQuery, dateRangeFromQuery, DateRange } from 'dateRanges'
import Disclaimer from 'reports/disclaimer'

interface DashboardOverviewPageProps {
    accounts: Account[]
    navigation: Navigation
    match: RouteMatch<any>
    history: History
    accountSlug: string
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    hasFeature: (feature: string, slug: string) => boolean
}

function DashboardOverviewPage(props: DashboardOverviewPageProps & MessageProps) {
    const rangeName = 'dateRange'
    const defaultRange = 'thisWeek'
    const rangeOptions: PresetType[] = ['today', 'yesterday', 'thisWeek', 'thisMonth', 'last7days', 'last4weeks']

    React.useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    const dateRange = dateRangeFromQuery(props.navigation.query(), 'thisWeek')

    const onDateRangeChanged = (range: DateRange) => {
        if (!areDateRangeDatesEqual(dateRange, range)) {
            props.navigation.addQueryWithReplace(dateRangeToQuery(range))
        }
    }

    const activeAccount = props.accounts.find((x) => x.slug === props.accountSlug) || props.accounts[0]

    return (
        <div id="dashboard-page">
            <Messages messages={props.messages} hideMessage={props.hideMessage} />
            <PageTitle data-userpilot="dashboard-header-tooltip">Overview</PageTitle>
            <PageHeading fullWidth>
                View your most important insights all in one place. Hover over the graphs to view details.
            </PageHeading>
            <Row style={{ marginTop: chartRowsGap, marginBottom: '2.5em' }}>
                <Col span={6}>
                    <Feature name="ForecastChartInOverview" accountSlug={props.accountSlug}>
                        <MiniVisitorsChart accountSlug={props.accountSlug} replaceTopMessages={props.replaceMessages} />
                    </Feature>
                    <Feature name="ForecastChartInOverview" accountSlug={props.accountSlug} invert>
                        <PopularDaysChart accountSlug={props.accountSlug} replaceMessages={props.replaceMessages} />
                    </Feature>
                </Col>
                <Col span={6}>
                    <PopularHoursChart
                        accountSlug={props.accountSlug}
                        timeslots={activeAccount.timeSlotsEnabled}
                        replaceMessages={props.replaceMessages}
                    />
                </Col>
            </Row>
            <PresetDateRangeElement
                presetName={rangeName}
                default={defaultRange}
                options={rangeOptions}
                style={{ marginRight: '15.5em' }}
            />
            <DateRangePicker
                range={dateRange}
                onChange={onDateRangeChanged}
                firstAvailableDay={new Date(2019, 0, 1)}
                style={{ top: '-6.21em' }}
            />
            <SingleDataItems
                accountSlug={props.accountSlug}
                dateRange={dateRange}
                replaceMessages={props.replaceMessages}
            />
            <Row style={{ marginTop: chartRowsGap }}>
                <Col span={6}>
                    <ConversionRateChart
                        accountSlug={props.accountSlug}
                        dateRange={dateRange}
                        type="platform"
                        replaceMessages={props.replaceMessages}
                    />
                </Col>
                <Col span={6}>
                    <ConversionRateChart
                        accountSlug={props.accountSlug}
                        dateRange={dateRange}
                        type="channel"
                        replaceMessages={props.replaceMessages}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: chartRowsGap, marginBottom: '2em' }}>
                <Col span={4}>
                    <ProductsSoldByTypeChart
                        accountSlug={props.accountSlug}
                        dateRange={dateRange}
                        replaceMessages={props.replaceMessages}
                    />
                </Col>
                <Col span={8}>
                    <TopSellingChart
                        accountSlug={props.accountSlug}
                        dateRange={dateRange}
                        replaceMessages={props.replaceMessages}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Disclaimer />
                </Col>
            </Row>
        </div>
    )
}

function mapStateToProps(state: State) {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
    }
}

export default withFeatures(withMessages(withNavigation(connect(mapStateToProps)(DashboardOverviewPage))))
