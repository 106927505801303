import * as React from 'react'
import TimeseriesBarChart from 'uiComponents/charts/timeseriesBarChart'
import { StatsService } from 'http/statsService'
import { format } from 'date-fns'
import { TimeseriesBarChartSource } from 'uiComponents/charts/timeseriesBarChartSource'
import { ChartWrapperWithTotals } from '../helpers'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import {
    areDateRangeDatesEqual,
    mapTimeseriesForTooltip,
    getTooltipRow,
    getTooltipXAxisLabel,
    getTooltipHeader,
    getTooltipFooter,
} from 'reports/helpers'
import { DateRange } from 'dateRanges'
import { Filters } from 'uiComponents/filter/schema'
import isEqual from 'lodash/isEqual'
import { DataPoint } from 'reports/schema'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { TimeSeries } from 'reports/schema'
import { differenceInDays } from 'date-fns'
import { parseISODate } from 'utils/dates'

interface UndatedBarcodesChartProps {
    statsService: StatsService
    filters: Filters[]
    accountSlug: string
    chart: TimeseriesBarChartSource
    dateRange: DateRange
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    getCurrency: (accountSlug: string) => Currency
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
}

interface UndatedBarcodesChartState {
    total: string
    loading: boolean
    mappedRevenue: DataPoint[]
}

class UndatedBarcodesChart extends React.Component<UndatedBarcodesChartProps, UndatedBarcodesChartState> {
    _isMounted = false
    private _lastRequest?: number = undefined

    constructor(props: UndatedBarcodesChartProps) {
        super(props)
        this.state = {
            total: '0',
            loading: true,
            mappedRevenue: [],
        }
    }

    async componentDidMount() {
        this._isMounted = true
        await this.getTransactionsData()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    async componentDidUpdate(prevProps: UndatedBarcodesChartProps) {
        if (
            !areDateRangeDatesEqual(prevProps.dateRange, this.props.dateRange) ||
            prevProps.accountSlug !== this.props.accountSlug ||
            !isEqual(prevProps.filters, this.props.filters)
        ) {
            await this.getTransactionsData()
        }
    }

    shouldShowCustomChart = (timeSeries: TimeSeries[]) => {
        const oneTimeslot = timeSeries[0].points.length < 2
        const wholeDayTimeSlot =
            timeSeries[0].points.length > 0 ? parseISODate(timeSeries[0].points[0].timestamp).getHours() === 0 : true
        return !oneTimeslot || !wholeDayTimeSlot
    }

    getTransactionsData = async () => {
        const requestTime = new Date().valueOf()
        this._lastRequest = requestTime

        this.setState({ loading: true })
        const dateFrom = format(this.props.chart.from(), 'yyyy-MM-dd')
        const dateTo = format(this.props.chart.to(), 'yyyy-MM-dd')
        const oneDayPeriod = differenceInDays(parseISODate(dateTo), parseISODate(dateFrom)) < 1
        try {
            const dated = false
            const data = await this.props.statsService.getBarcodesChartData(
                this.props.accountSlug,
                dateFrom,
                dateTo,
                this.props.chart.granularity,
                this.props.filters,
                dated,
            )

            if (this._lastRequest !== requestTime) {
                return
            }

            if (oneDayPeriod && this.shouldShowCustomChart(data.allBarcodes.timeSeries)) {
                this.props.chart.formatCustomSeries(data.allBarcodes.timeSeries)
            } else {
                this.props.chart.formatSeries(data.allBarcodes.timeSeries)
            }
            this.props.chart.formatSeries(data.allBarcodes.timeSeries)
            const total = data.allBarcodes.totals.filter((t) => t.name === 'total_value')[0]
            const xAxisCategories = this.props.chart.props().xAxisDataRaw
            const granularity = this.props.chart.customGranularity ? 'customTimeseries' : this.props.chart.granularity
            const mappedRevenue =
                data.allBarcodesRevenue.timeSeries.length > 0
                    ? mapTimeseriesForTooltip(
                          xAxisCategories,
                          data.allBarcodesRevenue.timeSeries[0].points,
                          granularity,
                      )
                    : []
            if (this._isMounted) {
                this.setState({
                    mappedRevenue,
                    total: total.value.toFixed(0),
                    loading: false,
                })
            }
        } catch {
            this.props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Undated barcodes chart could not be loaded, please try again later.',
            )
        }
    }

    formatMoney = (amount: string | number) => {
        return this.props.formatCurrencyString(amount, this.props.accountSlug)
    }

    chartTooltipFormatter = (params: TooltipParams[]) => {
        const revenuePoint = this.state.mappedRevenue[params[0].dataIndex]
        const revenue = revenuePoint ? revenuePoint.value.toFixed(2) : '0'
        const granularity = this.props.chart.customGranularity ? 'customTimeseries' : this.props.chart.granularity
        const xAxisLabel = revenuePoint
            ? getTooltipXAxisLabel(revenuePoint.timestamp, granularity)
            : params[0].axisValue

        let tooltip = getTooltipHeader(xAxisLabel)
        tooltip += getTooltipRow([`${params[0].marker} Redeemed:`, params[0].value, `(${this.formatMoney(revenue)})`])
        tooltip += getTooltipFooter()

        return tooltip
    }

    render() {
        const series = this.props.chart.props().series
        const axisData = this.props.chart.props().xAxisData
        const { loading } = this.state
        const legendData = this.props.chart.props().legendData
        if (series.length > 0) {
            series[0].color = '#5298F4'
        }
        return (
            <div style={{ position: 'relative' }}>
                {loading && <ChartDataLoader />}
                <ChartWrapperWithTotals total={this.state.total} id="transactions-total" title="Undated Barcodes">
                    <TimeseriesBarChart
                        axisData={axisData}
                        series={series}
                        legendData={legendData.length > 1 ? legendData : null}
                        loading={loading}
                        tooltipFormatter={this.chartTooltipFormatter}
                        legendDisabled
                    />
                </ChartWrapperWithTotals>
            </div>
        )
    }
}

export default withCurrency(UndatedBarcodesChart)
