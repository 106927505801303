import * as React from 'react'
import { match as RouteMatch } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import { PricingService } from '../pricingService'
import { ArticleService } from 'admin/articleService'
import { StatsService } from 'http/statsService'
import { History, Location } from 'history'
import ExceptionFormRouter from './exceptionFormRouter'
import ExceptionEdit from './exceptionEdit'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { MessageKind } from 'uiComponents/messages'
import { ChannelsService } from 'channels/channelsService'

interface ProductPricingPageParams {
    accountSlug: string
    id: string
    copy_from: string
}

interface ExceptionDetailPageProps {
    history: History
    location: Location
    pricingService: PricingService
    articleService: ArticleService
    statsService: StatsService
    channelsService: ChannelsService
    match: RouteMatch<ProductPricingPageParams>
    navigation: Navigation
    setActiveSection: (section: string, header?: string) => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    hasPermission: (permission: string, accountSlug: string) => boolean
    className?: string
}

class ExceptionDetailPage extends React.Component<ExceptionDetailPageProps> {
    async componentDidUpdate(prevProps: ExceptionDetailPageProps) {
        if (prevProps.match.params.accountSlug !== this.props.match.params.accountSlug) {
            this.props.history.push(`/account/${this.props.match.params.accountSlug}/products/pricing/exceptions/list`)
        }
    }

    render() {
        const overrideId = this.props.match.params.id
        const accountSlug = this.props.match.params.accountSlug
        const prototypeId = this.props.navigation.query().copy_from
        const { pricingService, history, channelsService, setActiveSection, className, statsService } = this.props
        return (
            <Switch>
                <Route
                    path="/account/:accountSlug/products/pricing/exceptions/duplicate"
                    render={() => (
                        <ExceptionEdit
                            className={className}
                            key="new"
                            id={prototypeId}
                            prototype={true}
                            accountSlug={accountSlug}
                            history={history}
                            pricingService={pricingService}
                            articleService={this.props.articleService}
                            statsService={statsService}
                            channelsService={channelsService}
                            setActiveSection={setActiveSection}
                            replaceTopMessages={this.props.replaceTopMessages}
                            removeAllMessages={this.props.removeAllMessages}
                            hasPermission={this.props.hasPermission}
                        />
                    )}
                />
                <Route
                    path="/account/:accountSlug/products/pricing/exceptions/create"
                    render={() => (
                        <ExceptionFormRouter
                            key="new"
                            className={className}
                            history={this.props.history}
                            accountSlug={accountSlug}
                            pricingService={pricingService}
                            articleService={this.props.articleService}
                            channelsService={channelsService}
                            statsService={statsService}
                            setActiveSection={setActiveSection}
                            replaceTopMessages={this.props.replaceTopMessages}
                            removeAllMessages={this.props.removeAllMessages}
                            hasPermission={this.props.hasPermission}
                        />
                    )}
                />
                <Route
                    path="/account/:accountSlug/products/pricing/exceptions/:id"
                    render={() => (
                        <ExceptionEdit
                            className={className}
                            key={overrideId}
                            id={overrideId}
                            accountSlug={accountSlug}
                            history={history}
                            pricingService={pricingService}
                            statsService={statsService}
                            channelsService={channelsService}
                            articleService={this.props.articleService}
                            setActiveSection={setActiveSection}
                            replaceTopMessages={this.props.replaceTopMessages}
                            removeAllMessages={this.props.removeAllMessages}
                            hasPermission={this.props.hasPermission}
                        />
                    )}
                />
            </Switch>
        )
    }
}

export default withNavigation(ExceptionDetailPage)
