import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { ModalDialog, ModalDialogTitle } from 'uiComponents/popups/modal'
import * as config from 'config'
import { BaseRouteParams } from 'hocs'
import { User } from 'auth/state'
import { AuthTicket } from 'http/oauthService'
import { ORDER_PATHS } from 'orders/paths'
import { pathUtils } from 'utils/pathnameFormatter'
import { invalidateCartTag } from 'orders/reduxQueries'
import { connect, useDispatch } from 'react-redux'
import { AppServices } from 'middleware'
import { withServices } from 'middleware/hocs'
import { State } from '../../store'

interface EditCartProps {
    user: User
    ticket: AuthTicket
    services: typeof AppServices
}

const EditCart = ({ user, ticket, services: { loginService } }: EditCartProps) => {
    const { accountSlug, id } = useParams<BaseRouteParams & { id: string }>()
    const history = useHistory()
    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch(invalidateCartTag())
        history.push(pathUtils.populateParams(ORDER_PATHS.fullPaths.cartPage, { accountSlug }))
    }

    // @ts-ignore
    window.getResellerJwt = async () => {
        const authTicket = await loginService.ensureTokenNotExpired(ticket)
        return authTicket.accessToken
    }

    React.useEffect(() => {
        document.getElementById('convious_loader')?.remove()
        const loaderEndpoint = config.getRequired('convious-loader-endpoint')
        const userAuth = user?.resellerId ? `&reseller_id=${user.resellerId}` : '&use-cp-auth=true'
        const script = document.createElement('script')
        script.id = 'convious_loader'
        script.src = `${loaderEndpoint}loader.js?k=${accountSlug}&type=inline&_convious_=${id}${userAuth}`
        document.head!.appendChild(script)
    }, [accountSlug, id, user])

    return (
        <ModalDialog onDismiss={closeModal} style={{ width: '70vw', top: '10vh' }}>
            <ModalDialogTitle>Edit cart</ModalDialogTitle>
            <div style={{ maxHeight: '70vh' }} id="convious-inline-checkout" />
        </ModalDialog>
    )
}

const mapStateToProps = (state: State) => {
    return {
        user: state.auth.user,
        ticket: state.auth.ticket,
    }
}

export default connect(mapStateToProps)(withServices(EditCart))
