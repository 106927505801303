import React, { useState, useEffect } from 'react'
import DayPicker, { Modifiers, DayModifiers } from 'react-day-picker'
import { isBefore, startOfDay, endOfDay } from 'date-fns'
import { createDateRange, DateRange } from 'dateRanges'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { getIsUSPartner } from 'auth/selectors'
import { useAppSelector } from 'store/hooks'
import './styles.scss'

interface DateRangePickerProps {
    rangeColor: string
    dateRange: DateRange
    disabledDays: { after: Date }
    modifiers?: Partial<Modifiers>
    month: Date
    fromMonth: Date
    toMonth: Date
    onDateRangeChange: (dateRange: DateRange) => void
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
    rangeColor,
    dateRange: initialDateRange,
    disabledDays,
    modifiers: initialModifiers,
    month,
    fromMonth,
    toMonth,
    onDateRangeChange,
}) => {
    const [dateRange, setDateRange] = useState<DateRange>(initialDateRange)
    const [newFrom, setNewFrom] = useState<Date | null>(null)
    const [mouseOverTo, setMouseOverTo] = useState<Date | null>(null)
    const isUSPartner = useAppSelector(getIsUSPartner)

    useEffect(() => {
        if (!areDateRangeDatesEqual(initialDateRange, dateRange)) {
            setDateRange(initialDateRange)
        }
    }, [initialDateRange, dateRange])

    const handleDayMouseEnter = (day: Date, modifiers: DayModifiers) => {
        if (modifiers.disabled) {
            return
        }
        if (newFrom) {
            setMouseOverTo(day)
        }
    }

    const handleDayClick = (day: Date, modifiers: DayModifiers) => {
        if (modifiers.disabled) {
            return
        }
        if (!newFrom) {
            setNewFrom(day)
            setMouseOverTo(day)
        } else if (newFrom && mouseOverTo) {
            const from = isBefore(newFrom, mouseOverTo) ? newFrom : mouseOverTo
            const to = isBefore(newFrom, mouseOverTo) ? mouseOverTo : newFrom
            const newDateRange = createDateRange(startOfDay(from), endOfDay(to))
            setDateRange(newDateRange)
            setNewFrom(null)
            onDateRangeChange(newDateRange)
        }
    }

    const selectedDays =
        newFrom && mouseOverTo ? [{ from: newFrom, to: mouseOverTo }] : [{ from: dateRange.from, to: dateRange.to }]
    const modifiers = {
        ...initialModifiers,
        edgeDate: newFrom ? [newFrom, mouseOverTo] : [dateRange.from, dateRange.to],
    }

    return (
        <DayPicker
            month={month}
            fromMonth={fromMonth}
            toMonth={toMonth}
            numberOfMonths={2}
            firstDayOfWeek={isUSPartner ? 0 : 1}
            selectedDays={selectedDays}
            disabledDays={disabledDays}
            onDayClick={handleDayClick}
            onDayMouseEnter={handleDayMouseEnter}
            modifiers={modifiers}
            weekdaysShort={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
            className={`day-picker ${rangeColor}`}
        />
    )
}

export default DateRangePicker
