import React, { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { pathUtils } from 'utils/pathnameFormatter'
import styled from 'styled-typed'
import { Grid } from 'uiComponents/layout'
import { Tab, TabRow } from 'uiComponents/navigation/tabs'
import { LoaderWrapper } from 'uiComponents/loaders'
import { Parkmap } from '../models'
import { CODES_PATHS, ParkMapPagesParams } from '../paths'
import classNames from 'classnames'
import { BaseRouteParams } from 'hocs'
import ParkMapViewerProvider from '../context/mapViewer'
import { useLazyGetParkMapQuery } from '../reduxQuery'
import MapViewer from './mapViewer'
import { useSelectedParkMapContext } from '../context/selectedParkMap'
import { usePrevious } from 'reactUtils'

const TabsContent = styled.div`
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const ParkMapDetailsContainer: React.FC = ({ children }) => {
    const history = useHistory()
    const location = useLocation()
    const params = useParams<BaseRouteParams>()
    const { parkMapId, accountSlug } = useParams<ParkMapPagesParams>()
    const { setSelectedParkMap } = useSelectedParkMapContext()
    const [getParkMap, { isLoading }] = useLazyGetParkMapQuery()
    const prevSlug = usePrevious(accountSlug)

    useEffect(() => {
        if (prevSlug && prevSlug !== accountSlug) {
            history.push(`/account/${accountSlug}/venue/park_map`)
        }
    }, [accountSlug])

    useEffect(() => {
        if (parkMapId && parkMapId !== 'create') {
            getParkMap({ id: parkMapId })
                .unwrap()
                .then((data: Parkmap) => {
                    if (data) {
                        setSelectedParkMap(data)
                    }
                })
        }
    }, [])

    const isActive = (path: string) => location.pathname.includes(path)
    const createOnClick = (path: string) => () => {
        const formattedPath = pathUtils.addQueryString(pathUtils.populateParams(path, params), location.search)
        history.push(formattedPath)
    }

    return (
        <ParkMapViewerProvider>
            <LoaderWrapper loading={isLoading}>
                <Grid container>
                    <Grid item={6} style={{ padding: '2em' }}>
                        <TabRow>
                            <Tab
                                className={classNames({
                                    active: isActive('/details'),
                                })}
                                onClick={createOnClick(CODES_PATHS.fullPaths.mapsAddPage)}
                            >
                                Map
                            </Tab>
                            <Tab
                                className={classNames({
                                    active: isActive('/styles'),
                                })}
                                onClick={createOnClick(CODES_PATHS.fullPaths.stylesAddPage)}
                            >
                                Style
                            </Tab>
                            <Tab
                                className={classNames({
                                    active: isActive('/cat_poi'),
                                })}
                                onClick={createOnClick(CODES_PATHS.fullPaths.poisAddPage)}
                            >
                                Point of Interests (POI)
                            </Tab>
                        </TabRow>
                        <TabsContent>{children}</TabsContent>
                    </Grid>
                    <Grid item={6} style={{ paddingBottom: 0, paddingLeft: 0, position: 'relative' }}>
                        <MapViewer />
                    </Grid>
                </Grid>
            </LoaderWrapper>
        </ParkMapViewerProvider>
    )
}

export default ParkMapDetailsContainer
