import React, { useMemo } from 'react'

import { useGetCommissionQuery } from 'products/reduxApi'
import { Col, Row } from 'uiComponents/flex'
import { SingleSelectFieldFormik, SingleSelectOption } from 'uiComponents/input'
import { TextWithHorizontalLine } from 'uiComponents/pageElements'
import { Section } from './common'
import Infotip from 'uiComponents/infotip'

interface Props {
    commissionTooltipText?: string
}

const Commission: React.FC<Props> = ({ commissionTooltipText }) => {
    const { data } = useGetCommissionQuery({})

    const commissionGroup = useMemo<SingleSelectOption[]>(() => {
        if (!data) {
            return [] as any
        }

        return data?.map((group) => ({ value: group.id, name: group.name }))
    }, [data])

    return (
        <>
            <Section span={12}>
                <TextWithHorizontalLine
                    style={{ marginTop: '0.8em', marginBottom: '1em' }}
                    text="Contract information"
                    alignment="left"
                />
                <Row>
                    <Col span={6} style={{ padding: '0 1em 0 0' }}>
                        <SingleSelectFieldFormik
                            data-testid="commissionId"
                            disabled
                            name="commissionGroupId"
                            withClear
                            label={
                                <>
                                    Commission Group
                                    <Infotip maxWidth="300px" pointer="left">
                                        {commissionTooltipText ||
                                            'If left empty or not set, the system will automatically use the "Product List Commission Group" value to determine the commission group.'}
                                    </Infotip>
                                </>
                            }
                            options={commissionGroup}
                        />
                    </Col>
                </Row>
            </Section>
        </>
    )
}

export default Commission
