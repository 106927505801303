import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import styled from 'styled-typed'
import { Tooltip } from 'uiComponents/tooltip'

const Container = styled.div`
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors.border};
`

const Header = styled.div`
    position: relative;
    background-color: ${(props) => props.theme.colors.background};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 32px;
    padding: 0 8px;
`

const Title = styled.div`
    font-size: 0.875rem;
    font-weight: 500;
    margin-right: 8px;
`

const Content = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 12px 16px;
`

const CloseIcon = styled(FontAwesomeIcon)`
    position: absolute;
    right: 8px;
    cursor: pointer;
    opacity: 0.4;
    &:hover {
        opacity: 1;
    }
`

const TooltipContainer = styled.div`
    position: relative;
`

const TooltipText = styled.div`
    color: ${(props) => props.theme.colors.white};
    font-size: 0.875rem;
`

interface CommonFilterProps {
    title: string
    description?: string
    filterId?: number
    onRemove: (ruleId?: number) => void
}

const CommonFilter: React.FC<CommonFilterProps> = (props) => {
    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)

    return (
        <Container>
            <Header>
                <Title>{props.title}</Title>
                {props.description && (
                    <TooltipContainer
                        onMouseEnter={() => setTooltipVisible(true)}
                        onMouseLeave={() => setTooltipVisible(false)}
                    >
                        <FontAwesomeIcon icon={faInfoCircle} />
                        {tooltipVisible && (
                            <Tooltip style={{ position: 'absolute', left: 28, top: -12, whiteSpace: 'nowrap' }}>
                                <TooltipText>{props.description}</TooltipText>
                            </Tooltip>
                        )}
                    </TooltipContainer>
                )}
                {props.filterId && <CloseIcon icon={faTimes} onClick={() => props.onRemove(props.filterId)} />}
            </Header>
            <Content>{props.children}</Content>
        </Container>
    )
}

export default CommonFilter
