import React, { useState } from 'react'
import { find } from 'lodash'
import styled from 'styled-components'

import { Category } from 'venue/parkMap/models'
import { Headline } from 'uiComponents/typography'
import ActionButton from 'uiComponents/buttons'
import useExpandedCategory from '../../../hooks/useExpandedCategory'
import { RowActions, RowContainer } from './shared'
import { generalIcons, animalsIcons } from '@convious/tap-icons'
import { Draggable, Droppable } from 'react-beautiful-dnd'

const CategoryListItem = styled.div`
    border: 1px solid ${(props) => props.theme.colors.border};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    padding: 8px 16px 8px 8px;
`

const CategoryColor = styled.div`
    width: 8px;
    height: 24px;
    border-radius: 2px;
    margin-right: 17px;
`

interface CategoryLiProps {
    index: number
    data: Category
    onAddPoi(): void
    onEditCategory(categoryId: string): void
    onDeleteCategory(categoryId: string): void
    children: React.ReactNode
}

const CategoryLi: React.FC<CategoryLiProps> = ({
    index,
    data,
    children,
    onEditCategory,
    onAddPoi,
    onDeleteCategory,
}) => {
    const { getExpandedIndex, saveExpandedIndex } = useExpandedCategory()
    const [expanded, setExpanded] = useState<boolean>(getExpandedIndex() === index)
    const content = find(data.content, { locale: 'en' }) ?? find(data.content, { default: true })
    const categoryIcon = find([...generalIcons, ...animalsIcons], { name: data.icon })

    const expand = () => {
        setExpanded(!expanded)
        saveExpandedIndex(index)
    }

    return (
        <Draggable key={data.id} draggableId={data.id} index={index}>
            {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <Droppable key={data.id} droppableId={data.id} type="droppable-poi">
                        {(provided) => (
                            <div
                                style={{ margin: '0 0 0.5rem 0' }}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <CategoryListItem>
                                    <RowContainer>
                                        <CategoryColor style={{ backgroundColor: data.color }} />
                                        {categoryIcon ? (
                                            <categoryIcon.icon
                                                style={{ width: '1.25rem', height: '1.25rem', marginRight: '0.313rem' }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                        <Headline size={6} style={{ margin: 0 }}>
                                            {content?.name}
                                        </Headline>
                                    </RowContainer>
                                    <RowActions
                                        id={data.id}
                                        onEdit={onEditCategory}
                                        expanded={expanded}
                                        setExpanded={expand}
                                        onDelete={onDeleteCategory}
                                    />
                                </CategoryListItem>
                                {expanded && (
                                    <>
                                        <div>
                                            {children && <div>{children}</div>}
                                            {provided.placeholder}
                                        </div>
                                        <RowContainer style={{ justifyContent: 'flex-end', margin: '0.5rem 0 1rem 0' }}>
                                            <ActionButton
                                                secondary
                                                kind="action"
                                                defaultChecked
                                                style={{ right: 0 }}
                                                onClick={onAddPoi}
                                            >
                                                + Add POI
                                            </ActionButton>
                                        </RowContainer>
                                    </>
                                )}
                            </div>
                        )}
                    </Droppable>
                </div>
            )}
        </Draggable>
    )
}

export default CategoryLi
