import styled from 'styled-typed'
import { Col } from 'uiComponents/flex'
import { ProductList } from 'admin/articleService'

export const LEFT_SIDE_SPAN = 6
export const RIGHT_SIDE_SPAN = 6

export const Section = styled(Col)`
    margin-bottom: 2em;
`

export const ImgLink = styled.a`
    text-decoration: none;
    font-size: 0.875em;
    font-weight: 300;
    color: ${(p) => p.theme.colors.boyBlue};
`

export const DeleteNote = styled.div`
    margin: 0 1em 0 1em;
    display: flex;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
    > span {
        font-size: 0.8rem;
    }
`

export const UploadExplanation = styled.div`
    float: right;
`

export const ImageUrlCol = styled(Col)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

export function generateSlug() {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (let i = 0; i < 15; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

export interface ProductListWithPath extends ProductList {
    path: string
    parentIds: string[]
}

export function iterateProductLists(
    pls: ProductList[],
    parentName: string,
    mapped: ProductListWithPath[],
    parents: string[] = [],
): ProductListWithPath[] {
    if (pls && pls.length > 0) {
        pls.forEach((pl: ProductList) => {
            const path = (parentName ? `${parentName} --> ` : '') + pl.name
            const parentIds = [...parents]
            parentIds.push(pl.uuid)
            const element = { ...pl, path: path, parentIds: parentIds }
            mapped.push(element)
            if (!pl.hasChildren || pl.children.length === 0) {
                return mapped
            } else {
                return iterateProductLists(pl.children, path, mapped, parentIds)
            }
        })
        return mapped
    }
    return []
}

export function addCategoryBranchToList(allLoadedCats: ProductList[], pl: ProductList): ProductList[] {
    const all = allLoadedCats
    all.push(pl)
    if (pl.hasChildren) {
        pl.children.map((ch) => {
            addCategoryBranchToList(all, ch)
        })
    } else {
        return all
    }
    return all
}
