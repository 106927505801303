import * as React from 'react'
import ReactEcharts from 'echarts-for-react'
import { withTheme } from 'styled-typed'
import { DashboardTheme } from 'theme'
import { NoDataNotice, tooltipStyle } from 'uiComponents/charts/styleComponents'
import { TooltipParams } from './timeseriesBarChart'

export interface ChartSeries {
    name: string
    type: string
    barWidth?: string | number
    barGap?: string | number
    xAxisIndex?: number
    data: any
    color?: string
    label?: {
        show?: boolean
        formatter?: (params: any) => string
        backgroundColor?: string
        color?: string
        padding: number[]
        borderRadius: number[]
    }
}

export interface Dataset {
    id?: string
    source: any[]
    dimensions?: []
    sourceHeader?: boolean
}

interface Props {
    loading: boolean
    title?: string
    series: ChartSeries[]
    theme: DashboardTheme
    xAxis: any[]
    yAxisName?: string
    xAxisInterval?: number | string
    rotateNames?: boolean
    percent?: boolean
    chartHeight?: string
    gridTop?: number
    gridBottom?: number
    gridLeft?: number
    gridRight?: number
    tooltipFormatter?: (params: TooltipParams) => string
    xAxisFormatter?: (value: string, index: number) => string
}

const Chart = (props: Props & React.HTMLAttributes<HTMLElement>) => {
    const [chartOption, setChartOption] = React.useState<any>({})

    React.useEffect(() => {
        loadBarChartData()
    }, [props])

    const getDataLength = () => {
        return props.series[0].data.length
    }

    const formatLegend = () => {
        const legend = props.series.length > 1 ? props.series.map((d) => d.name) : []
        return legend
    }

    const loadBarChartData = () => {
        const barChartOption = {
            textStyle: {
                fontFamily: 'Roboto',
            },
            grid: {
                top: 80,
                bottom: 55,
                left: 55,
                right: 0,
            },
            legend: {
                top: 15,
                left: 55,
                padding: 3,
                itemGap: 20,
                type: 'scroll',
                data: formatLegend(),
                show: true,
                selectedMode: false,
            },
            tooltip: {
                formatter: props.tooltipFormatter,
                ...tooltipStyle,
            },
            xAxis: props.xAxis,
            yAxis: [
                {
                    name: props.yAxisName,
                    nameTextStyle: {
                        fontSize: 14,
                        color: '#A4AFB2',
                        align: 'center',
                    },
                    axisLine: { show: false },
                    axisTick: { show: false },
                    axisLabel: {
                        color: '#A4AFB2',
                    },
                    splitLine: {
                        lineStyle: {
                            color: 'rgba(164, 175, 178, 0.3)',
                        },
                    },
                },
            ],
            series: props.series,
            color: props.theme.colors.chartRotatingColors,
        }

        setChartOption(barChartOption)
    }

    return (
        <NoDataNotice dataLength={getDataLength()} loading={props.loading}>
            <ReactEcharts
                option={chartOption}
                style={{
                    height: props.chartHeight ? props.chartHeight : '300px',
                    ...props.style,
                }}
                notMerge={true}
                lazyUpdate={true}
                onChartReady={() => {}}
            />
        </NoDataNotice>
    )
}

export default withTheme(Chart)
