import { createContext } from 'react'
import { ArticleFromApi, CategoryFromApi } from '../../../products/types'

export interface StudioContextValue {
    products: CategoryFromApi[]
    articles: ArticleFromApi[]
    isLoading: boolean
}

const StudioContext = createContext<StudioContextValue>({
    products: [],
    articles: [],
    isLoading: false,
})

export default StudioContext
