import React from 'react'
import styled from 'styled-typed'
import { DataTable, HeaderRow, DataRow, TableHeader, Cell } from 'uiComponents/table'
import { Row } from 'uiComponents/pageElements'
import { SecondaryText } from 'uiComponents/typography'
import Infotip from 'uiComponents/infotip'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { OverviewAdjustmentData } from '../pricingService'
import { PricingType } from 'products/articleConfigurationService'
import { ArticleListItem } from 'admin/articleService'

export const Error = styled.span`
    color: ${(p) => p.theme.colors.status.error};
`

export const Success = styled.span`
    color: ${(p) => p.theme.colors.status.success};
`

export const Highlighted = styled.span`
    font-weight: 500;
`

interface TableRowProps {
    status: 'error' | 'normal'
}

export const TableRow = styled(DataRow)<TableRowProps>`
    > ${Cell} {
        padding: 0 0.85em;
        cursor: default;
    }
    &:hover {
        > ${Cell} {
            font-weight: lighter;
        }
    }
    ${(p) => (p.status === 'error' ? `box-shadow: 0px 0px 0px 1px ${p.theme.colors.status.error};` : null)}
`

export const LinkText = styled.a`
    font-weight: lighter;
    text-decoration: none;
    color: ${(p) => p.theme.colors.textDark};
    &:hover {
        font-weight: 500;
    }
`

const Header = styled(TableHeader)`
    padding-right: 0.85em;
    padding-left: 0.85em;
`

const OutRow = styled(Row)`
    position: relative;
    height: 1.5em;
`
const OutCell = styled.div`
    padding: 0 0.85em;
    color: ${(p) => p.theme.colors.textLight};
    position: absolute;
`

export function fetchOkStatus(data: OverviewAdjustmentData): boolean | null {
    if (!data.price && !data.targetAvgPrice && !data.minPrice) {
        return null
    }
    if (
        (!data.price || (data.price && !data.price.error)) &&
        (!data.targetAvgPrice || (data.targetAvgPrice && !data.targetAvgPrice.error)) &&
        (!data.minPrice || (data.minPrice && !data.minPrice.error))
    ) {
        return true
    }
    return false
}

interface ExceptionOverviewTableProps {
    overviewData: OverviewAdjustmentData[]
    pricingType: PricingType | null
    exceptionProducts: ArticleListItem[]
    accountSlug: string
    formatCurrency: (amount: number, accountSlug: string) => React.ReactNode
    getCurrency: (accountSlug: string) => Currency
}

export function ExceptionOverviewTable(props: ExceptionOverviewTableProps) {
    const columnWidths = [null, '8em', '6em', '6em', '2em', '6em', '6em', '2em', '6em', '6em']

    const columnWidthsNarrow = [null, '13em', '9em', '9em']

    function fetchStatusDisplay(data: OverviewAdjustmentData): JSX.Element | null {
        const errors = []
        const status = fetchOkStatus(data)
        if (status === null) {
            return <>-</>
        }
        if (!!status) {
            return (
                <Highlighted>
                    <Success>OK</Success>
                </Highlighted>
            )
        }
        if (data.price && data.price.error) {
            errors.push(data.price.error)
        }
        if (data.targetAvgPrice && data.targetAvgPrice.error) {
            errors.push(data.targetAvgPrice.error)
        }
        if (data.minPrice && data.minPrice.error) {
            errors.push(data.minPrice.error)
        }
        if (errors.length > 0) {
            return (
                <>
                    <Highlighted>
                        <Error>Error</Error>
                    </Highlighted>
                    <Infotip pointer="left" maxWidth="30em">
                        {errors.map((e, i) => (
                            <div key={i}>{e}</div>
                        ))}
                    </Infotip>
                </>
            )
        }
        return null
    }

    function priceDisplay(
        data: OverviewAdjustmentData,
        priceSetting: 'price' | 'targetAvgPrice' | 'minPrice',
    ): JSX.Element {
        const priceObject = data[priceSetting]
        const price = priceObject ? props.formatCurrency(priceObject.new, props.accountSlug) : '-'
        return <Highlighted>{!priceObject ? '-' : priceObject.error ? <Error>{price}</Error> : price}</Highlighted>
    }

    function getProductNameString(id: string) {
        const product = props.exceptionProducts.find((p) => p.id === id)
        return product ? (product.name ? product.name : id) : id
    }

    function getProductName(id: string): JSX.Element {
        const name = getProductNameString(id)
        return (
            <LinkText href={`/account/${props.accountSlug}/products/pricing/${id}/detail`} target="_blank">
                {name}
            </LinkText>
        )
    }

    return (
        <div style={{ margin: '4em 0' }}>
            <div style={{ margin: '1em 0' }}>
                <SecondaryText style={{ fontStretch: 'expanded' }}>
                    HOW YOUR EXCEPTION AFFECTS PRICING SETTINGS
                </SecondaryText>
            </div>
            {(!props.pricingType || props.pricingType === 'nyop' || props.pricingType === 'rtp') && (
                <>
                    <OutRow>
                        <OutCell style={{ right: '28.9em' }}>Min. Price</OutCell>
                        <OutCell style={{ right: '16.7em' }}>Avg. Price</OutCell>
                        <OutCell style={{ right: '4.3em' }}>Max. Price</OutCell>
                    </OutRow>
                    <DataTable columnWidths={columnWidths}>
                        <HeaderRow>
                            <Header>Product</Header>
                            <Header>Status</Header>
                            <Header>Current</Header>
                            <Header>New</Header>
                            <Header nonInteractive />
                            <Header>Current</Header>
                            <Header>New</Header>
                            <Header nonInteractive />
                            <Header>Current</Header>
                            <Header>New</Header>
                        </HeaderRow>
                        {props.overviewData.map((od, i) => (
                            <TableRow key={i} status={fetchOkStatus(od) ? 'normal' : 'error'}>
                                <Cell className="withName" title={getProductNameString(od.productId)}>
                                    <span>{getProductName(od.productId)}</span>
                                </Cell>
                                <Cell style={{ overflow: 'visible' }}>{fetchStatusDisplay(od)}</Cell>
                                <Cell>
                                    {od.minPrice ? props.formatCurrency(od.minPrice.previous, props.accountSlug) : '-'}
                                </Cell>
                                <Cell>{priceDisplay(od, 'minPrice')}</Cell>
                                <Cell />
                                <Cell>
                                    {od.targetAvgPrice
                                        ? props.formatCurrency(od.targetAvgPrice.previous, props.accountSlug)
                                        : '-'}
                                </Cell>
                                <Cell>
                                    <Highlighted>{priceDisplay(od, 'targetAvgPrice')}</Highlighted>
                                </Cell>
                                <Cell />
                                <Cell>
                                    {od.price ? props.formatCurrency(od.price.previous, props.accountSlug) : '-'}
                                </Cell>
                                <Cell>{priceDisplay(od, 'price')}</Cell>
                            </TableRow>
                        ))}
                    </DataTable>
                </>
            )}
            {(props.pricingType === 'static' || props.pricingType === 'upsell') && (
                <DataTable columnWidths={columnWidthsNarrow}>
                    <HeaderRow>
                        <TableHeader>Product</TableHeader>
                        <TableHeader>Status</TableHeader>
                        <TableHeader>Current price</TableHeader>
                        <TableHeader>New price</TableHeader>
                    </HeaderRow>
                    {props.overviewData.map((od, i) => (
                        <TableRow key={i} status={fetchOkStatus(od) ? 'normal' : 'error'}>
                            <Cell className="withName">
                                <span>{getProductName(od.productId)}</span>
                            </Cell>
                            <Cell style={{ overflow: 'visible' }}>{fetchStatusDisplay(od)}</Cell>
                            <Cell>{od.price ? props.formatCurrency(od.price.previous, props.accountSlug) : '-'}</Cell>
                            <Cell>{priceDisplay(od, 'price')}</Cell>
                        </TableRow>
                    ))}
                </DataTable>
            )}
        </div>
    )
}

export default withCurrency(ExceptionOverviewTable)
