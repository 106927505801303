import * as React from 'react'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { DateAndTime, Order } from 'orders/schema'
import { convertTimeFormat, DateFormats, formatISOString } from 'utils/dates'

interface UpdateDialogProps {
    dialogType: 'visitDate' | 'visitTime'
    order?: Order
    updatedDateTime: DateAndTime
    onCancel: () => void
    onConfirm: () => void
}

export function UpdateDialog(props: UpdateDialogProps) {
    const title = props.dialogType === 'visitDate' ? 'Update visit date?' : 'Update visit time?'
    const orderDate =
        props.order && props.order.visitDate
            ? formatISOString(props.order.visitDate, DateFormats.EXTRA_LONG_DATE)
            : 'the date'
    const orderTime = props.order && props.order.visitTime ? convertTimeFormat(props.order.visitTime) : ''
    const date = props.updatedDateTime.date
        ? formatISOString(props.updatedDateTime.date, DateFormats.EXTRA_LONG_DATE)
        : orderDate
    const time = props.updatedDateTime.time ? convertTimeFormat(props.updatedDateTime.time) : orderTime
    const text = `Have you checked the availability on ${date}${time ? `, ${time}` : ''}?`

    return (
        <ConfirmationDialog
            title={title}
            text={text}
            buttonText="Update"
            onCancel={props.onCancel}
            onConfirm={props.onConfirm}
        />
    )
}
