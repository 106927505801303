import React from 'react'
import { IconProp } from '@fortawesome/fontawesome'
import { faCheckCircle, faExclamationTriangle, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Body, Text } from 'uiComponents/typography'
import { DiscountPoolUpload } from 'venue/bookingCodes/bookingCodesService'

interface CodeStatusProps {
    status: DiscountPoolUpload['status']
}

interface CodeOptions {
    key: string
    icon: IconProp
    status: 'success' | 'error' | 'processing'
}

const CODE_STATE: Record<DiscountPoolUpload['status'], CodeOptions> = {
    completed: {
        key: 'Successfully processed',
        icon: faCheckCircle as IconProp,
        status: 'success',
    },
    deleted: {
        key: 'Deleted',
        icon: faExclamationTriangle as IconProp,
        status: 'processing',
    },
    error: {
        key: 'Error',
        icon: faExclamationTriangle as IconProp,
        status: 'error',
    },
    expired: {
        key: 'Expired',
        icon: faExclamationTriangle as IconProp,
        status: 'error',
    },
    redeemed: {
        key: 'Redeemed',
        icon: faCheckCircle as IconProp,
        status: 'success',
    },
    valid: {
        key: 'Valid',
        icon: faCheckCircle as IconProp,
        status: 'success',
    },
    pending: {
        key: 'Pending',
        icon: faSpinner as IconProp,
        status: 'processing',
    },
}

const getCodeState = (status: DiscountPoolUpload['status']) => {
    const codeState = CODE_STATE[status]

    return (
        codeState || {
            key: 'Unknown',
            icon: faExclamationTriangle as IconProp,
            status: 'processing',
        }
    )
}

const CodeStatus: React.FC<CodeStatusProps> = ({ status }) => {
    const state = getCodeState(status)

    return (
        <Body size={1}>
            <Text status={state.status}>
                <FontAwesomeIcon icon={state.icon} style={{ marginRight: '.5rem' }} />
                {state.key}
            </Text>
        </Body>
    )
}

export default CodeStatus
