import React, { useState } from 'react'
import styled from 'styled-typed'
import { SingleSelect, SingleSelectOption, TextInput } from 'uiComponents/input'
import { IOption } from '../../types'
import RuleContainer from './container'

const Container = styled.div`
    padding: 1em;
    display: flex;
    flex-direction: row;

    & > div:not(:last-child) {
        width: 150px;
        background-color: ${(props) => props.theme.colors.white};
        border: 1px solid ${(props) => props.theme.colors.border};
    }

    input {
        width: 50px;
        margin-left: 0.5em;
    }
`

const Select = styled(SingleSelect)`
    width: 100px;
`

interface SelectQuantityProps {
    label: string
    options: IOption[]
    value?: IOption
    onChange: (value: IOption) => void
}

const FilterQuantity = (props: SelectQuantityProps) => {
    const [quantity, setQuantity] = useState<number>(0)
    const [operator, setOperator] = useState<string>(props.options[0].operator as string)

    const mappedOptions: SingleSelectOption[] = props.options.map(
        (option) =>
            ({
                name: option.caption,
                value: option.operator,
            } as SingleSelectOption),
    )

    const onChange = (value: string) => {
        setOperator(value)
        props.onChange({
            operator: value,
            value: quantity,
        })
    }

    const onChangeQuantity = (value: string) => {
        setQuantity(parseInt(value, 10))
        props.onChange({
            operator: operator,
            value: parseInt(value, 10),
        })
    }

    return (
        <RuleContainer label={props.label}>
            <Container>
                <Select
                    noSelectOption="Select"
                    options={mappedOptions}
                    onSelect={onChange}
                    selected={props.value?.operator as string}
                />
                <TextInput
                    id="quantity"
                    name="quantity"
                    placeholder="0"
                    type="number"
                    value={quantity || ''}
                    onChange={(e) => onChangeQuantity(e.target.value)}
                />
            </Container>
        </RuleContainer>
    )
}

export default FilterQuantity
