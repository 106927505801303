import React, { FC, useMemo } from 'react'
import { Button } from '@mui/material'
import {
    getAllSelectedOrderItemsOrdersUuids,
    getListOrderData,
    getSelectedOrderDetails,
    getSelectedOrdersDetails,
} from 'orders/orders/redux'
import { useAppSelector } from 'store/hooks'
import CircleCheckSolid from '@convious/icons/src/icons/solid/circleCheckSolid.svg'

export const FillDetailsButton: FC = () => {
    const selectedOrders = useAppSelector(getSelectedOrdersDetails)
    const selectedTicketsOrders = useAppSelector(getAllSelectedOrderItemsOrdersUuids)
    const selectedOrderItems = useAppSelector(getSelectedOrderDetails).filter((item) => !item.refundInfo)
    const orderList = useAppSelector(getListOrderData)
    const order = useMemo(() => {
        if (selectedOrders.length === 1) {
            return selectedOrders[0]
        } else if (selectedOrderItems.length > 0 && selectedOrders.length === 0) {
            return orderList.data?.results.find((order) => order.id === selectedTicketsOrders[0])
        }
        return null
    }, [orderList.data, selectedTicketsOrders])

    if (!order || !order.unfilledApf) {
        return null
    }

    return (
        <Button
            size="medium"
            color="primary"
            variant="outlined"
            startIcon={<CircleCheckSolid />}
            data-testid="orders-fill-apf-button"
            href={order.unfilledApfUrl}
            className="action-button"
        >
            Fill details
        </Button>
    )
}
