import React, { useState, useEffect, useRef } from 'react'
import { ColorPickerTrigger, PresetText } from 'uiComponents/colorPicker'

interface ColorInputProps {
    setColor: (color: string) => void
    value: string
}

export function ColorInput(props: ColorInputProps & React.InputHTMLAttributes<HTMLInputElement>) {
    const pickerRef = useRef(null)
    const [hex, setHex] = useState<string>('#ffff00')
    function openPicker() {
        if (!!pickerRef && !!pickerRef.current) {
            // @ts-ignore: Object is possibly 'null'.
            pickerRef.current.click()
        }
    }

    useEffect(() => {
        if (!!props.value) {
            setHex(props.value)
        }
    }, [props.value])

    function onColorSelect(e: React.ChangeEvent<HTMLInputElement>) {
        const c = e.target.value
        setHex(c)
        props.setColor(c)
    }

    const { setColor, value, ...rest } = props
    return (
        <>
            <ColorPickerTrigger onClick={openPicker} color={value} className="color-picker-trigger">
                {props.value === '' && <PresetText>preset</PresetText>}
            </ColorPickerTrigger>
            <input
                style={{ visibility: 'hidden', width: '0.1px' }}
                type="color"
                ref={pickerRef}
                onInput={onColorSelect}
                onChange={onColorSelect}
                value={hex}
                {...rest}
            />
        </>
    )
}
