import * as React from 'react'
import { State } from 'store'
import { Account } from 'auth/state'
import { connect } from 'react-redux'
import { Col } from 'uiComponents/flex'
import { SelectComponent, FormItem, SectionWrapper } from 'ticketTemplates/studio/imports'
import { SingleSelectOption } from 'uiComponents/input/singleSelect'
import CopySection from './copySection'
import { Locale } from 'admin/articleService'
import { Translation } from 'ticketTemplates/ticketTemplatesService'

interface LanguagesSectionProps {
    accounts: Account[]
    accountSlug: string
    handleTranslations: (value: string) => void
    handleLanguageChange: (value: string) => void
    moreInformation: Translation
    activeLanguage: string
    locales: Locale[]
    onFinishTyping: (v: string) => void
    additionalInfoError: string
}

function LanguagesSection(props: LanguagesSectionProps) {
    const [languageOptions, setlanguageOptions] = React.useState<SingleSelectOption[]>([])

    React.useEffect(() => {
        const test = props.locales.map((l) => ({
            value: l.code,
            name: l.name,
        }))
        setlanguageOptions(test)
    }, [props.locales])

    type SelectType = 'select'
    const translationLanguageField = {
        type: 'select' as SelectType,
        name: 'translation-language',
        label: 'Translation language',
        path: '',
        defaultValue: 'en',
        availableOptions: languageOptions,
    }

    return (
        <SectionWrapper title="Translations">
            <FormItem>
                <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor={translationLanguageField.name}>{translationLanguageField.label}</label>
                </Col>
                <Col span={6} className="justify-right">
                    <SelectComponent
                        id="translationsLanguage"
                        field={translationLanguageField}
                        handleChange={(v) => props.handleLanguageChange(v as string)}
                        value={props.activeLanguage}
                    />
                </Col>
            </FormItem>
            <CopySection
                handleTranslations={props.handleTranslations}
                activeLanguage={props.activeLanguage}
                moreInformation={props.moreInformation}
                onFinishTyping={props.onFinishTyping}
                additionalInfoError={props.additionalInfoError}
            />
        </SectionWrapper>
    )
}

function mapStateToProps(state: State) {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
    }
}
export default connect(mapStateToProps)(LanguagesSection)
