import React, { useState, useEffect, MouseEvent } from 'react'
import styled from 'styled-typed'
import { TableLoader } from 'uiComponents/loaders'
import { Activity, GroupedActivities } from 'crm/schema'
import { format, startOfToday } from 'date-fns'
import { ActivityItem } from 'crm/profiles/profileDetails/activityItem'
import { flatMap } from 'utils'

const GroupName = styled.div`
    font-size: 0.875em;
    font-weight: bold;
    margin: 1.3em 0 0.85em 1.5em;
`
const ItemWrapper = styled.div`
    position: relative;
    font-size: 0.75em;
    border: 1px solid ${(p) => p.theme.colors.tableRow};
    padding: 1.6em 1.5em 1.6em 4.25em;
    margin-bottom: 0.7em;

    span {
        opacity: 0.5;
    }
`

interface ProfileTimelinePageProps {
    accountSlug: string
    activities: Activity[]
    groupedActivities: GroupedActivities[]
    loading: boolean
    onSectionChange: () => void
}

function ProfileTimelinePage(props: ProfileTimelinePageProps) {
    const [activitiesExpanded, setActivitiesExpanded] = useState<string[]>([])
    const [eventsExpanded, setEventsExpanded] = useState<string[]>([])

    useEffect(() => {
        props.onSectionChange()
    }, [])

    useEffect(() => {
        setOpenedByDefaultItems()
    }, [props.activities])

    const setOpenedByDefaultItems = () => {
        const openEvents = ['Order', 'Payment']
        const activitiesWithOrderOrPayment = props.activities.filter(
            (a) => !a.activityEvents.every((ae) => openEvents.indexOf(ae.event) === -1),
        )
        const eventsToOpen = flatMap(activitiesWithOrderOrPayment, (x) => x.activityEvents)
            .filter((e) => openEvents.indexOf(e.event) > -1)
            .map((e) => e.timestamp)
        setActivitiesExpanded(activitiesWithOrderOrPayment.map((a) => a.activityStartTime))
        setEventsExpanded(eventsToOpen)
    }

    const setActivityItemExpanded = (item: string) => {
        const updatedList = [...activitiesExpanded]
        if (updatedList.indexOf(item) > -1) {
            updatedList.splice(updatedList.indexOf(item), 1)
        } else {
            updatedList.push(item)
        }
        setActivitiesExpanded(updatedList)
    }

    const setEventItemExpanded = (ev: MouseEvent, item: string) => {
        ev.stopPropagation()
        const updatedList = [...eventsExpanded]
        if (updatedList.indexOf(item) > -1) {
            updatedList.splice(updatedList.indexOf(item), 1)
        } else {
            updatedList.push(item)
        }
        setEventsExpanded(updatedList)
    }

    const { loading, activities, groupedActivities } = props

    return (
        <div>
            {loading && <TableLoader />}
            {!loading && activities.length === 0 && <ItemWrapper>No entries</ItemWrapper>}
            {!loading &&
                activities.length > 0 &&
                Object.keys(groupedActivities).map((key) => (
                    <div key={key}>
                        {key !== format(startOfToday(), 'MMMM yyyy') && <GroupName>{key}</GroupName>}
                        {groupedActivities[key].map((a: Activity) => (
                            <ActivityItem
                                key={a.activityStartTime}
                                activity={a}
                                accountSlug={props.accountSlug}
                                activityExpanded={activitiesExpanded.indexOf(a.activityStartTime) > -1}
                                eventsExpanded={eventsExpanded}
                                setActivityItemExpanded={setActivityItemExpanded}
                                setEventItemExpanded={setEventItemExpanded}
                            />
                        ))}
                    </div>
                ))}
        </div>
    )
}

export default ProfileTimelinePage
