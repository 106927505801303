import React from 'react'
import { useTable } from 'react-table'

import ReactTableComponent from './reactTableComponent'
import { ReactTableInstance, ReactTableProps } from './interface'
import { usePostInitiTableInstance, usePrepareTableInstanceOptions } from './tableState'
import './index.css'
import ReactTableContext from './reactTableContext'

const ReactTable: React.FC<ReactTableProps> = ({
    columns,
    data,
    pagination,
    totalCount,
    sort,
    rowSelect,
    sticky,
    elevation,
    loading,
    noResultsRow,
    event,
    tableProps: tableComponentProps,
    bodyProps,
    size,
    cellProps,
    expanded,
    rowContainerProps,
    rowProps,
    stateReducer,
    loadData,
    disableRowFromSelecting,
    storeStateInQuery = true,
    id,
    children,
    backgroundColor,
    bordered,
    headerProps,
    innerTable,
}) => {
    const innerTotal = totalCount || data?.length || 0

    const { tableProps, plugins, immutableTableState } = usePrepareTableInstanceOptions({
        columns,
        data,
        pagination,
        sort,
        rowSelect,
        sticky,
        event,
        expanded,
        pageCount: innerTotal,
        stateReducer,
        loadData,
        disableRowFromSelecting,
        storeStateInQuery,
    })

    const tableInstance = useTable(tableProps, ...plugins) as ReactTableInstance
    usePostInitiTableInstance({
        tableInstance,
        event,
        loadData,
        storeStateInQuery,
    })

    return (
        <ReactTableContext.Provider
            value={{
                cellProps: cellProps,
                noResultsRow: noResultsRow,
                sticky: sticky,
                size: size,
                pagination: pagination,
                instance: tableInstance,
                itemsTotalCount: innerTotal,
                elevation: elevation,
                displayFooter: !!columns.filter((column) => !!column.Footer).length,
                tableState: immutableTableState,
                bodyProps: bodyProps,
                tableProps: tableComponentProps,
                rowContainerProps: rowContainerProps,
                expanded: expanded,
                rowProps: rowProps,
                loading: loading,
                backgroundColor: backgroundColor,
                bordered: bordered,
                headerProps: headerProps,
                innerTable: innerTable,
            }}
        >
            {children || <ReactTableComponent id={id} />}
        </ReactTableContext.Provider>
    )
}

export default ReactTable
