import { BaseRouteParams } from 'hocs'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useGetFeatureValue, useHasFeatureFunc } from 'utils/useHasFeature'

export interface SupersetReportFeatureFlag {
    title: string
    menuTitle: string
    dashboardId: string
    featureFlag?: string
    height?: string
}

export const useListSupersetReports = () => {
    const hasFeature = useHasFeatureFunc()
    const reportsValue = useGetFeatureValue('superset-reports') as SupersetReportFeatureFlag[] | null
    const { accountSlug } = useParams<BaseRouteParams>()

    return useMemo(
        () =>
            reportsValue?.filter((report) => {
                if (!!report.featureFlag) {
                    return hasFeature(report.featureFlag)
                }

                return true
            }) || [],
        [reportsValue, accountSlug],
    )
}

export const useGetSupersetReport = (dashboardId: string) => {
    const reports = useListSupersetReports()

    return useMemo(() => reports.find((report) => report.dashboardId === dashboardId), [dashboardId, reports])
}
