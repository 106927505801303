import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import ActionButton from 'uiComponents/buttons'
import { FormWrapper } from 'uiComponents/form/form'
import { LoaderWrapper } from 'uiComponents/loaders'
import { Column, ColumnGap, Row } from 'uiComponents/pageElements/pageElements'
import * as yup from 'yup'
import { BaseRouteParams } from '../../../hocs'
import AudiencesAddCondition from '../components/addCondition'
import useAudienceBuilder from '../hooks/useAudienceBuilder'
import { ICustomAudience, ICustomAudienceStatus, IFilter } from '../types'

export const Field = styled.div`
    margin-bottom: 2em;
`

const Buttons = styled.div`
    position: absolute;
    bottom: 2em;
    right: 2em;
`

interface ICustomAudienceFormProps {
    filters: IFilter[]
    data: ICustomAudience
}

const CustomAudienceForm = ({ filters, data }: ICustomAudienceFormProps) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const { renderOptions, renderFilters, addFilter, save, isLoading } = useAudienceBuilder({ filters, data })
    const history = useHistory()

    const mapValidationSchema = yup.object().shape({
        name: yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Required'),
    })

    return (
        <LoaderWrapper loading={isLoading}>
            <FormWrapper
                enableReinitialize
                initialValues={{}}
                onSubmit={() => {}}
                validationSchema={mapValidationSchema}
            >
                <Row>
                    <Column style={{ marginTop: '2em', flexGrow: 1.2 }}>
                        {renderFilters()}
                        <AudiencesAddCondition onSelect={addFilter} />
                    </Column>
                    <ColumnGap />
                    <Column style={{ height: 'auto' }}>
                        {renderOptions()}
                        <Buttons>
                            <ActionButton
                                size="large"
                                secondary
                                style={{ border: 0, marginRight: '0.5em' }}
                                onClick={() => history.push(`/account/${accountSlug}/crm/audiences/custom`)}
                            >
                                Exit
                            </ActionButton>
                            <ActionButton
                                size="large"
                                secondary
                                style={{ marginRight: '0.5em' }}
                                onClick={() => save(ICustomAudienceStatus.DRAFT)}
                            >
                                Save as draft
                            </ActionButton>
                            <ActionButton size="large" type="submit" onClick={() => save(ICustomAudienceStatus.ACTIVE)}>
                                Publish
                            </ActionButton>
                        </Buttons>
                    </Column>
                </Row>
            </FormWrapper>
        </LoaderWrapper>
    )
}

export default CustomAudienceForm
