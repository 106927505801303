import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { InlineDataTable, HeaderRow, TableHeader } from 'uiComponents/table'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { NestedContainer, NestedElement } from 'products/components/nestedContainer'
import { PricingService } from 'products/pricing/pricingService'
import { Checkbox } from 'uiComponents/input'
import { Navigation, parseSearch } from 'navigation'
import { MessageKind } from 'uiComponents/messages'
import { ProductListWithPath } from 'products/crud/common'
import { ProductList } from 'admin/articleService'
import { PricingType } from 'products/articleConfigurationService'

interface ListTableProps {
    hasPermission: (permission: string, accountSlug: string) => boolean
    elements: NestedElement[]
    accountSlug: string
    pricingService: PricingService
    navigation: Navigation
    flat?: boolean
    deleteArticle: (id: string) => void
    deleteProductList: (id: string) => void
    duplicateProduct?: (id: string, name: string) => void
    updateProductListStatusAndPriority?: (id: string, enabled: boolean, priority: number) => void
    updateArticlePriority?: (id: string, priority: number) => void
    updateArticleStatus?: (id: string, enabled: boolean) => void
    hasFeature: (feature: string, slug: string) => boolean
    replaceTopMessages?: (id: string, status: MessageKind, text: string) => void
    hideMessage?: (id: string) => void
    removeAllMessages?: () => void
    flattenedCategories: ProductListWithPath[] | null
    accountCategories: ProductList[]
    setAccountCategories?: (cats: ProductList[]) => void
    orphans?: boolean
    backgroundLoading?: boolean
    origin?: '?from=flat' | '?from=nested' | ''
    showBulkEditButtons?: (v: boolean) => void
    bulkEditPricing?: boolean
    bulkEditValidity?: boolean
    cancelBulkEditPricing?: () => void
    cancelBulkEditValidity?: () => void
    updateArticleOriginalPrice?: (
        id: string,
        price: string,
        min: number | null,
        avg: number | null,
        gate: number | null,
        stepSize: string | null,
        charmPricing: boolean | null,
        pricingType: PricingType,
    ) => void
    refreshArticles?: () => void
    orphanArticlesExist?: boolean
}

function ProductListsTable(props: ListTableProps & RouteComponentProps<{}>) {
    const columnWidths =
        props.origin === '?from=flat'
            ? ['1em', '3em', null, '7em', '4.7em', '6.5em', '6.5em', '9.2em', '15em', '.5em']
            : ['3em', null, '7em', '4.7em', '6.5em', '6.5em', '9.2em', '15em', '.5em']

    const {
        elements,
        flat,
        pricingService,
        history,
        deleteArticle,
        deleteProductList,
        updateProductListStatusAndPriority,
        updateArticlePriority,
        updateArticleStatus,
        replaceTopMessages,
        hideMessage,
        removeAllMessages,
        origin,
        duplicateProduct,
    } = props

    const [allChecked, setAllChecked] = React.useState<boolean>(false)
    const search = props.location.search
    const params = parseSearch(search)
    const openElementIds = params.listIds?.split(',') || []

    return (
        <InlineDataTable columnWidths={columnWidths} id="products-list-page" bordered={!flat}>
            <HeaderRow>
                {props.origin === '?from=flat' && (
                    <TableHeader nonInteractive>
                        <Checkbox
                            id="selectArticle"
                            name="selectArticles"
                            checked={allChecked}
                            onChange={(e) => {
                                setAllChecked(e.target.checked)
                                if (props.showBulkEditButtons) {
                                    props.showBulkEditButtons(e.target.checked)
                                }
                            }}
                        />
                    </TableHeader>
                )}
                <TableHeader nonInteractive />
                <TableHeader>Name</TableHeader>
                <TableHeader>Priority</TableHeader>
                <TableHeader>Type</TableHeader>
                <TableHeader align="right" style={{ whiteSpace: 'nowrap' }}>
                    Min. Price
                </TableHeader>
                <TableHeader align="right" style={{ whiteSpace: 'nowrap' }}>
                    Max. Price
                </TableHeader>
                <TableHeader>Validity</TableHeader>
                <TableHeader nonInteractive />
                <TableHeader nonInteractive noPadding />
            </HeaderRow>
            <NestedContainer
                containerType="product"
                expanded={false}
                elements={elements}
                openRowsElementIds={openElementIds}
                flattenedCategories={props.flattenedCategories}
                accountCategories={props.accountCategories}
                orphans={props.orphans}
                pricingService={pricingService}
                accountSlug={props.accountSlug}
                hasPermission={props.hasPermission}
                grade={0}
                flat={flat}
                history={history}
                deleteArticle={deleteArticle}
                deleteProductList={deleteProductList}
                duplicateProduct={duplicateProduct}
                updateProductListStatusAndPriority={updateProductListStatusAndPriority}
                updateArticlePriority={updateArticlePriority}
                updateArticleStatus={updateArticleStatus}
                replaceTopMessages={replaceTopMessages}
                hideMessage={hideMessage}
                removeAllMessages={removeAllMessages}
                hasFeature={props.hasFeature}
                setAccountCategories={props.setAccountCategories}
                backgroundLoading={props.backgroundLoading}
                origin={origin}
                allChecked={allChecked}
                setAllChecked={setAllChecked}
                showBulkEditButtons={props.showBulkEditButtons}
                bulkEditPricing={props.bulkEditPricing}
                bulkEditValidity={props.bulkEditValidity}
                cancelBulkEditPricing={props.cancelBulkEditPricing}
                cancelBulkEditValidity={props.cancelBulkEditValidity}
                updateArticleOriginalPrice={props.updateArticleOriginalPrice}
                refreshArticles={props.refreshArticles}
                navigation={props.navigation}
            />
            {elements.length === 0 && !props.orphans && (
                <>
                    {props.orphanArticlesExist ? (
                        <NoResultsRow text="No categories were found." />
                    ) : (
                        <NoResultsRow text="Neither categories nor products were found." />
                    )}
                </>
            )}
        </InlineDataTable>
    )
}

export default withRouter(ProductListsTable)
