import { useFormikContext } from 'formik'
import * as React from 'react'
import styled from 'styled-components'
import phoneImage from '../assets/iphone.png'

const Container = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    padding-top: 50px;
`

const Image = styled.img`
    max-width: 250px;
`

const MessageBox = styled.div`
    position: absolute;
    top: 216px;
    width: 210px;
    height: 46px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
`

const MessageContainer = styled.div`
    width: 165px;
    height: 100%;
`

const MessageTitle = styled.div`
    white-space: nowrap;
    text-overflow: hidden;
    font-size: 0.6em;
    font-weight: bold;
    padding-bottom: 1px;
    padding-top: 4px;
`

const MessageBody = styled.div`
    font-size: 0.55em;
    line-height: 1.2em;
    height: 22px;
    overflow: hidden;
`

const LogoPlacement = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.5em;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    margin: 7px 7px 7px 5px;
    text-align: center;
    background: ${(props) => props.theme.colors.background};
`

const PhonePreview = () => {
    const { values } = useFormikContext<any>()

    return (
        <Container>
            <Image src={phoneImage} />
            <MessageBox>
                <LogoPlacement>LOGO</LogoPlacement>
                <MessageContainer>
                    <MessageTitle>{values?.content[0].title}</MessageTitle>
                    <MessageBody>{values?.content[0].body}</MessageBody>
                </MessageContainer>
            </MessageBox>
        </Container>
    )
}

export default PhonePreview
