import * as React from 'react'
import { Col } from 'uiComponents/flex'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { FieldType } from 'uiComponents/studio/form/fieldComponentSchema'
import { FormFieldComponent } from 'uiComponents/studio/form/fieldComponents'
import { FormLabel } from 'uiComponents/studio/form/formComponents'
import { usePromotion } from './context'
import { PromotionConfigPaths } from './types'
import styled from 'styled-components'
import { SecondaryText } from 'uiComponents/typography'
import Infotip from 'uiComponents/infotip'

type Props = {
    field: FieldType
    initialValue: string | number | string[]
}

export const DeleteNote = styled.div`
    margin: 1em 0 0 0;
    display: flex;
    align-items: center;
    > span {
        cursor: pointer;
    }
`

const FormField = (props: Props) => {
    const { fetchPartnerDetails, setValueForPath, getValueForPath } = usePromotion()
    const [value, setValue] = React.useState<string | number | string[]>(
        getValueForPath(props.field.path as PromotionConfigPaths),
    )

    React.useEffect(() => {
        setValue(props.initialValue)
    }, [getValueForPath(props.field.path as PromotionConfigPaths)])

    const handleInputChange = (inputValue: string | number | string[], path: string) => {
        setValue(inputValue)
        setValueForPath(inputValue, path)
    }

    const renderClearButton = () => {
        return (
            <DeleteNote
                onClick={() => {
                    setValueForPath(null, props.field.path)
                }}
            >
                <SecondaryText>x Clear</SecondaryText>
            </DeleteNote>
        )
    }

    const getValue = () => {
        switch (props.field.type) {
            case 'date':
                // @ts-ignore
                return value ? new Date(value) : null
            case 'checkbox':
                return !!value
            default:
                return value
        }
    }

    return (
        <FormItem key={props.field.name}>
            <Col span={12}>
                <FormLabel htmlFor={props.field.name}>
                    {props.field.label}
                    {props.field.infotip && (
                        <Infotip pointer="left" maxWidth="20em">
                            {props.field.infotip}
                        </Infotip>
                    )}
                </FormLabel>
            </Col>
            <Col span={12}>
                <FormFieldComponent
                    field={props.field}
                    handleChange={(
                        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | string | number | string[],
                        path: string,
                    ) => {
                        if (typeof e === 'string' || typeof e === 'number' || Array.isArray(e)) {
                            handleInputChange(e, path)
                        } else {
                            handleInputChange(e?.target?.value, path)
                        }
                    }}
                    value={getValue()}
                    checkValidity={(formValue) => {
                        if (props.field.path === 'partnerSlug' && formValue.length) {
                            fetchPartnerDetails(formValue)
                        }
                    }}
                />
                {props.field.path === 'endDate' && renderClearButton()}
            </Col>
        </FormItem>
    )
}

export default FormField
