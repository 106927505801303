import * as React from 'react'
import styled from 'styled-typed'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'uiComponents/typography'
import { Popup } from '../popups/popup'
import { specialize } from 'reactUtils'
import { MenuLink } from 'uiComponents/menus/'

const Container = styled.span`
    position: relative;
`

interface BorderProps {
    noBorder?: boolean
}
const MinimizedView = styled.div<BorderProps>`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 2.5em;
  border: ${(props) => (props.noBorder ? 'none' : '1px solid rgba(57,73,84,0.5);')}
  border-radius: .5em;
  font-size: 0.875em;
  font-weight: normal;
  padding: 0 1em;
  min-width: ${(props) => (props.noBorder ? '6em' : '7.7em')};
  background: ${(props) => props.theme.colors.white};
`
const PopupListHeader = styled(MinimizedView)`
    border: none;
    font-size: 1em;
    background: ${(props) => props.theme.colors.tableRow};
    height: 2.4em;
    min-width: ${(props) => (props.noBorder ? '5.8em' : '7.5em')};
`
const ExpandToggle = styled(FontAwesomeIcon)`
    margin-left: 1.3em;
    opacity: 0.7;
`

const PopupArea = styled(Popup)<BorderProps>`
  left: 0em;
  top: ${(props) => (props.noBorder ? '0.1em' : '-0.55em')};
  border-radius: 0.5em;
  font-size: 0.875em;
  width: 100%;
  border: ${(props) => (props.noBorder ? 'none' : '1px solid rgba(57,73,84,0.5);')}
  background: ${(props) => props.theme.colors.tableRow};
  box-shadow: none;
  font-weight: normal;
`

export const PopupList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
`

const PopupListItem = styled.li`
    &:last-child div {
        padding-bottom: 0.4em;
        border-radius: 0 0 0.5em 0.5em;
    }

    &:hover {
        color: ${(props) => props.theme.colors.boyBlue};
    }
`

const MenuItem = styled.div`
    padding-left: 0em !important;
    display: flex;
    align-items: baseline;
`

const RemoveButton = styled(Link)`
    display: block;
    padding: 0.2em 1em;
    font-size: 0.75em;
    color: ${(props) => props.theme.colors.textDark};
`

interface MenuState {
    expanded: boolean
}

export interface MenuOption {
    value: string
    text: string
    hidden?: boolean
}

interface DefaultSelectionElementProps<TOption extends MenuOption> {
    options: TOption[]
    emptySelectionText: string
}

export interface SelectionElementProps<TOption extends MenuOption> extends DefaultSelectionElementProps<TOption> {
    DefaultElement: ReactComponent<DefaultSelectionElementProps<TOption> & { style?: React.CSSProperties }>
}

interface DefaultListElementProps<TOption extends MenuOption> {
    option: TOption
    selectedOptions: TOption[]
    multiSelect: boolean
    onClick: (ev: React.MouseEvent<any>, option: TOption) => void
}

export interface ListElementProps<TOption extends MenuOption> extends DefaultListElementProps<TOption> {
    DefaultElement: ReactComponent<DefaultListElementProps<TOption> & { style?: React.CSSProperties }>
}

type ReactComponent<T> = React.ComponentClass<T> | React.StatelessComponent<T>

interface MenuProps<TOption extends MenuOption> {
    children?: React.ReactNode | undefined
    options: TOption[]
    selectionElement?: ReactComponent<SelectionElementProps<TOption>>
    listElement?: ReactComponent<ListElementProps<TOption>>
    value: string | string[]
    multiSelect?: boolean
    emptySelectionText?: string
    onChange?: (opt: TOption, all: TOption[]) => void
    listStyle?: React.CSSProperties
    noBorder?: boolean
}

function DefaultSelection({
    options,
    emptySelectionText,
    style,
}: DefaultSelectionElementProps<MenuOption> & { style?: React.CSSProperties }) {
    if (options.length === 1) {
        return <span style={style}>{options[0].text}</span>
    } else if (options.length === 2) {
        return (
            <span style={style}>
                {options[0].text} and {options[1].text}
            </span>
        )
    } else if (options.length > 2) {
        return (
            <span style={style}>
                {options[0].text}, {options[1].text} and more
            </span>
        )
    } else {
        return <span style={style}>{emptySelectionText}</span>
    }
}

function DefaultListElement({
    option,
    selectedOptions,
    multiSelect,
    onClick,
    style,
}: DefaultListElementProps<MenuOption> & { style?: React.CSSProperties }) {
    const selected = !!selectedOptions.find((x) => x.value === option.value)
    if (multiSelect) {
        return (
            <MenuItem style={style}>
                <MenuLink
                    onClick={selected ? (e) => e.preventDefault() : (e) => onClick(e, option)}
                    selected={selected}
                >
                    {option.text}
                </MenuLink>
                <RemoveButton onClick={(e) => onClick(e, option)} style={{ display: selected ? 'block' : 'none' }}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                </RemoveButton>
            </MenuItem>
        )
    } else {
        return (
            <MenuLink style={style} onClick={(e) => onClick(e, option)} selected={selected}>
                {option.text}
            </MenuLink>
        )
    }
}

export class PopupMenu<TOption extends MenuOption> extends React.Component<MenuProps<TOption>, MenuState> {
    popup: HTMLDivElement | null = null

    constructor(props: MenuProps<TOption>) {
        super(props)
        this.state = {
            expanded: false,
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.outsideClick, false)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.outsideClick, false)
    }

    outsideClick = (ev: MouseEvent) => {
        if (this.popup && this.popup.contains && !this.popup.contains(ev.target as Node) && this.state.expanded) {
            this.setState({
                expanded: false,
            })
        }
    }

    setPopupRef = (node: HTMLDivElement) => {
        this.popup = node
    }

    toggleExpanded = (e?: React.MouseEvent<any>) => {
        if (e) {
            e.preventDefault()
        }
        this.setState({ expanded: !this.state.expanded })
    }

    menuSelected = (e: React.MouseEvent<any>, opt: TOption) => {
        e.preventDefault()
        const handler = this.props.onChange
        if (handler) {
            handler(opt, this.props.options)
        }

        if (!this.props.multiSelect) {
            this.toggleExpanded()
        }
    }

    render() {
        const { value, options, children, multiSelect, emptySelectionText, listStyle, noBorder } = this.props
        const selectedValues = typeof value === 'string' ? [value] : value
        const selectedOptions = selectedValues
            .map((v) => options.find((o) => o.value === v))
            .filter((x) => x !== undefined) as TOption[]
        const SelectionElement =
            this.props.selectionElement || (DefaultSelection as ReactComponent<SelectionElementProps<TOption>>)
        const ListElement = this.props.listElement || (DefaultListElement as ReactComponent<ListElementProps<TOption>>)

        return (
            <Container ref={this.setPopupRef} className="popup-menu">
                <MinimizedView onClick={this.toggleExpanded} noBorder={noBorder}>
                    <SelectionElement
                        options={selectedOptions}
                        DefaultElement={DefaultSelection}
                        emptySelectionText={emptySelectionText || 'none'}
                    />
                    <ExpandToggle icon={faChevronDown} />
                </MinimizedView>
                <PopupArea visible={this.state.expanded} style={listStyle} noBorder={noBorder}>
                    <PopupList>
                        <PopupListHeader onClick={this.toggleExpanded} noBorder={noBorder}>
                            <SelectionElement
                                options={selectedOptions}
                                DefaultElement={DefaultSelection}
                                emptySelectionText={emptySelectionText || 'none'}
                            />
                            <ExpandToggle icon={faChevronUp} />
                        </PopupListHeader>
                        {options
                            .filter((x) => !x.hidden)
                            .map((opt, i) => (
                                <PopupListItem key={opt.value + i}>
                                    <ListElement
                                        option={opt}
                                        selectedOptions={selectedOptions}
                                        multiSelect={multiSelect || false}
                                        DefaultElement={DefaultListElement}
                                        onClick={this.menuSelected}
                                    />
                                </PopupListItem>
                            ))}
                    </PopupList>
                </PopupArea>
                {children}
            </Container>
        )
    }
}

export const SimplePopupMenu = specialize<PopupMenu<MenuOption>>(PopupMenu)
