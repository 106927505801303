import * as React from 'react'
import { PageLoader } from 'uiComponents/loaders'
import { FieldType } from 'uiComponents/studio/form/fieldComponentSchema'
import { BlacklistUrlSection } from 'engageTools/studio/form/blacklistUrlSection'
import { WhitelistInfoSection } from 'engageTools/studio/modalwindow/whitelistInfoSection'
import { ConfigurationUnit } from 'uiComponents/studio/menuItem'
import GeneralSection from './generalSection'
import { ModalWindowConfiguration, Languages, Translation, UrlRules, OnChangeFunc } from 'engageTools/studio/schema'
import LanguagesSection from './languagesSection'
import { getConfigValue, handleInputChange } from 'engageTools/studio/shared'
import { ComponentsService } from 'engageTools/studio/componentsService'
import { ImagesService } from 'http/imagesService'
import { MessageKind } from 'uiComponents/messages'
import { FrequencyLimitSection } from './frequencyLimitSection'
import AudienceMarketing from '../form/fields/audienceMarketing'

interface FormProps {
    accountSlug: string
    section: ConfigurationUnit
    modalWindowConfiguration: ModalWindowConfiguration
    languages: Languages | null
    translations: Translation[]
    urlRules: UrlRules | null
    updateUrlRules: (list: 'blacklist' | 'whitelist', logic: string, ruleType: string, value: string) => void
    clearWhitelist: () => void
    updateConfiguration: (path: string, value: string | number | boolean | string[]) => void
    updateTranslations: (slug: string, language: string, value: string) => void
    onLanguageChange: (selected: string[], primary: string) => void
    resetATranslation: (slug: string) => void
    componentsService: ComponentsService
    imagesService: ImagesService
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    translationsLoading: boolean
    translationsValidation: boolean
    setTranslationsValidation: (on: boolean) => void
    setActiveLanguage: (v: string) => void
    activeLanguage: string
}

export class ModalWindowForm extends React.Component<FormProps> {
    constructor(props: FormProps) {
        super(props)
    }

    handleInputChange: OnChangeFunc = (
        result: React.ChangeEvent<HTMLInputElement> | string | boolean,
        path: string,
    ) => {
        handleInputChange(result, path, this.setConfigValue)
    }

    setConfigValue = (path: string, value: boolean | string | number) => {
        if (path) {
            this.props.updateConfiguration(path, value)
        }
    }

    render() {
        if (!this.props.modalWindowConfiguration || this.props.translationsLoading) {
            return <PageLoader />
        } else {
            const { section } = this.props
            if (section.name === 'Languages' && this.props.languages) {
                return (
                    <LanguagesSection
                        accountSlug={this.props.accountSlug}
                        section={section}
                        getConfigValue={(item: FieldType) => getConfigValue(item, this.props.modalWindowConfiguration)}
                        handleInputChange={this.handleInputChange}
                        languages={this.props.languages}
                        updateLanguages={this.props.onLanguageChange}
                        translations={this.props.translations}
                        configuration={this.props.modalWindowConfiguration}
                        updateConfiguration={this.props.updateConfiguration}
                        updateTranslations={this.props.updateTranslations}
                        resetATranslation={this.props.resetATranslation}
                        translationsValidation={this.props.translationsValidation}
                        setTranslationsValidation={this.props.setTranslationsValidation}
                        activeLanguage={this.props.activeLanguage}
                        setActiveLanguage={this.props.setActiveLanguage}
                    />
                )
            } else if (section.name === 'Frequency limit') {
                return (
                    <FrequencyLimitSection
                        section={section}
                        configuration={this.props.modalWindowConfiguration}
                        getConfigValue={(item: FieldType) => getConfigValue(item, this.props.modalWindowConfiguration)}
                        handleInputChange={this.handleInputChange}
                        setConfigValue={this.setConfigValue}
                        updateConfiguration={this.props.updateConfiguration}
                    />
                )
            } else if (section.name === 'Blacklist') {
                return (
                    <>
                        {this.props.modalWindowConfiguration.trigger === 'onSpecificUrl' ? (
                            <WhitelistInfoSection
                                product="modal_window"
                                section={{
                                    name: 'Whitelist',
                                    label: 'Show your Pop Up on',
                                    fields: [],
                                }}
                                urlRules={this.props.urlRules}
                            />
                        ) : (
                            <BlacklistUrlSection
                                product="modal_window"
                                section={section}
                                urlRules={this.props.urlRules}
                                updateUrlRules={this.props.updateUrlRules}
                            />
                        )}
                    </>
                )
            } else if (section.name === 'Audience') {
                return <AudienceMarketing />
            } else {
                return (
                    <GeneralSection
                        accountSlug={this.props.accountSlug}
                        section={section}
                        getConfigValue={(item: FieldType) => getConfigValue(item, this.props.modalWindowConfiguration)}
                        handleInputChange={this.handleInputChange}
                        componentsService={this.props.componentsService}
                        imagesService={this.props.imagesService}
                        replaceTopMessages={this.props.replaceTopMessages}
                        removeAllMessages={this.props.removeAllMessages}
                        configuration={this.props.modalWindowConfiguration}
                        urlRules={this.props.urlRules}
                        updateUrlRules={this.props.updateUrlRules}
                        clearWhitelist={this.props.clearWhitelist}
                        resetATranslation={this.props.resetATranslation}
                    />
                )
            }
        }
    }
}
