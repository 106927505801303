import React, { useContext, useState } from 'react'
import { IconProp } from '@fortawesome/fontawesome'
import styled from 'styled-typed'
import { faTrash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DiscountRule } from 'products/discountRules/discountRulesService'
import { ActionButtonA } from 'uiComponents/buttons'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { DiscountRulesServiceContext } from 'products/discountRules/context'
import { DiscountRulesContext } from 'products/discountRules/table/tableContext'
import { MetadataContext } from 'products/discountRules/metadataContext'

const ActionCell = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
const Icon = styled(FontAwesomeIcon)`
    cursor: pointer;
    color: ${(props) => props.theme.colors.boyBlue};
`

interface DiscountRulesTableActionsProps {
    rule: DiscountRule
}

function DiscountRulesTableActions({ rule }: DiscountRulesTableActionsProps) {
    const discountRulesService = useContext(DiscountRulesServiceContext)
    const { reloadData } = useContext(DiscountRulesContext)
    const { accountSlug, replaceMessages } = useContext(MetadataContext)

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

    const onDeleteConfirm = async () => {
        try {
            await discountRulesService.deleteDiscountRule(accountSlug, rule.id)
            reloadData()
        } catch {
            replaceMessages('delete_error', 'error', 'Oops! Could not delete the option group. Please try again later.')
        } finally {
            setShowDeleteDialog(false)
        }
    }

    return (
        <ActionCell>
            {showDeleteDialog && (
                <ConfirmationDialog
                    title="Delete the rule?"
                    text="Are you sure you want to delete this discount rule?"
                    buttonText="Delete"
                    destructive
                    onCancel={() => setShowDeleteDialog(false)}
                    onConfirm={onDeleteConfirm}
                />
            )}
            <ActionButtonA
                href={`/account/${accountSlug}/products/discount_rules/details/${rule.id}`}
                kind="action"
                secondary
                style={{ marginRight: '1.5em' }}
            >
                Edit
            </ActionButtonA>
            <Icon icon={faTrash as IconProp} onClick={() => setShowDeleteDialog(true)} />
        </ActionCell>
    )
}

export default DiscountRulesTableActions
