import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { BaseRouteParams } from '../../hocs'
import ActionButton from '../../uiComponents/buttons'

const Container = styled.div`
    position: absolute;
    bottom: 2em;
    right: 2em;

    button:last-of-type {
        margin-left: 8px;
    }
`

const ExitButton = styled(ActionButton)`
    display: inline-block;
    border-color: transparent;
    background-color: transparent;
    color: ${(props) => props.theme.colors.textDark};
    margin-right: 8px;
`

interface ActionButtonsProps {
    canPublish?: boolean
}

const ActionButtons = ({ canPublish }: ActionButtonsProps) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const history = useHistory()

    return (
        <Container>
            <ExitButton
                size="large"
                secondary
                onClick={() => {
                    history.push(`/account/${accountSlug}/engage/push_notifications`)
                }}
            >
                Exit
            </ExitButton>
            {/* <ActionButton size="large" secondary>*/}
            {/*    Save draft*/}
            {/* </ActionButton>*/}
            <ActionButton size="large" kind="action" disabled={!canPublish} type="submit">
                Publish
            </ActionButton>
        </Container>
    )
}

export default ActionButtons
