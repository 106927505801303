import * as React from 'react'
import styled from 'styled-typed'
import { ActionButton } from 'uiComponents/buttons'
import { ModalDialog, ModalDialogTitle, NavigationSection } from 'uiComponents/popups/modal'
import { ChartDataLoader } from 'uiComponents/loaders'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { TextInput } from 'uiComponents/input'

const ErrorMessage = styled(ValidationMessage)`
    font-size: 0.8em;
    top: -0.6em;
`

interface DialogProps {
    accountSlug: string
    loading: boolean
    onCancel: () => void
    onConfirm: (name: string) => void
}

export function NewPoolDialog(props: DialogProps) {
    const [name, setName] = React.useState<string>('')
    const [nameError, setNameError] = React.useState<boolean>(false)

    const onSave = () => {
        const formValid = !!name.trim()
        formValid ? props.onConfirm(name) : setNameError(!name.trim())
    }

    const onFinishTyping = (value: string) => {
        setNameError(!value.trim())
    }

    return (
        <ModalDialog onDismiss={props.onCancel} interactive fromTop="20%">
            {props.loading && <ChartDataLoader />}
            <div style={{ width: '25em' }}>
                <ModalDialogTitle>Add new coupon pool</ModalDialogTitle>
                <FormItem htmlFor="discount-pool-name" style={{ position: 'relative' }}>
                    <FormItemName>Name</FormItemName>
                    <TextInput
                        id="discount-pool-name"
                        type="text"
                        placeholder="Coupon pool name"
                        maxLength={100}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onFinishTyping={onFinishTyping}
                        status={nameError ? 'error' : 'normal'}
                        block
                    />
                    <ErrorMessage className={nameError ? 'validation-message-visible' : ''}>
                        Please enter the name
                    </ErrorMessage>
                </FormItem>
                <NavigationSection>
                    <ActionButton size="large" secondary onClick={props.onCancel}>
                        Cancel
                    </ActionButton>
                    <ActionButton
                        id="add-new-discount-pool-button"
                        size="large"
                        style={{ marginLeft: '2em' }}
                        onClick={onSave}
                    >
                        Save
                    </ActionButton>
                </NavigationSection>
            </div>
        </ModalDialog>
    )
}
