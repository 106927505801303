import * as React from 'react'
import styled from 'styled-typed'
import { History } from 'history'
import { ActionButton, ActionButtonA } from 'uiComponents/buttons'
import { TopButtonWrapper } from 'uiComponents/settingsContainer'
import Permission from 'admin/permissionRequired'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag, faCalendarAlt } from '@fortawesome/pro-solid-svg-icons'
import ViewSelector from './viewSelector'
import SearchField from 'uiComponents/search/searchWithButton'
import { IconProp } from '@fortawesome/fontawesome'

const BulkActionButton = styled(ActionButton)`
    width: 10em;
    font-size: 0.85em;
    padding: 0 1em;
    height: 2rem;
    font-weight: 500;
`

interface TopButtonProps {
    showBulkButtons: boolean
    loading: boolean
    showAdjustView?: boolean
    history: History
    accountSlug: string
    showBulkEditValidity: () => void
    showBulkEditPricing: () => void
    origin: string
}

export function ProductsTopButtons(props: TopButtonProps) {
    const showAdjustView = props.showAdjustView ?? true

    return (
        <>
            <TopButtonWrapper style={{ justifyContent: 'space-between' }}>
                <div style={{ position: 'absolute', top: '7.25rem' }}>
                    {location.pathname.indexOf('flat') > -1 && (
                        <SearchField
                            placeholder="Search by product name or ID"
                            style={{ width: '20em' }}
                            hideExtendedSearch
                            disabled={props.loading}
                        />
                    )}
                </div>
                <div style={{ display: 'flex' }}>
                    {showAdjustView && (
                        <ViewSelector
                            setRadioSelection={(v: string) => {
                                v === 'categories'
                                    ? props.history.push(`/account/${props.accountSlug}/products/home/nested`)
                                    : props.history.push(`/account/${props.accountSlug}/products/home/flat`)
                            }}
                        />
                    )}
                    {props.showBulkButtons ? (
                        <div>
                            <BulkActionButton size="medium" secondary onClick={props.showBulkEditValidity}>
                                <FontAwesomeIcon icon={faCalendarAlt as IconProp} style={{ marginRight: '.5em' }} />
                                Edit validity
                            </BulkActionButton>
                            <BulkActionButton
                                size="medium"
                                secondary
                                style={{ marginRight: '1em', marginLeft: '1em' }}
                                onClick={props.showBulkEditPricing}
                            >
                                <FontAwesomeIcon icon={faTag as IconProp} style={{ marginRight: '.5em' }} />
                                Edit pricing
                            </BulkActionButton>
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Permission name="partner_admin" accountSlug={props.accountSlug}>
                        <ActionButtonA
                            id="newCategory"
                            size="medium"
                            href={`/account/${props.accountSlug}/products/crud/productList/new?from=${props.origin}`}
                            style={{ marginRight: '1em' }}
                            kind="action"
                            secondary
                        >
                            + Add category
                        </ActionButtonA>
                        <ActionButtonA
                            id="newProduct"
                            size="medium"
                            href={`/account/${props.accountSlug}/products/crud/new?from=${props.origin}`}
                            kind="action"
                        >
                            + Add product
                        </ActionButtonA>
                    </Permission>
                </div>
            </TopButtonWrapper>
        </>
    )
}

interface TopExceptioButtonProps {
    accountSlug: string
    for: 'pricing' | 'validity'
}
export function ExceptionsTopButtons(props: TopExceptioButtonProps) {
    return (
        <TopButtonWrapper>
            <ActionButtonA
                id="newException"
                size="medium"
                href={`/account/${props.accountSlug}/products/${props.for}/exceptions/create`}
                kind="action"
            >
                + Add exception
            </ActionButtonA>
        </TopButtonWrapper>
    )
}

type AddNewTopButtonSection = 'time_slots' | 'inventory' | 'option_groups'

function getAddNewTopButtonId(section: AddNewTopButtonSection) {
    switch (section) {
        case 'time_slots':
            return 'newTimeSlot'
        case 'inventory':
            return 'newInventory'
        case 'option_groups':
            return 'newOptionGroup'
        default:
            return ''
    }
}

function getAddNewTopButtonIHref(section: AddNewTopButtonSection) {
    switch (section) {
        case 'time_slots':
            return `time_slots/edit/new${location.search}`
        case 'inventory':
            return 'inventory/item/new'
        case 'option_groups':
            return 'option_groups/group_details/new'
        default:
            return ''
    }
}

interface AddNewTopButtonProps {
    accountSlug: string
    for: AddNewTopButtonSection
}
export function AddNewTopButton(props: AddNewTopButtonProps) {
    return (
        <TopButtonWrapper>
            <div data-userpilot={props.for === 'time_slots' ? 'timeslots-list-add-new-button' : ''}>
                <ActionButtonA
                    id={getAddNewTopButtonId(props.for)}
                    href={`/account/${props.accountSlug}/products/${getAddNewTopButtonIHref(props.for)}`}
                    kind="action"
                >
                    + Add new
                </ActionButtonA>
            </div>
        </TopButtonWrapper>
    )
}
