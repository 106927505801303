import React from 'react'
import styled from 'styled-typed'
import {
    Checkbox,
    TextInput,
    Toggle,
    ToggleWithValues,
    FinishTypingHandler,
    TextArea,
    NumberInput,
    TimePicker,
} from 'uiComponents/input'
import { SingleSelect, Status } from 'uiComponents/input/singleSelect'
import {
    FieldType,
    UrlField,
    TextField,
    TextAreaField,
    NumberField,
    SelectField,
    CheckboxField,
    PickerField,
    DateField,
    TimeField,
} from './fieldComponentSchema'
import { OnChangeFunc } from 'engageTools/studio/schema'
import ColorPicker from 'uiComponents/colorPicker'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { MultiSelectStudio } from './fields/multiSelectStudio'

export interface HandleButtonClickFunc {
    (): void
}

interface PickerComponentProps {
    field: PickerField
    handleChange?: OnChangeFunc
    value: string
}

export function PickerComponent(props: PickerComponentProps) {
    const handleColorChange = (value: string) => {
        if (typeof props.handleChange !== 'undefined') {
            props.handleChange(value, props.field.path)
        }
    }
    return <ColorPicker color={props.value} id={props.field.name} onChange={handleColorChange} alignTriggerRight />
}

interface ToggleComponentProps {
    field: FieldType
    handleChange?: OnChangeFunc
    value: boolean
    disabled?: boolean
}

export function ToggleComponent(props: ToggleComponentProps) {
    const isDisabled = !!props.disabled ? props.disabled : false
    const handleToggleChange = (value: boolean) => {
        if (!isDisabled) {
            if (typeof props.handleChange !== 'undefined') {
                props.handleChange(value, props.field.path)
            }
        }
    }
    return <Toggle id={props.field.name} disabled={isDisabled} isOn={props.value} onClick={handleToggleChange} />
}

interface ToggleWithValuesComponentProps {
    field: FieldType
    handleChange?: OnChangeFunc
    value: string
    options: { name: string; value: string }[]
    disabled?: boolean
}

export function ToggleWithValuesComponent(props: ToggleWithValuesComponentProps) {
    const isDisabled = !!props.disabled ? props.disabled : false
    const handleToggleChange = (value: string) => {
        if (!isDisabled) {
            if (typeof props.handleChange !== 'undefined') {
                props.handleChange(value, props.field.path)
            }
        }
    }
    return <ToggleWithValues options={props.options} selected={props.value} onClick={handleToggleChange} />
}

interface SelectProps {
    id?: string
    field: SelectField
    handleChange?: OnChangeFunc
    value: string | number | null
    availableOptions?: any[]
    disabled?: boolean
    noSelectOption?: string
    status?: Status
}

interface SelectComponentState {
    selectedValue: string | number
    open: boolean
}

export class SelectComponent extends React.Component<SelectProps, SelectComponentState> {
    constructor(props: SelectProps) {
        super(props)
        this.state = {
            selectedValue: this.getValue(this.props.value),
            open: false,
        }
    }
    onSelect = (v: string) => {
        if (typeof this.props.handleChange !== 'undefined') {
            this.props.handleChange(v, this.props.field.path)
        }
        this.setState({ selectedValue: v })
    }
    getValue = (value?: string | number | null): string | number => {
        let newValue = ''
        if (typeof value === 'boolean') {
            newValue = String(this.props.value)
        } else if (typeof value === 'undefined' || value === null) {
            newValue = ''
        } else {
            return value
        }
        return newValue
    }
    componentDidMount() {
        this.setState({ selectedValue: this.getValue(this.props.value) })
    }
    componentDidUpdate(prevProps: SelectProps) {
        if (prevProps !== this.props && this.props.value !== this.state.selectedValue) {
            this.setState({ selectedValue: this.getValue(this.props.value) })
        }
    }
    render() {
        const optionsPresent = typeof this.props.field.availableOptions !== 'undefined'
        const options = this.props.availableOptions
            ? this.props.availableOptions
            : this.props.field.availableOptions || []
        const value = this.state.selectedValue
        if (typeof value !== 'string' && typeof value !== 'number') {
            throw new Error(`Wrong input type: ${typeof value} of ${this.props.field.name}`)
        }

        const mappedOptions = options.map((o) => {
            o.value = o.value.toString()
            return o
        })
        return (
            <>
                {optionsPresent && (
                    <SingleSelect
                        id={this.props.field.name}
                        options={mappedOptions}
                        selected={this.state.selectedValue.toString()}
                        noSelectOption={this.props.noSelectOption ? this.props.noSelectOption : 'Select'}
                        width={this.props.field.span && this.props.field.span % 6 === 0 ? '100%' : '73%'}
                        lineHeight="2em"
                        maxHeight="15em"
                        onSelect={this.onSelect}
                        disabled={this.props.disabled}
                        status={this.props.status}
                        widthWhenOpen="100%"
                    />
                )}
            </>
        )
    }
}

interface CheckboxProps {
    field: CheckboxField
    handleChange?: OnChangeFunc
    value: any
}

export class CheckboxComponent extends React.Component<CheckboxProps> {
    constructor(props: CheckboxProps) {
        super(props)
    }

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (typeof this.props.handleChange !== 'undefined') {
            this.props.handleChange(e, this.props.field.path)
        }
    }

    render() {
        const { field, value } = this.props
        let checkboxValue = value
        if (typeof checkboxValue !== 'boolean') {
            throw new Error(`Wrong input type: ${typeof value}`)
        }
        return (
            <div style={{ display: 'flex' }}>
                <Checkbox name={field.name} onChange={this.handleInputChange} checked={!!this.props.value} />
                {field.label}
            </div>
        )
    }
}

interface InputProps {
    field: UrlField | TextField | TextAreaField
    handleChange?: OnChangeFunc
    value: string
    error?: boolean
    checkValidity?: FinishTypingHandler
    maxLength?: number
    onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
    style?: React.CSSProperties
    locale?: string
}

interface InputState {
    currentValue: string
}
export class InputComponent extends React.Component<InputProps, InputState> {
    constructor(props: InputProps) {
        super(props)
        this.state = {
            currentValue: this.props.value,
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps: InputProps) {
        if (nextProps.value !== this.state.currentValue) {
            this.setState({ currentValue: nextProps.value })
        }
    }
    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (typeof this.props.handleChange !== 'undefined') {
            this.props.handleChange(e, this.props.field.path)
        }
        this.setState({ currentValue: e.target.value })
    }
    render() {
        const { field, checkValidity, error, maxLength, onFocus, onBlur, style } = this.props
        return (
            <TextInput
                id={field.name}
                value={this.state.currentValue}
                name={field.name}
                style={{ width: '100%', fontSize: '0.75rem', ...style }}
                onChange={this.handleInputChange}
                maxLength={maxLength ? maxLength : field.maxLength ? field.maxLength : undefined}
                placeholder={field.placeholder ? field.placeholder : ''}
                onFinishTyping={checkValidity}
                status={error ? 'error' : 'normal'}
                locale={this.props.locale}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        )
    }
}

interface TextAreaProps {
    field: TextAreaField
    handleChange?: OnChangeFunc
    value: string
    error?: boolean
    checkValidity?: FinishTypingHandler
    onFocus?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    maxLength?: number
    locale?: string
}
interface TextAreaState {
    currentValue: string
}
export class TextAreaComponent extends React.Component<TextAreaProps, TextAreaState> {
    constructor(props: TextAreaProps) {
        super(props)
        this.state = {
            currentValue: this.props.value,
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps: TextAreaProps) {
        if (nextProps.value !== this.state.currentValue) {
            this.setState({ currentValue: nextProps.value })
        }
    }
    handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (typeof this.props.handleChange !== 'undefined') {
            this.props.handleChange(e, this.props.field.path)
        }
        this.setState({ currentValue: e.target.value })
    }
    render() {
        const { field, error, maxLength, locale } = this.props
        return (
            <TextArea
                id={field.name}
                value={this.state.currentValue}
                name={field.name}
                style={{ width: '100%', fontSize: '0.75rem' }}
                onChange={this.handleInputChange}
                onFinishTyping={this.props.checkValidity}
                onFocus={this.props.onFocus}
                status={error ? 'error' : 'normal'}
                maxLength={maxLength}
                locale={locale}
            />
        )
    }
}

const NumberUnit = styled.span`
    right: 0.5em;
    position: absolute;
`

interface NumberProps {
    field: NumberField
    handleChange?: OnChangeFunc
    value: number
    checkValidity?: FinishTypingHandler
    status?: 'normal' | 'success' | 'error'
    resetValidation?: () => void
}

interface NumberState {
    currentValue: number | ''
    lastValidValue: number
}

const nullConverter = {
    parse: (x: number) => x,
    render: (x: number) => x,
}

export class NumberComponent extends React.Component<NumberProps, NumberState> {
    constructor(props: NumberProps) {
        super(props)
        const converter = props.field.converter || nullConverter
        this.state = {
            currentValue: converter.parse(this.props.value.toString()),
            lastValidValue: converter.parse(this.props.value.toString()),
        }
    }
    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (typeof this.props.resetValidation !== 'undefined') {
            this.props.resetValidation()
        }
        if (e.target.value) {
            const value = parseInt(e.target.value, 10)
            this.setState({ currentValue: value })
        } else {
            this.setState({ currentValue: '' })
        }
    }

    checkRange = (v: string) => {
        const value = +v
        const { field } = this.props
        let validInput = value >= field.min && value <= field.max
        if (validInput) {
            if (typeof this.props.handleChange !== 'undefined') {
                const converter = field.converter || nullConverter
                this.props.handleChange(converter.render(value), field.path)
            }
            this.setState({ lastValidValue: value })
        } else {
            if (typeof this.props.checkValidity !== 'undefined') {
                const closestExtreme = value < field.min ? field.min : field.max
                this.setState({ currentValue: closestExtreme })
                this.props.checkValidity(v)
            }
        }
    }

    render() {
        const { field } = this.props
        return (
            <>
                <NumberInput
                    id={field.name}
                    value={this.state.currentValue}
                    name={field.name}
                    style={{ width: '100%' }}
                    onChange={this.handleInputChange}
                    onFinishTyping={this.checkRange}
                    min={field.min}
                    max={field.max}
                    status={this.props.status}
                    integerOnly
                    placeholder={field.placeholder}
                />
                <NumberUnit>{field.unit}</NumberUnit>
            </>
        )
    }
}

interface DateState {
    currentValue: Date
}

interface DateProps {
    field: DateField
    handleChange?: OnChangeFunc
    value: Date
    error?: boolean
}

export class DateComponent extends React.Component<DateProps, DateState> {
    constructor(props: DateProps) {
        super(props)
        this.state = {
            currentValue: this.props.value,
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps: DateProps) {
        if (nextProps.value !== this.state.currentValue) {
            this.setState({ currentValue: nextProps.value })
        }
    }
    handleInputChange = (date: Date) => {
        if (!!this.props.handleChange) {
            this.props.handleChange(date.getTime(), this.props.field.path)
        }
        this.setState({ currentValue: date })
    }
    render() {
        const { field, error } = this.props
        return (
            <DatePicker
                id={field.name}
                date={this.state.currentValue ? new Date(this.state.currentValue) : null}
                onChange={this.handleInputChange}
                status={error ? 'error' : 'normal'}
            />
        )
    }
}

interface TimeProps {
    field: TimeField
    handleChange?: OnChangeFunc
    value: string
    error?: boolean
    style?: React.CSSProperties
}

interface TimeState {
    currentValue: string
}
export class TimeComponent extends React.Component<TimeProps, TimeState> {
    constructor(props: TimeProps) {
        super(props)
        this.state = {
            currentValue: this.props.value,
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps: TimeProps) {
        if (nextProps.value !== this.state.currentValue) {
            this.setState({ currentValue: nextProps.value })
        }
    }
    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (typeof this.props.handleChange !== 'undefined') {
            this.props.handleChange(e, this.props.field.path)
        }
        this.setState({ currentValue: e.target.value })
    }
    render() {
        const { field, error, style } = this.props
        return (
            <TimePicker
                id={field.name}
                key={this.state.currentValue}
                value={this.state.currentValue}
                name={field.name}
                style={style}
                onChange={(time) =>
                    this.handleInputChange({ target: { value: time } } as React.ChangeEvent<HTMLInputElement>)
                }
                errorMessage={error ? 'Invalid time' : null}
            />
        )
    }
}

interface ComponentProps {
    field: FieldType
    handleChange?: OnChangeFunc
    value: any
    disabled?: boolean
    error?: boolean
    checkValidity?: FinishTypingHandler
    locale?: string
}

export function FormFieldComponent({
    field,
    handleChange,
    value,
    disabled,
    error,
    checkValidity,
    locale,
}: ComponentProps) {
    const commonProps = {
        value,
        disabled,
        error,
        locale,
        handleChange,
        checkValidity,
    }
    switch (field.type) {
        case 'picker':
            return <PickerComponent field={field} {...commonProps} />
        case 'url':
        case 'text':
            return <InputComponent field={field} {...commonProps} />
        case 'textArea':
            return <TextAreaComponent field={field} {...commonProps} />
        case 'number':
            return <NumberComponent field={field} {...commonProps} />
        case 'select':
            return <SelectComponent field={field} {...commonProps} />
        case 'checkbox':
            return <CheckboxComponent field={field} {...commonProps} />
        case 'toggle':
            return <ToggleComponent field={field} {...commonProps} />
        case 'date':
            return <DateComponent field={field} {...commonProps} />
        case 'time':
            return <TimeComponent field={field} {...commonProps} />
        case 'multiSelect':
            return <MultiSelectStudio field={field} {...commonProps} />
        default:
            throw new Error(`Unknown field type: ${(field as FieldType).type}`)
    }
}
