import React, { useState } from 'react'
import styled from 'styled-typed'
import cloneDeep from 'lodash/cloneDeep'
import * as uuid from 'uuid'
import { Messages } from 'uiComponents/messages'
import { withMessages, MessageProps } from 'hocs'
import { GoogleTTDServiceContext } from 'channels/context'
import { ImageUploadResponse } from 'http/imagesService'
import { retrieveImgDimentions } from 'uiComponents/input/upload'
import { ImagesServiceContext } from 'http/context'
import { VenueDetails, RawProductData, Translation, GoogleListingItem } from 'channels/googleTTDService'
import { Body } from 'uiComponents/typography'
import { TableLoader, ChartDataLoader } from 'uiComponents/loaders'
import { LocalesInfo, Locale } from 'admin/articleService'
import { ArticleService } from 'admin/articleService'
import { ContainerBody } from 'uiComponents/settingsContainer'
import { Row } from 'uiComponents/flex'
import { Toggle } from 'uiComponents/input/toggle'
import { VenueSection, Separator } from './venueSection'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { delay } from 'utils'
import { StyledATag } from 'uiComponents/typography'

const TextWithToggle = styled(Body)`
    display: flex;
    align-items: center;
`

interface GoogleTTDPageProps {
    accountSlug: string
    creatingModeOn: boolean
    articleService: ArticleService
    showAddVenueButton: (value: boolean) => void
    onSectionChange: (section: string) => void
    resetCreatingMode: () => void
}

function GoogleTTDPage(props: GoogleTTDPageProps & MessageProps) {
    const googleTTDService = React.useContext(GoogleTTDServiceContext)
    const imagesService = React.useContext(ImagesServiceContext)

    const [loading, setLoading] = useState<boolean>(true)
    const [updating, setUpdating] = useState<boolean>(false)
    const [featureEnabled, setFeatureEnabled] = useState<boolean>(false)
    const [locales, setLocales] = useState<LocalesInfo | null>(null)
    const [venueDataList, setVenueDataList] = useState<VenueDetails[]>([])
    const [ttdItems, setTTDItems] = useState<GoogleListingItem[]>([])
    const [ttdItemsInDB, setTTDItemsInDB] = useState<string[]>([])
    const [itemToDelete, setItemToDelete] = useState<string>('')
    const [updatingItem, setUpdatingItem] = useState<string>('')
    const [itemWithErrors, setItemWithErrors] = useState<string>('')
    const [markAllErrors, setMarkAllErrors] = useState<boolean>(false)

    function getProductNameFromTitle(titleTranslations: Translation[]) {
        return titleTranslations.find((x) => x.language === 'en')?.text || 'Unknown name'
    }

    function createVenueList(products: RawProductData[]) {
        const list: VenueDetails[] = []
        products.forEach((p) => {
            const existing = list.find((i) => i.locationId === p.location.id)
            if (existing) {
                existing.products.push({
                    internalId: p.internalId,
                    uuid: p.uuid,
                    name: getProductNameFromTitle(p.title),
                })
            } else {
                list.push({
                    locationId: p.location.id,
                    locationName: p.location.name,
                    googlePlaceId: p.location.googlePlaceId,
                    products: [
                        {
                            internalId: p.internalId,
                            uuid: p.uuid,
                            name: getProductNameFromTitle(p.title),
                        },
                    ],
                })
            }
        })
        return list
    }

    function getEmptyDescriptions(accountLocales: LocalesInfo | null = null): Translation[] {
        if (!locales && !accountLocales) {
            return []
        }
        const localesData = locales || (accountLocales as LocalesInfo)
        return localesData.locales.map((l) => ({
            language: l.code,
            text: '',
        }))
    }

    function getEmptyItem(venue: VenueDetails, accountLocales: LocalesInfo | null = null): GoogleListingItem {
        return {
            id: uuid.v4(),
            slug: props.accountSlug,
            locationId: venue?.locationId || '',
            googlePlaceId: venue?.googlePlaceId || '',
            includeInFeed: featureEnabled,
            title: '',
            image: '',
            description: getEmptyDescriptions(accountLocales),
            options: [
                {
                    articleUUID: '',
                    category: [],
                },
            ],
        }
    }

    function setListingItems(existingItems: GoogleListingItem[], venues: VenueDetails[], accountLocales: LocalesInfo) {
        if (!!existingItems.length) {
            setTTDItems(existingItems)
        } else if (!!venues.length) {
            setTTDItems([getEmptyItem(venues[0], accountLocales)])
        } else {
            setTTDItems([])
        }
    }

    async function getData() {
        try {
            const enabledProducts = await googleTTDService.getProducts(props.accountSlug)
            const listingItems = await googleTTDService.getGoogleTTDItems(props.accountSlug)
            const venueList = createVenueList(enabledProducts)
            const accountLocales = await props.articleService.getAccountLocales(props.accountSlug)
            setLocales(accountLocales)
            setVenueDataList(venueList)
            setFeatureEnabled(!!listingItems.find((i) => i.includeInFeed))
            setTTDItemsInDB(listingItems.map((i) => i.id))
            setListingItems(listingItems, venueList, accountLocales)
            props.showAddVenueButton(venueList.length > listingItems.length)
        } catch {
            props.replaceMessages('error', 'error', 'Oops, unable to get account details. Please try again.')
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        props.onSectionChange('google_ttd')
        setLoading(true)
        getData()
    }, [props.accountSlug])

    React.useEffect(() => {
        if (props.creatingModeOn) {
            const updatedList = cloneDeep(ttdItems)
            const existingListings = updatedList.map((i) => i.locationId)
            const venueData = venueDataList.find((v) => !existingListings.includes(v.locationId))
            if (!venueData) {
                props.resetCreatingMode()
                return
            }
            updatedList.push(getEmptyItem(venueData))
            setTTDItems(updatedList)
            props.resetCreatingMode()
            if (updatedList.length === venueDataList.length) {
                props.showAddVenueButton(false)
            }
        }
    }, [props.creatingModeOn])

    function onVenueChange(newLocationId: string, itemId: string) {
        const updatedList = cloneDeep(ttdItems)
        const updatedItem = updatedList.find((i) => i.id === itemId)
        if (!updatedItem) {
            return
        }
        const venueData = venueDataList.find((v) => v.locationId === newLocationId)
        updatedItem.locationId = newLocationId
        updatedItem.googlePlaceId = venueData?.googlePlaceId || ''
        updatedItem.image = ''
        updatedItem.description = getEmptyDescriptions()
        ;(updatedItem.options = [
            {
                articleUUID: '',
                category: [],
            },
        ]),
            setTTDItems(updatedList)
    }

    function onPlaceIdChange(newPlaceId: string, itemId: string) {
        const updatedList = cloneDeep(ttdItems)
        const updatedItem = updatedList.find((i) => i.id === itemId)
        if (!updatedItem) {
            return
        }
        updatedItem.googlePlaceId = newPlaceId
        setTTDItems(updatedList)
    }

    function onProductChange(newProductId: string, itemId: string) {
        const updatedList = cloneDeep(ttdItems)
        const updatedItem = updatedList.find((i) => i.id === itemId)
        if (!updatedItem) {
            return
        }
        updatedItem.options[0].articleUUID = newProductId
        setTTDItems(updatedList)
    }

    function onCategoryChange(newCategories: string[], itemId: string) {
        const updatedList = cloneDeep(ttdItems)
        const updatedItem = updatedList.find((i) => i.id === itemId)
        if (!updatedItem) {
            return
        }
        updatedItem.options[0].category = newCategories
        setTTDItems(updatedList)
    }

    function onDescriptionChange(value: string, language: string, itemId: string) {
        const updatedList = cloneDeep(ttdItems)
        const updatedItem = updatedList.find((i) => i.id === itemId)
        if (!updatedItem) {
            return
        }
        const updatedTranslation = updatedItem.description.find((d) => d.language === language)
        if (updatedTranslation) {
            updatedTranslation.text = value
        } else {
            const translation = {
                language,
                text: value,
            }
            updatedItem.description.push(translation)
        }
        setTTDItems(updatedList)
    }

    async function onImageUpload(file: File) {
        const dimentions = await retrieveImgDimentions(file)
        if (!dimentions) {
            return
        }
        if (dimentions.width !== dimentions.height) {
            return Promise.reject({
                message: 'The image you are trying to upload is not square.',
            })
        }
        if (dimentions.width < 300) {
            return Promise.reject({
                message: 'The image must be at least 300x300px.',
            })
        }
        return await imagesService.uploadImage(file)
    }

    function onImageUploadSuccess(response: ImageUploadResponse, itemId: string) {
        const updatedList = cloneDeep(ttdItems)
        const updatedItem = updatedList.find((i) => i.id === itemId)
        if (!updatedItem) {
            return
        }
        updatedItem.image = response.url
        setTTDItems(updatedList)
    }

    function onRemoveImage(itemId: string) {
        const updatedList = cloneDeep(ttdItems)
        const updatedItem = updatedList.find((i) => i.id === itemId)
        if (!updatedItem) {
            return
        }
        updatedItem.image = ''
        setTTDItems(updatedList)
    }

    function checkAllTranslationsEmpty(localesList: Locale[], item: GoogleListingItem) {
        return localesList.every((l) => {
            return !item.description.find((d) => d.language === l.code)?.text
        })
    }

    function itemPayloadValid(item: GoogleListingItem) {
        if (!locales) {
            return false
        }
        const allTranslationsEmpty = checkAllTranslationsEmpty(locales.locales, item)
        const translationsValid = locales.locales.every((l) => {
            return !!item.description.find((d) => d.language === l.code)?.text
        })
        return (
            (allTranslationsEmpty || !!translationsValid) &&
            !!item.options[0].articleUUID &&
            !!item.options[0].category.length &&
            !!item.googlePlaceId
        )
    }

    async function updateItemsinDBList() {
        const listingItems = await googleTTDService.getGoogleTTDItems(props.accountSlug)
        setTTDItemsInDB(listingItems.map((i) => i.id))
    }

    function updateDataAfterCreation(oldId: string, newId: string) {
        const updatedList = cloneDeep(ttdItems)
        const updatedItem = updatedList.find((i) => i.id === oldId)
        if (!updatedItem) {
            return
        }
        updatedItem.id = newId
        setTTDItems(updatedList)
        setTTDItemsInDB([...ttdItemsInDB, newId])
    }

    function cleanupData(item: GoogleListingItem) {
        if (locales?.locales && checkAllTranslationsEmpty(locales?.locales, item)) {
            const updatedItem = cloneDeep(item)
            updatedItem.description = []
            return updatedItem
        }
        return item
    }

    async function onSaveItem(id: string) {
        const item = ttdItems.find((i) => i.id === id)
        if (!item) {
            return
        }
        if (!itemPayloadValid(item)) {
            setItemWithErrors(id)
            props.replaceMessages('error', 'error', 'Please ensure all marked fields are completed.')
            return
        }
        try {
            const existingItemUpdate = ttdItemsInDB.includes(id)
            const venueData = venueDataList.find((v) => v.locationId === item.locationId)
            const itemData = cleanupData(item)
            if (existingItemUpdate) {
                await googleTTDService.updateGoogleTTDItem(props.accountSlug, id, itemData)
                await flashSuccessMessage(venueData?.locationName || '')
            } else {
                const newItem = await googleTTDService.createGoogleTTDItem(props.accountSlug, itemData)
                updateDataAfterCreation(id, newItem.id)
                await flashSuccessMessage(venueData?.locationName || '')
            }
        } catch {
            props.replaceMessages('error', 'error', 'Oops, unable to save the item. Please try again.')
        }
    }

    async function flashSuccessMessage(vanueName: string) {
        props.replaceMessages('success', 'success', `${vanueName} venue details saved successfully.`)
        await delay(3000)
        props.removeAllMessages()
    }

    async function onSaveSingleItem(id: string) {
        setUpdatingItem(id)
        await onSaveItem(id)
        await updateItemsinDBList()
        setUpdatingItem('')
    }

    async function updateAll() {
        ttdItems.forEach(async (i) => {
            const payload = {
                ...i,
                includeInFeed: !featureEnabled,
            }
            await googleTTDService.updateGoogleTTDItem(props.accountSlug, i.id, payload)
        })
    }

    async function toggleEnabled() {
        setUpdating(true)
        try {
            const allValid = ttdItems.every((i) => itemPayloadValid(i))
            if (!allValid) {
                setMarkAllErrors(true)
                props.replaceMessages('error', 'error', 'Please ensure all required fields of all items are completed.')
                return
            }
            await updateAll()
            await getData()
        } catch {
            props.replaceMessages('error', 'error', 'Oops, something went wrong, please contact the support.')
        } finally {
            setUpdating(false)
        }
    }

    async function onConfirmDelete() {
        setUpdatingItem(itemToDelete)
        try {
            const existingItemDeletion = ttdItemsInDB.includes(itemToDelete)
            if (existingItemDeletion) {
                await googleTTDService.deleteGoogleTTDItem(props.accountSlug, itemToDelete)
                await updateItemsinDBList()
            }
            const updatedList = ttdItems.filter((i) => i.id !== itemToDelete)
            setTTDItems(ttdItems.filter((i) => i.id !== itemToDelete))
            props.showAddVenueButton(venueDataList.length > updatedList.length)
        } catch {
            props.replaceMessages('error', 'error', 'Oops, unable to delete the item. Please try again.')
        } finally {
            setUpdatingItem('')
            setItemToDelete('')
        }
    }

    return (
        <ContainerBody style={{ flexDirection: 'column' }}>
            <Messages messages={props.messages} hideMessage={props.hideMessage} />
            {!!itemToDelete && (
                <ConfirmationDialog
                    title="Are you sure you want to delete?"
                    text="By choosing “delete” you will remove the full section."
                    buttonText="Delete"
                    destructive
                    onCancel={() => setItemToDelete('')}
                    onConfirm={onConfirmDelete}
                    loading={!!updatingItem}
                />
            )}
            {loading && <TableLoader />}
            {!loading && (
                <div style={{ position: 'relative' }}>
                    {updating && <ChartDataLoader />}
                    <Row style={{ marginBottom: '2em' }}>
                        <TextWithToggle size={1}>
                            <span>Enable Google’s free things to do listing</span>
                            <Toggle
                                isOn={featureEnabled}
                                onClick={toggleEnabled}
                                className="smallInlineToggle"
                                style={{ marginLeft: '1.5rem' }}
                                disabled={!venueDataList.length}
                            />
                        </TextWithToggle>
                    </Row>
                    <Row>
                        <Body size={1}>
                            By enabling Google Things to do free search listing, you will automatically appear via
                            Google Search and Google’s Things to do with a direct link to your online ticket shop.
                        </Body>
                        <Body size={1}>
                            Convious completes most configurations for you, but you can adjust your venue’s display
                            image, description, and translations. Please find more information&nbsp;
                            <StyledATag
                                target="_blank"
                                href="https://support.convious.com/help/google-things-to-do-free-organic-search-listing"
                            >
                                here
                            </StyledATag>
                            .
                        </Body>
                    </Row>
                    {!ttdItems.length && !venueDataList.length && (
                        <>
                            <Separator />
                            <Body size={1}>
                                There are no products enabled for Google things to do. Please contact the support if you
                                wish to change that.
                            </Body>
                        </>
                    )}
                    {ttdItems.map((item, i) => {
                        return (
                            <VenueSection
                                key={item.id}
                                index={i}
                                locales={locales}
                                venueDataList={venueDataList}
                                existingListings={ttdItems.map((x) => x.locationId)}
                                item={item}
                                accountSlug={props.accountSlug}
                                updating={updatingItem === item.id}
                                markErrors={markAllErrors || itemWithErrors === item.id}
                                onVenueChange={(id) => onVenueChange(id, item.id)}
                                onPlaceIdChange={(id) => onPlaceIdChange(id, item.id)}
                                onProductChange={(id) => onProductChange(id, item.id)}
                                onCategoryChange={(ids) => onCategoryChange(ids, item.id)}
                                onDescriptionChange={(v, l) => onDescriptionChange(v, l, item.id)}
                                onImageUpload={onImageUpload}
                                onImageUploadSuccess={(response) => onImageUploadSuccess(response, item.id)}
                                onRemoveImage={() => onRemoveImage(item.id)}
                                onSaveItem={() => onSaveSingleItem(item.id)}
                                onDeleteItem={() => setItemToDelete(item.id)}
                                replaceMessages={props.replaceMessages}
                                removeAllMessages={props.removeAllMessages}
                            />
                        )
                    })}
                </div>
            )}
        </ContainerBody>
    )
}

export default withMessages(GoogleTTDPage)
