import React from 'react'
import { UploadField } from 'uiComponents/studio/form/fieldComponentSchema'
import { UploadInput } from 'uiComponents/input/upload'
import { ComponentsService } from 'engageTools/studio/componentsService'
import { ImageUrl, OnChangeFunc } from 'engageTools/studio/schema'
import { ImagesService, ImageUploadResponse } from 'http/imagesService'
import { MessageKind } from 'uiComponents/messages'

interface GeneralSectionProps {
    field: UploadField
    style: React.CSSProperties
    value: boolean | string | number
    handleChange: OnChangeFunc
    componentsService: ComponentsService
    imagesService: ImagesService
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    acceptSvg: boolean
    type: 'icon' | 'img'
}

interface UploadResponse {
    url: string
    img_url: string
}

export function UploadElement(props: GeneralSectionProps) {
    async function handleNewImgUrl(response: UploadResponse) {
        const url = response.url || response.img_url
        props.handleChange(url, props.field.path)
    }

    const imageOptions = {
        max_height: 50,
    }

    async function uploadFile(file: File): Promise<ImageUploadResponse | ImageUrl> {
        if (props.type === 'icon') {
            return await props.componentsService.fileUpload(file)
        } else {
            return await props.imagesService.uploadImage(file, imageOptions)
        }
    }

    return (
        <UploadInput
            type={props.type}
            acceptSvg={props.type === 'icon'}
            onUpload={uploadFile}
            handleUploadSuccessResponse={handleNewImgUrl}
            inputId={props.field.name}
            replaceTopMessages={props.replaceTopMessages}
            removeAllMessages={props.removeAllMessages}
        />
    )
}
