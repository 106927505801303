import 'normalize.css'
import '../styles/global.scss'
import * as Sentry from '@sentry/browser'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as uuid from 'uuid'
import { AnyAction } from 'redux'
import { Location } from 'history'
import './utils/yupUtils'
import { PricingServiceContext } from 'products/pricing/context'
import { ArticleConfigurationServiceContext } from 'products/context'
import { AccountSettingsServiceContext, NotificationsServiceContext } from 'settings/context'
import { ChannelsServiceContext, GoogleTTDServiceContext } from 'channels/context'
import * as config from 'config'
import { UserServiceContext } from 'auth/context'
import { ImagesServiceContext, LoggingServiceContext, StatsServiceContext, LoginServiceContext } from 'http/context'
import { loadFeatureDefaults, loadAccountFeatures } from 'features/actions.async'
import { ComponentsServiceContext } from 'engageTools/studio/context'
import { AccountServiceContext, ProductsListServiceContext, ArticleServiceContext } from 'admin/context'
import { TimeSlotsServiceContext } from 'timeSlots/context'
import { InventoryServiceContext } from 'inventory/context'
import { DiscountRulesServiceContext } from 'products/discountRules/context'
import { CopyEditorServiceContext } from 'copyEditor/context'
import { OpeningTimesServiceContext } from 'venue/openingTimes/context'
import { TapChannelServiceContext } from 'tapPromotions/channelServiceContext'
import App from './app'
import { OrdersServiceContext } from 'orders/context'
import { AppServices, history, store } from './middleware'
import 'react-virtualized/styles.css'
import * as process from 'process'
import { ThemeProvider } from '@mui/material/styles'
import { themeOptions } from './muiTheme/defaultConviousTheme'
import { changeDateLocale } from 'preferences/actions.async'

if (process.env.NODE_ENV === 'development' && process.env.MSW_ENABLED === 'true') {
    const { worker } = require('../../mocks/browser')
    worker.start()
    console.info('Mock Service Worker enabled')
}

const sentryDSN = config.get('sentry-dsn')

if (!localStorage.getItem('cookieId')) {
    localStorage.setItem('cookieId', uuid.v4())
}

let pastLocation: Location
history.listen((location) => {
    const eventData = {
        click_from: pastLocation ? pastLocation.pathname + pastLocation.search : '',
        click_to: location.pathname + location.search,
    }
    AppServices.loggingService.logAction('page_view', eventData)
    pastLocation = location
})

/* eslint-disable max-len */
function startApp() {
    ReactDOM.render(
        <AccountServiceContext.Provider value={AppServices.accountService}>
            <ProductsListServiceContext.Provider value={AppServices.productsListService}>
                <ArticleServiceContext.Provider value={AppServices.articleService}>
                    <ArticleConfigurationServiceContext.Provider value={AppServices.articleConfigurationService}>
                        <PricingServiceContext.Provider value={AppServices.pricingService}>
                            <ComponentsServiceContext.Provider value={AppServices.componentsService}>
                                <UserServiceContext.Provider value={AppServices.userService}>
                                    <ImagesServiceContext.Provider value={AppServices.imagesService}>
                                        <TimeSlotsServiceContext.Provider value={AppServices.timeSlotsService}>
                                            <OpeningTimesServiceContext.Provider
                                                value={AppServices.openingTimesService}
                                            >
                                                <InventoryServiceContext.Provider value={AppServices.inventoryService}>
                                                    <LoggingServiceContext.Provider value={AppServices.loggingService}>
                                                        <LoginServiceContext.Provider value={AppServices.loginService}>
                                                            <StatsServiceContext.Provider
                                                                value={AppServices.statsService}
                                                            >
                                                                <AccountSettingsServiceContext.Provider
                                                                    value={AppServices.accountSettingsService}
                                                                >
                                                                    <ChannelsServiceContext.Provider
                                                                        value={AppServices.channelsService}
                                                                    >
                                                                        <NotificationsServiceContext.Provider
                                                                            value={AppServices.notificationsService}
                                                                        >
                                                                            <GoogleTTDServiceContext.Provider
                                                                                value={AppServices.googleTTDService}
                                                                            >
                                                                                <OrdersServiceContext.Provider
                                                                                    value={AppServices.ordersService}
                                                                                >
                                                                                    <TapChannelServiceContext.Provider
                                                                                        value={
                                                                                            AppServices.tapChannelService
                                                                                        }
                                                                                    >
                                                                                        <DiscountRulesServiceContext.Provider
                                                                                            value={
                                                                                                AppServices.discountRulesService
                                                                                            }
                                                                                        >
                                                                                            <CopyEditorServiceContext.Provider
                                                                                                value={
                                                                                                    AppServices.copyEditorService
                                                                                                }
                                                                                            >
                                                                                                <ThemeProvider
                                                                                                    theme={themeOptions}
                                                                                                >
                                                                                                    <App
                                                                                                        store={store}
                                                                                                    />
                                                                                                </ThemeProvider>
                                                                                            </CopyEditorServiceContext.Provider>
                                                                                        </DiscountRulesServiceContext.Provider>
                                                                                    </TapChannelServiceContext.Provider>
                                                                                </OrdersServiceContext.Provider>
                                                                            </GoogleTTDServiceContext.Provider>
                                                                        </NotificationsServiceContext.Provider>
                                                                    </ChannelsServiceContext.Provider>
                                                                </AccountSettingsServiceContext.Provider>
                                                            </StatsServiceContext.Provider>
                                                        </LoginServiceContext.Provider>
                                                    </LoggingServiceContext.Provider>
                                                </InventoryServiceContext.Provider>
                                            </OpeningTimesServiceContext.Provider>
                                        </TimeSlotsServiceContext.Provider>
                                    </ImagesServiceContext.Provider>
                                </UserServiceContext.Provider>
                            </ComponentsServiceContext.Provider>
                        </PricingServiceContext.Provider>
                    </ArticleConfigurationServiceContext.Provider>
                </ArticleServiceContext.Provider>
            </ProductsListServiceContext.Provider>
        </AccountServiceContext.Provider>,
        document.getElementById('app'),
    )
}

async function initializeSentry(sentryDSN: string | null) {
    if (sentryDSN) {
        Sentry.init({
            dsn: sentryDSN,
            release: process.env.VERSION,
        })
    }
}

async function bootstrap() {
    await initializeSentry(sentryDSN)
    const state = store.getState()
    await Promise.all([
        store.dispatch(loadFeatureDefaults() as any as AnyAction),
        store.dispatch(loadAccountFeatures(state.preferences.activeAccount) as any as AnyAction),
        store.dispatch(changeDateLocale() as any as AnyAction),
    ])
    startApp()
}
bootstrap()
