import React, { createContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { CustomReactTableEvents } from 'uiComponents/table/reactTable'
import { Emitter, EventEmitter } from 'utils/eventEmmiter'
import { resetState } from './redux'

interface OrderTableEventHandlerContextProps {
    eventEmmitter: Emitter<CustomReactTableEvents>
}

export const OrderTableEventHandlerContext = createContext<OrderTableEventHandlerContextProps>(undefined as any)

interface OrderTableEventHandlerProps {}

const OrderTableEventHandler: React.FC<OrderTableEventHandlerProps> = ({ children }) => {
    const dispatch = useDispatch()
    const eventEmmitter = useMemo(() => {
        const event = EventEmitter<CustomReactTableEvents>()

        event.on('table_event_resetSelectedRows', () => {
            dispatch(resetState())
        })

        return event
    }, [])

    return (
        <OrderTableEventHandlerContext.Provider value={{ eventEmmitter }}>
            {children}
        </OrderTableEventHandlerContext.Provider>
    )
}

export default OrderTableEventHandler
