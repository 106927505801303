import * as React from 'react'
import { User } from 'auth/state'
import { withCurrentUser } from 'hocs'
import { Redirect } from 'react-router-dom'

interface CheckAccountCreatedProps {
    user: User
    children: React.ReactNode
}

export const RequiresCreatedAccount = withCurrentUser(({ user, children }: CheckAccountCreatedProps) => {
    if (user && !user.isAdmin && user.accounts.length === 0) {
        return <Redirect to="/sign_up/account_details" />
    }
    return <>{children}</>
})
