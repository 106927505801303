import * as React from 'react'
import styled from 'styled-typed'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { ReservationCodesPool, RemoveUnusedCodesItem } from 'venue/bookingCodes/bookingCodesService'
import { InlineDataTable, HeaderRow, TableHeader, Cell, ExpandCell, DynamicRow, Sorting } from 'uiComponents/table'
import { ChartDataLoader } from 'uiComponents/loaders'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { faFileTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome'
import { InlineEdit } from 'uiComponents/input'
import { DisabledItemHoverInfotip } from 'uiComponents/infotip'
import { BatchesTable } from './batchesTable'
import { ActionsMenu } from './actionsMenu'
import { LoginService } from 'http/loginService'
import { RetailerResponseItem } from 'channels/channelsService'
import { TableLoader } from 'uiComponents/loaders'
import { Feature } from 'features'

const ProductsIcon = styled(FontAwesomeIcon)`
    font-size: 1.2em;
    opacity: 0.5;
    color: ${(props) => props.theme.colors.status.error};
`

interface ReservationCodePoolsPageProps {
    accountSlug: string
    currentExpanded: string
    navigation: Navigation
    match: RouteMatch<{}>
    poolsList: ReservationCodesPool[]
    poolDetailsList: ReservationCodesPool[]
    retailersList: RetailerResponseItem[]
    sort: Sorting
    loading: boolean
    updating: boolean
    creating: boolean
    loadingPoolDetails: boolean
    loginService: LoginService
    backofficeEndpoint: string
    onNameEditAccept: (id: string, value: string, retailer: string) => void
    onDeletePool: (id: string) => void
    onDeleteBatch: (id: string) => void
    onGenerateCodes: (id: string) => void
    onRemoveUnused: (item: RemoveUnusedCodesItem) => void
    setSort: (sort: Sorting) => void
}

function ResCodePoolsTable(props: ReservationCodePoolsPageProps) {
    const onSortChanged = (sorting: Sorting) => {
        props.navigation.addQueryWithReplace({
            sortBy: sorting.prop,
            sortDirection: sorting.direction,
        })
    }

    const toggleExpanded = (id: string) => {
        const expandId = props.currentExpanded === id ? null : id
        props.navigation.addQueryWithReplace({ expandedPool: expandId })
    }

    const columnWidths = ['4em', '25em', null, '17em']

    return (
        <InlineDataTable columnWidths={columnWidths} bordered id="res-codes-pools-list">
            <HeaderRow>
                <TableHeader nonInteractive />
                <TableHeader sortKey="name" sorting={props.sort} changeSort={onSortChanged} noPadding>
                    Name
                </TableHeader>
                <TableHeader sortKey="external_channel" sorting={props.sort} changeSort={onSortChanged}>
                    Retailer
                </TableHeader>
                <Feature name="ScannableReservationCodesFeature" accountSlug={props.accountSlug}>
                    <TableHeader nonInteractive>Type</TableHeader>
                </Feature>
                <TableHeader nonInteractive />
            </HeaderRow>
            {props.updating && !props.loading && <ChartDataLoader />}
            {props.poolsList.length === 0 && !props.creating && <NoResultsRow text="There are no campaigns yet." />}
            {props.poolsList.length > 0 &&
                props.poolsList.map((item, i) => {
                    const expanded = item.id === props.currentExpanded && item.hasImportRequests
                    const expandable = !!item.hasImportRequests
                    const retailerName =
                        props.retailersList.find((r) => r.id === item.externalChannel)?.name || item.externalChannel
                    const itemDetails = props.poolDetailsList.find((p) => p.id === item.id)
                    const type = item?.isScannable ? 'Scannable codes' : '-'

                    return (
                        <div key={item.id}>
                            <DynamicRow
                                id={item.id}
                                interactive={expandable}
                                highlighted={expanded}
                                onClick={() => (expandable ? toggleExpanded(item.id) : {})}
                                className={expanded ? 'expanded res-codes-pool' : 'res-codes-pool'}
                            >
                                {expandable ? (
                                    <ExpandCell className="expand" expanded={expanded} />
                                ) : (
                                    <Cell align="center" style={{ overflow: 'visible' }}>
                                        <DisabledItemHoverInfotip
                                            active
                                            infotipText="No generated or uploaded codes yet"
                                            offsetLeft="-1em"
                                            width="12em"
                                        >
                                            <ProductsIcon icon={faFileTimes as IconProp} />
                                        </DisabledItemHoverInfotip>
                                    </Cell>
                                )}
                                <Cell title={item.name} className="pool-name" noPadding>
                                    <InlineEdit
                                        id="new-pool-name"
                                        value={item.name}
                                        maxLength={100}
                                        onEditAccept={(v) => props.onNameEditAccept(item.id, v, item.externalChannel)}
                                        responsiveWidth
                                    />
                                </Cell>
                                <Cell title={retailerName}>
                                    <span>{retailerName}</span>
                                </Cell>
                                <Feature name="ScannableReservationCodesFeature" accountSlug={props.accountSlug}>
                                    <Cell title={type}>
                                        <span>{type}</span>
                                    </Cell>
                                </Feature>
                                <Cell align="right" className="overflow-allowed" onClick={(e) => e.stopPropagation()}>
                                    <ActionsMenu
                                        accountSlug={props.accountSlug}
                                        poolId={item.id}
                                        pool={item}
                                        poolHasCodes={item.hasImportRequests}
                                        backofficeEndpoint={props.backofficeEndpoint}
                                        loginService={props.loginService}
                                        onDelete={() => props.onDeletePool(item.id)}
                                        onGenerateCodes={() => props.onGenerateCodes(item.id)}
                                        onRemoveUnused={props.onRemoveUnused}
                                    />
                                </Cell>
                            </DynamicRow>
                            {expanded && props.loadingPoolDetails && <TableLoader />}
                            {expanded && !props.loadingPoolDetails && (
                                <BatchesTable
                                    accountSlug={props.accountSlug}
                                    poolCodeBatches={itemDetails?.importRequests || []}
                                    poolId={item.id}
                                    backofficeEndpoint={props.backofficeEndpoint}
                                    loginService={props.loginService}
                                    onDeleteBatch={props.onDeleteBatch}
                                    onRemoveUnused={props.onRemoveUnused}
                                />
                            )}
                        </div>
                    )
                })}
        </InlineDataTable>
    )
}

export default withNavigation(ResCodePoolsTable)
