import { Audience } from 'crm/schema'
import { isAfter } from 'date-fns'
import React from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { PromotionListResponse } from 'tapPromotions/types'
import { StatusName } from 'uiComponents/table'
import TableRow from './tableRow'

type Props = {
    accountSlug: string
    items: PromotionListResponse[]
    audiences: Audience[]
    onDelete(promotionId: string): void
    onDragEnd(result: DropResult): void
}

const PromotionTableBody = (props: Props) => {
    const isActive = (item: PromotionListResponse): StatusName => {
        if (!item.active) {
            return 'error'
        }

        if (item.endDate) {
            const endDate = new Date(item.endDate)

            return isAfter(endDate, new Date()) ? 'success' : 'warn'
        }

        return 'success'
    }

    return (
        <DragDropContext onDragEnd={props.onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {props.items.map((item, index) => (
                            <TableRow
                                item={item}
                                index={index}
                                key={item.id}
                                status={isActive(item)}
                                accountSlug={props.accountSlug}
                                onDelete={props.onDelete}
                                audiences={props.audiences}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default PromotionTableBody
