import React from 'react'
import styled from 'styled-typed'

import { PaginationSection } from '../../pagination'
import { ReactTableInstance } from '../interface'

const PaginationContainer = styled.div`
    background-color: ${(props) => props.theme.colors.white};
    position: relative;
    display: flex;
    align-items: center;
    padding: 21px 22px 21px 48px;
    height: 74px;
    box-sizing: border-box;
    border-radius: 0px 0px 4px 4px;
    z-index: 0;
`

const PaginationAbsoluteContainer = styled.div`
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
`

interface ReactTablePaginationProps {
    itemsTotalCount?: number
    instance: ReactTableInstance
}

const StandardPagination: React.FC<ReactTablePaginationProps> = ({ instance, itemsTotalCount }) => {
    return (
        <PaginationContainer>
            <PaginationAbsoluteContainer>
                <PaginationSection
                    pagination={{
                        page: instance.state.pageIndex + 1,
                        pageSize: instance.state.pageSize,
                    }}
                    onPaginationChanged={(pagination) => {
                        if (pagination.pageSize !== instance.state.pageSize) {
                            return instance.setPageSize(pagination.pageSize)
                        }

                        if (pagination.page !== instance.state.pageIndex + 1) {
                            return instance.gotoPage(pagination.page - 1)
                        }
                    }}
                    totalItemsCount={itemsTotalCount as number}
                />
            </PaginationAbsoluteContainer>
        </PaginationContainer>
    )
}

export default StandardPagination
