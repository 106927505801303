/* eslint-disable react/display-name */
import * as React from 'react'
import { connect } from 'react-redux'
import { State } from 'store'
import { Route, match as RouteMatch, RouteComponentProps } from 'react-router-dom'
import { withRouter, useHistory } from 'react-router-dom'
import { ReactComponent } from 'reactUtils'
import { Subtract } from 'utility-types'
import { User } from 'auth/state'
import { Profile } from 'settings/schema'
import { Navigation } from 'navigation'
import { delay } from 'utils'
import { Message, MessageKind, MessageButton } from 'uiComponents/messages'

export interface CurrentUserProps {
    user: User
}

export function withCurrentUser<TProps extends CurrentUserProps>(
    Component: ReactComponent<TProps & CurrentUserProps>,
): ReactComponent<Subtract<TProps, CurrentUserProps>> {
    // @ts-ignore
    return connect((state: State) => ({ user: state.auth.user }))(Component)
}

interface UserAndProfileProps {
    user: User
    profile: Profile
}

export function withUserAndProfile<TProps extends UserAndProfileProps>(
    Component: ReactComponent<TProps & UserAndProfileProps>,
): ReactComponent<Subtract<TProps, UserAndProfileProps>> {
    // @ts-ignore
    return connect((state: State) => ({
        user: state.auth.user,
        profile: state.profile.profile,
    }))(Component as any)
}

export interface BaseRouteParams {
    accountSlug: string
}

export interface WithNavigationProps<TRouteParams extends { [K in keyof TRouteParams]?: string | undefined }> {
    navigation: Navigation
    match: RouteMatch<TRouteParams>
}

export function withNavigation<
    TProps extends WithNavigationProps<TRouteParams>,
    TRouteParams extends { [K in keyof TRouteParams]?: string | undefined },
>(Component: ReactComponent<TProps>): ReactComponent<Subtract<TProps, WithNavigationProps<TRouteParams>>> {
    return function (props: TProps) {
        return (
            <Route
                render={({ history, match }) => {
                    return <Component {...props} navigation={new Navigation(history, history.location)} match={match} />
                }}
            />
        )
    }
}

export const useNavigation = () => {
    const history = useHistory()

    return React.useMemo(() => new Navigation(history, history.location), [history, history.location])
}

interface MessagesState {
    messages: Message[]
}

export type ReplaceMessagesFunc = (
    id: string,
    status: MessageKind,
    text: string,
    actionButton?: MessageButton,
    scrollToTop?: boolean,
) => void

export interface MessageProps {
    messages: Message[]
    addMessage: (id: string, status: MessageKind, text: string, actionButton?: MessageButton) => void
    hideMessage: (id: string) => void
    replaceMessages: ReplaceMessagesFunc
    removeAllMessages: () => void
}

function appendMessage(messages: Message[], message: Message): Message[] {
    let newMessages = [...messages]
    const idx = messages.findIndex((m) => m.id === message.id)
    if (idx > -1) {
        newMessages.splice(idx, 1)
    }

    newMessages.push(message)
    return newMessages
}

interface LocationState {
    messages?: Message[]
    keepMessages?: boolean
}

export function withMessages<TProps extends MessageProps>(
    Component: ReactComponent<TProps>,
): ReactComponent<Subtract<TProps, MessageProps>> {
    class MessagesComponent extends React.Component<TProps & RouteComponentProps<any>, MessagesState> {
        constructor(props: TProps & RouteComponentProps<any>) {
            super(props)
            this.state = {
                messages: [],
            }
        }
        componentDidMount() {
            const locationState = this.props.location.state as LocationState
            if (locationState && locationState.messages && locationState.messages.length > 0) {
                this.checkMessagesInLocationState(locationState)
            }
        }
        UNSAFE_componentWillReceiveProps(nextProps: TProps & RouteComponentProps<any>) {
            if (nextProps.location.state !== this.props.location.state && nextProps.location.state) {
                const locationState = nextProps.location.state as LocationState
                if (locationState.messages && locationState.messages.length > 0) {
                    this.checkMessagesInLocationState(locationState)
                }
            } else if (nextProps.location.pathname !== this.props.location.pathname) {
                this.removeAllMessages()
            }
        }

        checkMessagesInLocationState = async (locationState: LocationState) => {
            if (!locationState.messages) {
                return
            }

            const toAppend: Message[] = locationState.messages
            const keepMeessages = locationState.keepMessages
            // @ts-ignore
            if (!this.props.disregardLocationState) {
                this.setState({
                    messages: toAppend.reduce(appendMessage, this.state.messages),
                })
            }
            const state = { ...locationState }
            delete state.messages
            delete state.keepMessages
            this.props.history.replace({ ...this.props.history.location, state })
            if (!keepMeessages) {
                await delay(3000)
                this.hideAllMessages()
            }
        }

        addMessage = (id: string, status: MessageKind, text: string, actionButton: MessageButton = null) => {
            this.setState({
                messages: appendMessage(this.state.messages, {
                    id,
                    status,
                    text,
                    visible: true,
                    actionButton,
                }),
            })
        }

        hideMessage = (id: string) => {
            const foundIndex = this.state.messages.findIndex((m) => m.id === id)
            if (foundIndex === -1) {
                return
            }

            const found = this.state.messages[foundIndex]
            const newMessages = [...this.state.messages]
            newMessages[foundIndex] = { ...found, visible: false }
            this.setState({ messages: newMessages })
        }

        hideAllMessages = () => {
            const newMessages = [...this.state.messages]
            newMessages.map((m) => {
                m.visible = false
            })
            this.setState({ messages: newMessages })
        }

        replaceMessages = (
            id: string,
            status: MessageKind,
            text: string,
            actionButton: MessageButton = null,
            scrollToTop: boolean = false,
        ) => {
            this.setState({ messages: [] }, () => {
                this.addMessage(id, status, text, actionButton)
                if (scrollToTop) {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                }
            })
        }

        removeAllMessages = () => {
            this.setState({ messages: [] })
        }

        render() {
            return (
                <Component
                    {...this.props}
                    messages={this.state.messages}
                    addMessage={this.addMessage}
                    hideMessage={this.hideMessage}
                    replaceMessages={this.replaceMessages}
                    removeAllMessages={this.removeAllMessages}
                />
            )
        }
    }

    return withRouter(MessagesComponent) as any as ReactComponent<Subtract<TProps, MessageProps>>
}
