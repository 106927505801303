import React, { createContext, useReducer } from 'react'
import { ArticleFromApi, CategoryFromApi } from '../types'

export enum ProductTableActionKind {
    SET_PRODUCT_FOR_DELETING = 'SET_PRODUCT_FOR_DELETING',
    SET_PRODUCT_FOR_DUPLICATING = 'SET_PRODUCT_FOR_DUPLICATING',
}

interface ProductTableAction {
    type: ProductTableActionKind
    payload: any
}

interface State {
    selectProductToDelete?: CategoryFromApi | ArticleFromApi
    selectProductToDuplicate?: ArticleFromApi
}
interface Props {
    state: State
    dispatch: React.Dispatch<ProductTableAction>
}

export const productTableContext = createContext<Props>({
    dispatch: () => {},
    state: {},
})

const productTableReducer = (state: State, action: ProductTableAction) => {
    switch (action.type) {
        case ProductTableActionKind.SET_PRODUCT_FOR_DELETING:
            return {
                ...state,
                selectProductToDelete: action.payload,
            }
        case ProductTableActionKind.SET_PRODUCT_FOR_DUPLICATING:
            return {
                ...state,
                selectProductToDuplicate: action.payload,
            }
        default:
            return state
    }
}

export const ProductTableProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(productTableReducer, {})
    return (
        <productTableContext.Provider
            value={{
                state,
                dispatch: dispatch,
            }}
        >
            {children}
        </productTableContext.Provider>
    )
}
