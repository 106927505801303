import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { InlineEdit } from 'uiComponents/input'
import { BaseRouteParams, withNavigation, WithNavigationProps } from 'hocs'
import { FeatureProps, withFeatures } from 'features'
import { isValidEmail } from 'utils/formFieldChecks'
import { useUpdateEmailMutation } from 'orders/reduxQueries'
import { addMessage } from 'uiComponents/messages/actions'
import { IOrder } from 'orders/schema'

interface InlineEmailProps extends WithNavigationProps<BaseRouteParams>, FeatureProps {
    order: IOrder
    style?: React.CSSProperties
    limitedTextWidth?: string
    onUpdated?: (order: IOrder) => void
}

function InlineEmail({ order, style, match, limitedTextWidth, navigation, hasFeature, onUpdated }: InlineEmailProps) {
    const { accountSlug } = match.params
    const [updateEmail, status] = useUpdateEmailMutation()
    const searchResult = !!navigation.query().q
    const dispatch = useDispatch()
    const handleEditAccept = useCallback(
        async (email) => {
            if (!isValidEmail(email)) {
                dispatch(addMessage('inline-email', 'error', 'Invalid email. Please try again.'))
            } else {
                try {
                    await updateEmail({ orderUuid: order.id, email })
                    if (onUpdated) {
                        onUpdated({ ...order, customer: { ...order.customer, email } })
                    }
                } catch {
                    dispatch(
                        addMessage(
                            'inline-email',
                            'error',
                            `Oops! There was a problem with saving email ${email}. Please try again.`,
                        ),
                    )
                }
            }
        },
        [order.id],
    )

    return (
        <InlineEdit
            id="email"
            value={order?.customer?.email}
            onEditAccept={handleEditAccept}
            limitedTextWidth={limitedTextWidth}
            responsiveWidth
            maskIcon={!!order?.customer?.email}
            profileIcon={hasFeature('CRMProfilesPage', accountSlug) && !!order?.customer?.email}
            disabled={order.status === 'refunded' || order.status === 'refunding'}
            maskData={(!searchResult || status.isLoading) && !!order?.customer?.email}
            accountSlug={accountSlug}
            style={style}
        />
    )
}

export default withFeatures(withNavigation(InlineEmail))
