import { ConfigFieldRaw, allDefaultFields, Translations, RawTranslations } from './schema'
import { prettifySlug } from 'utils'
import { Locale } from 'admin/articleService'
import { remove } from 'lodash'

export const mapRawFields = (
    fields: ConfigFieldRaw[],
    locales: Locale[],
    allTranslations: RawTranslations,
    isUSPartner: boolean = false,
) => {
    const mappedDefaultFields = allDefaultFields.map((df) => {
        const configField = fields.find((f) => f.name === df.slug)
        return {
            name: df.name,
            slug: df.slug,
            type: df.type,
            buyer: configField ? !configField.repeatable : df.slug.includes('buyer'),
            custom: false,
            active: !!configField,
            optional: configField ? !configField.required : false,
            translations: configField?.translations || mapEmptyTranslations(locales, df.slug, allTranslations),
            products: configField?.articles || [],
        }
    })
    const customFields = fields.filter((f) => !allDefaultFields.find((df) => df.slug === f.name))
    const mappedCustomFields = customFields.map((cf) => ({
        name: getCustomFieldName(cf),
        slug: cf.name,
        type: cf.type,
        buyer: !cf.repeatable,
        custom: true,
        active: true,
        optional: !cf.required,
        translations: cf.translations,
        products: cf.articles || [],
    }))

    if (!isUSPartner) {
        remove(mappedDefaultFields, (f) => f.type === 'state')
    }

    return [...mappedDefaultFields, ...mappedCustomFields]
}

export const mapEmptyTranslations = (
    locales: Locale[],
    slug: string,
    allTranslations: RawTranslations,
    enLabel: string = '',
) => {
    const translations = {}
    locales.forEach((l) => {
        const existingLabel = allTranslations[l.code]?.find((t) => t.key === `${slug}_label`)?.value || ''
        const existingPlaceholder = allTranslations[l.code]?.find((t) => t.key === `${slug}_placeholder`)?.value || ''
        const label = existingLabel ? existingLabel : l.code === 'en' ? enLabel : ''
        Object.assign(translations, {
            [l.code]: { label, placeholder: existingPlaceholder },
        })
    })
    return translations
}

const getCustomFieldName = (field: ConfigFieldRaw) => {
    return field.translations.en?.label || prettifySlug(field.name)
}

const getTransValue = (value: string | null) => {
    const trimmed = value ? value.trim() : null
    return trimmed || ' '
}

export const mapTranslationsForPayload = (translations: Translations, type: 'label' | 'placeholder') => {
    const mappedTranslations = {}
    Object.entries(translations).forEach(([k, v]) => Object.assign(mappedTranslations, { [k]: getTransValue(v[type]) }))
    return mappedTranslations
}
