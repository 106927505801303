import React from 'react'
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd'

import { Category } from 'venue/parkMap/models'
import { sortPoisByName } from '../../../helpers'
import CategoryLi from './categoryLi'
import PoiLi, { ExtendedPoi } from './poiLi'

interface CategoriesPoisListProps {
    data: Category[]
    onDragEnd(result: DropResult, provided: ResponderProvided): void
    onEditCategory(categoryId: string): void
    onDeleteCategory(categoryId: string): void
    onDeletePoi(poiId: string): void
    onAddPoi(categoryId: string): void
    onEditPoi(poiId: string): void
}

const CategoriesPoisList = ({
    data,
    onEditCategory,
    onDragEnd,
    onDeleteCategory,
    onDeletePoi,
    onAddPoi,
    onEditPoi,
}: CategoriesPoisListProps) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="category-list" type="droppable-category">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {data.map((cat, index) => {
                            return (
                                <CategoryLi
                                    key={index}
                                    index={index}
                                    data={cat}
                                    onAddPoi={() => onAddPoi(cat.id)}
                                    onEditCategory={onEditCategory}
                                    onDeleteCategory={onDeleteCategory}
                                >
                                    {cat.pois
                                        ?.slice()
                                        .sort(sortPoisByName())
                                        .map((poi: ExtendedPoi, index: number) => (
                                            <PoiLi
                                                key={poi.id}
                                                data={poi}
                                                onEditPoi={onEditPoi}
                                                onDeletePoi={onDeletePoi}
                                                index={index}
                                            />
                                        ))}
                                </CategoryLi>
                            )
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default CategoriesPoisList
