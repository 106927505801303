import { CreatePricingSettingsError } from './pricingService'
import { MessageKind, Message } from 'uiComponents/messages'
import { AvailabilityDates } from 'products/articleConfigurationService'
import { SingleSelectOption } from 'uiComponents/input'

export const priceStepSizeOptions: SingleSelectOption[] = [
    { name: '0.01 (no rounding)', value: '0.01' },
    { name: '0.05', value: '0.05' },
    { name: '0.10', value: '0.10' },
    { name: '0.25', value: '0.25' },
    { name: '0.50', value: '0.50' },
    { name: '1.00', value: '1.00' },
]

export const DEFAULT_STEP_SIZE = '0.01'

export const priceStepSizeInfotipText =
    'This will define the steps used to round your prices. For example, a step size of \
  0.05 cents allows you to round 11.82 to 11.80 or 11.97 to 12.00. This allows unifying  prices and increasing their appeal.'

export const charmPricingInfotipText = 'With charm pricing, the left digit is reduced from a round number by one cent.'

export const pricingSaveSuccessMessage: Message = {
    id: 'pricing_settings_save_success',
    status: 'success',
    text: 'Pricing settings were successfully saved.',
    visible: true,
}

export function handleFailure(
    err: CreatePricingSettingsError,
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void,
) {
    switch (err.type) {
        case 'server_error':
            replaceTopMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
            return
        case 'settings_validation':
            replaceTopMessages('settings_validation', 'error', `Problem with pricing settings: ${err.message}.`)
            return
        case 'override_validation':
            replaceTopMessages(
                'override_validation',
                'error',
                `Problem applying exception '${err.overrideName}': ${err.message}.`,
            )
            return
        case 'unknown_validation_error':
            replaceTopMessages('unknown_validation_error', 'error', `Error: ${err.message}.`)
            return
        default:
            throw new Error(`Unknown error type ${(err as any).type}`)
    }
}

export interface Validity {
    valid: boolean
    message: string
}
export interface Validities {
    [key: string]: Validity
}
export function getValidity(value: number | null): {
    valid: boolean
    message: string
} {
    let validity = {
        valid: true,
        message: '',
    }
    if (value === null) {
        validity = {
            valid: false,
            message: 'This price should be set.',
        }
    } else if (value < 0) {
        validity = {
            valid: false,
            message: 'This price should be higher than 0.',
        }
    } else if (value > 9999) {
        validity = {
            valid: false,
            message: 'This price can not be higher than 9999.',
        }
    }
    return validity
}

export function priceToNumberOrNull(price: string) {
    return price === null ? null : Number(price)
}

export const validateMinAcceptancePrice = (min: number | null, max: number | null) => {
    let validity = {
        valid: true,
        message: '',
    }
    if (min === null) {
        return validity
    } else if (max !== null && min > max) {
        validity = {
            valid: false,
            message: 'Minimum Accepted price should be lower than the Maximum Accepted price.',
        }
    } else if (min < 0) {
        validity = {
            valid: false,
            message: 'Minimum Accepted price should be larger than 0.',
        }
    } else if (min > 9999) {
        validity = {
            valid: false,
            message: 'This price can not be higher than 9999.',
        }
    }
    return validity
}

export const validateAvgTargetPrice = (avg: number | null, min: number | null, max: number | null) => {
    let validity = {
        valid: true,
        message: '',
    }
    if (avg === null) {
        return validity
    }
    if (max !== null && avg > max) {
        validity = {
            valid: false,
            message: 'Average price should be lower than the Maximum Accepted price.',
        }
    }
    if (min !== null && avg < min) {
        validity = {
            valid: false,
            message: 'Average price should be higher than the Minimum Accepted price.',
        }
    }
    if (avg < 0) {
        validity = {
            valid: false,
            message: 'Average price should be higher than 0.',
        }
    } else if (avg > 9999) {
        validity = {
            valid: false,
            message: 'This price can not be higher than 9999.',
        }
    }
    return validity
}

export const validateGatePrice = (gate: number | null, max: number | null) => {
    let validity = {
        valid: true,
        message: '',
    }
    if (gate === null) {
        return validity
    } else if (max !== null && gate < max) {
        validity = {
            valid: false,
            message: 'Gate price should be higher than the Maximum Accepted price.',
        }
    } else if (gate < 0) {
        validity = {
            valid: false,
            message: 'Gate price should be higher than 0.',
        }
    } else if (gate > 9999) {
        validity = {
            valid: false,
            message: 'This price can not be higher than 9999.',
        }
    }
    return validity
}

export function isAnyDateAvailable(data: AvailabilityDates): boolean {
    const dates = Object.keys(data)
    let someDateAvailable = false
    dates.forEach((key) => {
        if (data[key].available) {
            someDateAvailable = true
            return
        }
    })
    return someDateAvailable
}
