import * as React from 'react'
import { ComponentsService } from './componentsService'
import { Configuration } from './schema'

export const ComponentsServiceContext = React.createContext(null as any as ComponentsService)

interface StudioFormContextState {
    values: Configuration | null
    handleFieldChange(name: string, value: string | number | boolean | string[]): void
}

export const StudioFormContext = React.createContext(null as any as StudioFormContextState)
