import React, { useContext } from 'react'
import { DiscountRulesServiceContext } from 'products/discountRules/context'
import { DiscountRulesContext } from 'products/discountRules/table/tableContext'
import { DiscountRule } from 'products/discountRules/discountRulesService'
import { Toggle } from 'uiComponents/input/toggle'
import { MetadataContext } from 'products/discountRules/metadataContext'

interface InlineNameProps {
    rule: DiscountRule
}

function StatusCell({ rule }: InlineNameProps) {
    const discountRulesService = useContext(DiscountRulesServiceContext)
    const { reloadData } = useContext(DiscountRulesContext)
    const { accountSlug, replaceMessages } = useContext(MetadataContext)

    const toggleEnabled = async (ruleId: number) => {
        try {
            await discountRulesService.updateDiscountRuleStatus(accountSlug, ruleId, !rule.enabled)
            reloadData()
        } catch {
            replaceMessages('email_error', 'error', 'Oops! We could not change the rule status. Please try again.')
        }
    }

    return (
        <>
            Activate
            <Toggle
                isOn={rule.enabled}
                onClick={() => toggleEnabled(rule.id)}
                className="smallInlineToggle"
                style={{ marginLeft: '0.7rem' }}
            />
        </>
    )
}

export default StatusCell
