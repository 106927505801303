import * as React from 'react'
import { PageTitle, PageHeading } from 'uiComponents/typography'
import { useGetFeatureValue } from 'utils/useHasFeature'
import { SupersetReportFeatureFlag } from 'reports/superset/hooks'

const OrdersDetailPageHeading = ({ account_slug }: { account_slug: string }) => {
    const supersetReportsFeatures = useGetFeatureValue('superset-reports')
    const salesReport: SupersetReportFeatureFlag = supersetReportsFeatures.find(
        (feature: SupersetReportFeatureFlag) => feature.featureFlag === 'superset-report-sales',
    )

    return (
        <>
            <PageTitle>Orders details</PageTitle>
            <PageHeading>
                Find the details for all items in your orders.{' '}
                {salesReport && (
                    <div>
                        Tax information can be found in the{' '}
                        <a href={`/account/${account_slug}/reports/superset/${salesReport.dashboardId}/`}>
                            New Sales Report
                        </a>
                        .
                    </div>
                )}
            </PageHeading>
        </>
    )
}

export default OrdersDetailPageHeading
