import * as React from 'react'
import { History } from 'history'
import { ArticleConfigurationService, ValidityException } from 'products/articleConfigurationService'
import { ArticleService as AdminArticleService } from 'admin/articleService'
import ExceptionForm from './exceptionForm'
import { LoaderWrapper } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { ArticleFromApi } from '../../types'
import { useGetAllArticles, useIsLoadingCategoriesOrArticles } from '../../redux'

interface ExceptionEditProps {
    id: string
    prototype?: boolean
    history: History
    articleConfigurationService: ArticleConfigurationService
    articleService: AdminArticleService
    accountSlug: string
    setActiveSection: (section: string, header?: string) => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    className?: string
    hasPermission: (permission: string, accountSlug: string) => boolean
    allArticles?: ArticleFromApi[]
    isLoadingArticles?: boolean
}

interface ExceptionEditState {
    exception: ValidityException | null
    exceptionProducts: ArticleFromApi[]
    loading: boolean
}

class ExceptionEdit extends React.Component<ExceptionEditProps, ExceptionEditState> {
    constructor(props: ExceptionEditProps) {
        super(props)
        this.state = {
            exception: null,
            exceptionProducts: [],
            loading: true,
        }
    }

    componentDidMount() {
        this.fetchExceptionInformation(this.props.id)
    }

    componentDidUpdate(prevProps: Readonly<ExceptionEditProps>, prevState: Readonly<ExceptionEditState>) {
        if (
            this.props.allArticles?.length &&
            this.state.exception?.articles?.length &&
            (prevProps.allArticles !== this.props.allArticles || prevState.exception !== this.state.exception)
        ) {
            const articlesIds = this.state.exception.articles.map((p) => p.id)

            const exceptionArticles = this.props.allArticles.filter((article) => articlesIds.includes(article.id))

            this.setState({
                exceptionProducts: exceptionArticles,
            })
        }
    }

    fetchExceptionInformation = async (id: string) => {
        try {
            const exception = await this.props.articleConfigurationService.getValidityException(
                this.props.accountSlug,
                id,
            )
            if (!exception || Object.keys(exception).length === 0) {
                this.props.replaceTopMessages('server_error', 'error', 'Oops! The exception could not be found.')
                return
            }
            this.setState({
                exception: exception,
            })
        } catch {
            this.props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Something went wrong. Please try again later.',
            )
        } finally {
            this.setState({ loading: false })
        }
    }

    render() {
        const { accountSlug, articleConfigurationService, setActiveSection, className, prototype } = this.props
        const { exception, exceptionProducts } = this.state

        return (
            <LoaderWrapper loading={this.state.loading || this.props.isLoadingArticles}>
                {exception && (
                    <ExceptionForm
                        className={className}
                        key={exception.id}
                        exception={exception}
                        history={this.props.history}
                        exceptionProducts={exceptionProducts}
                        accountSlug={accountSlug}
                        articleConfigurationService={articleConfigurationService}
                        articleService={this.props.articleService}
                        setActiveSection={setActiveSection}
                        replaceTopMessages={this.props.replaceTopMessages}
                        removeAllMessages={this.props.removeAllMessages}
                        hasPermission={this.props.hasPermission}
                        prototype={prototype}
                    />
                )}
            </LoaderWrapper>
        )
    }
}
const ArticlesWrapper = (props: Omit<ExceptionEditProps, 'allArticles'>) => {
    const articles = useGetAllArticles()
    const isLoadingArticles = useIsLoadingCategoriesOrArticles()

    return <ExceptionEdit {...props} allArticles={articles} isLoadingArticles={isLoadingArticles} />
}

export default ArticlesWrapper
