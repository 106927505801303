import React, { FC } from 'react'
import { Tooltip as ReactTooltip, ITooltip } from 'react-tooltip'
import { Body } from '../typography'
import styled from 'styled-typed'

export const Container = styled(ReactTooltip)`
    background-color: ${(props) => props.theme.colors.textDark};
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    opacity: 1;
`

interface Props extends ITooltip {
    text: string
}

const Tooltip: FC<Props> = (props) => {
    const { text, ...rest } = props

    return (
        <Container {...rest}>
            <Body className="tooltip-text" size={2} color="white">
                {text}
            </Body>
        </Container>
    )
}

export default Tooltip
