import * as React from 'react'
import { usePrevious } from 'reactUtils'
import TimeseriesBarChart from 'uiComponents/charts/timeseriesBarChart'
import { StatsServiceContext } from 'http/context'
import { DateRange } from 'dateRanges'
import { format } from 'date-fns'
import { TimeseriesBarChartSource } from 'uiComponents/charts/timeseriesBarChartSource'
import { ChartWrapperWithTotals } from '../helpers'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import {
    areDateRangeDatesEqual,
    mapTimeseriesForTooltip,
    getTooltipRow,
    getTooltipXAxisLabel,
    getTooltipHeader,
    getTooltipFooter,
    revenuePageMetricMap,
    AxisTitle,
    mapTimeseriesData,
    mapGroupedTimeseriesData,
    mapStringTimeseriesForTooltip,
} from 'reports/helpers'
import { FilterOption } from 'uiComponents/filter/schema'
import { Filters, MetaData } from 'uiComponents/filter/schema'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import {
    DataPoint,
    DownloadData,
    dummyDownloadData,
    DownloadHeader,
    RevenuePageMetricType,
    DataTotal,
    ReportTimeIdentifierType,
    RawDataSeries,
    DataSeries,
    dummyDataSeries,
    StatsResponse,
} from 'reports/schema'
import { QueryConfig, Query } from 'reports/queryGenerator'
import isEqual from 'lodash/isEqual'
import { withFeatures } from 'features'
import ExportMenu from 'reports/exportMenu'
import { LoginService } from 'http/loginService'
import { LoggingService } from 'http/loggingService'
import { weatherIconMap } from 'reports/constants'
import { appleTrademarkIcon } from 'reports/weatherIcons'
import Big from 'big.js'
import { formatISOString } from 'utils/dates'
import { getPartnersUnitTemperature } from 'utils/unitsSystem'
import { useAppSelector } from 'store/hooks'
import { getIsUSPartner } from 'auth/selectors'

const labelFormatter = (num: any) => {
    const isMillions = num > 1000000
    const isThousands = num > 999 && num < 1000000

    if (isMillions) {
        return Big(num).div(1000000).toFixed(1) + 'M'
    }

    if (isThousands) {
        return Big(num).div(1000).toFixed(1) + 'K'
    }

    return num as string
}

const queryConfig: QueryConfig = {
    querySetName: 'RevenuePageBarChart',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'groupBy', type: 'String' },
        { name: 'filters', type: '[FilterDictionary]' },
        { name: 'granularity', type: 'StatsGranularity' },
        { name: 'timeIdentifierType', type: 'TimeIdentifierType' },
    ],
    queries: [
        {
            name: 'groupped_revenue',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'groupBy', 'filters', 'timeIdentifierType'],
            customVariables: [{ name: 'metric', customValue: 'revenue' }],
            presetResult: 'timeseries',
        },
        {
            name: 'groupped_transactions',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'groupBy', 'filters', 'timeIdentifierType'],
            customVariables: [{ name: 'metric', customValue: 'transactions' }],
            presetResult: 'timeseries',
        },
        {
            name: 'groupped_products_sold',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'groupBy', 'filters', 'timeIdentifierType'],
            customVariables: [{ name: 'metric', customValue: 'products_sold' }],
            presetResult: 'timeseries',
        },
        {
            name: 'revenue',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters', 'timeIdentifierType'],
            customVariables: [{ name: 'metric', customValue: 'revenue' }],
            presetResult: 'timeseriesAndTotals',
        },
        {
            name: 'transactions',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters', 'timeIdentifierType'],
            customVariables: [{ name: 'metric', customValue: 'transactions' }],
            presetResult: 'timeseriesAndTotals',
        },
        {
            name: 'products_sold',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters', 'timeIdentifierType'],
            customVariables: [{ name: 'metric', customValue: 'products_sold' }],
            presetResult: 'timeseriesAndTotals',
        },
        {
            name: 'weatherIcon',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo'],
            customVariables: [{ name: 'metric', customValue: 'weather_icon' }],
            presetResult: 'dataSeries',
        },
        {
            name: 'weatherTemperature',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo'],
            customVariables: [{ name: 'metric', customValue: 'weather_temperature' }],
            presetResult: 'dataSeries',
        },
        {
            name: 'weatherRainChance',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo'],
            customVariables: [{ name: 'metric', customValue: 'weather_chance_of_rain' }],
            presetResult: 'dataSeries',
        },
        {
            name: 'weatherRainIntensity',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo'],
            customVariables: [{ name: 'metric', customValue: 'weather_rain_intensity' }],
            presetResult: 'dataSeries',
        },
    ],
}

const additionalRevenueQueries: Query[] = [
    {
        name: 'discounts',
        type: 'stats',
        configVariables: ['widget', 'dateFrom', 'dateTo', 'filters', 'timeIdentifierType'],
        customVariables: [{ name: 'metric', customValue: 'voucher_revenue' }],
        presetResult: 'totals',
    },
    {
        name: 'collected',
        type: 'stats',
        configVariables: ['widget', 'dateFrom', 'dateTo', 'filters', 'timeIdentifierType'],
        customVariables: [{ name: 'metric', customValue: 'collected_revenue' }],
        presetResult: 'totals',
    },
    {
        name: 'reseller',
        type: 'stats',
        configVariables: ['widget', 'dateFrom', 'dateTo', 'filters', 'timeIdentifierType'],
        customVariables: [{ name: 'metric', customValue: 'reseller_revenue' }],
        presetResult: 'totals',
    },
]

interface WeatherData {
    weatherIcon: DataSeries
    weatherTemperature: DataSeries
    weatherRainChance: DataSeries
    weatherRainIntensity: DataSeries
}

interface RevenueChartProps {
    dateRange: DateRange
    timeIdentifierType: ReportTimeIdentifierType
    filters: Filters[]
    accountSlug: string
    metric: RevenuePageMetricType
    chart: TimeseriesBarChartSource
    groupByLocation: boolean
    loginService: LoginService
    loggingService: LoggingService
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hideMessage: (id: string) => void
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    getCurrency: (accountSlug: string) => Currency
    hasFeature: (feature: string, accountSlug: string) => boolean
    metadata: MetaData[]
}

function RevenueChart(props: RevenueChartProps) {
    const _isMounted = React.useRef(false)
    const _lastRequest = React.useRef<number>()
    const statsService = React.useContext(StatsServiceContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [total, setTotal] = React.useState<string>('0')
    const [discounts, setDiscounts] = React.useState<string | null>('0')
    const [collected, setCollected] = React.useState<string | null>('0')
    const [reseller, setReseller] = React.useState<string | null>('0')
    const [mappedRevenue, setMappedRevenue] = React.useState<DataPoint[]>([])
    const [mappedTransactions, setMappedTransactions] = React.useState<DataPoint[]>([])
    const [mappedProductsSold, setMappedProductsSold] = React.useState<DataPoint[]>([])
    const [downloadData, setDownloadData] = React.useState<DownloadData>(dummyDownloadData)
    const [weatherData, setWeatherData] = React.useState<WeatherData | null>(null)

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevMetric = usePrevious(props.metric)
    const prevDateRange = usePrevious(props.dateRange)
    const prevFilters = usePrevious(props.filters)
    const prevGroupBy = usePrevious(props.groupByLocation)
    const prevTimeIdentifierType = usePrevious(props.timeIdentifierType)
    const prevMetadata = usePrevious(props.metadata)

    const isUSPartner = useAppSelector(getIsUSPartner)

    React.useEffect(() => {
        _isMounted.current = true
        if (
            prevAccountSlug !== props.accountSlug ||
            prevMetric !== props.metric ||
            !areDateRangeDatesEqual(prevDateRange, props.dateRange) ||
            !isEqual(prevFilters, props.filters) ||
            prevGroupBy !== props.groupByLocation ||
            prevTimeIdentifierType !== props.timeIdentifierType ||
            !isEqual(prevMetadata, props.metadata)
        ) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [
        props.accountSlug,
        props.metric,
        props.dateRange,
        props.filters,
        props.groupByLocation,
        props.timeIdentifierType,
        props.metadata,
    ])

    const buildDownloadData = (data: StatsResponse, weather: WeatherData) => {
        const currency = props.getCurrency(props.accountSlug)
        const name = 'sales_per_time_range'
        const width = 20
        const locationsHeader = !!props.groupByLocation ? [{ slug: 'location', label: 'Location', width }] : []
        const headers: DownloadHeader[] = [
            { slug: 'date', label: 'Date', width },
            ...locationsHeader,
            { slug: 'revenue', label: `Revenue (${currency.symbol})`, width },
            { slug: 'transactions', label: 'Transactions', width },
            { slug: 'products_sold', label: 'Products sold', width },
            { slug: 'weather', label: 'Weather', width },
            { slug: 'weatherTemperature', label: 'Temperature', width },
        ]
        const weatherIconDataForExport = buildWeatherDataForExport('weatherIcon', weather, data)
        const weatherTemperatureDataForExport = buildWeatherDataForExport('weatherTemperature', weather, data)

        const raw = [
            data.groupped_revenue.timeSeries,
            data.groupped_transactions.timeSeries,
            data.groupped_products_sold.timeSeries,
            weatherIconDataForExport,
            weatherTemperatureDataForExport,
        ]

        const xAxisCategories = props.chart.props().xAxisDataRaw

        const mappedRevenueData = !!data.revenue?.timeSeries.length
            ? mapTimeseriesForTooltip(xAxisCategories, data.revenue.timeSeries[0].points, props.chart.granularity)
            : []
        const mappedTransactionsData = !!data.transactions?.timeSeries.length
            ? mapTimeseriesForTooltip(xAxisCategories, data.transactions.timeSeries[0].points, props.chart.granularity)
            : []
        const mappedProductsSoldData = !!data.products_sold?.timeSeries.length
            ? mapTimeseriesForTooltip(xAxisCategories, data.products_sold.timeSeries[0].points, props.chart.granularity)
            : []

        const mappedWeatherIconData = !!weatherIconDataForExport.length
            ? mapStringTimeseriesForTooltip(
                  xAxisCategories,
                  weatherIconDataForExport[0].points,
                  props.chart.granularity,
              )
            : []

        const mappedWeatherTemperatureData = !!weatherTemperatureDataForExport.length
            ? mapStringTimeseriesForTooltip(
                  xAxisCategories,
                  weatherTemperatureDataForExport[0].points,
                  props.chart.granularity,
              )
            : []

        const values = !!props.groupByLocation
            ? mapGroupedTimeseriesData(raw, props.chart.props().xAxisDataRaw)
            : mapTimeseriesData([
                  mappedRevenueData,
                  mappedTransactionsData,
                  mappedProductsSoldData,
                  mappedWeatherIconData,
                  mappedWeatherTemperatureData,
              ])
        setDownloadData({ name, headers, values })
        setMappedRevenue(mappedRevenueData)
        setMappedTransactions(mappedTransactionsData)
        setMappedProductsSold(mappedProductsSoldData)
    }

    const buildWeatherDataForExport = (
        metric: 'weatherTemperature' | 'weatherIcon',
        weather: WeatherData,
        data: StatsResponse,
    ): RawDataSeries[] => {
        const timeSeries = data.groupped_revenue.timeSeries.length
            ? data.groupped_revenue.timeSeries
            : data.revenue?.timeSeries || []

        const weatherInfo = timeSeries.map((raw) => {
            const locationPoints = [...raw.points].map((point) => {
                const timestamp = point.timestamp
                const value = getWeatherValue(timestamp, raw.label, metric, weather)
                return { timestamp, value }
            })

            return {
                label: raw.label,
                points: locationPoints,
            }
        })
        return weatherInfo
    }

    const getWeatherValue = (
        timestamp: string,
        locationName: string | null,
        metric: 'weatherTemperature' | 'weatherIcon',
        weather: WeatherData,
    ): string | number => {
        if (!weather) {
            return ''
        }

        const locationMetadata = props.metadata.find((m) => m.category === 'location_id')
        let location: FilterOption | null = null
        let value: string[] | null = null

        const dateIndex = weather[metric].columns.indexOf('dt')
        const forecastIndex = weather[metric].columns.indexOf('weather_forecast')

        if (!!locationName && !!locationMetadata) {
            location = locationMetadata.options.find((locationOption) => locationOption.name === locationName) || null
            const locationId = location?.slug || ''
            const locationIdIndex = weather[metric].columns.indexOf('location_id')
            value =
                (weather[metric].data as string[][]).find((d) => {
                    return location
                        ? d[dateIndex] === formatISOString(timestamp, 'yyyy-MM-dd') && d[locationIdIndex] === locationId
                        : d[dateIndex] === formatISOString(timestamp, 'yyyy-MM-dd')
                }) || null
        } else {
            value = (weather[metric].data as string[][])[0]
        }
        return value ? value[forecastIndex] : ''
    }

    const getData = async () => {
        setLoading(true)
        const dateFrom = format(props.chart.from(), 'yyyy-MM-dd')
        const dateTo = format(props.chart.to(), 'yyyy-MM-dd')
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        try {
            const variables = {
                widget: props.accountSlug,
                dateFrom,
                dateTo,
                groupBy: props.groupByLocation ? 'location_id' : null,
                filters: props.filters,
                granularity: props.chart.granularity,
                timeIdentifierType: props.timeIdentifierType,
            }
            if (props.metric === 'revenue') {
                queryConfig.queries = [...queryConfig.queries, ...additionalRevenueQueries]
            }
            const data = await statsService.getStats(queryConfig, variables)
            if (_lastRequest.current !== requestTime) {
                return
            }
            const decimals = props.metric === 'revenue' ? 2 : 0
            props.chart.formatSeries(data[`groupped_${props.metric}`].timeSeries, decimals)
            const totalData = data[props.metric].totals.filter((t: DataTotal) => t.name === 'total_value')[0]
            const discountsData = data.discounts?.totals.filter((t) => t.name === 'total_value')[0]
            const collectedData = data.collected?.totals.filter((t) => t.name === 'total_value')[0]
            const resellerData = data.reseller?.totals.filter((t) => t.name === 'total_value')[0]

            const weatherIcon = data.weatherIcon?.dataSeries || dummyDataSeries
            const weatherTemperature = data.weatherTemperature?.dataSeries || dummyDataSeries
            const weatherRainChance = data.weatherRainChance?.dataSeries || dummyDataSeries
            const weatherRainIntensity = data.weatherRainIntensity?.dataSeries || dummyDataSeries
            const weather = {
                weatherIcon,
                weatherTemperature,
                weatherRainChance,
                weatherRainIntensity,
            }

            if (_isMounted.current) {
                setWeatherData(weather)
                buildDownloadData(data, weather)
                setTotal(props.metric === 'revenue' ? totalData.value.toFixed(2) : totalData.value.toFixed(0))
                setDiscounts(props.metric === 'revenue' ? discountsData.value.toFixed(2) : null)
                setCollected(props.metric === 'revenue' ? collectedData.value.toFixed(2) : null)
                setReseller(
                    props.metric === 'revenue' && props.hasFeature('ResellersFeature', props.accountSlug)
                        ? resellerData.value.toFixed(2)
                        : null,
                )
                setLoading(false)
            }
        } catch {
            props.replaceTopMessages(
                'server_error',
                'error',
                `Oops! ${revenuePageMetricMap[props.metric]} chart could not be loaded, please try again later.`,
            )
        }
    }

    const getWeatherForecastValueForDate = (date: string, dataSeries: string) => {
        if (!weatherData || !date) {
            return ''
        }
        const index = weatherData[dataSeries].columns.indexOf('weather_forecast')
        const data = (weatherData[dataSeries].data as string[][]).find((v) => v.indexOf(date) > -1)
        return data ? data[index] : ''
    }

    const getWeatherLine = (date: string | null) => {
        if (date) {
            const weather = getWeatherForecastValueForDate(date, 'weatherIcon')
            if (!weather) {
                return ''
            }
            const temp = getWeatherForecastValueForDate(date, 'weatherTemperature')
            const tempString = `<span style="margin-left:5px">${getPartnersUnitTemperature(
                Number(temp),
                isUSPartner,
                true,
            )}</span>`
            const showPrecipitation = ['rain', 'sleet', 'snow'].indexOf(weather) > -1
            let rainChanceString = ''
            let rainIntensityString = ''
            if (showPrecipitation) {
                const rainChance = getWeatherForecastValueForDate(date, 'weatherRainChance')
                const rainIntensity = getWeatherForecastValueForDate(date, 'weatherRainIntensity')
                rainChanceString = `,<span style="margin-left:10px">${Math.round(Number(rainChance) * 100)}%</span>`
                rainIntensityString = `,<span style="margin-left:10px">${
                    Math.round(Number(rainIntensity) * 100) / 100
                }mm</span>`
            }

            return `
        <table style="width:100%">
          <tr>
            <td>${appleTrademarkIcon} Weather:</td>
            <td style="text-align:right">
              ${weatherIconMap[weather] + tempString + rainChanceString + rainIntensityString}
            </td>
          </tr>
        </table>
      `
        }
        return ''
    }

    const formatMoney = (amount: string | number) => {
        return props.formatCurrencyString(Number(amount).toFixed(2), props.accountSlug)
    }

    const mainMetricMap = {
        revenue: mappedRevenue,
        transactions: mappedTransactions,
        products_sold: mappedProductsSold,
    }

    const chartTooltipFormatter = (params: TooltipParams[]) => {
        const totalPoint = mainMetricMap[props.metric][params[0].dataIndex]
        const totalData = totalPoint ? totalPoint.value : '0'
        const revenuePoint = mappedRevenue[params[0].dataIndex]
        const revenueData = revenuePoint ? revenuePoint.value : '0'
        const transactionsPoint = mappedTransactions[params[0].dataIndex]
        const transactionsData = transactionsPoint ? transactionsPoint.value : '0'
        const productsPoint = mappedProductsSold[params[0].dataIndex]
        const productsData = productsPoint ? productsPoint.value : '0'

        const xAxisLabel = totalPoint
            ? getTooltipXAxisLabel(totalPoint.timestamp, props.chart.granularity)
            : params[0].axisValue

        const date = totalPoint ? format(totalPoint.timestamp, 'yyyy-MM-dd') : null

        let tooltip = getTooltipHeader(xAxisLabel)

        if (params.length > 1) {
            const totalValue = props.metric === 'revenue' ? formatMoney(totalData) : totalData
            tooltip += getTooltipRow(['Total:', totalValue], true)

            params.forEach((p) => {
                const value = props.metric === 'revenue' ? formatMoney(p.value) : p.value
                if (Number(p.value) > 0) {
                    tooltip += getTooltipRow([`${p.marker} ${p.seriesName}:`, value])
                }
            })
            if (props.metric !== 'revenue') {
                tooltip += getTooltipRow(['Total revenue:', formatMoney(revenueData)])
            }
            if (props.metric !== 'transactions') {
                tooltip += getTooltipRow(['Total transactions:', transactionsData])
            }
            if (props.metric !== 'products_sold') {
                tooltip += getTooltipRow(['Total products sold:', productsData])
            }
        } else {
            const value = props.metric === 'revenue' ? formatMoney(params[0].value) : params[0].value
            tooltip += getTooltipRow([`${params[0].marker} ${revenuePageMetricMap[props.metric]}:`, value])
            if (props.metric !== 'revenue') {
                tooltip += getTooltipRow(['Revenue:', formatMoney(revenueData)])
            }
            if (props.metric !== 'transactions') {
                tooltip += getTooltipRow(['Transactions:', transactionsData])
            }
            if (props.metric !== 'products_sold') {
                tooltip += getTooltipRow(['Products sold:', productsData])
            }
        }
        if (props.chart.granularity === 'day') {
            tooltip += getWeatherLine(date)
        }
        tooltip += getTooltipFooter()

        return tooltip
    }

    const axisData = props.chart.props().xAxisData
    const series = props.chart.props().series
    const legendData = props.chart.props().legendData
    const axisTitle = props.timeIdentifierType === 'visit_date' ? 'Visit dates' : 'Purchase dates'

    return (
        <>
            {loading && <ChartDataLoader />}
            <ExportMenu
                accountSlug={props.accountSlug}
                downloadData={downloadData}
                loginService={props.loginService}
                loggingService={props.loggingService}
                replaceMessages={props.replaceTopMessages}
                hideMessage={props.hideMessage}
                withinChartWrapper
            />
            <AxisTitle title={axisTitle} left="50%" bottom="1.5em" style={{ transform: 'translateX(-50%)' }} />
            <ChartWrapperWithTotals
                title={revenuePageMetricMap[props.metric]}
                total={total}
                discounts={discounts}
                collected={collected}
                reseller={reseller}
                id="revenue-total"
                withCurrency={props.metric === 'revenue'}
                accountSlug={props.accountSlug}
                style={{ fontSize: '1.125em', right: '3em' }}
            >
                <TimeseriesBarChart
                    axisData={axisData}
                    series={series}
                    legendData={legendData.length > 1 ? legendData : null}
                    loading={loading}
                    tooltipFormatter={chartTooltipFormatter}
                    height="400px"
                    legendDisabled
                    chartOptions={{
                        yAxis: {
                            axisLabel: {
                                formatter: labelFormatter,
                            },
                        },
                    }}
                    titleOffset={20}
                />
            </ChartWrapperWithTotals>
        </>
    )
}

export default withFeatures(withCurrency(RevenueChart))
