import * as React from 'react'
import styled from 'styled-typed'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { History } from 'history'
import { Messages } from 'uiComponents/messages'
import { withMessages, MessageProps } from 'hocs'
import { ActionButton } from 'uiComponents/buttons'
import { Account } from 'auth/state'
import { changeActiveAccount } from 'preferences/actions.async'
import { parseFilterQuery } from 'uiComponents/filter'
import PartnerFilter from './partnerFilter'
import conviousLogo from 'images/convious-logo-pink.svg'
import Svg from 'svg'
import { AccountSettingsService } from 'settings/settingsService'
import { Filters } from 'uiComponents/filter/schema'
import cloneDeep from 'lodash/cloneDeep'

const Container = styled.div`
    margin-bottom: 10em;
`
const PartnerCard = styled.div`
    display: inline-block;
    vertical-align: top;
    background: ${(props) => props.theme.colors.white};
    width: 16.875em;
    height: 22.3125em;
    border-radius: 6px;
    padding: 1.5em 1.5em 1em 1.5em;
    margin: 0.8em 0.9em;
`
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`
const Name = styled.div`
    font-weight: bold;
    font-size: 1.25em;
    height: 4.3em;
    display: flex;
    align-items: center;
    justify-content: center;
`
const ImgWrapper = styled.div<{ brandColor: string }>`
    height: 6em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: ${(props) => props.brandColor};
    padding: 0.5em;
`
const Logo = styled.img`
    max-height: 100%;
    max-width: 100%;
`

interface ResellersLandingPageProps {
    accountSlug: string
    accounts: Account[]
    history: History
    disableCheckout: boolean
    filterQuery: string
    changeActiveAccount: typeof changeActiveAccount
    accountSettingsService: AccountSettingsService
}

function LandingPage(props: ResellersLandingPageProps & MessageProps) {
    const [accounts, setAccounts] = React.useState<Account[]>(props.accounts)

    React.useEffect(() => {
        if (props.filterQuery) {
            const filters = parseFilterQuery(props.filterQuery)
            applyFilters(filters)
        } else {
            setAccounts(props.accounts)
        }
    }, [props.filterQuery])

    const applyFilters = (filters: Filters[]) => {
        if (filters.length === 0) {
            setAccounts(props.accounts)
        }
        let filteredAcounts = cloneDeep(props.accounts)
        const industriesFilter = filters.find((f) => f.attribute === 'industry')
        const partnersFilter = filters.find((f) => f.attribute === 'partner')
        if (industriesFilter) {
            filteredAcounts = filteredAcounts.filter((a) => industriesFilter.values.includes(a.industry))
        }
        if (partnersFilter) {
            filteredAcounts = filteredAcounts.filter((a) => partnersFilter.values.includes(a.slug))
        }
        setAccounts(filteredAcounts)
    }

    const updateActiveAccount = (slug: string) => {
        if (props.accountSlug === slug) {
            return
        }
        props.changeActiveAccount(slug)
    }

    const goToNewOrder = (slug: string) => {
        updateActiveAccount(slug)
        props.history.push(`/resellers/account/${slug}/new_order/`)
    }

    const goToHistory = (slug: string) => {
        updateActiveAccount(slug)
        props.history.push(`/resellers/account/${slug}/orders/transactions/`)
    }

    const goToDetails = (slug: string) => {
        updateActiveAccount(slug)
        props.history.push(`/resellers/account/${slug}/orders/details/`)
    }

    const sortAccounts = (a: Account, b: Account) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        return 0
    }

    return (
        <>
            <Messages messages={props.messages} hideMessage={props.hideMessage} />
            <Container>
                <div style={{ margin: '2em 0 3em 0.8em' }}>
                    <PartnerFilter
                        accountSlug={props.accountSlug}
                        accounts={props.accounts}
                        accountSettingsService={props.accountSettingsService}
                    />
                </div>
                {accounts.length === 0 && (
                    <div style={{ marginLeft: '0.8em' }}>No partners meet your filter criteria.</div>
                )}
                {accounts.sort(sortAccounts).map((a) => (
                    <PartnerCard key={a.slug}>
                        <ContentWrapper>
                            {!a.style?.logoUrl && (
                                <ImgWrapper brandColor={a.style?.brandColor || ''}>
                                    <Svg src={conviousLogo} />
                                </ImgWrapper>
                            )}
                            {!!a.style?.logoUrl && (
                                <ImgWrapper brandColor={a.style?.brandColor || ''}>
                                    <Logo src={a.style?.logoUrl} />
                                </ImgWrapper>
                            )}
                            <Name>{a.name}</Name>
                            <ContentWrapper>
                                <ActionButton
                                    onClick={() => goToNewOrder(a.slug)}
                                    size="large"
                                    kind="action"
                                    disabled={!a.activeResellerContract}
                                >
                                    New order
                                </ActionButton>
                                <ActionButton
                                    onClick={() => goToHistory(a.slug)}
                                    size="large"
                                    kind="action"
                                    secondary
                                    style={{ margin: '0.5em 0' }}
                                >
                                    Orders history
                                </ActionButton>
                                <ActionButton onClick={() => goToDetails(a.slug)} size="large" kind="action" secondary>
                                    Orders details
                                </ActionButton>
                            </ContentWrapper>
                        </ContentWrapper>
                    </PartnerCard>
                ))}
            </Container>
        </>
    )
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            changeActiveAccount,
        },
        dispatch,
    )
}

export default withMessages(connect(null, mapDispatchToProps)(LandingPage))
