import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import CategoriesPoisListPage from 'venue/parkMap/components/categoriesAndPois/categoriesPoisListPage'
import CreateCategory from 'venue/parkMap/components/categoriesAndPois/categoryDetails/createCategory'
import EditCategory from 'venue/parkMap/components/categoriesAndPois/categoryDetails/editCategory'
import CreatePoi from 'venue/parkMap/components/categoriesAndPois/poiDetails/createPoi'
import EditPoi from 'venue/parkMap/components/categoriesAndPois/poiDetails/editPoi'

const ParkMapPoiRoutes = () => {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path} component={() => <CategoriesPoisListPage />} />
            <Route exact path={`${path}/category/create`} component={() => <CreateCategory />} />
            <Route path={`${path}/category/:categoryId`} component={() => <EditCategory />} />
            <Route path={`${path}/poi/create/:categoryId`} component={() => <CreatePoi />} />
            <Route path={`${path}/poi/:poiId`} component={() => <EditPoi />} />
        </Switch>
    )
}

export default ParkMapPoiRoutes
