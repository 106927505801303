import React from 'react'
import { DateFormats, formatISOString } from 'utils'
import { Cell, ExpandCell, MultipleStatus, NameAndStatus, Name, DataRow, DynamicRow } from 'uiComponents/table'
import { WeekdayPattern } from 'uiComponents/weekdayPattern'
import { ActionButtonA } from 'uiComponents/buttons'
import { InlineEdit } from 'uiComponents/input'
import { TimeSlotGroupWithStatus } from './timeSlotsService'
import { DateRange } from 'uiComponents/date'

interface TimeSlotsListPageProps {
    accountSlug: string
    timeslot: TimeSlotGroupWithStatus
    productsView: boolean
    productsViewFeatureOn: boolean
    isExpanded: boolean
    toggleExpanded: () => void
    onPriorityUpdateConfirm: (priority: string, id: string) => void
    hasPermission: (permission: string, accountSlug: string) => boolean
}

export function TimeSlotsListRow(props: TimeSlotsListPageProps) {
    const numberOfCategories = props.timeslot.productsLists.length
    const expandable = !props.productsView && !!numberOfCategories
    const categoryName =
        props.productsViewFeatureOn && !!numberOfCategories
            ? `${numberOfCategories} ${numberOfCategories === 1 ? 'category' : 'categories'}`
            : props.timeslot.productsListNames
    const categoryCellContent = props.productsView ? props.timeslot.article?.name || '' : categoryName
    const queryString = props.productsViewFeatureOn
        ? `/?timeslots_view=${props.productsView ? 'products' : 'categories'}`
        : ''

    const TimeSlotRow = props.productsView ? DataRow : DynamicRow

    let rowClass = props.timeslot.status === 'success' ? 'timeSlotRow' : 'inactive timeSlotRow'
    rowClass = props.isExpanded ? rowClass + ' expanded' : rowClass

    function getTimeRangeValue(timeslot: TimeSlotGroupWithStatus) {
        if (!!timeslot.availableFrom && !!timeslot.availableTo) {
            return <DateRange startDateString={timeslot.availableFrom} endDateString={timeslot.availableTo} />
        }
        if (!!timeslot.availableFrom) {
            return `From ${formatISOString(timeslot.availableFrom, DateFormats.LONG_DATE)}`
        }
        if (!!timeslot.availableTo) {
            return `Until ${formatISOString(timeslot.availableTo, DateFormats.LONG_DATE)}`
        }
        return ''
    }

    return (
        <TimeSlotRow
            interactive={expandable}
            className={rowClass}
            onClick={expandable ? props.toggleExpanded : () => {}}
        >
            {props.productsViewFeatureOn && (
                <>
                    {!!props.timeslot.productsLists.length ? (
                        <ExpandCell className="expand" expanded={props.isExpanded} align="right" noPadding />
                    ) : (
                        <Cell noPadding />
                    )}
                </>
            )}
            <Cell title={props.timeslot.name}>
                <NameAndStatus>
                    <Name className="name">{props.timeslot.name}</Name>
                    <MultipleStatus
                        status={props.timeslot.status}
                        withText
                        successText="Active"
                        errorText="Expired"
                        warnText="Upcoming"
                    />
                </NameAndStatus>
            </Cell>
            <Cell className="category withName">
                <span title={categoryCellContent}>{categoryCellContent}</span>
            </Cell>
            <Cell className="priority">
                <InlineEdit
                    isNumber
                    integerOnly
                    min={1}
                    max={1000}
                    id="input"
                    limitedTextWidth="5em"
                    value={props.timeslot.priority ? props.timeslot.priority : ''}
                    placeholder="Enter"
                    onEditAccept={(v) => props.onPriorityUpdateConfirm(v, props.timeslot.id)}
                    disabled={!props.hasPermission('partner_admin', props.accountSlug)}
                />
            </Cell>
            <Cell>
                {props.timeslot.weekdays && (
                    <WeekdayPattern
                        weekdays={
                            props.timeslot.weekdays.length > 0 ? props.timeslot.weekdays.toString() : '0,1,2,3,4,5,6'
                        }
                        type="strict"
                    />
                )}
            </Cell>
            <Cell className="dates wrapContent">{getTimeRangeValue(props.timeslot)}</Cell>
            <Cell>
                <ActionButtonA
                    className="editTimeSlot"
                    kind="action"
                    secondary
                    href={`/account/${props.accountSlug}/products/time_slots/edit/${props.timeslot.id}${queryString}`}
                >
                    Edit
                </ActionButtonA>
            </Cell>
        </TimeSlotRow>
    )
}
