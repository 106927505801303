import React from 'react'
import styled from 'styled-components'
import { TextInput } from 'uiComponents/input'

const Container = styled.div`
    width: 100%;
    margin-bottom: 2em;

    input {
        width: 100%;
    }
`

const Label = styled.div`
    font-size: 0.875rem;
    margin: 0.5em 0;
`

interface NameFilterProps {
    value: string
    onChange: (name: string) => void
}

const NameFilter = (props: NameFilterProps) => {
    return (
        <Container>
            <Label>Audience name</Label>
            <TextInput
                id="name"
                name="name"
                placeholder="Type custom audience name"
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
            />
        </Container>
    )
}

export default NameFilter
