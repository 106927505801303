import { Locale } from 'admin/articleService'
import { Audience } from 'crm/schema'
import { History } from 'history'
import * as React from 'react'
import styled from 'styled-typed'
import { PageLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { Footer } from 'uiComponents/studio/form/footer'
import {
    Form,
    FormBody,
    FormContainer,
    FormContent,
    Menu,
    ScrollArea,
    SidebarMenu,
    SidebarTitle,
} from 'uiComponents/studio/form/formComponents'
import copyIcon from 'uiComponents/studio/icons/copy.svg'
import targetIcon from 'uiComponents/studio/icons/target.svg'
import MenuItem from 'uiComponents/studio/menuItem'
import StudioConviousLogo from 'uiComponents/studio/studioLogo'
import { MenuStepType, PlacementContent, UseDateInput, UseFormInput, VenueLocation } from '../schema'
import ActionSection from './actionSection'
import ScheduleSection from './scheduleSection'
import TargetingSection from './targetingSection'
import TypeSection from './typeSection'

const Header = styled.div`
    font-size: 1.25em;
    padding: 1.2em;
    span {
        font-size: 0.6em;
        display: inline-block;
        margin-top: 0.8em;
    }
`

interface StudioFormProps {
    currentStep: 'action' | 'type' | 'scheduling' | 'targeting'
    setCurrentStep: (step: MenuStepType) => void
    history: History
    accountSlug: string
    accountLocales: Locale[]
    name: UseFormInput
    actionLink: UseFormInput
    defaultLanguage: UseFormInput
    placementSize: UseFormInput
    backgroundColor: UseFormInput
    customBackgroundColor: UseFormInput
    image: UseFormInput
    buttonAction: UseFormInput
    startDate: UseDateInput
    endDate: UseDateInput
    activeLanguage: UseFormInput
    activeTranslation: PlacementContent
    loading: boolean
    active: UseFormInput
    audience: UseFormInput
    hasPlacementAudienceFeature: boolean
    hasPlacementLocationFeature: boolean
    venueLocations: VenueLocation[]
    selectedVenueLocation: UseFormInput
    marketingConsent: UseFormInput
    audiences: Audience[]
    onTranslationUpdate: (key: string, value: string) => void
    onPublish: () => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

const StudioForm = ({
    accountSlug,
    accountLocales,
    history,
    currentStep,
    name,
    actionLink,
    loading,
    active,
    activeLanguage,
    defaultLanguage,
    placementSize,
    backgroundColor,
    customBackgroundColor,
    buttonAction,
    image,
    startDate,
    endDate,
    activeTranslation,
    audience,
    hasPlacementAudienceFeature,
    venueLocations,
    selectedVenueLocation,
    marketingConsent,
    hasPlacementLocationFeature,
    audiences,
    setCurrentStep,
    onPublish,
    replaceMessages,
    removeAllMessages,
    onTranslationUpdate,
}: StudioFormProps) => {
    const MENU_ITEMS = [
        {
            name: 'type',
            label: '1. Type',
            icon: copyIcon,
            show: true,
        },
        {
            name: 'action',
            label: '2. Action',
            icon: targetIcon,
            show: true,
        },
        {
            name: 'scheduling',
            label: '3. Scheduling',
            icon: targetIcon,
            show: true,
        },
        {
            name: 'targeting',
            label: '4. Targeting',
            icon: targetIcon,
            show: hasPlacementAudienceFeature,
        },
    ]

    const menuItems = () => {
        return (
            <nav>
                <Menu>
                    {MENU_ITEMS.filter((item) => item.show).map((item, index) => {
                        return (
                            <MenuItem
                                key={`${index}`}
                                product=""
                                accountSlug={accountSlug}
                                item={item}
                                highlighted={currentStep === item.name}
                                history={history}
                                onClick={setCurrentStep}
                                configurationId=""
                            />
                        )
                    })}
                </Menu>
            </nav>
        )
    }

    return (
        <FormContainer>
            <FormContent>
                <SidebarMenu>
                    <StudioConviousLogo />
                    <SidebarTitle>PLACEMENT LINK</SidebarTitle>
                    {menuItems()}
                </SidebarMenu>
                <Form noValidate>
                    <FormBody>
                        {loading && <PageLoader />}
                        {!loading && (
                            <>
                                <Header>
                                    Edit your placement
                                    <span>Compose and edit your Placement.</span>
                                </Header>
                                <ScrollArea id="scroll-area">
                                    {currentStep === 'type' && (
                                        <TypeSection
                                            accountLocales={accountLocales}
                                            defaultLanguage={defaultLanguage}
                                            name={name}
                                            placementSize={placementSize}
                                            backgroundColor={backgroundColor}
                                            customBackgroundColor={customBackgroundColor}
                                            image={image}
                                            buttonAction={buttonAction}
                                            activeLanguage={activeLanguage}
                                            activeTranslation={activeTranslation}
                                            onTranslationUpdate={onTranslationUpdate}
                                            replaceMessages={replaceMessages}
                                            removeAllMessages={removeAllMessages}
                                        />
                                    )}
                                    {currentStep === 'action' && <ActionSection actionLink={actionLink} />}
                                    {currentStep === 'scheduling' && (
                                        <ScheduleSection
                                            startDate={startDate}
                                            endDate={endDate}
                                            active={active}
                                            venueLocations={venueLocations}
                                            selectedVenueLocation={selectedVenueLocation}
                                            hasPlacementLocationFeature={hasPlacementLocationFeature}
                                        />
                                    )}
                                    {currentStep === 'targeting' && (
                                        <TargetingSection
                                            audience={audience}
                                            marketingConsent={marketingConsent}
                                            hasPlacementAudienceFeature={hasPlacementAudienceFeature}
                                            audiences={audiences}
                                        />
                                    )}
                                </ScrollArea>
                            </>
                        )}
                    </FormBody>
                </Form>
            </FormContent>
            <Footer
                accountSlug={accountSlug}
                menuItems={MENU_ITEMS.map((item) => item.name)}
                formSubmit={onPublish}
                history={history}
                navPathOverride={`/account/${accountSlug}/engage/tools/placements`}
                ongoingPublish={false}
                currentStepOverride={currentStep}
                currentStep=""
                product=""
                configurationId=""
                componentValues={null}
            />
        </FormContainer>
    )
}

export default StudioForm
