import * as React from 'react'
import { useParams } from 'react-router-dom'
import { BaseRouteParams } from '../../../hocs'
import styled from '../../../styled-typed'
import { LoaderWrapper } from '../../../uiComponents/loaders'
import ConsentText from '../components/consentText'
import useIsPartnerAllowed from '../hooks/useIsPartnerAllowed'
import CustomAudienceForm from '../modules/customAudienceForm'
import { useGetFiltersQuery } from '../reduxQuery'
import { ICustomAudience, ICustomAudienceStatus, IFilter } from '../types'

export const Container = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.colors.white};
    padding: 2em 1.6em 6em;
`

export const Title = styled.div`
    font-size: 1.375em;
    font-weight: 500;
    margin-bottom: 0.4em;
`

const CreateCustom = () => {
    useIsPartnerAllowed()

    const { accountSlug } = useParams<BaseRouteParams>()
    const params = useParams<BaseRouteParams>()
    const { data, isFetching } = useGetFiltersQuery({ slug: accountSlug })

    const mapFiltersToCustomAudiences = (data: IFilter[]): ICustomAudience => {
        return {
            name: '',
            description: '',
            account_slug: params.accountSlug,
            filters: data,
            status: ICustomAudienceStatus.DRAFT,
        }
    }

    return (
        <Container>
            <Title>Define your target audience</Title>
            <ConsentText />
            <LoaderWrapper loading={isFetching}>
                {data && <CustomAudienceForm filters={data} data={mapFiltersToCustomAudiences(data)} />}
            </LoaderWrapper>
        </Container>
    )
}

export default CreateCustom
