import { AuthTicket } from 'http/oauthService'
import { LoginService } from 'http/loginService'
import { DownloadData } from 'reports/schema'

export interface DownloadLinkProps {
    ticket: AuthTicket
    downloadEndpoint: string
    loginService: LoginService
    downloadData?: DownloadData
    downloadAllowed?: () => boolean
    onDownloadInitiated?: (endpoint: string, data?: DownloadData) => void
}

export const downloadLink = async ({
    downloadAllowed,
    loginService,
    ticket,
    downloadEndpoint,
    downloadData,
    onDownloadInitiated,
}: DownloadLinkProps) => {
    if (downloadAllowed && !downloadAllowed()) {
        return
    }

    try {
        await loginService.ensureTokenNotExpired(ticket)
        let frame = document.getElementById('download-frame') as HTMLIFrameElement
        if (!frame) {
            frame = document.createElement('iframe')
            frame.setAttribute('id', 'download-frame')
            frame.setAttribute('name', 'download-frame')
            frame.style.display = 'none'
            document.body.appendChild(frame)
        }
        const doc = frame.contentDocument || (frame.contentWindow ? frame.contentWindow.document : null)
        if (doc === null) {
            // tslint:disable-next-line no-console
            console.error('Unable to access iframe document')
            return
        }
        doc.body.innerHTML = ''
        const form = doc.createElement('form') as HTMLFormElement
        form.setAttribute('method', 'POST')
        form.setAttribute('action', downloadEndpoint)
        const token = doc.createElement('input') as HTMLInputElement
        token.setAttribute('type', 'hidden')
        token.setAttribute('name', 'token')
        token.setAttribute('value', ticket.accessToken)
        form.appendChild(token)

        if (downloadData) {
            const downloadDataEle = doc.createElement('input') as HTMLInputElement
            downloadDataEle.setAttribute('type', 'hidden')
            downloadDataEle.setAttribute('name', 'download_data')
            downloadDataEle.setAttribute('value', JSON.stringify(downloadData))
            form.appendChild(downloadDataEle)
        }

        doc.body.appendChild(form)
        form.submit()
        if (onDownloadInitiated) {
            onDownloadInitiated(downloadEndpoint, downloadData)
        }
    } catch (e) {}
}

interface FormValueType {
    element: string
    name: string
    value: any
}

export interface DownloadLinkExtendFormProps extends DownloadLinkProps {
    values?: FormValueType[]
}

export const downloadLinkExtendForm = async ({
    downloadAllowed,
    loginService,
    ticket,
    downloadEndpoint,
    downloadData,
    onDownloadInitiated,
    values,
}: DownloadLinkExtendFormProps) => {
    if (downloadAllowed && !downloadAllowed()) {
        return
    }

    try {
        await loginService.ensureTokenNotExpired(ticket)
        let frame = document.getElementById('download-frame') as HTMLIFrameElement
        if (!frame) {
            frame = document.createElement('iframe')
            frame.setAttribute('id', 'download-frame')
            frame.setAttribute('name', 'download-frame')
            frame.style.display = 'none'
            document.body.appendChild(frame)
        }
        const doc = frame.contentDocument || (frame.contentWindow ? frame.contentWindow.document : null)
        if (doc === null) {
            // tslint:disable-next-line no-console
            console.error('Unable to access iframe document')
            return
        }
        doc.body.innerHTML = ''
        const form = doc.createElement('form') as HTMLFormElement
        form.setAttribute('method', 'POST')
        form.setAttribute('action', downloadEndpoint)
        const submitButton = form.ownerDocument.createElement('button')
        submitButton.style.display = 'none'
        submitButton.type = 'submit'
        form.appendChild(submitButton)

        const token = doc.createElement('input') as HTMLInputElement
        token.setAttribute('type', 'hidden')
        token.setAttribute('name', 'token')
        token.setAttribute('value', ticket.accessToken)
        form.appendChild(token)

        if (values) {
            values.forEach((field) => {
                const element = doc.createElement(field.element)
                element.setAttribute('type', 'hidden')
                element.setAttribute('name', field.name)
                element.setAttribute('value', field.value)
                form.appendChild(element)
            })
        }

        if (downloadData) {
            const downloadDataEle = doc.createElement('input') as HTMLInputElement
            downloadDataEle.setAttribute('type', 'hidden')
            downloadDataEle.setAttribute('name', 'download_data')
            downloadDataEle.setAttribute('value', JSON.stringify(downloadData))
            form.appendChild(downloadDataEle)
        }

        doc.body.appendChild(form)

        submitButton.click()

        if (onDownloadInitiated) {
            onDownloadInitiated(downloadEndpoint, downloadData)
        }
    } catch (e) {}
}
