import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { useDeleteCampaignMutation } from 'engageTools/reduxApi'
import { ProductName } from 'engageTools/studio/schema'
import { BaseRouteParams } from 'hocs'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { ROUTE_PATHS } from 'engageTools/routePaths'
import { pathUtils } from 'utils/pathnameFormatter'

interface DeleteConfirmationProps {
    id: string
    type: ProductName
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({ type, id }) => {
    const routeParams = useParams<BaseRouteParams>()
    const location = useLocation()
    const history = useHistory()
    const [deleteTool] = useDeleteCampaignMutation()

    const close = () => {
        const path = pathUtils.populateParams(ROUTE_PATHS.engageToolList, routeParams)

        if (path) {
            history.push(pathUtils.addQueryString(path, location.search))
        }
    }

    return (
        <ConfirmationDialog
            title="Are you sure you want to delete this configuration?"
            text="This can't be undone!"
            buttonText="Delete"
            onCancel={() => close()}
            onConfirm={() => {
                deleteTool({
                    accountSlug: routeParams.accountSlug,
                    type,
                    id: id as string,
                })
                    .unwrap()
                    .finally(close)
            }}
        />
    )
}

export default DeleteConfirmation
