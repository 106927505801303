import { object, date } from 'yup'
import moment from 'moment'

export const ArticleDetailFormSchema = object({
    validFrom: date().required('Validity start date is required').nullable(),
    validTo: date()
        .required('Validity end date is required')
        .nullable()
        .max(moment().add(4, 'year').toDate(), 'Validity end date must be less than 4 years from now'),
    showFrom: date().required('Visibility start date is required').nullable(),
    showTo: date().required('Visibility end date is required').nullable(),
})
