import * as React from 'react'
import styled from 'styled-typed'
import { History } from 'history'
import {
    FormContainer,
    FormContent,
    SidebarMenu,
    Form,
    FormBody,
    ScrollArea,
    Menu,
    SidebarTitle,
} from 'uiComponents/studio/form/formComponents'
import MenuItem from 'uiComponents/studio/menuItem'
import { PageLoader } from 'uiComponents/loaders'
import { withFeatures } from 'features'
import { MessageKind } from 'uiComponents/messages'
import { Footer } from 'uiComponents/studio/form/footer'
import StudioConviousLogo from 'uiComponents/studio/studioLogo'
import { faToggleOn, faLink, faGlobeAmericas, faFileAlt } from '@fortawesome/pro-light-svg-icons'
import { MenuStepType, ConfigField, FieldType, FieldCategory } from './schema'
import { Headline } from 'uiComponents/typography'
import ActivateSection from './activateSection'
import AssignSection from './assignSection'
import TranslationsSection from './translationsSection'
import EnableSection from './enableSection'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { Locale } from 'admin/articleService'
import { ProductsMetaData, Article } from 'uiComponents/filter/schema'

const Header = styled.div`
    font-size: 1.25em;
    padding: 1.2em;
    span {
        font-size: 0.6em;
        display: inline-block;
        margin-top: 0.8em;
    }
`
const SubSection = styled.div`
    display: inline-block;
    font-size: 0.875em;
    width: 50%;
    text-align: center;
    margin-bottom: -0.1em;
    padding: 0.6em 0;
    cursor: pointer;
    border-bottom: 2px solid ${(p) => p.theme.colors.background};
    &.active {
        border-bottom: 2px solid ${(p) => p.theme.colors.boyBlue};
        font-weight: 400;
    }
    &:not(.active) {
        > ${Headline} {
            color: ${(p) => p.theme.colors.textLight};
        }
    }
`

interface StudioFormProps {
    currentStep: MenuStepType
    fieldsConfig: ConfigField[]
    history: History
    accountSlug: string
    locales: Locale[]
    categories: ProductsMetaData[]
    apfProducts: Article[]
    products: Article[]
    loading: boolean
    navigation: Navigation
    match: RouteMatch<{}>
    selectedProducts: string[]
    setSelectedProducts: (ids: string[]) => void
    activeLanguage: string
    markErrors: boolean
    category: FieldCategory
    onCategoryChange: (section: FieldCategory) => void
    setCurrentStep: (step: MenuStepType) => void
    onAddField: (type: FieldType, label: string, category: FieldCategory) => void
    onLanguageChange: (language: string) => void
    handleEnabledChange: (
        value: boolean,
        item: string,
        type: 'active' | 'optional',
        fieldCategory: FieldCategory,
    ) => void
    handleAssignChange: (value: string[], item: string, fieldCategory: FieldCategory) => void
    handleTranslationChange: (
        value: string,
        field: string,
        type: 'label' | 'placeholder',
        fieldCategory: FieldCategory,
    ) => void
    onSave: () => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function StudioForm(props: StudioFormProps) {
    const MENU_ITEMS = [
        {
            name: 'activate',
            label: '1. Activate',
            fontAwesomeIcon: faToggleOn,
        },
        {
            name: 'assign',
            label: '2. Assign',
            fontAwesomeIcon: faLink,
        },
        {
            name: 'translations',
            label: '3. Translations',
            fontAwesomeIcon: faGlobeAmericas,
        },
        {
            name: 'enable',
            label: '4. Enable',
            fontAwesomeIcon: faFileAlt,
        },
    ]

    const fieldsConfig =
        props.category === 'buyer'
            ? props.fieldsConfig.filter((f) => f.buyer)
            : props.fieldsConfig.filter((f) => !f.buyer)
    const activeFields = fieldsConfig.filter((f) => f.active)

    return (
        <FormContainer>
            <FormContent>
                <SidebarMenu>
                    <StudioConviousLogo />
                    <SidebarTitle>After Payment Form</SidebarTitle>
                    <nav>
                        <Menu>
                            {MENU_ITEMS.map((item, index) => (
                                <MenuItem
                                    key={`${index}`}
                                    product=""
                                    configurationId=""
                                    accountSlug={props.accountSlug}
                                    item={item}
                                    highlighted={props.currentStep === item.name}
                                    history={props.history}
                                    onClick={props.setCurrentStep}
                                />
                            ))}
                        </Menu>
                    </nav>
                </SidebarMenu>
                <Form noValidate>
                    <FormBody>
                        {!props.loading && (
                            <>
                                <Header>
                                    Edit your template
                                    <span>Edit your content and save it for changes.</span>
                                </Header>
                                <ScrollArea
                                    id="scroll-area"
                                    style={props.currentStep !== 'enable' ? { paddingBottom: '6em' } : {}}
                                >
                                    <div>
                                        {props.currentStep !== 'enable' && (
                                            <>
                                                <SubSection
                                                    className={props.category === 'buyer' ? 'active' : ''}
                                                    onClick={() => props.onCategoryChange('buyer')}
                                                >
                                                    <Headline size={6}>Buyer fields</Headline>
                                                </SubSection>
                                                <SubSection
                                                    className={props.category === 'ticket_holder' ? 'active' : ''}
                                                    onClick={() => props.onCategoryChange('ticket_holder')}
                                                >
                                                    <Headline size={6}>Ticket holder fields</Headline>
                                                </SubSection>
                                            </>
                                        )}
                                    </div>
                                    {props.currentStep === 'activate' && (
                                        <ActivateSection
                                            accountSlug={props.accountSlug}
                                            fieldsConfig={fieldsConfig}
                                            category={props.category}
                                            handleChange={props.handleEnabledChange}
                                            onAddField={props.onAddField}
                                        />
                                    )}
                                    {props.currentStep === 'assign' && (
                                        <AssignSection
                                            accountSlug={props.accountSlug}
                                            category={props.category}
                                            fieldsConfig={activeFields}
                                            categories={props.categories}
                                            products={props.products}
                                            handleAssign={props.handleAssignChange}
                                        />
                                    )}
                                    {props.currentStep === 'translations' && (
                                        <TranslationsSection
                                            accountSlug={props.accountSlug}
                                            category={props.category}
                                            fieldsConfig={activeFields}
                                            activeLanguage={props.activeLanguage}
                                            locales={props.locales}
                                            markErrors={props.markErrors}
                                            handleChange={props.handleTranslationChange}
                                            onLanguageChange={props.onLanguageChange}
                                        />
                                    )}
                                    {props.currentStep === 'enable' && (
                                        <EnableSection
                                            accountSlug={props.accountSlug}
                                            fieldsConfig={activeFields}
                                            apfProducts={props.apfProducts}
                                            selectedProducts={props.selectedProducts}
                                            setSelectedProducts={props.setSelectedProducts}
                                        />
                                    )}
                                </ScrollArea>
                            </>
                        )}
                        {props.loading && <PageLoader />}
                    </FormBody>
                </Form>
            </FormContent>
            <Footer
                accountSlug={props.accountSlug}
                menuItems={['activate', 'assign', 'translations', 'enable']}
                formSubmit={props.onSave}
                history={props.history}
                ongoingPublish={false}
                currentStepOverride={props.currentStep}
                navPathOverride={`/account/${props.accountSlug}/products/apf_studio`}
                currentStep=""
                product=""
                configurationId=""
                componentValues={null}
            />
        </FormContainer>
    )
}

export default withFeatures(withNavigation(StudioForm))
