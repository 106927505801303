import React, { useState } from 'react'
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DateFormats, parseTime, parseTimeOrNull } from 'utils/dates'
import classNames from 'classnames'
import './timePicker.scss'
import { format, isValid } from 'date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { ValidationMessage } from 'uiComponents/form/formElements'

export interface TimePickerProps {
    id?: string
    name?: string
    value?: string | null
    onChange: (timeString: string | null) => void
    style?: React.CSSProperties
    autoFocus?: boolean
    required?: boolean
    fallbackValue?: string
    errorMessage?: string | null
}

const formatDate = (date: Date) => format(date, 'HH:mm')

export const TimePicker = (props: TimePickerProps) => {
    const { id, style, onChange, autoFocus, required, fallbackValue, errorMessage } = props
    const [value, setValue] = useState<Date | null>(parseTimeOrNull(props.value))
    const [error, setError] = useState<string | null>(null)
    const [forceUpdateKey, setForceUpdateKey] = useState(0)

    const handleEmptyInput = () => {
        if (required) {
            if (fallbackValue) {
                setValue(parseTime(fallbackValue))
                onChange(fallbackValue)
                setError(null)
                setForceUpdateKey(forceUpdateKey + 1)
            } else {
                setError('This field is required')
                onChange(null)
            }
        } else {
            setError(null)
            onChange(null)
        }
    }

    const onChangeHandler = (date: Date | null) => {
        if (!date) {
            handleEmptyInput()
        } else if (!isValid(date)) {
            setError('Invalid time')
            onChange(null)
        } else {
            setError(null)
            onChange(formatDate(date))
        }
    }

    return (
        <div id={id} className={classNames('time-picker', { error: error || errorMessage })} style={style}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                localeText={{
                    fieldMeridiemPlaceholder: () => 'AM/PM',
                }}
            >
                <DesktopTimePicker
                    value={value}
                    onChange={(date) => {
                        setValue(date)
                        onChangeHandler(date)
                    }}
                    onAccept={onChangeHandler}
                    slotProps={{ field: { clearable: !required } }}
                    format={DateFormats.SHORT_TIME}
                    minutesStep={15}
                    skipDisabled={true}
                    thresholdToRenderTimeInASingleColumn={288} // always render in a single column
                    ampm={DateFormats.SHORT_TIME.includes('a')}
                    autoFocus={autoFocus}
                    key={forceUpdateKey}
                />
                <ValidationMessage
                    className={classNames('validation-message', {
                        'validation-message-visible': error || errorMessage,
                    })}
                >
                    {error || errorMessage}
                </ValidationMessage>
            </LocalizationProvider>
        </div>
    )
}
