import React, { useState, useEffect, useContext, useRef } from 'react'
import { usePrevious } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { format } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { ChartHeadline } from 'uiComponents/charts/styleComponents'
import { getTooltipRow, getTooltipHeader, getTooltipFooter, getMarker, AxisTitle } from 'reports/helpers'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import { QueryConfig } from 'reports/queryGenerator'
import { DateRange } from 'dateRanges'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { isEqual, find } from 'lodash'
import CustomAxisChart, { ChartSeries } from 'uiComponents/charts/customAxisChart'
import { withTheme } from 'styled-typed'
import { DashboardTheme } from 'theme'
import { Filters } from 'uiComponents/filter/schema'
import { sortByScreenName } from './utils'
import { roundNumber } from 'utils'

const queryConfig: QueryConfig = {
    querySetName: 'CheckoutFunnelReach',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'groupBy', type: 'String' },
        { name: 'filters', type: '[FilterDictionary]' },
    ],
    queries: [
        {
            name: 'checkoutReach',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'groupBy', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'checkout_funnel_reach_summary' }],
            presetResult: 'totals',
        },
        {
            name: 'checkoutDropRatio',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'groupBy', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'checkout_funnel_drop_ratio_summary' }],
            presetResult: 'totals',
        },
        {
            name: 'checkoutReachRatio',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'groupBy', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'checkout_funnel_reach_ratio_summary' }],
            presetResult: 'totals',
        },
    ],
}

interface ChartsProps {
    accountSlug: string
    dateRange: DateRange
    theme: DashboardTheme
    filters: Filters[]
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

type ChartSerieItem = {
    name: string
    value: number | string
    dropoff: number | string
    reach: number | string
}

function ConversionRateChart(props: ChartsProps) {
    const _isMounted = useRef(false)
    const _lastRequest = useRef<number>()
    const statsService = useContext(StatsServiceContext)
    const [loading, setLoading] = useState<boolean>(true)
    const [conversionRate, setConversionRate] = useState<ChartSerieItem[]>([])

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    const prevFilters = usePrevious(props.filters)

    useEffect(() => {
        _isMounted.current = true
        if (
            prevAccountSlug !== props.accountSlug ||
            !areDateRangeDatesEqual(prevDateRange, props.dateRange) ||
            !isEqual(prevFilters, props.filters)
        ) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug, props.dateRange, props.filters])

    const getData = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        setLoading(true)
        if (props.filters.length === 0) {
            return
        }
        const dateFrom = format(props.dateRange.from, 'yyyy-MM-dd')
        const dateTo = format(props.dateRange.to, 'yyyy-MM-dd')

        try {
            const variables = {
                widget: props.accountSlug,
                dateFrom,
                dateTo,
                groupBy: 'screens',
                filters: props.filters,
            }

            const data = await statsService.getStats(queryConfig, variables)

            if (_lastRequest.current !== requestTime) {
                return
            }

            const conversionSerieData: ChartSerieItem[] = data.checkoutReach.totals.map((item) => {
                const dropRatio = find(data.checkoutDropRatio.totals, {
                    name: item.name,
                })
                const reachRatio = find(data.checkoutReachRatio.totals, {
                    name: item.name,
                })
                return {
                    ...item,
                    dropoff: getPercentValue(dropRatio?.value),
                    reach: getPercentValue(reachRatio?.value),
                }
            })
            if (_isMounted.current) {
                setLoading(false)
                setConversionRate(conversionSerieData.sort(sortByScreenName))
            }
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Conversion rate chart could not be loaded, please try again later.',
            )
            setLoading(false)
            setConversionRate([])
        }
    }

    const getPercentValue = (value?: number) => {
        return value ? roundNumber(value * 100, 0) : 0
    }

    const chartTooltipFormatter = (params: TooltipParams) => {
        let tooltip = getTooltipHeader(params.data.name)
        tooltip += getTooltipRow([`${params.marker} Visits:`, `${params.data.value}`])
        tooltip += getTooltipRow([`${getMarker(props.theme.colors.emerald)} Reach:`, `${params.data.reach}%`])
        const dropOffValue = params.data.dropoff ? `-${params.data.dropoff}%` : `${params.data.dropoff}%`
        tooltip += getTooltipRow([`${getMarker(props.theme.colors.sunrise)} Drop off:`, dropOffValue])
        tooltip += getTooltipFooter()

        return tooltip
    }

    const labelFormatter = (params: any) => {
        return `${params.data.reach}%`
    }

    var barWidth = '10%'
    var barGap = `${((100 - 10 * conversionRate.length) / (10 * conversionRate.length)) * 100}%`

    const conversionSeries: ChartSeries[] = []

    conversionRate.forEach((rate) =>
        conversionSeries.push({
            name: rate.name,
            type: 'bar',
            data: [rate],
            xAxisIndex: 1,
            barWidth: barWidth,
            barGap: barGap,
        }),
    )

    conversionSeries.push({
        name: 'Reach rate',
        type: 'line',
        data: conversionRate,
        xAxisIndex: 0,
        color: props.theme.colors.emerald,
        label: {
            show: true,
            formatter: labelFormatter,
            backgroundColor: props.theme.colors.emerald,
            color: props.theme.colors.white,
            padding: [6, 6, 6, 6],
            borderRadius: [4, 4, 4, 4],
        },
    })

    return (
        <div style={{ position: 'relative' }}>
            {loading && <ChartDataLoader />}
            <ChartHeadline size={4}>
                <span>Checkout funnel</span>
            </ChartHeadline>
            <AxisTitle title="Visitors" left="0.8em" top="7.3em" />
            <CustomAxisChart
                chartHeight="420px"
                series={conversionSeries}
                loading={loading}
                tooltipFormatter={chartTooltipFormatter}
                xAxis={[
                    {
                        type: 'category',
                        data: conversionRate.map((d) => d.name),
                        axisLabel: { color: '#A4AFB2' },
                        axisLine: { show: false },
                        axisTick: { show: false },
                    },
                    {
                        type: 'category',
                        data: ['reach'],
                        show: false,
                    },
                ]}
            />
        </div>
    )
}

export default withTheme(ConversionRateChart)
