import * as React from 'react'
import styled, { keyframes } from 'styled-typed'
import { CONTAINER_BOX_SHADOW } from 'uiComponents/settingsContainer'

const SlideDown = keyframes`
  0% { opacity: 1; height: 0.9em }
  30% { opacity: 0; height: 0.9em }
  35% { opacity: 0; height: 0em }
  50% { opacity: 1; height: 0em; }
  100% { opacity: 1; height: 0.9em; }
`
const SlideInRight = keyframes`
  0% { opacity: 1; right: 0.4em; }
  30% { opacity: 0; right: 0.4em; }
  35% { opacity: 0; right: -3em; }
  50% { opacity: 1; right: -3em; }
  100% { opacity: 1; right: 0.4em; }
`

export const WonderBar = styled.div`
    position: absolute;
    top: 1.15em;
    width: 100%;
    height: 0.9em;
    background: ${(props) => props.theme.colors.boyBlue};
`

export const ModalWindow = styled.div`
    position: absolute;
    top: 1.75em;
    width: 50%;
    height: 3em;
    left: 2.0625em;
    border-radius: 5%;
    background: ${(props) => props.theme.colors.boyBlue};
`

export const ProofMessage = styled.div`
    position: absolute;
    right: 0.4em;
    bottom: 0.5em;
    height: 0.9em;
    width: 3em;
    border-radius: 0.25em;
    background: ${(props) => props.theme.colors.boyBlue};
`
const InfoBanner = styled.div`
    background: ${(props) => props.theme.colors.boyBlue};
    color: ${(props) => props.theme.colors.white};
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    font-size: 0.875em;
    font-weight: 300;
    line-height: 1.45em;
    width: 16em;
    border-radius: 0.5em 0.5em 0 0;
    padding-bottom: 0.1em;
    opacity: 0;
    transition: all 0.1s ease-in;
`
const ProductBox = styled.div`
    position: relative;
    box-sizing: content-box;
    margin: 0.5em;
    background: ${(props) => props.theme.colors.white};
    box-shadow: ${CONTAINER_BOX_SHADOW};
    border-radius: 0.625em;
    width: 12em;
    height: 15.625em;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: border, box-shadow 0.2s ease-in;

    &:hover {
        border: 2px solid ${(props) => props.theme.colors.boyBlue};
        margin: 0.37em;
        box-shadow: none;
    }

    &:hover ${WonderBar} {
        animation: ${SlideDown} 0.7s ease-in;
    }

    &:hover ${ProofMessage} {
        animation: ${SlideInRight} 0.7s ease-in;
    }

    &:hover ${InfoBanner} {
        opacity: 1;
    }

    @media (min-width: 568px) {
        width: 13.9em;
        margin: 0.9375em;

        &:hover {
            margin: 0.8125em;
        }
    }
`
const ProductTitle = styled.div`
    line-height: 1.3em;
    text-align: center;
    margin-bottom: 0.3em;
    max-width: 10em;
`
const ProductDescription = styled.div`
    font-size: 0.875em;
    font-weight: 300;
    line-height: 1.19em;
    text-align: center;
    max-width: 12em;
`
export const BrowserBox = styled.div`
    position: relative;
    margin: 1.8em auto 2.3em auto;
    height: 5.75em;
    width: 8.25em;
    border: 2px solid ${(props) => props.theme.colors.textDark};
    border-radius: 0.5em;
    font-weight: 800;
    overflow: hidden;

    &:before {
        content: '...';
        display: block;
        height: 0.5em;
        padding-left: 4px;
        font-size: 2.0625em;
        line-height: 0;
        letter-spacing: -2px;
        border-bottom: 2px solid ${(props) => props.theme.colors.textDark};
    }
`

interface ProductCardProps {
    children: React.ReactNode
    title: string
    description: string
    infoBannerText?: string
    onClick?: () => void
}

export function ProductCard(props: ProductCardProps & React.HTMLAttributes<HTMLElement>) {
    return (
        <ProductBox id={props.id} onClick={props.onClick}>
            {props.infoBannerText && <InfoBanner>{props.infoBannerText}</InfoBanner>}
            <BrowserBox>{props.children}</BrowserBox>
            <ProductTitle>{props.title}</ProductTitle>
            <ProductDescription>{props.description}</ProductDescription>
        </ProductBox>
    )
}
