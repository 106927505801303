import React, { FC } from 'react'
import { BaseRouteParams, withNavigation, WithNavigationProps } from 'hocs'
import OrdersFilter from 'orders/orders/filters/dynamicFilters'
import { history } from 'middleware'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isUserReseller } from 'auth/selectors'
import { pathUtils } from 'utils/pathnameFormatter'
import { Button } from '@mui/material'
import { ScanSolid } from '@convious/icons'
import ColumnsConfigurator from './columnsConfigurator'

type Props = WithNavigationProps<BaseRouteParams>

const UtilityHeader: FC<Props> = ({ match }) => {
    const { accountSlug } = match.params
    const location = useLocation()
    const isReseller = useSelector(isUserReseller)

    return (
        <>
            <OrdersFilter page="orders" accountSlug={accountSlug} />
            <ColumnsConfigurator />
            {!isReseller && (
                <Button
                    variant="outlined"
                    size="medium"
                    color="secondary"
                    onClick={() => {
                        history.push(pathUtils.addPath(location.pathname, 'redeemMode', location.search))
                    }}
                    data-testid="orders-redeemer-button"
                    startIcon={<ScanSolid />}
                    className="action-button"
                >
                    Redeemer
                </Button>
            )}
        </>
    )
}

export default withNavigation(UtilityHeader)
