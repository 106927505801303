import * as React from 'react'
import './styles.scss'
import { ArrowLeftSolid } from '@convious/icons'

interface NavigationBackElementProps {
    text: string
    topOffset?: string
    leftOffset?: string
    onClick: () => void
}

export function NavigationBack(props: NavigationBackElementProps) {
    return (
        <div className="navigation-back-container">
            <div className="element" onClick={props.onClick} style={{ top: props.topOffset, left: props.leftOffset }}>
                <ArrowLeftSolid className="arrow" />
                {props.text}
            </div>
        </div>
    )
}
