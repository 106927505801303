import React, { useRef } from 'react'
import styled from 'styled-typed'
import { UploadAreaText } from 'uiComponents/input/upload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleDown } from '@fortawesome/pro-light-svg-icons'

const DownloadContainer = styled.div`
    height: 5em;
    border: 1px solid ${(props) => props.theme.colors.boyBlue};
    color: ${(props) => props.theme.colors.boyBlue};
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 0.375em;
`
const DownloadAreaText = styled(UploadAreaText)`
    text-align: center;
    width: 100%;
    font-weight: 300;
`

interface ElementProps {
    exampleCSVHref: string
    exampleFileName: string
}

export function DownloadElement(props: ElementProps) {
    const downloadElement = useRef(null)

    const onDownloadExample = () => {
        if (!!downloadElement.current) {
            // @ts-ignore: Object is possibly 'null'.
            downloadElement.current.click()
        }
    }

    return (
        <DownloadContainer onClick={onDownloadExample}>
            <DownloadAreaText>
                <FontAwesomeIcon icon={faArrowCircleDown} style={{ marginRight: '.5em' }} />
                Download a CSV sample file
            </DownloadAreaText>
            <a
                ref={downloadElement}
                hidden
                href={props.exampleCSVHref}
                target="_blank"
                download={`${props.exampleFileName}.csv`}
                rel="noreferrer"
            />
        </DownloadContainer>
    )
}
