import React from 'react'
import { History } from 'history'
import { InlineDataTable, HeaderRow, TableHeader, DataRow } from 'uiComponents/table'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { OptionsService, OptionItem } from 'products/options/optionsService'
import { OptionItemsRowCells } from './optionItemsRowCells'
import { MessageKind } from 'uiComponents/messages'
import { optionsLisColumnWidths } from './groupsList'
import { ButtonWrapper } from 'uiComponents/pageElements'
import { ActionButtonA } from 'uiComponents/buttons'

interface OptionGroupsListPageProps {
    history: History
    accountSlug: string
    optionItems: OptionItem[]
    groupUuid: string
    optionsService: OptionsService
    loading: boolean
    reloadList: () => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

const columnWidths = optionsLisColumnWidths.slice(1)

export function OptionItemsList(props: OptionGroupsListPageProps) {
    return (
        <>
            <InlineDataTable columnWidths={columnWidths} id="option-items-list">
                <HeaderRow>
                    <TableHeader>Name</TableHeader>
                    <TableHeader align="center">Priority</TableHeader>
                    <TableHeader>Price</TableHeader>
                    <TableHeader nonInteractive />
                </HeaderRow>
                {props.optionItems.length === 0 && <NoResultsRow text="No option items created yet." />}
                {props.optionItems.map((item) => {
                    return (
                        <DataRow key={item.id} narrow>
                            <OptionItemsRowCells
                                optionItem={item}
                                groupUuid={props.groupUuid}
                                accountSlug={props.accountSlug}
                                history={props.history}
                                optionsService={props.optionsService}
                                replaceMessages={props.replaceMessages}
                                nested={false}
                                loading={props.loading}
                                reloadList={props.reloadList}
                            />
                        </DataRow>
                    )
                })}
                <ButtonWrapper>
                    <ActionButtonA
                        id="add-option-item"
                        size="large"
                        href={`/account/${props.accountSlug}/products/option_groups/option_item/${props.groupUuid}/new`}
                        style={{ marginTop: '2em' }}
                    >
                        Add new
                    </ActionButtonA>
                </ButtonWrapper>
            </InlineDataTable>
        </>
    )
}
