import React from 'react'
import { format as fnsFormatter } from 'date-fns'
import { parseISODate } from 'utils/dates'

interface DateFormatterProps {
    value?: string | Date
    format?: string
    fallback?: any
}

export const DateFormatter: React.FC<DateFormatterProps> = ({ value, fallback, format }) => {
    if (!value || !format) {
        return fallback || null
    }

    const dateValue = typeof value === 'string' ? parseISODate(value) : value

    return fnsFormatter(dateValue, format)
}
