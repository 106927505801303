import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, AnyAction } from 'redux'
import styled from 'styled-typed'
import NoAvatar from 'header/no-avatar.png'
import { profileLoaded } from 'settings/actions'
import { AvatarLoader } from 'uiComponents/loaders'
import { AccountSettingsService } from 'settings/settingsService'
import { PageTopMessage, HeaderMessageContainer } from 'uiComponents/messages'
import { UserService } from 'auth/userService'
import { Headline } from 'uiComponents/typography'

interface AccountSettingsProps {
    accountSettingsService: AccountSettingsService
    userService: UserService
    username: string
    avatarUrl: string
    profileLoaded: typeof profileLoaded
}

interface AccountSettingsState {
    uploading: boolean
    errorMessage: string
    showErrorMessage: boolean
}

interface AvatarProps {
    avatarUrl?: string
}

const Header = styled.div`
    margin-bottom: 1.375em;
    position: relative;
`

const AvatarFrame = styled.div`
    width: 5.3em;
    height: 5.3em;
    background: #ffffff;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.colors.boyBlue};
    display: flex;
    justify-content: center;
    align-items: center;
`
const Avatar = styled.div<AvatarProps>`
    width: 3.8em;
    height: 3.8em;
    ${(props) =>
        props.avatarUrl
            ? 'background: url(' + props.avatarUrl + ') no-repeat center'
            : 'background: url(' + NoAvatar + ') no-repeat center'};
    background-size: cover;
    border-radius: 50%;
`
const UploadArea = styled.label`
    display: inline-block;
    text-align: center;
    cursor: pointer;
    span {
        font-size: 0.875em;
        color: ${(props) => props.theme.colors.textLight};
    }
`

class AccountSettingsHeader extends React.Component<AccountSettingsProps, AccountSettingsState> {
    constructor(props: AccountSettingsProps) {
        super(props)
        this.state = {
            uploading: false,
            errorMessage: '',
            showErrorMessage: false,
        }
    }

    onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist()
        if (e.target.files) {
            this.fileUpload(e.target.files[0])
        }
    }

    fileUpload = async (file: File) => {
        this.setState({
            errorMessage: '',
            showErrorMessage: false,
            uploading: true,
        })
        try {
            await this.props.accountSettingsService.avatarUpload(file)
            this.updateProfile()
        } catch (e) {
            this.setState({
                errorMessage: e.message,
                showErrorMessage: true,
                uploading: false,
            })
        }
    }

    updateProfile = async () => {
        const userDetails = await this.props.userService.getLoggedInUser()
        this.props.profileLoaded(userDetails.profile)
        this.setState({ uploading: false })
    }

    render() {
        const { uploading, errorMessage, showErrorMessage } = this.state

        return (
            <Header>
                <HeaderMessageContainer>
                    <PageTopMessage
                        kind="error"
                        showMessage={showErrorMessage}
                        onDismiss={() => this.setState({ showErrorMessage: false })}
                    >
                        {errorMessage}
                    </PageTopMessage>
                </HeaderMessageContainer>
                <Headline size={1}>Personal Settings</Headline>
                {uploading && <AvatarLoader />}
                {!uploading && (
                    <UploadArea htmlFor="avatarUpload">
                        <AvatarFrame>
                            <Avatar avatarUrl={this.props.avatarUrl} />
                        </AvatarFrame>
                        <span>Change photo</span>
                    </UploadArea>
                )}
                <input
                    onChange={this.onFileSelected}
                    type="file"
                    accept="image/*"
                    id="avatarUpload"
                    style={{ display: 'none' }}
                />
            </Header>
        )
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators(
        {
            profileLoaded,
        },
        dispatch,
    )
}

export default connect(null, mapDispatchToProps)(AccountSettingsHeader)
