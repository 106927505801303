import * as React from 'react'
import styled from 'styled-typed'
import { FormItem, FormItemName } from 'uiComponents/form/formElements'
import { PageLoader } from 'uiComponents/loaders'
import { Row, Column, ColumnGap, TextWithHorizontalLine, ButtonWrapper } from 'uiComponents/pageElements'
import { Tag } from 'uiComponents/tag'
import { ActionButton } from 'uiComponents/buttons'
import { MessageKind } from 'uiComponents/messages'
import { TextInput } from 'uiComponents/input'
import { ArticleService as AdminArticleService, ArticleListItem, ProductList } from 'admin/articleService'
import { PricingType, pricingTypesNames } from 'products/articleConfigurationService'
import { SingleSelect, SingleSelectOption } from 'uiComponents/input/singleSelect'
import { ValidationNotice } from 'products/components/validationNotice'
import Feature from 'features/feature'
import { ResellerResponseItem } from 'channels/channelsService'

const TagHolder = styled.div`
    font-size: 0.875em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1.5em;
`

interface ExceptionInfoFormSectionProps {
    exceptionProducts?: ArticleListItem[]
    exception: boolean
    prototype?: boolean
    exceptionName: string
    pricingType: PricingType | null
    resellersOptions: SingleSelectOption[]
    allResellers: ResellerResponseItem[]
    resellers: string[]
    updateExceptionResellers: (resellers: string[]) => void
    onDuplicateException: () => void
    updateProducts: (products: ArticleListItem[]) => void
    updatePricingType: (type: PricingType) => void
    updateExceptionName: (name: string) => void
    validate: boolean
    articleService: AdminArticleService
    accountSlug: string
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hasPermission: (permission: string, accountSlug: string) => boolean
}

interface ExceptionInfoFormSectionState {
    exceptionProducts: ArticleListItem[]
    exceptionName: string
    nameValid: boolean
    productsValid: boolean
    pricingTypeSelectionValid: boolean
    allAccountProducts: ArticleListItem[]
    allProducts: ArticleListItem[]
    allAccountProductLists: ProductListWithPath[]
    allProductLists: ProductListWithPath[]
    loading: boolean
}

interface GroupObject {
    name: string
    products: ArticleListItem[]
}

interface ProductListWithPath extends ProductList {
    path: string
}

export class ExceptionInfoFormSection extends React.Component<
    ExceptionInfoFormSectionProps,
    ExceptionInfoFormSectionState
> {
    constructor(props: ExceptionInfoFormSectionProps) {
        super(props)
        this.state = {
            exceptionProducts: this.props.exceptionProducts ? this.props.exceptionProducts : [],
            exceptionName: this.props.exceptionName ? this.props.exceptionName : '',
            nameValid: true,
            productsValid: true,
            pricingTypeSelectionValid: true,
            allAccountProducts: [],
            allProducts: [],
            allAccountProductLists: [],
            allProductLists: [],
            loading: false,
        }
    }

    componentDidUpdate(prevProps: ExceptionInfoFormSectionProps) {
        if (!prevProps.validate && this.props.validate) {
            this.checkNameValidity(this.state.exceptionName)
            this.checkProductsValidity(this.state.exceptionProducts)
            this.checkPricingTypeValidity()
        }
    }

    iterateProductLists(pls: ProductList[], parentName: string, mapped: ProductListWithPath[]): ProductListWithPath[] {
        pls.forEach((pl: ProductList) => {
            const pathPrefix = parentName ? `${parentName} -- ` : ''
            const path = `${pathPrefix}${pl.name}`
            if (!pl.hasChildren || (pl.children.length === 0 && pl.articles.length > 0)) {
                const element = { ...pl, path: path }
                mapped.push(element)
            } else {
                this.iterateProductLists(pl.children, path, mapped)
            }
        })
        return mapped
    }

    async componentDidMount() {
        this.setState({ loading: true })
        const { pricingType } = this.props
        try {
            const articles = await this.props.articleService.listArticles(this.props.accountSlug)
            const productsLists = await this.props.articleService.getProductLists(this.props.accountSlug)
            const products =
                productsLists.length > 0
                    ? articles.filter((a) => a.productListUuids || a.multipleProductLists)
                    : articles
            const productLists = this.iterateProductLists(productsLists, '', [])
            const articlesWithPricingType = products.filter((a) => a.pricingType === pricingType)
            this.setState({
                allAccountProducts: products,
                allProducts: pricingType ? articlesWithPricingType : products,
                allAccountProductLists: productLists,
                allProductLists: pricingType
                    ? this.getProductListsHoldingArticles(productLists, articlesWithPricingType, this.props.pricingType)
                    : productLists,
            })
        } catch (e) {
            this.props.replaceTopMessages(
                'unknown_error',
                'error',
                `Oops! Something went wrong. Please try again later. Error: ${e}`,
            )
        } finally {
            this.setState({ loading: false })
        }
    }

    getProductListsHoldingArticles = (
        productLists: ProductListWithPath[],
        articles: ArticleListItem[],
        type: PricingType | null,
    ) => {
        return productLists.filter((pl) => {
            const articlesThatMatch = pl.articles.filter((a) => {
                return articles.find((aa) => {
                    if (type) {
                        return a.id === aa.id && a.pricingType === type
                    } else {
                        return a.id === aa.id
                    }
                })
            })
            return articlesThatMatch.length > 0
        })
    }

    handlePricingTypeSelectChange = async (type: PricingType) => {
        const articlesWithType = this.state.allAccountProducts.filter((ap) => ap.pricingType === type)
        const productListsWithType = this.getProductListsHoldingArticles(
            this.state.allAccountProductLists,
            articlesWithType,
            type,
        )
        this.setState({
            allProducts: articlesWithType,
            pricingTypeSelectionValid: true,
            allProductLists: productListsWithType,
            exceptionProducts: [],
        })
        this.props.updatePricingType(type)
        this.props.updateProducts([])
    }

    getAvailablePricingTypes = () => {
        return this.state.allAccountProducts
            .reduce(function (typeValues: string[], product: ArticleListItem) {
                var type = product.pricingType
                if (typeValues.indexOf(type) < 0) {
                    typeValues.push(type)
                }
                return typeValues
            }, [])
            .map((tv) => {
                return { value: tv, name: pricingTypesNames[tv] }
            })
    }

    getAvailableProducts = () => {
        const exceptionProductsIds = this.state.exceptionProducts.map((ep) => ep.id)
        const availableProducts = this.state.allProducts.filter((ap) => {
            return !exceptionProductsIds.includes(ap.id)
        })
        const options = availableProducts.map((ap) => {
            return { value: ap.id, name: ap.name }
        })
        if (options.length > 0) {
            options.push({ value: 'all', name: 'Select all' })
        }
        return options
    }

    getAvailableProductLists = (): SingleSelectOption[] => {
        const exceptionProductsIds = this.state.exceptionProducts.map((ep) => ep.id)
        const availableAccountProducts = this.state.allAccountProducts.filter((ap) => {
            return !exceptionProductsIds.includes(ap.id)
        })
        const filtered = this.getProductListsHoldingArticles(
            this.state.allAccountProductLists,
            availableAccountProducts,
            this.props.pricingType,
        )
        const options = filtered.map((f) => {
            return { value: f.uuid, name: f.path || f.name }
        })
        if (options.length > 0) {
            options.push({ value: 'all', name: 'Select all' })
        }
        return options
    }

    handleProductListSelectChange = async (id: string) => {
        if (id === 'all') {
            const exceptionProducts = this.props.pricingType
                ? this.state.allAccountProducts.filter((ap) => ap.pricingType === this.props.pricingType)
                : this.state.allAccountProducts
            this.props.updateProducts(exceptionProducts)
            this.setState({
                exceptionProducts: exceptionProducts,
                productsValid: true,
            })
        } else {
            const result = await this.props.articleService.getProductListDetails(this.props.accountSlug, id)
            const filteredByPricingType = result.articles.filter((p) => p.pricingType === this.props.pricingType)
            this.setState({ allProducts: filteredByPricingType })
        }
    }

    checkProductsValidity = (products: ArticleListItem[]) => {
        if (products.length === 0) {
            this.setState({ productsValid: false })
        } else {
            this.setState({ productsValid: true })
        }
    }

    checkPricingTypeValidity = () => {
        this.setState({ pricingTypeSelectionValid: !!this.props.pricingType })
    }

    handleProductSelectChange = (id: string) => {
        const products = [...this.state.exceptionProducts]
        if (id === 'all') {
            ;[...this.state.allProducts].forEach((ap) => {
                if (this.props.pricingType) {
                    if (!products.find((p) => p.id === ap.id) && this.props.pricingType === ap.pricingType) {
                        products.push(ap)
                    }
                } else {
                    if (!products.find((p) => p.id === ap.id)) {
                        products.push(ap)
                    }
                }
            })
            this.setState({ exceptionProducts: products, productsValid: true })
        } else {
            const selectedProduct = this.state.allProducts.find((p) => p.id === id)
            if (selectedProduct) {
                products.push(selectedProduct)
                this.setState({ exceptionProducts: products, productsValid: true })
            }
        }

        this.props.updateProducts(products)
    }

    removeProduct = (productId: string) => {
        const newProdArray = this.state.exceptionProducts.filter((p) => p.id !== productId)
        this.setState({ exceptionProducts: newProdArray })
        this.checkProductsValidity(newProdArray)
        this.props.updateProducts(newProdArray)
    }

    updateExceptionName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value
        this.setState({ exceptionName: name })
    }

    checkNameValidity = (n: string) => {
        if (!n || n.length < 1) {
            this.setState({ nameValid: false })
        } else {
            this.setState({ nameValid: true })
        }
    }

    onFinishTypingName = (n: string) => {
        this.checkNameValidity(n)
        this.props.updateExceptionName(n)
    }

    groupProducts = (products: ArticleListItem[]): { name: string; products: ArticleListItem[] }[] => {
        const groups: { name: string; products: ArticleListItem[] }[] = new Array(this.state.allProductLists.length)

        for (let i = 0; i < groups.length; i++) {
            const productList = this.state.allProductLists[i]
            const productsInGroup = products.filter((p) => {
                if (p.productListUuids) {
                    return !p.multipleProductLists && productList.articles.find((a) => a.id === p.id)
                }
                return false
            })
            const groupObject: GroupObject = {
                name: productList.path,
                products: productsInGroup,
            }
            groups[i] = groupObject
        }
        if (this.state.allProductLists.length > 0) {
            groups.push({
                name: 'Multiple product lists',
                products: products.filter((p) => p.multipleProductLists),
            })
        }
        return groups.filter((g) => g.products.length > 0)
    }

    handleResellersChange = (reseller: string) => {
        this.props.updateExceptionResellers([...this.props.resellers, reseller])
    }

    removeReseller = (reseller: string) => {
        const updatedResellers = this.props.resellers.filter((r) => r !== reseller)
        this.props.updateExceptionResellers(updatedResellers)
    }

    getResellerOptions = () => {
        return this.props.resellersOptions.filter((r) => this.props.resellers.indexOf(r.value) < 0)
    }

    render() {
        const nameNotValid = !this.state.nameValid && this.props.validate
        const productGroups = this.groupProducts(this.state.exceptionProducts)
        const resellerSelectOptions = this.getResellerOptions()
        const { exception, pricingType } = this.props
        return (
            <>
                {this.state.loading && <PageLoader style={{ paddingBottom: '4em' }} />}
                {!this.state.loading && (
                    <>
                        <Row>
                            <Column>
                                <FormItem htmlFor="exceptionName">
                                    <FormItemName style={{ display: 'flex' }}>Exception name</FormItemName>
                                    <TextInput
                                        id="exceptionName"
                                        name="exceptionName"
                                        placeholder="Type the name..."
                                        value={this.state.exceptionName}
                                        onChange={this.updateExceptionName}
                                        onFinishTyping={this.onFinishTypingName}
                                        status={nameNotValid ? 'error' : 'normal'}
                                        block={true}
                                    />
                                </FormItem>
                                <ValidationNotice className={nameNotValid ? 'validation-message-visible' : ''}>
                                    Exception name should be at least 10 characters long
                                </ValidationNotice>
                            </Column>
                            <ColumnGap />
                            <Column>
                                {this.props.hasPermission('edit_pricing_settings', this.props.accountSlug) && (
                                    <ButtonWrapper>
                                        {this.props.exception && !this.props.prototype && (
                                            <ActionButton
                                                onClick={this.props.onDuplicateException}
                                                kind="action"
                                                size="large"
                                                secondary
                                            >
                                                Duplicate
                                            </ActionButton>
                                        )}
                                    </ButtonWrapper>
                                )}
                            </Column>
                        </Row>
                        <Feature name="ResellersFeature" accountSlug={this.props.accountSlug}>
                            <Row>
                                <Column>
                                    <FormItem htmlFor="exceptionResellers">
                                        <FormItemName style={{ display: 'flex' }}>
                                            Only apply to the reseller(s) (optional)
                                        </FormItemName>
                                        <SingleSelect
                                            id="exceptionResellers"
                                            noSelectOption="Select reseller"
                                            options={resellerSelectOptions}
                                            selected={null}
                                            height="2.8em"
                                            onSelect={this.handleResellersChange}
                                            disabled={resellerSelectOptions.length === 0}
                                        />
                                    </FormItem>
                                    {this.props.resellers.length === 0 && <ValidationNotice>&nbsp;</ValidationNotice>}
                                </Column>
                                <ColumnGap />
                                <Column />
                            </Row>
                            {this.props.resellers.length > 0 && (
                                <Row>
                                    <Column>
                                        <TextWithHorizontalLine
                                            text="Applies only to resellers"
                                            alignment="left"
                                            style={{ margin: '1em 0', color: 'black' }}
                                        />
                                        <TagHolder>
                                            {this.props.resellers.map((r) => {
                                                const reseller = this.props.allResellers.find((o) => o.resellerId === r)
                                                const resellerName = !reseller
                                                    ? r
                                                    : reseller.archived
                                                    ? `ARCHIVED - ${reseller.name}`
                                                    : reseller.name
                                                return (
                                                    <Tag
                                                        onClick={() => {
                                                            this.removeReseller(r)
                                                        }}
                                                        key={r}
                                                        style={{ margin: '.25em .5em' }}
                                                    >
                                                        {resellerName}
                                                    </Tag>
                                                )
                                            })}
                                        </TagHolder>
                                    </Column>
                                </Row>
                            )}
                        </Feature>
                        {this.state.allAccountProducts.length > 0 && (
                            <Row>
                                <Column>
                                    <FormItem htmlFor="exceptionPricingType">
                                        <FormItemName style={{ display: 'flex' }}>Pricing type</FormItemName>
                                        <SingleSelect
                                            id="exceptionPricingType"
                                            noSelectOption="Select a pricing type"
                                            options={this.getAvailablePricingTypes()}
                                            selected={this.props.pricingType}
                                            height="2.8em"
                                            status={
                                                !this.state.pricingTypeSelectionValid && this.props.validate
                                                    ? 'error'
                                                    : 'normal'
                                            }
                                            onSelect={this.handlePricingTypeSelectChange}
                                            disabled={this.props.exception}
                                        />
                                    </FormItem>
                                    <ValidationNotice
                                        className={
                                            !this.state.pricingTypeSelectionValid && this.props.validate
                                                ? 'validation-message-visible'
                                                : ''
                                        }
                                    >
                                        Select a pricing type
                                    </ValidationNotice>
                                </Column>
                                <ColumnGap />
                                <Column />
                            </Row>
                        )}
                        <Row>
                            {(pricingType || (exception && !pricingType)) && (
                                <>
                                    {this.state.allProductLists.length > 0 && (
                                        <>
                                            <Column>
                                                <FormItem htmlFor="products">
                                                    <FormItemName style={{ display: 'flex' }}>
                                                        Product list
                                                    </FormItemName>
                                                    <SingleSelect
                                                        id="exceptionProductList"
                                                        noSelectOption="Select a product list"
                                                        options={this.getAvailableProductLists()}
                                                        selected={null}
                                                        height="2.8em"
                                                        onSelect={this.handleProductListSelectChange}
                                                        disabled={this.getAvailableProductLists().length === 0}
                                                    />
                                                </FormItem>
                                            </Column>
                                            <ColumnGap />
                                        </>
                                    )}
                                    <Column>
                                        <FormItem htmlFor="products">
                                            <FormItemName style={{ display: 'flex' }}>Product(s)</FormItemName>
                                            <SingleSelect
                                                id="exceptionProducts"
                                                noSelectOption="Select products"
                                                options={this.getAvailableProducts()}
                                                selected={null}
                                                height="2.8em"
                                                status={
                                                    !this.state.productsValid && this.props.validate
                                                        ? 'error'
                                                        : 'normal'
                                                }
                                                onSelect={this.handleProductSelectChange}
                                                disabled={this.getAvailableProducts().length === 0}
                                            />
                                        </FormItem>
                                        <ValidationNotice
                                            className={
                                                !this.state.productsValid && this.props.validate
                                                    ? 'validation-message-visible'
                                                    : ''
                                            }
                                        >
                                            Select at least one product
                                        </ValidationNotice>
                                    </Column>
                                    {this.state.allProductLists.length === 0 && (
                                        <>
                                            <ColumnGap />
                                            <Column />
                                        </>
                                    )}
                                </>
                            )}
                        </Row>
                        {(productGroups.length > 0 || this.state.exceptionProducts.length > 0) && (
                            <Row>
                                {productGroups.length === 0 ? (
                                    <TagHolder>
                                        {this.state.exceptionProducts.map((p) => {
                                            return (
                                                <Tag
                                                    onClick={() => {
                                                        this.removeProduct(p.id)
                                                    }}
                                                    key={p.id}
                                                    style={{ margin: '.25em .5em' }}
                                                >
                                                    {p.name}
                                                </Tag>
                                            )
                                        })}
                                    </TagHolder>
                                ) : (
                                    <Column>
                                        {productGroups.map((pg, i) => {
                                            return (
                                                <div key={i}>
                                                    {pg.products.length > 0 && (
                                                        <div key={i}>
                                                            <TextWithHorizontalLine
                                                                text={pg.name}
                                                                alignment="left"
                                                                style={{ margin: '1em 0', color: 'black' }}
                                                            />
                                                            <TagHolder>
                                                                {pg.products.map((p) => {
                                                                    return (
                                                                        <Tag
                                                                            onClick={() => {
                                                                                this.removeProduct(p.id)
                                                                            }}
                                                                            key={p.id}
                                                                            style={{ margin: '.25em .5em' }}
                                                                        >
                                                                            {p.name}
                                                                        </Tag>
                                                                    )
                                                                })}
                                                            </TagHolder>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </Column>
                                )}
                            </Row>
                        )}
                    </>
                )}
            </>
        )
    }
}
