import React from 'react'
import { Store } from 'redux'
import { ThemeProvider } from 'styled-typed'
import { hot } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { defaultTheme } from 'theme'
import { State } from 'store'
import Routes from 'routes'

interface AppProps {
    store: Store<State>
}

export class App extends React.Component<AppProps, {}> {
    render() {
        return (
            <Provider store={this.props.store}>
                <ThemeProvider theme={defaultTheme}>
                    <Routes />
                </ThemeProvider>
            </Provider>
        )
    }
}

export default hot(module)(App)
