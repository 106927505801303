import * as React from 'react'
import styled from 'styled-typed'
import logo from 'images/logo-mini.svg'
import Svg from 'svg'

const MenuWrapper = styled.div`
    z-index: 2;
    font-weight: lighter;
    display: flex;
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100vh;
`

const MainMenu = styled.div`
    background: ${(props) => props.theme.colors.aluminiumShades[5]};
    flex: 0 0 4.4em;
    width: 4.4em;
`
const MainMenuHeader = styled.div`
    height: 6em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export function Menu() {
    return (
        <MenuWrapper>
            <MainMenu>
                <MainMenuHeader>
                    <div style={{ width: '2.625em' }}>
                        <Svg src={logo} />
                    </div>
                </MainMenuHeader>
            </MainMenu>
        </MenuWrapper>
    )
}
