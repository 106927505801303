import * as React from 'react'
import Filter from 'uiComponents/filter'
import { MetaData, FilterOption, FilterCategory } from 'uiComponents/filter/schema'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { Account } from 'auth/state'
import { AccountSettingsService } from 'settings/settingsService'
import { Industry } from 'settings/schema'
import { prettifySlug } from 'utils'

interface PartnerFilterProps {
    navigation: Navigation
    match: RouteMatch<any>
    accountSlug: string
    accounts: Account[]
    accountSettingsService: AccountSettingsService
}

interface PartnerFilterState {
    metaData: MetaData[]
    loading: boolean
    industries: Industry[]
}

class PartnerFilter extends React.Component<PartnerFilterProps, PartnerFilterState> {
    constructor(props: PartnerFilterProps) {
        super(props)
        this.state = {
            metaData: [],
            industries: [],
            loading: false,
        }
    }

    async componentDidMount() {
        await this.getMetaData()
    }

    getMetaData = async () => {
        try {
            this.setState({ loading: true })
            const industries = await this.props.accountSettingsService.getIndustries()
            this.setState({ industries, loading: false })
        } catch {
            this.setState({ loading: false })
        } finally {
            this.setMetaData()
        }
    }

    setMetaData = () => {
        const metaData = [
            {
                category: 'partner' as FilterCategory,
                options: this.getPartnerOptions(),
            },
            {
                category: 'industry' as FilterCategory,
                options: this.getIndustryOptions(),
            },
        ]
        this.setState({ metaData })
    }

    getPartnerOptions = () => {
        return this.props.accounts
            .map((a) => ({
                name: a.name,
                slug: a.slug,
                category: 'partner' as FilterCategory,
                parents: [],
                children: [],
                leafNode: true,
            }))
            .sort(this.sortOptions)
    }

    getIndustryName = (slug: string) => {
        const industry = this.state.industries.find((i) => i.value === slug)
        return industry ? industry.name : prettifySlug(slug)
    }

    getIndustryOptions = () => {
        const industries = this.props.accounts.map((a) => a.industry)
        const uniqueIndustries = Array.from(new Set(industries)).filter((i) => !!i)
        return uniqueIndustries
            .map((i) => ({
                name: this.getIndustryName(i),
                slug: i,
                category: 'industry' as FilterCategory,
                parents: [],
                children: [],
                leafNode: true,
            }))
            .sort(this.sortOptions)
    }

    sortOptions = (a: FilterOption, b: FilterOption) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        return 0
    }

    render() {
        return (
            <Filter
                accountSlug={this.props.accountSlug}
                metaData={this.state.metaData}
                applicableFilters={['partner', 'industry']}
                standaloneFilters={['partner', 'industry']}
                singleSelectCategories={[]}
                loading={this.state.loading}
            />
        )
    }
}

export default withNavigation(PartnerFilter)
