import React, { useState, useEffect, useContext, useRef } from 'react'
import { usePrevious } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { DataTotal } from 'reports/schema'
import BarChart from 'uiComponents/charts/barChart'
import { format } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { ChartHeadline, ChartContainer } from 'uiComponents/charts/styleComponents'
import { getTooltipRow, getTooltipHeader, getTooltipFooter } from 'reports/helpers'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import { QueryConfig } from 'reports/queryGenerator'
import Infotip from 'uiComponents/infotip'
import { DateRange } from 'dateRanges'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { TrafficSourseInfotipContent } from 'reports/chartTooltips'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'

const queryConfig: QueryConfig = {
    querySetName: 'ConversionRateChart',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'metric', type: 'StatsMetric' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'groupBy', type: 'String' },
    ],
    queries: [
        {
            name: 'customGroupBy',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'groupBy'],
            customVariables: [],
            presetResult: 'totals',
        },
    ],
}

interface ChartsProps {
    accountSlug: string
    dateRange: DateRange
    type: 'platform' | 'channel'
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

function ConversionRateChart(props: ChartsProps) {
    const _isMounted = useRef(false)
    const _lastRequest = useRef<number>()
    const statsService = useContext(StatsServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [conversionRate, setConversionRate] = useState<DataTotal[]>([])

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    useEffect(() => {
        _isMounted.current = true
        if (prevAccountSlug !== props.accountSlug || !areDateRangeDatesEqual(prevDateRange, props.dateRange)) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug, props.dateRange])

    const getData = async () => {
        setLoading(true)
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime
        const dateFrom = format(props.dateRange.from, 'yyyy-MM-dd')
        const dateTo = format(props.dateRange.to, 'yyyy-MM-dd')
        try {
            const variables = {
                widget: props.accountSlug,
                metric: 'checkout_conversion_rate',
                dateFrom,
                dateTo,
                groupBy: props.type === 'platform' ? 'platform' : 'website_traffic_acquisition_channel',
            }
            const data = await statsService.getStats(queryConfig, variables)
            const conversionData = data.customGroupBy.totals ? data.customGroupBy.totals : []
            conversionData.map((cr) => (cr.value = Number((cr.value * 100).toFixed(2))))
            setLoading(false)
            if (_isMounted.current && _lastRequest.current === requestTime) {
                setConversionRate(conversionData)
            }
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                `Oops! Conversion per ${props.type} chart could not be loaded, please try again later.`,
            )
            setLoading(false)
        }
    }

    const chartTooltipFormatter = (params: TooltipParams[]) => {
        let tooltip = getTooltipHeader(params[0].axisValue)
        tooltip += getTooltipRow([`${params[0].marker} Conversion rate:`, `${params[0].value}%`])
        tooltip += getTooltipFooter()

        return tooltip
    }

    const conversionSeries = [
        {
            name: `Checkout conversion rate per ${props.type} (%)`,
            type: 'bar',
            barGap: 0,
            barWidth: '60%',
            data: conversionRate,
        },
    ]
    const navigationLink = `/account/${props.accountSlug}/reports/traffic/${location.search}`

    return (
        <ChartContainer className="clickable-title">
            {loading && <ChartDataLoader />}
            <ChartHeadline size={4}>
                <span>
                    <UnstyledLink to={navigationLink}>
                        <>Checkout conversion rate per {props.type}</>
                    </UnstyledLink>
                </span>
                <Infotip
                    pointer={props.type === 'platform' ? 'left' : 'right'}
                    fixedMaxSize
                    maxWidth="40em"
                    allowDefault
                >
                    {props.type === 'platform' ? (
                        <>
                            Supported platforms include Desktop, Mobile (Web), and Mobile (App).
                            <div>Mobile (Web) is an activity coming from mobile browsers.</div>
                            Mobile (App) is activity coming from the&nbsp;
                            <a href="https://www.convious.com/tap" target="_blank" rel="noreferrer">
                                <span className="link">Convious TAP app</span>
                            </a>
                            .
                        </>
                    ) : (
                        <TrafficSourseInfotipContent />
                    )}
                </Infotip>
            </ChartHeadline>
            <BarChart
                rotateNames
                percent
                yAxisName="%"
                chartHeight="300px"
                gridBottom={70}
                series={conversionSeries}
                loading={loading}
                tooltipFormatter={chartTooltipFormatter}
            />
        </ChartContainer>
    )
}

export default ConversionRateChart
