import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addInlineMessage } from 'uiComponents/messages/actions'
import { useGetFeatureValue } from 'utils/useHasFeature'

export const CONVIOUS_INFO_COMPONENT_NAME = 'conviousInfoPopup'

export const useShowConviousStudyInfo = () => {
    const dispatch = useDispatch()
    const infoPopup = useGetFeatureValue(CONVIOUS_INFO_COMPONENT_NAME)

    useEffect(() => {
        const lastInfoPopup = localStorage.getItem(CONVIOUS_INFO_COMPONENT_NAME)
        const shouldShowPopup = lastInfoPopup !== infoPopup?.header

        if (infoPopup && shouldShowPopup) {
            const action = {
                status: 'info',
                header: infoPopup.header,
                tag: 'general',
                text: infoPopup.text,
            } as any

            if (infoPopup.buttonText) {
                action.actionButton = {
                    buttonText: infoPopup.buttonText,
                    href: infoPopup.buttonLink,
                    kind: 'action',
                }
            }

            dispatch(addInlineMessage(action))
        }
    }, [infoPopup])
}
