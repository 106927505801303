import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { BaseRouteParams } from 'hocs'
import { State } from 'store'

const HubSpot = () => {
    const {
        location: { pathname },
    } = useHistory()
    const { accountSlug } = useParams<BaseRouteParams>()
    const profile = useSelector((state: State) => state.profile.profile)

    const userState = useMemo(
        () => ({
            email: profile?.email,
            firstName: profile?.firstName,
            lastName: profile?.lastName,
            widget: accountSlug,
        }),
        [profile, accountSlug],
    )

    useEffect(() => {
        if (!!window._hsq) {
            window._hsq.push(['identify', userState])
        }
    }, [userState])

    useEffect(() => {
        if (!!window._hsq) {
            window._hsq.push(['setPath', pathname])
            window._hsq.push(['trackPageView'])
        }
    }, [pathname])

    return null
}

export default HubSpot
