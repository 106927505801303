import * as React from 'react'
import { StatsService } from 'http/statsService'
import { InventoryService } from 'inventory/inventoryService'
import Filter from 'uiComponents/filter'
import {
    MetaData,
    RawMetaData,
    RawFilterOption,
    FilterOption,
    Filters,
    FilterCategory,
    multiLevelCategories,
} from 'uiComponents/filter/schema'
import { MessageKind } from 'uiComponents/messages'
import { DateRange } from 'dateRanges'
import { isBefore } from 'date-fns'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { getProductOptions } from 'uiComponents/filter/filterHelpers'
import { withFeatures } from 'features'

interface ReportsFilterProps {
    navigation: Navigation
    match: RouteMatch<any>
    statsService: StatsService
    inventoryService: InventoryService
    accountSlug: string
    applicableFilters: FilterCategory[]
    standaloneFilters: FilterCategory[]
    metadataCategories: FilterCategory[]
    appliedFilters: Filters[]
    dateRange: DateRange
    userpilot?: string
    maintainFilters?: boolean
    filterKey?: string
    customBaseElementText?: string
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    hideMessage: (id: string) => void
    setMetadata?: (data: MetaData[]) => void
    hasFeature: (feature: string, accountSlug: string) => boolean
}

interface ReportsFilterState {
    metaData: MetaData[]
    disabled: boolean
    loading: boolean
}

class ReportsFilter extends React.Component<ReportsFilterProps, ReportsFilterState> {
    categoriesSortedByPriority = ['product_lists', 'product_lists_with_screens']
    private _lastRequest?: number = undefined

    constructor(props: ReportsFilterProps) {
        super(props)
        this.state = {
            metaData: [],
            disabled: false,
            loading: false,
        }
    }

    async componentDidMount() {
        await this.getMetaData()
    }

    async componentDidUpdate(prevProps: ReportsFilterProps) {
        if (prevProps.accountSlug !== this.props.accountSlug) {
            if (!this.props.maintainFilters) {
                this.props.navigation.addQueryWithReplace({ filter: null })
            }
            await this.getMetaData()
        }
        if (!areDateRangeDatesEqual(prevProps.dateRange, this.props.dateRange)) {
            const disabled = this.checkDateRangeOutOfBounds()
            this.setState({ disabled })
            disabled && this.props.appliedFilters.length > 0
                ? this.props.replaceMessages(
                      'filter-warning',
                      'warn',
                      'Filters can only be applied to time ranges starting from Jan 1, 2020.',
                  )
                : this.props.hideMessage('filter-warning')
        }
    }

    checkDateRangeOutOfBounds = () => {
        return isBefore(this.props.dateRange.from, new Date(2020, 0, 1))
    }

    getMetaData = async () => {
        try {
            const requestTime = new Date().valueOf()
            this._lastRequest = requestTime
            this.setState({ loading: true })
            let metadataCategories = this.props.metadataCategories
            if (
                location.pathname.includes('/reports/customers/') &&
                !this.props.hasFeature('RetailersReservationCodes', this.props.accountSlug)
            ) {
                metadataCategories = metadataCategories.filter((c) => c !== 'channels')
            }
            const metaDataString = await this.props.statsService.getFilterMetaData(
                this.props.accountSlug,
                metadataCategories,
            )

            if (this._lastRequest !== requestTime) {
                return
            }

            const metaData = JSON.parse(metaDataString)
            if (
                this.props.applicableFilters.indexOf('capacity_pools') >= 0 ||
                this.props.standaloneFilters.indexOf('capacity_pools') >= 0
            ) {
                const availabilityPools = await this.props.inventoryService.getInventoryList(this.props.accountSlug)
                metaData.capacity_pools = availabilityPools.map((ap) => ({
                    slug: ap.id,
                    display_name: ap.name,
                }))
            }
            this.setMappedMetaData(metaData)
        } catch {
            this.props.replaceMessages('filter-error', 'error', 'Oops! Filters could not be loaded. Please try again.')
        } finally {
            this.setState({
                disabled: this.checkDateRangeOutOfBounds(),
                loading: false,
            })
        }
    }

    setMappedMetaData = (rawMetaData: RawMetaData) => {
        const metaData = Object.keys(rawMetaData).map((k) => {
            const multiLevel = multiLevelCategories.indexOf(k as FilterCategory) > -1
            return {
                category: k as FilterCategory,
                options:
                    k === 'products'
                        ? getProductOptions(rawMetaData[k])
                        : multiLevel
                        ? this.getMultiLevelOptions(k as FilterCategory, rawMetaData[k], [])
                        : this.getMappedCategories(k as FilterCategory, rawMetaData[k]),
            }
        })
        this.setState({ metaData })
        if (this.props.setMetadata) {
            this.props.setMetadata(metaData)
        }
    }

    getMultiLevelOptions = (
        category: FilterCategory,
        options: RawFilterOption[],
        parents: string[],
    ): FilterOption[] => {
        const sortFunction = this.categoriesSortedByPriority.includes(category)
            ? this.sortOptionsByPriority
            : this.sortOptionsByName
        return options
            .map((o) => ({
                name: o.display_name || '',
                slug: o.slug,
                category: category,
                parents: [...parents],
                children: this.getMultiLevelOptions(category, o.children, [...parents, o.slug]).sort(sortFunction),
                leafNode: !o.children?.length,
            }))
            .sort(sortFunction)
    }

    getMappedCategories = (category: FilterCategory, options: RawFilterOption[]) => {
        return options
            .map((o) => ({
                name: o.display_name || '',
                slug: o.slug,
                category: category,
                parents: [],
                children: [],
                leafNode: true,
            }))
            .sort(this.sortOptionsByName)
    }

    sortOptionsByName = (a: FilterOption, b: FilterOption) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        return 0
    }

    sortOptionsByPriority = (a: FilterOption, b: FilterOption) => {
        return (a.priority || 0) - (b.priority || 0)
    }

    render() {
        return (
            <Filter
                accountSlug={this.props.accountSlug}
                metaData={this.state.metaData}
                applicableFilters={this.props.applicableFilters}
                standaloneFilters={this.props.standaloneFilters}
                singleSelectCategories={['audience', 'product_lists_with_screens', 'product_lists']}
                disabled={this.state.disabled}
                loading={this.state.loading}
                userpilot={this.props.userpilot}
                filterKey={this.props.filterKey}
                customBaseElementText={this.props.customBaseElementText}
            />
        )
    }
}

export default withFeatures(withNavigation(ReportsFilter))
