import React, { useState } from 'react'
import styled, { css } from 'styled-typed'
import Money from 'uiComponents/money'
import { transparency } from 'utils/css'
import { ModalDialog, ModalDialogTitle } from 'uiComponents/popups/modal'
import { CopyIcon } from 'uiComponents/buttons/copyIcon'
import { Body } from 'uiComponents/typography'
import { InlineDataTable, HeaderRow, TableHeader, Cell, DataRow } from 'uiComponents/table'
import { OrderItemOptions, Order, OrderDetails } from 'orders/schema'
import { format } from 'date-fns'
import { utcValue } from 'utils'
import { DateFormats, parseISODate } from 'utils/dates'

const TriggerElement = styled.span`
    color: ${(props) => props.theme.colors.boyBlue};
    cursor: pointer;
`
const ContentContainer = styled.div`
    min-width: 25em;
    max-width: 30em;
`
const DiscountRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8em 1.3em 0.8em 1em;

    :nth-child(2n + 1) {
        background: ${(props) => props.theme.colors.white};
    }
    :nth-child(2n) {
        background: ${(props) => transparency(props.theme.colors.tableRow, 0.5)};
    }
`
const customCellStyle = css`
    color: ${(props) => props.theme.colors.textDark};
    background: ${(props) => props.theme.colors.white};
    font-weight: 500;
`
const HeaderCell = styled(TableHeader)`
    ${customCellStyle}
`
const CustomCell = styled(Cell)`
    ${customCellStyle}
`
const NameCell = styled(Cell)`
    align-self: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
`

export function getDiscountCodeString(discountsString: string) {
    return discountsString ? discountsString.split('|').join(', ') : ''
}

interface DiscountCodesPopupProps {
    discountsList: string[]
}

export function DiscountCodesPopup({ discountsList }: DiscountCodesPopupProps) {
    const [showPopup, setShowPopup] = useState(false)

    return (
        <>
            {showPopup && (
                <ModalDialog onDismiss={() => setShowPopup(false)} interactive fromTop="20%">
                    <>
                        <ModalDialogTitle>Order discount codes</ModalDialogTitle>
                        <ContentContainer>
                            {discountsList.map((code) => (
                                <DiscountRow key={code}>
                                    <Body size={1}>{code}</Body>
                                    <CopyIcon text={code} style={{ marginLeft: '2em' }} />
                                </DiscountRow>
                            ))}
                        </ContentContainer>
                    </>
                </ModalDialog>
            )}
            <TriggerElement onClick={() => setShowPopup(true)}>
                {discountsList.length === 1 ? `${discountsList[0]}` : `Show ${discountsList.length} codes`}
            </TriggerElement>
        </>
    )
}

interface DiscountCodesElementProps {
    discountsString: string
}

export function DiscountCodesElement({ discountsString }: DiscountCodesElementProps) {
    if (!discountsString) {
        return <>N/A</>
    }
    const discountsList = discountsString.split('|')
    return <DiscountCodesPopup discountsList={discountsList} />
}

export function getOptionsTotal(options: OrderItemOptions[]) {
    return options.reduce((sum, o) => sum + o.numberOfItems * o.price, 0)
}

interface OptionsPopupProps {
    accountSlug: string
    optionsList: OrderItemOptions[]
}

export function OptionsPopup({ optionsList, accountSlug }: OptionsPopupProps) {
    const [showPopup, setShowPopup] = useState(false)

    const getTriggerText = () => {
        const total = <Money amount={getOptionsTotal(optionsList)} accountSlug={accountSlug} />
        if (optionsList.length === 1) {
            return (
                <>
                    {optionsList[0].name} ({total})
                </>
            )
        }
        return (
            <>
                Show {optionsList.length} options ({total})
            </>
        )
    }

    const columnWidths = [null, '6em', '6.5em']

    return (
        <>
            {showPopup && (
                <ModalDialog onDismiss={() => setShowPopup(false)} interactive fromTop="20%">
                    <>
                        <ModalDialogTitle>Order item options</ModalDialogTitle>
                        <ContentContainer>
                            <InlineDataTable columnWidths={columnWidths}>
                                <HeaderRow>
                                    <HeaderCell>Name</HeaderCell>
                                    <HeaderCell align="center">Quantity</HeaderCell>
                                    <HeaderCell>Subtotal</HeaderCell>
                                </HeaderRow>
                                {optionsList.map((item, i) => {
                                    return (
                                        <DataRow key={i} narrow>
                                            <NameCell title={item.name} className="wrapContent">
                                                {item.name}
                                            </NameCell>
                                            <Cell align="center">{item.numberOfItems}</Cell>
                                            <Cell>
                                                <Money
                                                    amount={item.price * item.numberOfItems}
                                                    accountSlug={accountSlug}
                                                />
                                            </Cell>
                                        </DataRow>
                                    )
                                })}
                                <DataRow narrow>
                                    <CustomCell align="right">Total</CustomCell>
                                    <CustomCell align="center">
                                        {optionsList.reduce((sum, o) => sum + o.numberOfItems, 0)}
                                    </CustomCell>
                                    <CustomCell>
                                        <Money amount={getOptionsTotal(optionsList)} accountSlug={accountSlug} />
                                    </CustomCell>
                                </DataRow>
                            </InlineDataTable>
                        </ContentContainer>
                    </>
                </ModalDialog>
            )}
            <TriggerElement onClick={() => setShowPopup(true)}>{getTriggerText()}</TriggerElement>
        </>
    )
}

interface OptionsElementProps {
    accountSlug: string
    options: OrderItemOptions[]
}

export function OptionsElement({ options, accountSlug }: OptionsElementProps) {
    if (!options.length) {
        return <>N/A</>
    }
    return <OptionsPopup optionsList={options} accountSlug={accountSlug} />
}

export const getPaymentDateTime = (order: Order | OrderDetails) => {
    if (!order.paymentDate) {
        return '-'
    }

    const utcPaymentDate = utcValue(parseISODate(order.paymentDate))
    return format(utcPaymentDate, `${DateFormats.LONG_DATE} ${DateFormats.SHORT_TIME}`)
}
