import { useFormikContext } from 'formik'
import React, { memo, useEffect } from 'react'
import styled from 'styled-components'
import TextInputFieldFormik from 'uiComponents/input/textInput/textInputFieldFormik'
import useMarkers from '../../../hooks/useMarkers'
import { POI } from '../../../models'
import { Field } from '../../mapDetails/mapForm'
import { Label, Subtitle } from './poiForm'

const ParallelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > div {
        width: 48%;
        margin-bottom: 0;
    }
`

const DragDropContainer = styled.div`
    border-radius: 3px;
    border: 2px dotted ${(props) => props.theme.colors.border};
    background-color: ${(props) => props.theme.colors.background};
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8em;
`

const Spacer = styled.div`
    text-align: center;
    padding: 6px;
    font-size: 0.8em;
`

const MarkerCoordinates = ({ data }: { data: POI }) => {
    const {
        poisToDisplay,
        activeMarker,
        addActiveMarker,
        removeActiveMarker,
        markerCoordinates,
        onChangeCoordinates,
        removeMarker,
    } = useMarkers()
    const { values, setValues } = useFormikContext<POI>()

    useEffect(() => {
        addActiveMarker(new google.maps.LatLng(data.lat, data.lng))
    }, [])

    useEffect(() => {
        if (data.id) {
            setTimeout(() => removeMarker(data.id), 100)
        }
    }, [data, poisToDisplay])

    useEffect(() => {
        return () => {
            if (!activeMarker) {
                return
            }

            removeActiveMarker(activeMarker)
        }
    }, [activeMarker])

    useEffect(() => {
        if (values?.lng && values?.lat) {
            onChangeCoordinates(new google.maps.LatLng(values.lat, values.lng))
        }
    }, [values.lat, values.lng])

    useEffect(() => {
        if (markerCoordinates?.lat() && markerCoordinates?.lng()) {
            setValues({
                ...values,
                lat: markerCoordinates?.lat(),
                lng: markerCoordinates?.lng(),
            })
        }
    }, [markerCoordinates])

    return (
        <>
            <Subtitle>Coordinates</Subtitle>
            <Label>Enter coordinated manually</Label>
            <ParallelContainer>
                <Field>
                    <TextInputFieldFormik id="latitude" name="lat" placeholder="Latitude" />
                </Field>
                <Field>
                    <TextInputFieldFormik id="lng" name="lng" placeholder="Longitude" />
                </Field>
            </ParallelContainer>
            <Spacer>or</Spacer>
            <DragDropContainer>Drag the location pin on the map</DragDropContainer>
        </>
    )
}

export default memo(MarkerCoordinates)
