import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AppServices } from 'middleware'
import {
    ConfigurationVersions,
    ProductName,
    PublishDraftErrorContext,
    PublishDraftResponse,
    PublishDraftResponseError,
    ResponseError,
} from './studio/schema'

export const engageToolsApi = createApi({
    reducerPath: 'engageTools',
    baseQuery: fetchBaseQuery({}),
    tagTypes: ['engageTools'],
    endpoints: (builder) => ({}),
})

const extendedEngageToolsApi = engageToolsApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        deleteCampaign: builder.mutation({
            invalidatesTags: (result, error, arg) => [{ type: 'engageTools', id: arg.type }],
            queryFn: async ({ type, id, accountSlug }: { accountSlug: string; type: ProductName; id: string }) => {
                try {
                    const data = await AppServices.componentsService.deleteCampaignObject(accountSlug, type, id)

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        unpublishCampaign: builder.mutation({
            invalidatesTags: (result, error, arg) => [{ type: 'engageTools', id: arg.type }],
            queryFn: async ({ type, id, accountSlug }: { accountSlug: string; type: ProductName; id: string }) => {
                try {
                    const data = await AppServices.componentsService.unpublishCurrentVersion(accountSlug, type, id)

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        publishCampaign: builder.mutation({
            invalidatesTags: (result, error, arg) => [{ type: 'engageTools', id: arg.type }],
            queryFn: async ({
                type,
                id,
                accountSlug,
                tool,
            }: {
                accountSlug: string
                type: ProductName
                id: string
                tool: ConfigurationVersions
            }) => {
                try {
                    let draftId
                    if (!tool.next) {
                        const draftVersion = await AppServices.componentsService.createEmptyDraft(accountSlug, type, id)
                        draftId = draftVersion.id
                    } else {
                        draftId = tool.next.id
                    }

                    const data = (await AppServices.componentsService.publishDraftWithHandler(
                        accountSlug,
                        id,
                        draftId,
                        type,
                    )) as PublishDraftResponse
                    const fullError = data as ResponseError<PublishDraftErrorContext>
                    const partError = data as PublishDraftResponseError

                    if (partError.message || fullError.error) {
                        return { error: data }
                    }

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        getConfigurationsVersions: builder.query({
            providesTags: (result, error, arg) => [{ type: 'engageTools' as const, id: arg.type }, 'engageTools'],
            queryFn: async ({
                accountSlug,
                type,
                query,
            }: {
                accountSlug: string
                type: ProductName
                query: string
            }) => {
                try {
                    const data = await AppServices.componentsService.getConfigurationsVersions(accountSlug, type, query)

                    data?.sort((a, b) => {
                        let fa = a?.name || 'z'
                        let fb = b?.name || 'z'

                        return fa.localeCompare(fb)
                    })

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
    }),
})

export const {
    useLazyGetConfigurationsVersionsQuery,
    useGetConfigurationsVersionsQuery,
    usePublishCampaignMutation,
    useUnpublishCampaignMutation,
    useDeleteCampaignMutation,
} = extendedEngageToolsApi
export const { reducer, reducerPath } = engageToolsApi
