import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { isContractFormEditable, setIsEditable } from './redux'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

export const ContractEditWarning: React.FC = () => {
    const dispatch = useAppDispatch()
    const isEditable = useAppSelector(isContractFormEditable)
    const shouldShowContractPageWarning = location.pathname.includes('contract')

    const toggleEditable = useCallback(() => {
        dispatch(setIsEditable(true))
    }, [dispatch, setIsEditable])

    if (!shouldShowContractPageWarning) {
        return null
    }

    return (
        <Box my="2rem" maxWidth="80%">
            <Alert
                severity="warning"
                action={
                    !isEditable && (
                        <Button size="small" variant="text" onClick={toggleEditable}>
                            Edit Contract
                        </Button>
                    )
                }
            >
                Contract editing is only available for finance department,{' '}
                <strong>only edit if you have permission to do so</strong>
            </Alert>
        </Box>
    )
}
