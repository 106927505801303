import * as React from 'react'
import { dateRangeFromQuery, DateRange, dateRangeToQuery } from 'dateRanges'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { ConfigurationVersions, ComponentsService } from 'engageTools/studio/componentsService'
import { ProductName } from 'engageTools/studio/schema'
import { Container } from 'uiComponents/pageElements'
import { PageTitle, PageHeading } from 'uiComponents/typography'
import { EngageToolsService } from 'engageTools/engageToolsService'
import { withNavigation } from 'hocs'
import { Messages, MessageKind } from 'uiComponents/messages'
import { withMessages, MessageProps } from 'hocs'
import ProductContainer from './productContainer'
import TAPAppContainer from './tapAppContainer'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { PageLoader } from 'uiComponents/loaders'
import Filters, { FILTERS } from 'engageTools/filters'
import { withFeatures } from 'features'
import { format } from 'date-fns'
import { areDateRangeDatesEqual, ReportsToolsWrapper, ReportUpdateInfo } from 'reports/helpers'
import { parseFilterQuery } from 'uiComponents/filter'
import { Filters as FiltersType } from 'uiComponents/filter/schema'
import Disclaimer from 'reports/disclaimer'

interface CampaignsPageProps {
    accountSlug: string
    engageToolsService: EngageToolsService
    navigation: Navigation
    match: RouteMatch<{}>
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    componentsService: ComponentsService
    hasFeature: (feature: string, accountSlug: string) => boolean
}

interface CampaignsPageState {
    loading: boolean
    configurationVersions: ConfigurationVersions[]
}

class CampaignsPage extends React.Component<CampaignsPageProps & MessageProps, CampaignsPageState> {
    constructor(props: CampaignsPageProps & MessageProps) {
        super(props)
        this.state = {
            loading: true,
            configurationVersions: [],
        }
    }

    async componentDidMount() {
        await this.getProductVersions()
    }

    async componentDidUpdate(prevProps: CampaignsPageProps) {
        const query = this.props.navigation.query()
        const prevQuery = prevProps.navigation.query()
        const dateRange = dateRangeFromQuery(query)
        const prevDateRange = dateRangeFromQuery(prevQuery)
        if (
            prevProps.accountSlug !== this.props.accountSlug ||
            !areDateRangeDatesEqual(dateRange, prevDateRange) ||
            prevQuery.filter !== query.filter
        ) {
            await this.getProductVersions()
        }
    }

    getProductVersions = async () => {
        try {
            this.setState({ loading: true })
            const query = this.props.navigation.query()
            const dateRange = dateRangeFromQuery(query)
            const dateFrom = format(dateRange.from, 'yyyy-MM-dd')
            const dateTo = format(dateRange.to, 'yyyy-MM-dd')
            const dateRangeString = `?date_from=${dateFrom}&date_to=${dateTo}`
            const filtersString = query.filter ? `&filters=${query.filter}` : ''
            const defaultQueryString = '?configuration_type=default' + filtersString
            const campaignsQueryString = dateRangeString + filtersString
            const defaultVersions = await this.props.componentsService.getConfigurationsVersions(
                this.props.accountSlug,
                'all',
                defaultQueryString,
            )
            const campaignVersions = await this.props.componentsService.getConfigurationsVersions(
                this.props.accountSlug,
                'all',
                campaignsQueryString,
            )
            this.setState({
                configurationVersions: [...defaultVersions, ...campaignVersions],
            })
        } catch {
            this.handleErrorMessage()
        } finally {
            this.setState({ loading: false })
        }
    }

    onDateRangeChanged = async (range: DateRange) => {
        this.props.navigation.addQueryWithReplace(dateRangeToQuery(range))
    }
    handleErrorMessage = () => {
        this.props.replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again.')
    }

    sortByPublish = (a: any, b: any) => {
        return b.published - a.published
    }

    sortByCreated = (a: any, b: any) => {
        return b.showStats - a.showStats
    }

    checkShouldShowTap = (appliedFilters: FiltersType[]) => {
        const deviceType = appliedFilters.find((f) => f.attribute === 'device_type')
        const toolType = appliedFilters.find((f) => f.attribute === 'tool_type')
        return (
            (!deviceType || deviceType.values.includes('mobile')) && (!toolType || toolType.values.includes('tap_app'))
        )
    }

    render() {
        const { accountSlug, engageToolsService, hasFeature } = this.props
        const { configurationVersions } = this.state

        const query = this.props.navigation.query()
        const dateRange = dateRangeFromQuery(query)

        const currentOrNextVersionWonderbar = configurationVersions.find(
            (v) => v.product === 'wonderbar' && (v.current || v.next),
        )
        const currentOrNextVersionModalWindow = configurationVersions.find(
            (v) => v.product === 'modal_window' && (v.current || v.next),
        )
        const currentOrNextVersionTriggerButton = configurationVersions.find(
            (v) => v.product === 'trigger_button' && (v.current || v.next),
        )
        const currentOrNextVersionCheckout = configurationVersions.find(
            (v) => v.product === 'checkout' && (v.current || v.next),
        )

        const campaignProducts = [
            {
                name: 'checkout' as ProductName,
                versions: this.state.configurationVersions.filter((v) => v.product === 'checkout'),
                currentOrNextVersion: currentOrNextVersionCheckout,
            },
            {
                name: 'trigger_button' as ProductName,
                versions: this.state.configurationVersions.filter((v) => v.product === 'trigger_button'),
                currentOrNextVersion: currentOrNextVersionTriggerButton,
            },
            {
                name: 'wonderbar' as ProductName,
                versions: this.state.configurationVersions.filter((v) => v.product === 'wonderbar'),
                currentOrNextVersion: currentOrNextVersionWonderbar,
            },
            {
                name: 'modal_window' as ProductName,
                versions: this.state.configurationVersions.filter((v) => v.product === 'modal_window'),
                currentOrNextVersion: currentOrNextVersionModalWindow,
            },
        ]

        const filtersToApply = hasFeature('CRMAudiencesPage', accountSlug)
            ? FILTERS
            : FILTERS.filter((f) => f !== 'audience')
        const appliedFilters = parseFilterQuery(query.filter)
        const showTapStats = this.checkShouldShowTap(appliedFilters)

        return (
            <Container id="engage-tools-page" style={{ marginBottom: '10em' }}>
                <Messages messages={this.props.messages} hideMessage={this.props.hideMessage} />
                <PageTitle>Engage Tools</PageTitle>
                <PageHeading fullWidth>
                    <div>
                        See how your Engage Tools are performing and compare them directly. For each tool and version,
                        you can see your unique visitors, conversion rates, number of buyers, revenue generated, and
                        much more. Use the date picker to explore specific date ranges.
                    </div>
                    <div>
                        View our{' '}
                        <a
                            target="_blank"
                            href="https://support.convious.com/help/how-to-use-your-engage-tools-report"
                            rel="noreferrer"
                        >
                            Knowledge Base
                        </a>{' '}
                        on how to understand and use your data.
                    </div>
                </PageHeading>
                <div style={{ display: 'flex' }}>
                    <ReportUpdateInfo metric="Ticket sales" text="updated every 30 min." />
                    <ReportUpdateInfo metric="Capacity" text="updated every 1 hour." />
                </div>
                <ReportsToolsWrapper>
                    <Filters
                        accountSlug={accountSlug}
                        dateRange={dateRange}
                        applicableFilters={filtersToApply}
                        standaloneFilters={filtersToApply}
                        includeTap
                        replaceMessages={this.props.replaceMessages}
                        hideMessage={this.props.hideMessage}
                    />
                    <DateRangePicker
                        range={dateRange}
                        onChange={this.onDateRangeChanged}
                        firstAvailableDay={new Date(2019, 0, 1)}
                    />
                </ReportsToolsWrapper>
                {this.state.loading && <PageLoader />}
                {!this.state.loading &&
                    campaignProducts
                        .sort(this.sortByPublish)
                        .sort(this.sortByCreated)
                        .map((p) => (
                            <ProductContainer
                                key={p.name}
                                product={p.name}
                                dateRange={dateRange}
                                versions={p.versions}
                                activeAccount={accountSlug}
                                engageToolsService={engageToolsService}
                                currentOrNextVersion={!!p.currentOrNextVersion}
                                replaceTopMessages={this.props.replaceMessages}
                            />
                        ))}
                {!this.state.loading && showTapStats && (
                    <TAPAppContainer
                        dateRange={dateRange}
                        accountSlug={accountSlug}
                        engageToolsService={engageToolsService}
                        replaceTopMessages={this.props.replaceMessages}
                    />
                )}
                <div style={{ marginTop: '2em' }}>
                    <Disclaimer />
                </div>
            </Container>
        )
    }
}

export default withFeatures(withMessages(withNavigation(CampaignsPage)))
