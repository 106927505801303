import React from 'react'
import { Headline } from 'uiComponents/typography'
import { ContentSection } from 'uiComponents/pageElements'
import { SingleSelect } from 'uiComponents/input/singleSelect'
import { FormItem, FormItemName } from 'uiComponents/form/formElements'

export type ReportFrequencyType = 'never' | 'daily' | 'weekly' | 'monthly'

export const reportFrequencyOptions = [
    { name: 'Never', value: 'never' },
    { name: 'Daily', value: 'daily' },
    { name: 'Weekly', value: 'weekly' },
    { name: 'Monthly', value: 'monthly' },
]

export const weekdayOptions = [
    { name: 'Monday', value: '1' },
    { name: 'Tuesday', value: '2' },
    { name: 'Wednesday', value: '3' },
    { name: 'Thursday', value: '4' },
    { name: 'Friday', value: '5' },
    { name: 'Saturday', value: '6' },
    { name: 'Sunday', value: '0' },
]

interface ComponentProps {
    reportFrequency: ReportFrequencyType
    weekday: string
    setReportFrequency: (value: string) => void
    setWeekday: (value: string) => void
}

export function PerformanceReports(props: ComponentProps) {
    return (
        <ContentSection style={{ marginTop: '0.4rem' }}>
            <Headline size={4}>Performance reports</Headline>
            <FormItem htmlFor="report-frequency">
                <FormItemName>Email notification frequency</FormItemName>
                <SingleSelect
                    id="report-frequency"
                    options={reportFrequencyOptions}
                    selected={props.reportFrequency}
                    onSelect={props.setReportFrequency}
                />
            </FormItem>
            {props.reportFrequency === 'weekly' && (
                <FormItem htmlFor="week-start" style={{ marginTop: '1rem' }}>
                    <FormItemName>Receive my report every</FormItemName>
                    <SingleSelect
                        id="week-start"
                        options={weekdayOptions}
                        selected={props.weekday}
                        onSelect={props.setWeekday}
                    />
                </FormItem>
            )}
        </ContentSection>
    )
}
