import { FieldType } from 'uiComponents/studio/form/fieldComponentSchema'
import {
    WonderbarConfiguration,
    ModalWindowConfiguration,
    TriggerButtonConfiguration,
    CheckoutConfiguration,
    ProductName,
    Translation,
    UrlRules,
    Languages,
    SetConfigValueFunc,
    ProductConfig,
} from 'engageTools/studio/schema'
import { MODAL_COPY_SLUGS, COPY_KEYS as MODAL_COPY_KEYS } from './modalwindow/copySection'
import { TRIGGER_COPY_KEYS } from './triggerbutton/copySection'
import { WONDERBAR_COPY_SLUGS, COPY_KEYS as WONDERBAR_COPY_KEYS } from './wonderbar/copySection'
import { getAvailableLanguages } from 'engageTools/studio/languages'
import { isValidUrl } from 'utils/formFieldChecks'
import { get } from 'lodash'
import { Configuration } from './componentsService'

export const engageToolTypes = ['wonderbar', 'modal_window', 'checkout', 'trigger_button']
export const deviceTypes = ['any', 'desktop', 'mobile']
export const productTitles = {
    wonderbar: 'Wonderbar',
    modal_window: 'Pop Up',
    trigger_button: 'Trigger Button',
    checkout: 'Checkout',
}

export const productURLSlugs = {
    wonderbar: 'wonderbar',
    modal_window: 'popup',
    trigger_button: 'trigger_button',
    checkout: 'checkout',
}

export const productNameOptions = [
    { name: 'Checkout', value: 'checkout' },
    { name: 'Trigger Button', value: 'trigger_button' },
    { name: 'Wonderbar', value: 'wonderbar' },
    { name: 'Pop Up', value: 'modal_window' },
]

export function getProductSlug(product: ProductName) {
    switch (product) {
        case 'modal_window':
            return 'modalWindow'
        case 'trigger_button':
            return 'triggerButton'
        case 'wonderbar':
            return 'wonderbar'
        case 'checkout':
            return 'checkout'
        default:
            return ''
    }
}

export function getConfigValue(item: FieldType, configuration: any): any {
    const value = get(configuration, item.path)

    if (item.path === '' || typeof value === 'undefined' || value === '') {
        return item.defaultValue
    }
    return value
}

export function handleInputChange(
    result: React.ChangeEvent<HTMLInputElement> | string | boolean,
    path: string,
    setConfigFunction: SetConfigValueFunc,
) {
    let value
    if (typeof result !== 'object' || typeof result === 'boolean') {
        // TODO
        value = result
    } else if (Array.isArray(result)) {
        value = result
    } else {
        const event = result
        value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    }
    setConfigFunction(path, value)
}

export function getWhichLanguages(product: ProductName) {
    let languages
    switch (product) {
        case 'wonderbar':
            languages = 'wonderbarLanguages'
            break
        case 'modal_window':
            languages = 'modalWindowLanguages'
            break
        case 'trigger_button':
            languages = 'triggerButtonLanguages'
            break
        case 'checkout':
            languages = 'checkoutLanguages'
            break
        default:
            languages = 'wonderbarLanguages'
    }
    return languages
}

function foundMissingTranslation(
    product: ProductName,
    configuration:
        | WonderbarConfiguration
        | ModalWindowConfiguration
        | TriggerButtonConfiguration
        | CheckoutConfiguration,
    lang: string,
    translations: Translation[],
): boolean {
    if (translations.length === 0) {
        return true
    }

    for (let i = 0; i < translations.length; i++) {
        const t = translations[i]
        const allowNoCtaUrlCopy =
            (product === 'modal_window' &&
                t.slug === MODAL_COPY_KEYS.URL &&
                (configuration as ModalWindowConfiguration).linkToCheckout) ||
            (product === 'wonderbar' &&
                t.slug === WONDERBAR_COPY_KEYS.URL &&
                (configuration as WonderbarConfiguration).target === 'checkout')
        const allowNoProceedButtonCopyPopUp =
            product === 'modal_window' &&
            t.slug === MODAL_COPY_KEYS.BUTTON &&
            (configuration as ModalWindowConfiguration).hideProceedButton

        if (allowNoCtaUrlCopy) {
            continue
        } else if (allowNoProceedButtonCopyPopUp) {
            continue
        } else if (
            product === 'modal_window' &&
            t.slug === MODAL_COPY_KEYS.DISMISS &&
            !(configuration as ModalWindowConfiguration).showDismissButton
        ) {
            continue
        } else if (product === 'modal_window' && MODAL_COPY_SLUGS.indexOf(t.slug) < 0) {
            continue
        } else if (product === 'wonderbar' && WONDERBAR_COPY_SLUGS.indexOf(t.slug) < 0) {
            continue
        } else if (
            product === 'trigger_button' &&
            t.slug === TRIGGER_COPY_KEYS.URL &&
            (configuration as TriggerButtonConfiguration).linkToCheckout
        ) {
            continue
        } else if (
            typeof t.values[lang] === 'undefined' ||
            t.values[lang] === '' ||
            (t.slug === TRIGGER_COPY_KEYS.URL && !isValidUrl(t.values[lang]))
        ) {
            return true
        } else {
            continue
        }
    }
    return false
}

export function getLanguageWithMissingTranslations(
    product: ProductName,
    configuration:
        | WonderbarConfiguration
        | ModalWindowConfiguration
        | TriggerButtonConfiguration
        | CheckoutConfiguration
        | null,
    languages: Languages | null,
    translations: Translation[],
): string | null {
    if (!product || !configuration || !languages) {
        return null
    }

    const availableLanguages = getAvailableLanguages(languages.selected, product)

    if (typeof languages.selected !== 'undefined') {
        if (product === 'wonderbar') {
            const missingLanguage = availableLanguages.find((lang: string) =>
                foundMissingTranslation(product, configuration, lang, translations),
            )

            return !!missingLanguage ? missingLanguage : null
        } else {
            if (
                availableLanguages.indexOf(languages.primary) > -1 &&
                foundMissingTranslation(product, configuration, languages.primary, translations)
            ) {
                return languages.primary
            } else {
                const missingLanguage = availableLanguages
                    .filter((al) => al !== languages.primary)
                    .find((al) => foundMissingTranslation(product, configuration, al, translations))
                return !!missingLanguage ? missingLanguage : null
            }
        }
    }
    return null
}

export const updateTranslations = (
    translations: Translation[] | null,
    slug: string,
    language: string,
    value: string,
): Translation[] | null => {
    if (translations === null) {
        return translations
    }
    const newTranslations = translations.slice()
    for (let i = 0; i < translations.length; i++) {
        if (slug === translations[i].slug) {
            newTranslations[i].values[language] = value
            return newTranslations
        }
    }
    newTranslations.push({
        slug: slug,
        values: {
            [language]: value,
        },
    })
    return newTranslations
}

export const increasedUrlRulesBlacklist = (
    urlRules: UrlRules | null,
    ruleType: string,
    value: string,
): UrlRules | null => {
    if (!urlRules) {
        return urlRules
    }
    const newRules = { ...urlRules }
    const blacklist = newRules.blacklist
    for (let i = 0; i < blacklist.length; i++) {
        if (blacklist[i].type === ruleType) {
            blacklist[i].values.push(value)
            return newRules
        }
    }
    newRules.blacklist.push({
        type: ruleType,
        values: [value],
    })
    return newRules
}

export const decreasedUrlRulesBlacklist = (
    urlRules: UrlRules | null,
    ruleType: string,
    value: string,
): UrlRules | null => {
    if (!urlRules) {
        return urlRules
    }
    const newRules = { ...urlRules }
    const blacklist = newRules.blacklist
    for (let i = 0; i < blacklist.length; i++) {
        if (blacklist[i].type === ruleType) {
            const filteredBlacklistValues = blacklist[i].values.filter((v) => {
                return v !== value
            })
            newRules.blacklist[i].values = filteredBlacklistValues
            return newRules
        }
    }
    return newRules
}

export const increasedUrlRulesWhitelist = (
    urlRules: UrlRules | null,
    ruleType: string,
    value: string,
): UrlRules | null => {
    if (!urlRules) {
        return urlRules
    }
    const newRules = { ...urlRules }
    if (!newRules.whitelist) {
        newRules.whitelist = []
    }
    for (let i = 0; i < newRules.whitelist.length; i++) {
        if (newRules.whitelist[i].type === ruleType) {
            newRules.whitelist[i].values.push(value)
            return newRules
        }
    }
    newRules.whitelist.push({
        type: ruleType,
        values: [value],
    })
    return newRules
}

export const decreasedUrlRulesWhitelist = (
    urlRules: UrlRules | null,
    ruleType: string,
    value: string,
): UrlRules | null => {
    if (!urlRules) {
        return urlRules
    }
    const newRules = { ...urlRules }
    if (!newRules.whitelist) {
        return newRules
    }
    for (let i = 0; i < newRules.whitelist.length; i++) {
        if (newRules.whitelist[i].type === ruleType) {
            const filteredWitelistValues = newRules.whitelist[i].values.filter((v) => {
                return v !== value
            })
            newRules.whitelist[i].values = filteredWitelistValues
            return newRules
        }
    }
    return newRules
}

interface BuildConfiguration {
    config:
        | WonderbarConfiguration
        | ModalWindowConfiguration
        | TriggerButtonConfiguration
        | CheckoutConfiguration
        | null
    transl: Translation[]
    langs: Languages | null
    urlRules: UrlRules | null
    product: ProductName
    productConfig: ProductConfig | null
}

export function buildConfiguration({ config, transl, langs, urlRules, product, productConfig }: BuildConfiguration) {
    if (!config || !transl || !langs || !urlRules || !productConfig) {
        throw new Error('Cannot send null values as configuration')
    }
    let slug =
        product === 'trigger_button'
            ? 'trigger_button'
            : product === 'checkout'
            ? 'checkout_theme'
            : getProductSlug(product)
    return {
        components: [
            {
                slug: slug,
                value: config,
            },
        ],
        translations: transl,
        languages: langs,
        urlRules: urlRules,
        productConfig: productConfig,
    }
}

export const fontOptions = [
    {
        name: 'Acme',
        value: 'Acme',
    },
    {
        name: 'Anton',
        value: 'Anton',
    },
    {
        name: 'Fira Sans',
        value: 'Fira Sans',
    },
    {
        name: 'Lato',
        value: 'Lato',
    },
    {
        name: 'Libre Baskerville',
        value: 'Libre Baskerville',
    },
    {
        name: 'Merriweather',
        value: 'Merriweather',
    },
    {
        name: 'Montserrat',
        value: 'Montserrat',
    },
    {
        name: 'Nunito',
        value: 'Nunito',
    },
    {
        name: 'Open Sans',
        value: 'Open Sans',
    },
    {
        name: 'Oswald',
        value: 'Oswald',
    },
    {
        name: 'Pacifico',
        value: 'Pacifico',
    },
    {
        name: 'Permanent Marker',
        value: 'Permanent Marker',
    },
    {
        name: 'Playfair Display',
        value: 'Playfair Display',
    },
    {
        name: 'PT Sans',
        value: 'PT Sans',
    },
    {
        name: 'PT Serif',
        value: 'PT Serif',
    },
    {
        name: 'Raleway',
        value: 'Raleway',
    },
    {
        name: 'Roboto',
        value: 'Roboto',
    },
    {
        name: 'Roboto Slab',
        value: 'Roboto Slab',
    },
    {
        name: 'Shadows Into Light',
        value: 'Shadows Into Light',
    },
    {
        name: 'Source Sans Pro',
        value: 'Source Sans Pro',
    },
]

// Since translations are being returned using locales in lowercase
export const syncTranslationKeyWithLanguageLocale = (data: Configuration) => {
    return {
        ...data,
        translations: data.translations.map((translation: Translation) => ({
            ...translation,
            values: Object.fromEntries(
                Object.entries(translation.values).map(([key, value]) => {
                    const localeWithUpperCase = data.languages.selected.find(
                        (lang: string) => lang.toLowerCase() === key,
                    )
                    if (localeWithUpperCase) {
                        return [localeWithUpperCase, value]
                    }
                    return [key, value]
                }),
            ),
        })),
    }
}
