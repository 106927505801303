import { useField } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { Row } from 'uiComponents/flex'
import ToggleFieldFormik from 'uiComponents/input/toggle/toggleFieldFormik'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'

interface ToggleMapEnabledProps {
    onChange: () => void
}

const ToggleMapEnabled = ({ onChange }: ToggleMapEnabledProps) => {
    const [{ value }, {}, { setValue }] = useField('enabled')
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false)
    const [blockDialog, setBlockDialog] = useState<boolean>(true)
    const texts = {
        title: value ? 'Activate the current park map?' : 'Deactivate the current park map?',
        text: value
            ? 'This park map will be activated from the app.'
            : 'This park map will be deactivated from the app.',
    }

    useEffect(() => {
        if (blockDialog) {
            setBlockDialog(false)

            return
        }

        setShowConfirmationDialog(true)
    }, [value])

    const onConfirm = useCallback(() => {
        setShowConfirmationDialog(false)
        onChange()
    }, [])

    const onCancel = useCallback(() => {
        setBlockDialog(true)
        setShowConfirmationDialog(false)
        setValue(!value)
    }, [value])

    return (
        <Row>
            {showConfirmationDialog && (
                <ConfirmationDialog
                    title={texts.title}
                    text={texts.text}
                    buttonText={value ? 'Activate' : 'Deactivate'}
                    destructive
                    onCancel={onCancel}
                    onConfirm={onConfirm}
                />
            )}
            <ToggleFieldFormik name="enabled" label="Activate" id="enabled" variant="inline" />
        </Row>
    )
}

export default ToggleMapEnabled
