import { useDispatch } from 'react-redux'
import { replaceMessage } from 'uiComponents/messages/actions'
import { Parkmap } from '../models'
import { useUpdateParkMapMutation } from '../reduxQuery'
import { useSelectedParkMapContext } from '../context/selectedParkMap'

export const useUpdateMap = () => {
    const [updateParkMap, { isLoading }] = useUpdateParkMapMutation()
    const { selectedParkMap, setSelectedParkMap } = useSelectedParkMapContext()
    const dispatch = useDispatch()

    const updateMapStylingData = () => {
        if (!selectedParkMap) {
            return
        }
        updateMap(selectedParkMap)
    }

    const updateMap = (parkmap: Parkmap) => {
        updateParkMap({ parkMap: parkmap })
            .unwrap()
            .then((response) => {
                if (response) {
                    setSelectedParkMap(response)
                }
                dispatch(replaceMessage('update_park_map', 'success', 'Map updated', 5000))
            })
            .catch((error) => {
                dispatch(
                    replaceMessage('update_park_map', 'error', 'Ups. Something went wrong, please try again', 5000),
                )
            })
    }

    return { isLoading, updateMap, updateMapStylingData }
}

export default useUpdateMap
