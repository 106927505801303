import React from 'react'
import styled, { useTheme } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ParkMapZoomControls } from './types'

const ZoomControlContainer = styled.div`
    position: absolute;
    width: 28px;
    height: 53px;
    top: 24px;
    right: 24px;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
`

const ZoomControlButtonSeparator = styled.div`
    width: 20px;
    height: 1px;
    margin-left: 4px;
    background-color: ${(props) => props.theme.colors.aluminiumShades[10]};
`

const ZoomControlButton = styled.button`
    width: 28px;
    height: 26px;
    background: none;
    border: none;
`

const ParkMapZoomControl = (props: ParkMapZoomControls) => {
    const theme = useTheme()

    return (
        <>
            <ZoomControlContainer>
                <ZoomControlButton onClick={props.onZoomIn}>
                    <FontAwesomeIcon icon={faPlus as IconProp} color={theme.colors.aluminiumShades[70]} size="xs" />
                </ZoomControlButton>
                <ZoomControlButtonSeparator />
                <ZoomControlButton onClick={props.onZoomOut}>
                    <FontAwesomeIcon icon={faMinus as IconProp} color={theme.colors.aluminiumShades[70]} size="xs" />
                </ZoomControlButton>
            </ZoomControlContainer>
        </>
    )
}

export default ParkMapZoomControl
