import React from 'react'
import styled, { css } from 'styled-typed'
import { History } from 'history'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { IconProp } from '@fortawesome/fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BreadCrumbStyle = css`
    text-decoration: none;
    color: ${(p) => p.theme.colors.textLight};
    font-size: 0.75rem;
    font-weight: normal;
`

const BreadCrumbText = styled.span`
    ${BreadCrumbStyle}
    &.last {
        color: ${(p) => p.theme.colors.textDark};
    }
`

const BreadCrumbLink = styled(BreadCrumbText)`
    cursor: pointer;
`

const Arrow = styled.span`
    margin: 0 0.5rem;
    ${BreadCrumbStyle}
    font-size: 0.65rem;
`

export interface Path {
    name: string
    path: string
}
interface BreadCrumbsProps {
    path: Path[]
    style?: React.CSSProperties
    history: History
    icon?: IconProp
}

function BreadCrumbs(props: BreadCrumbsProps & RouteComponentProps<{}>) {
    return (
        <div style={props.style}>
            {props.path.map((p, index) => {
                return (
                    <div key={index} style={{ display: 'inline-block' }}>
                        {p.path ? (
                            <BreadCrumbLink
                                onClick={() => props.history.push(p.path)}
                                className={index === props.path.length - 1 ? 'last' : ''}
                            >
                                {props.icon && index === 0 ? <FontAwesomeIcon icon={props.icon} /> : <>{p.name}</>}
                            </BreadCrumbLink>
                        ) : (
                            <BreadCrumbText className={index === props.path.length - 1 ? 'last' : ''}>
                                {p.name}
                            </BreadCrumbText>
                        )}
                        <Arrow>{index < props.path.length - 1 && <FontAwesomeIcon icon={faChevronRight} />}</Arrow>
                    </div>
                )
            })}
        </div>
    )
}

export default withRouter(BreadCrumbs)
