import React, { useContext } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { format } from 'date-fns'
import { RuleFormContext } from './formContext'
import { Body } from 'uiComponents/typography'
import { Toggle } from 'uiComponents/input'
import { Row, Col } from 'uiComponents/flex'
import { TextInput } from 'uiComponents/input'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { textContainsSpecChars } from 'utils/formFieldChecks'
import { parseDate } from 'utils'

function GeneralSection() {
    const { rule, markErrors, updateRule } = useContext(RuleFormContext)

    const onStatusChange = (status: boolean) => {
        const updatedRule = cloneDeep(rule)
        updatedRule.enabled = status
        updateRule(updatedRule)
    }

    const onNameChange = (value: string) => {
        const updatedRule = cloneDeep(rule)
        updatedRule.name = value
        updateRule(updatedRule)
    }

    const onValidFromChange = (value: Date | null) => {
        const newDate = value ? format(value, 'yyyy-MM-dd') : null
        const updatedRule = cloneDeep(rule)
        updatedRule.validFrom = newDate
        updateRule(updatedRule)
    }

    const onValidToChange = (value: Date | null) => {
        const newDate = value ? format(value, 'yyyy-MM-dd') : null
        const updatedRule = cloneDeep(rule)
        updatedRule.validTo = newDate
        updateRule(updatedRule)
    }

    const invalidName = textContainsSpecChars(rule.name)
    const nameError = markErrors && (!rule.name.trim() || invalidName)
    const validityError = rule.validFrom && rule.validTo && rule.validTo < rule.validFrom

    return (
        <>
            <Row style={{ alignItems: 'center', marginBottom: '1.5em' }}>
                <Body size={1}>Activate</Body>
                <Toggle
                    isOn={rule.enabled}
                    onClick={onStatusChange}
                    className="smallInlineToggle"
                    style={{ marginLeft: '0.7rem' }}
                />
            </Row>
            <Row>
                <Col span={4}>
                    <FormItem htmlFor="rule-name">
                        <FormItemName>Name</FormItemName>
                        <TextInput
                            id="rule-name"
                            placeholder="Give your rule a name"
                            value={rule.name}
                            onChange={(e) => onNameChange(e.target.value)}
                            block
                            status={nameError ? 'error' : 'normal'}
                        />
                    </FormItem>
                    <ValidationMessage className={markErrors && invalidName ? 'validation-message-visible' : ''}>
                        Please only use A-z, 0-9, underscore and dash characters
                    </ValidationMessage>
                </Col>
                <Col span={3}>
                    <FormItem htmlFor="effective-from">
                        <FormItemName>Effective from</FormItemName>
                        <DatePicker
                            id="effective-from"
                            date={rule.validFrom ? parseDate(rule.validFrom) : null}
                            onChange={onValidFromChange}
                            status={markErrors && validityError ? 'error' : 'normal'}
                            allowNullDate={true}
                        />
                        <ValidationMessage />
                    </FormItem>
                </Col>
                <Col span={3}>
                    <FormItem htmlFor="effective-to">
                        <FormItemName>Effective to</FormItemName>
                        <DatePicker
                            id="effective-to"
                            date={rule.validTo ? parseDate(rule.validTo) : null}
                            onChange={onValidToChange}
                            status={markErrors && validityError ? 'error' : 'normal'}
                            allowNullDate={true}
                            lowerBoundary={rule.validFrom ? parseDate(rule.validFrom) : null}
                        />
                        <ValidationMessage
                            className={markErrors && validityError ? 'validation-message-visible' : ''}
                            style={{ top: '0.1em' }}
                        >
                            End date has to be greater than the start date
                        </ValidationMessage>
                    </FormItem>
                </Col>
                <Col span={2} />
            </Row>
            <Body size={1} style={{ marginBottom: '-1em' }}>
                Rule
            </Body>
        </>
    )
}

export default GeneralSection
