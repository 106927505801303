import * as React from 'react'
import { usePrevious } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { format } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { getComparisonColorFromPercentString, chartRowsGap } from 'reports/helpers'
import { DataNumber } from 'uiComponents/charts/dataNumber'
import { ChartRow, ChartContainer } from 'uiComponents/charts/styleComponents'
import { QueryConfig } from 'reports/queryGenerator'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { DateRange, DateRangeName } from 'dateRanges'
import { areDateRangeDatesEqual, comparisonCopyMap } from 'reports/helpers'
import { abbreviateNumber } from 'utils'
import { isBefore, startOfToday, differenceInCalendarDays } from 'date-fns'
import { Filters } from 'uiComponents/filter/schema'
import { ReportTimeIdentifierType } from 'reports/schema'
import isEqual from 'lodash/isEqual'

const queryConfig: QueryConfig = {
    querySetName: 'GeneralKPIs',
    variablesConfig: [
        { name: 'accountSlug', type: 'String' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'comparisonType', type: 'ComparisonTypes' },
        { name: 'timeFilter', type: 'StatsTimeFilter' },
        { name: 'delayType', type: 'DelayTypes' },
        { name: 'delay', type: 'Int' },
        { name: 'filters', type: '[FilterDictionary]' },
        { name: 'timeIdentifierType', type: 'TimeIdentifierType' },
    ],
    queries: [
        {
            name: 'revenue',
            type: 'comparisonMetrics',
            configVariables: [
                'accountSlug',
                'dateFrom',
                'dateTo',
                'comparisonType',
                'timeFilter',
                'delayType',
                'delay',
                'filters',
                'timeIdentifierType',
            ],
            customVariables: [{ name: 'metric', customValue: 'revenue' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'avgCartSize',
            type: 'comparisonMetrics',
            configVariables: [
                'accountSlug',
                'dateFrom',
                'dateTo',
                'comparisonType',
                'timeFilter',
                'delayType',
                'delay',
                'filters',
                'timeIdentifierType',
            ],
            customVariables: [{ name: 'metric', customValue: 'avg_cart_size' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'avgCartValue',
            type: 'comparisonMetrics',
            configVariables: [
                'accountSlug',
                'dateFrom',
                'dateTo',
                'comparisonType',
                'timeFilter',
                'delayType',
                'delay',
                'filters',
                'timeIdentifierType',
            ],
            customVariables: [{ name: 'metric', customValue: 'avg_order_size' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'checkoutConvRate',
            type: 'comparisonMetrics',
            configVariables: [
                'accountSlug',
                'dateFrom',
                'dateTo',
                'comparisonType',
                'timeFilter',
                'delayType',
                'delay',
                'filters',
                'timeIdentifierType',
            ],
            customVariables: [{ name: 'metric', customValue: 'checkout_conversion_rate' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'websiteConvRate',
            type: 'comparisonMetrics',
            configVariables: [
                'accountSlug',
                'dateFrom',
                'dateTo',
                'comparisonType',
                'timeFilter',
                'delayType',
                'delay',
                'filters',
                'timeIdentifierType',
            ],
            customVariables: [{ name: 'metric', customValue: 'website_conversion_rate' }],
            presetResult: 'totalsComparison',
        },
    ],
}

const infotipContentMap = {
    avgCartSize: {
        pageName: 'Average items per order',
        itemName: 'avg. items per order',
        more: 'more',
        less: 'less',
        metricText:
            'The average amount of items a customer buys per order, excluding Reservations or order changes (Items Sold / Number of Orders).',
    },
    revenue: {
        pageName: 'Revenue',
        itemName: 'revenue',
        more: 'more',
        less: 'less',
        metricText:
            'Total value of products sold at the Convious Checkout. Products correspond to the tickets, gift cards, order changes, and others.',
    },
    avgCartValue: {
        pageName: 'Average order value',
        itemName: 'avg. order value',
        more: 'higher',
        less: 'lower',
        metricText:
            'The average value of an order, excluding Reservations or order changes (Revenue / Number of Orders).',
    },
    checkoutConvRate: {
        pageName: 'Checkout conversion rate',
        itemName: 'checkout conversion rate',
        more: 'higher',
        less: 'lower',
        metricText:
            'The percentage of Checkout sessions in which a conversion (purchase) via your Checkout took place, excluding Reservations or order changes (Convious Buyers / Convious visitors).',
    },
    websiteConvRate: {
        pageName: 'Website conversion rate',
        itemName: 'website conversion rate',
        more: 'higher',
        less: 'lower',
        metricText:
            'The percentage of website sessions in which a purchase is completed in your ticket shop, excluding reservations or order changes (Convious Buyers / Website visitors).',
    },
}

interface SingleDataItemsProps {
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    dateRange: DateRange
    timeIdentifierType: ReportTimeIdentifierType
    filters: Filters[]
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

function SingleDataItems(props: SingleDataItemsProps) {
    const _isMounted = React.useRef(false)
    const _lastRequest = React.useRef<number>()
    const statsService = React.useContext(StatsServiceContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [timePeriod, setTimePeriod] = React.useState<DateRangeName>('yesterday')
    const [timePeriodLength, setTimePeriodLength] = React.useState<number>(0)
    const [avgCartSize, setAvgCartSize] = React.useState<string>('0')
    const [revenue, setRevenue] = React.useState<string>('0')
    const [avgCartValue, setAvgCartValue] = React.useState<string>('0')
    const [checkoutConvRate, setCheckoutConvRate] = React.useState<string>('0')
    const [websiteConvRate, setWebsiteConvRate] = React.useState<string>('0')
    const [comparisonCartSize, setComparisonCartSize] = React.useState<string>('-')
    const [comparisonRevenue, setComparisonRevenue] = React.useState<string>('-')
    const [comparisonCartValue, setComparisonCartValue] = React.useState<string>('-')
    const [comparisonCoConvRate, setComparisonCoConvRate] = React.useState<string>('-')
    const [comparisonWebConvRate, setComparisonWebConvRate] = React.useState<string>('-')

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    const prevFilters = usePrevious(props.filters)
    const prevTimeIdentifierType = usePrevious(props.timeIdentifierType)
    React.useEffect(() => {
        _isMounted.current = true
        if (
            prevAccountSlug !== props.accountSlug ||
            !areDateRangeDatesEqual(prevDateRange, props.dateRange) ||
            !isEqual(prevFilters, props.filters) ||
            prevTimeIdentifierType !== props.timeIdentifierType
        ) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug, props.dateRange, props.filters, props.timeIdentifierType])

    const getData = async () => {
        setLoading(true)
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime
        const timePeriods = ['today', 'yesterday', 'thisWeek', 'thisMonth']
        const timePeriodForMap = timePeriods.indexOf(props.dateRange.name) > -1 ? props.dateRange.name : 'custom'
        setTimePeriod(timePeriodForMap)
        const dateRangeLength = differenceInCalendarDays(props.dateRange.to, props.dateRange.from) + 1
        setTimePeriodLength(dateRangeLength)
        try {
            const todayIncluded = isBefore(startOfToday(), props.dateRange.to)
            const variables = {
                accountSlug: props.accountSlug,
                dateFrom: format(props.dateRange.from || new Date(), 'yyyy-MM-dd'),
                dateTo: format(props.dateRange.to || new Date(), 'yyyy-MM-dd'),
                comparisonType: 'percentage_growth',
                timeFilter: todayIncluded ? 'this_time_so_far' : null,
                delayType:
                    timePeriodForMap === 'thisMonth' ? 'months' : dateRangeLength <= 7 ? 'weeks' : 'previous_stretch',
                filters: props.filters,
                delay: 1,
                timeIdentifierType: props.timeIdentifierType,
            }
            const data = await statsService.getStats(queryConfig, variables)

            if (_isMounted.current && _lastRequest.current === requestTime) {
                setAvgCartSize(data.avgCartSize.totals?.value || '0')
                setRevenue(data.revenue.totals?.value || '0')
                setAvgCartValue(data.avgCartValue.totals?.value || '0')
                setCheckoutConvRate(data.checkoutConvRate.totals?.value || '0')
                setWebsiteConvRate(data.websiteConvRate.totals?.value || '0')
                setComparisonCartSize(data.avgCartSize.totals?.change || '-')
                setComparisonRevenue(data.revenue.totals?.change || '-')
                setComparisonCartValue(data.avgCartValue.totals?.change || '-')
                setComparisonCoConvRate(data.checkoutConvRate.totals?.change || '-')
                setComparisonWebConvRate(data.websiteConvRate.totals?.change || '-')
                setLoading(false)
            }
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Some data could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }

    const getPlusSign = (change: string) => {
        const value = Number(change.split('%')[0])
        return value > 0 ? '+' : ''
    }

    const formatChangeText = (stats: string, change: string) => {
        const value = Number(change.split('%')[0])
        return value >= 0
            ? `${getPlusSign(change)}${change} ${infotipContentMap[stats].more}`
            : `${change} ${infotipContentMap[stats].less}`
    }

    const formatInfotipText = (stats: string, change: string) => {
        const textPart = timePeriod === 'yesterday' || timePeriod === 'custom' ? 'had' : 'are expected to have'
        return (
            <>
                {comparisonCopyMap[timePeriod].infotipStart} you {textPart}
                &nbsp;{formatChangeText(stats, change)} {infotipContentMap[stats].itemName}
                &nbsp;than the {comparisonCopyMap[timePeriod].infotipEnd}.
            </>
        )
    }

    const getComparisonText = () => {
        return timePeriod === 'thisMonth' ? 'MoM' : timePeriodLength <= 7 ? 'WoW' : 'prev. period'
    }

    return (
        <ChartContainer style={{ marginTop: chartRowsGap, marginBottom: '2em' }}>
            {loading && <ChartDataLoader />}
            <ChartRow>
                <DataNumber
                    title="Revenue"
                    value={Number(revenue) >= 10000 ? abbreviateNumber(Number(revenue), 1) : Number(revenue).toFixed(2)}
                    comparisonItem={`${getPlusSign(comparisonRevenue)}${comparisonRevenue} ${getComparisonText()}`}
                    comparisonItemColor={getComparisonColorFromPercentString(comparisonRevenue)}
                    infotipText={formatInfotipText('revenue', comparisonRevenue)}
                    metricInfotipText={infotipContentMap['revenue'].metricText}
                    tooltipType={getComparisonText()}
                    withCurrency
                    accountSlug={props.accountSlug}
                    compact
                    id="1"
                    nthChildStyle="margin-right: -2em"
                />
                <DataNumber
                    title="Avg. order value"
                    value={Number(avgCartValue).toFixed(2)}
                    comparisonItem={`${getPlusSign(comparisonCartValue)}${comparisonCartValue} ${getComparisonText()}`}
                    comparisonItemColor={getComparisonColorFromPercentString(comparisonCartValue)}
                    infotipText={formatInfotipText('avgCartValue', comparisonCartValue)}
                    metricInfotipText={infotipContentMap['avgCartValue'].metricText}
                    withCurrency
                    accountSlug={props.accountSlug}
                    tooltipType={getComparisonText()}
                    compact
                    id="2"
                    nthChildStyle="margin-right: -1em"
                />
                <DataNumber
                    title="Avg. items per order"
                    value={Math.round(Number(avgCartSize) * 10) / 10}
                    comparisonItem={`${getPlusSign(comparisonCartSize)}${comparisonCartSize} ${getComparisonText()}`}
                    comparisonItemColor={getComparisonColorFromPercentString(comparisonCartSize)}
                    infotipText={formatInfotipText('avgCartSize', comparisonCartSize)}
                    metricInfotipText={infotipContentMap['avgCartSize'].metricText}
                    infotipPosition="right"
                    tooltipType={getComparisonText()}
                    compact
                    id="3"
                    nthChildStyle="margin-right: 1em"
                />
                <DataNumber
                    title="Website conv. rate"
                    value={`${(Number(websiteConvRate) * 100).toFixed(2)}%`}
                    comparisonItem={`${getPlusSign(
                        comparisonWebConvRate,
                    )}${comparisonWebConvRate} ${getComparisonText()}`}
                    comparisonItemColor={getComparisonColorFromPercentString(comparisonWebConvRate)}
                    infotipText={formatInfotipText('websiteConvRate', comparisonWebConvRate)}
                    metricInfotipText={infotipContentMap['websiteConvRate'].metricText}
                    infotipPosition="right"
                    tooltipType={getComparisonText()}
                    compact
                    id="4"
                />
                <DataNumber
                    title="Checkout conv. rate"
                    value={`${(Number(checkoutConvRate) * 100).toFixed(2)}%`}
                    comparisonItem={`${getPlusSign(
                        comparisonCoConvRate,
                    )}${comparisonCoConvRate} ${getComparisonText()}`}
                    comparisonItemColor={getComparisonColorFromPercentString(comparisonCoConvRate)}
                    infotipText={formatInfotipText('checkoutConvRate', comparisonCoConvRate)}
                    metricInfotipText={infotipContentMap['checkoutConvRate'].metricText}
                    infotipPosition="right"
                    tooltipType={getComparisonText()}
                    compact
                    id="5"
                    nthChildStyle="margin-right: -2em"
                />
            </ChartRow>
        </ChartContainer>
    )
}

export default withNavigation(SingleDataItems)
