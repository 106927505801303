import React, { useEffect, useState } from 'react'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Caption } from 'uiComponents/typography'
import ProductSearched from './productSearched'
import ProductNoResults from './productSearchNotFound'
import styled from 'styled-typed'
import { IconTextInput } from 'uiComponents/input'
import { searchProducts } from './utils'
import { IOption } from 'crm/audiences/types'

const SearchFieldInput = styled(IconTextInput)`
    width: 100%;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: 4px 0 0 4px;
    /* margin-bottom: 1em; */
    padding-left: 40px
    height: 42px;

    &:focus {
        box-shadow: none;
        border: 1px solid ${(props) => props.theme.colors.boyBlue};
    }
`

interface ProductSearchProps {
    selectedProductsUUID: string[]
    selectedProductListPathHash: string[]
    options: IOption[]
    onChange: (value: IOption) => void
}

export interface SearchResult {
    product: IOption
    parents?: IOption[]
}

const ProductSearch: React.FC<ProductSearchProps> = (props) => {
    const [search, setSearch] = useState<string>('')
    const [searchResults, setSearchResults] = useState<SearchResult[]>([])

    useEffect(() => {
        if (search.length > 2) {
            const results: SearchResult[] = []

            props.options.forEach((option) => {
                if (option.caption && option.caption.toLowerCase().includes(search.toLowerCase()) && !option.children) {
                    results.push({
                        product: option,
                    })
                }

                if (option.children) {
                    searchProducts(props.options, option.children, search, results)
                }
            })

            setSearchResults(results)
        }
    }, [search])

    const isChecked = (product: IOption) => {
        return props.selectedProductsUUID.includes(product.value as string)
    }

    const isDisabled = (product: IOption) => {
        return (
            props.selectedProductListPathHash.length > 0 &&
            !props.selectedProductListPathHash.includes(product.paths_id![0])
        )
    }

    return (
        <>
            <SearchFieldInput
                iconLeft={faSearch}
                placeholder="Search for product ( type at least 3 characters )"
                value={search || ''}
                onChange={(e) => setSearch(e.target.value)}
                block={true}
            />
            <Caption style={{ marginBottom: 24, marginTop: 4 }} color="light">
                Search query will only show ticket types{' '}
            </Caption>
            {search.length > 2 ? (
                <>
                    {searchResults.length ? (
                        searchResults.map((result, index) => (
                            <ProductSearched
                                key={index}
                                product={result.product}
                                parents={result.parents}
                                products={props.options}
                                onChange={props.onChange}
                                isChecked={isChecked}
                                isDisabled={isDisabled}
                            />
                        ))
                    ) : (
                        <ProductNoResults />
                    )}
                </>
            ) : (
                props.children
            )}
        </>
    )
}

export default ProductSearch
