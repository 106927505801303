import React from 'react'
import { isEqual } from 'lodash'

import { Cell, DataRow } from 'uiComponents/table'
import {
    ReactTableInstance,
    ReactTableRows,
    ReactTableState,
    RowProps,
    TableRowSize,
    ExtendedRowProps,
    DivProps,
} from './interface'
import { ReactTableCell } from './ui'
import classNames from 'classnames'

interface RenderRowProps {
    row: ReactTableRows
    tableState?: ReactTableState
    instance: ReactTableInstance
    size?: TableRowSize
    expanded?: boolean | ((props: { row: ReactTableRows }) => React.ReactNode)
    rowProps?: RowProps
    rowContainerProps?: ((row: ReactTableRows) => DivProps) | DivProps
    cellProps?: any
}

const RenderExpandedRow = ({
    expanded,
    row,
}: {
    expanded?: boolean | ((props: { row: ReactTableRows }) => React.ReactNode)
    row: ReactTableRows
}) => {
    if (!expanded || !row.isExpanded) {
        return null
    }

    if (typeof expanded === 'boolean') {
        return null // TODO expanded each cell
    }

    return (
        <DataRow style={{ height: '100%' }}>
            <Cell style={{ width: '100%', maxWidth: 'unset', overflow: 'unset', padding: 0 }}>{expanded({ row })}</Cell>
        </DataRow>
    )
}

const parseRowProps = (row: ReactTableRows, rowProps?: RowProps): ExtendedRowProps => {
    if (!rowProps) {
        return {} as ExtendedRowProps
    }

    if (typeof rowProps === 'function') {
        return rowProps(row) as ExtendedRowProps
    }

    return rowProps
}

const getContainerProps = (row: ReactTableRows, props?: ((row: ReactTableRows) => DivProps) | DivProps) => {
    if (!props) {
        return {}
    }

    if (typeof props === 'function') {
        return props(row)
    }

    return props
}

const RenderRow = React.memo<RenderRowProps>(
    ({ row, size, expanded, cellProps: extendedProps, rowContainerProps, rowProps }) => {
        const parsedRowProps = parseRowProps(row, rowProps)
        const parsedContainerProps = getContainerProps(row, rowContainerProps)

        return (
            <div
                {...parsedContainerProps}
                className={classNames({
                    bordered: true,
                    selected: parsedRowProps.selected,
                    expanded: row.isExpanded,
                })}
            >
                <DataRow narrow={size === 'small'} className="tr" {...parsedRowProps}>
                    {row.cells.map((cell: any) => {
                        const { style = {}, ...cellProps } = cell.getCellProps()

                        return (
                            <ReactTableCell
                                width={cell.column.width}
                                align={cell.column.align || 'left'}
                                {...cellProps}
                                style={{
                                    ...(cell.column.style || {}),
                                    ...(style || {}),
                                }}
                                className={`td ${cell.column.className || ''}`}
                                {...(extendedProps || {})}
                                key={cell.column.id}
                            >
                                {cell.render('Cell')}
                            </ReactTableCell>
                        )
                    })}
                </DataRow>
                {!!parsedRowProps?.rowFooter && parsedRowProps?.rowFooter(row)}
                <RenderExpandedRow row={row} expanded={expanded} />
            </div>
        )
    },
    (prevProps, nextProps) => {
        const rowId = prevProps.row.id
        const { selectedRowIds: prevSelected, ...prevState } = prevProps.tableState || {}
        const { selectedRowIds: nextSelected, ...nextState } = nextProps.tableState || {}

        const isRowPropsEqual = isEqual(
            parseRowProps(prevProps.row, prevProps.rowProps),
            parseRowProps(nextProps.row, nextProps.rowProps),
        )
        const isRowContainerPropsEqual = isEqual(
            getContainerProps(prevProps.row, prevProps.rowContainerProps),
            getContainerProps(nextProps.row, nextProps.rowContainerProps),
        )
        const isRowSelected = nextSelected !== undefined && (prevSelected || {})[rowId] === (nextSelected || {})[rowId]
        const isStateEqual = isEqual(prevState, nextState)
        const isColumnsEqual = isEqual(prevProps.instance.columns, nextProps.instance.columns)
        const isDataEqual = isEqual(prevProps.row.original, nextProps.row.original)

        return (
            isStateEqual &&
            isRowSelected &&
            isColumnsEqual &&
            isDataEqual &&
            isRowPropsEqual &&
            isRowContainerPropsEqual
        )
    },
)

RenderRow.displayName = 'RenderRow'
export default RenderRow
