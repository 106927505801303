import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, AnyAction } from 'redux'
import { State } from 'store'
import { User } from 'auth/state'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import PersonalSettingsHeader from './personalSettingsHeader'
import PersonalSettingsContainer from './personalSettingsContainer'
import { Profile, UpdateProfileRequest } from 'settings/schema'
import { profileLoaded } from 'settings/actions'
import { History, Location } from 'history'
import { userDataRetrieved } from 'auth/actions'
import { AccountSettingsService } from 'settings/settingsService'
import { PersonalSettingsParams, UpdatedAccountDetails } from 'settings/schema'
import { UserService } from 'auth/userService'
import { LoginService } from 'http/loginService'
import { PasswordResetService } from 'http/passwordResetService'
import { Messages } from 'uiComponents/messages'
import { withMessages, MessageProps } from 'hocs'
import { delay } from 'utils'

interface PersonalSettingsProps {
    accountSlug: string
    history: History
    profileDetails?: Profile
    profile: Profile
    user: User
    profileLoaded: typeof profileLoaded
    userDataRetrieved: typeof userDataRetrieved
    accountSettingsService: AccountSettingsService
    userService: UserService
    navigation: Navigation
    match: RouteMatch<PersonalSettingsParams>
    passwordResetService: PasswordResetService
    loginService: LoginService
    location: Location
    welcomePage: boolean
}

interface PersonalSettingsState {
    setPassSuccessful: boolean
    loading: boolean
}

class PersonalSettingsPage extends React.Component<PersonalSettingsProps & MessageProps, PersonalSettingsState> {
    constructor(props: PersonalSettingsProps & MessageProps) {
        super(props)
        this.state = {
            setPassSuccessful: false,
            loading: false,
        }
    }

    async componentDidMount() {
        const passToken = this.props.navigation.query().pass_token
        if (passToken) {
            sessionStorage.setItem('newUserPassToken', passToken)
        }
        const emailToken = this.props.navigation.query().email_token
        if (emailToken && !this.props.profile.emailConfirmed) {
            try {
                const emailConfirmed = await this.props.accountSettingsService.confirmEmail(emailToken)
                if (emailConfirmed) {
                    const updatedProfile = { ...this.props.profile }
                    updatedProfile.emailConfirmed = true
                    this.props.profileLoaded(updatedProfile)
                }
            } catch (e) {
                this.handleEmailConfirmationError(e)
            }
        }
    }

    handleEmailConfirmationError = (e: Error) => {
        if (this.props.profile.emailConfirmed) {
            this.props.history.push('/personal_settings')
        } else if (e.message === 'tokenInvalid') {
            this.props.replaceMessages(
                'email',
                'error',
                'Token is invalid, please click &quot;resend&quot; under your email address field for a new confirmation email.',
            )
        } else {
            this.props.replaceMessages(
                'email',
                'error',
                'We are unable to confirm your email at this time. Please try again later.',
            )
        }
    }

    resendEmailConfirmation = async () => {
        this.props.removeAllMessages()
        try {
            await this.props.accountSettingsService.resendConfirmEmail('')
        } catch {
            this.props.replaceMessages('unknown', 'error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    onSaveChanges = async (newDetails: UpdatedAccountDetails, password: string = '') => {
        this.setState({ loading: true })
        this.props.removeAllMessages()
        if (password) {
            await this.setPassword(password)
        }
        const { email, firstName, lastName, contactNo, companyRole } = newDetails
        if (email) {
            await this.updateEmail(email)
        }
        const profileUpdateRequest = {
            firstName,
            lastName,
            contactNo,
            companyRole,
        }
        await this.updatePersonal(profileUpdateRequest)
        const userDetails = await this.props.userService.getLoggedInUser()
        this.props.userDataRetrieved(userDetails.currentUser)
        this.props.profileLoaded(userDetails.profile)
        if (this.state.setPassSuccessful) {
            this.props.history.replace('/')
        }
    }

    setPassword = async (password: string) => {
        try {
            const passToken =
                this.props.navigation.query().pass_token || sessionStorage.getItem('newUserPassToken') || ''
            const result = await this.props.passwordResetService.consumeResetToken(passToken, password)
            if (!result) {
                this.setGeneralErrorMesssage('passTokenExpired')
                return
            }

            const loginResult = await this.props.loginService.login(this.props.user.username, password)
            if (loginResult) {
                sessionStorage.removeItem('newUserPassToken')
                this.setState({ setPassSuccessful: true })
            } else {
                this.setGeneralErrorMesssage('unknown')
            }
        } catch (e) {
            this.setGeneralErrorMesssage('unknown')
        }
    }

    updateEmail = async (email: string) => {
        try {
            await this.props.accountSettingsService.updateEmail(email, '')
            this.props.replaceMessages('success', 'success', 'Your email was updated successfully.')
        } catch (e) {
            this.setGeneralErrorMesssage(e.message)
        }
    }

    updatePersonal = async (updateRequest: UpdateProfileRequest) => {
        try {
            await this.props.accountSettingsService.updateUserProfile(updateRequest)
            this.props.replaceMessages('success', 'success', 'Your details were updated successfully.')
            this.setState({ loading: false })
            await delay(5000)
            this.props.removeAllMessages()
        } catch {
            this.setState({ loading: false })
            this.props.replaceMessages(
                'unknown',
                'error',
                'Oops! We were not able to update your personal details. Please try again later.',
            )
        }
    }

    setGeneralErrorMesssage = (errorMessage: string) => {
        if (errorMessage === 'passTokenExpired') {
            this.props.replaceMessages('pass_token', 'error', 'The link you were given has expired.')
        } else if (errorMessage === 'emailRegistered') {
            this.props.replaceMessages('email', 'error', 'Email provided is already registered.')
        } else {
            this.props.replaceMessages('unknown', 'error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    render() {
        const { user, profile, userService, history, accountSettingsService, location, welcomePage, accountSlug } =
            this.props
        const profileDetails = profile
            ? profile
            : {
                  firstName: '',
                  lastName: '',
                  avatarUrl: '',
                  email: '',
                  contactNo: '',
                  company: { name: '', role: '', industry: '', country: '', url: '' },
                  welcomeSeen: false,
                  tourSeen: true,
                  requiresPasswordChange: false,
                  signUp: null,
                  emailConfirmed: false,
              }
        const username = profileDetails.firstName || user.name || user.username

        return (
            <>
                <Messages messages={this.props.messages} hideMessage={this.props.hideMessage} />
                <PersonalSettingsHeader
                    username={username}
                    avatarUrl={profileDetails.avatarUrl}
                    userService={userService}
                    accountSettingsService={accountSettingsService}
                />
                <PersonalSettingsContainer
                    accountSlug={accountSlug}
                    user={user}
                    accountSettingsService={accountSettingsService}
                    profile={profileDetails}
                    onSave={this.onSaveChanges}
                    history={history}
                    resendEmail={this.resendEmailConfirmation}
                    location={location}
                    welcomePage={welcomePage}
                    loading={this.state.loading}
                    replaceMessages={this.props.replaceMessages}
                    removeAllMessages={this.props.removeAllMessages}
                />
            </>
        )
    }
}

const mapStateToProps = (state: State) => {
    return {
        user: state.auth.user,
        profile: state.profile.profile,
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators(
        {
            profileLoaded,
            userDataRetrieved,
        },
        dispatch,
    )
}

export default withMessages(withNavigation(connect(mapStateToProps, mapDispatchToProps)(PersonalSettingsPage)))
