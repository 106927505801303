import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import ConnectedLayout from 'uiComponents/appLayouts/headerSidebar'
import { PageLoader } from 'uiComponents/loaders'
import AccountRoutes from './account'
import UserRoutes from './user'

const SettingsRoutes = () => {
    return (
        <ConnectedLayout>
            <Suspense fallback={<PageLoader />}>
                <Switch>
                    <Route path="/account/:accountSlug/settings/account_settings" component={AccountRoutes} />
                    <Route path="/account/:accountSlug/settings/users" component={UserRoutes} />
                    <Route render={() => <Redirect to="account_settings" />} />
                </Switch>
            </Suspense>
        </ConnectedLayout>
    )
}

export default SettingsRoutes
