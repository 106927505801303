import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useHasFeature } from '../../../features'
import { BaseRouteParams } from '../../../hocs'

interface AudiencesNavigationSection {
    path: string
    active: boolean
    shouldShow: boolean
    tabTitle: string
}

export const useAudienceNavigation = () => {
    const { pathname, search } = useLocation()
    const { accountSlug } = useParams<BaseRouteParams>()
    const hasAccessToPredefinedAudiences = useHasFeature('CRMAudiencesPage')

    return useMemo(() => {
        const sections = [] as AudiencesNavigationSection[]

        if (hasAccessToPredefinedAudiences) {
            sections.push({
                path: `/account/${accountSlug}/crm/audiences/list`,
                active: pathname.includes('/list'),
                shouldShow: true,
                tabTitle: 'Predefined',
            })
        }

        return sections.filter((section) => section.shouldShow)
    }, [accountSlug, pathname, search])
}
