import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { History, Location } from 'history'
import { Container, ContainerHeading, FullWidthContainerBody, HeadingSectionName } from 'uiComponents/settingsContainer'
import { Headline } from 'uiComponents/typography'
import { withMessages, MessageProps } from 'hocs'
import { Messages } from 'uiComponents/messages'
import { withPermission } from 'admin/hocs'
import { withFeatures } from 'features'
import { OpeningTimesListPage } from './list'
import OpeningTimesForm from './form'
import OverrideForm from './exceptionsForm'

interface OpeningTimesPageProps {
    history: History
    accountSlug: string
    location: Location
    hasPermission: (permission: string, accountSlug: string) => boolean
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function OpeningTimesPage(props: OpeningTimesPageProps & MessageProps) {
    const [header, setHeader] = useState<string>('Opening Times Configuration')

    const setActiveHeader = (headerText: string) => {
        setHeader(headerText)
    }

    return (
        <div style={{ position: 'relative' }} id="opening-hours">
            <Messages messages={props.messages} hideMessage={props.hideMessage} />
            <Headline size={1} style={{ marginBottom: '1.5em' }}>
                {header}
            </Headline>
            <Container>
                <ContainerHeading>
                    <HeadingSectionName
                        className="active"
                        to={`/account/${props.accountSlug}/venue/opening_times/locations`}
                    >
                        Locations
                    </HeadingSectionName>
                </ContainerHeading>
                <Switch location={props.location}>
                    <Route
                        path="/account/:accountSlug/venue/opening_times/locations"
                        render={() => (
                            <FullWidthContainerBody>
                                <OpeningTimesListPage
                                    history={props.history}
                                    setActiveHeader={setActiveHeader}
                                    replaceTopMessages={props.replaceMessages}
                                    hasPermission={props.hasPermission}
                                    hasFeature={props.hasFeature}
                                    accountSlug={props.accountSlug}
                                />
                            </FullWidthContainerBody>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/venue/opening_times/default/:id"
                        exact
                        render={() => (
                            <OpeningTimesForm
                                history={props.history}
                                accountSlug={props.accountSlug}
                                setActiveHeader={setActiveHeader}
                                replaceMessages={props.replaceMessages}
                                removeAllMessages={props.removeAllMessages}
                            />
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/venue/opening_times/exception/:id/default/:openingTimesId/"
                        render={() => (
                            <OverrideForm
                                history={props.history}
                                accountSlug={props.accountSlug}
                                setActiveHeader={setActiveHeader}
                                replaceMessages={props.replaceMessages}
                                removeAllMessages={props.removeAllMessages}
                            />
                        )}
                    />
                    <Route
                        render={() => <Redirect to={`/account/${props.accountSlug}/venue/opening_times/locations`} />}
                    />
                </Switch>
            </Container>
        </div>
    )
}

export default withFeatures(withMessages(withPermission(OpeningTimesPage)))
