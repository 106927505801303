import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Alert, AlertTitle, Button } from '@mui/material'

interface Props {
    accountSlug: string
}

const RtpProductsWarning: FC<Props> = ({ accountSlug }) => {
    const history = useHistory()

    const goToProducts = () => {
        history.push(`/account/${accountSlug}/products/crud/new`)
    }

    return (
        <Alert severity="warning">
            <AlertTitle>Real Time Pricing (RTP) has not been configured for any products.</AlertTitle>
            In order to enable Dynamic Pricing, you need to set up at least one product with Real Time Pricing as its
            pricing type. &nbsp;
            <Button size="small" variant="contained" onClick={goToProducts}>
                Setup RTP product
            </Button>
        </Alert>
    )
}

export default RtpProductsWarning
