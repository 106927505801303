import cloneDeep from 'lodash/cloneDeep'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SingleSelectOption } from 'uiComponents/input'
import { BaseRouteParams } from '../../../hocs'
import { useVenueLocations } from '../../../tapPlacements/studio/helpers'
import CommonFilter from '../components/filters/commonFilter'
import LocationFilter from '../components/filters/locationFilter'
import NameFilter from '../components/filters/nameFilter'
import AudienceOperator from '../components/operator'
import FilterDateRange from '../components/rules/dateRange'
import RuleOutcome from '../components/rules/outcomeElement'
import ProductSelection from '../components/rules/productsSelection'

import FilterQuantity from '../components/rules/quantity'
import FilterRadio, { BasicRadioOptions } from '../components/rules/radio'
import { parseJsonValues, removeNullValues } from '../helpers'
import { ICustomAudience, ICustomAudienceStatus, IFilter, IOption, IRule, OperatorTypes } from '../types'
import useAudienceActions from './useAudienceActions'

export enum FilterTypes {
    LOCATION = 1,
    PRODUCT = 4,
    QUANTITY = 5,
    DATE_RANGE = 3,
}

interface IUseAudienceBuilder {
    filters: IFilter[]
    data: ICustomAudience
}

const useAudienceBuilder = ({ filters, data }: IUseAudienceBuilder) => {
    const [customAudience, setCustomAudience] = useState<ICustomAudience>(parseJsonValues(data))
    const [activeFilter, setActiveFilter] = useState<number | null>(null)
    const [activeField, setActiveField] = useState<number | null>(null)
    const [name, setName] = useState<string>(data.name)
    const [location, setLocation] = useState<IOption | null>(null)
    const { updateCA, createCA, isLoading } = useAudienceActions()
    const { accountSlug } = useParams<BaseRouteParams>()
    const venueLocations = useVenueLocations(accountSlug)

    useEffect(() => {
        if (venueLocations.length === 1) {
            const locationObj = venueLocations[0] as IOption
            setLocation(locationObj)
            setValueForField(FilterTypes.LOCATION, 1, locationObj)
        }
    }, [venueLocations])

    const setActive = (filterOrder: number, ruleId: number) => {
        setActiveFilter(filterOrder)
        setActiveField(ruleId)
    }

    const clearActive = () => {
        setActiveFilter(null)
        setActiveField(null)
    }

    const clearFieldValue = (filterId: number, ruleId: number) => {
        const newAudience = { ...customAudience }
        newAudience.filters.forEach((filter, filterIndex) => {
            if (filter.filter_order === filterId) {
                filter.rules.forEach((rule, ruleIndex) => {
                    if (rule.rule_id === ruleId) {
                        switch (rule.rule_id) {
                            case FilterTypes.DATE_RANGE:
                                rule.value = { type: 'custom', value: { start: '', end: '' } }
                                break
                            case FilterTypes.QUANTITY:
                                rule.value = { operator: undefined, value: 0 }
                                break
                            default:
                                rule.value = null
                                break
                        }
                    }
                })
            }
        })
        setCustomAudience(newAudience)
    }

    const onNameChange = (name: string) => {
        setName(name)
        const newAudience = { ...customAudience }
        newAudience.name = name
        setCustomAudience(newAudience)
    }

    const onLocationChange = (location: string) => {
        const locationValue = { value: location } as IOption
        setLocation(locationValue)
        setValueForField(FilterTypes.LOCATION, 1, locationValue)
    }

    const renderField = (rule: IRule, filterOrder?: number) => {
        if (!filterOrder || rule.options.length === 0) {
            return
        }

        return (
            <RuleOutcome
                key={`filter${filterOrder}${rule.rule_id}`}
                rule={rule}
                onClick={() => {
                    setActive(filterOrder, rule.rule_id)
                }}
                onClear={() => {
                    clearActive()
                    clearFieldValue(filterOrder, rule.rule_id)
                }}
                isActive={activeFilter === filterOrder && activeField === rule.rule_id}
            />
        )
    }
    const renderName = () => {
        return <NameFilter value={name} onChange={onNameChange} />
    }

    const renderLocationFilter = useCallback(() => {
        if (venueLocations.length < 2) {
            return
        }

        const locations: SingleSelectOption[] = []
        venueLocations.forEach((location) => {
            locations.push({ value: location.value, name: location.name })
        })

        return <LocationFilter value={location} locations={locations} onSelect={onLocationChange} />
    }, [venueLocations, customAudience, location])

    const renderFilter = (filter: IFilter) => {
        return (
            <CommonFilter
                title={filter.name}
                description={filter.description}
                filterId={filter.filter_order}
                onRemove={removeFilter}
            >
                {filter.rules.map((rule: IRule, index) => renderField(rule, filter.filter_order))}
            </CommonFilter>
        )
    }
    const renderCondition = (filter: IFilter) => {
        return (
            <AudienceOperator
                operator={filter.operator}
                onSelect={(value) => {
                    setOperatorForFilter(filter.filter_order, value)
                }}
            />
        )
    }

    const renderFilters = useCallback(() => {
        return (
            <>
                {renderName()}
                {customAudience.filters.map((filter: IFilter, index: number) => {
                    return (
                        <div key={`filter${index}`}>
                            {index > (customAudience.filters[0].filter_id === FilterTypes.LOCATION ? 1 : 0)
                                ? renderCondition(filter)
                                : null}
                            {filter.filter_id === FilterTypes.LOCATION ? renderLocationFilter() : renderFilter(filter)}
                        </div>
                    )
                })}
            </>
        )
    }, [customAudience, activeFilter, activeField, venueLocations])

    const setValueForField = useCallback(
        (filter_order: number | undefined, rule_id: number, value: any) => {
            if (filter_order) {
                const updatedAudience = { ...customAudience }

                updatedAudience.filters.map((filter: IFilter) => {
                    if (filter.filter_order === filter_order) {
                        filter.rules.map((rule: IRule) => {
                            if (rule.rule_id === rule_id) {
                                rule.value = value
                            }
                        })
                    }
                })

                setCustomAudience(updatedAudience)
            }
        },
        [customAudience],
    )

    const setOperatorForFilter = useCallback(
        (filter_order: number | undefined, operator: OperatorTypes) => {
            if (filter_order) {
                const filter = customAudience.filters.find((filter: IFilter) => filter.filter_order === filter_order)

                if (filter) {
                    filter.operator = operator
                    setCustomAudience({ ...customAudience })
                }
            }
        },
        [customAudience],
    )

    const resetFieldsValues = (rules: IRule[]) => {
        rules.forEach((rule: IRule) => {
            switch (rule.rule_id) {
                case FilterTypes.QUANTITY:
                    rule.value = {
                        operator: undefined,
                        value: 0,
                    }
                    break
                case FilterTypes.DATE_RANGE:
                    rule.value = {
                        type: 'preset',
                        value: null,
                    }
                    break
                default:
                    rule.value = null
                    break
            }
        })
    }

    const renderOptions = useCallback(() => {
        if (activeFilter && activeField) {
            const filter = customAudience.filters.find((filter: IFilter) => filter.filter_order === activeFilter)
            const rule = filter?.rules.find((rule: IRule) => rule.rule_id === activeField)

            if (filter && rule) {
                if (rule.rule_id === FilterTypes.DATE_RANGE) {
                    return (
                        <FilterDateRange
                            key={`radio${filter.filter_order}${rule.rule_id}`}
                            label="Select Date range"
                            options={rule.options}
                            value={rule.value as IOption}
                            onChange={(value) => setValueForField(filter.filter_order, rule.rule_id, value)}
                        />
                    )
                }

                if (rule.rule_id === FilterTypes.QUANTITY) {
                    return (
                        <FilterQuantity
                            key={`quantity${filter.filter_order}${rule.rule_id}`}
                            label="Select Quantity options"
                            value={rule.value as IOption}
                            options={rule.options}
                            onChange={(value) => setValueForField(filter.filter_order, rule.rule_id, value)}
                        />
                    )
                }

                if (rule.rule_id === FilterTypes.PRODUCT) {
                    return (
                        <ProductSelection
                            label="Select product"
                            options={rule.options}
                            value={rule.value !== null ? rule.value : { products: [], product_path_list: [] }}
                            onChange={(value) => setValueForField(filter.filter_order, rule.rule_id, value)}
                        />
                    )
                }

                return (
                    <FilterRadio
                        key={`radio${filter.filter_order}${rule.rule_id}`}
                        label="Select options"
                        options={rule.options as BasicRadioOptions[]}
                        value={rule.value}
                        onChange={(value) => setValueForField(filter.filter_order, rule.rule_id, value)}
                    />
                )
            }
        }

        return <></>
    }, [activeFilter, activeField, customAudience])

    const getMaxFilterOrder = (audience: ICustomAudience) => {
        let maxFilterOrder = 0
        audience.filters.forEach((filter, filterIndex) => {
            if (filter.filter_order) {
                maxFilterOrder = filter.filter_order
            }
        })
        return maxFilterOrder
    }

    const addFilter = useCallback(
        (value: string) => {
            const filter = filters.find((filter: IFilter) => filter.name === value)

            if (filter) {
                const newFilter = cloneDeep(filter)
                newFilter.filter_order = getMaxFilterOrder(customAudience) + 1
                resetFieldsValues(newFilter.rules)
                const updatedAudience = { ...customAudience, filters: [...customAudience.filters, newFilter] }
                setCustomAudience(updatedAudience)
            }
        },
        [customAudience],
    )
    const removeFilter = useCallback(
        (filterOrder: number) => {
            const filters = customAudience.filters.filter((filter: IFilter) => filter.filter_order !== filterOrder)
            setCustomAudience({ ...customAudience, filters })
        },
        [customAudience],
    )

    const save = useCallback(
        async (status: ICustomAudienceStatus) => {
            const audience = removeNullValues({ ...customAudience, status })

            if (customAudience.id) {
                updateCA(audience)
            } else {
                createCA(audience)
            }
        },
        [customAudience],
    )

    return {
        customAudience,
        renderFilters,
        renderOptions,
        addFilter,
        removeFilter,
        save,
        isLoading,
    }
}

export default useAudienceBuilder
