import React from 'react'
import { InlineDataTable, HeaderRow, TableHeader } from 'uiComponents/table'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { NestedContainer, NestedElement } from 'products/components/nestedContainer'
import { History } from 'history'
import { Navigation } from 'navigation'
import { withPermission } from 'admin/hocs'

interface ListTableProps {
    hasPermission: (permission: string, accountSlug: string) => boolean
    history: History
    navigation: Navigation
    elements: NestedElement[]
    accountSlug: string
    flat?: boolean
}

function ProductListsTable(props: ListTableProps) {
    const columnWidths = ['3.5em', null, '15em', '15em']

    const { elements, flat, history, accountSlug } = props
    return (
        <>
            <InlineDataTable columnWidths={columnWidths} id="products-list-page" bordered={!flat}>
                <HeaderRow>
                    <TableHeader nonInteractive />
                    <TableHeader>Name</TableHeader>
                    <TableHeader>Type</TableHeader>
                    <TableHeader>Product ID</TableHeader>
                </HeaderRow>
                {elements.length > 0 && (
                    <NestedContainer
                        containerType="productIds"
                        expanded={false}
                        elements={elements}
                        accountSlug={accountSlug}
                        hasPermission={props.hasPermission}
                        grade={0}
                        flat={flat}
                        history={history}
                        accountCategories={[]}
                        navigation={props.navigation}
                    />
                )}
                {elements.length === 0 && <NoResultsRow text="Neither categories nor products were found." />}
            </InlineDataTable>
        </>
    )
}

export default withPermission(ProductListsTable)
