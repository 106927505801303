import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { FormWrapper } from 'uiComponents/form/form'
import SingleSelectFieldFormik from 'uiComponents/input/singleSelect/singleSelectFieldFormik'
import TextInputFieldFormik from 'uiComponents/input/textInput/textInputFieldFormik'
import * as yup from 'yup'
import { BaseRouteParams } from '../../hocs'
import { useVenueLocations } from '../../tapPlacements/studio/helpers'
import { ActionButton, ActionButtonA } from '../../uiComponents/buttons'
import ProgressBar from '../components/progressBar'
import { LeftContainer } from './content'

export const Container = styled.div`
    margin-bottom: 3em;
`

const Field = styled.div`
    margin-bottom: 2em;
`

export const Buttons = styled.div`
    button {
        margin-left: 4px;
    }
`

export const ProgressBarContainer = styled.div`
    width: 66%;
`

export const ProgressWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.8em;
    margin-bottom: 2.2em;
`

interface FormProps {
    data?: any
    onSubmit: (values: any) => void
}

const TargetForm = ({ data, onSubmit }: FormProps) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const venueLocations = useVenueLocations(accountSlug)
    const history = useHistory()

    const progressBarItems = [
        {
            name: 'Target audience',
            active: true,
        },
        {
            name: 'Edit content',
        },
        {
            name: 'Schedule and publish',
        },
    ]

    const audiences = [
        {
            value: '0',
            name: 'All clients',
        },
        {
            value: '3d4ed7449dc14f9b9b773751422ab8bb',
            name: 'Clients who bought tickets in last year',
        },
    ]

    const validationSchema = yup.object().shape({
        name: yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Required'),
        segment_id: yup.string().required('Required'),
        location_id: venueLocations.length > 1 ? yup.string().required('Required') : yup.string(),
    })

    return (
        <Container>
            <FormWrapper
                enableReinitialize
                initialValues={data}
                onSubmit={(values) => {
                    onSubmit({
                        ...values,
                        location_id: values.location_id || venueLocations[0].value,
                    })
                    history.push(`/account/${accountSlug}/engage/push_notifications/content`)
                }}
                validationSchema={validationSchema}
            >
                <LeftContainer>
                    <ProgressWrapper>
                        <ProgressBarContainer>
                            <ProgressBar items={progressBarItems} />
                        </ProgressBarContainer>
                        <Buttons>
                            <ActionButtonA
                                size="large"
                                secondary
                                href={`/account/${accountSlug}/engage/push_notifications`}
                            >
                                Back
                            </ActionButtonA>
                            <ActionButton size="large" type="submit">
                                Next
                            </ActionButton>
                        </Buttons>
                    </ProgressWrapper>
                    <Field>
                        <TextInputFieldFormik
                            id="name"
                            name="name"
                            label="Campaign name"
                            placeholder="Type campaign name..."
                        />
                    </Field>
                    {audiences.length > 1 && (
                        <Field>
                            <SingleSelectFieldFormik
                                name="segment_id"
                                options={audiences}
                                noSelectOption="Select audience"
                                label="Campaign audience"
                            />
                        </Field>
                    )}
                    {venueLocations.length > 1 && (
                        <Field>
                            <SingleSelectFieldFormik
                                name="location_id"
                                id="location_id"
                                options={venueLocations}
                                noSelectOption="Select venue location"
                                label="Venue location"
                            />
                        </Field>
                    )}
                    {/* <AudienceEstimate />*/}
                </LeftContainer>
            </FormWrapper>
        </Container>
    )
}

export default TargetForm
