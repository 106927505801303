import { useGetPartnerFundLogQuery } from 'admin/reduxQuery'
import { PartnerFundParams } from 'admin/types'
import React, { useMemo } from 'react'
import styled from 'styled-typed'
import { DateFormatter } from 'uiComponents/date'
import Currency from 'uiComponents/money/currency'
import { ExtensibleReactTableComponentProps, ReactTable, TableColumn } from 'uiComponents/table/reactTable'
import TooltipWrapper from '../../uiComponents/tooltip/tooltipWrapper'
import { DateFormats } from 'utils/dates'

const initialData = [
    {
        action: 'test',
    },
    {
        action: 'test3',
    },
] as any

const TableWrapperStyles = styled.div`
    padding: 0.5rem 0 1rem 5.75rem;
    background-color: ${(props) => props.theme.colors.tableRow};
    width: 100%;

    & .tr {
        border-top: unset !important;
        height: 2rem !important;
    }

    &.bordered {
        background-color: red;
    }

    & .header {
        border-radius: 4px;
    }
`

const FundLog: React.FC<ExtensibleReactTableComponentProps<PartnerFundParams>> = ({
    row: {
        original: { accountSlug },
    },
}) => {
    const { data, isFetching } = useGetPartnerFundLogQuery({ accountSlug })
    const columns = useMemo(() => {
        return [
            {
                Header: 'Action',
                accessor: 'transactionType',
                width: '10.25rem',
            },
            {
                Header: 'Amount',
                accessor: 'amountValue',
                width: '7.5rem',
                Cell: ({ value, row: { original } }) => <Currency amount={value} currency={original.currencyCode} />,
            },
            {
                Header: 'Request time',
                accessor: 'createdAt',
                width: '11.25rem',
                Cell: ({ value }) => (
                    <DateFormatter
                        value={value}
                        fallback="-"
                        format={`${DateFormats.LONG_DATE}, ${DateFormats.LONG_TIME}`}
                    />
                ),
            },
            {
                Header: 'Transfer time',
                accessor: 'transferTime',
                width: '11.25rem',
                Cell: ({ value }) => (
                    <DateFormatter
                        value={value}
                        fallback="-"
                        format={`${DateFormats.LONG_DATE}, ${DateFormats.LONG_TIME}`}
                    />
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
                width: '8.75rem',
            },
            {
                Header: 'Description',
                accessor: 'capturedPaymentReference',
                Cell: ({ value }) => <TooltipWrapper text={value}>{value}</TooltipWrapper>,
            },
        ] as TableColumn<any>[]
    }, [])

    return (
        <TableWrapperStyles>
            <div>
                <ReactTable
                    size="small"
                    rowContainerProps={() => ({
                        style: {
                            marginTop: '0.5rem',
                            border: '1px solid #D1D8DF',
                            background: 'unset',
                        },
                    })}
                    rowProps={() => ({
                        style: {
                            height: '2rem',
                            lineHeight: '1rem',
                        },
                    })}
                    storeStateInQuery={false}
                    elevation={false}
                    loading={isFetching}
                    data={data ?? initialData}
                    columns={columns}
                    noResultsRow
                />
            </div>
        </TableWrapperStyles>
    )
}

export default FundLog
