import * as React from 'react'
import Filter from 'uiComponents/filter'
import { MetaData, FilterCategory } from 'uiComponents/filter/schema'
import { MessageKind } from 'uiComponents/messages'
import { DateRange } from 'dateRanges'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { deviceTypes } from 'engageTools/studio/shared'
import { connect } from 'react-redux'
import { State } from 'store'
import { crmSelectors } from 'crm/selectors'

export const FILTERS: FilterCategory[] = ['tool_status', 'device_type', 'audience']

interface FiltersProps extends ReturnType<typeof mapStateToProps> {
    navigation: Navigation
    match: RouteMatch<any>
    accountSlug: string
    applicableFilters: FilterCategory[]
    standaloneFilters: FilterCategory[]
    dateRange: DateRange
    includeTap?: boolean
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    hideMessage: (id: string) => void
}

interface FiltersState {
    metaData: MetaData[]
    loading: boolean
}

class Filters extends React.Component<FiltersProps, FiltersState> {
    constructor(props: FiltersProps) {
        super(props)
        this.state = {
            metaData: [],
            loading: false,
        }
    }

    async componentDidMount() {
        this.getMetaData()
    }

    async componentDidUpdate(prevProps: FiltersProps) {
        if (prevProps.accountSlug !== this.props.accountSlug) {
            this.props.navigation.addQueryWithReplace({ filter: null })
        }
    }

    generateToolStatusOptions = () => {
        return ['published', 'unpublished', 'draft', 'upcoming', 'expired'].map((status) => {
            return {
                name: status.charAt(0).toUpperCase() + status.slice(1),
                slug: status,
                category: 'tool_status' as FilterCategory,
                children: [],
                parents: [],
                leafNode: true,
            }
        })
    }

    generateDeviceTypeOptions = () => {
        return deviceTypes.map((device) => {
            return {
                name: device.charAt(0).toUpperCase() + device.slice(1),
                slug: device,
                category: 'device_type' as FilterCategory,
                children: [],
                parents: [],
                leafNode: true,
            }
        })
    }

    generateAudienceOptions = () => {
        return this.props.audiences.map((a) => ({
            name: a.name,
            slug: a.slug,
            category: 'audience' as FilterCategory,
            children: [],
            parents: [],
            leafNode: true,
        }))
    }

    getMetaData = () => {
        const metaData = [
            {
                category: 'device_type' as FilterCategory,
                options: this.generateDeviceTypeOptions(),
            },
            {
                category: 'tool_status' as FilterCategory,
                options: this.generateToolStatusOptions(),
            },
            {
                category: 'audience' as FilterCategory,
                options: this.generateAudienceOptions(),
            },
        ]
        this.setState({ metaData: metaData })
    }

    render() {
        return (
            <Filter
                accountSlug={this.props.accountSlug}
                metaData={this.state.metaData}
                applicableFilters={this.props.applicableFilters}
                standaloneFilters={this.props.standaloneFilters}
                singleSelectCategories={[]}
                loading={this.state.loading}
            />
        )
    }
}

const mapStateToProps = (state: State) => ({
    audiences: crmSelectors.getAudiences(state),
})

export default connect(mapStateToProps)(withNavigation(Filters))
