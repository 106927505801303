import * as React from 'react'
import { usePrevious } from 'reactUtils'
import styled from 'styled-typed'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation, QueryData } from 'navigation'

const SelectorWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
const PresetOption = styled.div`
    font-size: 0.875rem;
    height: 2.2rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${(p) => p.theme.colors.textLight};
    border: 1px solid transparent;
    cursor: pointer;
    padding: 0 0.8em;

    &.selected {
        color: ${(p) => p.theme.colors.textDark};
        background: ${(p) => p.theme.colors.white};
        border: 1px solid ${(props) => props.theme.colors.border};
        border-radius: 6px;
    }
`

export type PresetType =
    | 'today'
    | 'yesterday'
    | 'thisWeek'
    | 'thisMonth'
    | 'weekdays'
    | 'weekends'
    | '7days'
    | '4weeks'
    | 'last7days'
    | 'last4weeks'
    | 'last30days'
    | 'last90days'
    | 'lifetime'
    | 'revenue'
    | 'transactions'
    | 'products_sold'
    | 'order_value'
    | 'cart_size'
    | 'drop_rates'
    | 'back_rates'

interface PresetOptions {
    value: PresetType
    name: string
}

const allPresetOptions: PresetOptions[] = [
    { value: 'today', name: 'Today' },
    { value: 'yesterday', name: 'Yesterday' },
    { value: 'thisWeek', name: 'This week' },
    { value: 'thisMonth', name: 'This month' },
    { value: 'weekdays', name: 'Weekdays' },
    { value: 'weekends', name: 'Weekends' },
    { value: 'last7days', name: 'Last 7 days' },
    { value: 'last4weeks', name: 'Last 4 weeks' },
    { value: 'last30days', name: 'Last 30 days' },
    { value: 'last90days', name: 'Last 90 days' },
    { value: 'lifetime', name: 'Lifetime' },
    { value: '7days', name: '7 days' },
    { value: '4weeks', name: '4 weeks' },
    { value: 'revenue', name: 'Revenue' },
    { value: 'transactions', name: 'Transactions' },
    { value: 'products_sold', name: 'Products sold' },
    { value: 'order_value', name: 'Order value' },
    { value: 'cart_size', name: 'Cart size' },
    { value: 'drop_rates', name: 'Exit rate' },
    { value: 'back_rates', name: 'Backwards rate' },
]

interface PresetsElementProps {
    presetName: string
    default: PresetType
    options: PresetType[]
    navigation: Navigation
    match: RouteMatch<{}>
    onChange?: (v: string) => void
    style?: React.CSSProperties
}

function PresetsElement(props: PresetsElementProps) {
    const [value, setValue] = React.useState<string>(props.default)

    const prevQuery: QueryData = usePrevious(props.navigation.query()) || {}
    React.useEffect(() => {
        const newQuery = props.navigation.query()
        if (newQuery[props.presetName] !== value && newQuery[props.presetName] !== prevQuery[props.presetName]) {
            setValue(newQuery[props.presetName])
        }
    }, [props.navigation.query()])

    const onChange = (v: string) => {
        setValue(v)
        props.navigation.addQueryWithReplace({ [props.presetName]: v })
        if (props.onChange) {
            props.onChange(v)
        }
    }

    const options = allPresetOptions.filter((o) => props.options.indexOf(o.value) > -1)

    return (
        <SelectorWrapper onClick={(ev) => ev.stopPropagation()} style={props.style}>
            {options.map((o) => (
                <PresetOption
                    key={o.value}
                    className={value === o.value ? 'selected' : ''}
                    onClick={() => onChange(o.value)}
                    data-userpilot={o.value}
                >
                    {o.name}
                </PresetOption>
            ))}
        </SelectorWrapper>
    )
}

export default withNavigation(PresetsElement)
