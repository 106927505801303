import React from 'react'
import styled from 'styled-typed'
import { Headline, Body } from 'uiComponents/typography'
import { ContentSection } from 'uiComponents/pageElements'
import { Toggle } from 'uiComponents/input/toggle'

const TextWithToggle = styled(Body)`
    display: flex;
    align-items: center;
`

interface ComponentProps {
    barcodeAlertsOn: boolean
    toggleBarcodeAlerts: () => void
}

export function BarcodeAlerts(props: ComponentProps) {
    return (
        <ContentSection style={{ marginTop: '1.5rem' }}>
            <Headline size={4}>Barcode pool levels alerts</Headline>
            <TextWithToggle size={1}>
                Receive an email when barcode numbers are low
                <Toggle
                    isOn={props.barcodeAlertsOn}
                    onClick={props.toggleBarcodeAlerts}
                    className="smallInlineToggle"
                    style={{ marginLeft: '1rem' }}
                />
            </TextWithToggle>
        </ContentSection>
    )
}
