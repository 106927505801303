import { createContext } from 'react'

import { ReservationCodesPool } from 'venue/bookingCodes/bookingCodesService'

interface EditPoolDialogContextProps {
    toggle(): void
    selectCampaign(campaign: ReservationCodesPool): void
}

export const EditPoolDialogContext = createContext<EditPoolDialogContextProps>({} as EditPoolDialogContextProps)
