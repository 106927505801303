import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { replaceMessage } from 'uiComponents/messages/actions'
import { BaseRouteParams } from '../../../hocs'
import { useSelectedParkMapContext } from '../context/selectedParkMap'
import { Parkmap } from '../models'
import { useCreateParkMapMutation } from '../reduxQuery'
import { useParkMapViewerContext } from '../context/mapViewer'

const useCreateMap = () => {
    const [createParkMap, { isLoading }] = useCreateParkMapMutation()
    const { coords, zoom } = useParkMapViewerContext()
    const dispatch = useDispatch()
    const { accountSlug } = useParams<BaseRouteParams>()
    const history = useHistory()
    const { setSelectedParkMap } = useSelectedParkMapContext()

    const createMap = useCallback(
        async (parkmap: Parkmap) => {
            try {
                const response: any = await createParkMap({ parkMap: parkmap })

                if (response.data) {
                    setSelectedParkMap(response.data)
                    history.push(`/account/${accountSlug}/venue/park_map/${response.data.id}/details`)
                    dispatch(replaceMessage('create_park_map', 'success', 'Map created', 5000))
                } else {
                    dispatch(
                        replaceMessage('create_park_map', 'error', 'Ups. Something went wrong, please try again', 5000),
                    )
                }
            } catch (e) {
                dispatch(replaceMessage('create_park_map', 'error', e.message, 5000))
            }
        },
        [zoom, coords],
    )

    return { isLoading, createMap }
}

export default useCreateMap
