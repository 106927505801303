import { BaseRouteParams } from 'hocs'
import { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useHasFeature } from '../../../features'

const useIsPartnerAllowed = () => {
    const { pathname } = useLocation()
    const { accountSlug } = useParams<BaseRouteParams>()
    const hasAccessToPredefinedAudiences = useHasFeature('CRMAudiencesPage')
    const hasAccessToCustomAudiences = useHasFeature('CRMAudiencesCustom')
    const history = useHistory()

    useEffect(() => {
        if (!hasAccessToPredefinedAudiences && !hasAccessToCustomAudiences) {
            history.push(`/account/${accountSlug}/crm/audiences/welcome`)
        }

        if (hasAccessToPredefinedAudiences && pathname.includes('/list')) {
            return
        }

        if (!hasAccessToCustomAudiences) {
            history.push(`/account/${accountSlug}/crm/audiences/welcome`)
        }

        if (!hasAccessToPredefinedAudiences && !pathname.includes('/custom')) {
            history.push(`/account/${accountSlug}/crm/audiences/custom`)
        }
    }, [])
}

export default useIsPartnerAllowed
