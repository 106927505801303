import * as React from 'react'
import ReactEcharts from 'echarts-for-react'
import { withTheme } from 'styled-typed'
import { DashboardTheme } from 'theme'
import { NoDataNotice, tooltipStyle } from 'uiComponents/charts/styleComponents'
import isEqual from 'lodash/isEqual'
import { TooltipParams } from './timeseriesBarChart'

interface ChartData {
    name: string
    value: number
}

export interface BarChartSeries {
    name: string
    type: string
    barWidth?: string
    barGap?: number
    data: ChartData[]
}

interface BarChartProps {
    loading: boolean
    title?: string
    series: BarChartSeries[]
    theme: DashboardTheme
    yAxisName?: string
    xAxisInterval?: number | string
    rotateNames?: boolean
    percent?: boolean
    chartHeight?: string
    gridTop?: number
    gridBottom?: number
    gridLeft?: number
    gridRight?: number
    tooltipFormatter?: (params: TooltipParams[]) => string
    xAxisFormatter?: (value: string, index: number) => string
}

interface BarChartState {
    chartOption: any
}

class BarChart extends React.Component<BarChartProps & React.HTMLAttributes<HTMLElement>, BarChartState> {
    constructor(props: BarChartProps & React.HTMLAttributes<HTMLElement>) {
        super(props)
        this.state = {
            chartOption: {},
        }
    }

    fetchNamesFromData = () => {
        return this.props.series[0].data.map((d) => d.name)
    }

    formatLegend = () => {
        return this.props.series.length > 1 ? this.props.series.map((d) => d.name) : []
    }

    loadBarChartData = () => {
        const barChartOption = {
            textStyle: {
                fontFamily: 'Roboto',
            },
            title: {
                text: this.props.title || '',
                textStyle: {
                    fontSize: 20,
                    fontWeight: 'normal',
                },
                left: 'left',
                padding: [10, 0, 0, 0],
            },
            tooltip: {
                formatter: this.props.tooltipFormatter
                    ? this.props.tooltipFormatter
                    : this.props.series.length > 1
                    ? ''
                    : this.props.percent
                    ? '{b}: {c}%'
                    : '{b}: {c}',
                trigger: 'axis',
                axisPointer: {
                    type: 'none',
                },
                padding: [5, 10],
                ...tooltipStyle,
            },
            xAxis: {
                data: this.fetchNamesFromData(),
                axisLabel: {
                    rotate: this.props.rotateNames ? 50 : 0,
                    interval: this.props.xAxisInterval ? this.props.xAxisInterval : 0,
                    color: '#A4AFB2',
                    formatter: this.props.xAxisFormatter ? this.props.xAxisFormatter : undefined,
                },
                axisLine: { show: false },
                axisTick: { show: false },
            },
            yAxis: [
                {
                    name: this.props.yAxisName,
                    nameTextStyle: {
                        fontSize: 14,
                        color: '#A4AFB2',
                        align: 'center',
                    },
                    axisLine: { show: false },
                    axisTick: { show: false },
                    axisLabel: {
                        color: '#A4AFB2',
                    },
                    splitLine: {
                        lineStyle: {
                            color: 'rgba(164, 175, 178, 0.3)',
                        },
                    },
                },
            ],
            legend: {
                type: 'scroll',
                itemGap: 20,
                top: 280,
                left: 10,
                height: 0,
                data: this.formatLegend(),
            },
            grid: {
                top: this.props.gridTop ? this.props.gridTop : this.props.title ? 60 : 20,
                bottom: this.props.gridBottom ? this.props.gridBottom : 55,
                left: this.props.gridLeft ? this.props.gridLeft : 40,
                right: this.props.gridRight ? this.props.gridRight : 0,
            },
            series: this.props.series,
            color: this.props.theme.colors.chartRotatingColors,
        }
        this.setState({
            chartOption: barChartOption,
        })
    }

    componentDidMount() {
        this.loadBarChartData()
    }

    componentDidUpdate(prevProps: BarChartProps) {
        if (!isEqual(prevProps.series, this.props.series)) {
            this.loadBarChartData()
        }
    }

    getDataLength = () => {
        const withData = this.props.series.find((s) => !!s.data.find((d) => d.value > 0))
        return withData ? 999 : 0
    }

    render() {
        return (
            <NoDataNotice dataLength={this.getDataLength()} loading={this.props.loading}>
                <ReactEcharts
                    option={this.state.chartOption}
                    style={{
                        height: this.props.chartHeight ? this.props.chartHeight : '300px',
                        ...this.props.style,
                    }}
                    notMerge={true}
                    lazyUpdate={true}
                    onChartReady={() => {}}
                />
            </NoDataNotice>
        )
    }
}

export default withTheme(BarChart)
