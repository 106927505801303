import * as yup from 'yup'

export const inventoryValidation = yup.array().of(
    yup.object().shape({
        poolId: yup.string().nullable().required(),
        productListId: yup.string().nullable(),
        amountUsed: yup.number().integer().positive().required().min(1).max(10000),
    }),
)

const scannningOptionsSchema = yup.object({
    scanLimitType: yup.string(),
    ticketDuration: yup.string().nullable(),
})

interface FeatureFlags {
    scanningOptions?: boolean
}
export const createProductCrudValidationSchema = ({ scanningOptions }: FeatureFlags): yup.AnyObjectSchema => {
    const shape = {
        usedResources: inventoryValidation,
    } as any

    if (scanningOptions) {
        shape.scanningOptions = scannningOptionsSchema
    }

    return yup.object().shape(shape)
}
