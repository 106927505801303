import styled from 'styled-typed'
import * as React from 'react'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface TagProps {
    inverted?: boolean
    withBorder?: boolean
    disabled?: boolean
    inactive?: boolean
    customSymbol?: string
    maxTextWidth?: string
    onClick: () => void
    onTextClick?: () => void
    className?: string
}

interface TagElementProps {
    inverted?: boolean
    withBorder?: boolean
    disabled?: boolean
}

interface TextElementProps {
    clickable?: boolean
    maxWidth?: string
}

const TagElement = styled.div<TagElementProps>`
    background-color: ${(props) => (props.inverted ? props.theme.colors.white : props.theme.colors.background)};
    border-radius: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1em;
    padding-right: ${(props) => (props.disabled ? '1em' : '.1em')};
    height: 2em;
    border: ${(props) => (props.withBorder ? `1px solid ${props.theme.colors.border}` : '')};
`

const ClearTagElement = styled(TagElement)`
    height: 2.3em;
    padding-right: 0;
    min-width: 6em;
    cursor: pointer;
    transition: color 0.1s;

    .close-tag {
        color: ${(props) => props.theme.colors.textDark};
    }

    &:hover {
        color: ${(props) => props.theme.colors.status.error};
        .close-tag {
            color: ${(props) => props.theme.colors.status.error};
        }
        .custom-symbol {
            visibility: hidden;
        }
    }
`

const TextElement = styled.span<TextElementProps>`
    text-align: center;
    white-space: nowrap;
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : '')};
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        text-decoration: ${(props) => (props.clickable ? 'underline' : '')};
        cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
    }
`

const CloseButtonWrapper = styled.span`
    position: relative;
    margin: 0 0.5em 0 1em;
    color: ${(props) => props.theme.colors.border};
    background: ${(props) => props.theme.colors.white};
    border-radius: 50%;
    width: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.1s;

    &:hover:not(.inactive) {
        color: ${(props) => props.theme.colors.status.error};
        cursor: pointer;
    }
`
const CustomCloseTag = styled.span`
    position: absolute;
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.textDark};
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
`

export function Tag(props: TagProps & React.HTMLAttributes<HTMLElement>) {
    return (
        <TagElement
            className={props.className}
            style={props.style}
            inverted={props.inverted}
            withBorder={props.withBorder}
            disabled={props.disabled}
        >
            <TextElement clickable={!!props.onTextClick} onClick={props.onTextClick} maxWidth={props.maxTextWidth}>
                {props.children}
            </TextElement>
            {!props.disabled && <CloseTag onClick={props.onClick} inactive={props.inactive} />}
        </TagElement>
    )
}

export function ClearAllTag(props: TagProps & React.HTMLAttributes<HTMLElement>) {
    return (
        <ClearTagElement onClick={props.onClick}>
            <TextElement>
                <b>Clear</b>
            </TextElement>
            <CloseButtonWrapper className="close-tag">
                <CustomCloseTag className="custom-symbol">{props.customSymbol}</CustomCloseTag>
                <FontAwesomeIcon icon={faTimesCircle} style={{ width: '1.43em', height: '1.43em' }} />
            </CloseButtonWrapper>
        </ClearTagElement>
    )
}

export function CloseTag(props: TagProps & React.HTMLAttributes<HTMLElement>) {
    return (
        <CloseButtonWrapper onClick={props.onClick} className={props.inactive ? 'inactive' : ''}>
            <FontAwesomeIcon icon={faTimesCircle} style={{ width: '1.43em', height: '1.43em' }} />
        </CloseButtonWrapper>
    )
}
