import cleanDeep from 'clean-deep'
import { useNavigation } from 'hocs'
import { flowRight } from 'lodash'
import { QueryData } from 'navigation'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { Hooks, PluginHook, Row, useExpanded, usePagination, useRowSelect, useSortBy } from 'react-table'
import { useSticky } from 'react-table-sticky'
import { Emitter } from 'utils/eventEmmiter'
import { pathUtils } from 'utils/pathnameFormatter'
import { Sorting } from '..'
import {
    CustomReactTableEvents,
    ExtensibleReactTableComponentProps,
    LoadDataParams,
    PaginationType,
    ReactTableInstance,
    ReactTableOptions,
    ReactTableRows,
    ReactTableState,
    ReactTableStateReducer,
    TableColumn,
} from './interface'
import { useCursorPagination } from './pagination/useCursorPagination'

type SortDirection = 'asc' | 'desc'

export interface TableStateQueryParams {
    page: number
    pageSize: number
    dir?: SortDirection
    sortBy?: string
    cursor?: string
}

export class formatTableState {
    private static _formatPageSize(pageSize: number) {
        if ([10, 20, 25, 50, 100, 250, 500].includes(pageSize)) {
            return pageSize
        }

        return 10
    }

    static toQuery(query: QueryData): TableStateQueryParams {
        return {
            page: Number.parseInt(query.page, 10) || 1,
            pageSize: this._formatPageSize(Number.parseInt(query.pageSize, 10) || 10),
            dir: query.dir as SortDirection,
            sortBy: query.sortBy,
            cursor: query.cursor,
        }
    }

    static toTableInstance(query: QueryData): Partial<ReactTableState> {
        const pageIndex = Number.parseInt(query.page, 10) || 1
        const state = {} as Partial<ReactTableState>

        state.pageSize = this._formatPageSize(Number.parseInt(query.pageSize, 10) || 10)

        if (!query.cursor) {
            state.pageIndex = pageIndex - 1
        } else {
            state.token = query.cursor
        }

        if (query.sort) {
            state.sortBy = [{ id: query['sortBy'], desc: query['dir'] === 'desc' }]
        }

        return state
    }

    static toQueryFromTableState(tableState: Partial<ReactTableState>): TableStateQueryParams {
        const query = {
            page: (tableState.pageIndex || 0) + 1,
            pageSize: this._formatPageSize(tableState.pageSize || 10),
        } as TableStateQueryParams

        if (tableState.sortBy?.[0]) {
            query.sortBy = tableState.sortBy?.[0].id
            query.dir = tableState.sortBy?.[0].desc ? 'desc' : 'asc'
        }

        return query
    }

    static toQueryFromLoadData(
        params: Partial<LoadDataParams>,
        extraParams: Record<string, string | number | Array<string>> = {},
    ): string {
        if (!params) {
            return ''
        }

        const query = {
            page_size: params?.pagination?.pageSize,
            page: params?.pagination?.page,
            ...extraParams,
        } as any
        if (params?.sort?.prop) {
            query.sort_by = params.sort.prop
            query.sort_direction = params.sort.direction
        }

        return pathUtils.formatSerchParams(cleanDeep(query))
    }
}

interface UseHandleStateReducerProps {
    tableProps: ReactTableOptions<any>
    setTableState: (state: ReactTableState) => void
    event?: Emitter<CustomReactTableEvents>
    stateReducer?: ReactTableStateReducer
}

const useHandleStateReducer = ({ tableProps, setTableState, event, stateReducer }: UseHandleStateReducerProps) => {
    const tableReducer = useMemo(() => {
        const innerStateReducer: ReactTableStateReducer = (state, action, instance) => {
            setTableState(state)
            if (event) {
                switch (action.type) {
                    case 'toggleRowSelected':
                        event.emit('table_event_toggleRowSelected', {
                            action,
                            state,
                        })
                        break
                    case 'toggleAllRowsSelected':
                        event.emit('table_event_toggleAllRowsSelected', {
                            action,
                            state,
                        })
                        break
                    case 'resetSelectedRows':
                        event.emit('table_event_resetSelectedRows', {
                            action,
                            state,
                        })
                        break
                    default:
                        break
                }
            }

            return state
        }

        if (!!stateReducer) {
            return flowRight(innerStateReducer, stateReducer)
        } else {
            return innerStateReducer
        }
    }, [])

    tableProps.stateReducer = tableReducer
}

interface UseHandleSortingProps {
    tableProps: ReactTableOptions<any>
    plugins: PluginHook<any>[]
    sort?: Sorting | boolean
    loadData?: (tableState: LoadDataParams) => void
    storeStateInQuery: boolean
}

const useHandleSorting = ({ sort, loadData, tableProps, plugins, storeStateInQuery }: UseHandleSortingProps) => {
    const navigation = useNavigation()
    const tableState = formatTableState.toQuery(navigation.query())

    if (sort) {
        if (!!loadData) {
            tableProps.manualSortBy = true
            tableProps.autoResetSortBy = false
        }
        if (!tableProps.initialState) {
            tableProps.initialState = {} as ReactTableState
        }

        if (typeof sort === 'object') {
            tableProps.initialState.sortBy = [{ id: sort.prop, desc: sort.direction === 'desc' }]
        }

        if (tableState.sortBy && storeStateInQuery) {
            tableProps.initialState.sortBy = [{ id: tableState.sortBy, desc: tableState.dir === 'desc' }]
        }

        plugins.push(useSortBy)
    }
}

interface UseHandleExpandedProps {
    expanded?: boolean | ((props: ExtensibleReactTableComponentProps) => React.ReactNode)
    plugins: PluginHook<any>[]
    tableProps: ReactTableOptions<any>
}

const useHandleExpanded = ({ expanded, plugins, tableProps }: UseHandleExpandedProps) => {
    if (expanded) {
        plugins.push(useExpanded)
        tableProps.autoResetExpanded = false
    }
}

interface UseHandlePaginationProps {
    plugins: PluginHook<any>[]
    tableProps: ReactTableOptions<any>
    loadData?: (tableState: LoadDataParams) => void
    pagination?: PaginationType
    pageCount?: number
    storeStateInQuery?: boolean
}

const useHandlePagination = ({
    pagination,
    tableProps,
    loadData,
    pageCount,
    plugins,
    storeStateInQuery,
}: UseHandlePaginationProps) => {
    const navigation = useNavigation()
    const query = navigation.query()
    const tableStateFromQuery = formatTableState.toTableInstance(query)

    if (!pagination) {
        return
    }

    const isCursorPagination = typeof pagination !== 'boolean' && typeof pagination === 'object' && 'next' in pagination
    const isStandardPagination = typeof pagination !== 'object'
    tableProps.autoResetExpanded = true

    if (isStandardPagination) {
        if (!tableProps.initialState) {
            tableProps.initialState = {} as ReactTableState
        }

        if (!!loadData) {
            tableProps.manualPagination = true
            tableProps.autoResetPage = false
            tableProps.pageCount = pageCount
        } else {
            tableProps.manualPagination = false
        }

        if (!!tableStateFromQuery.pageIndex && storeStateInQuery) {
            tableProps.initialState.pageIndex = tableStateFromQuery.pageIndex
        }

        if (!!tableStateFromQuery.pageSize && storeStateInQuery) {
            tableProps.initialState.pageSize = tableStateFromQuery.pageSize
        }

        plugins.push(usePagination)

        return
    }

    if (isCursorPagination) {
        if (!tableProps.initialState) {
            tableProps.initialState = {} as ReactTableState
        }
        tableProps.autoResetExpanded = true
        tableProps.manualPagination = true

        if (!!tableStateFromQuery.pageSize && storeStateInQuery) {
            tableProps.initialState.pageSize = tableStateFromQuery.pageSize
        }

        plugins.push(useCursorPagination)
    }
}

interface UseHandleHiddenColumnsProps {
    tableProps: ReactTableOptions<any>
    storeStateInQuery: boolean
}

const useHandleHiddenColumns = ({ tableProps, storeStateInQuery }: UseHandleHiddenColumnsProps) => {
    const navigation = useNavigation()
    const query = navigation.query()

    if (!!query.hiddenColumns?.length && storeStateInQuery) {
        if (!tableProps.initialState) {
            tableProps.initialState = {} as ReactTableState
        }

        tableProps.initialState.hiddenColumns = query.hiddenColumns.split('|')
    }
}

interface UseHandleRowSelectProps<D extends object = {}> {
    plugins: PluginHook<any>[]
    disableRowFromSelecting?: (row: Row<D>) => boolean
    rowSelect?: boolean
}

const useHandleRowSelect = ({ rowSelect, plugins, disableRowFromSelecting }: UseHandleRowSelectProps) => {
    if (!!rowSelect) {
        plugins.push(useRowSelect)
        if (disableRowFromSelecting) {
            plugins.push((hooks: Hooks<any>) => {
                hooks.getToggleAllRowsSelectedProps = [
                    (props: any, { instance }: { instance: ReactTableInstance }) => {
                        const isRowSelected = (_row: ReactTableRows) => _row.isSelected || disableRowFromSelecting(_row)
                        const isAllRowsSelected = instance.rows.every(isRowSelected)
                        const selectedRowsLength = Object.keys(instance.state.selectedRowIds).length

                        return [
                            props,
                            {
                                onChange: () => {
                                    instance.rows.forEach((row) => {
                                        if (!disableRowFromSelecting(row)) {
                                            // It uses Row properties instead of instance. Bug in TS.
                                            // @ts-ignore
                                            row.toggleRowSelected(!isAllRowsSelected)
                                        }
                                    })
                                },
                                style: { cursor: 'pointer' },
                                checked: isAllRowsSelected,
                                title: 'Toggle All Rows Selected',
                                indeterminate: !isAllRowsSelected && selectedRowsLength,
                            },
                        ]
                    },
                ]
            })
        }
    }
}

interface UseHandleStickyProps {
    plugins: PluginHook<any>[]
    sticky?: boolean
}

const useHandleSticky = ({ sticky, plugins }: UseHandleStickyProps) => {
    if (!!sticky) {
        plugins.push(useSticky)
    }
}

interface UsePrepareTableInstanceOptionsProps<D extends object = {}> {
    data: D[]
    columns: TableColumn<D>[]
    pageCount?: number
    pagination?: PaginationType
    sort?: Sorting | boolean
    sticky?: boolean
    rowSelect?: boolean
    disableRowFromSelecting?: (row: Row<D>) => boolean
    expanded?: boolean | ((props: ExtensibleReactTableComponentProps) => ReactElement<any, any> | null)
    loadData?: (tableState: LoadDataParams) => void
    stateReducer?: ReactTableStateReducer
    event?: Emitter<CustomReactTableEvents>
    storeStateInQuery: boolean
}

export const usePrepareTableInstanceOptions = ({
    columns,
    data,
    sort,
    loadData,
    expanded,
    pagination,
    pageCount,
    rowSelect,
    disableRowFromSelecting,
    sticky,
    event,
    stateReducer,
    storeStateInQuery,
}: UsePrepareTableInstanceOptionsProps) => {
    const [tableState, setTableState] = useState<ReactTableState>()
    const plugins = [] as PluginHook<any>[]
    const tableProps = {
        columns: columns,
        data,
    } as ReactTableOptions<any>

    useHandleStateReducer({ event, setTableState, tableProps, stateReducer })
    useHandleSorting({ sort, loadData, tableProps, plugins, storeStateInQuery })
    useHandleExpanded({ expanded, plugins, tableProps })
    useHandlePagination({ storeStateInQuery, pagination, tableProps, loadData, pageCount, plugins })
    useHandleHiddenColumns({ tableProps, storeStateInQuery })
    useHandleRowSelect({ rowSelect, disableRowFromSelecting, plugins })
    useHandleSticky({ sticky, plugins })

    return {
        tableProps,
        plugins,
        immutableTableState: tableState,
    }
}

interface UseHandleUserEventsProps {
    event?: Emitter<CustomReactTableEvents>
    tableInstance: ReactTableInstance
    storeStateInQuery: boolean
}

export const useHandleUserEvents = ({ event, tableInstance, storeStateInQuery }: UseHandleUserEventsProps) => {
    const navigation = useNavigation()

    useEffect(() => {
        if (!!event) {
            event.on('toggleHideColumn', (columnId) => {
                if (storeStateInQuery) {
                    const hiddenColumns = [...(tableInstance.state.hiddenColumns ?? [])]
                    if (tableInstance.state.hiddenColumns?.includes(columnId)) {
                        navigation.addQuery({
                            hiddenColumns: hiddenColumns.filter((id) => id !== columnId)?.join('|'),
                        })
                    } else {
                        hiddenColumns.push(columnId)
                        navigation.addQuery({
                            hiddenColumns: hiddenColumns.join('|'),
                        })
                    }
                }
                tableInstance.toggleHideColumn(columnId)
            })

            event.on('setHiddenColumns', (columnIds) => {
                tableInstance.setHiddenColumns(columnIds)
            })

            event.on('toggleHideAllColumns', () => {
                tableInstance.toggleHideAllColumns(true)
            })

            event.on('toggleRowSelected', (rowId) => {
                tableInstance.toggleRowSelected(rowId)
            })

            event.on('toggleAllRowsSelected', (selected) => {
                tableInstance.toggleAllRowsSelected(selected)
            })

            event.on('resetSelectedRows', () => {
                tableInstance.toggleAllRowsSelected(false)
            })

            event.on('resetToken', () => {
                tableInstance.setPaginationToken && tableInstance.setPaginationToken('0')
            })
        }
    }, [])
}

interface UseHandleDataLoadingProps {
    loadData?: (tableState: LoadDataParams) => void
    tableInstance: ReactTableInstance
}

export const useHandleDataLoading = ({ tableInstance, loadData }: UseHandleDataLoadingProps) => {
    const { pageIndex, pageSize, sortBy, token } = tableInstance.state as any

    useEffect(() => {
        if (!!loadData) {
            loadData({
                pagination: {
                    page: pageIndex + 1,
                    pageSize: pageSize,
                },
                sort: {
                    direction: sortBy?.[0]?.desc ? 'desc' : 'asc',
                    prop: sortBy?.[0]?.id,
                },
                token,
            } as LoadDataParams)
        }
    }, [loadData, pageIndex, pageSize, sortBy, token])
}

interface UseUpdateTableStateInQueryProps {
    tableInstance: ReactTableInstance
    storeStateInQuery: boolean
}

const useUpdateTableStateInQuery = ({ tableInstance, storeStateInQuery }: UseUpdateTableStateInQueryProps) => {
    const { pageIndex, pageSize, sortBy, token } = tableInstance.state as any
    const navigation = useNavigation()

    useEffect(() => {
        if (storeStateInQuery) {
            const queryStr = formatTableState.toQueryFromTableState({
                pageIndex,
                pageSize,
                sortBy,
            })

            navigation.addQueryWithReplace(queryStr as unknown as any)
        }
    }, [pageIndex, pageSize, sortBy, token, storeStateInQuery])
}

interface UsePostInitiTableInstanceProps {
    tableInstance: ReactTableInstance
    event?: Emitter<CustomReactTableEvents>
    loadData?: (tableState: LoadDataParams) => void
    storeStateInQuery: boolean
}

export const usePostInitiTableInstance = ({
    tableInstance,
    event,
    loadData,
    storeStateInQuery,
}: UsePostInitiTableInstanceProps) => {
    useHandleUserEvents({ tableInstance, event, storeStateInQuery })
    useHandleDataLoading({ tableInstance, loadData })
    useUpdateTableStateInQuery({ tableInstance, storeStateInQuery })
}
