import * as React from 'react'
import { match as RouteMatch } from 'react-router-dom'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { PageTitle, PageHeading } from 'uiComponents/typography'
import { StatsService } from 'http/statsService'
import { dateRangeToQuery, DateRange } from 'dateRanges'
import TimeseriesChart from 'reports/discounts/timeseriesChart'
import { Row, Col } from 'uiComponents/flex'
import { chartRowsGap } from 'reports/helpers'
import SingleDataItems from 'reports/discounts/singleDataItems'
import { createTimeseriesBarChart, TimeseriesBarChartSource } from 'uiComponents/charts/timeseriesBarChartSource'
import { areDateRangeDatesEqual, ReportsToolsWrapper, ReportUpdateInfo } from 'reports/helpers'
import { Messages } from 'uiComponents/messages'
import { withMessages, MessageProps } from 'hocs'
import { dateRangeFromQuery } from 'dateRanges'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { withFeatures } from 'features'
import { LoginService } from 'http/loginService'
import { LoggingService } from 'http/loggingService'
import VerticalBartChart from 'reports/discounts/verticalBarChart'
import PopularCodesChart from 'reports/discounts/comparisonBarChart'
import SearchField from 'uiComponents/search/searchWithButton'
import { Filters } from 'uiComponents/filter/schema'
import Disclaimer from 'reports/disclaimer'

interface DiscountsReportsPageProps {
    navigation: Navigation
    match: RouteMatch<any>
    statsService: StatsService
    accountSlug: string
    backofficeEndpoint: string
    loginService: LoginService
    loggingService: LoggingService
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function DiscountsReportsPage(props: DiscountsReportsPageProps & MessageProps) {
    const dateRange = dateRangeFromQuery(props.navigation.query())
    const [chart, setChart] = React.useState<TimeseriesBarChartSource>(createTimeseriesBarChart(dateRange))

    React.useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    const onDateRangeChanged = (range: DateRange) => {
        if (!areDateRangeDatesEqual(dateRange, range)) {
            props.navigation.addQueryWithReplace(dateRangeToQuery(range))
            setChart(createTimeseriesBarChart(range))
        }
    }

    const query = props.navigation.query()
    const codesFilters: Filters[] = query.q ? [{ attribute: 'discount_names', values: [query.q] }] : []

    return (
        <div id="reports-discounts-page" style={{ marginBottom: '7em' }}>
            <Messages messages={props.messages} hideMessage={props.hideMessage} />
            <PageTitle data-userpilot="sales-header-tooltip">Discount Codes Report</PageTitle>
            <PageHeading fullWidth>
                <div>Your discount codes report holds information about your vouchers and coupons usage.</div>
                <div>
                    Find more information in our{' '}
                    <a
                        target="_blank"
                        href="https://support.convious.com/help/how-to-use-your-discount-code-report"
                        rel="noreferrer"
                    >
                        Knowledge Base
                    </a>
                    .
                </div>
            </PageHeading>
            <ReportUpdateInfo metric="Data" text="updated every 1 hour." />
            <ReportsToolsWrapper>
                <SearchField placeholder="Filter by discount code" hideExtendedSearch style={{ width: '18.5em' }} />
                <DateRangePicker
                    range={dateRange}
                    onChange={onDateRangeChanged}
                    firstAvailableDay={new Date(2019, 0, 1)}
                />
            </ReportsToolsWrapper>
            <SingleDataItems
                filters={codesFilters}
                accountSlug={props.accountSlug}
                dateRange={dateRange}
                replaceMessages={props.replaceMessages}
            />
            <Row style={{ marginTop: chartRowsGap }}>
                <Col span={6}>
                    <PopularCodesChart
                        accountSlug={props.accountSlug}
                        dateRange={dateRange}
                        replaceMessages={props.replaceMessages}
                    />
                </Col>
                <Col span={6}>
                    <VerticalBartChart
                        filters={codesFilters}
                        accountSlug={props.accountSlug}
                        dateRange={dateRange}
                        replaceMessages={props.replaceMessages}
                    />
                </Col>
            </Row>
            <TimeseriesChart
                filters={codesFilters}
                accountSlug={props.accountSlug}
                dateRange={dateRange}
                chart={chart}
                backofficeEndpoint={props.backofficeEndpoint}
                loginService={props.loginService}
                loggingService={props.loggingService}
                replaceTopMessages={props.replaceMessages}
                hideMessage={props.hideMessage}
                groupByLocation
            />
            <Disclaimer />
        </div>
    )
}

export default withFeatures(withMessages(withNavigation(DiscountsReportsPage)))
