import * as React from 'react'
import { History } from 'history'
import { useDispatch } from 'react-redux'
import SearchField, { SearchContainer } from 'uiComponents/search/searchField'
import { TableLoader } from 'uiComponents/loaders'
import { usePrevious, useAccountChange } from 'reactUtils'
import { format } from 'date-fns'
import { withFeatures } from 'features'
import { ActionButton } from 'uiComponents/buttons'
import { AddEditDialog } from './addEditDialog'
import { ComponentsService, ConfigurationVersions, ProductName } from 'engageTools/studio/componentsService'
import { MessageKind } from 'uiComponents/messages'
import { Navigation, renderSearch } from 'navigation'
import { dateRangeFromQuery } from 'dateRanges'
import { TapPlacementService } from 'tapPlacements/service'
import { useGetConfigurationsVersionsQuery, useLazyGetConfigurationsVersionsQuery } from 'engageTools/reduxApi'
import TableComponent from './tableComponent'
import ConnectedInlineMessage from 'uiComponents/messages/connectedInlineMessage'
import { Grid } from 'uiComponents/layout'
import { removeAllTagMessages, removeMessage } from 'uiComponents/messages/actions'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { ROUTE_PATHS } from 'engageTools/routePaths'
import { pathUtils } from 'utils/pathnameFormatter'
import { formatISOString } from 'utils/dates'

interface TableProps {
    accountSlug: string
    history: History
    hasFeature: (feature: string, accountSlug: string) => boolean
    componentsService: ComponentsService
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    type: ProductName
    tools: ConfigurationVersions[]
    setTools: (tools: ConfigurationVersions[]) => void
    navigation: Navigation
    canEditCampaigns: boolean
    dateRangeApplied: boolean
    placementService: TapPlacementService
}

const Table: React.FC<TableProps> = ({ children, ...props }) => {
    const [modalOn, setModalOn] = React.useState<boolean>(false)
    const [modalClosed, setModalClosed] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [toolToEdit, setToolToEdit] = React.useState<string>('')
    const [updating, setUpdating] = React.useState<boolean>(false)
    const [uponEdit, setUponEdit] = React.useState<boolean>(false)
    const routeParams = useParams()
    const dispatch = useDispatch()
    const history = useHistory()

    React.useEffect(() => {
        getEngageTools()
    }, [])

    React.useEffect(() => {
        setUpdating(false)
    }, [props.tools])

    React.useEffect(() => {
        if (!!uponEdit) {
            getEngageTools()
            setUponEdit(false)
        }
    }, [uponEdit])

    React.useEffect(() => {
        if (modalClosed) {
            props.removeAllMessages()
            setModalClosed(false)
        }
    }, [modalClosed])

    const prevType = usePrevious(props.type)
    React.useEffect(() => {
        if (!!prevType) {
            getEngageTools()
            const { q, page, ...rest } = props.navigation.query()
            if (q) {
                props.history.replace({
                    pathname: location.pathname,
                    search: renderSearch({ ...rest }),
                })
            }
        }
    }, [props.type])

    useAccountChange(props.accountSlug, () => getEngageTools())

    const prevRangeApplied = usePrevious(props.dateRangeApplied)
    React.useEffect(() => {
        if (typeof prevRangeApplied !== 'undefined' && prevRangeApplied !== props.dateRangeApplied) {
            getEngageTools()
        }
    }, [props.dateRangeApplied])

    const prevFilters = usePrevious(props.navigation.query().filter)
    const prevSearch = usePrevious(props.navigation.query().q)
    const prevDateFrom = usePrevious(props.navigation.query().dateFrom)
    const prevDateTo = usePrevious(props.navigation.query().dateTo)
    const prevDateRange = usePrevious(props.navigation.query().dateRange)
    React.useEffect(() => {
        const query = props.navigation.query()
        if (
            query.filter !== prevFilters ||
            query.q !== prevSearch ||
            query.dateFrom !== prevDateFrom ||
            query.dateTo !== prevDateTo ||
            query.dateRange !== prevDateRange
        ) {
            getEngageTools()
        }
    }, [
        props.navigation.query().filter,
        props.navigation.query().q,
        props.navigation.query().dateFrom,
        props.navigation.query().dateTo,
        props.navigation.query().dateRange,
    ])

    const [fetchTool, { data }] = useLazyGetConfigurationsVersionsQuery()
    const { data: fullData } = useGetConfigurationsVersionsQuery({
        accountSlug: props.accountSlug,
        query: '?',
        type: props.type,
    })

    React.useEffect(() => {
        if (data) {
            props.setTools(data)
        }
    }, [data])

    const getEngageTools = async () => {
        try {
            setLoading(true)

            const query = props.navigation.query()
            let queryString = query.filter ? `?filters=${encodeURIComponent(query.filter)}` : '?'
            if (!!props.dateRangeApplied) {
                if (query.dateFrom && query.dateTo) {
                    queryString = `${queryString}&date_from=${encodeURIComponent(
                        formatISOString(query.dateFrom, 'yyyy-MM-dd'),
                    )}&date_to=${encodeURIComponent(formatISOString(query.dateTo, 'yyyy-MM-dd'))}`
                } else if (query.dateRange) {
                    const rangeData = dateRangeFromQuery(query)
                    queryString = `${queryString}&date_from=${encodeURIComponent(
                        format(rangeData.from, 'yyyy-MM-dd'),
                    )}&date_to=${encodeURIComponent(format(rangeData.to, 'yyyy-MM-dd'))}`
                }
            }
            queryString = query.q ? `${queryString}&search=${encodeURIComponent(query.q)}` : queryString

            const response = await fetchTool({
                accountSlug: props.accountSlug,
                query: queryString,
                type: props.type,
            })
            props.setTools(response.data || [])
        } catch {
            handleErrorMessage()
        } finally {
            setLoading(false)
        }
    }

    const handleErrorMessage = () => {
        props.replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again.')
    }

    const unpublishTool = async (configId: string) => {
        const configData = props.tools.find((t) => t.id === configId)
        const productType = configData?.product
        if (!productType) {
            return
        }
        setLoading(true)
        try {
            await props.componentsService.unpublishCurrentVersion(props.accountSlug, productType, configId)
            await getEngageTools()
            props.replaceMessages('server_success', 'success', 'Your tool was successfully unpublished.')
        } catch {
            handleErrorMessage()
        } finally {
            setLoading(false)
        }
    }

    const publishTool = async (configId: string) => {
        const configData = props.tools.find((t) => t.id === configId)
        const productType = configData?.product || 'wonderbar'

        if (!configData) {
            return
        }
        setLoading(true)
        try {
            let draftId
            if (!configData.next) {
                const draftVersion = await props.componentsService.createEmptyDraft(
                    props.accountSlug,
                    productType,
                    configId,
                )
                draftId = draftVersion.id
            } else {
                draftId = configData.next.id
            }
            await props.componentsService.publishDraft(props.accountSlug, configId, draftId, productType)
            await getEngageTools()
            props.replaceMessages('server_success', 'success', 'Your tool was successfully published.')
        } catch {
            handleErrorMessage()
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        return () => {
            dispatch(removeAllTagMessages('EngageTools'))
        }
    }, [props.history.location.pathname])

    return (
        <>
            {modalOn && (
                <AddEditDialog
                    componentsService={props.componentsService}
                    onCancel={() => {
                        setModalOn(false)
                        setModalClosed(true)
                    }}
                    onConfirm={() => {
                        setModalOn(false)
                        setUponEdit(true)
                    }}
                    accountSlug={props.accountSlug}
                    id={toolToEdit}
                    tools={props.tools}
                    type={props.type}
                    replaceMessages={props.replaceMessages}
                    removeAllMessages={props.removeAllMessages}
                    hasFeature={props.hasFeature}
                />
            )}
            {loading && <TableLoader />}
            {!loading && (
                <>
                    <SearchContainer style={{ margin: '1.5em' }}>
                        <SearchField placeholder="Search by name" />
                        <ActionButton
                            kind="action"
                            size="medium"
                            onClick={() => {
                                setModalOn(true)
                                setToolToEdit('')
                            }}
                            id="add-new-campaign"
                        >
                            + Add new
                        </ActionButton>
                    </SearchContainer>

                    <ConnectedInlineMessage
                        tag="EngageTools"
                        texts={{
                            duplicate: ({ data: toolId, id }) => {
                                const tool = (fullData || []).find((_tool) => _tool.id === toolId)

                                if (tool && !tool?.duplicates?.length) {
                                    dispatch(removeMessage(id))
                                }

                                return (
                                    <Grid container>
                                        <Grid item={10} md={12}>
                                            After publishing <b>“{tool?.name}”</b> active duplicates were found. Trigger
                                            buttons are prioritized in ascending order by device type, audiences and
                                            creation date, review the list to make changes.
                                        </Grid>
                                        <Grid item>
                                            <ActionButton
                                                kind="action"
                                                onClick={() => {
                                                    if (tool) {
                                                        const path = generatePath(ROUTE_PATHS.duplicateModal, {
                                                            ...routeParams,
                                                            id: tool.id as string,
                                                        } as any)

                                                        props.history.push(path)
                                                    }
                                                }}
                                            >
                                                Duplicate list
                                            </ActionButton>
                                        </Grid>
                                    </Grid>
                                )
                            },
                        }}
                    >
                        {({ children }) => <div style={{ padding: '1rem' }}>{children}</div>}
                    </ConnectedInlineMessage>

                    <TableComponent
                        tools={props.tools}
                        setToEdit={setToolToEdit}
                        setModalOn={setModalOn}
                        type={props.type}
                        onUnpublish={unpublishTool}
                        onPublish={publishTool}
                        onDelete={(id: string) => {
                            history.push(
                                pathUtils.populateParams(ROUTE_PATHS.deleteModal, {
                                    accountSlug: props.accountSlug,
                                    id: id,
                                    section: props.type,
                                }),
                            )
                        }}
                        canEditCampaigns={props.canEditCampaigns}
                        updating={updating}
                        loading={loading}
                    />
                </>
            )}
            {children}
        </>
    )
}

export default withFeatures(Table)
