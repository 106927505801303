import { BaseRouteParams } from 'hocs'
import { pathUtils } from 'utils/pathnameFormatter'

export interface CodePageParams extends BaseRouteParams {
    id: string
}

export interface EditCodePageParams extends BaseRouteParams {
    poolId: string
    codeId: string
}

export const CODES_PATHS = {
    pagePaths: {
        discountPath: '/account/:accountSlug/venue/codes/discount',
    },
    internalPaths: {
        discountPath: {
            pools: 'pools',
            codes: 'codes',
            addPool: 'add/pool',
            addPoolSuccessful: 'add/pool/successful/:id',
            addByForm: 'add/form/:id',
            addByUpload: 'add/csv/:id',
            editCode: ':poolId/code/:codeId',
            deletePool: 'delete/pool/:id',
            deleteCode: 'delete/code/:id',
        },
    },
    get fullPaths() {
        return {
            _internalPaths: this.internalPaths,

            poolsPage: pathUtils.addPath(this.pagePaths.discountPath, this.internalPaths.discountPath.pools),
            get poolsPageAddPool() {
                return pathUtils.addPath(this.poolsPage, this._internalPaths.discountPath.addPool)
            },
            get poolsPageAddPoolSuccessful() {
                return pathUtils.addPath(this.poolsPage, this._internalPaths.discountPath.addPoolSuccessful)
            },
            get poolsPageAddByForm() {
                return pathUtils.addPath(this.poolsPage, this._internalPaths.discountPath.addByForm)
            },
            get editCode() {
                return pathUtils.addPath(this.poolsPage, this._internalPaths.discountPath.editCode)
            },
            get poolsPageAddByUpload() {
                return pathUtils.addPath(this.poolsPage, this._internalPaths.discountPath.addByUpload)
            },
            get poolsPageDeletePool() {
                return pathUtils.addPath(this.poolsPage, this._internalPaths.discountPath.deletePool)
            },
            get poolsPageDeleteCode() {
                return pathUtils.addPath(this.poolsPage, this._internalPaths.discountPath.deleteCode)
            },

            codesPage: pathUtils.addPath(this.pagePaths.discountPath, this.internalPaths.discountPath.codes),
        }
    },
}
