import * as React from 'react'
import { usePrevious } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { format } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { getComparisonColorFromPercentString } from 'reports/helpers'
import { ChartRow } from 'uiComponents/charts/styleComponents'
import { QueryConfig } from 'reports/queryGenerator'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { chartRowsGap } from 'reports/helpers'
import { DateRange } from 'dateRanges'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { isBefore, startOfToday, differenceInCalendarDays } from 'date-fns'
import { Filters } from 'uiComponents/filter/schema'
import isEqual from 'lodash/isEqual'
import { ComparisonDataNumber } from 'uiComponents/charts/dataNumber'
import Infotip from 'uiComponents/infotip'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'

const queryConfig: QueryConfig = {
    querySetName: 'SingleDataItemsCustomersReport',
    variablesConfig: [
        { name: 'accountSlug', type: 'String' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'comparisonType', type: 'ComparisonTypes' },
        { name: 'timeFilter', type: 'StatsTimeFilter' },
        { name: 'delayType', type: 'DelayTypes' },
        { name: 'delay', type: 'Int' },
        { name: 'filters', type: '[FilterDictionary]' },
    ],
    queries: [
        {
            name: 'ticketsSold',
            type: 'comparisonMetrics',
            configVariables: [
                'accountSlug',
                'comparisonType',
                'timeFilter',
                'dateFrom',
                'dateTo',
                'delayType',
                'delay',
                'filters',
            ],
            customVariables: [{ name: 'metric', customValue: 'products_sold_by_event_date' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'avgPrice',
            type: 'comparisonMetrics',
            configVariables: [
                'accountSlug',
                'comparisonType',
                'timeFilter',
                'dateFrom',
                'dateTo',
                'delayType',
                'delay',
                'filters',
            ],
            customVariables: [{ name: 'metric', customValue: 'avg_item_price_by_event_date_sold' }],
            presetResult: 'totalsComparison',
        },
        {
            name: 'avgLeadTime',
            type: 'comparisonMetrics',
            configVariables: [
                'accountSlug',
                'comparisonType',
                'timeFilter',
                'dateFrom',
                'dateTo',
                'delayType',
                'delay',
                'filters',
            ],
            customVariables: [{ name: 'metric', customValue: 'avg_lead_time' }],
            presetResult: 'totalsComparison',
        },
    ],
}

interface SingleDataItemsProps {
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    dateRange: DateRange
    filters: Filters[]
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    getCurrency: (accountSlug: string) => Currency
}

function SingleDataItems(props: SingleDataItemsProps) {
    const _isMounted = React.useRef(false)
    const _lastRequest = React.useRef<number>()
    const statsService = React.useContext(StatsServiceContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [timePeriodLength, setTimePeriodLength] = React.useState<number>(0)
    const [ticketsSold, setTicketsSold] = React.useState<string>('0')
    const [avgPrice, setAvgPrice] = React.useState<string>('0')
    const [avgLeadTime, setAvgLeadTime] = React.useState<string>('0')
    const [comparisonTicketsSold, setComparisonTicketsSold] = React.useState<string>('-')
    const [comparisonAvgPrice, setComparisonAvgPrice] = React.useState<string>('-')
    const [comparisonAvgLeadTime, setComparisonAvgLeadTime] = React.useState<string>('-')

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    const prevFilters = usePrevious(props.filters)
    React.useEffect(() => {
        _isMounted.current = true
        if (
            prevAccountSlug !== props.accountSlug ||
            !areDateRangeDatesEqual(prevDateRange, props.dateRange) ||
            !isEqual(prevFilters, props.filters)
        ) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug, props.dateRange, props.filters])

    const getData = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime
        setLoading(true)
        const dateRangeLength = differenceInCalendarDays(props.dateRange.to, props.dateRange.from) + 1
        setTimePeriodLength(dateRangeLength)
        try {
            const todayIncluded = isBefore(startOfToday(), props.dateRange.to)
            const variables = {
                accountSlug: props.accountSlug,
                dateFrom: format(props.dateRange.from, 'yyyy-MM-dd'),
                dateTo: format(props.dateRange.to, 'yyyy-MM-dd'),
                comparisonType: 'percentage_growth',
                timeFilter: todayIncluded ? 'this_time_so_far' : null,
                delayType: dateRangeLength <= 7 ? 'weeks' : 'previous_stretch',
                delay: 1,
                filters: props.filters,
            }
            const data = await statsService.getStats(queryConfig, variables)

            if (_isMounted.current && _lastRequest.current === requestTime) {
                setTicketsSold(data.ticketsSold.totals?.value ? Number(data.ticketsSold.totals?.value).toFixed(0) : '0')
                setAvgPrice(data.avgPrice.totals?.value ? Number(data.avgPrice.totals?.value).toFixed(2) : '0')
                setAvgLeadTime(data.avgLeadTime.totals?.value ? formatDays(data.avgLeadTime.totals?.value) : '0')
                setComparisonTicketsSold(data.ticketsSold.totals?.change || '-')
                setComparisonAvgPrice(data.avgPrice.totals?.change || '-')
                setComparisonAvgLeadTime(data.avgLeadTime.totals?.change || '-')
                setLoading(false)
            }
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Some big data numbers could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }

    const formatDays = (value: string) => {
        return String(Math.round(Number(value) * 10) / 10)
    }

    const getPlusSign = (change: string) => {
        const value = Number(change.split('%')[0])
        return value > 0 ? '+' : ''
    }

    const getComparison = (value: string, index: number) => {
        const text = timePeriodLength <= 7 ? 'WoW' : 'prev. period'
        return (
            <>
                <span className={getComparisonColorFromPercentString(value)}>
                    {getPlusSign(value)}
                    {value} {text}
                </span>
                {text === 'WoW' && (
                    <Infotip pointer={index > 1 ? 'right' : 'left'} maxWidth="23em">
                        Week over Week (or WoW) means comparing data from one week to the previous week.
                    </Infotip>
                )}
            </>
        )
    }

    return (
        <div style={{ position: 'relative' }}>
            {loading && <ChartDataLoader topOffset="0" />}
            <ChartRow style={{ marginBottom: chartRowsGap }}>
                <ComparisonDataNumber
                    value={ticketsSold}
                    comparison={getComparison(comparisonTicketsSold, 0)}
                    title="Tickets sold"
                />
                <ComparisonDataNumber
                    value={props.formatCurrencyString(Number(avgPrice).toFixed(2), props.accountSlug)}
                    comparison={getComparison(comparisonAvgPrice, 1)}
                    title="Avg. ticket price"
                />
                <ComparisonDataNumber
                    value={`${avgLeadTime} days`}
                    comparison={getComparison(comparisonAvgLeadTime, 2)}
                    title="Avg. lead time"
                />
            </ChartRow>
        </div>
    )
}

export default withCurrency(withNavigation(SingleDataItems))
