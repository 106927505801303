import * as React from 'react'
import styled from 'styled-typed'
import { User } from 'auth/state'
import { InlineDataTable, HeaderRow, TableHeader, Cell, ExpandCell, DynamicRow } from 'uiComponents/table'
import { SecondLevelHeaderRow, SecondLevelTableRow } from 'uiComponents/table/secondLevelTableElements'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { ActionButton } from 'uiComponents/buttons'
import { Sorting } from 'uiComponents/table'
import { faReply } from '@fortawesome/pro-regular-svg-icons'
import { faClock } from '@fortawesome/pro-regular-svg-icons'
import { faEnvelopeOpenText } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome'
import { Dispute, disputeStatusMap, disputeReasonMap, ModalType } from 'orders/paypalDisputes/schema'
import { statusesMap } from 'orders/schema'
import Money from 'uiComponents/money'
import { isBefore } from 'date-fns'
import { OnClickMenu, OnClickMenuItem } from 'uiComponents/menus/'
import { withPermission } from 'admin/hocs'
import { NestedContainerWrapper } from 'uiComponents/table/nestedContainer'
import { ChartDataLoader } from 'uiComponents/loaders'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { DateFormats, parseISODate, formatISOString } from 'utils/dates'

const Icon = styled(FontAwesomeIcon)`
    font-size: 0.85em;
    margin-right: 0.3em;
    margin-bottom: 0.15em;
`
const Link = styled(UnstyledLink)`
    color: ${(props) => props.theme.colors.boyBlue};
    &:hover {
        text-decoration: underline;
    }
`
const MenuItem = styled(OnClickMenuItem)`
    padding: 0 0.6em;
`
const PaypalIdCell = styled.div`
    display: flex;
    flex-direction: column;
    span {
        color: ${(props) => props.theme.colors.boyBlue};
        cursor: pointer;
        margin-bottom: 0.2em;
        &:hover {
            text-decoration: underline;
        }
    }
`
const LocationCell = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: unset;
    }
`
const DueDateCell = styled.div`
    display: flex;
    flex-direction: column;
    &.past-due-date {
        color: ${(props) => props.theme.colors.status.error};
    }
`
const ReasonCell = styled.div`
    display: flex;
    align-items: center;
`
const EmailIconWrapper = styled.div`
    cursor: pointer;
    position: relative;
    margin-left: 0.3em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 2em;
    width: 2em;
    background: ${(props) => props.theme.colors.border};
    &.new-message {
        &:after {
            content: '';
            position: absolute;
            top: 0.2em;
            right: 0;
            height: 0.5em;
            width: 0.5em;
            border-radius: 50%;
            background: ${(props) => props.theme.colors.status.error};
        }
    }
`

interface DisputesTableProps {
    section: 'active' | 'resolved'
    accountSlug: string
    user: User
    disputesList: Dispute[]
    loading: boolean
    sort: Sorting
    onSortChanged: (s: Sorting) => void
    onOpenModal: (id: string, type: ModalType) => void
    hasPermission: (permission: string, accountSlug: string) => boolean
}

function DisputesTable(props: DisputesTableProps) {
    const [currentExpanded, setCurrentExpanded] = React.useState<string>('')

    const toggleExpanded = (id: string) => {
        currentExpanded === id ? setCurrentExpanded('') : setCurrentExpanded(id)
    }

    const columnData = [
        { w: '1em', fixed: true }, // expand
        { w: '1.1em', fixed: true }, // expand with chevron
        { w: '12.5em', fixed: false }, // case id
        { w: '9em', fixed: true }, // order id
        { w: '9em', fixed: false }, // location
        { w: '15em', fixed: false }, // reason
        { w: '10em', fixed: true }, // due date
        { w: '10em', fixed: true }, // button
    ]

    return (
        <InlineDataTable columnData={columnData} bordered id="disputes-list">
            {props.loading && <ChartDataLoader />}
            <HeaderRow>
                <TableHeader nonInteractive noPadding />
                <TableHeader nonInteractive noPadding />
                <TableHeader>Case ID</TableHeader>
                <TableHeader>Order ID</TableHeader>
                <TableHeader>Location</TableHeader>
                <TableHeader>Reason</TableHeader>
                <TableHeader>Due date</TableHeader>
                <TableHeader nonInteractive />
            </HeaderRow>
            {props.disputesList.length === 0 && <NoResultsRow text={`There are no ${props.section} disputes.`} />}
            {props.disputesList.length > 0 &&
                props.disputesList.map((item, i) => {
                    const expanded = item.id === currentExpanded
                    const statusIcon = item.status === 'UNDER_REVIEW' ? faClock : faReply
                    const accountName = item.account.name
                    const pastDueDate = isBefore(new Date(), parseISODate(item.responseDueDate))
                    const actionsAllowed =
                        item.status !== 'RESOLVED' &&
                        item.status !== 'UNDER_REVIEW' &&
                        props.hasPermission('partner_admin', props.accountSlug)
                    return (
                        <div key={i}>
                            <DynamicRow
                                interactive
                                highlighted={expanded}
                                onClick={() => toggleExpanded(item.id)}
                                className={expanded ? 'expanded hide-bottom-border' : ''}
                            >
                                <Cell noPadding />
                                <ExpandCell className="expand" expanded={expanded} align="left" noPadding />
                                <Cell>
                                    <PaypalIdCell>
                                        <span
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                props.onOpenModal(item.id, 'details')
                                            }}
                                        >
                                            {item.disputeId}
                                        </span>
                                        <div className="status">
                                            <Icon icon={statusIcon as IconProp} />
                                            {disputeStatusMap[item.status]}
                                        </div>
                                    </PaypalIdCell>
                                </Cell>
                                <Cell>
                                    <Link
                                        to={`/account/${item.account.slug}/orders/transactions/?q=${item.order.number}`}
                                    >
                                        {item.order.number}
                                    </Link>
                                </Cell>
                                <Cell title={`${props.user.isAdmin ? accountName + ' - ' : ''}${item.order.location}`}>
                                    <LocationCell>
                                        {props.user.isAdmin && <span>{accountName}</span>}
                                        <span>{item.order.location}</span>
                                    </LocationCell>
                                </Cell>
                                <Cell title={disputeReasonMap[item.reason]}>
                                    <ReasonCell>
                                        {disputeReasonMap[item.reason]}
                                        {item.messages && item.messages.length > 0 && (
                                            <EmailIconWrapper
                                                className={
                                                    item.status === 'WAITING_FOR_SELLER_RESPONSE' ? 'new-message' : ''
                                                }
                                                onClick={() => props.onOpenModal(item.id, 'details')}
                                            >
                                                <FontAwesomeIcon icon={faEnvelopeOpenText as IconProp} />
                                            </EmailIconWrapper>
                                        )}
                                    </ReasonCell>
                                </Cell>
                                <Cell>
                                    {item.responseDueDate && (
                                        <DueDateCell className={pastDueDate ? 'past-due-date' : ''}>
                                            <span>{formatISOString(item.responseDueDate, DateFormats.LONG_DATE)}</span>
                                            <span>{formatISOString(item.responseDueDate, DateFormats.SHORT_TIME)}</span>
                                        </DueDateCell>
                                    )}
                                    {!item.responseDueDate && '-'}
                                </Cell>
                                <Cell align="right" className="overflow-allowed" onClick={(e) => e.stopPropagation()}>
                                    {!actionsAllowed && (
                                        <ActionButton
                                            kind="action"
                                            secondary
                                            onClick={() => props.onOpenModal(item.id, 'details')}
                                        >
                                            {' '}
                                            View
                                        </ActionButton>
                                    )}
                                    {actionsAllowed && (
                                        <OnClickMenu
                                            className="actions-button"
                                            title="Respond"
                                            kind="action"
                                            secondary
                                            customFromTop="2.6em"
                                            width="18em"
                                            alignRight
                                            customFromBottom={i >= props.disputesList.length - 2 ? '-0.9em' : ''}
                                        >
                                            {item.reason !== 'UNAUTHORISED' && (
                                                <MenuItem onClick={() => props.onOpenModal(item.id, 'message')}>
                                                    Send message
                                                </MenuItem>
                                            )}
                                            <MenuItem onClick={() => props.onOpenModal(item.id, 'refund')}>
                                                Refund
                                            </MenuItem>
                                            {item.reason === 'UNAUTHORISED' && (
                                                <MenuItem onClick={() => props.onOpenModal(item.id, 'reject')}>
                                                    Reject dispute
                                                </MenuItem>
                                            )}
                                        </OnClickMenu>
                                    )}
                                </Cell>
                            </DynamicRow>
                            <NestedContainerWrapper grade={0} className={expanded ? 'expanded' : ''}>
                                <SecondLevelHeaderRow className={!expanded ? 'hidden' : ''}>
                                    <TableHeader nonInteractive noPadding />
                                    <TableHeader nonInteractive noPadding />
                                    <TableHeader>Updated on</TableHeader>
                                    <TableHeader>Amount</TableHeader>
                                    <TableHeader>Order status</TableHeader>
                                    <TableHeader>Last updated by</TableHeader>
                                    <TableHeader>Opened on</TableHeader>
                                    <TableHeader nonInteractive />
                                </SecondLevelHeaderRow>
                                <SecondLevelTableRow className={!expanded ? 'hidden' : ''}>
                                    <Cell noPadding />
                                    <Cell noPadding />
                                    <Cell>{formatISOString(item.openedAt, DateFormats.LONG_DATE)}</Cell>
                                    <Cell>
                                        <Money amount={item.amount} accountSlug={props.accountSlug} />
                                    </Cell>
                                    <Cell title={statusesMap[item.order.status]?.text}>
                                        <span>{statusesMap[item.order.status]?.text}</span>
                                    </Cell>
                                    <Cell title={item.updatedBy}>
                                        <span>{item.updatedBy}</span>
                                    </Cell>
                                    <Cell>{formatISOString(item.updatedAt, DateFormats.LONG_DATE)}</Cell>
                                    <Cell />
                                </SecondLevelTableRow>
                            </NestedContainerWrapper>
                        </div>
                    )
                })}
        </InlineDataTable>
    )
}

export default withPermission(DisputesTable)
