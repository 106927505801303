import { useField } from 'formik'
import React from 'react'
import ButtonGroup from 'uiComponents/buttonGroup'
import { NumberInput, Radio } from 'uiComponents/input'
import FieldWrapper from 'uiComponents/input/fieldWrapper'
import RadioButton from 'uiComponents/input/radio/radioButton'
import { useGetComponentState } from 'uiComponents/input/_shared/hooks'
import { Currency, withCurrency } from 'uiComponents/money/moneyHoc'

interface ReductionSectionProps {
    accountSlug: string
    getCurrency: (accountSlug: string) => Currency
}

const ReductionSection = ({ accountSlug, getCurrency }: ReductionSectionProps) => {
    const [field, { error }] = useField('reductionRate')
    const [percentageField] = useField({ type: 'radio', name: 'rate', value: 'percentage' })
    const [flatField] = useField({ type: 'radio', name: 'rate', value: 'flat' })
    const state = useGetComponentState('reductionRate')
    const currency = getCurrency(accountSlug)

    return (
        <FieldWrapper label="Reduction" status={state} error={error}>
            <div style={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'nowrap' }}>
                <div style={{ position: 'relative' }}>
                    <NumberInput
                        {...field}
                        style={{ width: '6em', height: '34px' }}
                        maxLength={6}
                        prefix="-"
                        name="reductionRate"
                        status={state}
                        align="left"
                        placeholder={percentageField.checked ? '12.5' : '12.50'}
                    />
                </div>
                <ButtonGroup>
                    <Radio component={RadioButton} {...percentageField} style={{ width: '32px' }}>
                        %
                    </Radio>
                    <Radio component={RadioButton} {...flatField} style={{ width: '32px' }}>
                        {currency.symbol}
                    </Radio>
                </ButtonGroup>
            </div>
        </FieldWrapper>
    )
}

export default withCurrency(ReductionSection)
