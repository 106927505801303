import * as React from 'react'
import { usePrevious } from 'reactUtils'
import BarChart from 'uiComponents/charts/barChart'
import { StatsServiceContext } from 'http/context'
import { DateRange } from 'dateRanges'
import { format } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { areDateRangeDatesEqual, getTooltipRow, getTooltipHeader, getTooltipFooter, AxisTitle } from 'reports/helpers'
import { Filters } from 'uiComponents/filter/schema'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { DataTotal } from 'reports/schema'
import { QueryConfig } from 'reports/queryGenerator'
import { Headline } from 'uiComponents/typography'
import isEqual from 'lodash/isEqual'
import { withFeatures } from 'features'
import { CustomDataItem } from 'uiComponents/charts/styleComponents'

const queryConfig: QueryConfig = {
    querySetName: 'PricingDistributionChart',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'metric', type: 'StatsMetric' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'groupBy', type: 'String' },
        { name: 'filters', type: '[FilterDictionary]' },
    ],
    queries: [
        {
            name: 'priceDistribution',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'groupBy', 'filters'],
            customVariables: [],
            presetResult: 'totals',
        },
        {
            name: 'revenueShare',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'product_revenue_share' }],
            presetResult: 'totals',
        },
    ],
}

interface PriceDistributionChartProps {
    dateRange: DateRange
    filters: Filters[]
    accountSlug: string
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    getCurrency: (accountSlug: string) => Currency
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function PriceDistributionChart(props: PriceDistributionChartProps) {
    const _isMounted = React.useRef(false)
    const _lastRequest = React.useRef<number>()
    const statsService = React.useContext(StatsServiceContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [priceDistributionData, setPriceDistributionData] = React.useState<DataTotal[]>([])
    const [additionalData, setAdditionalData] = React.useState<number>(0)

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    const prevFilters = usePrevious(props.filters)
    React.useEffect(() => {
        _isMounted.current = true
        if (
            prevAccountSlug !== props.accountSlug ||
            !areDateRangeDatesEqual(prevDateRange, props.dateRange) ||
            !isEqual(prevFilters, props.filters)
        ) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug, props.dateRange, props.filters])

    const getData = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime
        const dateFrom = format(props.dateRange.from, 'yyyy-MM-dd')
        const dateTo = format(props.dateRange.to, 'yyyy-MM-dd')
        try {
            setLoading(true)
            const variables = {
                widget: props.accountSlug,
                metric: 'products_sold_by_event_date',
                dateFrom,
                dateTo,
                groupBy: 'item_price',
                filters: props.filters,
            }
            const data = await statsService.getStats(queryConfig, variables)
            if (_lastRequest.current !== requestTime) {
                return
            }
            const formatedData = formatData(data.priceDistribution.totals)
            const revenueShare =
                data.revenueShare.totals && data.revenueShare.totals.length > 0 ? data.revenueShare.totals[0].value : 0
            if (_isMounted.current) {
                setLoading(false)
                setPriceDistributionData(formatedData)
                setAdditionalData(revenueShare)
            }
        } catch {
            setLoading(false)
            props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Price distribution chart could not be loaded, please try again later.',
            )
        }
    }

    const formatData = (data: DataTotal[]) => {
        return data
            .sort((a, b) => Number(a.name) - Number(b.name))
            .map((d) => ({ value: d.value, name: formatMoney(d.name) }))
    }

    const formatMoney = (amount: string | number) => {
        return props.formatCurrencyString(Number(amount).toFixed(2), props.accountSlug)
    }

    const chartTooltipFormatter = (params: TooltipParams[]) => {
        let axisValue = params[0].axisValue
        let tooltip = getTooltipHeader(axisValue)
        tooltip += getTooltipRow([`${params[0].marker} Products sold:`, params[0].value])
        tooltip += getTooltipFooter()

        return tooltip
    }

    const priceDistributionSeries = [
        {
            name: '',
            type: 'bar',
            barWidth: '60%',
            data: priceDistributionData,
        },
    ]

    return (
        <div style={{ position: 'relative' }}>
            {loading && <ChartDataLoader />}
            <>
                {props.filters.length > 0 && !!additionalData && (
                    <CustomDataItem style={{ top: '0.2em', right: '0', maxWidth: '48em' }}>
                        Selected products make <span>{Math.round(additionalData * 10000) / 100}%</span> of revenue.
                    </CustomDataItem>
                )}
                <Headline size={4}>Price distribution</Headline>
                <AxisTitle title="Tickets" top="5.6em" left="0.5em" />
                <AxisTitle title="Ticket price" left="50%" bottom="-2em" style={{ transform: 'translateX(-50%)' }} />
                <BarChart
                    series={priceDistributionSeries}
                    gridTop={50}
                    gridBottom={20}
                    gridLeft={50}
                    gridRight={15}
                    loading={loading}
                    xAxisInterval="auto"
                    tooltipFormatter={chartTooltipFormatter}
                />
            </>
        </div>
    )
}

export default withFeatures(withCurrency(PriceDistributionChart))
