import React, { useState, useEffect, useContext, useRef } from 'react'
import { usePrevious } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { DataTotal } from 'reports/schema'
import BarChart from 'uiComponents/charts/barChart'
import { sortWeekdays, formatWeekDay } from '../helpers'
import { format, startOfToday, addDays } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { ChartHeadline, ChartContainer } from 'uiComponents/charts/styleComponents'
import Infotip from 'uiComponents/infotip'
import { getTooltipRow, getTooltipHeader, getTooltipFooter, formatWeekDayForTooltip } from 'reports/helpers'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import { QueryConfig } from 'reports/queryGenerator'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'

const queryConfig: QueryConfig = {
    querySetName: 'PopularDaysChart',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'metric', type: 'StatsMetric' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'groupBy', type: 'String' },
    ],
    queries: [
        {
            name: 'customGroupBy',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'groupBy'],
            customVariables: [],
            presetResult: 'totals',
        },
    ],
}

interface ChartsProps {
    accountSlug: string
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

function PopularDaysChart(props: ChartsProps) {
    const _isMounted = useRef(false)
    const _lastRequest = useRef<number>()
    const statsService = useContext(StatsServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [popularDaysData, setPopularDaysData] = useState<DataTotal[]>([])

    const prevAccountSlug = usePrevious(props.accountSlug)
    useEffect(() => {
        _isMounted.current = true
        if (prevAccountSlug !== props.accountSlug) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug])

    const getData = async () => {
        setLoading(true)
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        const dateFrom = format(addDays(startOfToday(), -14), 'yyyy-MM-dd')
        const dateTo = format(startOfToday(), 'yyyy-MM-dd')
        try {
            const variables = {
                widget: props.accountSlug,
                metric: 'visitors',
                dateFrom,
                dateTo,
                groupBy: 'weekday',
            }
            const data = await statsService.getStats(queryConfig, variables)

            if (_lastRequest.current !== requestTime) {
                return
            }
            const popularDays = data.customGroupBy.totals ? sortWeekdays(formatWeekDay(data.customGroupBy.totals)) : []
            if (_isMounted.current) {
                setLoading(false)
                setPopularDaysData(popularDays)
            }
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Some charts could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }

    const chartTooltipFormatter = (params: TooltipParams[]) => {
        let axisValue = formatWeekDayForTooltip(params[0].axisValue)
        let tooltip = getTooltipHeader(axisValue)
        tooltip += getTooltipRow([`${params[0].marker} Visitors:`, params[0].value])
        tooltip += getTooltipFooter()

        return tooltip
    }

    const popularDaysSeries = [
        {
            name: '',
            type: 'bar',
            barWidth: '60%',
            data: popularDaysData,
        },
    ]
    const navigationLink = `/account/${props.accountSlug}/reports/customers/${location.search}`

    return (
        <ChartContainer className="clickable-title">
            {loading && <ChartDataLoader />}
            <ChartHeadline size={4}>
                <span>
                    <UnstyledLink to={navigationLink}>Popular days</UnstyledLink>
                </span>
                <Infotip pointer="left" fixedMaxSize>
                    Visitors by weekday over the last 2 weeks. See more:{' '}
                    <span className="link">
                        <UnstyledLink to={navigationLink}>Customers report</UnstyledLink>
                    </span>
                </Infotip>
            </ChartHeadline>
            <BarChart
                series={popularDaysSeries}
                chartHeight="350px"
                gridBottom={20}
                gridLeft={45}
                gridTop={40}
                loading={loading}
                tooltipFormatter={chartTooltipFormatter}
            />
        </ChartContainer>
    )
}

export default PopularDaysChart
