import { ImageUploadResponse } from 'http/imagesService'
import { useUploadImageMutation } from 'http/reduxQuery'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { ActionButtonA } from 'uiComponents/buttons'
import { UploadInput, UploadResponse } from 'uiComponents/input'
import FieldWrapper from 'uiComponents/input/fieldWrapper'
import { Caption } from 'uiComponents/typography'
import { useSelectedParkMapContext } from 'venue/parkMap/context/selectedParkMap'
import { MessageKind } from '../../../../uiComponents/messages'
import { replaceMessage } from '../../../../uiComponents/messages/actions'
import { useParkMapViewerContext } from '../../context/mapViewer'
import { formatFileName } from '../../helpers'

export const ImageContainer = styled.div`
    background-color: ${(p) => p.theme.colors.background};
    border: 1px dashed ${(p) => p.theme.colors.textLight};
    border-radius: 4px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: space-between;
`

export const Image = styled.img`
    max-height: 60px;
    border-radius: 3px;
    margin-right: 8px;
`

export const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-top: 8px;
`

export const Button = styled(ActionButtonA)`
    margin: none;
`

const OverlayConfig = () => {
    const { selectedParkMap, setSelectedParkMap } = useSelectedParkMapContext()
    const { setOverlayVisibility } = useParkMapViewerContext()
    const overlay = selectedParkMap?.overlay
    const [image, setImage] = useState<string | null>(null)
    const [uploadImage] = useUploadImageMutation()
    const dispatch = useDispatch()

    useEffect(() => {
        if (overlay?.image && !image) {
            setImage(overlay.image)
        }
    }, [overlay])

    const onUpload = async (file: File) => {
        const response = (await uploadImage({ file })) as { data: ImageUploadResponse | undefined }
        return response.data as UploadResponse
    }

    const onRemove = () => {
        setImage(null)
        setSelectedParkMap((prev) => {
            if (prev && prev.overlay) {
                return { ...prev, overlay: { ...prev.overlay, image: null } }
            }
            return null
        })
        setOverlayVisibility(false)
    }

    const handleUploadSuccessResponse = useCallback(
        async (response: ImageUploadResponse) => {
            const url = response.url
            setImage(url)
            setSelectedParkMap((prev) => {
                if (prev && prev.overlay) {
                    return {
                        ...prev,
                        overlay: {
                            ...prev.overlay,
                            image: url,
                        },
                    }
                }
                return prev
            })
            setOverlayVisibility(true)
        },
        [selectedParkMap],
    )

    const replaceTopMessages = (id: string, status: MessageKind, text: string) => {
        dispatch(replaceMessage(id, status, text, 5000))
    }

    const removeAllMessages = () => {
        dispatch(removeAllMessages())
    }

    return (
        <FieldWrapper
            label={
                !!image
                    ? 'Image uploaded'
                    : 'Upload custom park map image, Max. resolution allowed: 4000x4000, file size: 5MB. If the jpg file is not working, use png.'
            }
            id="upload-image"
            status="normal"
        >
            {!!image ? (
                <ImageContainer>
                    <Image src={image} />
                    <div>
                        <Caption style={{ wordBreak: 'break-word' }}>
                            <div title={image}>{formatFileName(image, 60)}</div>
                        </Caption>
                        <Buttons>
                            <Button onClick={onRemove} kind="destructive" secondary>
                                Remove
                            </Button>
                        </Buttons>
                    </div>
                </ImageContainer>
            ) : (
                <UploadInput
                    type="img"
                    onUpload={onUpload}
                    handleUploadSuccessResponse={handleUploadSuccessResponse}
                    inputId="map"
                    status="normal"
                    fileSizeLimit={5}
                    replaceTopMessages={replaceTopMessages}
                    removeAllMessages={removeAllMessages}
                    uploadArea={true}
                />
            )}
        </FieldWrapper>
    )
}

export default OverlayConfig
