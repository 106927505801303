import React, { useCallback, useState } from 'react'
import ConfirmationModal from './confirmationModal'
import RefundTableModal from './refundTableModal'
import { RefundForm } from './interface'
import { IOrder } from 'orders/schema'
import { ReplySolid, TimesSolid } from '@convious/icons'
import { Button } from '@mui/material'
import { useHasFeature } from 'utils/useHasFeature'

interface BulkOrderCancelRefundProps {
    selectedOrderRows: IOrder[]
}

const BulkOrderCancelRefund = ({ selectedOrderRows }: BulkOrderCancelRefundProps) => {
    const [initialRowSelected, setInitialRowsSelected] = useState(selectedOrderRows)
    const [open, setOpen] = useState(false)
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
    const [refundForm, setRefundForm] = useState<RefundForm>()
    const [cancellation, setCancellation] = useState(false)
    const hasBulkRefundFeature = useHasFeature({ featureName: 'ordersBulkRefund' })

    const onConfirm = useCallback((refundFormValues) => {
        setOpen(false)
        setRefundForm(refundFormValues)
        setConfirmationModalOpen(true)
    }, [])

    const openModal = () => {
        setCancellation(false)
        setRefundForm(undefined)
        setInitialRowsSelected(selectedOrderRows)
        setOpen(true)
    }

    const openModalInCancellationMode = () => {
        setCancellation(true)
        setRefundForm(undefined)
        setInitialRowsSelected(selectedOrderRows)
        setOpen(true)
    }

    if (!hasBulkRefundFeature) {
        return null
    }

    return (
        <>
            {confirmationModalOpen && (
                <ConfirmationModal
                    cancellation={cancellation}
                    refundForm={refundForm as RefundForm}
                    closeConfirmationModal={() => {
                        setConfirmationModalOpen(false)
                    }}
                    openEditDialog={() => {
                        setConfirmationModalOpen(false)
                        setOpen(true)
                    }}
                />
            )}
            {open && (
                <RefundTableModal
                    cancellation={cancellation}
                    orders={initialRowSelected}
                    initialData={refundForm}
                    setRefundDialogOpen={setOpen}
                    handleSubmit={onConfirm}
                />
            )}
            <Button
                onClick={openModal}
                size="medium"
                color="error"
                variant="outlined"
                startIcon={<ReplySolid />}
                className="action-button"
                data-testid="bulk-refund-button"
            >
                Bulk refund
            </Button>
            <Button
                onClick={openModalInCancellationMode}
                size="medium"
                color="error"
                variant="outlined"
                startIcon={<TimesSolid />}
                className="action-button"
                data-testid="bulk-cancel-button"
            >
                Bulk cancel
            </Button>
        </>
    )
}

export default BulkOrderCancelRefund
