import styleIcon from 'uiComponents/studio/icons/style.svg'
import copyIcon from 'uiComponents/studio/icons/copy.svg'
import restrictionsIcon from 'uiComponents/studio/icons/target.svg'
import settingsIcon from 'uiComponents/studio/icons/settings.svg'
import { SectionObject } from 'uiComponents/studio/menuItem'
import { fontOptions } from '../shared'

export interface FormDefinitionSchema {
    text: SectionObject
    style: SectionObject
    targeting: SectionObject
    settings: SectionObject
}

export const TriggerButtonFormDefinition: FormDefinitionSchema = {
    text: {
        name: 'text',
        label: '1. Text',
        header: 'Create your Message',
        icon: copyIcon,
        description: 'Create and edit your Trigger Button text and all corresponding translations.',
        sections: [
            {
                name: 'Languages',
                fields: [],
            },
        ],
    },
    style: {
        name: 'style',
        label: '2. Style',
        header: 'Design your Look & Feel',
        icon: styleIcon,
        description:
            "To start, we use a default style and default colors for your Trigger Button, but you can customize them below to match your website's look and feel.",
        sections: [
            {
                name: 'General',
                fields: [
                    {
                        name: 'font-family',
                        type: 'select',
                        availableOptions: [...fontOptions],
                        label: 'Font-family',
                        span: 8,
                        path: 'styling.fontFamily',
                        defaultValue: 'Roboto',
                    },
                    {
                        name: 'font-size',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'Small',
                                value: 'small',
                            },
                            {
                                name: 'Medium',
                                value: 'medium',
                            },
                            {
                                name: 'Large',
                                value: 'large',
                            },
                        ],
                        label: 'Text size',
                        span: 8,
                        path: 'styling.tbFontSize',
                        defaultValue: 'medium',
                    },
                    {
                        name: 'font-color',
                        type: 'picker',
                        label: 'Font color',
                        span: 6,
                        path: 'styling.fontColor',
                        defaultValue: '',
                    },
                    {
                        name: 'background-color',
                        type: 'picker',
                        label: 'Background color',
                        span: 6,
                        path: 'styling.bgColor',
                        defaultValue: '',
                    },
                    {
                        name: 'background-opacity',
                        type: 'select',
                        label: 'Background opacity',
                        span: 6,
                        path: 'styling.bgOpacity',
                        availableOptions: [
                            {
                                name: 'Opaque',
                                value: '1',
                            },
                            {
                                name: 'Transparent',
                                value: '0.8',
                            },
                        ],
                        defaultValue: '1',
                    },
                ],
            },
            {
                name: 'Icon',
                fields: [
                    {
                        name: 'icon-source',
                        type: 'upload',
                        label: '',
                        span: 12,
                        path: 'styling.iconButton.iconSource',
                        defaultValue: '',
                    },
                    {
                        name: 'icon-color',
                        type: 'picker',
                        label: 'SVG icon color',
                        span: 6,
                        path: 'styling.iconButton.iconColor',
                        defaultValue: '',
                    },
                    {
                        name: 'background-color',
                        type: 'picker',
                        label: 'Background color',
                        span: 6,
                        path: 'styling.iconButton.bgColor',
                        defaultValue: '',
                    },
                ],
            },
            {
                name: "Minimize icon 'x'",
                fields: [
                    {
                        name: 'minimize',
                        type: 'toggle',
                        label: 'Enable minimizing',
                        span: 6,
                        path: 'xButtonVisible',
                        defaultValue: true,
                        infotip: "When enabled, the user can close the Trigger Button by clicking on the 'x' icon",
                    },
                    {
                        name: 'x-button-size',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'Small',
                                value: 'small',
                            },
                            {
                                name: 'Medium',
                                value: 'medium',
                            },
                            {
                                name: 'Large',
                                value: 'large',
                            },
                        ],
                        label: 'Icon size',
                        span: 6,
                        path: 'styling.xButton.size',
                        defaultValue: 'small',
                    },
                    {
                        name: 'x-button-type',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'Light',
                                value: 'light',
                            },
                            {
                                name: 'Dark',
                                value: 'dark',
                            },
                        ],
                        label: 'Icon type',
                        span: 6,
                        path: 'styling.xButton.type',
                        defaultValue: 'light',
                    },
                ],
            },
        ],
    },
    targeting: {
        name: 'targeting',
        label: '3. Targeting',
        header: 'Set your Restrictions',
        icon: restrictionsIcon,
        description: 'Choose the pages you want your Trigger Button to be shown and/or excluded.',
        sections: [
            {
                name: 'Audience',
                fields: [],
            },
            {
                name: 'Display on',
                infotip: 'Decide on which devices you want to show your Trigger Button.',
                fields: [
                    {
                        name: 'display',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'all devices',
                                value: 'all',
                            },
                            {
                                name: 'only mobile/tablet',
                                value: 'mobile',
                            },
                            {
                                name: 'only desktop',
                                value: 'desktop',
                            },
                        ],
                        label: '',
                        span: 12,
                        path: 'target',
                        defaultValue: 'all',
                    },
                ],
            },
            {
                name: 'Blacklist',
                label: 'Hide your Trigger Button on (optional)',
                infotip: 'Define which pages of your website will not show the Trigger Button.',
                fields: [],
            },
        ],
    },
    settings: {
        name: 'settings',
        label: '4. Settings',
        header: 'Set your Visibility Rules',
        icon: settingsIcon,
        description: 'Define and apply simple rules on how your Trigger Button displays to your audience.',
        sections: [
            {
                name: 'Display rules',
                fields: [
                    {
                        name: 'display-trigger',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'Upon page load',
                                value: 'onLoad',
                            },
                            {
                                name: 'With delay',
                                value: 'afterDelay',
                            },
                            {
                                name: 'On exit intent',
                                value: 'exitIntent',
                            },
                            {
                                name: 'On 50% scroll down',
                                value: 'scrollDown50',
                            },
                            {
                                name: 'On scroll up',
                                value: 'scrollUp',
                            },
                        ],
                        label: 'Display',
                        span: 8,
                        path: 'trigger',
                        defaultValue: 'afterDelay',
                    },
                    {
                        name: 'show-after',
                        type: 'number',
                        min: 0,
                        max: 120,
                        unit: 'sec',
                        label: 'Show after',
                        span: 8,
                        path: 'displayDelayPeriod',
                        defaultValue: 5,
                    },
                    {
                        name: 'position',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'Bottom-left',
                                value: 'bottom-left',
                            },
                            {
                                name: 'Bottom-right',
                                value: 'bottom-right',
                            },
                            {
                                name: 'Top-left',
                                value: 'top-left',
                            },
                            {
                                name: 'Top-right',
                                value: 'top-right',
                            },
                        ],
                        label: 'Position',
                        span: 8,
                        path: 'tbPosition',
                        defaultValue: 'bottom-left',
                    },
                ],
            },
        ],
    },
}
