import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faMobileAndroidAlt, faLaptop, faPhoneLaptop } from '@fortawesome/pro-solid-svg-icons'
import styled from 'styled-components'
import { Cell } from 'uiComponents/table'

export const TARGET_DEVICES = [
    {
        id: 'any',
        icon: faPhoneLaptop as IconProp,
    },
    {
        id: 'desktop',
        icon: faLaptop as IconProp,
    },
    {
        id: 'mobile',
        icon: faMobileAndroidAlt as IconProp,
    },
    {
        id: 'total',
        icon: faPhoneLaptop as IconProp,
    },
]

type DeviceType = typeof TARGET_DEVICES[number]['id']
type Device = typeof TARGET_DEVICES[number]

export const getDevice = (type?: DeviceType): Device => {
    const device = TARGET_DEVICES.find((dev) => dev.id === type)

    if (device) {
        return device
    }

    return getDevice('any')
}

export const NameCell = styled(Cell)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`
