import React, { FC, useCallback } from 'react'
import Slider, { SliderProps } from '@mui/material/Slider'
import Box, { BoxProps } from '@mui/material/Box'
import TooltipWrapper from 'uiComponents/tooltip/tooltipWrapper'
import { Caption } from 'uiComponents/typography'
import { SliderTick } from './types'
import { SliderRail } from './rail'
import './index.scss'

export type SliderInputProps = SliderProps & {
    name: string
    minLabel?: string
    maxLabel?: string
    recommendedValue?: number
    recommendedValueDescription?: string
    containerProps?: BoxProps
}

const SliderInput: FC<SliderInputProps> = ({
    name,
    min,
    max,
    step,
    value,
    onChange,
    style,
    minLabel,
    maxLabel,
    recommendedValue,
    recommendedValueDescription,
    marks = true,
    orientation = 'horizontal',
    containerProps,
    ...sliderProps
}) => {
    const generateMarks = useCallback(() => {
        if (!marks || !min || !max) {
            return false
        }

        const ticks: SliderTick[] = []

        for (let i = min; i <= max; i += step || 1) {
            ticks.push({
                value: i,
                label:
                    i === recommendedValue ? (
                        <TooltipWrapper
                            delayShow={500}
                            text={recommendedValueDescription}
                            className="convious-slider__recommended-tick-marker"
                        />
                    ) : undefined,
            })
        }

        return ticks
    }, [recommendedValue])

    return (
        <Box padding={orientation === 'horizontal' ? '0 0.25rem' : '0'} {...containerProps}>
            <Slider
                name={name}
                min={min}
                max={max}
                step={step}
                value={value || 0}
                style={style}
                orientation={orientation}
                onChange={onChange}
                marks={generateMarks()}
                className="convious-slider"
                components={{
                    Rail: () => (
                        <SliderRail min={min} max={max} orientation={orientation} recommendedValue={recommendedValue} />
                    ),
                }}
                {...sliderProps}
            />

            <Box display="flex" justifyContent="space-between">
                <Caption color="light">{minLabel}</Caption>
                <Caption color="light">{maxLabel}</Caption>
            </Box>
        </Box>
    )
}

export default SliderInput
