import { BaseRouteParams } from 'hocs'
import { history } from 'middleware'
import { getSelectedUnredeemedOrderDetails } from 'orders/orders/redux'
import { useRedeemBarcodesMutation } from 'orders/reduxQueries'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'

const Redeem = () => {
    const [redeemBarcodes, { isLoading }] = useRedeemBarcodesMutation()
    const location = useLocation()
    const { accountSlug } = useParams<BaseRouteParams>()
    const selectedItems = useSelector(getSelectedUnredeemedOrderDetails)
    const goBack = () => history.push(location.pathname.replace('/redeem', '') + location.search)

    return (
        <ConfirmationDialog
            loading={isLoading}
            title={`Redeem ${selectedItems.length} barcode?`}
            text="Redeeming can't be undone!"
            buttonText="Redeem"
            onCancel={goBack}
            onConfirm={() =>
                redeemBarcodes({
                    accountSlug,
                    barcodes: selectedItems
                        .map((item) => item.barcode?.barcode)
                        .filter((barcode) => !!barcode) as string[],
                })
            }
        />
    )
}

export default Redeem
