import React from 'react'
import styled, { keyframes } from 'styled-typed'
import { Navigation } from 'navigation'
import { PageLoader } from 'uiComponents/loaders'
import Svg from 'svg'
import linkIcon from './icons/link.svg'
import conviousPattern from './icons/convious-pattern@2x.png'
import { CONTAINER_BOX_SHADOW } from 'uiComponents/settingsContainer'

const appear = keyframes`
  from { transform: scale(0, 0) translate(-50%, -50%);}
  to { transform: scale(1, 1) translate(-50%, -50%);}
`

const Container = styled.div`
    font-family: ${(props) => props.theme.fonts.primary};
    color: ${(props) => props.theme.colors.textDark};
    position: absolute;
    left: 50%;
    top: 50%;
    align-items: center;
    animation: ${appear} 0.2s ease-in;
    animation-fill-mode: forwards;
    animation-duration: 0.2s;
    transform-origin: top left;
    background: ${(props) => props.theme.colors.white};
    border-radius: 0.625em;
    padding: 2em 2em 2.6em 2em;
    box-shadow: ${CONTAINER_BOX_SHADOW};
    min-height: 350px;
    min-width: 400px;

    @media screen and (min-height: 700px) {
        top: 40%;
    }
`

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Text = styled.div`
    text-align: center;
    line-height: 1.35em;
    max-width: 17em;
`
const Message = styled.div`
    margin-top: 0.3em;
    font-size: 0.875em;
    font-weight: 300;
    line-height: 1.35em;
`
const IconContainer = styled.div`
    margin: 0.7em 0 2.5em 0;
    width: 7.5em;
`
const ConviousPattern = styled.img`
    width: 100%;
    height: 10%;
`

interface PassTokenValidationPageProps {
    navigation: Navigation
    match: any
    loginService: any
    history: any
}

interface PassTokenValidationPageState {
    validatingToken: boolean
}

class PassTokenValidationPage extends React.Component<PassTokenValidationPageProps, PassTokenValidationPageState> {
    constructor(props: PassTokenValidationPageProps) {
        super(props)
        this.state = {
            validatingToken: true,
        }
    }

    async componentDidMount() {
        try {
            const username = decodeURIComponent(this.props.navigation.query().username)
            const token = this.props.navigation.query().pass_token
            const loginSuccessful = await this.props.loginService.loginWithNewUserPassToken(username, token)
            if (loginSuccessful) {
                this.props.history.replace('/new_user/welcome/' + location.search)
            } else {
                this.setState({ validatingToken: false })
            }
        } catch {
            this.setState({ validatingToken: false })
        }
    }

    render() {
        return (
            <>
                <ConviousPattern src={conviousPattern} />
                <Container>
                    {this.state.validatingToken && <PageLoader style={{ marginTop: '3em' }} />}
                    {!this.state.validatingToken && (
                        <MessageContainer>
                            <IconContainer>
                                <Svg src={linkIcon} />
                            </IconContainer>
                            <Text>
                                The link you were given has expired.
                                <Message>
                                    Please contact the admin who created your account to resend the confirmation email.
                                </Message>
                            </Text>
                        </MessageContainer>
                    )}
                </Container>
            </>
        )
    }
}

export default PassTokenValidationPage
