import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { BaseRouteParams } from 'hocs'
import { history } from 'middleware'
import { ModalDialog } from 'uiComponents/popups/modal'
import { pathUtils } from 'utils/pathnameFormatter'
import { DiscountPool } from 'venue/bookingCodes/bookingCodesService'
import { CODES_PATHS } from 'venue/bookingCodes/paths'
import AddPoolForm from './addPoolForm'
import AddPoolSuccessful from './addPoolSuccessful'

const AddPool: React.FC = () => {
    const { search } = useLocation()
    const { accountSlug } = useParams<BaseRouteParams>()
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [pool, setPool] = useState<DiscountPool>()

    const closePool = () => {
        history.push(
            pathUtils.populateParams(pathUtils.addQueryString(CODES_PATHS.fullPaths.poolsPage, search), {
                accountSlug,
            }),
        )
    }

    return (
        <ModalDialog onDismiss={closePool} interactive style={{ width: 576 }}>
            {showSuccess ? (
                <AddPoolSuccessful pool={pool} />
            ) : (
                <AddPoolForm
                    onPoolAdd={(pool) => {
                        setPool(pool)
                        setShowSuccess(true)
                    }}
                />
            )}
        </ModalDialog>
    )
}

export default AddPool
