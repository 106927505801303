import React from 'react'
import styledComponents from 'styled-components'

const Container = styledComponents.div<any>`
    border-radius: 4px;
    display: flex;

    .radio__button:first-child .radio__button__child {
        border-radius: 4px 0px 0px 4px;
    }

    .radio__button:last-child .radio__button__child {
        border-radius: 0px 4px 4px 0px;
    }

    .radio__button:nth-child(n+2) .radio__button__child {
        border-left: 0px;
    }
`

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    children: any
}

const ButtonGroup: React.FC<Props> = ({ children, ...rest }) => {
    return <Container {...rest}>{children}</Container>
}

export default ButtonGroup
