import * as React from 'react'
import { usePrevious } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { format } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { getComparisonColorFromPercentString, checkRequiredCapacityFiltersPresent } from 'reports/helpers'
import { ChartRow } from 'uiComponents/charts/styleComponents'
import { QueryConfig } from 'reports/queryGenerator'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { chartRowsGap } from 'reports/helpers'
import { DateRange } from 'dateRanges'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { isBefore, startOfToday, addDays, differenceInCalendarDays } from 'date-fns'
import { Filters } from 'uiComponents/filter/schema'
import isEqual from 'lodash/isEqual'
import { ComparisonDataNumber } from 'uiComponents/charts/dataNumber'
import Infotip from 'uiComponents/infotip'

const queryConfig: QueryConfig = {
    querySetName: 'SingleDataItemsCustomersReport',
    variablesConfig: [
        { name: 'accountSlug', type: 'String' },
        { name: 'metric', type: 'StatsMetric' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'today', type: 'Date' },
        { name: 'tomorrow', type: 'Date' },
        { name: 'nextWeekEnd', type: 'Date' },
        { name: 'comparisonType', type: 'ComparisonTypes' },
        { name: 'timeFilter', type: 'StatsTimeFilter' },
        { name: 'delayType', type: 'DelayTypes' },
        { name: 'delay', type: 'Int' },
        { name: 'filters', type: '[FilterDictionary]' },
    ],
    queries: [
        {
            name: 'selectedPeriod',
            type: 'comparisonMetrics',
            configVariables: [
                'accountSlug',
                'metric',
                'dateFrom',
                'dateTo',
                'comparisonType',
                'timeFilter',
                'delayType',
                'delay',
                'filters',
            ],
            customVariables: [],
            presetResult: 'totalsComparison',
        },
        {
            name: 'today',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'metric', 'comparisonType', 'delay', 'filters'],
            customVariables: [
                { name: 'dateFrom', configValue: 'today' },
                { name: 'dateTo', configValue: 'today' },
                { name: 'timeFilter', customValue: 'this_time_so_far' },
                { name: 'delayType', customValue: 'weeks' },
            ],
            presetResult: 'totalsComparison',
        },
        {
            name: 'tomorrow',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'metric', 'comparisonType', 'delay', 'filters'],
            customVariables: [
                { name: 'dateFrom', configValue: 'tomorrow' },
                { name: 'dateTo', configValue: 'tomorrow' },
                { name: 'timeFilter', customValue: 'next_7_days_so_far' },
                { name: 'delayType', customValue: 'weeks' },
            ],
            presetResult: 'totalsComparison',
        },
        {
            name: 'nextWeek',
            type: 'comparisonMetrics',
            configVariables: ['accountSlug', 'metric', 'comparisonType', 'delay', 'filters'],
            customVariables: [
                { name: 'dateFrom', configValue: 'tomorrow' },
                { name: 'dateTo', configValue: 'nextWeekEnd' },
                { name: 'timeFilter', customValue: 'next_1p7_days_so_far' },
                { name: 'delayType', customValue: 'weeks' },
            ],
            presetResult: 'totalsComparison',
        },
    ],
}

interface SingleDataItemsProps {
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    dateRange: DateRange
    filters: Filters[]
    loading: boolean
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

function SingleDataItems(props: SingleDataItemsProps) {
    const _isMounted = React.useRef(false)
    const _lastRequest = React.useRef<number>()
    const statsService = React.useContext(StatsServiceContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [timePeriodLength, setTimePeriodLength] = React.useState<number>(0)
    const [today, setToday] = React.useState<string>('0')
    const [tomorrow, setTomorrow] = React.useState<string>('0')
    const [nextWeek, setNextWeek] = React.useState<string>('0')
    const [total, setTotal] = React.useState<string>('0')
    const [comparisonToday, setComparisonToday] = React.useState<string>('-')
    const [comparisonTomorrow, setComparisonTomorrow] = React.useState<string>('-')
    const [comparisonNextWeek, setComparisonNextWeek] = React.useState<string>('-')
    const [comparisonTotal, setComparisonTotal] = React.useState<string>('-')

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    const prevFilters = usePrevious(props.filters)
    React.useEffect(() => {
        _isMounted.current = true
        if (
            (prevAccountSlug !== props.accountSlug && !isEqual(prevFilters, props.filters)) ||
            !areDateRangeDatesEqual(prevDateRange, props.dateRange) ||
            !isEqual(prevFilters, props.filters)
        ) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug, props.dateRange, props.filters])

    const getData = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        if (!checkRequiredCapacityFiltersPresent(props.filters)) {
            return
        }
        setLoading(true)
        const dateRangeLength = differenceInCalendarDays(props.dateRange.to, props.dateRange.from) + 1
        setTimePeriodLength(dateRangeLength)
        const todayDate = startOfToday()
        const tomorrowDate = addDays(todayDate, 1)
        const nextWeekEndDate = addDays(tomorrowDate, 6)
        try {
            const todayIncluded = isBefore(startOfToday(), props.dateRange.to)
            const variables = {
                accountSlug: props.accountSlug,
                metric: 'items_with_capacity_limits',
                dateFrom: format(props.dateRange.from, 'yyyy-MM-dd'),
                dateTo: format(props.dateRange.to, 'yyyy-MM-dd'),
                today: format(todayDate, 'yyyy-MM-dd'),
                tomorrow: format(tomorrowDate, 'yyyy-MM-dd'),
                nextWeekEnd: format(nextWeekEndDate, 'yyyy-MM-dd'),
                comparisonType: 'percentage_growth',
                timeFilter: todayIncluded ? 'this_time_so_far' : null,
                delayType: dateRangeLength <= 7 ? 'weeks' : 'previous_stretch',
                delay: 1,
                filters: props.filters,
            }
            const data = await statsService.getStats(queryConfig, variables)

            if (_lastRequest.current !== requestTime) {
                return
            }

            if (_isMounted.current) {
                setToday(data.today.totals?.value ? Number(data.today.totals?.value).toFixed(0) : '0')
                setTomorrow(data.tomorrow.totals?.value ? Number(data.tomorrow.totals?.value).toFixed(0) : '0')
                setNextWeek(data.nextWeek.totals?.value ? Number(data.nextWeek.totals?.value).toFixed(0) : '0')
                setTotal(data.selectedPeriod.totals?.value ? Number(data.selectedPeriod.totals?.value).toFixed(0) : '0')
                setComparisonToday(data.today.totals?.change || '-')
                setComparisonTomorrow(data.tomorrow.totals?.change || '-')
                setComparisonNextWeek(data.nextWeek.totals?.change || '-')
                setComparisonTotal(data.selectedPeriod.totals?.change || '-')
                setLoading(false)
            }
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Some big data numbers could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }

    const getPlusSign = (change: string) => {
        const value = Number(change.split('%')[0])
        return value > 0 ? '+' : ''
    }

    const getComparison = (value: string, dynamicText: boolean = false) => {
        const text = dynamicText ? (timePeriodLength <= 7 ? 'WoW' : 'prev. period') : 'WoW'
        return (
            <>
                <span className={getComparisonColorFromPercentString(value)}>
                    {getPlusSign(value)}
                    {value} {text}
                </span>
                {text === 'WoW' && (
                    <Infotip pointer="left" maxWidth="23em">
                        Week over Week (or WoW) means comparing data from one week to the previous week.
                    </Infotip>
                )}
            </>
        )
    }

    return (
        <div style={{ position: 'relative' }}>
            {(loading || props.loading) && <ChartDataLoader topOffset="0" />}
            <ChartRow style={{ marginBottom: chartRowsGap }}>
                <ComparisonDataNumber
                    value={today}
                    comparison={getComparison(comparisonToday)}
                    title="Sold for today"
                />
                <ComparisonDataNumber
                    value={tomorrow}
                    comparison={getComparison(comparisonTomorrow)}
                    title="Sold for tomorrow"
                />
                <ComparisonDataNumber
                    value={nextWeek}
                    comparison={getComparison(comparisonNextWeek)}
                    title="Sold for next week"
                />
                <ComparisonDataNumber
                    value={total}
                    comparison={getComparison(comparisonTotal, true)}
                    title="Sold in total"
                />
            </ChartRow>
        </div>
    )
}

export default withNavigation(SingleDataItems)
