import React from 'react'
import './index.scss'

const LongTextWithTooltip: React.FC<{ text: string }> = ({ text, children }) => {
    return (
        <div id="long-text-tooltip-wrapper">
            <span title={text} className="long-text-tooltip">
                {children}
            </span>
        </div>
    )
}

export default LongTextWithTooltip
