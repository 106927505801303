import { IconProp } from '@fortawesome/fontawesome'
import { faUndo } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useField, useFormikContext } from 'formik'
import React, { useState } from 'react'
import styled from 'styled-typed'
import ActionButton from 'uiComponents/buttons'
import { InputCard } from 'uiComponents/cards'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { Caption, Headline } from 'uiComponents/typography'
import { useGetPricingModels } from './pricingModels'
import { useGetDefaultIndustrySettingsQuery } from '../../products/pricing/reduxQueries'
import { GlobalAccountSettings } from '../../products/pricing/pricingService'

const ResetIcon = styled(FontAwesomeIcon)`
    height: 0.8rem;
    font-weight: lighter;
    margin-right: 0.2rem;
`

const Container = styled(InputCard)`
    padding: 1.5rem;
    display: flex;
    align-items: center;
`

const LeftContainer = styled.div`
    flex: 1;
`

const ResetToDefault = () => {
    const [open, setOpen] = useState<Boolean>()
    const [{ value }] = useField('pricingGoal')
    const pricingGoalOptions = useGetPricingModels()
    const { data: industryDefaults } = useGetDefaultIndustrySettingsQuery({
        modelId: value || pricingGoalOptions?.[0]?.value,
        version: 'V2',
    })
    const { setFieldValue } = useFormikContext()

    return (
        <>
            {open && (
                <ConfirmationDialog
                    onConfirm={() => {
                        Object.keys(industryDefaults || {}).forEach((key: keyof GlobalAccountSettings) => {
                            if (key === 'earlyBookingsImpact') {
                                return setFieldValue('earlyBookingsImpact', industryDefaults?.[key])
                            }
                            if (key === 'lateBookingsImpact') {
                                return setFieldValue('lateBookingsImpact', industryDefaults?.[key])
                            }

                            setFieldValue(key, industryDefaults?.[key])
                        })

                        setOpen(false)
                    }}
                    title="Reset to industry defaults?"
                    destructive
                    buttonText="Reset"
                    onCancel={() => setOpen(false)}
                />
            )}
            <Container>
                <LeftContainer>
                    <Headline style={{ margin: 0 }} size={6}>
                        Reset all to default settings
                    </Headline>
                    <Caption>All custom settings will be reverted</Caption>
                </LeftContainer>
                <ActionButton
                    onClick={() => {
                        setOpen(true)
                    }}
                    style={{ display: 'flex', flexWrap: 'nowrap' }}
                    kind="action"
                >
                    <ResetIcon icon={faUndo as IconProp} />
                    Reset
                </ActionButton>
            </Container>
        </>
    )
}

export default ResetToDefault
