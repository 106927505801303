import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FieldArray, FieldArrayRenderProps } from 'formik'
import { get } from 'lodash'

import ReactTable from './reactTable'
import { ReactTableInstance, ReactTableProps, ReactTableStateReducer } from './interface'
import { ValidationMessage } from 'auth/components'

const getValue = (valueField: string | number | Function, option: any) => {
    if (!option) {
        return
    }

    if (typeof valueField === 'function') {
        return valueField(option)
    }

    return option[valueField]
}

interface FormikReactTableFieldWrapperProps<D extends object = {}>
    extends Omit<ReactTableProps<D>, 'navigation' | 'match'> {
    name: string
    valueField: string | number | Function
}

const FormikReactTableFieldWrapper: React.FC<FormikReactTableFieldWrapperProps> = ({ name, ...props }) => {
    return (
        <FieldArray name={name}>
            {(formProps) => {
                const error = formProps.form.errors[name]

                return (
                    <>
                        <FormikReactTableField {...props} formProps={formProps} />
                        <ValidationMessage className="validation-message-visible">{error}</ValidationMessage>
                    </>
                )
            }}
        </FieldArray>
    )
}

interface FormikReactTableFieldProps<D extends object = {}> extends Omit<ReactTableProps<D>, 'navigation' | 'match'> {
    formProps: FieldArrayRenderProps
    valueField: string | number | Function
}

const FormikReactTableField: React.FC<FormikReactTableFieldProps> = ({ formProps, valueField, ...props }) => {
    const [tableInstance, setTableInstance] = useState<ReactTableInstance>()
    const initiatedInstance = useRef(false)
    const initialValues = get(formProps.form.initialValues, formProps.name)
    const stateReducer = useCallback<ReactTableStateReducer>((state, action, _, _instance) => {
        setTableInstance(_instance as ReactTableInstance)

        if (['toggleRowSelected', 'toggleAllRowsSelected'].includes(action.type)) {
            const _selectedRows = Object.keys(state?.selectedRowIds || {})
                .filter((key) => state?.selectedRowIds[key])
                .map((key) => _instance?.rowsById?.[key]?.original)

            formProps.form.setFieldValue(formProps.name, _selectedRows, false)
        }

        if (action.type === 'resetSelectedRows') {
            formProps.form.setFieldValue(formProps.name, [], false)
        }

        return state
    }, [])

    useEffect(() => {
        initiatedInstance.current = false
    }, [initialValues])

    useEffect(() => {
        if (!!tableInstance && !initiatedInstance.current) {
            const normalizedRows = {}

            tableInstance.rows.forEach((row) => {
                const rowOption = getValue(valueField, row.original)
                normalizedRows[rowOption] = row
            })

            initialValues?.forEach((option: any) => {
                const optionValue = getValue(valueField, option)
                const row = normalizedRows[optionValue]

                if (!!row) {
                    tableInstance.toggleRowSelected(row.id, true)
                }
            })

            initiatedInstance.current = true
        }
    }, [initialValues, props.data, valueField])

    return <ReactTable {...props} stateReducer={stateReducer} />
}

export default FormikReactTableFieldWrapper
