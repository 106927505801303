import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { LoaderWrapper } from 'uiComponents/loaders'
import { Category } from 'venue/parkMap/models'
import { ParkMapPagesParams } from 'venue/parkMap/paths'
import { useParkMapCategoryQuery, useUpdateCategoryMutation } from 'venue/parkMap/reduxQuery'
import CategoryForm from './categoryForm'

const EditCategory = () => {
    const { categoryId } = useParams<ParkMapPagesParams>()
    const { data, isLoading } = useParkMapCategoryQuery({ categoryId })
    const [updateCategory] = useUpdateCategoryMutation()
    const history = useHistory()

    return (
        <LoaderWrapper loading={isLoading}>
            <CategoryForm
                pageTitle="Edit Category"
                data={data}
                onSubmit={(category: Category) => {
                    updateCategory({ category: category })
                        .unwrap()
                        .then(() => history.goBack())
                }}
            />
        </LoaderWrapper>
    )
}

export default React.memo(EditCategory)
