import { Dispatch, Reducer, useMemo, useReducer } from 'react'
import { startOfToday } from 'date-fns'
import { getEndDate, getStartDate } from 'products/utils'

type Action =
    | { type: 'startAt'; payload: Date }
    | { type: 'endAt'; payload: Date }
    | { type: 'category'; payload: string }
    | { type: 'article'; payload?: string }

interface PreviewReducerState {
    startAt: Date
    endAt: Date
    category?: string
    article?: string
}

const previewReducer: Reducer<PreviewReducerState, Action> = (state, action) => {
    return {
        ...state,
        [action.type]: action.payload,
    }
}

export const useHandlePreviewFieldsState = (): [PreviewReducerState, Dispatch<Action>] => {
    const now = useMemo(() => startOfToday(), [])

    const [state, dispatch] = useReducer<typeof previewReducer>(previewReducer, {
        startAt: getStartDate(now),
        endAt: getEndDate(now),
        article: '',
        category: '',
    })

    return [state, dispatch]
}
