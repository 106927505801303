import React, { useState, useEffect } from 'react'
import { Col } from 'uiComponents/flex'
import Infotip from 'uiComponents/infotip'
import { ToggleComponent } from 'uiComponents/studio/form/fieldComponents'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { NumberFieldElement } from 'engageTools/studio/form/numberFieldElement'
import { ConfigurationUnit } from 'uiComponents/studio/menuItem'
import {
    ModalWindowConfiguration,
    OnChangeFunc,
    GetConfigValueFunc,
    SetConfigValueFunc,
} from 'engageTools/studio/schema'

interface FrequencyLimitSectionProps {
    section: ConfigurationUnit
    configuration: ModalWindowConfiguration
    getConfigValue: GetConfigValueFunc
    setConfigValue: SetConfigValueFunc
    handleInputChange: OnChangeFunc
    updateConfiguration: (path: string, value: string | number | boolean | string[]) => void
}

export function FrequencyLimitSection(props: FrequencyLimitSectionProps) {
    const [frequency, setFrequency] = useState<number>(0)
    const [unlimited, setUnlimited] = useState<boolean>(true)
    const { section, configuration } = props
    type ToggleType = 'toggle'
    type NumberType = 'number'

    useEffect(() => {
        setFrequency(configuration.maxTimesShownPerPeriod)
        setUnlimited(!configuration.maxTimesShownPerPeriod)
    }, [])

    const displayFrequencyUnlimitedField = {
        name: 'display-frequency-unlimited',
        type: 'toggle' as ToggleType,
        label: 'Unlimited',
        span: 6,
        path: 'maxTimesShownPerPeriod',
        defaultValue: false,
        infotip: 'When enabled, the Pop Up has no maximum display frequency per website viewer.',
    }
    const displayFrequencyField = {
        name: 'display-frequency',
        type: 'number' as NumberType,
        min: 0,
        max: 120,
        label: 'Frequency per day',
        span: 6,
        unit: '',
        path: 'maxTimesShownPerPeriod',
        defaultValue: 5,
        infotip: 'Maximum times per day, the Pop Up will be displayed to the website viewer.',
    }

    function handleUnlimitedFrequencyToggle(v: boolean) {
        if (!v) {
            props.updateConfiguration(displayFrequencyField.path, frequency)
            setUnlimited(false)
            setFrequency(5)
        } else {
            props.updateConfiguration(displayFrequencyField.path, 0)
            setUnlimited(true)
            setFrequency(0)
        }
    }
    function handleFrequencyChange(v: number) {
        setFrequency(v)
        props.updateConfiguration(displayFrequencyField.path, v)
    }

    return (
        <SectionWrapper title={section.name}>
            <FormItem>
                <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor={displayFrequencyUnlimitedField.name}>
                        {displayFrequencyUnlimitedField.label}
                        <Infotip pointer="left" maxWidth="16em" smallBoxText>
                            {displayFrequencyUnlimitedField.infotip}
                        </Infotip>
                    </label>
                </Col>
                <Col span={6} className="justify-right">
                    <ToggleComponent
                        field={displayFrequencyUnlimitedField}
                        handleChange={handleUnlimitedFrequencyToggle}
                        value={unlimited}
                    />
                </Col>
            </FormItem>
            {!unlimited && (
                <FormItem>
                    <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <label htmlFor={displayFrequencyField.name}>
                            {displayFrequencyField.label}
                            <Infotip pointer="left" maxWidth="16em" smallBoxText>
                                {displayFrequencyField.infotip}
                            </Infotip>
                        </label>
                    </Col>
                    <NumberFieldElement
                        field={displayFrequencyField}
                        handleChange={handleFrequencyChange}
                        value={frequency}
                    />
                </FormItem>
            )}
        </SectionWrapper>
    )
}
