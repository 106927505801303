import * as React from 'react'
import { UrlRules, ProductName } from 'engageTools/studio/schema'
import { SelectField } from 'uiComponents/studio/form/fieldComponentSchema'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { ConfigurationUnit } from 'uiComponents/studio/menuItem'
import {
    RuleDisplayContainer,
    RuleDisplay,
    getRuleTypeName,
    sortAlphabetically,
} from 'engageTools/studio/form/blacklistUrlSection'

interface WhitelistInfoSectionProps {
    urlRules: UrlRules | null
    section: ConfigurationUnit
    product: ProductName
}

export class WhitelistInfoSection extends React.Component<WhitelistInfoSectionProps> {
    render() {
        const { section } = this.props
        const urlRules: UrlRules | null = this.props.urlRules
        const selectRuleField = {
            name: 'whitelisted-logic',
            type: 'select',
            availableOptions: [
                {
                    name: 'URLs that contain',
                    value: 'contains',
                },
                {
                    name: 'URLs that do not contain',
                    value: 'not_contains',
                },
                {
                    name: 'REGEX',
                    value: 'regex',
                },
            ],
            label: 'URL that',
            span: 12,
            path: '',
            defaultValue: 'Contains',
        } as SelectField

        return (
            <SectionWrapper title={section.label || ''} infotip={section.infotip || ''}>
                <FormItem>
                    <RuleDisplayContainer>
                        {urlRules &&
                            urlRules.whitelist &&
                            urlRules.whitelist.sort(sortAlphabetically).map((rule, i) => {
                                if (rule.values.length > 0) {
                                    return (
                                        <RuleDisplay key={i} span={12}>
                                            <div className="ruleTitle">
                                                {getRuleTypeName(rule.type, selectRuleField.availableOptions)}
                                            </div>
                                            {rule.values.map((val, j) => {
                                                return (
                                                    <div className="ruleValue" key={j}>
                                                        <div className="valueText">{val}</div>
                                                    </div>
                                                )
                                            })}
                                        </RuleDisplay>
                                    )
                                } else {
                                    return null
                                }
                            })}
                    </RuleDisplayContainer>
                </FormItem>
            </SectionWrapper>
        )
    }
}
