import pick from 'lodash/pick'
import { usePushNotificationsContext } from '../context/PushNotifications'
import { decodeDateTimeStr } from '../helpers'
import { PNCampaign, PNFrequency, PNScheduleType } from '../types'

const usePNMap = () => {
    const { setTargetForm, setContentForm, setScheduleForm, setCampaign } = usePushNotificationsContext()

    const mapCampaignToForm = (campaign: PNCampaign) => {
        if (campaign.id) {
            // campaign
            setCampaign(campaign)

            // target
            setTargetForm(pick(campaign, ['name', 'location_id', 'segment_id']))

            // content
            setContentForm({
                url: campaign.url || '',
                content: campaign.content.length
                    ? campaign.content
                    : [
                          {
                              locale: 'en',
                              title: '',
                              body: '',
                          },
                      ],
            })

            // schedule
            const start = decodeDateTimeStr(campaign.schedule.start_time)
            const end = decodeDateTimeStr(campaign.schedule.end_time)
            setScheduleForm({
                type: PNScheduleType.time,
                recurring: campaign.schedule.frequency === PNFrequency.once,
                frequency: campaign.schedule.frequency,
                startDate: start.date,
                startTime: start.time,
                endDate: end.date,
                endTime: end.time,
            })
        }
    }

    return {
        mapCampaignToForm,
    }
}

export default usePNMap
