import React, { useState } from 'react'
import { History } from 'history'
import { Cell } from 'uiComponents/table'
import { OptionItem } from 'products/options/optionsService'
import { Col } from 'uiComponents/flex'
import { IconBox, IconWrapper, IconRow, MenuIcon } from 'products/actionMenu'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { faTrash, faPencilAlt } from '@fortawesome/pro-light-svg-icons'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { OptionsService } from 'products/options/optionsService'
import { MessageKind } from 'uiComponents/messages'
import Money from 'uiComponents/money'

interface OptionGroupListItemRowsProps {
    optionItem: OptionItem
    groupUuid: string
    accountSlug: string
    history: History
    optionsService: OptionsService
    nested: boolean
    loading: boolean
    reloadList: () => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

export function OptionItemsRowCells(props: OptionGroupListItemRowsProps) {
    const [itemToDelete, setItemToDelete] = useState('')

    const onDeleteConfirm = async () => {
        try {
            await props.optionsService.deleteOptionItem(props.accountSlug, itemToDelete, props.groupUuid)
            props.reloadList()
        } catch {
            props.replaceMessages(
                'delete_error',
                'error',
                'Oops! Could not delete the option item. Please try again later.',
            )
        } finally {
            setItemToDelete('')
        }
    }

    return (
        <>
            {!!itemToDelete && (
                <ConfirmationDialog
                    title="Delete the item?"
                    text="Are you sure you want to delete this option item?"
                    buttonText="Delete"
                    destructive
                    onCancel={() => setItemToDelete('')}
                    onConfirm={onDeleteConfirm}
                    loading={props.loading}
                />
            )}
            {props.nested && <Cell />}
            <Cell title={props.optionItem.name} className="withName">
                <span>{props.optionItem.name}</span>
            </Cell>
            <Cell align="center">{props.optionItem.priority}</Cell>
            <Cell style={{ textTransform: 'capitalize' }}>
                <Money amount={props.optionItem.price || 0} accountSlug={props.accountSlug} />
            </Cell>
            <Cell align="right" onClick={(e) => e.stopPropagation()}>
                <IconRow>
                    <Col />
                    <Col span={4}>
                        <IconBox title="Option item details">
                            <UnstyledLink
                                to={`/account/${props.accountSlug}/products/option_groups/option_item/${props.groupUuid}/${props.optionItem.id}`}
                            >
                                <IconWrapper>
                                    <MenuIcon icon={faPencilAlt} />
                                </IconWrapper>
                            </UnstyledLink>
                        </IconBox>
                    </Col>
                    <Col span={4}>
                        <IconBox title="Remove option item" onClick={() => setItemToDelete(props.optionItem.id)}>
                            <IconWrapper>
                                <MenuIcon icon={faTrash} />
                            </IconWrapper>
                        </IconBox>
                    </Col>
                </IconRow>
            </Cell>
        </>
    )
}
