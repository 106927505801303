import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-typed'

import { BaseRouteParams } from 'hocs'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'

const StyledLink = styled(UnstyledLink)`
    color: ${(props) => props.theme.colors.boyBlue};
`

interface AffectedOrdersProps {
    code?: string
    orders?: string[]
}

const AffectedOrders: React.FC<AffectedOrdersProps> = ({ code, orders }) => {
    const { accountSlug } = useParams<BaseRouteParams>()

    const path = `/account/${accountSlug}/orders/orders/?q=`

    if (!code || !orders || !Array.isArray(orders) || !orders.length) {
        return <>-</>
    }

    if (orders.length === 1) {
        const order = orders[0]
        return (
            <StyledLink to={`${path}${order}`} title={order}>
                {order}
            </StyledLink>
        )
    }

    return (
        <StyledLink to={`${path}${code}&searchType=extended`} title={orders.join(', ')}>
            {orders.length} orders
        </StyledLink>
    )
}

export default AffectedOrders
