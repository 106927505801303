import { createSlice } from '@reduxjs/toolkit'

export interface SideMenuState {
    open: boolean
}

const sideMenu = createSlice({
    name: 'sideMenu',
    initialState: {
        open: false,
    } as SideMenuState,
    reducers: {
        toggleSideBar(state: SideMenuState) {
            state.open = !state.open
        },
        closeSideBar(state: SideMenuState) {
            state.open = false
        },
    },
})

export const {
    actions: { closeSideBar, toggleSideBar },
    name,
    getInitialState,
} = sideMenu
export default sideMenu.reducer
