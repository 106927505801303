import React from 'react'
import { Headline } from './typography'

interface ErrorBoundaryProps {
    fallback?: any
}

interface ErrorBoundaryState {
    hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    render() {
        const { fallback } = this.props

        if (this.state.hasError) {
            return fallback ? fallback : <Headline size={5}>Something went wrong.</Headline>
        }

        return this.props.children
    }
}

export default ErrorBoundary
