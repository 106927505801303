import * as React from 'react'
import styled from 'styled-typed'
import { InfoText } from 'uiComponents/cards'
import { LeafNodeBox, SubOptionsList } from 'uiComponents/menus/'
import { NameWrapper, ArrowDown } from 'uiComponents/filter/filterOptionsItem'
import { ActionButton } from 'uiComponents/buttons'
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { createGetCategoryAncestors, useGetAllCategories, useGetAllCategoriesByParentId } from './redux'
import { CategoryFromApi } from './types'
import { flatten } from 'lodash'
import classNames from 'classnames'

export const Container = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    visibility: hidden;
    padding: 0;

    & > div {
        display: none;
    }

    &.visible {
        visibility: visible;
        padding: 1em 0 0 1.5rem;

        & > div {
            display: block;
        }
    }
`

export const Row = styled.div<{
    bold?: boolean
    indentation?: number
    underline?: boolean
}>`
    display: flex;
    justify-content: space-between;
    font-weight: ${(props) => (props.bold ? 'bolder' : 'normal')};
    padding-left: ${(props) => (props.indentation ? `${props.indentation * 1}em` : '')};
    ${(props) => props.underline && 'text-decoration: underline;'};
`
export const Text = styled(InfoText)`
    &:first-child {
        margin-right: 1.5em;
    }
`
export const Link = styled.div`
    &:hover {
        text-decoration: underline;
    }
`

const CategoryTreeWrapper = styled.div`
    visibility: hidden;

    &.visible {
        visibility: visible;
        width: 22.875rem;
        margin: 0 1.5rem 1.5rem 0;
        background: ${(props) => props.theme.colors.white};
        border: 1px solid ${(props) => props.theme.colors.border};
        box-sizing: border-box;
        border-radius: 4px;
        *border-bottom: none;
        height: fit-content;
    }

    & > ${LeafNodeBox}:nth-child(1) {
        border-radius: 4px 4px 0 0;
    }
`

const BorderLine = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
`

const List = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export interface AttachedProduct {
    id: string
    numericId: number
    name: string
    productListUuids: string[] | null
}

export interface CategoryLineProps {
    indentation: number
    item: CategoryFromApi
    filteredCatIds: string[]
    productListUuids: string[]
    accountSlug: string
    origin?: '?from=flat' | '?from=nested' | ''
    partnerAdmin?: boolean
    bordered?: boolean
}

export function CategoryLine(props: CategoryLineProps) {
    const trajectory = props.origin || ''
    const childCategories = useGetAllCategoriesByParentId(props.item?.id)
    const isParent = childCategories.length > 0

    if (props.filteredCatIds.indexOf(props.item.id) < 0) {
        return null
    }

    return (
        <>
            <LeafNodeBox className={isParent ? 'parent' : 'leaf'} grade={props.indentation}>
                <NameWrapper>
                    <UnstyledLink
                        to={`/account/${props.accountSlug}/products/crud/productList/${props.item.id}${trajectory}`}
                        disabled={props.productListUuids.indexOf(props.item.id) < 0 || !props.partnerAdmin}
                    >
                        {props.item.name['en']}
                    </UnstyledLink>
                    {isParent && <ArrowDown />}
                </NameWrapper>
            </LeafNodeBox>
            {props.bordered && !isParent && <BorderLine />}
            {isParent && (
                <>
                    <SubOptionsList>
                        {childCategories.map((child) => {
                            return (
                                <CategoryLine
                                    item={child}
                                    key={child.id + child.parentCategory?.id}
                                    indentation={props.indentation + 1}
                                    filteredCatIds={props.filteredCatIds}
                                    productListUuids={props.productListUuids}
                                    accountSlug={props.accountSlug}
                                    origin={props.origin}
                                    partnerAdmin={props.partnerAdmin}
                                />
                            )
                        })}
                    </SubOptionsList>
                    {props.bordered && <BorderLine />}
                </>
            )}
        </>
    )
}

function CategoryList(props: AttachedCategoriesListProps) {
    const accountCategories = useGetAllCategories()
    const ancestors = React.useMemo(() => {
        const getAncestors = createGetCategoryAncestors(accountCategories)
        return flatten(props.productListUuids.map((uuid) => getAncestors(uuid)))
    }, [accountCategories, props.productListUuids])

    const partnerAdmin = !!props.hasPermission && props.hasPermission('partner_admin', props.accountSlug)

    return (
        <List>
            {props.visible &&
                ancestors.map((category) => {
                    const isTopParent = category.parentCategory?.id === undefined
                    return (
                        <CategoryTreeWrapper key={category.id} className={classNames({ visible: isTopParent })}>
                            {isTopParent && (
                                <>
                                    <CategoryLine
                                        item={category}
                                        indentation={0}
                                        filteredCatIds={ancestors?.map((c) => c.id) || []}
                                        productListUuids={props.productListUuids}
                                        accountSlug={props.accountSlug}
                                        origin={props.origin}
                                        partnerAdmin={partnerAdmin}
                                        bordered
                                    />
                                    {!!props.onEditDetailsClick && partnerAdmin && (
                                        <ActionButton
                                            kind="action"
                                            secondary
                                            onClick={props.onEditDetailsClick}
                                            style={{ margin: '1rem', float: 'right' }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPencilAlt as IconProp}
                                                style={{ fontSize: '.8em', marginRight: '.5em' }}
                                            />
                                            Edit
                                        </ActionButton>
                                    )}
                                </>
                            )}
                        </CategoryTreeWrapper>
                    )
                })}
        </List>
    )
}

interface AttachedCategoriesListProps {
    visible: boolean
    productListUuids: string[]
    style?: React.CSSProperties
    onEditDetailsClick?: () => void
    accountSlug: string
    origin?: '?from=flat' | '?from=nested' | ''
    hasPermission?: (permission: string, accountSlug: string) => boolean
}

export function AttachedCategoriesList(props: AttachedCategoriesListProps) {
    return (
        <Container className={props.visible ? 'visible' : ''} style={props.style}>
            <div className="attached-categories-list">
                {!!props.productListUuids.length ? (
                    <CategoryList {...props} />
                ) : (
                    <Row bold style={{ marginBottom: '1.5em' }}>
                        <Text>No categories attached</Text>
                    </Row>
                )}
            </div>
        </Container>
    )
}
