import React, { useMemo } from 'react'
import styled from 'styled-typed'
import classNames from 'classnames'
import { ExtensibleReactTableComponentProps, ReactTable } from 'uiComponents/table/reactTable'
import { useSelector } from 'react-redux'
import { isLoadingArticlesAndCategories, useGetAllCategoriesAndArticlesById } from 'products/redux'
import { ProductTableProvider } from './provider'
import DeleteProduct from './deleteProduct'
import DuplicateProduct from './duplicateProduct'
import { useGetProductTableColumns } from './productTableColumns'

interface Props {
    productListId?: string
    depth?: number
}

const TableStyles = styled.div<{ depth: number }>`
    &.nestedTable {
        & .header {
            display: none;
        }

        .tr {
            height: 3rem !important;
            background-color: ${(props) => props.theme.colors.tableRow} !important;
        }
    }

    & .body .tr {
        height: 4rem;

        &::before {
            content: '';
            position: absolute;
            border-top: 1px solid ${(props) => props.theme.colors.border};
            width: calc(100% + ${(props) => (props.depth - 1) * 30}px);
            left: -${(props) => (props.depth - 1) * 30}px;
        }
    }
`
const ExpandedTableStyles = styled.div`
    width: 100%;
    padding-left: 30px;
    background-color: ${(props) => props.theme.colors.tableRow};
`

const ProductTable: React.FC<Props> = ({ productListId, depth = 1 }) => {
    const isLoading = useSelector(isLoadingArticlesAndCategories)
    const data = useGetAllCategoriesAndArticlesById(productListId)
    const columns = useGetProductTableColumns({ depth })
    const sortedData = useMemo(() => {
        return data.sort((a, b) => {
            return a.priority - b.priority
        })
    }, [data])

    const isLoadingTopLevel = isLoading && depth === 1

    return (
        <TableStyles
            depth={depth}
            className={classNames({
                nestedTable: depth > 1,
            })}
        >
            <ReactTable
                id="productTable"
                noResultsRow
                elevation={false}
                columns={columns}
                data={sortedData}
                size="small"
                loading={isLoadingTopLevel}
                storeStateInQuery={false}
                sort={{ prop: 'priority', direction: 'asc' }}
                expanded={({ row }: ExtensibleReactTableComponentProps<any>) => (
                    <ExpandedTableStyles>
                        <ProductTable productListId={row.original.id} depth={depth + 1} />
                    </ExpandedTableStyles>
                )}
            />
        </TableStyles>
    )
}

const ProductTableWrapper = () => {
    return (
        <ProductTableProvider>
            <DeleteProduct />
            <DuplicateProduct />
            <ProductTable />
        </ProductTableProvider>
    )
}

export default ProductTableWrapper
