import { InventoryItem } from 'inventory/inventoryService'
import { Availability } from 'reservations/types'
import { TimelineItem } from './types'
import uuid from 'uuid'

const startColor = 'rgb(255, 255, 255)'
const endColor = 'rgb(72, 120, 210)'

// Source for Color Interpolation: https://graphicdesign.stackexchange.com/a/83867
export const interpolateColor = (color1: number[], color2: number[], factor: number) => {
    var result = color1.slice()
    for (let i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]))
    }
    return result
}

export const interpolateColors = (color1: string, color2: string, steps: number) => {
    const color1Range = color1.match(/\d+/g)!.map(Number)
    const color2range = color2.match(/\d+/g)!.map(Number)

    const interpolatedColorArray = []

    for (var i = 0; i < steps; i++) {
        interpolatedColorArray.push(interpolateColor(color1Range, color2range, i / (steps - 1)))
    }

    return interpolatedColorArray
}

export const getColors = (capacity: number, available: number) => {
    if (isNaN(capacity) || isNaN(available)) return ''
    if (capacity === 0) return `background-color: ${endColor}`

    const reserved = capacity - available

    const capacityPercentage = Math.min(Math.floor((reserved / capacity) * 100), 100)

    const interpolatedColors = interpolateColors(startColor, endColor, 100)

    const [r, g, b] = interpolatedColors[Math.max(capacityPercentage - 1, 0)]

    const solidColor = `rgb(${r}, ${g}, ${b})`
    const background = `background-color: ${solidColor};`
    const border = 'border-width: 1px; border-style: solid;'
    const color = capacityPercentage >= 90 ? 'color: white;' : 'color: black;'

    return [background, border, color].join(' ')
}

export const getGroups = (inventoryList: InventoryItem[], style: string = 'min-width: 240px; padding: 20px 12px;') =>
    inventoryList.map((inventory) => ({ id: inventory.id, content: inventory.name, style }))

export const getItems = (availabilityList: Availability[]): TimelineItem[] =>
    availabilityList.reduce((items, { resourceId, availability }) => {
        const convertedItems = availability.map((item) => {
            const available = item.available || 0
            const capacity = item.capacity || 0

            return {
                id: uuid.v4(),
                available: available,
                capacity: capacity,
                start: item.dateFrom,
                end: item.dateTo,
                group: resourceId,
                style: getColors(capacity, available),
            }
        })

        return [...items, ...convertedItems]
    }, [])

const formatCalendarDate = (date: string, time: string) => `${date}T${time}`

const padZero = (num: number) => {
    return num < 10 ? `0${num}` : `${num}`
}

export const getCalendarMin = (date: string, startTime: string) => {
    const [hour, _minute] = startTime.split(':').map(Number)
    return formatCalendarDate(date, `${padZero(hour)}:00`)
}

export const getCalendarMax = (date: string, endTime: string) => {
    const [hour, minute] = endTime.split(':').map(Number)
    let calendarEndTime = minute > 0 ? `${padZero(hour + 1)}:00` : `${padZero(hour)}:00`
    return formatCalendarDate(date, calendarEndTime)
}
