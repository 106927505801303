import React from 'react'

import FieldWrapper, { FieldStatus, FieldVariant } from '../fieldWrapper'
import { useCustomId } from '../_shared/hooks'
import { Checkbox, CheckboxProps } from './checkbox'

interface CheckboxFieldProps extends CheckboxProps, React.InputHTMLAttributes<HTMLInputElement> {
    name: string
    id?: string
    label?: string
    error?: string
    state?: FieldStatus
    children?: (props: CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>) => React.ReactElement
    variant?: FieldVariant
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({
    name,
    label,
    id,
    children: Children,
    error,
    state,
    variant,
    ...props
}) => {
    const innerId = useCustomId(id)
    const customProps = {
        ...props,
        id: innerId,
    }

    return (
        <FieldWrapper variant={variant} label={label} error={error} id={innerId} status={state as FieldStatus}>
            {!!Children ? <Children {...customProps} /> : <Checkbox type="checkbox" {...customProps} />}
        </FieldWrapper>
    )
}

export default CheckboxField
