import * as React from 'react'
import { Account } from 'auth/state'
import { State } from 'store'
import { connect } from 'react-redux'
import { withFeatures } from 'features'
import {
    ButtonPosition,
    BarPosition,
    WonderbarConfiguration,
    ModalWindowConfiguration,
    TriggerButtonConfiguration,
    CheckoutConfiguration,
    Translation,
    UrlRules,
    Languages,
    ProductName,
} from 'engageTools/studio/schema'
import { LocaleService } from 'engageTools/studio/localesService'
import { debounce } from 'debounce'
import { getProductSlug } from 'engageTools/studio/shared'
import { PreviewLoaderIndicator } from './previewLoaders'
import {
    PreviewContainer,
    FrameWrapper,
    SafariMobilePreviewSrollWrapper,
    PreviewSize,
    PreviewFrame,
} from 'uiComponents/studio/preview/components'
import { AuthTicket } from 'http/oauthService'

const productNames = {
    wonderbar: 'wonderBar',
    modal_window: 'modalWindow',
    checkout: 'checkout',
    trigger_button: 'triggerButton',
    proof_messages: 'proofMessages',
}

const availableProducts = ['wonderbar', 'modal_window', 'checkout', 'trigger_button', 'proof_messages']

interface PreviewProps {
    product: ProductName
    accountSlug: string
    accounts: Account[]
    activeLanguage: string
    previewEndpoint: string
    previewSize: PreviewSize
    localeService: LocaleService
    toggleLoad: (on: boolean) => void
    hasFeature: (feature: string, slug: string) => boolean
    componentValues:
        | WonderbarConfiguration
        | ModalWindowConfiguration
        | TriggerButtonConfiguration
        | CheckoutConfiguration
        | null
    translations: Translation[]
    languages: Languages | null
    urlRules: UrlRules | null
    draftId: string
    handleInitialTranslations: (t: Translation[]) => void
    authTicket: AuthTicket
}

interface PreviewState {
    zoom: number
    previewLoaded: boolean
    fallbackSiteOn: boolean
}

interface UrlParts {
    url: string
    path: string
    search: string
}

function parseCompanyUrl(url: string): UrlParts | null {
    const pattern = /^([a-zA-Z]+:\/\/)?([^\/]+)([^?]*)\??(.*)?$/
    const match = pattern.exec(url)
    if (!match) {
        return null
    }
    const scheme = match[1] || 'http://'
    const host = match[2]
    const path = match[3] || '/'
    const search = match[4] || ''
    return {
        url: scheme + host,
        path,
        search,
    }
}

const minPreviewWidth = 1200

class Preview extends React.Component<PreviewProps, PreviewState> {
    productSlug = getProductSlug(this.props.product)
    private translationsTimeoutHandler: any = null
    private prieviewLoadedTimeoutHandler: any = null
    private previewFrame: Window | null = null
    private frameElement: HTMLIFrameElement | null = null
    private sendConfigurationLater = debounce(() => this.sendConfiguration(), 500)
    private updateZoomLater = debounce(() => this.updateZoom(), 500)

    constructor(props: PreviewProps) {
        super(props)
        this.state = {
            zoom: 1,
            previewLoaded: false,
            fallbackSiteOn: false,
        }
    }

    UNSAFE_componentWillMount() {
        window.addEventListener('message', this.onPreviewMessage, false)
        window.addEventListener('resize', this.onWindowResize, false)
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.onPreviewMessage)
        window.removeEventListener('resize', this.onWindowResize)
        this.sendConfigurationLater.clear()
        this.updateZoomLater.clear()
        if (this.translationsTimeoutHandler) {
            clearTimeout(this.translationsTimeoutHandler)
        }
        if (this.prieviewLoadedTimeoutHandler) {
            clearTimeout(this.prieviewLoadedTimeoutHandler)
        }
    }

    areTranslationsEmpty(): boolean {
        return this.props.translations && this.props.translations.length === 0
    }

    componentDidUpdate(prevProps: PreviewProps) {
        if (!this.state.fallbackSiteOn && !!this.frameElement && this.frameElement.contentWindow) {
            this.prieviewLoadedTimeoutHandler = setTimeout(() => {
                if (!this.state.previewLoaded) {
                    this.setState({ fallbackSiteOn: true })
                }
            }, 8000)
        }

        if (prevProps.translations !== this.props.translations) {
            this.translationsTimeoutHandler = setTimeout(() => {
                if (this.areTranslationsEmpty()) {
                    this.props.toggleLoad(false)
                    console.warn('Timed out after 15s, translations are empty and not being returned from the preview')
                }
            }, 15000)

            if (this.areTranslationsEmpty()) {
                this.props.toggleLoad(true)
            }
        }

        if (!this.state.previewLoaded) {
            return
        }

        if (
            prevProps.componentValues !== this.props.componentValues ||
            prevProps.translations !== this.props.translations ||
            prevProps.activeLanguage !== this.props.activeLanguage
        ) {
            this.sendConfigurationLater.trigger()
        }

        if (prevProps.previewSize !== this.props.previewSize) {
            this.updateZoom().then(() => this.sendResize())
        }
    }

    setFrameRef = (frame: HTMLIFrameElement) => {
        this.frameElement = frame
        this.updateZoom()
    }

    updateZoom() {
        return new Promise<void>((resolve) => {
            if (!this.frameElement) {
                resolve()
                return
            }
            const width = this.frameElement.clientWidth * this.state.zoom
            const zoom = this.props.previewSize !== 'desktop' || width >= minPreviewWidth ? 1 : width / minPreviewWidth

            if (this.state.zoom !== zoom) {
                this.setState({ zoom }, resolve)
            } else {
                resolve()
            }
        })
    }

    onWindowResize = () => {
        this.updateZoomLater.trigger()
    }

    sendResize() {
        if (this.previewFrame === null) {
            return
        }

        this.previewFrame.postMessage(
            {
                type: 'previewResize',
            },
            this.props.previewEndpoint,
        )
    }

    async requestCopies() {
        if (this.previewFrame === null) {
            return
        }

        this.previewFrame.postMessage(
            {
                type: 'getCopies',
                product: this.productSlug,
            },
            this.props.previewEndpoint,
        )
    }

    async sendConfiguration() {
        if (!this.props.languages || !this.props.translations || !this.props.componentValues) {
            console.warn('Missing preview configuration!')
            return
        }

        if (this.previewFrame === null) {
            return
        }

        const locales = await this.props.localeService.getLocales(this.props.languages.selected)
        const language = this.props.activeLanguage !== '' ? this.props.activeLanguage : this.props.languages.primary

        const previewProducts = {}

        availableProducts.forEach((ap) => {
            if (ap === this.props.product) {
                previewProducts[productNames[ap]] = this.props.draftId || null
            } else {
                previewProducts[productNames[ap]] = null
            }
        })
        if (this.props.product === 'trigger_button') {
            previewProducts[productNames['checkout']] = true
        }
        const productName =
            this.props.product === 'trigger_button'
                ? 'trigger_button'
                : this.props.product === 'checkout'
                ? 'checkout_theme'
                : this.productSlug
        this.previewFrame.postMessage(
            {
                type: 'configurationChange',
                components: {
                    [productName]: this.props.componentValues,
                },
                translations: this.props.translations,
                locales,
                activeLocale: language,
                products: previewProducts,
            },
            this.props.previewEndpoint,
        )
    }

    onPreviewMessage = (evt: MessageEvent) => {
        if (!evt.source || evt.origin !== this.props.previewEndpoint) {
            return
        }
        this.previewFrame = evt.source as Window
        switch (evt.data.type) {
            case 'requestConfiguration':
                this.setState({ previewLoaded: true })
                this.requestCopies()
                this.sendConfiguration()
                break
            case 'returnCopies':
                this.setState({ previewLoaded: true })
                this.handleInitialCopies(evt)
                break
            default:
                return
        }
    }

    handleInitialCopies = (evt: MessageEvent) => {
        const translations = evt.data.copies
        if (this.areTranslationsEmpty()) {
            this.props.handleInitialTranslations(translations)
        }
        this.props.toggleLoad(false)
    }

    render() {
        const { accountSlug, accounts, previewSize, product, componentValues } = this.props
        let position: ButtonPosition | BarPosition | null = null
        if (product === 'wonderbar' && componentValues) {
            position = (componentValues as WonderbarConfiguration).placement
        } else if (product === 'trigger_button' && componentValues) {
            position = (componentValues as TriggerButtonConfiguration).tbPosition
        }
        const account = accounts.find((x) => x.slug === accountSlug)

        if (!account) {
            return null
        }

        let url = parseCompanyUrl(account.url)
        let slug = accountSlug

        if (!url) {
            return null
        }

        if (this.state.fallbackSiteOn) {
            url = { url: 'https://www.convious.com', path: '/', search: '' }
            slug = 'convious_demo'
        }

        if (!this.props.componentValues || !this.props.translations || !this.props.languages) {
            return null
        }

        const search = url.search ? '&' + url.search : ''

        const previewUrl = `${this.props.previewEndpoint}${url.path}?convious_preview_address=${
            url.url
        }${search}&convious_preview_account=${slug}&convious_token=${encodeURIComponent(
            this.props.authTicket.accessToken,
        )}`
        return (
            <PreviewContainer className={previewSize}>
                <FrameWrapper>
                    <SafariMobilePreviewSrollWrapper>
                        <PreviewFrame src={previewUrl} ref={this.setFrameRef} zoom={this.state.zoom} />
                        {!this.state.previewLoaded && (
                            <PreviewLoaderIndicator product={product} size={previewSize} position={position} />
                        )}
                    </SafariMobilePreviewSrollWrapper>
                </FrameWrapper>
            </PreviewContainer>
        )
    }
}

function mapStateToProps(state: State) {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
        authTicket: state.auth.ticket,
    }
}

export default withFeatures(connect(mapStateToProps)(Preview))
