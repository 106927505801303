import * as React from 'react'
import { PriceSetting, AdjustmentType } from 'products/pricing/pricingService'
import { Currency } from 'uiComponents/money/moneyHoc'
import { ContainerWithStatus } from 'uiComponents/pageElements'
import { ValidationNotice } from 'products/components/validationNotice'
import { Adjustment } from './shared'
import { RulesWithToggle } from './rulesWithToggle'

interface RulesForNyopProps {
    currency: Currency
    adjustments: Adjustment[]
    activatePriceSettingRule: (priceType: PriceSetting, activate: boolean) => void
    setAdjustment: (priceType: PriceSetting, value: number, type: AdjustmentType) => void
    validate: boolean
}

export function RulesForNyop(props: RulesForNyopProps) {
    function handlePriceSettingRuleToggle(priceSetting: PriceSetting, on: boolean) {
        props.activatePriceSettingRule(priceSetting, on)
    }

    const { currency, adjustments, validate } = props
    const rulesNotValid = !adjustments.find((a) => a.on) && validate
    return (
        <>
            <ValidationNotice className={rulesNotValid ? 'validation-message-visible' : ''}>
                Select a rule
            </ValidationNotice>
            <ContainerWithStatus status={rulesNotValid ? 'error' : 'success'} style={{ flexDirection: 'column' }}>
                <RulesWithToggle
                    adjustments={adjustments}
                    currency={currency}
                    handleRuleChange={props.setAdjustment}
                    validate={validate}
                    handlePriceSettingRuleToggle={handlePriceSettingRuleToggle}
                />
            </ContainerWithStatus>
        </>
    )
}
