import React from 'react'
import Typography from '@mui/material/Typography'
import PrintDownload from './printAndDownload'
import CancelRefund from './cancelAndRefund'
import Email from './resendEmail'
import './index.scss'
import RedeemTickets from './redeemTickets'
import { ReplaceMessagesFunc } from 'hocs'
import { FillDetailsButton } from './fillDetails/fillDetalsButton'

interface OrderTicketsActionsProps {
    hideMessage: (id: string) => void
    replaceMessages: ReplaceMessagesFunc
}

const OrderTicketsActions: React.FC<OrderTicketsActionsProps> = ({ replaceMessages, hideMessage }) => {
    return (
        <div className="actions-container">
            <Typography variant="body1" className="actions-caption">
                Actions:
            </Typography>
            <FillDetailsButton />
            <PrintDownload />
            <Email />
            <RedeemTickets replaceMessages={replaceMessages} hideMessage={hideMessage} />
            <CancelRefund />
        </div>
    )
}

export default OrderTicketsActions
