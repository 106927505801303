import * as React from 'react'
import styled from 'styled-typed'
import { History } from 'history'
import { ModalDialog } from 'uiComponents/popups/modal'
import { ActionButton } from 'uiComponents/buttons'
import { BrowserBox } from 'uiComponents/signUpContainer/productCard'

interface MobileDeviceWarningProps {
    history: History
    returnPath: string
}

interface MobileDeviceWarningState {
    hideWarning: boolean
}

const WarningContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const WarningText = styled.div`
    text-align: center;
    line-height: 1.35em;
    max-width: 17em;
`
const WarningMessage = styled.div`
    font-size: 0.875em;
    font-weight: 300;
    line-height: 1.35em;
`

const WarningActionContainer = styled.div`
    margin-top: 1.7em;
    width: 100%;
    min-width: 15em;
    display: flex;
    align-items: stretch;

    @media screen and (min-width: 360px) {
        min-width: 17em;
    }
`
const MobileIcon = styled.div`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: 2.7em;
    width: 1.68em;
    border: 2px solid ${(props) => props.theme.colors.textDark};
    border-radius: 0.25em;
    margin-top: 0.77em;
    opacity: 0.5;

    &:before {
        content: '';
        display: block;
        border-bottom: 2px solid ${(props) => props.theme.colors.textDark};
        width: 0.7em;
        margin-left: 0.365em;
    }
`

export class MobileDeviceWarning extends React.Component<MobileDeviceWarningProps, MobileDeviceWarningState> {
    constructor(props: MobileDeviceWarningProps) {
        super(props)
        this.state = {
            hideWarning: false,
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize)
    }

    onWindowResize = () => {
        if (window.innerWidth < 1190 && !sessionStorage.getItem('MobileDeviceWarning')) {
            this.setState({ hideWarning: false })
        } else {
            this.setState({ hideWarning: true })
        }
    }

    hideWarning = () => {
        sessionStorage.setItem('MobileDeviceWarning', 'dismissed')
        this.setState({ hideWarning: true })
    }

    exitStudio = () => {
        this.props.history.push(this.props.returnPath)
    }

    render() {
        const warningDismissed = sessionStorage.getItem('MobileDeviceWarning')
        const showWarning = window.innerWidth < 1190 && !warningDismissed && !this.state.hideWarning

        return (
            <>
                {showWarning && (
                    <ModalDialog onDismiss={this.hideWarning}>
                        <WarningContainer>
                            <BrowserBox style={{ marginTop: '.7em' }}>
                                <MobileIcon />
                            </BrowserBox>
                            <WarningText>
                                Our Studio needs space...
                                <WarningMessage>We strongly recommend to use a desktop or a notebook.</WarningMessage>
                            </WarningText>
                            <WarningActionContainer>
                                <ActionButton
                                    id="dismiss-warning"
                                    size="large"
                                    secondary
                                    onClick={this.hideWarning}
                                    style={{ marginLeft: '.5em' }}
                                >
                                    Continue
                                </ActionButton>
                                <ActionButton size="large" onClick={this.exitStudio} style={{ marginLeft: '1em' }}>
                                    Set up later
                                </ActionButton>
                            </WarningActionContainer>
                        </WarningContainer>
                    </ModalDialog>
                )}
            </>
        )
    }
}
