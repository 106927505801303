import React from 'react'

import FieldWrapper, { FieldStatus, FieldVariant } from '../fieldWrapper'
import { useCustomId } from '../_shared/hooks'
import { Radio, RadioProps } from './radio'

export interface RadioFieldProps extends RadioProps, React.InputHTMLAttributes<HTMLInputElement> {
    name: string
    id?: string
    label?: string
    error?: string
    children?: any
    variant?: FieldVariant
    status?: FieldStatus
    labelProps?: any
}

const RadioField: React.FC<RadioFieldProps> = ({
    label,
    id,
    variant = 'inline-reverse',
    status,
    error,
    labelProps,
    className,
    ...props
}) => {
    const innerId = useCustomId(id)

    const customProps = {
        ...props,
        id: innerId,
    }

    return (
        <FieldWrapper
            variant={variant}
            label={label}
            id={innerId}
            status={status || 'normal'}
            error={error}
            className={className}
            labelProps={{ ...labelProps, style: { cursor: 'pointer', ...labelProps?.style } }}
        >
            <Radio type="radio" {...customProps} />
        </FieldWrapper>
    )
}

export default RadioField
