import * as React from 'react'
import { PriceSetting, AdjustmentType } from 'products/pricing/pricingService'
import { Currency } from 'uiComponents/money/moneyHoc'
import { Row, Column, ContainerWithStatus } from 'uiComponents/pageElements'
import { ValidationNotice } from 'products/components/validationNotice'
import { AdjustRuleItem } from './ruleItem'
import { Adjustment } from './shared'

interface RulesForUpsellProps {
    currency: Currency
    adjustment: Adjustment
    activatePriceSettingRule: (priceSetting: PriceSetting, activate: boolean) => void
    setAdjustment: (priceSetting: PriceSetting, value: number, type: AdjustmentType) => void
    validate: boolean
}

export function RulesForUpsell(props: RulesForUpsellProps) {
    function setAdjustment(priceSetting: PriceSetting, value: number, type: AdjustmentType) {
        props.setAdjustment(priceSetting, value, type)
        props.activatePriceSettingRule(priceSetting, true)
    }

    const { currency, adjustment, validate } = props
    const ruleNotValid = !adjustment.on && validate
    return (
        <>
            <ValidationNotice className={ruleNotValid ? 'validation-message-visible' : ''}>
                Select a rule
            </ValidationNotice>
            <ContainerWithStatus status={ruleNotValid ? 'error' : 'success'} style={{ flexDirection: 'column' }}>
                <Row>
                    <Column>
                        <div style={{ marginLeft: '0.5em' }}>Rule for Price</div>
                    </Column>
                </Row>
                <Row>
                    <AdjustRuleItem
                        isOn={true}
                        priceSetting={adjustment.name}
                        adjustmentType={adjustment.type}
                        adjustmentSize={adjustment.value}
                        currency={currency}
                        handleRuleChange={setAdjustment}
                        validate={validate}
                    />
                </Row>
            </ContainerWithStatus>
        </>
    )
}
