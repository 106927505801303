import * as React from 'react'
import { withCurrentUser } from 'hocs'
import { User } from 'auth/state'
import { LoggingService } from 'http/loggingService'
import { SidebarMenu } from 'sidebar/sidebarMenu'

interface SidebarProps {
    accountSlug: string
    selfSignUp: string | null
    hasPermission: (permission: string, accountSlug: string) => boolean
    hasFeature: (feature: string, accountSlug?: string) => boolean
    loggingService: LoggingService
    user: User
}

function Sidebar({ accountSlug, selfSignUp, hasPermission, hasFeature, loggingService, user }: SidebarProps) {
    return (
        <SidebarMenu
            accountSlug={accountSlug}
            selfSignUp={selfSignUp}
            hasPermission={hasPermission}
            hasFeature={hasFeature}
            loggingService={loggingService}
            user={user}
        />
    )
}

export default withCurrentUser(Sidebar)
