import React from 'react'
import ItemSection from './components/itemSection'
import Money from 'uiComponents/money'
import { IOrderItemOption } from 'orders/schema'

const ItemOptionsSection = ({
    options,
    isItemSelected,
    accountSlug,
    isItemCanceledRefunded,
}: {
    options: IOrderItemOption[]
    isItemSelected: boolean
    accountSlug: string
    isItemCanceledRefunded: boolean
}) => {
    const totalPrice = React.useMemo(() => {
        return options.reduce((acc, option) => acc + Number(option.priceInclTax), 0)
    }, [options])

    return (
        <ItemSection
            sectionName={
                <>
                    Options: (<Money amount={totalPrice} accountSlug={accountSlug} />)
                </>
            }
            isItemSelected={isItemSelected}
            isItemCanceledRefunded={isItemCanceledRefunded}
        >
            <div className="item-options-content">
                <div className="content-row">
                    <h6 className="content-column start">Name</h6>
                    <h6 className="content-column center">Quantity</h6>
                    <h6 className="content-column end">Subtotal</h6>
                </div>
                {options.map((option, index) => (
                    <div key={index} className="content-row">
                        <span className="content-column start body1 light">{option.name}</span>
                        <span className="content-column center body1 light">{option.numberOfItems}</span>
                        <span className="content-column end body1 light">{option.priceInclTax}</span>
                    </div>
                ))}
                <div className="separator" />
                <div className="content-column total-price">
                    <span className="body1 bold">Total:</span>
                    <span className="body1">
                        <Money amount={totalPrice} accountSlug={accountSlug} />
                    </span>
                </div>
            </div>
        </ItemSection>
    )
}

export default ItemOptionsSection
