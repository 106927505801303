import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faMap, faSatellite } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { useParkMapViewerContext } from '../../context/mapViewer'

const Container = styled.div`
    position: absolute;
    top: 14px;
    left: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`

export const Toggler = styled.a`
    display: flex;
    justify-content: center;
    height: 35px;
    width: 35px;
    padding: 8px;
    border-width: 1px;
    border-color: ${(props) => props.theme.colors.textDark};
    border-style: solid;
    border-radius: 6px;
    background: ${(props) => props.theme.colors.white};
    cursor: pointer;
`

const Left = styled(Toggler)`
    background: ${(props) => props.theme.colors.white};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-style: none;
    fill: ${(props) => props.theme.colors.textDark};
`

const LeftActive = styled(Left)`
    background: ${(props) => props.theme.colors.textDark};
    fill: ${(props) => props.theme.colors.white};
`

const Right = styled(Toggler)`
    fill: ${(props) => props.theme.colors.textDark};
    background: ${(props) => props.theme.colors.white};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-style: none;
`

const RightActive = styled(Right)`
    background: ${(props) => props.theme.colors.textDark};
    fill: ${(props) => props.theme.colors.white};
`

const ParkMapTypeControl = () => {
    const theme = useTheme()
    const { mapType, setMapType } = useParkMapViewerContext()

    const isSattelite = mapType === google.maps.MapTypeId.SATELLITE
    const LeftToggler = !isSattelite ? LeftActive : Left
    const RightToggler = isSattelite ? RightActive : Right

    return (
        <Container>
            <LeftToggler onClick={() => setMapType(google.maps.MapTypeId.SATELLITE)}>
                <FontAwesomeIcon
                    icon={faSatellite as IconProp}
                    color={theme.colors[!isSattelite ? 'white' : 'textDark']}
                    size="sm"
                />
            </LeftToggler>
            <RightToggler onClick={() => setMapType(google.maps.MapTypeId.ROADMAP)}>
                <FontAwesomeIcon
                    icon={faMap as IconProp}
                    color={theme.colors[isSattelite ? 'white' : 'textDark']}
                    size="sm"
                />
            </RightToggler>
        </Container>
    )
}

export default ParkMapTypeControl
