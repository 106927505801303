import * as React from 'react'
import { Switch, Route, Redirect, useParams } from 'react-router-dom'

import Studio from 'engageTools/studio'
import EngageToolsOverviewPage from 'engageTools/overview/'
import TapPlacementEditStudio from 'tapPlacements/studio'
import TapPromotionStudio from 'engageTools/studio/promotion'
import PromotionContext from 'engageTools/studio/promotion/context'
import WithRoutePermission from '../guards/withRoutePermission'
import { AppServices } from 'middleware'
import { BaseRouteParams } from 'hocs'
import { ConfigReader } from 'utils/confiReader'
import SidebarMenuAndHeader from 'uiComponents/appLayouts/headerSidebar'

function EngageToolsRoutes() {
    const { accountSlug } = useParams<BaseRouteParams>()

    return (
        <Switch>
            <Route
                path="/account/:accountSlug/engage/tools/wonderbar/:id/:step?"
                render={(routeProps) => (
                    <WithRoutePermission requiredPermissions={['edit_wonderbar']}>
                        <Studio
                            {...routeProps}
                            product="wonderbar"
                            accountSlug={accountSlug}
                            previewEndpoint={ConfigReader.getPreviewEndpoint()}
                            localeService={AppServices.localeService}
                            snippetService={AppServices.snippetService}
                            componentsService={AppServices.componentsService}
                            imagesService={AppServices.imagesService}
                            loggingService={AppServices.loggingService}
                        />
                    </WithRoutePermission>
                )}
            />
            <Route
                path="/account/:accountSlug/engage/tools/popup/:id/:step?"
                render={(routeProps) => (
                    <WithRoutePermission requiredPermissions={['edit_wonderbar']}>
                        <Studio
                            {...routeProps}
                            product="modal_window"
                            accountSlug={accountSlug}
                            previewEndpoint={ConfigReader.getPreviewEndpoint()}
                            localeService={AppServices.localeService}
                            snippetService={AppServices.snippetService}
                            componentsService={AppServices.componentsService}
                            imagesService={AppServices.imagesService}
                            loggingService={AppServices.loggingService}
                        />
                    </WithRoutePermission>
                )}
            />
            <Route
                path="/account/:accountSlug/engage/tools/trigger_button/:id/:step?"
                render={(routeProps) => (
                    <WithRoutePermission requiredPermissions={['edit_wonderbar']}>
                        <Studio
                            {...routeProps}
                            product="trigger_button"
                            accountSlug={accountSlug}
                            previewEndpoint={ConfigReader.getPreviewEndpoint()}
                            localeService={AppServices.localeService}
                            snippetService={AppServices.snippetService}
                            componentsService={AppServices.componentsService}
                            imagesService={AppServices.imagesService}
                            loggingService={AppServices.loggingService}
                        />
                    </WithRoutePermission>
                )}
            />
            <Route
                path="/account/:accountSlug/engage/tools/checkout/:id/:step?"
                render={(routeProps) => (
                    <WithRoutePermission requiredPermissions={['edit_wonderbar']}>
                        <Studio
                            {...routeProps}
                            product="checkout"
                            accountSlug={accountSlug}
                            previewEndpoint={ConfigReader.getPreviewEndpoint()}
                            localeService={AppServices.localeService}
                            snippetService={AppServices.snippetService}
                            componentsService={AppServices.componentsService}
                            imagesService={AppServices.imagesService}
                            loggingService={AppServices.loggingService}
                        />
                    </WithRoutePermission>
                )}
            />
            <Route
                path="/account/:accountSlug/engage/tools/placements/:step/:id?"
                render={(routeProps) => (
                    <WithRoutePermission requiredPermissions={['partner_admin']}>
                        <TapPlacementEditStudio
                            accountSlug={accountSlug}
                            tapPlacementService={AppServices.tapPLacementService}
                            history={routeProps.history}
                            componentsService={AppServices.componentsService}
                        />
                    </WithRoutePermission>
                )}
            />
            <Route
                path="/account/:accountSlug/engage/tools/promotions/:step/:id?"
                render={(routeProps) => (
                    <WithRoutePermission requiredPermissions={['partner_admin']}>
                        <PromotionContext>
                            <TapPromotionStudio
                                {...routeProps}
                                accountSlug={accountSlug}
                                promotionService={AppServices.tapPromotionService}
                            />
                        </PromotionContext>
                    </WithRoutePermission>
                )}
            />
            <Route
                path="/account/:accountSlug/engage/tools/home/"
                render={({ history, location }) => (
                    <SidebarMenuAndHeader>
                        <EngageToolsOverviewPage
                            accountSlug={accountSlug}
                            history={history}
                            location={location}
                            componentsService={AppServices.componentsService}
                            placementService={AppServices.tapPLacementService}
                            promotionService={AppServices.tapPromotionService}
                        />
                    </SidebarMenuAndHeader>
                )}
            />
            <Route render={() => <Redirect to={`/account/${accountSlug}/engage/tools/home/`} />} />
        </Switch>
    )
}

export default EngageToolsRoutes
