import { TimelineItem } from '../types'

const TimelineItemTemplate = (item: TimelineItem): string => {
    return `
      <div>
          <b>${item.capacity - item.available} reserved</b>
          <span>${item.available} available</span>
      </div>
  `
}

export default TimelineItemTemplate
