import { useHasFeature } from 'features'
import React from 'react'
import CheckboxField from 'uiComponents/input/checkbox/checkboxField'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import Audience from './audienceSelect'
import StudioFieldWrapper from './studioFormWrapper'

const AudienceMarketing = () => {
    const hasFeature = useHasFeature('CRMAudiencesPage')

    if (!hasFeature) {
        return null
    }

    return (
        <SectionWrapper title="Audience">
            <Audience />
            <div style={{ paddingBottom: '1rem' }} />
            <StudioFieldWrapper
                name="productConfig.marketingConsent"
                type="checkbox"
                label="Marketing cookies"
                variant="inline"
                normalize={(e) => e.target.checked}
                component={CheckboxField}
            />
            <div style={{ paddingBottom: '2rem' }} />
        </SectionWrapper>
    )
}

export default AudienceMarketing
