import * as React from 'react'
import { useEffect } from 'react'
import styled from '../../styled-typed'
import { LoaderWrapper } from '../../uiComponents/loaders'
import Header from '../components/header'
import { usePushNotificationsContext } from '../context/PushNotifications'
import ScheduleForm from '../forms/schedule'
import usePNActions from '../hooks/usePNActions'
import { TemplateContainer, TemplateDescription, TemplateName } from './target'

export const Buttons = styled.div`
    button {
        margin-left: 4px;
    }
`

export const SchedulePageHeader = () => {
    return (
        <>
            <TemplateName>Schedule your message</TemplateName>
            <TemplateDescription>Your notifications can be scheduled for fixed dates</TemplateDescription>
        </>
    )
}

const SchedulePage = () => {
    const { campaign, targetForm, contentForm, scheduleForm, setScheduleForm, isFormFilled } =
        usePushNotificationsContext()
    const { createPN, updatePN, isLoading } = usePNActions()

    useEffect(() => {
        isFormFilled()
    }, [])

    return (
        <div>
            <Header />
            <TemplateContainer>
                <SchedulePageHeader />
                <LoaderWrapper loading={isLoading}>
                    <ScheduleForm
                        onSubmit={(data) => {
                            setScheduleForm(data)
                            if (campaign) {
                                updatePN({ campaign, targetForm, contentForm, scheduleForm: data })
                            } else {
                                createPN({ targetForm, contentForm, scheduleForm: data })
                            }
                        }}
                        data={scheduleForm}
                    />
                </LoaderWrapper>
            </TemplateContainer>
        </div>
    )
}

export default SchedulePage
