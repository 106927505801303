import React, { useMemo } from 'react'
import { ArticleFromApi } from '../../types'
import { createGetCategoryAncestors, useGetAllArticles, useGetAllCategories } from '../../redux'
import { getProductName } from '../../productTable/utils'
import { SingleSelect, SingleSelectOption } from '../../../uiComponents/input'
import { Column } from '../../../uiComponents/pageElements'
import { FormItem, FormItemName } from '../../../uiComponents/form/formElements'

interface Props {
    selectedProductList: string | null
    selectedArticles: ArticleFromApi[] | null
    productsValid?: boolean
    handleProductListSelectChange: (id: string) => Promise<void>
}

export const ExceptionProductList = ({
    selectedProductList,
    selectedArticles,
    productsValid,
    handleProductListSelectChange,
}: Props) => {
    const productLists = useGetAllCategories()
    const articles = useGetAllArticles()
    const getCategoryAncestors = createGetCategoryAncestors(productLists)

    const availableProductLists = useMemo(() => {
        const productListsWithArticlesNotSelected = [] as string[]
        const selectedArticlesIds = selectedArticles?.map((ep) => ep.id) ?? []
        const allNotSelectedArticles = articles.filter((article) => !selectedArticlesIds.includes(article.id))

        allNotSelectedArticles.forEach((article) => {
            article.categories?.forEach((category) => {
                getCategoryAncestors(category.id).forEach((ancestor) =>
                    productListsWithArticlesNotSelected.push(ancestor.id),
                )

                productListsWithArticlesNotSelected.push(category.id)
            })
        })

        const filteredProductList = productLists.filter((category) => {
            return productListsWithArticlesNotSelected.includes(category.id)
        })

        const options = filteredProductList.map((f) => {
            return {
                value: f.id,
                name: getProductName(f.name) as string,
                parentId: f.parentCategory?.id,
            } as SingleSelectOption
        })

        options.push({ value: 'all', name: 'Select all' })

        return options
    }, [articles, productLists, selectedArticles, selectedProductList])

    return (
        <Column>
            <FormItem htmlFor="products">
                <FormItemName style={{ display: 'flex' }}>Product list</FormItemName>
                <SingleSelect
                    nested
                    id="exceptionProductList"
                    name="exceptionProductList"
                    noSelectOption="Select a product list"
                    options={availableProductLists}
                    selected={selectedProductList}
                    height="2.8em"
                    status={productsValid ? 'normal' : 'error'}
                    onSelect={handleProductListSelectChange}
                />
            </FormItem>
        </Column>
    )
}
